import React from 'react'
import { connect } from 'react-redux'
import convert from 'convert-units'
import _capitalize from 'lodash/capitalize'
import _startCase from 'lodash/startCase'

import SectionEntry from './section_entry'

import { getModelValue } from 'reducers/selectors'
import { listToSentence } from 'lib/language_helpers'

import { lengthMetricToImperialWithInches, precisionRound } from 'lib/unit_conversions'

class Biometrics extends React.Component {
  prefersImperial() {
    return this.props.unitPreference === 'imperial'
  }

  heightValue() {
    const heightCm = this.props.biometrics.height_cm

    if (!heightCm) return '-'

    if (this.prefersImperial()) {
      let { feet, inches } = lengthMetricToImperialWithInches(heightCm)

      feet = Math.round(feet)
      inches = Math.round(inches)

      return `${feet}ft. ${inches}in.`
    } else {
      return `${Math.round(heightCm)}cm`
    }
  }

  weightValue() {
    const weightKg = this.props.biometrics.weight_kg

    if (!weightKg) return '-'

    if (this.prefersImperial()) {
      let pounds = convert(weightKg).from('kg').to('lb')

      pounds = Math.round(pounds)

      return `${pounds}lbs`
    } else {
      const rounded = precisionRound(weightKg, 1)

      return `${rounded}kg`
    }
  }

  render() {
    const { biometrics, role } = this.props
    const prependRole = (slug) => `${role}-${slug}`

    return [
      <SectionEntry label="Height" key="height" editHref={prependRole('biographic-info')}>
        {this.heightValue()}
      </SectionEntry>,
      <SectionEntry label="Weight" key="weight" editHref={prependRole('biographic-info')}>
        {this.weightValue()}
      </SectionEntry>,
      <SectionEntry label="Eye Color" key="eye" editHref={prependRole('eye-color')}>
        {_capitalize(biometrics.eye_color)}
      </SectionEntry>,
      <SectionEntry label="Hair Color" key="hair" editHref={prependRole('hair-color')}>
        {_capitalize(biometrics.hair_color)}
      </SectionEntry>,
      <SectionEntry label="Ethnicity" key="ethnicity" editHref={prependRole('ethnicity')}>
        {listToSentence(this.props.biometrics.ethnicities.map(_startCase))}
      </SectionEntry>
    ]
  }
}

const mapStateToProps = (state, props) => ({
  biometrics: getModelValue(state, `${props.role}.biometrics`),
  unitPreference: getModelValue(state, 'preferences.unit_conversions')
})

export default connect(mapStateToProps)(Biometrics)
