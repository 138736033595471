import React, { FunctionComponent } from 'react'

import EmptyCircleIcon from './empty_circle_icon'
import CheckmarkIcon from './checkmark_icon'

interface Props {
  checked?: boolean
}

const CircleCheckmark: FunctionComponent<Props> = ({ checked }) =>
  checked ? (
    <CheckmarkIcon className="o-media__figure mr-3 c-icon--success" aria-hidden="true" />
  ) : (
    <EmptyCircleIcon className="o-media__figure mr-3" style={{ color: '#b3b3b3' }} aria-hidden="true" />
  )

export default CircleCheckmark
