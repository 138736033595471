import React, { FunctionComponent, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { push } from 'redux-little-router'

import {
  getCurrentDocRequestIndex,
  getSortedDocumentRequests,
  getCurrentKaseId,
  getCurrentDocumentRequestId,
  getStewardInfo,
  slaIsSet
} from 'reducers/selectors'
import { loadDocumentRequestData, goToDocRequestByIndex } from 'actions/document_request_actions'
import { DocumentRequestStatus } from 'lib/constants'

import AdditionalInformation from 'components/screens/document_upload/additional_information'
import ArrowIcon from 'components/icons/arrow_icon'
import BreakpointWrapper from 'components/breakpoint_wrapper'
import Button from 'components/button'
import DocumentUploadRequestContainer from './request_container'
import MobileDocumentDetails from './mobile_document_details'
import MobileDocUploadArea from 'components/screens/document_upload/mobile_doc_upload_area'
import MobileDocNavigation from './mobile_doc_navigation'
import Outline from 'components/screens/document_upload/outline'
import MobileOutline from 'components/screens/document_upload/mobile_outline'
import { smoothScrollToTopOfElement } from 'lib/ui_helpers'

interface MappedProps {
  currentDocRequestIdParam?: string
  currentDocRequestIndex: number
  documentRequests: DocumentRequestModel[]
  kaseId: number
  stewardEmail: string
  slaIsSet: boolean
}

type NavigationVia = 'Outline' | 'ArrowNavigation' | null

interface ActionProps {
  goToDocRequestByIndex: (docRequestIndex: number) => void
  loadDocumentRequestData: Function
  setDocumentRequestRoute: (newRoute: string, via: NavigationVia) => void
}

const DocumentUpload: FunctionComponent<MappedProps & ActionProps> = ({
  currentDocRequestIdParam,
  currentDocRequestIndex,
  documentRequests,
  goToDocRequestByIndex,
  kaseId,
  loadDocumentRequestData,
  setDocumentRequestRoute,
  stewardEmail,
  slaIsSet
}) => {
  const [currentNavigationVia, setCurrentNavigationVia] = useState(null)
  const [showOutlineModal, setShowOutlineModal] = useState(false)
  const [showTips, setShowTips] = useState(false)

  useEffect(() => {
    if (kaseId) {
      loadDocumentRequestData({ kaseId })
    }
  }, [kaseId])

  const requestNeedsCustomerActionIndex = documentRequests.findIndex(
    (request: DocumentRequestModel) => request.status === DocumentRequestStatus.NeedsCustomerAction
  )

  useEffect(() => {
    if (!documentRequests.length) return

    if (currentDocRequestIdParam) {
      // If the url includes a doc request id, go to that request
      const docRequestIndex = documentRequests.findIndex(
        (request: DocumentRequestModel) => request.id === currentDocRequestIdParam
      )

      goToDocRequestByIndex(docRequestIndex)
    } else if (requestNeedsCustomerActionIndex > -1) {
      // Find first request with 'needs customer action status' if any
      goToDocRequestByIndex(requestNeedsCustomerActionIndex)
    } else {
      // Return first request in array
      goToDocRequestByIndex(0)
    }
  }, [documentRequests.length])

  useEffect(() => {
    if (!documentRequests.length) return

    const currentlyViewedId = documentRequests[currentDocRequestIndex].id

    setDocumentRequestRoute(`/applications/${kaseId}/document_upload/${currentlyViewedId}`, currentNavigationVia)
  }, [currentDocRequestIndex])

  const closeOutlineModal = () => {
    setShowOutlineModal(false)
  }

  const showTheOutlineModal = () => {
    setShowOutlineModal(true)
  }

  const updateCurrentDocRequest = (requestId: string) => {
    const docRequestIndex = documentRequests.findIndex((request) => request.id === requestId)
    goToDocRequestByIndex(docRequestIndex)
    setCurrentNavigationVia('Outline')

    if (showOutlineModal) {
      closeOutlineModal()
    }
  }

  const showPreviousDocRequest = () => {
    const prevRequestIndex = Math.max(currentDocRequestIndex - 1, 0)
    goToDocRequestByIndex(prevRequestIndex)
    setCurrentNavigationVia('ArrowNavigation')
  }

  const showNextDocRequest = () => {
    const nextRequestIndex = Math.min(currentDocRequestIndex + 1, documentRequests.length - 1)

    goToDocRequestByIndex(nextRequestIndex)
    setCurrentNavigationVia('ArrowNavigation')
  }

  const toggleTips = (event) => {
    event.preventDefault()

    // Scroll to the top of the tooltip container
    if (!showTips) {
      smoothScrollToTopOfElement(document.getElementById('tips-for-success-anchor'))
    }

    setShowTips(!showTips)
  }

  const docRequestsAreLoading = !documentRequests || documentRequests.length === 0

  const activeDocumentRequest: DocumentRequestModel = docRequestsAreLoading
    ? null
    : documentRequests[currentDocRequestIndex]

  return (
    <div className="o-action-items__wrapper">
      <BreakpointWrapper mobile>
        <div style={{ minHeight: '90vh' }}>
          <MobileDocumentDetails documentRequest={activeDocumentRequest} slaIsSet={slaIsSet} />
          <MobileDocUploadArea documentRequest={activeDocumentRequest} />
        </div>
        <MobileOutline
          closeModal={closeOutlineModal}
          documentRequests={documentRequests}
          isActive={showOutlineModal}
          kaseId={kaseId}
          onDocRequestClick={updateCurrentDocRequest}
          slaIsSet={slaIsSet}
        />
        <MobileDocNavigation
          documentRequests={documentRequests}
          currentDocRequestIndex={currentDocRequestIndex}
          showPreviousDocRequest={showPreviousDocRequest}
          showNextDocRequest={showNextDocRequest}
          showOutlineModal={showTheOutlineModal}
        />
      </BreakpointWrapper>

      <BreakpointWrapper desktop>
        <div className="o-griddle__row o-griddle__row--no-gutters">
          <aside className="o-griddle__col--3">
            <Outline
              onClick={updateCurrentDocRequest}
              activeRequest={activeDocumentRequest}
              documentRequests={documentRequests}
            />
          </aside>
          <main className="o-griddle__col--6">
            <div className="o-action-items__center-column">
              <div className="o-griddle__col o-griddle__col--auto">
                <Button
                  className="block o-layout--block-center"
                  color="secondary"
                  icon={<ArrowIcon arrowDirection="up" />}
                  disabled={currentDocRequestIndex === 0}
                  iconOnly
                  onClick={showPreviousDocRequest}
                  title="View the previous document"
                />
              </div>

              <DocumentUploadRequestContainer
                documentRequest={activeDocumentRequest}
                toggleTipsForSuccess={toggleTips}
                slaIsSet={slaIsSet}
              />

              <div className="o-layout--block-center o-layout--sticky">
                <Button
                  className="block o-layout--block-center mb-6"
                  color="secondary"
                  disabled={currentDocRequestIndex >= documentRequests.length - 1}
                  icon={<ArrowIcon arrowDirection="down" />}
                  iconOnly
                  onClick={showNextDocRequest}
                  title="View the next document request"
                  style={{ backgroundColor: 'white' }}
                />
              </div>
            </div>
          </main>
          <aside className="o-griddle__col o-griddle__col--3 px-4 pt-8 lg:px-6">
            <div className="ml-3">
              <AdditionalInformation showTips={showTips} stewardEmail={stewardEmail} />
            </div>
          </aside>
        </div>
      </BreakpointWrapper>
    </div>
  )
}

function mapStateToProps(state: any): MappedProps {
  return {
    currentDocRequestIndex: getCurrentDocRequestIndex(state),
    documentRequests: getSortedDocumentRequests(state),
    currentDocRequestIdParam: getCurrentDocumentRequestId(state),
    kaseId: getCurrentKaseId(state),
    stewardEmail: getStewardInfo(state).email,
    slaIsSet: slaIsSet(state)
  }
}

function mapDispatchToActions(dispatch): ActionProps {
  return {
    loadDocumentRequestData: (options: { kaseId: number }) => dispatch(loadDocumentRequestData(options)),
    setDocumentRequestRoute: (newRoute: string, via: NavigationVia) => {
      const action = push(newRoute)
      action.payload.via = via

      dispatch(action)
    },
    goToDocRequestByIndex: (docRequestIndex: number) => {
      return dispatch(goToDocRequestByIndex(docRequestIndex))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToActions)(DocumentUpload) as FunctionComponent
