import server from './server'
import { AxiosResponse } from 'axios'

export interface UserInfo {
  id: number
  name: string
}

async function getStewards(): Promise<UserInfo[]> {
  const url = '/api/admin/stewards'

  return server.get(url).then((response: AxiosResponse<{ stewards: UserInfo[] }>) => response.data.stewards)
}

async function getAdmins(): Promise<UserInfo[]> {
  const url = '/api/admin/admins'

  return server.get(url).then((response: AxiosResponse<{ admins: UserInfo[] }>) => response.data.admins)
}

export default {
  getStewards,
  getAdmins
}
