import React, { ChangeEventHandler, FunctionComponent } from 'react'

interface Props {
  value?: string
  type: string
  label: string
  containerClassName?: string
  disabled?: boolean
  onChangeEvent: ChangeEventHandler
}

const defaultProps = {
  value: ''
}

const UnitTypeInputV2: FunctionComponent<Props> = ({
  value,
  type,
  label,
  containerClassName,
  disabled,
  onChangeEvent
}) => {
  const checked = value === type
  const onClick = () => checked && onChangeEvent(null)
  return (
    <div className={containerClassName}>
      <label className="c-custom-control c-custom-control--radio">
        <input
          name="unit_type"
          type="radio"
          className="c-custom-control__input"
          value={type}
          checked={checked}
          disabled={disabled}
          onChange={onChangeEvent}
          onClick={onClick}
        />

        <span className="c-custom-control__indicator" />
        <span className="c-type c-type--subhead-sm c-custom-control__description">{label}</span>
      </label>
    </div>
  )
}

UnitTypeInputV2.defaultProps = defaultProps

export default UnitTypeInputV2
