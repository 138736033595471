export const INITIALIZE_STORE = 'INITIALIZE_STORE'
export const SET_GLOBAL_ERROR = 'SET_GLOBAL_ERROR'

export const LOAD_REFERRAL_STATE = 'LOAD_REFERRAL_STATE'
export const LOAD_KASE = 'LOAD_KASE'
export const LOAD_CURRENT_USER = 'LOAD_CURRENT_USER'
export const LOAD_LAST_VIEWED_SECTION_AND_PANEL_NAME = 'LOAD_LAST_VIEWED_SECTION_AND_PANEL_NAME'
export const LOAD_RESOURCE_CONTEXT = 'LOAD_RESOURCE_CONTEXT'
export const LOAD_KASE_METADATA = 'LOAD_KASE_METADATA'
export const LOAD_KASE_FORM_METADATA = 'LOAD_KASE_FORM_METADATA'

export const TRANSITION_CURRENT_PANEL_OUT = 'TRANSITION_CURRENT_PANEL_OUT'
export const TRANSITION_NEW_PANEL_IN = 'TRANSITION_NEW_PANEL_IN'
export const TRANSITION_NEW_PANEL_COMPLETE = 'TRANSITION_NEW_PANEL_COMPLETE'
export const SHOW_CHAPTER = 'SHOW_CHAPTER'
export const SAVING_DATA = 'SAVING_DATA'
export const POST_SAVE_TRANSITION_END = 'POST_SAVE_TRANSITION_END'
export const VALIDATING_PATH = 'VALIDATING_PATH'
export const VALIDATED_PATH = 'VALIDATED_PATH'

export const LOAD_DATA = 'LOAD_DATA'
export const SAVED_DATA = 'SAVED_DATA'
export const UPDATE_DATA = 'UPDATE_DATA'
export const TOGGLE_SIDEBAR_HELP = 'TOGGLE_SIDEBAR_HELP'
export const TOGGLE_REVIEW_MODE = 'TOGGLE_REVIEW_MODE'
export const ENABLE_REVIEW_MODE = 'ENABLE_REVIEW_MODE'
export const DISABLE_REVIEW_MODE = 'DISABLE_REVIEW_MODE'
export const SET_ASSETS_EXPIRED = 'SET_ASSETS_EXPIRED'
export const SET_ASSETS_VERSION = 'SET_ASSETS_VERSION'
export const USER_LOGGED_IN = 'USER_LOGGED_IN'
export const UPDATE_WORKFLOW_STATUS = 'UPDATE_WORKFLOW_STATUS'

export const MASTER_PDF_ADD_UNUSED_PAGE = 'MASTER_PDF_ADD_UNUSED_PAGE'
export const MASTER_PDF_DUPLICATE_PAGE = 'MASTER_PDF_DUPLICATE_PAGE'
export const MASTER_PDF_SET_PAGES = 'MASTER_PDF_SET_PAGES'
export const MASTER_PDF_UPDATE = 'MASTER_PDF_UPDATE'
export const MASTER_PDF_UPDATE_PAGES = 'MASTER_PDF_UPDATE_PAGES'

export const PDF_SET_ANNOTATIONS = 'PDF_SET_ANNOTATIONS'
export const PDF_SET_FORM_DATA = 'PDF_SET_FORM_DATA'
export const PDF_SET_FORM_EDITS = 'PDF_SET_FORM_EDITS'
export const PDF_SET_FORM_NAME = 'PDF_SET_FORM_NAME'
export const PDF_CREATE_FORM_EDIT = 'PDF_CREATE_FORM_EDIT'
export const PDF_SET_FORM_EDIT_FIELD_VALUE = 'PDF_SET_FORM_EDIT_FIELD_VALUE'
export const PDF_SELECT_FIELD = 'PDF_SELECT_FIELD'

export const OPEN_MODAL = 'OPEN_MODAL'
export const CLOSE_MODAL = 'CLOSE_MODAL'
export const OPEN_CHAT = 'OPEN_CHAT'
export const UPLOADING_REQUESTED_FILES = 'UPLOADING_REQUESTED_FILES'
export const UPLOAD_REQUESTED_FILE_PROGRESS = 'UPLOAD_REQUESTED_FILE_PROGRESS'
export const UPLOAD_REQUESTED_FILE_FINISHED = 'UPLOAD_REQUESTED_FILE_FINISHED'
export const UPLOAD_ERROR = 'UPLOAD_ERROR'
export const UPLOAD_FINISHED = 'UPLOAD_FINISHED'
export const TOGGLE_EXPAND_DOCUMENT_REQUEST = 'TOGGLE_EXPAND_DOCUMENT_REQUEST'
export const LOADING_DOCUMENT_REQUESTS = 'LOADING_DOCUMENT_REQUESTS'
export const LOADED_DOCUMENT_REQUESTS = 'LOADED_DOCUMENT_REQUESTS'
export const LOADED_DOCUMENT_REVIEW_KASE = 'LOADED_DOCUMENT_REVIEW_KASE'
export const UPDATE_CURRENT_DOC_REQUEST_INDEX = 'UPDATE_CURRENT_DOC_REQUEST_INDEX'

export const ADDED_DOCUMENTS_TO_CURRENT_DOCUMENT_REQUEST = 'ADDED_DOCUMENTS_TO_CURRENT_DOCUMENT_REQUEST'
export const LOADED_DOCUMENT_SETS = 'LOADED_DOCUMENT_SETS'
export const LOADED_NEXT_DOCUMENT_REQUEST = 'LOADED_NEXT_DOCUMENT_REQUEST'
export const LOADED_PREVIOUS_DOCUMENT_REQUEST = 'LOADED_PREVIOUS_DOCUMENT_REQUEST'
export const UPDATED_CURRENT_DOCUMENT_REQUEST = 'UPDATED_CURRENT_DOCUMENT_REQUEST'

export const ADDED_ADMIN_DOCUMENT_REQUEST = 'ADDED_ADMIN_DOCUMENT_REQUEST'
export const LOADING_ADMIN_DOCUMENT_REQUESTS = 'LOADING_ADMIN_DOCUMENT_REQUESTS'
export const LOADED_ADMIN_DOCUMENT_REQUESTS = 'LOADED_ADMIN_DOCUMENT_REQUESTS'
export const LOADING_DOCUMENT_PREVIEW = 'LOADING_DOCUMENT_PREVIEW'
export const LOADED_DOCUMENT_PREVIEW = 'LOADED_DOCUMENT_PREVIEW'
export const REPLACING_DOCUMENT = 'REPLACING_DOCUMENT'
export const REPLACED_DOCUMENT = 'REPLACED_DOCUMENT'

export const UPDATED_DOCUMENT_REVIEW_KASE = 'UPDATED_DOCUMENT_REVIEW_KASE'
export const LOADING_DOCUMENT_REVIEW_KASES = 'LOADING_DOCUMENT_REVIEW_KASES'
export const LOADED_DOCUMENT_REVIEW_KASES = 'LOADED_DOCUMENT_REVIEW_KASES'
export const DOCUMENT_REVIEW_KASES_ERROR = 'DOCUMENT_REVIEW_KASES_ERROR'

export const INITIALIZE_TELEMETRY_SESSION = 'INITIALIZE_TELEMETRY_SESSION'
export const POST_TELEMETRY = 'POST_TELEMETRY'
export const POST_TELEMETRY_WITH_CONVERSION = 'POST_TELEMETRY_WITH_CONVERSION'
export const RESET_TELEMETRY = 'RESET_TELEMETRY'

export const EMAIL_FIELD_ID = 'account-holder-email-email'
export const PUBLIC_CHARGE_ESTIMATOR_RESULTS_PATH = '/public-charge-estimator/results/'

export const ATTORNEY_AGREEMENT_ACCEPTED = 'ATTORNEY_AGREEMENT_ACCEPTED'

/** Issues **/
export const ADDED_MESSAGE = 'ADDED_MESSAGE'
export const LOADED_ISSUES = 'LOADED_ISSUES'
export const LOADING_ISSUES = 'LOADING_ISSUES'
export const SET_CURRENT_ISSUE = 'SET_CURRENT_ISSUE'
export const UPDATE_ISSUE = 'UPDATE_ISSUE'

/** Tasks **/
export const LOADING_TASKS = 'LOADING_TASKS'
export const LOADED_TASKS = 'LOADED_TASKS'
export const UPDATED_TASK = 'UPDATED_TASK'

/** Addresses */
export const LOADING_ADDRESSES = 'LOADING_ADDRESS'
export const LOADED_ADDRESSES = 'LOADED_ADDRESS'
export const LOADED_CURRENT_ADDRESS_HISTORY_EDIT = 'LOADED_CURRENT_ADDRESS_HISTORY_EDIT'

/** Employments */
export const LOADING_EMPLOYMENTS = 'LOADING_EMPLOYMENTS'
export const LOADED_EMPLOYMENTS = 'LOADED_EMPLOYMENTS'
export const UPDATED_EMPLOYMENT = 'UPDATED_EMPLOYMENT'

export const LOADING_PROGRESS = 'LOADING_PROGRESS'
export const LOADED_PROGRESS = 'LOADED_PROGRESS'

/** Questionnaires */
export const LOADING_QUESTIONNAIRE = 'LOADING_QUESTIONNAIRE'
export const LOADING_QUESTIONNAIRE_SUCCESS = 'LOADING_QUESTIONNAIRE_SUCCESS'
export const UPDATING_QUESTIONNAIRE = 'UPDATING_QUESTIONNAIRE'
export const UPDATING_QUESTIONNAIRE_SUCCESS = 'UPDATING_QUESTIONNAIRE_SUCCESS'

/** V2 Questionnaires */
export const LOADING_QUESTIONNAIRE_V2 = 'LOADING_QUESTIONNAIRE_V2'
export const LOADING_QUESTIONNAIRE_V2_SUCCESS = 'LOADING_QUESTIONNAIRE_V2_SUCCESS'
export const UPDATING_QUESTIONNAIRE_V2 = 'UPDATING_QUESTIONNAIRE_V2'
export const UPDATING_QUESTIONNAIRE_SUCCESS_V2 = 'UPDATING_QUESTIONNAIRE_SUCCESS_V2'
export const LOADING_QUESTIONNAIRE_OUTLINE_V2 = 'LOADING_QUESTIONNAIRE_OUTLINE_V2'
export const LOADING_QUESTIONNAIRE_OUTLINE_V2_SUCCESS = 'LOADING_QUESTIONNAIRE_OUTLINE_V2_SUCCESS'

/** V2 Dashboard */
export const LOADING_DASHBOARD = 'LOADING_DASHBOARD'
export const LOADING_DASHBOARD_SUCCESS = 'LOADING_DASHBOARD_SUCCESS'

/** Lawyer Interactions */
export const LOADING_LAWYER_INTERACTIONS = 'LOADING_LAWYER_INTERACTIONS'
export const LOADING_LAWYER_INTERACTIONS_SUCCESS = 'LOADING_LAWYER_INTERACTIONS_SUCCESS'
export const LOADING_LAWYER_INTERACTIONS_ERROR = 'LOADING_LAWYER_INTERACTIONS_ERROR'

/** Post Ship Dashboard */
export const LOADING_POST_SHIP_DASHBOARD = 'LOADING_POST_SHIP_DASHBOARD'
export const LOADING_POST_SHIP_DASHBOARD_SUCCESS = 'LOADING_POST_SHIP_DASHBOARD_SUCCESS'
export const UPDATING_POST_SHIP_DASHBOARD = 'UPDATING_POST_SHIP_DASHBOARD'
export const UPDATING_POST_SHIP_DASHBOARD_SUCCESS = 'UPDATING_POST_SHIP_DASHBOARD_SUCCESS'

/** V2 Payment processing */
export const SET_IS_PAID = 'SET_IS_PAID'

/** V2 Features */
export const LOADING_FEATURES = 'LOADING_FEATURES'
export const LOADED_FEATURES = 'LOADED_FEATURES'

/** V2 Kase Tags **/
export const LOADING_KASE_TAGS = 'LOADING_KASE_TAGS'
export const LOADED_KASE_TAGS_SUCCESS = 'LOADED_KASE_TAGS_SUCCESS'

export const SAVING_STATES = {
  saving: 'saving',
  saved: 'saved',
  transitioning: 'transitioning'
}

/** c-paper-form setup outcomes **/
export const SETUP_OUTCOME_ELIGIBLE = 'setup_eligible'

/** c-paper-form section names **/
export const SECTION_NAME_PUBLIC_CHARGE = 'public_charge'
export const SECTION_NAME_SETUP = 'setup'
export const SECTION_NAME_PETITION = 'petition'
export const SECTION_NAME_REMAINING_INFORMATION = 'remaining_information'
export const SECTION_NAME_PUBLIC_CHARGE_ESTIMATOR = 'public_charge_estimator'

/** c-paper-form UI Field types **/
export const QUALIFIER_TYPE = 'qualifier'
export const COLLECTION_QUALIFIER_TYPE = 'collection_qualifier'
export const SMART_COLLECTION_QUALIFIER_TYPE = 'smart_collection_qualifier'
/*****************/

export const LEAD_CAPTURE_PANEL_SLUGS = ['-email', '-contact-info', '-email-gate']

/** Dashboard constants **/
export const DASHBOARD_PHASE_BUTTON_TYPE_MAIN_CTA = 'MainCTA'
export const DASHBOARD_PHASE_BUTTON_TYPE_STEP_INFO = 'StepInfo'
/*****************/

export const TELEMETRY_EVENTS = Object.freeze({
  VIEWED_PAGE: 'ViewedPage',
  SCROLLED_PAGE: 'ScrolledPage',
  VIEWED_DASHBOARD: 'ViewedDashboard',
  VIEWED_PRE_PAYMENT_DASHBOARD: 'ViewedPrePaymentDashboard',
  VIEWED_PRE_PAYMENT_DASHBOARD_VIDEO: 'ViewedPrePaymentDashboardVideo',
  VIEWED_MODAL: 'ViewedModal',
  VIEWED_TRANSITION: 'ViewedTransition',
  GET_CUSTOMER_TIME_ZONE: 'GetCustomerTimeZone',
  VIEWED_QUESTIONNAIRE_PAGE: 'ViewedQuestionnairePage',
  VIEWED_QUESTION: 'ViewedQuestion',
  SKIPPED_QUESTION: 'SkippedQuestion',
  COMPLETED_QUESTION: 'CompletedQuestion',
  UPDATED_QUESTION: 'UpdatedQuestion',
  FINISHED_SECTION: 'FinishedSection',
  LAUNCHED_DOCUMENTS: 'LaunchedDocuments',
  LAUNCHED_CHAT: 'LaunchedChat',
  CLICKED_TIMELINE_STEP: 'ClickedTimelineStep',
  CLICKED_PHASE_DETAILS: 'ClickedPhaseDetails',
  INITIATED_CHAT: 'InitiatedChat',
  CLOSED_CHAT: 'ClosedChat',
  VIEWED_REGISTRATION: 'ViewedRegistration',
  VIEWED_ATTORNEY_AGREEMENT: 'ViewedAttorneyAgreement',
  ACCEPTED_ATTORNEY_AGREEMENT: 'AcceptedAttorneyAgreement',
  DECLINED_ATTORNEY_AGREEMENT_FOR_REFUND: 'DeclinedAttorneyAgreementForRefund',
  INITIATED_CHECKOUT: 'InitiatedCheckout',
  INITIATED_PAYMENT: 'InitiatedPayment',
  SUCCEEDED_PAYMENT: 'SucceededPayment',
  FAILED_PAYMENT: 'FailedPayment',
  REGISTERED_USER: 'RegisteredUser',
  BECAME_LEAD: 'BecameLead',
  LEAD: 'Lead',
  STARTED_APP: 'StartedApp',
  LOGGED_IN_USER: 'LoggedInUser',
  LOGGED_OUT_USER: 'LoggedOutUser',
  SIGNED_UP_MORE_INFO: 'SignedUpMoreInfo',
  CLICKED_LINK: 'ClickedLink',
  OPENED_CONTEXTUAL_HELP: 'OpenedContextualHelp',
  CLOSED_CONTEXTUAL_HELP: 'ClosedContextualHelp',
  CLICKED_CTA: 'ClickedCTA',
  SUBMITTED_EMAIL: 'SubmittedEmail',
  VIEWED_DOCUMENT_REQUEST: 'ViewedDocumentRequest',
  MIDWAY_PP1: 'Q1Finished',
  VIEWED_POST_SHIP_DASHBOARD: 'ViewedPostShipDashboard',
  CLICKED_POST_SHIP_DASHBOARD_MILESTONE: 'ClickedPostShipDashboardMilestone',
  CLICKED_POST_SHIP_DASHBOARD_CONTEXTUAL_HELP: 'ClickedPostShipDashboardContextualHelp',

  // Note: The below event's signature is being renamed from UploadedDocument
  // to UploadedDocumentNew, as part of https://www.pivotaltracker.com/story/show/174373012
  //
  // As part of this work, we are posting a new server-side event called
  // _UploadedDocument, and updating our existing Heap reports that measure
  // the doc review backlog burndown to use an "OR" alias event,
  // namely either "_UploadedDocument" or "UploadedDocument".
  //
  // In order to prevent double-counting in these reports from the client, we are renaming
  // UploadedDocument to UploadedDocumentNew.
  //
  // While not ideal, it keeps our historical backlog data coherent, while
  // gaining the advantage of measuring a more accurate server-side event
  // that is not prone to ad-blockers (which can prevent client-side telemetry).
  UPLOADED_DOCUMENT: 'UploadedDocumentNew',

  DOCUMENT_DOWNLOADED_BY_ADMIN: 'DocumentDownloadedByAdmin',
  DELETED_DOCUMENT: 'DeletedDocument',
  COMPLETED_DOCUMENT_REQUEST: 'CompletedDocumentRequest',
  EDITED_DOCUMENT_REQUEST: 'EditedDocumentRequest',
  QA_BLURRED_FIELD: 'QaBlurredField',
  QA_DELETED_FROM_COLLECTION: 'QaDeletedFromCollection',
  QA_CONFIRMED_COLLECTION_EMPTY: 'QaConfirmedCollectionEmpty',
  QA_ADDED_TO_COLLECTION: 'QaAddedToCollection',
  QA_VIEWED_SSN: 'QaViewedSsn',
  STARTED_SECTION: 'StartedSection',
  CONTINUED_SECTION: 'ContinuedSection',
  VISITED_SECTION: 'VisitedSection',
  REVIEWED_DOCUMENT: 'ReviewedDocument',
  REVIEWED_DOC_REQUEST: 'ReviewedDocumentRequest',
  RAPID_VISA_REDIRECT: 'RapidVisaRedirect'
})

export const CONVERSION_TRACKING_DESTINATIONS = Object.freeze({
  GOOGLE: 'google',
  FACEBOOK: 'facebook'
})

export const CONVERSION_TRACKING_EVENTS = Object.freeze({
  APP_START: 'APP_START',
  PAYMENT: 'PAYMENT',
  PETITION_COMPLETE: 'PETITION_COMPLETE',
  REGISTRATION: 'REGISTRATION',
  SETUP_COMPLETE: 'SETUP_COMPLETE',
  EMAIL_CAPTURED: 'EMAIL_CAPTURED',
  PHONE_CAPTURED: 'PHONE_CAPTURED'
})

export const GOOGLE_ADWORDS_CONVERSION_TAG_IDS = Object.freeze({
  staging: {
    APP_START: 'AW-855207407/q9DQCLWS2IMBEO_b5ZcD',
    REGISTRATION: 'AW-855207407/q9DQCLWS2IMBEO_b5ZcD',
    PAYMENT: 'AW-855207407/q9DQCLWS2IMBEO_b5ZcD',
    EMAIL_CAPTURED: 'AW-855207407/TFQpCPnvgpMBEO_b5ZcD',
    PHONE_CAPTURED: 'AW-855207407/hb1cCOjegpMBEO_b5ZcD'
  },
  development: {
    APP_START: 'AW-855207407/q9DQCLWS2IMBEO_b5ZcD',
    REGISTRATION: 'AW-855207407/q9DQCLWS2IMBEO_b5ZcD',
    PAYMENT: 'AW-855207407/q9DQCLWS2IMBEO_b5ZcD',
    EMAIL_CAPTURED: 'AW-855207407/TFQpCPnvgpMBEO_b5ZcD',
    PHONE_CAPTURED: 'AW-855207407/hb1cCOjegpMBEO_b5ZcD'
  },
  production: {
    APP_START: 'AW-855207407/79E1CKP0gYMBEO_b5ZcD',
    REGISTRATION: 'AW-855207407/lRApCNjD8YIBEO_b5ZcD',
    PAYMENT: 'AW-855207407/aPETCOjB8YIBEO_b5ZcD',
    EMAIL_CAPTURED: 'AW-855207407/TFQpCPnvgpMBEO_b5ZcD',
    PHONE_CAPTURED: 'AW-855207407/hb1cCOjegpMBEO_b5ZcD'
  }
})

export const VWO_CONVERSION_GOAL_CONFIGS = Object.freeze({
  REGISTRATION: {
    goalId: 200,
    method: '_vis_opt_goal_conversion'
  },
  APP_START: {
    goalId: 201,
    method: '_vis_opt_goal_conversion'
  },
  SETUP_COMPLETE: {
    goalId: 202,
    method: '_vis_opt_goal_conversion'
  },
  PETITION_COMPLETE: {
    goalId: 203,
    method: '_vis_opt_goal_conversion'
  },
  EMAIL_CAPTURED: {
    goalId: 204,
    method: '_vis_opt_goal_conversion'
  },
  PHONE_CAPTURED: [
    {
      // goal tracking conversion from www
      goalId: 205,
      method: '_vis_opt_goal_conversion'
    }
  ],
  PAYMENT: {
    method: '_vis_opt_revenue_conversion'
  }
})

export const MBGC_GOVT_FILING_FEES = Object.freeze({
  BENEFICIARY_IN_US: 176000,
  BENEFICIARY_ABROAD: 120000
})

export const MBGC_TIMELINES = Object.freeze({
  SPONSOR_USC_BENEFICIARY_ABROAD: {
    minimumMonths: 17,
    maximumMonths: 24
  },
  SPONSOR_USC_BENEFICIARY_IN_US: {
    minimumMonths: 14,
    maximumMonths: 28
  },
  SPONSOR_LPR_BENEFICIARY_ABROAD: {
    minimumMonths: 15,
    maximumMonths: 23
  },
  SPONSOR_LPR_BENEFICIARY_IN_US: {
    minimumMonths: 22,
    maximumMonths: 36
  }
})

// TODO: Fix this after reviewing resource articles
export const MBGC_NUM_DAYS_BEFORE_EARLIEST_POSSIBLE_TRAVEL = Object.freeze({
  SPONSOR_USC: 99,
  SPONSOR_LPR: 99
})

// to prevent the database from exploding with a huge number of members
export const COLLECTION_SIZE_LIMIT = 50

// Used for V2 documents
export enum DocumentState {
  Approved = 'approved',
  Dismissed = 'dismissed',
  NeedsReview = 'needs_review',
  Rejected = 'rejected'
}

export enum DocumentStatus {
  NeedsReview = 'pending',
  NeedsCustomerAction = 'rejected',
  Dismissed = 'dismissed',
  Accepted = 'accepted'
}

export enum DocumentRequestStatus {
  NeedsReview = 'pending',
  NeedsCustomerAction = 'needs_more_documents',
  Dismissed = 'dismissed',
  Accepted = 'accepted'
}

/** Customer facing statuses for document upload tool */
export enum DocumentRequestCustomerStatus {
  SeeComment = 'see_comment',
  ToDo = 'to_do',
  SentToBoundless = 'sent_to_boundless',
  Accepted = 'accepted',
  DoneUploading = 'done_uploading'
}

// Customer States for Content in document upload tool
export enum DocumentUploadSummaryStates {
  UploadingNotStarted = 'uploading_not_started',
  ThresholdNotReached = 'threshold_not_reached',
  ThresholdReachedNoComments = 'threshold_reached_no_comments',
  CommentsExist = 'comments_exist',
  AllSectionsCustomerCompleted = 'all_sections_customer_completed',
  AllSectionsAccepted = 'all_sections_accepted'
}

export enum AutoSubmitModalType {
  FirstReview = 'first_review',
  StandardReview = 'standard_review'
}

// Dashboard
export enum JOB_TITLE_OPTIONS {
  JOB_TITLE_LAWYER = 'Independent Immigration Attorney',
  JOB_TITLE_STEWARD = 'Application Guide',
  JOB_TITLE_CUSTOMER_SUPPORT = 'Customer Support'
}

export enum KaseKind {
  MarriageBasedGreenCard = 'MarriageBasedGreenCard',
  Natz = 'Naturalization',
  NewMarriageBasedGreenCard = 'NewMarriageBasedGreenCard',
  K1FianceVisa = 'K1FianceVisa'
}

export enum WORKFLOW_STATUSES {
  // AOS Mapping
  AOS_PAUSE = 'aos_pause',
  AOS_PAID = 'aos_paid',
  AOS_INITIAL_LAWYER_REVIEW = 'aos_initial_lawyer_review',
  AOS_INITIAL_DOC_REQUEST = 'aos_initial_doc_request',
  AOS_DOC_GATHERING = 'aos_doc_gathering',
  AOS_PUBLIC_CHARGE_QUEUE = 'aos_public_charge_queue',
  AOS_READY_FOR_QA = 'aos_ready_for_qa',
  AOS_QA = 'aos_qa',
  AOS_OPEN_QUESTIONS_READY_TO_SEND = 'aos_open_questions_ready_to_send',
  AOS_WAITING_FOR_RESPONSE_TO_OPEN_QUESTIONS = 'aos_waiting_for_response_to_open_questions',
  AOS_FINALIZING_OPEN_QUESTIONS = 'aos_finalizing_open_questions',
  AOS_BOUNDLESS_LEGAL_REVIEW = 'aos_boundless_legal_review',
  AOS_MASTER_PDF_INITIAL_EDITS_POST_LAWYER_REVIEW = 'aos_master_pdf_initial_edits_post_lawyer_review',
  AOS_INITIAL_LR_QUESTIONS_READY_TO_SEND = 'aos_initial_lr_questions_ready_to_send',
  AOS_MASTER_PDF_WAITING_FOR_CUSTOMER_INFO_DOCS_POST_LAWYER_REVIEW = 'aos_master_pdf_waiting_for_customer_info_docs_post_lawyer_review',
  AOS_MASTER_PDF_FINAL_EDITS_POST_LAWYER_REVIEW = 'aos_master_pdf_final_edits_post_lawyer_review',
  AOS_MASTER_PDF_ARC_NEEDED = 'aos_master_pdf_arc_needed',
  AOS_MASTER_PDF_ARC_COMPLETED = 'aos_master_pdf_arc_completed',
  AOS_READY_FOR_CUSTOMER_REVIEW = 'aos_ready_for_customer_review',
  AOS_CUSTOMER_REVIEW = 'aos_customer_review',
  AOS_MASTER_PDF_INITIAL_EDITS_POST_CUSTOMER_REVIEW = 'aos_master_pdf_initial_edits_post_customer_review',
  AOS_INITIAL_CR_QUESTIONS_READY_TO_SEND = 'aos_initial_cr_questions_ready_to_send',
  AOS_MASTER_PDF_WAITING_FOR_CUSTOMER_INFO_DOCS_POST_CUSTOMER_REVIEW = 'aos_master_pdf_waiting_for_customer_info_docs_post_customer_review',
  AOS_MASTER_PDF_FINAL_EDITS = 'aos_master_pdf_final_edits',
  AOS_READY_FOR_PRINTSHOP = 'aos_ready_for_printshop',
  AOS_IN_PRINTSHOP = 'aos_in_printshop',
  AOS_READY_TO_SHIP = 'aos_ready_to_ship',
  AOS_PACKAGE_SHIPPED = 'aos_package_shipped',
  AOS_REFUNDING = 'aos_refunding',
  AOS_REFUNDED = 'aos_refunded',
  POST_SHIPMENT_PACKAGE_DELIVERED_TO_CUSTOMER = 'post_shipment_package_delivered_to_customer',
  POST_SHIPMENT_PACKAGE_DELIVERED_TO_USCIS = 'post_shipment_package_delivered_to_uscis',
  POST_SHIPMENT_WAITING = 'post_shipment_waiting',
  POST_SHIPMENT_PROCESSING_RFE = 'post_shipment_processing_rfe',
  POST_SHIPMENT_PROCESSING_SPECIAL_REQUEST = 'post_shipment_processing_special_request',
  POST_SHIPMENT_INTERVIEW_NOTICE_RECEIVED = 'post_shipment_interview_notice_received',
  POST_SHIPMENT_INTERVIEW_COMPLETE = 'post_shipment_interview_complete',
  POST_SHIPMENT_APPROVED = 'post_shipment_approved',
  POST_SHIPMENT_RETIRED = 'post_shipment_retired',
  POST_SHIPMENT_READY_FOR_QA = 'post_shipment_ready_for_qa',
  POST_SHIPMENT_IN_PRINTSHOP = 'post_shipment_in_printshop',
  POST_SHIPMENT_DENIED = 'post_shipment_denied',

  // CP Mapping
  CP_PAUSE = 'cp_pause',
  CP_PAID = 'cp_paid',
  CP_INITIAL_LAWYER_REVIEW = 'cp_initial_lawyer_review',
  CP_INITIAL_DOC_REQUEST = 'cp_initial_doc_request',
  CP_DOC_GATHERING = 'cp_doc_gathering',
  CP_READY_FOR_QA = 'cp_ready_for_qa',
  CP_QA = 'cp_qa',
  CP_OPEN_QUESTIONS_READY_TO_SEND = 'cp_open_questions_ready_to_send',
  CP_WAITING_FOR_RESPONSE_TO_OPEN_QUESTIONS = 'cp_waiting_for_response_to_open_questions',
  CP_FINALIZING_OPEN_QUESTIONS = 'cp_finalizing_open_questions',
  CP_BOUNDLESS_LEGAL_REVIEW = 'cp_boundless_legal_review',
  CP_MASTER_PDF_INITIAL_EDITS_POST_LAWYER_REVIEW = 'cp_master_pdf_initial_edits_post_lawyer_review',
  CP_INITIAL_LR_QUESTIONS_READY_TO_SEND = 'cp_initial_lr_questions_ready_to_send',
  CP_MASTER_PDF_WAITING_FOR_CUSTOMER_INFO_DOCS_POST_LAWYER_REVIEW = 'cp_master_pdf_waiting_for_customer_info_docs_post_lawyer_review',
  CP_MASTER_PDF_FINAL_EDITS_POST_LAWYER_REVIEW = 'cp_master_pdf_final_edits_post_lawyer_review',
  CP_MASTER_PDF_ARC_NEEDED = 'cp_master_pdf_arc_needed',
  CP_MASTER_PDF_ARC_COMPLETED = 'cp_master_pdf_arc_completed',
  CP_READY_FOR_CUSTOMER_REVIEW = 'cp_ready_for_customer_review',
  CP_CUSTOMER_REVIEW = 'cp_customer_review',
  CP_MASTER_PDF_INITIAL_EDITS_POST_CUSTOMER_REVIEW = 'cp_master_pdf_initial_edits_post_customer_review',
  CP_INITIAL_QUESTIONS_READY_TO_SEND = 'cp_initial_questions_ready_to_send',
  CP_MASTER_PDF_WAITING_FOR_CUSTOMER_INFO_DOCS_POST_CUSTOMER_REVIEW = 'cp_master_pdf_waiting_for_customer_info_docs_post_customer_review',
  CP_MASTER_PDF_FINAL_EDITS = 'cp_master_pdf_final_edits',
  CP_READY_FOR_PRINTSHOP = 'cp_ready_for_printshop',
  CP_IN_PRINTSHOP = 'cp_in_printshop',
  CP_READY_TO_SHIP = 'cp_ready_to_ship',
  CP_PACKAGE_SHIPPED = 'cp_package_shipped',
  CP_PACKAGE_DELIVERED_TO_CUSTOMER = 'cp_package_delivered_to_customer',
  CP_PACKAGE_DELIVERED_TO_USCIS = 'cp_package_delivered_to_uscis',
  CP_2_PAUSED = 'cp_2_paused',
  CP_I130_APPROVAL_NOTICE_RECEIVED = 'cp_i130_approval_notice_received',
  CP_NVC_WELCOME_LETTER_RECEIVED = 'cp_nvc_welcome_letter_received',
  CP_IV_DOCUMENT_GATHERING = 'cp_iv_document_gathering',
  CP_IV_READY_FOR_QA = 'cp_iv_ready_for_qa',
  CP_IV_QA = 'cp_iv_qa',
  CP_IV_OPEN_QUESTIONS_READY_TO_SEND = 'cp_iv_open_questions_ready_to_send',
  CP_IV_WAITING_FOR_RESPONSE_TO_OPEN_QUESTIONS = 'cp_iv_waiting_for_response_to_open_questions',
  CP_IV_FINALIZING_OPEN_QUESTIONS = 'cp_iv_finalizing_open_questions',
  CP_IV_LAWYER_REVIEW = 'cp_iv_lawyer_review',
  CP_IV_INITIAL_EDITS_POST_LAWYER_REVIEW = 'cp_iv_initial_edits_post_lawyer_review',
  CP_IV_WAITING_FOR_CUSTOMER_INFO_DOCS_POST_LAWYER_REVIEW = 'cp_iv_waiting_for_customer_info_docs_post_lawyer_review',
  CP_IV_FINAL_EDITS_POST_LAWYER_REVIEW = 'cp_iv_final_edits_post_lawyer_review',
  CP_IV_READY_FOR_CUSTOMER_REVIEW = 'cp_iv_ready_for_customer_review',
  CP_IV_CUSTOMER_REVIEW = 'cp_iv_customer_review',
  CP_IV_INITIAL_EDITS_POST_CUSTOMER_REVIEW = 'cp_iv_initial_edits_post_customer_review',
  CP_IV_WAITING_FOR_CUSTOMER_INFO_DOCS_POST_CUSTOMER_REVIEW = 'cp_iv_waiting_for_customer_info_docs_post_customer_review',
  CP_IV_FINAL_EDITS_POST_CUSTOMER_REVIEW = 'cp_iv_final_edits_post_customer_review',
  CP_IV_READY_FOR_NVC_ONLINE_UPLOAD_PRINTSHOP = 'cp_iv_ready_for_nvc_online_upload_printshop',
  CP_IV_IN_PRINTSHOP = 'cp_iv_in_printshop',
  CP_IV_APPLICATION_SENT_TO_CUSTOMER = 'cp_iv_application_sent_to_customer',
  CP_IV_PENDING_DOC_APPROVAL = 'cp_iv_pending_doc_approval',
  CP_IV_DOC_REJECTION = 'cp_iv_doc_rejection',
  CP_IV_APPLICATION_ACCEPTED_FOR_REVIEW_OR_WAITING = 'cp_iv_application_accepted_for_review_or_waiting',
  CP_INTERVIEW_NOTICE_RECEIVED = 'cp_interview_notice_received',
  CP_INTERVIEW_COMPLETE = 'cp_interview_complete',
  CP_APPROVED = 'cp_approved',
  CP_CP_REFUNDED = 'cp_cp_refunded',
  CP_CP_RETIRED = 'cp_cp_retired',
  CP_PROCESSING_RFE = 'cp_processing_rfe',
  CP_PROCESSING_SPECIAL_REQUEST = 'cp_processing_special_request',
  CP_REFUNDING = 'cp_refunding',
  CP_REFUNDED = 'cp_refunded',
  CP_RETIRED = 'cp_retired',

  // Dev Mappings
  POST_SHIPMENT_PACKAGE_SHIPPED = 'post_shipment_package_shipped',
  POST_SHIPMENT_PACKAGE_SHIPPED_2 = 'post_shipment_package_shipped_2',
  CP_I130_INITIAL_DOC_REQUEST = 'cp_i130_initial_doc_request',
  CP_I130_DOC_GATHERING = 'cp_i130_doc_gathering',
  CP_NEW_LIST_FOR_TESTS = 'cp_new_list_for_tests',

  // NATZ Mappings
  NATZ_REFUNDING = 'natz_refunding',
  NATZ_DOC_GATHERING = 'natz_document_gathering',

  // Post Shipment Mappings
  POST_SHIPMENT_REFUNDING = 'post_shipment_refunding',

  // Category-based filters
  CP_ALL = 'cp_all',
  AOS_ALL = 'aos_all',
  NATZ_ALL = 'natz_all'
}

export enum WorkflowCategory {
  AOS = 'AOS',
  CP = 'CP',
  NATZ = 'NATZ'
}

export const Roles = {
  TemporaryWorker: 'temporary_worker',
  Dra: 'dra'
}

export const TaskTypes = {
  QA: 'QA',
  CUSTOMER_QA: 'CustomerQA'
}
