import api from 'lib/api'
import {
  LOADED_DOCUMENT_SETS,
  LOADED_NEXT_DOCUMENT_REQUEST,
  LOADED_PREVIOUS_DOCUMENT_REQUEST,
  UPDATED_CURRENT_DOCUMENT_REQUEST
} from 'lib/constants'
import { UploadResult } from 'lib/upload_queue'

export const addUploadsToCurrentDocRequest = ({
  kaseId,
  docRequestId,
  docRequestPartId,
  uploadResults
}: {
  kaseId: number
  docRequestId: number
  docRequestPartId?: number
  uploadResults: UploadResult[]
}) => () => {
  const documentAttributes = uploadResults.map(({ signResult, rawFile }) => {
    return {
      document_request_part_id: docRequestPartId,
      file_size: rawFile.size,
      file_type: signResult.contentType,
      file_name: signResult.originalFilename,
      s3_path: signResult.filename
    }
  })

  return api.documentRequestsV2
    .addDocumentsToDocRequest(kaseId, docRequestId, documentAttributes)
    .then((response) => response)
}

export const fetchDocumentSets = (kase_id: number) => (dispatch) => {
  return api.documentRequestsV2.fetchDocumentSets(kase_id).then((response) => {
    const documentSets = response.document_sets
    const complete = response.complete

    dispatch({
      type: LOADED_DOCUMENT_SETS,
      documentSets,
      complete
    })

    return documentSets
  })
}

export const updateCurrentDocumentRequest = (docSetIndex: number, docRequestIndex: number) => (dispatch) => {
  dispatch({
    type: UPDATED_CURRENT_DOCUMENT_REQUEST,
    docSetIndex,
    docRequestIndex
  })
}

export const updateCurrentDocumentRequestFromId = (docRequestId: number) => (dispatch) => {
  dispatch({
    type: UPDATED_CURRENT_DOCUMENT_REQUEST,
    docRequestId
  })
}

export const getNextDocumentRequest = () => (dispatch) => {
  dispatch({
    type: LOADED_NEXT_DOCUMENT_REQUEST
  })
}

export const getPreviousDocumentRequest = () => (dispatch) => {
  dispatch({
    type: LOADED_PREVIOUS_DOCUMENT_REQUEST
  })
}

export const deleteDocument = (kase_id: number, doc_Id: number) => () => {
  return api.documentRequestsV2.deleteDocument(kase_id, doc_Id).then((response) => response)
}

export const setDocumentSetsComplete = (kase_id: number) => () => {
  return api.documentRequestsV2.setDocumentSetsComplete(kase_id).then((response) => response)
}

export const addDocumentMetaAnswer = (
  kase_id: number,
  document_id: number,
  document_meta_question_id: number,
  text: string
) => () => {
  return api.documentRequestsV2
    .addDocumentMetaAnswer(kase_id, document_id, document_meta_question_id, text)
    .then((response) => response)
}

export const updateDocumentMetaAnswer = (kase_id: number, document_meta_answer_id: number, text: string) => () => {
  return api.documentRequestsV2
    .updateDocumentMetaAnswer(kase_id, document_meta_answer_id, text)
    .then((response) => response)
}
