import React, { FunctionComponent, useEffect, useState } from 'react'

import { PopupModal, useCalendlyEventListener } from 'react-calendly'
import Button from '../../../../../button'
import { LawyerInteractionStatusData } from './types'

export interface Props {
  fetchLawyerInteractions: () => void
  statusData: LawyerInteractionStatusData
}

export const StatusSection: FunctionComponent<Props> = ({ fetchLawyerInteractions, statusData }) => {
  const [isCalendlyOpen, setIsCalendlyOpen] = useState(false)
  const [isClientSideOnlySchedulingStateActive, setIsClientSideOnlySchedulingStateActive] = React.useState(false)

  /**
   * This is the state that the UI shows while the meeting is being scheduled
   * but the status hasn't propagated to our API yet.
   */
  const PRE_TRANSITION_STATE = 'Ready to book'
  const RECURSION_INTERVAL_MS = 3000
  const MAX_RECURSION_ATTEMPTS = 5 // will keep pinging for a max of 15 seconds
  let recursionAttempts = 0

  /**
   * This function is called recursively after a meeting is scheduled until the status is no longer
   * 'not_scheduled' (statusText !== PRE_TRANSITION_STATE) so the UI can update to show the scheduled meeting.
   */
  const checkForUpdatedLawyerInteractions = () => {
    fetchLawyerInteractions()
    setTimeout(() => {
      recursionAttempts = recursionAttempts + 1

      if (
        isClientSideOnlySchedulingStateActive &&
        statusData.statusText === PRE_TRANSITION_STATE &&
        recursionAttempts < MAX_RECURSION_ATTEMPTS
      ) {
        checkForUpdatedLawyerInteractions()
      }
    }, RECURSION_INTERVAL_MS)
  }

  /**
   * This closes the transition state after the meeting is scheduled and ends the recursive function above.
   */
  useEffect(() => {
    if (isClientSideOnlySchedulingStateActive && statusData?.statusText !== PRE_TRANSITION_STATE) {
      setIsClientSideOnlySchedulingStateActive(false)
    }
  }, [statusData])

  /**
   * Calendly updates take a few seconds to propagate to the API, so we show a client-side only
   * transition state to the user while we wait for the API to update and then periodically check
   * for updates until the API is updated.
   */
  const enterClientSideTransitionState = () => {
    setIsClientSideOnlySchedulingStateActive(true)
  }

  useEffect(() => {
    if (isClientSideOnlySchedulingStateActive) checkForUpdatedLawyerInteractions()
  }, [isClientSideOnlySchedulingStateActive])

  useCalendlyEventListener({
    onEventScheduled: () => {
      // check that this is the correct event to prevent
      // all sections from switching to the transition state
      if (statusData.statusText === PRE_TRANSITION_STATE) {
        enterClientSideTransitionState()
      }
    }
  })

  if (!statusData) {
    return null
  }

  if (isClientSideOnlySchedulingStateActive) {
    return (
      <li className="c-list__item">
        <div className="c-type c-type--subhead-md c-type--emphasized">{statusData.callType}</div>
        <div className="c-type c-type--body-sans-sm c-type--success mt-2 c-type--bold">
          Scheduling: this may take a few minutes
        </div>
      </li>
    )
  }

  return (
    <li className="c-list__item">
      <div className="c-type c-type--subhead-md c-type--emphasized">{statusData.callType}</div>
      <div className={`c-type c-type--body-sans-sm c-type--${statusData.statusColor || 'success'} mt-2 c-type--bold`}>
        {statusData.statusText}
      </div>
      {statusData.meetingDetails && <div className="c-type c-type--body-sans-sm mt-4">{statusData.meetingDetails}</div>}
      {statusData.description && <div className="c-type c-type--body-sans-sm mt-4">{statusData.description}</div>}
      {statusData.calendlyScheduleUrl && (
        <>
          <Button
            className="c-btn--block mt-4"
            color={statusData.buttonStyle || 'secondary'}
            label={statusData.buttonText || 'Schedule call'}
            onClick={() => setIsCalendlyOpen(true)}
          />
          <PopupModal
            url={statusData.calendlyScheduleUrl}
            rootElement={document.getElementById('react-root')}
            onModalClose={() => setIsCalendlyOpen(false)}
            open={isCalendlyOpen}
          />
        </>
      )}
      {statusData.googleMeetUrl && (
        <Button
          className="c-btn--block mt-4"
          color={statusData.buttonStyle || 'secondary'}
          label={statusData.buttonText || 'Join call'}
          onClick={() => window.open(statusData.googleMeetUrl, '_blank')}
        />
      )}
    </li>
  )
}
