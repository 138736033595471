import React, { SyntheticEvent } from 'react'
import classNames from 'classnames'

import _find from 'lodash/find'
import _includes from 'lodash/includes'
import _reduce from 'lodash/reduce'

import { getMatches } from './config'
import { InputProps } from 'components/forms/inputs'

type BaseProps = Omit<InputProps, 'afterChangeEvents'>

interface TypeaheadValuesList {
  [Code: string]: string
}

interface Props extends BaseProps {
  hiddenCodes: string[]
  optionsPinnedToTop: string[]
  required: boolean
  typeaheadValuesList: TypeaheadValuesList
  value: Nullable<string>
  name: Nullable<string>
}

interface State {
  matches: string[]
  selectedIndex: number
  targets: any
}

const RETURN_KEY = 13
const UP_ARROW = 38
const DOWN_ARROW = 40

export default class TypeaheadInput extends React.Component<Props, State> {
  static defaultProps = {
    className: '',
    disabled: false,
    fullList: {},
    hiddenCodes: [],
    onBlur: () => {},
    path: '',
    placeholder: '',
    required: false,
    value: ''
  }

  inputRef: Nullable<HTMLInputElement> = null
  timeout = null

  constructor(props: Props) {
    super(props)

    const targets = _reduce(
      props.typeaheadValuesList,
      (result, name, code) => {
        result.push({ code, name })
        return result
      },
      []
    )

    this.state = {
      matches: [],
      selectedIndex: 0,
      targets
    }
  }

  componentWillUnmount() {
    this.timeout && clearTimeout(this.timeout)
  }

  currentSelection() {
    return this.state.matches[this.state.selectedIndex]
  }

  fireChange = (value: Nullable<string>, name: Nullable<string>) => {
    const event = { currentTarget: { value, name } }
    this.props.onChange(event)
  }

  fireValidation = (evt) => {
    this.props.onBlur(evt)
  }

  focus() {
    this.inputRef && this.inputRef.focus()
  }

  getExactMatch(matches: string[], searchValue: string) {
    return _find(matches, (code) => this.props.typeaheadValuesList[code].toLowerCase() === searchValue.toLowerCase())
  }

  getMatches(text: string) {
    if (!text) return []
    const matchReqs = {
      pinnedOptions: this.props.optionsPinnedToTop,
      targets: this.state.targets,
      text
    }
    const resultCodes = getMatches(matchReqs)
    return resultCodes.filter((code) => !_includes(this.props.hiddenCodes, code))
  }

  getSelectionIndexForKeypress(keyCode: number): number {
    switch (keyCode) {
      case UP_ARROW: {
        return Math.max(0, this.state.selectedIndex - 1)
      }
      case DOWN_ARROW: {
        return Math.min(this.state.matches.length - 1, this.state.selectedIndex + 1)
      }
      case RETURN_KEY: {
        return this.state.selectedIndex
      }
      default: {
        return 0
      }
    }
  }

  handleTextChange = (event: SyntheticEvent<HTMLInputElement>) => {
    const { value, name } = event.currentTarget
    let matches = this.getMatches(value.trim())
    const exactMatchCode = this.getExactMatch(matches, value.trim())

    if (exactMatchCode) {
      matches = []
    }

    this.setState({ matches, selectedIndex: 0 }, () => {
      this.fireChange(value, name)
    })
  }

  isTypeaheadOpen() {
    return this.state.matches.length > 0
  }

  handleBlur = (evt) => {
    if (this.isTypeaheadOpen()) return

    this.fireValidation(evt)
  }

  handleClick = (event: SyntheticEvent<HTMLLIElement>, name) => {
    const codeValue = event.currentTarget.getAttribute('value')
    if (!codeValue) return

    const selecteVal = this.props.typeaheadValuesList[codeValue]

    this.focus()

    this.setState({
      matches: [],
      selectedIndex: 0
    })

    this.fireChange(selecteVal, name)
    this.fireValidation()
  }

  handleKeyDown = (event: SyntheticEvent<HTMLInputElement>) => {
    if (!this.isTypeaheadOpen()) return

    const newIndex = this.getSelectionIndexForKeypress(event.keyCode)

    this.setState({ selectedIndex: newIndex })
  }

  handleKeyPress = (event: SyntheticEvent<HTMLInputElement>) => {
    if (event.charCode === RETURN_KEY) {
      event.stopPropagation()

      const currentCode = this.currentSelection()

      if (currentCode) {
        this.setState(
          {
            matches: [],
            selectedIndex: 0
          },
          () => {
            const val = this.props.typeaheadValuesList[currentCode]
            const name = event.currentTarget.name
            this.fireChange(val, name)
          }
        )
      }
    }
  }

  // TODO: after render, scroll so visible
  renderMatches() {
    if (this.state.matches.length === 0) return

    return (
      <div className="o-flag__item o-flag__item--overlay o-flag__item--bottom">
        <ul className="c-list c-list--boxed c-list--scrollable c-list--hoverable">
          {this.state.matches.map((k, i) => {
            return (
              <li
                key={k}
                value={k}
                className={classNames('c-list__item', {
                  selected: i === this.state.selectedIndex
                })}
                onClick={(evt) => this.handleClick(evt, this.props.name)}
              >
                <div className="c-type c-type--body-sans-sm">{this.props.typeaheadValuesList[k]}</div>
              </li>
            )
          })}
        </ul>
      </div>
    )
  }

  render() {
    const { className, disabled, name, path, id, placeholder, value } = this.props

    return (
      <React.Fragment>
        <input
          autoComplete="off"
          className={`js-typeahead-input ${className}`}
          data-model-path={path}
          disabled={disabled}
          id={id}
          name={name}
          onBlur={this.handleBlur}
          onChange={this.handleTextChange}
          onKeyDown={this.handleKeyDown}
          onKeyPress={this.handleKeyPress}
          placeholder={placeholder}
          ref={(ref) => (this.inputRef = ref)}
          tabIndex={0}
          type="text"
          value={value}
        />

        {this.renderMatches()}
      </React.Fragment>
    )
  }
}
