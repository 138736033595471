import React, { ButtonHTMLAttributes } from 'react'

import cx from 'classnames'

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  block?: boolean
  color?:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'transparent'
    | 'emphasized'
    | 'inverse'
    | 'flat'
    | 'bare'
    | 'link'
    | 'link-primary'
    | 'link-success'
    | 'link-danger'
  icon?: React.ReactNode
  iconOnly?: boolean
  isLoading?: boolean
  label?: React.ReactNode
  mobileLabel?: React.ReactNode
  size?: 'default' | 'small' | 'tiny'
}

/**
 * It's a button!
 * @param {boolean} [false] block - If button is display block
 * @param {string} color - 'primary'| 'secondary'| 'tertiary'| 'transparent'| 'emphasized'| 'inverse'| 'flat'| 'bare'| 'link'| 'link-primary'| 'link-success'| 'link-danger'
 * @param {ReactNode} icon - The icon component to display
 * @param {boolean} isLoading - Displays loading icon instead of icon if true
 * @param {ReactNode} label - Button Text
 * @param {ReactNode} mobileLabel - Button Text for mobile if different
 * @param {string} size - 'default' | 'small' | 'tiny'
 */
export default class Button extends React.Component<ButtonProps> {
  static defaultProps = {
    block: false,
    disabled: false,
    iconOnly: false,
    isLoading: false,
    size: 'default',
    type: 'button'
  }

  classes() {
    const { block, className, color, iconOnly, size } = this.props

    return cx('c-btn', {
      [className]: !!className,
      'c-btn--block': block,
      'c-btn--small': size === 'small',
      'c-btn--tiny': size === 'tiny',
      'c-btn--primary': color === 'primary',
      'c-btn--secondary': color === 'secondary',
      'c-btn--tertiary': color === 'tertiary',
      'c-btn--transparent': color === 'transparent',
      'c-btn--emphasized': color === 'emphasized',
      'c-btn--inverse': color === 'inverse',
      'c-btn--flat': color === 'flat',
      'c-btn--bare': color === 'bare',
      'c-btn--icon-only': iconOnly,
      'c-btn__link': color === 'link',
      'c-btn__link c-btn__link--success': color === 'link-success',
      'c-btn__link c-btn__link--danger': color === 'link-danger',
      'c-btn__link c-btn__link--primary': color === 'link-primary'
    })
  }

  icon() {
    const { color, isLoading, icon } = this.props

    // if the button is color='secondary' then the spinner needs to be
    // the primary brand color instead of white
    const spinnerColor = color === 'secondary' ? 'secondary-spinner' : ''
    if (isLoading) {
      return (
        <div className={`c-icon spinner ${spinnerColor}`} data-testid="continue-button-spinner">
          <div className="spinner__circle" />
        </div>
      )
    }

    return icon
  }

  getLabel() {
    const { mobileLabel, label, iconOnly } = this.props
    if (iconOnly) {
      return null
    }

    if (mobileLabel) {
      return (
        <>
          <span className="u-hide@md-up">{mobileLabel}</span>
          <span className="u-hide@sm-down">{label}</span>
        </>
      )
    }

    return label
  }

  render() {
    const {
      block /* eslint-disable-line @typescript-eslint/no-unused-vars */,
      isLoading /* eslint-disable-line @typescript-eslint/no-unused-vars */,
      color /* eslint-disable-line @typescript-eslint/no-unused-vars */,
      icon,
      iconOnly /* eslint-disable-line @typescript-eslint/no-unused-vars */,
      label /* eslint-disable-line @typescript-eslint/no-unused-vars */,
      mobileLabel /* eslint-disable-line @typescript-eslint/no-unused-vars */,
      ...otherProps
    } = this.props

    return (
      <button {...otherProps} className={this.classes()}>
        {this.getLabel()}
        {icon && <span className="c-btn__icon">{this.icon()}</span>}
      </button>
    )
  }
}
