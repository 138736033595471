import React, { FunctionComponent, useState, useEffect } from 'react'

import HelpIcon from 'components/icons/help_icon'
import Heading from 'components/type/heading'
import Paragraph from 'components/type/paragraph'
import HelpSectionItem from 'components/screens/shared/help_section_item'

interface Props {
  stewardEmail: string
}

const HelpSection: FunctionComponent<Props> = ({ stewardEmail }) => {
  const [tipsOpen, setTipsOpen] = useState(false)

  useEffect(() => {
    setTipsOpen(tipsOpen)
  }, [tipsOpen])

  // Keep track of the open/closed status of the <details>
  // It's a little weird but hey https://github.com/facebook/react/issues/15486
  const onToggle = (event) => {
    event.preventDefault()
    setTipsOpen(!tipsOpen)
  }

  return (
    <>
      <a href="/timeline" className="o-block c-btn c-btn__block c-btn--secondary">
        Return to Dashboard
      </a>
      <ul className="tool-menu-sublist tool-menu-sublist--no-bottom o-box">
        <li className="tool-menu-item block">
          <a id="tips-for-success-anchor"></a>
          <HelpSectionItem
            isOpen={tipsOpen}
            onToggle={onToggle}
            headerContent={
              <>
                <HelpIcon className="mr-2" />
                Have any questions?
              </>
            }
            helpContent={
              <>
                {' '}
                <Heading tag="h4" size="sm" color="emphasized">
                  Ask us anything
                </Heading>
                <Paragraph size="sm">
                  If you have any questions that are not related to the questions or tasks here, send an email to your
                  Application Guide at <a href={`mailto:${stewardEmail}`}> {stewardEmail}</a>
                </Paragraph>
              </>
            }
          />
        </li>
      </ul>
    </>
  )
}

export default HelpSection
