import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'
import { externalUrl } from 'lib/settings'
import { openChat } from 'actions/chat_actions'
import { KasePhase } from './types'

import Accordion from 'components/accordion'
import Button from 'components/button'
import ExternalLink from 'components/external_link'
import Markdown from 'components/markdown'
import Paragraph from 'components/type/paragraph'
import ResourcesIcon from 'components/icons/resources_icon'
import SkeletonHeading from 'components/skeleton/heading'
import SkeletonParagraph from 'components/skeleton/paragraph'
import ChatImg from 'images/prepay-dashboard/chat_with_us.png'

interface Props {
  currentPhase: KasePhase
  isLoading: boolean
  openChat: (args: { via: string }) => void
}

const ResourcesPanel: FunctionComponent<Props> = ({ currentPhase, isLoading, openChat }) => {
  const phaseDataResources = currentPhase?.resources
  const phaseDataFAQs = currentPhase?.hasOwnProperty('faqs') && currentPhase?.faqs

  if (isLoading) {
    return (
      <div className="o-griddle__col--12 o-griddle__col--md-6 o-griddle__col--lg-12 o-block o-block--spacious p-3">
        <aside className="c-dashboard__panel">
          <header className="o-layout--flex o-layout--vertical-center o-layout--space-between o-block o-block--tight">
            <span className="c-type c-type--headline-sm c-type--emphasized">
              <SkeletonHeading />
            </span>
          </header>
          <ul className="c-list">
            <SkeletonParagraph numLines={3} />
          </ul>
        </aside>
      </div>
    )
  }

  if (!phaseDataResources) {
    return null
  }

  const handleSupportMessageClick = (event) => {
    event.preventDefault()
    event.nativeEvent.stopImmediatePropagation()

    openChat({ via: 'ToolMenu' })
  }

  return (
    <aside className="c-dashboard__panel o-block--spacious">
      {phaseDataFAQs && phaseDataFAQs.questions.length > 0 ? (
        <>
          <header className="o-block o-block--tight pt-2">
            <span className="c-type c-type--headline-sm c-type--emphasized">{phaseDataFAQs.title}</span>
          </header>
          <hr className="c-divider o-block" aria-hidden="true" />
          <div>
            <ul className="c-list">
              {phaseDataFAQs.questions.map(({ question, answer }, index) => (
                <li key={index}>
                  <Accordion
                    chevronPosition="left"
                    className="py-4"
                    isSlim={true}
                    hasChildPadding={false}
                    header={<span className="c-type--bold">{question}</span>}
                  >
                    <Markdown
                      source={answer}
                      options={{ linkToNewTabs: true }}
                      className="bg-white p-4 ml-6 mt-2 markdown"
                    />
                  </Accordion>
                </li>
              ))}
            </ul>
            <hr className="c-divider o-block" aria-hidden="true" />
            <div className="flex items-center mt-4">
              <img src={ChatImg} alt="Boundless service agent" height={34} width={34} className="mr-4" />
              <Button color="link-primary" label="Chat with us" onClick={handleSupportMessageClick} />
            </div>
          </div>
        </>
      ) : (
        <>
          <header className="o-layout--flex o-layout--vertical-center o-layout--space-between o-block o-block--tight pt-2">
            <span className="c-type c-type--headline-sm c-type--emphasized">Resources & FAQ</span>
            <Paragraph>
              <ResourcesIcon
                className="mr-2 c-dashboard__icon c-dashboard__icon--muted c-icon--xsm"
                aria-hidden="true"
              />
              <ExternalLink href={externalUrl('/immigration-resources')}>See All Resources</ExternalLink>
            </Paragraph>
          </header>
          <hr className="c-divider o-block" aria-hidden="true" />
          <div>
            <ul className="c-list">
              {phaseDataResources.map((resource, index) => {
                return (
                  <li
                    key={`dashboard-resource-${index}`}
                    className="o-block o-block--compact c-type c-type--body-sans-md"
                  >
                    <ExternalLink href={externalUrl(resource.url_path)}>{resource.title}</ExternalLink>
                  </li>
                )
              })}
            </ul>
          </div>
        </>
      )}
    </aside>
  )
}

function mapDispatchToActions(dispatch) {
  return {
    openChat: (...args) => dispatch(openChat(...args))
  }
}

export default connect(null, mapDispatchToActions)(ResourcesPanel)
