import React from 'react'

import Interstitial from 'components/panels/interstitial'

function PreparingInAdvance() {
  return (
    <Interstitial
      headline="Get a head start on your application."
      subtitle="Congratulations on your upcoming marriage! We can help you prepare all of your paperwork and documents in advance so that you can apply as soon as you are legally married."
      image="setup-spouse-questions"
    />
  )
}

export default PreparingInAdvance
