import React, { FunctionComponent } from 'react'
import CheckmarkIcon from 'components/icons/checkmark_icon'

interface ExplicitProps {
  text: string
}

const BeginApplicationColumnItem: FunctionComponent<ExplicitProps> = ({ text }) => {
  return (
    <span className="flex c-type--body-sans-sm">
      <CheckmarkIcon className="mr-1" color="#168753" />
      {text}
    </span>
  )
}

export default BeginApplicationColumnItem as FunctionComponent<ExplicitProps>
