import React from 'react'
import { connect } from 'react-redux'
import u from 'updeep'

import api from 'lib/api'
import { appDomain } from 'lib/settings'
import { SECTION_NAME_PETITION, TELEMETRY_EVENTS, SET_GLOBAL_ERROR } from 'lib/constants'

import { openModal } from 'actions/modal_actions'
import { trackClientSideUserEvent, trackUserEvent } from 'actions/telemetry_actions_v2'
import { postTelemetry } from 'actions/telemetry_actions'
import { getCurrentKase, getCurrentUserHasAcceptedAttorneyAgreement, isUserLoggedIn } from 'reducers/selectors'

import ArrowIcon from 'components/icons/arrow_icon'
import Button from 'components/button'
import { showSignInModal } from 'components/sign_in_nav'
import { GlobalErrorObject } from './screens/questionnaire/lib/types'

export type PaymentType = 'splitIt' | 'stripe'

export type Tier = 'essential' | 'premium'

interface ExplicitProps {
  buttonLabel?: string
  buttonBlock?: boolean
  buttonSize?: 'default' | 'small' | 'tiny'
  includeUscisFees?: boolean
  isMobile?: boolean
  numberTeens?: number
  paymentType: PaymentType
  totalFeesInCents: number
  tier: Tier
}

interface MappedProps {
  userLoggedIn?: boolean
  isAttorneyAgreementAccepted?: boolean
  kase: Kase
}

interface ActionProps {
  setGlobalError: ({ type, payload }: GlobalErrorObject) => void
  openSplititModal: (
    applicationFeeInCents: number,
    containerSection: string,
    onCancel: () => void,
    tier: Tier,
    includeUscisFees?: boolean,
    numberTeens?: number
  ) => void
  openAttorneyAgreementModal: (closeModalCallback: () => {}) => void
  trackInitiatedPayment: (data: { paymentType: string; hasSelectedUSCISFees: boolean }) => void
  trackViewedTransition: (transitionKind: string) => void
  trackClientSideUserEvent: (...args) => void
}

interface State {
  hasError?: boolean
  paymentModalIsOpen?: boolean
  inProgress?: boolean
}

type Props = MappedProps & ExplicitProps & ActionProps

class Payment extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      hasError: false,
      paymentModalIsOpen: false,
      inProgress: false
    }
  }

  componentDidMount() {
    const currentURL = new URL(window.window.location.href)
    const sessionId = currentURL.searchParams.get('session_id')

    if (this.props.kase && currentURL.searchParams.get('canceled')) {
      api.payments.expireStripeCheckoutSession({ kase_id: this.props.kase.id, session_id: sessionId })
    }
  }

  redirectToStripeCheckout = () => {
    this.props.trackInitiatedPayment({
      paymentType: 'stripeCheckout',
      hasSelectedUSCISFees: this.props.includeUscisFees
    })
    const domain = appDomain()
    this.setState({ inProgress: true })

    api.payments
      .initiateStripeCheckoutSession({
        kase_id: this.props.kase.id,
        domain_url: `${domain}/applications/${this.props.kase.id}`,
        include_uscis_fees: this.props.includeUscisFees,
        number_teens: this.props.numberTeens,
        tier: this.props.tier
      })
      .then((res) => {
        // If the subtotal at stripe does not match the current total fees,
        // halt, and force a refresh with a global error.
        if (res.data.amountSubtotal !== this.props.totalFeesInCents) {
          this.props.setGlobalError({ type: SET_GLOBAL_ERROR, payload: 'Sorry, something went wrong.' })
          this.setState({ hasError: true })
        } else {
          // Redirect user to the Stripe provided URL for secure checkout
          window.window.location.href = res.data.sessionUrl
        }
      })
      .then(() => {
        setTimeout(() => {
          this.setState({ inProgress: false })
        }, 500)
      })
      .catch((errors) => {
        let error = errors?.response.data.errors ? errors.response.data.errors[0] : 'Sorry, something went wrong.'

        this.props.setGlobalError({ type: SET_GLOBAL_ERROR, payload: error })

        this.setState({ hasError: true, inProgress: false })
      })
  }

  launchAttorneyAgreementModal = () => {
    if (this.props.tier === 'premium') {
      this.props.trackViewedTransition('attorney-review-modal')
      this.props.openAttorneyAgreementModal(() => this.continuePaymentProcess)
    } else {
      this.continuePaymentProcess()
    }
  }

  launchCreateAccountModal() {
    showSignInModal({
      customTitle: 'Save Application to Continue to Attorney Review',
      isLogIn: false,
      via: 'PaymentFunnel',
      noRedirect: true
    })
  }

  startAttorneyReview = () => {
    trackClientSideUserEvent(TELEMETRY_EVENTS.INITIATED_CHECKOUT)
    this.launchAttorneyAgreementModal()
  }

  continuePaymentProcess = () => {
    if (this.props.paymentType === 'stripe') {
      this.redirectToStripeCheckout()
    } else {
      this.setState({ paymentModalIsOpen: true })
    }
  }

  hidePaymentModal = () => {
    this.setState({ paymentModalIsOpen: false })
  }

  getCTALabel = (alternateLabel: string, buttonLabelProp?: string) => {
    if (buttonLabelProp) {
      return buttonLabelProp
    } else {
      return 'Continue to Checkout'
    }
  }

  getCTASize = () => {
    const { buttonSize, isMobile } = this.props

    if (buttonSize) {
      return buttonSize
    } else {
      return isMobile ? 'small' : 'default'
    }
  }

  getCTA = () => {
    const { buttonLabel, isAttorneyAgreementAccepted } = this.props
    const { paymentModalIsOpen, inProgress, hasError } = this.state

    if (isAttorneyAgreementAccepted) {
      return (
        <Button
          block={this.props.buttonBlock}
          color="primary"
          className="o-block"
          size={this.getCTASize()}
          isLoading={paymentModalIsOpen || inProgress}
          disabled={paymentModalIsOpen || inProgress || hasError}
          onClick={this.continuePaymentProcess}
          label={this.getCTALabel('Continue', buttonLabel)}
        />
      )
    }

    return (
      <Button
        block={this.props.buttonBlock}
        color="primary"
        className="o-block"
        size={this.getCTASize()}
        isLoading={paymentModalIsOpen || inProgress}
        disabled={hasError}
        label={this.getCTALabel('Apply with lawyer support', buttonLabel)}
        onClick={this.startAttorneyReview}
        id="payment-cta"
      />
    )
  }

  render() {
    const { includeUscisFees, numberTeens, paymentType, openSplititModal, totalFeesInCents } = this.props
    const { paymentModalIsOpen } = this.state

    paymentModalIsOpen &&
      paymentType === 'splitIt' &&
      openSplititModal(
        totalFeesInCents,
        SECTION_NAME_PETITION,
        () => this.setState({ paymentModalIsOpen: false }),
        this.props.tier,
        includeUscisFees,
        numberTeens
      )

    return <>{this.getCTA()}</>
  }
}

function mapStateToProps(state): MappedProps {
  return {
    isAttorneyAgreementAccepted: getCurrentUserHasAcceptedAttorneyAgreement(state),
    kase: getCurrentKase(state),
    userLoggedIn: isUserLoggedIn(state)
  }
}

function mapDispatchToActions(dispatch: Function, ownProps: ExplicitProps & MappedProps): ActionProps {
  return {
    setGlobalError: ({ type, payload }: GlobalErrorObject) => dispatch({ type, payload }),
    openSplititModal: (
      totalFeesInCents: number,
      containerSection: string,
      onCancel: () => void,
      tier: Tier,
      includeUscisFees: boolean,
      numberTeens: number
    ) =>
      dispatch(
        openModal({
          name: 'SplititModalV2',
          via: 'PaymentPage',
          modalProps: {
            applicationFeeInCents: totalFeesInCents,
            containerSection,
            includeUscisFees,
            tier,
            numberTeens,
            onCancel: u.constant(onCancel)
          }
        })
      ),
    openAttorneyAgreementModal: (closeModalCallback) =>
      dispatch(
        openModal({
          name: 'AttorneyAgreementModal',
          via: 'PaymentPage',
          modalProps: { closeModalCallback }
        })
      ),
    trackViewedTransition: (name) =>
      dispatch(
        postTelemetry(TELEMETRY_EVENTS.VIEWED_TRANSITION, {
          TransitionName: name
        })
      ),
    trackInitiatedPayment: (args) =>
      dispatch(
        trackUserEvent(TELEMETRY_EVENTS.INITIATED_PAYMENT, {
          ...args,
          containerSection: SECTION_NAME_PETITION,
          tierCartIntent: ownProps.tier
        })
      ),
    trackClientSideUserEvent: (...args) => dispatch(trackClientSideUserEvent(...args))
  }
}

export default connect(mapStateToProps, mapDispatchToActions)(Payment)
