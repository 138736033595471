import React, { FunctionComponent } from 'react'
import InlineIconWrapper, { IconWrapperExternalProps } from './icon_wrapper'

const QuestionCircleIcon: FunctionComponent<IconWrapperExternalProps> = (props) => (
  <InlineIconWrapper {...props} viewBox="0 0 21 21">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.75 10.5C1.75 5.66751 5.66751 1.75 10.5 1.75C15.3325 1.75 19.25 5.66751 19.25 10.5C19.25 15.3325 15.3325 19.25 10.5 19.25C5.66751 19.25 1.75 15.3325 1.75 10.5ZM10.5 0.25C4.83908 0.25 0.25 4.83908 0.25 10.5C0.25 16.1609 4.83908 20.75 10.5 20.75C16.1609 20.75 20.75 16.1609 20.75 10.5C20.75 4.83908 16.1609 0.25 10.5 0.25ZM10.5 6.625C9.40896 6.625 8.625 7.41587 8.625 8.2625V8.8875H7.375V8.2625C7.375 6.60913 8.84104 5.375 10.5 5.375C12.159 5.375 13.625 6.60913 13.625 8.2625C13.625 9.02847 13.4421 9.60693 13.1097 10.0509C12.7867 10.4824 12.3673 10.7234 12.0373 10.8941C11.9561 10.9361 11.8832 10.973 11.817 11.0064L11.8168 11.0065C11.5791 11.1265 11.4293 11.2021 11.3059 11.3118C11.2062 11.4005 11.125 11.5112 11.125 11.7875V12.4125H9.875V11.7875C9.875 11.1576 10.1063 10.7058 10.4753 10.3777C10.7341 10.1476 11.071 9.97984 11.3223 9.85474L11.3223 9.85473L11.3223 9.85473C11.3731 9.82946 11.4203 9.80593 11.4627 9.78399C11.7577 9.63133 11.9633 9.49653 12.109 9.30182C12.2454 9.11964 12.375 8.82153 12.375 8.2625C12.375 7.41587 11.591 6.625 10.5 6.625ZM9.75 15.2876V13.7876H11.25V15.2876H9.75Z"
    />
  </InlineIconWrapper>
)

export default QuestionCircleIcon
