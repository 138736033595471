import React from 'react'
import { connect } from 'react-redux'
import _isEmpty from 'lodash/isEmpty'

import panelLocaleService from 'services/panel_locale_service'
import { joinI18nKeys } from 'lib/i18n'
import { openChat } from 'actions/chat_actions'
import Controls from './controls'
import JITFeedback from './jit_feedback'
import { PanelType } from './index'
import QuestionText from 'components/question_text'
import SaveProgressButton from 'components/save_progress_button'
import { smoothScrollToTopOfElement } from 'lib/ui_helpers'
import AskIcon from 'components/icons/ask_icon'

interface Props {
  openChat: Function
  panel: PanelType
  panelIsComplete: boolean
}

class Card extends React.Component<Props> {
  componentDidMount() {
    setTimeout(this.scrollToTop, 150)
  }

  scrollToTop = () => {
    const sheet = document.getElementById('sheet-container')
    if (sheet != null) {
      smoothScrollToTopOfElement(sheet)
    }
  }

  getQuestion() {
    const keys = joinI18nKeys(this.props.panel.panel_keys, 'question')

    return panelLocaleService.t(keys, {
      index: this.props.panel.panel_index,
      parentIndex: this.props.panel.parentIndex
    })
  }

  getJITFeedbackText() {
    const keys = joinI18nKeys(this.props.panel.panel_keys, 'jit_feedback.text')
    if (!panelLocaleService.exists(keys)) {
      return null
    }

    return panelLocaleService.t(keys, {
      index: this.props.panel.panel_index,
      parentIndex: this.props.panel.parentIndex
    })
  }

  handleChatTriggerClick = (event) => {
    event.preventDefault()
    event.nativeEvent.stopImmediatePropagation()

    this.props.openChat({ via: 'QuestionCard' })
  }

  renderJITFeedback() {
    let feedbackText = this.getJITFeedbackText()
    if (!feedbackText || _isEmpty(feedbackText.trim())) {
      feedbackText = null
    }

    if (!this.props.panelIsComplete) {
      feedbackText = null
    }

    // We always want to render the JIT because it scrolls the
    // page when its text changes.
    return <JITFeedback text={feedbackText} />
  }

  render() {
    return (
      <React.Fragment>
        <div className="o-grid__row">
          <div className="o-grid__col-6 o-grid__col--offset-1">
            <QuestionText question={this.getQuestion()} />

            <Controls panel={this.props.panel} />

            {this.renderJITFeedback()}
          </div>
        </div>

        <div className="c-sheet__footer u-hide@md-up">
          <div className="o-block">
            <button className="c-btn c-btn--flat" onClick={this.handleChatTriggerClick} type="button">
              <AskIcon />
              Need Help?
            </button>
          </div>
          <div>
            <SaveProgressButton isCompact={true} />
          </div>
        </div>
      </React.Fragment>
    )
  }
}

function mapDispatchToActions(dispatch) {
  return {
    openChat: (...args) => dispatch(openChat(...args))
  }
}

export default connect(null, mapDispatchToActions)(Card)
