import React, { FunctionComponent } from 'react'
import { AddressHistory } from 'reducers/address_histories'
import AddressForm from './address_form'
import PencilIcon from 'components/icons/pencil_icon'
import moment from 'moment'
import AlertCircleIcon from 'components/icons/alert_circle_icon'
import useStateWithCallback from 'use-state-with-callback'
import { scroller } from 'react-scroll'
import { updateCurrentAddressHistoryEdit } from 'actions/address_actions'
import { CurrentAddressFilledOptions } from '../../lib/types'
import ReactMarkdown from 'react-markdown'
import { MarkdownLinkRendererUnderline } from 'lib/markdown'

interface Props {
  /**
   * the AddressHistory that will populate this field
   * we call it 'value' so it fits in with other questionnaire fields in the QuestionContainer component
   */
  value: AddressHistory
  /**
   * The text to display if we are displaying an `address_history_gap`. There is default text available
   * if this prop is not set
   */
  gapText?: string
  /**
   * The current page path which is used to determine if we're looking at the beneficiary's address history
   * or the sponsor's
   */
  currentPagePath?: string
  /**
   * An object that tells you if current address has been filled or if spouse's current address has been filled
   * Used for the AddressForm to determine whether or not to render the currently lived here checkbox
   */
  currentAddressFilledOptions?: CurrentAddressFilledOptions
}

const AddressHistoryInput: FunctionComponent<Props> = ({
  value,
  gapText,
  currentPagePath,
  currentAddressFilledOptions
}) => {
  const addressHistory = value

  const [isEditing, setIsEditing] = useStateWithCallback(false, (isEditing) => {
    if (isEditing) {
      scroller.scrollTo('addressForm', {
        duration: 500,
        delay: 10,
        smooth: true,
        offset: -100
      })
    }
  })

  const formatDate = (date) => {
    if (date == null) {
      return 'Today'
    }
    return moment(date).format('l')
  }

  const handleEditButtonClick = (event) => {
    event.preventDefault()
    setIsEditing(true)
  }

  const handleFormCancel = () => {
    setIsEditing(false)
    updateCurrentAddressHistoryEdit(null)
  }

  const addressFormSaveCallback = (isFormError: boolean) => {
    if (!isFormError) {
      setIsEditing(false)
    }
  }

  if (isEditing) {
    return (
      <AddressForm
        addressToEdit={addressHistory}
        saveCallback={addressFormSaveCallback}
        cancelCallback={handleFormCancel}
        editSaveCallback={addressFormSaveCallback}
        currentPagePath={currentPagePath}
        currentAddressFilledOptions={currentAddressFilledOptions}
        hiddenCountryCodes={!addressHistory.start_date ? ['US'] : []}
      />
    )
  }

  const defaultGapText = `Need all addresses from ${formatDate(addressHistory.start_date)} to ${formatDate(
    addressHistory.end_date
  )}`

  if (addressHistory.address == null) {
    return (
      <div className="o-block o-layout o-layout--padded-x c-paper-form-missing" onClick={handleEditButtonClick}>
        <div className="o-grid--fluid">
          <div className="o-grid__cell--1/12">
            <AlertCircleIcon size="medium" onClick={handleEditButtonClick} />
          </div>
          <div className="o-grid__cell--11/12 c-type c-type--primary c-type--body-sans-sm">
            {/* @ts-ignore */}
            <ReactMarkdown
              source={gapText ? gapText : defaultGapText}
              renderers={{ Link: MarkdownLinkRendererUnderline }}
            />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="o-grid--fluid o-block c-paper-form-slim o-layout--padded-x">
      <div className="o-grid__cell--11/12">
        <p>
          {addressHistory.address.street}, {addressHistory.address.city}&nbsp;
          {addressHistory.address.province}
        </p>
        <p>
          {formatDate(addressHistory.start_date)} to {formatDate(addressHistory.end_date)}
        </p>
      </div>
      <div className="o-grid__cell--1/12">
        <PencilIcon
          size="medium"
          className="o-media__figure tool-menu-item-icon o-media--hover"
          onClick={handleEditButtonClick}
          aria-label="edit this address"
        />
        {addressHistory.address_history_mismatch ? (
          <div className="o-flag__item o-flag__item--drop@sm c-tooltip c-tooltip--danger" key={addressHistory.id}>
            <div className="c-type c-type--body-sans-sm">
              This entry has an address that doesn’t match your employment history. Please edit this so that it matches.
            </div>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default AddressHistoryInput
