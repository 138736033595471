import React from 'react'

import BufferedFieldValue from 'components/forms/buffered_field_value'

interface Props {
  className: string
  onBlur: Function
  formatter: Function
  id: string
  path: string
  containerClassName: string
  serializer: Function
}

class WeightInput extends React.Component<Props> {
  static defaultProps = {
    className: '',
    containerClassName: ''
  }

  render() {
    const { className, onBlur, formatter, id, path, serializer, containerClassName } = this.props

    return (
      <BufferedFieldValue className={containerClassName} formatter={formatter} path={path} serializer={serializer}>
        {(value, onChange) => (
          <input
            autoComplete="off"
            className={`c-paper-form__control ${className}`}
            data-model-path={path}
            id={id}
            name="weight"
            onBlur={onBlur}
            onChange={onChange}
            placeholder="0"
            required={true}
            pattern="\d*"
            inputMode="numeric"
            type="tel"
            value={value}
          />
        )}
      </BufferedFieldValue>
    )
  }
}

export default WeightInput
