import React from 'react'

import { joinI18nKeys } from 'lib/i18n'
import panelLocaleService from '../../../../services/panel_locale_service'
import { QuestionnaireInputContainerProps } from 'components/forms/inputs'

import BufferedFieldValue from '../../buffered_field_value'
import ValidatedInput from '../../validated_input'

class Email extends React.Component<QuestionnaireInputContainerProps> {
  render() {
    const { disabled, path } = this.props

    return (
      <div className="o-flag o-grid--fluid o-grid--stack@sm o-block c-paper-form__group">
        <label
          className="o-grid__cell--3/12 o-grid__cell--off@sm c-paper-form__label c-paper-form__segment"
          htmlFor={this.props.id}
        >
          <span className="c-type c-type--subhead-sm">
            {panelLocaleService.t(joinI18nKeys(this.props.resourceKeys, `${this.props.name}.label`))}
          </span>
        </label>

        <div className="o-grid__cell--9/12 o-grid__cell--off@sm c-paper-form__segment">
          <ValidatedInput disabled={disabled} path={path}>
            {({ fireValidation, getValidationClasses, renderValidations }) => (
              <React.Fragment>
                <BufferedFieldValue path={path}>
                  {(value, onChange) => (
                    <input
                      id={this.props.id}
                      name={this.props.name}
                      onChange={onChange}
                      onBlur={fireValidation}
                      type="email"
                      value={value}
                      className={`c-paper-form__control ${getValidationClasses()}`}
                      disabled={this.props.disabled}
                      autoComplete="off"
                    />
                  )}
                </BufferedFieldValue>

                {renderValidations()}
              </React.Fragment>
            )}
          </ValidatedInput>
        </div>
      </div>
    )
  }
}

export default Email
