import React, { ChangeEventHandler, FocusEventHandler, FunctionComponent } from 'react'
import countries from 'data/address_history_country_options.json'

import CommonLabelV2 from './common/common_label_v2'
import CommonSelectV2 from './common/common_select_v2'

interface Props {
  disabled?: boolean
  hiddenCountryCodes?: string[]
  label?: string
  value?: string
  onBlurEvent: FocusEventHandler
  onChangeEvent: ChangeEventHandler
}

const CountryInputV2: FunctionComponent<Props> = ({
  disabled,
  hiddenCountryCodes = [],
  label = 'country',
  value = '',
  onBlurEvent,
  onChangeEvent
}) => {
  /**
   * Copying the country object because when we alter it, it does so globally
   * We don't want it to remove countries globally for all instances of this component
   */
  const countriesCopy = { ...countries }
  hiddenCountryCodes?.forEach((abbreviation) => delete countriesCopy[abbreviation])

  const countryOptions = {
    '': 'Please select a country',
    ...countriesCopy
  }

  /**
   * This addresses a bug where migrations from 1.0 pass the country name as a string
   * instead of the country code, so it appears on the front end as if the user didn't
   * answer the question even though we have a saved value for it.
   */
  if (value?.length > 2) {
    value = Object.keys(countryOptions).find((key) => countryOptions[key] === value)
  }

  return (
    <div className="o-grid--fluid o-grid--stack@sm o-block c-paper-form__group">
      <CommonLabelV2 label={label} labelFor="country" />
      <CommonSelectV2
        inputName="country_code"
        value={value ? value : ''}
        options={countryOptions}
        containerClass="c-paper-form__control"
        onChangeEvent={onChangeEvent}
        onBlur={onBlurEvent}
        disabled={disabled}
      />
    </div>
  )
}

export default CountryInputV2
