import React, { FunctionComponent, useMemo } from 'react'

import {
  DASHBOARD_PHASE_INTAKE,
  DASHBOARD_PHASE_DOCUMENT_UPLOAD,
  DashboardPhase,
  DASHBOARD_PHASE_ILA,
  DASHBOARD_PHASE_DOCUMENT_REVIEW
} from './phase_map'
import IntakePhaseInformation from './phase_information/intake_phase_information'
import ILAPhaseInformation from './phase_information/ila_phase_information'
import { DashboardPhaseData } from './phase_config/phase_preview'
import DefaultPhaseInformation from './phase_information/default_phase_information'
import DocumentUploadPhaseInformation from 'components/screens/dashboard/customer_dashboard/phases/document_upload_phase_information'
import QAPhaseInformation from 'components/screens/dashboard/customer_dashboard/phase_information/qa_phase_information'

interface Props {
  currentPhase: DashboardPhase
  phaseData: DashboardPhaseData
}

const CurrentPhaseDetailsWrapper: FunctionComponent<Props> = ({ currentPhase, phaseData }) => {
  const phaseDetails = useMemo(() => {
    switch (currentPhase) {
      case DASHBOARD_PHASE_INTAKE:
        return <IntakePhaseInformation />
      case DASHBOARD_PHASE_ILA:
        return <ILAPhaseInformation currentPhase={currentPhase} />
      case DASHBOARD_PHASE_DOCUMENT_REVIEW:
        return <QAPhaseInformation currentPhase={currentPhase} />
      case DASHBOARD_PHASE_DOCUMENT_UPLOAD:
        return <DocumentUploadPhaseInformation currentPhase={currentPhase} phaseData={phaseData} />
      default:
        return <DefaultPhaseInformation currentPhase={currentPhase} />
    }
  }, [currentPhase])

  return phaseDetails
}

export default CurrentPhaseDetailsWrapper
