import { wwwDomain } from 'lib/settings'

export interface DomainResourceContext {
  externalDomain: string
}

export default function buildDomainResourceContext(): DomainResourceContext {
  return {
    externalDomain: wwwDomain()
  }
}
