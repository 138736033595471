import _map from 'lodash/map'

function viewMasterPdfUrl(masterPdfId) {
  return `/master_pdfs/${masterPdfId}`
}

function masterPdfFeedbackUrl(masterPdfId) {
  return `/master_pdfs/${masterPdfId}/feedback`
}

function storedFileUrl(kase, storedFile, options = {}) {
  const kaseId = kase.id ? kase.id : kase
  const storedFileId = storedFile.id ? storedFile.id : storedFile

  let url = `/admin/kases/${kaseId}/stored_files/${storedFileId}`

  if (options.inline) {
    url += '?inline=true'
  }

  return url
}

function documentUrl(kase, document, options = {}) {
  const kaseId = kase.id ? kase.id : kase
  const documentId = document.id ? document.id : document

  let url = `/api/kases/${kaseId}/documents/${documentId}/url`

  const params = {}

  if (options.disposition) params.disposition = options.disposition

  const query = _map(params, (value, key) => `${key}=${value}`).join('&')

  url += `?${query}`

  return url
}

function uploadDocumentsUrl(kaseId) {
  return `/applications/${kaseId}/document_upload`
}

function adminApplicationUrl(kase) {
  const kaseId = kase.id ? kase.id : kase

  return `/active_admin/applications/${kaseId}`
}

function editKaseModelUrl(kase, kaseModel) {
  const kaseId = kase.id ? kase.id : kase
  const kaseModelPath = kaseModel.path ? kaseModel.path : kaseModel

  return `/active_admin/applications/${kaseId}/edit_kase_model?path=${kaseModelPath}`
}

function newAdminDocumentRequestUrl(kase) {
  const kaseId = kase.id ? kase.id : kase

  return `/active_admin/applications/${kaseId}/edit_kase_model?path=admin_requested_documents`
}

function feedbackTemplateUrl(kase) {
  const kaseId = kase.id ? kase.id : kase

  return `/applications/${kaseId}/feedback_template`
}

function timelineUrl(kase) {
  const kaseId = kase.id ? kase.id : kase

  return `/applications/${kaseId}/timeline`
}

export default {
  adminApplicationUrl,
  documentUrl,
  editKaseModelUrl,
  feedbackTemplateUrl,
  masterPdfFeedbackUrl,
  newAdminDocumentRequestUrl,
  storedFileUrl,
  timelineUrl,
  uploadDocumentsUrl,
  viewMasterPdfUrl
}
