import _isEmpty from 'lodash/isEmpty'
import _isNumber from 'lodash/isNumber'

import { buildMessage, Validator } from './'

const required: Validator = ({ getModelData, path, options = {} }) => {
  const currentValue = getModelData(path)

  const booleanValue = currentValue === false || currentValue === true
  const whitelisted = booleanValue || _isNumber(currentValue)

  if (_isEmpty(currentValue) && !whitelisted) {
    const message = options.message || 'Please fill out this field'
    return [buildMessage(message)]
  }

  return []
}

export default required
