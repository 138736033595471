import React from 'react'
import BufferedFieldValue from 'components/forms/buffered_field_value'

interface Props {
  containerClassName: string
  disabled: boolean
  label: string
  path: string
  type: string
}

export default class UnitTypeInput extends React.Component<Props> {
  static defaultProps = {
    disabled: false,
    containerClassName: ''
  }

  render() {
    const { containerClassName, label, path, type } = this.props

    return (
      <BufferedFieldValue className={containerClassName} path={path}>
        {(value, onChange) => {
          const checked = value === type
          const onClick = () => checked && onChange(null)

          return (
            <label className="c-custom-control c-custom-control--radio">
              <input
                name="unit_type"
                type="radio"
                className="c-custom-control__input"
                value={type}
                checked={checked}
                onChange={onChange}
                onClick={onClick}
              />

              <span className="c-custom-control__indicator" />
              <span className="c-type c-type--subhead-sm c-custom-control__description">{label}</span>
            </label>
          )
        }}
      </BufferedFieldValue>
    )
  }
}
