import React, { ChangeEventHandler, FocusEventHandler, FunctionComponent, useEffect, useState } from 'react'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'

import { addNewAddressHistory, updateAddressHistory, deleteAddressHistory } from 'actions/address_actions'

import { Address, AddressHistory } from 'reducers/address_histories'
import {
  getBeneficiaryFirstName,
  getCurrentKaseId,
  getCurrentKaseKind,
  getSponsorFirstName,
  isMobileBrowser
} from 'reducers/selectors'

import Button from 'components/button'
import AddressV2 from 'components/forms/inputs/v2/address'
import DateInputV2 from 'components/forms/inputs/v2/address/date_input_v2'
import CommonCheckboxV2 from 'components/forms/inputs/v2/address/common/common_checkbox_v2'
import JITFeedback from 'components/forms/panel/jit_feedback'

import { validateDates } from '../histories_utils/date_validators'
import DocumentWarning from '../histories_utils/document_warning'

import { CurrentAddressFilledOptions } from '../../lib/types'
import { KaseKind } from 'lib/constants'
import { getAddressHistoryObjectFromPlaceUpdate } from '../../lib/places'

interface Props {
  addressToEdit?: AddressHistory
  saveCallback?: (isFormError?: boolean) => void
  cancelCallback?: () => void
  editSaveCallback?: (isFormError?: boolean) => void
  currentPagePath?: string
  currentAddressFilledOptions?: CurrentAddressFilledOptions
  hiddenCountryCodes?: any
}

interface InjectedProps {
  isMobile: boolean
  kaseId?: number
  sponsorFirstName?: string
  beneficiaryFirstName?: string
  currentKaseKind: KaseKind
}

interface MappedActions {
  addNewAddressHistory: Function
  updateAddressHistory: Function
  deleteAddressHistory: Function
}

type AllProps = Props & InjectedProps & MappedActions

// From the perspective of the address history record, spouse is referring to the spouse of the record
// From the perspective of this component, spouse refers to the spouse of the account holder
const AddressForm: FunctionComponent<AllProps> = (props) => {
  const [formValues, setFormValues] = useState<AddressHistory>({
    address_history_mismatch: false,
    end_date: null,
    id: null,
    shared: false,
    start_date: null
  })
  const [formAddressValues, setFormAddressValues] = useState<Address>({
    city: null,
    country_code: null,
    postal_code: null,
    province: null,
    street: null,
    unit_number: null,
    unit_type: null
  })

  const {
    addNewAddressHistory,
    addressToEdit = null,
    cancelCallback,
    currentPagePath,
    deleteAddressHistory,
    currentAddressFilledOptions,
    isMobile,
    kaseId,
    currentKaseKind,
    sponsorFirstName,
    beneficiaryFirstName,
    saveCallback,
    updateAddressHistory,
    hiddenCountryCodes = []
  } = props

  const [isFormError, setIsFormError] = useState(false)
  const [formErrorMessages, setFormErrorMessages] = useState([])

  const dateErrorMessage = 'Please enter a valid date'

  const [startDateIsValid, setStartDateIsValid] = useState(true)
  const [startDateValidationMessage, setStartDateValidationMessage] = useState(dateErrorMessage)

  const [endDateIsValid, setEndDateIsValid] = useState(true)
  const [endDateTouched, setEndDateTouched] = useState(false)
  const [endDateValidationMessage, setEndDateValidationMessage] = useState(dateErrorMessage)
  const [spouseStartDateIsValid, setSpouseStartDateIsValid] = useState(true)
  const [spouseStartDateValidationMessage, setSpouseStartDateValidationMessage] = useState(dateErrorMessage)

  const [spouseEndDateIsValid, setSpouseEndDateIsValid] = useState(true)
  const [spouseEndDateTouched, setSpouseEndDateTouched] = useState(false)
  const [spouseEndDateValidationMessage, setSpouseEndDateValidationMessage] = useState(dateErrorMessage)

  const [isSponsorCurrentAddressChecked, setIsSponsorCurrentAddressChecked] = useState(false)
  const [isBeneficiaryCurrentAddressChecked, setIsBeneficiaryCurrentAddressChecked] = useState(false)
  const [isCurrentAddressChecked, setIsCurrentAddressChecked] = useState(false)

  const isSponsorPath = currentPagePath === "sponsor's-profile-address-history"
  const isBeneficiaryPath = currentPagePath === "beneficiary's-profile-address-history"

  useEffect(() => {
    if (addressToEdit) {
      setFormValues(addressToEdit)
      setFormAddressValues(addressToEdit.address)
      if (!addressToEdit.end_date) {
        if (isSponsorPath) {
          setIsSponsorCurrentAddressChecked(true)
        } else if (isBeneficiaryPath) {
          setIsBeneficiaryCurrentAddressChecked(true)
        }
        setIsCurrentAddressChecked(true)
      } else {
        if (isSponsorPath) {
          setIsSponsorCurrentAddressChecked(false)
        } else if (isBeneficiaryPath) {
          setIsBeneficiaryCurrentAddressChecked(false)
        }
        setIsCurrentAddressChecked(false)
      }
      if (addressToEdit.shared && !addressToEdit.spouse_end_date) {
        if (isSponsorPath) {
          setIsBeneficiaryCurrentAddressChecked(true)
        } else if (isBeneficiaryPath) {
          setIsSponsorCurrentAddressChecked(true)
        }
      }
    }
  }, [addressToEdit])

  const changeFormValue = (evt) => {
    const { value, name } = evt.target
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value
    }))
  }

  //#CON-1430 - I'm removing the personalization for now because this is pulling from
  // the JSONB column on some occasions and doesn't appear to be working
  //const sponsorPersonalization = sponsorFirstName || 'Sponsor'
  const sponsorPersonalization = 'Sponsor'
  //const beneficiaryPersonalization = beneficiaryFirstName || 'Beneficiary'
  const beneficiaryPersonalization = 'Beneficiary'

  const livedTogether = addressToEdit?.shared

  const isInK1Flow = currentKaseKind === KaseKind.K1FianceVisa
  const isUSAddress = formAddressValues && formAddressValues.country_code === 'US'

  let docWarning
  // Show the following copy on a document warning in the Beneficiary's address
  // history form in any product flow EXCEPT K1 Visa
  if (isBeneficiaryPath && !isInK1Flow) {
    const text =
      'Your most recent U.S. address move-in date must be\
    **on or after your most recent I-94 entry date**.\
    Please check your I-94 to ensure this — if not it may slow\
    down your application.'

    docWarning = <DocumentWarning text={text} />
  }

  // Show the following copy on a document warning in the Beneficiary's address
  // history form in the K1 Fiance Visa flow *IF* the entered data is a current
  // address AND it is in the US.
  if (isBeneficiaryPath && isInK1Flow && isCurrentAddressChecked && isUSAddress) {
    const text =
      'Due to government requirements, the beneficiary must be sure to move\
        back to their home country before filing their K1 fiancé visa. They\
        are not eligible to file for this type of visa while living in the U.S.'

    docWarning = <DocumentWarning text={text} />
  }

  let addressMismatch
  if (formValues && formValues.address_history_mismatch) {
    const text = `The government requires that your address and employment history\
      shows you *living and working in the same locations at the same\
      time*, unless you worked remotely or crossed borders on your\
      commute to work.\
      <br /><br />\
      This address is different than the address you worked at while\
      living here. To continue, please do one of the following:\
      <br /><br />\
      **Show that you worked remotely or crossed borders.**\
      If this is the case, go to that specific employment and select\
      that option.\
      <br /><br />\
      **Match locations.** Edit this address, and the\
      address of where you worked while living here, so that their\
      country, state and province info are the same.\
      <br /><br />\
      **Remove overlap.** If you did live and work in two\
      different locations — and it wasn’t remote work or involved\
      crossing borders — edit your address and employment info so\
      their dates do not overlap.`

    addressMismatch = (
      <div className="o-grid--fluid o-grid--stack@sm o-block c-paper-form__group">
        <JITFeedback text={text} />
      </div>
    )
  }

  let livedTogetherJit
  if (formValues && formValues.shared) {
    const text =
      'Often spouses move into a shared residence at different times. Please enter\
    the move in dates of your spouse and if they currently live there'

    livedTogetherJit = (
      <div className="o-grid--fluid o-grid--stack@sm o-block c-paper-form__group">
        <JITFeedback text={text} />
      </div>
    )
  }

  const changeAddressValue: ChangeEventHandler<HTMLInputElement> = (evt) => {
    // this will be coming from unchecking unit radio
    if (evt === null) {
      setFormAddressValues({ ...formAddressValues, unit_type: null })
      return
    }
    const { value, name } = evt.currentTarget

    setFormAddressValues({ ...formAddressValues, [name.toLowerCase()]: value })
  }

  const handlePlacesUpdate = (addressComponents: google.maps.GeocoderAddressComponent[]) => {
    setFormAddressValues(getAddressHistoryObjectFromPlaceUpdate(addressComponents))
  }

  const handleErrorMessages = (error) => {
    const errors = error.response.data.errors
    if (errors) {
      setFormErrorMessages(errors.map((error) => error.detail))
    } else {
      setFormErrorMessages(['There was an error handling your request. Please check that no fields are empty'])
    }
  }

  const onBlurStartDate: FocusEventHandler<HTMLInputElement> = (event) => {
    // We use event.target.value to avoid race conditions when setting the state of
    // formValues.start_date
    // We have to add the time so that the date isn't offset by one day
    const startDate = new Date(event.target.value + ' 00:00:00')
    const endDate = new Date(formValues.end_date + ' 00:00:00')
    const validationObj = validateDates(startDate, endDate)

    setStartDateIsValid(validationObj.isStartDateValid)
    setStartDateValidationMessage(validationObj.startDateValidationMsg)
    // Only set these validations when end date is rendered
    if (!isCurrentAddressChecked && endDateTouched) {
      setEndDateIsValid(validationObj.isEndDateValid)
      setEndDateValidationMessage(validationObj.endDateValidationMsg)
    }
  }

  const onBlurEndDate: FocusEventHandler<HTMLInputElement> = (event) => {
    // We use event.target.value to avoid race conditions when setting the state of
    // formValues.start_date
    // We have to add the time so that the date isn't offset by one day
    const startDate = new Date(formValues.start_date + ' 00:00:00')
    const endDate = new Date(event.target.value)
    const validationObj = validateDates(startDate, endDate)

    setEndDateTouched(true)
    setStartDateIsValid(validationObj.isStartDateValid)
    setStartDateValidationMessage(validationObj.startDateValidationMsg)
    setEndDateIsValid(validationObj.isEndDateValid)
    setEndDateValidationMessage(validationObj.endDateValidationMsg)
  }

  const onBlurSpouseStartDate: FocusEventHandler<HTMLInputElement> = (event) => {
    // We use event.target.value to avoid race conditions when setting the state of
    // formValues.start_date
    // We have to add the time so that the date isn't offset by one day
    const startDate = new Date(event.target.value + ' 00:00:00')
    const endDate = new Date(formValues.spouse_end_date + ' 00:00:00')
    const validationObj = validateDates(startDate, endDate)

    setSpouseStartDateIsValid(validationObj.isStartDateValid)
    setSpouseStartDateValidationMessage(validationObj.startDateValidationMsg)
    // Only set these validations when end date is rendered
    if (
      ((isSponsorPath && !isBeneficiaryCurrentAddressChecked) ||
        (isBeneficiaryPath && !isSponsorCurrentAddressChecked)) &&
      spouseEndDateTouched
    ) {
      setSpouseEndDateIsValid(validationObj.isEndDateValid)
      setSpouseEndDateValidationMessage(validationObj.endDateValidationMsg)
    }
  }

  const onBlurSpouseEndDate: FocusEventHandler<HTMLInputElement> = (event) => {
    // We use event.target.value to avoid race conditions when setting the state of
    // formValues.start_date
    // We have to add the time so that the date isn't offset by one day
    const startDate = new Date(formValues.spouse_start_date + ' 00:00:00')
    const endDate = new Date(event.target.value)
    const validationObj = validateDates(startDate, endDate)

    setSpouseEndDateTouched(true)
    setSpouseStartDateIsValid(validationObj.isStartDateValid)
    setSpouseStartDateValidationMessage(validationObj.startDateValidationMsg)
    setSpouseEndDateIsValid(validationObj.isEndDateValid)
    setSpouseEndDateValidationMessage(validationObj.endDateValidationMsg)
  }

  // Returns true if inputs are valid, false otherwise
  const datesAreValid = (): boolean => {
    // Set these as scoped variables to avoid race conditions related to state
    let startDateValid = startDateIsValid,
      endDateValid = endDateIsValid,
      spouseStartDateValid = spouseStartDateIsValid,
      spouseEndDateValid = spouseEndDateIsValid

    // startDateIsValid only gets set during onBlur, so there could be a case where
    // they never enter the field and thus it is valid yet there is nothing entered
    // same with endDateIsValid
    if (startDateIsValid && !formValues.start_date) {
      startDateValid = false
      setStartDateIsValid(false)
      setStartDateValidationMessage(dateErrorMessage)
    }

    // If the checkbox for current address is not checked then end date should be filled
    if (!isCurrentAddressChecked && endDateIsValid && !formValues.end_date) {
      endDateValid = false
      setEndDateIsValid(false)
      setEndDateValidationMessage(dateErrorMessage)
    }

    if (formValues.shared && spouseStartDateIsValid && !formValues.spouse_start_date) {
      spouseStartDateValid = false
      setSpouseStartDateIsValid(false)
      setSpouseStartDateValidationMessage(dateErrorMessage)
    }

    if (
      formValues.shared &&
      ((isSponsorPath && !isBeneficiaryCurrentAddressChecked) ||
        (isBeneficiaryPath && !isSponsorCurrentAddressChecked)) &&
      spouseEndDateIsValid &&
      !formValues.spouse_end_date
    ) {
      spouseEndDateValid = false
      setSpouseEndDateIsValid(false)
      setSpouseEndDateValidationMessage(dateErrorMessage)
    }

    if (startDateValid && endDateValid && spouseStartDateValid && spouseEndDateValid) {
      return true
    } else {
      setIsFormError(true)
      setFormErrorMessages(['Please fix any errors in the form above'])
      return false
    }
  }

  const onAddressAddButtonClicked = async (event) => {
    event.preventDefault()
    const owner = isBeneficiaryPath ? 'beneficiary' : 'sponsor'
    let isFormError = false
    const allFormValuesToSave: AddressHistory = {
      ...formValues,
      address: {
        ...formAddressValues
      }
    }

    if (datesAreValid()) {
      if (!!addressToEdit && addressToEdit.id) {
        await updateAddressHistory({ kaseId, owner, formValues: allFormValuesToSave }).catch((error) => {
          isFormError = true
          handleErrorMessages(error)
        })
      } else {
        await addNewAddressHistory({ kaseId, owner, formValues: allFormValuesToSave }).catch((error) => {
          isFormError = true
          handleErrorMessages(error)
        })
      }

      setIsFormError(isFormError)
      saveCallback(isFormError)
    }
  }

  const onCurrentAddressCheckboxChange = () => {
    if (isSponsorPath) {
      setIsSponsorCurrentAddressChecked(!isSponsorCurrentAddressChecked)
    } else if (isBeneficiaryPath) {
      setIsBeneficiaryCurrentAddressChecked(!isBeneficiaryCurrentAddressChecked)
    }

    // Reset validation for end date and touched if checking box
    if (!isCurrentAddressChecked) {
      setEndDateIsValid(true)
      setEndDateTouched(false)
    }
    setIsCurrentAddressChecked(!isCurrentAddressChecked)
    setFormValues({ ...formValues, end_date: null })
  }

  const onSpouseCurrentAddressCheckboxChange = () => {
    // Reset validation for end date and touched if checking box
    if (
      (isSponsorPath && !isBeneficiaryCurrentAddressChecked) ||
      (isBeneficiaryPath && !isSponsorCurrentAddressChecked)
    ) {
      setSpouseEndDateIsValid(true)
      setSpouseEndDateTouched(false)
    }
    if (isSponsorPath) {
      setIsBeneficiaryCurrentAddressChecked(!isBeneficiaryCurrentAddressChecked)
    } else if (isBeneficiaryPath) {
      setIsSponsorCurrentAddressChecked(!isSponsorCurrentAddressChecked)
    }
    setFormValues({ ...formValues, spouse_end_date: null })
  }

  const onLivingTogetherCheckboxChange = () => {
    setFormValues({
      ...formValues,
      shared: !formValues.shared,
      spouse_end_date: null,
      spouse_start_date: null
    })
    // Reset these to true
    setSpouseStartDateIsValid(true)
    setSpouseEndDateIsValid(true)
    setSpouseEndDateTouched(false)
  }

  const handleCancel = (event) => {
    event.preventDefault()
    cancelCallback()
  }

  const handleDelete = async (event) => {
    event.preventDefault()
    await deleteAddressHistory(kaseId, formValues.id)
    saveCallback()
  }

  const handleEditInSpousesAddressBtnClick = (event) => {
    event.preventDefault()

    // We just got this from QuestionnaireContainer but used window.location.pathname instead of history
    const baseQuestionnaireUrlSectionLength = 5
    const baseQuestionnaireUrl: string = window.location.pathname
      .split('/')
      .slice(0, baseQuestionnaireUrlSectionLength)
      .join('/')

    let pathUrl
    if (isBeneficiaryPath) {
      pathUrl = "sponsor's-profile-address-history"
    } else if (isSponsorPath) {
      pathUrl = "beneficiary's-profile-address-history"
    }

    const href = `${baseQuestionnaireUrl}/${pathUrl}`
    window.location.href = href
  }

  const showCurrentAddressCheckbox =
    // there is no current address
    !currentAddressFilledOptions.has_current_address_filled ||
    // this is current address
    isCurrentAddressChecked ||
    // this was the current address, but its been unchecked
    (currentAddressFilledOptions.has_current_address_filled && !!addressToEdit && !addressToEdit.end_date)

  // Spouse refer's to the spouse of the account holder
  const showSpouseCurrentAddressCheckbox =
    // there is no current spouse address
    !currentAddressFilledOptions.has_spouse_current_address_filled ||
    // this is current spouse address
    (isSponsorPath && isBeneficiaryCurrentAddressChecked) ||
    (isBeneficiaryPath && isSponsorCurrentAddressChecked) ||
    // this was the current spouse address, but its been unchecked
    (currentAddressFilledOptions.has_spouse_current_address_filled &&
      !!addressToEdit?.address &&
      !addressToEdit?.spouse_end_date &&
      addressToEdit?.shared)

  const showDeleteButton = addressToEdit && addressToEdit?.address

  let formButtons
  // Disabled if viewing a shared address from the beneficiary's history
  const isSharedBeneficiaryAddress = isBeneficiaryPath && addressToEdit?.shared

  if (isSharedBeneficiaryAddress && !isMobile) {
    formButtons = (
      <>
        <div className="o-grid__cell--9/12"></div>
        <div className="o-grid__cell--3/12">
          <Button className="c-btn-right" color="secondary" onClick={handleCancel} type="button" label="Cancel" />
        </div>
      </>
    )
  }

  if (isSharedBeneficiaryAddress && isMobile) {
    formButtons = (
      <div className="flex">
        <Button className="pt-4 w-2/5" color="secondary" onClick={handleCancel} type="button" label="Cancel" />
      </div>
    )
  }

  if (!isSharedBeneficiaryAddress && !isMobile) {
    formButtons = (
      <>
        {/*If address is blank, this is probably an edit block from clicking the blue gap box*/}
        {showDeleteButton ? (
          <>
            <div className="o-grid__cell--3/12">
              <Button className="c-btn-right" color="secondary" onClick={handleDelete} type="button" label="Delete" />
            </div>
            <div className="o-grid__cell--3/12"></div>
          </>
        ) : (
          <div className="o-grid__cell--6/12"></div>
        )}
        <div className="o-grid__cell--3/12">
          <Button className="c-btn-right" color="secondary" onClick={handleCancel} type="button" label="Cancel" />
        </div>
        <div className="o-grid__cell--3/12">
          <Button
            className="c-btn-right"
            onClick={onAddressAddButtonClicked}
            type="button"
            color="primary"
            label="Save"
          />
        </div>
      </>
    )
  }

  if (!isSharedBeneficiaryAddress && isMobile) {
    formButtons = (
      <>
        <div className="flex justify-between pt-5">
          <Button className="w-2/5" color="secondary" onClick={handleCancel} type="button" label="Cancel" />
          <Button className="w-2/5" onClick={onAddressAddButtonClicked} color="primary" type="button" label="Save" />
        </div>
        {showDeleteButton && (
          <Button className="pt-4" color="transparent" onClick={handleDelete} type="button" label="Delete" />
        )}
      </>
    )
  }

  return (
    <div id="addressForm" className="o-block c-paper-form">
      <div className="o-block">
        {addressMismatch}
        {/* Only disabled if viewing sponsor's lived together address} */}
        {isSharedBeneficiaryAddress && (
          <div className="o-grid--fluid o-grid--stack@sm c-paper-form__group">
            <Button
              color="link-primary"
              onClick={handleEditInSpousesAddressBtnClick}
              type="button"
              label={`Edit this in ${sponsorPersonalization}'s address history`}
            />
          </div>
        )}
        {showCurrentAddressCheckbox && (
          <CommonCheckboxV2
            label={`${isSponsorPath ? sponsorPersonalization : beneficiaryPersonalization} currently lives here`}
            name="current_address"
            id="checkbox-for-current-address"
            checked={isSponsorPath ? isSponsorCurrentAddressChecked : isBeneficiaryCurrentAddressChecked}
            onChangeEvent={onCurrentAddressCheckboxChange}
            disabled={isSharedBeneficiaryAddress}
          />
        )}
        <DateInputV2
          value={formValues.start_date ? formValues.start_date : null}
          name="start_date"
          label="Move in date"
          onBlurEvent={onBlurStartDate}
          onChangeEvent={changeFormValue}
          isValid={startDateIsValid}
          validationMessage={startDateValidationMessage}
          disabled={isSharedBeneficiaryAddress}
        />
        {!isCurrentAddressChecked && (
          <DateInputV2
            value={formValues.end_date ? formValues.end_date : null}
            name="end_date"
            label="Move out date"
            onBlurEvent={onBlurEndDate}
            onChangeEvent={changeFormValue}
            isValid={endDateIsValid}
            validationMessage={endDateValidationMessage}
            disabled={isSharedBeneficiaryAddress}
          />
        )}
        {!hiddenCountryCodes.includes('US') && docWarning}
        <AddressV2
          address={formAddressValues}
          onChange={changeAddressValue}
          onPlacesUpdate={handlePlacesUpdate}
          disabled={isSharedBeneficiaryAddress}
          hiddenCountryCodes={hiddenCountryCodes}
        />

        {(isSponsorPath || (!!addressToEdit && livedTogether)) && (
          <CommonCheckboxV2
            label={`${sponsorPersonalization} lived at this address with ${beneficiaryPersonalization}  (for example, we rented an apartment or owned a home together)`}
            name="living-together"
            id="checkbox-for-living-together"
            checked={formValues.shared || false}
            onChangeEvent={onLivingTogetherCheckboxChange}
            disabled={isSharedBeneficiaryAddress}
          />
        )}
        {livedTogetherJit}

        {formValues.shared && (
          <>
            {showSpouseCurrentAddressCheckbox && (
              <CommonCheckboxV2
                label={`${isSponsorPath ? beneficiaryPersonalization : sponsorPersonalization} currently lives here`}
                name="spouse_current_address"
                id="checkbox-for-spouse-current-address"
                checked={isSponsorPath ? isBeneficiaryCurrentAddressChecked : isSponsorCurrentAddressChecked}
                onChangeEvent={onSpouseCurrentAddressCheckboxChange}
                disabled={isSharedBeneficiaryAddress}
              />
            )}
            <DateInputV2
              value={formValues.spouse_start_date ? formValues.spouse_start_date : null}
              name="spouse_start_date"
              label={`${isSponsorPath ? beneficiaryPersonalization : sponsorPersonalization}’s move in date`}
              onBlurEvent={onBlurSpouseStartDate}
              onChangeEvent={changeFormValue}
              isValid={spouseStartDateIsValid}
              validationMessage={spouseStartDateValidationMessage}
              disabled={isSharedBeneficiaryAddress}
            />
            {((isSponsorPath && !isBeneficiaryCurrentAddressChecked) ||
              (isBeneficiaryPath && !isSponsorCurrentAddressChecked)) && (
              <DateInputV2
                value={formValues.spouse_end_date ? formValues.spouse_end_date : null}
                name="spouse_end_date"
                label={`${isSponsorPath ? beneficiaryPersonalization : sponsorPersonalization}’s move out date`}
                onBlurEvent={onBlurSpouseEndDate}
                onChangeEvent={changeFormValue}
                isValid={spouseEndDateIsValid}
                validationMessage={spouseEndDateValidationMessage}
                disabled={isSharedBeneficiaryAddress}
              />
            )}
          </>
        )}

        <div className="o-grid--fluid o-grid--stack@sm o-block c-paper-form__group">{formButtons}</div>
        {isFormError && (
          <div className="c-alert c-alert--danger">
            <ul>
              {formErrorMessages.map((message) => (
                <li key={message}>{message}</li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  )
}

function mapStateToProps(state): InjectedProps {
  return {
    isMobile: isMobileBrowser(state),
    kaseId: getCurrentKaseId(state),
    sponsorFirstName: getSponsorFirstName(state),
    beneficiaryFirstName: getBeneficiaryFirstName(state),
    currentKaseKind: getCurrentKaseKind(state)
  }
}

function mapDispatchToActions(dispatch: Dispatch): MappedActions {
  return bindActionCreators(
    {
      addNewAddressHistory,
      updateAddressHistory,
      deleteAddressHistory
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToActions)(AddressForm) as FunctionComponent<Props>
