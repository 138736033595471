import React, { FunctionComponent, ReactNode } from 'react'
import cx from 'classnames'

import ModalNoHeader from 'components/modals/modal/modal_no_header'
import AlertCircleIcon from 'components/icons/alert_circle_icon'
import CheckmarkIcon from 'components/icons/checkmark_icon'
import UploadIcon from 'components/icons/upload_icon'
import Heading from 'components/type/heading'

interface ExplicitProps {
  children: ReactNode
  closeModal: () => void
  headerContent?: string
  showModal: boolean
  variant: 'info' | 'warning' | 'success'
}

const IconModal: FunctionComponent<ExplicitProps> = ({ children, closeModal, headerContent, showModal, variant }) => {
  const icon = () => {
    switch (variant) {
      case 'warning':
        return <AlertCircleIcon size="medium" />
      case 'success':
        return <CheckmarkIcon size="medium" />
      case 'info':
        return <UploadIcon size="medium" />
      default:
        return <CheckmarkIcon size="medium" />
    }
  }

  const modalClasses = cx('action-items__modal', {
    warning: variant === 'warning',
    success: variant === 'success',
    info: variant === 'info'
  })

  const iconClasses = cx('c-action-items__icon-wrapper', {
    warning: variant === 'warning',
    success: variant === 'success',
    info: variant === 'info'
  })

  return (
    <ModalNoHeader
      className={modalClasses}
      isOpen={showModal}
      onRequestClose={closeModal}
      body={
        <div className="o-box">
          <div className={iconClasses}>{icon()}</div>
          <Heading tag="h3" color="emphasized">
            {headerContent}
          </Heading>
          {children}
        </div>
      }
    />
  )
}

export default IconModal as FunctionComponent<ExplicitProps>
