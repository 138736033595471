import validators from 'lib/validations/validatorjs'

/**
 * QUESTIONNAIRE INPUT VALIDATORS
 *
 * A wrapper around the validator library that provides the test to check
 * validity as well as a message to display when invalid.
 *
 */
export interface InputValidatorType {
  test: (value: string, options?: any) => boolean
  message: string
}

const currency: InputValidatorType = {
  test: (value) => {
    return validators.isEmpty(value) || validators.isPositiveCurrency(value)
  },
  message: 'Must be valid currency.'
}

const currencyNegativeAllowed: InputValidatorType = {
  test: (value) => {
    return validators.isEmpty(value) || validators.isValidCurrency(value)
  },
  message: 'Must be valid currency.'
}

const date: InputValidatorType = {
  test: (value) => {
    return validators.isEmptyMaskedNumberInput(value) || validators.isDate(value, { format: 'MM/DD/YYYY' })
  },
  message: 'Must be a valid date in mm/dd/yyyy format.'
}

const maxLength: InputValidatorType = {
  test: (value, options?: { length: number }) => {
    if (!options?.length) {
      options.length = 40
    }
    return value.length <= options.length
  },
  message: `Must be ${length} characters or less.`
}

const numeric: InputValidatorType = {
  test: (value) => {
    return validators.isEmpty(value) || validators.isNumeric(value)
  },
  message: `Must be a number.`
}

const alienRegistrationNumber: InputValidatorType = {
  test: (value) => {
    return validators.isEmpty(value) || (validators.isNumeric(value) && validators.isLength(value, { min: 7, max: 9 }))
  },
  message: `Must be a valid 7-9 digit alien registration number.`
}

const classOfAdmission: InputValidatorType = {
  test: (value) => {
    return (
      validators.isEmpty(value) || (validators.isAlphanumeric(value) && validators.isLength(value, { min: 3, max: 3 }))
    )
  },
  message: `Must be a valid 3 character alphanumeric class of admission code.`
}

const pastDate: InputValidatorType = {
  test: (value) => {
    return validators.isEmptyMaskedNumberInput(value) || validators.isBefore(value, new Date().toDateString())
  },
  message: 'Must be before today’s date.'
}

const phone: InputValidatorType = {
  test: (value) => {
    return validators.isEmptyMaskedNumberInput(value) || validators.isMobilePhone(value)
  },
  message: 'Please enter a valid phone number.'
}

const email: InputValidatorType = {
  test: (value) => {
    return validators.isEmail(value)
  },
  message: 'Please enter a valid email address.'
}

const postalCode: InputValidatorType = {
  test: (value) => {
    return validators.isEmpty(value) || validators.isPostalCode(value, 'any')
  },
  message: 'Please enter a valid postal code.'
}

const required: InputValidatorType = {
  test: (value) => !validators.isEmpty(value),
  message: 'This field is required.'
}

const ssn: InputValidatorType = {
  test: (value) => {
    return validators.isEmptyMaskedNumberInput(value) || validators.isValidSSN(value)
  },
  message: 'Please enter a valid social security number.'
}

export const inputWarnings = {
  employer_name: {
    maxLength: 38,
    message:
      'If possible, please shorten the employer\'s name. It is okay to use abbreviations, like "Co" instead of "Company"'
  },
  job_title: {
    maxLength: 35,
    message:
      'If possible, please shorten this job title. It is okay to use abbreviations, like "Sr" instead of "Senior"'
  },
  name: {
    maxLength: 28,
    message:
      "Please double check that you are only entering part of the full name in this box. If this part of the name is long, it's okay to continue."
  },
  nature_of_organization: {
    maxLength: 38,
    message:
      'If possible, please shorten your answer. The government is asking for a basic description of what the organization is.'
  },
  street_address: {
    maxLength: 27,
    message:
      'If possible, please shorten this street address. It is okay to use abbreviations, like "St" instead of "Street"'
  },
  certification_entry: {
    maxLength: 106,
    message: 'If possible, please keep your description of the certification to less than 106 characters. A basic description is sufficient. '
  },
  benefit_entry_name: {
    maxLength: 44,
    message:
      'If possible, please keep your answer to less than 44 characters. It is okay to use abbreviations, like "TANF" for "Temporary Assistance for Needy Families." '
  },
  institution_entry_name: {
    maxLength: 20,
    message: 'If possible, please keep your answer to less than 20 characters. It is okay to use abbreviations, like "Hosp" for "Hospital". '
  },
  institution_entry_reason: {
    maxLength: 38,
    message: 'If possible, please keep your answer to less than 38 characters. A basic description is sufficient.'
  },
  limit_280_input: {
    maxLength: 280,
    message: 'If possible, please keep your answer to less than 280 characters. A basic description is sufficient.'
  }
}

export default {
  alienRegistrationNumber,
  classOfAdmission,
  currency,
  currencyNegativeAllowed,
  date,
  email,
  maxLength,
  numeric,
  pastDate,
  phone,
  postalCode,
  required,
  ssn
}
