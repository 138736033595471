import u from 'updeep'
import { LOADED_PROGRESS, LOADING_PROGRESS } from 'lib/constants'

export interface SectionProgress {
  name: string
  progress: number
}

export interface SectionProgressStore {
  sections: SectionProgress[]
  loadingSectionProgress?: boolean
}

const initialState: SectionProgressStore = {
  sections: [
    {
      name: 'address_and_employment',
      progress: 0.0
    }
  ]
}

export default function sectionProgressReducer(state: SectionProgressStore, action: any): SectionProgressStore {
  state = state || initialState
  switch (action.type) {
    case LOADING_PROGRESS: {
      return u(
        {
          loadingSectionProgress: true
        },
        state
      )
    }
    case LOADED_PROGRESS: {
      return u(
        {
          sections: action.payload.sections,
          loadingSectionProgress: false
        },
        state
      )
    }

    default: {
      return state
    }
  }
}
