import server from './server'
import { AxiosPromise } from 'axios'

interface ShowParams {
  kaseId: number
  formName: string
}

function show({ kaseId, formName }: ShowParams): AxiosPromise {
  return server.get(`/admin/kases/${kaseId}/kase_form_data/?form_name=${formName}`).then((response) => response.data)
}

export default { show }
