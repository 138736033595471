import React from 'react'

import Interstitial from 'components/panels/interstitial'

function NaturalizationSetupStart() {
  return (
    <Interstitial
      headline="Let&#39;s figure out if you are eligible to apply for citizenship."
      subtitle="Answer the next few questions to find out."
    />
  )
}

export default NaturalizationSetupStart
