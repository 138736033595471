import React, { useState, useEffect } from 'react'
import { isProductionStage } from 'lib/settings'
import Button from 'components/button'
import getTelemetryService from 'lib/telemetry'

const TelemetryLogger = () => {
  /**
   * We're relying on a window setting because we don't support different
   * environment variables per deployment stage (because we promote a staging
   * build to production instead of rebuilding when deploying).
   */
  if (isProductionStage()) {
    return null
  }
  const [isOpen, setIsOpen] = useState(false)
  const [events, setEvents] = useState([])
  const telemetryService = getTelemetryService()
  const recentQueue = telemetryService.reporter.recent

  useEffect(() => {
    if (isOpen) {
      recentQueue.subscribe(() => {
        setEvents(recentQueue.getEvents().map(({ name }) => name))
      })
    } else {
      recentQueue.unsubscribe()
    }
  }, [isOpen])

  return (
    <div
      style={{
        position: 'fixed',
        bottom: '3rem',
        left: '0.5rem',
        zIndex: 10000
      }}
    >
      <Button
        style={{
          background: '#000',
          width: '2rem',
          height: '2rem',
          padding: 0,
          opacity: 0.5
        }}
        label="📡"
        title="Telemetry"
        onClick={() => {
          setIsOpen(!isOpen)
        }}
      />
      {isOpen && (
        <div
          style={{
            position: 'fixed',
            bottom: '0.5rem',
            left: '6rem',
            zIndex: 10000,
            border: '1px solid gray',
            width: '300px',
            height: '300px',
            background: 'white',
            padding: '10px',
            overflowY: 'scroll'
          }}
          className={'o-block o-block--ample o-box o-box--ample c-type c-type--body-sans-md'}
        >
          {events.map((name, index) => (
            <div key={index}>
              <div>{name}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default TelemetryLogger
