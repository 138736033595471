import React, { FunctionComponent, ReactNode } from 'react'
import cx from 'classnames'

import { DocumentState } from 'lib/constants'
import { readableFileSize } from 'lib/filesize'
import DocumentIcon from 'components/icons/document_icon'
import Paragraph from 'components/type/paragraph'
import CheckmarkIcon from 'components/icons/checkmark_icon'
import { PartContent } from 'reducers/documents_v2'

interface UploadingDoc extends PartContent {
  progress: number
  rawFile: File
}

interface Props {
  partContent: PartContent | UploadingDoc
  handleFileNameChange?: (partContent: PartContent) => () => void
  inlineControls?: ReactNode
  isMobile?: boolean
  kaseId: number
  renderThumbnails: boolean
  selected?: boolean
  showDocumentStatus?: boolean
  showFileSize?: boolean
}

const ListedDocument: FunctionComponent<Props> = ({
  partContent,
  handleFileNameChange,
  inlineControls,
  isMobile = false,
  kaseId,
  renderThumbnails,
  selected,
  showDocumentStatus = false,
  showFileSize,
  children
}) => {
  function isInstanceOfUploadingDoc(object: any): object is UploadingDoc {
    return object.progress !== undefined && object.rawFile !== undefined
  }

  function getFilesize() {
    let size
    if (isInstanceOfUploadingDoc(partContent)) {
      size = partContent.rawFile.size
    } else {
      size = partContent.document?.file_size
    }

    return readableFileSize(size)
  }

  function renderDocumentUploadProgress() {
    return (
      isInstanceOfUploadingDoc(partContent) && (
        <div className="o-block o-block--compact c-progress-bar">
          <div className="c-progress-bar__filled" style={{ width: `${partContent.progress}%` }} />
        </div>
      )
    )
  }

  function renderDocumentStatus() {
    if (partContent.document?.state === DocumentState.Approved) {
      return (
        <Paragraph italic color="success">
          Accepted <CheckmarkIcon />
        </Paragraph>
      )
    }

    if (partContent.document?.state === DocumentState.NeedsReview) {
      return <Paragraph italic>Pending initial check</Paragraph>
    }

    return null
  }

  function shortenedFilename() {
    if (!partContent) return null

    const originalName = partContent.document?.file_name

    if (!originalName) return null

    const maxFilenameLength = 25

    if (originalName.length < maxFilenameLength) {
      return originalName
    }

    const prefix = originalName.substring(0, maxFilenameLength - 2)
    const suffix = originalName.substring(originalName.length - 3)

    return (
      <span>
        {prefix}&hellip;{suffix}
      </span>
    )
  }

  const onClicked = (event) => {
    event.preventDefault()

    const openUrl = `/api/v1/kases/${kaseId}/documents/${partContent.document?.id}/direct_url`

    // TODO: console will complain about this being called with fourth boolean parameter
    window.open(openUrl, '_blank')
  }

  function isImage() {
    const file_type = partContent.document?.file_type
    if (!file_type) {
      return false
    }
    return !!file_type.match(/^image/)
  }

  function renderThumbnail() {
    if (isImage()) {
      const imageDivStyle = {
        backgroundImage: `url(/api/v1/kases/${kaseId}/documents/${partContent.document?.id}/direct_url)`
      }
      const thumbnailClasses = cx('c-card-horizontal__thumbnail o-media__figure c-img c-img--bg-cover', {
        'c-card-horizontal__thumbnail--mobile': isMobile
      })

      return <a href="#" onClick={onClicked} className={thumbnailClasses} style={imageDivStyle} />
    } else {
      return (
        <a href="#" onClick={onClicked}>
          <DocumentIcon className="c-card-horizontal__thumbnail o-media__figure c-img" />
        </a>
      )
    }
  }

  const renderEditName = () => {
    if (!handleFileNameChange) return null

    return (
      <div className="o-block o-block--tight">
        <form onSubmit={handleFileNameChange(partContent)} className="o-media">
          <input type="text" defaultValue={partContent.document?.file_name} className="o-media__body" />
          <button className="c-btn o-media__figure" type="submit">
            Rename File
          </button>
        </form>
      </div>
    )
  }

  const containerClasses = cx('c-listed-document-request__document', {
    'c-listed-document-request__document--selected': selected,
    'js-admin-document--selected': selected,
    'c-custom-control': !renderThumbnails
  })

  const cardClasses = cx('o-media', 'c-card-horizontal', 'o-action-items__card', 'o-block o-block--compact', {
    'js-document-upload-complete': !isInstanceOfUploadingDoc(partContent),
    'c-listed-document-request__document--accepted': partContent.document?.state === DocumentState.Approved,
    'o-action-items__card--warning': partContent.document?.state === DocumentState.Rejected,
    'border-b border-t-0 border-gray-200 p-1 mb-0': isMobile,
    'border-none': isMobile && partContent.document?.state === DocumentState.Rejected
  })

  // Do not show dismissed documents to customer
  if (partContent.document?.state === DocumentState.Dismissed) return null

  return (
    <div className={containerClasses}>
      {renderEditName()}
      <div className={cardClasses} onClick={onClicked}>
        {renderThumbnails && renderThumbnail()}

        <div className="o-media__body o-box">
          <Paragraph spacing="sm">{shortenedFilename()}</Paragraph>
          {showFileSize && <Paragraph>{getFilesize()}</Paragraph>}
          {isInstanceOfUploadingDoc(partContent) && renderDocumentUploadProgress()}
          {showDocumentStatus && renderDocumentStatus()}
        </div>
        {inlineControls}
      </div>
      {children}
    </div>
  )
}

export default ListedDocument
