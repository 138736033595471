import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'
import { allIssuesAddressed, getCurrentOpenQaTask } from 'reducers/selectors'
import { Task } from 'reducers/tasks'

import Accordion from 'components/accordion'
import DocumentIcon from 'components/icons/document_icon'
import Paragraph from 'components/type/paragraph'
import SubmitTaskButton from 'components/screens/issues_v2/submit_task_button'
import NewMessagesHint from 'components/screens/issues/new_messages_hint'
interface Props {
  allIssuesAddressed: boolean
  currentOpenTask: Task
}

export const IssuesHeader: FunctionComponent<Props> = ({ allIssuesAddressed, currentOpenTask }) => {
  const showNewMessagesHint = currentOpenTask?.attributes.metadata?.number_of_returns > 0 && !allIssuesAddressed

  const text =
    'Please carefully read each question or to-do item, follow its instructions, and reply to each one. We won’t see your responses until you’ve replied to each item and submitted them to Boundless for review.'

  return (
    <>
      {showNewMessagesHint && <NewMessagesHint />}

      <Accordion
        className="o-block u-hide@md-up mb-2"
        header={
          <span className="c-type--bold c-type--body-sans-sm">
            <DocumentIcon className="mr-1" />
            Instructions
          </span>
        }
      >
        {text}
      </Accordion>

      <div className="u-hide@sm-down">
        <Paragraph color="emphasized" bold>
          Instructions
        </Paragraph>
        <Paragraph>{text}</Paragraph>
        <SubmitTaskButton />
      </div>
    </>
  )
}

function mapStateToProps(state) {
  return {
    allIssuesAddressed: allIssuesAddressed(state),
    currentOpenTask: getCurrentOpenQaTask(state)
  }
}

export default connect(mapStateToProps)(IssuesHeader)
