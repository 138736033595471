import React, { FunctionComponent } from 'react'

import EligibilityReportInfoCell from './eligibility_report_info_cell'

interface Props {
  screen: string
  boundlessFees: number
  boundlessProcessingTime: string
  govtFees: number
  govtProcessingTime: string
}

const EligibilityReportInfo: FunctionComponent<Props> = (props) => {
  const isMobile = props.screen === 'mobile'
  const monthlyAmount = Math.ceil(props.boundlessFees / 12)

  return (
    <React.Fragment>
      {isMobile ? (
        <div className="o-grid__cell">
          <div className="o-block o-block--spacious">
            <EligibilityReportInfoCell
              header="Boundless Price"
              content={`$${props.boundlessFees}`}
              subtitle={`Or as low as $${monthlyAmount}/mo for 12 mos`}
            />
          </div>
          <div className="o-block o-block--spacious">
            <EligibilityReportInfoCell
              header="Gov't Filing Fees"
              content={`~$${props.govtFees.toLocaleString('en-US')}`}
              hasLink
              linkHref="/content/cost-mbgc"
              linkText="How this is calculated"
            />
          </div>
          <div className="o-block o-block--spacious">
            <EligibilityReportInfoCell
              header="File With Boundless In"
              content={props.boundlessProcessingTime}
              subtitle="After we get your documents"
            />
          </div>
          <div className="o-block o-block--spacious">
            <EligibilityReportInfoCell
              header="Gov't Wait Time After Filing"
              content={props.govtProcessingTime}
              hasLink
              linkHref="/content/how-long-mbgc"
              linkText="How this is calculated"
            />
          </div>
        </div>
      ) : (
        <>
          <div className="o-grid--fluid o-block o-block--ample">
            <div className="o-grid__cell--3/12" />
            <div className="o-grid__cell--4/12">
              <EligibilityReportInfoCell
                header="Boundless Price"
                headerId="header-1-desktop"
                content={`$${props.boundlessFees}`}
                contentId="info-1-desktop"
                subtitle={`Or as low as ${monthlyAmount}/mo for 12 mos`}
              />
            </div>
            <div className="o-grid__cell--1/12" />
            <div className="o-grid__cell--4/12">
              <EligibilityReportInfoCell
                header="File With Boundless In"
                headerId="header-2-desktop"
                content={props.boundlessProcessingTime}
                contentId="info-2-desktop"
                subtitle="After we get your documents"
              />
            </div>
          </div>
          <div className="o-grid--fluid o-block o-block--ample">
            <div className="o-grid__cell--3/12" />
            <div className="o-grid__cell--4/12">
              <EligibilityReportInfoCell
                header="Gov't Fees Due At Filing"
                headerId="header-1-desktop"
                content={`~$${props.govtFees.toLocaleString('en-US')}`}
                contentId="info-3-desktop"
                hasLink
                linkHref="/content/cost-mbgc"
                linkText="How this is calculated"
              />
            </div>
            <div className="o-grid__cell--1/12" />
            <div className="o-grid__cell--4/12">
              <EligibilityReportInfoCell
                header="Wait Time After Filing"
                headerId="header-2-desktop"
                content={props.govtProcessingTime}
                contentId="info-4-desktop"
                hasLink
                linkHref="/content/how-long-mbgc"
                linkText="How this is calculated"
              />
            </div>
          </div>
        </>
      )}
    </React.Fragment>
  )
}

export default EligibilityReportInfo
