import React from 'react'
import { connect } from 'react-redux'

import { SECTION_NAME_SETUP, TELEMETRY_EVENTS } from 'lib/constants'
import { postTelemetry } from 'actions/telemetry_actions'

import { DashboardPhase, DASHBOARD_PHASE_UNKNOWN, DASHBOARD_PHASE_INTAKE } from './phase_map'
import TeamPanel from './team_panel'
import TimelinePreviewWrapper from './timeline_preview_wrapper'
import WorkArea from './work_area'
import ResourcesPanel from './resources_panel'
import DocumentUploadPanel from './document_upload_panel'
import WorkflowDebug from 'components/screens/dashboard/customer_dashboard/workflow_debug'
import PrePaymentDashboard from 'components/screens/dashboard/pre_payment_dashboard'

import {
  getCurrentDashboardPhaseForKase,
  getNavigableSectionsForCustomerDashboard,
  isScreenLargeWidth
} from 'reducers/selectors'
import PaymentSuccessModal from './modals/payment_success_modal'
import Hint from 'components/hint'
import Paragraph from 'components/type/paragraph'

interface ActionProps {
  trackViewedDashboard?: ({ currentPhase }: { currentPhase: DashboardPhase }) => void
}

type Props = ActionProps & {
  currentPhase: DashboardPhase
  sections: any[]
  isScreenLargeWidth?: boolean
}

interface State {
  showModal: boolean
}

class DashboardLayout extends React.Component<Props, State> {
  state = {
    showModal: window.location.search.indexOf('payment_success') !== -1
  }

  hideModal = () => {
    this.setState({ showModal: false })
  }

  componentDidMount() {
    const { currentPhase, trackViewedDashboard } = this.props

    trackViewedDashboard({ currentPhase })
  }

  render() {
    const { currentPhase, sections, isScreenLargeWidth } = this.props

    const setupSection = sections.find((section) => section.name === SECTION_NAME_SETUP)
    const setupSectionIncomplete = setupSection && setupSection.progress < 100

    if (!currentPhase) {
      // TODO skeleton UI?
      return <div>Loading...</div>
    }

    if (currentPhase === DASHBOARD_PHASE_UNKNOWN) {
      return <PrePaymentDashboard />
    }

    const shouldShowSetupIncompleteAlert = currentPhase === DASHBOARD_PHASE_INTAKE && setupSectionIncomplete

    return (
      <div style={{ width: '100%' }} className="o-layout--impose">
        <WorkflowDebug />
        <PaymentSuccessModal hideModal={this.hideModal} showModal={this.state.showModal} />
        <TimelinePreviewWrapper currentPhase={currentPhase} isDesktop={isScreenLargeWidth} />
        <div className="o-dashboard__container">
          <div className="o-griddle__row">
            <div className="o-griddle__col--12 o-griddle__col--lg-8">
              {currentPhase === 'ILA' && isScreenLargeWidth && (
                <Hint variant="warning" title="Next Steps">
                  <Paragraph>
                    While you wait for your independent attorney to assess your situation, you can start completing your
                    forms and uploading your documents.
                  </Paragraph>
                </Hint>
              )}
              {shouldShowSetupIncompleteAlert && isScreenLargeWidth && (
                <Hint variant="warning" title="We Updated Our App">
                  <Paragraph>
                    To better understand your situation, we added additional questions to Getting To Know You. Click
                    “Continue” to finish them.
                  </Paragraph>
                </Hint>
              )}
              <WorkArea currentPhase={currentPhase} />
            </div>
            <div className="o-griddle__col">
              <div className="o-griddle__row">
                <TeamPanel />
                <ResourcesPanel currentPhase={currentPhase} />
                <DocumentUploadPanel currentPhase={currentPhase} />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapDispatchToActions(dispatch: Function): ActionProps {
  return {
    trackViewedDashboard: (args) => dispatch(postTelemetry(TELEMETRY_EVENTS.VIEWED_DASHBOARD, args))
  }
}

function mapStateToProps(state: any): Props {
  return {
    currentPhase: getCurrentDashboardPhaseForKase(state),
    sections: getNavigableSectionsForCustomerDashboard(state),
    isScreenLargeWidth: isScreenLargeWidth(state)
  }
}

export default connect(mapStateToProps, mapDispatchToActions)(DashboardLayout)
