import _isFunction from 'lodash/isFunction'
import _last from 'lodash/last'
import _merge from 'lodash/merge'
import _noop from 'lodash/noop'

import CommonReporter from './common/reporter'
import { isPresent, isBlank } from 'lib/presence'

function runCallback(...args) {
  const callback = _last(args)

  if (_isFunction(callback)) {
    callback()
  }

  if (isBlank(callback)) {
    return
  }

  if (_isFunction(callback.event_callback)) {
    callback.event_callback()
  }
}

const NullAdwordsReporter = runCallback

const NullHeapReporter = {
  addUserProperties: _noop
}

const NullAnalyticsService = {
  identify: runCallback,
  page: _noop,
  reset: _noop,
  track: runCallback
}

const ADMIN_KASE_KIND_PLACEHOLDER = 'no-relevant-kase-kind-admin'

export default class TelemetryReporter extends CommonReporter {
  constructor({ reportingEnvironment, currentKaseKind, forceAdminTelemetry }) {
    super(reportingEnvironment)
    this.forceAdminTelemetry = forceAdminTelemetry
    this.currentKaseKind = forceAdminTelemetry ? ADMIN_KASE_KIND_PLACEHOLDER : currentKaseKind
  }

  _defaults() {
    return _merge(
      {
        applicationCategory: this.currentKaseKind
      },
      super._defaults()
    )
  }

  _analytics() {
    return this._enabled() && this._segmentLoaded() ? this._segmentService() : NullAnalyticsService
  }

  track(eventName, eventData = {}, callback = null) {
    const usingSegment = this._enabled() && this._segmentLoaded()
    if (!usingSegment) {
      // if not using segment, call superclass method for tracking
      super.track(eventName, eventData, callback)
      return
    }

    // extract event data
    const fullEventData = this._getFullEventData(eventData)

    // Define destination options for Segment. See more details in
    // the analytics.js API docs
    // https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#managing-data-flow-with-the-integrations-object
    // by default, we configure the track call to send to all destinations
    // we are connected to in Segment.
    const integrations = fullEventData._analyticsIntegrations || {
      All: true
    }
    delete fullEventData._analyticsIntegrations

    // debug hooks (same code as superclass)
    this._debug(eventName, fullEventData)
    this.recent.push(eventName, fullEventData)

    // make a track API call with the destination
    // options set.
    return this._analytics().track(eventName, fullEventData, { integrations }, callback)
  }

  _googleAdwords() {
    return this._enabled() && this._googleAdwordsLoaded() ? this._googleAdwordsReporter() : NullAdwordsReporter
  }

  _heap() {
    return this._enabled() && this._heapLoaded() ? this._heapReporter() : NullHeapReporter
  }

  _googleAdwordsLoaded() {
    return isPresent(this._googleAdwordsReporter())
  }

  _segmentLoaded() {
    const segment = this._segmentService()
    return isPresent(segment) && segment.loadedSuccessfully
  }

  _heapLoaded() {
    return isPresent(this._heapReporter())
  }
}
