import React, { Component, Fragment, FunctionComponent } from 'react'
import { connect } from 'react-redux'
import _reduce from 'lodash/reduce'
import _find from 'lodash/find'
import _filter from 'lodash/filter'
import classNames from 'classnames'

import getDocumentRequestLocaleService from 'services/document_request_locale_service'

import {
  bindIsDocumentRequestHidden,
  getCurrentKaseId,
  getVisibleCustomerDocumentRequests,
  isCurrentKasePaid
} from 'reducers/selectors'

import { toggleExpand, fetchDocumentRequests } from 'actions/document_request_actions'

import ListedDocumentRequestBasic from './listed_document_request_basic'
import ReadOnlyDocumentRequest from './read_only_document_request'
import ChevronIcon from 'components/icons/chevron_icon'
import Paragraph from 'components/type/paragraph'
import { DocumentRequestStatus, DocumentStatus } from 'lib/constants'
import DocumentStatusIcon, { DOCUMENT_ICON_STATUS } from './document_status_icon'
import { getDocRequestPriority } from 'components/screens/document_upload/utils'
import inflect from 'lib/utilities/inflect'
import Markdown from 'components/markdown'

interface ExplicitProps {
  hideAcceptedDocumentRequests?: boolean
}

interface PropsFromRedux {
  documentRequests: DocumentRequestModel[]
  expandedRequestIds: { [key: string]: number }
  isDocumentRequestHidden: (request: DocumentRequestModel) => boolean
  isPaidUser: boolean
  kaseId: number
}

interface Actions {
  toggleExpand: (docRequestId: string) => void
  fetchDocumentRequests: Function
}

type ComponentProps = ExplicitProps & Actions & PropsFromRedux

interface State {
  /**
   * A list of prioritized document request IDs
   */
  prioritizedDocumentRequestIds: string[]
}

class DocumentRequestList extends Component<ComponentProps, State> {
  constructor(props: ComponentProps) {
    super(props)

    this.state = { prioritizedDocumentRequestIds: [] }
  }

  toggleExpand = (docRequest: DocumentRequestModel) => () => {
    this.props.toggleExpand(docRequest.id)
  }

  getPrioritizedDocumentRequestIds = (documentRequests: DocumentRequestModel[]) => {
    let prioritizedDocumentRequests = documentRequests.sort((a, b) => {
      const diff = getDocRequestPriority(a) - getDocRequestPriority(b)
      return diff > 0 ? 1 : diff < 0 ? -1 : 0
    })

    if (this.props.hideAcceptedDocumentRequests) {
      prioritizedDocumentRequests = _filter(
        prioritizedDocumentRequests,
        (request) => request.status !== DocumentRequestStatus.Accepted
      )
    }

    return prioritizedDocumentRequests.map((docRequest) => docRequest.id)
  }

  componentDidMount() {
    this.props.fetchDocumentRequests({ kaseId: this.props.kaseId })
    this.setState({
      prioritizedDocumentRequestIds: this.getPrioritizedDocumentRequestIds(this.props.documentRequests)
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps: ComponentProps) {
    // Set the order of the document requests ONCE because we don't want to
    // reorder them on the fly.
    if (nextProps.documentRequests.length !== this.props.documentRequests.length) {
      this.setState({
        prioritizedDocumentRequestIds: this.getPrioritizedDocumentRequestIds(nextProps.documentRequests)
      })
    }
  }

  getDocumentRequestStatusText = (docRequest: DocumentRequestModel, numComments: number = 0) => {
    if (docRequest.documents.length === 0) {
      return (
        <Paragraph size="sm">
          <DocumentStatusIcon status={DOCUMENT_ICON_STATUS.NOT_STARTED} /> Not started
        </Paragraph>
      )
    }

    if (numComments >= 1) {
      return (
        <Paragraph size="sm" className="t-color--warning">
          <DocumentStatusIcon status={DOCUMENT_ICON_STATUS.HAS_COMMENTS} />{' '}
          {inflect(numComments, 'Comment', 'Comments')}
        </Paragraph>
      )
    }

    const numDocumentsInReview = docRequest.documents.filter((doc) => doc.status === DocumentStatus.NeedsReview).length

    switch (docRequest.status) {
      case DocumentRequestStatus.Accepted:
        return (
          <Paragraph size="sm" className="t-color--success">
            <DocumentStatusIcon status={DOCUMENT_ICON_STATUS.ACCEPTED} /> Accepted
          </Paragraph>
        )
      case DocumentRequestStatus.NeedsCustomerAction:
        return (
          <Paragraph size="sm" className="t-color--warning">
            <DocumentStatusIcon status={DOCUMENT_ICON_STATUS.HAS_COMMENTS} /> Needs attention
          </Paragraph>
        )
      case DocumentRequestStatus.NeedsReview:
        return (
          <Paragraph size="sm" className="t-color--primary">
            <DocumentStatusIcon status={DOCUMENT_ICON_STATUS.IN_PROGRESS} /> {numDocumentsInReview} pending initial
            check
          </Paragraph>
        )
      case DocumentRequestStatus.Dismissed:
        return (
          <Paragraph size="sm" className="t-color--warning">
            <DocumentStatusIcon status={DOCUMENT_ICON_STATUS.IN_PROGRESS} /> Dismissed
          </Paragraph>
        )
      default:
        return (
          <Paragraph size="sm">
            <DocumentStatusIcon /> Not started
          </Paragraph>
        )
    }
  }

  renderDocumentRequest = (docRequest: DocumentRequestModel) => {
    const requestContainerClasses = classNames('c-document-request__container', {
      'u-hidden-sm-up': this.props.isDocumentRequestHidden(docRequest)
    })

    const documentRequestLocaleService = getDocumentRequestLocaleService()

    const numComments = _reduce(
      docRequest.documents,
      (count, document) => {
        if (document.status !== DocumentRequestStatus.Dismissed && document.comment.body != null) {
          return count + 1
        }

        return count
      },
      0
    )

    const documentRequestHasWarning = !!docRequest.comment.body || numComments > 0

    return (
      <Fragment key={`doc-request-${docRequest.id}`}>
        <div className="o-block o-block--tight">
          <div className="c-document-request__header" onClick={this.toggleExpand(docRequest)}>
            <div className="o-box">
              <a
                href="#"
                className={classNames('c-type c-type--headline-sm o-block o-block--compact', {
                  't-color--warning': documentRequestHasWarning
                })}
                onClick={(event) => event.preventDefault()}
              >
                <Markdown source={docRequest.title || documentRequestLocaleService.t(docRequest, 'title', true)} />
              </a>

              {this.getDocumentRequestStatusText(docRequest, numComments)}
            </div>

            <ChevronIcon />
          </div>

          <div className={requestContainerClasses}>
            <ListedDocumentRequestBasic documentRequest={docRequest} />
          </div>
        </div>
        <hr className="o-block o-block--tight c-divider" />
      </Fragment>
    )
  }

  getPrioritizedDocumentRequests = () => {
    const { documentRequests } = this.props
    return this.state.prioritizedDocumentRequestIds.map((id) => _find(documentRequests, (x) => x.id === id))
  }

  render() {
    const { isPaidUser, documentRequests } = this.props

    if (isPaidUser) {
      const prioritizedDocRequests = this.getPrioritizedDocumentRequests()
      return prioritizedDocRequests.map(this.renderDocumentRequest)
    }

    return documentRequests.map((docRequest) => (
      <ReadOnlyDocumentRequest key={`doc-request-${docRequest.id}`} docRequest={docRequest} />
    ))
  }
}

function mapStateToProps(state: any): PropsFromRedux {
  return {
    documentRequests: getVisibleCustomerDocumentRequests(state),
    expandedRequestIds: state.documents.uploadModal.expandedRequestIds,
    isDocumentRequestHidden: bindIsDocumentRequestHidden(state),
    isPaidUser: isCurrentKasePaid(state),
    kaseId: getCurrentKaseId(state)
  }
}

function mapDispatchToActions(dispatch): Actions {
  return {
    toggleExpand: (...args) => dispatch(toggleExpand(...args)),
    fetchDocumentRequests: (...args) => dispatch(fetchDocumentRequests(...args))
  }
}

export default connect(mapStateToProps, mapDispatchToActions)(DocumentRequestList) as FunctionComponent<ExplicitProps>
