import React, { FunctionComponent, useRef } from 'react'

import BufferedFieldValue from 'components/forms/buffered_field_value'
import { InputProps } from 'components/forms/inputs'

interface Options {
  value: string | number
  label: string
}

interface Props extends InputProps {
  options: Options[]
}

const PaperFormSelectInput: FunctionComponent<Props> = (props) => {
  const { afterChangeEvents, className, disabled, id, name, onBlur, path, options } = props

  const inputRef = useRef<HTMLInputElement | HTMLSelectElement>()

  const renderSelectOptions = () => {
    return options.map((option, i) => {
      const { value, label } = option

      return (
        <option key={`${label}-${i}`} value={value}>
          {label}
        </option>
      )
    })
  }

  return (
    <BufferedFieldValue afterChangeEvents={afterChangeEvents} path={path}>
      {(value, onChange) => {
        const inputProps = {
          className,
          disabled,
          id,
          name,
          onBlur,
          onChange,
          ref: inputRef,
          value
        }
        return (
          <div className="o-block o-block--compact">
            <select {...inputProps} data-model-path={path}>
              {renderSelectOptions()}
            </select>
          </div>
        )
      }}
    </BufferedFieldValue>
  )
}

export default PaperFormSelectInput
