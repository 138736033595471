import { createSelector } from 'reselect'
import { getCurrentUser } from 'reducers/application/selectors'
import { Roles } from 'lib/constants'

export const hasLimitedQaAccess = createSelector(
  getCurrentUser,
  (user) => user && [Roles.TemporaryWorker, Roles.Dra].includes(user.role)
)

export const isCurrentUserTemporary = createSelector(
  getCurrentUser,
  (user) => user && user.role === Roles.TemporaryWorker
)

export const isCurrentUserAdmin = createSelector(getCurrentUser, (user) => user && user.admin)
