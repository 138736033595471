import React from 'react'

interface Props {
  className: string
  name: string
  onChange: (value: Nullable<string>) => void
  value: 'yes' | 'no' | null
}

export default class QualifierComponent extends React.Component<Props> {
  static defaultProps = {
    className: '',
    onChange: () => {},
    value: null
  }

  handleClick = (event: SyntheticMouseEvent<HTMLInputElement>) => {
    this.props.onChange(event.currentTarget.value)
    document.activeElement && document.activeElement.blur()
  }

  render() {
    const { name, value } = this.props

    const yesClasses = `o-grid__cell--6/12 c-btn ${value === 'yes' ? 'c-btn--primary' : 'c-btn--transparent'}`

    const noClasses = `o-grid__cell--6/12 c-btn ${value === 'no' ? 'c-btn--primary' : 'c-btn--transparent'}`

    return (
      <div className={`o-flag o-grid--fluid o-block c-paper-form__group ${this.props.className}`}>
        <button className={yesClasses} onClick={this.handleClick} name={name} type="button" value="yes">
          Yes
        </button>

        <button type="button" className={noClasses} name={name} value="no" onClick={this.handleClick}>
          No
        </button>
      </div>
    )
  }
}
