import u from 'updeep'
import { JOB_TITLE_OPTIONS, WORKFLOW_STATUSES, LOADING_DASHBOARD, LOADING_DASHBOARD_SUCCESS } from 'lib/constants'
import {
  ContentForStatus,
  DASHBOARD_CONTENT_FOR_STATUS_DATA,
  SHIPMENT
} from 'components/screens/dashboard/customer_dashboard/phase_config/phase_description'
import _isEmpty from 'lodash/isEmpty'
import { Pronouns, getPronounsForAdminMapping } from 'lib/utilities/pronouns'
import { KasePhaseData } from 'components/screens/dashboard/customer_dashboard/v2/types'

export interface TeamMemberInfo {
  id: string
  firstName: string
  fullName: string
  email?: string
  jobTitle: JOB_TITLE_OPTIONS
  profilePicturePath?: string
  pronouns: Pronouns
  braggingRights?: string
}

const formatFirstName = (fullName: string) => {
  // TODO this is a flawed way to get the first name.
  // Someone name "Anne Marie Wyatt" would not show the correct name.
  // We should split this out into 2 fields in the DB.
  if (typeof fullName !== 'string') {
    return ''
  }
  return fullName.split(' ')[0]
}

function getTeamMemberInfo(person: any, jobTitle: JOB_TITLE_OPTIONS): TeamMemberInfo {
  return {
    braggingRights: person.bragging_rights,
    email: person.email,
    firstName: formatFirstName(person.full_name),
    fullName: person.full_name,
    id: person.id,
    jobTitle,
    profilePicturePath: person.profile_picture_path,
    pronouns: getPronounsForAdminMapping(person.pronouns)
  }
}

export const getLawyerInfoFromKase = (kase) => {
  if (!kase || _isEmpty(kase.lawyer)) {
    return null
  }

  return getTeamMemberInfo(kase.lawyer, JOB_TITLE_OPTIONS.JOB_TITLE_LAWYER)
}

const jobTitleForSteward = (steward) => {
  // same as boundless_support_team_name in app/decorators/user_decorator.rb
  return steward.full_name === 'Boundless Support Team'
    ? JOB_TITLE_OPTIONS.JOB_TITLE_CUSTOMER_SUPPORT
    : JOB_TITLE_OPTIONS.JOB_TITLE_STEWARD
}

export const getStewardInfoFromKase = (kase) => {
  if (!kase || _isEmpty(kase.steward)) {
    return null
  }

  const jobTitle = jobTitleForSteward(kase.steward)

  return getTeamMemberInfo(kase.steward, jobTitle)
}

export function getContentForStatusData(status: keyof typeof WORKFLOW_STATUSES): ContentForStatus {
  // TODO: handle RFE Board Columns, for now default to Shipped if workflow status is null
  return DASHBOARD_CONTENT_FOR_STATUS_DATA[status] || SHIPMENT.SHIPPED
}

// -----------------------------------------
// -----------------------------------------
//
// NEW V2 DASHBOARD REDUCER
//
// -----------------------------------------
// -----------------------------------------
export interface DashboardStore {
  data: KasePhaseData
  isLoading: boolean
}

const initialState: DashboardStore = {
  data: null,
  isLoading: false
}

export default function dashboardReducer(state: DashboardStore, action: any): DashboardStore {
  state = state || initialState
  switch (action.type) {
    case LOADING_DASHBOARD: {
      return u(
        {
          isLoading: action.payload
        },
        state
      )
    }

    case LOADING_DASHBOARD_SUCCESS: {
      const data: KasePhaseData = action.payload.data.attributes
      data.currentPhase = data.phases.find((phase) => phase.active)
      return u(
        {
          data
        },
        state
      )
    }

    default: {
      return state
    }
  }
}
