import React, { ChangeEvent, FunctionComponent, useState } from 'react'
import cx from 'classnames'

import Button from 'components/button'
import CommonCheckboxV2 from 'components/forms/inputs/v2/address/common/common_checkbox_v2'
import ArrowIcon from 'components/icons/arrow_icon'
import Markdown from 'components/markdown'

import { MilestoneTask } from '../post_ship_dashboard/lib/types'
import ChevronIcon from 'components/icons/chevron_icon'

interface Props {
  /**
   * Boolean used for mobile styling
   */
  isMobile: boolean
  /**
   * Function that saves the milestone answer, posting it to the backend and updating the redux store
   */
  onSaveMilestoneAnswer: (answer: string, milestoneTaskId: number, milestoneTaskKey: string, answerId?: number) => void
  /**
   * The task data for this task component
   */
  task: MilestoneTask
}

const PostMilestoneTask: FunctionComponent<Props> = ({ isMobile, onSaveMilestoneAnswer, task }) => {
  const [isChecked, setIsChecked] = useState(task.answer?.value === 'true')
  const [showExpandedDetails, setShowExpandedDetails] = useState(false)

  const taskHasLink = task.link.text != null

  const checkboxLabel = (
    <div className="c-type">
      <h2
        className={cx('mb-4', {
          'c-type--headline-sm c-type--emphasized': !isChecked,
          'c-type--body-sans-md': isChecked
        })}
      >
        {task.prompt}
      </h2>
    </div>
  )

  const getButtonLabel = (taskHasLink: boolean) => {
    if (taskHasLink) {
      return task.link.text
    } else {
      return showExpandedDetails ? 'See Less' : 'See More'
    }
  }

  const getButtonIcon = (taskHasLink: boolean) => {
    if (taskHasLink) {
      return <ArrowIcon arrowDirection="right" />
    } else {
      return <ChevronIcon chevronDirection={showExpandedDetails ? 'up' : 'down'} />
    }
  }

  const onClickTaskButton = (taskHasLink: boolean) => {
    if (taskHasLink) {
      window.open(`${task.link.url}`, '_blank')
    } else {
      setShowExpandedDetails(!showExpandedDetails)
    }
  }

  const onClickCheckbox = (event: ChangeEvent<Element>) => {
    const target = event.target as HTMLInputElement

    if (target.checked) {
      setShowExpandedDetails(false)
    }

    const newIsCheckedValue = target.checked
    setIsChecked(newIsCheckedValue)
    onSaveMilestoneAnswer(newIsCheckedValue.toString(), task.milestone_task_id, task.key, task.answer?.id)
  }

  return (
    <div className="border-b border-gray-200 mb-4 pb-4">
      <div className={cx('flex justify-between', { 'flex-col': isMobile })}>
        <div className={cx({ 'pr-12': !isChecked })}>
          <CommonCheckboxV2
            checked={isChecked}
            classNames="m-0"
            label={checkboxLabel}
            name=""
            id={`${task.key}-checkbox`}
            onChangeEvent={onClickCheckbox}
          />
          {/* @ts-ignore */}
          {!isChecked && (
            <Markdown
              source={task.prompt_description}
              className="c-type--react-markdown c-type--milestone-markdown ml-6 mt-2"
              options={{ linkToNewTabs: true }}
            />
          )}
        </div>
        {!isChecked && (
          <Button
            className="mt-4"
            id={`${task.key}-button`}
            color="primary"
            onClick={() => onClickTaskButton(taskHasLink)}
            label={getButtonLabel(taskHasLink)}
            icon={getButtonIcon(taskHasLink)}
          />
        )}
      </div>
      {showExpandedDetails && (
        <Markdown
          source={task.expandable_details}
          className="c-type--react-markdown c-type--milestone-markdown bg-gray-100 p-4 mt-4 ml-8"
          options={{ linkToNewTabs: true }}
        />
      )}
    </div>
  )
}

export default PostMilestoneTask
