import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'
import Paragraph from 'components/type/paragraph'
import { isMostRecentMessageFromCustomer } from 'reducers/selectors'

interface MappedProps {
  isMostRecentMessageFromCustomer: boolean
}

export const MessageAlert: FunctionComponent<MappedProps> = ({ isMostRecentMessageFromCustomer }) => {
  if (isMostRecentMessageFromCustomer) {
    return (
      <div className="sticky top-0 c-alert c-alert--success py-6">
        <Paragraph spacing="none">
          <span className="c-type--bold c-type--inline">Reply saved:</span> You’ve replied to this item. Once you’ve
          saved your replies to <em>all</em> outstanding items, you can then submit them to Boundless.
        </Paragraph>
      </div>
    )
  } else {
    return (
      <div className="sticky top-0 c-alert c-alert--warning py-6">
        <Paragraph spacing="none">
          <span className="c-type--bold c-type--inline">Required:</span> Add a reply below — even if you just need to
          let us know that you can’t address this item. Once you’ve saved your replies to <em>all</em> outstanding
          items, you can then submit them to Boundless.
        </Paragraph>
      </div>
    )
  }
}

function mapStateToProps(state: any) {
  return {
    isMostRecentMessageFromCustomer: isMostRecentMessageFromCustomer(state)
  }
}

export default connect(mapStateToProps)(MessageAlert)
