import React from 'react'
import { connect } from 'react-redux'

import { getUnitConversionPreference } from 'reducers/selectors'
import { getCentimetersFromFtIn, getFeet, getInches } from './helpers'

import BufferedFieldValue from 'components/forms/buffered_field_value'

interface Props {
  className: string
  disabled: boolean
  id: string
  path: string
  onBlur: Function
  containerClassName: string
}

class MinorInput extends React.Component<Props> {
  static defaultProps = {
    className: '',
    disabled: false,
    onBlur: () => {},
    containerClassName: ''
  }

  private formatInputValue = (centimeters: Nullable<string>): string => {
    if (!centimeters) return ''

    return parseInt(getInches(centimeters), 10).toString()
  }

  private serializeServerValue = (inches: Nullable<string>, { previousValue }): number => {
    const previousFeet = getFeet(previousValue)
    const newInches = parseInt(inches || 0, 10)

    return getCentimetersFromFtIn(previousFeet, newInches)
  }

  public render() {
    const { className, disabled, id, path, onBlur, containerClassName } = this.props

    return (
      <BufferedFieldValue
        className={containerClassName}
        formatter={this.formatInputValue}
        path={path}
        serializer={this.serializeServerValue}
      >
        {(value, onChange) => (
          <input
            data-model-path={path}
            id={`${id}-minor`}
            pattern="\d*"
            inputMode="numeric"
            type="tel"
            name="minor"
            className={`c-paper-form__control ${className}`}
            required={true}
            autoComplete="off"
            placeholder="0"
            disabled={disabled}
            onBlur={onBlur}
            onChange={onChange}
            value={value}
          />
        )}
      </BufferedFieldValue>
    )
  }
}

function mapStateToProps(state) {
  return {
    units: getUnitConversionPreference(state)
  }
}

export default connect(mapStateToProps)(MinorInput)
