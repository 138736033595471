import { FocusEventHandler } from 'react'

import { AfterChangeFunction } from 'components/forms/buffered_field_value'
import { ModelDataSerializableValue } from 'reducers/model'
import { FieldIndexType } from 'components/forms/field/index'

// TODO remove `value`
export interface QuestionnaireInputContainerProps {
  after_change_event_list?: string[]
  document_type?: string
  disabled?: boolean
  id: string
  onChange: Function
  name: string
  path: string
  placeholder?: string
  panelIndex: FieldIndexType
  currentIndex: FieldIndexType
  parentIndex: FieldIndexType
  resourceKeys: string[]
  value?: any
}

export interface InputProps {
  afterChangeEvents: AfterChangeFunction<ModelDataSerializableValue>[]
  className: string
  disabled: boolean
  id: string
  name: string
  // TODO: this is actually the fireValidation method, need to rename to avoid
  // confusion
  onBlur: FocusEventHandler<HTMLInputElement | HTMLSelectElement>
  path: string
  placeholder: string
  reviewOnly?: boolean
  saveOnBlur?: boolean
}

export const defaultInputProps = {
  afterChangeEvents: [],
  className: '',
  disabled: false,
  name: '',
  onBlur: () => {},
  placeholder: '',
  reviewOnly: false
}

export interface QAToolSectionProps {
  role?: Role
  reviewOnly?: boolean
}

export type Role = 'sponsor' | 'beneficiary' | 'applicant'
