import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'
import { allIssuesAddressed, canSubmitTask, getIssuesProgress } from 'reducers/selectors'
import Paragraph from 'components/type/paragraph'
import UnassignTaskButton from './unassign_task_button'

interface MappedProps {
  allIssuesAddressed: boolean
  canSubmitTask: boolean
  percentCompleted: number
}

const MobileActionBar: FunctionComponent<MappedProps> = ({ allIssuesAddressed, canSubmitTask, percentCompleted }) => {
  const showSubmitButton = !allIssuesAddressed || canSubmitTask

  return (
    <div
      style={{
        backgroundColor: 'white',
        boxShadow: '0px -1px 4px rgba(0, 0, 0, 0.05)'
      }}
      className="u-hide@md-up o-layout--sticky flex items-center p-3 w-full"
    >
      {!showSubmitButton && (
        <a href="/timeline" className="c-btn c-btn--small c-btn--secondary">
          Back to Dashboard
        </a>
      )}
      {showSubmitButton && <UnassignTaskButton buttonSize="small" />}
      <Paragraph size="sm" className="flex-grow" spacing="none" centered>
        {percentCompleted}% Complete
      </Paragraph>
    </div>
  )
}

function mapStateToProps(state): MappedProps {
  return {
    allIssuesAddressed: allIssuesAddressed(state),
    canSubmitTask: canSubmitTask(state),
    percentCompleted: getIssuesProgress(state)
  }
}

export default connect(mapStateToProps)(MobileActionBar) as FunctionComponent
