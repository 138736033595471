import React, { FunctionComponent, useState } from 'react'
import { connect } from 'react-redux'

import { DocumentReviewKaseModel } from 'reducers/documents/index'

import { getDocumentReviewKase, hasUnresolvedComments } from 'reducers/selectors'

import { getCookie, setCookie } from 'lib/cookies'

import IconModal from 'components/modals/icon_modal'
import Paragraph from 'components/type/paragraph'
import Button from 'components/button'

interface MappedProps {
  documentReviewKase?: DocumentReviewKaseModel
  hasUnresolvedComments: boolean
}

const COMMENTS_COOKIE = 'commentModalSeenAfterReviewNumber'

export const CommentsPresentModal: FunctionComponent<MappedProps> = ({ documentReviewKase, hasUnresolvedComments }) => {
  if (!documentReviewKase) return null

  const commentsModalReviewNumberCookie = getCookie(COMMENTS_COOKIE)
  const completedReviewCount = documentReviewKase && documentReviewKase.completedReviewCount

  const parsedCookie = commentsModalReviewNumberCookie != null && parseInt(commentsModalReviewNumberCookie, 10)

  const modalHasBeenSeen = !isNaN(parsedCookie) && parsedCookie === completedReviewCount

  const shouldShowModal = hasUnresolvedComments && !modalHasBeenSeen

  const [showModal, setShowModal] = useState(shouldShowModal)

  const closeModal = () => {
    const cookieReviewCount = completedReviewCount ? completedReviewCount.toString() : 0

    setCookie({ name: COMMENTS_COOKIE, value: cookieReviewCount })
    setShowModal(false)
  }

  return (
    <IconModal
      showModal={showModal}
      closeModal={closeModal}
      headerContent="We added tasks for you to complete"
      variant="warning"
    >
      <Paragraph spacing="lg">
        This is typical for Boundless customers. Please complete the tasks by uploading additional or replacement
        documents the U.S. government requires.
      </Paragraph>
      <Button onClick={closeModal} label="Complete Tasks" color="primary" block />
    </IconModal>
  )
}

const mapStateToProps = (state: any): MappedProps => {
  return {
    documentReviewKase: getDocumentReviewKase(state),
    hasUnresolvedComments: hasUnresolvedComments(state)
  }
}

export default connect(mapStateToProps)(CommentsPresentModal)
