import server from 'lib/api/v1/server'
import { AxiosPromise } from 'axios'
import { formatKasesUrl, formatPostRequestBody, normalizeResponse } from 'lib/api/v1/helpers'
import { Task } from 'reducers/tasks'

const formatTasksUrl = (kaseId, path, params = null) => {
  const baseUrl = formatKasesUrl(kaseId, 'tasks')
  const fullPath = path ? `${baseUrl}/${path}/` : `${baseUrl}/`

  return params ? `${fullPath}?${params}` : fullPath
}

function index(request): AxiosPromise<Task[]> {
  const { kaseId } = request

  return server
    .get(formatTasksUrl(kaseId, ''))
    .then((response) => normalizeResponse(response))
    .catch((
      error // eslint-disable-next-line no-console
    ) => console.log('Error loading tasks', error))
}

function assign(request): AxiosPromise<Task> {
  const { kaseId, taskId, assigneeParams } = request
  const requestBody = formatPostRequestBody('tasks', assigneeParams)

  return server
    .patch(formatTasksUrl(kaseId, `${taskId}/assign`), requestBody)
    .then((response) => normalizeResponse(response))
    .catch((
      error // eslint-disable-next-line no-console
    ) => console.log('Error assigning task', error))
}

function resolve(request): AxiosPromise<Task> {
  const { kaseId, taskId } = request

  return server
    .patch(formatTasksUrl(kaseId, `${taskId}/resolve`))
    .then((response) => normalizeResponse(response))
    .catch((
      error // eslint-disable-next-line no-console
    ) => console.log('Error resolving task', error))
}

export default {
  index,
  assign,
  resolve
}
