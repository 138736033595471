import React, { ChangeEventHandler, FocusEventHandler, FunctionComponent } from 'react'
import CommonInputV2 from './common/common_input_v2'
import CommonLabelV2 from './common/common_label_v2'

interface Props {
  value?: string
  disabled?: boolean
  onChangeEvent: ChangeEventHandler
  onBlurEvent: FocusEventHandler
}

const defaultProps = {
  value: ''
}

const UnitNumberInputV2: FunctionComponent<Props> = ({ value, disabled, onChangeEvent, onBlurEvent }) => {
  return (
    <>
      <CommonLabelV2 label="Unit Number" labelFor="unit_number" classNameSize="o-grid__cell--6/12" />
      <CommonInputV2
        inputName="unit_number"
        value={value ? value : ''}
        onChangeEvent={onChangeEvent}
        onBlur={onBlurEvent}
        classNameSize="o-grid__cell--6/12"
        disabled={disabled}
      />
    </>
  )
}

UnitNumberInputV2.defaultProps = defaultProps

export default UnitNumberInputV2
