import React, { FunctionComponent } from 'react'
import cx from 'classnames'
import CheckmarkIcon from 'components/icons/checkmark_icon'
import LocationIcon from 'components/icons/location_icon'

import { DashboardPhase, getDashboardPhaseTitle } from './phase_map'

interface Props {
  phase: DashboardPhase
  isPastStep?: boolean
  isCurrentStep?: boolean
  isFutureStep?: boolean
  isPreviewedStep?: boolean
  onPhaseClicked?: (phase: DashboardPhase) => void
}

const TimelineStep: FunctionComponent<Props> = ({
  phase,
  isPastStep,
  isCurrentStep,
  isFutureStep,
  isPreviewedStep,
  onPhaseClicked
}) => {
  const classes = cx('c-timeline__step', {
    'c-timeline__step--past': isPastStep,
    'c-timeline__step--present': isCurrentStep,
    'c-timeline__step--future': isFutureStep,
    'c-timeline__step--preview': isPreviewedStep
  })

  const handlePhaseClicked = () => {
    onPhaseClicked(phase)
  }

  let buttonTitle = ''
  if (isFutureStep) {
    buttonTitle = 'Preview this future step'
  } else if (isCurrentStep) {
    buttonTitle = 'View the current step'
  }

  return (
    <button disabled={isPastStep} className={classes} onClick={handlePhaseClicked} title={buttonTitle} type="button">
      <div className="c-timeline__step-content">
        {isPastStep && <CheckmarkIcon className="mr-3" />}
        {isCurrentStep && <LocationIcon className="mr-3" />}
        <span className="c-timeline__step-name" style={{ textAlign: isFutureStep ? 'center' : 'left' }}>
          {getDashboardPhaseTitle(phase)}
        </span>
      </div>
    </button>
  )
}

export default TimelineStep
