import React from 'react'

import Interstitial from 'components/panels/interstitial'
import ExternalLink from 'components/external_link'

function PublicChargeStart() {
  return (
    <Interstitial
      headline="The public charge rule may seem intrusive, but we’re here to help."
      subtitle={
        <>
          The <ExternalLink href="https://www.boundless.com/public-charge-rule/">public charge rule</ExternalLink>{' '}
          requires a large amount of personal information and supporting documents — from both you and your family — in
          order to get your green card. You’ll be guided through each question to maximize your chance of success.
        </>
      }
    />
  )
}

export default PublicChargeStart
