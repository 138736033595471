import React from 'react'

import { QuestionnaireInputContainerProps } from '..'

import t from 'lib/update_transformations'

import QualifierComponent from 'components/forms/inputs/qualifier/component'
import { MappedProps } from './index'

type Props = QuestionnaireInputContainerProps & MappedProps

interface State {
  qualifierValue: null | 'yes' | 'no'
}

export default class SmartCollectionQualifier extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      qualifierValue: this.getQualifierValue()
    }
  }

  handleChange = (value: Nullable<string>) => {
    // Make sure we don't do anything destructive unless we get a definite value
    if (value !== 'yes' && value !== 'no') return

    // Prevent double click
    if (value === this.state.qualifierValue) return

    const { path } = this.props

    const complete = value === 'no'
    let newCollectionCount: number

    if (value === 'no') {
      newCollectionCount = this.props.currentIndex - 1
    } else {
      newCollectionCount = this.props.currentIndex
    }

    const collectionPath = `${path}.items`
    const completePath = `${path}.complete`

    this.setState(
      {
        qualifierValue: value
      },
      () => {
        this.props.saveData({
          path: collectionPath,
          operation: t.resizeCollection(newCollectionCount)
        })
        this.props.saveData({
          path: completePath,
          operation: t.replaceValue(complete)
        })
      }
    )
  }

  render() {
    const { name } = this.props

    return <QualifierComponent name={name} onChange={this.handleChange} value={this.state.qualifierValue} />
  }

  private getQualifierValue(): string {
    const { currentIndex } = this.props
    const { complete, items } = this.getSmartCollection()

    if (complete === null) return null

    if (currentIndex > items.length) {
      // We are on the very last panel
      return complete ? 'no' : null
    } else {
      return 'yes'
    }
  }

  private getSmartCollection() {
    return this.props.value
  }
}
