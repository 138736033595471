import Cookies from 'js-cookie'
import uuidv4 from 'uuid/v4'
import { cookieDomain } from '../../cookies'

const cookieName = '_boundless_tracking_id'

export function trackingId() {
  return Cookies.get(cookieName)
}

export function createTrackingId() {
  if (trackingId()) {
    return
  }

  Cookies.set(cookieName, uuidv4(), {
    expires: 365 * 100,
    path: '/',
    secure: process.env.NODE_ENV === 'production',
    domain: cookieDomain()
  })
}
