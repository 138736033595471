import React, { FunctionComponent } from 'react'
import cx from 'classnames'

import ChevronIcon from 'components/icons/chevron_icon'
import CollapsibleOutlineRequest from './collapsible_outline_request'

import { withCurlyQuotes } from 'lib/utilities/text_parsing'
import _startCase from 'lodash/startCase'
import { DocumentRequestModelV2 } from 'reducers/documents_v2'
import CheckmarkIcon from 'components/icons/checkmark_icon'

interface Props {
  /**
   * An array of document requests to render in this section.
   */
  documentRequests?: DocumentRequestModelV2[]
  /**
   * The title of this section.
   */
  title: string
  /**
   * The ID of the document request that is currently dispayed.
   */
  activeRequestId?: number
  /**
   * Callback that's triggered when a document request is clicked.
   */
  onRequestClicked: (setIndex: number, requestIndex: number) => void
  /**
   * Determines whether request has been submitted for review or not
   */
  slaIsSet: boolean
  /**
   * Determines whether the section is open.
   */
  isActiveDocumentSet: boolean
  /**
   * Callback that's triggered when a section is clicked.
   */
  onDocumentSetClicked: () => void
  /**
   * The index for the set for this outline section
   */
  setIndex: number
}

const CollapsibleOutlineSection: FunctionComponent<Props> = ({
  documentRequests,
  title,
  onRequestClicked,
  activeRequestId,
  slaIsSet,
  isActiveDocumentSet,
  onDocumentSetClicked,
  setIndex
}) => {
  const allRequests: DocumentRequestModelV2[] = []
  var requestsHaveDocumentsAndAnswers = false
  if (documentRequests != null) {
    allRequests.push(...documentRequests)
    const requestsHaveDocuments = documentRequests.every((docRequest) =>
      docRequest.parts.every((part) => part.optional || part.contents.length > 0)
    )
    const metaQuestionsAnswered = documentRequests.every((docRequest) =>
      docRequest.parts.every((part) =>
        part.contents.every((doc) => doc.meta_questions.every((question) => question.attributes.input_value))
      )
    )

    requestsHaveDocumentsAndAnswers = requestsHaveDocuments && metaQuestionsAnswered
  }

  // Keep track of the open/closed status of the <details>
  // It's a little weird but hey https://github.com/facebook/react/issues/15486
  const onToggle = (event) => {
    event.preventDefault()
    onDocumentSetClicked()
  }

  return (
    <details open={isActiveDocumentSet} className="o-action-items__details">
      <summary onClick={onToggle}>
        <div className="o-action-items__outline-category o-griddle__row o-griddle__row--no-gutters o-griddle__row--align-items-center">
          <div className="o-griddle__col flex items-center">
            <span
              className={cx('o-action-items__category-name flex', {
                'is-active': isActiveDocumentSet
              })}
            >
              {withCurlyQuotes(_startCase(title.split('_').join(' ')))}
              {requestsHaveDocumentsAndAnswers ? <CheckmarkIcon /> : ''}
            </span>
          </div>
          <div className="o-griddle__col--auto">
            <ChevronIcon size="small" chevronDirection={isActiveDocumentSet ? 'up' : 'down'} />
          </div>
        </div>
      </summary>

      {documentRequests && (
        <ul className="o-list-bare">
          {documentRequests.map((request, index) => (
            <CollapsibleOutlineRequest
              key={request.id}
              setIndex={setIndex}
              requestIndex={index}
              request={request}
              activeRequestId={activeRequestId}
              onRequestClicked={onRequestClicked}
              slaIsSet={slaIsSet}
            />
          ))}
        </ul>
      )}
    </details>
  )
}

export default CollapsibleOutlineSection
