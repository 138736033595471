import React, { FunctionComponent } from 'react'

import Error from 'components/error'
import SkeletonParagraph from 'components/skeleton/paragraph'
import SkeletonHeading from 'components/skeleton/heading'

interface Props {
  /**
   * This is an error thrown after there was an issue when we hit an endpoint
   */
  error?: string
}

const LoadingDashboard: FunctionComponent<Props> = ({ error }) => {
  return (
    <div className="w-full relative">
      <div className="bg-blue-500 text-white h-92 py-8">
        <div className="o-dashboard__container pt-4">
          <h1 className="c-type c-type--body-sans-md">Welcome Back</h1>
          <h2 className="c-type c-type--headline-md py-4">Your K-1 Progress</h2>
        </div>
      </div>
      <div className="o-dashboard__container -mt-52">
        <div className="bg-white">
          {error && <Error>{error}</Error>}
          <div>
            <div className="p-4">
              <SkeletonHeading />
              <SkeletonParagraph numLines={3} className="pt-8" />
              <SkeletonParagraph numLines={3} className="pt-8" />
              <SkeletonParagraph numLines={3} className="pt-8" />
              <SkeletonParagraph numLines={3} className="pt-8" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoadingDashboard
