import React, { FunctionComponent, AnchorHTMLAttributes } from 'react'
import getTelemetryService from 'lib/telemetry'

interface Props extends AnchorHTMLAttributes<HTMLAnchorElement> {
  trackLinkName?: string
}

const ExternalLink: FunctionComponent<Props> = ({ children, trackLinkName, ...otherProps }) => {
  const linkClicked = (event) => {
    if (!trackLinkName) return

    event.preventDefault()

    getTelemetryService().trackLink({
      linkName: trackLinkName,
      redirectTo: otherProps.href,
      newTab: true
    })
  }

  return (
    <a {...otherProps} target="_blank" rel="noopener noreferrer" onClick={linkClicked}>
      {children}
    </a>
  )
}

export default ExternalLink
