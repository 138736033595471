import axios from 'axios'

import { addDefaultConfigToRequest } from './default_config'
import { addAuthorizationToRequest } from './authorization'
import { addCsrfHeaderToRequest, refreshCsrfInDom } from './csrf'
import { handleSessionExpirationError } from './session_expiration'

import { incrementPendingRequests, decrementPendingRequests } from './pending_requests'

/**
 * Prevents authorization header from being added to all subsequent axios
 * requests.
 *
 * @example Can be used with addAuthHeader method below to
 * temporarily disable authorization for signIn API requests,
 * followed by re-enabling it once the request is complete.
 *
 * ejectAuthHeader()
 * api.signIn(...).then((response) => {
 *   //...save JWT token in local storage...
 *   addAuthHeader()
 * })
 */
export const ejectAuthHeader = () => {
  if (axios.authInterceptor !== null) {
    axios.interceptors.request.eject(axios.authInterceptor)
  }
}

/**
 * Adds authorization header to all subsequent axios requests.
 *
 */
export const addAuthHeader = () => {
  axios.authInterceptor = axios.interceptors.request.use(addAuthorizationToRequest)
}

axios.interceptors.request.use(addDefaultConfigToRequest)
addAuthHeader()

axios.interceptors.request.use(addCsrfHeaderToRequest)
axios.interceptors.request.use(incrementPendingRequests)

axios.interceptors.response.use(...decrementPendingRequests)
axios.interceptors.response.use(refreshCsrfInDom)
axios.interceptors.response.use((response) => response, handleSessionExpirationError)

export default axios
