import { buildMessage, Validator } from './'

const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const isEmailValid = (email: string) => emailRegex.test(email)

const validEmail: Validator = ({ getModelData, path }) => {
  const email = getModelData(path)

  if (!email) return []

  if (!isEmailValid(email)) {
    return [buildMessage('Please enter a valid email address.')]
  }

  return []
}

export default validEmail
