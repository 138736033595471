import { DocumentsStoreV2 } from '.'

export const getDocumentSets = (documents: DocumentsStoreV2) => {
  if (!documents) return []
  return documents.documentSets
}

export const getCurrentDocRequestIndex = (documents: DocumentsStoreV2) => documents.currentDocRequestIndex

export const getCurrentDocRequestId = (documents: DocumentsStoreV2) => documents.currentDocRequest.id

export const getNextDocRequestId = (documents: DocumentsStoreV2) => {
  if (documents.documentSets.length > 0) {
    const currentDocSetIndex = documents.currentDocSetIndex
    const currentDocRequestIndex = documents.currentDocRequestIndex
    const currentDocSet = documents.documentSets[documents.currentDocSetIndex]
    const currentDocRequests = documents.documentSets[currentDocSetIndex].document_requests
    const currentDocRequestsLength = currentDocRequests.length
    const docSetLength = documents.documentSets.length

    // If this is the last doc request, just return the current doc request's id
    if (currentDocSetIndex === docSetLength - 1 && currentDocRequestIndex === currentDocRequests.length - 1) {
      return documents.currentDocRequest.id
    }
    // Go to next set if we are on the last request of the current set
    else if (currentDocRequestIndex + 1 >= currentDocRequestsLength) {
      const nextDocSet = documents.documentSets[currentDocSetIndex + 1]

      return nextDocSet.document_requests[0].id
    }
    // Get next doc request id
    else {
      return currentDocSet.document_requests[currentDocRequestIndex + 1].id
    }
  } else {
    return 0
  }
}

export const getPrevDocRequestId = (documents: DocumentsStoreV2) => {
  if (documents.documentSets.length > 0) {
    const currentDocSetIndex = documents.currentDocSetIndex
    const currentDocRequestIndex = documents.currentDocRequestIndex
    const currentDocSet = documents.documentSets[documents.currentDocSetIndex]

    // If this is the first doc request, just return the current doc request's id
    if (currentDocSetIndex === 0 && currentDocRequestIndex === 0) {
      return documents.currentDocRequest.id
    }
    // Go to previous set if we are on the first request of the current set
    else if (currentDocRequestIndex - 1 < 0) {
      const previousDocSet = documents.documentSets[currentDocSetIndex - 1]
      const previousDocRequestsLength = previousDocSet.document_requests.length

      return previousDocSet.document_requests[previousDocRequestsLength - 1].id
    }
    // Get previous doc request id
    else {
      return currentDocSet.document_requests[currentDocRequestIndex - 1].id
    }
  } else {
    return 0
  }
}

export const getCurrentDocSetIndex = (documents: DocumentsStoreV2) => documents.currentDocSetIndex

export const getCurrentDocSetId = (documents: DocumentsStoreV2) =>
  documents.documentSets[documents.currentDocSetIndex].id

export const getCurrentDocRequest = (documents: DocumentsStoreV2) => documents.currentDocRequest

export const getDocumentSetsComplete = (documents: DocumentsStoreV2) => documents.documentSetsComplete
