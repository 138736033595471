import React, { Component } from 'react'
import { connect } from 'react-redux'
import _find from 'lodash/find'
import {
  getCurrentChapter,
  getCurrentKaseId,
  getCurrentContentSource,
  getCurrentSectionForOutline,
  getCurrentSectionProgress,
  getCurrentPanel,
  getCurrentKaseKind,
  getSectionProgress
} from 'reducers/selectors'
import { loadSectionProgress } from 'actions/section_progress_actions'
import classNames from 'classnames'
import _isEmpty from 'lodash/isEmpty'

import { SECTION_NAME_PETITION } from 'lib/constants'
import { joinI18nKeys } from 'lib/i18n'
import { showPanel } from 'actions/panel_actions'
import { isFeatureEnabled } from '../lib/feature_flags_deprecated'

import Experiments from 'components/_experiments'
import ProgressBar from 'components/progress_bar'
import SaveIndicator from 'components/save_indicator'
import panelLocaleService from 'services/panel_locale_service'

interface Props {
  contentSource?: string
  currentChapter?: any
  currentKaseKind: string
  currentKaseId: number
  currentPanel: any
  section: any
  percentDone: number
  showPanel: () => void
  addEmpSectionProgress: any
  loadSectionProgress: Function
}

class Outline extends Component<Props> {
  private clickablePanelLimitReached: boolean
  private clickableChapterLimitReached: boolean

  componentDidMount() {
    const kase = this.props.currentKaseId
    this.props.loadSectionProgress({ kase })
  }

  remainingTimeString() {
    if (this.isSlim()) return

    let minsRemaining = (this.props.section.time_estimation_mins * (100 - this.props.percentDone)) / 100

    let timeRemaining = ''

    if (minsRemaining === 0) return timeRemaining

    if (Math.round(minsRemaining) <= 1) {
      timeRemaining = `1 min effort left`
    } else {
      timeRemaining = `${Math.round(minsRemaining)} mins effort left`
    }

    return <div className="o-block o-block--tight c-type c-type--body-sans-sm">{timeRemaining}</div>
  }

  isPanelSelected(panel) {
    return this.props.currentPanel.slug === panel.slug
  }

  isPaymentFunnelPanelSelected(panelSlug) {
    return this.props.currentPanel.slug === panelSlug
  }

  isMarriageBasedGreenCardApplication() {
    return this.props.currentKaseKind === 'MarriageBasedGreenCard'
  }

  isNewMarriageBasedGreenCardApplication() {
    return this.props.currentKaseKind === 'NewMarriageBasedGreenCard'
  }

  isSlim() {
    return this.props.section.slim_outline
  }

  firstChapter() {
    return this.props.section.chapters[0]
  }

  isExperimentalOutlineEnabled(experimentName) {
    return isFeatureEnabled(experimentName)
  }

  getUniqueKey(panel) {
    // append parentIndex if exists to make key unique
    const { name, parentIndex } = panel
    const suffix = parentIndex ? parentIndex : null

    return suffix ? `${name}_${suffix}` : name
  }

  renderPanel(chapter, panel) {
    const isPanelSelected = this.isPanelSelected(panel)

    if (!panel.name) {
      /* eslint-disable no-console */
      console.error('panel missing name', panel)
      /* eslint-enable no-console */
    }

    const hasData = panel.hasData
    const isComplete = panel.complete

    const isClickable = !this.props.section.linear || isPanelSelected || !this.clickablePanelLimitReached

    // If a panel is incomplete,
    // all the panels coming after
    // should not be clickable
    this.clickablePanelLimitReached = this.clickablePanelLimitReached || !isComplete

    const className = classNames('panel', 'o-block', 'o-block--tight', 'c-type', 'c-type--subhead-sm', {
      selected: isPanelSelected,
      clickable: isClickable,
      disabled: !isClickable,
      complete: isComplete,
      incomplete: hasData && !isComplete
    })

    function handleClick() {
      if (!isClickable) {
        return false
      }

      this.props.showPanel({ panel, via: 'OutlineNav' })
    }

    const keys = joinI18nKeys(panel.panel_keys, 'title')

    const panelTitle = panelLocaleService.t(keys, {
      index: panel.panel_index,
      parentIndex: panel.parentIndex
    })

    const panelKey = this.getUniqueKey(panel)

    return (
      <li id={panelKey} key={panelKey} className={className} onClick={handleClick.bind(this)}>
        {panelTitle}

        {isClickable && hasData && !isComplete && <span className="asterix">*</span>}

        {hasData && isComplete && <div className="checkmark" />}
      </li>
    )
  }

  isChapterSelected(chapter) {
    if (!this.props.currentChapter) {
      return false
    }

    return this.props.currentChapter.name === chapter.name
  }

  renderChapterTitle(chapter) {
    const { contentSource } = this.props

    if (chapter.name === 'eligibility') {
      switch (contentSource) {
        case 'income':
          return 'Cost Calculator'
        case 'timeline':
          return 'Wait Time Calculator'
        default:
          return 'Check Your Eligibility'
      }
    }

    return panelLocaleService.t(`${chapter.chapter_key}.title`)
  }

  renderNestedChapter(chapter, i) {
    if (_isEmpty(chapter.panels)) {
      return null
    }

    const isSelected = this.isChapterSelected(chapter)

    const hasSomeData = chapter.hasSomeData
    const isComplete = chapter.complete

    let isClickable = !this.props.section.linear || isSelected || i === 0 || !this.clickableChapterLimitReached

    // If a chapter does not have all data, all chapters coming after that
    // should not be clickable
    this.clickableChapterLimitReached = this.clickableChapterLimitReached || !isComplete

    const className = classNames('chapter', {
      selected: isSelected,
      'o-block o-block--tight': isSelected,
      clickable: isClickable,
      disabled: !isClickable,
      complete: !chapter.static && isComplete,
      incomplete: !chapter.static && hasSomeData && !isComplete
    })

    function handleClick() {
      if (!isClickable) {
        return false
      }

      this.props.showPanel({
        panel: chapter.panels[0],
        via: 'OutlineNav'
      })
    }

    return (
      <React.Fragment>
        <div id={chapter.name} className={className} onClick={handleClick.bind(this)}>
          <h4 className="c-type c-type--subhead-sm c-type--inline">
            {/* FLOW this is the sidebar clickable chapters */}
            {this.renderChapterTitle(chapter)}
          </h4>

          {isClickable && hasSomeData && !isComplete && <span className="asterix">*</span>}

          {hasSomeData && isComplete && <div className="checkmark" />}
        </div>

        {isSelected && (
          <ul className="o-list-bare panel-list">
            {chapter.panels.map((panel) => {
              if (!panel.navigable) return null
              // FLOW This renders the Panel if its selected
              return this.renderPanel(chapter, panel)
            })}
          </ul>
        )}
      </React.Fragment>
    )
  }

  renderChapters() {
    return this.props.section.chapters.map((chapter, i) => {
      if (chapter.new) {
        return <li key={chapter.name}>{this.renderNewComponent(chapter, i)}</li>
      } else if (!chapter.flat) {
        return <li key={chapter.name}>{this.renderNestedChapter(chapter, i)}</li>
      } else {
        return <li key={chapter.name}>{this.renderFlatChapter(chapter, i)}</li>
      }
    })
  }

  renderProgress() {
    if (this.props.section.name === 'address_and_employment_history') {
      const progress = _find(this.props.addEmpSectionProgress.sections, ['name', 'address_and_employment'])
      return (
        <li className="o-block o-block--compact">
          <div className="o-block o-block--tight c-type c-type--body-sans-sm">{progress.progress}% done</div>

          {this.remainingTimeString()}

          <SaveIndicator />
        </li>
      )
    }

    return (
      <li className="o-block o-block--compact">
        <div className="o-block o-block--tight c-type c-type--body-sans-sm">{this.props.percentDone}% done</div>

        {this.remainingTimeString()}

        <SaveIndicator />
      </li>
    )
  }

  renderFlatChapter(chapter) {
    if (this.props.section.linear || chapter.panels == null) return null

    return (
      <ul className="o-list-bare panel-list u-hide@sm-down chapter--flat">
        <li className="o-block o-block--compact">
          <hr className="c-divider c-divider--important c-divider--primary c-divider--broad" />
        </li>

        {chapter.panels.map((panel) => {
          if (!panel.navigable) return null

          return this.renderPanel(chapter, panel)
        })}
      </ul>
    )
  }

  renderNewComponent(chapter) {
    const isSelected = this.isChapterSelected(chapter)

    const hasSomeData = chapter.hasSomeData
    const isComplete = chapter.completed
    const isClickable = true

    const className = classNames('chapter', {
      selected: isSelected,
      'o-block o-block--tight': isSelected,
      clickable: isClickable,
      disabled: !isClickable,
      complete: !chapter.static && isComplete,
      incomplete: !chapter.static && hasSomeData && !isComplete
    })

    function handleClick() {
      if (!isClickable) {
        return false
      }

      this.props.showPanel({
        panel: chapter.panels[0],
        via: 'OutlineNav'
      })
    }

    return (
      <React.Fragment>
        <div id={chapter.name} className={className} onClick={handleClick.bind(this)}>
          <h4 className="c-type c-type--subhead-sm c-type--inline">{this.renderChapterTitle(chapter)}</h4>
        </div>
      </React.Fragment>
    )
  }

  isHidden() {
    return Boolean(this.props.currentPanel && this.props.currentPanel.emphasized)
  }

  renderSlimOutline() {
    return (
      <div className="o-grid__col-2 u-hide@sm-down">
        <ProgressBar.Horizontal className="o-block o-block--ample u-hide@md-up" fillStyles={['slim', 'secondary']} />

        <section className="o-box o-box--spacious">
          <hr className="o-block c-divider c-divider--narrow c-divider--secondary c-divider--emphasized" />
          <h1 id="eligibility" className="c-type c-type--headline-sm c-type--emphasized">
            {this.renderChapterTitle(this.firstChapter())}
          </h1>
        </section>
        {this.renderProgress()}
      </div>
    )
  }

  renderCompleteOutline() {
    if (this.props.section.name === SECTION_NAME_PETITION && this.props.currentKaseKind === 'MarriageBasedGreenCard') {
      const petitionOutlineEnabled = this.isExperimentalOutlineEnabled('petition_outline_experiment')

      if (petitionOutlineEnabled) return <Experiments.PetitionOutline />
    }

    return (
      <div className="o-grid__col-2 u-hide@sm-down">
        <ProgressBar.Horizontal className="o-block o-block--ample u-hide@md-up" fillStyles={['slim', 'secondary']} />

        <ul className="outline outline-list o-block o-list-bare u-hide@sm-down">
          {this.renderProgress()}
          {this.renderChapters()}
        </ul>
      </div>
    )
  }

  render() {
    this.clickablePanelLimitReached = false
    this.clickableChapterLimitReached = false

    if (this.isHidden()) {
      return <div className="o-grid__col-2 u-hide@sm-down" />
    }

    return this.isSlim() ? this.renderSlimOutline() : this.renderCompleteOutline()
  }
}

function mapStateToProps(state) {
  return {
    currentChapter: getCurrentChapter(state),
    currentKaseId: getCurrentKaseId(state),
    contentSource: getCurrentContentSource(state),
    currentKaseKind: getCurrentKaseKind(state),
    currentPanel: getCurrentPanel(state),
    section: getCurrentSectionForOutline(state),
    percentDone: getCurrentSectionProgress(state),
    addEmpSectionProgress: getSectionProgress(state)
  }
}

function mapDispatchToActions(dispatch) {
  return {
    showPanel: (...args) => dispatch(showPanel(...args)),
    loadSectionProgress
  }
}

export default connect(mapStateToProps, mapDispatchToActions)(Outline)
