import React, { FunctionComponent, MouseEvent } from 'react'

import DocumentRequestPart from 'components/screens/document_upload_v2/document_request_part'
import Button from 'components/button'
import HelpIcon from 'components/icons/help_icon'

import Markdown from 'components/markdown'
import FirstRequestCompleteModal from 'components/screens/document_upload_v2/modals/first_request_complete_modal'
import CommentsPresentModal from 'components/screens/document_upload_v2/modals/comments_present_modal'
import IncompleteQuestionnairesModal from 'components/screens/document_upload_v2/modals/incomplete_questionnaires_modal'
import { DocumentRequestModelV2 } from 'reducers/documents_v2'

interface Props {
  documentRequest: DocumentRequestModelV2
  showHelpText?: boolean
  toggleTipsForSuccess?: (event: MouseEvent) => void
}

type ComponentProps = Props

const ListedDocumentRequest: FunctionComponent<ComponentProps> = ({
  documentRequest,
  showHelpText = true,
  toggleTipsForSuccess
}) => {
  const documentInstructions = (
    <Markdown
      className="o-block o-block--compact doc-upload-section c-type--react-markdown"
      source={documentRequest.description}
      options={{ linkToNewTabs: true }}
    />
  )

  return (
    <div className="o-block">
      <FirstRequestCompleteModal />
      <CommentsPresentModal />
      {window.isUserCompletionEnabled() && <IncompleteQuestionnairesModal />}
      {showHelpText && (
        <div className="o-block o-block--ample c-type c-type--body-sans-md">
          {documentInstructions}
          <Button
            color="link-primary"
            className="u-hide@sm-down c-type--body-sans-sm"
            onClick={toggleTipsForSuccess}
            label={
              <>
                <HelpIcon size="small" />
                <span>Tips For Success</span>
              </>
            }
          />
        </div>
      )}
      {documentRequest.parts.map((documentRequestPart) => (
        <DocumentRequestPart
          key={documentRequestPart.id}
          documentRequest={documentRequest}
          documentRequestPart={documentRequestPart}
        />
      ))}
    </div>
  )
}

export default ListedDocumentRequest
