import React from 'react'
import classNames from 'classnames'
import { connect } from 'react-redux'

import { openChat } from 'actions/chat_actions'
import { openModal } from '../../../actions/modal_actions'
import { isUserLoggedIn } from 'reducers/selectors'
import EligibilityReport from 'components/panels/outcomes/setup_completed_eligibility_report'

interface Props {
  openChat: openChat
  openModal: openModal
  userLoggedIn: boolean
}

class SetupCompleted extends React.Component<Props> {
  renderContent(screen) {
    const mediaClass = classNames({
      'u-hide@sm-down': screen === 'desktop',
      'u-hide@md-up': screen === 'mobile'
    })

    if (screen === 'desktop') {
      return (
        <React.Fragment>
          <div className={mediaClass + ' o-grid__col-8 c-context-panel__copy c-context-panel__copy--important'}>
            <EligibilityReport screen={screen} />
          </div>
        </React.Fragment>
      )
    } else {
      return (
        <React.Fragment>
          <div className={mediaClass + ' o-grid__col-8 c-context-panel__copy'}>
            <EligibilityReport screen={screen} />
          </div>
        </React.Fragment>
      )
    }
  }

  render() {
    return (
      <div className="c-context-panel">
        <div className="o-grid__row c-context-panel__content">
          {this.renderContent('desktop')}
          {this.renderContent('mobile')}
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    userLoggedIn: isUserLoggedIn(state)
  }
}

function mapDispatchToActions(dispatch) {
  return {
    openChat: (...args) => dispatch(openChat(...args)),
    openModal: (...args) => dispatch(openModal(...args))
  }
}

export default connect(mapStateToProps, mapDispatchToActions)(SetupCompleted)
