import { INITIALIZE_STORE } from 'lib/constants'
import { setErrorContext } from 'lib/honeybadger'

export default () => (next) => (action) => {
  next(action)

  if (action.type === INITIALIZE_STORE) {
    const { currentUser, kase } = action.storeData

    const context = {}

    if (currentUser) {
      context.user_id = currentUser.id
      context.user_email = currentUser.email
    }

    if (kase) {
      context.kase_id = kase.id
    }

    setErrorContext(context)
  }
}
