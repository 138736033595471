import React, { FunctionComponent } from 'react'
import ExternalLink from 'components/external_link'
import Linkify from 'react-linkify'

interface Props {
  children?: string
}

const LinkifyText: FunctionComponent<Props> = ({ children }) => {
  return (
    <Linkify
      componentDecorator={(decoratedHref, decoratedText, key) => (
        <ExternalLink href={decoratedHref} key={key}>
          {decoratedText}
        </ExternalLink>
      )}
    >
      {children}
    </Linkify>
  )
}

export default LinkifyText
