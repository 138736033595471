import React, { FunctionComponent } from 'react'

import Button from 'components/button'
import ArrowIcon from 'components/icons/arrow_icon'
import UpdateRequestStatusButton from './update_request_status_button'
import FirstRequestCompleteModal from 'components/screens/document_upload/modals/first_request_complete_modal'
import CommentsPresentModal from 'components/screens/document_upload/modals/comments_present_modal'

interface Props {
  currentDocRequestIndex?: number
  documentRequests: DocumentRequestModel[]
  showPreviousDocRequest: () => void
  showNextDocRequest: () => void
  showOutlineModal: () => void
}

const MobileDocNavigation: FunctionComponent<Props> = ({
  currentDocRequestIndex,
  showPreviousDocRequest,
  showNextDocRequest,
  showOutlineModal,
  documentRequests
}) => {
  return (
    <>
      <FirstRequestCompleteModal />
      <CommentsPresentModal />
      {/* Sticky bottom */}
      <div
        style={{
          backgroundColor: 'white',
          boxShadow: '0px -1px 4px rgba(0, 0, 0, 0.05)'
        }}
        className="u-hide@md-up o-layout--sticky flex p-3 w-full"
      >
        <Button
          color="secondary"
          icon={<ArrowIcon arrowDirection="left" />}
          disabled={currentDocRequestIndex === 0}
          iconOnly
          onClick={showPreviousDocRequest}
          title="View the previous document"
        />
        <UpdateRequestStatusButton
          documentRequest={documentRequests[currentDocRequestIndex]}
          className="mx-3 flex-grow"
        />
        <Button
          color="secondary"
          disabled={currentDocRequestIndex >= documentRequests.length - 1}
          icon={<ArrowIcon arrowDirection="right" />}
          iconOnly
          onClick={showNextDocRequest}
          title="View the next document request"
        />
      </div>
      <Button
        className="mx-auto u-hide@md-up"
        block
        color="link-primary"
        label="View all documents"
        onClick={showOutlineModal}
        style={{ textAlign: 'center' }}
      />
    </>
  )
}

export default MobileDocNavigation
