import { DashboardPhase } from 'components/screens/dashboard/customer_dashboard/phase_map'

interface ResourceInfo {
  title: string
  urlPath: string
}

export interface DashboardPhaseData {
  title: string
  resources?: ResourceInfo[]
}

export const DASHBOARD_PHASES_DATA: {
  [key in DashboardPhase]: DashboardPhaseData
} = {
  POST_SHIPMENT: null,
  UNKNOWN: null,
  PRE_PAYMENT: {
    title: 'Get to Know Boundless',
    resources: []
  },
  INTAKE: {
    title: 'Getting to Know You',
    resources: [
      {
        title: 'Questions to expect in a green card application',
        urlPath: '/immigration-resources/questions-to-expect-in-a-green-card-application/'
      }
    ]
  },
  ILA: {
    title: 'Attorney Assessment',
    resources: [
      {
        title: 'How to create flawless digital documents',
        urlPath: '/immigration-resources/creating-flawless-digital-documents/'
      },
      {
        title: 'Providing evidence of relationship',
        urlPath: '/immigration-resources/how-do-we-prove-our-marriage-is-real/'
      },
      {
        title: 'How to translate non-English immigration documents',
        urlPath: '/immigration-resources/translating-immigration-documents-english/'
      },
      {
        title: 'Help with hard-to-find documents',
        urlPath: '/immigration-resources/top-10-hard-to-find-immigration-documents-and-how-to-get-them/'
      }
    ]
  },
  DOCUMENT_UPLOAD: {
    title: 'Forms and Documents',
    resources: [
      {
        title: 'How to create flawless digital documents',
        urlPath: '/immigration-resources/creating-flawless-digital-documents/'
      },
      {
        title: 'Providing evidence of relationship',
        urlPath: '/immigration-resources/how-do-we-prove-our-marriage-is-real/'
      },
      {
        title: 'How to translate non-English immigration documents',
        urlPath: '/immigration-resources/translating-immigration-documents-english/'
      },
      {
        title: 'Help with hard-to-find documents',
        urlPath: '/immigration-resources/top-10-hard-to-find-immigration-documents-and-how-to-get-them/'
      }
    ]
  },
  DOCUMENT_REVIEW: {
    title: 'Quality Assurance'
  },
  LEGAL_REVIEW: {
    title: 'Legal Review'
  },
  CUSTOMER_REVIEW: {
    title: 'Customer Review'
  },
  SHIPMENT: {
    title: 'Finalize and Ship',
    resources: [
      {
        title: 'Checking your Green Card application status',
        urlPath: '/immigration-resources/how-to-check-green-card-status/'
      },
      {
        title: 'Preparing for the Green Card interview',
        urlPath: '/immigration-resources/preparing-for-the-marriage-green-card-interview/'
      },
      {
        title: 'What to expect at your green card interview',
        urlPath: '/immigration-resources/marriage-green-card-interview-explained/'
      }
    ]
  }
}
