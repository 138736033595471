import { LOCATION_CHANGED, push } from 'redux-little-router'
import { fragments } from 'config/routes'

import { SECTION_NAME_SETUP } from 'lib/constants'

import { getCurrentKaseId, getRecordedPetitionProgress, isSetupIneligible, isCurrentKasePaid } from 'reducers/selectors'

const navigationBlockingMiddleware = (store) => (next) => (action) => {
  const state = store.getState()

  switch (action.type) {
    case LOCATION_CHANGED: {
      const path = action.payload.pathname

      if (path.includes(fragments.payment) || path.includes(fragments.petition) || path.includes(fragments.timeline)) {
        // don't block navigation if kase is paid already
        const unpaidKase = !isCurrentKasePaid(state)

        // don't block navigation if some significant
        // progress has been made already (special cases)
        const MIN_PETITION_PROGRESS_FOR_ALLOWING_THROUGH = 20
        const hasNotMadeEnoughPetitionProgress =
          getRecordedPetitionProgress(state) < MIN_PETITION_PROGRESS_FOR_ALLOWING_THROUGH

        const preventNavigation = isSetupIneligible(state) && unpaidKase && hasNotMadeEnoughPetitionProgress

        if (preventNavigation) {
          const kaseId = getCurrentKaseId(state)
          return store.dispatch(push(`/applications/${kaseId}/${SECTION_NAME_SETUP}`))
        }
      } else if (path.includes(fragments.document_upload)) {
        // block navigation if kase is unpaid
        const unpaidKase = !isCurrentKasePaid(state)

        if (unpaidKase) {
          const kaseId = getCurrentKaseId(state)
          return store.dispatch(push(`/applications/${kaseId}${fragments.timeline}`))
        }
      }

      break
    }

    default:
  }

  return next(action)
}

export default navigationBlockingMiddleware
