import $ from 'jquery'
import Dropzone from 'dropzone'
import Honeybadger from 'honeybadger-js'
import Popper from 'popper.js'
import Cookies from 'js-cookie'

import { showSignInModal } from './components/sign_in_nav'

Dropzone.autoDiscover = false

window.jQuery = window.$ = $
window.Popper = Popper
window.Honeybadger = Honeybadger
window.showSignInModal = showSignInModal
window.Cookies = Cookies
window._sessionStateCookie = JSON.parse(Cookies.get('_boundless_session_state_token') || '{}')
