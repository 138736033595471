import React, { FunctionComponent } from 'react'
import { DashboardPhase } from './phase_map'
import { DashboardPhaseData } from './phase_config/phase_preview'
import Paragraph from 'components/type/paragraph'
import ClockIcon from 'components/icons/clock_icon'
import ExitIcon from 'components/icons/exit_icon'
import CloseIcon from 'components/icons/close_icon'

interface PhasePreviewData {
  description: string
  estimatedLength?: string
  beginsAfter: string
}

export const DASHBOARD_PREVIEW_DATA: {
  [key in DashboardPhase]?: PhasePreviewData
} = {
  PRE_PAYMENT: {
    description: 'Check us out!',
    estimatedLength: '5-6 weeks processing time',
    beginsAfter: ''
  },
  INTAKE: null,
  POST_SHIPMENT: null,
  ILA: {
    description:
      'Your independent attorney will review your answers to our questions and follow up via email only if they have any questions. ' +
      'This step helps provide a customized plan for you throughout the Boundless process.',
    estimatedLength: '1–2 business days + your response time',
    beginsAfter: 'Begins after finishing Getting to Know You'
  },
  DOCUMENT_UPLOAD: {
    description:
      'You’ll answer additional questions and upload supporting documents from a customized ' +
      'list your attorney prepared. All documents must be approved before moving forward.',
    estimatedLength: 'Depends on how quickly you complete requests',
    beginsAfter: 'You’ll get a head start during Attorney Assessment'
  },
  DOCUMENT_REVIEW: {
    description:
      'We’ll thoroughly cross-reference your application forms against the information and supporting documents you provided for ' +
      'consistency, and we’ll reach out via email if we have any questions or need more documents.',
    estimatedLength: '12–14 business days + your response time',
    beginsAfter: 'Begins after finishing Forms and Documents'
  },
  LEGAL_REVIEW: {
    description:
      'Your independent attorney will thoroughly check your application for accuracy, completeness, and consistency. ' +
      'They may also request additional documents from you to help strengthen your application.',
    estimatedLength: '5–6 business days + your response time',
    beginsAfter: 'Begins after finishing Quality Assurance'
  },
  CUSTOMER_REVIEW: {
    description:
      'For a final check, we’ll send a digital copy of your application package for you to look over and request any changes that are needed.',
    estimatedLength: 'Depends on how quickly you review your package',
    beginsAfter: 'Begins after finishing Legal Review'
  },
  SHIPMENT: {
    description:
      'Your final application package — including all forms and supporting documents — will be printed and mailed to your doorstep, with clear ' +
      'instructions on where to sign and how to send the package to the government.',
    estimatedLength: '3–6 business days',
    beginsAfter: 'Begins after finishing Customer Review'
  }
}

interface Props {
  currentPhase: DashboardPhase
  phaseData: DashboardPhaseData
  closePhasePreview: () => void
}

const PhasePreview: FunctionComponent<Props> = ({ currentPhase, phaseData, closePhasePreview }) => {
  if (!currentPhase || !phaseData) {
    return null
  }

  const phasePreviewData = DASHBOARD_PREVIEW_DATA[currentPhase]

  const { description, beginsAfter } = phasePreviewData

  return (
    <div className="o-griddle__row" style={{ height: '100%' }}>
      <div className="o-griddle__col--7">
        <div className="c-phase-details__info">
          <h2 className="o-block c-type c-type--headline-md c-type--emphasized o-block--ample" data-qa="preview-title">
            {phaseData.title}
          </h2>
          <Paragraph spacing="lg" data-qa="preview-description">
            {description}
          </Paragraph>
          <Paragraph>
            <ClockIcon size="small" className="mr-1" />
            {phasePreviewData.estimatedLength}
          </Paragraph>
        </div>
      </div>
      <div className="o-griddle__col">
        <div className="c-phase-details__preview">
          <button
            className="c-phase-details__close-preview-btn"
            type="button"
            title="Hide this preview"
            onClick={closePhasePreview}
          >
            <CloseIcon />
          </button>
          <div className="o-box">
            <ExitIcon className="o-block" style={{ opacity: 0.75 }} />
            <Paragraph size="sm">{beginsAfter}</Paragraph>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PhasePreview
