import server from 'lib/api/v1/server'
import { AxiosPromise } from 'axios'
import { formatKasesUrl } from './helpers'

function fetchMilestoneDataForKase(kaseId: number): AxiosPromise {
  return server
    .get(formatKasesUrl(kaseId, 'milestones'))
    .then((response) => response.data)
    .catch((error) => Promise.reject(error))
}

function patchMilestoneAnswer(answer: string, answerId: number, kaseId: number): AxiosPromise {
  return server
    .patch(formatKasesUrl(kaseId, `milestone_answers/${answerId}`), {
      value: answer
    })
    .then((response) => response.data)
    .catch((error) => Promise.reject(error))
}

function postMilestoneAnswer(answer: string, kaseId: number, milestoneTaskId: number): AxiosPromise {
  return server
    .post(formatKasesUrl(kaseId, 'milestone_answers'), {
      milestone_task_id: milestoneTaskId,
      value: answer
    })
    .then((response) => response.data)
    .catch((error) => Promise.reject(error))
}

export default {
  fetchMilestoneDataForKase,
  patchMilestoneAnswer,
  postMilestoneAnswer
}
