import React, { FunctionComponent, useState, CSSProperties, useEffect } from 'react'
import { connect } from 'react-redux'
import Select from 'react-select'
import Button from 'components/button'
import { getWorkflowStatus } from 'reducers/selectors'
import { updateWorkflowStatus } from 'actions/kase_actions'
import { isProductionStage } from 'lib/settings'

const AOS_WORKFLOWS = [
  { label: 'Paid', value: 'aos_paid' },
  { label: 'Initial lawyer review', value: 'aos_initial_lawyer_review' },
  { label: 'Initial doc request', value: 'aos_initial_doc_request' },
  { label: 'Doc gathering', value: 'aos_doc_gathering' },
  { label: 'Ready for QA', value: 'aos_ready_for_qa' },
  { label: 'QA', value: 'aos_qa' },
  {
    label: 'Open questions ready to send',
    value: 'aos_open_questions_ready_to_send'
  },
  {
    label: 'Waiting for responses to open questions',
    value: 'aos_waiting_for_response_to_open_questions'
  },
  {
    label: 'Finalizing open questions',
    value: 'aos_finalizing_open_questions'
  },
  { label: 'Boundless legal review', value: 'aos_boundless_legal_review' },
  {
    label: 'MPDF initial edits post lawyer review',
    value: 'aos_master_pdf_initial_edits_post_lawyer_review'
  },
  {
    label: 'MPDF customer clarification needed post lawyer review',
    value: 'aos_master_pdf_waiting_for_customer_info_docs_post_lawyer_review'
  },
  {
    label: 'MPDF waiting for customer info/docs post lawyer review',
    value: 'aos_master_pdf_final_edits_post_lawyer_review'
  },
  { label: 'MPDF - ARC Needed', value: 'aos_master_pdf_arc_needed' },
  { label: 'MPDF - ARC Completed', value: 'aos_master_pdf_arc_completed' },
  {
    label: 'Ready for customer review',
    value: 'aos_ready_for_customer_review'
  },
  { label: 'Customer review', value: 'aos_customer_review' },
  {
    label: 'MPDF initial edits post customer review',
    value: 'aos_master_pdf_initial_edits_post_customer_review'
  },
  {
    label: 'MPDF waiting for customer info docs',
    value: 'aos_master_pdf_waiting_for_customer_info_docs_post_customer_review'
  },
  {
    label: 'MPDF final edits post Customer Review',
    value: 'aos_master_pdf_final_edits'
  },
  { label: 'Ready for printshop', value: 'aos_ready_for_printshop' },
  { label: 'In printshop', value: 'aos_in_printshop' },
  { label: 'Ready to ship', value: 'aos_ready_to_ship' },
  { label: 'Package shipped', value: 'aos_package_shipped' },
  {
    label: 'Package delivered to USCIS',
    value: 'post_shipment_package_delivered_to_uscis'
  },
  { label: 'Refunding', value: 'aos_refunding' }
]

const CP_WORKFLOWS = [
  { label: 'Paid', value: 'cp_paid' },
  { label: 'Initial lawyer review', value: 'cp_initial_lawyer_review' },
  { label: 'Initial doc request', value: 'cp_initial_doc_request' },
  { label: 'Doc gathering', value: 'cp_doc_gathering' },
  { label: 'Ready for qa', value: 'cp_ready_for_qa' },
  { label: 'QA', value: 'cp_qa' },
  {
    label: 'Open questions ready to send',
    value: 'cp_open_questions_ready_to_send'
  },
  {
    label: 'Waiting for response to open questions',
    value: 'cp_waiting_for_response_to_open_questions'
  },
  {
    label: 'Finalizing open questions',
    value: 'cp_finalizing_open_questions'
  },
  { label: 'Boundless legal review', value: 'cp_boundless_legal_review' },
  {
    label: 'MPDF initial edits post lawyer review',
    value: 'cp_master_pdf_initial_edits_post_lawyer_review'
  },
  {
    label: 'MPDF customer clarification needed post lawyer review',
    value: 'cp_master_pdf_waiting_for_customer_info_docs_post_lawyer_review'
  },
  {
    label: 'MPDF waiting for customer info/docs post lawyer review',
    value: 'cp_master_pdf_final_edits_post_lawyer_review'
  },
  { label: 'MPDF - ARC Needed', value: 'cp_master_pdf_arc_needed' },
  { label: 'MPDF - ARC Completed', value: 'cp_master_pdf_arc_completed' },
  {
    label: 'Ready for customer review',
    value: 'cp_ready_for_customer_review'
  },
  { label: 'Customer review', value: 'cp_customer_review' },
  {
    label: 'MPDF initial edits post customer review',
    value: 'cp_master_pdf_initial_edits_post_customer_review'
  },
  {
    label: 'MPDF waiting for customer info docs',
    value: 'cp_master_pdf_waiting_for_customer_info_docs_post_customer_review'
  },
  {
    label: 'MPDF final edits post Customer Review',
    value: 'cp_master_pdf_final_edits'
  },
  { label: 'Ready for printshop', value: 'cp_ready_for_printshop' },
  { label: 'In printshop', value: 'cp_in_printshop' },
  { label: 'Ready to ship', value: 'cp_ready_to_ship' },
  { label: 'Package shipped', value: 'cp_package_shipped' },
  {
    label: 'Package delivered to USCIS',
    value: 'cp_package_delivered_to_uscis'
  },
  { label: 'Refunding', value: 'cp_refunding' }
]

const POST_SHIPMENT_WORKFLOWS = [{ label: 'Package shipped', value: 'post_shipment_package_shipped_2' }]

const SELECT_OPTIONS = [
  { label: 'AOS', options: AOS_WORKFLOWS },
  { label: 'CP', options: CP_WORKFLOWS },
  { label: 'Post Shipment', options: POST_SHIPMENT_WORKFLOWS }
]

const debugPanelStyle: CSSProperties = {
  position: 'absolute',
  right: 0,
  top: '100%',
  padding: '.5rem',
  width: 300,
  zIndex: 1,
  boxShadow: '0 5px 7px rgba(0, 0, 0, 0.06)'
}

interface Props {
  workflowStatus: string
  updateWorkflowStatus: (workflowStatus: string) => void
}

const getDefaultWorkflowValue = (worfklowStatus: string) => {
  const defaultValue = [...AOS_WORKFLOWS, ...CP_WORKFLOWS].find((x) => x.value === worfklowStatus)
  return defaultValue || null
}

const WorkflowDebug: FunctionComponent<Props> = ({ workflowStatus, updateWorkflowStatus }) => {
  /**
   * We're relying on a window setting because, as of March 5, 2020, we don't
   * support different environment variables per deployment stage (because we
   * promote a staging build to production instead of rebuilding when
   * deploying).
   */
  if (isProductionStage()) {
    return null
  }

  const [showPanel, setShowPanel] = useState(false)
  const togglePanel = () => {
    setShowPanel(!showPanel)
  }

  const handleDocumentKeyDown = (event: KeyboardEvent) => {
    if (event.keyCode === 27) {
      setShowPanel(false)
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleDocumentKeyDown)

    return () => {
      document.removeEventListener('keydown', handleDocumentKeyDown)
    }
  })

  const setWorkflowStatus = (option: { value: string }) => {
    updateWorkflowStatus(option.value)
  }

  return (
    <div style={{ position: 'absolute', right: '1rem', top: '1rem', zIndex: 999 }}>
      <Button label="📌" color="tertiary" size="small" onClick={togglePanel} name="Toggle the dev menu" />
      <div
        style={Object.assign({}, debugPanelStyle, {
          display: showPanel ? 'flex' : 'none'
        })}
        className="c-card o-box"
      >
        <h4 className="c-type c-type--subhead-md o-block">Hacks 🤫</h4>
        <label className="c-type c-type--body-sans-sm o-block o-block--tight" htmlFor="debug-workflow">
          Workflow Status (Trello list)
        </label>
        <Select
          className="o-block o-block--compact"
          options={SELECT_OPTIONS}
          onChange={setWorkflowStatus}
          defaultValue={getDefaultWorkflowValue(workflowStatus)}
          id="debug-workflow"
        />
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  workflowStatus: getWorkflowStatus(state)
})

const mapDispatchToProps = (dispatch) => ({
  updateWorkflowStatus: (workflowStatus: string) => dispatch(updateWorkflowStatus(workflowStatus))
})

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowDebug)
