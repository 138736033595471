import _isEmpty from 'lodash/isEmpty'

export function isBlank(object: unknown): boolean {
  switch (typeof object) {
    case 'boolean':
      return false
    case 'function':
      return false
    case 'number':
      return isNaN(object)
    default:
      return _isEmpty(object)
  }
}

export function isPresent(object: unknown): boolean {
  return !isBlank(object)
}
