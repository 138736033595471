import addressHistoriesReducer from './address_histories'
import applicationReducer from './application'
import assetsReducer from './assets'
import browserReducer from './browser'
import customerMasterPdfReducer from './customer_master_pdf'
import dashboardReducer from './dashboard'
import documentsReducer from './documents'
import documentsReducerV2 from './documents_v2'
import employmentHistoriesReducer from './employment_histories'
import featuresReducer from './features'
import globalReducer from './global'
import helpReducer from './help'
import issuesReducer from './issues'
import lawyerInteractionsReducer from './lawyer_interactions'
import modalsReducer from './modals'
import modelReducer from './model'
import panelReducer from './panel'
import postShipDashboardReducer from './post_ship_dashboard'
import questionnaireReducer from './questionnaire'
import questionnaireV2Reducer from './questionnaireV2'

import routerReducer from './router'
import sectionProgressReducer from './section_progress'
import tasksReducer from './tasks'
import uploadsReducer from './uploads'

// TODO eventually annotate this state shape
export type ApplicationState = any

export default {
  addressHistories: addressHistoriesReducer,
  application: applicationReducer,
  assets: assetsReducer,
  browser: browserReducer,
  dashboard: dashboardReducer,
  documents: documentsReducer,
  documentsV2: documentsReducerV2,
  employmentHistories: employmentHistoriesReducer,
  features: featuresReducer,
  global: globalReducer,
  help: helpReducer,
  kaseIssues: issuesReducer,
  kaseTasks: tasksReducer,
  lawyerInteractions: lawyerInteractionsReducer,
  masterPdf: customerMasterPdfReducer,
  modals: modalsReducer,
  model: modelReducer,
  panel: panelReducer,
  postShipDashboard: postShipDashboardReducer,
  questionnaire: questionnaireReducer,
  questionnaireV2: questionnaireV2Reducer,
  router: routerReducer,
  sectionProgress: sectionProgressReducer,
  uploads: uploadsReducer
}
