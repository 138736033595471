import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'

import UploadCustomerFeedback from './upload_customer_feedback'
import CheckmarkIllustration from 'components/illustrations/checkmark_illustration'
import { workflowStatusAllowsCustomerReview } from 'reducers/selectors'
import Paragraph from 'components/type/paragraph'

interface Props {
  showCustomerReview?: boolean
}

const CustomerReviewPhase: FunctionComponent<Props> = (props) => {
  const { showCustomerReview } = props

  if (showCustomerReview) {
    return <UploadCustomerFeedback />
  }

  return (
    <div className="o-layout--center o-box o-box--spacious">
      <div className="o-block o-block--compact">
        <CheckmarkIllustration />
      </div>
      <h2 className="o-block c-type c-type--headline-md c-type--emphasized">Feedback Submitted</h2>
      <Paragraph>Your feedback was successfully submitted to Boundless!</Paragraph>
    </div>
  )
}

function mapStateToProps(state) {
  return {
    showCustomerReview: workflowStatusAllowsCustomerReview(state)
  }
}

export default connect(mapStateToProps)(CustomerReviewPhase)
