import React, { ReactNode } from 'react'

import ExternalLink from 'components/external_link'
import UploadPhasePrimaryCTA from './cta'
import IncompleteQuestionnairesCTA from './incomplete_questionnaires_cta'
import IntakePrimaryCTA from 'components/screens/dashboard/customer_dashboard/phases/intake_phase/intake_primary_cta'

interface DocUploadPhaseContent {
  phaseInstruction: string
  phaseDescription: ReactNode
  primaryCTA: ReactNode
}

// SELF SUFFICIENCY INCOMPLETE
export const selfSufficiencyIncompleteContent: DocUploadPhaseContent = {
  phaseInstruction: '{{attorney}} has finished Attorney Assessment — everything looks good!',
  phaseDescription:
    'Once you finish the Self-Sufficiency Questionnaire, we’ll help you upload your documents. Finishing the questionnaire helps {{attorney}} build a personalized list of documents required by the U.S. government for your green card application.',
  primaryCTA: <IntakePrimaryCTA />
}

// if in initial doc request stage OR uploading has not begun
export const initialDocRequestContent = (uploadingHasNotBegun): DocUploadPhaseContent => ({
  phaseInstruction: `{{attorney}} has finished Attorney Assessment — everything looks good! ${
    uploadingHasNotBegun ? 'Start' : 'Keep'
  } uploading your documents to make progress.`,
  phaseDescription: (
    <>
      The U.S. government will use your documents to verify the information in your application, so it’s important to
      upload the{' '}
      <ExternalLink href="https://www.boundless.com/immigration-resources/us-visa-reciprocity-schedule/">
        correct documents
      </ExternalLink>{' '}
      as{' '}
      <ExternalLink href="https://www.boundless.com/immigration-resources/creating-flawless-digital-documents/">
        <span className="italic">clearly</span>
      </ExternalLink>{' '}
      <span className="italic">and early as possible.</span> This helps prevent delays and rejection by the U.S.
      government.
      <br />
      <br />
      <span className="font-bold">Note:</span> Additional questionnaires or documents will appear below if the
      government needs more info based on your situation.
    </>
  ),
  primaryCTA: <UploadPhasePrimaryCTA />
})

// COMMENTS PRESENT
export const commentsToReadContent: DocUploadPhaseContent = {
  phaseInstruction: 'We checked your documents and added tasks for you to complete.',
  phaseDescription: (
    <>
      Don’t worry — all Boundless customers are given tasks to complete. This just means you need to upload additional
      or replacement documents required by the U.S. government.
      <br />
      <br />
      We’ve helped thousands of couples through this process, so we know what the U.S. government looks for.
    </>
  ),

  primaryCTA: <UploadPhasePrimaryCTA hasUnresolvedComments={true} />
}

// IF COMPLETED QUESTIONNAIRES
export const docGatheringUploadingInProgressContent: DocUploadPhaseContent = {
  phaseInstruction: 'Keep it up! Please continue uploading your required supporting documents.',
  phaseDescription: (
    <>
      The U.S. government requires many documents, and we know it takes time to collect all of them. But it’s important
      to finish this step as quickly as possible to avoid delays.
      <br />
      <br />
      And don’t worry — we check each document thoroughly to make sure it meets the government’s specifications.
    </>
  ),
  primaryCTA: <UploadPhasePrimaryCTA />
}

const documentSetsRemainingPhrase = (sections) => {
  if (sections === 1) {
    return 'is still 1 document set'
  }

  return `are still ${sections} document sets`
}

export const beingReviewedWithActiveRequestsContent = (sections): DocUploadPhaseContent => {
  return {
    phaseInstruction: `We’ll check your documents soon! In the meantime, there ${documentSetsRemainingPhrase(
      sections
    )} for you to upload.`,
    phaseDescription:
      'We’ll start checking the quality of your documents to help ensure they meet government specifications. Keep uploading documents while you wait!',
    primaryCTA: <UploadPhasePrimaryCTA />
  }
}

export const aosInReviewNoActiveRequestContent: DocUploadPhaseContent = {
  phaseInstruction: 'We’ll check your documents soon! After that, we’ll start Quality Assurance.',
  phaseDescription: (
    <>
      You’re almost there!
      <br />
      <br />
      <span className="font-bold">IMPORTANT:</span> Keep in mind that you’ll need to keep uploading earnings, asset, and
      liability statements as you receive them until we assemble your final application package.
      <br />
      <br />
      The U.S. government requires the most recent of all these documents at the time of application filing.
    </>
  ),
  primaryCTA: <UploadPhasePrimaryCTA finalReview={true} />
}

export const cpInReviewNoActiveRequestContent: DocUploadPhaseContent = {
  phaseInstruction: 'We’ll check your documents soon! After that, we’ll start Quality Assurance.',
  phaseDescription: (
    <>
      You’re almost there! We’ll start checking the quality of your documents to help ensure they meet government
      specifications.
      <br />
      <br />
      In the meantime, keep uploading “evidence-of-relationship” documents as they become available, and make sure to
      update your <ExternalLink href="https://apply.boundless.com/petition">profile</ExternalLink> if you move or change
      employers.
    </>
  ),
  primaryCTA: <UploadPhasePrimaryCTA finalReview={true} />
}

// QUESTIONNAIRES INCOMPLETE

// DocumentUploadSummaryStates.ThresholdNotReached && DocumentUploadSummaryStates.ThresholdReachedNoComments
export const questionnairesIncompleteCurrentlyUploading: DocUploadPhaseContent = {
  phaseInstruction: 'Complete your remaining questionnaires, and continue uploading documents.',
  phaseDescription: (
    <>
      <span className="font-bold" style={{ textTransform: 'uppercase' }}>
        IMPORTANT:
      </span>{' '}
      Make sure to finish your questionnaires as quickly as possible — your independent attorney may add new required
      supporting documents to your list, based on your answers.
      <br />
      <br />
      The sooner you complete them, the sooner you’ll have your full list of documents.
    </>
  ),
  primaryCTA: <IncompleteQuestionnairesCTA />
}

// DocumentUploadSummaryStates.AllSectionsCustomerCompleted:
export const QUESTIONNAIRES_INCOMPLETE_ALL_DOC_REQUESTS_COMPLETE: DocUploadPhaseContent = {
  phaseInstruction: 'In order to proceed to Quality Assurance, complete your remaining questionnaires below.',
  phaseDescription: (
    <>
      <span className="font-bold" style={{ textTransform: 'uppercase' }}>
        IMPORTANT:
      </span>{' '}
      Your independent attorney may add documents for you to upload, based on your answers to some questions.
      <br />
      <br />
      If there are no more documents for you to upload, we’ll begin Quality Assurance.
    </>
  ),
  primaryCTA: <IncompleteQuestionnairesCTA />
}

// DocumentUploadSummaryStates.AllSectionsAccepted
export const questionnairesCompletedAfterFinalReview: DocUploadPhaseContent = {
  phaseInstruction: 'Let us know you’re ready for Quality Assurance so we can make sure your application is flawless.',
  phaseDescription: (
    <>
      Now’s a good time to double-check your answers to the additional questionnaires. After you click Begin Quality
      Assurance, your application will be added to our inspection queue.
    </>
  ),
  primaryCTA: <IncompleteQuestionnairesCTA readyToMoveToQA={true} />
}
