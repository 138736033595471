import _includes from 'lodash/includes'
import PhoneNumber from 'awesome-phonenumber'

const MASKABLE_COUNTRIES = ['US', 'CA']

interface PhoneMaskOptions {
  maskChar?: string
}

export function phoneMaskForCountry(code: string, options: PhoneMaskOptions = {}): Nullable<string> {
  options.maskChar = options.maskChar || ' '

  if (!_includes(MASKABLE_COUNTRIES, code)) {
    return null
  }

  return PhoneNumber.getExample(code).getNumber('national').replace(/\d/g, '9').replace(/\s/g, options.maskChar)
}

export function requiredPhoneLengthForCountry(code: string): Nullable<number> {
  const mask = phoneMaskForCountry(code)

  if (!mask) {
    return null
  }

  return mask.replace(/[^\d]/g, '').length
}
