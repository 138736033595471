import React, { ChangeEventHandler, FocusEventHandler, FunctionComponent, useEffect, useState } from 'react'
import moment from 'moment'
import CommonInputV2 from './common/common_input_v2'
import CommonLabelV2 from './common/common_label_v2'
import { formatValue } from '../../date_input/format'

interface Props {
  value?: string
  name?: string
  label?: string
  onChangeEvent: ChangeEventHandler
  onBlurEvent: FocusEventHandler
  isValid?: boolean
  disabled?: boolean
  validationMessage?: string
  className?: string
}

const defaultProps = {
  value: '',
  name: '',
  label: ''
}

const DateInputV2: FunctionComponent<Props> = ({
  value,
  name,
  label,
  onChangeEvent,
  onBlurEvent,
  isValid,
  disabled,
  validationMessage,
  className
}) => {
  const [typing, setIsTyping] = useState(false)
  const [typedValue, setTypedValue] = useState(value)

  const formatDate = (date) => {
    if (date === null) {
      return null
    }

    return moment(date).format('L')
  }

  useEffect(() => {
    if (value !== null) {
      setTypedValue(formatDate(value))
    }
  }, [value])

  const formattedValue = () => {
    if (typing) {
      return typedValue
    } else {
      return formatDate(typedValue)
    }
  }

  const internalChangeEvent = (evt) => {
    const { value } = evt.target
    setIsTyping(true)

    // Do not format date input when deleting chars
    if (window?.event?.inputType === 'deleteContentBackward') {
      setTypedValue(value)
      return
    }

    setTypedValue(formatValue(value))
  }

  const internalOnBlurEvent = (evt) => {
    onChangeEvent(evt)
    onBlurEvent(evt)
  }

  return (
    <div className={`o-grid--fluid o-grid--stack@sm o-block c-paper-form__group ${className}`}>
      <CommonLabelV2 label={label} labelFor={name} classNameSize="o-grid__cell--3/12" />
      <CommonInputV2
        inputName={name}
        value={formattedValue() ? formattedValue() : ''}
        keyCapture={() => setIsTyping(true)}
        onChangeEvent={internalChangeEvent}
        onBlur={internalOnBlurEvent}
        placeholder="mm/dd/yyyy"
        classNameSize="o-grid__cell--6/12"
        isValid={isValid}
        validationMessage={validationMessage}
        disabled={disabled}
      />
    </div>
  )
}

DateInputV2.defaultProps = defaultProps

export default DateInputV2
