import server from './server'
import { AxiosPromise } from 'axios'
import { formatKasesUrl } from './helpers'

import { EmploymentHistory } from '../../../reducers/employment_histories'

const formatEmploymentHistoryUrl = (kaseId: number, path = null, params = null) => {
  const baseUrl = formatKasesUrl(kaseId, 'employment_histories')
  const fullPath = path ? `${baseUrl}/${path}/` : `${baseUrl}/`

  return params ? `${fullPath}?${params}` : fullPath
}

function index(kaseId: number): AxiosPromise<EmploymentHistory[]> {
  return server
    .get(formatEmploymentHistoryUrl(kaseId))
    .then((response) => {
      return response.data
    })
    .catch((
      error // eslint-disable-next-line no-console
    ) => console.log('Error loading employment histories', error))
}

function createEmploymentHistory(
  kaseId: number,
  owner: string,
  employmentHistory: EmploymentHistory
): AxiosPromise<EmploymentHistory> {
  return server
    .post(formatEmploymentHistoryUrl(kaseId), {
      employment_history: {
        owner,
        ...employmentHistory
      }
    })
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      throw error
    })
}

function updateEmploymentHistory(
  kaseId: number,
  owner: string,
  employmentHistory: EmploymentHistory
): AxiosPromise<EmploymentHistory> {
  return server
    .put(formatEmploymentHistoryUrl(kaseId, employmentHistory.id), {
      employment_history: {
        owner,
        ...employmentHistory
      }
    })
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      throw error
    })
}

function deleteEmploymentHistory(kaseId: number, id: number): AxiosPromise<EmploymentHistory> {
  return server
    .delete(formatEmploymentHistoryUrl(kaseId, id))
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      throw error
    })
}

export default {
  index,
  createEmploymentHistory,
  updateEmploymentHistory,
  deleteEmploymentHistory
}
