import React, { FunctionComponent } from 'react'

import IconModal from 'components/modals/icon_modal'
import Paragraph from 'components/type/paragraph'
import Button from 'components/button'

interface ExplicitProps {
  closeModal: (submitForReview: boolean) => () => void
  showModal: boolean
}

export const SubmitReviewModal: FunctionComponent<ExplicitProps> = ({ closeModal, showModal }) => {
  return (
    <IconModal
      closeModal={closeModal(false)}
      headerContent="You’ve replied to every item!"
      showModal={showModal}
      variant="success"
    >
      <Paragraph spacing="lg">
        Just one more step: Submit all of your pending answers so we can start checking your information. Once you
        submit, you won’t be able to edit your answers.
      </Paragraph>
      <Button onClick={closeModal(true)} label="Submit" color="primary" className="mb-3" block />
      <Button onClick={closeModal(false)} label="I'm not finished yet" color="secondary" block />
    </IconModal>
  )
}

export default SubmitReviewModal as FunctionComponent<ExplicitProps>
