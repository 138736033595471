import React from 'react'
import { Provider } from 'react-redux'
import { Fragment } from 'redux-little-router'
import getStore from 'stores/app_store'
import App from './app'
function AppRoot() {
  return (
    <Provider store={getStore()}>
      <Fragment forRoute="/applications/:kaseId">
        <App />
      </Fragment>
    </Provider>
  )
}
export default AppRoot
