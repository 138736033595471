import React, { FunctionComponent } from 'react'
import cx from 'classnames'

import HCCLink from 'components/panels/outcomes/setup_completed_hcc_link'
import Paragraph from 'components/type/paragraph'

interface Props {
  content: any
  contentId?: string
  header?: string
  headerClass?: string
  headerId?: string
  hasLink?: boolean
  linkHref?: string
  linkOpensChat?: boolean
  linkText?: string
  subtitle?: string
  screen: 'desktop' | 'mobile'
  smallContent?: boolean
  className?: string // classes that apply to the whole object
  contentClassName?: string // classes that don't apply to the header
}

const EligibilityReportInfoCell: FunctionComponent<Props> = (props) => {
  const headerSpacingClasses = cx('o-block o-grid--fluid o-grid--fluid--trailed', {
    'o-block--ample': props.screen === 'desktop',
    'o-block--compact': props.screen === 'mobile'
  })

  const layoutClasses = cx({
    'o-layout--left': props.screen === 'mobile'
  })

  const typeClasses = cx({
    'c-type--headline-sm': props.smallContent,
    'c-type--headline-md': !props.smallContent
  })

  return (
    <div className={props.className}>
      {props.header && (
        <div className={`${headerSpacingClasses} ${props.headerClass}`}>
          <div className="o-box o-box--light o-box--dense">
            <div id={props.headerId} className="o-layout o-layout--padded-x--minuscule c-type c-type--subhead-sm">
              {props.header}
            </div>
          </div>
        </div>
      )}
      <div className={props.contentClassName}>
        <div
          className={cx('o-block o-block--compact c-type c-type--emphasized', layoutClasses, typeClasses)}
          id={props.contentId}
        >
          {props.content}
        </div>
        <div className={cx('o-layout', layoutClasses)}>
          {props.hasLink && (
            <HCCLink
              href={props.linkHref}
              overrideToChat={props.linkOpensChat}
              linkText={props.linkText}
              icon="question"
            />
          )}
          {props.subtitle && (
            <Paragraph serif size="sm" className="c-type--understated">
              {props.subtitle}
            </Paragraph>
          )}
        </div>
      </div>
    </div>
  )
}

export default EligibilityReportInfoCell
