import moment from 'moment'

import { joinPaths } from 'lib/path_helpers'
import { buildMessage, Validator } from '.'

function parseDate(date) {
  return moment(date, 'YYYY/MM/DD')
}

function getDatePaths(path, startName, endName) {
  // this validator gets passed a specific path, but we
  // need to compare it against the other end of the range
  // so we create a regex to find whichever specific path part got passed in
  const specificDatePath = new RegExp(`.(${startName}|${endName})$`)
  // and grab just the parent part of the path
  const parent = path.replace(specificDatePath, '')
  // then add the related path parts back on to get both start and end date
  const startDatePath = joinPaths(parent, startName)
  const endDatePath = joinPaths(parent, endName)

  return {
    startDatePath,
    endDatePath
  }
}

const defaultStartDateName = 'start_date'
const defaultEndDateName = 'end_date.date'
const defaultTimeframe = 1

const dateRangeWithTimeframe: Validator = ({ getModelData, path, options = {} }) => {
  const startDateName = options.startDateName || defaultStartDateName
  const endDateName = options.endDateName || defaultEndDateName
  const timeframe = options.timeframe || defaultTimeframe

  const datePaths = getDatePaths(path, startDateName, endDateName)

  const startDate = parseDate(getModelData(datePaths.startDatePath))
  const endDate = parseDate(getModelData(datePaths.endDatePath))

  const invalidDates = !startDate.isValid() || !endDate.isValid()

  const dateRangeIsLessThanTimeframe = endDate.diff(startDate, 'years', true) < timeframe

  if (invalidDates || !dateRangeIsLessThanTimeframe) {
    return []
  } else {
    return [buildMessage(`Date range must be greater than ${timeframe} year(s)`)]
  }
}

export default dateRangeWithTimeframe
