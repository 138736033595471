import React, { FunctionComponent, useEffect, useRef } from 'react'
import AsidePanel from './aside_panel'
import { Issue, Message } from 'reducers/issues'
import { getMessagesForCurrentIssue } from 'reducers/issues/selectors'
import { connect } from 'react-redux'
import ThreadPanelReply from 'components/screens/issues/thread_panel_reply'
import { getCurrentIssue } from 'reducers/selectors'
import MessageAlert from 'components/screens/issues/message_alert'
import ThreadPanelMessage from 'components/screens/issues/thread_panel_message'

interface ExplicitProps {
  isOpen?: boolean
  currentIssue?: Issue
  closePanel: () => void
  submitReply: () => void
}

interface MappedProps {
  messagesForIssue?: Message[]
}

type Props = MappedProps & ExplicitProps

export const ThreadPanel: FunctionComponent<Props> = ({
  currentIssue,
  isOpen,
  closePanel,
  submitReply,
  messagesForIssue = []
}) => {
  const containerRef = useRef<HTMLDivElement>()

  useEffect(() => {
    // Scroll to the bottom of the container do display the most recent message
    if (isOpen) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight
    }
  }, [isOpen])

  return (
    <AsidePanel showPanel={isOpen} closePanel={closePanel} headerContent="Messages">
      <div className="overflow-y-auto" ref={containerRef}>
        <MessageAlert />

        <div className="flex-grow sm:px-4 md:px-6 divide-y">
          <ThreadPanelMessage
            createdAt={currentIssue?.attributes?.created_at}
            messageBody={currentIssue?.attributes?.description}
            creatorId={currentIssue?.attributes?.creator_id}
          />

          {messagesForIssue &&
            messagesForIssue.map((message) => (
              <ThreadPanelMessage
                key={message.id}
                createdAt={message.attributes.created_at}
                messageBody={message.attributes.text}
                creatorId={message.attributes.creator_id}
              />
            ))}
        </div>
      </div>

      <ThreadPanelReply submitReply={submitReply} />
    </AsidePanel>
  )
}

function mapStateToProps(state) {
  return {
    messagesForIssue: getMessagesForCurrentIssue(state.kaseIssues),
    currentIssue: getCurrentIssue(state)
  }
}

export default connect(mapStateToProps)(ThreadPanel)
