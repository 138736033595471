import React, { FunctionComponent, useRef, useState } from 'react'
import cx from 'classnames'

import Paragraph from 'components/type/paragraph'
import BlankModal from 'components/modals/modal/blank_modal'
import Button from 'components/button'
import { TeamMemberInfo } from 'reducers/dashboard'
import { capitalize } from 'lib/language_helpers'
import { JOB_TITLE_OPTIONS } from 'lib/constants'
import ProfilePicturePlaceholder from 'components/screens/dashboard/customer_dashboard/profile_picture_placeholder'

export const LAWYER_CONTACT_TEXT = `If you need to contact your attorney, please first send your questions to your ${JOB_TITLE_OPTIONS.JOB_TITLE_STEWARD}. Send an email to:`
export const STEWARD_CONTACT_TEXT = 'Send an email to:'

interface Props {
  showModal?: boolean
  hideModal: () => void
  memberType?: 'lawyer' | 'steward'
  contactEmail: string
  teamMember: TeamMemberInfo
}

const TeamMemberModal: FunctionComponent<Props> = ({
  showModal,
  hideModal,
  memberType = 'lawyer',
  teamMember,
  contactEmail
}) => {
  const { profilePicturePath, fullName, firstName, jobTitle, pronouns, braggingRights } = teamMember
  const emailInputRef = useRef<HTMLInputElement>(null)
  const [emailWasCopied, setEmailWasCopied] = useState(false)

  const isJobTitleCustomerSupport = () => {
    return jobTitle === JOB_TITLE_OPTIONS.JOB_TITLE_CUSTOMER_SUPPORT
  }

  const copyEmailToClipboard = (event: React.MouseEvent) => {
    event.stopPropagation()

    if (emailInputRef.current) {
      emailInputRef.current.select()
      emailInputRef.current.setSelectionRange(0, contactEmail.length)

      document.execCommand('copy')

      emailInputRef.current.setSelectionRange(0, 0)

      setEmailWasCopied(true)
      setTimeout(() => {
        setEmailWasCopied(false)
      }, 1500)
    }
  }

  return (
    <BlankModal isOpen={showModal} onRequestClose={hideModal}>
      <div style={{ background: 'white' }}>
        <div
          className={cx('c-team-member-modal__header-background', {
            'c-team-member-modal__header-background--lawyer': memberType === 'lawyer',
            'c-team-member-modal__header-background--steward': memberType === 'steward'
          })}
        >
          <button className="c-team-member-modal__close-btn" type="button" onClick={hideModal} title="Close">
            &times;
          </button>
        </div>
        <div className="c-team-member-modal__picture-wrapper">
          {profilePicturePath && (
            <img className="c-team-member-modal__picture" alt={`Picture of ${firstName}`} src={profilePicturePath} />
          )}

          {!profilePicturePath && (
            <ProfilePicturePlaceholder initial={fullName.charAt(0)} className="c-team-member-modal__picture" />
          )}
        </div>
        <div className="o-box c-team-member-modal__content">
          <h2 className="o-block c-type c-type--headline-md c-type--emphasized o-block--compact">
            {fullName}
            {memberType === 'lawyer' && ', Esq.'}
          </h2>
          <Paragraph spacing="sm" className="c-type--emphasized">
            {jobTitle}
          </Paragraph>
          {braggingRights && (
            <Paragraph spacing="lg" className="t-color--success">
              ★ {braggingRights}
            </Paragraph>
          )}
          <h3 className="o-block o-block--compact c-type c-type--subhead-sm c-type--emphasized">About</h3>
          {memberType === 'steward' && !isJobTitleCustomerSupport() && (
            <Paragraph>
              Your Application Guide is here to answer any of your questions and guide you through the rest of the
              Boundless process. They can also relay your questions to your independent attorney.
            </Paragraph>
          )}
          {memberType === 'lawyer' && (
            <Paragraph>
              {firstName} is your independent immigration attorney. {capitalize(pronouns.personal)}'ll assess your
              situation, thoroughly review your application, and answer your legal questions.
            </Paragraph>
          )}
          {isJobTitleCustomerSupport() && (
            <Paragraph>
              For all questions and concerns, please contact the Boundless Support Team at the email address below. They
              can also relay your questions to your independent attorney.
            </Paragraph>
          )}
          <h3 className="o-block o-block--compact c-type c-type--subhead-sm c-type--emphasized">Contact</h3>
          <Paragraph>{memberType === 'lawyer' ? LAWYER_CONTACT_TEXT : STEWARD_CONTACT_TEXT}</Paragraph>
          <div className="o-griddle__row o-griddle__row--no-gutters">
            <div className="o-griddle__col">
              <input ref={emailInputRef} readOnly type="text" value={contactEmail} className="c-input o-block mb-0" />
            </div>
            <div className="o-griddle__col--auto">
              <Button label={emailWasCopied ? 'Copied!' : 'Copy'} color="primary" onClick={copyEmailToClipboard} />
            </div>
          </div>
        </div>
      </div>
    </BlankModal>
  )
}

export default TeamMemberModal
