import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'

import { openModal as openModalAction } from 'actions/modal_actions'

import { QuestionnairePageV2, QuestionnaireOutlineV2 } from '../lib/types'
import { OutlineTypes } from './desktopV2'

import ArrowIcon from 'components/icons/arrow_icon'
import Button from 'components/button'
import ProgressBar from 'components/progress_bar'

import { allQuestionsOnPageCompleted } from '../lib/helpers'

interface MobileQuestionnaireOutlineProps {
  currentPageData: QuestionnairePageV2
  handleContinueClick: any
  handlePreviousClick: any
  isEditingIssue: boolean
  isEditingSummaryQuestionSet: boolean
  isPaidUser: boolean
  isUpdating: boolean
  nextPagePath?: string
  openModal: any
  outlineType: OutlineTypes
  previousPagePath?: string
  questionnaireOutlineData: QuestionnaireOutlineV2
}

export const MobileQuestionnaireOutlineV2: FunctionComponent<MobileQuestionnaireOutlineProps> = ({
  currentPageData,
  handleContinueClick,
  handlePreviousClick,
  isEditingIssue = false,
  isEditingSummaryQuestionSet,
  isPaidUser,
  isUpdating,
  nextPagePath,
  openModal,
  outlineType = OutlineTypes.Complete,
  previousPagePath,
  questionnaireOutlineData
}) => {
  const allPages = questionnaireOutlineData.attributes.sections.reduce((acc, section) => {
    return [...acc, ...section.attributes.pages]
  }, [])

  const currentPage = currentPageData?.data.attributes

  const isQuestionPage = ['Page', 'HistoryPage', 'SummaryPage'].includes(currentPage?.type)
  const isEligibilityReport = currentPage?.type === 'EligibilityReport'
  const isLandingPage = currentPage?.type === 'LandingPage'
  const isExitPage = currentPage?.type === 'ExitPage'
  const isLoginPage = currentPage?.type === 'LoginPage'
  const isIneligiblePage = currentPage?.type === 'IneligiblePage'
  const isPrepaymentPage = currentPage?.type === 'PrepaymentPage'
  const isPaymentPage = currentPage?.type === 'PaymentPage'
  const isEstimatedTimelinePage = currentPage?.type === 'EstimatedTimelinePage'
  const isK1PrePayInterstitialPage = currentPage?.name === 'ExitPagePrePayment'
  const isK1PrePayEligibilityExitPage = currentPage?.path === 'eligibility-eligibilityexitpage'
  const isK1StandalonePaymentPage = isPaymentPage && questionnaireOutlineData?.attributes?.sections.length === 1

  // TODO: this logic is copied from app/client/javascript/components/screens/questionnaire/panel/index.tsx
  // Can we condense this down into a single component so we don't have to track logic
  // for the buttons in different spots?
  const showPreviousButton = !isEditingIssue && previousPagePath
  const showContinueButton = currentPageData && !isPaymentPage && !(isIneligiblePage && !isPaidUser) && !isLoginPage
  const showIncompleteMessage = isQuestionPage && !nextPagePath
  const showSeeAllSections =
    !isEligibilityReport &&
    questionnaireOutlineData.attributes?.sections.length > 1 &&
    outlineType !== OutlineTypes.Disabled
  const showProgressNumbers = outlineType !== OutlineTypes.Disabled

  const currentPageIndex = allPages?.findIndex((page) => page?.id === currentPageData?.data.id)

  const continueButtonLabel = () => {
    if (isExitPage) {
      if (isK1PrePayEligibilityExitPage) {
        return 'Continue'
      } else {
        return 'Back to Dashboard'
      }
    } else if (isK1PrePayInterstitialPage) {
      return 'See Payment Options'
    } else if (isIneligiblePage) {
      return 'Chat with Boundless'
    } else if (isQuestionPage && isEditingIssue) {
      return 'Save & Return to Issues'
    } else {
      return 'Continue'
    }
  }

  const getProgressPercent = () => Math.round((100 * (currentPageIndex + 1)) / allPages.length)

  const handleModalClick = (event) => {
    event.preventDefault()

    // getTelemetryService().trackLink({
    //   linkName: 'LaunchSeeAllSectionsModal'
    // })

    openModal({ name: 'SeeAllSectionsModalNewV2' })
  }

  const showOnlyIcon = isQuestionPage

  const disableContinueButton =
    (!nextPagePath && !isExitPage) ||
    (outlineType === OutlineTypes.Slim && !allQuestionsOnPageCompleted(currentPageData.data)) ||
    isEditingSummaryQuestionSet

  return (
    <>
      {isEditingIssue ? (
        <div className="o-layout--block-center">
          <Button
            id="return-to-issues-button-mobile"
            className="c-sheet__action c-btn--btn-events-only o-block"
            color="emphasized"
            disabled={!nextPagePath}
            onClick={handleContinueClick}
            label="Save & Return to Issues"
            icon={<ArrowIcon arrowDirection="right" />}
            isLoading={isUpdating}
          />
        </div>
      ) : (
        <>
          <div
            className={classNames('c-sheet__action-container c-sheet__action-container--secondary u-hide@md-up', {
              'c-sheet__action-container--floating-bare':
                isPrepaymentPage || isPaymentPage || isEstimatedTimelinePage || isEligibilityReport || isLandingPage
            })}
          >
            {showPreviousButton && (
              <Button
                className="c-sheet__action c-btn c-btn--icon-only c-btn--secondary-mobile c-btn--btn-events-only"
                color="primary"
                icon={<ArrowIcon arrowDirection="left" />}
                id="previous-button-mobile"
                isLoading={isUpdating}
                onClick={handlePreviousClick}
              />
            )}
            {/* Continue button */}
            {showContinueButton && (
              <div className={classNames('o-layout c-sheet__action-container--primary o-layout--right')}>
                <div className="o-block">
                  {showIncompleteMessage ? (
                    <div className="c-sheet__action p-2">Please complete all questions</div>
                  ) : (
                    <div className="o-layout--block-center">
                      <Button
                        id="continue-button-mobile"
                        className="c-sheet__action c-btn--btn-events-only o-block o-block--compact"
                        color="primary"
                        disabled={disableContinueButton}
                        onClick={handleContinueClick}
                        label={continueButtonLabel()}
                        iconOnly={showOnlyIcon}
                        icon={<ArrowIcon arrowDirection="right" />}
                        isLoading={isUpdating}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>

          {/* Progress Bar */}
          {isQuestionPage && !showIncompleteMessage && (
            <div className="c-sheet__progress-indicator u-hide@md-up">
              <ProgressBar.Horizontal
                className="c-sheet__progress-bar"
                progress={getProgressPercent()}
                fillStyles={['slim', 'secondary']}
              />

              {showProgressNumbers && (
                <div className="c-sheet__progress-label c-type c-type--subhead-sm c-type--muted">
                  {currentPageIndex >= 0 ? currentPageIndex + 1 : 'N/A'} / {allPages.length}
                </div>
              )}
            </div>
          )}

          {/* See all sections */}
          {showSeeAllSections && (
            <div className="c-sheet__folio u-hide@md-up">
              <a className="c-link c-link--secondary c-type c-type--subhead-sm" href="#" onClick={handleModalClick}>
                See All Sections
              </a>
            </div>
          )}

          {isK1StandalonePaymentPage && (
            <div className="c-sheet__folio u-hide@md-up">
              <a className="c-link c-link--secondary c-type c-type--subhead-sm" href="/timeline">
                Return to Dashboard
              </a>
            </div>
          )}
        </>
      )}
    </>
  )
}

const mapDispatchToProps = (dispatch) => ({
  openModal: (...args) => dispatch(openModalAction(...args))
})

export default connect(null, mapDispatchToProps)(MobileQuestionnaireOutlineV2)
