import _get from 'lodash/get'
import { ConditionMap } from '.'
import { WorkflowCategory } from 'lib/constants'
import { isPublicChargeDisabled } from 'lib/feature_flags_deprecated'

const fieldForPerson = (field) => (role) => (data) => Boolean(data[role][field])

const hasNoGreenCard = fieldForPerson('no_green_card')
const hasNoEmploymentVerification = fieldForPerson('no_employment_verification')
const hasNoRecentPaystubs = fieldForPerson('no_recent_paystubs')
const hasNoBirthCertificate = fieldForPerson('no_birth_certificate')

const hasNotIndicatedPreviousEmployment = (role) => (data, field) => {
  const employment = _get(data, [role, 'employments', field.currentIndex])

  if (!employment) {
    return true
  }

  return employment.has_previous_employment !== 'yes'
}

const isAOSorCP2 = (data) => {
  // only show if is aos or cp_ready_for_docs is true
  if (data) {
    const workflowCategoryIsAOS = data.computed_workflow_category === WorkflowCategory.AOS
    const isCP2ReadyForDocs = data.cp_ready_for_docs

    return workflowCategoryIsAOS || isCP2ReadyForDocs
  }

  return false
}

// TODO: some of these conditions are no longer needed.
// let's do an audit sometime.

// Condition functions take in the model reducer's state (which is state.model)
// and return true/false.
//
// These functions can be used with `hidden_if` inside
// of app/views/kases/sections/*.yml
const conditions: ConditionMap = {}

conditions.notMarried = (data) => {
  return !data.eligibility.is_married // B20-1267
}

conditions.hasNotIndicatedSponsorEmployment = hasNotIndicatedPreviousEmployment('sponsor')

conditions.hasNotIndicatedBeneficiaryEmployment = hasNotIndicatedPreviousEmployment('beneficiary')

conditions.hasNotIndicatedBeneficiaryOutsideEmployment = (data) => {
  return data.beneficiary.outside_employment.has_previous_employment !== 'yes'
}

conditions.sponsorHasNoGreenCard = hasNoGreenCard('sponsor')
conditions.sponsorHasNoRecentPaystubs = hasNoRecentPaystubs('sponsor')

conditions.sponsorHasNoEmploymentVerification = hasNoEmploymentVerification('sponsor')

conditions.beneficiaryHasNoBirthCertificate = hasNoBirthCertificate('beneficiary')

conditions.sponsorHasNotIndicatedDifferentMailingAddress = (data) =>
  data.sponsor.has_different_mailing_address !== 'yes'

conditions.beneficiaryHasNoPreviousNameDocumentForField = (data, field) => {
  const previousName = data.beneficiary.previous_names.items[field.currentIndex] // B20-1267
  return Boolean(previousName.no_name_document)
}

conditions.sponsorHasNoPreviousNameDocumentForField = (data, field) => {
  const previousName = data.sponsor.previous_names.items[field.currentIndex]
  return Boolean(previousName.no_name_document)
}

conditions.isSponsor = (data) => {
  return Boolean(data.account.role === 'sponsor') // B20-1267
}

conditions.isBeneficiary = (data) => {
  return Boolean(data.account.role === 'beneficiary') // B20-1267
}

conditions.beneficiaryNotInUS = (data) => {
  return Boolean(data['eligibility'].beneficiary_not_in_us)
}

conditions.filingInIllinois = (data) => {
  return Boolean(data['filing_in_illinois'] === 'yes')
}

conditions.viewingFirstPanelOfGroup = function (data, field) {
  return field.currentIndex === 0
}

conditions.isAOSorCP2 = (data) => {
  return isAOSorCP2(data)
}

conditions.isPublicChargeAndAOSorCP2 = (data) => {
  return !isPublicChargeDisabled() && isAOSorCP2(data)
}

conditions.isPublicChargeDisabled = () => {
  return isPublicChargeDisabled()
}

conditions.beneHasSsn = (data) => {
  return Boolean(data.beneficiary.no_ssn === false)
}

conditions.sponsorHasNotFiledIncomeTaxReturns = (data) => {
  const qualifier = _get(data, ['sponsor', 'has_filed_income_tax_returns'])

  return !qualifier || qualifier === 'no'
}

export default conditions
