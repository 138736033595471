import { TOGGLE_REVIEW_MODE, ENABLE_REVIEW_MODE, DISABLE_REVIEW_MODE } from 'lib/constants'

import u from 'updeep'

const initialState = {
  reviewMode: false
}

export default function panelReducer(state, action) {
  state = state || initialState

  switch (action.type) {
    case TOGGLE_REVIEW_MODE: {
      return u(
        {
          reviewMode: !state.reviewMode
        },
        state
      )
    }
    case ENABLE_REVIEW_MODE: {
      return u(
        {
          reviewMode: true
        },
        state
      )
    }
    case DISABLE_REVIEW_MODE: {
      return u(
        {
          reviewMode: false
        },
        state
      )
    }
    default:
      return state
  }
}
