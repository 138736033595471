import React from 'react'
import { connect } from 'react-redux'

import { getCurrentSectionName } from 'reducers/selectors'
import panelLocaleService from 'services/panel_locale_service'
import SectionCompleteGrayIcon from 'components/icons/section_complete_gray_icon'

interface Props {
  sectionName: string
}

class SectionEndInterstitial extends React.Component<Props> {
  handleChatTriggerClick = (event) => {
    event.preventDefault()
    event.nativeEvent.stopImmediatePropagation()

    this.props.openChat({ via: 'SectionEndInterstitial' })
  }

  render() {
    const sectionDisplayName = panelLocaleService.t(`sections.${this.props.sectionName}.title`)

    return (
      <div className="o-box o-box--copious">
        <div className="o-block o-layout o-layout--center">
          <SectionCompleteGrayIcon className="c-icon--gigantic" />
        </div>
        <div className="o-box o-box--copious">
          <div className="o-layout o-layout--center o-block o-block--copious o-grid--fluid">
            <div className="o-grid__cell--12/12">
              <h1 className="c-type c-type--headline-md c-type--emphasized">
                You&#39;re done with your {sectionDisplayName}!
              </h1>
            </div>
          </div>
          <div className="o-layout o-layout--center o-block o-block--copious o-grid--fluid">
            <div className="o-grid__cell--12/12">
              <h2 className="c-type c-type--headline-sm">
                Click below to return to your dashboard and continue your application.
              </h2>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    sectionName: getCurrentSectionName(state)
  }
}

export default connect(mapStateToProps, null)(SectionEndInterstitial)
