import React from 'react'

type FillStyle = 'slim'

export interface Props {
  progress: number
  className?: string
  fillStyles: FillStyle[]
  [containerPropKey: string]: any
}

function buildFillStyles(styles: FillStyle[]): string {
  if (styles) {
    return styles.map((style) => `c-progress-bar__filled--${style}`).join(' ')
  }

  return ''
}

export default function Vertical(props: Props) {
  const { progress, className, fillStyles } = props
  const classes = `c-progress-bar c-progress-bar--vertical ${className || ''}`
  const progressMultiplier = 0.936

  const style = {
    height: `${progress * progressMultiplier}vh`
  }
  const title = `You have completed ${progress}% of your application.`

  return (
    <div className={classes}>
      <a className={`c-progress-bar__filled ${buildFillStyles(fillStyles)}`} style={style} title={title} />
    </div>
  )
}
