import React, { ChangeEventHandler, FunctionComponent, KeyboardEventHandler } from 'react'

interface Props {
  inputName: string
  value?: string
  classNameSize?: string
  placeholder?: string
  onChangeEvent: ChangeEventHandler
  onBlur?: ChangeEventHandler
  keyCapture?: KeyboardEventHandler
  isValid?: boolean
  validationMessage?: string
  disabled?: boolean
}

const defaultProps = {
  onBlur: () => {},
  value: '',
  classNameSize: '',
  placeholder: '',
  keyCapture: () => {}
}

const CommonInputV2: FunctionComponent<Props & React.InputHTMLAttributes<HTMLInputElement>> = ({
  children,
  inputName,
  value,
  classNameSize,
  placeholder,
  onChangeEvent,
  keyCapture,
  onBlur,
  isValid,
  validationMessage,
  disabled
}) => {
  let classSize = 'o-grid__cell--9/12'
  if (classNameSize) {
    classSize = classNameSize
  }
  return (
    <div className={`${classSize} o-grid__cell--off@sm c-paper-form__segment c-paper-form__segment--drop@sm`}>
      <input
        className="c-paper-form__control"
        name={inputName}
        value={value}
        onKeyDownCapture={keyCapture}
        placeholder={placeholder}
        onChange={onChangeEvent}
        onBlur={onBlur}
        disabled={disabled}
      />
      {children}
      {typeof isValid === 'boolean' && !isValid ? (
        <div className="o-flag__item o-flag__item--drop@sm c-tooltip c-tooltip--danger" key={validationMessage}>
          <div className="c-type c-type--body-sans-sm">{validationMessage}</div>
        </div>
      ) : null}
    </div>
  )
}

CommonInputV2.defaultProps = defaultProps

export default CommonInputV2
