import React from 'react'

export interface Props {
  prefix: Nullable<string | number>
}

export default class CountryCodePrefix extends React.Component<Props> {
  render() {
    const { prefix } = this.props

    return <span className="c-type c-type--body-sans-md">{prefix ? `+${prefix}` : ''}</span>
  }
}
