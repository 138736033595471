import React, { FunctionComponent, useEffect } from 'react'
import ReactMarkdown from 'react-markdown'

import { MarkdownLinkRendererUnderline } from 'lib/markdown'
import { isElementWithinViewport } from 'lib/ui_helpers'
import LightbulbIcon from 'components/icons/lightbulb_icon'
import HandClickIcon from 'components/icons/hand_click_icon'
import LightningBoltIcon from 'components/icons/lightning_bolt_icon'

interface Props {
  text: string | null
  variant?: string
}

/**
 * The just-in-time feedback panel appears below some questions
 * and renders contextual information.
 */
const JITFeedback: FunctionComponent<Props> = ({ text, variant = 'default' }) => {
  const backgroundColor = variant === 'headsUp' ? 'o-box--heads-up-box' : 'o-box--primary'
  const iconColor = variant === 'headsUp' ? 'c-icon--heads-up-box' : 'c-icon--primary'
  const textColor = variant === 'headsUp' ? 'c-type--heads-up-box' : 'c-type--primary'

  /**
   * Scroll to the just-in-time panel if it's not within the viewport
   */
  const scrollToContinueButton = () => {
    const continueButton = document.getElementById('continue-button-desktop')
    if (
      continueButton != null &&
      !isElementWithinViewport(continueButton) &&
      continueButton.scrollIntoView != null // IE <8 and Safari <5.1 don't support this
    ) {
      continueButton.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }
  }

  useEffect(() => {
    setTimeout(scrollToContinueButton, 150)
  }, [text])

  const getRelevantIcon = () => {
    if (variant === 'headsUp') {
      return <HandClickIcon className={`${iconColor}`} />
    } else if (variant === 'weGotYou') {
      return <LightningBoltIcon className={`${iconColor}`} />
    } else {
      return <LightbulbIcon className={`${iconColor}`} />
    }
  }

  if (text == null) {
    return null
  }

  return (
    <div className={`o-block o-layout o-layout--padded-x o-layout--left o-box ${backgroundColor}`}>
      <div className="o-grid--fluid">
        <div className="o-grid__cell--1/12">{getRelevantIcon()}</div>
        <div className={`o-grid__cell--11/12 c-type c-type--body-sans-sm c-type--react-markdown ${textColor}`}>
          <ReactMarkdown source={text} renderers={{ Link: MarkdownLinkRendererUnderline }} />
        </div>
      </div>
    </div>
  )
}

export default JITFeedback
