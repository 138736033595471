import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'

import { toggleContextualHelp } from '../../../../actions/help_actions'
import Button from '../../../../components/button'
import GreenCircleCheckmarkListIcon from '../../../../components/icons/green_circle_checkmark_list_icon'
import { PaymentDetails } from '../lib/types'
import { numberToLocaleString } from 'lib/utilities/text_parsing'

interface ExplicitProps {
  isMobile?: boolean
  numberOfChildren: number
  onClickEssentialServiceBtn: () => void
  onClickPremiumServiceBtn: () => void
  paymentDetails: PaymentDetails
}

interface MappedActions {
  toggleContextualHelp: (args: { via: any; desktopOnly: any }) => void
}

type Props = ExplicitProps & MappedActions

const PaymentSelectService: FunctionComponent<Props> = ({
  isMobile,
  numberOfChildren,
  onClickEssentialServiceBtn,
  onClickPremiumServiceBtn,
  paymentDetails
}) => {
  const getChildrenBreakdown = () => {
    if (numberOfChildren === 1) {
      return 'For 1 adult, 1 child'
    } else if (numberOfChildren > 1) {
      return `For 1 adult, ${numberOfChildren} children`
    }
  }

  const featureItem = (text: string, firstItem: boolean) => {
    return (
      <li className={`flex mb-2 ${firstItem ? 'mt-2' : ''}`}>
        <GreenCircleCheckmarkListIcon className="mr-2" />
        {text}
      </li>
    )
  }

  const essentialFeatures = (
    <>
      {featureItem('Complete immigration application, shipped directly to USCIS', true)}
      {featureItem('Comprehensive review of all government forms and documents', false)}
      {featureItem('Unlimited live support', false)}
      {featureItem('Guaranteed approval, or your money back', false)}
    </>
  )

  const premiumFeatures = (
    <>
      {featureItem('All Essential services and guarantees are included', true)}
      {featureItem('One legal consultation with an independent immigration lawyer', false)}
      {featureItem('Attorney review and feedback for your entire application', false)}
      {featureItem('Personalized interview preparation with an immigration lawyer', false)}
    </>
  )

  const { essential, premium } = paymentDetails

  // Ideally, this component would be able to render any number of service columns, but for now
  // we are saving time by reusing the two column fixed layout from the original component designs
  // When we need to add more columns, we can refactor this component to be more flexible as it will
  // require a redesign anyway and likely be in BUI.
  return (
    <>
      <div className="border border-default c-sheet__body c-type--emphasized p-0 position-relative text-left">
        <div className="box-header c-type c-type--subhead-sm">MOST POPULAR</div>
        <div className="bg-essential flex justify-between">
          <div className="bg-essential c-type flex-basis-0 flex-1 p-4 pt-6">
            <h4 className="c-type--body-sans-md font-bold mb-4">Essential application service</h4>

            <div className="mb-3">
              From <strong>${numberToLocaleString(essential.total_boundless_fees)}</strong>
            </div>
            {numberOfChildren > 0 && (
              <div className="mb-4">
                <span className="c-type--body-sans italic">{getChildrenBreakdown()}</span>
              </div>
            )}
          </div>

          <div className="border-l border-default bg-premium c-type flex-basis-0 flex-1 p-4 pt-6">
            <div className="c-type">
              <h4 className="c-type--body-sans-md font-bold mb-4">Premium application service</h4>

              <div className="mb-3">
                From <strong>${numberToLocaleString(premium.total_boundless_fees)}</strong>
              </div>

              {numberOfChildren > 0 && (
                <div className="mb-4">
                  <span className="c-type--body-sans italic">{getChildrenBreakdown()}</span>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="flex justify-between">
          <div className="c-type flex-basis-0 flex-1 p-4">
            <ul className="c-type c-type--body-sans-sm o-list-bare">{essentialFeatures}</ul>
          </div>

          <div className="border-l border-default c-type flex-basis-0 flex-1 p-4">
            <ul className="c-type c-type--body-sans-sm o-list-bare">{premiumFeatures}</ul>
          </div>
        </div>

        <div className="flex justify-between">
          <div className="c-type flex-basis-0 flex-1 p-4">
            <Button
              block
              color="primary"
              id="select-just-bondless-button"
              label={'Select'}
              onClick={onClickEssentialServiceBtn}
            />
          </div>
          <div className="border-l border-default c-type flex-basis-0 flex-1 p-4">
            <Button
              block
              color="primary"
              id="select-determine-fees-button"
              label={'Select'}
              onClick={onClickPremiumServiceBtn}
            />
          </div>
        </div>
      </div>
    </>
  )
}

function mapDispatchToActions(dispatch): MappedActions {
  return {
    toggleContextualHelp: (...args) => dispatch(toggleContextualHelp(...args))
  }
}

export default connect(null, mapDispatchToActions)(PaymentSelectService) as FunctionComponent<ExplicitProps>
