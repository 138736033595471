import React, { FunctionComponent } from 'react'
import Markdown from 'components/markdown'

interface Props {
  question: string
  toggleContextualHelp: (args: any) => void
}

const QuestionText: FunctionComponent<Props> = (props) => {
  const { question, toggleContextualHelp } = props

  return (
    <Markdown
      source={question}
      className="c-type--react-markdown o-block c-type c-type--body-serif-md c-type--emphasized"
      options={{ linkToNewTabs: true, toggleContextualHelp }}
    />
  )
}

export default QuestionText
