import i18next from 'i18next'
import { i18nFormatter } from 'lib/i18n/formatter'
import { i18nMissingInterpolationHandler } from 'lib/i18n/interpolation'
import { capitalize } from 'lib/language_helpers'

const autoCapitalize = {
  type: 'postProcessor',
  name: 'autoCapitalize',
  process: (value) => capitalize(value)
}

export default (englishTranslationMap) => {
  const config = {
    fallbackLng: 'en',
    interpolation: {
      format: i18nFormatter
    },
    missingInterpolationHandler: i18nMissingInterpolationHandler,
    postProcess: ['autoCapitalize'],
    resources: {
      en: {
        translation: englishTranslationMap
      }
    }
  }

  return i18next.init(config).use(autoCapitalize)
}
