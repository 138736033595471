import React, { FunctionComponent } from 'react'
import moment from 'moment'
import useStateWithCallback from 'use-state-with-callback'
import { scroller } from 'react-scroll'

import { EmploymentHistory } from 'reducers/employment_histories'
import PencilIcon from 'components/icons/pencil_icon'
import EmploymentFormV2 from './employment_form'
import AlertCircleIcon from 'components/icons/alert_circle_icon'
import { MarkdownLinkRendererUnderline } from 'lib/markdown'
import ReactMarkdown from 'react-markdown'

interface Props {
  /**
   * the EmploymentHistory that will populate this field
   * we call it 'value' so it fits in with other questionnaire fields in the QuestionContainer component
   */
  value: EmploymentHistory
  /**
   * The text to display if we are displaying an `employment_history_gap`. There is default text available
   * if this prop is not set
   */
  gapText?: string
  /**
   * The current page path which is used to determine if we're looking at the beneficiary's employment history
   * or the sponsor's
   */
  currentPagePath?: string
}

const EmploymentHistoryInput: FunctionComponent<Props> = ({ value, gapText, currentPagePath }) => {
  const employmentHistory = value
  const [hideUSEmployment, setHideUSEmployment] = useStateWithCallback(false, () => {})
  const [isEditing, setIsEditing] = useStateWithCallback(false, (isEditing) => {
    if (isEditing) {
      scroller.scrollTo('employmentForm', {
        duration: 500,
        delay: 10,
        smooth: true,
        offset: -100
      })
    }
  })

  const formatDate = (date) => {
    if (date == null) {
      return 'Today'
    }
    return moment(date).format('l')
  }

  const handleClick = (event) => {
    event.preventDefault()
    if (!employmentHistory.start_date) {
      setHideUSEmployment(true)
    }
    setIsEditing(true)
  }

  const handleFormCancel = () => {
    setIsEditing(false)
  }

  const employmentFormSaveCallback = (isFormError: boolean) => {
    if (!isFormError) {
      setIsEditing(false)
    }
  }

  if (isEditing) {
    return (
      <EmploymentFormV2
        cancelCallback={handleFormCancel}
        employmentToEdit={employmentHistory}
        saveCallback={employmentFormSaveCallback}
        currentPagePath={currentPagePath}
        hiddenCountryCodes={hideUSEmployment ? ['US'] : []}
      />
    )
  }

  const defaultGapText = `Need employment from ${formatDate(employmentHistory.start_date)} to ${formatDate(
    employmentHistory.end_date
  )}`

  if (employmentHistory.gap) {
    return (
      <div className="o-block c-paper-form-missing o-layout--padded-x--full" onClick={handleClick}>
        <div className="o-grid--fluid">
          <div className="o-layout--padded-right o-layout--padded-bottom">
            <AlertCircleIcon size="medium" onClick={handleClick} />
          </div>
          <ReactMarkdown
            source={gapText ? gapText : defaultGapText}
            renderers={{ Link: MarkdownLinkRendererUnderline }}
          />
        </div>
      </div>
    )
  }

  return (
    <div className="o-grid--fluid o-block c-paper-form-slim o-layout--padded-x">
      <div className="o-grid__cell--11/12">
        {!employmentHistory.gap && !employmentHistory.unemployed ? (
          <>
            <p>{employmentHistory.employer_name}</p>
            <p>
              {employmentHistory.address.street}, {employmentHistory.address.city}
              &nbsp;
              {employmentHistory.address.province}
            </p>
          </>
        ) : null}
        {employmentHistory.unemployed ? <p>Unemployed or retired</p> : null}
        <p>
          {formatDate(employmentHistory.start_date)} to {formatDate(employmentHistory.end_date)}
        </p>
      </div>
      <div className="o-grid__cell--1/12">
        <PencilIcon
          size="medium"
          className="o-media__figure tool-menu-item-icon o-media--hover"
          onClick={handleClick}
          aria-label="edit this employment"
        />
        {employmentHistory.address_history_mismatch ? (
          <div className="o-flag__item o-flag__item--drop@sm c-tooltip c-tooltip--danger" key={employmentHistory.id}>
            <div className="c-type c-type--body-sans-sm">
              This entry has an address that doesn't match your address history. Please edit this so that it matches.
            </div>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default EmploymentHistoryInput
