import React from 'react'
import { connect } from 'react-redux'

import AskMyAttorney from '../ask_my_attorney'
import PanelErrors from '../panel_errors'

import { isCurrentKasePaid } from 'reducers/selectors'

interface Props {
  isKasePaid: boolean
}

class SetupCompletedIneligible extends React.Component<Props> {
  renderRefundInfo() {
    if (this.props.isKasePaid) {
      return (
        <p className="o-block c-type c-type--body-sans-md">
          <b>To request a full refund:</b> Please email your Application Guide.
        </p>
      )
    }
  }

  render() {
    return (
      <div className="o-grid__row">
        <div className="o-grid__col-6 o-grid__col--offset-1">
          <h1 className="o-block o-block--ample c-type c-type--headline-md c-type--emphasized">
            Unfortunately, Boundless cannot help you at this time.
          </h1>

          <PanelErrors />

          <p className="o-block c-type c-type--body-sans-md">
            If you disagree with this result, email us at <a href="mailto:help@boundless.com">help@boundless.com</a> to
            talk about this further.
          </p>

          {this.renderRefundInfo()}

          <AskMyAttorney />
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    isKasePaid: isCurrentKasePaid(state)
  }
}

export default connect(mapStateToProps)(SetupCompletedIneligible)
