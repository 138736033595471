import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'

import CheckmarkIllustration from 'components/illustrations/checkmark_illustration'
import Paragraph from 'components/type/paragraph'
import { WORKFLOW_STATUSES } from 'lib/constants'
import { getWorkflowCategory } from 'reducers/selectors'

import PostShipmentCopyCP from './post_shipment_copy_cp'
import PostShipmentCopyAOS from './post_shipment_copy_aos'

interface ExplicitProps {
  workflowStatus: WORKFLOW_STATUSES
}

interface PropsFromRedux {
  workflowCategory: 'AOS' | 'CP'
}

type Props = ExplicitProps & PropsFromRedux

const isInPrintshop = (workflowStatus: WORKFLOW_STATUSES) => {
  return (
    [
      WORKFLOW_STATUSES.AOS_READY_FOR_PRINTSHOP,
      WORKFLOW_STATUSES.AOS_IN_PRINTSHOP,
      WORKFLOW_STATUSES.CP_READY_FOR_PRINTSHOP,
      WORKFLOW_STATUSES.CP_IN_PRINTSHOP
    ].indexOf(workflowStatus) !== -1
  )
}

const ShipmentPhase: FunctionComponent<Props> = ({ workflowStatus, workflowCategory }) => {
  if (isInPrintshop(workflowStatus)) {
    return (
      <div className="o-layout--center o-box o-box--spacious">
        <div className="o-block o-block--compact">
          <CheckmarkIllustration />
        </div>
        <h2 className="o-block c-type c-type--headline-md c-type--emphasized">You’re all set for now!</h2>
        <Paragraph>We’re putting together your package and will email you as soon as it ships.</Paragraph>
      </div>
    )
  }

  if (workflowStatus === WORKFLOW_STATUSES.AOS_READY_TO_SHIP || workflowStatus === WORKFLOW_STATUSES.CP_READY_TO_SHIP) {
    return (
      <div className="o-layout--center o-box o-box--spacious">
        <div className="o-block o-block--compact">
          <CheckmarkIllustration />
        </div>
        <h2 className="o-block c-type c-type--headline-md c-type--emphasized">You’re all set for now!</h2>
        <Paragraph>We’ll email you as soon as your package ships.</Paragraph>
      </div>
    )
  }

  if (workflowCategory === 'CP') {
    return <PostShipmentCopyCP workflowStatus={workflowStatus} />
  }

  if (workflowCategory === 'AOS') {
    return <PostShipmentCopyAOS workflowStatus={workflowStatus} />
  }
}

function mapStateToProps(state) {
  return { workflowCategory: getWorkflowCategory(state) }
}

export default connect(mapStateToProps)(ShipmentPhase)
