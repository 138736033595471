/**
 * Replace  single quotes `'` with curly quotes `’`
 */
export function withCurlyQuotes(text?: string) {
  if (!text || typeof text !== 'string') {
    return text
  }
  return text.replace(/'/g, '’')
}

// This is to add commas to a number
export function numberToLocaleString(num?: number) {
  if (num) {
    return num.toLocaleString('en-US')
  } else {
    return ''
  }
}
