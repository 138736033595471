import React from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'

import { clearPanelFields } from 'actions/panel_actions'
import { InputProps } from 'components/forms/inputs'

import BufferedFieldValue from 'components/forms/buffered_field_value'

export type Choice = string | number | boolean

type Props = InputProps & {
  clearPanelFields: Function
  choices: Choice[]
  choiceLabels: { [choice: string]: string }
  label?: string
  noWrap?: boolean
  shouldClearPanelFields?: boolean
}

export function choiceToString(choice: Nullable<Choice>): string {
  if (choice == null) return ''

  switch (choice) {
    case true: {
      return 'true'
    }

    case false: {
      return 'false'
    }

    default: {
      return choice.toString()
    }
  }
}

function serializeInputValue(value: string): any {
  switch (value) {
    case 'true': {
      return true
    }

    case 'false': {
      return false
    }

    default: {
      return value
    }
  }
}

class RadioInput extends React.Component<Props> {
  static defaultProps = {
    choices: ['Yes', 'No']
  }

  getChoiceLabel(choiceString: string): string {
    return this.props.choiceLabels[choiceString] || ''
  }

  // Clear any excused fields when
  // an new input is selected
  clearFields = () => {
    if (this.props.shouldClearPanelFields) {
      this.props.clearPanelFields({
        excludeFields: [this.props.name]
      })
    }
  }

  shouldWrapLayout() {
    if (this.props.noWrap) {
      return false
    }

    return this.props.choices.length > 4 || this.props.label
  }

  handleChange = (onChange: Function, fireValidation: Function) => (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event)
    fireValidation()
  }

  renderChoices(wrapperClasses: string, classes: string) {
    const { disabled, name, path, afterChangeEvents, fireValidation } = this.props

    return (
      <div className={wrapperClasses}>
        <BufferedFieldValue
          afterChangeEvents={(afterChangeEvents || []).concat([this.clearFields])}
          formatter={choiceToString}
          path={path}
          serializer={serializeInputValue}
          className={classes}
        >
          {(value, onChange) =>
            this.props.choices.map((choice) => {
              const choiceString = choiceToString(choice)
              const checked = value === choiceString

              const classes = classNames('o-block', {
                'o-grid__cell--6/12 o-grid--fill-y@sm': this.shouldWrapLayout()
              })

              return (
                <div className={classes} key={choiceString}>
                  <label className={classNames('o-block', 'c-custom-control', 'c-custom-control--radio')}>
                    <input
                      type="radio"
                      className="c-custom-control__input"
                      id={`form-${name}-${choiceString}`}
                      disabled={disabled}
                      name={name}
                      data-model-path={path}
                      value={choice}
                      checked={checked}
                      onChange={this.handleChange(onChange, fireValidation)}
                    />

                    <span className="c-custom-control__indicator" />

                    <div className="c-custom-control__description c-type c-type--body-sans-md">
                      {this.getChoiceLabel(choiceString)}
                    </div>
                  </label>
                </div>
              )
            })
          }
        </BufferedFieldValue>
      </div>
    )
  }

  renderWithLabel() {
    const wrapperClasses = 'o-grid__cell--9/12 o-grid--fluid o-grid__cell--off@sm o-layout--vertical-bottom'

    return (
      <div className="o-grid--fluid o-block--grid o-grid--stack@sm ${this.props.className}">
        <div className="o-grid__cell--3/12 o-grid__cell--off@sm">
          <div className="o-block o-block--tight c-paper-form__label c-paper-form__segment" key="label">
            <span className="c-type c-type--subhead-sm">{this.props.label}</span>
          </div>
        </div>

        {this.renderChoices(wrapperClasses, '')}
      </div>
    )
  }

  renderWithoutLabel() {
    const fieldClasses = classNames(this.props.className, {
      'o-grid--fluid': this.shouldWrapLayout(),
      'o-block--grid': this.shouldWrapLayout()
    })
    return this.renderChoices('', fieldClasses)
  }

  render() {
    return this.props.label ? this.renderWithLabel() : this.renderWithoutLabel()
  }
}

function mapDispatchToActions(dispatch) {
  return {
    clearPanelFields: (...args) => dispatch(clearPanelFields(...args))
  }
}

export default connect(null, mapDispatchToActions)(RadioInput)
