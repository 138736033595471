import React, { FunctionComponent } from 'react'
import IconWrapper, { IconWrapperExternalProps } from './icon_wrapper'

const SolidQuestionMarkIcon: FunctionComponent<IconWrapperExternalProps> = (props) => (
  <IconWrapper {...props} viewBox="0 0 18 18" fill="none">
    <path
      d="M8.95817 14.0003C9.24984 14.0003 9.4965 13.8995 9.69817 13.6978C9.89928 13.4967 9.99984 13.2503 9.99984 12.9587C9.99984 12.667 9.89928 12.4206 9.69817 12.2195C9.4965 12.0178 9.24984 11.917 8.95817 11.917C8.6665 11.917 8.41984 12.0178 8.21817 12.2195C8.01706 12.4206 7.9165 12.667 7.9165 12.9587C7.9165 13.2503 8.01706 13.4967 8.21817 13.6978C8.41984 13.8995 8.6665 14.0003 8.95817 14.0003ZM8.20817 10.792H9.74984C9.74984 10.3337 9.80206 9.97255 9.9065 9.70866C10.0104 9.44477 10.3054 9.08366 10.7915 8.62532C11.1526 8.26421 11.4373 7.92032 11.6457 7.59366C11.854 7.26755 11.9582 6.87532 11.9582 6.41699C11.9582 5.63921 11.6734 5.04199 11.104 4.62532C10.5346 4.20866 9.86095 4.00032 9.08317 4.00032C8.2915 4.00032 7.64928 4.20866 7.1565 4.62532C6.66317 5.04199 6.31928 5.54199 6.12484 6.12532L7.49984 6.66699C7.56928 6.41699 7.72567 6.14616 7.969 5.85449C8.21178 5.56283 8.58317 5.41699 9.08317 5.41699C9.52761 5.41699 9.86095 5.53838 10.0832 5.78116C10.3054 6.02449 10.4165 6.29199 10.4165 6.58366C10.4165 6.86144 10.3332 7.12171 10.1665 7.36449C9.99984 7.60782 9.7915 7.83366 9.5415 8.04199C8.93039 8.58366 8.55539 8.99338 8.4165 9.27116C8.27761 9.54893 8.20817 10.0559 8.20817 10.792ZM8.99984 17.3337C7.84706 17.3337 6.76373 17.1148 5.74984 16.677C4.73595 16.2398 3.854 15.6462 3.104 14.8962C2.354 14.1462 1.76039 13.2642 1.32317 12.2503C0.885393 11.2364 0.666504 10.1531 0.666504 9.00032C0.666504 7.84755 0.885393 6.76421 1.32317 5.75033C1.76039 4.73644 2.354 3.85449 3.104 3.10449C3.854 2.35449 4.73595 1.7606 5.74984 1.32283C6.76373 0.885603 7.84706 0.666992 8.99984 0.666992C10.1526 0.666992 11.2359 0.885603 12.2498 1.32283C13.2637 1.7606 14.1457 2.35449 14.8957 3.10449C15.6457 3.85449 16.2393 4.73644 16.6765 5.75033C17.1143 6.76421 17.3332 7.84755 17.3332 9.00032C17.3332 10.1531 17.1143 11.2364 16.6765 12.2503C16.2393 13.2642 15.6457 14.1462 14.8957 14.8962C14.1457 15.6462 13.2637 16.2398 12.2498 16.677C11.2359 17.1148 10.1526 17.3337 8.99984 17.3337Z"
      fill="#3243B2"
    />
  </IconWrapper>
)

export default SolidQuestionMarkIcon
