import React, { ReactNode } from 'react'
import { connect } from 'react-redux'
import _capitalize from 'lodash/capitalize'
import _partial from 'lodash/partial'

import { renderKaseModel } from '../../render_kase_model'
import formatDate from '../../../lib/format_date'
import { showPanel } from '../../../actions/panel_actions'
import { getModelValue } from 'reducers/selectors'

interface Props {
  children: any
  editHref: string
  getValueAtPath: Function
  label: string | ReactNode
  path: Nullable<string>
  showPanel: Function
  value: Nullable<any>
}

class SectionEntry extends React.Component<Props> {
  primitiveType(value) {
    // need to do this because apparently `typeof null => 'object'`
    if (value === null) {
      return 'null'
    }

    switch (typeof value) {
      case 'string': {
        if (/^\d{4}-\d{2}-\d{2}$/.test(value)) {
          return 'date'
        } else {
          return 'string'
        }
      }

      case 'number': {
        return 'number'
      }

      case 'boolean': {
        return 'boolean'
      }

      default: {
        throw new Error(`Unknown primitive type ${value}`)
      }
    }
  }

  renderPrimitive(value) {
    switch (this.primitiveType(value)) {
      case 'string': {
        return _capitalize(value)
      }

      case 'number': {
        return value
      }

      case 'date': {
        return formatDate.reviewScreen(value)
      }

      case 'boolean': {
        return value ? 'Yes' : 'No'
      }

      case 'null': {
        return null
      }

      default: {
        throw new Error(`No primitive renderer defined for ${value}`)
      }
    }
  }

  entryValue(value) {
    if (!value) {
      return null
    } else if (value.type) {
      return renderKaseModel(value)
    } else {
      return this.renderPrimitive(value)
    }
  }

  entryValueForPath(path) {
    const value = this.props.getValueAtPath(path)

    return this.entryValue(value)
  }

  renderValue() {
    const { path, children, value } = this.props

    if (children) return children
    if (path) return this.entryValueForPath(path) || '-'
    if (value) return this.entryValue(value) || '-'
  }

  editClicked = (event) => {
    event.preventDefault()

    this.props.showPanel({
      slug: this.props.editHref,
      via: 'ReviewPageEdit',
      reviewMode: true
    })
  }

  render() {
    const { editHref, label } = this.props

    const editLink = (
      <span className="c-type c-type--link c-type--body-sans-sm">
        {editHref && (
          <a href={`/petition/${editHref}`} onClick={this.editClicked}>
            Edit
          </a>
        )}
      </span>
    )

    return (
      <div className="o-grid--fluid">
        <div className="o-layout--padded-right o-grid__cell--4/12 o-block o-block--tight">
          <span className="c-type c-type--body-sans-md c-type--muted">
            {label} <span className="hidden-md-up">{editLink}</span>
          </span>
        </div>

        <div className="o-grid__cell--7/12 o-block">
          <div className="c-type c-type--body-sans-md c-type--emphasized">{this.renderValue()}</div>
        </div>

        <div className="o-layout--right o-grid__cell--1/12 hidden-sm-down">{editLink}</div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    getValueAtPath: _partial(getModelValue, state)
  }
}

function mapDispatchToActions(dispatch) {
  return {
    showPanel: (...args) => dispatch(showPanel(...args))
  }
}

export default connect(mapStateToProps, mapDispatchToActions)(SectionEntry)
