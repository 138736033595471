import React, { ChangeEventHandler, FunctionComponent, ReactNode } from 'react'
import cx from 'classnames'

interface Props {
  classNames?: string
  label: ReactNode
  name: string
  id: string
  checked?: boolean
  disabled?: boolean
  onChangeEvent: ChangeEventHandler
}

const CommonCheckboxV2: FunctionComponent<Props> = ({
  classNames,
  label,
  name,
  id,
  checked = false,
  disabled,
  onChangeEvent = () => {}
}) => {
  const classes = cx('o-flag o-grid--fluid o-block c-paper-form__group', classNames)

  return (
    <div className={classes}>
      <label className="o-block o-block--compact c-custom-control c-custom-control--check c-custom-control--multiline">
        <div className="c-custom-control__multiline-wrapper">
          <input
            checked={checked}
            className="c-custom-control__input"
            id={id}
            name={name}
            onChange={onChangeEvent}
            type="checkbox"
            disabled={disabled}
          />
          <span className="c-custom-control__indicator" />
        </div>

        <span className="c-custom-control__description c-type c-type--body-sans-md">{label}</span>
      </label>
    </div>
  )
}

export default CommonCheckboxV2
