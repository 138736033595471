import React, { FunctionComponent } from 'react'
import { TeamMemberInfo } from 'reducers/dashboard'
import Paragraph from 'components/type/paragraph'
import { JOB_TITLE_OPTIONS } from 'lib/constants'
import ProfilePicturePlaceholder from 'components/screens/dashboard/customer_dashboard/profile_picture_placeholder'

export const TeamMember: FunctionComponent<TeamMemberInfo> = ({ fullName, jobTitle, profilePicturePath }) => {
  const isJobTitleCustomerSupport = () => {
    return jobTitle === JOB_TITLE_OPTIONS.JOB_TITLE_CUSTOMER_SUPPORT
  }

  return (
    <div className="o-griddle__row o-griddle__row--align-items-center">
      <div className="o-griddle__col--auto">
        {profilePicturePath && (
          <img className="c-dashboard__profile-img" src={profilePicturePath} alt={`${fullName}'s profile picture`} />
        )}

        {!profilePicturePath && (
          <ProfilePicturePlaceholder
            initial={fullName.charAt(0)}
            className="c-img--circle"
            style={{ width: '3.5rem', height: '3.5rem' }}
          />
        )}
      </div>
      <div className="o-griddle__col pl-0 o-box o-box--off">
        <Paragraph bold spacing="xs" className="c-dashboard__profile-name" data-qa="team-member-name">
          {fullName}
        </Paragraph>

        {!isJobTitleCustomerSupport() && <Paragraph data-qa="team-member-title">{jobTitle}</Paragraph>}
      </div>
    </div>
  )
}
