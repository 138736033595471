import u from 'updeep'
import {
  LOADING_QUESTIONNAIRE,
  LOADING_QUESTIONNAIRE_SUCCESS,
  UPDATING_QUESTIONNAIRE,
  UPDATING_QUESTIONNAIRE_SUCCESS
} from 'lib/constants'
import { Questionnaire } from 'components/screens/questionnaire/lib/types'

export interface QuestionnaireStore {
  data: Questionnaire
  isLoading: boolean
  isUpdating: boolean
}

const initialState: QuestionnaireStore = {
  data: null,
  isLoading: false,
  isUpdating: false
}

const setSectionOrder = (questionnaire: Questionnaire): Questionnaire => {
  // first check for the presence of the questionnaire_sections attribute as
  // the v2 questionnaire api response does not include this attribute
  if (!questionnaire.attributes.questionnaire_sections) {
    return questionnaire
  }

  const orderedSections = questionnaire.attributes.questionnaire_sections.sort((a, b) => {
    return a.order - b.order
  })
  questionnaire.attributes.questionnaire_sections = orderedSections

  return questionnaire
}

export default function questionnaireReducer(state: QuestionnaireStore, action: any): QuestionnaireStore | {} {
  state = state || initialState
  switch (action.type) {
    case LOADING_QUESTIONNAIRE: {
      return u(
        {
          isLoading: action.payload
        },
        state
      )
    }

    case LOADING_QUESTIONNAIRE_SUCCESS: {
      return u(
        {
          data: setSectionOrder(action.payload.data)
        },
        state
      )
    }

    case UPDATING_QUESTIONNAIRE: {
      return u(
        {
          isUpdating: action.payload
        },
        state
      )
    }

    case UPDATING_QUESTIONNAIRE_SUCCESS: {
      return u(
        {
          data: setSectionOrder(action.payload.data)
        },
        state
      )
    }

    default: {
      return state
    }
  }
}
