import ContinueButton from './continue_button'
import PreviousButton from './previous_button'
import ProgressIndicator from './progress_indicator'

const Navigation = {
  ContinueButton,
  PreviousButton,
  ProgressIndicator
}

export default Navigation
