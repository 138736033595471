import URL from 'url-parse'
import _some from 'javascript/lib/utilities/some'
import Cookies from 'js-cookie'

import { isProductionEnv } from 'lib/settings'

const localHostnames = ['localhost', '127.0.0.1', 'boundless.test']

function isProductionEnvironment() {
  return process.env.NODE_ENV === 'production'
}

function isLocal(hostname) {
  return _some(localHostnames, (localHostname) => {
    return !!hostname.match(new RegExp(`${localHostname}$`))
  })
}

function currentUrl() {
  return URL(window.location.href)
}

export function cookieDomain() {
  const { hostname } = currentUrl()

  // Deal with wpengine staging for now
  if (/staging\.wpengine\.com$/.test(hostname)) {
    return 'wwwstage.boundless.com'
  }

  if (isProductionEnvironment() && /boundless\.com$/.test(hostname)) {
    return '.boundless.com'
  }

  if (/boundless\.test$/.test(hostname)) {
    return '.boundless.test'
  }

  if (isLocal(hostname)) {
    return hostname
  }

  return 'localhost'
}

export const getCookie = (cookieName) => Cookies.get(cookieName)

export const setCookie = ({ name, value, expiration = 365 * 20 }) => {
  const cookieSettings = {
    domain: cookieDomain(),
    expires: expiration,
    secure: isProductionEnv()
  }

  Cookies.set(name, value, cookieSettings)
}
