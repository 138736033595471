import _includes from 'lodash/includes'

import operations from '../lib/update_transformations'
import routeVia from '../lib/route_via'
import { saveData } from './kase_actions'

import { ENABLE_REVIEW_MODE, DISABLE_REVIEW_MODE } from 'lib/constants'

import {
  getFirstPanelOfSection,
  getNextPanel,
  getPanelBySlug,
  getPreviousPanel,
  getAllFieldsInCurrentPanel
} from 'reducers/selectors'

function clearedValueForField(field) {
  switch (field.type) {
    case 'collection_checkboxes':
      return []
    case 'dictionary_checkboxes':
      return []
    case 'address':
      return {}
    case 'phone':
      return {}
    case 'upload':
      return {}
    default:
      return null
  }
}

export function clearPanelFields({ excludeFields = [] }) {
  return (dispatch, getState) => {
    const fields = getAllFieldsInCurrentPanel(getState())
    fields
      .filter((field) => field.type !== 'upload')
      .filter((field) => !_includes(excludeFields, field.name))
      .forEach((field) => {
        dispatch(
          saveData({
            path: field.path,
            operation: operations.replaceValue(clearedValueForField(field))
          })
        )
      })
  }
}

export function showPanel(
  { panel = null, slug = null, reviewMode = false, via, changingSections = false },
  action = 'push'
) {
  return (dispatch, getState) => {
    panel = panel || getPanelBySlug(getState(), slug)

    if (!panel) {
      return
    }

    dispatch({
      type: reviewMode ? ENABLE_REVIEW_MODE : DISABLE_REVIEW_MODE
    })

    let query = changingSections ? null : window.location.search
    dispatch(routeVia(action, via, query, panel))
  }
}

export function showSection(sectionName) {
  return (dispatch, getState) => {
    const panel = getFirstPanelOfSection(getState(), sectionName)

    dispatch(showPanel({ panel, changingSections: true }))
  }
}

export function showPreviousPanel({ via }) {
  return (dispatch, getState) => {
    const state = getState()
    const previousPanel = getPreviousPanel(state)

    return dispatch(showPanel({ panel: previousPanel, via }))
  }
}

export function showNextPanel({ via }) {
  return (dispatch, getState) => {
    const state = getState()
    const nextPanel = getNextPanel(state)

    return dispatch(showPanel({ panel: nextPanel, via }))
  }
}

export function showPetitionReviewPanel({ via }) {
  return (dispatch, getState) => {
    const state = getState()
    const nextPanel = getNextPanel(state)

    return dispatch(showPanel({ panel: nextPanel, via }))
  }
}
