import { AxiosRequestConfig } from 'axios'
import jwtStorage from './jwt_storage'

const authorizationHeader = () => {
  const headers = {}

  const token = jwtStorage.getToken()

  if (token) {
    headers['Authorization'] = token
  }
  return headers
}

export function addAuthorizationToRequest(config: AxiosRequestConfig) {
  config.headers = Object.assign({}, authorizationHeader(), config.headers || {})

  return config
}
