import React from 'react'
import _map from 'lodash/map'

import BufferedFieldValue from 'components/forms/buffered_field_value'

import { phoneCountryCodeMap } from 'data/phone'
import { InputProps } from 'components/forms/inputs'

const countryCodes = phoneCountryCodeMap()

interface Props extends InputProps {
  countryCodeNumberPath: string
}

class CountryCodeInput extends React.Component<Props> {
  static defaultProps = {
    afterChangeEvents: [],
    className: '',
    disabled: false,
    placeholder: ''
  }

  updateCountryCode = ({ value, changeValueAtPath }) => {
    const isoCountryCode = value

    changeValueAtPath(this.props.countryCodeNumberPath, {
      value: countryCodes[isoCountryCode].countryCode
    })
  }

  blankCountryOption(value: Nullable<string>) {
    const disabled = Boolean(value)

    return (
      <option disabled={disabled} hidden={disabled} value="">
        Select a country code
      </option>
    )
  }

  render() {
    const { className, id, onBlur, path } = this.props

    const afterChangeEvents = this.props.afterChangeEvents.concat([this.updateCountryCode])

    return (
      <BufferedFieldValue afterChangeEvents={afterChangeEvents} path={path}>
        {(value, onChange) => (
          <select
            id={id}
            name="iso_country_code"
            data-model-path={path}
            className={className}
            required={true}
            value={value}
            onBlur={onBlur}
            onChange={onChange}
            autoComplete="off"
          >
            {this.blankCountryOption(value)}

            {_map(countryCodes, (info, isoCode) => {
              return (
                <option key={`code-${isoCode}`} value={isoCode}>
                  {info.name} (+{info.countryCode})
                </option>
              )
            })}
          </select>
        )}
      </BufferedFieldValue>
    )
  }
}

export default CountryCodeInput
