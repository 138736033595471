import React, { Component } from 'react'

import SetupCompletedActionButton from '../../panels/outcomes/setup_completed_action_button'
import Modal from '../modal'

interface Props {
  isActive: boolean
}

export default class MobileValuePropsModal extends Component<Props> {
  render() {
    return (
      <Modal isActive={this.props.isActive} title="How Boundless Helps You">
        {({ closeModal }) => (
          <div>
            <p className="o-block o-block--ample c-type c-type--body-sans-md">Immigrate with confidence.</p>

            <p className="o-block o-block--ample c-type c-type--body-sans-md">
              We’ll guide you through simple questions that will help you complete all required forms (I-130, I-485,
              work and travel permits, as applicable) for a marriage green card. An independent immigration attorney
              will then review all of your materials and answer any questions you have &mdash; for no additional fee! In
              the end, you’ll get a ready-to-file application package at your doorstep.
            </p>

            <div className="o-block o-layout o-layout--center" onClick={closeModal({ via: 'MobileValuePropsModal' })}>
              <SetupCompletedActionButton btnClass="c-btn--block" btnText="Continue Application" />
            </div>
          </div>
        )}
      </Modal>
    )
  }
}
