import React, { FunctionComponent } from 'react'
import DocumentRequestUploadArea from 'components/screens/document_upload_v2/document_request_upload_area'
import { deleteDocument, fetchDocumentSets } from 'actions/document_request_actions_v2'

import { PartContent, DocumentRequestModelV2, DocumentRequestPartModel } from 'reducers/documents_v2'
import { getCurrentKaseId, getIsFileUploading, getUploadsForDocumentRequestPart } from 'reducers/selectors'
import ListedDocument from '../listed_document'
import Button from 'components/button'
import TrashIcon from 'components/icons/trash_icon'
import { connect } from 'react-redux'
import cx from 'classnames'
import MetaQuestion from './meta_question'
import { DocumentState } from 'lib/constants'

interface Props {
  documentRequest: DocumentRequestModelV2
  documentRequestPart: DocumentRequestPartModel
}

interface ActionProps {
  deleteDocument: Function
  fetchDocumentSets: Function
}

interface MappedProps {
  isFileUploading: boolean
  kaseId: number
  uploads: any[]
}

type ComponentProps = Props & ActionProps & MappedProps

const DocumentRequestPart: FunctionComponent<ComponentProps> = ({
  deleteDocument,
  documentRequest,
  documentRequestPart,
  fetchDocumentSets,
  isFileUploading,
  kaseId,
  uploads
}) => {
  const { can_upload, contents, header, limit, id } = documentRequestPart
  const showDocumentRequestPartBoxStyling = id !== 0

  const onDeleteDocument = (event, partContent: PartContent) => {
    event.preventDefault()
    event.stopPropagation()

    deleteDocument(kaseId, partContent.document.id).then(() => fetchDocumentSets(kaseId))
  }

  const renderPartContent = (partContent: PartContent, i: number) => {
    return (
      <div key={i}>
        <ListedDocument
          partContent={partContent}
          inlineControls={
            <div className="o-layout o-layout--right">
              <Button
                onClick={(event) => onDeleteDocument(event, partContent)}
                icon={<TrashIcon />}
                iconOnly
                color="tertiary"
              />
            </div>
          }
          kaseId={kaseId}
          renderThumbnails={true}
        />
        {partContent.meta_questions.map((metaQuestion) => {
          return (
            <MetaQuestion
              key={metaQuestion.id}
              documentId={partContent.document.id}
              isFileUploading={isFileUploading}
              kaseId={kaseId}
              metaQuestion={metaQuestion}
            />
          )
        })}
      </div>
    )
  }
  return (
    <div className={cx('mb-4', { 'c-card c-card--block c-card-horizontal': showDocumentRequestPartBoxStyling })}>
      {showDocumentRequestPartBoxStyling && (
        <h4 className="o-layout--flex o-layout--space-between mb-4">
          <div>{header}</div>
          <div>
            ({contents.length} of {limit} files uploaded)
          </div>
        </h4>
      )}
      {can_upload && (
        <DocumentRequestUploadArea
          documentRequest={documentRequest}
          documentRequestPartId={id}
          uploadLimit={limit - contents.length}
        />
      )}
      {uploads.map((document, i) => (
        <ListedDocument kaseId={kaseId} key={`uploading-doc-${i}`} partContent={document} renderThumbnails={true} />
      ))}
      {contents.map(
        (content, i) =>
          content.document && content.document.state !== DocumentState.Dismissed && renderPartContent(content, i)
      )}
    </div>
  )
}

const mapDispatchToActions = (dispatch: Function): ActionProps => {
  return {
    deleteDocument: (kase_id: number, doc_Id: number) => dispatch(deleteDocument(kase_id, doc_Id)),
    fetchDocumentSets: (kaseId: number) => dispatch(fetchDocumentSets(kaseId))
  }
}

const mapStateToProps = (state: any, ownProps: Props): MappedProps => {
  const { id } = ownProps.documentRequestPart

  return {
    uploads: getUploadsForDocumentRequestPart(state, id),
    kaseId: getCurrentKaseId(state),
    isFileUploading: getIsFileUploading(state)
  }
}

export default connect(mapStateToProps, mapDispatchToActions)(DocumentRequestPart)
