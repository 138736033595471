import React, { FunctionComponent, useMemo } from 'react'
import { connect } from 'react-redux'

import { openModal } from 'actions/modal_actions'
import {
  getCurrentDashboardPhaseForKase,
  getCurrentKaseId,
  getCurrentKaseKind,
  getNavigableSectionsForStopGapDashboard
} from 'reducers/selectors'
import { DASHBOARD_PHASE_BUTTON_TYPE_MAIN_CTA, TELEMETRY_EVENTS, KaseKind } from 'lib/constants'
import { DashboardPhase } from 'components/screens/dashboard/customer_dashboard/phase_map'
import Button from 'components/button'
import formatSectionUrl from 'lib/utilities/format_section_url'

import { postTelemetry } from 'actions/telemetry_actions'

export const PRIMARY_CTA_LABEL_QUESTIONS_REMAINING = 'Continue Questions'
export const PRIMARY_CTA_LABEL_ASSESSMENT_READY = 'Begin Assessment'

export const PRIMARY_CTA_FORM_CHECK = 'Start Checking Forms'

interface Props {
  currentPhase: DashboardPhase
  sections: any[]
  kaseId: number
  kaseKind: KaseKind
  getCurrentKaseKind
  openConfirmationModal: () => void
  trackClickedPhaseDetails: ({ currentPhase, buttonType }: { currentPhase: string; buttonType: string }) => void
  allSectionsFinished: boolean
}

const IntakePrimaryCTA: FunctionComponent<Props> = ({
  currentPhase,
  sections,
  kaseId,
  kaseKind,
  allSectionsFinished,
  openConfirmationModal,
  trackClickedPhaseDetails
}) => {
  const getNextSectionURL = useMemo(() => {
    // sections are ordered, find the first incomplete
    const nextIncompleteSection = sections.find((section) => section.progress !== 100)

    return nextIncompleteSection ? formatSectionUrl(kaseId, nextIncompleteSection.name) : ''
  }, [])

  const onCTAClick = () => {
    if (allSectionsFinished) {
      openConfirmationModal()
    }

    trackClickedPhaseDetails({
      currentPhase,
      buttonType: DASHBOARD_PHASE_BUTTON_TYPE_MAIN_CTA
    })
  }

  if (allSectionsFinished) {
    let buttonLabel =
      kaseKind === KaseKind.NewMarriageBasedGreenCard ? PRIMARY_CTA_FORM_CHECK : PRIMARY_CTA_LABEL_ASSESSMENT_READY
    return <Button color="emphasized" onClick={onCTAClick} label={buttonLabel} />
  }

  return (
    <a
      href={getNextSectionURL}
      target="_blank"
      rel="noopener noreferrer"
      className="c-btn c-btn--emphasized"
      onClick={onCTAClick}
    >
      {PRIMARY_CTA_LABEL_QUESTIONS_REMAINING}
    </a>
  )
}

function mapDispatchToActions(dispatch) {
  return {
    openConfirmationModal: () =>
      dispatch(
        openModal({
          name: 'BeginLawyerReviewConfirmationModal',
          via: `${PRIMARY_CTA_LABEL_ASSESSMENT_READY} button`
        })
      ),
    trackClickedPhaseDetails: (args) => dispatch(postTelemetry(TELEMETRY_EVENTS.CLICKED_PHASE_DETAILS, args))
  }
}

function mapStateToProps(state) {
  return {
    kaseId: getCurrentKaseId(state),
    kaseKind: getCurrentKaseKind(state),
    currentPhase: getCurrentDashboardPhaseForKase(state),
    sections: getNavigableSectionsForStopGapDashboard(state)
  }
}

export default connect(mapStateToProps, mapDispatchToActions)(IntakePrimaryCTA)
