import React, { FunctionComponent } from 'react'
import Button from 'components/button'
import ArrowIcon from 'components/icons/arrow_icon'
import HtmlSafe from 'components/html_safe'
import { openChat } from 'actions/chat_actions'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'

interface ExplicitProps {
  body: string
  handlePreviousClick: () => void
  isPaidUser: boolean
  title: string
}

interface ActionProps {
  openChat: (args: { via: string }) => void
}

type Props = ExplicitProps & ActionProps

const IneligiblePage: FunctionComponent<Props> = ({ body, handlePreviousClick, isPaidUser, openChat, title }) => {
  return (
    <>
      <div className="c-sheet__action-container c-sheet__action-container--secondary u-hide@sm-down">
        <Button
          id="previous-button-desktop"
          aria-label="back to questionnaire"
          className="c-sheet__action c-btn c-btn--icon-only c-btn--secondary c-btn--btn-events-only"
          color="primary"
          onClick={handlePreviousClick}
          icon={<ArrowIcon arrowDirection="up" />}
        />
      </div>

      <div className="c-sheet__body">
        <div className="o-grid__row">
          <div className="o-grid__col-6 o-grid__col--offset-1">
            <div className="o-block o-block--copious o-grid--fluid">
              <div className="o-grid__cell--12/12">
                <h1 className="c-type c-type--headline-md c-type--emphasized">{title}</h1>
              </div>

              <div className="o-grid__cell--12/12 py-8 interstitial-body-text">{HtmlSafe({ text: body })}</div>
              {isPaidUser && (
                <Button
                  color="primary"
                  size="default"
                  label="Chat with Boundless"
                  onClick={() => openChat({ via: 'IneligiblePage' })}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators({ openChat }, dispatch)
}

export default connect(null, mapDispatchToProps)(IneligiblePage)
