import React, { FunctionComponent } from 'react'
import { ApplicationState } from 'reducers'
import { connect } from 'react-redux'

import QuestionCircleIcon from 'components/icons/question_circle_icon'

import { DocumentUploadSummaryStates } from 'lib/constants'
import { getCurrentDocumentUploadSummaryState, getDocRequestsLeftBeforeThreshold } from 'reducers/selectors'

interface ExplicitProps {
  onClick?: () => void
}

interface MappedProps {
  currentDocmentUploadSummaryState?: DocumentUploadSummaryStates
  numberOfRequestsBeforeThreshold: number
}

const NextStepButton: FunctionComponent<ExplicitProps & MappedProps> = ({
  currentDocmentUploadSummaryState,
  numberOfRequestsBeforeThreshold,
  onClick
}) => {
  const nextStepText = () => {
    switch (currentDocmentUploadSummaryState) {
      case DocumentUploadSummaryStates.UploadingNotStarted:
        return 'Upload a document set'
      case DocumentUploadSummaryStates.ThresholdNotReached: {
        const sectionsRemaining = numberOfRequestsBeforeThreshold
        const inflectedSections = sectionsRemaining > 1 ? 'sets' : 'set'

        return `Upload ${numberOfRequestsBeforeThreshold} more ${inflectedSections}`
      }
      case DocumentUploadSummaryStates.CommentsExist:
        return 'Complete tasks in red'
      case DocumentUploadSummaryStates.AllSectionsCustomerCompleted:
        return "We'll check your documents soon"
      case DocumentUploadSummaryStates.ThresholdReachedNoComments:
        return 'Continue uploading documents'
      case DocumentUploadSummaryStates.AllSectionsAccepted:
        return 'All documents accepted'
      default:
        return 'Continue uploading documents'
    }
  }

  return (
    <button
      className="o-action-items__next-step border border-l-8 border-t-1 border-r-1 border-b-1 border-blue-500 flex justify-between items-center"
      onClick={onClick}
    >
      <span className="o-action-items__next-step-content c-type c-type--body-sans-sm">{nextStepText()}</span>
      <QuestionCircleIcon />
    </button>
  )
}

function mapStateToProps(state: ApplicationState) {
  return {
    currentDocmentUploadSummaryState: getCurrentDocumentUploadSummaryState(state),
    numberOfRequestsBeforeThreshold: getDocRequestsLeftBeforeThreshold(state)
  }
}

export default connect(mapStateToProps)(NextStepButton) as FunctionComponent<ExplicitProps>
