import { isPresent } from 'lib/presence'
import mbgcHooks from './marriage_based_green_card'
import nMbgcHooks from './new_marriage_based_green_card'
import naturalizationHooks from './naturalization'

import { AfterChangeFunction } from 'components/forms/buffered_field_value'
import { AfterChangeHooks } from './types'
import { ModelDataSerializableValue } from 'reducers/model'

const hooks: AfterChangeHooks = {
  ...mbgcHooks,
  ...naturalizationHooks,
  ...nMbgcHooks
}

export function getAfterChangeHooks(eventList: string[] = []): AfterChangeFunction<ModelDataSerializableValue>[] {
  return eventList.map((hookName) => hooks[hookName]).filter(isPresent)
}
