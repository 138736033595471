import axios, { AxiosPromise } from 'axios'

const wpEndpoint = 'https://boundless.com/wp-json/wp/v2/posts'

function fetchBlogPosts(category: number, postCount: string): AxiosPromise {
  /** Wordpress blocks requests via CORS policy that have the x-csrf and asset-version headers
   * so we have to remove them from the request before fetching otherwise the request is denied
   */
  return axios.get(`${wpEndpoint}?per_page=${postCount || '10'}${category ? `&categories[]=${category}` : ''}`, {
    transformRequest: [
      (data, headers) => {
        delete headers['X-CSRF-Token']
        delete headers['Asset-Version']
        return data
      }
    ]
  })
}

export default { fetchBlogPosts }
