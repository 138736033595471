import axios from 'axios'

import { addCsrfHeaderToRequest, refreshCsrfInDom } from 'lib/api/csrf'
import { handleSessionExpirationError } from 'lib/api/session_expiration'
import { addAuthorizationToRequest } from 'lib/api/authorization'

const instance = axios.create({
  headers: { Accept: 'application/vnd.api+json' }
})

instance.interceptors.request.use(addCsrfHeaderToRequest)

instance.interceptors.response.use(refreshCsrfInDom)
instance.interceptors.response.use((response) => response, handleSessionExpirationError)

instance.interceptors.request.use(addAuthorizationToRequest)

export default instance
