import React from 'react'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import classNames from 'classnames'
import Dropzone, { DropzoneRef } from 'react-dropzone'

import { uploadRequestedFiles, uploadRequestedFileProgress, uploadRequestedFileFinished } from 'actions/upload_actions'

import { postTelemetry } from 'actions/telemetry_actions'

import UploadQueue, { SignResult } from 'lib/upload_queue'
import { listToSentence } from 'lib/language_helpers'
import { TELEMETRY_EVENTS } from 'lib/constants'

import { addUploadsToRequest, fetchDocumentRequests } from 'actions/document_request_actions'
import { acceptedFileTypesForDocumentRequest } from 'lib/accepted_files_types'
import { getCurrentKaseId } from 'admin/reducers/selectors'
import { isCurrentUserAdmin, getWorkflowStatus, getDocumentUploadLocation } from 'reducers/selectors'
import S3Upload, { UploadProgress } from 'lib/admin/s3_upload'
import UpdateRequestStatusButton from './update_request_status_button'

interface MappedProps {
  kaseId: number
  isCurrentUserAdmin: boolean
  workflowStatus: string
  documentUploadLocation?: string
}

interface ActionProps {
  fetchDocumentRequests: typeof fetchDocumentRequests
  addUploadsToRequest: typeof addUploadsToRequest
  uploadRequestedFiles: typeof uploadRequestedFiles
  uploadRequestedFileProgress: typeof uploadRequestedFileProgress
  uploadRequestedFileFinished: typeof uploadRequestedFileFinished
  postTelemetry: typeof postTelemetry
}

interface ExplicitProps {
  setRequestStatusAutomatically?: boolean
  documentRequest: DocumentRequestModel
  uploadDisabled?: boolean
}

type Props = ExplicitProps & MappedProps & ActionProps

class DocumentRequestUploadArea extends React.Component<Props> {
  static defaultProps = {
    isCurrentUserAdmin: false
  }

  dropzoneRef: DropzoneRef
  uploadQueue: UploadQueue
  uploadingBy: string = ''

  constructor(props: Props) {
    super(props)

    this.uploadQueue = new UploadQueue({
      onUploadsComplete: this.onAllUploadsComplete
    })
  }

  componentDidMount() {
    // Prevent the default browser behavior of loading the file when the dropzone
    // is disabled
    window.addEventListener(
      'dragover',
      (event) => {
        event.preventDefault()
      },
      false
    )
    window.addEventListener(
      'drop',
      (event) => {
        event.preventDefault()
      },
      false
    )
  }

  onFileUploadClick: React.MouseEventHandler<HTMLAnchorElement> = (event) => {
    event.preventDefault()

    this.uploadingBy = 'UploadDialog'
    this.dropzoneRef.open()
  }

  // Although not ideal this is the best function available to us from Dropzone
  // to differentiate drag and drop uploads from uploads via clicking the file
  // upload button.
  handleDragEnter = () => {
    this.uploadingBy = 'DragAndDrop'
  }

  handleDrop = (files: File[]) => {
    const { documentRequest } = this.props

    // Signal to the queue that we need to wait on additional uploads
    // before firing a data update.
    this.uploadQueue.addCount(files.length)

    this.props.uploadRequestedFiles({
      files,
      documentRequest
    })

    new S3Upload(files, this.props.kaseId, {
      onProgress: this.onUploadRequestedFileProgress,
      onUploadComplete: this.onUploadFinish
    })
  }

  onUploadRequestedFileProgress = (percent: number, status: UploadProgress, rawFile: File) => {
    this.props.uploadRequestedFileProgress({
      percent,
      status,
      rawFile
    })
  }

  onAllUploadsComplete = (uploadResults: UploadResult[]) => {
    const {
      setRequestStatusAutomatically,
      documentRequest,
      documentUploadLocation,
      fetchDocumentRequests,
      isCurrentUserAdmin,
      kaseId,
      workflowStatus,
      uploadRequestedFileFinished,
      addUploadsToRequest,
      postTelemetry
    } = this.props

    addUploadsToRequest({
      documentRequest,
      uploadResults,
      isAdmin: isCurrentUserAdmin,
      updateRequestStatus: setRequestStatusAutomatically
    })
      .then(() => fetchDocumentRequests({ kaseId }))
      .then(() => {
        uploadResults.forEach((result) => {
          uploadRequestedFileFinished({
            rawFile: result.rawFile,
            uploadingBy: this.uploadingBy,
            documentType: documentRequest.document_type,
            workflowStatus: workflowStatus
          })

          postTelemetry(TELEMETRY_EVENTS.UPLOADED_DOCUMENT, {
            fileSize: result.rawFile.size,
            documentType: documentRequest.document_type,
            documentContentType: result.signResult.contentType,
            uploadingFrom: documentUploadLocation,
            uploadingBy: this.uploadingBy,
            workflowStatus: workflowStatus,
            _analyticsIntegrations: {
              All: true,
              AutopilotHQ: false
            }
          })
        })
      })
  }

  onUploadFinish = (signResult: SignResult, rawFile: File) => {
    this.uploadQueue.addResult({ rawFile, signResult })
  }

  acceptedFileTypes() {
    return acceptedFileTypesForDocumentRequest(this.props.documentRequest)
  }

  render() {
    const classes = classNames(
      'o-block',
      'c-card',
      'c-card--uploader',
      'c-card--transparent',
      'c-dropzone',
      'text-center'
    )

    const { documentRequest, setRequestStatusAutomatically, uploadDisabled } = this.props

    return (
      <Dropzone
        accept={this.acceptedFileTypes().types.join(',')}
        acceptClassName="c-dropzone--accepted"
        activeClassName="c-dropzone--active"
        className={classes}
        disabled={uploadDisabled}
        disabledClassName="c-dropzone--disabled"
        disableClick={true}
        key="document-upload-card"
        onDragEnter={this.handleDragEnter}
        onDrop={this.handleDrop}
        ref={(node) => {
          this.dropzoneRef = node
        }}
        rejectClassName="c-dropzone--rejected"
      >
        <div className="c-card__img-container">
          <img className="c-card__img--top c-img--fluid" alt="" />
        </div>

        <div className="c-card--block">
          <a
            href="#upload-document"
            onClick={this.onFileUploadClick}
            className="o-block o-block--compact c-type c-type--subhead-sm"
          >
            Upload Your Documents
          </a>

          <p className="o-block o-block--ample c-type c-type--body-sans-sm">or drop your files here.</p>

          <p className="o-block o-block--ample c-type c-type--body-sans-sm c-type--muted">
            Supports: {listToSentence(this.acceptedFileTypes().names)}
          </p>

          {!setRequestStatusAutomatically && (
            <UpdateRequestStatusButton
              documentRequest={documentRequest}
              requestCanBeCompleted={documentRequest.documents.length > 0}
            />
          )}
        </div>
      </Dropzone>
    )
  }
}

function mapStateToProps(state): MappedProps {
  return {
    isCurrentUserAdmin: isCurrentUserAdmin(state),
    kaseId: getCurrentKaseId(state),
    workflowStatus: getWorkflowStatus(state),
    documentUploadLocation: getDocumentUploadLocation(state)
  }
}

function mapDispatchToActions(dispatch: Dispatch): ActionProps {
  return bindActionCreators(
    {
      fetchDocumentRequests,
      addUploadsToRequest,
      uploadRequestedFiles,
      uploadRequestedFileFinished,
      uploadRequestedFileProgress,
      postTelemetry
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToActions)(DocumentRequestUploadArea)
