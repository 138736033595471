import React from 'react'

import BufferedFieldValue from 'components/forms/buffered_field_value'
import ValidatedInput from 'components/forms/validated_input'

interface Props {
  disabled: boolean
  id: string
  path: string
}

export default class UnitNumberInput extends React.Component<Props> {
  static defaultProps = {
    disabled: false
  }

  render() {
    const { disabled, id, path } = this.props

    return (
      <ValidatedInput disabled={disabled} path={path}>
        {({ fireValidation, getValidationClasses, renderValidations }) => (
          <React.Fragment>
            <BufferedFieldValue path={path}>
              {(value, onChange) => (
                <input
                  type="text"
                  name="unit_number"
                  id={`${id}-unit-number`}
                  className={`c-paper-form__control ${getValidationClasses()}`}
                  value={value}
                  onBlur={fireValidation}
                  onChange={onChange}
                  disabled={disabled}
                />
              )}
            </BufferedFieldValue>
            {renderValidations()}
          </React.Fragment>
        )}
      </ValidatedInput>
    )
  }
}
