import u from 'updeep'
import { LOADED_FEATURES, LOADING_FEATURES } from '../../lib/constants'

export interface Features {
  [key: string]: boolean
}

export interface FeaturesStore {
  features: Features
  isLoading: boolean
  isLoaded: boolean
}

const initialState: FeaturesStore = {
  features: {},
  isLoading: false,
  isLoaded: false
}

export default function featuresReducer(state: FeaturesStore, action: any): FeaturesStore {
  state = state || initialState
  switch (action.type) {
    case LOADING_FEATURES: {
      return u(
        {
          isLoading: true
        },
        state
      )
    }

    case LOADED_FEATURES: {
      return u(
        {
          features: action.payload,
          isLoading: false,
          isLoaded: true
        },
        state
      )
    }

    default: {
      return state
    }
  }
}
