import React, { FunctionComponent } from 'react'
import { WORKFLOW_STATUSES } from 'lib/constants'
import ArrowIllustration from 'components/illustrations/arrow_illustration'
import Paragraph from 'components/type/paragraph'
import PostShipmentStep from './post_shipment_step'
import DashboardSuitecaseIllustration from 'components/illustrations/dashboard_suitcase_illustration'
import DashboardEmailIllustration from 'components/illustrations/dashboard_email_illustration'
import MilestonesList from './milestones_list'
import ExternalLink from 'components/external_link'
import { CP_MILESTONES } from './milestones_content'

interface Props {
  workflowStatus: WORKFLOW_STATUSES
}

const PostShipmentCopyCP: FunctionComponent<Props> = ({ workflowStatus }) => {
  // CP-specific - package shipped but not yet delivered to USCIS
  if (
    workflowStatus === WORKFLOW_STATUSES.CP_PACKAGE_SHIPPED ||
    workflowStatus === WORKFLOW_STATUSES.CP_PACKAGE_DELIVERED_TO_CUSTOMER
  ) {
    return (
      <>
        <div className="o-griddle__row o-box">
          <div className="o-griddle__col--hide o-griddle__col--show-sm-up o-griddle__col--auto">
            <ArrowIllustration />
          </div>
          <div className="o-griddle__col">
            <h2 className="o-block c-type c-type--headline-md c-type--emphasized">
              Next: Sign, Complete, and Ship Your Package
            </h2>
            <Paragraph>
              Once you receive your package, make sure to follow the instructions inside, and mail the package to USCIS.
              Please mail the package <strong>within 2 weeks of receiving it</strong> — otherwise, if your information
              or forms change, we’ll need to charge you a fee for updating your application and sending you a new
              package.
            </Paragraph>
          </div>
        </div>
        <div className="o-block">
          <PostShipmentStep illustration={<DashboardSuitecaseIllustration />} header="End of Attorney Relationship">
            <Paragraph>
              You’ve reached the end of your attorney-client relationship, and you’ll soon receive a farewell email from
              your independent attorney. But don’t worry — Boundless will continue to guide you from here on out!
            </Paragraph>
          </PostShipmentStep>

          <PostShipmentStep illustration={<DashboardEmailIllustration />} header="Future Email Communications">
            <Paragraph>
              Boundless will communicate with you via email with next steps and any actions you need to take. Expect to
              receive an email with more detailed information about your package.
            </Paragraph>
          </PostShipmentStep>
        </div>
        <MilestonesList milestones={CP_MILESTONES} />
      </>
    )
  }

  // Otherwise, we assume the package was received by USCIS:
  return (
    <>
      <div className="o-griddle__row o-box">
        <div className="o-griddle__col--hide o-griddle__col--show-sm-up o-griddle__col--auto">
          <ArrowIllustration />
        </div>
        <div className="o-griddle__col">
          <h2 className="o-block c-type c-type--headline-md c-type--emphasized">
            Next: Create an Online USCIS Account
          </h2>
          <Paragraph>
            To receive continuous updates on your application from USCIS, you can create an account on USCIS.gov with
            your personal email address after receiving your{' '}
            <ExternalLink href="https://www.boundless.com/immigration-resources/how-to-check-green-card-status/#receipt-numbers">
              Receipt Numbers
            </ExternalLink>
            . To create a USCIS online account, follow the{' '}
            <ExternalLink href="https://www.uscis.gov/file-online/uscis-online-account-setup">
              instructions provided on the USCIS website
            </ExternalLink>
            .
          </Paragraph>
        </div>
      </div>
      <div className="o-block">
        <PostShipmentStep illustration={<DashboardSuitecaseIllustration />} header="Keep Us Posted">
          <Paragraph>
            Now that USCIS has your package, it’ll typically take{' '}
            <ExternalLink href="https://www.boundless.com/immigration-resources/how-long-does-marriage-green-card-take/#currently-living-abroad">
              7–15 months
            </ExternalLink>{' '}
            to move on to the next stage. In order for Boundless to help you through the remaining steps, please notify
            your Boundless Support Team of the following via email:
          </Paragraph>
          <ol className="o-block o-list c-type c-type--body-sans-md">
            <li>If any of your information changes (such as your home address or work)</li>
            <li>When USCIS approves your family-based petition (Form I-130)</li>
            <li>When you receive your Interview Appointment Notice</li>
          </ol>
        </PostShipmentStep>

        <PostShipmentStep illustration={<DashboardEmailIllustration />} header="Future Email Communications">
          <Paragraph>
            Boundless will communicate with you via email with next steps and any actions you need to take.
          </Paragraph>
        </PostShipmentStep>
      </div>
      <MilestonesList milestones={CP_MILESTONES} />
    </>
  )
}

export default PostShipmentCopyCP
