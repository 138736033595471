import React from 'react'

import { joinI18nKeys } from 'lib/i18n'
import panelLocaleService from '../../../../services/panel_locale_service'
import { QuestionnaireInputContainerProps } from 'components/forms/inputs'

import ValidatedInput from '../../validated_input'
import AdmissionClassesInput from './admission_classes_input'

export default class AdmissionClasses extends React.Component<QuestionnaireInputContainerProps> {
  render() {
    const { disabled, path } = this.props

    return (
      <div className="o-flag o-grid--fluid o-grid--stack@sm o-block c-paper-form__group">
        <label
          className="o-grid__cell--3/12 o-grid__cell--off@sm c-paper-form__label c-paper-form__segment"
          htmlFor={this.props.id}
        >
          <span className="c-type c-type--subhead-sm">
            {panelLocaleService.t(joinI18nKeys(this.props.resourceKeys, `${this.props.name}.label`))}
          </span>
        </label>

        <div className="o-grid__cell--9/12 o-grid__cell--off@sm c-paper-form__segment">
          <ValidatedInput disabled={disabled} path={path}>
            {({ fireValidation, getValidationClasses, renderValidations }) => (
              <React.Fragment>
                <AdmissionClassesInput
                  id="paperFormAdmissionClasses"
                  onBlur={fireValidation}
                  path={path}
                  className={`c-paper-form__control ${getValidationClasses()}`}
                />

                {renderValidations()}
              </React.Fragment>
            )}
          </ValidatedInput>
        </div>
      </div>
    )
  }
}
