import normalize from 'json-api-normalizer'

export const formatKasesUrl = (kaseId, path) => {
  return `/api/v1/kases/${kaseId}/${path}`
}

export const formatPostRequestBody = (type, body) => {
  return {
    data: {
      type,
      attributes: body
    }
  }
}

export const normalizeResponse = (response) => {
  return normalize(response.data, { camelizeKeys: false }) || {}
}
