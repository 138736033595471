import { LOCATION_CHANGED } from 'redux-little-router'

import { TOGGLE_SIDEBAR_HELP } from 'lib/constants'

const initialState = {
  sidebar: {
    open: false
  }
}

export default function helpReducer(state, action) {
  state = state || initialState

  switch (action.type) {
    case LOCATION_CHANGED: {
      const newState = Object.assign({}, state)
      newState.sidebar.open = false

      return newState
    }
    case TOGGLE_SIDEBAR_HELP: {
      const newState = Object.assign({}, state)
      newState.sidebar.open = !state.sidebar.open

      return newState
    }
    default:
      return state
  }
}
