import api from '../lib/api'
import {
  LOADING_QUESTIONNAIRE,
  LOADING_QUESTIONNAIRE_SUCCESS,
  UPDATING_QUESTIONNAIRE,
  UPDATING_QUESTIONNAIRE_SUCCESS,
  SET_GLOBAL_ERROR,
  LOAD_CURRENT_USER
} from 'lib/constants'
import { getCurrentKaseId } from 'reducers/selectors'
import { getCurrentQuestionnaireId } from 'reducers/questionnaire/selectors'

export function loadingQuestionnaireSuccess(data) {
  return (dispatch) => {
    dispatch({
      type: LOADING_QUESTIONNAIRE_SUCCESS,
      payload: data ?? {}
    })
  }
}

export const loadQuestionnaire = (questionnaireSlug: string) => (dispatch, getState) => {
  let state = getState()
  const kaseId = getCurrentKaseId(state)

  dispatch({ type: LOADING_QUESTIONNAIRE, payload: true })
  return api.questionnaire
    .index({ kaseId, questionnaireSlug })
    .then((data) => {
      dispatch(loadingQuestionnaireSuccess(data?.data))
    })
    .finally(() => {
      dispatch({ type: LOADING_QUESTIONNAIRE, payload: false })
    })
    .catch(() => {
      dispatch({
        type: SET_GLOBAL_ERROR,
        payload: 'Sorry, we’re having trouble fetching your questionnaire progress.'
      })
    })
}

export interface SaveQuestionForQuestionnaireParams {
  kaseId?: number
  questionnaireId?: string
  questionId: string
  answer: string
  answerId?: string
  currentPageId?: string
}

export interface MarkPageViewed {
  kaseId: number
  pageId: string
}

export function updatingQuestionnaireSuccess(data) {
  return (dispatch) => {
    dispatch({
      type: UPDATING_QUESTIONNAIRE_SUCCESS,
      payload: data ?? {}
    })
  }
}

export const saveQuestionForQuestionnaire =
  ({ questionId, answer, answerId }: SaveQuestionForQuestionnaireParams) =>
  (dispatch, getState) => {
    let state = getState()

    const kaseId = getCurrentKaseId(state)
    const questionnaireId = getCurrentQuestionnaireId(state)

    dispatch({ type: UPDATING_QUESTIONNAIRE, payload: true })
    return api.questionnaire
      .postQuestionnaireAnswer({
        kaseId,
        questionnaireId,
        questionId,
        answer,
        answerId
      })
      .then((data) => {
        dispatch(updatingQuestionnaireSuccess(data?.data))
      })
      .finally(() => {
        dispatch({ type: UPDATING_QUESTIONNAIRE, payload: false })
      })
      .catch(() => {
        dispatch({
          type: SET_GLOBAL_ERROR,
          payload: 'Sorry, we’re having trouble updating your questionnaire progress.'
        })
      })
  }

export const updateUserEmailFromQuestionnaire = (email: string) => (dispatch) => {
  dispatch({ type: UPDATING_QUESTIONNAIRE, payload: true })
  return api.usersV2
    .updateEmail(email)
    .then((res) => {
      dispatch({ type: LOAD_CURRENT_USER, currentUser: res.data.attributes })
    })
    .finally(() => {
      dispatch({ type: UPDATING_QUESTIONNAIRE, payload: false })
    })
    .catch(() => {
      dispatch({
        type: SET_GLOBAL_ERROR,
        payload: 'Sorry, we’re having trouble updating your questionnaire progress.'
      })
    })
}

export const markPageViewed =
  ({ kaseId, pageId }) =>
  (dispatch) => {
    return api.questionnaire
      .markPageViewed({ kaseId, pageId })
      .then((data) => {
        dispatch(loadingQuestionnaireSuccess(data?.data))
      })
      .finally(() => {
        dispatch({ type: LOADING_QUESTIONNAIRE, payload: false })
      })
  }
