import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'

import { showPanel } from 'actions/panel_actions'
import { openChat } from 'actions/chat_actions'
import { toggleContextualHelp } from 'actions/help_actions'

import {
  getCurrentKaseCompletedEligibility,
  getCurrentKaseId,
  getCurrentKaseKind,
  getTimelinePageUrl,
  isCurrentKasePaid,
  isSidebarHelpOpen,
  isUserLoggedIn
} from 'reducers/selectors'
import { getIsFeatureEnabled } from 'reducers/features/selectors'

import { showSignInModal } from '../../../sign_in_nav'

import { KaseKind } from 'lib/constants'
import { Questionnaire, QuestionnairePageDataV2 } from '../lib/types'
import { FEATURES } from 'lib/features'

import { ChatABControl } from './chat_ab_test/control'
import { ChatABTest } from './chat_ab_test/test'

interface Props {
  completedEligibility: boolean
  contextualHelpV2?: any
  currentKaseKind: KaseKind
  currentKaseId: number | string
  currentPageData: QuestionnairePageDataV2
  isChatDiscoveryTestEnabled: boolean
  isPaidUser: boolean
  open: boolean
  openChat: (args: { via: string }) => void
  questionnaireData: Questionnaire
  timelinePageUrl: string
  toggleContextualHelp: (args: { via: string }) => void
  userLoggedIn: boolean
}

const ToolMenuV2: FunctionComponent<Props> = ({
  completedEligibility,
  contextualHelpV2,
  currentKaseId,
  currentKaseKind,
  currentPageData,
  isPaidUser,
  isChatDiscoveryTestEnabled,
  open,
  openChat,
  questionnaireData,
  timelinePageUrl,
  toggleContextualHelp,
  userLoggedIn
}) => {
  const handleSaveProgressClick = (event) => {
    event.preventDefault()
    showSignInModal({
      via: 'SaveProgressButton',
      isLogIn: false,
      noRedirect: true
    })
  }

  const handleSupportMessageClick = (event) => {
    event.preventDefault()
    event.nativeEvent.stopImmediatePropagation()

    openChat({ via: 'ToolMenu' })
  }

  const toggleHelp = (event) => {
    event.preventDefault()
    toggleContextualHelp({ via: 'ToolMenu' })
    // @ts-ignore
    document.activeElement?.blur()
  }

  const renderReturnToDashboardButton = () => {
    if (completedEligibility) {
      return (
        <a href={timelinePageUrl} className="o-block c-btn c-btn--block c-btn--secondary">
          Return to Dashboard
        </a>
      )
    }
  }

  const renderPaymentButton = () => {
    const kaseKindPrefx = currentKaseKind === KaseKind.K1FianceVisa ? `k1` : `mbgc`
    if (completedEligibility) {
      if (!isPaidUser && currentPageData?.attributes?.type !== 'PaymentPage') {
        return (
          <a
            href={`/applications/${currentKaseId}/questionnaire/${kaseKindPrefx}_payment`}
            className="o-block c-btn c-btn--block c-btn--primary"
          >
            See Payment Options
          </a>
        )
      }
    }
  }

  // Only show the Save Progress button for MBGC in PP1 (if the user is not already logged in)
  const showSaveProgressButton =
    currentKaseKind === KaseKind.NewMarriageBasedGreenCard &&
    questionnaireData?.attributes?.name === 'Petition Part 1' &&
    !userLoggedIn

  const contactEmail = isPaidUser ? 'support.team' : 'help'

  return (
    <div className="tool-menu">
      <ul className="tool-menu-top o-list-bare pr-3">
        <li className="o-block o-block--ample">
          <div className="o-grid__row tool-menu-bottom">
            <div className="o-grid__col-3">
              {renderReturnToDashboardButton()}
              {renderPaymentButton()}
            </div>
          </div>
        </li>
        {isChatDiscoveryTestEnabled ? (
          <ChatABTest
            contactEmail={contactEmail}
            contextualHelpV2={contextualHelpV2}
            handleSupportMessageClick={handleSupportMessageClick}
            contextualHelpOpen={open}
          />
        ) : (
          <ChatABControl
            contactEmail={contactEmail}
            contextualHelpV2={contextualHelpV2}
            handleSupportMessageClick={handleSupportMessageClick}
            toggleHelp={toggleHelp}
            contextualHelpOpen={open}
          />
        )}
      </ul>
      {showSaveProgressButton && (
        <div className="o-block">
          <button className={'c-btn c-btn--block c-btn--emphasized'} onClick={handleSaveProgressClick} type="button">
            Save Progress
          </button>
        </div>
      )}
    </div>
  )
}

function mapStateToProps(state) {
  return {
    completedEligibility: getCurrentKaseCompletedEligibility(state),
    currentKaseId: getCurrentKaseId(state),
    currentKaseKind: getCurrentKaseKind(state),
    isPaidUser: isCurrentKasePaid(state),
    open: isSidebarHelpOpen(state),
    timelinePageUrl: getTimelinePageUrl(state),
    userLoggedIn: isUserLoggedIn(state),
    isChatDiscoveryTestEnabled: getIsFeatureEnabled(state, FEATURES.DISCOVERABLE_CHAT)
  }
}

function mapDispatchToActions(dispatch) {
  return {
    openChat: (...args) => dispatch(openChat(...args)),
    showPanel: (...args) => dispatch(showPanel(...args)),
    toggleContextualHelp: (...args) => dispatch(toggleContextualHelp(...args))
  }
}

export default connect(mapStateToProps, mapDispatchToActions)(ToolMenuV2)
