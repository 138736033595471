import { buildMessage, Validator } from '.'
import { pathParent } from 'lib/path_helpers'

function isUSAddress(address: { country: { code: string } }): boolean {
  return address.country != null && address.country.code === 'US'
}

export const postalRequiredIfUSAddress: Validator = ({ getModelData, path }) => {
  const addressData = getModelData(pathParent(path))
  const postal_code = addressData.postal_code

  if (isUSAddress(addressData) && postal_code == null) {
    return [buildMessage('Please enter zip code')]
  }

  return []
}

export const provinceRequiredIfUSAddress: Validator = ({ getModelData, path }) => {
  const addressData = getModelData(pathParent(path))
  const province = addressData.province
  const countryCode = addressData && addressData.country && addressData.country.code

  if (countryCode === 'US' && province == null) {
    return [buildMessage('Please enter province')]
  }

  return []
}
