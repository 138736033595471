import React from 'react'
import { connect } from 'react-redux'

import { clearPanelFields } from 'actions/panel_actions'
import { QuestionnaireInputContainerProps } from './'

import BufferedFieldValue from '../buffered_field_value'
import ValidatedInput from '../validated_input'
import { getAfterChangeHooks } from 'components/forms/field_after_change_events'

type Props = QuestionnaireInputContainerProps & {
  clearPanelFields: typeof clearPanelFields
}

class Qualifier extends React.Component<Props> {
  handleChange = (onChange, fireValidation) => (event: React.MouseEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value || null

    onChange(value)
    fireValidation()
    this.clearFields()
  }

  handleKeyPress = (onChange, fireValidation) => (event: React.KeyboardEvent<HTMLDivElement>) => {
    const value = {
      y: 'yes',
      Y: 'yes',
      n: 'no',
      N: 'no'
    }[event.key]

    onChange(value || event.target.value || null)
    fireValidation()
    this.clearFields()
  }

  clearFields() {
    this.props.clearPanelFields({ excludeFields: [this.props.name] })
  }

  render() {
    const { disabled, name, path } = this.props

    const afterChangeEvents = getAfterChangeHooks(this.props.after_change_event_list)

    return (
      <ValidatedInput disabled={disabled} path={path}>
        {({ fireValidation, getValidationClasses, renderValidations }) => (
          <React.Fragment>
            <BufferedFieldValue afterChangeEvents={afterChangeEvents} path={path}>
              {(value, onChange) => (
                <div
                  className={`o-flag o-grid--fluid o-block c-paper-form__group ${getValidationClasses()}`}
                  onKeyPress={this.handleKeyPress(onChange, fireValidation)}
                >
                  <button
                    type="button"
                    className={`o-grid__cell--6/12 c-btn ${value === 'yes' ? 'c-btn--primary' : 'c-btn--transparent'}`}
                    name={name}
                    value="yes"
                    onClick={this.handleChange(onChange, fireValidation)}
                  >
                    Yes
                  </button>

                  <button
                    type="button"
                    className={`o-grid__cell--6/12 c-btn ${value === 'no' ? 'c-btn--primary' : 'c-btn--transparent'}`}
                    name={name}
                    value="no"
                    onClick={this.handleChange(onChange, fireValidation)}
                  >
                    No
                  </button>
                </div>
              )}
            </BufferedFieldValue>

            {renderValidations()}
          </React.Fragment>
        )}
      </ValidatedInput>
    )
  }
}

function mapDispatchToActions(dispatch) {
  return {
    clearPanelFields: (...args) => dispatch(clearPanelFields(...args))
  }
}

export default connect(null, mapDispatchToActions)(Qualifier)
