import React from 'react'
import { connect } from 'react-redux'

import { getActiveModal, getActiveModalProps } from 'reducers/selectors'

import modals from '..'

interface Props {
  activeModal?: string
  onlyModals?: string[]
  modalProps: {
    [propKey: string]: any
  }
}

class ModalRevealer extends React.Component<Props> {
  modalKeys() {
    return this.props.onlyModals || Object.keys(modals)
  }

  render() {
    return this.modalKeys().map((name) => {
      const ModalComponent = modals[name]

      return <ModalComponent key={name} isActive={name === this.props.activeModal} {...this.props.modalProps} />
    })
  }
}

function mapStateToProps(state) {
  return {
    activeModal: getActiveModal(state),
    modalProps: getActiveModalProps(state)
  }
}

export default connect(mapStateToProps)(ModalRevealer)
