import React from 'react'

import BufferedFieldValue from 'components/forms/buffered_field_value'
import { defaultInputProps, InputProps } from 'components/forms/inputs'

export default class TextareaInput extends React.Component<InputProps> {
  static defaultProps = defaultInputProps

  render() {
    const { afterChangeEvents, className, id, name, onBlur, saveOnBlur, path, placeholder, reviewOnly } = this.props

    return (
      <BufferedFieldValue
        afterChangeEvents={afterChangeEvents}
        afterSaveOnBlur={onBlur}
        path={path}
        saveOnBlur={saveOnBlur}
      >
        {(value, onChange) => (
          <textarea
            autoComplete="off"
            className={`c-qa-textarea ${className}`}
            data-model-path={path}
            disabled={reviewOnly}
            id={id}
            name={name}
            onBlur={saveOnBlur ? onBlur : undefined}
            onChange={onChange}
            placeholder={placeholder}
            rows={7}
            value={value}
          />
        )}
      </BufferedFieldValue>
    )
  }
}
