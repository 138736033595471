import React, { FunctionComponent, useState } from 'react'

import { DashboardPhase, getDashboardPhaseTitle, DASHBOARD_PHASE_LIST } from './phase_map'
import PhaseDetailsLayout from './phase_details_layout'
import Timeline from './timeline'

interface Props {
  currentPhase: DashboardPhase
  isDesktop?: boolean
}

const TimelinePreviewWrapper: FunctionComponent<Props> = ({ currentPhase, isDesktop }) => {
  const [currentPhasePreview, setCurrentPhasePreview] = useState<DashboardPhase>(null)
  const activePhaseTitle = getDashboardPhaseTitle(currentPhase)

  if (!isDesktop) {
    return (
      <section className="c-phase-details__wrapper">
        <div className="o-container o-layout--center o-box">
          <h3 className="c-type c-type--subhead-md c-type--watermark o-block" aria-hidden="true">
            You're at
          </h3>
          <h1 className="c-type c-type--headline-md c-type--tertiary o-block">{activePhaseTitle}</h1>
        </div>
      </section>
    )
  }

  return (
    <>
      <PhaseDetailsLayout
        closePhasePreview={() => setCurrentPhasePreview(null)}
        currentPhase={currentPhase}
        currentPhasePreview={currentPhasePreview}
        phaseList={DASHBOARD_PHASE_LIST}
      />
      <div className="o-container">
        <Timeline
          currentPhase={currentPhase}
          currentPhasePreview={currentPhasePreview}
          updateCurrentPhasePreview={(phase: DashboardPhase) => setCurrentPhasePreview(phase)}
          phaseList={DASHBOARD_PHASE_LIST}
        />
      </div>
    </>
  )
}

export default TimelinePreviewWrapper
