import React, { FunctionComponent } from 'react'
import { createUserEvent } from 'actions/user_event_actions'

// TODO: This does not need to use ToolsAdminState since it's not an admin
// component
// https://www.pivotaltracker.com/story/show/175789486
import { ToolsAdminState } from 'admin/reducers'
import { getCurrentKaseId } from 'admin/reducers/selectors'
import { getCurrentUserInitialTrackingMetadata, getPetitionEmail, isWorkflowCategoryAOS } from 'reducers/selectors'
import { connect } from 'react-redux'
import { bindActionCreators, AnyAction, Dispatch } from 'redux'
import { postTelemetryWithConversion } from 'actions/telemetry_actions'
import { rapidVisaRedirectUrl } from 'lib/settings'
import { TELEMETRY_EVENTS, CONVERSION_TRACKING_DESTINATIONS } from 'lib/constants'

interface ExplicitProps {
  className: string
}

interface UTMTrackingMetadata {
  utm_source?: string
  utm_campaign?: string
  utm_medium?: string
}

interface MappedProps {
  initialTrackingMetadata?: UTMTrackingMetadata
  isWorkflowCategoryAOS: boolean
  kaseId: number
  userEmail: string
}

interface ActionProps {
  postTelemetryWithConversion: Function
}

const GoToRapidVisaLink: FunctionComponent<ExplicitProps & MappedProps & ActionProps> = ({
  children,
  className,
  initialTrackingMetadata,
  isWorkflowCategoryAOS,
  kaseId,
  postTelemetryWithConversion,
  userEmail
}) => {
  const getQueryParams = () => {
    const params = []
    params.push(`e=${encodeURIComponent(userEmail)}`)

    if (initialTrackingMetadata) {
      if (initialTrackingMetadata.utm_source) {
        params.push(`utm_source=${initialTrackingMetadata.utm_source}`)
      }

      if (initialTrackingMetadata.utm_campaign) {
        params.push(`utm_campaign=${initialTrackingMetadata.utm_campaign}`)
      }

      if (initialTrackingMetadata.utm_medium) {
        params.push(`utm_medium=${initialTrackingMetadata.utm_medium}`)
      }
    }

    return `?${params.join('&')}`
  }

  const getHref = () => {
    const rapidVisaDomain = rapidVisaRedirectUrl()
    const params = getQueryParams()
    const path = isWorkflowCategoryAOS ? 'Boundless-AOS.aspx' : 'Boundless-CR1.aspx'
    return `${rapidVisaDomain}/${path}${params}`
  }

  const linkClicked = (event: React.MouseEvent) => {
    event.preventDefault()

    const userEvent = {
      kaseId: kaseId,
      eventType: 'rapid_visa_redirect'
    }

    postTelemetryWithConversion(
      TELEMETRY_EVENTS.RAPID_VISA_REDIRECT,
      {},
      TELEMETRY_EVENTS.RAPID_VISA_REDIRECT,
      {},
      null, // callback
      [CONVERSION_TRACKING_DESTINATIONS.FACEBOOK]
    )

    createUserEvent(userEvent).finally(() => {
      window.location.href = getHref()
    })
  }

  return (
    <a className={className} href={getHref()} onClick={linkClicked}>
      {children}
    </a>
  )
}

// TODO: This does not need to use ToolsAdminState since it's not an admin
// component
// https://www.pivotaltracker.com/story/show/175789486
function mapStateToProps(state: ToolsAdminState): MappedProps {
  return {
    initialTrackingMetadata: getCurrentUserInitialTrackingMetadata(state),
    isWorkflowCategoryAOS: isWorkflowCategoryAOS(state),
    kaseId: getCurrentKaseId(state),
    userEmail: getPetitionEmail(state)
  }
}

function mapDispatchToActions(dispatch: Dispatch<AnyAction>) {
  return bindActionCreators({ postTelemetryWithConversion }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToActions)(GoToRapidVisaLink)
