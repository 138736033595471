import api from '../lib/api'
import { LOADING_PROGRESS, LOADED_PROGRESS } from 'lib/constants'

interface FetchProgressParams {
  kaseId: number
}

export function loadedSectionProgress(data) {
  return (dispatch) => {
    dispatch({
      type: LOADED_PROGRESS,
      payload: data ?? {}
    })
  }
}

export const loadSectionProgress = ({ kaseId }: FetchProgressParams) => (dispatch) => {
  dispatch({ type: LOADING_PROGRESS, payload: true })
  return api.sectionProgress
    .index({ kaseId })
    .then((data) => {
      dispatch(loadedSectionProgress(data.data))
    })
    .finally(() => {
      dispatch({ type: LOADING_PROGRESS, payload: false })
    })
}
