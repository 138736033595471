import React, { FunctionComponent } from 'react'

import { DocumentUploadSummaryStates } from 'lib/constants'
import { DashboardPhase } from 'components/screens/dashboard/customer_dashboard/phase_map'
import {
  initialDocRequestContent,
  questionnairesIncompleteCurrentlyUploading,
  commentsToReadContent,
  QUESTIONNAIRES_INCOMPLETE_ALL_DOC_REQUESTS_COMPLETE
} from './doc_upload_phase_content'

import PhaseInformationTemplate from 'components/screens/dashboard/customer_dashboard/phase_information/phase_information_template'
import FancySpinner from 'components/admin/fancy_spinner'

interface ExplicitProps {
  currentDocumentUploadSummaryState: DocumentUploadSummaryStates
  currentPhase: DashboardPhase
}

const DocGatheringQuestionnairesIncomplete: FunctionComponent<ExplicitProps> = ({
  currentDocumentUploadSummaryState,
  currentPhase
}) => {
  const contentForPhase = () => {
    switch (currentDocumentUploadSummaryState) {
      case DocumentUploadSummaryStates.UploadingNotStarted:
        return initialDocRequestContent(true)
      case DocumentUploadSummaryStates.ThresholdNotReached:
      case DocumentUploadSummaryStates.ThresholdReachedNoComments:
        return questionnairesIncompleteCurrentlyUploading
      case DocumentUploadSummaryStates.CommentsExist:
        return commentsToReadContent
      case DocumentUploadSummaryStates.AllSectionsCustomerCompleted:
        return QUESTIONNAIRES_INCOMPLETE_ALL_DOC_REQUESTS_COMPLETE
      case DocumentUploadSummaryStates.AllSectionsAccepted:
        return QUESTIONNAIRES_INCOMPLETE_ALL_DOC_REQUESTS_COMPLETE
      default:
        return null
    }
  }

  const currentContent = contentForPhase()

  if (!currentContent) {
    return (
      <div className="o-block o-layout--center flex items-center justify-center" style={{ height: '100%' }}>
        <FancySpinner variation="primary" />
      </div>
    )
  }

  return (
    <PhaseInformationTemplate
      currentPhase={currentPhase}
      primaryCTA={currentContent.primaryCTA}
      header={currentContent.phaseInstruction}
      description={currentContent.phaseDescription}
    />
  )
}

export default DocGatheringQuestionnairesIncomplete as FunctionComponent<ExplicitProps>
