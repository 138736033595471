import React from 'react'

const CheckmarkIllustration = () => (
  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="30" cy="30" r="30" fill="#DAF0E6" />
    <path
      d="M41.4023 21.5999L24.9023 38.0999L17.4023 30.5999"
      stroke="#168753"
      strokeWidth="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default CheckmarkIllustration
