import React from 'react'
import { connect } from 'react-redux'

import { isMobileBrowser, isUserLoggedIn } from 'reducers/selectors'

function handleSetupSuccessClick(event, { mobile } = { mobile: false }) {
  event.preventDefault()

  window.showSignInModal({
    successUrl: '/petition',
    via: mobile ? 'MobileSetupOutcome' : 'SetupOutcome',
    isLogIn: false
  })
}

function SetupCompletedActionButton(props) {
  const btnClass = `c-btn c-btn--${props.btnVariant} ${props.btnClass}`

  if (props.userLoggedIn) {
    return (
      <a href="/petition" className={btnClass}>
        Keep Going
      </a>
    )
  }

  if (props.isMobile) {
    return (
      <a href="#create-account" className={btnClass} onClick={(e) => handleSetupSuccessClick(e, { mobile: true })}>
        {props.btnText}
      </a>
    )
  } else {
    return (
      <div>
        <a href="#create-account" className={btnClass} onClick={handleSetupSuccessClick}>
          {props.btnText}
        </a>
      </div>
    )
  }
}

SetupCompletedActionButton.defaultProps = {
  btnClass: '',
  btnText: 'Keep Going',
  btnVariant: 'primary'
}

function mapStateToProps(state) {
  return {
    isMobile: isMobileBrowser(state),
    userLoggedIn: isUserLoggedIn(state)
  }
}

export default connect(mapStateToProps)(SetupCompletedActionButton)
