import React, { FunctionComponent, useEffect, useState } from 'react'

import { InputOption, ValidatorParams } from '../lib/types'
import { AddressHistory } from 'reducers/address_histories/index'
import { trackUserEvent } from 'actions/telemetry_actions_v2'
import { TELEMETRY_EVENTS } from 'lib/constants'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import InputTransformer from '../inputs/input_transformer'

interface QuestionContainerProps {
  id: string
  name?: string
  onSave: (answer: string) => any
  onPlacesUpdate: (answer: google.maps.GeocoderAddressComponent[]) => void
  placeholder?: string
  type: string
  currentPagePath?: string
  options?: InputOption[]
  questionKey?: string
  questionnaireName: string
  validators?: ValidatorParams[]
  value?: string | AddressHistory
}

interface ActionProps {
  trackUserEvent: typeof trackUserEvent
}
/**
 *
 * A Question is a single input in a questionnaire of any type that
 * is assigned to the correct input component from the embedded map
 * and passed the relevant data.
 *
 */
const QuestionContainer: FunctionComponent<QuestionContainerProps & ActionProps> = ({
  type,
  id,
  name,
  onSave,
  onPlacesUpdate,
  currentPagePath,
  options,
  placeholder,
  questionKey,
  questionnaireName,
  trackUserEvent,
  validators,
  value
}) => {
  const [currentValue, setCurrentValue] = useState(value || '')

  useEffect(() => {
    setCurrentValue(value)
  }, [value])

  useEffect(() => {
    if (questionKey) {
      trackUserEvent(TELEMETRY_EVENTS.VIEWED_QUESTION, {
        questionKey,
        questionnaireName,
        inputType: type
      })
    }
  }, [questionKey])

  return (
    <InputTransformer
      id={id}
      currentPagePath={currentPagePath}
      name={name}
      options={options}
      onSave={onSave}
      onPlacesUpdate={onPlacesUpdate}
      placeholder={placeholder}
      type={type}
      validators={validators}
      value={currentValue}
    />
  )
}

function mapDispatchToActions(dispatch: Dispatch): ActionProps {
  return bindActionCreators(
    {
      trackUserEvent
    },
    dispatch
  )
}

export default connect(null, mapDispatchToActions)(QuestionContainer) as FunctionComponent<QuestionContainerProps>
