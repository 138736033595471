import React, { FunctionComponent } from 'react'

interface PostShipmentStepProps {
  header: string
  illustration: React.ReactNode
}

const PostShipmentStep: FunctionComponent<PostShipmentStepProps> = ({ illustration, header, children }) => (
  <div className="o-griddle__row o-box">
    <div className="o-griddle__col--hide o-griddle__col--show-sm-up o-griddle__col--auto">{illustration}</div>
    <div className="o-griddle__col pt-3">
      <h2 className="o-block c-type c-type--headline-sm c-type--emphasized">{header}</h2>
      {children}
    </div>
  </div>
)

export default PostShipmentStep
