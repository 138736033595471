import u from 'updeep'
import {
  LOADING_QUESTIONNAIRE_V2,
  LOADING_QUESTIONNAIRE_OUTLINE_V2,
  LOADING_QUESTIONNAIRE_OUTLINE_V2_SUCCESS,
  LOADING_QUESTIONNAIRE_V2_SUCCESS,
  UPDATING_QUESTIONNAIRE_V2,
  UPDATING_QUESTIONNAIRE_SUCCESS_V2
} from 'lib/constants'
import { QuestionnaireOutlineV2, QuestionnaireV2 } from 'components/screens/questionnaire/lib/types'

export interface QuestionnaireV2Store {
  data: QuestionnaireV2
  outline: QuestionnaireOutlineV2
  isLoadingData: boolean
  isLoadingOutline: boolean
  isUpdating: boolean
}

const initialState: QuestionnaireV2Store = {
  data: null,
  outline: null,
  isLoadingData: false,
  isLoadingOutline: false,
  isUpdating: false
}

export default function questionnaireV2Reducer(state: QuestionnaireV2Store, action: any): QuestionnaireV2Store | {} {
  state = state || initialState
  switch (action.type) {
    case LOADING_QUESTIONNAIRE_V2: {
      return u(
        {
          isLoadingData: action.payload
        },
        state
      )
    }

    case LOADING_QUESTIONNAIRE_V2_SUCCESS: {
      return u(
        {
          data: action.payload.data as QuestionnaireV2
        },
        state
      )
    }

    case UPDATING_QUESTIONNAIRE_V2: {
      return u(
        {
          isUpdating: action.payload
        },
        state
      )
    }

    case UPDATING_QUESTIONNAIRE_SUCCESS_V2: {
      return u(
        {
          data: action.payload.data as QuestionnaireV2
        },
        state
      )
    }

    case LOADING_QUESTIONNAIRE_OUTLINE_V2: {
      return u(
        {
          isLoadingOutline: action.payload
        },
        state
      )
    }

    case LOADING_QUESTIONNAIRE_OUTLINE_V2_SUCCESS: {
      return u(
        {
          outline: action.payload.data as QuestionnaireOutlineV2
        },
        state
      )
    }

    default: {
      return state
    }
  }
}
