import React, { FunctionComponent, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import {
  getCurrentKaseId,
  getCurrentDocumentUploadSummaryState,
  getPostILASectionsForCustomerDashboard,
  isSelfSufficiencyIncomplete,
  allSectionsComplete
} from 'reducers/selectors'
import { loadDocumentRequestData } from 'actions/document_request_actions'
import { loadSectionProgress } from 'actions/section_progress_actions'
import formatSectionUrl from 'lib/utilities/format_section_url'

import SectionItem from 'components/screens/dashboard/customer_dashboard/section_item'
import { DashboardPhase } from 'components/screens/dashboard/customer_dashboard/phase_map'
import SupportingDocuments from 'components/screens/dashboard/customer_dashboard/supporting_documents'
import Paragraph from 'components/type/paragraph'
import { DocumentUploadSummaryStates, SECTION_NAME_PUBLIC_CHARGE } from 'lib/constants'
import { getSectionDisplayName } from 'components/screens/document_upload/utils'
import ExternalLink from 'components/external_link'
import Heading from 'components/type/heading'
import CircleCheckmark from 'components/icons/circle_checkmark'
import FancySpinner from 'components/admin/fancy_spinner'
import ConfirmMoveToQA from 'components/screens/dashboard/customer_dashboard/confirm_move_to_qa'
import { isPublicChargeDisabled } from 'lib/feature_flags_deprecated'

interface ExplicitProps {
  phase: DashboardPhase
}

interface ActionProps {
  loadDocumentRequestData: Function
  loadSectionProgress: Function
}

interface MappedProps {
  allSectionsComplete: boolean
  currentDocumentUploadSummaryState: DocumentUploadSummaryStates
  kaseId: number
  sections: any[]
  isSelfSufficiencyIncomplete: boolean
}

type Props = ExplicitProps & MappedProps & ActionProps

export const RemainingInfoAndDocuments: FunctionComponent<Props> = ({
  allSectionsComplete,
  currentDocumentUploadSummaryState,
  sections,
  kaseId,
  loadDocumentRequestData,
  loadSectionProgress,
  phase,
  isSelfSufficiencyIncomplete
}) => {
  const [docRequestsLoaded, setDocRequestsLoaded] = useState(false)

  useEffect(() => {
    if (kaseId) {
      loadSectionProgress({ kaseId })
      loadDocumentRequestData({ kaseId }).then(() => setDocRequestsLoaded(true))
    }
  }, [kaseId])

  const selfSufficiencySectionName = getSectionDisplayName(SECTION_NAME_PUBLIC_CHARGE)

  const selfSufficiencyUrl = `/applications/${kaseId}/${SECTION_NAME_PUBLIC_CHARGE}`

  const customerWillMoveSelfToQA =
    allSectionsComplete && currentDocumentUploadSummaryState === DocumentUploadSummaryStates.AllSectionsAccepted

  const userCompletionEnabled = window.isUserCompletionEnabled()

  const renderDocUploadSection = () => {
    if (!isPublicChargeDisabled() && isSelfSufficiencyIncomplete) {
      return (
        <>
          <Heading size="sm" color="emphasized" tag="h2">
            Supporting Documents
          </Heading>
          <hr className="o-block c-divider c-divider--important" />
          <Paragraph>
            Please complete <a href={selfSufficiencyUrl}>{selfSufficiencySectionName} Questionnaire</a> to access your
            personalized list of required documents. To get a head start,{' '}
            <ExternalLink href="https://www.boundless.com/immigration-resources/hard-to-find-marriage-green-card-documents/">
              read more about hard-to-find documents you can start gathering now
            </ExternalLink>
            .
          </Paragraph>
        </>
      )
    } else {
      return <SupportingDocuments />
    }
  }

  if (!docRequestsLoaded)
    return (
      <div className="o-block o-layout--center flex items-center justify-center" style={{ height: '100%' }}>
        <FancySpinner variation="primary" />
      </div>
    )

  return (
    <>
      {customerWillMoveSelfToQA && userCompletionEnabled && (
        <>
          <Heading tag="h2" size="sm" color="emphasized">
            Next Steps
          </Heading>
          <ul className="o-block o-block--generous c-list">
            <li
              className="
                c-list__item
                o-griddle__row
                o-griddle__row--no-gutters
                o-griddle__row--align-items-center"
              style={{ minHeight: '5rem' }}
            >
              <div className="o-griddle__col--auto py-3 pr-2">
                <CircleCheckmark checked={false} />
              </div>
              <div className="o-griddle__col py-3">
                <Paragraph spacing="none" color="emphasized">
                  Begin Quality Assurance
                </Paragraph>{' '}
              </div>
              <div className="o-griddle__col--12 o-griddle__col--auto-sm-up py-1">
                <ConfirmMoveToQA inWorkArea />
              </div>
            </li>
          </ul>
        </>
      )}
      {sections.length > 0 && (
        <>
          <h2 className="o-block c-type c-type--headline-sm c-type--emphasized">Additional Forms</h2>
          <ul className="o-block o-block--generous c-list">
            {sections.map((section) => {
              const url = formatSectionUrl(kaseId, section.name)
              return (
                <SectionItem
                  empty={section.empty}
                  name={section.name}
                  phaseName={phase}
                  progress={section.progress}
                  url={url}
                  key={section.name}
                  dashboard_meta={section.dashboard_meta}
                />
              )
            })}
          </ul>
        </>
      )}
      <div className="o-block">{renderDocUploadSection()}</div>
    </>
  )
}

// TODO - add progress data to joint sponsor section
function mapStateToProps(state) {
  return {
    allSectionsComplete: allSectionsComplete(state),
    kaseId: getCurrentKaseId(state),
    sections: getPostILASectionsForCustomerDashboard(state),
    isSelfSufficiencyIncomplete: isSelfSufficiencyIncomplete(state),
    currentDocumentUploadSummaryState: getCurrentDocumentUploadSummaryState(state)
  }
}

function mapDispatchToActions(dispatch): ActionProps {
  return bindActionCreators({ loadDocumentRequestData, loadSectionProgress }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToActions)(RemainingInfoAndDocuments)
