import naturalizationSkipFunctions from './naturalization'

import Decorator from 'lib/decorator'

interface ShouldSkipChapterPublicArgs {
  kaseKind: string
  modelData: Decorator
  chapterName: string
}

interface ShouldSkipArgs {
  modelState: ModelState
  name: string
}

type ShouldSkipFunction = (args: ShouldSkipArgs) => boolean

export interface ShouldSkipCollection {
  [chapterName: string]: ShouldSkipFunction
}

const skipFunctions = {
  Naturalization: naturalizationSkipFunctions
}

export const shouldSkipChapter = ({ modelData, kaseKind, chapterName }: ShouldSkipChapterPublicArgs): boolean => {
  const fns = skipFunctions[kaseKind]
  const skipFunction = fns && fns[chapterName]
  if (!skipFunction) {
    return false
  }

  return modelData[skipFunction]()
}
