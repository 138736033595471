import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'

import { getCurrentKaseKind } from 'reducers/selectors'
import ModalNoHeader from 'components/modals/modal/modal_no_header'
import Button from 'components/button'
import ArrowIcon from 'components/icons/arrow_icon'
import Paragraph from 'components/type/paragraph'
import { KaseKind } from 'lib/constants'

interface ExplicitProps {
  showModal?: boolean
  hideModal: () => void
}

interface MappedProps {
  kaseKind: KaseKind
}

type Props = ExplicitProps & MappedProps

const KaseKindReadable = {
  [KaseKind.NewMarriageBasedGreenCard]: 'green card',
  [KaseKind.K1FianceVisa]: 'K-1 visa'
}

const PaymentSuccessModal: FunctionComponent<Props> = (props) => {
  const { showModal, hideModal, kaseKind } = props
  return (
    <ModalNoHeader
      onRequestClose={hideModal}
      modalWidth="38rem"
      isOpen={showModal}
      body={
        <div className="o-box">
          <h3 className="o-block o-block--ample o-layout--center c-type c-type--headline-md c-type--emphasized">
            Thank you for choosing Boundless
          </h3>
          <Paragraph centered>We’re excited to help you apply for your {KaseKindReadable[kaseKind]}!</Paragraph>
        </div>
      }
      footer={
        <div className="o-layout--center">
          <Button
            mobileLabel="Continue"
            color="primary"
            label="Continue my application"
            icon={<ArrowIcon arrowDirection="right" />}
            onClick={hideModal}
          />
        </div>
      }
    />
  )
}

function mapStateToProps(state) {
  return {
    kaseKind: getCurrentKaseKind(state)
  }
}

export default connect(mapStateToProps)(PaymentSuccessModal)
