import BoundlessOverview from './panels/outcomes/boundless_overview'
import MobileChapterInterstitial from './panels/mobile_chapter_interstitial'
import MoralCharacterInterstitial from 'components/panels/moral_character_interstitial'
import NaturalizationEligible from 'components/panels/outcomes/naturalization_eligible'
import NaturalizationEligibleCannotHelp from 'components/panels/outcomes/naturalization_eligible_cannot_help'
import NaturalizationIneligible from 'components/panels/outcomes/naturalization_ineligible'
import NaturalizationSetupStart from 'components/panels/naturalization_setup_start'
import PetitionReview from 'components/panels/petition_review/index'
import PreparingInAdvance from 'components/panels/preparing_in_advance'
import PrePetitionInterstitial from 'components/panels/pre_petition_interstitial'
import PublicChargeStart from 'components/panels/public_charge_start'
import SatisfactionGuarantee from './panels/outcomes/satisfaction_guarantee'
import SaveProgress from 'components/panels/save_progress'
import SectionEndInterstitial from 'components/panels/section_end_interstitial'
import SetupCompleted from 'components/panels/outcomes/setup_completed'
import SetupCompletedIneligible from 'components/panels/outcomes/setup_completed_ineligible'
import SetupStart from 'components/panels/setup_start'
import SpouseQuestions from 'components/panels/spouse_questions'
import UscisQuestionnaireStart from 'components/panels/uscis_questionnaire_start'

export default {
  BoundlessOverview,
  MobileChapterInterstitial,
  MoralCharacterInterstitial,
  NaturalizationEligible,
  NaturalizationEligibleCannotHelp,
  NaturalizationIneligible,
  NaturalizationSetupStart,
  PetitionReview,
  PreparingInAdvance,
  PrePetitionInterstitial,
  PublicChargeStart,
  SatisfactionGuarantee,
  SaveProgress,
  SectionEndInterstitial,
  SetupCompleted,
  SetupCompletedIneligible,
  SetupStart,
  SpouseQuestions,
  UscisQuestionnaireStart
}
