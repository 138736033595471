import React, { FunctionComponent, ReactNode, MouseEvent } from 'react'
import CloseIcon from 'components/icons/close_icon'

interface ExplicitProps {
  headerContent: ReactNode
  helpContent: ReactNode
  isOpen: boolean
  onToggle: (event: MouseEvent<HTMLButtonElement>) => void
}

const HelpSectionItem: FunctionComponent<ExplicitProps> = ({ headerContent, helpContent, isOpen, onToggle }) => {
  return (
    <div className="pt-3 mb-2 tool-menu-item-link o-action-items__help-section pb-3">
      <button
        className="flex justify-between items-center w-full c-btn o-action-items__help-section--item"
        onClick={onToggle}
      >
        <span className="c-type--inline c-type--subhead-sm">{headerContent}</span>
        <CloseIcon size="small" className={`pt-0 ${isOpen ? '' : 'hidden'}`} />
      </button>

      <div className={`o-action-items__help-section--content ${isOpen ? 'open' : ''}`}>
        <div className="o-box o-box--ample c-accordion__content mt-2">{helpContent}</div>
      </div>
    </div>
  )
}

export default HelpSectionItem as FunctionComponent<ExplicitProps>
