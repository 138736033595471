import React, { Component } from 'react'
import { connect } from 'react-redux'

import {
  getCurrentChapter,
  getCurrentContentSource,
  getCurrentSectionForOutline,
  getCurrentSectionProgress,
  getCurrentPanel,
  getCurrentKaseKind
} from 'reducers/selectors'
import customChapterMappings from './custom_chapters'
import classNames from 'classnames'
import _isEmpty from 'lodash/isEmpty'
import _find from 'lodash/find'
import _some from 'lodash/some'
import _every from 'lodash/every'

import { showPanel } from 'actions/panel_actions'

import ProgressBar from 'components/progress_bar'
import SaveIndicator from 'components/save_indicator'
import panelLocaleService from 'services/panel_locale_service'

interface Props {
  contentSource?: string
  currentChapter?: any
  currentKaseKind: string
  currentPanel: any
  section: any
  percentDone: number
  showPanel: (args: { panel: any; via: string }) => void
}

class PetitionOutline extends Component<Props> {
  renderRemainingTime() {
    let minsRemaining = (this.props.section.time_estimation_mins * (100 - this.props.percentDone)) / 100

    let timeRemaining = ''

    if (minsRemaining === 0) return timeRemaining

    if (Math.round(minsRemaining) <= 1) {
      timeRemaining = `1 min effort left`
    } else {
      timeRemaining = `${Math.round(minsRemaining)} mins effort left`
    }

    return <div className="o-block o-block--tight c-type c-type--body-sans-sm">{timeRemaining}</div>
  }

  isPanelSelected(panelSlug) {
    return this.props.currentPanel.slug === panelSlug
  }

  isMarriageBasedGreenCardApplication() {
    return this.props.currentKaseKind === 'MarriageBasedGreenCard'
  }

  getPanelChapters(chapterMappings) {
    const allPanelChapters = this.props.section.chapters
    return chapterMappings.map((chapter) =>
      _find(allPanelChapters, (panelChapter) => this.getChapterTitle(panelChapter) === chapter)
    )
  }

  getChapterTitle(chapter) {
    return panelLocaleService.t(`${chapter.chapter_key}.title`)
  }

  isChapterSelected(chapterMappings) {
    if (!this.props.currentChapter) return false

    const currentChapterTitle = this.getChapterTitle(this.props.currentChapter)

    return _some(chapterMappings, (chapterMapping) => currentChapterTitle === chapterMapping)
  }

  chaptersHaveData(panelChapters) {
    return _some(panelChapters, (chapter) => chapter.hasSomeData)
  }

  chaptersAreComplete(panelChapters) {
    return _every(panelChapters, (chapter) => chapter.complete)
  }

  chaptersAreStatic(panelChapters) {
    return _every(panelChapters, (chapter) => chapter.static)
  }

  getFirstPanelForChapter(chapter) {
    const allPanelChapters = this.props.section.chapters
    return _find(allPanelChapters, (panelChapter) => this.getChapterTitle(panelChapter) === chapter).panels[0]
  }

  renderCustomChapter(customChapter, i) {
    if (_isEmpty(customChapter.panelChapterMapping)) return null

    const panelChaptersMapping = customChapter.panelChapterMapping
    const panelChaptersForCustomChapter = this.getPanelChapters(panelChaptersMapping)

    const isSelected = this.isChapterSelected(panelChaptersMapping)
    const hasSomeData = this.chaptersHaveData(panelChaptersForCustomChapter)
    const isComplete = this.chaptersAreComplete(panelChaptersForCustomChapter)
    const isStatic = this.chaptersAreStatic(panelChaptersForCustomChapter)

    let isClickable = !this.props.section.linear || isSelected || i === 0 || !this.clickableChapterLimitReached

    // If a chapter does not have all data, all chapters coming after that
    // should not be clickable
    this.clickableChapterLimitReached = this.clickableChapterLimitReached || !isComplete

    const className = classNames('chapter', {
      selected: isSelected,
      'o-block o-block--tight': isSelected,
      clickable: true,
      disabled: false,
      complete: !isStatic && isComplete,
      incomplete: !isStatic && hasSomeData && !isComplete
    })

    function handleClick() {
      const firstPanel = this.getFirstPanelForChapter(panelChaptersMapping[0])

      if (!firstPanel) return

      this.props.showPanel({
        panel: firstPanel,
        via: 'PetitionOutlineExperimentNav'
      })
    }

    return (
      <React.Fragment>
        <div id={customChapter.name} className={className} onClick={handleClick.bind(this)}>
          <h4 className="c-type c-type--subhead-sm c-type--inline">{customChapter.name}</h4>

          {isClickable && hasSomeData && !isComplete && <span className="asterix">*</span>}

          {hasSomeData && isComplete && <div className="checkmark" />}
        </div>
      </React.Fragment>
    )
  }

  renderCustomChapters() {
    return customChapterMappings.map((customChapterMapping, i) => {
      return <li key={customChapterMapping.name}>{this.renderCustomChapter(customChapterMapping, i)}</li>
    })
  }

  renderProgress() {
    return (
      <li className="o-block o-block--compact">
        <div className="o-block o-block--tight c-type c-type--body-sans-sm">{this.props.percentDone}% done</div>

        {this.renderRemainingTime()}

        <SaveIndicator />
      </li>
    )
  }

  renderPaymentFunnelEntry(panelSlug, entryText) {
    const isSelected = this.isPanelSelected(panelSlug)

    const className = classNames('chapter', 'o-block', 'o-block--tight', 'c-type', 'c-type--subhead-sm', 'clickable', {
      selected: isSelected
    })

    function handleClick() {
      this.props.showPanel({
        slug: panelSlug,
        via: 'OutlineNav'
      })
    }

    return (
      <li id={panelSlug} className={className} onClick={handleClick.bind(this)}>
        <h4 className="c-type c-type--subhead-sm">{entryText}</h4>
      </li>
    )
  }

  renderPaymentFunnel() {
    if (this.props.section.linear) return null

    return (
      <ul className="outline-list o-list-bare u-hide@sm-down">
        {this.isMarriageBasedGreenCardApplication() &&
          this.renderPaymentFunnelEntry('application-summary', 'Application Summary')}

        {this.renderPaymentFunnelEntry('boundless-overview', 'Next Steps')}

        {this.renderPaymentFunnelEntry('satisfaction-guarantee', 'Apply With Lawyer Support')}
      </ul>
    )
  }

  isHidden() {
    return Boolean(this.props.currentPanel && this.props.currentPanel.emphasized)
  }

  render() {
    this.clickablePanelLimitReached = false
    this.clickableChapterLimitReached = false

    return (
      <div className="o-grid__col-2 u-hide@sm-down o-grid__col--no-gutter o-grid__col--collapsed">
        <ProgressBar.Horizontal className="o-block o-block--ample u-hide@md-up" fillStyles={['slim', 'secondary']} />
        <section className="o-box o-box--spacious c-expanded-outline">
          <h2 className="o-block o-block--copious o-layout--padded-right--full c-type c-type--headline-sm c-type--emphasized">
            Green Card Application
          </h2>

          <ul className="outline outline-list o-block o-list-bare u-hide@sm-down">{this.renderCustomChapters()}</ul>

          {this.renderPaymentFunnel()}
          <div id="vwo-petition-outline-progress" className="o-box o-box--copious">
            {this.renderProgress()}
          </div>
        </section>
        <ProgressBar.Vertical
          id="vwo-petition-outline-progress-bar"
          className="u-hide@sm-down o-layout--sticky"
          fillStyles={['slim', 'secondary', 'outer-right']}
        />
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    currentChapter: getCurrentChapter(state),
    contentSource: getCurrentContentSource(state),
    currentKaseKind: getCurrentKaseKind(state),
    currentPanel: getCurrentPanel(state),
    section: getCurrentSectionForOutline(state),
    percentDone: getCurrentSectionProgress(state)
  }
}

function mapDispatchToActions(dispatch) {
  return {
    showPanel: (...args) => dispatch(showPanel(...args))
  }
}

export default connect(mapStateToProps, mapDispatchToActions)(PetitionOutline)
