import server from 'lib/api/v1/server'
import { AxiosPromise } from 'axios'

const usersPath = '/api/v1/users'

function acceptAttorneyAgreement(): AxiosPromise {
  return server
    .patch(usersPath + '/accepted_attorney_agreement')
    .then((response) => {
      return response.data
    })
    .catch((error) => Promise.reject(error))
}

function updateEmail(email: string): AxiosPromise {
  return server
    .patch(usersPath + '/update_email', { email })
    .then((response) => {
      return response.data
    })
    .catch((error) => Promise.reject(error))
}

export default {
  acceptAttorneyAgreement,
  updateEmail
}
