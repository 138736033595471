import 'gascrolldepth'

// gascrolldepth leaks into the global window object
const scrollDepth = window.gascrolldepth
window.gascrolldepth = undefined

export function initializeScrollTracking(telemetryService) {
  function pageScrolled(data) {
    // this handler fires three times with ScrollTiming, ScrollDistance and
    // ScrollPercentage. We use the ScrollTiming because it includes both the
    // timing and the percentage information.
    if (data.event !== 'ScrollTiming') {
      return
    }

    let eventData = {
      percentage: data.eventLabel,
      timing: data.eventTiming
    }

    // we consider a scrolling event interactive if it's beyond 75%
    if (data.eventLabel === '25%' || data.eventLabel === '50%') {
      eventData['nonInteraction'] = 1
    }

    telemetryService.trackScroll(eventData)
  }

  scrollDepth.init({
    eventHandler: pageScrolled
  })
}

/**
 * Reset the gascrolldepth plugin. Call this method when routing without a page
 * reload, like in the app.
 */
export function resetScrollTracking() {
  if (scrollDepth != null) {
    scrollDepth.reset()
  }
}
