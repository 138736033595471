import React from 'react'

import BufferedFieldValue from 'components/forms/buffered_field_value'
import TypeaheadInput from 'components/forms/inputs/typeahead'
import { formattedAdmissionClasses } from './options'

import { getCodeFromName, getNameFromCode } from 'components/forms/inputs/typeahead/config'

import { defaultInputProps, InputProps } from 'components/forms/inputs'

interface Props extends InputProps {
  hiddenAdmissionClassCodes: string[]
}

export default class AdmissionClassesInput extends React.Component<Props> {
  static defaultProps = {
    ...defaultInputProps,
    hiddenAdmissionClassCodes: []
  }

  render() {
    const {
      afterChangeEvents,
      className,
      disabled,
      hiddenAdmissionClassCodes,
      id,
      onBlur,
      path,
      placeholder
    } = this.props

    return (
      <BufferedFieldValue
        afterChangeEvents={afterChangeEvents}
        formatter={getNameFromCode(formattedAdmissionClasses)}
        path={path}
        serializer={getCodeFromName(formattedAdmissionClasses)}
      >
        {(value, onChange) => (
          <div className="qa-typeahead-container">
            <TypeaheadInput
              className={className}
              path={path}
              disabled={disabled}
              hiddenCodes={hiddenAdmissionClassCodes || []}
              id={`${id}-admission-classes`}
              name={name}
              onBlur={onBlur}
              onChange={onChange}
              placeholder={placeholder}
              value={value}
              typeaheadValuesList={formattedAdmissionClasses}
            />
          </div>
        )}
      </BufferedFieldValue>
    )
  }
}
