import React, { FunctionComponent, useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import _debounce from 'lodash/debounce'
import SkeletonBlock from 'components/skeleton/block'
import TimelineStep from './timeline_step'
import { TELEMETRY_EVENTS } from 'lib/constants'
import { postTelemetry } from 'actions/telemetry_actions'
import { KasePhase } from './types'

type Props = ActionProps & {
  /**
   * The DashboardPhase that matches the customer's phase.
   */
  currentPhase: KasePhase
  /**
   * The DashboardPhase that's currently being previewed.
   */
  currentPhasePreview?: KasePhase
  phaseList?: KasePhase[]
  updateCurrentPhasePreview: (phase: KasePhase) => () => void
  isLoading: boolean
  setArrowLeftPosition: (left: number) => void
  setIsFullTimelineVisible: (isFullTimelineVisible: boolean) => void
}

interface PhaseClickTrackingParams {
  phase: string
  currentPhase: string
}

interface ActionProps {
  trackPhaseClicked?: (params: PhaseClickTrackingParams) => void
}

const Timeline: FunctionComponent<Props> = ({
  currentPhase,
  currentPhasePreview,
  isLoading,
  phaseList,
  setArrowLeftPosition,
  setIsFullTimelineVisible,
  trackPhaseClicked,
  updateCurrentPhasePreview
}) => {
  if (isLoading) {
    return (
      <div className="c-timeline">
        <SkeletonBlock height="3rem" />
      </div>
    )
  }
  const [currentPhaseElem, setCurrentPhaseElem] = useState(null)
  const [currentPhaseIndex, setCurrentPhaseIndex] = useState(0)
  const [width, setWidth] = useState(window.innerWidth)
  const timelineRef = useRef(null)

  useEffect(() => {
    setCurrentPhaseIndex(phaseList?.findIndex((x) => x.active === currentPhase.active))
  }, [currentPhase])

  useEffect(() => {
    const debouncedHandleResize = _debounce(function handleResize() {
      setWidth(window.innerWidth)
    }, 250)

    window.addEventListener('resize', debouncedHandleResize)

    return () => {
      window.removeEventListener('resize', debouncedHandleResize)
    }
  })

  useEffect(() => {
    if (timelineRef.current !== null) {
      const timelineOffsetWidth = timelineRef.current.offsetWidth
      const timelineScrollWidth = timelineRef.current.scrollWidth
      setIsFullTimelineVisible(timelineOffsetWidth === timelineScrollWidth)
    }

    if (currentPhaseElem !== null) {
      const timelineElem = timelineRef.current
      const padding = parseFloat(getComputedStyle(timelineElem).getPropertyValue('padding-left'))
      const arrowWidth = parseFloat(getComputedStyle(currentPhaseElem, ':after').getPropertyValue('width'))

      const chevronLeft = currentPhaseElem.offsetLeft
      const chevronCenter = currentPhaseElem.offsetWidth / 2

      // this scrolls the current phase chevron all the way
      // to the left within the container
      const valueToScroll = chevronLeft + arrowWidth - padding
      timelineElem.scrollLeft = valueToScroll

      if (currentPhaseIndex > 0) {
        const leftOffset = window.innerWidth < 1200 ? currentPhaseElem.getBoundingClientRect().x : chevronLeft
        setArrowLeftPosition(leftOffset - padding + chevronCenter)
      }
    }
  }, [currentPhaseElem, width])

  const phasePreviewIndex = phaseList.indexOf(currentPhasePreview)

  const onPhaseClicked = (phase: KasePhase) => {
    trackPhaseClicked({ phase: phase.name, currentPhase: currentPhase.name })

    if (currentPhasePreview !== phase && currentPhase.name !== phase.name) {
      updateCurrentPhasePreview(phase)
    } else if (currentPhase.name === phase.name) {
      updateCurrentPhasePreview(null)
    }
  }

  return (
    <div className="o-container c-timeline__container" ref={timelineRef}>
      <div className="c-timeline">
        {phaseList?.map((phase, index) => (
          <TimelineStep
            key={phase.name}
            phase={{ index, ...phase }}
            isPastStep={index < currentPhaseIndex}
            isCurrentStep={index === currentPhaseIndex}
            isFutureStep={index > currentPhaseIndex}
            isPreviewedStep={index === phasePreviewIndex}
            showCPStyle={phaseList.some((p) => p.name === 'CUSTOMER_COMPLETED')}
            onPhaseClicked={onPhaseClicked}
            setCurrentPhaseElem={setCurrentPhaseElem}
          />
        ))}
      </div>
    </div>
  )
}

function mapDispatchToActions(dispatch: Function): ActionProps {
  return {
    trackPhaseClicked: (args) => dispatch(postTelemetry(TELEMETRY_EVENTS.CLICKED_TIMELINE_STEP, args))
  }
}

export default connect(null, mapDispatchToActions)(Timeline)
