import React, { FunctionComponent, ReactNode } from 'react'
import cx from 'classnames'

import Paragraph from 'components/type/paragraph'
import CircleCheckmark from 'components/icons/circle_checkmark'

interface ExplicitProps {
  isComplete: boolean
  isDisabled?: boolean
  sectionTitle: string
  additionalInfoColor?: 'warning' | 'success'
  additionalInfo?: string
  cta: ReactNode
}

const SectionItemBasic: FunctionComponent<ExplicitProps> = ({
  isComplete,
  isDisabled,
  sectionTitle,
  additionalInfoColor,
  additionalInfo,
  cta
}) => {
  return (
    <li
      className={cx(
        'c-list__item',
        'o-griddle__row',
        'o-griddle__row--no-gutters',
        'o-griddle__row--align-items-center',
        {
          'c-list__item--disabled': isComplete && isDisabled
        }
      )}
      style={{ minHeight: '5rem' }}
    >
      <div className="o-griddle__col--auto py-3 pr-2">
        <CircleCheckmark checked={isComplete} />
      </div>
      <div className="o-griddle__col py-3">
        <Paragraph spacing="none" color="emphasized">
          {sectionTitle}
        </Paragraph>{' '}
        {additionalInfo && (
          <Paragraph
            size="sm"
            color={additionalInfoColor ? additionalInfoColor : 'warning'}
            spacing="none"
            className="mt-3"
          >
            {additionalInfo}
          </Paragraph>
        )}
      </div>
      {cta}
    </li>
  )
}

export default SectionItemBasic as FunctionComponent<ExplicitProps>
