import React from 'react'

import BufferedFieldValue from 'components/forms/buffered_field_value'

import UNITED_STATES from 'data/united_states'

import { defaultInputProps, InputProps } from 'components/forms/inputs'

interface Props extends InputProps {
  country?: string
}

export default class ProvinceInput extends React.Component<Props> {
  static defaultProps = {
    ...defaultInputProps
  }

  inputRef: Nullable<HTMLInputElement | HTMLSelectElement> = null

  isUS() {
    const { country } = this.props

    return country && country.toLowerCase() === 'us'
  }

  render() {
    const { className, disabled, id, onBlur, path } = this.props

    return (
      <BufferedFieldValue path={path}>
        {(value, onChange) => {
          const inputProps = {
            className: className,
            disabled,
            id: `${id}-province`,
            name: 'province',
            onBlur: onBlur,
            onChange,
            ref: this.inputRef,
            value
          }
          return [
            <div key="province-control">
              {this.isUS() ? (
                <select {...inputProps} data-model-path={path}>
                  {Object.keys(UNITED_STATES).map((stateCode) => {
                    const state = UNITED_STATES[stateCode]

                    return (
                      <option key={stateCode} value={stateCode}>
                        {state}
                      </option>
                    )
                  })}
                </select>
              ) : (
                <input {...inputProps} data-model-path={path} required={true} type="text" />
              )}
            </div>
          ]
        }}
      </BufferedFieldValue>
    )
  }
}
