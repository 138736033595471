import React from 'react'
import { connect } from 'react-redux'

import { getCurrentFlattenedPanelsCount, getCurrentPanelIndex, isCurrentPanelCustomComponent } from 'reducers/selectors'

import ProgressBar from 'components/progress_bar'

interface Props {
  currentPanelIndex: number
  isCustomComponent: boolean
  panelCount: number
}

class ProgressIndicator extends React.Component<Props> {
  getProgressPercent() {
    return Math.round((100 * (this.props.currentPanelIndex + 1)) / this.props.panelCount)
  }

  render() {
    if (this.props.isCustomComponent) return null

    const currentPanelNumber = this.props.currentPanelIndex + 1

    return (
      <div className="c-sheet__progress-indicator u-hide@md-up">
        <ProgressBar.Horizontal
          className="c-sheet__progress-bar"
          progress={this.getProgressPercent()}
          fillStyles={['slim', 'secondary']}
        />

        <div className="c-sheet__progress-label c-type c-type--subhead-sm c-type--muted">
          {currentPanelNumber} / {this.props.panelCount}
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    currentPanelIndex: getCurrentPanelIndex(state),
    isCustomComponent: isCurrentPanelCustomComponent(state),
    panelCount: getCurrentFlattenedPanelsCount(state)
  }
}

export default connect(mapStateToProps)(ProgressIndicator)
