import React from 'react'
import { connect } from 'react-redux'

import { getUnitConversionPreference } from 'reducers/selectors'
import { getCentimetersFromFtIn, getFeet, getInches } from './helpers'

import BufferedFieldValue from 'components/forms/buffered_field_value'

interface Props {
  className: string
  disabled: boolean
  id: string
  path: string
  containerClassName: string
  units: 'metric' | 'imperial'
}

class MajorInput extends React.Component<Props> {
  static defaultProps = {
    className: '',
    units: 'imperial',
    containerClassName: ''
  }

  formatInputValue = (centimeters: Nullable<string>): string => {
    if (!centimeters) return ''

    let displayValue

    if (this.isMetric()) {
      displayValue = centimeters
    } else {
      displayValue = getFeet(centimeters)
    }

    return parseInt(displayValue, 10).toString()
  }

  serializeServerValue = (majorValue: Nullable<string>, { previousValue }): number => {
    if (this.isMetric()) {
      return parseInt(majorValue, 10)
    } else {
      const feet = parseInt(majorValue, 10)
      const previousInches = getInches(previousValue)

      return getCentimetersFromFtIn(feet, previousInches)
    }
  }

  isMetric() {
    return this.props.units === 'metric'
  }

  render() {
    const { className, onBlur, id, path, containerClassName } = this.props

    return (
      <BufferedFieldValue
        className={containerClassName}
        formatter={this.formatInputValue}
        path={path}
        serializer={this.serializeServerValue}
      >
        {(value, onChange) => (
          <input
            data-model-path={path}
            id={`${id}-major`}
            pattern="\d*"
            inputMode="numeric"
            type="tel"
            name="major"
            className={`c-paper-form__control ${className}`}
            required={true}
            autoComplete="off"
            placeholder="0"
            onBlur={onBlur}
            onChange={onChange}
            value={value}
          />
        )}
      </BufferedFieldValue>
    )
  }
}

function mapStateToProps(state) {
  return {
    units: getUnitConversionPreference(state)
  }
}

export default connect(mapStateToProps)(MajorInput)
