import React, { FunctionComponent } from 'react'
import cx from 'classnames'
import formatMoney from 'lib/format_money'
import { SideBySideLineItem } from 'lib/types'
import Markdown from 'components/markdown'

interface Props {
  isDiscount?: boolean
  lineItems: SideBySideLineItem[]
  onShowServiceFeeModal?: () => void
  showAppliedDiscounts: boolean
}

const CheckoutLineItems = ({ isDiscount, lineItems, onShowServiceFeeModal, showAppliedDiscounts }) => {
  const classNames = cx('md:flex justify-between', {
    'mb-0': showAppliedDiscounts,
    'mb-2': !showAppliedDiscounts,
    'text-success-500': isDiscount
  })

  return lineItems.map((lineItem: SideBySideLineItem, i: number) => {
    return (
      <>
        <li className={classNames} key={`lineItemInFull${i}`}>
          <Markdown
            source={lineItem.name}
            className="c-type--react-markdown c-type--bold mt-2"
            options={{ linkToNewTabs: true, toggleContextualHelp: onShowServiceFeeModal }}
          />
          <div className="mt-1">
            {isDiscount && '-'}
            {showAppliedDiscounts && lineItem.amount_before_discount > 0
              ? formatMoney(lineItem.amount_before_discount)
              : formatMoney(lineItem.amount)}
          </div>
        </li>
        {!showAppliedDiscounts && lineItem.amount_before_discount && (
          <li
            className={cx('c-type--copy-spacing c-type--body-sans-xs -mt-2', classNames)}
            key={`lineItemDiscount${i}`}
          >
            <div className="text-success-500">{lineItem.discount_description}</div>
            <div className="line-through opacity-50">{formatMoney(lineItem.amount_before_discount)}</div>
          </li>
        )}
      </>
    )
  })
}

export default CheckoutLineItems as FunctionComponent<Props>
