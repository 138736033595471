import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'

import Paragraph from 'components/type/paragraph'
import { WORKFLOW_STATUSES } from 'lib/constants'
import DashboardEmailIllustration from 'components/illustrations/dashboard_email_illustration'
import { getStewardInfo } from 'reducers/selectors'
import { TeamMemberInfo } from 'reducers/dashboard'

interface ExplicitProps {
  workflowStatus: WORKFLOW_STATUSES
}

interface PropsFromRedux {
  steward: TeamMemberInfo
}

const LegalReviewPhase: FunctionComponent<ExplicitProps & PropsFromRedux> = ({ workflowStatus, steward }) => {
  const customerShouldCheckTheirEmail =
    [
      WORKFLOW_STATUSES.AOS_MASTER_PDF_WAITING_FOR_CUSTOMER_INFO_DOCS_POST_LAWYER_REVIEW,
      WORKFLOW_STATUSES.CP_MASTER_PDF_WAITING_FOR_CUSTOMER_INFO_DOCS_POST_LAWYER_REVIEW,
      WORKFLOW_STATUSES.AOS_MASTER_PDF_FINAL_EDITS_POST_LAWYER_REVIEW,
      WORKFLOW_STATUSES.CP_MASTER_PDF_FINAL_EDITS_POST_LAWYER_REVIEW,
      WORKFLOW_STATUSES.AOS_MASTER_PDF_FINAL_EDITS,
      WORKFLOW_STATUSES.CP_MASTER_PDF_FINAL_EDITS
    ].indexOf(workflowStatus) !== -1

  return (
    <>
      {customerShouldCheckTheirEmail && (
        <div className="o-layout--center o-box o-box--spacious">
          <div className="o-block o-block--compact">
            <DashboardEmailIllustration />
          </div>
          <h2 className="o-block c-type c-type--headline-md c-type--emphasized">
            Look out for an email from {steward.firstName || 'your Customer Success Advocate'}!
          </h2>
          <Paragraph>They just need your answers to a few questions to continue the process.</Paragraph>
        </div>
      )}
    </>
  )
}

function mapStateToProps(state: any) {
  return {
    steward: getStewardInfo(state)
  }
}

export default connect(mapStateToProps)(LegalReviewPhase) as FunctionComponent<ExplicitProps>
