import u from 'updeep'
import { LOADED_ADDRESSES, LOADING_ADDRESSES, LOADED_CURRENT_ADDRESS_HISTORY_EDIT } from 'lib/constants'

export interface Address {
  street: string
  unit_number: string
  unit_type: string
  city: string
  country_code: string
  postal_code: string
  province: string
}

export interface AddressHistory {
  address_history_mismatch: boolean
  address?: Address
  end_date: string
  id?: number
  intended_address?: boolean
  mailing_address?: boolean
  physical_address_abroad?: boolean
  owner?: string
  shared?: boolean
  spouse_end_date?: string
  spouse_start_date?: string
  start_date: string
}

export interface PersonAddressHistory {
  complete: boolean
  current_address_filled: boolean
  five_year_complete?: boolean
  has_valid_outside_us_address?: boolean
  address_history?: AddressHistory[]
}

export interface KaseAddressHistory {
  kase_id: number
  sponsor: PersonAddressHistory
  beneficiary: PersonAddressHistory
}

export interface AddressHistoryById {
  [key: string]: AddressHistory
}

export interface AddressHistoryStore {
  addressHistories: KaseAddressHistory
  loadingAddressHistories?: boolean
  currentAddressHistoryEdit?: AddressHistory
}

const initialState: AddressHistoryStore = {
  addressHistories: {
    kase_id: null,
    sponsor: {
      complete: false,
      current_address_filled: false,
      address_history: []
    },
    beneficiary: {
      complete: false,
      current_address_filled: false,
      address_history: []
    }
  },
  loadingAddressHistories: false
}

export default function addressHistoriesReducer(state: AddressHistoryStore, action: any): AddressHistoryStore {
  state = state || initialState
  switch (action.type) {
    case LOADING_ADDRESSES: {
      return u(
        {
          loadingAddressHistories: action.payload
        },
        state
      )
    }

    case LOADED_ADDRESSES: {
      return u(
        {
          addressHistories: action.payload
        },
        state
      )
    }

    case LOADED_CURRENT_ADDRESS_HISTORY_EDIT: {
      return u(
        {
          currentAddressHistoryEdit: action.payload
        },
        state
      )
    }

    default: {
      return state
    }
  }
}
