import server from './server'
import { AxiosPromise } from 'axios'

import { MasterPDFPageType } from 'reducers/master_pdf'

interface UpdateParams {
  [objectKey: string]: any
}

function update(masterPdfId: number, masterPdfParams: UpdateParams): AxiosPromise {
  const url = `/api/master_pdfs/${masterPdfId}`

  return server.put(url, {
    master_pdf: masterPdfParams
  })
}

interface CreateParams {
  adminNote?: string
  createdBy: string
  kaseId: number
  watermark: boolean
  masterPagesList: MasterPDFPageType[]
}

function create({ adminNote, createdBy, kaseId, watermark, masterPagesList }: CreateParams): AxiosPromise {
  const url = `/admin/kases/${kaseId}/master_pdfs`

  return server.post(url, {
    admin_note: adminNote,
    created_by: createdBy,
    is_watermarked: watermark,
    master_page_list: masterPagesList.map((page) => page.key)
  })
}

interface MasterPdfParams {
  created_by: string
  size: number
  s3_direct_path: string
}

interface CreateUploadParams {
  kaseId: number
  masterPdfParams: MasterPdfParams
}

function createUpload({ kaseId, masterPdfParams }: CreateUploadParams): AxiosPromise {
  const url = `/admin/kases/${kaseId}/master_pdf_uploads`

  return server.post(url, {
    master_pdf: masterPdfParams
  })
}

export default { update, create, createUpload }
