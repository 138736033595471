import { AxiosRequestConfig } from 'axios'

const defaultHeaders = {
  Accept: 'application/json'
}

export function addDefaultConfigToRequest(config: AxiosRequestConfig) {
  config.headers = Object.assign({}, defaultHeaders, config.headers || {})

  return config
}
