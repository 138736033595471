import React, { FunctionComponent } from 'react'
import Heading from 'components/type/heading'
import { connect } from 'react-redux'
import { getIssuesProgress } from 'reducers/selectors'

interface MappedProps {
  percentCompleted: number
}

export const Outline: FunctionComponent<MappedProps> = ({ percentCompleted }) => {
  return (
    <div className="o-pane__fill o-grid__row">
      <div className="o-grid__col-2 px-4">
        <div className="pt-16">
          <hr className="o-block c-divider c-divider--narrow c-divider--emphasized c-divider--secondary" />
          <Heading tag="h1" size="sm" color="emphasized">
            Questions and Tasks
          </Heading>
        </div>

        <div className="o-block o-block--compact">
          <div className="o-block o-block--tight c-type c-type--body-sans-sm">{percentCompleted}% done</div>
        </div>
      </div>
    </div>
  )
}
function mapStateToProps(state: any) {
  return {
    percentCompleted: getIssuesProgress(state)
  }
}

export default connect(mapStateToProps)(Outline)
