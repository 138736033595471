import React, { FunctionComponent } from 'react'
import SkeletonHeading from 'components/skeleton/heading'
import SkeletonParagraph from 'components/skeleton/paragraph'
import SkeletonBlock from 'components/skeleton/block'
import BreakpointWrapper from 'components/breakpoint_wrapper'

export const LoadingSkeleton: FunctionComponent = () => {
  return (
    <div className="o-action-items__wrapper">
      <BreakpointWrapper mobile>
        <div className="p-4 bg-white">
          <SkeletonHeading centered size="lg" />
          <SkeletonBlock height="4rem" spacing="lg" />
          <SkeletonBlock height="2rem" />
          <SkeletonParagraph numLines={3} />
          <SkeletonBlock height="2rem" />
          <SkeletonParagraph numLines={3} />
        </div>
      </BreakpointWrapper>
      <BreakpointWrapper desktop>
        <div className="o-griddle__row o-griddle__row--no-gutters">
          <aside className="o-griddle__col o-griddle__col--3 px-4 pt-8 lg:px-6">
            <div className="ml-3">
              <SkeletonHeading centered />
              <SkeletonParagraph centered />
            </div>
          </aside>
          <main className="o-griddle__col--6">
            <div className="o-action-items__center-column">
              <SkeletonHeading centered />
              <SkeletonParagraph numLines={5} />
              <SkeletonBlock height="2rem" />
            </div>
          </main>
          <aside className="o-griddle__col o-griddle__col--3 px-4 pt-8 lg:px-6">
            <div className="ml-3">
              <SkeletonBlock height="2rem" />
              <SkeletonParagraph numLines={2} />
            </div>
          </aside>
        </div>
      </BreakpointWrapper>
    </div>
  )
}

export default LoadingSkeleton
