import _cloneDeep from 'lodash/cloneDeep'

export const getKaseModelTemplate = (key) => {
  let template = window.kaseModelTemplates[key]
  if (template) return template

  // eslint-disable-next-line no-console
  console.warn('No kase model template for ' + key + ' exists. Please add in kase_model_helper.rb')
  /* eslint-enable no-console */

  return {}
}

export const buildKaseModelTemplate = (key) => {
  return _cloneDeep(getKaseModelTemplate(key))
}
