import React, { FunctionComponent, useEffect, useState } from 'react'
import { connect } from 'react-redux'

import {
  allIssuesAddressed,
  getCurrentIssue,
  getCurrentKaseId,
  getCurrentOpenQaTask,
  getOpenIssues,
  getStewardInfo,
  isLoadingIssues,
  isLoadingTasks
} from 'reducers/selectors'
import { loadIssues, addMessage, setCurrentIssue } from 'actions/issue_actions'
import { assignTask, loadTasks } from 'actions/tasks_actions'

import { Issue } from 'reducers/issues'
import { Task } from 'reducers/tasks'
import { bindActionCreators, Dispatch } from 'redux'
import IssuesContainer from 'components/screens/issues/issues_container'
import HelpSection from 'components/screens/issues/help_section'
import Outline from 'components/screens/issues/outline'
import ThreadPanel from 'components/screens/issues/thread_panel'
import SubmitSuccess from 'components/screens/issues/submit_success'

import LoadingSkeleton from 'components/screens/issues/loading_skeleton'
import BreakpointWrapper from 'components/breakpoint_wrapper'
import MobileIssuesContainer from './mobile_issues_container'
import MobileIssueHints from './mobile_issue_hints'
import MobileActionBar from './mobile_action_bar'
import SubmitReviewModal from './submit_review_modal'

interface MappedProps {
  currentOpenTask: Task
  kaseId: number
  issues: Issue[]
  stewardEmail: string
  currentIssue?: Issue
  isLoading?: boolean
  /**
   * If true, will reveal modal to submit to Boundless
   */
  allIssuesAddressed: boolean
}

interface ActionProps {
  assignTask: typeof assignTask
  loadIssues: typeof loadIssues
  addMessage: typeof addMessage
  setCurrentIssue: typeof setCurrentIssue
  loadTasks: typeof loadTasks
}

export const Issues: FunctionComponent<MappedProps & ActionProps> = ({
  allIssuesAddressed,
  currentOpenTask,
  kaseId,
  issues,
  stewardEmail,
  currentIssue,
  assignTask,
  loadIssues,
  loadTasks,
  addMessage,
  setCurrentIssue,
  isLoading
}) => {
  const [isThreadOpen, setIsThreadOpen] = useState(false)
  const [showSubmitReviewModal, setShowSubmitReviewModal] = useState(false)

  const saveNewMessage = (reply: string) => {
    if (!currentIssue) {
      return
    }

    addMessage({ kaseId, issueId: currentIssue.id, text: reply })
  }

  const onOpenThread = (issue) => {
    setCurrentIssue(issue.id)
    setIsThreadOpen(!isThreadOpen)
  }

  const closePanel = () => {
    // Don't clear the current issue! The ThreadPanel needs to display it until
    // it's hidden

    setIsThreadOpen(!isThreadOpen)

    if (allIssuesAddressed) {
      setShowSubmitReviewModal(true)
    }
  }

  useEffect(() => {
    if (kaseId) {
      loadIssues({ kaseId })
      loadTasks({ kaseId })
    }
  }, [kaseId])

  if (isLoading) {
    return <LoadingSkeleton />
  }

  const closeSubmitReviewModal = (submitForReview) => () => {
    if (submitForReview) {
      if (!currentOpenTask) {
        setShowSubmitReviewModal(false)
        return
      }

      assignTask({
        kaseId,
        taskId: currentOpenTask.id,
        assigneeId: null
      }).then(() => setShowSubmitReviewModal(false))
    } else {
      setShowSubmitReviewModal(false)
    }
  }

  const taskIsAssignedToBoundless = !currentOpenTask?.attributes.assignee_id

  return (
    <div className="o-action-items__wrapper">
      <SubmitReviewModal showModal={showSubmitReviewModal} closeModal={closeSubmitReviewModal} />
      <BreakpointWrapper mobile>
        <div className="o-box o-box--light p-4" style={{ minHeight: '90vh' }}>
          <MobileIssueHints />
          <MobileIssuesContainer
            issues={issues}
            taskIsAssignedToBoundless={taskIsAssignedToBoundless}
            onOpenThread={onOpenThread}
          />
        </div>
        <MobileActionBar />
      </BreakpointWrapper>

      <BreakpointWrapper desktop>
        <div className="o-griddle__row o-griddle__row--no-gutters">
          <aside className="o-griddle__col--3">
            <Outline />
          </aside>
          <main className="o-griddle__col--6">
            <div className="o-action-items__center-column">
              {taskIsAssignedToBoundless ? (
                <SubmitSuccess />
              ) : (
                <IssuesContainer issues={issues} onOpenThread={onOpenThread} />
              )}
            </div>
          </main>
          <aside className="o-griddle__col o-griddle__col--3 px-4 pt-16 lg:px-6">
            <div className="ml-3">
              <HelpSection stewardEmail={stewardEmail} />
            </div>
          </aside>
        </div>
      </BreakpointWrapper>
      <ThreadPanel isOpen={isThreadOpen} closePanel={closePanel} submitReply={saveNewMessage} />
    </div>
  )
}

function mapStateToProps(state: any): MappedProps {
  const isLoading = isLoadingIssues(state) || isLoadingTasks(state)

  return {
    allIssuesAddressed: allIssuesAddressed(state),
    kaseId: getCurrentKaseId(state),
    issues: getOpenIssues(state),
    stewardEmail: getStewardInfo(state).email || '',
    currentIssue: getCurrentIssue(state),
    currentOpenTask: getCurrentOpenQaTask(state),
    isLoading: isLoading
  }
}

function mapDispatchToActions(dispatch: Dispatch) {
  return bindActionCreators(
    {
      assignTask,
      loadIssues,
      loadTasks,
      addMessage,
      setCurrentIssue
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToActions)(Issues) as FunctionComponent
