import mbgcOutcomeFunctions from './marriage_based_green_card'
import naturalizationOutcomeFunctions from './naturalization'
import newmbgcOutcomeFunctions from './new_marriage_based_green_card'
import Decorator from 'lib/decorator'

interface OutcomePublicArgs {
  kaseKind: string
  modelData: Decorator
  sectionName: string
}

interface OutcomeArgs {
  modelState: ModelState
  sectionName: string
}

type OutcomeFunction = (args: OutcomeArgs) => string

export interface OutcomeCollection {
  [sectionName: string]: OutcomeFunction
}

const outcomeFunctions = {
  MarriageBasedGreenCard: mbgcOutcomeFunctions,
  Naturalization: naturalizationOutcomeFunctions,
  NewMarriageBasedGreenCard: newmbgcOutcomeFunctions
}

const getSectionOutcome = ({ modelData, kaseKind, sectionName }: OutcomePublicArgs): boolean => {
  const fns = outcomeFunctions[kaseKind]

  return modelData[fns[sectionName]]()
}

export default getSectionOutcome
