import React from 'react'

import { QuestionnaireInputContainerProps } from '../'

import CountryInput from './country_input'
import ValidatedInput from 'components/forms/validated_input'

type Props = QuestionnaireInputContainerProps & {
  hidden_country_codes: string[]
}

class Country extends React.Component<Props> {
  render() {
    const { disabled } = this.props

    return (
      <div className="o-block">
        <div className="o-grid--fluid o-grid--stack@sm o-block c-paper-form__group">
          <label
            className="o-grid__cell--3/12 o-grid__cell--off@sm c-paper-form__label c-paper-form__segment"
            htmlFor={`${this.props.id}-country`}
          >
            <span className="c-type c-type--subhead-sm">Country</span>
          </label>

          <div className="o-grid__cell--9/12 o-grid__cell--off@sm o-flag c-paper-form__segment">
            <ValidatedInput disabled={disabled} path={this.props.path}>
              {({ fireValidation, getValidationClasses, renderValidations }) => (
                <React.Fragment>
                  <CountryInput
                    className={`c-paper-form__control ${getValidationClasses()}`}
                    disabled={disabled}
                    hiddenCountryCodes={this.props.hidden_country_codes || []}
                    id={this.props.id}
                    onBlur={fireValidation}
                    path={this.props.path}
                  />

                  {renderValidations()}
                </React.Fragment>
              )}
            </ValidatedInput>
          </div>
        </div>
      </div>
    )
  }
}

export default Country
