import React, { FunctionComponent, useState, useEffect } from 'react'
import { connect } from 'react-redux'

import {
  completedReviewCount,
  getCurrentDocRequestIndex,
  getNumberOfDocRequestsNeedReview,
  getDocRequestsLeftBeforeThreshold,
  getVisibleCustomerDocumentRequests
} from 'reducers/selectors'
import { goToDocRequestByIndex } from 'actions/document_request_actions'

import { getCookie, setCookie } from 'lib/cookies'

import IconModal from 'components/modals/icon_modal'
import Paragraph from 'components/type/paragraph'
import Button from 'components/button'

interface MappedProps {
  completedReviewCount?: number
  currentDocRequestIndex: number
  numberOfRequestsInReview?: number
  numberOfRequestsBeforeThreshold: number
  numberOfVisibleDocRequests: number
}

interface ActionProps {
  goToDocRequestByIndex: (docRequestIndex: number) => void
}

const FIRST_COMPLETED_REQ_COOKIE = 'seenFirstDocUploadCompleteModal'

export const FirstRequestCompleteModal: FunctionComponent<MappedProps & ActionProps> = ({
  completedReviewCount,
  currentDocRequestIndex,
  goToDocRequestByIndex,
  numberOfRequestsInReview,
  numberOfRequestsBeforeThreshold,
  numberOfVisibleDocRequests
}) => {
  const completedReqCookie = getCookie(FIRST_COMPLETED_REQ_COOKIE)
  const shouldShowModal =
    numberOfRequestsInReview === 1 &&
    numberOfRequestsBeforeThreshold > 0 &&
    !completedReqCookie &&
    !completedReviewCount

  const [showModal, setShowModal] = useState(shouldShowModal)
  useEffect(() => {
    setShowModal(shouldShowModal)
  }, [numberOfRequestsInReview === 1])

  const closeModal = (goToNextSection) => () => {
    if (goToNextSection) {
      const nextDocRequest = numberOfVisibleDocRequests > currentDocRequestIndex + 1 ? currentDocRequestIndex + 1 : 0
      goToDocRequestByIndex(nextDocRequest)
    }
    setCookie({ name: FIRST_COMPLETED_REQ_COOKIE, value: 'viewed' })
    setShowModal(false)
  }

  return (
    <IconModal
      showModal={showModal}
      closeModal={closeModal(false)}
      headerContent={`You're doing great! Upload ${numberOfRequestsBeforeThreshold} more document sets so we can start checking them.`}
      variant="success"
    >
      <Paragraph spacing="lg">
        We need at least half of your document sets before we start checking them to get a well-rounded picture of your
        application.
      </Paragraph>
      <Button onClick={closeModal(true)} label="Go To Next Section" color="primary" block />
    </IconModal>
  )
}

const mapStateToProps = (state: any): MappedProps => {
  return {
    completedReviewCount: completedReviewCount(state),
    currentDocRequestIndex: getCurrentDocRequestIndex(state),
    numberOfRequestsInReview: getNumberOfDocRequestsNeedReview(state),
    numberOfRequestsBeforeThreshold: getDocRequestsLeftBeforeThreshold(state),
    numberOfVisibleDocRequests: getVisibleCustomerDocumentRequests(state).length
  }
}

function mapDispatchToActions(dispatch): ActionProps {
  return {
    goToDocRequestByIndex: (docRequestIndex: number) => {
      return dispatch(goToDocRequestByIndex(docRequestIndex))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToActions)(FirstRequestCompleteModal)
