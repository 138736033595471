import React, { ChangeEvent } from 'react'
import { connect } from 'react-redux'

import { saveData } from 'actions/kase_actions'
import operations, { ModelOperation } from 'lib/update_transformations'
import { getModelValue } from 'reducers/selectors'

import { ModelDataSerializableValue } from 'reducers/model'

type RenderFunction = (value: string, onChange: EventHandler) => React.ReactNode

type InputControlValue = ModelDataSerializableValue
type Formatter = (value: ModelDataSerializableValue) => InputControlValue

type EventOrValue = ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement> | InputControlValue

interface ChangeEventHandler {
  (eventOrValue: EventOrValue): void
}

interface MappedProps {
  getModelValueAtPath: (path: string) => any
  persistedValue?: any
}

interface ActionProps {
  saveData: Function
  afterSaveOnBlur?: Function
}

type ChangePathValueFunction = (
  path: string,
  options: {
    operation?: ModelOperation
    value?: any
  }
) => void

export type AfterChangeFunction<T> = (args: {
  eventTargetPath: string
  value: InputControlValue
  getModelValueAtPath: (path: string) => any
  serializedValue: T
  serializedValueChanged: boolean
  previousValue: any
  changeValueAtPath: ChangePathValueFunction
}) => void

type Serializer = Function

type Props = MappedProps &
  ActionProps & {
    afterChangeEvents: [AfterChangeFunction<ModelDataSerializableValue>]
    children: RenderFunction
    className?: string
    formatter: Formatter
    formatAsYouType: boolean
    serializer: Serializer
    saveOnBlur?: boolean
    path: string
  }

interface State {
  inputValue: InputControlValue
}

const defaultFormatter: Formatter = (value) => {
  if (value === 0) return '0'

  if (value == null) return ''

  return value
}

function getValueFromEventOrValue(eventOrValue: EventOrValue): InputControlValue {
  let value: InputControlValue

  if (eventOrValue && eventOrValue.currentTarget) {
    const event = eventOrValue

    if (event.currentTarget.type === 'checkbox') {
      value = event.currentTarget.checked
    } else {
      value = event.currentTarget.value || null
    }
  } else {
    value = eventOrValue
  }

  if (value == null && value !== false) {
    value = ''
  }

  return value
}

class BufferedFieldValue extends React.Component<Props, State> {
  static defaultProps = {
    afterChangeEvents: [],
    formatter: defaultFormatter,
    formatAsYouType: false,
    serializer: (value) => value,
    afterSaveOnBlur: () => {}
  }

  state: State = {
    inputValue: ''
  }

  focused: boolean = false

  constructor(props) {
    super(props)

    this.state = { inputValue: props.formatter(props.persistedValue) }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const value = nextProps.persistedValue
    const valueCanBeOverwritten = !this.focused

    if (valueCanBeOverwritten) {
      this.updateInputValue(value)
    }
  }

  onBlur = () => {
    const { persistedValue, saveOnBlur, afterSaveOnBlur } = this.props

    if (!saveOnBlur) {
      if (persistedValue) this.updateInputValue(persistedValue)
    } else {
      this.saveValue(this.state.inputValue)
      // this is the method that will fire validation
      // for the newly saved value
      afterSaveOnBlur()
    }

    this.focused = false
  }

  onFocus = () => (this.focused = true)

  updateInputValue(value: ModelDataSerializableValue, format = true) {
    if (format) value = this.props.formatter(value)

    if (this.state.inputValue !== value) {
      this.setState({ inputValue: value })
    }
  }

  onChange: ChangeEventHandler = (eventOrValue) => {
    const { saveOnBlur, formatAsYouType } = this.props

    const value: InputControlValue = getValueFromEventOrValue(eventOrValue)

    this.updateInputValue(value, formatAsYouType)

    if (!saveOnBlur) {
      this.saveValue(value)
    }
  }

  saveValue(newValue) {
    const { persistedValue, serializer, afterChangeEvents } = this.props

    const serializedValue = serializer(newValue, {
      previousValue: persistedValue
    })

    const serializedValueChanged = serializedValue !== persistedValue

    if (serializedValueChanged) this.changeDataModelValue(serializedValue)

    afterChangeEvents.forEach((fn) =>
      fn({
        value: newValue,
        serializedValueChanged,
        serializedValue,
        previousValue: persistedValue,
        eventTargetPath: this.props.path,
        changeValueAtPath: this.changeValueAtPath,
        getModelValueAtPath: this.props.getModelValueAtPath
      })
    )
  }

  changeValueAtPath: ChangePathValueFunction = (path, { operation, value }) => {
    operation = operation || operations.replaceValue(value || value === 0 ? value : null)

    this.props.saveData({ path, operation })
  }

  changeDataModelValue(value) {
    this.changeValueAtPath(this.props.path, {
      operation: operations.replaceValue(value)
    })
  }

  valueToString(): string {
    const value = this.state.inputValue

    if (value == null) return ''

    return value
  }

  render() {
    const { className } = this.props

    /**
     * The className needs to be empty to satisfy this selector:
     * .o-grid--fluid > [className=""] { ... }
     * https://boundlesshq.slack.com/archives/CM7U0BZB7/p1588111597013700?thread_ts=1588110208.007400&cid=CM7U0BZB7
     */

    return (
      <div
        onBlur={this.onBlur}
        onFocus={this.onFocus}
        // See comment above...
        className={className || ''}
        data-qa="buffered-field-value"
      >
        {this.props.children(this.valueToString(), this.onChange)}
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps): MappedProps => ({
  // TODO[perf] anonymous functions trigger re-renders
  getModelValueAtPath: (path) => getModelValue(state, path),
  persistedValue: getModelValue(state, ownProps.path)
})

const mapDispatchToActions = (dispatch: Function): ActionProps => {
  return {
    saveData: (...args) => dispatch(saveData(...args))
  }
}

export default connect(mapStateToProps, mapDispatchToActions)(BufferedFieldValue)
