import { createSelector } from 'reselect'

import { isPresent } from 'lib/presence'

import * as fromApplication from './application/selectors'
import * as fromAssets from './assets/selectors'
import * as fromDashboard from './dashboard/selectors'
import * as fromDocuments from './documents/selectors'
import * as fromDocumentsV2 from './documents_v2/selectors'
import * as fromIssues from './issues/selectors'
import * as fromBrowser from './browser/selectors'
import * as fromHelp from './help/selectors'
import * as fromMasterPdf from './master_pdf/selectors'
import * as fromModals from './modals/selectors'
import * as fromModel from './model/selectors'
import * as fromPanel from './panel/selectors'
import * as fromPdf from './pdf/selectors'
import * as fromRoles from './roles/selectors'
import * as fromRouter from './router/selectors'
import * as fromTasks from './tasks/selectors'
import * as fromUploads from './uploads/selectors'
import * as fromCrossDomain from './cross_domain_selectors'
import * as fromAddressHistories from './address_histories/selectors'
import * as fromEmploymentHistories from './employment_histories/selectors'
import * as fromSectionProgress from './section_progress/selectors'
import * as fromLawyerInteractions from './lawyer_interactions/selectors'

import { getComputedResourceContext } from 'resource_context'
import { mostRecentMessageIsFromUser } from 'components/screens/issues/utils'
import { KaseKind } from 'lib/constants'

// Application + kase

export const getCurrentKase = (state) => fromApplication.getCurrentKase(state.application)

export const getCurrentKaseId = (state) => fromApplication.getCurrentKaseId(state.application)

export const getCurrentKaseKind = (state) => fromApplication.getCurrentKaseKind(state.application)

export const getCurrentKaseKindString = (state) => fromApplication.getCurrentKaseKindString(state.application)

export const getCurrentKaseKindResourceKey = (state) => fromApplication.getCurrentKaseKindResourceKey(state.application)

export const getCurrentKaseState = (state) => fromApplication.getCurrentKaseState(state.application)

export const getCurrentKaseProcess = (state) => fromApplication.getCurrentKaseProcess(state.application)

export const getCurrentKaseIsAOS = (state) => fromApplication.getCurrentKaseIsAOS(state.application)

export const getShouldOpenAttorneyAgreementModal = (state) =>
  fromApplication.getShouldOpenAttorneyAgreementModal(state.application)

export const getCurrentKaseCompletedEligibility = (state) =>
  fromApplication.getCurrentKaseCompletedEligibility(state.application)

export const isAttorneyAgreementAccepted = (state) => fromApplication.isAttorneyAgreementAccepted(state.application)

export const getCurrentUserHasAcceptedAttorneyAgreement = (state) =>
  fromApplication.getCurrentUserHasAcceptedAttorneyAgreement(state.application)

export const getVwoCampaignVariant = (state, campaign) =>
  fromApplication.getVwoCampaignVariant(state.application, campaign)

export const isCurrentKasePaid = (state) => fromApplication.isCurrentKasePaid(state.application)

export const getIsConvertedKase = (state) => fromApplication.getIsConvertedKase(state.application)

export const getCoupleFirstNames = (state) => fromApplication.getCoupleFirstNames(state.application)

export const isApplicationFeeChanged = (state) => fromApplication.isApplicationFeeChanged(state.application)

export const getApplicationFeeInCents = (state) => fromApplication.getApplicationFeeInCents(state.application)

export const getLawyerReviewFeeInCents = (state) => fromApplication.getLawyerReviewFeeInCents(state.application)

export const getBoundlessFeeInCents = (state) => fromApplication.getBoundlessFeeInCents(state.application)

export const getCurrentApplicationFeeInCents = (state) =>
  fromApplication.getCurrentApplicationFeeInCents(state.application)

export const getCurrentApplicationFeeInDollars = (state) =>
  fromApplication.getCurrentApplicationFeeInDollars(state.application)

export const getCurrentKaseLawyer = (state) => fromApplication.getCurrentKaseLawyer(state.application)

export const getWorkflowStatus = (state) => fromApplication.getWorkflowStatus(state.application)

export const workflowStatusIsPaid = (state) => fromApplication.workflowStatusIsPaid(state.application)

export const workflowStatusIsDocGathering = (state) => fromApplication.workflowStatusIsDocGathering(state.application)

export const workflowStatusAllowsCustomerReview = (state) =>
  fromApplication.workflowStatusAllowsCustomerReview(state.application)

export const isKaseBeingRefunded = (state) => fromApplication.isKaseBeingRefunded(state.application)

export const getPaymentPageUrl = (state) => fromApplication.getPaymentPageUrl(state.application)

export const getTimelinePageUrl = (state) => fromApplication.getTimelinePageUrl(state.application)

export const getTrelloCardLabels = (state) => fromApplication.getTrelloCardLabels(state.application)

// Dashboard
export const getCurrentDashboardPhaseForKase = (state) =>
  fromApplication.getCurrentDashboardPhaseForKase(state.application) //TODO: Move to dashboard selectors?

export const getLawyerInfo = (state) => fromDashboard.getLawyerInfo(state.application)

export const getStewardInfo = (state) => fromDashboard.getStewardInfo(state.application)

export const getDashboardContentForWorkflowStatus = (state) =>
  fromDashboard.getDashboardContentForWorkflowStatus(state.application)

export const getNavigableSectionsForCustomerDashboard = (state) =>
  fromModel.getNavigableSectionsForCustomerDashboard(state.model)

export const getPostILASectionsForCustomerDashboard = (state) =>
  fromModel.getPostILASectionsForCustomerDashboard(state.model)

export const isSelfSufficiencyIncomplete = (state) => fromModel.isSelfSufficiencyIncomplete(state.model)

export const allSectionsComplete = (state) => fromModel.allSectionsComplete(state.model)

export const firstIncompleteSection = (state) => fromModel.firstIncompleteSection(state.model)

// Assets
export const getAssetVersion = (state) => fromAssets.getAssetVersion(state.assets)

// Browser

export const isMobileBrowser = (state) => fromBrowser.isMobileBrowser(state.browser)

export const isDesktopBrowser = (state) => fromBrowser.isDesktopBrowser(state.browser)

export const isScreenSmallWidth = (state) => fromBrowser.isScreenSmallWidth(state.browser)

export const isScreenMediumWidth = (state) => fromBrowser.isScreenMediumWidth(state.browser)

export const isScreenLargeWidth = (state) => fromBrowser.isScreenLargeWidth(state.browser)

export const isScreenMediumOrLargeWidth = (state) => fromBrowser.isScreenMediumOrLargeWidth(state.browser)

export const isScreenSmallOrMediumWidth = (state) => fromBrowser.isScreenSmallOrMediumWidth(state.browser)

// Documents

export const getDocumentRequests = (state) => fromDocuments.getDocumentRequests(state.documents)

export const getVisibleCustomerDocumentRequests = (state) =>
  fromDocuments.getVisibleCustomerDocumentRequests(state.documents)

export const getActionableDocumentRequests = (state) => fromDocuments.getActionableDocumentRequests(state.documents)

export const getInitialDocReviewThresholdCount = (state) =>
  fromDocuments.getInitialDocReviewThresholdCount(state.documents)

export const getNumberOfDocRequestsNeedReview = (state) =>
  fromDocuments.getNumberOfDocRequestsNeedReview(state.documents)

export const getInitialThresholdReached = (state) => fromDocuments.getInitialThresholdReached(state.documents)

export const slaIsSet = (state) => fromDocuments.slaIsSet(state.documents)

export const unresolvedCommentCount = (state) => fromDocuments.unresolvedCommentCount(state.documents)

export const hasUnresolvedComments = (state) => fromDocuments.hasUnresolvedComments(state.documents)

export const completedReviewCount = (state) => fromDocuments.completedReviewCount(state.documents)

export const getModelMetadata = (state) => fromModel.getMetadata(state.model)

export const getSortedDocumentRequests = createSelector(
  getVisibleCustomerDocumentRequests,
  getModelMetadata,
  fromCrossDomain.getSortedDocumentRequests
)

export const getCurrentDocumentUploadSummaryState = (state) =>
  fromDocuments.getCurrentDocumentUploadSummaryState(state.documents)

export const bindIsDocumentRequestHidden = (state) => (documentRequest) =>
  fromDocuments.isDocumentRequestHidden(state.documents, documentRequest)

export const currentlyHasDocumentRequests = (state) => fromDocuments.currentlyHasDocumentRequests(state.documents)

export const getDocRequestsLeftBeforeThreshold = (state) =>
  fromDocuments.getDocRequestsLeftBeforeThreshold(state.documents)

export const getCurrentDocRequestIndex = (state) => fromDocuments.getCurrentDocRequestIndex(state.documents)

export const getDocumentReviewKase = (state) => fromDocuments.getDocumentReviewKase(state.documents)

// DocumentsV2
export const getDocumentSets = (state) => fromDocumentsV2.getDocumentSets(state.documentsV2)

export const getDocumentSetsComplete = (state) => fromDocumentsV2.getDocumentSetsComplete(state.documentsV2)

export const getCurrentDocRequestIndexV2 = (state) => fromDocumentsV2.getCurrentDocRequestIndex(state.documentsV2)

export const getCurrentDocSetIndex = (state) => fromDocumentsV2.getCurrentDocSetIndex(state.documentsV2)

export const getCurrentDocRequest = (state) => fromDocumentsV2.getCurrentDocRequest(state.documentsV2)

export const getNextDocRequestId = (state) => fromDocumentsV2.getNextDocRequestId(state.documentsV2)

export const getPrevDocRequestId = (state) => fromDocumentsV2.getPrevDocRequestId(state.documentsV2)

// Help
export const isSidebarHelpOpen = (state) => fromHelp.isSidebarHelpOpen(state.help)

// Master PDF
export const isCustomerReviewFeedbackSubmitted = (state) =>
  fromMasterPdf.isCustomerReviewFeedbackSubmitted(state.masterPdf)

export const getCustomerReviewFeedbackFileName = (state) =>
  fromMasterPdf.getCustomerReviewFeedbackFileName(state.masterPdf)

export const hasCustomerReviewPdf = (state) => fromMasterPdf.hasCustomerReviewPdf(state.masterPdf)

export const isCustomerFeedbackUploaded = (state) => fromMasterPdf.isCustomerFeedbackUploaded(state.masterPdf)

export const getMasterPDFPagesList = (state) => fromMasterPdf.getMasterPDFPagesList(state.masterPdf)

export const getMasterPDFUnusedPagesList = (state) => fromMasterPdf.getMasterPDFUnusedPagesList(state.masterPdf)

export const getMasterPdfId = (state) => fromMasterPdf.getMasterPdfId(state.masterPdf)

// Modals
export const getActiveModal = (state) => fromModals.getActiveModal(state.modals)

export const getActiveModalProps = (state) => fromModals.getActiveModalProps(state.modals)

export const isModalOpen = (state) => fromModals.isModalOpen(state.modals)

// Model
export const getModelData = (state) => fromModel.getModelData(state.model)

export const getKaseTags = (state) => fromModel.getKaseTags(state.model)

export const readableKaseTag = (state) => fromModel.getReadableKaseTag(state.model)

export const isSetupIneligible = (state) => fromModel.isSetupIneligible(state.model)

export const sponsorHasPetitionHistory = (state) => fromModel.sponsorHasPetitionHistory(state.model)

export const sponsorIsCitizen = (state) => fromModel.sponsorIsCitizen(state.model)

export const isBeneficiaryNotInUS = (state) => fromModel.beneficiaryNotInUS(state.model)

export const getPublicChargeEstimatorResults = (state) => fromModel.getPublicChargeEstimatorResults(state.model)

export const getPreviousNames = (state, role) => fromModel.getPreviousNames(state.model, role)

export const areSponsorAndBeneficiaryLivingTogether = (state) =>
  fromModel.areSponsorAndBeneficiaryLivingTogether(state.model)

export const beneficiaryHasInternationalAddressHistory = (state) =>
  fromModel.beneficiaryHasInternationalAddressHistory(state.model)

export const beneficiaryHasInternationalEmploymentHistory = (state) =>
  fromModel.beneficiaryHasInternationalEmploymentHistory(state.model)

export const getAccountHolderRole = (state) => fromModel.getAccountHolderRole(state.model)

export const isSponsor = (state) => {
  return getAccountHolderRole(state) === 'sponsor'
}

export const isBeneficiary = (state) => {
  return getAccountHolderRole(state) === 'beneficiary'
}

export const getUnitConversionPreference = (state) => fromModel.getUnitConversionPreference(state.model)

export const getModelValue = (state, path) => fromModel.getModelValue(state.model, path)

export const isModelValueEmpty = (state, path) => fromModel.isModelValueEmpty(state.model, path)

export const isQualifierYesAtPath = (state, path) => fromModel.isQualifierYesAtPath(state.model, path)

export const getCurrentSectionName = (state) => fromModel.getCurrentSectionName(state.model)

export const getSectionByName = (state, sectionName) => fromModel.getSectionByName(state.model, sectionName)

export const isOnSetupSection = (state) => fromModel.isOnSetupSection(state.model)

export const isOnPetitionSection = (state) => fromModel.isOnPetitionSection(state.model)

export const isOnPublicChargeEstimatorSection = (state) => fromModel.isOnPublicChargeEstimatorSection(state.model)

export const getCurrentContentSource = (state) => fromModel.getCurrentContentSource(state.model)

export const getCurrentPanelSlug = (state) => fromModel.getCurrentPanelSlug(state.model)

export const getIndexForCurrentPanel = (state) => fromModel.getIndexForCurrentPanel(state.model)

export const getCurrentPanelSlugIndex = (state) => fromModel.getCurrentPanelSlugIndex(state.model)

export const getCurrentSectionOutcome = (state) => fromModel.getCurrentSectionOutcome(state.model)

export const isCurrentlyInSection = (state) => fromModel.isCurrentlyInSection(state.model)

export const getCurrentSection = (state) => fromModel.getCurrentSection(state.model)

export const getCurrentSectionForOutline = (state) => fromModel.getCurrentSectionForOutline(state.model)

export const getCurrentSectionProgress = (state) => fromModel.getCurrentSectionProgress(state.model)

export const getRecordedPetitionProgress = (state) => fromApplication.getRecordedPetitionProgress(state.application)

export const getSetupSectionProgress = (state) => fromModel.getSetupSectionProgress(state.model)

export const getCurrentSectionErrors = (state) => fromModel.getCurrentSectionErrors(state.model)

export const getNavigableSectionsForStopGapDashboard = (state) =>
  fromModel.getNavigableSectionsForStopGapDashboard(state.model)

export const isJointSponsorRequired = (state) => fromModel.isJointSponsorRequired(state.model)

export const isInterpreterInfoRequired = (state) => fromModel.isInterpreterInfoRequired(state.model)

export const isWorkflowCategoryAOS = (state) => fromModel.isWorkflowCategoryAOS(state.model)

export const isWorkflowCategoryCP2 = (state) => fromModel.isWorkflowCategoryCP2(state.model)

// model + mbgc data
export const getHouseholdSize = (state) => fromModel.getHouseholdSize(state.model)

export const getSponsorProvince = (state) => fromModel.getSponsorProvince(state.model)

export const getMBGCSpouse = (state) => fromModel.getMBGCSpouse(state.model)

export const getWorkflowCategory = (state) => fromModel.getWorkflowCategory(state.model)

// Model + naturalization data
export const getNaturalizationHouseholdSize = (state) => fromModel.getNaturalizationHouseholdSize(state.model)

export const getApplicantProvince = (state) => fromModel.getApplicantProvince(state.model)

export const isFilingOnMarriageBasis = (state) => fromModel.isFilingOnMarriageBasis(state.model)

// Model + panel concerns
export const getCurrentFlattenedPanels = (state) => fromModel.getCurrentFlattenedPanels(state.model)

export const getCurrentFlattenedPanelsCount = (state) => fromModel.getCurrentFlattenedPanelsCount(state.model)

export const getCurrentPanelName = (state) => fromModel.getCurrentPanelName(state.model)

export const getCurrentPanelIndex = (state) => fromModel.getCurrentPanelIndex(state.model)

export const getCurrentPanel = (state) => fromModel.getCurrentPanel(state.model)

export const getCurrentSectionChapters = (state) => fromModel.getCurrentSectionChapters(state.model)

export const getCurrentChapter = (state) => fromModel.getCurrentChapter(state.model)

export const getAllFieldsInCurrentPanel = (state) => fromModel.getAllFieldsInCurrentPanel(state.model)

export const getPanelBySlug = (state, slug) => fromModel.getPanelBySlug(state.model, slug)

export const hasEnteredContactInfo = (state) => fromModel.hasEnteredContactInfo(state.model)

export const isLeadCapturePanel = (state, panel) => fromModel.isLeadCapturePanel(state.model, panel)

export const isJITFeedbackPanel = (panel) => fromModel.isJITFeedbackPanel(panel)

export const getPreviousPanel = (state) => fromModel.getPreviousPanel(state.model)
export const getNextPanel = (state) => fromModel.getNextPanel(state.model)

export const isCurrentlyOnOutcome = (state) => fromModel.isCurrentlyOnOutcome(state.model)

export const hasCurrentPanelBeenModified = (state) => fromModel.hasCurrentPanelBeenModified(state.model)

export const getFieldValuesForCurrentPanel = (state) => fromModel.getFieldValuesForCurrentPanel(state.model)

export const isCurrentPanelComplete = (state) => fromModel.isCurrentPanelComplete(state.model)

export const isCurrentPanelCustomComponent = (state) => fromModel.isCurrentPanelCustomComponent(state.model)

export const isCurrentPanelSaveProgressInterstitial = (state) =>
  fromModel.isCurrentPanelSaveProgressInterstitial(state.model)

export const getFirstPanelOfSection = (state, sectionName) => fromModel.getFirstPanelOfSection(state.model, sectionName)

export const getFirstPanelOfCurrentSection = (state) => fromModel.getFirstPanelOfCurrentSection(state.model)

// Model + UI concerns
export const areSaveRequestsInFlight = (state) => fromModel.areSaveRequestsInFlight(state.model)

export const isCurrentlySavingData = (state) => fromModel.isCurrentlySavingData(state.model)

export const isDataSaved = (state) => fromModel.isDataSaved(state.model)

export const isPanelCurrentlyUpdating = (state) => fromModel.isPanelCurrentlyUpdating(state.model)

export const isPostSaveTransitioning = (state) => fromModel.isPostSaveTransitioning(state.model)

export const getLastUpdatedAt = (state) => fromModel.getLastUpdatedAt(state.model)

export const getPendingUpdates = (state) => fromModel.getPendingUpdates(state.model)

export const isCurrentlyAtSectionStart = (state) => fromModel.isCurrentlyAtSectionStart(state.model)

export const isCurrentlyAtEnd = (state) => fromModel.isCurrentlyAtEnd(state.model)

// Model + Validations
export const getValidationConfig = (state) => fromModel.getValidationConfig(state.model)

export const getValidationResultAtPath = (state, path) => fromModel.getValidationResultAtPath(state.model, path)

export const getActiveValidationPaths = (state) => fromModel.getActiveValidationPaths(state.model)

export const canAdvanceToNextPanel = (state) => fromModel.canAdvanceToNextPanel(state.model)

// Model + Review concerns
export const getSponsorFirstName = (state) => fromModel.getSponsorFirstName(state.model)

export const getSponsorAddressHistory = (state) => fromModel.getSponsorAddressHistory(state.model)

export const getBeneficiaryFirstName = (state) => fromModel.getBeneficiaryFirstName(state.model)

export const getApplicantFirstName = (state) => fromModel.getApplicantFirstName(state.model)

export const getJointSponsorFirstName = (state) => fromModel.getJointSponsorFirstName(state.model)

export const getHouseholdMembersFirstNames = (state) => fromModel.getHouseholdMembersFirstNames(state.model)

export const getChildrenFirstNames = (state) => fromModel.getChildrenFirstNames(state.model)

export const getBeneficiaryAddressHistory = (state) => fromModel.getBeneficiaryAddressHistory(state.model)

export const getNatzApplicantFirstName = (state) => fromModel.getNatzApplicantFirstName(state.model)

// Model + metadata/needed
export const isPathNeeded = (state, path) => fromModel.isPathNeeded(state.model, path)

// Panel
export const isInReviewMode = (state) => fromPanel.isInReviewMode(state.panel)

// PDF
export const getCurrentPdfFormName = (state) => fromPdf.getCurrentPdfFormName(state.pdf)

export const getPdfFormDataForField = (state, fieldName) => fromPdf.getPdfFormDataForField(state.pdf, fieldName)

export const getPdfSelectedFieldName = (state) => fromPdf.getPdfSelectedFieldName(state.pdf)

export const getPdfFormCurrentFieldEditHistory = (state, fieldName) =>
  fromPdf.getPdfFormCurrentFieldEditHistory(state.pdf, fieldName)

export const getPdfFormCurrentFieldEditValue = (state, fieldName) =>
  fromPdf.getPdfFormCurrentFieldEditValue(state.pdf, fieldName)

export const getPdfFormFieldsForPage = (state, pageIndex) => fromPdf.getPdfFormFieldsForPage(state.pdf, pageIndex)

export const getPdfCheckboxFormFields = (state, fieldName) => fromPdf.getPdfCheckboxFormFields(state.pdf, fieldName)

export const getPdfFormField = (state, fieldName) => fromPdf.getPdfFormField(state.pdf, fieldName)

export const getPdfFormEditFieldValue = (state) => fromPdf.getPdfFormEditFieldValue(state.pdf)

export const getPdfSelectedFormField = (state) => fromPdf.getPdfSelectedFormField(state.pdf)

// Router

export const isRouterLocationChanging = (state) => fromRouter.isRouterLocationChanging(state.router)

export const getCurrentDocumentRequestId = (state) => fromRouter.getCurrentDocumentRequestId(state.router)

// Session
export const getCurrentUser = (state) => fromApplication.getCurrentUser(state.application)

export const getCurrentUserInitialTrackingMetadata = (state) =>
  fromApplication.getCurrentUserInitialTrackingMetadata(state.application)

export const getCurrentUserEmail = (state) => fromApplication.getCurrentUserEmail(state.application)

export const getCurrentUserPhoneNumber = (state) => fromApplication.getCurrentUserPhoneNumber(state.application)

export const getCurrentUserId = (state) => fromApplication.getCurrentUserId(state.application)

export const getCurrentUserTrackingMetadata = (state) =>
  fromApplication.getCurrentUserTrackingMetadata(state.application)

export const isCurrentUserAdmin = (state) => fromRoles.isCurrentUserAdmin(state.application)

export const isCurrentUserTemporary = (state) => fromRoles.isCurrentUserTemporary(state.application)

export const hasLimitedQaAccess = (state) => fromRoles.hasLimitedQaAccess(state.application)

export const isUserLoggedIn = (state) => fromModel.isUserLoggedIn(state.model)

// Uploads
export const getUploadsForDocumentRequest = (state, documentRequest) =>
  fromUploads.getUploadsForDocumentRequest(state.uploads, documentRequest)

export const getUploadsForDocumentRequestPart = (state, documentRequestPartId) =>
  fromUploads.getUploadsForDocumentRequestPart(state.uploads, documentRequestPartId)

export const getIsFileUploading = (state) => fromUploads.getIsFileUploading(state.uploads)

// Issues

/**
 * Returns whether kase has issues at all, not just open issues
 */
export const hasIssues = (state) => fromIssues.hasIssues(state.kaseIssues)
export const getAllIssues = (state) => fromIssues.getAllIssues(state.kaseIssues)
export const getIssuesSorted = (state) => fromIssues.getIssuesSorted(state.kaseIssues)
export const getOpenIssues = (state) => fromIssues.getOpenIssues(state.kaseIssues)
export const getMessages = (state) => fromIssues.getMessages(state.kaseIssues)
export const getCurrentIssue = (state) => fromIssues.getCurrentIssue(state.kaseIssues)
export const isLoadingIssues = (state) => fromIssues.isLoadingIssues(state.kaseIssues)

export const allIssuesAddressed = createSelector(
  getOpenIssues,
  getMessages,
  getCurrentUserId,
  (issues, messages, userId) => {
    return Object.keys(issues).every((issueId) => mostRecentMessageIsFromUser(issues[issueId], messages, userId))
  }
)

export const isMostRecentMessageFromCustomer = createSelector(
  getCurrentIssue,
  getMessages,
  getCurrentUserId,
  (issue, messages, userId) => mostRecentMessageIsFromUser(issue, messages, userId)
)

export const getIssuesProgress = createSelector(
  getOpenIssues,
  getMessages,
  getCurrentUserId,
  (issues, messages, userId) => {
    const totalIssuesCount = Object.keys(issues).length

    if (!totalIssuesCount) {
      return 0
    }

    const completedIssues = Object.keys(issues).filter((issueId) =>
      mostRecentMessageIsFromUser(issues[issueId], messages, userId)
    ).length

    const percentCompleted = Math.round((completedIssues * 100) / totalIssuesCount)

    return percentCompleted
  }
)

export const getCurrentOpenQaTask = (state) => fromTasks.getCurrentOpenQaTask(state.kaseTasks)

export const isLoadingTasks = (state) => fromTasks.isLoadingTasks(state.kaseTasks)

export const canSubmitTask = createSelector(
  getCurrentUserId,
  getCurrentOpenQaTask,
  (currentUserId, currentOpenQaTask) => currentOpenQaTask?.attributes.assignee_id === currentUserId.toString()
)

// Address and Employment selectors
export const getKaseSponsorAddressHistories = (state) =>
  fromAddressHistories.getKaseSponsorAddressHistories(state.addressHistories)

export const getKaseBeneficiaryAddressHistories = (state) =>
  fromAddressHistories.getKaseBeneficiaryAddressHistories(state.addressHistories)

export const getCurrentEditedAddressHistory = (state) =>
  fromAddressHistories.getCurrentEditedAddressHistory(state.addressHistories)

export const isLoadingEmploymentHistories = (state) =>
  fromEmploymentHistories.isLoadingEmploymentHistories(state.employmentHistories)

export const getKaseBeneficiaryEmploymentHistories = (state) =>
  fromEmploymentHistories.getKaseBeneficiaryEmploymentHistories(state.employmentHistories)

export const getKaseSponsorEmploymentHistories = (state) =>
  fromEmploymentHistories.getKaseSponsorEmploymentHistories(state.employmentHistories)

export const isLoadingAddressHistories = (state) =>
  fromAddressHistories.isLoadingAddressHistories(state.addressHistories)

export const getSectionProgress = (state) => fromSectionProgress.getSectionProgress(state.sectionProgress)

export const getHasCurrentAddressFilled = (state) => {
  if (isSponsor(state)) {
    return getKaseSponsorAddressHistories(state).current_address_filled
  }
  return getKaseBeneficiaryAddressHistories(state).current_address_filled
}

export const getHasSpouseCurrentAddressFilled = (state) => {
  if (isSponsor(state)) {
    return getKaseBeneficiaryAddressHistories(state).current_address_filled
  }
  return getKaseSponsorAddressHistories(state).current_address_filled
}

export const getCurrentAddresses = (state) => {
  const currentAddresses = {
    beneficiary: null,
    sponsor: null
  }
  const beneficiaryAddressHistories = getKaseBeneficiaryAddressHistories(state)
  const sponsorAddressHistories = getKaseSponsorAddressHistories(state)

  const findCurrentAddress = (addressHistories) => {
    return addressHistories.address_history.find((a) => a.end_date === null)
  }

  if (beneficiaryAddressHistories.current_address_filled) {
    currentAddresses.beneficiary = findCurrentAddress(beneficiaryAddressHistories)
  }

  if (sponsorAddressHistories.current_address_filled) {
    currentAddresses.sponsor = findCurrentAddress(sponsorAddressHistories)
  }

  return currentAddresses
}

export const getIsAccountHolderAddress = (state) => {
  return (
    (isSponsor(state) && getCurrentPanelSlug(state) === 'sponsor-address-list') ||
    (isBeneficiary(state) && getCurrentPanelSlug(state) === 'beneficiary-address-list')
  )
}

export const getIsAccountHolderEmployment = (state) => {
  return (
    (isSponsor(state) && getCurrentPanelSlug(state) === 'sponsor-employment-list') ||
    (isBeneficiary(state) && getCurrentPanelSlug(state) === 'beneficiary-employment-list')
  )
}

export const getIsAccountHolderMailing = (state) => {
  return (
    (isSponsor(state) && getCurrentPanelSlug(state) === 'sponsor-mailing-address') ||
    (isBeneficiary(state) && getCurrentPanelSlug(state) === 'beneficiary-mailing-address')
  )
}

export const getKaseNamesAndRoles = createSelector(
  getBeneficiaryFirstName,
  getSponsorFirstName,
  isSponsor,
  (beneficiaryFirstName, sponsorFirstName, isSponsorRole) => {
    return {
      you: {
        firstName: `${isSponsorRole ? sponsorFirstName : beneficiaryFirstName}`,
        role: isSponsorRole ? 'sponsor' : 'beneficiary'
      },
      spouse: {
        firstName: `${isSponsorRole ? beneficiaryFirstName : sponsorFirstName}`,
        role: isSponsorRole ? 'beneficiary' : 'sponsor'
      }
    }
  }
)

export const getBeneficiaryMailingAddress = (state) => getKaseBeneficiaryAddressHistories(state).mailing_address
export const getSponsorMailingAddress = (state) => getKaseSponsorAddressHistories(state).mailing_address
export const getBeneficiaryIntendedAddress = (state) => getKaseBeneficiaryAddressHistories(state).intended_address

export const getMailingAddresses = createSelector(
  getBeneficiaryMailingAddress,
  getSponsorMailingAddress,
  (benficiaryMailingAddress = null, sponsorMailingAddress = null) => {
    return {
      beneficiary: benficiaryMailingAddress,
      sponsor: sponsorMailingAddress
    }
  }
)

export const getIsSponsorComplete = (state) => getKaseSponsorAddressHistories(state).complete

const getIsCurrentAddressInUS = (addressArray) =>
  addressArray.some((a) => {
    if (a.address === null) return false
    return a.end_date === null && a.address.country_code === 'US'
  })

// the inverse does exist above, however it is dependent on the model, I want this based on add/emp
const getIsBeneficiaryInUS = (state) => {
  const beneficiaryAddressHistories = getKaseBeneficiaryAddressHistories(state)
  return getIsCurrentAddressInUS(beneficiaryAddressHistories.address_history)
}
const getIsSponsorInUS = (state) => {
  const sponsorAddressHistories = getKaseSponsorAddressHistories(state)
  return getIsCurrentAddressInUS(sponsorAddressHistories.address_history)
}

const getBeneficiarySponsorLiveTogether = (state) => {
  const currentAddresses = getCurrentAddresses(state)
  if (currentAddresses.sponsor === null || currentAddresses.beneficiary === null) return false
  return currentAddresses.sponsor.shared && currentAddresses.beneficiary.shared
}

export const getIntendedAddressOptions = createSelector(
  getBeneficiarySponsorLiveTogether,
  getIsBeneficiaryInUS,
  getIsSponsorInUS,
  getCurrentAddresses,
  getBeneficiaryFirstName,
  getSponsorFirstName,
  (liveTogether, isBeneficiaryInUS, isSponsorInUS, currentAddresses, beneficiaryFirstName, sponsorFirstName) => {
    const optionsArray = []

    // If live together, edit bene record
    if (liveTogether) {
      optionsArray.push({
        name: 'beneficiary',
        address: currentAddresses.beneficiary,
        label: `Use ${beneficiaryFirstName} & ${sponsorFirstName}’s current address`
      })
    } else {
      // If sponsor in US, make selectable
      if (isSponsorInUS) {
        optionsArray.push({
          name: 'sponsor',
          address: currentAddresses.sponsor,
          label: `Use ${sponsorFirstName}’s current address`
        })
      }
      // If bene in US, make selectable
      if (isBeneficiaryInUS) {
        optionsArray.push({
          name: 'beneficiary',
          address: currentAddresses.beneficiary,
          label: `Use ${beneficiaryFirstName}’s current address`
        })
      }
    }
    return optionsArray
  }
)

/////////////////////
// Mixed selectors
/////////////////////

export const getAccountCurrentAddress = (state) =>
  fromModel.getAccountCurrentAddress(state.model, getCurrentKaseKind(state))

export const getComputedInitialPanel = (state) => fromModel.getComputedInitialPanel(state.model)

export const getCurrentAccountName = createSelector(getModelData, getCurrentKaseKind, (data, kind) => {
  if (kind === KaseKind.Natz) return data.applicant.name

  if (kind === KaseKind.MarriageBasedGreenCard) {
    if (data.account.role === 'sponsor') {
      // B20-1267
      return data.sponsor.uscis_contact_info.name
    } else {
      return data.beneficiary.uscis_contact_info.name
    }
  }
})

export const getCurrentAccountFirstNames = createSelector(getModelData, getCurrentKaseKind, (data, kind) => {
  if (kind === KaseKind.Natz) {
    return [data.applicant?.name?.first_name ?? '']
  }

  if (kind === KaseKind.MarriageBasedGreenCard || kind === KaseKind.NewMarriageBasedGreenCard) {
    return [data.sponsor?.name?.first_name ?? '', data.beneficiary?.name?.first_name ?? '']
  }
})

const ADMIN_KASE_PLACEHOLDER = 'no-relevant-kase-id-admin'

export const getCurrentUserTelemetry = createSelector(
  getCurrentUser,
  isUserLoggedIn,
  getCurrentKase,
  (user, isLoggedIn, kase) => {
    if (isPresent(user)) {
      const traits = {
        id: user.id,
        guestId: user.guest_id,
        createdAt: user.created_at,
        isAdmin: user.admin,
        kaseId: kase && kase.id,
        isLoggedIn
      }

      if (user.admin) {
        traits.email = user.email
        traits.kaseId = ADMIN_KASE_PLACEHOLDER
      }

      return traits
    } else {
      return {}
    }
  }
)

export const getDocumentUploadLocation = createSelector(getActiveModal, (activeModal) =>
  fromUploads.getDocumentUploadLocation(activeModal)
)

export const getPetitionEmail = createSelector(getModelData, getCurrentKaseKind, (data, kind) => {
  if (kind === 'MarriageBasedGreenCard') {
    return data.account.role === 'sponsor' // B20-1267
      ? data.sponsor.uscis_contact_info.email // B20-1267
      : data.beneficiary.uscis_contact_info.email // B20-1267
  }
})

export const getResourceContext = createSelector(
  (state) => state,
  (state, options) => options,
  getCurrentKaseKind,
  (state, options, kaseKind) => getComputedResourceContext(state, options, kaseKind)
)

// TODO: Maybe we can group resource context
// selectors in a different file instead of
// dumping them in reducers/selectors
export const getLastThreeTaxYears = createSelector(
  getResourceContext,
  (resourceContext) => resourceContext.last_three_tax_years
)

export const getRelativeLastThreeTaxYears = createSelector(
  getResourceContext,
  (resourceContext) => resourceContext.relative_last_three_tax_years
)

export const getI18nContext = createSelector(getResourceContext, (resourceContext) => resourceContext.context)

export const getExternalDomain = createSelector(getResourceContext, (resourceContext) => resourceContext.externalDomain)

export const groupSortedDocumentRequests = createSelector(
  getSortedDocumentRequests,
  getBeneficiaryFirstName,
  getSponsorFirstName,
  getJointSponsorFirstName,
  getHouseholdMembersFirstNames,
  getChildrenFirstNames,
  getApplicantFirstName,
  fromCrossDomain.groupSortedDocumentRequests
)

export const getLawyerInteractions = (state) => fromLawyerInteractions.getLawyerInteractions(state.lawyerInteractions)
