import React from 'react'

import { connect } from 'react-redux'
import { postTelemetry, resetTelemetry } from '../../actions/telemetry_actions'
import { isUserLoggedIn, getTimelinePageUrl, getCurrentUser } from '../../reducers/selectors'

import { AnalyticsContext, AppHeader } from '@boundless-immigration/boundless-ui'
import { deployStage } from '../../lib/settings'
import { openChat } from '../../actions/chat_actions'
import { TELEMETRY_EVENTS } from 'lib/constants'
import api from 'lib/api'
import { showSignInModal } from 'components/sign_in_nav'
import { trackUserEvent } from 'actions/telemetry_actions_v2'
import { getIsFeatureEnabled } from 'reducers/features/selectors'
import { FEATURES } from 'lib/features'

interface MappedProps {
  userLoggedIn?: boolean
  timelineUrl?: string
  currentUser?: any
  isAnalyticsDebugEnabled?: boolean
}

interface ActionProps {
  openChat: (args: { via: string }) => void
  trackLogout: () => void
  trackUserEventApiFunction: typeof trackUserEvent
}

function NavbarRoot({
  userLoggedIn,
  timelineUrl,
  currentUser,
  isAnalyticsDebugEnabled,
  openChat,
  trackLogout,
  trackUserEventApiFunction
}: MappedProps & ActionProps) {
  const isProd = deployStage() === 'production'
  // Point WP site links to WP-staging unless we are in prod, then point to WP-production
  const marketingSiteDomain = isProd ? 'https://www.boundless.com' : 'https://wwwstage.boundless.com'

  const logoLink = userLoggedIn ? timelineUrl : marketingSiteDomain

  const handleClickLogin = () => {
    if (userLoggedIn) {
      trackLogout()
      api.users.signOut().then(() => {
        window.location.href = '/users/sign_in'
      })
    } else {
      showSignInModal({
        successUrl: '/start',
        via: 'Navbar'
      })
    }
  }

  return (
    <AnalyticsContext.Provider
      value={{
        appName: 'apply',
        pageName: window.location.pathname,
        apiFunction: trackUserEventApiFunction,
        debug: isAnalyticsDebugEnabled
      }}
    >
      <AppHeader
        logoUrl={logoLink}
        handleLoginClick={handleClickLogin}
        isAdmin={currentUser.admin}
        adminLink="/active_admin"
        isLoggedIn={userLoggedIn}
        isProduction={isProd}
        liveHelp
        liveHelpContactEmail="help"
        liveHelpContactTypes={['email', 'phone', 'chat']}
        liveHelpOnClickChat={() => openChat({ via: 'navbar' })}
        userEmail={currentUser.email}
      />
      <div className="js-ribbon"></div>
    </AnalyticsContext.Provider>
  )
}

function mapStateToProps(state: any): MappedProps {
  return {
    userLoggedIn: isUserLoggedIn(state),
    timelineUrl: getTimelinePageUrl(state),
    currentUser: getCurrentUser(state),
    isAnalyticsDebugEnabled: getIsFeatureEnabled(state, FEATURES.BUI_ANALYTICS_DEBUG_ENABLED)
  }
}

function mapDispatchToActions(dispatch: any) {
  return {
    openChat: (...args) => dispatch(openChat(...args)),
    trackLogout: () => {
      dispatch(postTelemetry(TELEMETRY_EVENTS.LOGGED_OUT_USER))
      dispatch(resetTelemetry)
    },
    trackUserEventApiFunction: (event: string, event_data: any) => dispatch(trackUserEvent(event, event_data))
  }
}

export default connect(mapStateToProps, mapDispatchToActions)(NavbarRoot)
