import React, { FunctionComponent } from 'react'
import IconWrapper, { IconWrapperExternalProps } from './icon_wrapper'

const CheckmarkIcon: FunctionComponent<IconWrapperExternalProps> = (props) => (
  <IconWrapper {...props} viewBox="0 0 32 32">
    <path d="M13 24.914l-8.414-8.414 1.414-1.414 7 7 13-13 1.414 1.414-14.414 14.414z" />
  </IconWrapper>
)

export default CheckmarkIcon
