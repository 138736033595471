import React, { FunctionComponent } from 'react'

interface Props {
  label: string
  labelFor: string
  classNameSize?: string
}

const defaultProps = {
  classNameSize: ''
}

const CommonLabelV2: FunctionComponent<Props> = ({ label, labelFor, classNameSize }) => {
  let sizeName = 'o-grid__cell--3/12'
  if (classNameSize) {
    sizeName = classNameSize
  }
  return (
    <label className={`${sizeName} o-grid__cell--off@sm c-paper-form__label c-paper-form__segment`} htmlFor={labelFor}>
      <span className="c-type c-type--subhead-sm">{label}</span>
    </label>
  )
}

CommonLabelV2.defaultProps = defaultProps

export default CommonLabelV2
