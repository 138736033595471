import { push, replace } from 'redux-little-router'
import { panelPath } from './route_helpers'
import Panel from './model/panel'

const actions = { push, replace }

export default function routeVia(actionName: string, via: string, query: string, panel: Panel) {
  const actionPath = panelPath(panel) + (query || '')
  const action = actions[actionName](actionPath)
  action.payload.via = via
  action.payload.newPanel = panel

  return action
}
