import React, { FunctionComponent, useEffect } from 'react'
import { connect } from 'react-redux'
import Error from 'components/error'
import { getGlobalError } from 'reducers/global/selector'
import { smoothScrollToTopOfElement } from 'lib/ui_helpers'

interface MappedProps {
  message?: string
}

const GlobalError: FunctionComponent<MappedProps> = ({ message }) => {
  const showErrorMessage = message && message.length !== 0

  useEffect(() => {
    if (showErrorMessage) {
      smoothScrollToTopOfElement(document.getElementById('error-wrapper'))
    }
  }, [message])

  return <span id="error-wrapper">{showErrorMessage && <Error>{message}</Error>}</span>
}

function mapStateToProps(state) {
  return {
    message: getGlobalError(state)
  }
}

export default connect(mapStateToProps)(GlobalError)
