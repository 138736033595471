import { AxiosPromise } from 'axios'

import server from './server'
import { Tier } from '../../components/payment'

interface PaymentCreateRequest {
  containerSection: string
  kase_id: number
  paymentType: string
  tokenId: string
}

interface StripeCheckoutObject {
  domain_url?: string
  include_uscis_fees?: boolean
  kase_id: number
  number_teens?: number
  session_id?: string
  tier?: Tier
}

interface SplitItCheckoutObject {
  kase_id: number
  include_uscis_fees?: boolean
  number_teens?: number
  tier?: Tier
}

function create({ containerSection, kase_id, paymentType, tokenId }: PaymentCreateRequest): AxiosPromise {
  return server.post(`/api/kases/${kase_id}/payments`, {
    token_id: tokenId,
    paymentType,
    container_section: containerSection
  })
}

function initiateStripeCheckoutSession({
  kase_id,
  domain_url,
  include_uscis_fees,
  number_teens,
  tier
}: StripeCheckoutObject): AxiosPromise {
  return server
    .post(`/api/kases/${kase_id}/payments/stripe`, {
      domain_url,
      include_uscis_fees,
      number_teens,
      tier
    })
    .then((response) => {
      return response
    })
}

function expireStripeCheckoutSession({ kase_id, session_id }: StripeCheckoutObject): AxiosPromise {
  return server.post(`/api/kases/${kase_id}/payments/expire_stripe`, {
    session_id
  })
}

function initiateSplitItCheckout({
  kase_id,
  include_uscis_fees,
  number_teens,
  tier
}: SplitItCheckoutObject): AxiosPromise {
  return server.post(`/api/kases/${kase_id}/payments/split_it`, { include_uscis_fees, number_teens, tier })
}

function verifySplitItPayment(kase_id: string | number, installmentPlanNumber: string): AxiosPromise {
  return server.get(`/api/kases/${kase_id}/payments/split_it/${installmentPlanNumber}`)
}

function expireSplitItCheckout(kase_id: number): AxiosPromise {
  return server.post(`/api/kases/${kase_id}/payments/expire_split_it`)
}

export default {
  create,
  expireSplitItCheckout,
  expireStripeCheckoutSession,
  initiateSplitItCheckout,
  initiateStripeCheckoutSession,
  verifySplitItPayment
}
