import React, { FunctionComponent } from 'react'
import cx from 'classnames'
import { Link } from 'react-router-dom'

import { QuestionnaireOutlineV2, QuestionnaireOutlinePageV2, QuestionnaireOutlineSectionV2 } from '../lib/types'

export enum OutlineTypes {
  Slim,
  Disabled,
  Complete
}
interface OutlineProps {
  baseQuestionnaireUrl: string
  currentPagePath: string
  currentSectionName?: string
  outlineType?: OutlineTypes
  questionnaireOutlineData: QuestionnaireOutlineV2
  setCurrentSectionHandler: Function
}

const DesktopQuestionnaireOutlineV2: FunctionComponent<OutlineProps> = (props) => {
  const {
    baseQuestionnaireUrl,
    currentPagePath,
    currentSectionName,
    outlineType = OutlineTypes.Complete,
    questionnaireOutlineData,
    setCurrentSectionHandler
  } = props

  const questionnaireSections = questionnaireOutlineData?.attributes?.sections

  // currently only used on the K1 > AOS conversion product
  // some code below may be reflective of specific use case
  const sectionOutline = questionnaireOutlineData?.attributes?.sections.find(
    (section) => section.attributes.name === currentSectionName
  )

  // The summary section behaves differently in the outline view:
  // - there are no completeness/viewed attributes
  // - the section title is not displayed
  // - the section does not collapse, only the pages
  // - there is a visual divider between these pages and the rest of the questionnaire
  const renderSummarySection = (summarySection: QuestionnaireOutlineSectionV2) => {
    return (
      <div key={summarySection.attributes.name} className="o-list-bare panel-list u-hide@sm-down chapter--flat">
        <span className="o-block o-block--compact">
          <hr className="c-divider c-divider--important c-divider--primary c-divider--broad" />
        </span>
        {summarySection.attributes.pages.map((page: QuestionnaireOutlinePageV2) => {
          const isSelectedPage = currentPagePath === page?.attributes?.path
          const sectionClasses = cx('chapter', {
            selected: isSelectedPage,
            'o-block o-block--tight': isSelectedPage,
            clickable: true
          })
          return (
            <li key={page.id} className={'panel o-block o-block--tight c-type c-type--subhead-sm clickable complete'}>
              {/* @ts-ignore */}
              <Link to={`${baseQuestionnaireUrl}/${page?.attributes?.path}`} className={sectionClasses}>
                <h4 className="c-type c-type--subhead-sm c-type--inline">{page?.attributes?.name}</h4>
              </Link>
            </li>
          )
        })}
      </div>
    )
  }

  switch (outlineType) {
    case OutlineTypes.Slim:
      if (currentPagePath === 'summary-your-payment-options') {
        return <div />
      } else {
        return (
          <div className="outline">
            <section className="o-box o-box--spacious">
              <hr className="o-block c-divider c-divider--narrow c-divider--secondary c-divider--emphasized" />
              <h1 id="eligibility" className="c-type c-type--headline-sm c-type--emphasized">
                Check Your Eligibility
              </h1>
            </section>
            {questionnaireOutlineData?.attributes.percent_complete}% done
          </div>
        )
      }
    case OutlineTypes.Disabled:
      return (
        <div>
          <section className="o-box">
            <div className="o-box o-box--copius mb-12">
              <a href="/timeline" className="c-type c-type--body-sans-sm">
                Back to dashboard
              </a>
            </div>
            <h1 id="eligibility" className="c-type c-type--headline-sm c-type--emphasized mb-6">
              {currentSectionName}
            </h1>
            <hr className="o-block c-divider c-divider--narrow c-divider--secondary c-divider--emphasized" />
          </section>
          <ul className="outline-list o-block o-list-bare">
            {sectionOutline?.attributes?.pages?.map((page) => {
              const {
                attributes: { name, path, type, viewed, completed },
                id
              } = page
              const classNames = cx('panel o-block o-block--tight c-type c-type--subhead-sm', {
                selected: currentPagePath === path
              })
              const isComplete = ((completed && type !== 'PaymentPage') || type !== 'EstimatedTimelinePage') && viewed
              return (
                <li key={id} className={classNames}>
                  <h4 className="c-type c-type--subhead-sm c-type--inline">
                    {name}
                    {isComplete && <i className="checkmark" data-testid="checkmark" />}
                  </h4>
                </li>
              )
            })}
          </ul>
        </div>
      )
    case OutlineTypes.Complete:
      return (
        <div className="o-box o-box--spacious">
          <div className="o-block o-block--compact">
            <div className="o-block o-block--tight c-type c-type--body-sans-sm">
              {questionnaireOutlineData?.attributes.percent_complete}% done
            </div>
          </div>

          <span className="o-block o-block--compact">
            <hr className="c-divider c-divider--important c-divider--primary c-divider--broad" />
          </span>
          <ul className="outline-list o-block o-list-bare">
            {questionnaireSections
              ?.filter((section: QuestionnaireOutlineSectionV2) => {
                return section.attributes.outline_visible
              })
              .filter((section: QuestionnaireOutlineSectionV2) => {
                return section.attributes.pages.length > 0
              })
              .map((section: QuestionnaireOutlineSectionV2) => {
                const {
                  attributes: { name, pages, completed },
                  id
                } = section

                if (name === 'Summary') return renderSummarySection(section)

                const isSelectedSection = name === currentSectionName
                const nonInterstitialPages = pages.filter((page) =>
                  ['Page', 'HistoryPage', 'SummaryPage', 'EstimatedTimelinePage'].includes(page.attributes.type)
                )

                return (
                  <li
                    id={id}
                    key={name}
                    className={cx({
                      complete: completed,
                      incomplete: !completed
                    })}
                  >
                    <div
                      className={cx('chapter clickable', {
                        'o-block o-block--tight selected': isSelectedSection
                      })}
                      onClick={() => setCurrentSectionHandler(name)}
                    >
                      <h4 className="c-type c-type--subhead-sm c-type--inline">{name}</h4>
                      {!completed && <i className="asterix">*</i>}
                      {completed && <i className="checkmark" data-testid="checkmark" />}
                    </div>

                    {isSelectedSection && nonInterstitialPages && (
                      <ul className="o-list-bare panel-list">
                        {nonInterstitialPages?.map((page) => {
                          const {
                            attributes: { name, path, viewed, completed },
                            id
                          } = page

                          const pageClasses = cx(
                            'panel',
                            'o-block',
                            'o-block--tight',
                            'c-type',
                            'c-type--subhead-sm',
                            'clickable',
                            'no-link-styling',
                            {
                              selected: currentPagePath === path,
                              complete: completed && viewed,
                              incomplete: !completed && viewed
                            }
                          )

                          return (
                            <li id={id} key={id} className={pageClasses}>
                              {/* @ts-ignore */}
                              <Link to={`${baseQuestionnaireUrl}/${path}`}>
                                {name}
                                {completed && viewed && <i className="checkmark" data-testid="checkmark" />}
                                {!completed && viewed && <i className="asterix">*</i>}
                              </Link>
                            </li>
                          )
                        })}
                      </ul>
                    )}
                  </li>
                )
              })}
          </ul>
        </div>
      )
    default:
      return <div />
  }
}

export default DesktopQuestionnaireOutlineV2
