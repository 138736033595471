import React, { FunctionComponent } from 'react'
import Paragraph from 'components/type/paragraph'
import MessageInitials from './message_initials'
import LinkifyText from 'components/linkify_text'
interface ExplicitProps {
  creatorId?: string
  createdAt?: string
  messageBody?: string
  creatorName?: string
}

export const ThreadPanelMessage: FunctionComponent<ExplicitProps> = ({
  creatorId,
  createdAt,
  creatorName,
  messageBody
}) => {
  return (
    <div className="py-4 md:py-6 border-gray-200">
      <MessageInitials creatorId={creatorId} createdAt={createdAt} creatorName={creatorName} />
      <Paragraph spacing="xs">
        <LinkifyText>{messageBody}</LinkifyText>
      </Paragraph>
    </div>
  )
}

export default ThreadPanelMessage
