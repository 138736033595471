import React, { FunctionComponent, useState } from 'react'
import { connect } from 'react-redux'
import api from 'lib/api'
import Modal from '../modal'
import { closeModal } from '../../../actions/modal_actions'
import { getCurrentKaseId } from 'reducers/selectors'
import ArrowIcon from 'components/icons/arrow_icon'
import { updateWorkflowStatus } from 'actions/kase_actions'

interface Props {
  isActive?: boolean
  closeModal: () => void
  kaseId: number
  updateWorkflowStatus: (workflowStatus: string) => void
}

type LawyerReviewStatus = 'not started' | 'starting' | 'started'

const BeginLawyerReviewConfirmationModal: FunctionComponent<Props> = ({
  isActive,
  closeModal,
  kaseId,
  updateWorkflowStatus
}) => {
  const [reviewStatus, setReviewStatus] = useState<LawyerReviewStatus>('not started')

  const [showError, setShowError] = useState(false)

  const startLawyerAssessment = () => {
    setReviewStatus('starting')

    setShowError(false)

    api.kases
      .startLawyerAssessment({ kaseId })
      .then((response) => {
        const { new_workflow_status } = response.data
        if (new_workflow_status != null) {
          updateWorkflowStatus(new_workflow_status)
          closeModal()
        } else {
          setShowError(true)
          setReviewStatus('not started')
        }
      })
      .catch(() => {
        setShowError(true)
        setReviewStatus('not started')
      })
  }

  const cancelAssessment = () => {
    setShowError(false)
    closeModal()
  }

  const disableButtons = reviewStatus !== 'not started'

  const getBeginButtonIcon = () => {
    if (reviewStatus === 'not started') {
      return (
        <span className="c-btn__icon">
          <ArrowIcon arrowDirection="right" />
        </span>
      )
    }

    return (
      <span className="c-btn__icon">
        <div className="c-icon spinner">
          <div className="spinner__circle" />
        </div>
      </span>
    )
  }

  return (
    <Modal
      modalWidth="o-grid__col-8"
      modalOffset="o-grid__col--offset-2"
      isActive={isActive}
      title="Are you ready to begin your assessment?"
      disableClosingWithoutAction={reviewStatus !== 'not started'}
    >
      {() => (
        <div>
          <p className="o-block o-block--copious c-type c-type--body-sans-md">
            The rest of the application process will go quicker if your information is accurate and complete at this
            point. It’s a good time to double check everything now before your lawyer begins their assessment.
          </p>
          {showError && (
            <div className="o-block c-alert c-alert--danger">
              <p className="c-type c-type--body-sans-md">
                Something went wrong. Please make sure you're connected to the internet and try again. If this error
                persists, please <a href="mailto:help@boundless.com">contact our support team</a>.
              </p>
            </div>
          )}
          <div className="o-grid--fluid">
            <div className="o-grid__cell--fill o-grid__cell--off@sm o-block@sm-down">
              <button
                className="c-btn c-btn--secondary o-grid__cell--off@sm"
                disabled={disableButtons}
                onClick={cancelAssessment}
              >
                No, go back to my info
              </button>
            </div>
            <div className="o-grid__cell--fill o-grid__cell--off@sm o-layout--right">
              <button
                className="c-btn c-btn--primary c-btn--block@sm-down"
                disabled={disableButtons}
                onClick={startLawyerAssessment}
              >
                Yes, begin assessment
                {getBeginButtonIcon()}
              </button>
            </div>
          </div>
        </div>
      )}
    </Modal>
  )
}

function mapDispatchToActions(dispatch) {
  return {
    closeModal: (...args) => dispatch(closeModal(...args)),
    updateWorkflowStatus: (workflowStatus: string) => dispatch(updateWorkflowStatus(workflowStatus))
  }
}

function mapStateToProps(state) {
  return {
    kaseId: getCurrentKaseId(state)
  }
}

export default connect(mapStateToProps, mapDispatchToActions)(BeginLawyerReviewConfirmationModal)
