import u from 'updeep'
import {
  LOADING_POST_SHIP_DASHBOARD,
  LOADING_POST_SHIP_DASHBOARD_SUCCESS,
  UPDATING_POST_SHIP_DASHBOARD,
  UPDATING_POST_SHIP_DASHBOARD_SUCCESS
} from 'lib/constants'
import { PostShipDashboardData } from 'components/screens/dashboard/post_ship_dashboard/lib/types'

// -----------------------------------------
// POST SHIP DASHBOARD REDUCER
// -----------------------------------------

export interface PostShipDashboardStore {
  data: PostShipDashboardData
  isLoading: boolean
  isUpdating: boolean
}

const initialState: PostShipDashboardStore = {
  data: null,
  isLoading: false,
  isUpdating: false
}

export default function postShipDashboardReducer(state: PostShipDashboardStore, action: any): PostShipDashboardStore {
  state = state || initialState
  switch (action.type) {
    case LOADING_POST_SHIP_DASHBOARD: {
      return u(
        {
          isLoading: action.payload
        },
        state
      )
    }

    case LOADING_POST_SHIP_DASHBOARD_SUCCESS: {
      const data: PostShipDashboardData = action.payload.data
      return u(
        {
          data
        },
        state
      )
    }

    case UPDATING_POST_SHIP_DASHBOARD: {
      return u(
        {
          isUpdating: action.payload
        },
        state
      )
    }

    case UPDATING_POST_SHIP_DASHBOARD_SUCCESS: {
      return u(
        {
          data: action.payload.data
        },
        state
      )
    }

    default: {
      return state
    }
  }
}
