import { buildMessage, Validator } from './'

const requiredIfHasSkills = (getModelData, path, skills_path, error_message) => {
  const hasI140 = getModelData('beneficiary.has_i_140_form')
  if (hasI140) return []

  const currentValue = getModelData(path)
  const skillsExists = getModelData(skills_path)

  if (skillsExists && currentValue == null) {
    return [buildMessage(error_message)]
  }

  return []
}

export const requiredIfHasLanguageSkills: Validator = ({ getModelData, path }) => {
  return requiredIfHasSkills(
    getModelData,
    path,
    'beneficiary.has_english_language_certification',
    'Please add language skills'
  )
}

export const requiredIfHasOccupationalSkills: Validator = ({ getModelData, path }) => {
  return requiredIfHasSkills(
    getModelData,
    path,
    'beneficiary.has_occupational_certification',
    'Please add occupational skills or certificates'
  )
}
