import countries from './country_list.json'

interface CountryList {
  [countryCode: string]: string
}

export const COUNTRIES: CountryList = countries

export function getCountryNameFromCode(countryCode: string): string {
  return COUNTRIES[countryCode] || ''
}

export function getCountryCodeFromName(countryName: string) {
  for (let countryCode in COUNTRIES) {
    if (countryName.toLowerCase() === COUNTRIES[countryCode].toLowerCase()) {
      return countryCode
    }
  }

  return null
}
