import React, { FunctionComponent } from 'react'
import AskQuestionButton from 'components/ask_question_button'
import PhoneIcon from 'components/icons/phone_icon'

import SupportAgent from 'images/postship-dashboard/supportavatar.png'

const PostShipHelp: FunctionComponent = () => {
  return (
    <>
      <h4 className="c-type c-type--subhead-md py-8">Get Help</h4>
      <hr className="c-divider o-block" />
      <ul className="o-list-bare c-type c-type--body-sans-sm">
        <li className="flex justify-start pb-4">
          <img alt="support agent" className="h-9 w-9" src={SupportAgent} />
          <div className="pl-2 pt-2">
            <AskQuestionButton via="PostShipDashboard">Start Chat</AskQuestionButton>
          </div>
        </li>
        <li className="flex justify-start text-blue-500  pb-4">
          <PhoneIcon size="medium" className="mx-2" />
          <a href="tel:1-855-268-6353" className="c-link pl-2">
            Call Us 1&#8209;855&#8209;268&#8209;6353
          </a>
        </li>
      </ul>
    </>
  )
}

export default PostShipHelp
