import server from './server'
import { AxiosResponse } from 'axios'

import { KaseEvent } from 'lib/kase_event'

export interface KaseEventIndexRequest {
  kaseId: number
  filters: {
    eventType: string
  }
}

interface KaseEventIndexQuery {
  event_type?: string
}

interface KaseEventIndexResponse {
  events: KaseEvent[]
}

async function index(request: KaseEventIndexRequest): Promise<KaseEvent[]> {
  const { kaseId } = request
  const filters = request.filters
  const query: KaseEventIndexQuery = {}

  if (filters && filters.eventType) query.event_type = filters.eventType

  return server
    .get(`/api/admin/kases/${kaseId}/events`, { params: query })
    .then((response: AxiosResponse<KaseEventIndexResponse>) => response.data.events)
}

export default { index }
