import React from 'react'
import { connect } from 'react-redux'

import panelLocaleService from 'services/panel_locale_service'

import { getCurrentContentSource } from 'reducers/selectors'
import { ChapterType, PanelType } from './index'

import ProfileIcon from 'components/icons/profile_icon'

interface Props {
  chapter: ChapterType
  panel: PanelType
  contentSource?: 'income' | 'timeline'
}

class ChapterHeader extends React.Component<Props> {
  getTitle() {
    const { chapter, contentSource, panel } = this.props

    if (chapter.name === 'eligibility' && contentSource === 'income') {
      return 'Cost Calculator'
    }

    if (chapter.name === 'eligibility' && contentSource === 'timeline') {
      return 'Wait Time Calculator'
    }

    return panelLocaleService.t(panel.chapter_title_key, {
      index: panel.panel_index
    })
  }

  render() {
    const title = this.getTitle()

    return (
      <div className="c-sheet__header">
        <div className="o-grid__row">
          <div className="o-grid__col-6 o-grid__col--offset-1">
            <div className="c-image-label">
              <ProfileIcon className="c-image-label__image c-icon--lg-responsive" />
              <h2 className="c-image-label__copy">{title}</h2>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    contentSource: getCurrentContentSource(state)
  }
}

export default connect(mapStateToProps)(ChapterHeader)
