import moment from 'moment'

import { buildMessage, Validator } from './'

function parseDate(date: string) {
  return moment(date, 'YYYY/MM/DD')
}

const DATE_NOW = moment()

const validDateNotFuture: Validator = ({ getModelData, path }) => {
  const date = getModelData(path)

  if (!date) return []

  const parsedDate = parseDate(date)

  const errors = []

  if (!parsedDate.isValid()) {
    errors.push(buildMessage('Please enter a valid date'))
  } else if (moment(parsedDate).isAfter(DATE_NOW)) {
    errors.push(buildMessage(`Please enter a date not in the future`))
  }

  return errors
}

export default validDateNotFuture
