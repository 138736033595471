import React, { FunctionComponent, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import cx from 'classnames'

import { trackUserEvent } from 'actions/telemetry_actions_v2'
import { AddressHistory } from 'reducers/address_histories/index'

import ContextualHelpModal from 'components/modals/modal/contextual_help_modal'
import HelpIcon from 'components/icons/help_icon'
import InputTransformer from 'components/screens/questionnaire/inputs/input_transformer'
import JITFeedback from 'components/forms/panel/jit_feedback'

import { TELEMETRY_EVENTS } from 'lib/constants'
import { MilestoneTask } from './lib/types'

interface ActionProps {
  /**
   * Action that allows us to send tracking events to the backend so they can
   * send them on the Segment, ad blockers can't block the events sent to BE first :D
   */
  trackUserEvent: typeof trackUserEvent
}

interface InjectedProps {
  /**
   * Boolean used for mobile styling
   */
  isMobile: boolean
  /**
   * Passing a change in an input up to be managed in local state
   */
  onAnswerUpdate: (answer: string) => void
  /**
   * The task data for this task component
   */
  task: MilestoneTask
  /**
   * A piece of data to display to the user the current value
   * stored in the local state of the form or the DB when changes are saved.
   */
  value?: string | AddressHistory | Date

  disabledField: boolean
}

type Props = InjectedProps & ActionProps

const PreMilestoneTask: FunctionComponent<Props> = ({
  isMobile,
  onAnswerUpdate,
  task,
  trackUserEvent,
  value,
  disabledField
}) => {
  const [showContextualHelpModal, setIsContextualHelpModalShown] = useState(false)
  const [currentValue, setCurrentValue] = useState(value || '')
  const [fieldHasSomeContent, setFieldHasSomeContent] = useState(false)

  useEffect(() => {
    if (value !== '') {
      setFieldHasSomeContent(true)
    } else {
      setFieldHasSomeContent(false)
    }

    setCurrentValue(value)
  }, [value])

  const onContextualHelpLinkClick = (key: string) => {
    setIsContextualHelpModalShown(!showContextualHelpModal)

    trackUserEvent(TELEMETRY_EVENTS.CLICKED_POST_SHIP_DASHBOARD_CONTEXTUAL_HELP, {
      taskKey: key
    })
  }

  const contextualHelpLink = () => {
    return (
      <p className={cx('c-type--body-sans-sm pb-4', { 'py-6': !isMobile })}>
        <a
          className="u-clickable"
          data-qa="inline-help-button"
          onClick={() => onContextualHelpLinkClick(task.key)}
          title={task.contextual_help.header}
        >
          <HelpIcon className="mr-1" />
          {task.contextual_help.header}
        </a>
      </p>
    )
  }

  const getInputName = () => {
    if (task.input_type === 'string' || task.input_type === 'alien_registration_number') {
      return ''
    } else if (task.input_type === 'state_or_province') {
      return 'state or \nprovince'
    } else {
      return task.input_type
    }
  }

  return (
    <div>
      <div className={cx({ 'flex ': !isMobile })}>
        <div className={cx({ 'w-1/2 mr-4 pt-2': !isMobile, 'pt-6 pb-3': isMobile && task.prompt })}>
          <h3 className="c-type c-type--body-sans-md">{task.prompt}</h3>
          {!isMobile && task.contextual_help.header && contextualHelpLink()}
        </div>
        <div className={cx({ 'w-1/2 ml-4': !isMobile })}>
          <InputTransformer
            id={task.key}
            name={getInputName()}
            placeholder={task.placeholder}
            onSave={onAnswerUpdate}
            type={task.input_type === 'city' || task.input_type === 'state_or_province' ? 'string' : task.input_type}
            value={currentValue}
            disabled={disabledField}
          />
        </div>
      </div>

      {isMobile && task.contextual_help.header && contextualHelpLink()}
      {task.jit_feedback && fieldHasSomeContent && <JITFeedback text={task.jit_feedback} variant="weGotYou" />}
      {showContextualHelpModal && (
        <ContextualHelpModal
          header={task.contextual_help.header}
          imageUrl={task.contextual_help.image}
          isOpen={showContextualHelpModal}
          onRequestClose={() => setIsContextualHelpModalShown(false)}
          text={task.contextual_help.body}
        />
      )}
    </div>
  )
}

function mapDispatchToActions(dispatch: Function): ActionProps {
  return {
    trackUserEvent: (event: string, args) => dispatch(trackUserEvent(event, args))
  }
}

export default connect(null, mapDispatchToActions)(PreMilestoneTask)
