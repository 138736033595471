import React, { FunctionComponent } from 'react'
import cx from 'classnames'

import getDocumentRequestLocaleService from 'services/document_request_locale_service'

import Modal from 'components/modals/modal'
import RequestStatusIndicator from './request_status_indicator'
import { getDocRequestCustomerStatus } from './utils'
import { DocumentRequestCustomerStatus } from 'lib/constants'

interface Props {
  closeModal: Function
  documentRequests: DocumentRequestModel[]
  isActive?: boolean
  onDocRequestClick: (requestId: string) => void
  slaIsSet: boolean
}

const MobileOutline: FunctionComponent<Props> = ({
  closeModal,
  documentRequests,
  isActive,
  onDocRequestClick,
  slaIsSet
}) => {
  const documentRequestLocaleService = getDocumentRequestLocaleService()

  return (
    <Modal isActive={isActive} title="Document List" closeModal={closeModal}>
      {() => (
        <ul className="o-block o-list-bare">
          {documentRequests.map((request) => {
            const customerStatus = getDocRequestCustomerStatus(request, slaIsSet)
            const requestClasses = cx('c-link c-type c-type--body-sans-sm flex', {
              'c-type--alert': customerStatus === DocumentRequestCustomerStatus.SeeComment,
              'c-type--muted-dark': customerStatus === DocumentRequestCustomerStatus.Accepted
            })
            return (
              <li key={request.id} className="c-list__item py-1">
                <a className={requestClasses} href="#" onClick={() => onDocRequestClick(request.id)}>
                  <span className="flex-grow mr-1">
                    {request.title || documentRequestLocaleService.t(request, 'title', true)}
                  </span>
                  <RequestStatusIndicator iconOnly customerStatus={customerStatus} />
                </a>
              </li>
            )
          })}
        </ul>
      )}
    </Modal>
  )
}

export default MobileOutline
