import React from 'react'
import Button from 'components/button'
import { isProductionStage } from 'lib/settings'

const FeatureFlagInspector = () => {
  /**
   * We're relying on a window setting because we don't support different
   * environment variables per deployment stage (because we promote a staging
   * build to production instead of rebuilding when deploying).
   */
  if (isProductionStage()) {
    return null
  }

  return (
    <>
      <div
        style={{
          position: 'fixed',
          bottom: '.5rem',
          left: '.5rem',
          zIndex: 10000
        }}
      >
        <a href="/admin/flipper" target="_blank">
          <Button
            style={{
              background: '#fff',
              width: '2rem',
              height: '2rem',
              padding: 0,
              opacity: 0.5
            }}
            color="secondary"
            label="🚩"
            title="Feature flags"
            onClick={() => {}}
          />
        </a>
      </div>
    </>
  )
}

export default FeatureFlagInspector
