import { PanelGraph } from './shared'

const setupPanelGraph: PanelGraph = {
  start: 'eligibility.start',
  panels: {
    'eligibility.start': {
      nextPanels: [
        {
          key: 'eligibility.marital_status'
        }
      ]
    },
    'eligibility.marital_status': {
      nextPanels: [
        {
          key: 'eligibility.intent_to_marry', // B20-1267
          condition: 'notMarried'
        },
        {
          key: 'eligibility.account_holder_position'
        }
      ]
    },
    // B20-1267
    'eligibility.intent_to_marry': {
      nextPanels: [
        {
          key: 'eligibility.preparing_in_advance',
          condition: 'notMarriedButHasIntentToMarry'
        },
        {
          key: 'eligibility.ineligible_more_info',
          condition: 'notMarriedHasNoIntentToMarry'
        },
        {
          key: 'eligibility.account_holder_position'
        }
      ]
    },
    'eligibility.preparing_in_advance': {
      nextPanels: [
        {
          key: 'eligibility.account_holder_position'
        }
      ]
    },
    'eligibility.ineligible_more_info': {
      nextPanels: [
        {
          key: 'eligibility.setup_completed_ineligible'
        }
      ]
    },
    'eligibility.account_holder_position': {
      nextPanels: [
        {
          key: 'eligibility.sponsor_legal_status'
        }
      ]
    },
    'eligibility.sponsor_legal_status': {
      nextPanels: [
        {
          key: 'eligibility.setup_completed_ineligible',
          condition: 'otherSponsorLegalStatus'
        },
        {
          key: 'eligibility.legal_history'
        }
      ]
    },
    'eligibility.legal_history': {
      nextPanels: [
        {
          key: 'eligibility.setup_completed_ineligible',
          condition: 'criminalBackground'
        },
        {
          key: 'eligibility.beneficiary_country'
        }
      ]
    },
    'eligibility.beneficiary_country': {
      nextPanels: [
        {
          key: 'eligibility.visa_status',
          condition: 'beneficiaryInUS'
        },
        {
          key: 'eligibility.dependent_children'
        }
      ]
    },
    'eligibility.visa_status': {
      nextPanels: [
        {
          key: 'eligibility.setup_completed_ineligible',
          condition: 'invalidVisa'
        },
        {
          key: 'eligibility.public_benefits',
          condition: 'shouldShowPublicBenefitsQuestion'
        },
        {
          key: 'eligibility.dependent_children'
        }
      ]
    },
    'eligibility.public_benefits': {
      nextPanels: [
        {
          key: 'eligibility.setup_completed_ineligible',
          condition: 'hasUsedPublicBenefits'
        },
        {
          key: 'eligibility.dependent_children'
        }
      ]
    },
    'eligibility.dependent_children': {
      nextPanels: [
        {
          key: 'eligibility.other_dependents'
        }
      ]
    },
    'eligibility.other_dependents': {
      nextPanels: [
        {
          key: 'eligibility.others_supported'
        }
      ]
    },
    'eligibility.others_supported': {
      nextPanels: [
        {
          key: 'eligibility.income_requirement'
        }
      ]
    },
    'eligibility.income_requirement': {
      nextPanels: [
        {
          key: 'eligibility.additional_sponsors',
          condition: 'incomeQuestionFailed'
        },
        {
          key: 'eligibility.sponsor_email_gate',
          condition: 'sponsorRole'
        },
        {
          key: 'eligibility.beneficiary_email_gate',
          condition: 'beneficiaryRole'
        },
        {
          key: 'eligibility.setup_completed'
        }
      ]
    },
    'eligibility.additional_sponsors': {
      nextPanels: [
        {
          key: 'eligibility.additional_assets',
          condition: 'noAdditionalSponsors'
        },
        {
          key: 'eligibility.sponsor_email_gate',
          condition: 'sponsorRole'
        },
        {
          key: 'eligibility.beneficiary_email_gate',
          condition: 'beneficiaryRole'
        }
      ]
    },
    'eligibility.additional_assets': {
      nextPanels: [
        {
          key: 'eligibility.setup_completed_ineligible',
          condition: 'noAdditionalAssets'
        },
        {
          key: 'eligibility.sponsor_email_gate',
          condition: 'sponsorRole'
        },
        {
          key: 'eligibility.beneficiary_email_gate',
          condition: 'beneficiaryRole'
        }
      ]
    },
    'eligibility.sponsor_email_gate': {
      nextPanels: [
        {
          key: 'eligibility.setup_completed'
        }
      ]
    },
    'eligibility.beneficiary_email_gate': {
      nextPanels: [
        {
          key: 'eligibility.setup_completed'
        }
      ]
    }
  }
}

export default setupPanelGraph
