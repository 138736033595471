import { DocumentStatus, DocumentRequestStatus, DocumentRequestCustomerStatus } from 'lib/constants'
import getDocumentRequestLocaleService from 'services/document_request_locale_service'
import panelLocaleService from 'services/panel_locale_service'

export const requestHasComments = (request: DocumentRequestModel) => !!request.comment.body

export const documentNeedsAction = (document: DocumentModel) =>
  document.status === DocumentStatus.NeedsCustomerAction && !!document.comment.body

export const requestHasDocumentNeedsAction = (request: DocumentRequestModel) =>
  request.documents.some((document) => documentNeedsAction(document))

export const requestHasDocumentNeedReview = (request: DocumentRequestModel) =>
  request.documents.some((document) => document.status === DocumentStatus.NeedsReview)

export const hasCustomerActionItems = (request) => requestHasComments(request) || requestHasDocumentNeedsAction(request)

export const getNumberOfDocRequestsNeedCustomerAction = (requests: DocumentRequestModel[]) => {
  const requestsNeedCustomerAction = requests.filter(
    (request) => request.status === DocumentRequestStatus.NeedsCustomerAction
  )

  return requestsNeedCustomerAction.length
}

/**
 * @param {Object} request - Document Request
 * @param {boolean} slaSet - boolean representing whether the SLA exists in db
 */
export const getDocRequestCustomerStatus = (request: DocumentRequestModel, slaSet: boolean = false) => {
  const status = request.status

  // request status === NeedsCustomerAction
  // Doc Request has comments, a document needs customer action
  if (status === DocumentRequestStatus.NeedsCustomerAction && hasCustomerActionItems(request)) {
    return DocumentRequestCustomerStatus.SeeComment
  }

  // request status === NeedsCustomerAction
  // this should display if no documents have been uploaded or customer
  // has not clicked "Done Uploading"
  if (status === DocumentRequestStatus.NeedsCustomerAction) {
    return DocumentRequestCustomerStatus.ToDo
  }

  // request status === NeedsReview
  // if the SLA is not set it means documents have been uploaded and customer
  // has clicked "done uploading", but requests have not been submitted for review
  // this will only happen before the threshold is reached
  if (status === DocumentRequestStatus.NeedsReview && !slaSet) {
    return DocumentRequestCustomerStatus.DoneUploading
  }

  // request status === NeedsReview
  // if the SLA is not set it means documents have been uploaded and customer
  // has clicked "done uploading", and threshold has been reached
  if (status === DocumentRequestStatus.NeedsReview && slaSet) {
    return DocumentRequestCustomerStatus.SentToBoundless
  }

  // request status === Accepted
  // obvs
  if (status === DocumentRequestStatus.Accepted) {
    return DocumentRequestCustomerStatus.Accepted
  }

  return DocumentRequestCustomerStatus.ToDo
}

export const getDocRequestSummary = (docRequest?: DocumentRequestModel) => {
  if (!docRequest) {
    return null
  }

  const documentRequestLocaleService = getDocumentRequestLocaleService()
  return documentRequestLocaleService.t(docRequest, 'summary', true, true)
}

export const getDocRequestTitle = (docRequest?: DocumentRequestModel) => {
  if (!docRequest) {
    return null
  }

  const documentRequestLocaleService = getDocumentRequestLocaleService()
  return docRequest.title || documentRequestLocaleService.t(docRequest, 'title', true, true)
}

export const getDocRequestDescription = (docRequest?: DocumentRequestModel) => {
  if (!docRequest) {
    return null
  }

  const documentRequestLocaleService = getDocumentRequestLocaleService()
  return docRequest.description || documentRequestLocaleService.t(docRequest, 'description')
}

export const getSectionDisplayName = (sectionName) => {
  return panelLocaleService.t(`sections.${sectionName}.title`)
}

export const getDocRequestPriority = (docRequest: DocumentRequestModel) => {
  const { status, documents } = docRequest

  if (documents.length > 0 && status === DocumentRequestStatus.NeedsCustomerAction) {
    return 0 // Highest priority
  }

  if (status === DocumentRequestStatus.NeedsCustomerAction) {
    return 1
  }

  if (documents.length > 0 && status === DocumentRequestStatus.NeedsReview) {
    return 2
  }

  if (status === DocumentRequestStatus.NeedsReview) {
    return 3
  }

  if (status === DocumentRequestStatus.Accepted) {
    return 4
  }

  return 5
}
