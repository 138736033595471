import React, { FunctionComponent } from 'react'

import { DashboardPhase } from './phase_map'
import Paragraph from 'components/type/paragraph'

interface Props {
  currentPhase: DashboardPhase
}

const SHOW_FOR_PHASES: DashboardPhase[] = ['CUSTOMER_REVIEW']

const DocumentUploadPanel: FunctionComponent<Props> = ({ currentPhase }) => {
  if (SHOW_FOR_PHASES.indexOf(currentPhase) === -1) {
    return null
  }

  return (
    <div className="o-griddle__col--12 o-griddle__col--md-6 o-griddle__col--lg-12 o-block">
      <aside className="c-dashboard__panel">
        <header className="o-block o-block--compact c-type c-type--headline-sm c-type--emphasized">
          Your Documents
        </header>
        <hr className="o-block c-divider" />
        <Paragraph>
          <a href="/document_upload">Upload Documents</a>
        </Paragraph>
      </aside>
    </div>
  )
}

export default DocumentUploadPanel
