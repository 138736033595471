import React, { FunctionComponent } from 'react'

import BoundlessRVLogo from 'images/rvpp_logo@2x.png'
import RapidVisaPreview from 'images/rapidvisa-application-preview@2x.png'
import LetterIllustration from 'components/illustrations/letter_illustration'
import Heading from 'components/type/heading'
import Paragraph from 'components/type/paragraph'
import GreenCircleCheckmarkIcon from 'components/icons/green_circle_checkmark_icon'
import GoToRapidVisaLink from './go_to_rapid_visa_link'

interface ExplicitProps {
  email: string
  isMobileBrowser: boolean
}

const EligibilityMarketingInfo: FunctionComponent<ExplicitProps> = ({ email, isMobileBrowser }) => {
  const infoList = () => {
    return (
      <ul className={`c-list ${isMobileBrowser ? 'mb-8' : ''}`}>
        <li className="py-4">
          <span className="flex">
            <GreenCircleCheckmarkIcon className="mr-4" />
            <span className="c-type c-type--body-sans-md">See how easy our software makes filling out the forms</span>
          </span>
        </li>
        <li className="py-4">
          <span className="flex">
            <GreenCircleCheckmarkIcon className="mr-4" />
            <span className="c-type c-type--body-sans-md">No obligation to pay, fill out as much as you want</span>
          </span>
        </li>
        <li className="py-4">
          <span className="flex">
            <GreenCircleCheckmarkIcon className="mr-4" />
            <span className="c-type c-type--body-sans-md">Get help along the way with live chat or phone support</span>
          </span>
        </li>
      </ul>
    )
  }

  const renderMobile = () => {
    return (
      <div className="bg-white">
        <div className="flex justify-center mb-4 pt-6">
          <img src={BoundlessRVLogo} alt="RapidVisa Boundless logo" style={{ width: 253 }} />
        </div>
        <div className="flex flex-col p-8 items-center">
          <Heading tag="h3" color="emphasized">
            Try our exclusive service by starting your online application for free
          </Heading>
          {infoList()}

          <GoToRapidVisaLink className="c-link font-bold mb-12 ml-9 self-start c-type c-type--body-sans-md">
            Start applying for free
          </GoToRapidVisaLink>
          <img
            src={RapidVisaPreview}
            alt="RapidVisa application preview mac and iphone"
            className="w-full mb-8 pb-6 border-b border-gray-300"
          />
          <LetterIllustration />
          <Heading tag="h2" subhead color="emphasized" centered className="mt-6">
            This service can’t be found anywhere else
          </Heading>
          <Paragraph centered className="mb-8">
            We sent a unique link to <span className="font-bold">{email}</span> to continue your application later: you
            won’t be able to find this service on RapidVisa's homepage.
          </Paragraph>
        </div>
      </div>
    )
  }

  const renderDesktop = () => {
    return (
      <div className="bg-white p-16">
        <div className="flex flex-col items-center mb-16">
          <img src={BoundlessRVLogo} alt="RapidVisa Boundless logo" style={{ width: 407 }} />
        </div>
        <div className="flex justify-center">
          <div className="flex w-3/4">
            <div className="mr-8">
              <Heading tag="h3" color="emphasized">
                Try our exclusive service by starting your online application for free
              </Heading>
              {infoList()}

              <GoToRapidVisaLink className="c-link font-bold mt-4 ml-9 o-block c-type c-type--body-sans-md">
                Start applying for free
              </GoToRapidVisaLink>
            </div>
            <div className="pl-8 pt-4 border-l border-gray-300">
              <img src={RapidVisaPreview} alt="RapidVisa application preview mac and iphone" className="w-full mb-3" />
              <div>
                <Heading tag="h2" subhead color="emphasized" centered>
                  This service can’t be found anywhere else
                </Heading>
                <div className="flex">
                  <span className="mr-4">
                    <LetterIllustration />
                  </span>
                  <Paragraph>
                    We sent a unique link to <span className="font-bold">{email}</span> to continue your application
                    later: you won’t be able to find this service on RapidVisa's homepage.
                  </Paragraph>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return isMobileBrowser ? renderMobile() : renderDesktop()
}

export default EligibilityMarketingInfo as FunctionComponent<ExplicitProps>
