import React, { FunctionComponent } from 'react'
import IconWrapper, { IconWrapperExternalProps } from './icon_wrapper'

const UploadIcon: FunctionComponent<IconWrapperExternalProps> = (props) => (
  <IconWrapper {...props} viewBox="0 0 20 20" style={{ fill: 'none' }}>
    <path d="M14.4004 7.39978L10.0004 2.99978L5.60039 7.39978" strokeWidth="1.2" strokeLinecap="square" />
    <path d="M10 3.59958L10 13.1996" strokeWidth="1.2" strokeLinecap="square" />
    <path d="M17 17H3" strokeWidth="1.2" />
  </IconWrapper>
)

export default UploadIcon
