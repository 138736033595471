import React, { FunctionComponent } from 'react'
import Hint from 'components/hint'
import Paragraph from 'components/type/paragraph'

export const NewMessagesHint: FunctionComponent = () => {
  return (
    <Hint variant="primary">
      <Paragraph>
        <span className="font-bold o-block o-block--tight">We added updates that need your attention</span>
        We just need to follow up on some of your answers. Please read each{' '}
        <span className="font-bold">New Message</span> below, and reply to all of them before sending your responses
        back to Boundless.
      </Paragraph>
    </Hint>
  )
}

export default NewMessagesHint
