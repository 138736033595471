import MarriageBasedGreenCard from './marriage_based_green_card'
import Naturalization from './naturalization'
import NewMarriageBasedGreenCard from './new_marriage_based_green_card'
import PanelConditions, { PanelConditionFunction } from './panels'

export type ConditionFunction = (data: any, field: any) => boolean

export interface ConditionMap {
  [name: string]: ConditionFunction
}

const allConditions = {
  MarriageBasedGreenCard,
  Naturalization,
  NewMarriageBasedGreenCard
}

export function getCondition(conditionName: string): Nullable<ConditionFunction> {
  if (!conditionName) return null

  const [kind, name] = conditionName.split('.')
  const conditions = allConditions[kind]

  if (!conditions) return null

  return conditions[name] || null
}

export function getPanelCondition(name: string): Nullable<PanelConditionFunction> {
  return PanelConditions[name]
}
