import React, { FunctionComponent } from 'react'
import IconWrapper, { IconWrapperExternalProps } from './icon_wrapper'

const GreenCircleCheckmarkIcon: FunctionComponent<IconWrapperExternalProps> = (props) => (
  <IconWrapper viewBox="0 0 24 24" {...props} color="#168753">
    <circle cx="9" cy="9" r="9" fill="#168753" />
    <path d="M4.5 9L7.5 12L13.5 6" stroke="white" strokeWidth="1.5" />
  </IconWrapper>
)

export default GreenCircleCheckmarkIcon
