import React, { FunctionComponent } from 'react'
import EmptyCircleIcon from 'components/icons/empty_circle_icon'
import Paragraph from 'components/type/paragraph'
import Button from 'components/button'

interface Props {
  openConfirmationModal: () => void
  lawyerName?: string
  allSectionsFinished?: boolean
}

const StartAssessmentPanel: FunctionComponent<Props> = ({ openConfirmationModal, lawyerName, allSectionsFinished }) => (
  <div className="o-block" style={{ opacity: allSectionsFinished ? 1 : 0.6 }}>
    {!allSectionsFinished && (
      <Paragraph>Please finish the above questionnaires so Attorney Assessment can begin.</Paragraph>
    )}

    <div className="o-block o-griddle__row o-griddle__row--no-gutters o-griddle__row--align-items-center">
      <div className="o-griddle__col--auto py-3">
        <EmptyCircleIcon className="o-media__figure mr-3" />
      </div>
      <div className="o-griddle__col">
        <h3 className="c-type--inline c-type--body-sans-md py-3">Attorney Assessment</h3>
      </div>
      <div className="o-griddle__col--12 o-griddle__col--auto-sm-up py-1">
        <Button
          color="primary"
          onClick={openConfirmationModal}
          disabled={!allSectionsFinished}
          label="Begin Assessment"
        />
      </div>
    </div>

    {allSectionsFinished && (
      <Paragraph>
        Your independent lawyer {lawyerName && `${lawyerName} `}will look over your info and determine how best to work
        together to successfully complete the rest of your application.{' '}
        <a href="https://www.boundless.com/what-you-get-with-boundless/" target="_blank" rel="noopener noreferrer">
          See everything you get with Boundless.
        </a>
      </Paragraph>
    )}
  </div>
)

export default StartAssessmentPanel
