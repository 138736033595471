import React from 'react'
import { connect } from 'react-redux'
import _isFunction from 'lodash/isFunction'

import Address from 'components/forms/inputs/address'
import AdmissionClasses from 'components/forms/inputs/admission_classes'
import Checkbox from 'components/forms/inputs/checkbox'
import Excuser from 'components/forms/inputs/checkbox/excuser'
import CollectionCheckboxes from 'components/forms/inputs/collection_checkboxes'
import CollectionQualifier from 'components/forms/inputs/collection_qualifier'
import Country from 'components/forms/inputs/country/index'
import Currency from 'components/forms/inputs/currency'
import DateInput from 'components/forms/inputs/date_input'
import DictionaryCheckboxes from 'components/forms/inputs/dictionary_checkboxes'
import Email from 'components/forms/inputs/email'
import HeightInputContainer from 'components/forms/inputs/height'
import MetricToggle from 'components/forms/inputs/metric_toggle'
import Number from 'components/forms/inputs/number'
import Phone from 'components/forms/inputs/phone'
import Qualifier from 'components/forms/inputs/qualifier'
import PaperFormSelect from 'components/forms/inputs/paper_form_select'
import Radio from 'components/forms/inputs/radio'
import SmartCollectionQualifier from 'components/forms/inputs/smart_collection_qualifier'
import SpecializedIncomeQuestionRadio from 'components/forms/inputs/specialized/income_question_radio'
import SocialSecurityNumber from 'components/forms/inputs/ssn'
import TextInputContainer from 'components/forms/inputs/text'
import TextareaInput from 'components/forms/inputs/text_area'
import USStateInput from 'components/forms/inputs/address/us_state_input'
import UnitInfo from 'components/forms/inputs/unit_info'
import WeightInputContainer from 'components/forms/inputs/weight'

import t from 'lib/update_transformations'
import { saveData } from 'actions/kase_actions'
import { getModelValue } from 'reducers/selectors'

import { QuestionnaireInputContainerProps } from './inputs'
import { FieldIndexType } from 'components/forms/field/index'

interface ComplexValueType {
  [objectKey: string]: any
}

type ValueType = boolean | number | string | ComplexValueType | null

interface QuestionField {
  disabled?: boolean
  document_type?: string // TODO deprecate with v2 document system
  hidden: boolean
  name: string
  path: string
  type: string
}

interface MappedProps {
  value?: ValueType
}

type Props = MappedProps & {
  field: QuestionField
  panelIndex: Nullable<number> | Nullable<string>
  currentIndex: FieldIndexType
  parentIndex: FieldIndexType
  resourceKeys: string[]
  saveData: Function
}

const componentMap = {
  address: Address,
  admission_classes: AdmissionClasses,
  collection_checkboxes: CollectionCheckboxes,
  collection_qualifier: CollectionQualifier,
  country: Country,
  currency: Currency,
  date: DateInput,
  dictionary_checkboxes: DictionaryCheckboxes,
  email: Email,
  excuser: Excuser,
  checkbox: Checkbox,
  height: HeightInputContainer,
  metric_toggle: MetricToggle,
  number: Number,
  qualifier: Qualifier,
  radio: Radio,
  select: PaperFormSelect,
  smart_collection_qualifier: SmartCollectionQualifier,
  specialized_income_question_radio: SpecializedIncomeQuestionRadio,
  ssn: SocialSecurityNumber,
  state: USStateInput,
  tel: Phone,
  textarea: TextareaInput,
  unit_info: UnitInfo,
  weight: WeightInputContainer
}

function getComponent(field: QuestionField): React.ReactNode {
  return componentMap[field.type] || TextInputContainer
}

function buildQuestionnaireInputContainerProps(field: any): QuestionnaireInputContainerProps {
  let { required } = field

  if (field.disabled) {
    required = false
  }

  required = Boolean(required)

  const props = {
    ...field,
    id: field.name.replace(/_/g, '-'),
    required
  }

  return props
}

class Field extends React.Component<Props> {
  handleChange = (field) => (operationOrValue) => {
    let operation

    if (_isFunction(operationOrValue)) {
      operation = operationOrValue
    } else {
      const value = operationOrValue
      operation = t.replaceValue(value)
    }

    this.props.saveData({ path: field.path, operation })
  }

  render() {
    const { field, panelIndex, currentIndex, parentIndex, resourceKeys, value } = this.props

    const inputProps = buildQuestionnaireInputContainerProps(field)
    const InputComponent = getComponent(field)

    return (
      <InputComponent
        onChange={this.handleChange(field)}
        panelIndex={panelIndex}
        currentIndex={currentIndex}
        parentIndex={parentIndex}
        resourceKeys={resourceKeys}
        value={value}
        {...inputProps}
      />
    )
  }
}

function mapStateToProps(state, ownProps): MappedProps {
  return {
    value: getModelValue(state, ownProps.field.path)
  }
}

function mapDispatchToProps(dispatch) {
  return {
    saveData: (...args) => dispatch(saveData(...args))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Field)
