import React, { FunctionComponent } from 'react'
import ReactMarkdown from 'react-markdown'

import { QuestionnaireInputContainerProps, InputOption } from '../lib/types'

type Props = QuestionnaireInputContainerProps & {
  options: InputOption[]
}

const CheckboxGroup: FunctionComponent<Props> = ({ disabled, id, options, onChangeEvent, value }) => {
  /**
   * Since the value represents a group of checked checkboxes, building an
   * array of all the selected checkboxes in the group to send back as the
   * event.target.value when a single checkbox is checked/unchecked.
   * @param event
   */
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const clicked = event.target.value
    value = value ? value.split(',') : []
    if (value.includes(clicked)) {
      event.target.value = value.filter((i: string) => i !== clicked)
    } else {
      event.target.value = value.concat([clicked])
    }
    onChangeEvent(event)
  }

  return (
    <div className="o-block c-paper-form__group">
      {options.map((option) => {
        return (
          <div className="o-block" key={option.key}>
            <label className="o-block c-custom-control c-custom-control--check">
              <input
                type="checkbox"
                checked={value.includes(option.key)}
                className="c-custom-control__input"
                id={`form-${id}-${option.key}`}
                disabled={disabled}
                name={id}
                value={option.key}
                onChange={handleChange}
              />

              <span className="c-custom-control__indicator" />

              <div className="c-custom-control__description c-type c-type--primary c-type--body-sans-md c-color--black">
                <ReactMarkdown source={option.value} />
              </div>
            </label>
          </div>
        )
      })}
    </div>
  )
}

export default CheckboxGroup
