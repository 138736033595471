import React, { FunctionComponent, HTMLAttributes } from 'react'
import cx from 'classnames'
import { TYPE_SPACING_MAP } from './settings'

export type ParagraphProps = HTMLAttributes<HTMLParagraphElement> & {
  /**
   * The color of the text.
   */
  color?: 'primary' | 'warning' | 'success' | 'accent' | 'emphasized'
  /**
   * The relative size of the text.
   * Note: we do not have a size="lg" version of our serif font.
   */
  size?: 'lg' | 'md' | 'sm'
  /**
   * If true, the text will use our Serif font.
   * Note: we do not have a size="lg" version of our serif font.
   */
  serif?: boolean
  /**
   * If true, the text will be bolded.
   */
  bold?: boolean
  /**
   * The amount of space below the element. This space is canceled out if the
   * element is the last o-block in a o-box.
   */
  spacing?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'none'
  /**
   * If true, the text will be italicized.
   */
  italic?: boolean
  /**
   * If true, the text will be centered horizontally.
   */
  centered?: boolean
}

const Paragraph: FunctionComponent<ParagraphProps> = (props) => {
  const { className, children, color, size = 'md', serif, bold, spacing, italic, centered, ...otherProps } = props

  const spacingClassName = TYPE_SPACING_MAP[spacing]

  const classes = cx('c-type', 'o-block', {
    [className]: !!className,
    'c-type--body-sans-lg': size === 'lg' && !serif,
    'c-type--body-sans-md': size === 'md' && !serif,
    'c-type--body-sans-sm': size === 'sm' && !serif,
    'c-type--body-serif-md': serif && (size === 'md' || size === 'lg'),
    'c-type--body-serif-sm': serif && size === 'sm',
    'c-type--bold': bold,
    'c-type--italic': italic,
    'o-layout--center': centered,
    't-color--primary': color === 'primary',
    't-color--success': color === 'success',
    't-color--warning': color === 'warning',
    't-color--accent': color === 'accent',
    'c-type--emphasized': color === 'emphasized',
    [`o-block--${spacingClassName}`]: !!spacingClassName
  })

  return (
    <p className={classes} {...otherProps}>
      {children}
    </p>
  )
}

export default Paragraph
