import { AxiosError } from 'axios'
import jwtStorage from './jwt_storage'

export function handleSessionExpirationError(error: AxiosError): any {
  // 401 - Unauthorized (when the user has no session, meaning they are logged out)
  // 422 - When you try to use an old CSRF/Session (which happens after Devise destroys the User session)
  if (
    error.response &&
    // The 422 makes a Cypress test fail.
    // (error.response.status === 440 || error.response.status === 401 || error.response.status === 422)
    (error.response.status === 440 || error.response.status === 401)
  ) {
    // Clear local storage of JWT
    jwtStorage.removeToken()

    // Redirect to login page in case of HTTP expired status
    window.location.href = '/users/sign_in?expired=true'

    return Promise.resolve()
  }

  return Promise.reject(error)
}
