import React from 'react'
import { connect } from 'react-redux'
import formatMoney from 'lib/format_money'
import moment from 'moment-timezone'

import { MBGC_GOVT_FILING_FEES, MBGC_TIMELINES, MBGC_NUM_DAYS_BEFORE_EARLIEST_POSSIBLE_TRAVEL } from 'lib/constants'

import {
  getAccountHolderRole,
  getCurrentApplicationFeeInCents,
  getCurrentContentSource,
  isBeneficiaryNotInUS,
  sponsorIsCitizen
} from 'reducers/selectors'

import { minimumIncomeRequirement } from 'resource_context/marriage_based_green_card/helpers'
import EligibilityReportInfoCell from 'components/panels/outcomes/setup_completed_eligibility_report_info_cell'

interface Props {
  accountHolderRole: 'sponsor' | 'beneficiary'
  beneficiaryNotInUS: boolean
  contentSource: 'timeline' | 'income' | null
  currentApplicationFeeInCents: number
  minIncome: string
  screen: 'desktop' | 'mobile'
  sponsorIsCitizen: boolean
}

class EligibilityReportInfo extends React.Component<Props> {
  contentSourceIsDefault() {
    return !this.props.contentSource
  }

  contentSourceIsTimeline() {
    return this.props.contentSource === 'timeline'
  }

  contentSourceIsIncome() {
    return this.props.contentSource === 'income'
  }

  sponsorCitizen() {
    return this.props.sponsorIsCitizen
  }

  sponsorLPR() {
    return !this.props.sponsorIsCitizen
  }

  beneficiaryInUS() {
    return !this.props.beneficiaryNotInUS
  }

  beneficiaryAbroad() {
    return this.props.beneficiaryNotInUS
  }

  sponsorLPRBeneficiaryInUS() {
    return this.sponsorLPR() && this.beneficiaryInUS()
  }

  renderProcessingTime() {
    let processingTimes: any

    if (this.sponsorCitizen() && this.beneficiaryAbroad()) {
      processingTimes = MBGC_TIMELINES.SPONSOR_USC_BENEFICIARY_ABROAD
    } else if (this.sponsorCitizen() && this.beneficiaryInUS()) {
      processingTimes = MBGC_TIMELINES.SPONSOR_USC_BENEFICIARY_IN_US
    } else if (this.sponsorLPR() && this.beneficiaryAbroad()) {
      processingTimes = MBGC_TIMELINES.SPONSOR_LPR_BENEFICIARY_ABROAD
    } else {
      processingTimes = MBGC_TIMELINES.SPONSOR_LPR_BENEFICIARY_IN_US
    }

    return `${processingTimes.minimumMonths}–${processingTimes.maximumMonths} months`
  }

  renderBoundlessProcessingTime() {
    return '5–6 weeks'
  }

  renderEarliestTravelTime() {
    if (this.beneficiaryAbroad()) {
      return null
    }

    const waitTime = this.sponsorCitizen()
      ? MBGC_NUM_DAYS_BEFORE_EARLIEST_POSSIBLE_TRAVEL.SPONSOR_USC
      : MBGC_NUM_DAYS_BEFORE_EARLIEST_POSSIBLE_TRAVEL.SPONSOR_LPR
    const earliestTravelDate = moment().add(waitTime, 'days').format('MM/YYYY')

    let earliestTravelText = ''
    if (this.sponsorCitizen() && this.beneficiaryInUS()) {
      earliestTravelText = `Cannot travel before ${earliestTravelDate}`
    } else if (this.sponsorLPR() && this.beneficiaryInUS()) {
      earliestTravelText = `Your wait time may be shortened to ${MBGC_TIMELINES.SPONSOR_USC_BENEFICIARY_IN_US.minimumMonths} months`
    }

    return earliestTravelText
  }

  renderEarliestTravelTimeHeader() {
    if (this.beneficiaryAbroad()) {
      return null
    }

    if (this.sponsorCitizen() && this.beneficiaryInUS()) {
      return 'Travel Restrictions'
    } else if (this.sponsorLPR() && this.beneficiaryInUS()) {
      return 'Tip'
    }
  }

  renderTimelineSecondLinkText() {
    const { accountHolderRole } = this.props

    if (this.sponsorCitizen() && this.beneficiaryInUS()) {
      return 'Why travel restrictions?'
    } else if (this.sponsorLPR() && this.beneficiaryInUS()) {
      return 'How does this work?'
    } else {
      return accountHolderRole === 'sponsor'
        ? 'Can my spouse come to the US sooner?'
        : 'Can I visit my spouse in the US sooner?'
    }
  }

  renderGovtFilingFees() {
    const fees = this.beneficiaryAbroad()
      ? MBGC_GOVT_FILING_FEES.BENEFICIARY_ABROAD
      : MBGC_GOVT_FILING_FEES.BENEFICIARY_IN_US

    return '~' + formatMoney(fees)
  }

  renderBoundlessPrice() {
    return formatMoney(this.props.currentApplicationFeeInCents)
  }

  renderInstallmentAmount() {
    const monthlyEstimateInCents = Math.ceil(this.props.currentApplicationFeeInCents / 1200) * 100
    return formatMoney(monthlyEstimateInCents)
  }

  renderMinIncome() {
    return '$' + this.props.minIncome
  }

  renderDesktopDefault() {
    return (
      <React.Fragment>
        <div className="o-grid--fluid o-block o-block--ample">
          <div className="o-grid__cell--3/12" />
          <div className="o-grid__cell--4/12">
            <EligibilityReportInfoCell
              header="Boundless Price"
              headerId="header-1-desktop"
              content={this.renderBoundlessPrice()}
              contentId="info-1-desktop"
              subtitle={`Or as low as ${this.renderInstallmentAmount()}/mo for 12 mos`}
              screen={this.props.screen}
            />
          </div>
          <div className="o-grid__cell--1/12" />
          <div className="o-grid__cell--4/12">
            <EligibilityReportInfoCell
              header="File With Boundless In"
              headerId="header-2-desktop"
              content={this.renderBoundlessProcessingTime()}
              contentId="info-2-desktop"
              subtitle="After we get your documents"
              screen={this.props.screen}
            />
          </div>
        </div>
        <div className="o-grid--fluid o-block o-block--ample">
          <div className="o-grid__cell--3/12" />
          <div className="o-grid__cell--4/12">
            <EligibilityReportInfoCell
              header="Gov't Fees Due At Filing"
              headerId="header-1-desktop"
              content={this.renderGovtFilingFees()}
              contentId="info-3-desktop"
              hasLink
              linkHref="/content/cost-mbgc"
              linkText="How this is calculated"
              screen={this.props.screen}
            />
          </div>
          <div className="o-grid__cell--1/12" />
          <div className="o-grid__cell--4/12">
            <EligibilityReportInfoCell
              header="Wait Time After Filing"
              headerId="header-2-desktop"
              content={this.renderProcessingTime()}
              contentId="info-4-desktop"
              hasLink
              linkHref="/content/how-long-mbgc"
              linkText="How this is calculated"
              screen={this.props.screen}
            />
          </div>
        </div>
      </React.Fragment>
    )
  }

  renderDesktopIncome() {
    return (
      <React.Fragment>
        <div className="o-grid--fluid o-block o-block--ample">
          <div className="o-grid__cell--3/12" />
          <div className="o-grid__cell--4/12">
            <EligibilityReportInfoCell
              header="File With Boundless In"
              headerId="header-1-desktop"
              content={this.renderBoundlessProcessingTime()}
              contentId="info-1-desktop"
              subtitle="After we get your documents"
              screen={this.props.screen}
            />
          </div>
          <div className="o-grid__cell--1/12" />
          <div className="o-grid__cell--4/12">
            <EligibilityReportInfoCell
              header="Boundless Price"
              headerId="header-2-desktop"
              content={this.renderBoundlessPrice()}
              contentId="info-2-desktop"
              subtitle="Or as low as $79/mo for 12 mos"
              screen={this.props.screen}
            />
          </div>
        </div>
        <div className="o-grid--fluid o-block o-block--ample">
          <div className="o-grid__cell--3/12" />
          <div className="o-grid__cell--4/12">
            <EligibilityReportInfoCell
              header="Minimum Income"
              headerId="header-3-desktop"
              content={this.renderMinIncome()}
              contentId="info-3-desktop"
              hasLink={true}
              linkHref="/content/income-reqs-mbgc"
              linkText="How this is calculated"
              linkOpensChat={false}
              screen={this.props.screen}
            />
          </div>
          <div className="o-grid__cell--1/12" />
          <div className="o-grid__cell--4/12">
            <EligibilityReportInfoCell
              header="Gov't Fees Due At Filing"
              headerId="header-4-desktop"
              content={this.renderGovtFilingFees()}
              contentId="info-4-desktop"
              hasLink={true}
              linkHref="/content/cost-mbgc"
              linkText="How this is calculated"
              linkOpensChat={false}
              screen={this.props.screen}
            />
          </div>
        </div>
      </React.Fragment>
    )
  }

  renderDesktopTimeline() {
    return (
      <React.Fragment>
        <div className="o-grid--fluid o-block o-block--ample">
          <div className="o-grid__cell--3/12" />
          <div className="o-grid__cell--4/12">
            <EligibilityReportInfoCell
              header="File With Boundless In"
              headerId="header-1-desktop"
              content={this.renderBoundlessProcessingTime()}
              contentId="info-1-desktop"
              subtitle="After we get your documents"
              screen={this.props.screen}
            />
          </div>
          <div className="o-grid__cell--1/12" />
          <div className="o-grid__cell--4/12">
            <EligibilityReportInfoCell
              header="Boundless Price"
              headerId="header-2-desktop"
              content={this.renderBoundlessPrice()}
              contentId="info-2-desktop"
              subtitle="Or as low as $79/mo for 12 mos"
              screen={this.props.screen}
            />
          </div>
        </div>
        <div className="o-grid--fluid o-block o-block--ample">
          <div className="o-grid__cell--3/12" />
          <div className="o-grid__cell--4/12">
            <EligibilityReportInfoCell
              header="Wait Time After Filing"
              headerId="header-3-desktop"
              content={this.renderProcessingTime()}
              contentId="info-3-desktop"
              hasLink={true}
              linkHref="/content/how-long-mbgc"
              linkText="How this is calculated"
              linkOpensChat={false}
              screen={this.props.screen}
            />
          </div>
          <div className="o-grid__cell--1/12" />
          <div className="o-grid__cell--4/12">
            {this.beneficiaryInUS() && (
              <EligibilityReportInfoCell
                header={this.renderEarliestTravelTimeHeader()}
                headerId="header-4-desktop"
                content={this.renderEarliestTravelTime()}
                contentId="info-4-desktop"
                hasLink={true}
                linkHref="/content/advance-parole-explained"
                linkText={this.renderTimelineSecondLinkText()}
                linkOpensChat={this.sponsorLPRBeneficiaryInUS()}
                screen={this.props.screen}
                smallContent={this.sponsorLPRBeneficiaryInUS()}
              />
            )}
          </div>
        </div>
      </React.Fragment>
    )
  }

  renderMobileDefault() {
    return (
      <div className="o-grid__cell">
        <div className="o-block o-block--spacious">
          <EligibilityReportInfoCell
            header="Boundless Price"
            content={this.renderBoundlessPrice()}
            screen={this.props.screen}
            subtitle="Or as low as $79/mo for 12 mos"
          />
        </div>
        <div className="o-block o-block--spacious">
          <EligibilityReportInfoCell
            header="Gov't Filing Fees"
            content={this.renderGovtFilingFees()}
            hasLink
            linkHref="/content/cost-mbgc"
            linkText="How this is calculated"
            screen={this.props.screen}
          />
        </div>
        <div className="o-block o-block--spacious">
          <EligibilityReportInfoCell
            header="File With Boundless In"
            content={this.renderBoundlessProcessingTime()}
            screen={this.props.screen}
            subtitle="After we get your documents"
          />
        </div>
        <div className="o-block o-block--spacious">
          <EligibilityReportInfoCell
            header="Gov't Wait Time After Filing"
            content={this.renderProcessingTime()}
            hasLink
            linkHref="/content/how-long-mbgc"
            linkText="How this is calculated"
            screen={this.props.screen}
          />
        </div>
      </div>
    )
  }

  renderMobileIncome() {
    return (
      <div className="o-grid__cell">
        <div className="o-block o-block--spacious">
          <EligibilityReportInfoCell
            header="Boundless Price"
            content={this.renderBoundlessPrice()}
            screen={this.props.screen}
            subtitle="Or as low as $79/mo for 12 mos"
          />
        </div>
        <div className="o-block o-block--spacious">
          <EligibilityReportInfoCell
            header="Gov't Filing Fees"
            content={this.renderGovtFilingFees()}
            hasLink
            linkHref="/content/cost-mbgc"
            linkText="How this is calculated"
            screen={this.props.screen}
          />
        </div>
        <div className="o-block o-block--spacious">
          <EligibilityReportInfoCell
            header="Minimum Income"
            content={this.renderMinIncome()}
            hasLink
            linkHref="/content/income-reqs-mbgc"
            linkText="How this is calculated"
            screen={this.props.screen}
          />
        </div>
        <div className="o-block o-block--spacious">
          <EligibilityReportInfoCell
            header="File With Boundless In"
            content={this.renderBoundlessProcessingTime()}
            screen={this.props.screen}
            subtitle="After we get your documents"
          />
        </div>
      </div>
    )
  }

  renderMobileTimeline() {
    return (
      <div className="o-grid__cell">
        <div className="o-block o-block--spacious">
          <EligibilityReportInfoCell
            header="Boundless Price"
            content={this.renderBoundlessPrice()}
            screen={this.props.screen}
            subtitle="Or as low as $79/mo for 12 mos"
          />
        </div>
        <div className="o-block o-block--spacious">
          <EligibilityReportInfoCell
            header="File With Boundless In"
            content={this.renderBoundlessProcessingTime()}
            screen={this.props.screen}
            subtitle="After we get your documents"
          />
        </div>
        <div className="o-block o-block--spacious">
          <EligibilityReportInfoCell
            header="Gov't Wait Time After Filing"
            content={this.renderProcessingTime()}
            hasLink
            linkHref="/content/how-long-mbgc"
            linkText="How this is calculated"
            screen={this.props.screen}
          />
        </div>
        <div className="o-block o-block--spacious">
          {this.beneficiaryInUS() && (
            <EligibilityReportInfoCell
              header={this.renderEarliestTravelTimeHeader()}
              content={this.renderEarliestTravelTime()}
              hasLink
              linkHref="/content/advance-parole-explained"
              linkText={this.renderTimelineSecondLinkText()}
              linkOpensChat={this.sponsorLPRBeneficiaryInUS()}
              screen={this.props.screen}
              smallContent={this.sponsorLPRBeneficiaryInUS()}
            />
          )}
        </div>
      </div>
    )
  }

  renderDesktop() {
    if (this.contentSourceIsDefault()) {
      return this.renderDesktopDefault()
    }

    if (this.contentSourceIsIncome()) {
      return this.renderDesktopIncome()
    }

    if (this.contentSourceIsTimeline()) {
      return this.renderDesktopTimeline()
    }
  }

  renderMobile() {
    if (this.contentSourceIsDefault()) {
      return this.renderMobileDefault()
    }

    if (this.contentSourceIsIncome()) {
      return this.renderMobileIncome()
    }

    if (this.contentSourceIsTimeline()) {
      return this.renderMobileTimeline()
    }
  }

  render() {
    const isDesktop = this.props.screen === 'desktop'

    if (isDesktop) {
      return this.renderDesktop()
    } else {
      return this.renderMobile()
    }
  }
}

function mapStateToProps(state) {
  return {
    accountHolderRole: getAccountHolderRole(state),
    beneficiaryNotInUS: isBeneficiaryNotInUS(state),
    currentApplicationFeeInCents: getCurrentApplicationFeeInCents(state),
    sponsorIsCitizen: sponsorIsCitizen(state),
    contentSource: getCurrentContentSource(state),
    minIncome: minimumIncomeRequirement(state)
  }
}

export default connect(mapStateToProps)(EligibilityReportInfo)
