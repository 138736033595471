import React from 'react'

import Interstitial from 'components/panels/interstitial'

export const UscisQuestionnaireStart = () => (
  <Interstitial
    headline={'Some of the following questions may seem strange.'}
    subtitle={
      "However, USCIS requires them on every application. Answer them to the best of your ability and if you're unsure, please select yes and then explain your situation."
    }
    image="setup-start"
  />
)

export default UscisQuestionnaireStart
