import { ConditionMap } from './'

const conditions: ConditionMap = {}

function notIndicated(value: Nullable<string>): boolean {
  return value !== 'yes'
}

conditions.hasNoGreenCard = (data) => Boolean(data.eligibility.no_green_card)

conditions.spouseCitizenSinceBirth = (data) => Boolean(data.applicant.current_marriage.spouse.citizen_since_birth)

conditions.hasNotIndicatedEmployment = (data, field) => {
  const hasEmployment = data.ui.applicant_has_employments[field.currentIndex || 0]

  return notIndicated(hasEmployment)
}

conditions.viewingFirstTripPanel = (data, field) => field.currentIndex === 0

conditions.currentTripIsNotIndicated = (data, field) => {
  if (field.currentIndex === 0) return false

  const hasTrip = data.applicant.travel_history.ui_has_trips[field.currentIndex]

  return notIndicated(hasTrip)
}

conditions.applicantHasNotIndicatedOtherMembers = (data) => {
  return data.household.has_other_members !== 'yes'
}

export default conditions
