import React from 'react'
import { connect } from 'react-redux'

import Section from './section'
import SectionEntry from './section_entry'

import datedEntryLabel from './dated_entry_label'

import { Role } from 'components/forms/inputs/index'

import {
  areSponsorAndBeneficiaryLivingTogether,
  beneficiaryHasInternationalAddressHistory,
  getAccountHolderRole,
  getModelValue
} from 'reducers/selectors'

interface Props {
  accountHolderRole: string
  beneficiaryHasInternationalAddressHistory: boolean
  addresses: any[]
  livingTogether: boolean
  personFirstName: string
  outsideAddress: any
  role: Role
  togetherAddress: any
}

class AddressHistory extends React.Component<Props> {
  renderAddressHistoryEntry = (address, i) => {
    const isCurrentAddress = i === 0
    const label = datedEntryLabel(address, { current: isCurrentAddress })

    let editPath

    if (isCurrentAddress) {
      editPath = `${this.props.role}-current-address`
    } else {
      editPath = `${this.props.role}-previous-address/${i}`
    }

    return <SectionEntry key={i} label={label} value={address} editHref={editPath} />
  }

  renderAddress(address, editHref, labelOverride = null) {
    const label = labelOverride || datedEntryLabel(address)

    return <SectionEntry label={label} value={address} editHref={editHref} />
  }

  renderOutsideAddress() {
    const { beneficiaryHasInternationalAddressHistory, outsideAddress, role } = this.props

    if (role !== 'beneficiary') return null

    if (!beneficiaryHasInternationalAddressHistory) {
      return this.renderAddress(outsideAddress, 'beneficiary-outside-address')
    }
  }

  renderTogetherAddress() {
    const { togetherAddress, livingTogether } = this.props

    if (livingTogether) return null

    const labelPrepend = 'Lived Together'

    return this.renderAddress(
      togetherAddress,
      'last-together-address',
      datedEntryLabel(togetherAddress, { prepend: labelPrepend })
    )
  }

  render() {
    const name = this.props.personFirstName || 'Spouse'
    const header =
      this.props.accountHolderRole === this.props.role ? 'Your Address History' : `${name}'s Address History`

    return (
      <Section headerText={header}>
        {this.props.addresses.map(this.renderAddressHistoryEntry)}
        {this.renderTogetherAddress()}
        {this.renderOutsideAddress()}
      </Section>
    )
  }
}

function mapStateToProps(state, props) {
  let outsideAddress
  const togetherAddress = getModelValue(state, 'account.together_address')

  if (props.role === 'beneficiary') {
    outsideAddress = getModelValue(state, 'beneficiary.international_address')
  }

  return {
    accountHolderRole: getAccountHolderRole(state),
    addresses: getModelValue(state, `${props.role}.addresses`),
    beneficiaryHasInternationalAddressHistory: beneficiaryHasInternationalAddressHistory(state),
    livingTogether: areSponsorAndBeneficiaryLivingTogether(state),
    personFirstName: getModelValue(state, `${props.role}.name.first_name`),
    outsideAddress,
    togetherAddress
  }
}

export default connect(mapStateToProps)(AddressHistory)
