import React, { FunctionComponent, ReactNode } from 'react'
import { connect } from 'react-redux'
import { renderToString } from 'react-dom/server'

import Paragraph from 'components/type/paragraph'
import Button from 'components/button'
import { getLawyerInfo, getStewardInfo } from 'reducers/selectors'
import { DASHBOARD_PHASE_BUTTON_TYPE_MAIN_CTA, TELEMETRY_EVENTS } from 'lib/constants'
import { DashboardPhase } from 'components/screens/dashboard/customer_dashboard/phase_map'
import ClockIcon from 'components/icons/clock_icon'
import { TeamMemberInfo } from 'reducers/dashboard'
import { postTelemetry } from 'actions/telemetry_actions'

interface PropsFromRedux {
  lawyerInfo?: TeamMemberInfo
  stewardInfo?: TeamMemberInfo
}

interface ExplicitProps {
  currentPhase: DashboardPhase
  estimatedLength?: string
  header: ReactNode
  description: ReactNode
  primaryCTA?: string | ReactNode
}

interface ActionProps {
  trackClickedPhaseDetails: ({ currentPhase, buttonType }: { currentPhase: string; buttonType: string }) => void
}

type Props = PropsFromRedux & ExplicitProps & ActionProps

const formatTextWithLawyerAndSteward = (lawyer: TeamMemberInfo, steward: TeamMemberInfo, text: ReactNode) => {
  let formattedText = text

  if (typeof text === 'object' && React.isValidElement(text)) {
    formattedText = renderToString(text)
  }

  if (lawyer && lawyer.firstName) {
    formattedText = formattedText.toString().replace(/{{lawyer}}/g, lawyer.firstName)
    formattedText = formattedText.toString().replace(/{{attorney}}/g, lawyer.firstName)
  }

  if (steward && steward.firstName) {
    formattedText = formattedText.toString().replace(/{{steward}}/g, steward.firstName)
  }

  return formattedText.toString()
}

const PhaseInformationTemplate: FunctionComponent<Props> = ({
  currentPhase,
  estimatedLength,
  header,
  description,
  primaryCTA,
  stewardInfo,
  lawyerInfo,
  trackClickedPhaseDetails
}) => {
  const primaryCTAIsLabel = typeof primaryCTA === 'string'

  const onCTAClick = () => {
    trackClickedPhaseDetails({
      currentPhase,
      buttonType: DASHBOARD_PHASE_BUTTON_TYPE_MAIN_CTA
    })
  }

  return (
    <div className="c-phase-details__info">
      <small className="c-type c-type--subhead-sm o-block o-block--compact">The latest</small>
      <header
        className="c-phase-details__header o-block"
        data-qa="phase-message"
        dangerouslySetInnerHTML={{
          __html: formatTextWithLawyerAndSteward(lawyerInfo, stewardInfo, header)
        }}
      />
      <Paragraph
        size="sm"
        data-qa="phase-description"
        dangerouslySetInnerHTML={{
          __html: formatTextWithLawyerAndSteward(lawyerInfo, stewardInfo, description)
        }}
      />
      <div className="c-phase-details__controls">
        <div className="o-griddle__row o-griddle__row--align-items-center o-griddle__row--justify-content-end">
          {estimatedLength && (
            <div className="o-griddle__col o-box">
              <Paragraph size="sm" data-qa="phase-estimated-length">
                <ClockIcon size="small" /> {estimatedLength}
              </Paragraph>
            </div>
          )}
          {primaryCTA && (
            <div className="o-griddle__col--auto">
              {primaryCTAIsLabel ? <Button color="emphasized" label={primaryCTA} onClick={onCTAClick} /> : primaryCTA}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

function mapStateToProps(state: any): PropsFromRedux {
  return {
    lawyerInfo: getLawyerInfo(state),
    stewardInfo: getStewardInfo(state)
  }
}

function mapDispatchToActions(dispatch: Function): ActionProps {
  return {
    trackClickedPhaseDetails: (args) => dispatch(postTelemetry(TELEMETRY_EVENTS.CLICKED_PHASE_DETAILS, args))
  }
}

export default connect<PropsFromRedux, {}, ExplicitProps>(
  mapStateToProps,
  mapDispatchToActions
)(PhaseInformationTemplate) as FunctionComponent<ExplicitProps>
