import {
  UPLOAD_ERROR,
  UPLOAD_FINISHED,
  UPLOADING_REQUESTED_FILES,
  UPLOAD_REQUESTED_FILE_PROGRESS,
  UPLOAD_REQUESTED_FILE_FINISHED
} from 'lib/constants'

import { getDocumentUploadLocation } from 'reducers/selectors'

export function uploadRequestedFiles({ files, documentRequest, documentRequestPartId }) {
  return (dispatch) => {
    dispatch({
      type: UPLOADING_REQUESTED_FILES,
      documentRequest,
      documentRequestPartId,
      files
    })
  }
}

export function uploadRequestedFileProgress({ percent, status, rawFile }) {
  return (dispatch) => {
    dispatch({
      type: UPLOAD_REQUESTED_FILE_PROGRESS,
      progress: percent,
      status,
      rawFile
    })
  }
}

export function uploadError({ rawFile, message }) {
  return (dispatch) => {
    dispatch({
      type: UPLOAD_ERROR,
      rawFile,
      message
    })
  }
}

export function uploadFinished({ rawFile, document }) {
  return (dispatch) => {
    dispatch({
      type: UPLOAD_FINISHED,
      rawFile,
      document
    })
  }
}

export function uploadRequestedFileFinished({ rawFile, uploadingBy, documentType, workflowStatus }) {
  return (dispatch, getState) => {
    dispatch({
      type: UPLOAD_REQUESTED_FILE_FINISHED,
      rawFile,
      documentType,
      uploadingFrom: getDocumentUploadLocation(getState()),
      uploadingBy,
      workflowStatus
    })
  }
}
