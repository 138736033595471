import React, { FunctionComponent, useEffect, useState } from 'react'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import cx from 'classnames'

import { saveQuestionForQuestionnaire } from 'actions/questionnaire_actions'

import CartQuestion from './cart_question'
import Button from 'components/button'
import ArrowIcon from 'components/icons/arrow_icon'
import HelpIcon from 'components/icons/help_icon'

import { PaymentDetermineGovernemntFeeExplicitProps, QuestionMapping } from '../lib/types'
import { CHILD_NUMBERS, QUESTION_MAPPINGS } from '../lib/constants'

interface ActionProps {
  /**
   * Redux action to save questions
   */
  saveQuestionForQuestionnaire: typeof saveQuestionForQuestionnaire
}

type Props = PaymentDetermineGovernemntFeeExplicitProps & ActionProps

const PaymentDetermineGovernmentFees: FunctionComponent<Props> = ({
  extraQuestions,
  handleOpenFeeBreakdownModal,
  handleUpdateChildrenAge,
  isMobile,
  numberOfKids,
  onNextPaymentOptionsBtnClicked,
  saveQuestionForQuestionnaire,
  storedChildrenAgeValues,
  totalUscisFeeRange,
  uscisFeesWithoutBoundlessServiceFee,
  updateAllQuestionsAnswered
}) => {
  const childQuestions: QuestionMapping[] = []
  const [childrenAgeValues, setChildrenAgeValues] = useState(storedChildrenAgeValues)

  const allQuestionsAnswered =
    childrenAgeValues.length === numberOfKids &&
    extraQuestions.every((question) => question.attributes.input_value !== null)

  for (let i = 0; i < numberOfKids; i++) {
    const { label, type, options } = QUESTION_MAPPINGS['age_of_applying_child']
    const newLabel = numberOfKids > 1 ? `How old is your ${CHILD_NUMBERS[i]} applying child?` : label

    childQuestions.push({
      label: newLabel,
      type,
      options
    })
  }

  useEffect(() => {
    updateAllQuestionsAnswered(allQuestionsAnswered)
  }, [allQuestionsAnswered])

  const handleUpdateQuestionnaire = (event: React.ChangeEvent, question) => {
    const target = event.target as HTMLInputElement

    const modifiedAnswerObject = {
      questionId: question.id,
      answer: target.value,
      answerId: question.attributes.answer_id
    }

    saveQuestionForQuestionnaire(modifiedAnswerObject)
  }

  const handleChildCount = (event: React.ChangeEvent) => {
    const target = event.target as HTMLInputElement

    let newTeenCount = 0
    const currentChildIndex = target.name.substring(22)

    const updatedChildrenAgeValues = childrenAgeValues
    updatedChildrenAgeValues[currentChildIndex] = target.value

    updatedChildrenAgeValues.forEach((val) => val === 'teen' && newTeenCount++)

    handleUpdateChildrenAge(newTeenCount, JSON.stringify(updatedChildrenAgeValues))
    setChildrenAgeValues([...updatedChildrenAgeValues])
  }

  return (
    <div className="c-sheet__body border border-default p-4 text-left">
      <div className="flex justify-between my-3">
        <div className="c-type pr-4">
          <h4 className="c-type--body-sans-md c-type--emphasized mb-3">
            Your USCIS fees range from <strong>{totalUscisFeeRange}</strong>. Answer the{' '}
            {extraQuestions.length + childQuestions.length > 1 ? 'questions' : 'question'} below to see your exact fee
            amount.
          </h4>
        </div>
      </div>

      {extraQuestions.map((question, index) => {
        const key = question.attributes.key
        const { label, type, options } = QUESTION_MAPPINGS[key]
        return (
          <div key={key} className={cx({ 'mt-4': index > 0 })}>
            <CartQuestion
              defaultValue={question?.attributes?.input_value}
              inputName={key}
              isMobile={isMobile}
              label={label}
              question={question}
              options={options}
              type={type}
              onChange={(event, question) => handleUpdateQuestionnaire(event, question)}
            />
          </div>
        )
      })}

      {childQuestions.map((childQuestion, index) => {
        let storedChildrenAgeValue = ''
        if (index < storedChildrenAgeValues.length) {
          storedChildrenAgeValue = storedChildrenAgeValues[index]
        }

        return (
          <div key={`age_of_applying_child_${index}`} className="mt-4">
            <CartQuestion
              defaultValue={storedChildrenAgeValue}
              inputName={`age_of_applying_child_${index}`}
              isMobile={isMobile}
              label={childQuestion.label}
              options={childQuestion.options}
              type={childQuestion.type}
              onChange={handleChildCount}
            />
          </div>
        )
      })}

      <div className="mt-6">
        <div className="bg-blue-200-opaque c-type mt-3 text-center">
          {allQuestionsAnswered ? (
            <div className="p-6">
              <>
                <div className="c-type--body-sans-md c-type--emphasized mb-4">
                  Your exact USCIS fees are <strong>${uscisFeesWithoutBoundlessServiceFee.toLocaleString()}</strong>
                </div>
                <a href="" onClick={handleOpenFeeBreakdownModal} title="Fee breakdown">
                  Fee breakdown
                  <HelpIcon />
                </a>
              </>
            </div>
          ) : (
            <div className="c-type--body-sans-sm--inline content-center flex items-center justify-center p-6">
              Your exact government fees are...
            </div>
          )}
        </div>
      </div>

      <div className="flex justify-end mt-4">
        <Button
          block
          id="next-payment-options-button"
          color="primary"
          onClick={onNextPaymentOptionsBtnClicked}
          label="Next: Payment Options"
          icon={<ArrowIcon arrowDirection="down" />}
          disabled={!allQuestionsAnswered}
        />
      </div>
    </div>
  )
}

function mapDispatchToActions(dispatch: Dispatch): ActionProps {
  return bindActionCreators(
    {
      saveQuestionForQuestionnaire
    },
    dispatch
  )
}
export default connect(
  null,
  mapDispatchToActions
)(PaymentDetermineGovernmentFees) as FunctionComponent<PaymentDetermineGovernemntFeeExplicitProps>
