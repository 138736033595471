import React, { FunctionComponent } from 'react'
import IconWrapper, { IconWrapperExternalProps } from './icon_wrapper'

const PhoneIcon: FunctionComponent<IconWrapperExternalProps> = (props) => (
  <IconWrapper {...props} viewBox="0 0 32 32">
    <path d="M4.64 28.8h16.96v-25.6h-16.96zM6.24 27.2v-5.6h13.76v5.6zM20 4.8v15.52h-13.76v-15.52zM11.8 23.52h2.8v1.92h-2.8zM29.045 23.742l-1.178-1.085c1.574-1.76 2.537-4.096 2.537-6.658s-0.962-4.898-2.545-6.667l0.008 0.010 1.178-1.085c1.831 2.047 2.95 4.764 2.95 7.742s-1.119 5.695-2.96 7.754l0.010-0.011zM25.718 11.33c1.105 1.235 1.78 2.874 1.78 4.67s-0.675 3.436-1.786 4.677l0.006-0.007-1.178-1.085c0.848-0.948 1.367-2.206 1.367-3.586s-0.518-2.638-1.371-3.591l0.005 0.005z" />
  </IconWrapper>
)

export default PhoneIcon
