import React, { ChangeEventHandler, FocusEventHandler, FunctionComponent, useEffect, useState } from 'react'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'

import {
  getBeneficiaryFirstName,
  getCurrentKaseId,
  getCurrentPanel,
  getSponsorFirstName,
  isMobileBrowser
} from 'reducers/selectors'
import { addNewEmploymentHistory, deleteEmploymentHistory, updateEmploymentHistory } from 'actions/employment_actions'

import AddressV2 from 'components/forms/inputs/v2/address'
import CommonInputV2 from 'components/forms/inputs/v2/address/common/common_input_v2'
import CommonLabelV2 from 'components/forms/inputs/v2/address/common/common_label_v2'
import DateInputV2 from 'components/forms/inputs/v2/address/date_input_v2'
import { PanelType } from 'components/forms/panel'
import JITFeedback from 'components/forms/panel/jit_feedback'
import { validateDates } from '../histories_utils/date_validators'
import CommonCheckboxV2 from 'components/forms/inputs/v2/address/common/common_checkbox_v2'
import CommonRadiosV2 from 'components/forms/inputs/v2/address/common/common_radios_v2'
import DocumentWarning from '../histories_utils/document_warning'
import { Address, EmploymentHistory } from 'reducers/employment_histories'
import Button from 'components/button'
import { getAddressHistoryObjectFromPlaceUpdate } from '../../lib/places'
import { inputWarnings } from '../input/input_validators'

interface InjectedProps {
  currentPanel: PanelType
  kaseId?: number
  isMobile: boolean
  sponsorFirstName?: string
  beneficiaryFirstName?: string
}

interface MappedActions {
  addNewEmploymentHistory: Function
  updateEmploymentHistory: Function
  deleteEmploymentHistory: Function
}

interface Props {
  employmentToEdit?: EmploymentHistory
  saveCallback?: (isFormError?: boolean) => void
  cancelCallback?: () => void
  currentPagePath?: string
  hiddenCountryCodes?: any
}

type AllProps = Props & InjectedProps & MappedActions

const EmploymentForm: FunctionComponent<AllProps> = (props) => {
  const {
    addNewEmploymentHistory,
    cancelCallback,
    currentPagePath,
    deleteEmploymentHistory,
    employmentToEdit,
    isMobile,
    kaseId,
    sponsorFirstName,
    beneficiaryFirstName,
    saveCallback,
    updateEmploymentHistory,
    hiddenCountryCodes = []
  } = props

  const [formValues, setFormValues] = useState<EmploymentHistory>({
    id: null,
    start_date: null,
    end_date: null,
    employer_name: null,
    job_title: null,
    remote: false,
    self_employed: false,
    unemployed: false,
    current: false
  })

  const [formAddressValues, setFormAddressValues] = useState<Address>({
    unit_number: null,
    unit_type: null,
    country_code: null,
    street: null,
    city: null,
    postal_code: null,
    province: null
  })

  const [isCurrentEmploymentSituation, setIsCurrentEmploymentSituation] = useState(false)

  const [startDateIsValid, setStartDateIsValid] = useState(true)
  const [startDateValidationMessage, setStartDateValidationMessage] = useState('Please enter a valid date')

  const [endDateIsValid, setEndDateIsValid] = useState(true)
  const [endDateValidationMessage, setEndDateValidationMessage] = useState('Please enter a valid date')

  const [isFormError, setIsFormError] = useState(false)
  const [formErrorMessages, setFormErrorMessages] = useState([])

  const [employedRadioValue, setEmployedRadioValue] = useState('employed')
  const [employmentTypeRadioValue, setEmploymentTypeRadioValue] = useState('')

  const isSponsorPath = currentPagePath === "sponsor's-profile-employment-history"
  const isBeneficiaryPath = currentPagePath === "beneficiary's-profile-employment-history"

  useEffect(() => {
    if (employmentToEdit) {
      setFormValues(employmentToEdit)
      setFormAddressValues(employmentToEdit.address)
      setIsCurrentEmploymentSituation(employmentToEdit.current)

      if (employmentToEdit.unemployed) {
        setEmployedRadioValue('unemployed')
      } else {
        setEmployedRadioValue('employed')
      }

      if (employmentToEdit.remote) {
        setEmploymentTypeRadioValue('remote')
      } else if (employmentToEdit.self_employed) {
        setEmploymentTypeRadioValue('self-employed')
      } else if (!employmentToEdit.address) {
        // If address is empty then this is probably an editable address from when we clicked on a blue block
        // So no selection should be made
        setEmploymentTypeRadioValue('')
      } else {
        setEmploymentTypeRadioValue('none')
      }
    }
  }, [employmentToEdit])

  const changeEmploymentHistoryValue: ChangeEventHandler<HTMLInputElement> = (evt) => {
    const { value, name } = evt.currentTarget

    setFormValues({
      ...formValues,
      [name]: value
    })
  }

  const changeAddressValue: ChangeEventHandler<HTMLInputElement> = (evt) => {
    // this will be coming from unchecking unit radio
    if (evt === null) {
      setFormAddressValues({ ...formAddressValues, unit_type: null })
      return
    }
    const { value, name } = evt.currentTarget

    setFormAddressValues({ ...formAddressValues, [name]: value })
  }

  const handlePlacesUpdate = (addressComponents: google.maps.GeocoderAddressComponent[]) => {
    setFormAddressValues(getAddressHistoryObjectFromPlaceUpdate(addressComponents))
  }

  const onEmployedRadioValueChange: ChangeEventHandler<HTMLInputElement> = (evt) => {
    const { value } = evt.currentTarget

    if (value === 'unemployed') {
      setFormValues({ ...formValues, unemployed: true })
    } else {
      setFormValues({ ...formValues, unemployed: false })
    }
    setEmployedRadioValue(value)
  }

  const onEmploymentTypeRadioValueChange: ChangeEventHandler<HTMLInputElement> = (evt) => {
    const { value } = evt.currentTarget

    if (value === 'remote') {
      setFormValues({ ...formValues, remote: true, self_employed: false })
    } else if (value === 'self-employed') {
      setFormValues({ ...formValues, remote: false, self_employed: true })
    } else {
      setFormValues({ ...formValues, remote: false, self_employed: false })
    }

    setEmploymentTypeRadioValue(value)
  }

  const onBlurStartDate: FocusEventHandler<HTMLInputElement> = (event) => {
    // We use event.target.value to avoid race conditions when setting the state of
    // formValues.start_date
    // We have to add the time so that the date isn't offset by one day
    const startDate = new Date(event.target.value + ' 00:00:00')
    const endDate = new Date(formValues.end_date + ' 00:00:00')
    const validationObj = validateDates(startDate, endDate)

    setStartDateIsValid(validationObj.isStartDateValid)
    setStartDateValidationMessage(validationObj.startDateValidationMsg)
    // Only set these validations when end date is rendered
    if (!isCurrentEmploymentSituation) {
      setEndDateIsValid(validationObj.isEndDateValid)
      setEndDateValidationMessage(validationObj.endDateValidationMsg)
    }
  }

  const onBlurEndDate: FocusEventHandler<HTMLInputElement> = (event) => {
    // We use event.target.value to avoid race conditions when setting the state of
    // formValues.start_date
    // We have to add the time so that the date isn't offset by one day
    const startDate = new Date(formValues.start_date + ' 00:00:00')
    const endDate = new Date(event.target.value)
    const validationObj = validateDates(startDate, endDate)

    setStartDateIsValid(validationObj.isStartDateValid)
    setStartDateValidationMessage(validationObj.startDateValidationMsg)
    setEndDateIsValid(validationObj.isEndDateValid)
    setEndDateValidationMessage(validationObj.endDateValidationMsg)
  }

  // Returns true if inputs are valid, false otherwise
  const datesAreValid = (): boolean => {
    // Set these as scoped variables to avoid race conditions related to state
    let startDateValid = startDateIsValid,
      endDateValid = endDateIsValid

    // startDateIsValid only gets set during onBlur, so there could be a case where
    // they never enter the field and thus it is valid yet there is nothing entered
    // same with endDateIsValid
    if (startDateIsValid && !formValues.start_date) {
      startDateValid = false
      setStartDateIsValid(false)
      setStartDateValidationMessage('Please enter a valid date')
    }

    // If the checkbox for current employment is not checked then end date should be filled
    if (!isCurrentEmploymentSituation && endDateIsValid && !formValues.end_date) {
      endDateValid = false
      setEndDateIsValid(false)
      setEndDateValidationMessage('Please enter a valid date')
    }

    if (startDateValid && endDateValid) {
      return true
    } else {
      setIsFormError(true)
      setFormErrorMessages(['Please fix any errors in the form above'])
      return false
    }
  }

  const secondRadioGroupIsValid = (): boolean => {
    // User must select on option from this group to be considered valid
    // Unless user is unemployed
    const isValid = formValues.unemployed ? true : employmentTypeRadioValue !== ''

    if (!isValid) {
      setIsFormError(true)
      setFormErrorMessages(['Please select if you worked remotely, were self-employed, or if neither apply'])
    }

    return isValid
  }

  const handleErrorMessages = (error) => {
    const errors = error.response.data.errors
    if (errors) {
      setFormErrorMessages(errors.map((error) => error.detail))
    } else {
      setFormErrorMessages(['There was an error handling your request. Please check that no fields are empty'])
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    let isFormError = false
    const owner = isBeneficiaryPath ? 'beneficiary' : 'sponsor'
    const allFormValuesToSave = {
      ...formValues,
      address: {
        ...formAddressValues
      }
    }

    if (datesAreValid() && secondRadioGroupIsValid()) {
      if (employmentToEdit?.id) {
        await updateEmploymentHistory(kaseId, owner, allFormValuesToSave).catch((error) => {
          isFormError = true
          handleErrorMessages(error)
        })
      } else {
        await addNewEmploymentHistory(kaseId, owner, allFormValuesToSave).catch((error) => {
          isFormError = true
          handleErrorMessages(error)
        })
      }

      setIsFormError(isFormError)
      saveCallback(isFormError)
    }
  }

  const handleCancel = (event) => {
    event.preventDefault()
    cancelCallback()
  }

  const handleDelete = async (event) => {
    event.preventDefault()
    await deleteEmploymentHistory(kaseId, formValues.id)
    saveCallback()
  }

  const onCurrentEmploymentChecked = () => {
    setIsCurrentEmploymentSituation(!isCurrentEmploymentSituation)
    // Reset validation for end date
    setEndDateIsValid(true)
    setFormValues({ ...formValues, end_date: null })
  }

  let workedRemotelyJit
  if (formValues.remote) {
    const text =
      'If you worked remotely, please enter the location of the office you \
      reported to below (as opposed to the location of your remote office).'
    workedRemotelyJit = (
      <div className="o-grid--fluid o-grid--stack@sm o-block c-paper-form__group">
        <JITFeedback text={text} />
      </div>
    )
  }

  let selfEmployedJit
  if (formValues.self_employed) {
    const text = 'For self employment, please enter the location of your home office below.'
    selfEmployedJit = (
      <div className="o-grid--fluid o-grid--stack@sm o-block c-paper-form__group">
        <JITFeedback text={text} />
      </div>
    )
  }

  let addressHistoryMismatchJit
  if (employmentToEdit?.address_history_mismatch) {
    const text =
      'The government requires that your address and employment history\
    shows you *living and working in the same locations at the same time*, unless you \
    worked remotely or crossed borders on your commute to work.\
    <br /><br />\
    This employment’s address is different from the address you lived at while working \
    here. To continue, please do one of the following:\
    <br /><br />\
    **Show that you worked remotely or crossed borders.**\
    If this is the case, select that option here.\
    <br /><br />\
    **Match locations.** Edit this employment’s address, and the address you \
    lived at while working here, so that their country, state and province \
    info are the same.\
    <br /><br />\
    **Remove overlap.** If you did live and work in two different locations \
    — and it wasn’t remote work or involved crossing borders — edit your address \
    and employment dates so that they do not overlap.'
    addressHistoryMismatchJit = (
      <div className="o-grid--fluid o-grid--stack@sm o-block c-paper-form__group">
        <JITFeedback text={text} />
      </div>
    )
  }

  const docWarningText =
    '**If this was a job in the U.S.** — to speed\
  up your application, we recommend filling out the info\
  below exactly as it appears on your **W-2s or Employment Verification Letters**,\
  if you have them.'

  //#CON-1430 - I'm removing the personalization for now because this is pulling from
  // the JSONB column on some occasions and doesn't appear to be working

  //const sponsorPersonalization = sponsorFirstName || 'Sponsor'
  //const beneficiaryPersonalization = beneficiaryFirstName || 'Beneficiary'
  const sponsorPersonalization = 'Sponsor'
  const beneficiaryPersonalization = 'Beneficiary'

  const radioPersonalization = isSponsorPath ? sponsorPersonalization : beneficiaryPersonalization

  const showDeleteButton = employmentToEdit && (employmentToEdit.address || employmentToEdit.unemployed)

  // TODO: Split this up into their own components
  return (
    <div id="employmentForm" className="o-block c-paper-form">
      {addressHistoryMismatchJit}
      <CommonCheckboxV2
        label={`This is ${
          isSponsorPath ? sponsorPersonalization : beneficiaryPersonalization
        }’s current employment situation`}
        name="current-employment-situation"
        id="checkbox-for-current-employment-situation"
        checked={isCurrentEmploymentSituation}
        onChangeEvent={onCurrentEmploymentChecked}
        disabled={false}
      />
      <DateInputV2
        value={formValues.start_date ? formValues.start_date : null}
        name="start_date"
        label="Start date"
        onBlurEvent={onBlurStartDate}
        onChangeEvent={changeEmploymentHistoryValue}
        isValid={startDateIsValid}
        validationMessage={startDateValidationMessage}
      />
      {!isCurrentEmploymentSituation && (
        <DateInputV2
          value={formValues.end_date ? formValues.end_date : null}
          name="end_date"
          label="End date"
          onBlurEvent={onBlurEndDate}
          onChangeEvent={changeEmploymentHistoryValue}
          isValid={endDateIsValid}
          validationMessage={endDateValidationMessage}
        />
      )}
      {/* FIRST RADIO INPUT LIST BLOCK*/}
      <CommonRadiosV2
        inputName="is_employed"
        options={[
          {
            label: `${radioPersonalization} was working at this time`,
            value: 'employed'
          },
          {
            label: `${radioPersonalization} was unemployed or retired at this time`,
            value: 'unemployed'
          }
        ]}
        onChangeEvent={onEmployedRadioValueChange}
        value={employedRadioValue}
      />
      {/* END FIRST RADIO INPUT LIST BLOCK*/}
      {/* EMPLOYER BLOCK*/}
      {formValues.unemployed ? (
        <hr className="o-block c-divider c-divider--emphasized" />
      ) : (
        <>
          <hr className="o-block c-divider c-divider--emphasized" />
          {!hiddenCountryCodes.includes('US') && <DocumentWarning text={docWarningText} />}
          <div className="o-grid--fluid o-grid--stack@sm o-block c-paper-form__group">
            <CommonLabelV2 label="employer name" labelFor="employer name" />
            <CommonInputV2
              value={formValues.employer_name ? formValues.employer_name : ''}
              inputName="employer_name"
              onChangeEvent={changeEmploymentHistoryValue}
            >
              {formValues.employer_name?.length > inputWarnings.employer_name.maxLength && (
                <div className="o-flag__item o-flag__item--drop@sm c-tooltip c-tooltip--warning" key="too-long">
                  <div className="c-type c-type--body-sans-sm">{inputWarnings.employer_name.message}</div>
                </div>
              )}
            </CommonInputV2>
          </div>
          <div className="o-grid--fluid o-grid--stack@sm o-block c-paper-form__group">
            <CommonLabelV2 label="job title" labelFor="job title" />
            <CommonInputV2
              value={formValues.job_title ? formValues.job_title : ''}
              inputName="job_title"
              onChangeEvent={changeEmploymentHistoryValue}
            >
              {formValues.job_title?.length > inputWarnings.job_title.maxLength && (
                <div className="o-flag__item o-flag__item--drop@sm c-tooltip c-tooltip--warning" key="too-long">
                  <div className="c-type c-type--body-sans-sm">{inputWarnings.job_title.message}</div>
                </div>
              )}
            </CommonInputV2>
          </div>
        </>
      )}
      {/* END EMPLOYER BLOCK*/}
      {/* SECOND RADIO INPUT LIST BLOCK*/}
      {!formValues.unemployed && (
        <>
          <hr className="o-block c-divider c-divider--emphasized" />
          <CommonRadiosV2
            inputName="employment_type"
            options={[
              {
                label: `${radioPersonalization} worked remotely, or commuted across state, province or\
                national borders at this time`,
                value: 'remote'
              },
              {
                label: `${radioPersonalization} was self-employed at this time`,
                value: 'self-employed'
              },
              {
                label: 'None of the above',
                value: 'none'
              }
            ]}
            onChangeEvent={onEmploymentTypeRadioValueChange}
            value={employmentTypeRadioValue}
          />
          {workedRemotelyJit}
          {selfEmployedJit}
          {/* END SECOND RADIO INPUT LIST BLOCK*/}
          {/* ADDRESS BLOCK*/}
          <hr className="o-block c-divider c-divider--emphasized" />
          <div className="o-block">
            <AddressV2
              address={formAddressValues}
              onChange={changeAddressValue}
              onPlacesUpdate={handlePlacesUpdate}
              hiddenCountryCodes={hiddenCountryCodes}
            />
          </div>
          {/* END ADDRESS BLOCK*/}
        </>
      )}

      {isMobile && (
        <div className="o-grid--fluid o-grid--stack@sm o-block c-paper-form__group">
          <div className="flex justify-between pt-5">
            <Button className="w-2/5" color="secondary" onClick={handleCancel} type="button" label="Cancel" />
            <Button className="w-2/5" onClick={handleSubmit} color="primary" type="button" label="Save" />
          </div>
          {showDeleteButton && (
            <Button className="mt-7" color="transparent" onClick={handleDelete} type="button" label="Delete" />
          )}
        </div>
      )}

      {!isMobile && (
        <div className="o-grid--fluid o-block c-paper-form__group">
          {/*If address is blank, this is probably an edit block from clicking the blue gap box*/}
          {showDeleteButton ? (
            <>
              <div className="o-grid__cell--3/12">
                <Button className="c-btn-left" color="secondary" onClick={handleDelete} type="button" label="Delete" />
              </div>
              <div className="o-grid__cell--3/12"></div>
            </>
          ) : (
            <div className="o-grid__cell--6/12"></div>
          )}

          <div className="o-grid__cell--3/12">
            <Button className="c-btn-right" color="secondary" onClick={handleCancel} type="button" label="Cancel" />
          </div>
          <div className="o-grid__cell--3/12">
            <Button className="c-btn-right" onClick={handleSubmit} type="button" color="primary" label="Save" />
          </div>
        </div>
      )}

      {isFormError && (
        <div className="c-alert c-alert--danger">
          <ul>
            {formErrorMessages.map((message) => (
              <li key={message}>{message}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  )
}

function mapStateToProps(state): InjectedProps {
  return {
    currentPanel: getCurrentPanel(state),
    isMobile: isMobileBrowser(state),
    kaseId: getCurrentKaseId(state),
    sponsorFirstName: getSponsorFirstName(state),
    beneficiaryFirstName: getBeneficiaryFirstName(state)
  }
}

function mapDispatchToActions(dispatch: Dispatch) {
  return bindActionCreators(
    {
      addNewEmploymentHistory,
      updateEmploymentHistory,
      deleteEmploymentHistory
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToActions)(EmploymentForm) as FunctionComponent<Props>
