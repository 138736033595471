/**
 * Taken and adapted from https://github.com/odysseyscience/react-s3-uploader
 */
import _defaults from 'lodash/defaults'
import api from 'lib/api'

const XHR_DONE = 4
const SUCCESS_CODES = [200, 201]

interface KeyValuePair {
  [key: string]: string
}

export enum UploadProgress {
  Waiting = 'waiting',
  Uploading = 'uploading',
  Finalizing = 'fimalizing',
  UploadComplete = 'complete'
}

type MethodType = 'GET' | 'POST' | 'PUT' | 'DELETE'

interface S3UploadOptions {
  contentDisposition?: string
  s3path?: string
  server?: string
  signingUrl?: string
  signingUrlHeaders?: KeyValuePair
  signingUrlMethod?: MethodType
  uploadRequestHeaders?: KeyValuePair
  getSignedUrl?: (file: File, callback: Function) => void
  onError?: (status: UploadProgress, file: File, xhr?: XMLHttpRequest) => void
  onProgress?: (percent: number, status: UploadProgress, file: File) => void
  onUploadComplete?: (signResult, file: File) => void
}

class S3Upload {
  private httpRequest: XMLHttpRequest
  private options: S3UploadOptions

  constructor(files: File[], kaseId: number, options: S3UploadOptions = {}) {
    if (!kaseId) {
      throw new Error('Please provide a kase ID when uploading documents')
    }

    this.options = _defaults(options, {
      contentDisposition: 'auto',
      s3path: `documents/${kaseId}`,
      server: '',
      signingUrl: '/documents/signatures',
      signingUrlMethod: 'POST'
    })

    if (!files) {
      return
    }

    this.handleFileSelect(files)
  }

  private onUploadComplete = (signResult, file: File) => {
    if (this.options.onUploadComplete) {
      this.options.onUploadComplete(signResult, file)
    }
  }

  private onProgress = (percent: number, status: UploadProgress, file: File) => {
    if (this.options.onProgress) {
      this.options.onProgress(percent, status, file)
    }
  }

  private onError = (status, file: File, xhr?: XMLHttpRequest) => {
    if (this.options.onError) {
      this.options.onError(status, file, xhr)
    }
  }

  private scrubFileName = (fileName: string) => {
    return fileName.replace(/[^\w\d_\-.]+/gi, '')
  }

  private handleFileSelect = (files: File[]) => {
    const result = []
    for (let i = 0; i < files.length; i++) {
      const file = files[i]
      result.push(this.uploadFile(file))
    }
  }

  private createCORSRequest = (method: MethodType, url: string) => {
    const xhr = new XMLHttpRequest()
    if (xhr.withCredentials != null) {
      xhr.open(method, url, true)

      return xhr
    }

    return null
  }

  private executeOnSignedUrl = (file: File, callback: (file: File, signedUrl: string) => void) => {
    const fileName = this.scrubFileName(file.name)
    let queryString = `?objectName=${fileName}&contentType=${encodeURIComponent(file.type)}`

    if (this.options.s3path) {
      queryString += `&path=${encodeURIComponent(this.options.s3path)}`
    }

    const { signingUrlMethod, server, signingUrl } = this.options

    const xhr = this.createCORSRequest(signingUrlMethod, server + signingUrl + queryString)

    const signingUrlHeaders = Object.assign({}, api.getCsrfHeaders(), this.options.signingUrlHeaders)

    Object.keys(signingUrlHeaders).forEach((key) => {
      const value = signingUrlHeaders[key]
      xhr.setRequestHeader(key, value)
    })

    if (xhr.overrideMimeType) {
      xhr.overrideMimeType('text/plain; charset=x-user-defined')
    }

    xhr.onreadystatechange = () => {
      if (xhr.readyState === XHR_DONE && SUCCESS_CODES.indexOf(xhr.status) >= 0) {
        let result
        try {
          result = JSON.parse(xhr.responseText)
        } catch (error) {
          this.onError('Invalid response from server', file, xhr)
          return false
        }

        return callback(file, result)
      } else if (xhr.readyState === XHR_DONE && SUCCESS_CODES.indexOf(xhr.status) < 0) {
        return this.onError(`Could not contact request-signing server. Status: ${xhr.status}`, file, xhr)
      }
    }
    return xhr.send()
  }

  uploadToS3 = (file: File, signResult) => {
    const xhr = this.createCORSRequest('PUT', signResult.signedUrl)

    if (!xhr) {
      this.onError('CORS not supported', file, null)
    } else {
      xhr.onload = () => {
        if (SUCCESS_CODES.indexOf(xhr.status) >= 0) {
          this.onProgress(100, UploadProgress.UploadComplete, file)
          return this.onUploadComplete(signResult, file)
        } else {
          return this.onError('Upload error: ' + xhr.status, file, xhr)
        }
      }

      xhr.onerror = () => this.onError('XHR error', file, xhr)

      xhr.upload.onprogress = (e) => {
        let percentLoaded = 0
        if (e.lengthComputable) {
          percentLoaded = Math.round((e.loaded / e.total) * 100)
          return this.onProgress(
            percentLoaded,
            percentLoaded === 100 ? UploadProgress.Finalizing : UploadProgress.Uploading,
            file
          )
        }
      }
    }

    xhr.setRequestHeader('Content-Type', file.type)

    const { contentDisposition } = this.options
    if (contentDisposition) {
      let disposition = contentDisposition
      if (contentDisposition === 'auto') {
        if (file.type.substr(0, 6) === 'image/') {
          disposition = 'inline'
        } else {
          disposition = 'attachment'
        }
      }

      const fileName = this.scrubFileName(file.name)
      xhr.setRequestHeader('Content-Disposition', `${disposition}; filename="${fileName}"`)
    }

    if (signResult.headers) {
      const signResultHeaders = signResult.headers
      Object.keys(signResultHeaders).forEach((key) => {
        xhr.setRequestHeader(key, signResultHeaders[key])
      })
    }

    const uploadRequestHeaders = Object.assign({}, { 'x-amz-acl': 'private' }, this.options.uploadRequestHeaders)
    Object.keys(uploadRequestHeaders).forEach((key) => {
      xhr.setRequestHeader(key, uploadRequestHeaders[key])
    })

    this.httpRequest = xhr

    return xhr.send(file)
  }

  uploadFile = (file: File) => {
    if (this.options.getSignedUrl) {
      return this.options.getSignedUrl(file, this.uploadToS3)
    }

    return this.executeOnSignedUrl(file, this.uploadToS3)
  }

  abortUpload = () => {
    this.httpRequest && this.httpRequest.abort()
  }
}

export default S3Upload
