import server from './server'
import { AxiosPromise } from 'axios'

function getEligibilityDataApi({ kaseId }): AxiosPromise {
  return server.get(`/api/v1/kases/${kaseId}/offer`).then((response) => response.data)
}

export default {
  getEligibilityDataApi
}
