import React, { SyntheticEvent } from 'react'
import classNames from 'classnames'
import { connect } from 'react-redux'
import _includes from 'lodash/includes'

import getTelemetryService from 'lib/telemetry'
import { openModal } from 'actions/modal_actions'
import { showNextPanel } from 'actions/panel_actions'
import { tween } from 'popmotion'
import { SECTION_NAME_SETUP } from 'lib/constants'

import {
  getCurrentSectionChapters,
  getCurrentChapter,
  getCurrentPanel,
  isCurrentPanelComplete,
  isCurrentPanelCustomComponent,
  isCurrentPanelSaveProgressInterstitial,
  getCurrentSectionName,
  isOnPetitionSection,
  getTimelinePageUrl,
  isMobileBrowser
} from 'reducers/selectors'

import Components from 'components'
import Navigation from './navigation'
import Panel, { PanelType, ChapterType } from './panel'

interface Props {
  currentPanel: PanelType
  currentSectionChapters: [ChapterType]
  currentChapter: ChapterType
  sectionName: string
  isCustomComponent: boolean
  isMobileBrowser: boolean
  isOnSaveProgressInterstitial: boolean
  openModal: typeof openModal
  panelIsComplete: boolean
  showNextPanel: typeof showNextPanel
  isOnPetitionSection?: boolean
  timelinePageUrl: string
}

const COMPONENTS_NEEDING_CLEAR_SHEET = [
  'MobileChapterInterstitial',
  'MoralCharacterInterstitial',
  'NaturalizationEligible',
  'NaturalizationSetupStart',
  'PreparingInAdvance',
  'PublicChargeStart',
  'SaveProgress',
  'SetupCompleted',
  'SetupStart',
  'SectionEndInterstitial',
  'UscisQuestionnaireStart',
  'PrePetitionInterstitial'
]

class Container extends React.Component<Props> {
  handleModalClick = (event) => {
    event.preventDefault()

    getTelemetryService().trackLink({
      linkName: 'LaunchSeeAllSectionsModal'
    })

    this.props.openModal({ name: 'SeeAllSectionsModal' })
  }

  isAtSetup() {
    return this.props.sectionName === SECTION_NAME_SETUP
  }

  renderPanel() {
    if (this.props.isCustomComponent) {
      return this.renderComponent()
    } else {
      const { currentPanel, panelIsComplete } = this.props
      return <Panel.Card panel={currentPanel} panelIsComplete={panelIsComplete} />
    }
  }

  renderComponent() {
    const panel = this.props.currentPanel

    if (!panel.component) return null

    const { name } = panel.component

    const PanelComponent = Components[name]
    const props = panel.component.props || {}

    return <PanelComponent {...props} panel={panel} />
  }

  renderChapterHeader() {
    // Don't render the chapter header if its a custom component UNLESS
    // its the address and employment history section
    if (this.props.isCustomComponent && this.props.currentChapter.section_name !== 'address_and_employment_history')
      return null

    return <Panel.ChapterHeader chapter={this.props.currentChapter} panel={this.props.currentPanel} />
  }

  onSubmit = (event: SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault()

    this.props.showNextPanel({ via: 'FormSubmit' })

    return false
  }

  isSheetClear() {
    return (
      this.props.currentPanel.component &&
      _includes(COMPONENTS_NEEDING_CLEAR_SHEET, this.props.currentPanel.component.name)
    )
  }

  isOnIneligibleOutcome() {
    return this.props.currentPanel.outcome === 'ineligible'
  }

  // TODO[ui]: get rid of this check and remove the
  // Start Attorney Review button, using this button instead to launch the
  // payment modal with the button label 'Start Attorney Review'
  isOnPaymentScreen() {
    return this.props.currentPanel.name === 'satisfaction_guarantee'
  }

  isNaturalizationEligible() {
    return this.props.currentPanel.component && this.props.currentPanel.component.name === 'NaturalizationEligible'
  }

  renderContinueButton() {
    if (
      this.isOnIneligibleOutcome() ||
      this.isOnPaymentScreen() ||
      this.isNaturalizationEligible() ||
      [
        'new_sponsor_address_history',
        'new_beneficiary_address_history',
        'new_sponsor_employment_history',
        'new_beneficiary_employment_history',
        'new_sponsor_mailing_address',
        'new_beneficiary_mailing_address',
        'new_beneficiary_intended_address'
      ].includes(this.props.currentChapter.name)
    ) {
      return null
    }

    return <Navigation.ContinueButton floating={this.floating()} isTextShownOnMobile={this.props.isCustomComponent} />
  }

  floating() {
    let floating = null
    if (this.props.isCustomComponent) {
      if (this.isSheetClear()) {
        floating = 'bare'
      } else {
        floating = 'normal'
      }
    }

    return floating
  }

  getPanelAnimationDuration() {
    if (process.env.RAILS_ENV === 'test') {
      return 0
    }

    return 400
  }

  render() {
    const containerClasses = classNames('o-grid__col-8 c-sheet', {
      'c-sheet--clear': this.isSheetClear()
    })

    const { isOnSaveProgressInterstitial, currentSectionChapters } = this.props

    const showSeeAllSections = !(
      isOnSaveProgressInterstitial ||
      (currentSectionChapters && currentSectionChapters.length === 1)
    )

    return (
      <form className={containerClasses} onSubmit={this.onSubmit} id="sheet-container">
        {!this.isNaturalizationEligible() && <Navigation.PreviousButton />}

        {this.renderChapterHeader()}

        <div className="c-sheet__body">{this.renderPanel()}</div>

        {this.renderContinueButton()}
        <Navigation.ProgressIndicator />

        {showSeeAllSections && (
          <div className="c-sheet__folio u-hide@md-up">
            <a href="#" className="c-link c-link--secondary c-type c-type--subhead-sm" onClick={this.handleModalClick}>
              See All Sections
            </a>
          </div>
        )}
      </form>
    )
  }
}

function mapStateToProps(state) {
  return {
    currentSectionChapters: getCurrentSectionChapters(state),
    currentChapter: getCurrentChapter(state),
    currentPanel: getCurrentPanel(state),
    sectionName: getCurrentSectionName(state),
    isOnSaveProgressInterstitial: isCurrentPanelSaveProgressInterstitial(state),
    isCustomComponent: isCurrentPanelCustomComponent(state),
    panelIsComplete: isCurrentPanelComplete(state),
    isOnPetitionSection: isOnPetitionSection(state),
    timelinePageUrl: getTimelinePageUrl(state),
    isMobileBrowser: isMobileBrowser(state)
  }
}

function mapDispatchToActions(dispatch) {
  return {
    openModal: (...args) => dispatch(openModal(...args)),
    showNextPanel: (...args) => dispatch(showNextPanel(...args))
  }
}

export default connect(mapStateToProps, mapDispatchToActions)(Container)
