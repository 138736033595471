import React, { ReactNode, FunctionComponent } from 'react'

import ControlledModalWrapper, { CloseReason } from './controlled_modal_wrapper'
import Heading from 'components/type/heading'
import CloseIcon from 'components/icons/close_icon'

interface Props {
  body: ReactNode
  header: ReactNode
  isOpen?: boolean
  modalWidth?: string | number
  onRequestClose: (reason: CloseReason) => void
}

const ModalWithHeader: FunctionComponent<Props> = ({ body, header, isOpen, modalWidth = '30rem', onRequestClose }) => (
  <ControlledModalWrapper onRequestClose={onRequestClose} isOpen={isOpen} style={{ width: modalWidth }}>
    <div className="c-modal__content--original">
      <header className="c-modal__header--original">
        <Heading tag="h3" color="emphasized" size="sm" spacing="none">
          {header}
        </Heading>
        <button
          className="c-close"
          type="button"
          title="Dismiss this modal"
          onClick={() => onRequestClose(CloseReason.CloseButton)}
        >
          <CloseIcon />
        </button>
      </header>
      {body}
    </div>
  </ControlledModalWrapper>
)

export default ModalWithHeader
