import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'

window.pendingHttpRequests = 0

const increment = () => window.pendingHttpRequests++
const decrement = () => window.pendingHttpRequests--

export function incrementPendingRequests(config: AxiosRequestConfig) {
  increment()
  return config
}

function decrementPendingRequestsSuccess(response: AxiosResponse) {
  decrement()
  return response
}

function decrementPendingRequestsError(error: AxiosError): Promise<AxiosError> {
  decrement()
  return Promise.reject(error)
}

export const decrementPendingRequests = [decrementPendingRequestsSuccess, decrementPendingRequestsError]
