import server from 'lib/api/v1/server'
import { AxiosPromise } from 'axios'

import { Questionnaire } from 'components/screens/questionnaire/lib/types'
import { MarkPageViewed, SaveQuestionForQuestionnaireParams } from 'actions/questionnaire_actions'

interface LoadQuestionnaireParams {
  kaseId: number
  questionnaireSlug: string
}

const FormatQuestionnaireUrlWithId = (kaseId: number, questionnaireId: string) => {
  return `/api/v1/kases/${kaseId}/questionnaires/${questionnaireId}`
}

const FormatQuestionnaireUrlWithSlug = (kaseId: number, questionnaireSlug: string) => {
  return `/api/v1/kases/${kaseId}/questionnaires/by_slug/${questionnaireSlug}`
}

function index(request: LoadQuestionnaireParams): AxiosPromise<Questionnaire> {
  const { kaseId, questionnaireSlug } = request

  return server
    .get(FormatQuestionnaireUrlWithSlug(kaseId, questionnaireSlug))
    .then((response) => {
      return response
    })
    .catch((error) => Promise.reject(error))
}

function postQuestionnaireAnswer(request: SaveQuestionForQuestionnaireParams): AxiosPromise<Questionnaire> {
  const { kaseId, questionnaireId, questionId, answer, answerId } = request

  if (answerId) {
    return server
      .patch(FormatQuestionnaireUrlWithId(kaseId, questionnaireId) + '/answers/' + answerId, {
        question_id: questionId,
        text: answer,
        kase_id: kaseId
      })
      .then((response) => {
        return response
      })
      .catch((error) => Promise.reject(error))
  }

  return server
    .post(FormatQuestionnaireUrlWithId(kaseId, questionnaireId) + '/answers', {
      question_id: questionId,
      text: answer,
      kase_id: kaseId
    })
    .then((response) => {
      return response
    })
    .catch((error) => Promise.reject(error))
}

function markPageViewed(request: MarkPageViewed) {
  const { kaseId, pageId } = request
  return server
    .post(`/api/v1/kases/${kaseId}/pages/${pageId}/page_views`)
    .then((response) => {
      return response
    })
    .catch((error) => Promise.reject(error))
}

export default {
  index,
  postQuestionnaireAnswer,
  markPageViewed
}
