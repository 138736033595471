import PlacesInput from 'components/screens/questionnaire/inputs/places/places_input'
import React, { ChangeEventHandler, FunctionComponent } from 'react'
import CityInputV2 from './city_input_v2'
import CountryInputV2 from './country_input_v2'
import PostalCodeInputV2 from './postal_code_input_v2'
import ProvinceInputV2 from './province_input_v2'
import UnitNumberInputV2 from './unit_number_input_v2'
import UnitTypeInputV2 from './unit_type_input_v2'

// only adding what's needed now, will likely need more in future
type Fields = 'country_code'
interface Props {
  address?: any
  disabled?: boolean
  disabledFields?: Fields[]
  onChange: ChangeEventHandler
  onFieldBlur?: ChangeEventHandler
  onPlacesUpdate?: (answer: google.maps.GeocoderAddressComponent[]) => void
  hiddenCountryCodes?: any
  isUnitedStates?: boolean
}

const defaultProps = {
  address: {},
  hiddenCountryCodes: []
}

const addressTypes = {
  APT: 'APT',
  FLR: 'Floor',
  STE: 'Suite'
}

const AddressV2: FunctionComponent<Props> = ({
  address,
  disabled,
  disabledFields = [],
  onChange,
  onFieldBlur,
  onPlacesUpdate,
  hiddenCountryCodes,
  isUnitedStates
}) => {
  if (!onFieldBlur) {
    onFieldBlur = () => {}
  }

  return (
    <>
      <PlacesInput
        value={address?.street ? address.street : ''}
        onChangeEvent={onChange}
        id="address-form-street-address"
        isUnitedStates={isUnitedStates}
        placeType="address"
        onPlacesUpdate={onPlacesUpdate}
        disabled={disabled}
        name="street"
      />
      <div className="o-grid--fluid">
        <div className="o-grid__cell--7/12 o-grid__cell--off@sm">
          <div className="o-grid--fluid o-grid--stack@sm o-block c-paper-form__group">
            {Object.keys(addressTypes).map((type) => (
              <UnitTypeInputV2
                containerClassName="o-grid__cell--4/12"
                key={type}
                type={type}
                label={addressTypes[type]}
                value={address?.unit_type ? address.unit_type : ''}
                onChangeEvent={onChange}
                disabled={disabled}
              />
            ))}
          </div>
        </div>
        <div className="o-grid__cell--5/12 o-grid__cell--off@sm">
          <div className="o-grid--fluid o-grid--stack@sm o-block c-paper-form__group">
            <UnitNumberInputV2
              value={address?.unit_number ? address.unit_number : ''}
              onChangeEvent={onChange}
              onBlurEvent={onFieldBlur}
              disabled={disabled}
            />
          </div>
        </div>
      </div>
      <CityInputV2
        value={address?.city ? address.city : ''}
        onChangeEvent={onChange}
        onBlurEvent={onFieldBlur}
        disabled={disabled}
      />
      <ProvinceInputV2
        value={address?.province ? address.province : ''}
        onChangeEvent={onChange}
        onBlurEvent={onFieldBlur}
        isUnitedStates={address?.country_code === 'US'}
        disabled={disabled}
      />
      <PostalCodeInputV2
        value={address?.postal_code ? address.postal_code : ''}
        onChangeEvent={onChange}
        onBlurEvent={onFieldBlur}
        disabled={disabled}
      />
      <CountryInputV2
        value={address?.country_code ? address.country_code : ''}
        onChangeEvent={onChange}
        onBlurEvent={onFieldBlur}
        disabled={disabled || disabledFields?.includes('country_code')}
        hiddenCountryCodes={hiddenCountryCodes}
      />
    </>
  )
}

AddressV2.defaultProps = defaultProps

export default AddressV2
