export interface SignResult {
  filename: string
  contentType: string
  originalFilename: string
}

export interface UploadResult {
  rawFile: File
  signResult: SignResult
}

export interface UploadCallback {
  (uploadResults: UploadResult[]): void
}

export default class UploadQueue {
  onUploadsComplete!: UploadCallback
  uploads: UploadResult[] = []
  uploadCount: number = 0

  constructor({ onUploadsComplete }) {
    this.onUploadsComplete = onUploadsComplete
    this.uploadCount = 0
  }

  addCount(count: number) {
    this.uploadCount = this.uploadCount + count
  }

  addResult(result: UploadResult) {
    this.uploads.push(result)

    const allUploadsComplete = this.uploads.length === this.uploadCount

    if (allUploadsComplete) {
      const uploadResults = this.uploads

      this.uploads = []
      this.uploadCount = 0

      this.onUploadsComplete(uploadResults)
    }
  }
}
