import React, { ReactNode } from 'react'
import { connect } from 'react-redux'
import cx from 'classnames'

import { closeModal } from 'actions/modal_actions'
import CloseIcon from 'components/icons/close_icon'

import ModalBodyWrapper from './modal_body_wrapper'

const ESCAPE_KEY = 27

interface ExplicitProps {
  className?: string
  isActive?: boolean
  title?: ReactNode
  modalWidth: string
  modalOffset: string
  disableClosingWithoutAction?: boolean
  hideCloseIcon?: boolean
  overrideModalBody?: boolean
  children: (extraProps: {
    closeModal: (options?: { via?: string }) => void
    preventModalClose: (event: React.MouseEvent) => void
  }) => ReactNode
}

interface ReduxProps {
  closeModal: (options?: { via?: string }) => void
}

class Modal extends React.Component<ExplicitProps & ReduxProps> {
  static defaultProps = {
    modalWidth: 'o-grid__col-6',
    modalOffset: 'o-grid__col--offset-3',
    disableClosingWithoutAction: false,
    hideCloseIcon: false,
    title: ''
  }

  private modalRef = React.createRef<HTMLDivElement>()

  componentDidMount() {
    this.toggleKeypressListeners()
    this.modalRef.current && this.modalRef.current.focus()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isActive === this.props.isActive) return

    this.toggleKeypressListeners()
  }

  toggleKeypressListeners() {
    if (this.props.isActive) {
      document.addEventListener('keydown', this.handleEscKey)
    } else {
      document.removeEventListener('keydown', this.handleEscKey)
    }
  }

  handleEscKey = (event) => {
    const { isActive, closeModal, disableClosingWithoutAction } = this.props
    if (!isActive) return

    if (event.keyCode === ESCAPE_KEY && !disableClosingWithoutAction) {
      closeModal({ via: 'EscapeKey' })
    }
  }

  handleCloseModal =
    ({ via }) =>
    (event) => {
      event.preventDefault()
      this.props.closeModal({ via })
    }

  preventModalClose = (event) => event.stopPropagation()

  handleBackdropClick = () => {
    if (!this.props.disableClosingWithoutAction) {
      this.props.closeModal({ via: 'backdrop' })
    }
  }

  render() {
    const { modalWidth, modalOffset, className, children, overrideModalBody, isActive } = this.props

    const wrappedChildren = children({
      closeModal: this.handleCloseModal,
      preventModalClose: this.preventModalClose
    })

    if (!isActive) {
      return null
    }

    return (
      <div className="c-modal__backdrop" onClick={this.handleBackdropClick}>
        <div className="c-modal">
          <div className={cx('c-modal__dialog', className)} role="dialog" tabIndex={-1} ref={this.modalRef}>
            <div className="c-modal__header">
              <div className="o-grid__row o-grid__row--no-edges">
                <div
                  className={`o-media ${modalWidth} ${modalOffset} o-grid__col--no-edges o-grid__col--no-gutter o-media c-modal__header-content`}
                  onClick={this.preventModalClose}
                >
                  <h3 className="c-modal__title o-media__body">
                    <div className="c-type c-type--headline-sm c-type--emphasized">{this.props.title}</div>
                  </h3>
                  {!this.props.hideCloseIcon && (
                    <button
                      id="close-modal"
                      aria-label="Close"
                      className="o-media__figure c-close"
                      data-dismiss="modal"
                      type="button"
                      onClick={this.handleCloseModal({
                        via: 'ModalCloseIcon'
                      })}
                    >
                      <CloseIcon />
                    </button>
                  )}
                </div>
              </div>
            </div>

            {overrideModalBody ? (
              wrappedChildren
            ) : (
              <ModalBodyWrapper
                preventModalClose={this.preventModalClose}
                modalWidth={modalWidth}
                modalOffset={modalOffset}
              >
                {wrappedChildren}
              </ModalBodyWrapper>
            )}
          </div>
        </div>
      </div>
    )
  }
}

function mapDispatchToActions(dispatch, ownProps) {
  const dispatchCloseModal = (...args) => {
    dispatch(closeModal(...args))
  }

  const closeModalFn = ownProps.closeModal || dispatchCloseModal

  return {
    closeModal: (options?: { via?: string }) => closeModalFn(options)
  }
}

export default connect(null, mapDispatchToActions)(Modal)
