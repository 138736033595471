import u from 'updeep'
import { SET_GLOBAL_ERROR } from 'lib/constants'

interface GlobalStore {
  error: string
}

const initialState: GlobalStore = {
  error: ''
}

export default function errorReducer(state: GlobalStore, action: any): GlobalStore {
  state = state || initialState
  switch (action.type) {
    case SET_GLOBAL_ERROR: {
      return u(
        {
          error: action.payload
        },
        state
      )
    }

    default: {
      return state
    }
  }
}
