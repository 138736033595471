/**
 * Returns true if the provided element is within the viewport.
 * @param element
 */
export function isElementWithinViewport(element: HTMLElement) {
  const rect = element.getBoundingClientRect()
  const windowHeight = window.innerHeight || document.documentElement.offsetHeight
  return rect.top >= 0 && rect.bottom <= windowHeight
}

export function smoothScrollToTopOfElement(
  element: HTMLElement = document.getElementById('react-root'),
  scrollIfSmoothNotSupported: boolean = false
) {
  // Offset by the height of the nav
  let navHeight = 0
  const nav = document.getElementById('navbar-container') || document.getElementById('app-navbar')
  if (nav != null) {
    navHeight = nav.offsetHeight
  }

  const top = element.getBoundingClientRect().top + window.pageYOffset - navHeight

  // Chrome, Firefox, and Opera are the only browsers that support passing
  // an objet to window.scrollTo
  const scrollOptionsSupported = 'scrollBehavior' in document.documentElement.style
  if (scrollOptionsSupported) {
    window.scrollTo({
      top,
      behavior: 'smooth'
    })
  } else if (scrollIfSmoothNotSupported) {
    window.scrollTo(0, top)
  }
}

export const isElementPartiallyVisibleWithinContainer = (element: HTMLElement, container: HTMLElement) => {
  const topOfContainer = container.scrollTop
  const bottomOfContainer = container.scrollTop + container.clientHeight

  const elementTop = element.offsetTop + element.clientTop
  const elementBottom = elementTop + element.clientHeight

  if (elementTop < bottomOfContainer && elementBottom > topOfContainer) {
    return true
  }
  return false
}
