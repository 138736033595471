import getStore from 'stores/app_store'
import { isBlank } from './presence'
import { getCurrentKaseId } from 'reducers/selectors'

export function panelPath(panel) {
  const state = getStore().getState()
  const kaseId = getCurrentKaseId(state)
  const sectionName = panel.section_name
  const slug = panel.slug

  if (isBlank(slug)) {
    console.error(`Panel ${panel.name} is missing a slug`) // eslint-disable-line
  }

  return `/applications/${kaseId}/${sectionName}/${slug}`
}
