import React, { FunctionComponent, useMemo, CSSProperties } from 'react'
import cx from 'classnames'

import { DashboardPhase, getDashboardPhaseData } from './phase_map'
import PhasePreview from './phase_preview'
import CurrentPhaseDetailsWrapper from './current_phase_details_wrapper'

interface Props {
  /**
   * The DashboardPhase that matches the customer's phase.
   */
  currentPhase: DashboardPhase
  /**
   * The DashboardPhase that's currently being previewed.
   */
  currentPhasePreview?: DashboardPhase
  /**
   * Callback triggered by the X button in the PhaseDetails.
   * Should be set up to close the preview.
   */
  closePhasePreview: () => void
  phaseList: DashboardPhase[]
}

const PhaseDetailsLayout: FunctionComponent<Props> = ({
  currentPhase,
  currentPhasePreview,
  phaseList,
  closePhasePreview
}) => {
  const phaseToDisplay = currentPhasePreview || currentPhase
  const phaseData = getDashboardPhaseData(phaseToDisplay)
  const phaseIndex = phaseList.indexOf(phaseToDisplay) || 0
  const showPreview = !!currentPhasePreview && currentPhasePreview !== currentPhase

  // Position the arrow using a percentage to keep it responsive
  const [arrowStyle, arrowIsFlipped] = useMemo(() => {
    const numPhases = phaseList.length
    const leftOffset = 0.4 // offset the arrow from the left side of the phase
    const arrowIsFlipped = phaseIndex > numPhases * 0.5
    const style: CSSProperties = {
      left: (((phaseIndex + leftOffset) / numPhases) * 100).toFixed(0) + '%'
    }
    return [style, arrowIsFlipped]
  }, [phaseIndex])

  // Position the details container
  const boxStyle: CSSProperties = useMemo(() => {
    const numPhases = phaseList.length

    if (phaseIndex < numPhases / 4) {
      return { left: '1rem' }
    }

    // If the phase is in the right-most 25% of the screen
    if (phaseIndex + 1 > (numPhases / 4) * 3) {
      // 45rem = 4rem for the width + 1rem of spacing
      return { left: `calc(100% - 45rem)` }
    }

    // 22.5rem = half of 45rem width
    return { left: `calc(50% - 22.5rem)` }
  }, [phaseIndex])

  const content = showPreview ? (
    <PhasePreview closePhasePreview={closePhasePreview} currentPhase={phaseToDisplay} phaseData={phaseData} />
  ) : (
    <CurrentPhaseDetailsWrapper currentPhase={phaseToDisplay} phaseData={phaseData} />
  )

  return (
    <div className="c-phase-details__wrapper">
      <div className="o-container">
        <div className="c-phase-details__container">
          <div className="c-phase-details" style={boxStyle}>
            {content}
          </div>
        </div>
        <div className="c-phase-details__arrow-wrapper" role="presentation">
          <div
            className={cx('c-phase-details__arrow-element', {
              flipped: arrowIsFlipped
            })}
            style={arrowStyle}
          />
        </div>
      </div>
    </div>
  )
}

export default PhaseDetailsLayout
