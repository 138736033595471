import React, { FunctionComponent, useState } from 'react'

import { QuestionnaireInputContainerProps } from '../lib/types'
import Input from './input'

const SensitiveDataInput: FunctionComponent<QuestionnaireInputContainerProps> = ({
  disabled,
  id,
  name,
  onBlurEvent,
  onChangeEvent,
  value,
  validators
}) => {
  const [revealInputValue, setRevealInputValue] = useState(false)

  const fieldType = revealInputValue ? 'numeric' : 'password'

  const handleSaveSSN = () => {
    onBlurEvent(value.replaceAll('-', ''))
  }

  const toggleRevealInputValue = (event) => {
    event.preventDefault()

    setRevealInputValue(!revealInputValue)
  }

  const showHideToggle = (value) => {
    if (!value || value.length === 0) return

    return (
      <div className="o-layout--vertical-center o-layout--impose__body">
        <a href="#" className="c-type c-type--body-sans-sm c-link o-layout--center" onClick={toggleRevealInputValue}>
          {revealInputValue ? 'Hide' : 'Show'}
        </a>
      </div>
    )
  }

  return (
    <Input
      autoComplete="off"
      className="c-paper-form__control"
      disabled={disabled}
      id={id}
      name={name}
      onBlurEvent={handleSaveSSN}
      onChangeEvent={onChangeEvent}
      mask={revealInputValue ? '999-99-9999' : '999999999'}
      maskChar={''}
      placeholder="123-45-6789"
      type={fieldType}
      value={value}
      validators={validators}
    >
      {showHideToggle(value)}
    </Input>
  )
}

export default SensitiveDataInput
