import React, { FunctionComponent } from 'react'
import cx from 'classnames'

import { keyIsEnter } from 'lib/keyboard_helpers'
import { connect } from 'react-redux'
import { ApplicationState } from 'reducers'
import { getCurrentDocSetIndex } from 'reducers/selectors'
import { DocumentRequestModelV2 } from 'reducers/documents_v2'
import CheckmarkIcon from 'components/icons/checkmark_icon'

interface MappedProps {
  currentDocSetIndex: number
}

interface Props {
  request: DocumentRequestModelV2
  activeRequestId?: number
  onRequestClicked: (setIndex: number, requestIndex: number) => void
  slaIsSet: boolean
  setIndex: number
  requestIndex: number
}

const CollapsibleOutlineRequest: FunctionComponent<Props & MappedProps> = ({
  request,
  activeRequestId,
  onRequestClicked,
  setIndex,
  requestIndex,
  currentDocSetIndex
}) => {
  const requestTitleClasses = cx('c-type c-type--body-sans-sm o-action-items__request-status')

  const requestButtonClasses = cx(
    'o-action-items__outline-action-item c-btn c-btn--align-left c-btn--white-space-pre-line c-btn__link c-btn__link--primary w-full',
    {
      'is-active': activeRequestId === request.id && setIndex === currentDocSetIndex
    },
    requestTitleClasses
  )

  const handleEnterDocRequestSelected = (setIndex: number, requestIndex: number) => (event: React.KeyboardEvent) => {
    if (keyIsEnter(event)) onRequestClicked(setIndex, requestIndex)
  }

  const hasDocument = request.parts.every((part) => part.optional || part.contents.length > 0) ? true : false
  const metaQuestionsAnswered = request.parts.every((part) =>
    part.contents.every((doc) => doc.meta_questions.every((question) => question.attributes.input_value))
  )

  return (
    <li
      key={request.id}
      onClick={() => onRequestClicked(setIndex, requestIndex)}
      onKeyPress={handleEnterDocRequestSelected(setIndex, requestIndex)}
    >
      <button className={requestButtonClasses}>
        {request.title} {hasDocument && metaQuestionsAnswered ? <CheckmarkIcon color="#168753" /> : ''}
      </button>
    </li>
  )
}

function mapStateToProps(state: ApplicationState): MappedProps {
  return {
    currentDocSetIndex: getCurrentDocSetIndex(state)
  }
}

export default connect(mapStateToProps, null)(CollapsibleOutlineRequest)
