export function formatCurrency(value: Nullable<string | number>): string {
  if (value == null) return ''

  return value
    .toString()
    .replace(/[^\d.-]/g, '') // only allow decimal point, digits and minus sign
    .replace(/^(-?\d+)(-)([\d.]*)$/g, '$1$3') // disallow any minus signs in the middle
    .replace(/-+/g, '-') // disallow minus signs in succession
    .replace(/(\.)(\d*)(\.)(\d*)$/g, '$1$2$4') // only allow 1 decimal point
    .replace(/(\.\d\d)(.*)$/g, '$1') // truncate to two decimal places
    .replace(/(\d)(?=(\d\d\d)+(\.\d*)?$)/g, '$1,') // insert commas every 3 placeso
}

export function serializeFixedPoint2(value: string): Nullable<number> {
  if (!value) return null

  // Remove the commas before parsing the string to a fixed point
  // number
  return parseFloat(value.replace(/,/g, '')).toFixed(2)
}
