import React from 'react'
import { connect } from 'react-redux'

import Fee from 'components/fee'

import { getCurrentKaseKindString, readableKaseTag } from 'reducers/selectors'

interface MappedProps {
  currentKaseKindString: string
  readableKaseTag: string
}

class BoundlessOverview extends React.Component<MappedProps> {
  render() {
    return (
      <div className="c-sheet__body">
        <div className="o-grid__row">
          <div className="o-grid__col-6 o-grid__col--offset-1">
            <div className="o-block o-block--spacious">
              <h1 className="o-block c-type c-type--headline-md c-type--emphasized">What&rsquo;s next</h1>
              <p className="c-type c-type--headline-sm">
                We’ll help you complete your government forms using our easy online tools and then thoroughly check each
                answer. Afterwards, we’ll help you upload your documents, then your independent attorney reviews your
                entire application.
              </p>
            </div>

            <div className="o-block">
              <hr className="o-block o-block--ample c-divider c-divider--important c-divider--primary" />

              <div className="o-block o-block--ample">
                <h2 className="o-block o-block--compact c-type c-type--headline-sm c-type--emphasized">
                  Finish questions and documents
                </h2>
                <p className="c-type c-type--body-serif-sm">
                  You’ll answer more in-depth questions to complete your application. Then we'll help you upload all the
                  supporting documents U.S. Citizenship and Immigration Services requires for your{' '}
                  {this.props.readableKaseTag} to your secure customer dashboard.
                </p>
              </div>
              <div className="o-block o-block--ample">
                <h2 className="o-block o-block--compact c-type c-type--headline-sm c-type--emphasized">
                  Your Application is Attorney-Reviewed
                </h2>
                <p className="c-type c-type--body-serif-sm">
                  At no extra cost, an experienced independent immigration attorney will review your entire application
                  and ensure it’s in the best possible state for filing.
                </p>
              </div>
              <div className="o-block o-block--ample">
                <h2 className="o-block o-block--compact c-type c-type--headline-sm c-type--emphasized">
                  No hassle with paperwork
                </h2>
                <p className="c-type c-type--body-serif-sm">
                  After all required documents are uploaded, Boundless will print and assemble your completed
                  application, and you'll receive it at your doorstep in as little as 5–6 weeks, depending on how fast
                  you complete each step.
                </p>
              </div>
            </div>

            <div className="o-block">
              <hr className="c-divider c-divider--primary" />
            </div>

            <div className="o-block o-block--spacious">
              <p className="c-type c-type--body-serif-sm c-type--emphasized">
                Get your complete {this.props.readableKaseTag} application, reviewed by an attorney and ready for
                submission to the USCIS&#8239;&mdash;&thinsp;all for <Fee />.
              </p>
            </div>

            <div className="o-block">
              <h2 className="o-block o-block--ample c-type c-type--headline-md c-type--emphasized">FAQs</h2>
              <div className="o-block o-block--ample">
                <h4 className="o-block o-block--compact c-type c-type--body-sans-md c-type--bold">
                  What will the attorney do for me?
                </h4>
                <p className="o-block c-type c-type--body-sans-sm">
                  Our network of independent immigration attorneys have years of experience and have helped thousands of
                  applications like yours get filed successfully. An attorney in our network will review your
                  application and all of the supporting documents for accuracy, consistency, and completeness. If the
                  attorney finds anything they have questions about, they’ll reach out to you for more information.
                  Ultimately, your attorney is there to identify and address anything in your application that may slow
                  it down, so that you can file quickly, successfully and with confidence.
                </p>
              </div>
              <div className="o-block o-block--ample">
                <h4 className="o-block o-block--compact c-type c-type--body-sans-md c-type--bold">
                  What if I skipped some questions?
                </h4>
                <p className="o-block c-type c-type--body-sans-sm">
                  Not a problem! There’s a lot that happens between now and filing your completed application with the
                  government. You’ll have plenty of opportunities to ask our team about questions you’re unsure of, and
                  you’ll be able to go back and answer those questions. In the end, you’ll have the confidence of
                  knowing that your application is complete, attorney-reviewed, and ready to file.
                </p>
              </div>
              <div className="o-block o-block--ample">
                <h4 className="o-block o-block--compact c-type c-type--body-sans-md c-type--bold">
                  What about supporting documents?
                </h4>
                <p className="o-block c-type c-type--body-sans-sm">
                  The documents required for your {this.props.readableKaseTag} application are unique to your
                  circumstances. Your independent attorney will help determine what documents are needed for your
                  application, and all you have to do is scan or snap a picture of the document and upload it to your
                  secure online account.
                </p>
              </div>
              <div className="o-block o-block--ample">
                <h4 className="o-block o-block--compact c-type c-type--body-sans-md c-type--bold">
                  How do I know my application package is complete?
                </h4>
                <p className="o-block c-type c-type--body-sans-sm">
                  With Boundless, you get an independent immigration lawyer who will review your entire application
                  package to make sure it’s complete (and answer any extra questions you might have). Only after this
                  independent lawyer has confirmed the readiness of your application package will Boundless mail it to
                  you for filing with the federal government.
                </p>
              </div>
              <div className="o-block o-block--ample">
                <h4 className="o-block o-block--compact c-type c-type--body-sans-md c-type--bold">
                  And what happens after I file my application?
                </h4>
                <p className="o-block c-type c-type--body-sans-sm">
                  Filing your application package is only the first step — depending on your situation, it could be 10
                  months or more until your {this.props.readableKaseTag} application is approved. Boundless stays with
                  you—helping you stay on top of interview preparation, follow-on forms, and every other important
                  milestone along the way.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state: any): MappedProps {
  return {
    currentKaseKindString: getCurrentKaseKindString(state),
    readableKaseTag: readableKaseTag(state)
  }
}

export default connect(mapStateToProps)(BoundlessOverview)
