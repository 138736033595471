import React from 'react'

import AskIcon from '../../../../icons/ask_icon'
import CloseIcon from '../../../../icons/close_icon'
import EmailIcon from '../../../../icons/email_icon'
import HelpIcon from '../../../../icons/help_icon'
import PhoneIcon from '../../../../icons/phone_icon'
import Sidebar_help from '../../../../../components/sidebar_help'

export interface ChatABControlProps {
  contactEmail: string
  contextualHelpOpen: boolean
  contextualHelpV2: any
  handleSupportMessageClick: (event: any) => void
  toggleHelp: (event: any) => void
}

export const ChatABControl = ({
  contactEmail,
  contextualHelpV2,
  handleSupportMessageClick,
  toggleHelp,
  contextualHelpOpen
}: ChatABControlProps) => {
  return (
    <li className="o-block o-block--ample pr-4">
      <ul className="tool-menu-sublist o-list-bare">
        <li className="tool-menu-sublist-header">
          <span className="o-block o-block--tight c-type c-type--body-sans-sm c-type--emphasized">Have Questions?</span>
        </li>
        <li className="tool-menu-item">
          <ul className="tool-menu-sublist o-list-bare">
            <li className="tool-menu-item" key="menu-help-link">
              <a
                data-qa="sidebar-help-button"
                className="o-media o-media--tight o-box o-box--tight tool-menu-item-link"
                onClick={toggleHelp}
              >
                <HelpIcon className="o-media__figure tool-menu-item-icon" aria-hidden="true" />

                <span className="o-media__body c-type c-type--subhead-sm">Help</span>

                {contextualHelpOpen && <CloseIcon className="tool-menu-item-icon tool-menu-item-icon--right" />}
              </a>
            </li>
            <li>
              <Sidebar_help contextualHelpV2={contextualHelpV2} />
            </li>
          </ul>
        </li>

        <li className="tool-menu-item">
          <a
            href="#support-message"
            className="o-media o-media--tight o-box o-box--tight tool-menu-item-link"
            onClick={handleSupportMessageClick}
          >
            <AskIcon className="o-media__figure tool-menu-item-icon" aria-hidden="true" />

            <span className="o-media__body c-type c-type--subhead-sm">Chat With Us</span>
          </a>
        </li>

        <li className="tool-menu-item">
          <a
            href={`mailto:${contactEmail}@boundless.com`}
            className="o-media o-media--tight o-box o-box--tight tool-menu-item-link"
          >
            <EmailIcon className="o-media__figure tool-menu-item-icon" aria-hidden="true" />

            <span className="o-media__body c-type c-type--subhead-sm">Email {contactEmail}@boundless.com</span>
          </a>
        </li>

        <li className="tool-menu-item">
          <a href="tel:1-855-268-6353" className="o-media o-media--tight o-box o-box--tight tool-menu-item-link">
            <PhoneIcon className="o-media__figure tool-menu-item-icon" aria-hidden="true" />

            <span className="o-media__body c-type c-type--subhead-sm">Call 1-855-268-6353</span>
          </a>
        </li>
      </ul>
    </li>
  )
}
