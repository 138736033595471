import React from 'react'

import Interstitial from 'components/panels/interstitial'

function SaveProgress() {
  const body = () => (
    <div className="o-box o-box--ample">
      <p className="o-block o-block--compact c-type c-type--body-sans-sm c-type--emphasized">Set a password to</p>
      <ul className="c-type c-type--body-sans-sm o-layout--left">
        <li>Securely store your application info</li>
        <li>Access your info on any device</li>
        <li>Get help from a teammate 7 days/week</li>
      </ul>
    </div>
  )

  return (
    <Interstitial
      headline="You're over 50% done with the application. Save your progress now."
      body={body()}
      image="setup-spouse-questions"
    />
  )
}

export default SaveProgress
