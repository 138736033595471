import isNumeric from 'fast-isnumeric'

export function isArrayPathPart(path: string): boolean {
  return isNumeric(path)
}

export function pathToArray(path: string): string[] {
  return path.split('.')
}

export function joinPaths(...paths: string[]) {
  return paths.join('.')
}

export function pathParent(path: string): string {
  return joinPaths(...pathToArray(path).slice(0, -1))
}
