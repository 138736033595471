import cx from 'classnames'
import React, { FunctionComponent, useState } from 'react'
import { connect } from 'react-redux'
import api from 'lib/api'

import { getDocumentSetsComplete, getCurrentKaseId, getCurrentKaseState } from 'reducers/selectors'

import Button from 'components/button'
import Paragraph from 'components/type/paragraph'
import ConfirmationModal from 'components/modals/modal/confirmation_modal'

interface MappedProps {
  documentSetsComplete: boolean
  kaseId: number
  kaseState: string
}

interface ExplicitProps {
  isMobile?: boolean
}

export const SubmitDocumentsButton: FunctionComponent<MappedProps & ExplicitProps> = ({
  documentSetsComplete,
  isMobile = false,
  kaseId,
  kaseState
}) => {
  const classNames = cx('o-block c-btn c-btn__block c-btn--quaternary', {
    'mx-3 flex-grow px-2': isMobile
  })

  const wrapperNames = cx({
    'flex flex-col items-center': isMobile
  })

  const returnClassNames = cx('js-continue-button o-block o-block--compact', {
    'mx-1 flex-grow px-2': isMobile
  })

  const [isModalOpen, setIsModalOpen] = useState(false)

  const inDocUpload = kaseState === 'customer_document_upload'

  const onSubmit = () => {
    api.documentRequestsV2.setDocumentSetsComplete(kaseId).then(() => {
      window.location.href = `/applications/${kaseId}/timeline`
    })
  }

  const onReturnToIssuesButtonClicked = (event) => {
    event.preventDefault()

    const issuesURL = `/applications/${kaseId}/questions`
    window.location.href = issuesURL
  }

  const returnToIssuesButton = (
    <div className={wrapperNames}>
      <Button
        id="continue-button-desktop"
        className={returnClassNames}
        color="emphasized"
        onClick={(event) => onReturnToIssuesButtonClicked(event)}
        label="Save & Return to Issues"
      />
    </div>
  )

  if (kaseState === 'customer_document_check' || kaseState === 'customer_qa' || kaseState === 'customer_legal_review') {
    return returnToIssuesButton
  }

  return (
    <div className={wrapperNames}>
      <Button
        className={classNames}
        onClick={() => setIsModalOpen(true)}
        type="button"
        label="Submit to Boundless"
        disabled={!documentSetsComplete || !inDocUpload}
      />
      {!documentSetsComplete && (
        <Paragraph size="sm">Please upload a document to each request before submitting.</Paragraph>
      )}
      {!inDocUpload && <Paragraph size="sm">Your documents are currently being checked.</Paragraph>}
      <ConfirmationModal
        cancelLabel="Cancel"
        confirmLabel="Submit Documents"
        onRequestClose={() => setIsModalOpen(false)}
        header="Submit your documents?"
        body={
          <p className="o-block o-block--copious c-type c-type--body-sans-md">
            Once you submit your supporting documents, we will begin to thoroughly check them for accuracy and
            completeness. Please note that if you upload documents <strong>after</strong> submitting, we won't be able
            to see them until we're done checking the ones you submitted.
          </p>
        }
        isOpen={isModalOpen}
        onRequestConfirm={onSubmit}
      />
    </div>
  )
}

function mapStateToProps(state): MappedProps {
  return {
    documentSetsComplete: getDocumentSetsComplete(state),
    kaseId: getCurrentKaseId(state),
    kaseState: getCurrentKaseState(state)
  }
}

export default connect(mapStateToProps, null)(SubmitDocumentsButton) as FunctionComponent<ExplicitProps>
