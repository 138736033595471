import { TOGGLE_SIDEBAR_HELP } from 'lib/constants'
import { isMobileBrowser } from 'reducers/selectors'
import { toggleModal } from './modal_actions'

export function toggleContextualHelp({ via, desktopOnly }) {
  return (dispatch, getState) => {
    const isMobile = isMobileBrowser(getState())

    if (isMobile && !desktopOnly) {
      dispatch(toggleModal({ via, name: 'ContextualHelpModal' }))
    } else {
      dispatch({
        type: TOGGLE_SIDEBAR_HELP,
        via
      })
    }
  }
}
