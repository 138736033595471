import api from 'lib/api'
import { LOADING_ADDRESSES, LOADED_ADDRESSES, LOADED_CURRENT_ADDRESS_HISTORY_EDIT } from 'lib/constants'
import { Address, AddressHistory } from '../reducers/address_histories'
import { loadQuestionnaire } from 'actions/questionnaire_actions'
import moment from 'moment'

export interface FetchAddressParams {
  kaseId: number
}

export function loadedAddresses(data) {
  return (dispatch) => {
    dispatch({
      type: LOADED_ADDRESSES,
      payload: data ?? {}
    })
  }
}

export const loadCurrentAddressHistories = ({ kaseId }: FetchAddressParams) => (dispatch) => {
  dispatch({ type: LOADING_ADDRESSES, payload: true })
  return api.addressHistories
    .index({ kaseId })
    .then((data) => {
      dispatch(loadedAddresses(data.data))
    })
    .finally(() => {
      dispatch({ type: LOADING_ADDRESSES, payload: false })
    })
}

const formatDates = (formValues: AddressHistory) => {
  const formattedDates: Partial<AddressHistory> = {}
  if (formValues.start_date) {
    formattedDates.start_date = moment(formValues.start_date).format('YYYY-MM-DD')
  }

  if (formValues.end_date) {
    formattedDates.end_date = moment(formValues.end_date).format('YYYY-MM-DD')
  }

  if (formValues.spouse_end_date) {
    formattedDates.spouse_end_date = moment(formValues.spouse_end_date).format('YYYY-MM-DD')
  }

  if (formValues.spouse_start_date) {
    formattedDates.spouse_start_date = moment(formValues.spouse_start_date).format('YYYY-MM-DD')
  }
  return formattedDates
}

const removeTrailingSpaces = (address: Address) => {
  const cleanedAddress = {}
  Object.keys(address).forEach((key) => {
    const value = address[key] && address[key].length > 0 ? address[key].trim() : address[key]
    cleanedAddress[key] = value
  })
  return cleanedAddress
}

export const addNewAddressHistory = ({
  kaseId,
  owner,
  formValues
}: {
  kaseId: number
  owner: string
  formValues: AddressHistory
}) => (dispatch) => {
  const params = {
    ...formValues,
    ...formatDates(formValues),
    address: removeTrailingSpaces(formValues.address),
    owner,
    kaseId
  }

  return (
    api.addressHistories
      .createAddress(params)
      // Update the questionnaire since we read the address/employment history data from there
      .then(() => dispatch(loadQuestionnaire('address_and_employment_history')))
  )
}

export const updateAddressHistory = ({
  kaseId,
  owner,
  formValues
}: {
  kaseId: number
  owner: string
  formValues: AddressHistory
}) => (dispatch) => {
  const params = {
    ...formValues,
    ...formatDates(formValues),
    address: removeTrailingSpaces(formValues.address),
    owner,
    kaseId
  }
  return (
    api.addressHistories
      .updateAddress(params)
      // Update the questionnaire since we read the address/employment history data from there
      .then(() => dispatch(loadQuestionnaire('address_and_employment_history')))
  )
}

export const deleteAddressHistory = (kaseId: number, id: number) => (dispatch) => {
  return (
    api.addressHistories
      .deleteAddressHistory(kaseId, id)
      // Update the questionnaire since we read the address/employment history data from there
      .then(() => dispatch(loadQuestionnaire('address_and_employment_history')))
  )
}

export const updateCurrentAddressHistoryEdit = (addressHistory: AddressHistory) => (dispatch) => {
  dispatch({
    type: LOADED_CURRENT_ADDRESS_HISTORY_EDIT,
    payload: addressHistory
  })
}

export const updateMailingAddress = ({
  kaseId,
  owner,
  formValues
}: {
  kaseId: number
  owner: string
  formValues?: AddressHistory
}) => (dispatch) => {
  const params = {
    ...formValues,
    ...formatDates(formValues),
    owner,
    mailing_address: true,
    kaseId
  }

  return (
    api.addressHistories
      .updateMailingAddress(params)
      // Update the questionnaire since we read the address/employment history data from there
      .then(() => dispatch(loadQuestionnaire('address_and_employment_history')))
  )
}

export const updateIntendedAddress = ({
  kaseId,
  owner,
  formValues
}: {
  kaseId: number
  owner: string
  formValues?: AddressHistory
}) => (dispatch) => {
  const params = {
    ...formValues,
    ...formatDates(formValues),
    owner,
    intended_address: true,
    kaseId
  }

  return (
    api.addressHistories
      .updateIntendedAddress(params)
      // Update the questionnaire since we read the address/employment history data from there
      .then(() => dispatch(loadQuestionnaire('address_and_employment_history')))
  )
}

export const updatePhysicalAddressAbroad = ({
  kaseId,
  owner,
  formValues
}: {
  kaseId: number
  owner: string
  formValues?: AddressHistory
}) => (dispatch) => {
  const params = {
    ...formValues,
    ...formatDates(formValues),
    owner,
    physical_address_abroad: true,
    kaseId
  }

  return (
    api.addressHistories
      .updatePhysicalAddressAbroad(params)
      // Update the questionnaire since we read the address/employment history data from there
      .then(() => dispatch(loadQuestionnaire('address_and_employment_history')))
  )
}
