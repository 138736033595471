import server from './server'

interface DocumentAttributes {
  s3_path: string
  file_type: string
  file_size: number
  file_name: string
  document_request_part_id?: number
}

function addDocumentsToDocRequest(kaseId: number, docRequestId: number, documentAttributes: DocumentAttributes[]) {
  const data = {
    document_request: {
      documents_attributes: documentAttributes
    }
  }

  return server
    .patch(`/api/v1/kases/${kaseId}/document_requests/${docRequestId}`, data)
    .then((response) => response)
    .catch((error) =>
      // eslint-disable-next-line no-console
      console.error('Error trying to create document request', error)
    )
}

function fetchDocumentSets(kase_id: number) {
  return server
    .get(`/api/v1/kases/${kase_id}/document_sets`)
    .then((response) => response.data.data)
    .catch((error) =>
      // eslint-disable-next-line no-console
      console.error('Error trying to create document request', error)
    )
}

function deleteDocument(kase_id: number, doc_Id: number) {
  return server
    .delete(`/api/v1/kases/${kase_id}/documents/${doc_Id}`)
    .then((response) => response.data.data)
    .catch((error) =>
      // eslint-disable-next-line no-console
      console.error('Error trying to create document request', error)
    )
}

function setDocumentSetsComplete(kase_id: number) {
  return server
    .post(`/api/v1/kases/${kase_id}/events?event=customer_document_upload_sent_to_ops`)
    .then((response) => response)
    .catch((error) =>
      // eslint-disable-next-line no-console
      console.error('Error trying to create document request', error)
    )
}

function getDocumentUrl(kaseId: number, documentId: number) {
  return server
    .get(`/api/v1/kases/${kaseId}/documents/${documentId}/direct_url`)
    .then((response) => response.data.url)
    .catch((error) => error)
}

function addDocumentMetaAnswer(kase_id: number, document_id: number, document_meta_question_id: number, text: string) {
  const data = {
    document_id,
    document_meta_question_id,
    text
  }

  return server
    .post(`/api/v1/kases/${kase_id}/document_meta_answers`, data)
    .then((response) => response.data.data)
    .catch((error) => error)
}

function updateDocumentMetaAnswer(kase_id: number, document_meta_answer_id: number, text: string) {
  const data = {
    text
  }

  return server
    .patch(`/api/v1/kases/${kase_id}/document_meta_answers/${document_meta_answer_id}`, data)
    .then((response) => response.data.data)
    .catch((error) => error)
}

export default {
  addDocumentMetaAnswer,
  addDocumentsToDocRequest,
  deleteDocument,
  fetchDocumentSets,
  setDocumentSetsComplete,
  getDocumentUrl,
  updateDocumentMetaAnswer
}
