import _flatten from 'lodash/flatten'

import { bindGetModelData } from 'lib/model/data'
import { getValidatorsAtPath } from './validators'

export interface PathValidationState {
  fired: boolean
  error: boolean
  success: boolean
  validating: boolean
}

export interface PathValidationMessage {
  text: string
  type: 'error'
}

export interface PathValidationResult {
  state: PathValidationState
  messages: PathValidationMessage[]
}

export type PathValidationConfig = any

interface ValidationArguments {
  data: any
  validationConfig: any
}

function hasErrorMessages(messages: PathValidationMessage[]): boolean {
  return messages.filter((message) => message.type === 'error').length > 0
}

function applyValidators({ validators, data, path }): PathValidationMessage[][] {
  return validators.map((validator) =>
    validator.func({
      data,
      path,
      options: validator.options,
      getModelData: bindGetModelData(data)
    })
  )
}

function buildValidationResults(results: PathValidationMessage[][]): PathValidationResult {
  const messages = _flatten(results)

  const error = hasErrorMessages(messages)
  const success = !error

  return {
    state: {
      fired: true,
      success,
      error,
      validating: false
    },
    messages
  }
}

// Checks if a path is valid, but only fires synchronous (non-XHR)
// validations.
export function getValidationResultsSync(
  path: string,
  { data, validationConfig }: ValidationArguments
): PathValidationResult {
  const validators = getValidatorsAtPath(path, validationConfig).filter((validator) => !validator.async)

  const results = applyValidators({ data, path, validators })
  return buildValidationResults(results)
}

// Use async validation method for smaller number of validations to be executed
// because this is called inside of the forEach loop it takes a significant
// amount of time to run through large numbers of validations
export async function getValidationResults(
  path: string,
  { data, validationConfig }: ValidationArguments
): Promise<PathValidationResult> {
  const validators = getValidatorsAtPath(path, validationConfig)
  const validations = applyValidators({ data, path, validators })

  const results = await Promise.all(validations)
  return buildValidationResults(results)
}
