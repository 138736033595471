import React, { FunctionComponent } from 'react'
import cx from 'classnames'

import getDocumentRequestLocaleService from 'services/document_request_locale_service'

import Modal from 'components/modals/modal'
import { connect } from 'react-redux'
import { getDocumentSets } from 'reducers/selectors'
import { DocumentSet } from 'reducers/documents_v2'
import { ApplicationState } from 'reducers'

interface Props {
  closeModal: Function
  isActive?: boolean
  onDocRequestClick: (docSetIndex: number, docRequestIndex: number) => void
  slaIsSet: boolean
}

interface MappedProps {
  documentSets: DocumentSet[]
}

const MobileOutline: FunctionComponent<Props & MappedProps> = ({
  closeModal,
  documentSets,
  isActive,
  onDocRequestClick
}) => {
  const documentRequestLocaleService = getDocumentRequestLocaleService()

  return (
    <Modal isActive={isActive} title="Document List" closeModal={closeModal}>
      {() => (
        <ul className="o-block o-list-bare">
          {documentSets.map((docSet, setIndex) => {
            return docSet.document_requests.map((request, requestIndex) => {
              const requestClasses = cx('c-link c-type c-type--body-sans-sm flex')
              return (
                <li key={request.id} className="c-list__item py-1">
                  <a className={requestClasses} href="#" onClick={() => onDocRequestClick(setIndex, requestIndex)}>
                    <span className="flex-grow mr-1">
                      {request.name || documentRequestLocaleService.t(request, 'title', true)}
                    </span>
                  </a>
                </li>
              )
            })
          })}
        </ul>
      )}
    </Modal>
  )
}

function mapStateToProps(state: ApplicationState) {
  return {
    documentSets: getDocumentSets(state)
  }
}

export default connect(mapStateToProps, null)(MobileOutline) as FunctionComponent<Props>
