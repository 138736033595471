import React, { FunctionComponent } from 'react'
import IconWrapper, { IconWrapperExternalProps } from './icon_wrapper'
import classNames from 'classnames'

const AmexIcon: FunctionComponent<IconWrapperExternalProps> = (props) => {
  const className = classNames(props.className, 'c-icon--payment')

  return (
    <IconWrapper {...props} className={className} viewBox="0 0 32 20">
      <g fill="currentColor" fillRule="nonzero">
        <path d="M28,0.842105263 C29.7440571,0.842105263 31.1578947,2.2559429 31.1578947,4 L31.1578947,16 C31.1578947,17.7440571 29.7440571,19.1578947 28,19.1578947 L4,19.1578947 C2.2559429,19.1578947 0.842105263,17.7440571 0.842105263,16 L0.842105263,4 C0.842105263,2.2559429 2.2559429,0.842105263 4,0.842105263 L28,0.842105263 Z M28,7.47939722e-16 L4,7.47939722e-16 C1.790861,0 2.705415e-16,1.790861 0,4 L0,16 C2.705415e-16,18.209139 1.790861,20 4,20 L28,20 C30.209139,20 32,18.209139 32,16 L32,4 C32,1.790861 30.209139,0 28,7.47939722e-16 Z" />
        <path d="M15.6883333,7.45833333 L15.0833333,7.45833333 L13.7083333,10.3825 L12.3333333,7.45833333 L10.5,7.45833333 L10.5,11.3266667 L8.74,7.45833333 L6.99833333,7.45833333 L5,12.0416667 L6.36583333,12.0416667 L6.77833333,11.0241667 L8.92333333,11.0241667 L9.35416667,12.0416667 L11.6458333,12.0416667 L11.6458333,8.74166667 L13.1766667,12.0416667 L14.2858333,12.0416667 L15.6883333,9.01666667 L15.6883333,12.0416667 L16.9166667,12.0416667 L16.9166667,7.45833333 L15.725,7.45833333 L15.6883333,7.45833333 Z M7.20916667,9.97916667 L7.8325,8.4575 L8.48333333,9.97916667 L7.20916667,9.97916667 Z" />
        <path d="M27,7.45833333 L25.3866667,7.45833333 L24.085,8.83333333 L22.8108333,7.45833333 L17.76,7.45833333 L17.76,12.0416667 L22.71,12.0416667 L24.0575,10.6025 L25.3775,12.0416667 L27,12.0416667 L24.8825,9.75 L27,7.45833333 Z M21.5,11.5833333 L21.5,10.9875 L18.9333333,10.9875 L18.9333333,10.2633333 L21.5,10.2633333 L21.5,9.2275 L18.9333333,9.2275 L18.9333333,8.50333333 L21.5,8.50333333 L21.5,7.80666667 L23.2691667,9.75 L21.5,11.5833333 Z" />
      </g>
    </IconWrapper>
  )
}

export default AmexIcon
