import _get from 'lodash/get'
import _reduce from 'lodash/reduce'
import Decorator from 'lib/decorator'

interface GetSectionErrorOptions {
  modelData: Decorator
  kaseKind: string
  sectionName: string
}

interface ErrorConfig {
  [kaseKind: string]: {
    [sectionName: string]: {
      [errorType: string]: string
    }
  }
}

// make sure to update 'setupIneligible' in mbgc_decorator.ts
// or natz_decorator.ts
// and 'ineligible?' in app.rb
// with any new ineligible reasons
const errorConfig: ErrorConfig = {
  MarriageBasedGreenCard: {
    setup: {
      beneficiary_criminal_background: 'criminalBackground',
      not_married_no_intent_to_marry: 'notMarriedHasNoIntentToMarry',
      sponsor_legal_status: 'otherSponsorLegalStatus',
      beneficiary_invalid_visa: 'invalidVisa',
      failed_income_requirement: 'incomeRequirementFailed',
      beneficiary_has_used_public_benefits: 'hasUsedPublicBenefits',
      bene_in_us_sponsor_is_lpr: 'isIneligibleDueToAOSBeneLPRSponsor'
    },
    petition: {
      legal_history: 'hasLawEnforcementHistory'
    }
  },
  Naturalization: {
    setup: {
      no_green_card: 'noGreenCard',
      under_18_years_old: 'under18',
      not_enough_green_card_time: 'notEnoughGreenCardTime',
      doesnt_meet_presence_requirement: 'presenceRequirementNotMet',
      doesnt_meet_latest_trip_return_requirement: 'doesntMeetLatestTripReturnRequirement',
      doesnt_meet_long_term_return_requirement: 'doesntMeetLongTermReturnRequirement',
      has_unpaid_taxes: 'hasUnpaidTaxes',
      has_law_enforcement_history: 'hasLawEnforcementHistory'
    }
  },
  NewMarriageBasedGreenCard: {
    setup: {
      beneficiary_criminal_background: 'criminalBackground',
      not_married_no_intent_to_marry: 'notMarriedHasNoIntentToMarry',
      sponsor_legal_status: 'otherSponsorLegalStatus',
      beneficiary_invalid_visa: 'invalidVisa',
      failed_income_requirement: 'incomeRequirementFailed',
      beneficiary_has_used_public_benefits: 'hasUsedPublicBenefits',
      bene_in_us_sponsor_is_lpr: 'isIneligibleDueToAOSBeneLPRSponsor'
    },
    petition: {
      legal_history: 'hasLawEnforcementHistory'
    }
  }
}

export default function getSectionErrors({ modelData, kaseKind, sectionName }: GetSectionErrorOptions): string[] {
  const errorFns = _get(errorConfig, [kaseKind, sectionName])
  if (!errorFns) return []

  return _reduce(
    errorFns,
    (errors, errorFn, errorType) => {
      if (modelData[errorFn]()) {
        errors.push(errorType)
      }

      return errors
    },
    []
  )
}
