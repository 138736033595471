import React from 'react'
import { connect } from 'react-redux'
import googleMaps from 'google-maps-api'

import _forEach from 'lodash/forEach'

import operations from 'lib/update_transformations'
import { getModelValue } from 'reducers/selectors'
import { QuestionnaireInputContainerProps } from '../'

import CountryInput from '../country/country_input'
import CityInput from './city_input'
import PostalCodeInput from './postal_code_input'
import ProvinceInput from './province_input'
import StreetInput from './street_input'
import UnitInfo from 'components/forms/inputs/unit_info'
import ValidatedInput from 'components/forms/validated_input'
import ProvinceLabel from './province_label'

type Props = QuestionnaireInputContainerProps & {
  address: any
  hidden_country_codes: string[]
  update_hidden_fields_on_autocomplete: boolean
  visible_fields: string[]
}

const allFields = ['city', 'country', 'postal_code', 'province', 'street', 'unit_number', 'unit_type']

class Address extends React.Component<Props> {
  static defaultProps = {
    name: '',
    onChange: () => {},
    resourceKeys: [],
    update_hidden_fields_on_autocomplete: false,
    visible_fields: allFields
  }

  autocomplete: any = null
  cityRef: Nullable<HTMLInputElement> = null
  containerRef: Nullable<HTMLDivElement> = null
  postalCodeRef: Nullable<HTMLInputElement> = null
  streetRef: Nullable<HTMLInputElement> = null

  clearProvinceValue = ({ changeValueAtPath }: any) => {
    const path = this.fieldPath('province')
    changeValueAtPath(path, { value: null })
  }

  componentDidUpdate() {
    if (this.autocomplete) {
      this.autocomplete.setOptions(this.getAutocompleteOptions())
    }
  }

  shouldUpdateField(name: string): boolean {
    return this.props.update_hidden_fields_on_autocomplete || this.isVisible(name)
  }

  // transform google address into our format
  extractAddress(addressComponents) {
    const address: any = {}

    const data: any = {}

    // Mark the address as autofilled only if we are autocompleting
    // from the street component.
    data.autofilled = this.streetHasAutocomplete()

    _forEach(addressComponents, (c) => {
      address[c.types[0]] = c.long_name
      address[`${c.types[0]}_short`] = c.short_name
    })

    if (this.shouldUpdateField('street') && (address.street_number || address.route)) {
      data.street = `${address.street_number || ''} ${address.route}`.trim()
    }

    if (this.shouldUpdateField('city') && address.locality) {
      data.city = address.locality
    }

    if (this.shouldUpdateField('province') && address.administrative_area_level_1_short) {
      data.province =
        address.country_short === 'US' ? address.administrative_area_level_1_short : address.administrative_area_level_1
    }

    if (this.shouldUpdateField('postal_code') && (address.postal_code_prefix || address.postal_code)) {
      data.postal_code = `${address.postal_code_prefix || ''} ${address.postal_code || ''}`.trim()
    }

    if (address.country_short) {
      // save country even if invisible
      data.country = { code: address.country_short }
    }

    this.props.onChange(operations.mergeObject(data))
  }

  handleAddressAutocomplete = () => {
    const place = this.autocomplete.getPlace()

    if (place.address_components) {
      this.extractAddress(place.address_components)
      this.fireAllValidations()
    }
  }

  getAutocompleteOptions() {
    const { address } = this.props
    const options = {}

    // For information on autocomplete results types see:
    // https://developers.google.com/places/supported_types#table3
    options.types = [this.isVisible('street') ? 'address' : '(cities)']

    if (address.country && address.country.code) {
      options.componentRestrictions = {
        country: address.country.code.toLowerCase()
      }
    }

    return options
  }

  streetHasAutocomplete() {
    return this.isVisible('street')
  }

  cityHasAutocomplete() {
    return !this.streetHasAutocomplete()
  }

  fireAllValidations() {
    const refs = [document.activeElement, this.streetRef, this.cityRef, this.postalCodeRef]

    refs.forEach((ref) => {
      if (ref) {
        ref.focus()
        ref.blur()
      }
    })
  }

  autocompleteInputRef() {
    return this.cityHasAutocomplete() ? this.cityRef : this.streetRef
  }

  componentDidMount() {
    var input = this.autocompleteInputRef()

    if (input) {
      input.dataset.blockEnterKeyNavigation = 'true'

      googleMaps(window.GOOGLE_MAPS_KEY, ['places'])().then((maps) => {
        this.autocomplete = new maps.places.Autocomplete(input, this.getAutocompleteOptions())

        this.autocomplete.addListener('place_changed', this.handleAddressAutocomplete)
      })
    }
  }

  isVisible(fieldName: string): boolean {
    return this.props.visible_fields.indexOf(fieldName) > -1
  }

  currentCountryCode(): Nullable<string> {
    const { address } = this.props

    if (!address) return null

    return address.country ? address.country.code : null
  }

  fieldPath(fieldName: string): string {
    return `${this.props.path}.${fieldName}`
  }

  render() {
    const { disabled } = this.props
    const isUS = this.currentCountryCode() === 'US'

    return (
      <div className="o-block" ref={(ref) => (this.containerRef = ref)}>
        {this.isVisible('country') && (
          <div className="o-grid--fluid o-grid--stack@sm o-block c-paper-form__group">
            <label
              className="o-grid__cell--3/12 o-grid__cell--off@sm c-paper-form__label c-paper-form__segment"
              htmlFor={`${this.props.id}-country`}
            >
              <span className="c-type c-type--subhead-sm">Country</span>
            </label>

            <div className="o-grid__cell--9/12 o-grid__cell--off@sm o-flag c-paper-form__segment">
              <ValidatedInput disabled={disabled} path={this.fieldPath('country.code')}>
                {({ fireValidation, getValidationClasses, renderValidations }) => (
                  <React.Fragment>
                    <CountryInput
                      afterChangeEvents={this.isVisible('province') ? [this.clearProvinceValue] : []}
                      className={`c-paper-form__control ${getValidationClasses()}`}
                      disabled={disabled}
                      hiddenCountryCodes={this.props.hidden_country_codes || []}
                      id={this.props.id}
                      onBlur={fireValidation}
                      path={this.fieldPath('country.code')}
                    />

                    {renderValidations()}
                  </React.Fragment>
                )}
              </ValidatedInput>
            </div>
          </div>
        )}

        {this.isVisible('street') && (
          <div key="street-container" className="o-grid--fluid o-grid--stack@sm o-block c-paper-form__group">
            <label
              className="o-grid__cell--3/12 o-grid__cell--off@sm c-paper-form__label c-paper-form__segment"
              htmlFor={`${this.props.id}-street`}
            >
              <span className="c-type c-type--subhead-sm">Street</span>
            </label>

            <div className="o-grid__cell--9/12 o-grid__cell--off@sm c-paper-form__segment">
              <StreetInput
                address={this.props.address}
                disabled={disabled}
                id={this.props.id}
                path={this.fieldPath('street')}
                updateHiddenFieldsOnAutocomplete={this.props.update_hidden_fields_on_autocomplete}
                inputRef={(ref) => (this.streetRef = ref)}
                visibleFields={this.props.visible_fields}
              />
            </div>
          </div>
        )}

        {this.isVisible('unit_type') && <UnitInfo disabled={disabled} id={this.props.id} path={this.props.path} />}

        {this.isVisible('city') && (
          <div key="city-container" className="o-grid--fluid o-grid--stack@sm o-block c-paper-form__group">
            <label
              className="o-grid__cell--3/12 o-grid__cell--off@sm c-paper-form__label c-paper-form__segment"
              htmlFor={`${this.props.id}-city`}
            >
              <span className="c-type c-type--subhead-sm">City or Town</span>
            </label>

            <div className="o-grid__cell--9/12 o-grid__cell--off@sm c-paper-form__segment">
              <CityInput
                id={this.props.id}
                type={this.cityHasAutocomplete() ? 'search' : 'text'}
                path={this.fieldPath('city')}
                disabled={disabled}
                inputRef={(ref) => (this.cityRef = ref)}
                autoComplete={this.cityHasAutocomplete() ? 'off' : 'city'}
              />
            </div>
          </div>
        )}

        {this.isVisible('province') && (
          <div className="o-grid--fluid o-grid--stack@sm o-block c-paper-form__group">
            <ValidatedInput disabled={disabled} path={this.fieldPath('province')}>
              {({ fireValidation, getValidationClasses, renderValidations }) => (
                <React.Fragment>
                  <ProvinceLabel
                    className="o-grid__cell--3/12 o-grid__cell--off@sm c-paper-form__label c-paper-form__segment"
                    id={this.props.id}
                    isUS={isUS}
                  />
                  <div className="o-grid__cell--9/12 o-grid__cell--off@sm c-paper-form__segment">
                    <ProvinceInput
                      className={`c-paper-form__control ${getValidationClasses()}`}
                      country={this.currentCountryCode()}
                      disabled={disabled}
                      id={this.props.id}
                      onBlur={fireValidation}
                      path={this.fieldPath('province')}
                    />
                    {renderValidations()}
                  </div>
                </React.Fragment>
              )}
            </ValidatedInput>
          </div>
        )}

        {this.isVisible('postal_code') && (
          <div className="o-grid--fluid o-grid--stack@sm o-block c-paper-form__group">
            <label
              className="o-grid__cell--3/12 o-grid__cell--off@sm c-paper-form__label c-paper-form__segment"
              htmlFor={`${this.props.id}-postal-code`}
            >
              <span className="c-type c-type--subhead-sm">{isUS ? 'ZIP Code' : 'Postal Code'}</span>
            </label>

            <div className="o-grid__cell--9/12 o-grid__cell--off@sm c-paper-form__segment">
              <PostalCodeInput
                disabled={disabled}
                id={this.props.id}
                inputRef={(ref) => (this.postalCodeRef = ref)}
                path={this.fieldPath('postal_code')}
              />
            </div>
          </div>
        )}
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    address: getModelValue(state, ownProps.path)
  }
}

export default connect(mapStateToProps)(Address)
