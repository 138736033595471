import React from 'react'
import classNames from 'classnames'
import BreakpointWrapper from 'components/breakpoint_wrapper'
import GlobalError from 'components/error/global'
// import LoadingSkeleton from 'components/screens/issues_v2/loading_skeleton'

export function VisaPathQuizContainer() {
  const isLandingPage = true

  return (
    <>
      {/* Mobile screens */}
      <BreakpointWrapper mobile>
        <div className="o-grid__col-12">
          <div className="o-pane__fill o-grid__row" style={{ height: '100%' }}>
            <GlobalError />
            <div className={classNames('o-grid__col-8 c-sheet', { 'c-sheet--clear': isLandingPage })}>
              <h2>We found a Visa Path Quiz you've started</h2>
              You will be re-directed shortly...
            </div>
          </div>
        </div>
      </BreakpointWrapper>
      {/* Desktop screens */}
      <BreakpointWrapper desktop>
        <div className="o-pane__fill o-grid__row">
          <aside className="o-grid__col-2 u-hide@sm-down">
            <ul className="outline outline-list o-block o-list-bare u-hide@sm-down">
              <li>List of things</li>
            </ul>
          </aside>
          <main className="o-grid__col-8 c-sheet">
            <GlobalError />
            <h2>We found a Visa Path Quiz you've started</h2>
            You will be re-directed shortly...
          </main>
        </div>
      </BreakpointWrapper>
    </>
  )
}

export default VisaPathQuizContainer
