import React from 'react'
import { connect } from 'react-redux'

import { getCurrentQuestionnaire, getCurrentQuestionnaireSections } from 'reducers/questionnaire/selectors'
import { getCurrentKaseKind, isCurrentKasePaid } from 'reducers/selectors'

import { closeModal } from 'actions/modal_actions'

import Modal from '../modal'

import { KaseKind } from 'lib/constants'
import { Questionnaire } from '../../screens/questionnaire/lib/types'

interface Props {
  closeModal: typeof closeModal
  currentKaseKind: KaseKind
  isActive: boolean
  isPaidUser: boolean
  questionnaireData?: Questionnaire
  sections?: any
}
class SeeAllSectionsModalNew extends React.Component<Props> {
  handleSectionClick = () => {
    this.props.closeModal()
  }

  renderChapters() {
    return this.props.sections?.map((section, index) => {
      const firstIndex = 0
      const sectionName = section?.name === 'Summary' ? 'Your Payment Options' : section?.name

      const rootUrl: string = window.location.pathname.split('/').slice(0, 5).join('/')

      const firstPageInSection = section.pages[0]?.attributes?.path

      return (
        <li key={sectionName}>
          {index !== firstIndex && <hr className="c-divider" />}
          <a href={rootUrl + '/' + firstPageInSection} onClick={this.handleSectionClick}>
            <div className="o-box o-box--compact">
              <span className="o-block c-type c-type--subhead-sm">{sectionName}</span>
            </div>
          </a>
        </li>
      )
    })
  }

  render() {
    // There is currently no dashboard available to prepay users in the MBGC product, so that
    // is the only case where we don't show this link. Remove this check when the prepay
    // dashboard for MBGC is built!
    const showDashboardLink =
      !this.props.isPaidUser && this.props.currentKaseKind !== KaseKind.NewMarriageBasedGreenCard
    return (
      <Modal isActive={this.props.isActive} title="All Sections">
        {() => (
          <ul className="o-block o-list-bare">
            {showDashboardLink && (
              <li key="Return to Dashboard">
                <a href="/timeline" onClick={this.handleSectionClick}>
                  <div className="o-box o-box--compact">
                    <span className="o-block c-type c-type--subhead-sm">Return to Dashboard</span>
                  </div>
                </a>
                <hr className="c-divider" />
              </li>
            )}
            {this.renderChapters()}
          </ul>
        )}
      </Modal>
    )
  }
}

const mapStateToProps = (state) => ({
  currentKaseKind: getCurrentKaseKind(state),
  isPaidUser: isCurrentKasePaid(state),
  questionnaireData: getCurrentQuestionnaire(state),
  sections: getCurrentQuestionnaireSections(state)
})

const mapDispatchToActions = (dispatch) => {
  return {
    closeModal: (...args) => dispatch(closeModal(...args))
  }
}

export default connect(mapStateToProps, mapDispatchToActions)(SeeAllSectionsModalNew)
