import { Palette } from '@boundless-immigration/boundless-ui'
import React from 'react'

const DashboardSuitecaseIllustration = () => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M24.8568 15.6683L16.4758 24.0493L12.6663 20.2398" stroke={Palette.emphasisMain} strokeWidth="2" />
    <rect x="3" y="10.5703" width="30" height="20.8571" stroke={Palette.emphasisMain} strokeWidth="2" />
    <path d="M23.3337 10.0952V4H12.667V10.0952" stroke={Palette.emphasisMain} strokeWidth="2" />
  </svg>
)

export default DashboardSuitecaseIllustration
