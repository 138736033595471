// avoid pulling in any libraries from moment or lodash into this class
import Cookies from 'js-cookie'
import _merge from 'lodash/merge'
import URL from 'url-parse'
import EventQueue from './event_queue'
import HeapAdapter from './heap'

import { cookieDomain } from 'lib/cookies'
import { trackingId } from 'lib/telemetry/common/id'

import { GOOGLE_ADWORDS_CONVERSION_TAG_IDS, TELEMETRY_EVENTS, VWO_CONVERSION_GOAL_CONFIGS } from 'lib/constants'

const DEBUG_COOKIE_NAME = 'telemetryDebug' // debug the events and their data
const DEBUG_TRANSIT_COOKIE_NAME = 'telemetryDebugTransit' // debug sending events
const OPT_OUT_COOKIE_NAME = 'telemetryOptOut'

export default class CommonReporter {
  constructor(reportingEnvironment) {
    this.reportingEnvironment = reportingEnvironment
    this.currentUrl = URL(window.location.href)

    this._enableDebugHooks()
    window.enableTelemetryDebug = this._enableDebug
  }

  page() {
    return this._analytics().page()
  }

  track(eventName, eventData = {}, callback = null) {
    const fullEventData = this._getFullEventData(eventData)

    this._debug(eventName, fullEventData)
    this.recent.push(eventName, fullEventData)

    return this._analytics().track(eventName, fullEventData, {}, callback)
  }

  captureGoogleAdsConversion(conversionType, conversionData = {}) {
    return new Promise((resolve) => {
      const conversionId = this._adwordConversionId(conversionType)
      const fullConversionData = {
        send_to: conversionId,
        event_callback: resolve,
        ...conversionData
      }

      this._debugConversion(conversionType, conversionId, conversionData)
      this._googleAdwords()('event', 'conversion', fullConversionData)
      this._vwo(this._vwoGoalConfig(conversionType), conversionData)
    })
  }

  captureFacebookPixelConversion(conversionEvent, conversionData = {}) {
    return new Promise((resolve) => {
      this._debugConversion(conversionEvent, 'facebook', conversionData)
      if (window._fbq) {
        window._fbq('track', conversionEvent, conversionData)
      }

      resolve()
    })
  }

  identify(userData = {}) {
    const self = this
    return new Promise((resolve) => {
      if (!this.forceAdminTelemetry) {
        delete userData.guestId
      }

      const traits = _merge({}, self._defaults(), userData)
      self._debugIdentify(traits)
      self._analytics().identify(trackingId(), traits, {}, resolve)
    })
  }

  reset() {
    this._analytics().reset()
  }

  optOut() {
    Cookies.set(OPT_OUT_COOKIE_NAME, true, {
      expires: 365,
      domain: cookieDomain()
    })
  }

  optIn() {
    Cookies.remove(OPT_OUT_COOKIE_NAME)
  }

  // private

  _analytics() {
    return this._segmentService()
  }

  _googleAdwords() {
    return this._googleAdwordsReporter()
  }

  _heap(debug = false) {
    if (!window._heapReporter) {
      window._heapReporter = this._heapReporter(debug)
    }

    return window._heapReporter
  }

  _googleAdwordsReporter() {
    return window.gtag
  }

  _heapReporter(debug) {
    const adapter = new HeapAdapter(this)

    if (!window.heap) return

    adapter.debug = debug

    return adapter
  }

  _segmentService() {
    return window.analytics
  }

  _vwoQueue() {
    if (!window._vis_opt_queue) window._vis_opt_queue = []
    return window._vis_opt_queue
  }

  _vwo(config = {}, conversionData) {
    this._vwoQueue().push(() => {
      const configsToPush = Array.isArray(config) ? config : [config]

      configsToPush.forEach((configToPush) => {
        if (this._debugEnabled()) {
          this._debugVWO(configToPush, conversionData)
        }

        let payload = configToPush.goalId || conversionData.value
        window[configToPush.method](payload)
      })
    })
  }

  _debugConversion(conversionType, conversionId, conversionData) {
    if (this._debugEnabled()) {
      /* eslint-disable no-console */
      console.log(`Capturing conversion: ${conversionType}: '${conversionId}' with data:`)
      console.log(conversionData)
      /* eslint-enable no-console */
    }
  }

  _debugIdentify(userData = {}) {
    if (this._debugEnabled()) {
      /* eslint-disable no-console */
      console.log(`Identifying user: ${JSON.stringify(userData)}`)
      /* eslint-enable no-console */
    }
  }

  _debug(eventName, eventData = {}) {
    if (this._debugEnabled()) {
      /* eslint-disable no-console */
      console.log(`Tracking: ${eventName}: ${JSON.stringify(eventData)}`)
      /* eslint-enable no-console */
    }
  }

  _debugVWO(config = {}, conversionData) {
    if (this._debugEnabled()) {
      let payload = config.goalId || conversionData.value
      /* eslint-disable no-console */
      console.log(`VWO Conversion: window['${config.method}'](${payload})`)
      /* eslint-enable no-console */
    }
  }

  _debugHeap(method = '', data) {
    if (this._debugEnabled()) {
      /* eslint-disable no-console */
      console.log(`Tracking Heap Method: ${method} with data:`)
      console.log(data)
      /* eslint-enable no-console */
    }
  }

  _catchError(e) {
    /* eslint-disable no-console */
    console.error(e)
    /* eslint-enable no-console */
  }

  _debugEnabled() {
    return !!Cookies.get(DEBUG_COOKIE_NAME)
  }

  _enableDebug() {
    Cookies.set(DEBUG_COOKIE_NAME, 'debug')
    location.reload()
  }

  _hostname() {
    return this.currentUrl ? this.currentUrl.hostname : null
  }

  _defaults() {
    return {
      environment: this.reportingEnvironment,
      hostname: this._hostname()
    }
  }

  _runCallback(callback) {
    return callback && callback()
  }

  _adwordConversionId(conversionType) {
    const conversionIds = GOOGLE_ADWORDS_CONVERSION_TAG_IDS[this.reportingEnvironment]

    if (!conversionIds) return null

    return conversionIds[conversionType]
  }

  _vwoGoalConfig(conversionType) {
    return VWO_CONVERSION_GOAL_CONFIGS[conversionType] || {}
  }

  _enabled() {
    if (this._isReportingForced()) {
      return true
    }

    if (this._isReportingEnv('development')) {
      return this._debugEnabled() && !this._isOptedOut()
    }

    if (this._isReportingEnv('test') || this._isReportingEnv('customtestenv')) {
      return false
    }

    return !this._isOptedOut()
  }

  _getFullEventData(eventData) {
    return _merge({}, this._defaults(), eventData)
  }

  _isReportingEnv(env) {
    return this.reportingEnvironment === env
  }

  _isOptedOut() {
    return !this.forceAdminTelemetry && Cookies.get(OPT_OUT_COOKIE_NAME)
  }

  _isReportingForced() {
    return Cookies.get(DEBUG_TRANSIT_COOKIE_NAME)
  }

  _enableDebugHooks() {
    this.recent = new EventQueue()
    this.eventNames = TELEMETRY_EVENTS
  }
}
