import { Validator, ValidatorArguments } from 'lib/validations/validators'
import { sharedConditions as conditions } from 'lib/conditions/shared'
import { pathParent } from 'lib/path_helpers'

interface ValidatesIfOptions {
  conditionFnName: string
  conditionArgs: {
    [argName: string]: any
  }
}

interface ValidatesIf {
  (options: ValidatesIfOptions): (validationFn: Validator) => Validator
}

const noopCondition = () => true

// This wraps a validator (required, date, etc) with a conditional
// function that only fires a given validator if the right conditions are
// met.
//
// ex:
//
//   import required from 'lib/validations/validators/required'
//
//   const wrappedValidator = validatesIf({
//     conditionFnName: 'isFalsey',
//     conditionArgs: { path: 'foo.bar.path' },
//   })(required)
//
// You can now use wrappedValidator like a normal validation function
//
// PS: Can also use relative paths, i.e.
//
//   const wrappedValidator = validatesIf({
//     conditionFnName: 'isPresent',
//     conditionArgs: { path: '_.baz' },
//   })(required)
//
// This validator will conditionally validate if the key 'baz' is present
// on the parent object of the requested path. See validates_if_test for more
// details.
//
export const validatesIf: ValidatesIf = ({ conditionFnName, conditionArgs }) => (validationFn) => (
  wrappedValidatorOptions: ValidatorArguments
) => {
  const conditionFn = conditions[conditionFnName] || noopCondition
  const { data, path } = wrappedValidatorOptions

  const fnArgs = conditionArgs || {}
  let fnArgsPath = fnArgs.path
  if (fnArgsPath.charAt(0) === '_') {
    fnArgsPath = fnArgsPath.replace('_', pathParent(path))
  }

  const shouldValidate = conditionFn({
    args: { ...fnArgs, path: fnArgsPath },
    modelData: data
  })

  if (shouldValidate) {
    return validationFn(wrappedValidatorOptions)
  } else {
    return []
  }
}
