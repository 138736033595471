import u from 'updeep'
import { ADDED_MESSAGE, LOADED_ISSUES, LOADING_ISSUES, SET_CURRENT_ISSUE, UPDATE_ISSUE } from 'lib/constants'

export interface IssuesById {
  [key: string]: Issue
}

export interface MessagesById {
  [key: string]: Message
}

export interface MessageIdentifier {
  id: string
  type: 'messages'
}

export interface Link {
  label: string
  uri: string
}

export interface Issue {
  id: string
  type: string
  attributes: {
    created_at: string
    creator_id: string
    description: string
    kase_id: string
    links: Link[]
    status: 'open' | 'resolved'
  }
  relationships: {
    messages: {
      data: MessageIdentifier[]
    }
  }
}

export interface Message {
  type: string
  id: string
  attributes: {
    text: string
    creator_id: string
    created_at: string
    issue_id: string
  }
}

export interface KaseIssuesStore {
  issues: IssuesById
  messages: MessagesById
  loadingIssues?: boolean
  currentIssueId?: string
}

const initialState: KaseIssuesStore = {
  issues: {},
  messages: {},
  loadingIssues: false,
  currentIssueId: null
}

export default function issuesReducer(state: KaseIssuesStore, action: any): KaseIssuesStore {
  state = state || initialState

  switch (action.type) {
    case LOADING_ISSUES: {
      return u(
        {
          loadingIssues: true
        },
        state
      )
    }

    case LOADED_ISSUES: {
      return u(
        {
          issues: action.issues || {},
          messages: action.messages || {},
          loadingIssues: false
        },
        state
      )
    }

    case SET_CURRENT_ISSUE: {
      return u(
        {
          currentIssueId: action.issueId
        },
        state
      )
    }

    case ADDED_MESSAGE: {
      const message = Object.values(action.messages)[0] as Message
      const issueId = message.attributes.issue_id
      const issue = state.issues[issueId]

      const updatedIssue = {
        ...issue,
        relationships: {
          messages: {
            data: [
              ...(issue.relationships?.messages?.data || []),
              {
                type: 'messages',
                id: message.id
              }
            ]
          }
        }
      }
      const issues = { ...state.issues, [issueId]: updatedIssue }

      return u(
        {
          issues: { ...issues },
          messages: { ...state.messages, ...action.messages }
        },
        state
      )
    }

    case UPDATE_ISSUE: {
      return u(
        {
          issues: {
            ...state.issues,
            [action.issue.id]: action.issue
          }
        },
        state
      )
    }

    default: {
      return state
    }
  }
}
