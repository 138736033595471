import React from 'react'
import { connect } from 'react-redux'
import _isEmpty from 'lodash/isEmpty'

import panelLocaleService from 'services/panel_locale_service'
import { getCurrentSectionForOutline } from 'reducers/selectors'
import { closeModal } from 'actions/modal_actions'
import { showPanel } from 'actions/panel_actions'

import Modal from '../modal'

interface Props {
  closeModal: typeof closeModal
  section: any
  isActive: boolean
  showPanel: typeof showPanel
}

class SeeAllSectionsModal extends React.Component<Props> {
  renderChapter(chapter) {
    function handleChapterClick(event) {
      event.preventDefault()

      this.props.showPanel({
        panel: chapter.panels[0],
        via: 'SeeAllSectionsModal'
      })

      this.props.closeModal()
    }

    return (
      <a href="#" onClick={handleChapterClick.bind(this)}>
        <div className="o-box o-box--compact">
          <span className="o-block c-type c-type--subhead-sm">
            {panelLocaleService.t(`${chapter.chapter_key}.title`)}
          </span>
        </div>
      </a>
    )
  }

  renderChapters() {
    return this.props.section.chapters.map((chapter, i) => {
      return (
        !chapter.hidden &&
        !_isEmpty(chapter.panels) && (
          <li key={chapter.name}>
            {i !== 0 && <hr className="c-divider" />}
            {this.renderChapter(chapter)}
          </li>
        )
      )
    })
  }

  render() {
    return (
      <Modal isActive={this.props.isActive} title="All Sections">
        {() => <ul className="o-block o-list-bare">{this.renderChapters()}</ul>}
      </Modal>
    )
  }
}

function mapStateToProps(state) {
  return {
    section: getCurrentSectionForOutline(state)
  }
}

function mapDispatchToActions(dispatch) {
  return {
    closeModal: (...args) => dispatch(closeModal(...args)),
    showPanel: (...args) => dispatch(showPanel(...args))
  }
}

export default connect(mapStateToProps, mapDispatchToActions)(SeeAllSectionsModal)
