import Decorator from 'lib/decorator'
import _some from 'lodash/some'
import { isPublicChargeDisabled } from 'lib/feature_flags_deprecated'

export default class NewMBGCModelData extends Decorator {
  notMarried(): boolean {
    return this.eligibility.is_married === false // B20-1267
  }

  notMarriedButHasIntentToMarry(): boolean {
    return this.eligibility.is_married === false && this.eligibility.intent_to_marry === true // B20-1267
  }

  notMarriedHasNoIntentToMarry(): boolean {
    return this.eligibility.is_married === false && this.eligibility.intent_to_marry === false // B20-1267
  }

  sponsorIsLpr(): boolean {
    return this.get('sponsor.legal_status.code') === 'lpr' // B20-1267
  }

  sponsorIsUsc(): boolean {
    return this.get('sponsor.legal_status.code') === 'usc' // B20-1267
  }

  otherSponsorLegalStatus(): boolean {
    return this.get('sponsor.legal_status.code') === 'other' // B20-1267
  }

  criminalBackground(): boolean {
    return this.eligibility.has_criminal_background === true // B20-1267
  }

  isIneligibleDueToAOSBeneLPRSponsor(): boolean {
    return this.beneInUSSponsorIsLpr()
  }

  beneInUSSponsorIsLpr(): boolean {
    return this.beneficiaryInUS() && this.sponsorIsLpr()
  }

  invalidVisa(): boolean {
    return this.eligibility.has_valid_visa === false // B20-1267
  }

  shouldShowPublicBenefitsQuestion(): boolean {
    return this.isConcurrent() && !isPublicChargeDisabled()
  }

  hasUsedPublicBenefits(): boolean {
    return this.eligibility.has_used_public_benefits === true // B20-1267
  }

  incomeQuestionFailed(): boolean {
    // B20-1267
    return this.household.meets_income_requirement === false || this.household.income_question === 'less_than_minimum'
  }

  noAdditionalSponsors(): boolean {
    return this.household.has_additional_sponsors === false // B20-1267
  }

  noAdditionalAssets(): boolean {
    return this.household.has_additional_assets === false // B20-1267
  }

  incomeRequirementFailed(): boolean {
    return this.household.does_not_meet_income_requirement === true
  }

  setupIneligible(): boolean {
    return (
      this.notMarriedHasNoIntentToMarry() ||
      this.criminalBackground() ||
      this.invalidVisa() ||
      this.otherSponsorLegalStatus() ||
      this.incomeRequirementFailed() ||
      this.hasUsedPublicBenefits()
    )
  }

  eligibilityOutcome(): string {
    return this.setupIneligible() ? 'setup_ineligible' : 'setup_eligible'
  }

  petitionOutcome(): string {
    return 'petition_final'
  }

  PublicChargeEstimatorOutcome(): string {
    return 'public_charge_estimator_completed'
  }

  hasLawEnforcementHistory(): boolean {
    // B20-1267
    return this.beneficiary.law_enforcement_history === true || this.sponsor.law_enforcement_history === true
  }

  sponsorRole(): boolean {
    return this.get('account.role') === 'sponsor' // B20-1267
  }

  beneficiaryRole(): boolean {
    return this.get('account.role') === 'beneficiary' // B20-1267
  }

  sponsorLPR(): boolean {
    return this.get('sponsor.legal_status.code') === 'lpr' // B20-1267
  }

  sponsorCitizen(): boolean {
    return this.get('sponsor.legal_status.code') === 'usc' // B20-1267
  }

  sponsorCurrentlyUnemployed() {
    return this.get('sponsor.current_employment.unemployed') === true
  }

  sponsorNoPetitionHistory() {
    return !this.get('sponsor.petition_history') // B20-1267
  }

  beneficiaryCurrentAddressUS() {
    return this.get('beneficiary.current_address.country.code') === 'US'
  }

  coupleCohabitate() {
    return this.computed_currently_live_together === true
  }

  neverLivedTogether() {
    return this.get('account.not_lived_together') === true
  }

  beneficiaryHasOutsideUSAddress() {
    return _some(this.beneficiary.addresses || [], ({ country }) => country && country.code && country.code !== 'US')
  }

  beneficiaryCurrentlyUnemployed() {
    return this.get('beneficiary.current_employment.unemployed') === true
  }

  beneficiaryHasOutsideUSEmployment() {
    const outsideUSEmpl = _some(
      this.beneficiary.employments || [],
      ({ address }) => address && address.country && address.country.code && address.country.code !== 'US'
    )

    return outsideUSEmpl
  }

  beneficiaryHasIndicatedNoOutsideUSEmployment() {
    return (
      Boolean(this.beneficiary.outside_employment) &&
      this.beneficiary.outside_employment.has_previous_employment !== 'yes'
    )
  }

  togetherAddressIfAnyIsNotPast() {
    return this.coupleCohabitate() || this.neverLivedTogether()
  }

  beneficiaryOutsideEmploymentIfAnyIsNotPast() {
    return this.beneficiaryHasOutsideUSEmployment() || this.beneficiaryHasIndicatedNoOutsideUSEmployment()
  }

  beneficiaryInUS(): boolean {
    return this.eligibility.beneficiary_not_in_us === false // B20-1267
  }

  hasNoFurtherPreviousEmployment({ path }): boolean {
    const employment = this.get(path)

    return employment && employment.has_previous_employment !== 'yes'
  }

  isConcurrent(): boolean {
    return this.get('computed_workflow_category') === 'AOS'
  }

  isConsular(): boolean {
    return this.get('computed_workflow_category') === 'CP'
  }

  hasEmploymentBasedGC(): boolean {
    return this.get('public_charge_estimator_proto.employment_based_gc') === 'employment_gc'
  }
}
