import MBGCModelData from './mbgc_decorator'
import NewMBGCModelData from './new_mbgc_decorator'
import NatzModelData from './natz_decorator'
import K1FianceVisaData from './k1_fiance_decorator'
import { ModelDataObject } from 'reducers/model'
import Decorator from 'lib/decorator'

interface DecoratorFunctions {
  [kaseKind: string]: {
    (data: ModelDataObject): Decorator
  }
}

const decoratorFunctions: DecoratorFunctions = {
  MarriageBasedGreenCard: (data: ModelDataObject) => new MBGCModelData(data),
  NewMarriageBasedGreenCard: (data: ModelDataObject) => new NewMBGCModelData(data),
  Naturalization: (data: ModelDataObject) => new NatzModelData(data),
  K1FianceVisa: (data: ModelDataObject) => new K1FianceVisaData(data)
}

const decorate = (data: ModelDataObject, kaseKind: string): any => {
  const decoratorFn = decoratorFunctions[kaseKind] || (() => new Decorator(data))

  return decoratorFn(data)
}

export default decorate
