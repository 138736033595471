import admissionClasses from 'data/admission_classes.json'
import _reduce from 'lodash/reduce'

export const formattedAdmissionClasses = _reduce(
  admissionClasses,
  (formattedClasses, desc, code) => {
    formattedClasses[code] = `${code} - ${desc}`
    return formattedClasses
  },
  {}
)
