import React from 'react'

import { QuestionnaireInputContainerProps } from 'components/forms/inputs'

import ValidatedInput from 'components/forms/validated_input'
import { joinI18nKeys } from 'lib/i18n'
import panelLocaleService from 'services/panel_locale_service'

import TextAreaInput from './textarea_input'

export default class Textarea extends React.Component<QuestionnaireInputContainerProps> {
  getPlaceholder() {
    const { placeholder, resourceKeys, name } = this.props
    const i18nPlaceholderKey = joinI18nKeys(resourceKeys, `${name}.placeholder`)

    let i18nPlaceholder
    if (panelLocaleService.exists(i18nPlaceholderKey)) {
      i18nPlaceholder = panelLocaleService.t(i18nPlaceholderKey)
    }

    return placeholder || i18nPlaceholder || 'Please type here...'
  }

  render() {
    const { disabled, id, path } = this.props

    return (
      <ValidatedInput disabled={disabled} path={path}>
        {({ fireValidation, getValidationClasses, renderValidations }) => (
          <div
            className={`o-flag o-grid--fluid o-grid--stack@sm o-block c-paper-form__group ${getValidationClasses()}`}
          >
            <TextAreaInput
              className="c-paper-form__control"
              disabled={disabled}
              id={id}
              name={name}
              onBlur={fireValidation}
              path={path}
              placeholder={this.getPlaceholder()}
            />
            {renderValidations()}
          </div>
        )}
      </ValidatedInput>
    )
  }
}
