export const CLASS_OF_ADMISSION_CODES = {
  A1: 'A1 - AMBASSADOR, DIPLOMAT',
  A2: 'A2 - OTHER DIPLOMATIC OFFICIALS',
  A3: 'A3 - ATTENDANTS OF A-1, A-2',
  AS: 'AS - ASYLUM',
  ASD: 'ASD - ASYLUM STATUS DENIED',
  AW: 'AW - RAW APPLIED FOR AT A PORT',
  B1: 'B1 - TEMPORARY VISITOR FOR BUSINESS',
  B1A: 'B1A - PERSNL-DOM SRVANT OF NI EMP',
  B1B: 'B1B - DOMESTIC SERVANT OF USC',
  B1C: 'B1C - EMPLOYED BY FOREIGN AIRLINE',
  B1D: 'B1D - MISSIONARIES',
  B2: 'B2 - TEMPORARY VISITOR FOR PLEASURE',
  BE: 'BE - BERING STRAIT ENTRIES',
  C1: 'C1 - ALIEN IN TRANSIT THROUGH U.S.',
  C2: 'C2 - ALIEN IN TRANSIT TO UN HQ',
  C3: 'C3 - FRN GOV OFF IN TRANSIT THRU US',
  C4: 'C4 - TRANSIT WITHOUT A VISA',
  CC: 'CC - CUBAN MASS MIGRATION PROJECT',
  CH: 'CH - PAROLEE (HUMANITARIAN-HQ AUTH)',
  CP: 'CP - PAROLEE (PUBLIC INT-HQ AUTH)',
  CW1: 'CW1 - PRINCIPAL TRANSITIONAL WORKERS',
  CW2: 'CW2 - DEPENDENT OF CW1',
  D1: 'D1 - ALIEN CREW DEPART SAME VESSEL',
  D2: 'D2 - ALIEN CREW DEPART OTHER VESSEL',
  DA: 'DA - ADVANCE PAROLE (DISTRICT AUTH)',
  DACA: 'DACA - DEFERRED ACTION FOR CHILDHOOD ARRIVALS',
  DE: 'DE - PAROLEE (DEFERRED INSPECTION)',
  DT: 'DT - PAROLEE (DISTRICT-POE AUTH)',
  DX: 'DX - CREW ARRIVING DETAINED ON SHIP',
  E1: 'E1 - TREATY TRADER-SPOUSE-CHILDREN',
  E2: 'E2 - TREATY INVESTOR-SPOUSE-CHILD',
  E2C: 'E2C - CNMI INVESTOR',
  E3: 'E3 - AUSTRALIA FREE TRADE AGREEMENT',
  EAO: 'EAO - EMPLOYMENT ADVISORY OPTION',
  EWI: 'EWI - ENTRY WITHOUT INSPECTION',
  F1: 'F1 - STUDENT - ACADEMIC',
  F2: 'F2 - SPOUSE-CHILD OF F-1',
  F2A: 'F2A - FAMILY PREFERENCE',
  FSM: 'FSM - CFA ADM FED STATES MICRONESIA',
  FUG: 'FUG - FAMILY UNITY GRANTED',
  G1: 'G1 - PRINCIPAL REP. FOREIGN GOVT',
  G2: 'G2 - OTHER REP FOREIGN GOVT',
  G3: 'G3 - REP NON-RECOGNIZED FOREIGN GOV',
  G4: 'G4 - OFFICER-EMPLOYEE INTL. ORG.',
  G5: 'G5 - ATTENDANTS OF G1, G2, G3, G4',
  GB: 'GB - VISITOR WITHOUT A VISA 15 DAYS',
  GT: 'GT - VISITOR WITHOUT A VISA 15 DAYS',
  H1: 'H1 - ALIEN OF DIST MERIT &amp; ABILITY',
  H1A: 'H1A - REGISTERED NURSE',
  H1B: 'H1B - SPECIALTY OCCUPATION',
  H1B1: 'H1B1 - SPECIALTY OCCUPATION',
  H1B2: 'H1B2 - DoD SPECIALTY',
  H1B3: 'H1B3 - FASHION MODEL',
  H1B4: 'H1B4 - UNIQUE PGM ARTIST-ENT',
  H1B5: 'H1B5 - ALIEN ATHLETE',
  H1BS: 'H1BS - SUPPORT PERSON OF H-1',
  H1C: 'H1C - NURSE RELIEF',
  H2: 'H2 - TEMPORARY LABOR CERTIFICATION',
  H2A: 'H2A - TEMPORARY AGRICULTURAL WORKER',
  H2B: 'H2B - TEMPORARY NON-AG WORKER',
  H2R: 'H2R - RET(H2B)WRKR NOT SUBJCT TO CAP',
  H3: 'H3 - ALIEN TRAINEE',
  H3A: 'H3A - TRAINEE',
  H3B: 'H3B - SPECIAL EDUCATION TRAINING',
  H4: 'H4 - SPS OR CHLD OF H1,H2,H3 OR H2R',
  HSC: 'HSC - FREE TRADE H1B1',
  I: 'I - FOREIGN PRESS',
  IMM: 'IMM - IMMIGRANT',
  IN: 'IN - INDEFINITE PAROLE',
  J1: 'J1 - EXCHANGE VISITOR - OTHERS',
  J1S: 'J1S - EXCHANGE VISITOR - STUDENT',
  J2: 'J2 - SPOUSE-CHILD OF J-1',
  J2S: 'J2S - SPOUSE-CHILD OF J-1S',
  K1: 'K1 - ALIEN FIANCE(E) OF USC',
  K2: 'K2 - CHILD OF K1',
  K3: 'K3 - SPOUSE OF USC',
  K4: 'K4 - CHILD OF USC',
  L1: 'L1 - INTRA-COMPANY TRANSFEREE',
  L1A: 'L1A - MANAGER OR EXECUTIVE',
  L1B: 'L1B - SPECIALIZED KNOWLEDGE ALIEN',
  L2: 'L2 - SPOUSE-CHILD OF L-1',
  LZ: 'LZ - BLANKET L PETITION',
  M1: 'M1 - STUDENT - VOCATIONAL-NON-ACAD.',
  M2: 'M2 - SPOUSE-CHILD OF M-1',
  MIS: 'MIS - CFA ADM REP MARSHALL ISLANDS',
  ML: 'ML - PAROLEE-MEDICAL, LEGAL, HUMAN',
  N1: 'N1 - PRINCIPAL REP. OF NATO MEMBER',
  N2: 'N2 - OTHER REP. OF NATO MEMBER',
  N3: 'N3 - CLERICAL STAFF FOR N-1, N-2',
  N4: 'N4 - OFFICIALS OF NATO',
  N5: 'N5 - EXPERTS EMPLOYED BY NATO',
  N6: 'N6 - CIVILIAN COMPONENT OF NATO',
  N7: 'N7 - ATTENDANTS OF N-1 THROUGH N-6',
  N8: 'N8 - PARENT OF SPEC IMMIGRANT CHILD',
  N9: 'N9 - SPOUSE-CHILD OF N8',
  O1: 'O1 - ALIEN W-EXTRAORDINARY ABILITY',
  O1A: 'O1A - EXTRAORDINARY ALIEN - NON-ARTS',
  O1B: 'O1B - EXTRAORDINARY ALIEN IN ARTS',
  O2: 'O2 - ACCOMPANYING ALIEN TO O1',
  O3: 'O3 - SPOUSE-CHILD OF O-1, O-2',
  OP: 'OP - PAROLEE (OVERSEAS AUTHORIZED)',
  P1: 'P1 - ATHLETE OR ENTERTAINER',
  P1A: 'P1A - ALIEN WITH ATHLETIC EVENT',
  P1B: 'P1B - ALIEN WITH ENTERTAINMENT GROUP',
  P1S: 'P1S - SUPPORT PERSON OF P-1',
  P2: 'P2 - EXHANGE ARTIST-ENTERTAINER',
  P2S: 'P2S - SUPPORT PERSON OF P-2',
  P3: 'P3 - UNIQUE PGM ARTIST-ENTERTAINER',
  P3S: 'P3S - SUPPORT PERSON OF P-3',
  P4: 'P4 - SPOUSE-CHILD OF P-1, P-2, P-3',
  PAL: 'PAL - CFA ADMISSION PALAU',
  PAR: 'PAR - PAROLEE',
  PI: 'PI - PACIFIC ISLANDER',
  Q1: 'Q1 - INTL CULTURAL XCHG VISITORS',
  Q2: 'Q2 - IRISH PEACE PROCESS PARTICPNTS',
  Q3: 'Q3 - SPOUSE-CHILD OF Q2',
  R1: 'R1 - RELIGIOUS OCCUPATION',
  R2: 'R2 - SPOUSE-CHILD OF R-1',
  RE: 'RE - REFUGEE',
  RE5: 'RE5 - HAITIAN W-GRANTED REFUGEE STAT',
  RW: 'RW - RAW APPLIED FOR AT A US CO',
  S1: 'S1 - SPECIAL AGRICULTURAL WORKER',
  S2: 'S2 - SPECIAL AGRICULTURAL WORKER',
  S9: 'S9 - EMERGENCY FARM WORKER',
  SDF: 'SDF - SUSPECTED DOCUMENT FRAUD',
  ST: 'ST - STOWAWAY',
  T1: 'T1 - VICTIM OF SEVERE FORM OF TRAFK',
  T2: 'T2 - SPOUSE OF T1',
  T3: 'T3 - CHILD OF T1',
  T4: 'T4 - PARENT OF T1',
  T5: 'T5 - UNMARRIED UNDER 18 SIBLG T1 NI',
  TB: 'TB - SPOUSE OR CHILD OF CAN. FR',
  TC: 'TC - CANADIAN FREE TRADE AGREEMENT',
  TD: 'TD - NAFTA DEPENDENT',
  TN1: 'TN1 - NAFTA PRINCIPAL (CANADA)',
  TN2: 'TN2 - NAFTA PRINCIPAL (MEXICO)',
  TWO: 'TWO - TRANSIT WITHOUT A VISA',
  U1: 'U1 - VICTIM OF CRIMINAL ACTIVITY',
  U2: 'U2 - SPOUSE OF U1',
  U3: 'U3 - CHILD OF U1',
  U4: 'U4 - PARENT OF U1',
  U5: 'U5 - UNMARRIED UNDER 18 SIBLG U1 NI',
  UN: 'UN - UNKNOWN',
  UU: 'UU - UNKNOWN',
  V1: 'V1 - SPOUSE OF LPR',
  V2: 'V2 - CHILD OF LPR',
  V3: 'V3 - CHILD OF V2',
  WB: 'WB - VISITOR FOR BUSINESS - VWPP/VWP',
  WD: 'WD - WITHDRAWL (I-275)',
  WI: 'WI - WITHOUT INSPECTION',
  WT: 'WT - VISITOR FOR PLEASURE - VWPP',
  X: 'X - EOIR'
}
