import React from 'react'

import Paragraph from 'components/type/paragraph'
import ExternalLink from 'components/external_link'
import { JOB_TITLE_OPTIONS } from 'lib/constants'

export const CP_MILESTONES = [
  {
    title: 'Form I-130 Approval',
    description: (
      <Paragraph>
        After you mail your package to U.S. Citizenship and Immigration Services (USCIS), it’ll typically take{' '}
        <strong>
          <ExternalLink href="https://www.boundless.com/immigration-resources/how-long-does-marriage-green-card-take/#currently-living-abroad">
            7–15 months
          </ExternalLink>{' '}
          to receive their decision
        </strong>{' '}
        on{' '}
        <ExternalLink href="https://www.boundless.com/immigration-resources/form-i-130-explained/">
          Form I-130
        </ExternalLink>{' '}
        (family sponsorship petition) and move on to the next stage. Once you’re approved, please notify your{' '}
        {JOB_TITLE_OPTIONS.JOB_TITLE_STEWARD} via email. You’ll then receive a welcome email or letter from the National
        Visa Center (NVC) typically about 7–8 weeks following approval.
      </Paragraph>
    )
  },
  {
    title: 'Forms and Documents',
    description: (
      <Paragraph>
        Your relationship with your independent attorney will restart so they can help guide you through{' '}
        <ExternalLink href="https://www.boundless.com/immigration-resources/the-ds260-and-ds261-online-forms/">
          Form DS-260
        </ExternalLink>{' '}
        (online green card application) and document gathering. <strong>Completion time for this step varies</strong>{' '}
        based on how quickly you upload{' '}
        <ExternalLink href="https://www.boundless.com/immigration-resources/what-documents-for-marriage-green-card/#online-green-card-application-form-ds260">
          supporting documents
        </ExternalLink>
        . (You’ll receive more detailed instructions via email when you reach this step.)
      </Paragraph>
    )
  },
  {
    title: 'Checks and Reviews',
    description: (
      <Paragraph>
        Your application will undergo multiple levels of checks and reviews: a Quality Assurance check by our team, a
        full legal review by your independent attorney, and a final check by you, which collectively take about{' '}
        <strong>2–3 weeks to complete</strong>. This is to help ensure that your application has the best chance of
        success.
      </Paragraph>
    )
  },
  {
    title: 'Finalize and Ship',
    description: (
      <Paragraph>
        We’ll prepare a digital or printed copy (depending on your U.S. consulate) of your final application package and
        send it to you. It takes{' '}
        <strong>1–2 business days for printing or digital assembly, plus 2 business days</strong> for delivery to a
        domestic (U.S.) address or <strong>3–4 business days</strong> to an international (non-U.S.) address.
      </Paragraph>
    )
  },
  {
    title: 'In-Person Interview and Medical Exam',
    description: (
      <Paragraph>
        We'll help you
        <ExternalLink href="https://www.boundless.com/immigration-resources/preparing-for-the-marriage-green-card-interview/">
          prepare for your in-person interview
        </ExternalLink>
        . The interview typically happens <strong>3–4 months after the NVC approves all your documents</strong>. As soon
        as you receive your Interview Appointment Notice, you’ll schedule a{' '}
        <ExternalLink href="https://www.boundless.com/immigration-resources/preparing-for-the-medical-exam/">
          medical exam
        </ExternalLink>{' '}
        and prepare all original copies of the documents you already submitted. You’ll bring the originals and
        medical-exam results to your interview.
      </Paragraph>
    )
  },
  {
    title: 'Receive a Decision',
    description: (
      <Paragraph>
        You can expect to receive a decision on your application either the same day as your interview or, more
        typically, <strong>about 1–2 weeks later</strong>. If you’re approved, you’ll receive your physical green card
        in the mail
        <strong>about 3–4 weeks</strong> after arriving in the United States! Fingers crossed!
      </Paragraph>
    )
  }
]

export const AOS_MILESTONES = [
  {
    title: 'Receive USCIS Receipt Numbers',
    description: (
      <Paragraph>
        You can expect to receive your official{' '}
        <ExternalLink href="https://www.boundless.com/immigration-resources/how-to-check-green-card-status/#receipt-numbers">
          Receipt Numbers
        </ExternalLink>{' '}
        via mail, typically <strong>about 2–3 weeks</strong> after mailing your package to U.S. Citizenship and
        Immigration Services (USCIS). We’ll be notified, too.
      </Paragraph>
    )
  },
  {
    title: 'Attend Biometrics Appointment',
    description: (
      <Paragraph>
        You’ll receive a notice about your{' '}
        <ExternalLink href="https://www.boundless.com/immigration-resources/biometrics-appointment/">
          biometrics appointment
        </ExternalLink>{' '}
        typically <strong>about 1 month</strong> after USCIS receives your application package. The appointment usually
        takes place at the{' '}
        <ExternalLink href="https://www.uscis.gov/about-us/find-uscis-office/field-offices">
          USCIS field office
        </ExternalLink>{' '}
        closest to where you live and is usually low-stress — USCIS will simply take fingerprints and photos of the
        spouse seeking a green card for background and security checks.
      </Paragraph>
    )
  },
  {
    title: 'Receive Work and Travel Permits',
    description: (
      <>
        <Paragraph>
          You’ll receive your{' '}
          <ExternalLink href="https://www.boundless.com/immigration-resources/the-work-permit-explained/">
            work permit
          </ExternalLink>{' '}
          and{' '}
          <ExternalLink href="https://www.boundless.com/immigration-resources/the-advance-parole-travel-document-explained/">
            travel permit
          </ExternalLink>{' '}
          typically about <strong>5–8 months</strong> after USCIS receives your application package, followed by your
          Social Security Number, which typically arrives <strong>about 2 weeks</strong> later.
        </Paragraph>
        <Paragraph>
          <strong>IMPORTANT</strong>: While the green card application is pending, a spouse seeking a green card must
          not travel anywhere outside the United States until they receive their travel permit.
        </Paragraph>
      </>
    )
  },
  {
    title: 'Attend In-Person Interview',
    description: (
      <Paragraph>
        You’ll receive an Interview Appointment Notice — listing the date, time, and location of your in-person
        interview — typically <strong>about 10–14 months</strong> after receiving your Receipt Numbers. As soon as you
        notify your {JOB_TITLE_OPTIONS.JOB_TITLE_STEWARD} via email of your interview appointment, they’ll send you a
        detailed guide to help you{' '}
        <ExternalLink href="https://www.boundless.com/immigration-resources/preparing-for-the-marriage-green-card-interview/">
          prepare for a successful interview
        </ExternalLink>
        .
      </Paragraph>
    )
  },
  {
    title: 'Receive a Decision',
    description: (
      <Paragraph>
        You can expect to receive a decision on your application either the same day as your interview or, more
        typically, about <strong>1–3 weeks later</strong>. If you’re approved, you’ll receive your physical green card
        in the mail <strong>about 2–4 weeks</strong> after that! Fingers crossed!
      </Paragraph>
    )
  }
]
