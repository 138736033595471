import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'

import { getCurrentKaseId } from 'reducers/selectors'
import api from 'lib/api'

export const PRIMARY_CTA_UPLOAD_DOCUMENTS = 'Upload Documents'
export const PRIMARY_CTA_RESOLVE_COMMENTS = 'Resolve Comments'

interface MappedProps {
  kaseId: number
}

interface ExplicitProps {
  hasUnresolvedComments?: boolean
  finalReview?: boolean
}

type Props = MappedProps & ExplicitProps

const UploadPhasePrimaryCTA: FunctionComponent<Props> = ({ hasUnresolvedComments, finalReview, kaseId }) => {
  const uploadDocumentsUrl = api.routes.uploadDocumentsUrl(kaseId)

  return (
    <div>
      {!finalReview && (
        <a href={uploadDocumentsUrl} rel="noopener noreferrer" className="c-btn c-btn--emphasized">
          {hasUnresolvedComments ? PRIMARY_CTA_RESOLVE_COMMENTS : PRIMARY_CTA_UPLOAD_DOCUMENTS}
        </a>
      )}
    </div>
  )
}

function mapStateToProps(state) {
  return {
    kaseId: getCurrentKaseId(state)
  }
}

export default connect(mapStateToProps)(UploadPhasePrimaryCTA)
