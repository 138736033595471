import React, { FunctionComponent, useEffect, useState } from 'react'
import { ApplicationState } from 'reducers'
import { connect } from 'react-redux'
import {
  getDocRequestsLeftBeforeThreshold,
  getInitialThresholdReached,
  groupSortedDocumentRequests,
  slaIsSet
} from 'reducers/selectors'
import CollapsibleOutlineSection from './collapsible_outline_section'
import Heading from 'components/type/heading'
import NextStepButton from './next_step_button'
import ProcessModal from './process_modal'
import { DocumentRequestSection } from 'reducers/cross_domain_selectors'

interface ExplicitProps {
  /**
   * The document request that's currently displayed.
   */
  activeRequest?: DocumentRequestModel
  /**
   * Callback that's triggered when a document request is clicked.
   */
  onClick: Function
  documentRequests: DocumentRequestModel[]
}

interface MappedProps {
  requestCountLeftBeforeThreshold: number
  initialThresholdReached: boolean
  groupedDocumentRequests: DocumentRequestSection[]
  slaIsSet: boolean
}

const DocumentUploadOutline: FunctionComponent<ExplicitProps & MappedProps> = ({
  requestCountLeftBeforeThreshold = 0,
  initialThresholdReached,
  documentRequests,
  onClick,
  activeRequest,
  groupedDocumentRequests,
  slaIsSet
}) => {
  const [displayProcessModal, toggleDisplayProcessModal] = useState(false)
  const [activeCategory, setActiveCategory] = useState(null)

  const activeRequestId = activeRequest != null ? activeRequest.id : null

  const onRequestClicked = (requestId: string) => {
    if (activeRequest == null || requestId !== activeRequest.id) {
      onClick(requestId)
    }
  }

  const onSectionClicked = (category: string) => {
    if (category == null || category !== activeCategory) {
      setActiveCategory(category)
    } else if (category === activeCategory) {
      setActiveCategory(null)
    }
  }

  const isActiveCategory = (category) => category === activeCategory

  /**
   * Default section to "active" when any of the doc requests within it
   * are active (ie displayed to the user).
   * Here, we figure out whether this section is active by concatenating all
   * the doc requests (nested or not) into an array and looking up the active
   * using its ID.
   **/
  const sectionHasActiveDocRequest = (section) => {
    const allRequests = []

    if (section.documents != null) {
      allRequests.push(...section.documents)
    }

    if (section.nestedDocuments != null) {
      section.nestedDocuments.forEach((category) => {
        allRequests.push(...category.documentRequests)
      })
    }

    return !!allRequests.find((request) => request.id === activeRequestId)
  }

  const getSectionWithActiveDocRequest = () => {
    const activeSection = groupedDocumentRequests.find((section) => sectionHasActiveDocRequest(section))

    return activeSection ? activeSection.category : null
  }

  useEffect(() => {
    setActiveCategory(getSectionWithActiveDocRequest())
  }, [groupedDocumentRequests])

  return (
    <div className="o-action-items__outline px-3 lg:px-6">
      <ProcessModal
        initialThresholdReached={initialThresholdReached}
        isOpen={displayProcessModal}
        closeModal={() => toggleDisplayProcessModal(false)}
        thresholdForFirstReview={requestCountLeftBeforeThreshold}
      />
      <div className="o-griddle__row">
        <div className="o-griddle__col">
          <Heading
            spacing="sm"
            tag="h4"
            subhead
            size="sm"
            style={{
              paddingLeft: '1rem'
            }}
          >
            Next Step
          </Heading>
          <NextStepButton documentRequests={documentRequests} onClick={() => toggleDisplayProcessModal(true)} />
        </div>
      </div>

      {groupedDocumentRequests.map((section) => (
        <CollapsibleOutlineSection
          key={section.category}
          title={section.title}
          documentRequests={section.documents}
          nestedDocumentRequestCategories={section.nestedDocuments}
          onRequestClicked={onRequestClicked}
          activeRequestId={activeRequestId}
          slaIsSet={slaIsSet}
          isActiveCategory={isActiveCategory(section.category)}
          onSectionClicked={() => onSectionClicked(section.category)}
        />
      ))}
    </div>
  )
}

function mapStateToProps(state: ApplicationState) {
  return {
    requestCountLeftBeforeThreshold: getDocRequestsLeftBeforeThreshold(state),
    initialThresholdReached: getInitialThresholdReached(state),
    groupedDocumentRequests: groupSortedDocumentRequests(state),
    slaIsSet: slaIsSet(state)
  }
}

export default connect(mapStateToProps, null)(DocumentUploadOutline) as FunctionComponent<ExplicitProps>
