import React, { ChangeEventHandler, FocusEventHandler, FunctionComponent } from 'react'
import CommonLabelV2 from './address/common/common_label_v2'

import embassyCities from 'data/embassy_cities.json'

import CommonSelectV2 from './address/common/common_select_v2'

interface Props {
  label?: string
  value?: string
  kind?: string
  onChangeEvent: ChangeEventHandler
  onBlurEvent: FocusEventHandler
  disabled?: boolean
}

const defaultProps = {
  label: 'location',
  value: '',
  kind: 'mbgc'
}

const EmbassyCitiesInputV2: FunctionComponent<Props> = ({
  label,
  value,
  kind,
  onChangeEvent,
  onBlurEvent,
  disabled
}) => {
  const embassyCityOptions = {
    '': 'Please select a location',
    ...embassyCities[kind]
  }

  return (
    <div className="o-grid--fluid o-grid--stack@sm o-block c-paper-form__group">
      <CommonLabelV2 label={label} labelFor="embassyCities" />
      <CommonSelectV2
        inputName="embassy_city"
        value={value ? value : ''}
        options={embassyCityOptions}
        containerClass="c-paper-form__control"
        onChangeEvent={onChangeEvent}
        onBlur={onBlurEvent}
        disabled={disabled}
      />
    </div>
  )
}

EmbassyCitiesInputV2.defaultProps = defaultProps

export default EmbassyCitiesInputV2
