import React from 'react'

import PanelErrors from '../panel_errors'

export default class NaturalizationIneligible extends React.Component {
  render() {
    return (
      <div className="o-grid__row">
        <div className="o-grid__col-6 o-grid__col--offset-1">
          <h1 className="o-block o-block--ample c-type c-type--headline-md c-type--emphasized">
            Unfortunately, we cannot help you at this time.
          </h1>

          <PanelErrors />

          <p className="o-block c-type c-type--body-sans-md">
            If you disagree with this result, email us at <a href="mailto:help@boundless.com">help@boundless.com</a> to
            talk about this further.
          </p>
        </div>
      </div>
    )
  }
}
