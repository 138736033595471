import _cloneDeep from 'lodash/cloneDeep'
import _pullAt from 'lodash/pullAt'

import {
  UPLOAD_ERROR,
  UPLOAD_FINISHED,
  UPLOADING_REQUESTED_FILES,
  UPLOAD_REQUESTED_FILE_PROGRESS,
  UPLOAD_REQUESTED_FILE_FINISHED
} from 'lib/constants'

import { fileId } from 'lib/file_uploads'

function requestUpload(rawFile, documentRequest, documentRequestPartId) {
  return {
    id: fileId(rawFile),
    original_filename: rawFile.name,
    rawFile: rawFile,
    uploadErrors: [],
    progress: 0,
    status: null,
    documentRequestPath: documentRequest.path,
    documentRequestPartId: documentRequestPartId
  }
}

const initialState = {
  collection: [],
  requestUploads: [],
  isFileUploading: false
}

export function fileIndex(collection, rawFile) {
  const id = fileId(rawFile)
  return collection.findIndex((file) => file.id === id)
}

export default function uploadsReducer(state, action) {
  state = state || initialState

  switch (action.type) {
    case UPLOADING_REQUESTED_FILES: {
      const files = action.files.map((file) =>
        requestUpload(file, action.documentRequest, action.documentRequestPartId)
      )

      return Object.assign({}, state, {
        isFileUploading: true,
        requestUploads: files
      })
    }

    case UPLOAD_REQUESTED_FILE_PROGRESS: {
      const index = fileIndex(state.requestUploads, action.rawFile)
      const file = Object.assign({}, state.requestUploads[index], {
        progress: action.progress,
        status: action.status
      })

      const files = _cloneDeep(state.requestUploads)
      files[index] = file

      return Object.assign({}, state, {
        isFileUploading: true,
        requestUploads: files
      })
    }

    case UPLOAD_ERROR: {
      const index = fileIndex(state.collection, action.rawFile)
      const file = Object.assign({}, state.collection[index], {
        uploadErrors: [action.message]
      })

      const files = _cloneDeep(state.collection)
      files[index] = file

      return Object.assign({}, state, {
        isFileUploading: false,
        collection: files
      })
    }

    case UPLOAD_FINISHED: {
      const index = fileIndex(state.collection, action.rawFile)
      const files = _cloneDeep(state.collection)
      _pullAt(files, index)

      return Object.assign({}, state, {
        isFileUploading: false,
        collection: files
      })
    }

    case UPLOAD_REQUESTED_FILE_FINISHED: {
      const index = fileIndex(state.requestUploads, action.rawFile)
      const files = _cloneDeep(state.requestUploads)
      _pullAt(files, index)

      return Object.assign({}, state, {
        isFileUploading: false,
        requestUploads: files
      })
    }

    default: {
      return state
    }
  }
}
