import React from 'react'
import { connect } from 'react-redux'
import _uniq from 'lodash/uniq'

import { joinI18nKeys } from 'lib/i18n'
import { clearPanelFields } from 'actions/panel_actions'
import { QuestionnaireInputContainerProps } from '../'
import panelLocaleService from 'services/panel_locale_service'

import CheckboxInput from './checkbox_input'

type Props = QuestionnaireInputContainerProps & {
  clearPanelFields: Function
  confirm: Nullable<string>
  label: Nullable<string>
  shouldClearPanelFields: boolean
  skip_field_clearing_for: string[]
}

class Checkbox extends React.Component<Props> {
  static defaultProps = {
    id: '',
    name: '',
    onChange: () => {},
    panelIndex: null,
    resourceKeys: [],
    skip_field_clearing_for: [],
    shouldClearPanelFields: false
  }

  clearFields = () => {
    if (this.props.shouldClearPanelFields) {
      this.props.clearPanelFields({
        excludeFields: this.fieldsExcludedFromClearing()
      })
    }
  }

  fieldsExcludedFromClearing() {
    return _uniq([this.props.name].concat(this.props.skip_field_clearing_for))
  }

  getLabel() {
    if (this.props.label) return this.props.label

    const options = this.props.panelIndex
      ? {
          index: this.props.panelIndex,
          parentIndex: this.props.parentIndex
        }
      : {}

    return panelLocaleService.t(joinI18nKeys(this.props.resourceKeys, `${this.props.name}.label`), options)
  }

  render() {
    const classes = 'o-flag o-grid--fluid o-grid--stack@sm o-block c-paper-form__group'

    const { id, name, path } = this.props

    return (
      <div className={classes}>
        <label className="o-block c-custom-control c-custom-control--check c-custom-control--multiline">
          <CheckboxInput afterChangeEvents={[this.clearFields]} id={id} name={name} path={path} />

          <span className="c-custom-control__description c-type c-type--body-sans-md">{this.getLabel()}</span>
        </label>
      </div>
    )
  }
}

function mapDispatchToActions(dispatch) {
  return {
    clearPanelFields: (...args) => dispatch(clearPanelFields(...args))
  }
}

export default connect(null, mapDispatchToActions)(Checkbox)
