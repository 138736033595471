import u from 'updeep'
import { LOADED_TASKS, LOADING_TASKS, UPDATED_TASK } from 'lib/constants'

export interface QaTaskMetadata {
  number_of_returns: number
}

export interface Task {
  type: string
  id: string
  attributes: {
    kind: string
    assignee_id: string | null
    created_at: string
    kase_id: string
    state: string
    metadata?: QaTaskMetadata
  }
}

export interface TasksById {
  [key: string]: Task
}

export interface TasksStore {
  tasks: TasksById
  loadingTasks?: boolean
}

const initialState: TasksStore = {
  tasks: {}
}

export default function tasksReducer(state: TasksStore, action: any): TasksStore {
  state = state || initialState
  switch (action.type) {
    case LOADING_TASKS: {
      return u(
        {
          loadingTasks: true
        },
        state
      )
    }

    case LOADED_TASKS: {
      return {
        ...state,
        tasks: action.tasks,
        loadingTasks: false
      }
    }

    case UPDATED_TASK: {
      return u(
        {
          tasks: { ...state.tasks, ...action.tasks },
          loadingTasks: false
        },
        state
      )
    }

    default: {
      return state
    }
  }
}
