import { PanelGraph } from './shared'

const setupPanelGraph: PanelGraph = {
  start: 'eligibility.start',
  panels: {
    'eligibility.start': {
      nextPanels: [
        {
          key: 'eligibility.green_card'
        }
      ]
    },
    'eligibility.green_card': {
      nextPanels: [
        {
          key: 'eligibility.no_green_card_more_info',
          condition: 'noGreenCard'
        },
        {
          key: 'eligibility.exemptions',
          condition: 'greenCardTooNew'
        },
        {
          key: 'eligibility.over18'
        }
      ]
    },
    'eligibility.no_green_card_more_info': {
      nextPanels: [
        {
          key: 'eligibility.ineligible'
        }
      ]
    },
    'eligibility.over18': {
      nextPanels: [
        {
          key: 'eligibility.exemptions',
          condition: 'under18'
        },
        {
          key: 'eligibility.married_to_citizen',
          condition: 'greenCardBetween3And5YearsOld'
        },
        {
          key: 'eligibility.time_outside_us'
        }
      ]
    },
    'eligibility.married_to_citizen': {
      nextPanels: [
        {
          key: 'eligibility.exemptions',
          condition: 'notMarriedToUSCitizen'
        },
        {
          key: 'eligibility.marriage_start'
        }
      ]
    },
    'eligibility.marriage_start': {
      nextPanels: [
        {
          key: 'eligibility.exemptions',
          condition: 'currentMarriageTooNew'
        },
        {
          key: 'eligibility.spouse_citizenship_start'
        }
      ]
    },
    'eligibility.spouse_citizenship_start': {
      nextPanels: [
        {
          key: 'eligibility.exemptions',
          condition: 'spouseCitizenshipTooRecent'
        },
        {
          key: 'eligibility.time_outside_us'
        }
      ]
    },
    'eligibility.time_outside_us': {
      nextPanels: [
        {
          key: 'eligibility.exemptions',
          condition: 'presenceRequirementNotMet'
        },
        {
          key: 'eligibility.long_trips_outside_us'
        }
      ]
    },
    'eligibility.long_trips_outside_us': {
      nextPanels: [
        {
          key: 'eligibility.latest_trip_return',
          condition: 'longTripsOutsideUs'
        },
        {
          key: 'eligibility.unpaid_taxes'
        }
      ]
    },
    'eligibility.latest_trip_return': {
      nextPanels: [
        {
          key: 'eligibility.exemptions',
          condition: 'tripRequirementsNotMet'
        },
        {
          key: 'eligibility.unpaid_taxes'
        }
      ]
    },
    'eligibility.exemptions': {
      nextPanels: [
        {
          key: 'eligibility.ineligible',
          condition: 'doesNotQualifyForExemption'
        },
        {
          key: 'eligibility.eligible_cannot_help'
        }
      ]
    },
    'eligibility.unpaid_taxes': {
      nextPanels: [
        {
          key: 'eligibility.ineligible',
          condition: 'hasUnpaidTaxes'
        },
        {
          key: 'eligibility.law_enforcement_history'
        }
      ]
    },
    'eligibility.law_enforcement_history': {
      nextPanels: [
        {
          key: 'eligibility.ineligible',
          condition: 'hasLawEnforcementHistory'
        },
        {
          key: 'eligibility.fee_waiver'
        }
      ]
    },
    'eligibility.fee_waiver': {
      nextPanels: [
        {
          key: 'eligibility.public_benefits',
          condition: 'feeWaiverQualificationForILRC'
        },
        {
          key: 'eligibility.eligible'
        }
      ]
    },
    'eligibility.public_benefits': {
      nextPanels: [
        {
          key: 'eligibility.household_size',
          condition: 'doesNotReceivePublicBenefits'
        },
        {
          key: 'eligibility.eligible'
        }
      ]
    },
    'eligibility.household_size': {
      nextPanels: [
        {
          key: 'eligibility.maximum_income'
        }
      ]
    },
    'eligibility.maximum_income': {
      nextPanels: [
        {
          key: 'eligibility.eligible'
        }
      ]
    }
  }
}

export default setupPanelGraph
