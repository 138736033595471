import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'

import { getCurrentKaseId } from 'reducers/selectors'

import Button from 'components/button'
import AirplaneIcon from 'components/icons/airplane_icon'
import ApprovedIdIcon from 'components/icons/approved_id_icon'
import ArrowIcon from 'components/icons/arrow_icon'
import ClockIcon from 'components/icons/clock_icon'
import TaskIcon from 'components/icons/task_icon'

interface InjectedProps {
  /**
   * Beneficiary first name string provided by BE with the rest of the Milestone data
   * used, in this case, to figure out which icon to display with a specific dateName -
   * one of which is dynamic and includes the beneficiary's first name :D
   */
  beneficiaryFirstName?: string
  /**
   * The display "name" of a specific and significant moment in the journey
   */
  dateName: string
  /**
   * Typically a range, BE calculates an estimated date based on a variety of things and
   * we show this to customers, sometimes with a disclosure that its an ESTIMATE.
   */
  estimatedCompleteDate: string
  /**
   * The explanation of how this date range was calculated
   */
  explanation?: string
  /**
   * Boolean used for conditional rendering at end of milestone list
   */
  isLastMilestoneComplete: boolean
}

interface MappedProps {
  kaseId: number
}

type Props = InjectedProps & MappedProps

const NextImportantDateCard: FunctionComponent<Props> = ({
  beneficiaryFirstName,
  dateName,
  estimatedCompleteDate,
  explanation,
  isLastMilestoneComplete,
  kaseId
}) => {
  const noDateEstimateYet = estimatedCompleteDate.includes('to see')
  const isK1ConversionFinalStep = isLastMilestoneComplete

  const getApplicableNextDateIcon = () => {
    if (dateName === 'Estimated I-129F Approval') {
      return <TaskIcon size="default" className="mr-2" />
    } else if (dateName === 'Embassy Approval') {
      return <ApprovedIdIcon size="default" className="mr-2" />
    } else if (dateName === `After ${beneficiaryFirstName} enters the U.S.`) {
      return <AirplaneIcon size="default" className="mr-2" />
    } else {
      return <ClockIcon size="default" className="mr-2" />
    }
  }

  return (
    <div className="text-center">
      <h3 className="c-type c-type--subhead-md text-blue-500 py-2">
        {isK1ConversionFinalStep ? "It's time to start your green card" : 'Your Next Important Date'}
      </h3>
      <div className="bg-blue-500 text-white mx-px py-8">
        <div className="flex justify-center pb-4">
          {isK1ConversionFinalStep ? (
            <div>
              <h4 className="c-type c-type--body-sans-md pt-2 pb-8">
                By finishing your K-1, you'll begin your green card application with <b>25% already done</b>
              </h4>
              <Button
                color="emphasized"
                onClick={() => {
                  window.location.href = `/api/v1/kases/${kaseId}/continue_from_completed`
                }}
                label="Start Green Card"
                icon={<ArrowIcon arrowDirection="right" />}
              />
            </div>
          ) : (
            <>
              {getApplicableNextDateIcon()}
              <h4 className="c-type c-type--body-sans-md pt-2">{dateName}</h4>
            </>
          )}
        </div>
        {!isK1ConversionFinalStep && <h2 className="c-type c-type--headline-md">{estimatedCompleteDate}</h2>}
        {!isK1ConversionFinalStep && noDateEstimateYet && (
          <ArrowIcon arrowDirection="down" size="medium" className="pt-4 text-coral-500" />
        )}
        {!isK1ConversionFinalStep && estimatedCompleteDate && explanation && (
          <p className="c-type c-type--body-sans-sm pt-6 opacity-50">{explanation}</p>
        )}
      </div>
    </div>
  )
}

function mapStateToProps(state): MappedProps {
  return {
    kaseId: getCurrentKaseId(state)
  }
}

export default connect(mapStateToProps)(NextImportantDateCard)
