import React from 'react'
import moment from 'moment'

import BufferedFieldValue from '../../buffered_field_value'
import DateInputControl from './control'

import { conditionallyFireValidation, ValidationConditionFunction } from 'components/forms/conditional_validation'

import { defaultInputProps, InputProps } from 'components/forms/inputs'

function parseDateString(dateString: string): moment.Moment {
  dateString = dateString.trim()

  if (dateString.match(/^\d{4}-\d{2}-\d{2}$/)) {
    return moment(dateString, 'YYYY-MM-DD')
  } else if (dateString.match(/^\d{2}\/\d{2}\/\d{4}$/)) {
    return moment(dateString, 'MM/DD/YYYY')
  } else {
    return moment.invalid()
  }
}

function serializeDate(date: string): Nullable<string> {
  const momentDate = parseDateString(date)

  return momentDate.isValid() ? momentDate.format('YYYY-MM-DD') : null
}

function formatDateInputValue(modelValue: Nullable<any>): string {
  if (!modelValue) return ''

  if (typeof modelValue !== 'string') {
    if (!modelValue.date) return ''
    modelValue = modelValue.date
  }

  modelValue = modelValue.replace(/[^0-9-]/g, '')

  const [year, month, day] = modelValue.split('-')

  return [month, day, year].filter((value) => Boolean(value)).join('/')
}

const shouldValidateOnChange: ValidationConditionFunction = ({ value }) =>
  Boolean(typeof value === 'string' && value.length === 10)

class DateInput extends React.Component<InputProps> {
  static defaultProps = defaultInputProps

  render() {
    const { afterChangeEvents, className, disabled, path, id, name, onBlur } = this.props

    // TODO figure out a better way of firing validation when we know a field
    // is complete
    const fireValidation = onBlur

    return (
      <BufferedFieldValue
        afterChangeEvents={afterChangeEvents.concat([
          conditionallyFireValidation(shouldValidateOnChange, fireValidation)
        ])}
        formatter={formatDateInputValue}
        path={path}
        serializer={serializeDate}
      >
        {(value, onChange) => (
          <DateInputControl
            autoComplete="off"
            className={className}
            data-model-path={path}
            disabled={disabled}
            id={id}
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            value={value}
          />
        )}
      </BufferedFieldValue>
    )
  }
}

export default DateInput
