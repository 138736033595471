import { MilestoneTask } from 'components/screens/dashboard/post_ship_dashboard/lib/types'
import { Address } from 'reducers/address_histories'
import { Answer, QuestionnairePage, QuestionnaireQuestion } from './types'

interface TaskAnswer {
  answer: { value?: string; id?: number }
  milestoneTaskId: number
}

export const findAddressComponentByType = (
  type: string,
  addressComponents: google.maps.GeocoderAddressComponent[]
): google.maps.GeocoderAddressComponent | null => {
  let matchedComponent: google.maps.GeocoderAddressComponent = null
  addressComponents.forEach((addressComponent) => {
    const match = addressComponent.types.find((t) => t === type)
    if (match) {
      matchedComponent = addressComponent
    }
  })
  return matchedComponent
}

const formatStreetAddressFromAddressComponents = (
  addressComponents: google.maps.GeocoderAddressComponent[]
): string => {
  let formattedStreetAddress = ''
  const addressValue1 = findAddressComponentByType('street_number', addressComponents)?.long_name
  const addressValue2 = findAddressComponentByType('route', addressComponents)?.long_name
  formattedStreetAddress += addressValue1 ? addressValue1 : ''
  formattedStreetAddress += addressValue2 ? ' ' + addressValue2 : ''
  return formattedStreetAddress
}

const getQuestionsToSaveFromGroupedQuestions = (
  groupedQuestions: QuestionnaireQuestion[],
  addressComponents: google.maps.GeocoderAddressComponent[]
): Answer[] => {
  let questionsToSave: Answer[] = []
  groupedQuestions.forEach((question) => {
    const inputType = question.attributes.input_type

    let addressValue: string
    switch (inputType) {
      case 'street_address':
      case 'street_address_us': {
        addressValue = formatStreetAddressFromAddressComponents(addressComponents)
        break
      }

      case 'city':
      case 'city_us': {
        addressValue = findAddressComponentByType('locality', addressComponents)?.long_name
        break
      }

      case 'state':
      case 'state_or_province': {
        addressValue = findAddressComponentByType('administrative_area_level_1', addressComponents)?.short_name
        break
      }

      case 'postal_code':
        addressValue = findAddressComponentByType('postal_code', addressComponents)?.long_name
        break

      case 'country':
      case 'country_no_us': {
        addressValue = findAddressComponentByType('country', addressComponents)?.short_name
        break
      }

      case 'unit_type':
        break

      case 'unit_number':
        break

      default:
        break
    }

    if (addressValue) {
      questionsToSave.push({
        questionId: question.id,
        answer: addressValue,
        answerId: question.attributes.answer_id
      })
    }
  })

  return questionsToSave
}

/**
 * Takes a Google Places Address Components object and applies the
 * address parts to the correct questions on the questionnaire page
 * so they can all be saved as a batch.
 */
export const getAnswersToSaveFromPlaceUpdate = (
  question: QuestionnaireQuestion,
  panelData: QuestionnairePage,
  addressComponents: google.maps.GeocoderAddressComponent[]
): Answer[] => {
  const logicalGroup = question.attributes.logical_group

  if (logicalGroup) {
    const panelQuestions: QuestionnaireQuestion[] = []
    panelData?.attributes?.page_elements?.forEach((element) => {
      if (element?.attributes?.questions?.length) {
        panelQuestions.push(...element?.attributes?.questions)
      }
    })

    const groupedQuestions = panelQuestions.filter((question) => question.attributes.logical_group === logicalGroup)
    return getQuestionsToSaveFromGroupedQuestions(groupedQuestions, addressComponents)
  }

  return getQuestionsToSaveFromGroupedQuestions([question], addressComponents)
}

/**
 * Takes a Google Places Address Components object and applies the
 * address parts to the correct properties on the Address object used
 * for address histories.
 */
export const getAddressHistoryObjectFromPlaceUpdate = (
  addressComponents: google.maps.GeocoderAddressComponent[]
): Address => {
  const newAddress: Address = {
    street: formatStreetAddressFromAddressComponents(addressComponents),
    unit_number: null,
    unit_type: null,
    city: findAddressComponentByType('locality', addressComponents)?.long_name,
    country_code: findAddressComponentByType('country', addressComponents)?.short_name,
    postal_code: findAddressComponentByType('postal_code', addressComponents)?.long_name,
    province: findAddressComponentByType('administrative_area_level_1', addressComponents)?.short_name
  }

  return newAddress
}
