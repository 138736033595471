import React from 'react'

import { defaultInputProps, InputProps } from '..'

import ValidatedInput from '../../validated_input'
import SSNInput from './ssn_input'

type Props = InputProps & {
  label?: string
}

export const DEFAULT_SSN_FIELD_LABEL = 'SSN'

export default class SocialSecurityNumber extends React.Component<Props> {
  static defaultProps = defaultInputProps

  getLabel() {
    return this.props.label || DEFAULT_SSN_FIELD_LABEL
  }

  render() {
    const { disabled, id, name, path } = this.props

    return (
      <div className="o-flag o-grid--fluid o-grid--stack@sm o-block c-paper-form__group">
        <label
          className="o-grid__cell--3/12 o-grid__cell--off@sm c-paper-form__label c-paper-form__segment"
          htmlFor={id}
        >
          <span className="c-type c-type--subhead-sm">{this.getLabel()}</span>
        </label>

        <div className="o-grid__cell--9/12 o-grid__cell--off@sm c-paper-form__segment">
          <ValidatedInput disabled={disabled} path={path}>
            {({ fireValidation, getValidationClasses, renderValidations }) => (
              <React.Fragment>
                <SSNInput
                  className={`c-paper-form__control ${getValidationClasses()}`}
                  disabled={disabled}
                  id={id}
                  name={name}
                  onBlur={fireValidation}
                  path={path}
                />
                {renderValidations()}
              </React.Fragment>
            )}
          </ValidatedInput>
        </div>
      </div>
    )
  }
}
