import React, { FunctionComponent } from 'react'
import SkeletonHeading from 'components/skeleton/heading'
import SkeletonBlock from 'components/skeleton/block'
import SkeletonParagraph from 'components/skeleton/paragraph'
import { Issue } from 'reducers/issues'
import Heading from 'components/type/heading'
import IssueCard from 'components/screens/issues_v2/issue_card'
import IssuesHeader from 'components/screens/issues_v2/issues_header'
import Paragraph from 'components/type/paragraph'

interface ExplicitProps {
  issues?: Issue[]
  kaseState: string
  onOpenThread: Function
}

const IssuesContainer: FunctionComponent<ExplicitProps> = ({ issues, kaseState, onOpenThread }) => {
  const kaseStateRoleIsCustomer = kaseState?.indexOf('customer') === 0
  const activeIssues = issues.length && kaseStateRoleIsCustomer
  const loadingIssues = !issues

  if (loadingIssues) {
    // Loading UI
    return (
      <div className="o-action-items__actions-container px-8">
        <SkeletonHeading centered />
        <SkeletonBlock width="5rem" height="2.5rem" className="mx-auto" />
        <SkeletonParagraph numLines={5} />
        <SkeletonParagraph className="w-1/4" />
        <SkeletonBlock height="12rem" />
      </div>
    )
  }

  return (
    <div className="o-action-items__actions-container sm:py-3 lg:px-8">
      <div className="o-block">
        <Heading tag="h1" color="emphasized" centered>
          Questions and Tasks
        </Heading>
      </div>

      {activeIssues ? (
        <div className="mt-12">
          <IssuesHeader />
          <div className="mt-12 o-action-items__actions-container sm:pt-1">
            {issues.map((issue) => (
              <IssueCard key={issue.id} issue={issue} onOpenThread={onOpenThread} />
            ))}
          </div>
        </div>
      ) : (
        <Paragraph className="mt-12 text-center">There are no issues to resolve at the moment.</Paragraph>
      )}
    </div>
  )
}

export default IssuesContainer
