import React, { FunctionComponent } from 'react'
import IconWrapper, { IconWrapperExternalProps } from './icon_wrapper'

const TrashIcon: FunctionComponent<IconWrapperExternalProps> = (props) => (
  <IconWrapper {...props} viewBox="0 0 32 32">
    <path d="M28.5 7h-8v-4h-9v4h-8v2h3.11l1.462 19.5h15.856l1.472-19.5h3.1zM13.5 5h5v2h-5zM22.072 26.5h-12.144l-1.312-17.5h14.768zM20.2 22.53l-1.6-0.060 0.3-8 1.6 0.060z" />
  </IconWrapper>
)

export default TrashIcon
