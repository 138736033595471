import _get from 'lodash/get'
import _every from 'lodash/every'
import _isEmpty from 'lodash/isEmpty'
import _isMatch from 'lodash/isMatch'
import _isObject from 'lodash/isObject'
import _some from 'lodash/some'

import { KaseModelMetadata } from 'reducers/model'
import Decorator from 'lib/decorator'
import { sharedConditions as conditions } from 'lib/conditions/shared'

export class MissingNeededConditionFunctionError extends Error {}

function conditionFnNotValidError(conditionName: string) {
  const msg =
    `${conditionName} is not a valid needed conditional function. ` + 'Please implement it in model/needed/index.ts'

  throw new MissingNeededConditionFunctionError(msg)
}

function evaluateCondition(condition: any, pathIndices: number[], modelData: Decorator) {
  const conditionFunction = conditions[condition.if]

  if (!conditionFunction) {
    conditionFnNotValidError(condition.if)
  }

  return conditionFunction({
    args: condition.args || {},
    modelData,
    pathIndices
  })
}

function evaluateConditions(needed: any, pathIndices: number[], modelData: Decorator) {
  return _some(needed, (condition) => {
    if (Array.isArray(condition)) {
      return _every(condition, (subCondition) => evaluateCondition(subCondition, pathIndices, modelData))
    }

    return evaluateCondition(condition, pathIndices, modelData)
  })
}

function getMetadataPath(modelMetadata: any, path: string) {
  // e. g beneficiary.children.0.assets.items.1.kind
  // translated to => beneficiary.children.@member.assets.items.@member.kind
  let pathParts = path.split('.')

  for (let i = 0; i < pathParts.length; i++) {
    const parentPathParts = pathParts.slice(0, i)
    const parentPath = !_isEmpty(parentPathParts) ? `${parentPathParts.join('.')}.` : ''
    const parentDataType = _get(modelMetadata, `${parentPath}@type`)

    if (parentDataType === 'collection' || parentDataType === 'dictionary') {
      pathParts[i] = '@member'
    }
  }
  const pathInMetadata = _isEmpty(pathParts) ? '' : `${pathParts.join('.')}`

  return pathInMetadata
}

export function isAnsweredAtPath(modelData: Decorator, modelMetadata: KaseModelMetadata, path: string): boolean {
  if (!modelMetadata) return true

  const pathInMetadata = getMetadataPath(modelMetadata, path)

  const defaultValue = _get(modelMetadata, `${pathInMetadata}.@default`)
  if (defaultValue == null) {
    return !modelData.valueIsMissing(path)
  }

  const value = _get(modelData, path)

  if (_isObject(defaultValue)) {
    return !_isMatch(value, defaultValue)
  } else {
    return value !== defaultValue
  }
}

export function getCategoryAtPath(modelMetadata: KaseModelMetadata, path: string): string {
  if (!modelMetadata) return null

  const pathInMetadata = getMetadataPath(modelMetadata, path)

  return _get(modelMetadata, `${pathInMetadata}.@category`) || null
}

function getNeededPath(parentPath: string, leafPart: string): string {
  return `${parentPath}${leafPart}`
}

export function isPathNeeded(modelData: Decorator, modelMetadata: KaseModelMetadata, path: string): boolean {
  if (!modelMetadata) return true
  let neededPath: string

  let pathParts = path.split('.')
  const pathIndices = []

  for (let i = 0; i < pathParts.length; i++) {
    const parentPathParts = pathParts.slice(0, i)
    const parentPath = !_isEmpty(parentPathParts) ? `${parentPathParts.join('.')}.` : ''
    const parentDataType = _get(modelMetadata, `${parentPath}@type`)
    let leafPart = pathParts[i]

    if (parentDataType === 'collection' || parentDataType === 'dictionary') {
      pathIndices.push(leafPart)
      pathParts[i] = '@member'
      neededPath = getNeededPath(parentPath, '@member')
    } else {
      neededPath = getNeededPath(parentPath, leafPart)
    }

    const needed = _get(modelMetadata, `${neededPath}.@needed`)

    if (needed == null) {
      continue
    }

    if (!_isObject(needed)) {
      if (needed) {
        continue
      } else {
        return false
      }
    }

    if (!evaluateConditions(needed, pathIndices, modelData)) return false
  }

  return true
}
