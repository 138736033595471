import React from 'react'

import { defaultInputProps, InputProps } from 'components/forms/inputs/index'
import BufferedFieldValue from 'components/forms/buffered_field_value'

import { formatCurrency, serializeFixedPoint2 } from './helpers'

type Props = InputProps

export default class CurrencyInput extends React.Component<Props> {
  static defaultProps = {
    ...defaultInputProps
  }

  render() {
    const { disabled, id, path, placeholder, className, onBlur, afterChangeEvents } = this.props

    return (
      <BufferedFieldValue
        afterChangeEvents={afterChangeEvents}
        formatAsYouType={true}
        formatter={formatCurrency}
        path={path}
        serializer={serializeFixedPoint2}
      >
        {(value, onChange) => (
          <input
            autoComplete="off"
            data-model-path={path}
            className={className}
            disabled={disabled}
            id={id}
            inputMode="numeric"
            onBlur={onBlur}
            onChange={onChange}
            placeholder={placeholder}
            value={value}
          />
        )}
      </BufferedFieldValue>
    )
  }
}
