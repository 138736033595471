import React, { FunctionComponent } from 'react'
import RequestStatusIndicator from './request_status_indicator'
import {
  getDocRequestCustomerStatus,
  getDocRequestSummary,
  getDocRequestTitle,
  getDocRequestDescription
} from './utils'
import Heading from 'components/type/heading'
import Paragraph from 'components/type/paragraph'
import Accordion from 'components/accordion'
import DocumentIcon from 'components/icons/document_icon'
import Markdown from 'components/markdown'
import SkeletonBlock from 'components/skeleton/block'
import SkeletonHeading from 'components/skeleton/heading'

interface Props {
  documentRequest?: DocumentRequestModel
  slaIsSet: boolean
}

const MobileDocumentDetails: FunctionComponent<Props> = ({ documentRequest, slaIsSet }) => {
  const summary = getDocRequestSummary(documentRequest)
  const title = getDocRequestTitle(documentRequest)
  const description = getDocRequestDescription(documentRequest)

  // Skeleton UI!
  let information = (
    <>
      <SkeletonBlock spacing="lg" className="mx-auto" style={{ width: '6rem', height: '2rem' }} />
      <SkeletonHeading centered />
    </>
  )
  if (documentRequest) {
    information = (
      <>
        <div className="o-block o-block--spacious o-layout--center">
          <RequestStatusIndicator customerStatus={getDocRequestCustomerStatus(documentRequest, slaIsSet)} />
        </div>
        <Heading color="emphasized" tag="h1" centered>
          {title}
        </Heading>
        {summary && (
          <Paragraph size="sm" centered spacing="lg">
            {summary}
          </Paragraph>
        )}
      </>
    )
  }

  return (
    <div className="o-layout--padded-x o-box o-box--light">
      {information}
      <Accordion
        className="o-block"
        header={
          <span className="c-type--bold c-type--body-sans-sm">
            <DocumentIcon className="mr-1" /> Document Instructions
          </span>
        }
      >
        {description && (
          <Markdown
            className="o-block o-block--compact c-type--body-serif-sm"
            source={description}
            options={{ linkToNewTabs: true }}
          />
        )}
      </Accordion>
    </div>
  )
}

export default MobileDocumentDetails
