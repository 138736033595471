import React, { FunctionComponent, useMemo } from 'react'
import { connect } from 'react-redux'

import { DashboardPhase } from './phase_map'
import TimelineStep from './timeline_step'
import { TELEMETRY_EVENTS } from 'lib/constants'
import { postTelemetry } from 'actions/telemetry_actions'

type Props = ActionProps & {
  /**
   * The DashboardPhase that matches the customer's phase.
   */
  currentPhase: DashboardPhase
  /**
   * The DashboardPhase that's currently being previewed.
   */
  currentPhasePreview?: DashboardPhase
  phaseList: DashboardPhase[]
  updateCurrentPhasePreview: (phase: DashboardPhase) => () => void
}

interface PhaseClickTrackingParams {
  phase: DashboardPhase
  currentPhase: DashboardPhase
}

interface ActionProps {
  trackPhaseClicked?: (params: PhaseClickTrackingParams) => void
}

const Timeline: FunctionComponent<Props> = ({
  currentPhase,
  currentPhasePreview,
  updateCurrentPhasePreview,
  trackPhaseClicked,
  phaseList
}) => {
  const currentPhaseIndex = useMemo(() => {
    return phaseList.indexOf(currentPhase) || 0
  }, [currentPhase])

  const phasePreviewIndex = phaseList.indexOf(currentPhasePreview)

  const onPhaseClicked = (phase: DashboardPhase) => {
    if (currentPhasePreview !== phase) {
      trackPhaseClicked({ phase, currentPhase })
      updateCurrentPhasePreview(phase)
    }
  }

  return (
    <div className="c-timeline">
      {phaseList.map((phase, index) => (
        <TimelineStep
          key={phase}
          phase={phase}
          isPastStep={index < currentPhaseIndex}
          isCurrentStep={index === currentPhaseIndex}
          isFutureStep={index > currentPhaseIndex}
          isPreviewedStep={index === phasePreviewIndex}
          onPhaseClicked={onPhaseClicked}
        />
      ))}
    </div>
  )
}

function mapDispatchToActions(dispatch: Function): ActionProps {
  return {
    trackPhaseClicked: (args) => dispatch(postTelemetry(TELEMETRY_EVENTS.CLICKED_TIMELINE_STEP, args))
  }
}

export default connect(null, mapDispatchToActions)(Timeline)
