import server from './server'
import { AxiosPromise } from 'axios'

interface IndexDocumentRequests {
  kaseId: number
}

interface CreateDocumentRequest {
  kaseId: number
  documentRequest: DocumentRequestModel
}

function index(request: IndexDocumentRequests) {
  const { kaseId } = request

  return server.get<DocumentRequestModel[]>(`/api/kases/${kaseId}/document_requests`).then((response) => response.data)
}

function create(request: CreateDocumentRequest): AxiosPromise<DocumentRequestModel> {
  const { kaseId } = request
  const data = {
    document_request: request.documentRequest
  }

  return server
    .post(`/api/kases/${kaseId}/document_requests`, data)
    .then((response) => response.data.document_request)
    .catch((error) =>
      // eslint-disable-next-line no-console
      console.error('Error trying to create document request', error)
    )
}

export default {
  create,
  index
}
