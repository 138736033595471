import React, { FunctionComponent } from 'react'

interface Props {
  children: string
}

const Error: FunctionComponent<Props> = ({ children }) => {
  return (
    <div className="px-4 pt-4">
      <div className="c-alert c-alert--danger">{children} Please refresh the page and try again.</div>
    </div>
  )
}

export default Error
