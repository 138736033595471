import React from 'react'
import { connect } from 'react-redux'
import _capitalize from 'lodash/capitalize'

import SectionEntry from './section_entry'

import { getOrdinal } from 'lib/language_helpers'
import { getPreviousNames } from 'reducers/selectors'

import { Role } from 'components/forms/inputs/index'

interface Props {
  role: Role
  previousNames: PersonNameModel[]
}

class PreviousNames extends React.Component<Props> {
  renderName(name, i) {
    const { role } = this.props
    const ordinal = _capitalize(getOrdinal(i))
    const nameLabel = `${ordinal} Previous Name`

    const editHref = i === 0 ? `${role}-first-previous-name` : `${role}-other-previous-names/${i}`

    return <SectionEntry label={nameLabel} path={name.path} key={`${i}-previous-name`} editHref={editHref} />
  }

  render() {
    return this.props.previousNames.map((name, i) => this.renderName(name, i))
  }
}

const mapStateToProps = (state, props) => ({
  previousNames: getPreviousNames(state, props.role)
})

export default connect(mapStateToProps)(PreviousNames)
