import React, { FunctionComponent } from 'react'
import cx from 'classnames'
import moment from 'moment'
import { getCurrentAccountFirstNames, getCurrentUserId } from 'reducers/selectors'
import { connect } from 'react-redux'

interface ExplicitProps {
  creatorId?: string
  createdAt?: string
  messageBody?: string
}

interface MappedProps {
  currentUserId?: number
  customerNames?: string[]
}

type Props = ExplicitProps & MappedProps

export const MessageInitials: FunctionComponent<Props> = ({ creatorId, createdAt, currentUserId, customerNames }) => {
  const userId = createdAt ? creatorId : currentUserId.toString()
  const customerInitials = customerNames.reduce((initials, name) => {
    return (initials += name.charAt(0))
  }, '')

  const creatorIsAdmin = userId !== currentUserId.toString()
  const displayInitials = creatorIsAdmin ? 'B' : customerInitials
  const displayName = creatorIsAdmin ? 'Boundless' : customerNames.join(' & ')

  return (
    <div className="flex items-center mb-3">
      <div
        className={cx('flex c-badge--rounded text-white items-center justify-center bg-blue-500 font-bold', {
          'bg-blue-500': creatorIsAdmin,
          'bg-coral-500': !creatorIsAdmin
        })}
        style={{ width: '2rem', height: '2rem' }}
      >
        {/* Larsseit is very difficult to center vertically .__. */}
        <span style={{ paddingTop: '0.2rem', fontSize: '.8rem' }}>{displayInitials}</span>
      </div>
      <div className="pl-3">
        <div className="font-bold c-type c-type--body-sans-md">{displayName}</div>
        {createdAt && (
          <div className="mt-2 text-muted c-type--body-sans-sm">{moment(createdAt).format('MM/DD/YYYY')}</div>
        )}
      </div>
    </div>
  )
}

function mapStateToProps(state) {
  return {
    currentUserId: getCurrentUserId(state),
    customerNames: getCurrentAccountFirstNames(state)
  }
}

export default connect(mapStateToProps)(MessageInitials)
