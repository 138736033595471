import moment from 'moment'
import _capitalize from 'lodash/capitalize'

import { buildPerson, getInterrogativeClauses, getNeutralPronouns, ResourceContextPerson } from '../shared/build_person'
import { dollarsWithCommas } from 'lib/format_money'

import {
  getHouseholdSize,
  getSponsorProvince,
  getMBGCSpouse,
  getWorkflowCategory,
  getModelValue
} from 'reducers/selectors'

import { ApplicationState } from 'reducers'
import { provincialPovertyGuideline } from '../shared/poverty_guidelines'
import { ResourceContextMBGCSpouse } from './'

export interface ResourceContextMBGCEligibility {
  marriageStatus: 'married' | 'unmarried'
  marriageIntention: 'planning' | 'not_planning'
  sponsorLegalStatus: 'usc' | 'lpr' | 'other'
  visaValidity: 'valid' | 'invalid'
  publicBenefits: 'has_used_public_benefits' | 'has_not_used_public_benefits'
  sponsorLegalStatusBeneficiaryLocation:
    | 'usc_local'
    | 'usc_abroad'
    | 'lpr_local'
    | 'lpr_abroad'
    | 'lpr_local_ineligible'
}

export interface ResourceContextMBGCPublicChargeEstimatorProto {
  accountRole: string
  caseType: string
  military: string
  age: string
  employmentBasedGC: string
  secondaryEducation: string
  professionalSkills: string
  languageProficiency: string
  employmentHistory: string
  caregiver: string
  publicBenefits: string
  householdIncome: string
  financialLiabilities: string
  creditScore: string
  healthInsurance: string
  medicalConditions: string
  medicalCostCoverage: string
  povertyGuideline125: string
  povertyGuideline250: string
  possessiveAdjective: string
  secondPersonPossAdjSelfOrOther: string
  secondPersonPossAdjSelfOrOtherCap: string
  firstPersonSubjectOrPossAdj: string
  reflexivePronoun: string
  presentSimpleInterrogativeCap: string
  presentContinuousInterrogative: string
  presentContinuousInterrogativeCap: string
  pastSimpleInterrogative: string
  pastContinuousInterrogativeCap: string
  firstPersonSubjectSpecific: string
  secondPersonBeInfinitive: string
  secondPersonBeInfinitiveCap: string
  secondPersonBePastSimple: string
  secondPersonSubjectSpecific: string
  secondPersonSubjectSpecificCap: string
  secondPersonSubjectGeneric: string
  secondPersonObject: string
  secondPersonHaveInfinitive: string
  grammarIDoOrFamilyMemberDoes: string
}

export interface ResourceContextMiscDetails {
  combinedLocation: 'both_abroad' | 'not_both_abroad'
  currentYear: number
  previousYear: number
}

export interface ResourceContextSponsor extends ResourceContextPerson {
  uscisNumberStatus: 'has_uscis_number' | 'no_uscis_number'
  previousNamesStatus: 'has_used_other_names'
  employerStatus: 'aos'
  legalIssuesStatus: 'yes_sponsor'
  activeMilitaryDutyStatus: 'yes' | 'no'
  taxReturnFiledStatus: 'filed' | 'not_filed'
}

export interface ResourceContextBeneficiary extends ResourceContextPerson {
  ssnStatus: 'has_ssn' | 'no_ssn'
  legalIssuesStatus: 'yes_beneficiary'
  immigrationProceedingsStatus: 'yes_exclusion_deportation'
  employerStatus: 'aos'
  previousNamesStatus: 'has_used_other_names'
}

export interface ResourcePublicChargeContext {
  // filingInIllinois?: 'yes' | 'no'
  filedTaxReturn?: 'filed_tax_return' | 'not_filed_tax_return'
  hasAdditionalIncome?: 'has_additional_income' | 'no_additional_income'
  hasAssets?: 'has_assets' | 'no_assets'
  hasCreditReport?: 'has_credit_report' | 'no_credit_report'
  hasDebtsOrLiabilities?: 'has_debts_or_liabilities' | 'no_debts_or_liabilities'
  hadBankruptcy?: 'had_bankruptcy' | 'no_bankruptcy'
  hasHealthInsurance?: 'has_health_insurance' | 'no_health_insurance'
  willEnroll?: 'will_enroll' | 'no_enrollment'
  hasI140?: 'has_i_140_form' | 'no_i_140_form'
  hasProofOfEducation?: 'has_proof_of_education' | 'no_proof_of_education'
  hasCertifications?: 'has_certifications' | 'no_certifications'
  hasEnglishCertification?: 'has_english_certification' | 'no_english_certification'
  limitedWorkAbility?: 'work_ability_limited' | 'work_ability_not_limited'
  sponsorHasAssets?: 'sponsor_has_assets' | 'sponsor_has_no_assets'
}

export function getMonthAndYearFiveYearsAgo(): string {
  return moment().subtract(5, 'years').format('MMMM YYYY')
}

export function buildSponsor(state: ApplicationState): ResourceContextSponsor {
  const accountRole = getModelValue(state, 'account.role') || 'sponsor'
  const isSponsor = accountRole === 'sponsor'

  const workflowCategory = getWorkflowCategory(state)
  const isAos = workflowCategory === 'AOS'

  const hasUscisNumber = getModelValue(state, 'sponsor.has_uscis_number') === 'yes'
  const uscisNumberStatus = hasUscisNumber ? 'has_uscis_number' : 'no_uscis_number'

  const previousNames = getModelValue(state, 'sponsor.previous_names.items')
  const previousNamesStatus = previousNames.length > 0 ? 'has_used_other_names' : null

  const unemployed = getModelValue(state, 'sponsor.employments.0.unemployed')
  const employerStatus = (!unemployed && isAos && 'aos') || null

  const hasLawEnforcementHistory = getModelValue(state, 'sponsor.law_enforcement_history')
  const legalIssuesStatus = (hasLawEnforcementHistory && isSponsor && 'yes_sponsor') || null

  const isOnActiveMilitaryDuty = getModelValue(state, 'sponsor.is_on_active_military_duty')
  const activeMilitaryDutyStatus = isOnActiveMilitaryDuty ? 'yes' : 'no'

  const hasFiledIncomeTaxReturns = getModelValue(state, 'sponsor.has_filed_income_tax_returns') === 'yes'
  const taxReturnFiledStatus = hasFiledIncomeTaxReturns ? 'filed' : 'not_filed'

  return {
    ...buildPerson(getModelValue(state, 'sponsor'), 'sponsor'),
    uscisNumberStatus,
    previousNamesStatus,
    employerStatus,
    legalIssuesStatus,
    activeMilitaryDutyStatus,
    taxReturnFiledStatus
  }
}

export function buildBeneficiary(state: ApplicationState): ResourceContextBeneficiary {
  const accountRole = getModelValue(state, 'account.role') || 'sponsor'
  const isBeneficiary = accountRole === 'beneficiary'

  const workflowCategory = getWorkflowCategory(state)
  const isAos = workflowCategory === 'AOS'

  const noSSN = getModelValue(state, 'beneficiary.no_ssn')
  const ssnStatus = noSSN ? 'no_ssn' : 'has_ssn'

  const hasLawEnforcementHistory = getModelValue(state, 'beneficiary.law_enforcement_history')
  const legalIssuesStatus = (hasLawEnforcementHistory && isBeneficiary && 'yes_beneficiary') || null

  const hadImmigrationProceedings = getModelValue(state, 'beneficiary.had_immigration_proceedings') === 'yes'
  const immigrationProceedingKind = getModelValue(state, 'beneficiary.immigration_proceeding.kind')
  const isImmigrationProceedingExclusion = immigrationProceedingKind === 'exclusion'
  const immigrationProceedingsStatus =
    (hadImmigrationProceedings && isImmigrationProceedingExclusion && 'yes_exclusion_deportation') || null

  const unemployed = getModelValue(state, 'beneficiary.employments.0.unemployed')
  const employerStatus = (!unemployed && isAos && 'aos') || null

  const previousNames = getModelValue(state, 'beneficiary.previous_names.items')
  const previousNamesStatus = previousNames.length > 0 ? 'has_used_other_names' : null

  const beneficiary = getModelValue(state, 'beneficiary')
  const interrogativeClauses = getInterrogativeClauses(beneficiary)

  const thirdPerson = accountRole === 'sponsor'

  const presentInterrogativeClause = thirdPerson
    ? interrogativeClauses.thirdPersonPresent
    : interrogativeClauses.secondPersonPresent
  const presentContinuousInterrogativeClause = thirdPerson
    ? interrogativeClauses.thirdPersonPresentContinuous
    : interrogativeClauses.secondPersonPresentContinuous
  const perfectInterrogativeClause = thirdPerson
    ? interrogativeClauses.thirdPersonPresentPerfect
    : interrogativeClauses.secondPersonPresentPerfect

  const neutralPronouns = getNeutralPronouns(beneficiary)
  const neutralPronoun = thirdPerson ? neutralPronouns.thirdPerson : neutralPronouns.secondPerson

  const hasOrHave = thirdPerson ? 'has' : 'have'
  const isOrAre = thirdPerson ? 'is' : 'are'
  const possessiveAdjective = thirdPerson ? 'their' : 'your'
  const verbSuffix = thirdPerson ? 's' : ''

  return {
    ...buildPerson(beneficiary, 'beneficiary'),
    ssnStatus,
    legalIssuesStatus,
    immigrationProceedingsStatus,
    employerStatus,
    previousNamesStatus,
    presentInterrogativeClause,
    presentContinuousInterrogativeClause,
    perfectInterrogativeClause,
    neutralPronoun,
    hasOrHave,
    isOrAre,
    possessiveAdjective,
    verbSuffix
  }
}

export function buildSpouse(state: ApplicationState): ResourceContextMBGCSpouse {
  const spouse = getMBGCSpouse(state)

  if (!spouse) return {}

  const name = spouse.name.first_name

  return {
    name: name ? name.trim() : null
  }
}

export function buildEligibility(state: ApplicationState): ResourceContextMBGCEligibility {
  const sponsorLegalStatus = getModelValue(state, 'sponsor.legal_status.code')
  const eligibility = getModelValue(state, 'eligibility') || {}

  const beneficiaryLocation = eligibility.beneficiary_not_in_us ? 'abroad' : 'local'

  const visaValidity = eligibility.has_valid_visa ? 'valid' : 'invalid'

  const publicBenefits = eligibility.has_used_public_benefits
    ? 'has_used_public_benefits'
    : 'has_not_used_public_benefits'

  const marriageStatus = eligibility.is_married ? 'married' : 'unmarried'

  const marriageIntention = eligibility.intent_to_marry ? 'planning' : 'not_planning'

  const beneInUSSponsorIsLpr = beneficiaryLocation === 'local' && sponsorLegalStatus === 'lpr'

  var sponsorLegalStatusBeneficiaryLocation
  if (beneInUSSponsorIsLpr) {
    sponsorLegalStatusBeneficiaryLocation = 'lpr_local_ineligible'
  } else {
    sponsorLegalStatusBeneficiaryLocation = `${sponsorLegalStatus}_${beneficiaryLocation}`
  }

  return {
    marriageIntention,
    marriageStatus,
    beneficiaryLocation,
    sponsorLegalStatus,
    sponsorLegalStatusBeneficiaryLocation,
    visaValidity,
    publicBenefits
  }
}

export function buildPublicChargeEstimatorProto(
  state: ApplicationState
): ResourceContextMBGCPublicChargeEstimatorProto {
  const account = getModelValue(state, 'account') || {}
  const publicChargeEstimatorProto = getModelValue(state, 'public_charge_estimator_proto') || {}

  const employmentBasedGC = publicChargeEstimatorProto.employment_based_gc
  var accountRole
  if (employmentBasedGC === 'employment_gc') {
    accountRole = 'beneficiary'
  } else {
    accountRole = account.role
  }
  const caseType = publicChargeEstimatorProto.case_type ? 'is_in_us' : 'is_not_in_us'
  const military = publicChargeEstimatorProto.military ? 'is_in_military' : 'is_not_in_military'
  const age = publicChargeEstimatorProto.age ? 'is_in_age_range' : 'is_not_in_age_range'
  const secondaryEducation = publicChargeEstimatorProto.secondary_education
    ? 'has_secondary_education'
    : 'does_not_have_secondary_education'
  const professionalSkills = publicChargeEstimatorProto.professional_skills
    ? 'has_professional_skills'
    : 'does_not_have_professional_skills'
  const languageProficiency = publicChargeEstimatorProto.language_proficiency
    ? 'has_language_proficiency'
    : 'does_not_have_language_proficiency'
  const employmentHistory = publicChargeEstimatorProto.employment_history
    ? 'has_employment_history'
    : 'does_not_have_employment_history'
  const caregiver = publicChargeEstimatorProto.caregiver ? 'is_caregiver' : 'is_not_caregiver'
  const publicBenefits = publicChargeEstimatorProto.public_benefits
  const householdIncome = publicChargeEstimatorProto.household_income
  const financialLiabilities = publicChargeEstimatorProto.financial_liabilities
    ? 'has_financial_liabilities'
    : 'does_not_have_financial_liabilities'
  const creditScore = publicChargeEstimatorProto.credit_score
  const healthInsurance = publicChargeEstimatorProto.health_insurance
  const medicalConditions = publicChargeEstimatorProto.medical_conditions
    ? 'has_medical_conditions'
    : 'does_not_have_medical_conditions'
  var medicalCostCoverage
  if (publicChargeEstimatorProto.medical_cost_coverage) {
    medicalCostCoverage = 'has_medical_cost_coverage'
  } else {
    if (publicChargeEstimatorProto.health_insurance === 'none') {
      medicalCostCoverage = 'does_not_have_medical_cost_coverage_or_insurance'
    } else {
      medicalCostCoverage = 'does_not_have_medical_cost_coverage_but_has_insurance'
    }
  }

  // TODO: possibly reuse the existing household size code
  const householdSize = publicChargeEstimatorProto.household_size
  const povertyGuideline125 = dollarsWithCommas(provincialPovertyGuideline('WA', householdSize, 1.25))
  const povertyGuideline250 = dollarsWithCommas(provincialPovertyGuideline('WA', householdSize, 2.5))

  // simple pronouns
  const possessiveAdjective = accountRole === 'beneficiary' ? 'your' : 'their'
  const reflexivePronoun = accountRole === 'beneficiary' ? 'yourself' : 'themselves'
  const secondPersonPossAdjSelfOrOther = accountRole === 'beneficiary' ? 'your' : "your family member's"
  const secondPersonPossAdjSelfOrOtherCap = _capitalize(secondPersonPossAdjSelfOrOther)
  const secondPersonSubjectSpecific = accountRole === 'beneficiary' ? 'you' : 'your family member'
  const secondPersonSubjectSpecificCap = _capitalize(secondPersonSubjectSpecific)
  const secondPersonSubjectGeneric = accountRole === 'beneficiary' ? 'you' : 'they'
  const secondPersonObject = accountRole === 'beneficiary' ? 'you' : 'them'
  const firstPersonSubjectSpecific = accountRole === 'beneficiary' ? 'I' : 'my family member'

  // w/ verbs
  const firstPersonSubjectOrPossAdj = accountRole === 'beneficiary' ? 'I have' : 'my family member has'
  const grammarIDoOrFamilyMemberDoes = accountRole === 'beneficiary' ? 'I do' : 'my family member does'

  // TODO: these are "present simple" forms - not infinitive!
  const secondPersonBeInfinitive = accountRole === 'beneficiary' ? 'you are' : 'your family member is'
  const secondPersonBeInfinitiveCap = _capitalize(secondPersonBeInfinitive)
  const secondPersonBePastSimple = accountRole === 'beneficiary' ? 'you were' : 'your family member was'
  const secondPersonHaveInfinitive = accountRole === 'beneficiary' ? 'you have' : 'your family member has'

  // questions
  const presentSimpleInterrogative = accountRole === 'beneficiary' ? 'do you' : 'does your family member'
  const presentSimpleInterrogativeCap = _capitalize(presentSimpleInterrogative)
  const presentContinuousInterrogative = accountRole === 'beneficiary' ? 'are you' : 'is your family member'
  const presentContinuousInterrogativeCap = _capitalize(presentContinuousInterrogative)
  // TODO: can remove this one, switch to you / your family member
  const pastSimpleInterrogative = accountRole === 'beneficiary' ? 'did you' : 'did your family member'
  const pastContinuousInterrogative = accountRole === 'beneficiary' ? 'were you' : 'was your family member'
  const pastContinuousInterrogativeCap = _capitalize(pastContinuousInterrogative)

  return {
    accountRole,
    caseType,
    military,
    age,
    employmentBasedGC,
    secondaryEducation,
    professionalSkills,
    languageProficiency,
    employmentHistory,
    caregiver,
    publicBenefits,
    householdIncome,
    financialLiabilities,
    creditScore,
    healthInsurance,
    medicalConditions,
    medicalCostCoverage,
    povertyGuideline125,
    povertyGuideline250,
    possessiveAdjective,
    secondPersonPossAdjSelfOrOther,
    secondPersonPossAdjSelfOrOtherCap,
    firstPersonSubjectOrPossAdj,
    reflexivePronoun,
    presentSimpleInterrogativeCap,
    presentContinuousInterrogative,
    presentContinuousInterrogativeCap,
    pastSimpleInterrogative,
    pastContinuousInterrogativeCap,
    firstPersonSubjectSpecific,
    secondPersonBeInfinitive,
    secondPersonBeInfinitiveCap,
    secondPersonBePastSimple,
    secondPersonSubjectSpecificCap,
    secondPersonSubjectGeneric,
    secondPersonObject,
    secondPersonSubjectSpecific,
    secondPersonHaveInfinitive,
    grammarIDoOrFamilyMemberDoes
  }
}

export function buildMiscDetails(state: ApplicationState): ResourceContextMiscDetails {
  const sponsorCountryCode = getModelValue(state, 'sponsor.addresses.0.country.code')
  const sponsorAbroad = Boolean(sponsorCountryCode) && sponsorCountryCode !== 'US'
  const beneficiaryAbroad = getModelValue(state, 'eligibility.beneficiary_not_in_us')

  const combinedLocation = sponsorAbroad && beneficiaryAbroad ? 'both_abroad' : 'not_both_abroad'

  return {
    combinedLocation,
    currentYear: new Date().getFullYear(),
    previousYear: new Date().getFullYear() - 1
  }
}

const jointSponsor = {
  name: 'your joint sponsor',
  name_cap: 'Your joint sponsor',
  gender_pronoun: 'they',
  gender_pronoun_cap: 'They',
  gender_possessive_pronoun: 'their',
  gender_possessive_pronoun_cap: 'Their'
}

export function buildJointSponsor(): ResourceContextPerson {
  return jointSponsor
}

export function buildPublicChargeContext(state: ApplicationState): ResourcePublicChargeContext {
  // const filingInIllinois = getModelValue(state, 'filing_in_illinois')
  const beneficiary = getModelValue(state, 'beneficiary')
  const sponsor = getModelValue(state, 'sponsor')

  const filedTaxReturn =
    beneficiary.tax_return.has_filed_tax_recent_year_return === 'yes' ? 'filed_tax_return' : 'not_filed_tax_return'

  const hasAdditionalIncome =
    beneficiary.has_additional_income === 'yes' ? 'has_additional_income' : 'no_additional_income'

  const hasAssets = beneficiary.has_proof_of_assets === true ? 'has_assets' : 'no_assets'

  const hasCreditReport = beneficiary.has_credit_report === true ? 'has_credit_report' : 'no_credit_report'

  const hasDebtsOrLiabilities =
    beneficiary.has_debts_or_liabilities === true ? 'has_debts_or_liabilities' : 'no_debts_or_liabilities'

  const hadBankruptcy = beneficiary.has_had_bankruptcy === true ? 'had_bankruptcy' : 'no_bankruptcy'

  const hasHealthInsurance =
    beneficiary.has_proof_of_health_insurance === true ? 'has_health_insurance' : 'no_health_insurance'

  const willEnroll =
    beneficiary.health_insurance.enrollment_status === 'no_enrollment' ? 'no_enrollment' : 'will_enroll'

  const hasI140 = beneficiary.has_i_140_form === true ? 'has_i_140_form' : 'no_i_140_form'

  const hasProofOfEducation =
    beneficiary.has_proof_of_education === true ? 'has_proof_of_education' : 'no_proof_of_education'

  const hasCertifications =
    beneficiary.has_occupational_certification === true ? 'has_certifications' : 'no_certifications'

  const hasEnglishCertification =
    beneficiary.has_english_language_certification === true ? 'has_english_certification' : 'no_english_certification'

  const limitedWorkAbility =
    beneficiary.limited_work_ability === true ? 'work_ability_limited' : 'work_ability_not_limited'

  const sponsorHasAssets = sponsor.has_proof_of_assets === true ? 'sponsor_has_assets' : 'sponsor_has_no_assets'

  return {
    // filingInIlinois,
    filedTaxReturn,
    hasAdditionalIncome,
    hasAssets,
    hasCreditReport,
    hasDebtsOrLiabilities,
    hadBankruptcy,
    hasHealthInsurance,
    hasI140,
    hasProofOfEducation,
    hasCertifications,
    hasEnglishCertification,
    limitedWorkAbility,
    sponsorHasAssets,
    willEnroll
  }
}

// TODO: Use the backend logic to dictate levels
// https://trello.com/c/IBOlUeAP/118-income-levels-logic-should-only-be-dictated-by-backend
export function minimumIncomeRequirementAmount(state: ApplicationState): number {
  const numDependents = getHouseholdSize(state)
  const province = getSponsorProvince(state)

  return provincialPovertyGuideline(province, numDependents + 2, 1.25)
}

export function minimumIncomeRequirement(state: ApplicationState): string {
  return dollarsWithCommas(minimumIncomeRequirementAmount(state))
}

function getMostRecentTaxYear(cutOffMonth: number, cutOffDay: number): number {
  const thisYear = moment().year()
  const today = new Date()
  const taxCutoff = new Date(thisYear, cutOffMonth, cutOffDay)

  if (today < taxCutoff) {
    return thisYear - 2
  } else {
    return thisYear - 1
  }
}

export function mostRecentTaxYear(): number {
  const cutOffMonth = 1 // February (months are 0 indexed)
  const cutOffDay = 1

  return getMostRecentTaxYear(cutOffMonth, cutOffDay)
}

export function mostRecentTaxYearForPublicCharge(): number {
  const cutOffMonth = 3 // Apr (months are 0 indexed)
  const cutOffDay = 15

  return getMostRecentTaxYear(cutOffMonth, cutOffDay)
}

export function lastThreeTaxYears(): string[] {
  const latestYear = mostRecentTaxYear()
  return [`${latestYear}`, `${latestYear - 1}`, `${latestYear - 2}`]
}

export function relativeLastThreeTaxYears(state): string[] {
  let latestYear = mostRecentTaxYear()
  if (state.model.data.sponsor.tax_return.tax_year_filed) {
    latestYear = state.model.data.sponsor.tax_return.tax_year_filed
  }

  return [`${latestYear}`, `${latestYear - 1}`, `${latestYear - 2}`]
}

/**
 * Returns a URL to the assets worksheet for that applications's workflow
 * category.
 */
export function assetsWorksheetUrl(state: ApplicationState): string {
  const workflowCategory = getWorkflowCategory(state)

  if (workflowCategory === 'AOS') {
    return '/assets_worksheet_aos.docx'
  } else {
    return '/assets_worksheet_cp.docx'
  }
}
