import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'

import { DashboardPhase } from 'components/screens/dashboard/customer_dashboard/phase_map'
import PhaseInformationTemplate from 'components/screens/dashboard/customer_dashboard/phase_information/phase_information_template'
import {
  getDashboardContentForWorkflowStatus,
  getWorkflowStatus,
  isSelfSufficiencyIncomplete
} from 'reducers/selectors'
import { WORKFLOW_STATUSES } from 'lib/constants'
import { PRIMARY_CTA_LABEL_QUESTIONS_REMAINING } from 'components/screens/dashboard/customer_dashboard/phases/intake_phase/intake_primary_cta'
import { ContentForStatus } from 'components/screens/dashboard/customer_dashboard/phase_config/phase_description'

const INITIAL_LAWYER_REVIEW_NO_DOC_UPLOAD_CONTENT = {
  message:
    '{{attorney}} will review your answers within 1–2 business days while you finish your remaining questionnaires.',
  phaseDescription:
    '{{attorney}} will reach out to you via email if they have any questions. In the meantime, you may see additional questionnaires to complete below, followed by a "Supporting Documents" section.' +
    '<br/><br/>Once you finish the Self-Sufficiency Questionnaire, {{attorney}} will build your personalized list of documents required by the U.S. government, and we’ll help you upload them.',
  primaryCTA: PRIMARY_CTA_LABEL_QUESTIONS_REMAINING
}

interface ExplicitProps {
  currentPhase: DashboardPhase
}

interface PropsFromRedux {
  contentForStatus: ContentForStatus
  workflowStatus: string
  isSelfSufficiencyIncomplete: boolean
}

type Props = ExplicitProps & PropsFromRedux

export const ILAPhaseInformation: FunctionComponent<Props> = ({
  contentForStatus,
  currentPhase,
  workflowStatus,
  isSelfSufficiencyIncomplete
}) => {
  const shouldDisplayIncompleteSelfSufficiencyContent = () => {
    return isSelfSufficiencyIncomplete && workflowStatus === WORKFLOW_STATUSES.AOS_INITIAL_LAWYER_REVIEW
  }

  const phaseContent = shouldDisplayIncompleteSelfSufficiencyContent()
    ? INITIAL_LAWYER_REVIEW_NO_DOC_UPLOAD_CONTENT
    : contentForStatus

  return (
    <PhaseInformationTemplate
      currentPhase={currentPhase}
      header={phaseContent.message}
      description={phaseContent.phaseDescription}
      primaryCTA={phaseContent.primaryCTA}
    />
  )
}

function mapStateToProps(state): PropsFromRedux {
  return {
    contentForStatus: getDashboardContentForWorkflowStatus(state),
    workflowStatus: getWorkflowStatus(state),
    isSelfSufficiencyIncomplete: isSelfSufficiencyIncomplete(state)
  }
}

export default connect(mapStateToProps)(ILAPhaseInformation) as FunctionComponent<ExplicitProps>
