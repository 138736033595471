import { buildMessage, Validator } from './'

const ssnRegex = /^\d{9}$/

const validSsn: Validator = ({ getModelData, path }) => {
  const number = getModelData(path)

  if (!number) return []

  if (!ssnRegex.test(number)) {
    return [buildMessage('Format must be XXX-XX-XXXX')]
  }

  return []
}

export default validSsn
