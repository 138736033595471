import Decorator from 'lib/decorator'
import Cookies from 'js-cookie'
import _isEmpty from 'lodash/isEmpty'

const ILRC_COOKIE_NAME = 'src_ilrc_landing'

export default class NatzModelData extends Decorator {
  noGreenCard(): boolean {
    return this.eligibility.no_green_card === true
  }

  greenCardTooNew(): boolean {
    return this.eligibility.green_card_too_new === true
  }

  greenCardBetween3And5YearsOld(): boolean {
    return this.eligibility.green_card_between_3_and_5_years_old === true
  }

  under18(): boolean {
    return this.eligibility.over_18 === false
  }

  notMarriedToUSCitizen(): boolean {
    return this.eligibility.married_to_us_citizen === false
  }

  marriedToUSCitizen(): boolean {
    return this.eligibility.married_to_us_citizen === true
  }

  currentMarriageTooNew(): boolean {
    return this.get('applicant.current_marriage.younger_than_3_years') === true
  }

  spouseCitizenshipTooRecent(): boolean {
    return this.get('applicant.current_marriage.spouse.citizen_in_last_3_years') === true
  }

  presenceRequirementNotMet(): boolean {
    return this.eligibility.been_in_us_at_least_half_time === false
  }

  longTripsOutsideUs(): boolean {
    return this.eligibility.long_trips_outside_us === true
  }

  doesntMeetLatestTripReturnRequirement(): boolean {
    return this.doesnt_meet_latest_trip_return_requirement === true
  }

  doesntMeetLongTermReturnRequirement(): boolean {
    return this.doesnt_meet_long_term_return_requirement === true
  }

  tripRequirementsNotMet(): boolean {
    return this.doesntMeetLatestTripReturnRequirement() || this.doesntMeetLongTermReturnRequirement()
  }

  hasUnpaidTaxes(): boolean {
    return this.eligibility.has_unpaid_taxes === true
  }

  hasLawEnforcementHistory(): boolean {
    return this.applicant.moral_character.has_law_enforcement_history === true
  }

  doesNotQualifyForExemption(): boolean {
    return this.eligibility.qualifies_for_exemption === false
  }

  qualifiesForExemption(): boolean {
    return this.eligibility.qualifies_for_exemption === true
  }

  possiblyQualifiesForFeeWaiver(): boolean {
    return this.eligibility.possibly_qualifies_for_fee_waiver === true
  }

  feeWaiverQualificationForILRC(): boolean {
    return this.possiblyQualifiesForFeeWaiver() && Boolean(Cookies.get(ILRC_COOKIE_NAME))
  }

  doesNotReceivePublicBenefits(): boolean {
    return this.eligibility.receives_public_benefits === false
  }

  receivesPublicBenefits(): boolean {
    return this.eligibility.receives_public_benefits === true
  }

  meetsIncomeRequirement(): boolean {
    return this.household.meets_income_requirement === true
  }

  eligibleForFeeWaiver(): boolean {
    return this.receivesPublicBenefits() || this.meetsIncomeRequirement()
  }

  notEnoughGreenCardTime(): boolean {
    return this.not_enough_green_card_time === true
  }

  setupIneligible(): boolean {
    return (
      this.noGreenCard() ||
      this.greenCardTooNew() ||
      this.under18() ||
      this.notMarriedToUSCitizen() ||
      this.currentMarriageTooNew() ||
      this.spouseCitizenshipTooRecent() ||
      this.presenceRequirementNotMet() ||
      this.doesntMeetLatestTripReturnRequirement() ||
      this.doesntMeetLongTermReturnRequirement() ||
      this.hasUnpaidTaxes() ||
      this.hasLawEnforcementHistory()
    )
  }

  eligibilityOutcome(): string {
    if (this.setupIneligible()) {
      if (this.qualifiesForExemption()) {
        return 'setup_eligible_cannot_help'
      } else {
        return 'setup_ineligible'
      }
    } else {
      if (this.eligibleForFeeWaiver()) {
        return 'setup_eligible_with_fee_waiver'
      } else {
        return 'setup_eligible'
      }
    }
  }

  petitionOutcome(): string {
    return 'petition_final'
  }

  hasNotAnsweredPrimaryOrPreviousNationality({ pathIndex }): boolean {
    const primaryNationalityCountry = this.get('applicant.nationality.country')

    if (primaryNationalityCountry == null || primaryNationalityCountry.code == null) {
      return true
    }

    if (pathIndex <= 0) {
      return false
    }

    const previousIndex = pathIndex - 1
    const previousNationality = this.get('applicant.other_nationalities.$', {
      pathIndex: previousIndex
    })

    if (
      previousNationality == null ||
      previousNationality.country == null ||
      previousNationality.country.code == null
    ) {
      return true
    }

    return false
  }

  singleNeverMarried(): boolean {
    return this.get('applicant.family.marital_status') === 'single_never_married'
  }

  isMarried(): boolean {
    return this.get('applicant.family.marital_status') === 'married'
  }

  missingCurrentSpouseName(): boolean {
    return (
      this.get('applicant.current_marriage.spouse.name.first_name') == null ||
      this.get('applicant.current_marriage.spouse.name.last_name') == null
    )
  }

  currentlyLiveTogether(): boolean {
    return this.get('applicant.current_marriage.spouse.currently_live_together') === true
  }

  clarificationNotRequiredFor({ path }): boolean {
    const address = this.get(path)

    if (!address || !address.street) {
      return true
    }

    return address.autofilled === true
  }

  nameIsNotPresentAt({ path }): boolean {
    const name = this.get(path)

    return name == null || name.first_name == null || name.last_name == null
  }

  spouseNotUSCitizen(): boolean {
    return this.get('applicant.current_marriage.spouse.legal_status.code') !== 'usc'
  }

  spouseNotLPR(): boolean {
    return this.get('applicant.current_marriage.spouse.legal_status.code') !== 'lpr'
  }

  spouseNotOtherStatus(): boolean {
    return this.get('applicant.current_marriage.spouse.legal_status.code') !== 'other'
  }

  isTheSamePersonAsCurrent({ path, currentPath }): boolean {
    const previousPerson = this.get(path)
    const currentPerson = this.get(currentPath)
    if (previousPerson == null || currentPerson == null) {
      return false
    }

    return (
      previousPerson.name.first_name === currentPerson.name.first_name &&
      previousPerson.name.middle_name === currentPerson.name.middle_name &&
      previousPerson.name.last_name === currentPerson.name.last_name
    )
  }

  previousPersonStatusIsNotTheGivenCode({ code, path }): boolean {
    const previousPersonStatus = this.get(`${path}.legal_status`)

    return !previousPersonStatus || previousPersonStatus.unknown || previousPersonStatus.code !== code
  }

  childCurrentlyLivesWithApplicant({ pathIndex }): boolean {
    return Boolean(
      this.get('applicant.family.children.$.currently_lives_with_applicant', {
        pathIndex
      })
    )
  }

  isUnemployed({ path }): boolean {
    return Boolean(this.get(`${path}.unemployed`))
  }

  rawNameIsNotPresentAt({ path }): boolean {
    return !this.get(`${path}.name`)
  }

  doesNotQualifyForSelectiveService(): boolean {
    return !this.get('applicant_qualifies_for_selective_service')
  }

  didNotRegisterForSelectiveService(): boolean {
    return !this.get('applicant.moral_character.registered_for_selective_service')
  }

  registeredForSelectiveService(): boolean {
    return Boolean(this.get('applicant.moral_character.registered_for_selective_service'))
  }

  applicantIs26OrOlder(): boolean {
    return Boolean(this.get('applicant_is_26_or_older'))
  }

  applicantIsYoungerThan26(): boolean {
    return Boolean(this.get('applicant_is_younger_than_26'))
  }

  mailingAddressSameAsCurrentAddress(): boolean {
    return Boolean(this.get('applicant.mailing_address_same_as_current_address'))
  }

  applicantDoesNotHaveDisabilities(): boolean {
    const disabilities = this.get('applicant.disability_accommodations')

    return disabilities == null || _isEmpty(disabilities)
  }

  applicantDoesNotHaveResidencyIssues(): boolean {
    return !this.get('applicant.has_residency_issues')
  }

  applicantNeedsResidencyFlag(): boolean {
    return Boolean(this.get('applicant.needs_residency_flag'))
  }
}
