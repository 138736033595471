import { useEffect } from 'react'

export interface PlacesAutoCompleteOptions {
  placeType: PlaceTypes
  isUnitedStates?: boolean
}

export type PlaceTypes = 'city' | 'address'

/**
 * A hook to attach the google maps places autocomplete script to an input field.
 *
 * Uses google.maps.places.Autocomplete
 * Ref: https://developers.google.com/maps/documentation/javascript/examples/places-autocomplete#maps_places_autocomplete-javascript
 */
export const usePlacesAutoComplete = (
  autoCompleteRef: React.MutableRefObject<any>,
  options: PlacesAutoCompleteOptions,
  onSelectCallback: (
    addressComponents: google.maps.GeocoderAddressComponent[],
    autoComplete: google.maps.places.Autocomplete
  ) => void
) => {
  let autoComplete: google.maps.places.Autocomplete

  const handlePlaceSelect = (autoComplete: google.maps.places.Autocomplete) => {
    if (!autoComplete) {
      return
    }

    const addressObject = autoComplete.getPlace()

    const address_components = addressObject?.address_components

    if (address_components) {
      onSelectCallback(address_components, autoComplete)
    }
  }

  useEffect(() => {
    const componentRestrictions = options?.isUnitedStates ? { country: 'us' } : null
    const types = options?.placeType === 'city' ? ['(cities)'] : [options?.placeType]
    autoComplete = new window.google.maps.places.Autocomplete(autoCompleteRef.current, {
      componentRestrictions,
      fields: ['address_components'],
      types
    })
    autoComplete.addListener('place_changed', () => handlePlaceSelect(autoComplete))

    return () => {
      autoComplete.unbind('place_changed')
    }
  }, [autoComplete])
}
