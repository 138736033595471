import { PanelType } from './index'
import _compact from 'lodash/compact'
import _every from 'lodash/every'
import _some from 'lodash/some'
import _snakeCase from 'lodash/snakeCase'
import _isNil from 'lodash/isNil'
import Decorator from 'lib/decorator'
import FieldMetadata from 'components/forms/field/metadata'
import { KaseModelMetadata } from 'reducers/model'
import { PathValidationConfig } from 'lib/validations'

export default class PanelMetadata {
  panel: PanelType
  kaseKind: string
  allFields: FieldMetadata[]
  fieldsToShow: FieldMetadata[]
  data: Decorator

  constructor(
    panel: PanelType,
    kaseKind: string,
    data: Decorator,
    modelMetadata: KaseModelMetadata,
    validationConfig: PathValidationConfig
  ) {
    this.data = data
    this.panel = panel
    this.kaseKind = kaseKind

    this.allFields = panel.fields.map(
      (field) => new FieldMetadata(field, data, modelMetadata, validationConfig, panel.currentIndex, panel.parentIndex)
    )

    this.fieldsToShow = this.allFields.filter((field) => !field.isHidden())
  }

  getMetadata(): PanelType {
    return {
      ...this.panel,
      name: this.getPanelName(),
      complete: this.isComplete(),
      hasData: this.hasData(),
      slug: this.getPanelSlug(),
      panel_keys: this.getPanelKeys(),
      fields: this.fieldsToShow.map((field) => field.getMetadata()),
      allFields: this.allFields.map((field) => field.getMetadata()),
      contributesToProgress: this.contributesToProgress()
    }
  }

  getPanelName(): string {
    if (this.panel.panel_index != null) {
      return `${this.panel.name}_${this.panel.panel_index}`
    }

    return this.panel.name
  }

  formatIndex(index) {
    if (!isNaN(index)) {
      return parseInt(index, 10) + 1
    } else {
      return index
    }
  }

  getPanelSlug(): string {
    const { panel_index, parentIndex } = this.panel
    let slug = this.panel.slug

    if (panel_index != null) {
      const slugIndex = this.formatIndex(panel_index)

      // matches 'foo/$/bar'
      if (slug && slug.match(/\/\$\//)) {
        const index = this.formatIndex(parentIndex)
        slug = slug.replace('$', index.toString())
      }

      return `${slug}/${slugIndex.toString()}`
    }

    return slug
  }

  getPanelKeys(): string | string[] {
    const { i18n_key, section_name, name, currentIndex } = this.panel

    if (i18n_key) {
      return i18n_key
    }

    const kaseKindUnderscore = _snakeCase(this.kaseKind)
    const baseKey = `${kaseKindUnderscore}.${section_name}.panels.${name}`

    if (!_isNil(currentIndex)) {
      return [`${baseKey}_${currentIndex}`, baseKey]
    } else {
      return baseKey
    }
  }

  hasData(): boolean {
    if (!this.isQuestionPanel()) {
      return false
    }

    return _some(this.presenceFields(), (field) => field.fieldHasData())
  }

  isComplete(): boolean {
    if (!this.isQuestionPanel()) {
      return true
    }

    const excuser = this.excuserField()
    if (excuser && excuser.isExcused()) {
      return true
    }

    const qualifier = this.qualifierField()
    if (qualifier && qualifier.isQualified()) {
      return true
    }

    const fieldsToCheckForCompleteness = _compact([...this.dataFields(), qualifier])
    return _every(fieldsToCheckForCompleteness, (fieldMetadata) => {
      if (fieldMetadata.isDataField() && !fieldMetadata.isRequired()) {
        return true
      }

      return fieldMetadata.isComplete()
    })
  }

  isQuestionPanel(): boolean {
    return !this.panel.component
  }

  excuserField(): FieldMetadata {
    return this.fieldsToShow.find((field) => field.isExcuser())
  }

  qualifierField(): FieldMetadata {
    return this.fieldsToShow.find((field) => field.isQualifier())
  }

  dataFields(): FieldMetadata[] {
    return this.fieldsToShow.filter((field) => field.isDataField())
  }

  presenceFields(): FieldMetadata[] {
    return this.fieldsToShow.filter((field) => !field.isIgnoredForPresence())
  }

  contributesToProgress(): boolean {
    return _some(this.fieldsToShow, (field) => field.isRequired())
  }
}
