import Decorator from 'lib/decorator'
import getDocumentRequestLocaleService from 'services/document_request_locale_service'
import { DocumentRequestStatus } from 'lib/constants'

class DocumentRequestDecorator extends Decorator {
  getTitle(): string {
    let title: string = this.title || this.t('title', true)

    // Rails sometimes encodes apostrophes as &#39;
    title = title.replace(/&#39;/g, "'")

    return title
  }

  getDescription(): string {
    return this.description || this.t('description', true)
  }

  isAccepted(): boolean {
    return this.status === DocumentRequestStatus.Accepted
  }

  getDocumentsPath(): string {
    return `${this.path}.documents`
  }

  needsMoreDocs(): boolean {
    return this.status === DocumentRequestStatus.NeedsCustomerAction
  }

  private t(...args) {
    return getDocumentRequestLocaleService().t(this, ...args)
  }
}

interface DocumentRequestDecorator extends Decorator, DocumentRequestModel {}

export default DocumentRequestDecorator
