import React from 'react'
import { connect } from 'react-redux'

import AskQuestionButton from 'components/ask_question_button'
import Fee from 'components/fee'
import Payment from 'components/payment'

import formatMoney from 'lib/format_money'
import { toggleContextualHelp } from 'actions/help_actions'
import { KaseKind, WorkflowCategory } from 'lib/constants'

import {
  getBoundlessFeeInCents,
  getCurrentApplicationFeeInCents,
  getLawyerReviewFeeInCents,
  getCurrentKaseKind,
  getWorkflowCategory,
  isCurrentKasePaid,
  getExternalDomain
} from 'reducers/selectors'
import VisaIcon from 'components/icons/visa_icon'
import MastercardIcon from 'components/icons/mastercard_icon'
import AmexIcon from 'components/icons/amex_icon'
import DiscoverIcon from 'components/icons/discover_icon'

interface Props {
  boundlessFeeInCents: number
  currentApplicationFeeInCents: number
  currentKaseKind: KaseKind
  mbgcWorkflowCategory: WorkflowCategory
  isPaidUser: boolean
  lawyerReviewFeeInCents: number
  toggleContextualHelp: any
  externalDomain: string
}

interface State {
  paymentType: string
}

class SatisfactionGuarantee extends React.Component<Props, State> {
  state = {
    paymentType: 'stripe'
  }

  renderPaymentButton() {
    if (!this.props.isPaidUser) {
      return <Payment paymentType={this.state.paymentType} />
    }
  }

  renderPaymentOptions() {
    if (!this.props.isPaidUser) {
      return (
        <fieldset className="o-block c-paper-form">
          <div className="o-block c-paper-form__group">
            <div className="o-block">
              <label className="o-media c-custom-control c-custom-control--off@sm c-custom-control--radio">
                <input
                  type="radio"
                  className="c-custom-control__input"
                  name="paymentType"
                  onChange={this.handleOptionChange}
                  value="stripe"
                  checked={this.state.paymentType === 'stripe'}
                />
                <span className="c-custom-control__indicator" />
                <p className="o-block o-block--compact o-block--off@md c-type c-type--body-sans-md c-custom-control__description">
                  Credit / Debit Card
                </p>
                <div className="o-media__figure">
                  <ul className="o-list__inline o-list__inline--tight t-color--primary">
                    <li>
                      <VisaIcon />
                    </li>
                    <li>
                      <MastercardIcon />
                    </li>
                    <li>
                      <AmexIcon />
                    </li>
                    <li>
                      <DiscoverIcon />
                    </li>
                  </ul>
                </div>
              </label>
            </div>
          </div>
          <div className="o-block c-paper-form__group">
            <div className="o-block">
              <label className="o-media c-custom-control c-custom-control--off@sm c-custom-control--radio">
                <input
                  type="radio"
                  className="c-custom-control__input"
                  name="paymentType"
                  onChange={this.handleOptionChange}
                  value="splitIt"
                  checked={this.state.paymentType === 'splitIt'}
                />
                <span className="c-custom-control__indicator" />
                <div className="o-block o-block--compact o-block--off@md c-custom-control__description">
                  <p className="o-block o-block--tight c-type c-type--body-sans-md">Monthly Payments</p>
                  <p className="c-type c-type--body-sans-sm c-type--bold">
                    <a onClick={this.onClick}>0% interest, as low as ${this.monthlyPrice()}/month</a>
                  </p>
                </div>
                <div className="o-media__figure">
                  <ul className="o-list__inline o-list__inline--tight t-color--primary">
                    <li>
                      <VisaIcon />
                    </li>
                    <li>
                      <MastercardIcon />
                    </li>
                    <li>
                      <AmexIcon />
                    </li>
                  </ul>
                </div>
              </label>
            </div>
          </div>
        </fieldset>
      )
    }
  }

  monthlyPrice = () => {
    return Math.round(this.props.currentApplicationFeeInCents / 1200)
  }

  handleOptionChange = (event) => {
    this.setState({ paymentType: event.target.value })
  }

  render() {
    const { boundlessFeeInCents, lawyerReviewFeeInCents } = this.props

    return (
      <div className="o-grid__row">
        <div className="o-grid__col-6 o-grid__col--offset-1">
          <div className="o-block o-block--copious">
            <h1 className="o-block c-type c-type--headline-md c-type--emphasized">
              Your application checked and lawyer-reviewed
            </h1>
            <p className="o-block c-type c-type--headline-sm">
              Once you finish your forms, we’ll check them for completeness and accuracy, and we’ll check your info
              twice more after that. Then your independent attorney will review your entire application to make sure
              you’re ready to file.
            </p>
          </div>

          <div className="o-block">
            <hr className="c-divider c-divider--important c-divider--primary" />
            <div className="o-box o-box--ample">
              <p className="o-block c-type c-type--body-serif-sm c-type--emphasized">
                Attorney-reviewed, fully prepared package sent to your door.
                <br className="u-hidden-md-down" />
                100% satisfaction guarantee. All for a flat fee of <Fee />*
              </p>

              {this.renderPaymentOptions()}
              {this.renderPaymentButton()}

              <p className="o-block c-type c-type--body-serif-sm">
                * Your payment includes a {formatMoney(boundlessFeeInCents)} fee for Boundless and a{' '}
                {formatMoney(lawyerReviewFeeInCents)} independent attorney fee. Government filing fees not included.
              </p>
            </div>
            <hr className="c-divider c-divider--primary" />
          </div>

          <p className="o-block c-type c-type--body-sans-sm">
            Have questions? <AskQuestionButton via="PetitionOutcome">Chat with us</AskQuestionButton> or give us a call
            at{' '}
            <a href="tel:1-855-268-6353" className="c-link">
              1&#8209;855&#8209;268&#8209;6353
            </a>
            .
          </p>
        </div>
      </div>
    )
  }

  onClick = (event) => {
    event.preventDefault()
    this.props.toggleContextualHelp({ via: 'MonthlyPayment' })
    this.setState({ paymentType: 'splitIt' })
  }
}

function mapDispatchToActions(dispatch) {
  return {
    toggleContextualHelp: (...args) => dispatch(toggleContextualHelp(...args))
  }
}

function mapStateToProps(state) {
  return {
    boundlessFeeInCents: getBoundlessFeeInCents(state),
    currentApplicationFeeInCents: getCurrentApplicationFeeInCents(state),
    currentKaseKind: getCurrentKaseKind(state),
    mbgcWorkflowCategory: getWorkflowCategory(state),
    isPaidUser: isCurrentKasePaid(state),
    lawyerReviewFeeInCents: getLawyerReviewFeeInCents(state),
    externalDomain: getExternalDomain(state)
  }
}

export default connect(mapStateToProps, mapDispatchToActions)(SatisfactionGuarantee)
