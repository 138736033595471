import React, { FunctionComponent, useEffect, useRef, useState } from 'react'

import CommonLabelV2 from 'components/forms/inputs/v2/address/common/common_label_v2'
import { PlaceTypes, usePlacesAutoComplete } from './usePlacesAutoComplete'
import { inputWarnings } from '../input/input_validators'

export interface PlacesInputProps {
  disabled?: boolean
  id: string
  isUnitedStates?: boolean
  name: string
  onBlurEvent?: (event: React.ChangeEvent<HTMLInputElement> | string) => any
  onChangeEvent: (event: React.ChangeEvent<HTMLInputElement>) => any
  onPlacesUpdate: (answer: google.maps.GeocoderAddressComponent[]) => void
  placeType: PlaceTypes
  value?: string
}

/**
 * A string input that uses Google Places Autocomplete to return
 * a full address instead of a single address component.
 *
 * Falls back to saving the input string on blur if it fails.
 */
const PlacesInput: FunctionComponent<PlacesInputProps> = ({
  disabled,
  id,
  isUnitedStates,
  name,
  onBlurEvent,
  onChangeEvent,
  onPlacesUpdate,
  placeType,
  value
}) => {
  // This autoCompleteInputRef is used to connect the <input> below to
  // the Google Places auto-complete functionality
  const autoCompleteInputRef = useRef(null)
  const [blurTimeout, setBlurTimeout] = useState<NodeJS.Timeout>(null)
  const savedTimeout = useRef(blurTimeout)

  const [displayValue, setDisplayValue] = useState(value)
  useEffect(() => {
    setDisplayValue(value)
  }, [value])

  // If the blur is triggered by selecting from googles places autocomplete,
  // clearing the timeout will cancel the blur from triggering the default save.
  const onPlaceSelected = (addressComponents: google.maps.GeocoderAddressComponent[]) => {
    clearTimeout(savedTimeout.current)
    onPlacesUpdate(addressComponents)
  }

  usePlacesAutoComplete(autoCompleteInputRef, { placeType, isUnitedStates }, onPlaceSelected)

  useEffect(() => {
    savedTimeout.current = blurTimeout
  }, [blurTimeout])

  // This prevents the blur from triggering a save, but only if the Google Places
  // Autocomplete doesn't work or if the user needs to type an exact answer in. So we
  // delay the blur by HANDLE_BLUR_DELAY to allow the chance to cancel the blur in the
  // onPlaceSelected callback function above.
  const HANDLE_BLUR_DELAY = 300
  const handleBlur = (event) => {
    event.persist()
    setBlurTimeout(
      setTimeout(() => {
        if (onBlurEvent) {
          onBlurEvent(event)
        }
      }, HANDLE_BLUR_DELAY)
    )
  }

  return (
    <div className="o-flag o-grid--fluid o-grid--stack@sm o-block c-paper-form__group">
      <CommonLabelV2 label={name} labelFor={name} />
      <div className="o-grid__cell--9/12 o-grid__cell--off@sm c-paper-form__segment c-paper-form__segment--drop@sm">
        <input
          autoComplete="off"
          ref={autoCompleteInputRef}
          className="c-paper-form__control"
          disabled={disabled}
          id={id}
          name={name}
          value={displayValue}
          onChange={onChangeEvent}
          onBlur={handleBlur}
        />
        {placeType === 'address' && displayValue?.length > inputWarnings.street_address.maxLength && (
          <div className="o-flag__item o-flag__item--drop@sm c-tooltip c-tooltip--warning" key="too-long">
            <div className="c-type c-type--body-sans-sm">{inputWarnings.street_address.message}</div>
          </div>
        )}
      </div>
    </div>
  )
}

export default PlacesInput
