import { DocumentRequestsState } from './index'

export const getDocumentRequestPathsForKase = (state: DocumentRequestsState, kaseId: number) => {
  return state.documentRequestPaths[kaseId] || []
}

export const isCurrentlyLoadingDocumentRequests = (state: DocumentRequestsState) => state.isLoading

export const isCurrentlyLoadingPreviewDocument = (state: DocumentRequestsState) => state.isDocumentPreviewLoading

export const isCurrentlyReplacingDocument = (state: DocumentRequestsState) => state.isDocumentReplacing
