import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import getTelemetryService from 'lib/telemetry'
import api from 'lib/api'
import { CONVERSION_TRACKING_EVENTS, KaseKind, TELEMETRY_EVENTS } from 'lib/constants'
import { isEmailValid } from 'lib/validations/validators/valid_email'
import { externalUrl } from 'lib/settings'

import getStore from 'stores/app_store'

import { openModal, closeModal } from 'actions/modal_actions'
import { updateUserLoggedIn } from 'actions/model_actions'
import { loadCurrentUser } from 'actions/user_actions'
import { trackUserEvent, trackClientSideUserEvent } from 'actions/telemetry_actions_v2'
import { initializeTelemetrySession, postTelemetry, postTelemetryWithConversion } from 'actions/telemetry_actions'

import { getActiveModal, getCurrentKaseKind, getKaseTags } from 'reducers/selectors'
import { ApplicationState } from 'reducers'

import SuccessCheckmarkIcon from 'components/icons/success_checkmark_icon'
import ExternalLink from 'components/external_link'
import Button from 'components/button'
import Paragraph from 'components/type/paragraph'

interface SignInParams {
  customTitle?: string
  isLogIn?: boolean
  noRedirect?: boolean
  successUrl?: string
  via: string
}
export const showSignInModal = ({ customTitle, isLogIn = true, noRedirect = false, successUrl, via }: SignInParams) => {
  const modalProps = {
    customTitle,
    isLogIn,
    noRedirect,
    successUrl
  }

  getStore().dispatch(openModal({ name: 'SessionModal', via, modalProps }))

  return false
}

export const closeSignInModal = (via) => {
  getStore().dispatch(closeModal({ name: 'SessionModal', via }))
  return false
}

interface ExplicitProps {
  isLogIn?: boolean
  noRedirect: boolean
  store: ApplicationState
  successUrl?: string
  noHeader?: boolean
  initializeTelemetrySession: typeof initializeTelemetrySession
  loadCurrentUser: typeof loadCurrentUser
  onViewChange: (isLogIn: boolean) => void
  postTelemetry: typeof postTelemetry
  postTelemetryWithConversion: typeof postTelemetryWithConversion
  trackUserEvent: typeof trackUserEvent
  trackClientSideUserEvent: typeof trackClientSideUserEvent
  updateUserLoggedIn: typeof updateUserLoggedIn
}

interface MappedProps {
  kaseKind?: string
  activeModal?: string
  kaseTags?: any
}

type Props = MappedProps & ExplicitProps

interface State {
  isLogIn: boolean
  errors: any
  successUrl: string
  accountCreated: boolean
  isSubmitting: boolean
  revealPassword: boolean
  email?: string
  password?: string
  hasAcceptedPrivacyPolicy: boolean
  optInString?: string
  hasOptedIntoEmails: boolean
}

export class SignInNav extends React.Component<Props, State> {
  static defaultProps = {
    noRedirect: false,
    onViewChange: () => {}
  }

  private emailRef = React.createRef<HTMLInputElement>()
  private passwordRef = React.createRef<HTMLInputElement>()

  constructor(props) {
    super(props)

    this.state = {
      isLogIn: Boolean(props.isLogIn),
      errors: {},
      successUrl: this.props.successUrl,
      accountCreated: false,
      isSubmitting: false,
      revealPassword: false,
      email: this.getPrefillEmail(),
      hasAcceptedPrivacyPolicy: false,
      optInString: this.getOptInString(),
      hasOptedIntoEmails: true
    }
  }

  validate() {
    const email = (this.state.email || '').trim()
    const validEmail = email.length > 0 && isEmailValid(email)

    if (!validEmail) {
      this.setState({ errors: { email: ['Please enter a valid email'] } })
      return false
    }

    const password = this.state.password || ''

    if (!password.trim()) {
      this.setState({ errors: { password: ['Please enter your password'] } })
      return false
    }

    return true
  }

  logIn() {
    if (!this.validate()) {
      return
    }

    const { email, password } = this.state
    const request = { email, password }

    api.users
      .signIn(request)
      .then((user) => {
        if (this.props.noRedirect) {
          this.props.initializeTelemetrySession(user)
          this.props.postTelemetry(TELEMETRY_EVENTS.LOGGED_IN_USER)
          this.noRedirectUpdate(user)
        } else {
          this.props.initializeTelemetrySession(user)
          this.props.postTelemetry(TELEMETRY_EVENTS.LOGGED_IN_USER, {}, () => {
            window.location.href = this.redirectToUrl()
          })
        }
      })
      .catch((errors) => this.setState({ errors }))
  }

  redirectTo() {
    window.window.location.href = this.redirectToUrl()
  }

  redirectToUrl() {
    return this.state.successUrl || window.location.pathname
  }

  noRedirectUpdate(user) {
    if (this.props.activeModal === 'SessionModal') {
      closeSignInModal('SignUpModal')
      this.props.updateUserLoggedIn()
      this.props.loadCurrentUser(user)
    }
  }

  successMsg() {
    let self = this
    this.setState({ accountCreated: true })
    window.setTimeout(() => {
      if (self.props.noRedirect) {
        self.noRedirectUpdate({
          email: this.state.email
        })
      } else {
        self.redirectTo()
      }
    }, 2500)
  }

  getCurrentKaseId() {
    return window.applicationDataStore && window.applicationDataStore.getCurrentKaseId()
  }

  getPrefillEmail() {
    return window.applicationDataStore.getCanonicalEmailAddress()
  }

  getOptInString() {
    if (this.props.isLogIn) return ''
    switch (this.props.kaseKind) {
      case KaseKind.K1FianceVisa: {
        return 'K-1 fiancé visa'
      }
      case KaseKind.NewMarriageBasedGreenCard: {
        return 'Marriage Based Green Card'
      }
      default: {
        return ''
      }
    }
  }

  signUp() {
    if (!this.validate() || this.state.isSubmitting) return false

    this.setState({ isSubmitting: true })

    const request = {
      email: this.state.email,
      password: this.state.password,
      kase_id: this.getCurrentKaseId(),
      email_opt_in: this.state.hasOptedIntoEmails
    }

    api.users
      .create(request)
      .then(this.afterSignUp)
      .catch((errors) => this.setState({ errors, isSubmitting: false }))
  }

  afterSignUp = (user) => {
    const telemetryService = getTelemetryService()

    if (this.props.kaseKind === 'K1FianceVisa' || this.props.kaseKind === 'NewMarriageBasedGreenCard') {
      telemetryService.captureGoogleAdsConversion(CONVERSION_TRACKING_EVENTS.REGISTRATION)
      telemetryService.track(TELEMETRY_EVENTS.BECAME_LEAD)
      if (this.props.kaseTags.length > 0) {
        telemetryService.track(`Lead-${this.props.kaseTags[0].name}`)
      }
    }

    this.successMsg()
  }

  handleSubmit = (event) => {
    event.preventDefault()

    this.setState({ errors: {} })

    this.state.isLogIn ? this.logIn() : this.signUp()
  }

  switchSignInType = (event) => {
    event.preventDefault()

    const isLogIn = !this.state.isLogIn

    this.setState({ isLogIn })
    this.props.onViewChange(isLogIn)

    const emailRef = this.emailRef.current
    if (emailRef && !emailRef.value) {
      emailRef.focus()
    }
  }

  hasKnownPasswordError() {
    const passwordErrors = this.state.errors['password']

    return passwordErrors && passwordErrors[0] === 'Password known-password'
  }

  emailTakenError() {
    const emailErrors = this.state.errors['email']

    return emailErrors && emailErrors[0] === 'Email already-taken'
  }

  handlePasswordArticleClick(event) {
    event.preventDefault()

    getTelemetryService().trackLink({
      linkName: 'PasswordSecurityArticle',
      redirectTo: externalUrl('/security/password-security'),
      newTab: true
    })
  }

  renderErrors(fieldName) {
    const messages = this.state.errors[fieldName]

    if (fieldName === 'password' && this.hasKnownPasswordError()) {
      return (
        <div className="c-alert c-alert--danger">
          <div className="c-type c-type--body-sans-sm">
            Your password is too easy to guess or is a known password, please try another. See{' '}
            <a href={externalUrl('/security/password-security')} onClick={this.handlePasswordArticleClick}>
              this article
            </a>{' '}
            for information on good passwords.
          </div>
        </div>
      )
    }

    if (fieldName === 'email' && this.emailTakenError()) {
      return (
        <div className="c-alert c-alert--danger">
          <div className="c-type c-type--body-sans-sm">Invalid email or password.</div>
        </div>
      )
    }

    if (messages && messages.length > 0) {
      return (
        <div className="c-alert c-alert--danger">
          <span className="c-type c-type--body-sans-sm">{messages.join(', ')}</span>
        </div>
      )
    }
  }

  renderAllErrors() {
    return (
      <div className="o-block">
        {this.renderErrors('topLevel')}
        {this.renderErrors('email')}
        {this.renderErrors('password')}
      </div>
    )
  }

  componentDidMount() {
    this.props.onViewChange(this.state.isLogIn)

    setTimeout(() => {
      const emailRef = this.emailRef.current
      if (emailRef) {
        emailRef.focus()
      }
    }, 0)

    if (!this.props.isLogIn) {
      this.props.trackUserEvent(TELEMETRY_EVENTS.VIEWED_REGISTRATION)
    }
  }

  getCTALabel() {
    if (this.state.isLogIn) return 'Log In'
    if (this.state.isSubmitting) return 'Creating account...'

    if (this.props.kaseKind === KaseKind.K1FianceVisa) {
      return 'Continue'
    } else {
      return 'Save Progress'
    }
  }

  renderEmailField() {
    return (
      <div className="o-block">
        <label className="c-type c-type--body-sans-md mb-3" htmlFor="sign-up-email">
          Email
        </label>
        <input
          ref={this.emailRef}
          id="sign-up-email"
          autoFocus
          className="c-input"
          type="email"
          value={this.state.email || ''}
          onChange={(e) => this.setState({ email: e.target.value })}
        />
      </div>
    )
  }

  toggleRevealPassword = (event) => {
    event.preventDefault()

    this.setState({ revealPassword: !this.state.revealPassword })

    const passwordRef = this.passwordRef.current
    if (passwordRef) {
      passwordRef.focus()
    }
  }

  showPasswordButton() {
    const { password, revealPassword } = this.state

    if (!password || password.length === 0) return

    return (
      <div className="o-layout--vertical-center o-layout--impose__body">
        <a href="#" className="c-type c-type--body-sans-sm c-link o-layout--center" onClick={this.toggleRevealPassword}>
          {revealPassword ? 'Hide' : 'Show'}
        </a>
      </div>
    )
  }

  renderPasswordField() {
    const fieldType = this.state.revealPassword ? 'text' : 'password'

    return (
      <div className="o-block o-block--compact">
        <label className="c-type c-type--body-sans-md mb-3" htmlFor="sign-up-password">
          Password (6 characters minimum)
        </label>
        <div className="o-layout--impose">
          <input
            ref={this.passwordRef}
            id="sign-up-password"
            autoComplete="off"
            className="c-input c-input--password"
            type={fieldType}
            onChange={(e) => this.setState({ password: e.target.value })}
          />
          {this.showPasswordButton()}
        </div>
      </div>
    )
  }

  private togglePrivacyPolicyCheckbox = () => {
    this.setState({
      hasAcceptedPrivacyPolicy: !this.state.hasAcceptedPrivacyPolicy
    })
  }

  private toggleOptInCheckbox = () => {
    this.setState({
      hasOptedIntoEmails: !this.state.hasOptedIntoEmails
    })
  }

  renderHeader() {
    if (this.state.isLogIn || this.props.noHeader) return null

    return (
      <Paragraph size="sm">
        Secure your information, and continue making progress for free. You only pay when your application is reviewed
        by an independent attorney.
      </Paragraph>
    )
  }

  renderFooter() {
    if (this.state.isLogIn) return null

    return (
      <>
        <label className="o-block o-block--compact c-custom-control c-custom-control--check c-custom-control--multiline">
          <div className="c-custom-control__multiline-wrapper">
            <input
              type="checkbox"
              className="c-custom-control__input"
              name="privacy-policy"
              id="checkbox-for-privacy-policy"
              onChange={this.togglePrivacyPolicyCheckbox}
            />
            <span className="c-custom-control__indicator" />
          </div>
          <span className="c-custom-control__description c-type c-type--body-sans-md">
            I agree to the Boundless{' '}
            <ExternalLink href={externalUrl('/privacy')} data-qa="privacy-policy-link">
              Privacy Policy
            </ExternalLink>
            .
          </span>
        </label>

        {this.state.optInString && (
          <label className="o-block o-block--compact c-custom-control c-custom-control--check c-custom-control--multiline">
            <div className="c-custom-control__multiline-wrapper">
              <input
                type="checkbox"
                className="c-custom-control__input"
                name="opt-in"
                id="checkbox-for-opt-in"
                defaultChecked={this.state.hasOptedIntoEmails}
                onChange={this.toggleOptInCheckbox}
              />
              <span className="c-custom-control__indicator" />
            </div>
            <span className="c-custom-control__description c-type c-type--body-sans-md c-type--primary-medium">
              Send me important tips and updates about the {this.state.optInString} process.
            </span>
          </label>
        )}

        <Paragraph>
          By continuing, I agree to the{' '}
          <ExternalLink href={externalUrl('/terms')} data-qa="terms-of-use-link">
            Terms of Use
          </ExternalLink>
          .
        </Paragraph>
      </>
    )
  }

  renderDefaultView() {
    const { isLogIn, isSubmitting, hasAcceptedPrivacyPolicy } = this.state

    const submitButtonDisabled = isSubmitting || (!isLogIn && !hasAcceptedPrivacyPolicy)

    return (
      <React.Fragment>
        {this.renderHeader()}

        <form>
          {this.renderEmailField()}
          {this.renderPasswordField()}

          {this.renderAllErrors()}

          {this.renderFooter()}

          <div className="o-block o-block--compact">
            <Button
              data-qa="sign-in-submit-button"
              block
              color="primary"
              onClick={this.handleSubmit}
              disabled={submitButtonDisabled}
              label={this.getCTALabel()}
              type="submit"
            />
          </div>

          {isLogIn && (
            <div className="o-block o-block--compact c-type c-type--body-sans-sm">
              <a href="/users/password/new">Forgot your password?</a>
            </div>
          )}
        </form>

        <hr className="o-block o-block--compact c-divider" />

        <p className="o-block c-type c-type--body-sans-sm c-type--muted">
          {isLogIn ? "Don't have a Boundless account? " : 'Already have an application in progress? '}
          <a href={isLogIn ? '#signup' : '#login'} onClick={this.switchSignInType}>
            {isLogIn ? 'Sign up' : 'Log in'}
          </a>
        </p>
      </React.Fragment>
    )
  }

  renderSuccessView() {
    return (
      <div className="o-layout--center o-layout--padded-x--full">
        <div className="o-block">
          <SuccessCheckmarkIcon className="c-icon--xl" />
        </div>
        <h3 className="o-block o-block--ample c-type c-type--header-sm c-type--emphasized">
          Your secure account has been created
        </h3>
        <p className="o-block c-type c-type--body-sans-md">
          You progress is saved, secure, and accessible from any device &mdash; just login to access it. From now on
          your progress will save automatically as you complete your application.
        </p>
        <div className="o-box o-box--compact">
          <p className="c-type c-type--body-sans-sm">
            This window will close automatically, or you can{' '}
            <a
              className="c-link"
              href="#"
              onClick={this.noRedirectUpdate.bind(this, {
                email: this.state.email
              })}
            >
              click here
            </a>{' '}
            to close and continue.
          </p>
        </div>
      </div>
    )
  }

  render() {
    // don't show the success view after an account is created for K1
    return this.state.accountCreated && this.props.kaseKind !== KaseKind.K1FianceVisa
      ? this.renderSuccessView()
      : this.renderDefaultView()
  }
}

function mapDispatchToActions(dispatch) {
  return bindActionCreators(
    {
      loadCurrentUser,
      updateUserLoggedIn,
      initializeTelemetrySession,
      postTelemetry,
      postTelemetryWithConversion,
      trackUserEvent,
      trackClientSideUserEvent
    },
    dispatch
  )
}

function mapStateToProps(state) {
  return {
    activeModal: getActiveModal(state),
    kaseKind: getCurrentKaseKind(state),
    kaseTags: getKaseTags(state)
  }
}

export default connect(mapStateToProps, mapDispatchToActions)(SignInNav)
