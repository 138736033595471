import moment from 'moment'

import { getHumanNumber } from 'lib/language_helpers'

type FormattableValue = string | moment

export function formatDate(date: moment | Date, format: string = 'MMMM Do, Y'): string {
  if (date instanceof Date) date = moment(date)
  if (date instanceof moment) return date.format(format)

  return date
}

function formatHumanNumber(value: string): string {
  return getHumanNumber(parseInt(value, 10))
}

export function i18nFormatter(value: FormattableValue, format: string) {
  switch (format) {
    case 'date': {
      return formatDate(value)
    }
    case 'humanNumber': {
      return formatHumanNumber(value)
    }
    case 'uppercase': {
      return value.toUpperCase()
    }
    case 'lowercase': {
      return value.toLowerCase()
    }
    default: {
      return value
    }
  }
}
