import React from 'react'
import classNames from 'classnames'
import { connect } from 'react-redux'

import { showPreviousPanel } from 'actions/panel_actions'

import {
  getCurrentPanel,
  isCurrentlyAtSectionStart,
  isCurrentPanelCustomComponent,
  isInReviewMode
} from 'reducers/selectors'

import { PanelType } from 'components/forms/panel'
import ArrowIcon from 'components/icons/arrow_icon'

interface Props {
  currentPanel: PanelType
  isAtSectionStart: boolean
  isInReviewMode: boolean
  isOnCustomComponent: boolean
  showPreviousPanel: Function
}

class PreviousButton extends React.Component<Props> {
  onClick = (event: SyntheticMouseEvent<HTMLButtonElement>) => {
    event.preventDefault()

    this.props.showPreviousPanel({ via: 'PreviousPanelLink' })
  }

  isHidden() {
    return this.props.isAtSectionStart || this.props.isInReviewMode
  }

  isFloating() {
    return this.props.isOnCustomComponent && !this.isEmphasized()
  }

  isEmphasized() {
    return Boolean(this.props.currentPanel && this.props.currentPanel.emphasized)
  }

  render() {
    if (this.isHidden()) return null

    const actionContainerClassesMobile = classNames(
      'c-sheet__action-container c-sheet__action-container--secondary u-hide@md-up',
      {
        'c-sheet__action-container--floating': this.isFloating()
      }
    )

    return (
      <React.Fragment>
        <div className="c-sheet__action-container c-sheet__action-container--secondary u-hide@sm-down">
          <button
            onClick={this.onClick}
            type="button"
            className="c-sheet__action c-btn c-btn--icon-only c-btn--secondary js-previous-button"
            aria-label="back"
          >
            <span className="c-btn__icon">
              <ArrowIcon arrowDirection="up" />
            </span>
          </button>
        </div>

        <div className={actionContainerClassesMobile}>
          <button
            onClick={this.onClick}
            type="button"
            className="c-sheet__action c-btn c-btn--icon-only c-btn--secondary js-previous-button"
            aria-label="back"
          >
            <span className="c-btn__icon">
              <ArrowIcon arrowDirection="left" />
            </span>
          </button>
        </div>
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    currentPanel: getCurrentPanel(state),
    isAtSectionStart: isCurrentlyAtSectionStart(state),
    isInReviewMode: isInReviewMode(state),
    isOnCustomComponent: isCurrentPanelCustomComponent(state)
  }
}

function mapDispatchToActions(dispatch) {
  return {
    showPreviousPanel: (...args) => dispatch(showPreviousPanel(...args))
  }
}

export default connect(mapStateToProps, mapDispatchToActions)(PreviousButton)
