import React, { FunctionComponent, useState } from 'react'
import cx from 'classnames'

import AskQuestionButton from 'components/ask_question_button'
import ChevronIcon from 'components/icons/chevron_icon'
import PhoneIcon from 'components/icons/phone_icon'
import SupportAgent from 'images/postship-dashboard/supportavatar.png'

interface Props {
  /**
   * Beneficiary first name string provided by BE with the rest of the Milestone data
   * for the welcome header, when available
   */
  beneficiaryFirstName?: string
  /**
   * Boolean to flag small and medium sized browser windows. When someone accesses
   * the Milestone Tracker on a small or medium sized window, we make the Milestone
   * list full width and move the Help menu to the header, other than this difference,
   * medium sized windows follow the same styles as large screens.
   */
  isMobileOrTablet: boolean
  /**
   * Sponsor first name string provided by BE with the rest of the Milestone data
   * for the welcome header, when available
   */
  sponsorFirstName?: string
}

const PostShipHeader: FunctionComponent<Props> = ({ isMobileOrTablet, beneficiaryFirstName, sponsorFirstName }) => {
  const [helpIsOpen, setHelpIsOpen] = useState(false)

  const welcomeHeader =
    sponsorFirstName && beneficiaryFirstName
      ? `Welcome back, ${sponsorFirstName} & ${beneficiaryFirstName}`
      : 'Welcome back'

  const onToggle = (event) => {
    event.preventDefault()
    setHelpIsOpen(!helpIsOpen)
  }

  return (
    <div className="pt-4 py-8 sm:px-4 bg-blue-500 text-white h-92">
      <div className="flex justify-end md:pr-2 position-relative">
        {isMobileOrTablet && (
          <button
            className={cx('c-btn--flat h-full pl-2', {
              'bg-white text-blue-500': helpIsOpen,
              'bg-blue-500 text-white': !helpIsOpen
            })}
            onClick={onToggle}
          >
            Help
            <ChevronIcon className="mr-2" chevronDirection={helpIsOpen ? 'up' : 'down'} />
          </button>
        )}
        {isMobileOrTablet && helpIsOpen && (
          <ul className="o-list-bare c-type c-type--body-sans-sm p-2 pt-4 mt-5 bg-white position-absolute">
            <li className="flex justify-start pb-4">
              <img alt="support agent" className="h-9 w-9" src={SupportAgent} />
              <div className="pl-2 pt-2">
                <AskQuestionButton via="PostShipDashboard">Start Chat</AskQuestionButton>
              </div>
            </li>
            <li className="flex justify-start text-blue-500  pb-4">
              <PhoneIcon size="medium" className="mx-2" />
              <a href="tel:1-855-268-6353" className="c-link pl-2">
                Call Us 1&#8209;855&#8209;268&#8209;6353
              </a>
            </li>
          </ul>
        )}
      </div>
      <div className="o-dashboard__container">
        <h1 className="c-type c-type--body-sans-md lg:pt-6">{welcomeHeader}</h1>
        <h2 className="c-type c-type--headline-lg py-6">Your K-1 Progress</h2>
      </div>
    </div>
  )
}

export default PostShipHeader
