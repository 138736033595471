export enum KaseStates {
  pre_payment = 'pre_payment',
  post_payment = 'post_payment',
  customer_form_check = 'customer_form_check',
  ops_form_check = 'ops_form_check',
  customer_document_upload = 'customer_document_upload',
  ops_document_check = 'ops_document_check',
  customer_document_check = 'customer_document_check',
  quality_assurance = 'quality_assurance',
  legal_review = 'legal_review',
  print_shop = 'print_shop',
  customer_qa = 'customer_qa',
  customer_legal_review = 'customer_legal_review',
  package_shipped = 'package_shipped',
  customer_completed = 'customer_completed'
}

export enum KaseEvents {
  customer_document_check_sent_to_ops = 'customer_document_check_sent_to_ops',
  customer_form_check_sent_to_ops = 'customer_form_check_sent_to_ops',
  customer_qa_sent_to_ops = 'customer_qa_sent_to_ops',
  legal_review_sent_to_ops = 'legal_review_sent_to_ops'
}
