import { OPEN_MODAL, CLOSE_MODAL } from 'lib/constants'
import { isModalOpen, getActiveModal } from 'reducers/selectors'

interface OpenModalArguments {
  name: string
  via: string
  modalProps?: any
}

export function openModal({ name, via, modalProps = {} }: OpenModalArguments) {
  return (dispatch) => {
    dispatch({
      type: OPEN_MODAL,
      modal: name,
      via,
      modalProps
    })
  }
}

export function closeModal(options: any = {}) {
  return (dispatch, getState) => {
    const name = getActiveModal(getState())

    dispatch({
      type: CLOSE_MODAL,
      modal: name,
      via: options.via
    })
  }
}

export function toggleModal({ name, via, modalProps = {} }: OpenModalArguments) {
  return (dispatch, getState) => {
    const currentlyActive = isModalOpen(getState())

    if (currentlyActive) {
      dispatch(closeModal())
    } else {
      dispatch(openModal({ name, via, modalProps }))
    }
  }
}
