import server, { ejectAuthHeader, addAuthHeader } from './server'
import _uniq from 'lodash/uniq'
import { PDFDocument, degrees } from 'pdf-lib'

/**
 * Removes a list of pages from a PDF file.
 * If you'd like to generate a URL from the blob, you can call:
 * `URL.createObjectURL(pdfBlob)`
 * @param pdfUrl The URL of the PDF document to modify
 * @param pages An array of page indices (zero-based)
 */
async function removePagesFromPDF(pdfUrl: string, pages: number[]): Promise<Blob> {
  ejectAuthHeader()

  const pdfBytes: ArrayBuffer = await server
    .get(pdfUrl, {
      responseType: 'arraybuffer'
    })
    .then((res) => res.data as ArrayBuffer)

  addAuthHeader()

  // Load the PDF doc
  const document = await PDFDocument.load(pdfBytes)

  // Sort the pages to remove in descending order
  // If we didn't do that, we could end up deleting the wrong pages
  const sortedPageNumbers = _uniq(pages.sort((a, b) => b - a))

  for (let i = 0; i < sortedPageNumbers.length; i++) {
    document.removePage(sortedPageNumbers[i])
  }

  // Serialize the document
  const updatedPdfBytes = await document.save()

  return new Blob([updatedPdfBytes], { type: 'application/pdf' })
}

export interface PageToRotate {
  index: number
  rotationInDegrees: number
}

/**
 * Rotate the specified pages and return a blob.
 * If you'd like to generate a URL from the blob, you can call:
 * `URL.createObjectURL(pdfBlob)`
 * @param pdfUrl The URL of the PDF to download and edit
 * @param pagesToRotate An array of PageToRotate object
 */
async function rotatePages(pdfUrl: string, pagesToRotate: PageToRotate[]): Promise<Blob> {
  ejectAuthHeader()

  const pdfBytes: ArrayBuffer = await server
    .get(pdfUrl, {
      responseType: 'arraybuffer'
    })
    .then((res) => res.data as ArrayBuffer)

  addAuthHeader()

  // Load the PDF doc
  const document = await PDFDocument.load(pdfBytes)

  const pages = document.getPages()

  for (let i = 0; i < pagesToRotate.length; i++) {
    const pageRotationData = pagesToRotate[i]
    const pageFromDocument = pages[pageRotationData.index]
    if (pageFromDocument != null) {
      pageFromDocument.setRotation(degrees(pageFromDocument.getRotation().angle + pageRotationData.rotationInDegrees))
    }
  }

  // Serialize the document
  const updatedPdfBytes = await document.save()

  return new Blob([updatedPdfBytes], { type: 'application/pdf' })
}

export default {
  removePagesFromPDF,
  rotatePages
}
