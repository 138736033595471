import api from '../lib/api'
import {
  UPDATING_QUESTIONNAIRE_V2,
  UPDATING_QUESTIONNAIRE_SUCCESS_V2,
  SET_GLOBAL_ERROR,
  LOADING_QUESTIONNAIRE_V2,
  LOADING_QUESTIONNAIRE_V2_SUCCESS,
  LOADING_QUESTIONNAIRE_OUTLINE_V2,
  LOADING_QUESTIONNAIRE_OUTLINE_V2_SUCCESS
} from 'lib/constants'
import { getCurrentKaseId } from 'reducers/selectors'
import { getCurrentQuestionnaireIdV2 } from 'reducers/questionnaireV2/selectors'
import { MarkPageViewed, SaveQuestionForQuestionnaireParams } from './questionnaire_actions'

export const loadQuestionnaireV2 = (questionnaireSlug: string, pagePath?: string) => (dispatch, getState) => {
  let state = getState()
  const kaseId = getCurrentKaseId(state)

  dispatch({ type: LOADING_QUESTIONNAIRE_V2, payload: true })
  return api.questionnaireV2
    .index({ kaseId, questionnaireSlug, page: pagePath })
    .then((data) => {
      dispatch({
        type: LOADING_QUESTIONNAIRE_V2_SUCCESS,
        payload: data?.data ?? {}
      })
    })
    .finally(() => {
      dispatch({ type: LOADING_QUESTIONNAIRE_V2, payload: false })
    })
    .catch(() => {
      dispatch({
        type: SET_GLOBAL_ERROR,
        payload: 'Sorry, we’re having trouble fetching your questionnaire progress.'
      })
    })
}

export const loadQuestionnaireOutlineV2 = (questionnaireSlug: string) => (dispatch, getState) => {
  let state = getState()
  const kaseId = getCurrentKaseId(state)

  dispatch({ type: LOADING_QUESTIONNAIRE_OUTLINE_V2, payload: true })
  return api.questionnaireV2
    .fetchQuestionnaireOutline({ kaseId, questionnaireSlug })
    .then((data) => {
      dispatch({
        type: LOADING_QUESTIONNAIRE_OUTLINE_V2_SUCCESS,
        payload: data?.data ?? {}
      })
    })
    .finally(() => {
      dispatch({ type: LOADING_QUESTIONNAIRE_OUTLINE_V2, payload: false })
    })
    .catch(() => {
      dispatch({
        type: SET_GLOBAL_ERROR,
        payload: 'Sorry, we’re having trouble fetching your questionnaire progress.'
      })
    })
}

export const saveQuestionForQuestionnaireV2 =
  ({ questionId, answer, answerId }: SaveQuestionForQuestionnaireParams, currentPageId?: string) =>
  (dispatch, getState) => {
    let state = getState()

    const kaseId = getCurrentKaseId(state)
    const questionnaireId = getCurrentQuestionnaireIdV2(state)

    dispatch({ type: UPDATING_QUESTIONNAIRE_V2, payload: true })
    return api.questionnaireV2
      .postQuestionnaireAnswerV2({
        kaseId,
        questionnaireId,
        questionId,
        answer,
        answerId,
        currentPageId
      })
      .then((data) => {
        dispatch({
          type: UPDATING_QUESTIONNAIRE_SUCCESS_V2,
          payload: data?.data ?? {}
        })
      })
      .finally(() => {
        dispatch({ type: UPDATING_QUESTIONNAIRE_V2, payload: false })
      })
      .catch(() => {
        dispatch({
          type: SET_GLOBAL_ERROR,
          payload: 'Sorry, we’re having trouble updating your questionnaire progress.'
        })
      })
  }

export const markPageViewedV2 =
  ({ kaseId, pageId }: MarkPageViewed) =>
  (dispatch) => {
    dispatch({ type: LOADING_QUESTIONNAIRE_V2, payload: true })
    return api.questionnaireV2
      .markPageViewed({ kaseId, pageId })
      .then((data) => {
        dispatch({
          type: LOADING_QUESTIONNAIRE_V2_SUCCESS,
          payload: data?.data ?? {}
        })
      })
      .finally(() => {
        dispatch({ type: LOADING_QUESTIONNAIRE_V2, payload: false })
      })
  }
