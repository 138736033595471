import { ApplicationState } from 'reducers'

export const getTrelloCardUrl = (state: ApplicationState): string => {
  return state.kase.trello_card_url || null
}

export const getTrelloCardId = (state: ApplicationState): string => {
  return state.kase.trello_card_id || null
}

export const getCurrentKaseId = (application) => application.kase && application.kase.id
