import React, { Component, ReactNode } from 'react'
import cx from 'classnames'

import ControlledModalWrapper, { CloseReason } from './controlled_modal_wrapper'

interface Props {
  body: ReactNode
  className?: string
  footer?: ReactNode
  isOpen?: boolean
  modalWidth?: string | number
  onRequestClose: (reason: CloseReason) => void
}

export default class ModalNoHeader extends Component<Props> {
  static defaultProps = {
    modalWidth: '30rem'
  }

  render() {
    const { body, className, footer, modalWidth, onRequestClose, isOpen } = this.props

    const contentClasses = cx('c-modal__content--headerless', {
      [className]: !!className
    })

    return (
      <ControlledModalWrapper onRequestClose={onRequestClose} isOpen={isOpen} style={{ width: modalWidth }}>
        <div className={contentClasses}>
          <div className="c-modal__body--headerless">
            <div className="c-modal__body-content--headerless">{body}</div>
          </div>
          {footer && <div className="c-modal__footer--headerless">{footer}</div>}
        </div>
      </ControlledModalWrapper>
    )
  }
}
