import server from './server'
import { AxiosPromise, AxiosResponse } from 'axios'
import { WorkflowCategory } from 'lib/constants'
import forceRefresh from 'lib/force_refresh'

type ISO8601Date = string

interface CustomerName {
  first_name: string
  last_name: string
}

interface KaseDocumentReview {
  empty_document_requests: number
  kase_id: number
  needs_customer_action_doc_requests: number
  last_uploaded_at: ISO8601Date
  names: CustomerName[]
  pending_document_requests: number
  pending_documents: number
  rejected_documents: number
}

interface KaseDocumentReviewsIndexResponse {
  kase_document_reviews: KaseDocumentReview[]
}

interface KaseFilter {
  workflowCategory?: WorkflowCategory
  stewardId?: number
}

interface FinishDocumentRequestData {
  summary: string
  sendEmailNotification: boolean
  qaReady: boolean
  sendQuestionnaireReminder: boolean
}

export interface FinishDocumentRequest {
  kaseId: number
  data: FinishDocumentRequestData
}

interface AssignReviewerRequest {
  kaseId: number
  reviewerId: number
  lockVersion: number
}

interface UnassignReviewerRequest {
  kaseId: number
}

async function index({ workflowStatus, stewardId }: KaseFilter): Promise<KaseDocumentReview[]> {
  const baseUrl = '/api/admin/kase_document_reviews'

  let queryParams = []
  if (workflowStatus) {
    queryParams.push(`workflow_status=${workflowStatus}`)
  }
  if (stewardId) {
    queryParams.push(`steward_id=${stewardId}`)
  }

  const query = queryParams.length === 0 ? '' : `?${queryParams.join('&')}`

  const url = baseUrl + query

  return server
    .get(url)
    .then((response: AxiosResponse<KaseDocumentReviewsIndexResponse>) => response.data.kase_document_reviews)
}

async function finish(request: FinishDocumentRequest): AxiosPromise<any> {
  const { kaseId, data } = request

  return server
    .post(`/api/admin/kases/${kaseId}/document-review/finish`, data)
    .then((response) => response.data.summary)
    .catch((error) =>
      // eslint-disable-next-line no-console
      console.error('Error trying to finish document review', error)
    )
}

async function unassign(request: UnassignReviewerRequest): AxiosPromise<any> {
  const { kaseId } = request

  return server
    .post(`/api/admin/kases/${kaseId}/document-review/unassign`)
    .then((response) => {
      return response.data.kase_document_review
    })
    .catch((error) =>
      // eslint-disable-next-line no-console
      console.error('Error trying to unassign document reviewer', error)
    )
}

async function assign(request: AssignReviewerRequest): AxiosPromise<any> {
  const { kaseId, reviewerId, lockVersion } = request

  const docReviewData = {
    data: {
      reviewer_id: reviewerId,
      lock_version: lockVersion
    }
  }

  return server
    .post(`/api/admin/kases/${kaseId}/document-review/assign`, docReviewData)
    .then((response) => {
      return response.data.kase_document_review
    })
    .catch((error) => {
      let httpStatus = error.response.status
      if (httpStatus === 409) {
        setTimeout(() => {
          alert('This customer is already assigned to another DRA. Please pick a different customer')
          setTimeout(forceRefresh, 250)
        }, 500)
      } else {
        // eslint-disable-next-line no-console
        console.error('Error trying to assign document reviewer', error)
      }
    })
}

export default {
  finish,
  index,
  unassign,
  assign
}
