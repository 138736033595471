import React from 'react'
import { connect } from 'react-redux'
import { getCurrentContentSource } from 'reducers/selectors'

import Interstitial from 'components/panels/interstitial'

interface Props {
  contentSource?: 'timeline' | 'income'
}

class SetupStart extends React.Component<Props> {
  headline() {
    switch (this.props.contentSource) {
      case 'timeline':
        return "Wait time is based on the couple's status and where they live."
      case 'income':
        return "Cost is based on the couple's status and where they live."
      default:
        return "Let's confirm that you are eligible to apply for a marriage green card."
    }
  }

  subtitle() {
    switch (this.props.contentSource) {
      case 'timeline':
      case 'income':
        return 'Answer a few questions to get your result!'
      default:
        return 'Answer a few questions to get started!'
    }
  }

  render() {
    return <Interstitial headline={this.headline()} subtitle={this.subtitle()} image="setup-start" />
  }
}

function mapStateToProps(state) {
  return {
    contentSource: getCurrentContentSource(state)
  }
}

export default connect(mapStateToProps)(SetupStart)
