import _merge from 'lodash/merge'

import { SET_ASSETS_EXPIRED, SET_ASSETS_VERSION } from 'lib/constants'

const initialState = { expired: false }

export default function assetsReducer(state, action) {
  state = state || initialState

  switch (action.type) {
    case SET_ASSETS_VERSION: {
      return _merge({}, state, {
        version: action.version
      })
    }

    case SET_ASSETS_EXPIRED: {
      return _merge({}, state, {
        expired: true
      })
    }

    default: {
      return state
    }
  }
}
