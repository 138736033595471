import server from './server'
import { AxiosPromise } from 'axios'

interface ShowParams {
  kaseId: number
  formName: string
}

function index({ kaseId, formName }: ShowParams): AxiosPromise {
  return server.get(`/admin/kases/${kaseId}/kase_form_edits/?form_name=${formName}`).then((response) => response.data)
}

interface CreateParams {
  kaseId: number
  formName: string
  fieldName: string
  value: string
}

function create({ kaseId, formName, fieldName, value }: CreateParams): AxiosPromise {
  const url = `/admin/kases/${kaseId}/kase_form_edits/?form_name=${formName}`

  const data = {
    form_edit: {
      field_name: fieldName,
      value
    }
  }

  return server.post(url, data).then((response) => response.data)
}

export default { index, create }
