// Refer to this doc for more information about setting feature flags
// https://boundless-immigration.slite.com/api/s/note/7WRvAhcvf7oa324csZKRCP/Feature-flags

import _includes from 'lodash/includes'
import _pull from 'lodash/pull'
import _uniq from 'lodash/uniq'
import _isObject from 'lodash/isObject'
import Cookies from 'js-cookie'

import { deployStage, isDevelopmentEnv, isProductionEnv, isStagingStage } from './settings'
import { cookieDomain } from './cookies'
import server from './api/server'

const FEATURE_FLAGS_COOKIE_NAME_PREFIX = '_boundless_feature_flags'
const USER_COMPLETION_FEATURE_FLAG = 'user_completion'
const NEW_ADDRESS_SECTION_FEATURE_FLAG = 'new_address_section'
const NEW_ISSUE_SCREEN_FEATURE_FLAG = 'new_issues_screen'
const JOINT_SPONSOR_SECTION_FEATURE_FLAG = 'joint_sponsor_section'
const PUBLIC_CHARGE_REMOVED = 'publicChargeRemoved'
const LEGACY_BOUNDLESS_MBGC = 'legacyBoundlessMBGC'

/**
 * When adding or removing feature flags, please add them to the array below!
 */
export const FEATURES_OLD = [
  {
    name: 'Joint Sponsor Section',
    flag: JOINT_SPONSOR_SECTION_FEATURE_FLAG
  },
  {
    name: 'User Completion',
    flag: USER_COMPLETION_FEATURE_FLAG
  },
  {
    name: 'New Address Section',
    flag: NEW_ADDRESS_SECTION_FEATURE_FLAG
  },
  {
    name: 'New Issues Screen',
    flag: NEW_ISSUE_SCREEN_FEATURE_FLAG
  }
]

// The server sets a permanent cookie 20 years in the future
// this mirrors it
const FEATURE_FLAGS_EXPIRY_DAYS = 365 * 20

export function enableDebugLogging() {
  const disabledExplicitly = window._boundlessDisableLogs

  return !disabledExplicitly && isDevelopmentEnv()
}

function featureFlagCookieName() {
  return `${FEATURE_FLAGS_COOKIE_NAME_PREFIX}_${deployStage()}`
}

// These are feature flags that are set using ENV variables and are
// set on the window in _data.html.slim
function getServerEnabledFeatures() {
  const serverFeatures = window.serverEnabledFeatures || {}

  return Object.keys(serverFeatures).filter((feature) => {
    return serverFeatures[feature] && serverFeatures[feature] !== 'false'
  })
}

function getCookieFeatureFlags() {
  return (Cookies.get(featureFlagCookieName()) || '').replace(' ', '').split(',')
}

function getEnabledFeatures() {
  return [...getServerEnabledFeatures(), ...getCookieFeatureFlags()]
}

function cookieSettings() {
  return {
    domain: cookieDomain(),
    expires: FEATURE_FLAGS_EXPIRY_DAYS,
    secure: isProductionEnv()
  }
}

function setFeatureFlagCookie(currentFeatures) {
  Cookies.set(featureFlagCookieName(), _uniq(currentFeatures).join(','), cookieSettings())
}

export function isFeatureEnabled(featureName) {
  return _includes(getEnabledFeatures(), featureName)
}

window.isFeatureEnabled = isFeatureEnabled
window.getEnabledFeatures = getEnabledFeatures

function associateFeatureFlagWithUser(params) {
  if (!_isObject(params)) {
    params = {
      name: params
    }
  }

  return server.post('/api/enable_user_feature', params)
}

export function enableFeature(name: string, experimentId?: string): Promise<void> {
  return new Promise((resolve, reject) => {
    const currentFeatures = getEnabledFeatures(),
      store = window.applicationDataStore,
      updateCookieAndResolve = () => {
        currentFeatures.push(name)
        setFeatureFlagCookie(currentFeatures)
        resolve()
      }

    if (_includes(currentFeatures, name)) return resolve()

    currentFeatures.push(name)
    setFeatureFlagCookie(currentFeatures)

    if (store && store.getCurrentUser()) {
      associateFeatureFlagWithUser({
        name,
        experiment_id: experimentId
      })
        .then(updateCookieAndResolve)
        .catch(reject)
    } else {
      updateCookieAndResolve()
    }
  })
}

window.enableFeature = enableFeature

function disassociateFeatureFlagWithUser(data) {
  if (!_isObject(data)) {
    data = {
      name: data
    }
  }

  return server.delete('/api/disable_user_feature', { data })
}

export function disableFeature(name: string): Promise<void> {
  return new Promise((resolve, reject) => {
    const currentFeatures = getEnabledFeatures(),
      store = window.applicationDataStore,
      updateCookieAndResolve = () => {
        setFeatureFlagCookie(_pull(currentFeatures, name))
        resolve()
      }

    if (!_includes(currentFeatures, name)) return resolve()

    if (store && store.getCurrentUser()) {
      disassociateFeatureFlagWithUser({ name }).then(updateCookieAndResolve).catch(reject)
    } else {
      updateCookieAndResolve()
    }
  })
}

window.disableFeature = disableFeature

export function isUserCompletionEnabled() {
  return isFeatureEnabled(USER_COMPLETION_FEATURE_FLAG)
}

window.isUserCompletionEnabled = isUserCompletionEnabled

// This is confusing. Public Charge is currently disabled
// if the feature flag for removing public charge returns
// true
export function isPublicChargeDisabled() {
  return isFeatureEnabled(PUBLIC_CHARGE_REMOVED)
}

window.isPublicChargeDisabled = isPublicChargeDisabled

// Legacy Boundless MBGC feature
export function isLegacyBoundlessMBGCEnabled() {
  return isFeatureEnabled(LEGACY_BOUNDLESS_MBGC)
}

window.isLegacyBoundlessMBGCEnabled = isLegacyBoundlessMBGCEnabled

// SORRY for the one below, ideally we wouldn't need client-side
// flags for this but SplitIt is a real Piece Of....Wonderful Software
// ok bye
//
const ASYNC_SPLITIT_TEST = 'async_splitit_test'
export function testAsyncSplitIt() {
  if (isDevelopmentEnv() || isStagingStage()) {
    enableFeature(ASYNC_SPLITIT_TEST).then(() => {
      location.reload()
    })
  }
}

export function stopAsyncSplitItTest() {
  disableFeature(ASYNC_SPLITIT_TEST).then(() => {
    location.reload()
  })
}

export function isAsyncSplitItTestRunning() {
  return isFeatureEnabled(ASYNC_SPLITIT_TEST)
}

window.testAsyncSplitIt = testAsyncSplitIt
window.stopAsyncSplitItTest = stopAsyncSplitItTest
window.isAsyncSplitItTestRunning = isAsyncSplitItTestRunning
