import React, { FunctionComponent } from 'react'

import { AutoSubmitModalType } from 'lib/constants'

import IconModal from 'components/modals/icon_modal'
import Paragraph from 'components/type/paragraph'
import Button from 'components/button'

interface ExplicitProps {
  hideAutoSubmissionModal: () => void
  modalType?: AutoSubmitModalType
  showModal: boolean
}

export const firstReviewContent = {
  header: 'We’ll start checking your documents soon',
  body:
    'We’re experiencing high volume, so it may take longer than normal to check your documents. We appreciate your patience. In the meantime, please keep uploading documents.'
}

export const standardReviewContent = {
  header: 'Thanks! Keep uploading your documents soon',
  body:
    'We’re experiencing high volume, so it may take longer than normal to check your documents. We appreciate your patience. If you still have documents to upload, keep doing so in the meantime.'
}

export const AutoSubmissionModal: FunctionComponent<ExplicitProps> = ({
  hideAutoSubmissionModal,
  modalType,
  showModal
}) => {
  const isFirstReviewModal = modalType === AutoSubmitModalType.FirstReview

  const headerContent = isFirstReviewModal ? firstReviewContent.header : standardReviewContent.header
  const bodyContent = isFirstReviewModal ? firstReviewContent.body : standardReviewContent.body

  return (
    <IconModal
      closeModal={hideAutoSubmissionModal}
      headerContent={headerContent}
      showModal={showModal}
      variant="success"
    >
      <Paragraph spacing="lg">{bodyContent}</Paragraph>
      <Button onClick={hideAutoSubmissionModal} label="Continue Uploading" color="primary" block />
    </IconModal>
  )
}

export default AutoSubmissionModal as FunctionComponent<ExplicitProps>
