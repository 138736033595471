import { ChapterOrderFunctionCollection, ChapterOrderForSectionArgs } from './index'

import { ChapterType } from 'components/forms/panel'
import MBGCModelData from 'reducers/model/decorators/mbgc_decorator'

// reorder chapters and put beneficiary chapters first
function orderChaptersWithBeneFirst(originalChapters: ChapterType[]) {
  const sponsorChapters = originalChapters.filter((chapter) => chapter.name.includes('sponsor'))
  const beneficiaryChapters = originalChapters.filter((chapter) => chapter.name.includes('beneficiary'))

  return [...beneficiaryChapters, ...sponsorChapters]
}

const petitionOrderFunction = ({ modelData, originalChapters }: ChapterOrderForSectionArgs): ChapterType[] => {
  // if we get the wrong decorator, don't bother reordering
  if (!(modelData instanceof MBGCModelData)) {
    return originalChapters
  }

  if (modelData.sponsorRole()) {
    return originalChapters
  } else {
    var chapters = orderChaptersWithBeneFirst(originalChapters)
    const petitionOutcomesChapter = originalChapters.find((chapter) => chapter.name === 'petition_outcomes')
    chapters.push(petitionOutcomesChapter)

    return chapters
  }
}

const remainingInfoOrderFunction = ({ modelData, originalChapters }: ChapterOrderForSectionArgs): ChapterType[] => {
  // if we get the wrong decorator, don't bother reordering
  if (!(modelData instanceof MBGCModelData)) {
    return originalChapters
  }

  if (modelData.sponsorRole()) {
    return originalChapters
  } else {
    return orderChaptersWithBeneFirst(originalChapters)
  }
}

const mbgcChapterOrderFunctions: ChapterOrderFunctionCollection = {
  petition: petitionOrderFunction,
  remaining_information: remainingInfoOrderFunction
}

export default mbgcChapterOrderFunctions
