import server from 'lib/api/v1/server'
import { AxiosPromise } from 'axios'

function fetchFeatures(userId: number): AxiosPromise {
  return server
    .get(`/api/v1/features?user_id=${userId}`)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error))
}

export default {
  fetchFeatures
}
