import React, { FunctionComponent, useEffect, useState } from 'react'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { Route } from 'react-router-dom'
import _includes from 'lodash/includes'
import classNames from 'classnames'
import { DateTime } from 'luxon'

import QuestionnaireOutline, { OutlineTypes } from './outline/desktop'
import QuestionnairePanel from './panel'
import MobileQuestionnaireOutline from './outline/mobile'
import IneligiblePage from './panel/ineligible'
import ToolMenuV2 from 'components/screens/questionnaire/right_sidebar/tool_menu_v2'
import BreakpointWrapper from 'components/breakpoint_wrapper'
import GlobalError from 'components/error/global'
import LoadingSkeleton from 'components/screens/issues_v2/loading_skeleton'

import {
  loadQuestionnaire,
  markPageViewed as markPageViewedAction,
  saveQuestionForQuestionnaire
} from 'actions/questionnaire_actions'
import { sendTaskEvent } from 'actions/tasks_actions'
import { trackUserEvent, trackClientSideUserEvent } from 'actions/telemetry_actions_v2'
import { toggleContextualHelp } from 'actions/help_actions'

import {
  getCurrentKaseId,
  getCurrentKaseState,
  getCurrentKaseKind,
  isMobileBrowser,
  isSidebarHelpOpen,
  isCurrentKasePaid
} from 'reducers/selectors'
import { getIsFeatureEnabled } from 'reducers/features/selectors'
import { getAllQuestionnairePages, getCurrentQuestionnaire } from 'reducers/questionnaire/selectors'

import { Answer, Questionnaire, QuestionnairePage, SaveQuestionFn } from './lib/types'
import { smoothScrollToTopOfElement } from 'lib/ui_helpers'
import { TELEMETRY_EVENTS } from 'lib/constants'
import getTelemetryService from 'lib/telemetry'
import { FEATURES } from 'lib/features'

interface MappedProps {
  allPages: QuestionnairePage[]
  currentKaseId: number
  currentKaseKind?: string
  isChatDiscoveryTestEnabled: boolean
  isContextualHelpOpen: boolean
  isLoading: boolean
  isUpdating: boolean
  isPaidUser: boolean
  kaseState: string
  questionnaireData: Questionnaire
  showContextualHelpModal: boolean
}

interface ActionProps {
  loadQuestionnaire: typeof loadQuestionnaire
  markPageViewedAction: typeof markPageViewedAction
  saveQuestionForQuestionnaire: typeof saveQuestionForQuestionnaire
  sendTaskEvent: typeof sendTaskEvent
  toggleContextualHelp: typeof toggleContextualHelp
  trackUserEvent: typeof trackUserEvent
  trackClientSideUserEvent: typeof trackClientSideUserEvent
}

interface ExplicitProps {
  questionnaireSlug: string
  pathname: string
  historyPush: (path: string) => void
  historyReplace: (path: string) => void
}

const QuestionnaireContainerV1: FunctionComponent<MappedProps & ActionProps & ExplicitProps> = ({
  allPages,
  currentKaseId,
  currentKaseKind,
  pathname,
  historyPush,
  historyReplace,
  isChatDiscoveryTestEnabled,
  isContextualHelpOpen,
  isLoading,
  isUpdating,
  isPaidUser,
  kaseState,
  loadQuestionnaire,
  markPageViewedAction,
  questionnaireData,
  questionnaireSlug,
  saveQuestionForQuestionnaire,
  sendTaskEvent,
  showContextualHelpModal,
  toggleContextualHelp,
  trackUserEvent,
  trackClientSideUserEvent
}) => {
  const [previousPagePath, setPreviousPagePath] = useState('')
  const [currentPagePath, setCurrentPagePath] = useState('')
  const [nextPagePath, setNextPagePath] = useState('')
  const [currentPageData, setCurrentPageData] = useState(null as QuestionnairePage)
  const [showRefundModal, setShowRefundModal] = useState(false)
  const [didMidwayEventFire, setDidMidwayEventFire] = useState(false)
  const [editingSummaryQuestionSetIndex, setEditingSummaryQuestionSetIndex] = useState(-1)

  // Used below to parse the beginning and ending of the url path
  const baseQuestionnaireUrlSectionLength = 5
  const rootUrl = `/applications/${currentKaseId}`
  const issuesURL = `${rootUrl}/questions`

  const isEditingIssue = kaseState === 'customer_form_check'

  const getCurrentPageFromUrl = () => {
    let currentPagePath: string
    if (pathname) {
      currentPagePath = pathname.split('/').slice(baseQuestionnaireUrlSectionLength).join('/')
    }
    if (!currentPagePath) {
      // If we can't determine a current page, go to the first question in the first section
      currentPagePath = allPages?.[0]?.attributes?.path
    }

    return currentPagePath
  }

  const getCurrentPageData = (currentPagePath: string) => {
    if (currentPagePath) {
      return allPages.find((page) => page.attributes.path === currentPagePath)
    }
  }

  // Sets the baseQuestionnaireUrl to allow the component to dynamically generate links and routes for the questionnaire
  // If anyone has a cleaner method to get this, please update this! Seems like the router should provide it already.
  // Ex: ('/applications/9/questionnaire/admissiblity') ~ anything after 'admissiblity' is stripped off
  const baseQuestionnaireUrl: string = pathname.split('/').slice(0, baseQuestionnaireUrlSectionLength).join('/')

  // Handles when a different section name is clicked in the outline view
  const handleOutlineSectionClick = (sectionName) => {
    const firstPageInSection = questionnaireData?.attributes?.questionnaire_sections
      ?.find((section) => section.name === sectionName)
      ?.pages?.filter((page) => ['Page', 'HistoryPage', 'SummaryPage'].includes(page?.attributes?.type))[0]
      ?.attributes?.path

    historyPush(`${baseQuestionnaireUrl}/${firstPageInSection}`)
  }

  // get the previous page’s path from the allPages array
  const getPreviousPagePath = (currentPageIndex) => {
    const previousPage = allPages[currentPageIndex - 1]

    if (!previousPage?.attributes?.path) {
      return null
    }

    return `${baseQuestionnaireUrl}/${previousPage.attributes?.path}`
  }

  // get the next page’s path from the allPages array
  // UNLESS the next page has type = ExitPage AND the customer
  // is not done answering all questions in the questionnaire
  const getNextPagePath = (currentPageIndex) => {
    const nextPage = allPages[currentPageIndex + 1]
    const incompleteQuestionnaire = questionnaireData.attributes.percent_complete < 100

    if (!nextPage && incompleteQuestionnaire) {
      return null
    }

    if (!nextPage) {
      return `${rootUrl}/timeline`
    }

    if (nextPage.attributes.type === 'ExitPage' && incompleteQuestionnaire) {
      return null
    }

    return `${baseQuestionnaireUrl}/${nextPage.attributes?.path}`
  }

  // Navigation click handling
  // ------------------------------
  // When the continue or back buttons are clicked in the panel or the mobile
  // outline sections and the questionnaire is being updated simultaneously
  // we want to wait until the new questionnaire data is returned to ensure
  // that the next/previous page paths are accurate and also to give a better
  // ux experience where the customer sees the value being saved before the
  // next page appears.
  const [waitToContinueUntilUpdated, setWaitToContinueUntilUpdated] = useState(false)
  const [waitToGoBackUntilUpdated, setWaitToGoBackUntilUpdated] = useState(false)
  const [pageDataBeforeUpdate, setPageDataBeforeUpdate] = useState(currentPageData)

  const handleContinueClick = () => {
    const isExitPage = currentPageData?.attributes?.type === 'ExitPage'
    const isPrePayExitPage = isExitPage && currentPageData?.attributes.name === 'ExitPagePrePayment'
    const currentPageIndex = allPages?.findIndex((page) => page?.id === currentPageData?.id)
    const nextPage = allPages[currentPageIndex + 1]

    if (isEditingIssue) {
      window.location.href = issuesURL
      return
    }

    if (isUpdating) {
      setPageDataBeforeUpdate(currentPageData)
      setWaitToContinueUntilUpdated(true)
      return
    }

    if (isPrePayExitPage) {
      window.location.href = `/applications/${currentKaseId}/petition/satisfaction-guarantee`
      return
    }

    /** in case there are some instances where there is no next page
     * we want to route the user back to the dashboard as a fail safe
     */
    if (isExitPage || nextPage?.attributes?.type === 'EligibilityReport' || !nextPage) {
      window.location.href = `/applications/${currentKaseId}/timeline`
      return
    }

    if (currentPageData?.attributes?.type === 'EligibilityReport') {
      window.location.href = `/applications/${currentKaseId}/questionnaire/petition_1`
      return
    }

    if (currentPageData?.attributes?.type === 'IneligiblePage') {
      setShowRefundModal(true)
      return
    }

    historyPush(nextPagePath)
  }

  const handlePreviousClick = () => {
    if (isUpdating) {
      setWaitToGoBackUntilUpdated(true)
    } else {
      historyPush(previousPagePath)
    }
  }

  const saveGroupOfQuestionsForQuestionnaire = (answers: Answer[]) => {
    answers.forEach((answer) => saveQuestionForQuestionnaire(answer))
  }

  const handleSaveQuestion: SaveQuestionFn = (question, answer) => {
    if (answer.length > 1) {
      saveGroupOfQuestionsForQuestionnaire(answer)
    } else {
      saveQuestionForQuestionnaire(answer[0])

      if (question.attributes.key === 'mbgc_setup_account_email') {
        trackClientSideUserEvent(TELEMETRY_EVENTS.BECAME_LEAD)
      }
    }
  }

  const getNumberOfQuestionsOnPage = (page: QuestionnairePage): number => {
    let numberOfQuestions = 0
    page.attributes.page_elements.forEach((pageElement) => {
      numberOfQuestions += pageElement?.attributes?.questions?.length || 0
    })
    return numberOfQuestions
  }

  // Checks to see if the current questionnaire page has
  // new elements since the questionnaire last updated
  const currentPageHasNewElements = (): boolean => {
    const hasNewPageElements =
      currentPageData.attributes.page_elements.length > pageDataBeforeUpdate.attributes.page_elements.length
    const hasNewQuestions =
      getNumberOfQuestionsOnPage(currentPageData) > getNumberOfQuestionsOnPage(pageDataBeforeUpdate)
    return hasNewPageElements || hasNewQuestions
  }

  /**
   * Finds the first instance of a question without a current
   * input value and puts it in focus
   */
  const focusFirstUnansweredQuestion = (pageData: QuestionnairePage): void => {
    pageData?.attributes?.page_elements?.every((pageElement) => {
      return pageElement.attributes?.questions?.every((question) => {
        if (!question.attributes.input_value) {
          document.getElementById(question.id)?.focus()
          return false
        }
        return true
      })
    })
  }

  const handleRefundConfirm = () => {
    const dashboardUrl = `${rootUrl.split('/').slice(0, 3).join('/')}/timeline`

    sendTaskEvent('refund_requested', () => (window.location.href = dashboardUrl))
    setShowRefundModal(false)
  }

  const markPageViewed = () => {
    const pageId = currentPageData?.id

    // If page id has history in it, assume that it's part of address/employment history
    // These typse of pages will not have real ids
    if (!pageId.includes('history')) {
      markPageViewedAction({
        kaseId: currentKaseId,
        pageId
      })
    }
  }

  useEffect(() => {
    loadQuestionnaire(questionnaireSlug)

    // For use in HubSpot: we want to track the most recent timezone
    // of the customer for our sales team
    trackUserEvent(TELEMETRY_EVENTS.GET_CUSTOMER_TIME_ZONE, {
      timeZone: DateTime.local().zoneName
    })
  }, [])

  /**
   * If the continue button was clicked while the questionnaire
   * is updating, this will trigger after the update is completed.
   * If the currentPage had additional elements added during the update
   * we stay on that page and focus the first unanswered question on the
   * page. If nothing was added to the current page, we navigate to the
   * next page.
   */
  useEffect(() => {
    if (!isUpdating && waitToContinueUntilUpdated) {
      setWaitToContinueUntilUpdated(false)
      if (currentPageHasNewElements()) {
        focusFirstUnansweredQuestion(currentPageData)
      } else {
        historyPush(nextPagePath)
      }
    }
    if (!isUpdating && waitToGoBackUntilUpdated) {
      setWaitToGoBackUntilUpdated(false)
      historyPush(previousPagePath)
    }
  }, [isUpdating])

  /**
   * TODO: This should be updated so we send a useful "Eligibility Complete"
   *   event once we deprecate "Email Gate" for MBGC. The new event will be
   *   helpful with CON-283. This work can be pulled into a sprint once CON-560 is done
   */
  useEffect(() => {
    const progress = questionnaireData?.attributes?.percent_complete
    /** This sends a midway PP1 event to facebook for MBGC kases ONLY
     * Checks if progress is over 50% and that we haven't already fired the event
     */

    if (
      questionnaireSlug === 'petition_1' &&
      currentKaseKind === 'NewMarriageBasedGreenCard' &&
      !didMidwayEventFire &&
      progress >= 50 &&
      progress <= 60
    ) {
      setDidMidwayEventFire(true)
      const telemetryService = getTelemetryService()
      telemetryService.track(TELEMETRY_EVENTS.MIDWAY_PP1)
    }
    if (progress === 100 && questionnaireSlug === 'mbgc_setup' && !!currentPageData?.attributes) {
      trackUserEvent(TELEMETRY_EVENTS.VIEWED_QUESTIONNAIRE_PAGE, {
        pageName: currentPageData.attributes.name,
        questionnaireName: questionnaireSlug
      })
    }
  }, [
    questionnaireSlug,
    currentPageData?.attributes?.name,
    questionnaireData?.attributes?.percent_complete,
    didMidwayEventFire
  ])

  /**
   * When the questionnaireData or the current page location is updated, update
   * the data needed for displaying the correct page data, outline data and for
   * setting the paths for navigation.
   */
  useEffect(() => {
    if (questionnaireData) {
      const newPage = getCurrentPageFromUrl()
      const updatedCurrentPageData = getCurrentPageData(newPage)
      const currentPageIndex = allPages?.findIndex((page) => page?.id === updatedCurrentPageData?.id)

      setCurrentPageData(updatedCurrentPageData)
      setCurrentPagePath(newPage)
      setPreviousPagePath(getPreviousPagePath(currentPageIndex))
      setNextPagePath(getNextPagePath(currentPageIndex))
    }
  }, [pathname, questionnaireData])

  /**
   * Scrolls the questionnaire to the top of the page whenever a
   * new page is rendered
   */
  useEffect(() => {
    smoothScrollToTopOfElement(document.getElementById('react-root'))
  }, [pathname])

  /**
   * New telemetry events
   */
  useEffect(() => {
    if (currentPageData) {
      trackUserEvent(TELEMETRY_EVENTS.VIEWED_QUESTIONNAIRE_PAGE, {
        pageName: currentPageData.attributes.name,
        questionnaireName: questionnaireSlug
      })
    }
  }, [currentPageData?.id])

  if (isLoading || !questionnaireData) {
    return <LoadingSkeleton />
  }

  const redirectToBeginning = (): void => {
    historyReplace(allPages?.[0]?.attributes?.path)
  }

  const isQuestionPage = ['Page', 'HistoryPage', 'SummaryPage'].includes(currentPageData?.attributes?.type)
  const isIneligiblePage = currentPageData?.attributes?.type === 'IneligiblePage'
  const isPrepaymentPage = currentPageData?.attributes?.type === 'PrepaymentPage'
  const isPaymentPage = currentPageData?.attributes?.type === 'PaymentPage'
  const isEstimatedTimelinePage = currentPageData?.attributes?.type === 'EstimatedTimelinePage'
  const isLandingPage = currentPageData?.attributes?.type === 'LandingPage'
  const contextualHelp =
    currentPageData?.attributes?.page_elements?.filter((element) =>
      ['question_set', 'summary_question_set'].includes(element.type)
    )?.[0]?.attributes?.contextual_help || currentPageData?.attributes?.payment_details?.contextual_help // payment_details only exist on type of PaymentPage
  const isLoginPage = currentPageData?.attributes?.type === 'LoginPage'
  const isInvalidPage = !currentPageData

  const showPanelWithSidebars =
    (isQuestionPage ||
      isIneligiblePage ||
      isPrepaymentPage ||
      isPaymentPage ||
      isEstimatedTimelinePage ||
      isInvalidPage) &&
    !isLoginPage

  let outlineType = OutlineTypes.Complete
  if (_includes(['mbgc_setup', 'k1_eligibility', 'k1_payment', 'mbgc_payment'], questionnaireSlug)) {
    outlineType = OutlineTypes.Slim
  } else if (_includes(['k1_to_aos_conversion'], questionnaireSlug)) {
    outlineType = OutlineTypes.Disabled
  }

  return (
    <>
      {/* Mobile screens */}
      <BreakpointWrapper mobile>
        <div className="o-grid__col-12">
          <div className="o-pane__fill o-grid__row " style={{ height: '100%' }}>
            <GlobalError />
            <div className={classNames('o-grid__col-8 c-sheet', { 'c-sheet--clear': isLandingPage })}>
              {/* @ts-ignore */}
              <Route path={baseQuestionnaireUrl + '/:panelPath'}>
                <QuestionnairePanel
                  contextualHelp={contextualHelp}
                  currentKaseKind={currentKaseKind}
                  currentPagePath={currentPagePath}
                  editingSummaryQuestionSetIndex={editingSummaryQuestionSetIndex}
                  handleContinueClick={handleContinueClick}
                  handlePreviousClick={handlePreviousClick}
                  handleRefundConfirmClick={handleRefundConfirm}
                  isChatDiscoveryTestEnabled={isChatDiscoveryTestEnabled}
                  isContextualHelpOpen={isContextualHelpOpen}
                  isMobile={true}
                  onSaveQuestion={handleSaveQuestion}
                  panelData={currentPageData}
                  sectionName={currentPageData?.sectionName}
                  questionnaireSlug={questionnaireSlug}
                  redirectToBeginning={redirectToBeginning}
                  setEditingSummaryQuestionSetIndex={setEditingSummaryQuestionSetIndex}
                  setShowRefundModal={setShowRefundModal}
                  showContextualHelpModal={showContextualHelpModal}
                  showRefundModal={showRefundModal}
                  slimOutline={outlineType === OutlineTypes.Slim}
                  toggleContextualHelp={toggleContextualHelp}
                />
              </Route>
              <MobileQuestionnaireOutline
                currentPageData={currentPageData}
                handleContinueClick={handleContinueClick}
                handlePreviousClick={handlePreviousClick}
                isEditingIssue={isEditingIssue}
                isPaidUser={isPaidUser}
                isEditingSummaryQuestionSet={editingSummaryQuestionSetIndex > -1}
                isUpdating={isUpdating}
                nextPagePath={nextPagePath}
                outlineType={outlineType}
                previousPagePath={previousPagePath}
                questionnaireData={questionnaireData}
              />
            </div>
          </div>
        </div>
      </BreakpointWrapper>

      {/* Desktop */}
      <BreakpointWrapper desktop>
        {showPanelWithSidebars ? (
          <div className="o-pane__fill o-grid__row">
            <aside className="o-grid__col-2 u-hide@sm-down" aria-label="questionnaire-navigation">
              <QuestionnaireOutline
                baseQuestionnaireUrl={baseQuestionnaireUrl}
                currentPagePath={currentPagePath}
                currentSectionName={currentPageData?.sectionName}
                outlineType={outlineType}
                questionnaireData={questionnaireData}
                setCurrentSectionHandler={handleOutlineSectionClick}
              />
            </aside>

            <main className="o-grid__col-8 c-sheet">
              <GlobalError />
              {/* @ts-ignore */}
              <Route path={baseQuestionnaireUrl + '/:panelPath'}>
                <>
                  {!isIneligiblePage && (
                    <QuestionnairePanel
                      currentKaseKind={currentKaseKind}
                      currentPagePath={currentPagePath}
                      editingSummaryQuestionSetIndex={editingSummaryQuestionSetIndex}
                      handleContinueClick={handleContinueClick}
                      handlePreviousClick={handlePreviousClick}
                      isContextualHelpOpen={isContextualHelpOpen}
                      isEditingIssue={isEditingIssue}
                      isUpdating={isUpdating}
                      markPageViewed={markPageViewed}
                      nextPagePath={nextPagePath}
                      onSaveQuestion={handleSaveQuestion}
                      panelData={currentPageData}
                      sectionName={currentPageData?.sectionName}
                      previousPagePath={previousPagePath}
                      questionnaireSlug={questionnaireSlug}
                      redirectToBeginning={redirectToBeginning}
                      setEditingSummaryQuestionSetIndex={setEditingSummaryQuestionSetIndex}
                      slimOutline={outlineType === OutlineTypes.Slim}
                      toggleContextualHelp={toggleContextualHelp}
                    />
                  )}

                  {isIneligiblePage && (
                    <IneligiblePage
                      body={currentPageData?.attributes?.body}
                      handlePreviousClick={handlePreviousClick}
                      isPaidUser={isPaidUser}
                      title={currentPageData?.attributes?.subtitle}
                    />
                  )}
                </>
              </Route>
            </main>

            <aside className="o-grid__col-2 u-hide@sm-down">
              <ToolMenuV2
                contextualHelpV2={contextualHelp}
                currentPageData={currentPageData}
                questionnaireData={questionnaireData}
              />
            </aside>
          </div>
        ) : (
          <div className="o-pane__fill o-grid__row">
            <div className="o-grid__col-2" />
            <form className="o-grid__col-8 c-sheet c-sheet--clear" autoComplete="off">
              {/* @ts-ignore */}
              <Route path={baseQuestionnaireUrl + '/:panelPath'}>
                <QuestionnairePanel
                  currentKaseKind={currentKaseKind}
                  currentPagePath={currentPagePath}
                  editingSummaryQuestionSetIndex={editingSummaryQuestionSetIndex}
                  handleContinueClick={handleContinueClick}
                  handlePreviousClick={handlePreviousClick}
                  isContextualHelpOpen={isContextualHelpOpen}
                  isEditingIssue={isEditingIssue}
                  isUpdating={isUpdating}
                  markPageViewed={markPageViewed}
                  onSaveQuestion={handleSaveQuestion}
                  nextPagePath={nextPagePath}
                  panelData={currentPageData}
                  sectionName={currentPageData?.sectionName}
                  previousPagePath={previousPagePath}
                  questionnaireSlug={questionnaireSlug}
                  slimOutline={outlineType === OutlineTypes.Slim}
                  redirectToBeginning={redirectToBeginning}
                  setEditingSummaryQuestionSetIndex={setEditingSummaryQuestionSetIndex}
                  toggleContextualHelp={toggleContextualHelp}
                />
              </Route>
            </form>
            <div className="o-grid__col-2" />
          </div>
        )}
      </BreakpointWrapper>
    </>
  )
}

function mapStateToProps(state: any): MappedProps {
  const isMobile = isMobileBrowser(state)
  const allPages = getAllQuestionnairePages(state)

  // For Desktop, we do not want to include the section interstitials
  const desktopPages = allPages.filter((page) => page.attributes.type !== 'SectionPage')

  return {
    currentKaseId: getCurrentKaseId(state),
    isChatDiscoveryTestEnabled: getIsFeatureEnabled(state, FEATURES.DISCOVERABLE_CHAT),
    isContextualHelpOpen: isSidebarHelpOpen(state),
    isLoading: state.questionnaire.isLoading,
    isUpdating: state.questionnaire.isUpdating,
    kaseState: getCurrentKaseState(state),
    currentKaseKind: getCurrentKaseKind(state),
    isPaidUser: isCurrentKasePaid(state),
    questionnaireData: getCurrentQuestionnaire(state),
    allPages: isMobile ? allPages : desktopPages,
    showContextualHelpModal: isMobile && isSidebarHelpOpen(state)
  }
}

function mapDispatchToActions(dispatch: Dispatch): ActionProps {
  return bindActionCreators(
    {
      loadQuestionnaire,
      markPageViewedAction,
      saveQuestionForQuestionnaire,
      sendTaskEvent,
      toggleContextualHelp,
      trackUserEvent,
      trackClientSideUserEvent
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToActions
)(QuestionnaireContainerV1) as FunctionComponent<ExplicitProps>
