import React from 'react'
import { connect } from 'react-redux'

import Modal from '../modal'
import { acceptAttorneyAgreement } from '../../../actions/kase_actions'
import { closeModal } from '../../../actions/modal_actions'
import { trackUserEvent } from 'actions/telemetry_actions_v2'
import { TELEMETRY_EVENTS } from 'lib/constants'
import { sendTaskEvent } from 'actions/tasks_actions'
import { isCurrentKasePaid } from 'reducers/selectors'
import { KaseStates } from 'lib/kase_state'

interface Props {
  acceptAttorneyAgreement: Function
  closeModal: Function
  closeModalCallback?: () => {}
  disableClosingWithoutAction?: boolean
  isActive: boolean
  sendTaskEvent: Function
  trackUserEvent: typeof trackUserEvent
}

interface MappedProps {
  isCurrentKasePaid: boolean
}

interface State {
  agreed: boolean
}

class AttorneyAgreementModal extends React.Component<Props & MappedProps, State> {
  state: State = {
    agreed: false
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isActive && this.props.isActive) {
      this.props.trackUserEvent(TELEMETRY_EVENTS.VIEWED_ATTORNEY_AGREEMENT)
    }
  }

  handleChange = () => {
    this.setState({
      agreed: !this.state.agreed
    })
  }

  acceptAgreement = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()

    this.props.acceptAttorneyAgreement()
    this.props.trackUserEvent(TELEMETRY_EVENTS.ACCEPTED_ATTORNEY_AGREEMENT)
    this.closeModal()
    if (this.props.hasOwnProperty('closeModalCallback')) {
      this.props.closeModalCallback()
    }
  }

  requestRefund = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()

    this.props.sendTaskEvent('refund_requested')
    this.props.trackUserEvent(TELEMETRY_EVENTS.DECLINED_ATTORNEY_AGREEMENT_FOR_REFUND)
    this.closeModal()
  }

  closeModal() {
    this.props.closeModal({ via: 'AttorneyAgreementModal' })
  }

  render() {
    return (
      <Modal
        disableClosingWithoutAction={this.props.disableClosingWithoutAction}
        hideCloseIcon={this.props.disableClosingWithoutAction}
        isActive={this.props.isActive}
        title="Limited Scope Representation Agreement (Attorney Agreement)"
      >
        {() => (
          <div>
            <p className="o-block c-type c-type--body-serif-sm">Last Updated: November 7th, 2023</p>

            <p className="o-block c-type c-type--body-sans-sm">
              If you paid or selected a payment plan on or before 11/06/2023, please{' '}
              <a href="https://www.boundless.com/former-attorney-agreements" target="_blank" rel="noreferrer">
                click here
              </a>{' '}
              to view the Attorney Agreement that applies to you.
            </p>

            <p className="o-block o-block--tight c-type c-type--body-sans-sm c-type--emphasized">
              By clicking a button or checking a box online indicating that you agree to this Attorney Agreement, you
              accept all of the terms and conditions set forth below.
            </p>

            <div className="o-block o-box scrollable-view">
              <p className="o-block o-block--tight c-type c-type--body-sans-sm c-type--emphasized">A. Introduction</p>

              <p className="o-block c-type c-type--body-sans-sm">
                Boundless Immigration Inc. (<strong>“Boundless”</strong>) has made arrangements with independent
                attorneys to provide assistance to Boundless customers who purchase certain Boundless Services. If the
                Service you have purchased from Boundless includes assistance from an independent attorney, this Limited
                Scope Representation Agreement (<strong>“Attorney Agreement”</strong>) governs your relationship with
                the independent attorney (<strong>“Attorney”</strong>) who will assist you in connection with the
                purchased Service.
              </p>

              <p className="o-block c-type c-type--body-sans-sm">
                Your relationship with Boundless is and shall remain governed by the Boundless{' '}
                <a href="https://www.boundless.com/terms/" target="_blank" rel="noreferrer">
                  Terms of Use
                </a>{' '}
                (including any applicable Service-Specific Terms) and the Boundless{' '}
                <a href="https://www.boundless.com/privacy/" target="_blank" rel="noreferrer">
                  Privacy Policy
                </a>
                .
              </p>

              <p className="o-block o-block--tight c-type c-type--body-sans-sm c-type--emphasized">
                B. Scope of Representation
              </p>

              <ol className="o-list o-block c-type c-type--body-serif-sm">
                <li>
                  <strong>Ask My Attorney Service.</strong> If you have purchased the Ask My Attorney service, the
                  Attorney that you have scheduled a call with will provide the following services:
                  <p>&nbsp;</p>
                  <ul className="o-list o-block c-type c-type--body-serif-sm">
                    <li>
                      The Attorney will speak with you during one 30-minute phone call about your immigration status,
                      goals and concerns.
                    </li>
                    <li>
                      The Attorney will explain the options available to you and provide recommendations, including any
                      Boundless Services that may be appropriate for you.
                    </li>
                  </ul>
                </li>

                <li>
                  <strong>
                    K-1 Fiancé Visa Service; Marriage Green Card: Spousal Visa Service; Marriage Green Card: Adjustment
                    of Status Service.{' '}
                  </strong>{' '}
                  If you have purchased the K-1 Fiancé Visa service, Marriage Green Card: Spousal Visa service, or
                  Marriage Green Card: Adjustment of Status service, the Attorney assigned to assist you will provide
                  the following services:
                  <p>&nbsp;</p>
                  <ul className="o-list o-block c-type c-type--body-serif-sm">
                    <li>
                      The Attorney will review the forms you have completed for errors, omissions, and consistency, and
                      review the supporting documents for completeness and consistency with the forms prior to filing.
                    </li>
                    <li>
                      The Attorney will answer your questions about the forms and supporting documents that you have
                      submitted for the Attorney’s review.
                    </li>
                    <li>
                      The Attorney will speak with you during two 30-minute phone calls about your immigration status,
                      goals and concerns.
                    </li>
                  </ul>
                </li>

                <li>
                  The Attorney’s representation of you is limited to the services described above, and no more. The
                  Attorney will not perform any additional legal work for you, including things such as filing documents
                  with the appropriate agency or authorities or representing you in any proceeding before any court or
                  government agency, including United States Citizenship and Immigration Services (USCIS).
                </li>
              </ol>

              <p className="o-block o-block--tight c-type c-type--body-sans-sm c-type--emphasized">C. Independence</p>

              <p className="o-block c-type c-type--body-serif-sm">
                Each Attorney is an independent practitioner. The Attorney will exercise the Attorney’s independent
                legal judgment, without interference, influence, or control by Boundless. The Attorney must act in your
                best interest, provide competent assistance, and comply with the rules of professional conduct governing
                the Attorney’s practice of law.
              </p>

              <p className="o-block o-block--tight c-type c-type--body-sans-sm c-type--emphasized">
                D. Attorney Compensation
              </p>

              <p className="o-block c-type c-type--body-serif-sm">
                A portion of the amount you pay to Boundless when you purchase a Boundless Service that includes
                assistance from an Attorney will be paid to the Attorney (the <strong>“Flat Fee”</strong>). Upon the
                Attorney’s receipt of all or any part of the Flat Fee, the funds paid to the Attorney are the property
                of the Attorney and will not be placed in a trust account.
              </p>

              <p className="o-block c-type c-type--body-serif-sm">
                The fact that you have paid in advance does not affect your right to terminate the attorney-client
                relationship. If your relationship with the Attorney is terminated before the applicable legal services
                described above have been completed, you may or may not have a right to a refund of all or a portion of
                the amount of the Flat Fee.
              </p>

              <p className="o-block c-type c-type--body-serif-sm">
                In addition to the Flat Fee, the Attorney may also be compensated an additional amount by Boundless to
                ensure sufficient attorney time and resources will be available to serve Boundless’ customers. You are
                not responsible for, and will not be charged, any such additional amount paid by Boundless to the
                Attorney.
              </p>

              <p className="o-block o-block--tight c-type c-type--body-sans-sm c-type--emphasized">
                E. Your Consent Regarding Attorney Communications
              </p>

              <p className="o-block c-type c-type--body-serif-sm">
                You expressly grant Boundless permission to receive, store, forward, and review communications between
                yourself and the Attorney, solely for the purpose of facilitating such communications and the Attorney’s
                services under this Attorney Agreement. You understand that Boundless has agreed to keep such
                communications and the information contained therein confidential to at least the same level of privacy
                as required by the Attorney under the laws and rules governing the Attorney’s communications with you.
                To the extent any provision of this Attorney Agreement conflicts with any provision of the{' '}
                <a href="https://www.boundless.com/privacy/" target="_blank" rel="noreferrer">
                  Privacy Policy
                </a>
                , the provision of this Attorney Agreement controls.
              </p>

              <p className="o-block o-block--tight c-type c-type--body-sans-sm c-type--emphasized">
                F. Your Acknowledgments and Agreement
              </p>

              <p className="o-block c-type c-type--body-serif-sm">
                You acknowledge and agree to each of the following:
              </p>

              <ol className="o-list o-block c-type c-type--body-serif-sm">
                <li>You have read and understand this Attorney Agreement.</li>

                <li>
                  The Attorney is relying entirely on the facts, information, and documents you provide. The Attorney
                  will not make any independent investigation of your facts or information, or the authenticity of any
                  documents you provide. It is therefore extremely important that you are completely truthful as to any
                  information you provide.
                </li>

                <li>The Attorney is not promising you a favorable result.</li>

                <li>
                  The legal services described above in the section titled “Scope of Representation” are the only legal
                  services to be provided by the Attorney, and the Attorney is not required to provide assistance or
                  representation in any further or other legal matters.
                </li>

                <li>
                  The Attorney’s representation of you ends when the services described above have been completed or the
                  attorney-client relationship is terminated.
                </li>

                <li>
                  You are responsible for the Flat Fee and agree that the Flat Fee may be taken from the purchase price
                  you paid to Boundless for the Service you purchased and paid by Boundless to the Attorney that assists
                  you. You understand that the Attorney may receive additional compensation from Boundless beyond the
                  Flat Fee, but that you are not responsible for any such additional compensation.
                </li>
              </ol>
            </div>

            <label className="o-block o-block--tight c-type c-type--body-sans-sm c-type--emphasized">
              <input type="checkbox" checked={this.state.agreed} onChange={this.handleChange} />{' '}
              <span>I agree with the above document</span>
            </label>

            <p className="o-block o-block c-type c-type--body-serif-sm">
              This agreement can be downloaded and printed{' '}
              <a href="/attorney-agreement.pdf" target="_blank" rel="noopener noreferrer">
                here
              </a>
              .
            </p>
            <div className="o-grid__row">
              <button
                id="continue-button-attorney-agreement-modal"
                className="o-grid__col c-btn c-btn--primary"
                disabled={!this.state.agreed}
                onClick={this.acceptAgreement}
              >
                Continue{' '}
              </button>
              {this.props.isCurrentKasePaid && (
                <button
                  id="cancel-button-attorney-agreement-modal"
                  className="o-grid__col c-btn c-btn--secondary"
                  onClick={this.requestRefund}
                >
                  Cancel &amp; Get Refund
                </button>
              )}
            </div>
          </div>
        )}
      </Modal>
    )
  }
}

function mapStateToProps(state: KaseStates): MappedProps {
  return {
    isCurrentKasePaid: isCurrentKasePaid(state)
  }
}

function mapDispatchToActions(dispatch) {
  return {
    acceptAttorneyAgreement: () => dispatch(acceptAttorneyAgreement()),
    closeModal: (...args) => dispatch(closeModal(...args)),
    sendTaskEvent: (event: string) => dispatch(sendTaskEvent(event)),
    trackUserEvent: (event: string) => dispatch(trackUserEvent(event))
  }
}

export default connect(mapStateToProps, mapDispatchToActions)(AttorneyAgreementModal)
