import React, { FunctionComponent, useState } from 'react'
import { connect } from 'react-redux'

import api from 'lib/api'
import { wordDocsAndPdfs } from 'lib/accepted_files_types'
import UploadToPathButton from 'components/upload_to_path_button'
import Button from 'components/button'
import { isCustomerFeedbackUploaded, getMasterPdfId, getCustomerReviewFeedbackFileName } from 'reducers/selectors'
import {
  addFeedbackToMasterPDF,
  removeFeedbackFromMasterPDF,
  submitFeedbackForMasterPDF
} from 'actions/master_pdf_actions'

interface Props {
  addFeedbackToMasterPDF: (masterPdfId: number, signResult: any, rawFile: any) => void
  hasCustomerFeedbackUploaded?: boolean
  masterPdfId: number
  reviewFileName?: string
  removeFeedbackFromMasterPDF: (masterPdfId: number) => void
  submitFeedbackForMasterPDF: (masterPdfId: number) => Promise<any>
}

const CustomerFeedbackUploader: FunctionComponent<Props> = (props) => {
  const [isSubmittingFeedback, setIsSubmittingFeedback] = useState(false)

  const { hasCustomerFeedbackUploaded, masterPdfId, reviewFileName } = props

  const deleteCustomerReviewFeedback = () => {
    props.removeFeedbackFromMasterPDF(props.masterPdfId)
  }

  const submitFeedback = () => {
    setIsSubmittingFeedback(true)

    const { masterPdfId, submitFeedbackForMasterPDF } = props

    submitFeedbackForMasterPDF(masterPdfId).catch(() => {
      setIsSubmittingFeedback(false)
    })
  }

  const url = api.routes.masterPdfFeedbackUrl(masterPdfId)

  if (hasCustomerFeedbackUploaded) {
    return (
      <>
        <div className="o-block o-box c-card c-card--block">
          <div className="o-block">
            <a className="c-type c-type--body-sans-md o-block o-block--compact" href={url}>
              Uploaded {reviewFileName}
            </a>
          </div>
          <div className="o-block">
            <Button color="secondary" onClick={deleteCustomerReviewFeedback} label="Delete" />
          </div>
        </div>
        <div className="o-block">
          <Button onClick={submitFeedback} disabled={isSubmittingFeedback} color="primary" label="Submit Feedback" />
        </div>
      </>
    )
  } else {
    return (
      <>
        <UploadToPathButton
          onDocumentUploaded={(signResult, rawFile) =>
            props.addFeedbackToMasterPDF(props.masterPdfId, signResult, rawFile)
          }
          acceptedFileTypes={wordDocsAndPdfs}
        />
        <div className="o-block">
          <Button disabled color="primary" label="Submit Feedback" />
        </div>
      </>
    )
  }
}

function mapStateToProps(state) {
  return {
    hasCustomerFeedbackUploaded: isCustomerFeedbackUploaded(state),
    masterPdfId: getMasterPdfId(state),
    reviewFileName: getCustomerReviewFeedbackFileName(state)
  }
}

function mapDispatchToActions(dispatch) {
  return {
    addFeedbackToMasterPDF: (...args) => dispatch(addFeedbackToMasterPDF(...args)),
    removeFeedbackFromMasterPDF: (masterPdfId: number) => dispatch(removeFeedbackFromMasterPDF(masterPdfId)),
    submitFeedbackForMasterPDF: (masterPdfId: number) => dispatch(submitFeedbackForMasterPDF(masterPdfId))
  }
}

export default connect(mapStateToProps, mapDispatchToActions)(CustomerFeedbackUploader)
