import convert from 'convert-units'

const INCHES_IN_FOOT = 12

export const lengthMetricToImperialWithInches = (cm) => {
  if (cm === null) return

  const totalInches = convert(cm).from('cm').to('in')

  const feet = parseInt(Math.floor(totalInches / INCHES_IN_FOOT), 10)
  const inches = Math.round(totalInches % INCHES_IN_FOOT)

  return { feet, inches }
}

export const precisionRound = (number, precision) => {
  const factor = Math.pow(10, precision)

  return Math.round(number * factor) / factor
}
