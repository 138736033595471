import React, { ReactNode } from 'react'
import { WORKFLOW_STATUSES } from 'lib/constants'
import CustomerReviewPrimaryCTA from '../phases/customer_review_phase/customer_review_primary_cta'

export interface ContentForStatus {
  message: string
  primaryCTA?: string | ReactNode
  phaseDescription: string
}

interface StatusToContentMapping {
  [status: string]: ContentForStatus
}

const CUSTOMER_REVIEW = {
  READY: {
    message: 'Your digital application package is ready for you to review! Look for instructions below.',
    primaryCTA: <CustomerReviewPrimaryCTA />,
    phaseDescription:
      'We want to make sure we meet your expectations by giving you this opportunity to carefully check your digital application package and share your feedback.<br/>Below is a Digital Review Packet that includes a guide to common green card forms, instructions for providing your mailing address, and a Feedback Form for listing any changes you’d like us to make. If you’re satisfied, just let us know in the Feedback Form and upload it.'
  },
  COMPLETE: {
    message:
      'Thanks for reviewing your application package! We’re looking over your feedback and will update you in 2–3 business days.',
    phaseDescription:
      'If you indicated in the Feedback Form that you’d like us to make changes, we’ll update your application accordingly or reach out to you for clarification. Otherwise, we’ll start assembling your finalized application package and prepare it for shipping.'
  },
  CLARIFICATION: {
    message: 'Check your email — we just have a few questions for you before we Finalize and Ship.',
    phaseDescription:
      'We want to make sure your application package is in its best possible shape when you file. That’s why we’re reaching out for clarification about your feedback. Just send an email to {{steward}} explaining how we can resolve the outstanding issues with your application.<br/><br/>Congrats — you’re close to the finish line! Remember: The faster you respond, the sooner we can ship your package!'
  }
}

const DOCUMENT_REVIEW = {
  READY_FOR_REVIEW: {
    message:
      'Congrats — you finished uploading your documents! We’ll start checking your application materials for accuracy, completeness, and consistency.',
    phaseDescription:
      'We’ll thoroughly check your application forms and supporting documents to make sure your information matches. We’ll reach out via email if we have any questions.'
  },
  OPEN_QUESTIONS: {
    message: 'Check your email — we just have a few questions for you before Legal Review can begin.',
    phaseDescription:
      'Sometimes, a document doesn’t match the info that was entered in our online app. In some cases, there might be missing info or documents. We just need to make sure there are no inconsistencies, typos, or missing pieces in your application to help ensure you succeed.<br/><br/>As a friendly reminder, the faster you respond, the sooner we can ship your package!'
  },
  FINALIZING_OPEN_QUESTIONS: {
    message:
      'Thanks for taking care of that! We’re updating your application and will reach out via email if we need anything else.',
    phaseDescription:
      'We’re updating your application with the info and/or documents you provided to make sure everything’s accurate, complete, and consistent before we assemble your digital application package for Legal Review. This helps to maximize your application’s chance of being accepted by the U.S. government.'
  }
}

const LEGAL_REVIEW = {
  READY_FOR_REVIEW: {
    message: '{{attorney}} is reviewing your digital application package. We’ll update you in 5-6 business days.',
    phaseDescription: `{{attorney}} is reviewing your digital application package for accuracy, completeness, and consistency. We’ll reach out via email if {{attorney}} has any questions or needs additional documents to strengthen your application.<br/><br/>After that, we’ll go through our “Anti-Rejection” Checklist to help ensure USCIS won’t reject your application based on the most common errors and issues they find in applications.`
  },
  CLARIFICATION: {
    message:
      'Check your email — {{attorney}} just has a few questions for you to answer before Customer Review can begin.',
    phaseDescription:
      '{{attorney}} wants to make sure nothing was overlooked earlier in our process, so look for an email from {{steward}} with {{attorney}}’s questions. Don’t worry — most are about minor typos, inconsistencies, and requests for additional info or documents that could boost the strength of your application. Once you respond to their questions, we’ll update your application so it’s flawless when you file.<br/><br/>Remember: The faster you respond, the sooner we can ship your package!'
  },
  FINAL_EDITS: {
    message:
      'Thanks for responding to {{attorney}}’s questions! We’re updating your application and will reach out in 1 business day.',
    phaseDescription:
      'We’re updating your application with the info and/or documents you provided to make sure everything looks good. After making updates, we’ll do an “Anti-Rejection” Check to help make sure USCIS won’t reject your application based on the most common errors and issues they find in applications.'
  }
}

const MASTER_PDF_FINAL_EDITS = {
  message:
    'Thanks for reviewing your application package! We’re looking over your response and will update you in 1–2 business days.',
  phaseDescription:
    'This is it — we’re incorporating the rest of your feedback to resolve all outstanding issues with your application. Once everything is polished, we’ll be ready to Finalize and Ship. Fingers crossed!'
}

export const SHIPMENT = {
  IN_PRINTSHOP: {
    message: 'Congrats — we’ve finalized your application and are printing and assembling your package!',
    phaseDescription:
      'We’ve sent the digital copy of your green card application package to our print shop. They’ll print a hard copy of your entire application, including all forms and supporting documents. After that, we’ll assemble your package precisely how the U.S. government prefers.<br/><br/>This step typically takes about 1–2 business days.'
  },
  READY_TO_SHIP: {
    message: 'Hooray — your application package is ready to ship! We’ll let you know when it’s on the way.',
    phaseDescription:
      'Our delivery service is scheduled to pick up your application package soon. We’ll send you a tracking number for your package as soon as it’s available.<br/><br/>It usually takes about 2 business days for delivery to a domestic (U.S.) address and 3–4 business days to an international address.'
  },
  SHIPPED: {
    message: 'Congrats — your application package has shipped and will be arriving soon!',
    phaseDescription:
      'Once your application package arrives, please review everything in the box. You’ll find an instruction card and clearly marked spaces to sign, date, and add additional items. After that, just slip your application package into an envelope and drop it back in the mail.<br/><br/>That’s it — you’ll just wait to hear back from the U.S. government. At this time, you’ll part ways with {{attorney}}, but your Customer Success Advocate stays with you the rest of the way!'
  },
  POST_SHIPMENT_DELIVERED_TO_USCIS_AOS: {
    message:
      'Congrats — USCIS received your application! We’ll reach out to you via email when you’re ready for the next steps.',
    phaseDescription:
      'You did it! Now, it’s time to wait. This part requires a little patience, but don’t worry — we won’t go anywhere.<br/><br/>While you wait to hear back from the U.S. government, feel free to reach out to your Boundless Support Team whenever you have questions. Once your green card interview date is set, we’ll be in touch again to help you and your spouse fully prepare.'
  },
  POST_SHIPMENT_DELIVERED_TO_USCIS_CP: {
    message:
      'Congrats — USCIS received your application! We’ll reach out to you via email when you’re ready for the next steps.',
    phaseDescription:
      'You did it! Now, it’s time to wait. This part requires a little patience, but don’t worry — we won’t go anywhere.<br/><br/>While you wait to hear back from the U.S. government, feel free to reach out to your Boundless Support Team whenever you have questions. Once your green card interview date is set, we’ll be in touch again to help you fully prepare.'
  }
}

const ILA = {
  message:
    '{{attorney}} will review your answers within 1–2 business days. While waiting, you can start uploading your supporting documents.',
  phaseDescription:
    '{{attorney}} is reviewing your answers to the questionnaires. They’ll reach out to you via email if they have any questions.<br/><br/>In the meantime, you can start uploading your supporting documents. The U.S. government will use these to verify the information in your application. {{attorney}} might also ask for more documents or add additional questionnaires after Attorney Assessment.'
}

export const DASHBOARD_CONTENT_FOR_STATUS_DATA: StatusToContentMapping = {
  // INTAKE / Getting to Know You has a custom component outside this file

  // ILA / Lawyer Assessment
  [WORKFLOW_STATUSES.AOS_INITIAL_LAWYER_REVIEW]: ILA,
  [WORKFLOW_STATUSES.CP_INITIAL_LAWYER_REVIEW]: ILA,

  // DOCUMENT_UPLOAD has a custom component outside this file

  // DOCUMENT_REVIEW / Quality Assurance
  [WORKFLOW_STATUSES.AOS_READY_FOR_QA]: DOCUMENT_REVIEW.READY_FOR_REVIEW,
  [WORKFLOW_STATUSES.CP_READY_FOR_QA]: DOCUMENT_REVIEW.READY_FOR_REVIEW,
  [WORKFLOW_STATUSES.AOS_QA]: DOCUMENT_REVIEW.READY_FOR_REVIEW,
  [WORKFLOW_STATUSES.CP_QA]: DOCUMENT_REVIEW.READY_FOR_REVIEW,
  [WORKFLOW_STATUSES.AOS_OPEN_QUESTIONS_READY_TO_SEND]: DOCUMENT_REVIEW.READY_FOR_REVIEW,
  [WORKFLOW_STATUSES.CP_OPEN_QUESTIONS_READY_TO_SEND]: DOCUMENT_REVIEW.READY_FOR_REVIEW,
  [WORKFLOW_STATUSES.AOS_WAITING_FOR_RESPONSE_TO_OPEN_QUESTIONS]: DOCUMENT_REVIEW.OPEN_QUESTIONS,
  [WORKFLOW_STATUSES.CP_WAITING_FOR_RESPONSE_TO_OPEN_QUESTIONS]: DOCUMENT_REVIEW.OPEN_QUESTIONS,
  [WORKFLOW_STATUSES.AOS_FINALIZING_OPEN_QUESTIONS]: DOCUMENT_REVIEW.FINALIZING_OPEN_QUESTIONS,
  [WORKFLOW_STATUSES.CP_FINALIZING_OPEN_QUESTIONS]: DOCUMENT_REVIEW.FINALIZING_OPEN_QUESTIONS,

  // LEGAL_REVIEW / Legal Review
  [WORKFLOW_STATUSES.AOS_BOUNDLESS_LEGAL_REVIEW]: LEGAL_REVIEW.READY_FOR_REVIEW,
  [WORKFLOW_STATUSES.CP_BOUNDLESS_LEGAL_REVIEW]: LEGAL_REVIEW.READY_FOR_REVIEW,
  [WORKFLOW_STATUSES.AOS_MASTER_PDF_INITIAL_EDITS_POST_LAWYER_REVIEW]: LEGAL_REVIEW.READY_FOR_REVIEW,
  [WORKFLOW_STATUSES.AOS_INITIAL_LR_QUESTIONS_READY_TO_SEND]: LEGAL_REVIEW.READY_FOR_REVIEW,
  [WORKFLOW_STATUSES.CP_MASTER_PDF_INITIAL_EDITS_POST_LAWYER_REVIEW]: LEGAL_REVIEW.READY_FOR_REVIEW,
  [WORKFLOW_STATUSES.CP_INITIAL_LR_QUESTIONS_READY_TO_SEND]: LEGAL_REVIEW.READY_FOR_REVIEW,
  [WORKFLOW_STATUSES.AOS_MASTER_PDF_WAITING_FOR_CUSTOMER_INFO_DOCS_POST_LAWYER_REVIEW]: LEGAL_REVIEW.CLARIFICATION,
  [WORKFLOW_STATUSES.CP_MASTER_PDF_WAITING_FOR_CUSTOMER_INFO_DOCS_POST_LAWYER_REVIEW]: LEGAL_REVIEW.CLARIFICATION,
  [WORKFLOW_STATUSES.AOS_MASTER_PDF_FINAL_EDITS_POST_LAWYER_REVIEW]: LEGAL_REVIEW.CLARIFICATION,
  [WORKFLOW_STATUSES.CP_MASTER_PDF_FINAL_EDITS_POST_LAWYER_REVIEW]: LEGAL_REVIEW.CLARIFICATION,
  [WORKFLOW_STATUSES.AOS_MASTER_PDF_ARC_NEEDED]: LEGAL_REVIEW.READY_FOR_REVIEW,
  [WORKFLOW_STATUSES.AOS_MASTER_PDF_ARC_COMPLETED]: LEGAL_REVIEW.READY_FOR_REVIEW,
  [WORKFLOW_STATUSES.CP_MASTER_PDF_ARC_NEEDED]: LEGAL_REVIEW.READY_FOR_REVIEW,
  [WORKFLOW_STATUSES.CP_MASTER_PDF_ARC_COMPLETED]: LEGAL_REVIEW.READY_FOR_REVIEW,

  // CUSTOMER_REVIEW / Customer review
  [WORKFLOW_STATUSES.AOS_CUSTOMER_REVIEW]: CUSTOMER_REVIEW.READY,
  [WORKFLOW_STATUSES.AOS_CUSTOMER_REVIEW]: CUSTOMER_REVIEW.READY,
  [WORKFLOW_STATUSES.AOS_READY_FOR_CUSTOMER_REVIEW]: CUSTOMER_REVIEW.READY,
  [WORKFLOW_STATUSES.CP_CUSTOMER_REVIEW]: CUSTOMER_REVIEW.READY,
  [WORKFLOW_STATUSES.CP_READY_FOR_CUSTOMER_REVIEW]: CUSTOMER_REVIEW.READY,
  [WORKFLOW_STATUSES.AOS_MASTER_PDF_INITIAL_EDITS_POST_CUSTOMER_REVIEW]: CUSTOMER_REVIEW.COMPLETE,
  [WORKFLOW_STATUSES.AOS_INITIAL_CR_QUESTIONS_READY_TO_SEND]: CUSTOMER_REVIEW.COMPLETE,
  [WORKFLOW_STATUSES.CP_MASTER_PDF_INITIAL_EDITS_POST_CUSTOMER_REVIEW]: CUSTOMER_REVIEW.COMPLETE,
  [WORKFLOW_STATUSES.CP_INITIAL_QUESTIONS_READY_TO_SEND]: CUSTOMER_REVIEW.COMPLETE,
  [WORKFLOW_STATUSES.AOS_MASTER_PDF_WAITING_FOR_CUSTOMER_INFO_DOCS_POST_CUSTOMER_REVIEW]: CUSTOMER_REVIEW.CLARIFICATION,
  [WORKFLOW_STATUSES.CP_MASTER_PDF_WAITING_FOR_CUSTOMER_INFO_DOCS_POST_CUSTOMER_REVIEW]: CUSTOMER_REVIEW.CLARIFICATION,
  [WORKFLOW_STATUSES.AOS_MASTER_PDF_FINAL_EDITS]: MASTER_PDF_FINAL_EDITS,
  [WORKFLOW_STATUSES.CP_MASTER_PDF_FINAL_EDITS]: MASTER_PDF_FINAL_EDITS,

  // SHIPMENT / Finalize and Ship
  [WORKFLOW_STATUSES.CP_READY_FOR_PRINTSHOP]: SHIPMENT.IN_PRINTSHOP,
  [WORKFLOW_STATUSES.AOS_READY_FOR_PRINTSHOP]: SHIPMENT.IN_PRINTSHOP,
  [WORKFLOW_STATUSES.AOS_IN_PRINTSHOP]: SHIPMENT.IN_PRINTSHOP,
  [WORKFLOW_STATUSES.CP_IN_PRINTSHOP]: SHIPMENT.IN_PRINTSHOP,
  [WORKFLOW_STATUSES.AOS_READY_TO_SHIP]: SHIPMENT.READY_TO_SHIP,
  [WORKFLOW_STATUSES.CP_READY_TO_SHIP]: SHIPMENT.READY_TO_SHIP,
  [WORKFLOW_STATUSES.AOS_PACKAGE_SHIPPED]: SHIPMENT.SHIPPED,
  [WORKFLOW_STATUSES.CP_PACKAGE_SHIPPED]: SHIPMENT.SHIPPED,

  [WORKFLOW_STATUSES.CP_PACKAGE_DELIVERED_TO_CUSTOMER]: SHIPMENT.SHIPPED, // Not great, but we're outta time

  [WORKFLOW_STATUSES.CP_PACKAGE_DELIVERED_TO_USCIS]: SHIPMENT.POST_SHIPMENT_DELIVERED_TO_USCIS_CP,
  [WORKFLOW_STATUSES.CP_2_PAUSED]: SHIPMENT.POST_SHIPMENT_DELIVERED_TO_USCIS_CP,
  [WORKFLOW_STATUSES.CP_I130_APPROVAL_NOTICE_RECEIVED]: SHIPMENT.POST_SHIPMENT_DELIVERED_TO_USCIS_CP,
  [WORKFLOW_STATUSES.CP_NVC_WELCOME_LETTER_RECEIVED]: SHIPMENT.POST_SHIPMENT_DELIVERED_TO_USCIS_CP,
  [WORKFLOW_STATUSES.CP_IV_DOCUMENT_GATHERING]: SHIPMENT.POST_SHIPMENT_DELIVERED_TO_USCIS_CP,
  [WORKFLOW_STATUSES.CP_IV_READY_FOR_QA]: SHIPMENT.POST_SHIPMENT_DELIVERED_TO_USCIS_CP,
  [WORKFLOW_STATUSES.CP_IV_QA]: SHIPMENT.POST_SHIPMENT_DELIVERED_TO_USCIS_CP,
  [WORKFLOW_STATUSES.CP_IV_OPEN_QUESTIONS_READY_TO_SEND]: SHIPMENT.POST_SHIPMENT_DELIVERED_TO_USCIS_CP,
  [WORKFLOW_STATUSES.CP_IV_WAITING_FOR_RESPONSE_TO_OPEN_QUESTIONS]: SHIPMENT.POST_SHIPMENT_DELIVERED_TO_USCIS_CP,
  [WORKFLOW_STATUSES.CP_IV_FINALIZING_OPEN_QUESTIONS]: SHIPMENT.POST_SHIPMENT_DELIVERED_TO_USCIS_CP,
  [WORKFLOW_STATUSES.CP_IV_LAWYER_REVIEW]: SHIPMENT.POST_SHIPMENT_DELIVERED_TO_USCIS_CP,
  [WORKFLOW_STATUSES.CP_IV_INITIAL_EDITS_POST_LAWYER_REVIEW]: SHIPMENT.POST_SHIPMENT_DELIVERED_TO_USCIS_CP,
  [WORKFLOW_STATUSES.CP_IV_WAITING_FOR_CUSTOMER_INFO_DOCS_POST_LAWYER_REVIEW]:
    SHIPMENT.POST_SHIPMENT_DELIVERED_TO_USCIS_CP,
  [WORKFLOW_STATUSES.CP_IV_FINAL_EDITS_POST_LAWYER_REVIEW]: SHIPMENT.POST_SHIPMENT_DELIVERED_TO_USCIS_CP,
  [WORKFLOW_STATUSES.CP_IV_READY_FOR_CUSTOMER_REVIEW]: SHIPMENT.POST_SHIPMENT_DELIVERED_TO_USCIS_CP,
  [WORKFLOW_STATUSES.CP_IV_CUSTOMER_REVIEW]: SHIPMENT.POST_SHIPMENT_DELIVERED_TO_USCIS_CP,
  [WORKFLOW_STATUSES.CP_IV_INITIAL_EDITS_POST_CUSTOMER_REVIEW]: SHIPMENT.POST_SHIPMENT_DELIVERED_TO_USCIS_CP,
  [WORKFLOW_STATUSES.CP_IV_WAITING_FOR_CUSTOMER_INFO_DOCS_POST_CUSTOMER_REVIEW]:
    SHIPMENT.POST_SHIPMENT_DELIVERED_TO_USCIS_CP,
  [WORKFLOW_STATUSES.CP_IV_FINAL_EDITS_POST_CUSTOMER_REVIEW]: SHIPMENT.POST_SHIPMENT_DELIVERED_TO_USCIS_CP,
  [WORKFLOW_STATUSES.CP_IV_READY_FOR_NVC_ONLINE_UPLOAD_PRINTSHOP]: SHIPMENT.POST_SHIPMENT_DELIVERED_TO_USCIS_CP,
  [WORKFLOW_STATUSES.CP_IV_IN_PRINTSHOP]: SHIPMENT.POST_SHIPMENT_DELIVERED_TO_USCIS_CP,
  [WORKFLOW_STATUSES.CP_IV_APPLICATION_SENT_TO_CUSTOMER]: SHIPMENT.POST_SHIPMENT_DELIVERED_TO_USCIS_CP,
  [WORKFLOW_STATUSES.CP_IV_PENDING_DOC_APPROVAL]: SHIPMENT.POST_SHIPMENT_DELIVERED_TO_USCIS_CP,
  [WORKFLOW_STATUSES.CP_IV_DOC_REJECTION]: SHIPMENT.POST_SHIPMENT_DELIVERED_TO_USCIS_CP,
  [WORKFLOW_STATUSES.CP_IV_APPLICATION_ACCEPTED_FOR_REVIEW_OR_WAITING]: SHIPMENT.POST_SHIPMENT_DELIVERED_TO_USCIS_CP,
  [WORKFLOW_STATUSES.CP_INTERVIEW_NOTICE_RECEIVED]: SHIPMENT.POST_SHIPMENT_DELIVERED_TO_USCIS_CP,
  [WORKFLOW_STATUSES.CP_INTERVIEW_COMPLETE]: SHIPMENT.POST_SHIPMENT_DELIVERED_TO_USCIS_CP,
  [WORKFLOW_STATUSES.CP_APPROVED]: SHIPMENT.POST_SHIPMENT_DELIVERED_TO_USCIS_CP,
  [WORKFLOW_STATUSES.CP_CP_REFUNDED]: SHIPMENT.POST_SHIPMENT_DELIVERED_TO_USCIS_CP,
  [WORKFLOW_STATUSES.CP_CP_RETIRED]: SHIPMENT.POST_SHIPMENT_DELIVERED_TO_USCIS_CP,
  [WORKFLOW_STATUSES.CP_PROCESSING_RFE]: SHIPMENT.POST_SHIPMENT_DELIVERED_TO_USCIS_CP,
  [WORKFLOW_STATUSES.CP_PROCESSING_SPECIAL_REQUEST]: SHIPMENT.POST_SHIPMENT_DELIVERED_TO_USCIS_CP,
  [WORKFLOW_STATUSES.CP_RETIRED]: SHIPMENT.POST_SHIPMENT_DELIVERED_TO_USCIS_CP,

  // AOS post-shipment board
  [WORKFLOW_STATUSES.POST_SHIPMENT_PACKAGE_DELIVERED_TO_CUSTOMER]: SHIPMENT.SHIPPED, // Not great, but we're outta time

  [WORKFLOW_STATUSES.POST_SHIPMENT_PACKAGE_DELIVERED_TO_USCIS]: SHIPMENT.POST_SHIPMENT_DELIVERED_TO_USCIS_AOS,
  [WORKFLOW_STATUSES.POST_SHIPMENT_WAITING]: SHIPMENT.POST_SHIPMENT_DELIVERED_TO_USCIS_AOS,
  [WORKFLOW_STATUSES.POST_SHIPMENT_PROCESSING_RFE]: SHIPMENT.POST_SHIPMENT_DELIVERED_TO_USCIS_AOS,
  [WORKFLOW_STATUSES.POST_SHIPMENT_PROCESSING_SPECIAL_REQUEST]: SHIPMENT.POST_SHIPMENT_DELIVERED_TO_USCIS_AOS,
  [WORKFLOW_STATUSES.POST_SHIPMENT_INTERVIEW_NOTICE_RECEIVED]: SHIPMENT.POST_SHIPMENT_DELIVERED_TO_USCIS_AOS,
  [WORKFLOW_STATUSES.POST_SHIPMENT_INTERVIEW_COMPLETE]: SHIPMENT.POST_SHIPMENT_DELIVERED_TO_USCIS_AOS,
  [WORKFLOW_STATUSES.POST_SHIPMENT_APPROVED]: SHIPMENT.POST_SHIPMENT_DELIVERED_TO_USCIS_AOS,
  [WORKFLOW_STATUSES.POST_SHIPMENT_RETIRED]: SHIPMENT.POST_SHIPMENT_DELIVERED_TO_USCIS_AOS,
  [WORKFLOW_STATUSES.POST_SHIPMENT_READY_FOR_QA]: SHIPMENT.POST_SHIPMENT_DELIVERED_TO_USCIS_AOS,
  [WORKFLOW_STATUSES.POST_SHIPMENT_IN_PRINTSHOP]: SHIPMENT.POST_SHIPMENT_DELIVERED_TO_USCIS_AOS,

  // Post-shipment
  [WORKFLOW_STATUSES.POST_SHIPMENT_PACKAGE_SHIPPED]: SHIPMENT.SHIPPED,
  [WORKFLOW_STATUSES.POST_SHIPMENT_PACKAGE_SHIPPED_2]: SHIPMENT.SHIPPED
}
