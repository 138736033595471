import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { openChat } from 'actions/chat_actions'
import { openModal } from 'actions/modal_actions'
import { isUserLoggedIn, isMobileBrowser, getPetitionEmail } from 'reducers/selectors'
import ProcessInfo from 'components/panels/outcomes/rapid_visa_redirect/process_info'
import RapidVisaRedirectEligibilityReport from 'components/panels/outcomes/rapid_visa_redirect/eligibility_report'
import EligibilityMarketingInfo from './eligibility_marketing_info'

interface Props {
  isMobileBrowser: boolean
  openChat: typeof openChat
  openModal: typeof openModal
  petitionEmail: string
  userLoggedIn: boolean
}

class SetupComplete extends React.Component<Props> {
  renderContent() {
    const { isMobileBrowser, petitionEmail } = this.props

    if (!isMobileBrowser) {
      return (
        <React.Fragment>
          <RapidVisaRedirectEligibilityReport screen="desktop" />

          <EligibilityMarketingInfo isMobileBrowser={isMobileBrowser} email={petitionEmail} />
          <ProcessInfo isMobileBrowser={isMobileBrowser} />
        </React.Fragment>
      )
    } else {
      return (
        <React.Fragment>
          <RapidVisaRedirectEligibilityReport screen="mobile" />

          <EligibilityMarketingInfo isMobileBrowser={isMobileBrowser} email={petitionEmail} />
          <ProcessInfo isMobileBrowser={isMobileBrowser} />
        </React.Fragment>
      )
    }
  }

  render() {
    return <div className="o-block">{this.renderContent()}</div>
  }
}

function mapStateToProps(state) {
  return {
    userLoggedIn: isUserLoggedIn(state),
    isMobileBrowser: isMobileBrowser(state),
    petitionEmail: getPetitionEmail(state)
  }
}

function mapDispatchToActions(dispatch) {
  return bindActionCreators(
    {
      openChat,
      openModal
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToActions)(SetupComplete)
