import React, { FunctionComponent, useState, useEffect } from 'react'

import HelpIcon from 'components/icons/help_icon'
import Heading from 'components/type/heading'
import Paragraph from 'components/type/paragraph'
import MissingDocumentsIcon from 'components/icons/missing_documents_icon'
import ExternalLink from 'components/external_link'
import HelpSectionItem from 'components/screens/shared/help_section_item'
import SubmitDocumentsButton from 'components/screens/document_upload_v2/submit_documents_button'

interface Props {
  showTips: boolean
}

const AdditionalInformation: FunctionComponent<Props> = ({ showTips }) => {
  const openChat = (event: React.MouseEvent<HTMLAnchorElement>) => {
    if (window.handleOpenChatTool) {
      window.handleOpenChatTool(event.nativeEvent, { via: 'DocumentUpload' })
    }
  }

  const [tipsOpen, setTipsOpen] = useState(false)
  const [missingDocsInfoOpen, setMissingDocsInfoOpen] = useState(false)

  useEffect(() => {
    setTipsOpen(showTips)
  }, [showTips])

  // Keep track of the open/closed status of the <details>
  // It's a little weird but hey https://github.com/facebook/react/issues/15486
  const onToggle = (event) => {
    event.preventDefault()
    setTipsOpen(!tipsOpen)
  }

  return (
    <>
      <SubmitDocumentsButton isMobile={false} />
      <a href="/timeline" className="o-block c-btn c-btn__block c-btn--secondary">
        Return to Dashboard
      </a>
      <ul className="tool-menu-sublist tool-menu-sublist--no-bottom o-box">
        <li className="tool-menu-item block">
          <a id="tips-for-success-anchor"></a>
          <HelpSectionItem
            isOpen={tipsOpen}
            onToggle={onToggle}
            headerContent={
              <>
                <HelpIcon className="mr-2" />
                Tips For Success
              </>
            }
            helpContent={
              <>
                <Heading tag="h4" size="sm" color="emphasized">
                  Tips for Successful Documents
                </Heading>
                <Heading tag="p" size="sm" subhead={true} color="emphasized" spacing="sm">
                  Upload the Right Documents
                </Heading>
                <Paragraph size="sm">
                  The U.S. government has specific requirements based on the country where a document was issued. See{' '}
                  <ExternalLink href="https://www.boundless.com/immigration-resources/us-visa-reciprocity-schedule/">
                    this guide
                  </ExternalLink>{' '}
                  <span className="italic">before</span> uploading a non-U.S.-issued document.
                </Paragraph>
                <Heading tag="p" size="sm" subhead={true} color="emphasized" spacing="sm">
                  Get Certified Translations
                </Heading>
                <Paragraph size="sm">
                  The U.S. government requires a{' '}
                  <ExternalLink href="https://www.boundless.com/immigration-resources/translating-immigration-documents-english/">
                    <i>certified</i> translation
                  </ExternalLink>{' '}
                  of any document that’s not in English. RushTranslate, our partner, can help with your translation
                  needs. <ExternalLink href="https://rushtranslate.com/boundless">Click here</ExternalLink> for more
                  info.
                </Paragraph>
                <Heading tag="p" size="sm" subhead={true} color="emphasized" spacing="sm">
                  Upload High-Quality Images
                </Heading>
                <Paragraph size="sm">
                  Make sure your images are clear and readable. This helps prevent rejection by the U.S. government. See{' '}
                  <ExternalLink href="https://www.boundless.com/immigration-resources/creating-flawless-digital-documents/">
                    this guide
                  </ExternalLink>{' '}
                  for tips on creating flawless digital documents.
                </Paragraph>
              </>
            }
          />
        </li>
        <li className="tool-menu-item block">
          <HelpSectionItem
            isOpen={missingDocsInfoOpen}
            onToggle={() => setMissingDocsInfoOpen(!missingDocsInfoOpen)}
            headerContent={
              <>
                <MissingDocumentsIcon className="mr-2" />
                I'm Missing Documents
              </>
            }
            helpContent={
              <>
                {' '}
                <Heading tag="h4" size="sm" color="emphasized">
                  Missing Documents?
                </Heading>
                <Paragraph size="sm">
                  If you cannot obtain your required supporting documents, please contact us by{' '}
                  <a onClick={openChat} href="#">
                    clicking the chat icon below
                  </a>
                  .
                </Paragraph>
                <Paragraph size="sm">
                  Please let us know which documents you're missing and why. This will help us understand how best to
                  help your case moving forward. It is typically difficult to move a case forward to Quality Assurance
                  unless we have all of the necessary documents.
                </Paragraph>
              </>
            }
          />
        </li>
      </ul>
    </>
  )
}

export default AdditionalInformation
