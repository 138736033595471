import React, { FunctionComponent, useState } from 'react'

import ChevronIcon from 'components/icons/chevron_icon'

const LEARN_MORE = 'Learn more'
const HIDDEN_CLASS_NAME = 'u-hidden-sm-up'

interface Props {
  title: string
  description: React.ReactNode
}

const Milestone: FunctionComponent<Props> = ({ title, description }) => {
  const [expanded, setExpanded] = useState(false)
  const [descriptionClass, setDescriptionClass] = useState(HIDDEN_CLASS_NAME)
  const [toggleTitle, setToggleTitle] = useState(LEARN_MORE)

  const toggleExpand = () => {
    const current_expanded = !expanded
    setExpanded(current_expanded)
    setDescriptionClass(current_expanded ? '' : HIDDEN_CLASS_NAME)
    setToggleTitle(current_expanded ? 'Collapse' : LEARN_MORE)
  }

  return (
    <>
      <div className="o-box o-block o-block--tight">
        <div className="o-media" onClick={toggleExpand}>
          <div className="o-media__figure o-box">
            <div className="c-type c-type--headline-sm o-block o-block--compact">{title}</div>
          </div>

          <div className="o-media__figure">
            {toggleTitle}
            <ChevronIcon className="ml-1" chevronDirection={expanded ? 'up' : 'down'} />
          </div>
        </div>

        <div className={descriptionClass}>{description}</div>
      </div>
      <hr className="o-block o-block--tight c-divider" />
    </>
  )
}

export default Milestone
