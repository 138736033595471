import React, { ReactNode } from 'react'

import { joinI18nKeys } from 'lib/i18n'

import Field from '../field'

import { PanelType } from './index'

interface Props {
  panel: PanelType
}

export default class Controls extends React.Component<Props> {
  renderControls(): ReactNode[] {
    const { panel } = this.props

    return (panel.fields || []).map((field) => {
      const key = `${panel.name}-${field.name}`
      const resourceKeys = joinI18nKeys(panel.panel_keys, 'fields')
      const panelIndex = panel.panel_index

      return (
        <Field
          key={key}
          resourceKeys={resourceKeys}
          panelIndex={panelIndex}
          currentIndex={panel.currentIndex}
          parentIndex={panel.parentIndex}
          field={field}
        />
      )
    })
  }

  render() {
    return <fieldset className="o-block c-paper-form">{this.renderControls()}</fieldset>
  }
}
