import React, { FunctionComponent, useEffect, useState } from 'react'

import Input from '../inputs/input'
import RadioGroup from '../inputs/radio_group'
import { InputOption, ValidatorParams } from '../lib/types'
import CommonCheckboxV2 from 'components/forms/inputs/v2/address/common/common_checkbox_v2'
import CountryInputV2 from 'components/forms/inputs/v2/address/country_input_v2'
import ProvinceInputV2 from 'components/forms/inputs/v2/address/province_input_v2'
import CheckboxGroup from '../inputs/checkbox_group'
import VisaTypes from '../inputs/visa_type'
import TextArea from '../inputs/text_area'
import SelectDropdown from '../inputs/select_dropdown'
import ssnInput from '../inputs/ssn_input'
import Phone from '../inputs/phone'
import AddressHistoryInput from '../inputs/address_history'
import EmploymentHistoryInput from '../inputs/employment_history'
import AddressInput from '../inputs/address_input'
import EmbassyCitiesInputV2 from 'components/forms/inputs/v2/embassy_cities_input_v2'
import { AddressHistory } from 'reducers/address_histories/index'
import PlacesInput from './places/places_input'

import countries from 'data/address_history_country_options.json'
import DatePicker from 'components/datepicker'
import { inputWarnings } from './input/input_validators'

interface Props {
  currentPagePath?: string
  disabled?: boolean
  id: string
  isMetaQuestion?: boolean // this is a hack to remove padding in doc upload, let's remove when we can
  name?: string
  onPlacesUpdate?: (answer: google.maps.GeocoderAddressComponent[]) => void
  onSave: (answer: string) => any
  options?: InputOption[]
  placeholder?: string
  type: string
  validators?: ValidatorParams[]
  value?: string | AddressHistory | Date
}

/**
 * This component will return a type of input based on what type you pass it.
 * Used in the Question component. Can be used anywhere else where multiple types
 * of inputs are needed
 */
const InputTransformer: FunctionComponent<Props> = ({
  currentPagePath,
  disabled,
  id,
  isMetaQuestion = false,
  name,
  onPlacesUpdate,
  onSave,
  options,
  placeholder,
  type,
  validators,
  value
}) => {
  const [currentValue, setCurrentValue] = useState(value || '')

  // Used for passing as hidden country codes for input type of country_us_only
  const countryKeysNoUS = Object.keys(countries)
  countryKeysNoUS.splice(countryKeysNoUS.indexOf('US'), 1)

  useEffect(() => {
    setCurrentValue(value)
  }, [value])

  /**
   * Take any additional validators being passed in as props
   * and concatenate them with the default validators defined below
   * in the fieldsLookup map.
   *
   * @param defaults the default validator(s) for an input type
   * @returns {ValidatorParams[]}
   */
  const combineValidators = (defaults) => {
    return validators ? defaults.concat(validators) : defaults
  }

  const setPlaceholder = (defaultPlaceholder) => {
    return placeholder ? placeholder : defaultPlaceholder
  }

  const fieldsLookup = {
    default: { component: Input },
    address_history: {
      component: AddressHistoryInput,
      saveOnChange: false,
      props: { currentPagePath, currentAddressFilledOptions: options ? options[0] : null }
    },
    address_history_gap: {
      component: AddressHistoryInput,
      saveOnChange: false,
      props: { gapText: name, currentPagePath, currentAddressFilledOptions: options ? options[0] : null }
    },
    alien_registration_number: {
      component: Input,
      props: {
        inputMode: 'numeric',
        type: 'tel',
        validators: combineValidators([{ name: 'alienRegistrationNumber' }])
      }
    },
    benefit_entry_name: {
      component: Input,
      props: {
        warning: inputWarnings.benefit_entry_name
      }
    },
    certification_entry: {
      component: Input,
      props: {
        warning: inputWarnings.certification_entry
      }
    },
    city: {
      component: PlacesInput,
      props: {
        onPlacesUpdate,
        placeType: 'city'
      }
    },
    city_us: {
      component: PlacesInput,
      props: {
        isUnitedStates: true,
        onPlacesUpdate,
        placeType: 'city'
      }
    },
    class_of_admission: {
      component: Input,
      props: {
        formatter: 'allCaps',
        validators: combineValidators([{ name: 'classOfAdmission' }])
      }
    },
    country: {
      component: CountryInputV2,
      props: { hiddenCountryCodes: [], label: name },
      saveOnChange: true
    },
    country_no_us: {
      component: CountryInputV2,
      props: { hiddenCountryCodes: ['US'], label: 'country' },
      saveOnChange: true
    },
    country_us_only: {
      component: CountryInputV2,
      props: {
        // Hide all countries except for the 'US'
        hiddenCountryCodes: countryKeysNoUS,
        label: 'country',
        value: 'United States of America (and U.S. Territories)',
        disabled: true
      },
      saveOnChange: true
    },
    checkbox: {
      component: CommonCheckboxV2,
      props: { label: name, checked: currentValue === 'true' },
      saveOnChange: true
    },
    current_address: {
      component: AddressInput,
      saveOnChange: false,
      props: { currentPagePath, addressHistoryCheckboxOptions: options, type }
    },
    date: {
      component: Input,
      props: {
        inputMode: 'numeric',
        type: 'tel',
        placeholder: setPlaceholder('mm/dd/yyyy'),
        mask: '99/99/9999',
        validators: combineValidators([{ name: 'date' }])
      }
    },
    datepicker: {
      component: DatePicker,
      props: {
        selected: currentValue
      }
    },
    dollar: {
      component: Input,
      props: {
        inputMode: 'numeric',
        type: 'tel',
        placeholder: setPlaceholder('0.00'),
        prefix: '$',
        validators: combineValidators([{ name: 'currency' }]),
        formatter: 'currency'
      }
    },
    dollar_negative_allowed: {
      component: Input,
      props: {
        inputMode: 'numeric',
        type: 'tel',
        placeholder: setPlaceholder('0.00'),
        prefix: '$',
        validators: combineValidators([{ name: 'currencyNegativeAllowed' }]),
        formatter: 'currency'
      }
    },
    dropdown: {
      component: SelectDropdown,
      props: {
        options: options
      },
      saveOnChange: true
    },
    email: {
      component: Input,
      props: {
        type: 'email',
        placeholder: 'example@email.com',
        validators: combineValidators([{ name: 'email' }])
      }
    },
    embassy_cities: {
      component: EmbassyCitiesInputV2,
      props: { label: name, kind: 'mbgc' },
      saveOnChange: true
    },
    employer_name: {
      component: Input,
      props: {
        warning: inputWarnings.employer_name
      }
    },
    employment_history: {
      component: EmploymentHistoryInput,
      saveOnChange: false,
      props: { currentPagePath }
    },
    employment_history_gap: {
      component: EmploymentHistoryInput,
      saveOnChange: false,
      props: { gapText: name, currentPagePath }
    },
    horizontal_radio_button: {
      component: RadioGroup,
      props: { options: options, orientation: 'horizontal' },
      saveOnChange: true
    },
    intended_address: {
      component: AddressInput,
      saveOnChange: false,
      props: { currentPagePath, addressHistoryCheckboxOptions: options, type }
    },
    institution_entry_name: {
      component: Input,
      props: {
        warning: inputWarnings.institution_entry_name
      }
    },
    institution_entry_reason: {
      component: Input,
      props: {
        warning: inputWarnings.institution_entry_reason
      }
    },
    limit_280_input: {
      component: Input,
      props: {
        warning: inputWarnings.limit_280_input
      }
    },
    job_title: {
      component: Input,
      props: {
        warning: inputWarnings.job_title
      }
    },
    k1_embassy_cities: {
      component: EmbassyCitiesInputV2,
      props: { label: name, kind: 'k1' },
      saveOnChange: true
    },
    mailing_address: {
      component: AddressInput,
      saveOnChange: false,
      props: { currentPagePath, addressHistoryCheckboxOptions: options, type }
    },
    name: {
      component: Input,
      props: {
        warning: inputWarnings.name
      }
    },
    nature_of_organization: {
      component: Input,
      props: {
        warning: inputWarnings.nature_of_organization
      }
    },
    number: {
      component: Input,
      props: {
        inputMode: 'numeric',
        type: 'tel',
        validators: combineValidators([{ name: 'numeric' }])
      }
    },
    phone: { component: Phone },
    physical_address_abroad: {
      component: AddressInput,
      saveOnChange: false,
      props: { currentPagePath, addressHistoryCheckboxOptions: options, type }
    },
    postal_code: {
      component: Input
    },
    radio_button_grid: {
      component: RadioGroup,
      props: { options: options, orientation: 'grid' },
      saveOnChange: true
    },
    state: {
      component: ProvinceInputV2,
      props: {
        isUnitedStates: true
      },
      saveOnChange: true
    },
    state_or_province: {
      component: ProvinceInputV2
    },
    street_address: {
      component: PlacesInput,
      props: {
        onPlacesUpdate,
        placeType: 'address'
      }
    },
    street_address_us: {
      component: PlacesInput,
      props: {
        isUnitedStates: true,
        onPlacesUpdate,
        placeType: 'address'
      }
    },
    string: { component: Input },
    ssn: {
      component: ssnInput,
      props: {
        validators: combineValidators([{ name: 'ssn' }])
      }
    },
    textarea: {
      component: TextArea
    },
    unit_number: {
      component: Input
    },
    unit_type: {
      component: RadioGroup,
      props: { options: options, orientation: 'horizontal' },
      saveOnChange: true
    },
    vertical_checkbox_group: {
      component: CheckboxGroup,
      props: { options: options },
      saveOnChange: true
    },
    vertical_radio_button: {
      component: RadioGroup,
      props: { options: options, orientation: 'vertical' },
      saveOnChange: true
    },
    visa_type: {
      component: VisaTypes,
      saveOnChange: true
    },
    ['yes/no']: {
      component: RadioGroup,
      props: {
        options: [
          { key: 'yes', value: 'Yes' },
          { key: 'no', value: 'No' }
        ],
        orientation: 'vertical'
      },
      saveOnChange: true
    }
  }

  let field = fieldsLookup[type]

  // Use a plain text input if there isn't a matching input type
  if (!field) {
    // eslint-disable-next-line no-console
    console.warn(`Missing input field component for "${type}" input type.`)

    field = fieldsLookup['default']
  }

  const FieldComponent = field.component
  const fieldProps = field.props

  /**
   * On an input value change, update the value in state, but do
   * not save the value to the db unless its specified by the
   * input type (checkbox, radio, select)
   * @param event
   */
  const handleChange = (event) => {
    if (type === 'checkbox') {
      const stringChecked = event.target.checked.toString()
      setCurrentValue(stringChecked)
      onSave(stringChecked)
    } else if (type === 'datepicker') {
      setCurrentValue(event)
      onSave(event)
    } else if (type !== 'address_history' && type !== 'employment_history') {
      const stringValue = event.target.value.toString()
      setCurrentValue(stringValue)
      if (field.saveOnChange) {
        onSave(stringValue)
      }
    }
  }

  /**
   * On an input field blur, save the value to the db, but only
   * if its different than the currently saved value
   * @param {event | string}
   */
  const handleBlur = (event: React.ChangeEvent<HTMLInputElement> | string) => {
    let stringValue: string
    if (typeof event === 'string') {
      stringValue = event
    } else {
      stringValue = event?.target?.value?.toString()
    }

    const initialStringValue = value ? value.toString() : ''
    if (!field.saveOnChange && stringValue !== initialStringValue) {
      onSave(stringValue)
    }
  }

  return (
    <FieldComponent
      disabled={disabled}
      id={id}
      name={name}
      onBlurEvent={handleBlur}
      onChangeEvent={handleChange}
      onPlacesUpdate={onPlacesUpdate}
      placeholder={placeholder}
      validators={validators}
      value={currentValue}
      isMetaQuestion={isMetaQuestion}
      {...fieldProps}
    />
  )
}

export default InputTransformer as FunctionComponent<Props>
