import React, { FunctionComponent } from 'react'
import Input from '../input'

import { phoneMaskForCountry } from 'lib/input_mask'

import { QuestionnaireInputContainerProps } from '../../lib/types'

type Props = QuestionnaireInputContainerProps & {
  isoCountryCode: Nullable<string>
}

// http://www.fileformat.info/info/unicode/char/2007/index.htm
const MASK_CHAR = '\u2007'

const PhoneNumberInput: FunctionComponent<Props> = ({
  className,
  disabled,
  id,
  isoCountryCode,
  name,
  placeholder,
  prefix,
  onBlurEvent,
  onChangeEvent,
  value
}) => {
  const numberMask = () => {
    return phoneMaskForCountry(isoCountryCode, {
      maskChar: MASK_CHAR
    })
  }

  const getPlaceholder = () => {
    let mask = numberMask()
    return mask ? mask.replace(/\d/g, MASK_CHAR) : placeholder
  }

  const permanentMaskChars = () => {
    const mask = numberMask()

    if (!mask) return []

    const indexes = []

    for (let i = 0; i < mask.length; i++) {
      if (mask[i] !== '9') {
        indexes.push(i)
      }
    }

    return indexes
  }

  return (
    <Input
      autoComplete="off"
      className={className}
      disabled={disabled}
      id={`${id}-number`}
      mask={numberMask()}
      maskChar={MASK_CHAR}
      name={name}
      onBlurEvent={onBlurEvent}
      onChangeEvent={onChangeEvent}
      permanents={permanentMaskChars()}
      placeholder={getPlaceholder()}
      prefix={prefix}
      type="tel"
      value={value}
    />
  )
}

export default PhoneNumberInput
