import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'
import { isCurrentKasePaid, getCurrentKaseKind, isKaseBeingRefunded } from 'reducers/selectors'

import PrePaymentDashboard from 'components/screens/dashboard/pre_payment_dashboard'
import DocumentUpload from 'components/screens/document_upload'
import DocumentUploadV2 from 'components/screens/document_upload_v2'
import RefundingScreen from 'components/screens/refunding_screen'
import _includes from 'lodash/includes'

interface Props {
  isCurrentKasePaid: boolean
  currentKaseKind: 'Naturalization' | 'MarriageBasedGreenCard' | 'NewMarriageBasedGreenCard' | 'K1FianceVisa'
  isKaseBeingRefunded: boolean
}

const DocumentUploadRouter: FunctionComponent<Props> = ({
  isCurrentKasePaid,
  currentKaseKind,
  isKaseBeingRefunded
}) => {
  if (isKaseBeingRefunded) {
    return <RefundingScreen />
  }

  if (isCurrentKasePaid) {
    if (_includes(['NewMarriageBasedGreenCard', 'K1FianceVisa'], currentKaseKind)) {
      return <DocumentUploadV2 />
    } else {
      return <DocumentUpload />
    }
  }

  return <PrePaymentDashboard />
}

function mapStateToProps(state) {
  return {
    isKaseBeingRefunded: isKaseBeingRefunded(state),
    isCurrentKasePaid: isCurrentKasePaid(state),
    currentKaseKind: getCurrentKaseKind(state)
  }
}

export default connect(mapStateToProps, null)(DocumentUploadRouter)
