import React, { FunctionComponent } from 'react'

import ModalWithHeader from 'components/modals/modal/modal_with_header'
import Paragraph from 'components/type/paragraph'

interface Props {
  isOpen: boolean
  closeModal: () => void
}

const ProcessModal: FunctionComponent<Props> = ({ isOpen, closeModal }) => {
  return (
    <ModalWithHeader
      isOpen={isOpen}
      header="Uploading Your Supporting Documents"
      onRequestClose={closeModal}
      modalWidth="35rem"
      body={
        <ol style={{ padding: '2.5rem' }}>
          <li>
            <Paragraph bold spacing="sm">
              The Purpose of Supporting Documents
            </Paragraph>
            <Paragraph spacing="xl">
              Supporting documents are required by the U.S. government, and help them verify the accuracy of the
              information you provided in the application forms. Missing or incorrect documents may slow down your
              application or even cause rejections, denials or requests for evidence (RFE).
            </Paragraph>
          </li>
          <li>
            <Paragraph bold spacing="sm">
              How to Upload Your Documents
            </Paragraph>
            <Paragraph spacing="xl">
              For each supporting document, please carefully read its description for step-by-step guidance on what the
              document is and what you need to upload. Once you’ve uploaded documents to each of the document sections,
              you can submit them for review. We won’t see your documents until they’ve been submitted. If you need
              help, please read our Tips For Success or I’m Missing Documents resources, or call or chat our team.
            </Paragraph>
          </li>
        </ol>
      }
    />
  )
}

export default ProcessModal
