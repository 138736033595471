import React, { FunctionComponent } from 'react'
import IconWrapper, { IconWrapperExternalProps } from './icon_wrapper'

const AlertCircleIcon: FunctionComponent<IconWrapperExternalProps> = (props) => (
  <IconWrapper {...props} viewBox="0 0 32 32">
    <path d="M16,32 C7.1784,32 0,24.82288 0,16.0008 C0,7.17872 7.1784,0 16,0 C24.8216,0 32,7.17872 32,16.0008 C32,24.82288 24.8216,32 16,32 Z M16,30.0016 C23.7184,30.0016 30,23.71792 30,16.0008 C30,8.28208 23.7184,1.9984 16,1.9984 C8.2816,1.9984 2,8.28208 2,16.0008 C2,23.71792 8.2816,30.0016 16,30.0016 Z M14.8,18.00032 L14.8,7.99968 L17.1984,7.99968 L17.1984,18.00032 L14.8,18.00032 Z M14.8,23.50048 L14.8,20.69872 L17.1984,20.69872 L17.1984,23.50048 L14.8,23.50048 Z" />
  </IconWrapper>
)

export default AlertCircleIcon
