import React from 'react'
import { connect } from 'react-redux'

import getTelemetryService from './telemetry'

import { getCurrentPanelSlug } from 'reducers/selectors'
import { ApplicationState } from 'reducers'

interface LinkRendererProps {
  children: React.ReactNode[]
  currentPanelSlug?: string
  href: string
  linkClasses?: string
}

class MarkdownLink extends React.Component<LinkRendererProps> {
  handleClick = (event: React.MouseEvent<HTMLAnchorElement>): void => {
    event.preventDefault()

    const { currentPanelSlug, href } = this.props

    getTelemetryService().trackLink({
      linkName: 'ContextualHelpLink',
      redirectTo: href,
      newTab: true,
      eventData: {
        LinkDestination: href,
        PanelSlug: currentPanelSlug
      }
    })
  }

  render() {
    const { children, href, linkClasses } = this.props

    return (
      <a href={href} target="_blank" className={linkClasses} rel="noopener noreferrer" onClick={this.handleClick}>
        {children}
      </a>
    )
  }
}

function mapStateToProps(state: ApplicationState) {
  return {
    currentPanelSlug: getCurrentPanelSlug(state)
  }
}

const WrappedLinkRenderer = ({ linkClasses }) => {
  const WrapperComponent = (props) => <MarkdownLink linkClasses={linkClasses} {...props} />

  return WrapperComponent
}

export const MarkdownLinkRenderer = connect(mapStateToProps)(MarkdownLink)
export const MarkdownLinkRendererUnderline = connect(mapStateToProps)(
  WrappedLinkRenderer({ linkClasses: 'c-link c-link--standout' })
)
