import server from 'lib/api/v1/server'
import { AxiosPromise } from 'axios'
import { Issue, Message } from 'reducers/issues'
import { formatKasesUrl, formatPostRequestBody, normalizeResponse } from 'lib/api/v1/helpers'

const formatIssuesUrl = (kaseId, path) => {
  const baseUrl = formatKasesUrl(kaseId, 'issues')
  return `${baseUrl}/${path}`
}

function index(request): AxiosPromise<Issue[]> {
  const { kaseId } = request

  return server
    .get(formatIssuesUrl(kaseId, ''))
    .then((response) => normalizeResponse(response))
    .catch((
      error // eslint-disable-next-line no-console
    ) => console.log('Error loading issues', error))
}

function saveMessage(request): AxiosPromise<Message[]> {
  const { kaseId, issueId, text } = request
  const requestBody = formatPostRequestBody('messages', { text })

  return server
    .post(formatIssuesUrl(kaseId, `${issueId}/messages`), requestBody)
    .then((response) => normalizeResponse(response))
    .catch((
      error // eslint-disable-next-line no-console
    ) => console.error(error))
}

export default {
  index,
  saveMessage
}
