import React, { FunctionComponent } from 'react'
import { DashboardPhase, getDashboardPhaseData } from './phase_map'
import { externalUrl } from 'lib/settings'
import ResourcesIcon from 'components/icons/resources_icon'
import ExternalLink from 'components/external_link'
import Paragraph from 'components/type/paragraph'

interface Props {
  currentPhase: DashboardPhase
}

const ResourcesPanel: FunctionComponent<Props> = ({ currentPhase }) => {
  const phaseDataResources = getDashboardPhaseData(currentPhase).resources

  if (!phaseDataResources) {
    return null
  }

  return (
    <div className="o-griddle__col--12 o-griddle__col--md-6 o-griddle__col--lg-12 o-block o-block--spacious">
      <aside className="c-dashboard__panel">
        <header className="o-layout--flex o-layout--vertical-center o-layout--space-between o-block o-block--tight">
          <span className="c-type c-type--headline-sm c-type--emphasized">Resources & FAQ</span>
          <Paragraph>
            <ResourcesIcon className="mr-2 c-dashboard__icon c-dashboard__icon--muted c-icon--xsm" />
            <ExternalLink href={externalUrl('/immigration-resources')}>See All Resources</ExternalLink>
          </Paragraph>
        </header>
        <hr className="c-divider o-block" />
        <div>
          <ul className="c-list">
            {phaseDataResources.map((resource, index) => {
              return (
                <li
                  key={`dashboard-resource-${index}`}
                  className="o-block o-block--compact c-type c-type--body-sans-md"
                >
                  <ExternalLink href={externalUrl(resource.urlPath)}>{resource.title}</ExternalLink>
                </li>
              )
            })}
          </ul>
        </div>
      </aside>
    </div>
  )
}

export default ResourcesPanel
