import moment from 'moment'

import { buildMessage, Validator } from './'

function parseDate(date: string) {
  return moment(date, 'YYYY/MM/DD')
}

const MIN_VALID_DATE = parseDate('1800/01/01')

const validDate: Validator = ({ getModelData, path }) => {
  const date = getModelData(path)

  if (!date) return []

  const parsedDate = parseDate(date)

  const errors = []

  if (!parsedDate.isValid()) {
    errors.push(buildMessage('Please enter a valid date'))
  } else if (parsedDate < MIN_VALID_DATE) {
    errors.push(buildMessage(`Please enter a date after ${MIN_VALID_DATE.format('l')}`))
  }

  return errors
}

export default validDate
