import Moment from 'moment'
import { extendMoment } from 'moment-range'
import Decorator from 'lib/decorator'
import _get from 'lodash/get'
import _cloneDeep from 'lodash/cloneDeep'
import { isPresent } from 'lib/presence'

// TODO: Fix TS warning https://www.pivotaltracker.com/story/show/176301977
const moment = extendMoment(Moment)

interface SharedConditionFunctionArgs {
  args: {
    [key: string]: any
  }
  modelData: Decorator
  pathIndices?: number[]
}

interface SharedConditionFunction {
  (conditionArgs: SharedConditionFunctionArgs): boolean
}

interface SharedConditionFunctions {
  [name: string]: SharedConditionFunction
}

// Takes a path with an index placeholder and substitutes in the correct
// indices:
//
//   addIndexes('my.marriages.$.addresses.$', [0, 5])
//
//   # => 'my.marriages.0.addresses.5'
function addIndexes(path: string, pathIndices: number[]) {
  let currentIndex = 0

  return path
    .split('.')
    .map((part) => {
      if (part === '$') {
        const pathIndex = pathIndices[currentIndex]
        currentIndex++

        return pathIndex
      }

      return part
    })
    .join('.')
}

// conditions
export const sharedConditions: SharedConditionFunctions = {}

function criminalIntentNeededByKey() {
  return {
    break_law: 'AOS',
    break_law_controlled_substances: 'CP',
    caused_injury: 'AOS',
    child_custody: 'ALL',
    claimed_diplomatic_immunity: 'AOS',
    colombia_revolutionary: 'CP',
    communist_or_totalitarian: 'ALL',
    criminal_not_arrested: 'ALL',
    espionage: 'ALL',
    false_claim_us_citizenship: 'AOS',
    forced_abortion_sterilization: 'CP',
    gambling: 'ALL',
    genocide_or_torture: 'ALL',
    groups_to_cause_injury: 'AOS',
    illegal_entry: 'AOS',
    illegal_entry_aid: 'ALL',
    illegal_stay: 'ALL',
    illegal_vote: 'ALL',
    illegal_work: 'AOS',
    illegally_take_property: 'CP',
    import_controlled_substances: 'AOS',
    ineligible_for_citizenship: 'CP',
    law_enforcement_pardon: 'ALL',
    left_to_avoid_army: 'ALL',
    mental: 'CP',
    money_laundering: 'CP',
    murder: 'ALL',
    national_security: 'AOS',
    nazi: 'ALL',
    ordered_to_leave: 'CP',
    overthrow: 'ALL',
    polygamy: 'ALL',
    prevent_religious_freedom: 'ALL',
    prostitution: 'ALL',
    provided_false_info_to_us: 'ALL',
    rape: 'AOS',
    recruitment_of_children: 'CP',
    revoke_citizenship: 'AOS',
    revoke_citizenship_tax_evasion: 'CP',
    drug_trafficking: 'ALL',
    human_trafficking: 'ALL',
    sexual_trafficking: 'ALL',
    sold_to_groups_to_cause_injury: 'AOS',
    terrorism: 'ALL',
    tuition_fraud: 'CP',
    visa_overstay: 'ALL'
  }
}

sharedConditions.computedWorkflowCategoryNeedsKey = ({ modelData, pathIndices }) => {
  const value = _get(modelData, 'computed_workflow_category')
  const category = criminalIntentNeededByKey()[pathIndices[0]]

  return category === 'ALL' || category === value
}

sharedConditions.isDateRangeTooShort = ({ modelData, args, pathIndices }) => {
  const value = _get(modelData, addIndexes(args.path, pathIndices))
  if (!value.start_date || !value.end_date || !value.end_date.date) return true

  const range = moment.range(value.start_date, value.end_date.date)
  return range.diff('years') < 1
}

sharedConditions.pathEquals = ({ modelData, args, pathIndices }) => {
  const value = _get(modelData, addIndexes(args.path, pathIndices))

  return value === args.value
}

sharedConditions.pathDoesNotEqual = ({ modelData, args, pathIndices }) => {
  const value = _get(modelData, addIndexes(args.path, pathIndices))

  return value !== args.value
}

sharedConditions.pathGreaterThan = ({ modelData, args, pathIndices }) => {
  const { path, value } = args
  const valueAtPath = _get(modelData, addIndexes(path, pathIndices))

  if (!valueAtPath || isNaN(valueAtPath)) return false

  return valueAtPath > value
}

sharedConditions.isPresent = ({ modelData, args, pathIndices }) => {
  const value = _get(modelData, addIndexes(args.path, pathIndices))

  return isPresent(value)
}

sharedConditions.isTruthy = ({ modelData, args, pathIndices }) => {
  const value = _get(modelData, addIndexes(args.path, pathIndices))

  return Boolean(value)
}

sharedConditions.isFalse = ({ modelData, args, pathIndices }) => {
  const value = _get(modelData, addIndexes(args.path, pathIndices))

  return value === false
}

sharedConditions.isFalsey = ({ modelData, args, pathIndices }) => {
  return !sharedConditions.isTruthy({ modelData, args, pathIndices })
}

sharedConditions.isQualifierYes = ({ modelData, args, pathIndices }) => {
  const value = _get(modelData, addIndexes(args.path, pathIndices))

  return value === 'yes'
}

sharedConditions.isQualifierNo = ({ modelData, args, pathIndices }) => {
  const value = _get(modelData, addIndexes(args.path, pathIndices))

  return value === 'no'
}

// TODO: this is only to support filing_in_illinois conditional requirement,
//  delete when no longer needed for Illinois residents
sharedConditions.isQualifierNoOrNull = ({ modelData, args, pathIndices }) => {
  const value = _get(modelData, addIndexes(args.path, pathIndices))

  return !isPresent(value) || value === 'no'
}

sharedConditions.isNeededInSmartCollection = ({ modelData, args, pathIndices }) => {
  const _pathIndicesCopy = _cloneDeep(pathIndices)

  const memberIndex = _pathIndicesCopy.pop()
  const collectionPath = addIndexes(args.path, _pathIndicesCopy)

  const items = _get(modelData, `${collectionPath}.items`)

  return memberIndex < (items || []).length
}

sharedConditions.isFirstInSmartCollection = (args) => {
  const _pathIndicesCopy = _cloneDeep(args.pathIndices)
  const memberIndex = _pathIndicesCopy.pop()

  // TODO: Fix the underlying cause of why memberIndex 'could' be a string
  // during runtime; https://www.pivotaltracker.com/story/show/176301875
  return parseInt(memberIndex) === 0
}
