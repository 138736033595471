import { ShouldSkipCollection } from './'

const skipFunctions: ShouldSkipCollection = {
  other_nationality: [
    {
      functionName: 'hasNotAnsweredPrimaryOrPreviousNationality'
    }
  ],
  marital_status: 'marriedToUSCitizen',
  address_clarify: [
    {
      functionName: 'addressIsUnavailableAt',
      args: {
        path: 'applicant.addresses.$'
      }
    },
    {
      functionName: 'clarificationNotRequiredFor',
      args: {
        path: 'applicant.addresses.$'
      }
    }
  ],
  address_start: [
    {
      functionName: 'addressIsIncompleteAt',
      args: {
        path: 'applicant.addresses.$'
      }
    }
  ],
  mailing_address: [
    {
      functionName: 'addressIsIncompleteAt',
      args: {
        path: 'applicant.current_address'
      }
    }
  ],
  mailing_address_details: [
    {
      functionName: 'addressIsIncompleteAt',
      args: {
        path: 'applicant.current_address'
      }
    },
    {
      functionName: 'mailingAddressSameAsCurrentAddress'
    }
  ],
  mailing_address_clarify: [
    {
      functionName: 'addressIsIncompleteAt',
      args: {
        path: 'applicant.current_address'
      }
    },
    {
      functionName: 'mailingAddressSameAsCurrentAddress'
    },
    {
      functionName: 'clarificationNotRequiredFor',
      args: {
        path: 'applicant.mailing_address'
      }
    }
  ],
  mailing_address_co: [
    {
      functionName: 'addressIsIncompleteAt',
      args: {
        path: 'applicant.current_address'
      }
    },
    {
      functionName: 'mailingAddressSameAsCurrentAddress'
    },
    {
      functionName: 'addressIsIncompleteAt',
      args: {
        path: 'applicant.mailing_address'
      }
    }
  ],
  residency_in_state_prompt: [
    {
      functionName: 'applicantDoesNotHaveResidencyIssues'
    },
    {
      functionName: 'applicantNeedsResidencyFlag'
    }
  ],
  previous_marriages: 'missingCurrentSpouseName',
  spouse_birth_place: 'missingCurrentSpouseName',
  spouse_live_together: 'missingCurrentSpouseName',
  spouse_current_employer: 'missingCurrentSpouseName',
  spouse_address: [
    {
      functionName: 'missingCurrentSpouseName'
    },
    {
      functionName: 'currentlyLiveTogether'
    }
  ],
  spouse_legal_status: 'missingCurrentSpouseName',
  spouse_military: 'missingCurrentSpouseName',
  spouse_previous_marriages: 'missingCurrentSpouseName',
  spouse_address_clarify: [
    {
      functionName: 'currentlyLiveTogether'
    },
    {
      functionName: 'clarificationNotRequiredFor',
      args: {
        path: 'applicant.current_marriage.spouse.address'
      }
    }
  ],
  spouse_previous_name_legal: [
    {
      functionName: 'nameIsNotPresentAt',
      args: {
        path: 'applicant.current_marriage.spouse.previous_names.$'
      }
    }
  ],
  spouse_citizenship_start_non_marriage_basis: 'spouseNotUSCitizen',
  spouse_nationality: 'spouseNotLPR',
  spouse_legal_status_details: 'spouseNotOtherStatus',
  previous_marriages_not_currently_married: 'isMarried',
  previous_spouse_birth_place: [
    {
      functionName: 'nameIsNotPresentAt',
      args: {
        path: 'applicant.previous_marriages.$.spouse.name'
      }
    },
    {
      functionName: 'isTheSamePersonAsCurrent',
      args: {
        path: 'applicant.previous_marriages.$.spouse',
        currentPath: 'applicant.current_marriage.spouse'
      }
    }
  ],
  previous_spouse_legal_status: [
    {
      functionName: 'nameIsNotPresentAt',
      args: {
        path: 'applicant.previous_marriages.$.spouse.name'
      }
    }
  ],
  previous_spouse_nationality: [
    {
      functionName: 'nameIsNotPresentAt',
      args: {
        path: 'applicant.previous_marriages.$.spouse.name'
      }
    },
    {
      functionName: 'previousPersonStatusIsNotTheGivenCode',
      args: {
        path: 'applicant.previous_marriages.$.spouse',
        code: 'lpr'
      }
    }
  ],
  previous_spouse_legal_status_details: [
    {
      functionName: 'nameIsNotPresentAt',
      args: {
        path: 'applicant.previous_marriages.$.spouse.name'
      }
    },
    {
      functionName: 'previousPersonStatusIsNotTheGivenCode',
      args: {
        path: 'applicant.previous_marriages.$.spouse',
        code: 'other'
      }
    }
  ],
  spouse_previous_spouse_birth_date: [
    {
      functionName: 'nameIsNotPresentAt',
      args: {
        path: 'applicant.current_marriage.spouse.previous_marriages.$.spouse.name'
      }
    },
    {
      functionName: 'isTheSamePersonAsCurrent',
      args: {
        path: 'applicant.current_marriage.spouse.previous_marriages.$.spouse',
        currentPath: 'applicant'
      }
    }
  ],
  spouse_previous_spouse_birth_place: [
    {
      functionName: 'nameIsNotPresentAt',
      args: {
        path: 'applicant.current_marriage.spouse.previous_marriages.$.spouse.name'
      }
    },
    {
      functionName: 'isTheSamePersonAsCurrent',
      args: {
        path: 'applicant.current_marriage.spouse.previous_marriages.$.spouse',
        currentPath: 'applicant'
      }
    }
  ],
  spouse_previous_spouse_legal_status: [
    {
      functionName: 'nameIsNotPresentAt',
      args: {
        path: 'applicant.current_marriage.spouse.previous_marriages.$.spouse.name'
      }
    },
    {
      functionName: 'isTheSamePersonAsCurrent',
      args: {
        path: 'applicant.current_marriage.spouse.previous_marriages.$.spouse',
        currentPath: 'applicant'
      }
    }
  ],
  spouse_previous_spouse_nationality: [
    {
      functionName: 'nameIsNotPresentAt',
      args: {
        path: 'applicant.current_marriage.spouse.previous_marriages.$.spouse.name'
      }
    },
    {
      functionName: 'isTheSamePersonAsCurrent',
      args: {
        path: 'applicant.current_marriage.spouse.previous_marriages.$.spouse',
        currentPath: 'applicant'
      }
    },
    {
      functionName: 'previousPersonStatusIsNotTheGivenCode',
      args: {
        path: 'applicant.current_marriage.spouse.previous_marriages.$.spouse',
        code: 'lpr'
      }
    }
  ],
  spouse_previous_spouse_legal_status_details: [
    {
      functionName: 'nameIsNotPresentAt',
      args: {
        path: 'applicant.current_marriage.spouse.previous_marriages.$.spouse.name'
      }
    },
    {
      functionName: 'isTheSamePersonAsCurrent',
      args: {
        path: 'applicant.current_marriage.spouse.previous_marriages.$.spouse',
        currentPath: 'applicant'
      }
    },
    {
      functionName: 'previousPersonStatusIsNotTheGivenCode',
      args: {
        path: 'applicant.current_marriage.spouse.previous_marriages.$.spouse',
        code: 'other'
      }
    }
  ],
  child_birth_date: [
    {
      functionName: 'nameIsNotPresentAt',
      args: {
        path: 'applicant.family.children.$.name'
      }
    }
  ],
  child_birth_place: [
    {
      functionName: 'nameIsNotPresentAt',
      args: {
        path: 'applicant.family.children.$.name'
      }
    }
  ],
  child_relationship: [
    {
      functionName: 'nameIsNotPresentAt',
      args: {
        path: 'applicant.family.children.$.name'
      }
    }
  ],
  child_address_young: [
    {
      functionName: 'nameIsNotPresentAt',
      args: {
        path: 'applicant.family.children.$.name'
      }
    }
  ],
  child_address: [
    {
      functionName: 'nameIsNotPresentAt',
      args: {
        path: 'applicant.family.children.$.name'
      }
    },
    {
      functionName: 'childCurrentlyLivesWithApplicant'
    }
  ],
  child_address_clarify: [
    {
      functionName: 'nameIsNotPresentAt',
      args: {
        path: 'applicant.family.children.$.name'
      }
    },
    {
      functionName: 'childCurrentlyLivesWithApplicant'
    },
    {
      functionName: 'clarificationNotRequiredFor',
      args: {
        path: 'applicant.family.children.$.current_address'
      }
    }
  ],
  child_arn: [
    {
      functionName: 'nameIsNotPresentAt',
      args: {
        path: 'applicant.family.children.$.name'
      }
    }
  ],
  current_employment: [
    {
      functionName: 'rawNameIsNotPresentAt',
      args: {
        path: 'applicant.employments.0'
      }
    }
  ],
  current_employer_address: [
    {
      functionName: 'rawNameIsNotPresentAt',
      args: {
        path: 'applicant.employments.0'
      }
    }
  ],
  current_employer_address_clarify: [
    {
      functionName: 'rawNameIsNotPresentAt',
      args: {
        path: 'applicant.employments.0'
      }
    },
    {
      functionName: 'clarificationNotRequiredFor',
      args: {
        path: 'applicant.employments.0.address'
      }
    }
  ],
  previous_employment: [
    {
      functionName: 'rawNameIsNotPresentAt',
      args: {
        path: 'applicant.employments.$'
      }
    }
  ],
  previous_employer_address: [
    {
      functionName: 'rawNameIsNotPresentAt',
      args: {
        path: 'applicant.employments.$'
      }
    }
  ],
  previous_employer_address_clarify: [
    {
      functionName: 'rawNameIsNotPresentAt',
      args: {
        path: 'applicant.employments.$'
      }
    },
    {
      functionName: 'clarificationNotRequiredFor',
      args: {
        path: 'applicant.employments.$.address'
      }
    }
  ],
  school_address: [
    {
      functionName: 'rawNameIsNotPresentAt',
      args: {
        path: 'applicant.education.schools.$'
      }
    }
  ],
  school_address_clarify: [
    {
      functionName: 'rawNameIsNotPresentAt',
      args: {
        path: 'applicant.education.schools.$'
      }
    },
    {
      functionName: 'clarificationNotRequiredFor',
      args: {
        path: 'applicant.education.schools.$.address'
      }
    }
  ],
  selective_service: 'doesNotQualifyForSelectiveService',
  selective_service_details: [
    {
      functionName: 'doesNotQualifyForSelectiveService'
    },
    {
      functionName: 'didNotRegisterForSelectiveService'
    }
  ],
  selective_service_prompt: [
    {
      functionName: 'doesNotQualifyForSelectiveService'
    },
    {
      functionName: 'registeredForSelectiveService'
    },
    {
      functionName: 'applicantIs26OrOlder'
    }
  ],
  status_information_letter: [
    {
      functionName: 'doesNotQualifyForSelectiveService'
    },
    {
      functionName: 'registeredForSelectiveService'
    },
    {
      functionName: 'applicantIsYoungerThan26'
    }
  ],
  applicant_disability_accommodation_details: 'applicantDoesNotHaveDisabilities'
}

export default skipFunctions
