import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'

import contextualHelpForPanel from '../lib/contextual_help_for_panel'
import { getCurrentPanel } from 'reducers/selectors'
import { toggleContextualHelp } from '../actions/help_actions'
import HelpIcon from 'components/icons/help_icon'

interface Props {
  help?: any
  toggleContextualHelp: (args: any) => void
  text: string
}

export const InlineHelp: FunctionComponent<Props> = (props) => {
  if (!props.help) {
    return <span>{props.text}</span>
  }

  function onClick(event) {
    event.preventDefault()
    props.toggleContextualHelp({ via: 'InlineHelp' })
    document.activeElement.blur()
  }

  return (
    <a data-qa="inline-help-button" href="#" style={{ display: 'inline' }} onClick={onClick} title="Get help">
      {props.text}
      <HelpIcon />
    </a>
  )
}

function mapStateToProps(state) {
  return {
    help: contextualHelpForPanel(getCurrentPanel(state))
  }
}

function mapDispatchToActions(dispatch) {
  return {
    toggleContextualHelp: (...args) => dispatch(toggleContextualHelp(...args))
  }
}

export default connect(mapStateToProps, mapDispatchToActions)(InlineHelp)
