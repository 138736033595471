import React, { FunctionComponent } from 'react'

import Paragraph from 'components/type/paragraph'
import UnassignTaskButton from 'components/screens/issues/unassign_task_button'
import { allIssuesAddressed, canSubmitTask, getCurrentOpenQaTask } from 'reducers/selectors'
import { connect } from 'react-redux'
import { Task } from 'reducers/tasks'
import NewMessagesHint from 'components/screens/issues/new_messages_hint'

interface Props {
  allIssuesAddressed: boolean
  canSubmitTask: boolean
  currentOpenTask: Task
}

export const IssuesHeader: FunctionComponent<Props> = ({ allIssuesAddressed, canSubmitTask, currentOpenTask }) => {
  const isReturn = currentOpenTask?.attributes.metadata?.number_of_returns > 0 && !allIssuesAddressed

  if (!canSubmitTask) {
    return null
  }

  return (
    <>
      {isReturn && <NewMessagesHint />}
      <Paragraph color="emphasized" bold>
        Instructions
      </Paragraph>
      <Paragraph>
        Please carefully read each question or task, follow the instructions, and{' '}
        <span className="font-bold">reply to all items before submitting them to Boundless</span> — otherwise, we won’t
        see your responses. Even if you can’t address a certain item, please type a reply to let us know. If your task
        is to upload a document or update your petition, make sure to come back here and add a reply.
      </Paragraph>
      <UnassignTaskButton />
    </>
  )
}

function mapStateToProps(state) {
  return {
    allIssuesAddressed: allIssuesAddressed(state),
    canSubmitTask: canSubmitTask(state),
    currentOpenTask: getCurrentOpenQaTask(state)
  }
}

export default connect(mapStateToProps)(IssuesHeader)
