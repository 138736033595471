import React, { FunctionComponent, ReactNode } from 'react'
import cx from 'classnames'

import ChevronIcon from 'components/icons/chevron_icon'

interface Props {
  /**
   * The class names that will be applied to the outermost container
   */
  className?: string
  /**
   * Decides if you can click on it and activate onClick
   */
  disabled?: boolean
  /**
   * Controls whether or not there will be a blue outline around the summary when it's clicked
   * default = true
   */
  hasSummaryOutline?: boolean
  /**
   * The header text of the accordion
   */
  header: ReactNode
  /**
   * The id of the accordion
   */
  id?: string
  /**
   * Controls whether this accordion is open
   */
  isOpen: boolean
  /**
   * Controls whether the header text section will have horizontal padding or not
   */
  noXPadding?: boolean
  /**
   * The callback function for when the header is clicked
   */
  onClicked: () => void
  /**
   * Whether or not to show the chevron
   * default = true
   */
  showChevron?: boolean
}

/**
 * This is different from Accordion in that isOpen and onClicked are controlled by the parent component
 * Example of use case is in the PaymentPanel component
 */
const ControlledAccordion: FunctionComponent<Props> = ({
  className,
  disabled,
  hasSummaryOutline = true,
  header,
  id,
  isOpen,
  noXPadding,
  onClicked,
  showChevron = true,
  children
}) => {
  // Keep track of the open/closed status of the <details>
  // It's a little weird but hey https://github.com/facebook/react/issues/15486
  const toggleAccordion = (event) => {
    event.preventDefault()
    if (!disabled) {
      onClicked()
    }
  }

  const containerClassName = cx('border', 'border-gray-200', {
    'c-accordion--new': hasSummaryOutline,
    'c-accordion--new--no-outline': !hasSummaryOutline,
    [className]: !!className
  })

  const innerClassNames = cx('flex items-center u-clickable', {
    'p-4': !noXPadding,
    'py-4': noXPadding,
    'u-clickable': !disabled,
    'u-not-clickable': disabled
  })

  /**
   * "fun" fact: there's a bug in Safari that prevents <summary> elements from
   * acting as flex containers. Therefore, we nest the content of the <summary>
   * within a div for proper styling. I love browsers.
   * https://bugs.webkit.org/show_bug.cgi?id=190065
   */
  return (
    <details className={containerClassName} id={id} open={isOpen}>
      <summary onClick={toggleAccordion}>
        <div className={innerClassNames}>
          <span className="flex-auto c-type--body-sans-md">{header}</span>
          {showChevron && (
            <span className="flex-initial">
              <ChevronIcon chevronDirection={isOpen ? 'up' : 'down'} />
            </span>
          )}
        </div>
      </summary>
      <div>{children}</div>
    </details>
  )
}

export default ControlledAccordion
