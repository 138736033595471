import React, { FunctionComponent } from 'react'

import { joinI18nKeys } from 'lib/i18n'
import { QuestionnaireInputContainerProps } from 'components/forms/inputs'
import panelLocaleService from 'services/panel_locale_service'
import { choiceToString } from 'components/forms/inputs/radio/radio_input'
import { getAfterChangeHooks } from 'components/forms/field_after_change_events'
import ValidatedInput from 'components/forms/validated_input'
import PaperFormSelectInput from 'components/forms/inputs/paper_form_select/paper_form_select_input'

type Props = QuestionnaireInputContainerProps & {
  label?: string
  choices: []
}

const PaperFormSelect: FunctionComponent<Props> = (props) => {
  const { disabled, name, path, after_change_event_list, id, resourceKeys, choices } = props

  const afterChangeEvents = getAfterChangeHooks(after_change_event_list) || []

  const getLabel = () => {
    if (!name) return null

    const key = joinI18nKeys(resourceKeys, `${name}.label`)
    if (!panelLocaleService.exists(key)) {
      return null
    }

    return panelLocaleService.t(key)
  }

  const getChoiceLabel = (choice) => {
    // Do not translate the choice if it's a number
    if (isNaN(parseInt(choice, 10))) {
      return panelLocaleService.t(joinI18nKeys(resourceKeys, `${name}.${choice}`))
    } else {
      return choice
    }
  }

  const getOptions = () => {
    const options = [
      {
        value: '',
        label: `Select`
      }
    ]

    const mappedOptions = choices.map((choice) => ({
      value: choiceToString(choice),
      label: getChoiceLabel(choice)
    }))

    options.push(...mappedOptions)

    return options
  }

  return (
    <div className="o-flag o-grid--fluid o-grid--stack@sm o-block c-paper-form__group">
      <label className="o-grid__cell--3/12 o-grid__cell--off@sm c-paper-form__label c-paper-form__segment" htmlFor={id}>
        <span className="c-type c-type--subhead-sm">{getLabel()}</span>
      </label>

      <div className="o-grid__cell--9/12 o-grid__cell--off@sm c-paper-form__segment self-end">
        <ValidatedInput disabled={disabled} path={path}>
          {({ fireValidation, renderValidations }) => (
            <>
              <PaperFormSelectInput
                className={`c-paper-form__control`}
                disabled={disabled}
                id={id}
                name={name}
                onBlur={fireValidation}
                type="select"
                options={getOptions()}
                path={path}
                afterChangeEvents={afterChangeEvents}
              />
              {renderValidations()}
            </>
          )}
        </ValidatedInput>
      </div>
    </div>
  )
}

export default PaperFormSelect
