import _forEach from 'lodash/forEach'
import _includes from 'lodash/includes'
import fuzzysort from 'fuzzysort'

export function scoreResultFunc(pinnedValues) {
  const pinnedOptions = pinnedValues
  return function (result) {
    let max = -10000000
    let score: number

    _forEach(result, (item) => {
      if (item) {
        score = item.score
        const itemName = item.target

        if (_includes(pinnedOptions, itemName)) {
          score /= 100
        }

        if (score > max) {
          max = score
        }
      }
    })

    return max
  }
}

export function getCodeFromName(typeAheadValues: object): Function {
  const values = typeAheadValues
  return function (name: string) {
    if (!name) return null

    for (let code in values) {
      if (name.toLowerCase() === values[code].toLowerCase()) {
        return code
      }
    }

    return null
  }
}

interface MatchReqs {
  pinnedOptions: string[]
  targets: []
  text: string
}

export function getMatches({ pinnedOptions, text, targets }: MatchReqs): string[] {
  const searchOptions = {
    limit: 25,
    keys: ['name'],
    threshold: -10000,
    scoreFn: scoreResultFunc(pinnedOptions)
  }

  const results = fuzzysort.go(text, targets, searchOptions)

  return results.map((result) => result.obj.code)
}

export function getNameFromCode(typeAheadValues: object): Function {
  const values = typeAheadValues
  return function (code: string) {
    return values[code] || ''
  }
}
