import React from 'react'
import { connect } from 'react-redux'

import AddressHistory from './address_history'
import Biometrics from './biometrics'
import EmploymentHistory from './employment_history'
import Parents from './parents'
import PreviousMarriages from './previous_marriages'
import PreviousNames from './previous_names'
import Section from './section'
import SectionEntry from './section_entry'
import AlternativeHelpLink from 'components/alternative_help_link'

import { getAccountHolderRole, getModelValue, sponsorHasPetitionHistory, sponsorIsCitizen } from 'reducers/selectors'

interface Props {
  accountHolderRole: string
  beneficiaryFirstName: string
  sponsorFirstName: string
  sponsorHasPetitionHistory: boolean
  sponsorIsCitizen: boolean
}

class PetitionReview extends React.Component<Props> {
  renderSponsorProfileSection() {
    const isAccountHolder = this.props.accountHolderRole === 'sponsor'
    const name = this.props.sponsorFirstName || 'Spouse'
    const header = isAccountHolder ? 'Your Profile' : `${name}'s Profile`

    // B20-1267
    return (
      <Section headerText={header}>
        <SectionEntry label="Full Name" path="sponsor.name" editHref={isAccountHolder ? 'your-name' : 'sponsor-name'} />
        <SectionEntry label="Application Role">Sponsor</SectionEntry>
        <SectionEntry label="U.S. Legal Status" path="sponsor.legal_status" editHref="sponsor-legal-status" />
        <SectionEntry label="Gender" path="sponsor.gender" editHref="sponsor-gender" />
        <SectionEntry label="Birthday" path="sponsor.birthday" editHref="sponsor-birthday" />
        <SectionEntry label="Phone" path="sponsor.uscis_contact_info.mobile_phone" editHref="sponsor-contact-info" />
        <SectionEntry label="Birth Place" path="sponsor.birth_place" editHref="sponsor-birthplace" />
        <SectionEntry label="Children" path="household.dependent_children" editHref="dependent-children" />
        <SectionEntry label="Other Dependents" path="household.other_dependents" editHref="other-dependents" />
        <SectionEntry label="Others Supported" path="household.others_supported" editHref="others-supported" />
        <Biometrics role="sponsor" />
        <PreviousMarriages role="sponsor" />
        <PreviousNames role="sponsor" />
      </Section>
    )
  }

  renderBeneficiaryProfileSection() {
    const isAccountHolder = this.props.accountHolderRole === 'beneficiary'
    const name = this.props.beneficiaryFirstName || 'Spouse'
    const header = isAccountHolder ? 'Your Profile' : `${name}'s Profile`

    // B20-1267
    return (
      <Section headerText={header}>
        <SectionEntry
          label="Full Name"
          path="beneficiary.name"
          editHref={isAccountHolder ? 'your-name' : 'beneficiary-name'}
        />

        <SectionEntry label="Application Role">Beneficiary</SectionEntry>

        <SectionEntry label="Gender" path="beneficiary.gender" editHref="beneficiary-gender" />

        <SectionEntry label="Birthday" path="beneficiary.birthday" editHref="beneficiary-birthday" />

        <SectionEntry label="Current Location" path="beneficiary.location" editHref="beneficiary-location" />

        <SectionEntry label="Birth Place" path="beneficiary.birth_place" editHref="beneficiary-birth-place" />
        <SectionEntry label="Nationality" path="beneficiary.nationality.country" editHref="beneficiary-nationality" />

        <Biometrics role="beneficiary" />
        <PreviousMarriages role="beneficiary" />
        <PreviousNames role="beneficiary" />
      </Section>
    )
  }

  renderMarriageSection() {
    // B20-1267
    return (
      <Section headerText="Your Marriage">
        <SectionEntry
          label="Marriage Date"
          path="account.current_marriage.start_date"
          editHref="sponsor-current-marriage"
        />
        <SectionEntry label="Location" path="account.current_marriage.address" editHref="sponsor-current-marriage" />
      </Section>
    )
  }

  renderSponsorGovernmentInfo() {
    const isAccountHolder = this.props.accountHolderRole === 'sponsor'
    const name = this.props.sponsorFirstName || 'Spouse'
    const header = isAccountHolder ? 'Your Government Info' : `${name}'s Government Info`

    // B20-1267
    return (
      <Section headerText={header}>
        <SectionEntry
          label="Has Legal History"
          path="sponsor.law_enforcement_history"
          editHref="sponsor-law-enforcement-history"
        />
        <SectionEntry label="Social Security Number" path="sponsor.ssn" editHref="sponsor-ssn" />

        {this.props.sponsorIsCitizen ? (
          <SectionEntry label="Citizenship Path" path="sponsor.citizenship_path" editHref="sponsor-citizenship-path" />
        ) : (
          <SectionEntry label="Green Card Path" path="sponsor.lpr_path" editHref="sponsor-lpr-path" />
        )}

        {this.props.sponsorHasPetitionHistory && (
          <SectionEntry
            label="Previous Petition"
            path="sponsor.previous_petition"
            editHref="sponsor-previous-petition-details"
          />
        )}

        <SectionEntry label="USCIS Number" path="sponsor.uscis_number" editHref="sponsor-uscis-number" />
      </Section>
    )
  }

  renderBeneficiaryGovernmentInfo() {
    const isAccountHolder = this.props.accountHolderRole === 'beneficiary'
    const name = this.props.beneficiaryFirstName || 'Spouse'
    const header = isAccountHolder ? 'Your Government Info' : `${name}'s Government Info`

    // B20-1267
    return (
      <Section headerText={header}>
        <SectionEntry
          label="Has Legal History"
          path="beneficiary.law_enforcement_history"
          editHref="beneficiary-law-enforcement-history"
        />
        <SectionEntry label="Has Been to U.S." path="beneficiary.been_to_us" editHref="beneficiary-us-presence" />
        <SectionEntry label="Social Security Number" path="beneficiary.ssn" editHref="beneficiary-ssn" />
        <SectionEntry label="Alien Registration Number" path="beneficiary.arn" editHref="beneficiary-arn" />
        <SectionEntry
          label="Has Petition History"
          path="beneficiary.petition_history"
          editHref="beneficiary-petition-history"
        />
        <SectionEntry label="USCIS Number" path="beneficiary.uscis_number" editHref="beneficiary-uscis-number" />
        <SectionEntry
          label="Previous Immigration Proceedings"
          path="beneficiary.immigration_proceeding"
          editHref="beneficiary-immigration-proceedings"
        />
      </Section>
    )
  }

  render() {
    const { sponsorFirstName, beneficiaryFirstName } = this.props

    return (
      <div className="o-layout--padded-x--full o-layout--padded-off@sm">
        <div className="o-block o-block--copious o-grid--fluid o-grid--stack@sm">
          <div className="o-block o-grid__cell--9/12 o-layout--padded-right o-layout--padded--off@sm o-grid__cell--off@sm">
            <h1 className="o-block o-block--compact c-type c-type--headline-md c-type--emphasized header">
              Check your answers for accuracy
            </h1>
            <p className="o-block o-block--ample c-type c-type--body-sans-md c-type--emphasized">
              You’ll be able to update your answers after talking with your independent attorney, but for now it’s
              important to give your attorney as much accurate information as you can.
            </p>
            <AlternativeHelpLink />
          </div>
        </div>

        <div className="o-block o-block--ample petition-review-content">
          {this.renderSponsorProfileSection()}
          {this.renderBeneficiaryProfileSection()}
          {this.renderMarriageSection()}

          <Parents role="sponsor" firstName={sponsorFirstName} />
          <Parents role="beneficiary" firstName={beneficiaryFirstName} />
          <AddressHistory role="sponsor" />
          <AddressHistory role="beneficiary" />
          <EmploymentHistory role="sponsor" />
          <EmploymentHistory role="beneficiary" />

          {this.renderSponsorGovernmentInfo()}
          {this.renderBeneficiaryGovernmentInfo()}
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    accountHolderRole: getAccountHolderRole(state),
    beneficiaryFirstName: getModelValue(state, 'beneficiary.name.first_name'), // B20-1267
    sponsorFirstName: getModelValue(state, 'sponsor.name.first_name'), // B20-1267
    sponsorHasPetitionHistory: sponsorHasPetitionHistory(state),
    sponsorIsCitizen: sponsorIsCitizen(state)
  }
}

export default connect(mapStateToProps)(PetitionReview)
