import React from 'react'

import Interstitial from 'components/panels/interstitial'

function PrePetitionInterstitial() {
  return (
    <Interstitial
      headline="Next, we’ll get to know you and your spouse a little better."
      body="We'll ask more in-depth questions later on in the process."
      image="setup-start"
    />
  )
}

export default PrePetitionInterstitial
