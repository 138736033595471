import u from 'updeep'

import { INITIALIZE_STORE, MASTER_PDF_UPDATE } from 'lib/constants'

const initialState = {
  id: null,
  feedbackUploaded: null,
  feedbackSubmitted: null,
  reviewFileOriginalName: null,
  status: null
}

export default function customerMasterPdfReducer(state: any, action: any) {
  state = state || initialState

  switch (action.type) {
    case MASTER_PDF_UPDATE: {
      return Object.assign({}, state, action.masterPdf)
    }

    case INITIALIZE_STORE: {
      const masterPdf = action.storeData.kase.master_pdf

      if (!masterPdf) return state

      return u(
        {
          id: masterPdf.id,
          feedbackSubmitted: masterPdf.review_feedback_submitted,
          feedbackUploaded: masterPdf.review_feedback_uploaded,
          reviewFileOriginalName: masterPdf.review_file_original_name,
          status: masterPdf.status
        },
        state
      )
    }

    default: {
      return state
    }
  }
}
