import moment from 'moment'

import { buildPerson, ResourceContextPerson } from '../shared/build_person'
import { dollarsWithCommas } from 'lib/format_money'
import {
  getModelValue,
  isFilingOnMarriageBasis,
  getApplicantProvince,
  getNaturalizationHouseholdSize
} from 'reducers/selectors'

import { ApplicationState } from 'reducers'

import { provincialPovertyGuideline } from '../shared/poverty_guidelines'

export interface ResourceContextTimeInCountry {
  required_months: 18 | 30
  years: 3 | 5
}

export function applicant26thBirthday(state: ApplicationState): string {
  const birthday = getModelValue(state, 'applicant.birthday')

  if (!birthday) return 'your birthday'

  return moment(birthday, 'YYYY-MM-DD').format('MMMM DD, YYYY')
}

export function timeInCountry(state: ApplicationState): ResourceContextTimeInCountry {
  const threeYears = isFilingOnMarriageBasis(state)

  return {
    years: threeYears ? 3 : 5,
    required_months: threeYears ? 18 : 30
  }
}

export function buildApplicant(state: ApplicationState): ResourceContextPerson {
  return buildPerson(getModelValue(state, 'applicant'), 'applicant')
}

export function buildSpouse(state: ApplicationState): ResourceContextPerson {
  return buildPerson(getModelValue(state, 'applicant.current_marriage.spouse'), 'spouse')
}

export function maximumIncome(state: ApplicationState): string {
  const numDependents = getNaturalizationHouseholdSize(state)
  const province = getApplicantProvince(state)

  return dollarsWithCommas(provincialPovertyGuideline(province, numDependents + 2, 2.0))
}
