import React, { FunctionComponent } from 'react'
import cx from 'classnames'
import { Questionnaire, QuestionnairePage, QuestionnaireQuestion, QuestionnaireSection } from '../lib/types'
import { Link } from 'react-router-dom'
import { allQuestionsOnPageCompleted, questionIsCompleted, someQuestionsOnPageCompleted } from '../lib/helpers'

export enum OutlineTypes {
  Slim,
  Disabled,
  Complete
}
interface OutlineProps {
  baseQuestionnaireUrl: string
  currentPagePath: string
  currentSectionName?: string
  outlineType?: OutlineTypes
  questionnaireData: Questionnaire
  setCurrentSectionHandler: Function
}

const QuestionnaireOutline: FunctionComponent<OutlineProps> = (props) => {
  const {
    baseQuestionnaireUrl,
    currentPagePath,
    currentSectionName,
    outlineType = OutlineTypes.Complete,
    questionnaireData,
    setCurrentSectionHandler
  } = props
  const questionnaireSections = questionnaireData?.attributes?.questionnaire_sections

  // The summary section behaves differently in the outline view:
  // - there are no completeness/viewed attributes
  // - the section title is not displayed
  // - the section does not collapse, only the pages
  // - there is a visual divider between these pages and the rest of the questionniare
  const renderSummarySection = (summarySection: QuestionnaireSection) => {
    return (
      <div key={summarySection.name} className="o-list-bare panel-list u-hide@sm-down chapter--flat">
        <span className="o-block o-block--compact">
          <hr className="c-divider c-divider--important c-divider--primary c-divider--broad" />
        </span>
        {summarySection.pages.map((page: QuestionnairePage) => {
          const isSelectedPage = currentPagePath === page?.attributes?.path
          const sectionClasses = cx('chapter', {
            selected: isSelectedPage,
            'o-block o-block--tight': isSelectedPage,
            clickable: true
          })
          return (
            <li key={page.id} className={'panel o-block o-block--tight c-type c-type--subhead-sm clickable complete'}>
              {/* @ts-ignore */}
              <Link to={`${baseQuestionnaireUrl}/${page?.attributes?.path}`} className={sectionClasses}>
                <h4 className="c-type c-type--subhead-sm c-type--inline">{page?.attributes?.name}</h4>
              </Link>
            </li>
          )
        })}
      </div>
    )
  }

  switch (outlineType) {
    case OutlineTypes.Slim:
      if (currentPagePath === 'summary-your-payment-options') {
        return <div />
      } else {
        return (
          <div className="outline">
            <section className="o-box o-box--spacious">
              <hr className="o-block c-divider c-divider--narrow c-divider--secondary c-divider--emphasized" />
              <h1 id="eligibility" className="c-type c-type--headline-sm c-type--emphasized">
                Check Your Eligibility
              </h1>
            </section>
            {questionnaireData.attributes.percent_complete}% done
          </div>
        )
      }
    case OutlineTypes.Disabled:
      // currently only used on the K1 > AOS conversion product
      // some code below may be reflective of specific use case
      const sectionOutline = questionnaireData.attributes.questionnaire_sections.find(
        (section) => section.name === currentSectionName
      )
      return (
        <div>
          <section className="o-box">
            <div className="o-box o-box--copius mb-12">
              <a href="/timeline" className="c-type c-type--body-sans-sm">
                Back to dashboard
              </a>
            </div>
            <h1 id="eligibility" className="c-type c-type--headline-sm c-type--emphasized mb-6">
              {currentSectionName}
            </h1>
            <hr className="o-block c-divider c-divider--narrow c-divider--secondary c-divider--emphasized" />
          </section>
          <ul className="outline-list o-block o-list-bare">
            {sectionOutline.pages.map((page) => {
              const {
                attributes: { name, path, type, viewed },
                id
              } = page
              const classNames = cx('panel o-block o-block--tight c-type c-type--subhead-sm', {
                selected: currentPagePath === path
              })
              const isComplete =
                ((allQuestionsOnPageCompleted(page) && type !== 'PaymentPage') || type !== 'EstimatedTimelinePage') &&
                viewed
              return (
                <li key={id} className={classNames}>
                  <h4 className="c-type c-type--subhead-sm c-type--inline">
                    {name}
                    {isComplete && <i className="checkmark" data-testid="checkmark" />}
                  </h4>
                </li>
              )
            })}
          </ul>
        </div>
      )
    case OutlineTypes.Complete:
      return (
        <div className="o-box o-box--spacious">
          <div className="o-block o-block--compact">
            <div className="o-block o-block--tight c-type c-type--body-sans-sm">
              {questionnaireData.attributes.percent_complete}% done
            </div>
          </div>

          <span className="o-block o-block--compact">
            <hr className="c-divider c-divider--important c-divider--primary c-divider--broad" />
          </span>
          <ul className="outline-list o-block o-list-bare">
            {questionnaireSections?.map((questionnaireSection: QuestionnaireSection, i) => {
              if (questionnaireSection.name === 'Summary') {
                return renderSummarySection(questionnaireSection)
              }

              const sectionName = questionnaireSection?.name
              const isSelectedSection = sectionName === currentSectionName
              const nonInterstitialPages = questionnaireSection?.pages?.filter((page) =>
                ['Page', 'HistoryPage', 'SummaryPage', 'EstimatedTimelinePage'].includes(page.attributes.type)
              )

              const isQuestionOptionalOrCompleted = (page, question: QuestionnaireQuestion) => {
                // We need to determine that a user has viewed a page to not mark pages with only
                // optional or checkbox questions as 'done'.
                const hasViewedPage = page?.attributes?.viewed

                if (page.attributes.type === 'HistoryPage') {
                  return (
                    (question?.attributes?.input_type !== 'address_history_gap' &&
                      question?.attributes?.input_type !== 'employment_history_gap') ||
                    question.attributes.type === 'OptionalQuestion'
                  )
                }

                return (
                  questionIsCompleted(question) ||
                  (question?.attributes?.type === 'OptionalQuestion' && hasViewedPage) ||
                  (question?.attributes?.input_type === 'checkbox' && hasViewedPage)
                )
              }

              /**
               * Pages completed will be different based on what type of page it is
               * Because the different types of pages are expected to have different types of
               * questions
               */
              const allPagesCompleted = nonInterstitialPages?.every((page) =>
                page?.attributes?.page_elements?.every((element) => {
                  if (!element.attributes?.questions) {
                    return true
                  }

                  return element.attributes?.questions?.every((question) =>
                    isQuestionOptionalOrCompleted(page, question)
                  )
                })
              )

              const somePagesCompleted = nonInterstitialPages.some((page) =>
                page?.attributes?.page_elements?.some((element) => {
                  if (page.attributes.type === 'HistoryPage') {
                    return element.attributes?.questions?.some(
                      (question) =>
                        question?.attributes?.input_type === 'address_history' ||
                        question?.attributes?.input_type === 'employment_history'
                    )
                  } else {
                    return element.attributes?.questions?.some(
                      (question) => question?.attributes?.input_value && question?.attributes?.input_type !== 'checkbox'
                    )
                  }
                })
              )

              const sectionClasses = cx({
                complete: allPagesCompleted,
                incomplete: somePagesCompleted && !allPagesCompleted
              })

              return (
                <li id={sectionName} key={sectionName} className={sectionClasses}>
                  <div
                    className={cx('chapter clickable', {
                      'o-block o-block--tight selected': isSelectedSection
                    })}
                    onClick={() => {
                      setCurrentSectionHandler(sectionName)
                    }}
                  >
                    <h4 className="c-type c-type--subhead-sm c-type--inline">{sectionName}</h4>
                    {/* Adding an asterisk when there are outstanding pages to be completed */}
                    {somePagesCompleted && !allPagesCompleted && <i className="asterix">*</i>}

                    {/* Adding a checkmark when all pages below a section have been completed */}
                    {allPagesCompleted && <i className="checkmark" data-testid="checkmark" />}
                  </div>

                  {questionnaireSection.name === currentSectionName && nonInterstitialPages && (
                    <ul className="o-list-bare panel-list">
                      {nonInterstitialPages?.map((page) => {
                        const allQuestionsCompleted = allQuestionsOnPageCompleted(page)
                        const someQuestionsCompleted = someQuestionsOnPageCompleted(page)

                        const hasViewedPage = page?.attributes?.viewed

                        const isSelectedPage = currentPagePath === page?.attributes?.path
                        const pageClasses = cx(
                          'panel',
                          'o-block',
                          'o-block--tight',
                          'c-type',
                          'c-type--subhead-sm',
                          'clickable',
                          'no-link-styling',
                          {
                            selected: isSelectedPage,
                            complete: allQuestionsCompleted && hasViewedPage,
                            incomplete: someQuestionsCompleted
                          }
                        )
                        return (
                          <li id={page.id} key={page.id} className={pageClasses}>
                            {/* @ts-ignore */}
                            <Link to={`${baseQuestionnaireUrl}/${page?.attributes?.path}`}>
                              {page?.attributes?.name}

                              {allQuestionsCompleted && hasViewedPage && (
                                <i className="checkmark" data-testid="checkmark" />
                              )}
                              {!allQuestionsCompleted && someQuestionsCompleted && <i className="asterix">*</i>}
                            </Link>
                          </li>
                        )
                      })}
                    </ul>
                  )}
                </li>
              )
            })}
          </ul>
        </div>
      )
    default:
      return <div />
  }
}

export default QuestionnaireOutline
