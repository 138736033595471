import React from 'react'

interface Props {
  className: string
  label: string
}

export default class WeightLabel extends React.Component<Props> {
  static defaultProps = {
    className: ''
  }

  render() {
    const { className, label } = this.props

    return (
      <div className={className}>
        <div className="c-type c-type--subhead-sm">{label}</div>
      </div>
    )
  }
}
