import React, { FunctionComponent } from 'react'
import { APPLICATION_RESOURCE, fragments } from 'config/routes'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { Fragment } from 'redux-little-router'

import AppLayout from 'components/app_layout'
import DebugError from 'components/debug_error'
import ReviewScreen from 'components/screens/review_screen'
import DashboardRouter from 'components/screens/dashboard/dashboard_router'
import DocumentUploadRouter from 'components/screens/document_upload_v2/document_upload_router'
import QuestionsRouter from './screens/issues_v2/questions_router'
import FeatureFlagInspector from 'components/feature_flags'
import TelemetryLogger from 'components/telemetry'
import Questionnaire from 'components/screens/questionnaire'
import VisaPathQuizContainer from 'components/screens/visa_path_quiz'
import initRibbon from 'static/lib/ribbon'

const userIsTabbing = (e) => {
  if (e.keyCode === 9) {
    // the "I am a keyboard user" key
    document.body.classList.add('user-is-tabbing')
    window.removeEventListener('keydown', userIsTabbing)
  }
}

window.addEventListener('keydown', userIsTabbing)

const App: FunctionComponent = () => {
  // This used to live with the old navbar and it was accidentally removed during
  // the navbar refactor/rebrand. This is needed to initialize the global
  // notification banner (ribbon).
  initRibbon()

  return (
    <React.Fragment>
      <Fragment forRoute={fragments.timeline}>
        <DashboardRouter />
      </Fragment>
      <Fragment forRoute={fragments.document_upload}>
        <DocumentUploadRouter />
      </Fragment>
      <Fragment forRoute={fragments.questions}>
        <QuestionsRouter />
      </Fragment>
      <Fragment forRoute={fragments.review}>
        <ReviewScreen />
      </Fragment>
      <Fragment forRoute={fragments.panel}>
        <AppLayout />
      </Fragment>

      {/* Ideally, we'd like to update all routes to use react-router-dom but the
        old panel routes and some others are very tightly integrated with
        redux-little-router and a lot of refactoring is necessary to make that
        switch. For now, the new Questionnaire sections are the only routes that
        will be included in this react-router-dom block which can work side-by-side
        with redux-little-router as long as the paths don't match anything from above.
    */}
      <BrowserRouter>
        <Switch>
          {/* TODO: Placeholder route for VPQ kases in apply */}
          <Route path={`${APPLICATION_RESOURCE}/visa_path_quiz`} component={VisaPathQuizContainer} />
          <Route path={`${APPLICATION_RESOURCE}/questionnaire/:questionnaireSlug`} component={Questionnaire} />{' '}
        </Switch>
      </BrowserRouter>
      <DebugError />
      <FeatureFlagInspector />
      <TelemetryLogger />
    </React.Fragment>
  )
}

export default App
