import React, { FunctionComponent, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { loadTasks, sendTaskEvent } from 'actions/tasks_actions'
import { getOpenIssues, getCurrentKaseState, allIssuesAddressed } from 'reducers/selectors'
import { KaseStates, KaseEvents } from 'lib/kase_state'

import Button, { ButtonProps } from 'components/button'
import IconModal from 'components/modals/icon_modal'
import Paragraph from 'components/type/paragraph'

interface ExplicitProps {
  buttonSize?: ButtonProps['size']
}

interface MappedProps {
  hasOpenIssues: boolean
  kaseState: KaseStates
  allIssuesAddressed: boolean
}

interface ActionProps {
  sendTaskEvent: typeof sendTaskEvent
}

type Props = MappedProps & ActionProps & ExplicitProps

export const SubmitTaskButton: FunctionComponent<Props> = ({
  allIssuesAddressed,
  buttonSize = 'default',
  hasOpenIssues,
  kaseState,
  sendTaskEvent
}) => {
  const [showModal, setShowModal] = useState(false)
  const isDisabled = !allIssuesAddressed

  const submitToBoundless = async (event) => {
    event.preventDefault()

    let kaseEvent

    switch (kaseState) {
      case KaseStates.customer_document_check: {
        kaseEvent = KaseEvents.customer_document_check_sent_to_ops
        break
      }

      case KaseStates.customer_qa: {
        kaseEvent = KaseEvents.customer_qa_sent_to_ops
        break
      }

      case KaseStates.customer_legal_review: {
        kaseEvent = KaseEvents.legal_review_sent_to_ops
        break
      }

      default: {
        kaseEvent = KaseEvents.customer_form_check_sent_to_ops
      }
    }

    try {
      await sendTaskEvent(kaseEvent)
      setShowModal(false)
      window.location.replace('/timeline')
    } catch (error) {
      // Do not redirect if we were not able to send the case event.
    }
  }

  const closeModal = () => setShowModal(false)

  return (
    <>
      <IconModal variant="info" headerContent="Ready to Submit?" showModal={showModal} closeModal={closeModal}>
        <>
          <Paragraph>
            Please ensure you’ve resolved each item to the best of your ability. Once you submit, we’ll use your
            responses to update your application.
          </Paragraph>
          <Button color="primary" onClick={submitToBoundless} label="Confirm" block className="mt-6 mb-3" />
          <Button color="secondary" onClick={closeModal} label="Cancel" block />
        </>
      </IconModal>
      <Button
        size={buttonSize}
        label="Submit to Boundless"
        color="emphasized"
        disabled={isDisabled}
        onClick={() => setShowModal(true)}
      />
    </>
  )
}

function mapStateToProps(state: MappedProps) {
  return {
    hasOpenIssues: getOpenIssues(state).length > 0,
    kaseState: getCurrentKaseState(state),
    allIssuesAddressed: allIssuesAddressed(state)
  }
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators({ sendTaskEvent, loadTasks }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(SubmitTaskButton) as FunctionComponent<ExplicitProps>
