import _endsWith from 'lodash/endsWith'

export const getUploadsForDocumentRequest = (uploads, documentRequest) => {
  if (documentRequest) {
    return uploads.requestUploads.filter((upload) => upload.documentRequestPath === documentRequest.path)
  }

  return []
}

export const getUploadsForDocumentRequestPart = (uploads, documentRequestPartId) =>
  uploads.requestUploads.filter((upload) => upload.documentRequestPartId === documentRequestPartId)

export const getDocumentUploadLocation = (activeModal) => {
  if (activeModal === 'DocumentsModal') {
    return 'DocumentsModal'
  } else if (_endsWith(window.location.href, 'document_upload')) {
    return 'PostPaymentDocumentUpload'
  } else if (_endsWith(window.location.href, 'documents')) {
    return 'PostPaymentDocuments'
  } else {
    return 'Question'
  }
}

export const getIsFileUploading = (uploads) => {
  return uploads.isFileUploading
}
