import React, { FunctionComponent } from 'react'
import Paragraph from 'components/type/paragraph'
import CloseIcon from 'components/icons/close_icon'
import { KasePhase } from './types'

interface Props {
  currentPhase: KasePhase
  closePhasePreview: () => void
}

const PhasePreview: FunctionComponent<Props> = ({ currentPhase, closePhasePreview }) => {
  if (!currentPhase?.preview) {
    return null
  }

  return (
    <div className="c-phase-details__info">
      <small className="c-type c-type--subhead-sm o-block--compact c-phase-details__subhead">
        What to expect during
      </small>
      <header className="c-phase-details__header o-block" data-qa="preview-title">
        {currentPhase?.display_name}
      </header>
      <Paragraph size="sm" data-qa="preview-description" spacing="none">
        {currentPhase?.preview}
      </Paragraph>
      <button
        className="c-phase-details__close-preview-btn --dark"
        type="button"
        title="Hide this preview"
        onClick={closePhasePreview}
      >
        <CloseIcon />
      </button>
    </div>
  )
}

export default PhasePreview
