import moment from 'moment'
import _includes from 'lodash/includes'

import { joinPaths } from '../../path_helpers'
import { buildMessage, Validator } from './'

function parseDate(date) {
  return moment(date, 'YYYY/MM/DD')
}

const defaultStartDateName = 'start_date'
const defaultEndDateName = 'end_date.date'

const dateRange: Validator = ({ getModelData, path, options = {} }) => {
  const startDateName = options.startDateName || defaultStartDateName
  const endDateName = options.endDateName || defaultEndDateName

  const parentRegex = new RegExp(`.(${startDateName}|${endDateName})$`)

  const parent = path.replace(parentRegex, '')

  const startDatePath = joinPaths(parent, startDateName)
  const endDatePath = joinPaths(parent, endDateName)

  const startDate = parseDate(getModelData(startDatePath))
  const endDate = parseDate(getModelData(endDatePath))

  const invalidDates = !startDate.isValid() || !endDate.isValid()

  if (invalidDates || startDate < endDate) {
    return []
  } else if (_includes(path, startDateName)) {
    return [buildMessage('Start date must be before end date')]
  } else {
    return [buildMessage('End date must be after start date')]
  }
}

export default dateRange
