/**
 * This is mostly copied from ControlledModalWrapper
 * TODO: figure out a way to dry it up
 * https://www.pivotaltracker.com/story/show/174994071
 */

import React, { CSSProperties, createRef, MouseEvent } from 'react'
import uuidv4 from 'uuid/v4'
import { createPortal } from 'react-dom'
import { CSSTransition } from 'react-transition-group'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import FocusTrap from 'focus-trap-react'

import { keyIsEscape } from 'lib/keyboard_helpers'

export enum CloseReason {
  EscapeKey,
  BackdropClicked,
  CloseButton
}

interface Props {
  isOpen?: boolean
  style?: CSSProperties
  handleBackdropClick?: () => void
  asideWidth?: string
  /**
   * This callback is required for accessibility reasons. It's bad practice not
   * to allow users to close the modal using the Escape key or clicking on the
   * backdrop. The consumer of this component can choose not to close the modal
   * when this callback is triggered, but I hope that by making it required, we
   * will encourage good practices.
   */
  onRequestClose: (reason: CloseReason) => void
}

export default class ControlledAsideWrapper extends React.Component<Props> {
  private _isMounted: boolean
  private uniqueKey: string
  private portalElement: HTMLDivElement
  private modalContainerRef = createRef<HTMLElement>()

  constructor(props) {
    super(props)

    this.uniqueKey = uuidv4()
    this._isMounted = false
  }

  componentDidMount() {
    this._isMounted = true

    this.portalElement = document.createElement('div')
    this.portalElement.dataset['modalId'] = this.uniqueKey
    document.body.appendChild(this.portalElement)

    // Force a render on the next frame
    setTimeout(() => {
      if (this._isMounted) {
        this.forceUpdate()

        // If the modal is open when mounted, componentDidUpdate will be skipped.
        // Thus, we set up the side effects here.
        if (this.props.isOpen) {
          this.addModalSideEffects()
        }
      }
    })
  }

  componentDidUpdate(prevProps: Props) {
    if (!prevProps.isOpen && this.props.isOpen) {
      this.addModalSideEffects()
    } else if (prevProps.isOpen && !this.props.isOpen) {
      this.removeModalSideEffects()
    }
  }

  componentWillUnmount() {
    this._isMounted = false

    document.body.removeChild(this.portalElement)
    this.portalElement = null

    this.removeModalSideEffects()
  }

  /**
   * Call this method when showing the modal to activate the side effects:
   * - lock the html and body elements
   * - add a keyboard listener for the Escape key
   */
  private addModalSideEffects() {
    disableBodyScroll(this.portalElement, { reserveScrollBarGap: true })
    document.addEventListener('keydown', this.handleKeyPress)
  }

  private removeModalSideEffects() {
    clearAllBodyScrollLocks()
    document.addEventListener('keydown', this.handleKeyPress)
  }

  private handleKeyPress = (event: KeyboardEvent) => {
    if (keyIsEscape(event)) {
      this.props.onRequestClose(CloseReason.EscapeKey)
    }
  }

  private onClickOutside = (event: MouseEvent) => {
    if (this.modalContainerRef.current && this.modalContainerRef.current.contains(event.target as HTMLElement)) {
      return
    }

    this.props.onRequestClose(CloseReason.BackdropClicked)
  }

  private getFocusTrapFallback = () => {
    // eslint-disable-next-line no-console
    console.error('A modal needs at least one focusable element to be accessible.')
    return this.portalElement
  }

  render() {
    const { children, isOpen, asideWidth = '34rem' } = this.props

    // The portalElement is added to the DOM after mount, so the first render
    // should be skipped
    if (!this.portalElement) {
      return null
    }

    return createPortal(
      <>
        <CSSTransition appear classNames="aside-backdrop-animation" in={isOpen} timeout={200} unmountOnExit>
          <div className="c-aside__backdrop" />
        </CSSTransition>
        <CSSTransition appear classNames="aside-animation" in={isOpen} timeout={200} unmountOnExit>
          <FocusTrap focusTrapOptions={{ fallbackFocus: this.getFocusTrapFallback }}>
            <div className="c-aside__wrapper" onClick={this.onClickOutside}>
              <aside
                ref={this.modalContainerRef}
                className="bg-white c-aside__container"
                aria-modal="true"
                role="dialog"
                style={{ width: asideWidth }}
                tabIndex={-1}
              >
                {children}
              </aside>
            </div>
          </FocusTrap>
        </CSSTransition>
      </>,
      this.portalElement
    )
  }
}
