import React, { FunctionComponent } from 'react'
import IconWrapper, { IconWrapperExternalProps } from './icon_wrapper'

const EmptyCircleIcon: FunctionComponent<IconWrapperExternalProps> = (props) => (
  <IconWrapper {...props} viewBox="0 0 22 22">
    <circle cx="11" cy="11" r="10" fill="none" strokeWidth="2" />
  </IconWrapper>
)

export default EmptyCircleIcon
