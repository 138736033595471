export function keyIsEnter(event) {
  const ENTER_KEY = 13
  const keyCode = event.keyCode || event.which

  return keyCode === ENTER_KEY
}

export function keyIsEscape(event: KeyboardEvent) {
  return event.key === 'Escape' || event.key === 'Esc'
}

export function keyIsArrowUp(event) {
  return event.key === 'ArrowUp'
}

export function keyIsArrowDown(event) {
  return event.key === 'ArrowDown'
}

export function keyIsArrowRight(event) {
  return event.key === 'ArrowRight'
}

export function keyIsArrowLeft(event) {
  return event.key === 'ArrowLeft'
}

export function shiftKeyIsDown(event: React.SyntheticEvent) {
  if (event.hasOwnProperty('shiftKey')) {
    return event['shiftKey']
  }

  if (event.nativeEvent.hasOwnProperty('shiftKey')) {
    return event.nativeEvent['shiftKey']
  }

  return false
}
