import React, { CSSProperties, FunctionComponent } from 'react'
import cx from 'classnames'

interface Props {
  initial: string
  className: string
  style?: CSSProperties
}

const ProfilePicturePlaceholder: FunctionComponent<Props> = ({ initial, className, style }) => {
  return (
    <div
      className={cx(
        'flex text-blue-500 items-center justify-center bg-blue-200 font-bold c-type c-type--body-sans-md',
        className
      )}
      style={style}
    >
      {/* Larsseit is very difficult to center vertically .__. */}
      <span
        style={{
          paddingLeft: '0.2rem',
          paddingTop: '0.3rem',
          fontSize: '2rem'
        }}
      >
        {initial}
      </span>
    </div>
  )
}

export default ProfilePicturePlaceholder
