import React, { FunctionComponent, useMemo, useRef, CSSProperties, useEffect } from 'react'
import { connect } from 'react-redux'
import cx from 'classnames'
import SkeletonParagraph from 'components/skeleton/paragraph'
import SkeletonHeading from 'components/skeleton/heading'
import PhasePreview from './phase_preview'
import PhaseSummary from './phase_summary'
import { KasePhase } from './types'

import { getCoupleFirstNames, getCurrentKaseKindString } from 'reducers/selectors'

interface Props {
  arrowLeftPosition: number
  closePhasePreview: () => void
  coupleFirstNames?: string
  currentPhase: KasePhase
  currentPhasePreview?: KasePhase
  isFullTimelineVisible: boolean
  isLoading: boolean
  kaseKindString: string
  phaseList?: KasePhase[]
}

const PhaseDetailsLayout: FunctionComponent<Props> = ({
  arrowLeftPosition,
  closePhasePreview,
  coupleFirstNames = '',
  currentPhase,
  currentPhasePreview,
  isFullTimelineVisible,
  isLoading,
  kaseKindString,
  phaseList
}) => {
  if (isLoading) {
    return (
      <div className="c-phase-details__wrapper">
        <div className="o-container">
          <div className={cx('c-phase-details__container')}>
            <div className="c-phase-details p-8">
              <SkeletonHeading />
              <SkeletonParagraph numLines={3} />
            </div>
          </div>
          <div className="c-phase-details__arrow-wrapper" role="presentation">
            <div className={cx('c-phase-details__arrow-element')} />
          </div>
        </div>
      </div>
    )
  }

  const phaseToDisplay = currentPhasePreview || currentPhase
  const phaseIndex = phaseList?.findIndex((phase) => phase.name === phaseToDisplay.name) || 0
  const showPreview = !!currentPhasePreview && currentPhasePreview.preview && currentPhasePreview !== currentPhase
  const arrowRef = useRef(null)

  useEffect(() => {
    if (arrowRef.current !== null && arrowLeftPosition > 0) {
      const leftPosition = arrowLeftPosition - arrowRef.current.offsetWidth
      arrowRef.current.style.left = `${leftPosition}px`
    }
  }, [arrowLeftPosition])

  const arrowIsFlipped = useMemo(() => {
    if (!isFullTimelineVisible) return
    const numPhases = phaseList?.length || 0
    const arrowIsFlipped = phaseIndex > numPhases / 2
    return arrowIsFlipped
  }, [arrowLeftPosition])

  // Position the details container
  const boxStyle: CSSProperties = useMemo(() => {
    const numPhases = phaseList?.length || 0

    if (phaseIndex < numPhases / 2) {
      return { marginLeft: '0' }
    }

    return { marginLeft: 'auto' }
  }, [arrowLeftPosition])

  const content = showPreview ? (
    <PhasePreview closePhasePreview={closePhasePreview} currentPhase={phaseToDisplay} />
  ) : (
    <PhaseSummary
      confirmation={phaseToDisplay.summary.confirmation}
      currentPhaseName={phaseToDisplay.name}
      description={phaseToDisplay.summary.description}
      header={phaseToDisplay.summary.title}
      primaryCTA={phaseToDisplay.summary.button_label}
      primaryCTAActionName={phaseToDisplay.summary.button_action}
      primaryCTAEventName={phaseToDisplay.summary.button_event}
      primaryCTAUrl={phaseToDisplay.summary.button_location}
    />
  )

  const showGreeting = coupleFirstNames.length > 0

  return (
    <div className="c-phase-details__wrapper">
      <div className="o-container">
        {showGreeting && (
          <h4 className="o-block o-block--compact c-type c-type--body-sans-md c-type--primary">
            Welcome back, {coupleFirstNames}
          </h4>
        )}
        <h1 className="o-block o-block--ample c-type c-type--primary c-type--headline-lg capitalize mb-8">
          Your {kaseKindString}
        </h1>
        <div
          className={cx('c-phase-details__container', {
            preview: showPreview
          })}
        >
          <div className="c-phase-details" style={boxStyle}>
            {content}
          </div>
        </div>
        <div className="c-phase-details__arrow-wrapper" role="presentation">
          <div
            className={cx('c-phase-details__arrow-element', {
              flipped: arrowIsFlipped,
              preview: showPreview
            })}
            ref={arrowRef}
          />
        </div>
      </div>
    </div>
  )
}

function mapStateToProps(state) {
  return {
    coupleFirstNames: getCoupleFirstNames(state),
    kaseKindString: getCurrentKaseKindString(state)
  }
}

export default connect(mapStateToProps)(PhaseDetailsLayout)
