import React from 'react'
import { connect } from 'react-redux'

import Modal from '../modal'
import { createUserEvent } from '../../../actions/user_event_actions'
import { getCurrentKaseId } from 'reducers/selectors'
import EmailIcon from 'components/icons/email_icon'
import CheckmarkIcon from 'components/icons/checkmark_icon'

interface Props {
  isActive: boolean
  kaseId: number
}

interface State {
  buttonState: 'initial' | 'saving' | 'saved'
}

class ContinueOnDesktopModalNew extends React.Component<Props, State> {
  state: State = {
    buttonState: 'initial'
  }

  markSaved = () => this.setState({ buttonState: 'saved' })

  clickedEmailMyselfDesktopLink = () => {
    this.setState({
      buttonState: 'saving'
    })

    const userEvent = {
      kaseId: this.props.kaseId,
      eventType: 'send_app_link'
    }

    createUserEvent(userEvent).then(this.markSaved)
  }

  buttonIcon() {
    switch (this.state.buttonState) {
      case 'saving': {
        return (
          <div className="c-icon spinner">
            <div className="spinner__circle" />
          </div>
        )
      }
      case 'saved': {
        return <CheckmarkIcon />
      }

      case 'initial':
      default: {
        return <EmailIcon />
      }
    }
  }

  render() {
    return (
      <Modal isActive={this.props.isActive} title="Continue on Desktop or Laptop">
        {() => (
          <div>
            <p className="o-block c-type c-type--body-sans-md">
              The U.S. government forms ask for a lot of info about previous addresses and employment, and many people
              prefer completing this step on a desktop or laptop computer where there is a larger screen. Tap the button
              below to receive an email with a link to continue on a desktop or laptop.
            </p>

            <p className="o-block o-block--ample c-type c-type--body-sans-md">
              To continue on mobile, tap the X above.
            </p>

            <div className="o-layout o-layout--center">
              <button className="c-btn c-btn--primary" onClick={this.clickedEmailMyselfDesktopLink}>
                Email me a link
                <span className="c-btn__icon">{this.buttonIcon()}</span>
              </button>
            </div>
          </div>
        )}
      </Modal>
    )
  }
}

const mapStateToProps = (state) => ({
  kaseId: getCurrentKaseId(state)
})

export default connect(mapStateToProps)(ContinueOnDesktopModalNew)
