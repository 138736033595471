import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'

import {
  getCurrentKaseId,
  getCurrentDocumentUploadSummaryState,
  getActionableDocumentRequests,
  unresolvedCommentCount
} from 'reducers/selectors'
import api from 'lib/api'
import Heading from 'components/type/heading'
import inflect from 'lib/utilities/inflect'
import SectionItemBasic from './section_item_basic'

import { DocumentUploadSummaryStates } from 'lib/constants'

interface MappedProps {
  currentDocumentUploadSummaryState?: DocumentUploadSummaryStates
  kaseId: number
  unresolvedCommentCount: number
  actionableRequestsCount: number
}

const STATES = {
  uploadingNotStarted: {
    text: 'Upload Your Supporting Documents',
    buttonCTA: 'GO TO DOCUMENTS',
    secondary: true
  },
  outstandingComments: {
    text: 'Upload Your Supporting Documents',
    buttonCTA: 'RESOLVE COMMENTS',
    secondary: false
  },
  moreUploadsNeeded: {
    text: 'Upload Your Supporting Documents',
    buttonCTA: 'CONTINUE UPLOADING',
    secondary: false
  },
  uploadsComplete: {
    text: 'Documents Uploaded',
    buttonCTA: 'GO TO DOCUMENTS',
    secondary: true
  }
}

const SupportingDocuments: FunctionComponent<MappedProps & ActionProps> = ({
  currentDocumentUploadSummaryState,
  unresolvedCommentCount,
  kaseId,
  actionableRequestsCount
}) => {
  let uploadState = STATES['uploadsComplete']
  let additionalInfo

  const uploadDocumentsUrl = api.routes.uploadDocumentsUrl(kaseId)

  switch (currentDocumentUploadSummaryState) {
    case DocumentUploadSummaryStates.AllSectionsCustomerCompleted:
      uploadState = STATES['uploadsComplete']
      additionalInfo = 'Additional documents may be requested'
      break
    case DocumentUploadSummaryStates.CommentsExist:
      uploadState = STATES['outstandingComments']
      additionalInfo = inflect(unresolvedCommentCount, 'Comment', 'Comments')
      break
    case DocumentUploadSummaryStates.ThresholdReachedNoComments:
      uploadState = STATES['moreUploadsNeeded']
      additionalInfo = `${inflect(actionableRequestsCount, 'Set', 'Sets')} Left`
      break
    default:
      uploadState = STATES['uploadingNotStarted']
  }

  const ctaClass = uploadState['secondary'] ? 'c-btn--secondary' : 'c-btn--primary'

  return (
    <>
      <Heading tag="h2" size="sm" color="emphasized">
        Supporting Documents
      </Heading>
      <ul>
        <SectionItemBasic
          additionalInfo={additionalInfo}
          additionalInfoColor={
            currentDocumentUploadSummaryState !== DocumentUploadSummaryStates.AllSectionsCustomerCompleted
              ? 'warning'
              : null
          }
          isComplete={actionableRequestsCount === 0}
          sectionTitle={uploadState['text']}
          cta={
            <a href={uploadDocumentsUrl} rel="noopener noreferrer" className={classNames('c-btn', ctaClass)}>
              {uploadState['buttonCTA']}
            </a>
          }
        />
      </ul>
    </>
  )
}

function mapStateToProps(state) {
  return {
    unresolvedCommentCount: unresolvedCommentCount(state),
    kaseId: getCurrentKaseId(state),
    actionableRequestsCount: getActionableDocumentRequests(state).length,
    currentDocumentUploadSummaryState: getCurrentDocumentUploadSummaryState(state)
  }
}

export default connect(mapStateToProps)(SupportingDocuments)
