import React, { FunctionComponent, useState } from 'react'
import { connect } from 'react-redux'
import { Task } from 'reducers/tasks'
import { bindActionCreators, Dispatch } from 'redux'
import { allIssuesAddressed, canSubmitTask, getCurrentKaseId, getCurrentOpenQaTask } from 'reducers/selectors'
import { assignTask } from 'actions/tasks_actions'

import IconModal from 'components/modals/icon_modal'
import Paragraph from 'components/type/paragraph'
import Button, { ButtonProps } from 'components/button'

interface ExplicitProps {
  buttonSize?: ButtonProps['size']
}

interface MappedProps {
  kaseId: number
  currentOpenTask: Task
  canSubmitTask: boolean
  allIssuesAddressed: boolean
}

interface ActionProps {
  assignTask: typeof assignTask
}

type Props = MappedProps & ActionProps & ExplicitProps

export const UnassignTaskButton: FunctionComponent<Props> = ({
  buttonSize = 'default',
  canSubmitTask,
  allIssuesAddressed,
  currentOpenTask,
  kaseId,
  assignTask
}) => {
  const [showModal, setShowModal] = useState(false)
  const unassignTask = () => {
    if (!currentOpenTask) {
      return
    }

    assignTask({ kaseId, taskId: currentOpenTask.id, assigneeId: null })
  }

  const closeModal = () => setShowModal(false)

  const isDisabled = !(allIssuesAddressed && canSubmitTask)

  return (
    <>
      <IconModal variant="info" headerContent="Ready to Submit?" showModal={showModal} closeModal={closeModal}>
        <>
          <Paragraph>
            Please ensure you’ve responded to each item to the best of your ability. Once you submit, we’ll use your
            responses to update your application.
          </Paragraph>
          <Button color="primary" onClick={unassignTask} label="Confirm" block className="mt-6 mb-3" />
          <Button color="secondary" onClick={closeModal} label="Cancel" block />
        </>
      </IconModal>
      <Button
        size={buttonSize}
        label="Submit to Boundless"
        color="emphasized"
        disabled={isDisabled}
        onClick={() => setShowModal(true)}
      />
    </>
  )
}

function mapStateToProps(state: MappedProps) {
  return {
    kaseId: getCurrentKaseId(state),
    canSubmitTask: canSubmitTask(state),
    currentOpenTask: getCurrentOpenQaTask(state),
    allIssuesAddressed: allIssuesAddressed(state)
  }
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators({ assignTask }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(UnassignTaskButton) as FunctionComponent<ExplicitProps>
