import React, { FunctionComponent, useEffect, useState } from 'react'
import moment from 'moment-timezone'
import cx from 'classnames'

import Button from 'components/button'
import PreMilestoneTask from './preMilestoneTask'

import { MilestoneTask } from './lib/types'

interface TaskAnswer {
  answer: { value?: string | Date; id?: number }
  milestoneTaskId: number
}

interface InjectedProps {
  /**
   * Copy provided from the backend, unique to the milestone
   */
  buttonCopy: string
  /**
   * Boolean used for mobile styling
   */
  isMobile: boolean
  /**
   * Boolean used for conditional rendering at end of milestone list
   */
  isLastMilestone: boolean
  /**
   * Boolean from the reducer when the request to update a Milestone
   * has been made and before it is complete
   */
  isUpdating: boolean
  /**
   * Function that saves the milestone answer, posting it to the backend and updating the redux store
   */
  onSaveMilestoneAnswer: (answer: string, milestoneTaskId: number, milestoneTaskKey: string, answerId?: number) => void
  /**
   * Filtered array of tasks with type: "PreMilestoneTask", these are styled differently
   * than the post tasks.
   */
  preMilestoneTasks: MilestoneTask[]
  /**
   * Boolean based on a check for any empty answer values on the PreMilestoneTask array items
   */
  preTasksCompleted: boolean

  /**
   * Boolean used to determine if the save button should be shown
   */
  showButton: boolean

  /**
   * Boolean used to determine if the fields are editable
   */
  disabledField: boolean
}

type Props = InjectedProps

const PreMilestoneTaskList: FunctionComponent<Props> = ({
  buttonCopy,
  isLastMilestone,
  isMobile,
  isUpdating,
  onSaveMilestoneAnswer,
  preMilestoneTasks,
  preTasksCompleted,
  showButton,
  disabledField
}) => {
  const preTaskAnswerArr: TaskAnswer[] = []

  preMilestoneTasks.map((task) => {
    preTaskAnswerArr.push({
      answer: task.answer,
      milestoneTaskId: task.milestone_task_id
    })
  })

  const [taskAnswers, setTaskAnswers] = useState(preTaskAnswerArr)
  const [tasksWithNewAnswers, setTasksWithNewAnswers] = useState<MilestoneTask[]>([])
  const [changesMade, setChangesMade] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [changesSaved, setChangesSaved] = useState(false)

  useEffect(() => {
    if (isUpdating && !changesMade) {
      setIsSaving(true)
    }

    if (isSaving) {
      setIsSaving(false)
      setChangesSaved(true)
    }
  }, [isUpdating])

  const onSaveAnswer = () => {
    setChangesMade(false)

    tasksWithNewAnswers.map((task) => {
      const newTaskAnswer: TaskAnswer = taskAnswers.find(
        (taskAnswer) => taskAnswer.milestoneTaskId === task.milestone_task_id
      )

      if (newTaskAnswer.answer.value) {
        if (newTaskAnswer.answer?.value instanceof Date) {
          const stringValue = newTaskAnswer.answer.value.toString()
          const formattedValue = moment(stringValue).format('MM/DD/YYYY')
          newTaskAnswer.answer.value = formattedValue
        }
        onSaveMilestoneAnswer(newTaskAnswer.answer.value, task.milestone_task_id, task.key, newTaskAnswer.answer.id)

        const updatedTasksArr = tasksWithNewAnswers.filter(
          (task) => task.milestone_task_id === newTaskAnswer.milestoneTaskId
        )
        setTasksWithNewAnswers(updatedTasksArr)
      }
    })
  }

  const handleAnswerUpdate = (task: MilestoneTask, answer: string) => {
    setChangesMade(true)
    setChangesSaved(false)

    if (!tasksWithNewAnswers.includes(task)) {
      setTasksWithNewAnswers([...tasksWithNewAnswers, task])
    }

    const newTaskAnswers = taskAnswers.map((taskAnswer) => {
      if (taskAnswer.milestoneTaskId === task.milestone_task_id) {
        return { ...taskAnswer, answer: { value: answer, id: taskAnswer.answer?.id } }
      }

      return taskAnswer
    })

    setTaskAnswers(newTaskAnswers)
  }

  const getDisplayValue = (task: MilestoneTask) => {
    if (taskAnswers) {
      const newTaskAnswer: TaskAnswer = taskAnswers.find(
        (taskAnswer) => taskAnswer.milestoneTaskId === task.milestone_task_id
      )

      if (newTaskAnswer) {
        if (task.input_type === 'datepicker') {
          if (newTaskAnswer.answer?.value) {
            return new Date(newTaskAnswer.answer.value)
          } else {
            return ''
          }
        }

        return newTaskAnswer.answer?.value || ''
      }
    }
    return null
  }

  const notAllFieldsFilled = preMilestoneTasks.some((task) => getDisplayValue(task) === '')

  return (
    <div className={cx('bg-gray-100', { 'px-4 pb-4 mt-4': isMobile, 'px-8 pb-8 pt-6 my-4': !isMobile })}>
      {preMilestoneTasks.map((task: MilestoneTask) => (
        <div className={cx({ 'pb-4': !isMobile, 'pb-1': isMobile })} key={task.key}>
          <PreMilestoneTask
            isLastMilestone={isLastMilestone}
            isMobile={isMobile}
            onAnswerUpdate={(answer) => handleAnswerUpdate(task, answer)}
            task={task}
            value={getDisplayValue(task)}
            disabledField={disabledField}
          />
        </div>
      ))}
      <div className={cx({ 'flex justify-end': !isMobile, 'pt-8': isMobile })}>
        {changesSaved && preTasksCompleted && (
          <p className={cx('c-type--body-sans-sm', { 'p-5 mt-1': !isMobile, 'text-center mb-2': isMobile })}>Saved</p>
        )}
        {showButton && (
          <Button
            className="c-btn--block@sm-down"
            id={buttonCopy}
            color="primary"
            onClick={() => onSaveAnswer()}
            label={preTasksCompleted ? 'Save' : buttonCopy}
            disabled={notAllFieldsFilled || !changesMade || isUpdating}
            size={isMobile ? 'small' : 'default'}
          />
        )}
      </div>
    </div>
  )
}

export default PreMilestoneTaskList
