import React, { FunctionComponent, useMemo } from 'react'
import { connect } from 'react-redux'
import cx from 'classnames'

import { getCurrentKaseId, getNavigableSectionsForCustomerDashboard } from 'reducers/selectors'
import SectionItem from 'components/screens/dashboard/customer_dashboard/section_item'
import CircleCheckmark from 'components/icons/circle_checkmark'
import { DASHBOARD_PHASE_PRE_PAYMENT } from 'components/screens/dashboard/customer_dashboard/phase_map'
import { SECTION_NAME_PETITION } from 'lib/constants'
import Paragraph from 'components/type/paragraph'

interface Props {
  sections: any[]
  kaseId: number
}

const PrePaymentPhase: FunctionComponent<Props> = ({ sections, kaseId }) => {
  const petitionSection = useMemo(() => {
    return sections.find((section) => section.name === SECTION_NAME_PETITION)
  }, [])
  const petitionSectionUrl = `/applications/${kaseId}/petition`
  const paymentSectionUrl = `/applications/${kaseId}/payment`

  return (
    <>
      <h2 className="o-block c-type c-type--subhead-md">Next Steps</h2>
      <ul className="o-block c-list">
        <SectionItem
          customTitle={'Preview Our Online Application'}
          empty={petitionSection.empty}
          name={SECTION_NAME_PETITION}
          phaseName={DASHBOARD_PHASE_PRE_PAYMENT}
          progress={petitionSection.progress}
          url={petitionSectionUrl}
          dashboard_meta={petitionSection.dashboard_meta}
        />
        <SectionItem
          customTitle={'Pay Risk-Free'}
          name={'payment'}
          phaseName={DASHBOARD_PHASE_PRE_PAYMENT}
          customCTA={'Start for $79/mo'}
          hideProgress={true}
          progress={0}
          url={paymentSectionUrl}
          dashboard_meta={{}}
        />
        <li
          className={cx(
            'c-list__item',
            'o-griddle__row',
            'o-griddle__row--no-gutters',
            'o-griddle__row--align-items-center',
            'c-list__item--disabled'
          )}
          style={{ minHeight: '5rem' }}
        >
          <div className="o-griddle__col--auto py-3">
            <CircleCheckmark checked={false} />
          </div>
          <div className="o-griddle__col py-3">
            <h3 className="c-type c-type--headline-sm">Begin Working With Your Attorney</h3>
          </div>
          <Paragraph size="sm" spacing="none">
            Right after payment!
          </Paragraph>
        </li>
      </ul>
    </>
  )
}

function mapStateToProps(state) {
  return {
    kaseId: getCurrentKaseId(state),
    sections: getNavigableSectionsForCustomerDashboard(state)
  }
}

export default connect(mapStateToProps)(PrePaymentPhase)
