import { PathValidationResult } from './'

export const NULL_VALIDATION: PathValidationResult = {
  state: {
    fired: false,
    error: false,
    success: false,
    validating: false
  },
  messages: []
}
