import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'

import { getCurrentDashboardPhaseForKase, getMasterPdfId, isCustomerFeedbackUploaded } from 'reducers/selectors'
import { DASHBOARD_PHASE_BUTTON_TYPE_MAIN_CTA, TELEMETRY_EVENTS } from 'lib/constants'
import { DashboardPhase } from 'components/screens/dashboard/customer_dashboard/phase_map'
import Button from 'components/button'
import api from 'lib/api'
import { submitFeedbackForMasterPDF } from 'actions/master_pdf_actions'
import { postTelemetry } from 'actions/telemetry_actions'

export const PRIMARY_CTA_SUBMIT_FEEDBACK = 'Submit Feedback'
export const PRIMARY_CTA_DOWNLOAD_APPLICATION = 'Download Application'

interface Props {
  currentPhase: DashboardPhase
  hasCustomerFeedbackUploaded: boolean
  masterPdfId?: number
  submitFeedbackForMasterPDF: (masterPdfId: number) => Promise<any>
  trackClickedPhaseDetails: ({ currentPhase, buttonType }: { currentPhase: string; buttonType: string }) => void
}

const CustomerReviewPrimaryCTA: FunctionComponent<Props> = ({
  currentPhase,
  hasCustomerFeedbackUploaded,
  masterPdfId,
  submitFeedbackForMasterPDF,
  trackClickedPhaseDetails
}) => {
  const downloadMasterPDFUrl = api.routes.viewMasterPdfUrl(masterPdfId)

  const submitFeedback = () => {
    submitFeedbackForMasterPDF(masterPdfId)
  }

  const onCTAClick = () => {
    if (hasCustomerFeedbackUploaded) {
      submitFeedback()
    }

    trackClickedPhaseDetails({
      currentPhase,
      buttonType: DASHBOARD_PHASE_BUTTON_TYPE_MAIN_CTA
    })
  }

  return (
    masterPdfId && (
      <>
        <div className="o-griddle__col--auto">
          {hasCustomerFeedbackUploaded ? (
            <Button color="emphasized" onClick={onCTAClick} label={PRIMARY_CTA_SUBMIT_FEEDBACK} />
          ) : (
            <a
              href={downloadMasterPDFUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="c-btn c-btn--emphasized"
              onClick={onCTAClick}
            >
              {PRIMARY_CTA_DOWNLOAD_APPLICATION}
            </a>
          )}
        </div>
      </>
    )
  )
}

function mapDispatchToActions(dispatch) {
  return {
    submitFeedbackForMasterPDF: (masterPdfId: number) => dispatch(submitFeedbackForMasterPDF(masterPdfId)),
    trackClickedPhaseDetails: (args) => dispatch(postTelemetry(TELEMETRY_EVENTS.CLICKED_PHASE_DETAILS, args))
  }
}

function mapStateToProps(state) {
  return {
    hasCustomerFeedbackUploaded: isCustomerFeedbackUploaded(state),
    currentPhase: getCurrentDashboardPhaseForKase(state),
    masterPdfId: getMasterPdfId(state)
  }
}

export default connect(mapStateToProps, mapDispatchToActions)(CustomerReviewPrimaryCTA)
