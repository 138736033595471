import { PanelGroupRepeatsFunctionCollection } from './'

import _last from 'lodash/last'

const getRepeatsForTripsGroup = (data) => {
  if (!data.get('ui.applicant_has_taken_trips')) {
    return 0
  }

  return (data.get('applicant.travel_history.trip_count') || 1) + 1
}

const getRepeatsForAddressesGroup = (data) => {
  const addresses = data.get(`applicant.addresses`)
  const hasCompletedAddressHistory = data.get(`applicant.address_history_complete`)

  let addressesCount = addresses.length
  if (hasCompletedAddressHistory === false) {
    addressesCount++
  }

  return Math.max(addressesCount, 1)
}

const getRepeatsForSpousePreviousNamesGroup = (data) => {
  if (!data.isMarried() || data.missingCurrentSpouseName()) {
    return 0
  }

  return (data.get('applicant.current_marriage.spouse.number_of_previous_names') || 0) + 1
}

const getRepeatsForPreviousSpousesGroup = (data) => data.get('applicant.number_of_previous_marriages') || 0

const getRepeatsForOtherNationalityGroup = (data) => (data.get('applicant.number_of_other_nationalities') || 0) + 1

const getRepeatsForSchoolsGroup = (data) => data.get('applicant.education.number_of_schools') || 0

const getRepeatsForSpousePreviousSpousesGroup = (data) =>
  data.get('applicant.current_marriage.spouse.number_of_previous_marriages') || 0

const getRepeatsForApplicantPreviousNamesGroup = (data) => (data.get('applicant.number_of_previous_names') || 0) + 1

const getRepeatsForChildrenGroup = (data) => data.get('applicant.family.number_of_children') || 0

const getRepeatsForPreviousEmployersGroup = (data) => {
  const employments = data.get('applicant.employments') || []
  let repeatsCount = employments.length - 1

  const currentlyUnemployed = Boolean(data.get('applicant.employments.0.unemployed'))

  const lastEmployment = _last(employments)
  const lastEmploymentNamePresent = Boolean(lastEmployment && lastEmployment.name)

  if (lastEmploymentNamePresent || (employments.length === 1 && currentlyUnemployed)) {
    repeatsCount++
  }

  return Math.max(repeatsCount, 0)
}

export const getRepeatsForSmartQualifierCollection = (path: string) => (data) => {
  const collection = data.get(path)
  const count = (collection.items || []).length

  // We always show an extra panel for the final qualifier question
  // so they can answer "No"
  return count + 1
}

export const natzRepeatsFunctions: PanelGroupRepeatsFunctionCollection = {
  trips_group: getRepeatsForTripsGroup,
  addresses_group: getRepeatsForAddressesGroup,
  schools_group: getRepeatsForSchoolsGroup,
  spouse_previous_names_group: getRepeatsForSpousePreviousNamesGroup,
  previous_spouses_group: getRepeatsForPreviousSpousesGroup,
  other_nationality_group: getRepeatsForOtherNationalityGroup,
  spouse_previous_spouses_group: getRepeatsForSpousePreviousSpousesGroup,
  applicant_previous_names_group: getRepeatsForApplicantPreviousNamesGroup,
  children_group: getRepeatsForChildrenGroup,
  previous_employers_group: getRepeatsForPreviousEmployersGroup
}

const repeatableOffsetPanels = new Set(['previous_employers_group'])

export const natzPathIndexOffsets = repeatableOffsetPanels

export const natzNameIndexOffsets = repeatableOffsetPanels
