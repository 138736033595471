import React from 'react'
import { connect } from 'react-redux'

import panelLocaleService from 'services/panel_locale_service'
import { joinI18nKeys } from 'lib/i18n'
import { QuestionnaireInputContainerProps } from 'components/forms/inputs'
import { getUnitConversionPreference } from 'reducers/selectors'

import ValidatedInput from 'components/forms/validated_input'
import MajorInput from './major_input'
import MinorInput from './minor_input'
import HeightLabel from './height_label'

type Props = QuestionnaireInputContainerProps & {
  units: 'imperial' | 'metric'
}

class HeightInputContainer extends React.Component<Props> {
  isImperial() {
    return this.props.units === 'imperial'
  }

  isMetric() {
    return this.props.units === 'metric'
  }

  getMajorLabel() {
    return this.isMetric() ? 'cm' : 'ft'
  }

  renderMajorInput() {
    const majorClassName = this.isImperial() ? 'o-grid__cell--6/12' : 'o-grid__cell--3/12'

    const { disabled, id, path } = this.props

    return (
      <div
        className={`${
          this.isMetric() ? 'o-grid__cell--off@sm o-grid__cell--fill' : 'o-grid__cell--6/12'
        } o-grid--fluid`}
      >
        <ValidatedInput className={majorClassName} disabled={disabled} path={path}>
          {({ fireValidation, getValidationClasses, renderValidations }) => (
            <React.Fragment>
              <MajorInput className={getValidationClasses()} onBlur={fireValidation} id={id} path={path} />

              {this.isMetric() && renderValidations()}
            </React.Fragment>
          )}
        </ValidatedInput>
        <HeightLabel
          className="o-grid__cell--fill c-paper-form__label c-paper-form__label--border-on@sm"
          label={this.getMajorLabel()}
        />
      </div>
    )
  }

  renderMinorInput() {
    if (this.isMetric()) return null

    const { disabled, id, path } = this.props

    return (
      <div className="o-grid__cell--6/12 o-grid--fluid">
        <ValidatedInput className="o-grid__cell--9/12" disabled={disabled} path={path}>
          {({ fireValidation, getValidationClasses, renderValidations }) => (
            <React.Fragment>
              <MinorInput
                className={getValidationClasses()}
                disabled={disabled}
                id={id}
                onBlur={fireValidation}
                path={path}
              />
              {renderValidations()}
            </React.Fragment>
          )}
        </ValidatedInput>

        <HeightLabel className="o-grid__cell--fill c-paper-form__label c-paper-form__label--border-on@sm" label="in" />
      </div>
    )
  }

  render() {
    const { id, name, resourceKeys } = this.props

    return (
      <div className="o-block c-paper-form__group o-grid--fluid o-flag o-grid--stack@sm">
        <label
          className="o-grid__cell--3/12 o-grid__cell--off@sm c-paper-form__label c-paper-form__segment"
          htmlFor={id}
        >
          <span className="c-type c-type--subhead-sm">
            {panelLocaleService.t(joinI18nKeys(resourceKeys, `${name}.label`))}
          </span>
        </label>

        <div className="o-grid__cell--9/12 o-grid__cell--off@sm o-grid--fluid c-paper-form__segment">
          {this.renderMajorInput()}
          {this.renderMinorInput()}
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    units: getUnitConversionPreference(state)
  }
}

export default connect(mapStateToProps)(HeightInputContainer)
