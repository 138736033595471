import React from 'react'

interface Props {
  text: string
}

function HtmlSafe({ text }: Props) {
  return <span dangerouslySetInnerHTML={{ __html: text }} />
}

export default HtmlSafe
