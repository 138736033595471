import server from './server'
import { AxiosPromise } from 'axios'
import { formatKasesUrl } from './helpers'

import { AddressHistory } from '../../../reducers/address_histories'

const formatAddressHistoryUrl = (kaseId, path, params = null) => {
  const baseUrl = formatKasesUrl(kaseId, 'address_histories')
  const fullPath = path ? `${baseUrl}/${path}/` : `${baseUrl}/`

  return params ? `${fullPath}?${params}` : fullPath
}

function index(request): AxiosPromise<AddressHistory[]> {
  const { kaseId } = request

  return server
    .get(formatAddressHistoryUrl(kaseId, ''))
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      throw error
    })
}

function createAddress({
  kaseId,
  ...address_history
}: {
  kaseId: number
} & AddressHistory): AxiosPromise<AddressHistory> {
  return server
    .post(formatAddressHistoryUrl(kaseId, ''), { address_history })
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      throw error
    })
}

function updateAddress({
  kaseId,
  ...address_history
}: {
  kaseId: number
} & AddressHistory): AxiosPromise<AddressHistory> {
  return server
    .put(formatAddressHistoryUrl(kaseId, address_history.id), {
      address_history
    })
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      throw error
    })
}

function deleteAddressHistory(kaseId: number, id: number): AxiosPromise<AddressHistory> {
  return server
    .delete(formatAddressHistoryUrl(kaseId, id))
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      throw error
    })
}

function updateMailingAddress({
  kaseId,
  ...address_history
}: {
  kaseId: number
} & AddressHistory): AxiosPromise<AddressHistory> {
  return server
    .post(formatAddressHistoryUrl(kaseId, 'mailing_address'), { address_history })
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      throw error
    })
}

function updateIntendedAddress({
  kaseId,
  ...address_history
}: {
  kaseId: number
} & AddressHistory): AxiosPromise<AddressHistory> {
  return server
    .post(formatAddressHistoryUrl(kaseId, 'intended_address'), { address_history })
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      throw error
    })
}

function updatePhysicalAddressAbroad({
  kaseId,
  ...address_history
}: {
  kaseId: number
} & AddressHistory): AxiosPromise<AddressHistory> {
  return server
    .post(formatAddressHistoryUrl(kaseId, 'physical_address_abroad'), { address_history })
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      throw error
    })
}

export default {
  index,
  createAddress,
  updateAddress,
  deleteAddressHistory,
  updateMailingAddress,
  updateIntendedAddress,
  updatePhysicalAddressAbroad
}
