import _isEmpty from 'lodash/isEmpty'

import { joinI18nKeys } from 'lib/i18n'
import panelLocaleService from '../services/panel_locale_service'

export interface ContextualHelpType {
  alternative_link_text?: string
  header?: string
  text?: string
}

export default function contextualHelpForPanel(panel: {
  panel_keys: string
  panel_index: Nullable<number>
}): Nullable<ContextualHelpType> {
  if (!panel) return null
  if (!panel.panel_keys) return null

  const help = ['text', 'header', 'alternative_link_text'].reduce((result, propertyName) => {
    const keys = joinI18nKeys(panel.panel_keys, `help.${propertyName}`)

    if (panelLocaleService.exists(keys)) {
      result[propertyName] = panelLocaleService.t(keys, {
        index: panel.panel_index
      })
    }

    return result
  }, {})

  return _isEmpty(help) ? null : help
}
