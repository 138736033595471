import getStore from 'stores/app_store'
import { setAssetsExpired, setAssetsVersion } from 'actions/asset_actions'
import { getAssetVersion as getAssetVersionSelector } from 'reducers/selectors'

import { AxiosRequestConfig, AxiosResponse } from 'axios'

interface AssetHeader {
  'Asset-Version'?: string
}

const getAssetVersion = (): Nullable<string> => getAssetVersionSelector(getStore().getState())

const checkAssetsAreOutOfDate = (nextAssetVersion: Nullable<string>) => {
  if (nextAssetVersion !== getAssetVersion()) {
    getStore().dispatch(setAssetsExpired())
  }
}

const assetVersionHeaders = (): AssetHeader => {
  const version = getAssetVersion()

  return version ? { 'Asset-Version': version } : {}
}

export const addAssetVersionHeader = (config: AxiosRequestConfig): AxiosRequestConfig => {
  config.headers = Object.assign({}, assetVersionHeaders(), config.headers || {})

  return config
}

export function checkAssetVersion(response: AxiosResponse): AxiosResponse {
  const nextAssetVersion = response.headers && response.headers['asset-version']

  if (getAssetVersion()) {
    checkAssetsAreOutOfDate(nextAssetVersion)
  } else {
    getStore().dispatch(setAssetsVersion({ version: nextAssetVersion }))
  }

  return response
}
