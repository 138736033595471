import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'
import AlertCircleIcon from 'components/icons/alert_circle_icon'
import {
  getCurrentKaseKind,
  getWorkflowCategory,
  getNatzApplicantFirstName,
  getBeneficiaryFirstName,
  getSponsorFirstName,
  isUserLoggedIn,
  isCurrentKasePaid,
  getExternalDomain
} from 'reducers/selectors'

interface Props {
  kaseKind: 'Naturalization' | 'MarriageBasedGreenCard' | 'NewMarriageBasedGreenCard'
  workflowCategory: 'AOS' | 'CP' | 'NATZ'
  sponsorFirstName?: string
  beneficiaryFirstName?: string
  natzApplicantFirstName?: string
  isUserLoggedIn?: boolean
  isCurrentKasePaid?: boolean
  externalDomain: string
}

const getAlertText = ({ workflowCategory }) => {
  if (workflowCategory === 'AOS') {
    return null
  }

  return null
}

const getWelcomeMessage = ({
  kaseKind,
  natzApplicantFirstName,
  sponsorFirstName,
  beneficiaryFirstName,
  isUserLoggedIn
}) => {
  if (kaseKind === 'Naturalization') {
    if (natzApplicantFirstName) {
      return `Welcome ${natzApplicantFirstName}!`
    }
    return 'Welcome to Boundless!'
  } else if (isUserLoggedIn && beneficiaryFirstName && sponsorFirstName) {
    return `Welcome, ${beneficiaryFirstName} & ${sponsorFirstName}!`
  }

  return "Let's continue your application process."
}

const DashboardWelcomeMessage: FunctionComponent<Props> = ({
  kaseKind,
  workflowCategory,
  sponsorFirstName,
  beneficiaryFirstName,
  natzApplicantFirstName,
  isUserLoggedIn,
  isCurrentKasePaid,
  externalDomain
}) => {
  const alertText = getAlertText({ workflowCategory })

  const showPaymentConfirmation = window.location.search.indexOf('payment_success') !== -1

  const welcomeMessage = getWelcomeMessage({
    kaseKind,
    natzApplicantFirstName,
    sponsorFirstName,
    beneficiaryFirstName,
    isUserLoggedIn
  })

  return (
    <div className="o-grid__col-8 o-box o-box--spacious">
      <h3 className="o-block o-block--copious c-type c-type--headline-md c-type--emphasized">{welcomeMessage}</h3>
      {alertText && (
        <div className="o-block o-block--copious c-notification c-notification--info">
          <AlertCircleIcon className="c-notification__icon" />
          <p className="c-type c-type--body-sans-md">
            {alertText}{' '}
            <a
              target="_blank"
              className="c-link"
              rel="noopener noreferrer"
              href={`${externalDomain}/blog/fee-hikes-citizenship-immigration-forms`}
            >
              Learn More →
            </a>
          </p>
        </div>
      )}
      {isCurrentKasePaid && (
        <p className="o-block c-type c-type--body-sans-md">
          Thank you for trusting Boundless to help you navigate your immigration journey with confidence.
        </p>
      )}
      {showPaymentConfirmation && (
        <div className="o-block o-block--compact">
          <p className="c-type c-type--headline-sm c-type--secondary">
            Your payment has been processed successfully. Welcome to Boundless!
          </p>
        </div>
      )}
    </div>
  )
}

function mapStateToProps(state) {
  return {
    kaseKind: getCurrentKaseKind(state),
    workflowCategory: getWorkflowCategory(state),
    sponsorFirstName: getSponsorFirstName(state),
    beneficiaryFirstName: getBeneficiaryFirstName(state),
    natzApplicantFirstName: getNatzApplicantFirstName(state),
    isUserLoggedIn: isUserLoggedIn(state),
    isCurrentKasePaid: isCurrentKasePaid(state),
    externalDomain: getExternalDomain(state)
  }
}

export default connect(mapStateToProps, null)(DashboardWelcomeMessage)
