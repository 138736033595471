import { isDevelopmentStage } from 'lib/settings'
import DebugCable from './debug_cable'

export default class EventQueue {
  constructor(max = 15) {
    this.events = []
    this.max = max

    if (isDevelopmentStage()) {
      // enable actioncable in dev
      const debugCable = new DebugCable()
      this.cable = debugCable.getConsumer()
    }

    this.subscriber = null // single subscriber only
  }

  push(eventName, data) {
    const event = {
      name: eventName,
      data
    }

    this.events.unshift(event)

    if (this.events.length > this.max) {
      this.events.pop()
    }
    this.subscriber && this.subscriber()

    return true
  }

  getEvents() {
    return this.events
  }

  subscribe(func) {
    if (!this.subscriber) {
      this.subscriber = func

      if (isDevelopmentStage()) {
        // get server events from websocket
        this.channel = this.cable.subscriptions.create('TelemetryDebug', {
          received: ({ event }) => {
            this.push('SERVER_' + event, {})
          }
        })
      }
    }
  }

  unsubscribe() {
    this.subscriber = null

    if (isDevelopmentStage()) {
      // unsubscribe from server events
      this.channel && this.channel.unsubscribe()
    }
  }
}
