import PhoneNumber from 'awesome-phonenumber'

export function formatPhone(phoneModel) {
  if (!(phoneModel.number && phoneModel.iso_country_code)) {
    return null
  }

  const phone = new PhoneNumber(phoneModel.number, phoneModel.iso_country_code)

  if (phone.isValid()) {
    if (phoneModel.iso_country_code === 'US') {
      return phone.getNumber('national')
    } else {
      return phone.getNumber('international')
    }
  } else {
    return `+${phoneModel.country_code}${phoneModel.number}`
  }
}
