import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'
import { getCurrentKaseKindString } from 'reducers/selectors'
import EstimatedTimelineBg from 'images/estimated_timeline_bg.png'
import TimelineDesktop from 'images/timeline_desktop.svg'
import TimelineMobile from 'images/timeline_mobile.svg'
import Card, { CardHeader, CardBody } from 'components/card'
import GreenCircleCheckmarkListIcon from 'components/icons/green_circle_checkmark_list_icon'
import Heading from 'components/type/heading'
import Paragraph from 'components/type/paragraph'

interface MappedProps {
  currentKaseKindString: string
}

const EstimatedTimelineOverview: FunctionComponent<MappedProps> = ({ currentKaseKindString }) => {
  return (
    <>
      <div className="c-sheet__header">
        <div className="o-griddle__row o-griddle__row--no-gutters">
          <div className="o-griddle__col--10 o-griddle__col--push-1">
            <h1 className="o-block c-type--headline-md c-type--emphasized">Your estimated green card timeline</h1>
          </div>
        </div>
      </div>

      {/* mobile */}
      <div className="c-sheet__body" style={{ overflow: 'hidden', paddingBottom: '4rem' }}>
        <div className="o-griddle__row o-griddle__row--no-gutters o-block--compact">
          <div className="o-griddle__col--3 o-griddle__col--hide-sm-up">
            <img src={TimelineMobile} style={{ width: '100%', height: 'auto' }} />
          </div>
          <div className="o-griddle__col--9 o-griddle__col--hide-sm-up">
            <Card variant="primary" className="text-center o-block--generous c-app-card--timeline">
              <CardHeader className="c-app-card__header--sm">
                <Heading tag="h4" subhead spacing="none">
                  Be ready to file in
                </Heading>
              </CardHeader>
              <CardBody className="c-app-card__body--sm">
                <Heading tag="h3" size="sm" color="emphasized" spacing="sm">
                  ~2.5 months
                </Heading>
                <Paragraph size="sm">or sooner, depending on your case</Paragraph>
              </CardBody>
            </Card>
            <Card variant="primary" className="text-center c-app-card--timeline">
              <CardHeader className="c-app-card__header--sm">
                <Heading tag="h4" subhead spacing="none">
                  Get approved in
                </Heading>
              </CardHeader>
              <CardBody className="c-app-card__body--sm">
                <Heading tag="h3" size="sm" color="emphasized" spacing="sm">
                  ~10-23 months
                </Heading>
                <Paragraph size="sm">or sooner, depending on field office</Paragraph>
              </CardBody>
            </Card>
          </div>
        </div>
        {/* end mobile */}

        {/* start desktop */}
        <div className="o-griddle__row o-griddle__row--no-gutters o-block--compact">
          <div className="o-griddle__col--sm-9 o-griddle__col--push-sm-2 o-griddle__col--show-sm-up o-griddle__col--hide-sm-down">
            <img src={TimelineDesktop} style={{ width: '100%', height: 'auto' }} />
          </div>
        </div>
        <div className="o-griddle__row o-griddle__row--no-gutters o-block--spacious">
          <div className="o-griddle__col--sm-4 o-griddle__col--push-sm-1 o-griddle__col--hide-sm-down">
            <Card variant="primary" className="text-center c-app-card--timeline">
              <CardHeader className="c-app-card__header--sm">
                <Heading tag="h4" subhead spacing="none">
                  Be ready to file in
                </Heading>
              </CardHeader>
              <CardBody className="c-app-card__body--sm">
                <Heading tag="h3" size="sm" color="emphasized" spacing="sm">
                  ~2.5 months
                </Heading>
                <Paragraph size="sm">or sooner, depending on your case</Paragraph>
              </CardBody>
            </Card>
          </div>
          <div className="o-griddle__col--sm-4 o-griddle__col--push-sm-1 o-griddle__col--hide-sm-down">
            <Card variant="primary" className="text-center c-app-card--timeline">
              <CardHeader className="c-app-card__header--sm">
                <Heading tag="h4" subhead spacing="none">
                  Get approved in
                </Heading>
              </CardHeader>
              <CardBody className="c-app-card__body--sm">
                <Heading tag="h3" size="sm" color="emphasized" spacing="sm">
                  ~10-23 months
                </Heading>
                <Paragraph size="sm">or sooner, depending on field office</Paragraph>
              </CardBody>
            </Card>
          </div>
        </div>
        {/* end desktop */}

        <div className="o-griddle__row o-griddle__row--no-gutters">
          <div className="o-griddle__col--12 o-griddle__col--sm-10 o-griddle__col--push-sm-1 bg-blue-200">
            <div className="o-griddle__row">
              <div className="o-griddle__col--12 o-griddle__col--md-6">
                <div className="p-8">
                  <Paragraph size="md" color="emphasized" spacing="lg">
                    We’ll do everything we can to speed up your application!
                  </Paragraph>
                  <ul className="o-list-bare c-type c-type--body-sans-sm sm:text-left">
                    <li className="flex mb-2">
                      <GreenCircleCheckmarkListIcon className="mr-2" />
                      Our software makes filling out the forms quick and easy
                    </li>
                    <li className="flex mb-2">
                      <GreenCircleCheckmarkListIcon className="mr-2" />
                      Our team will quickly review your application so you’re ready to file ASAP
                    </li>
                    <li className="flex">
                      <GreenCircleCheckmarkListIcon className="mr-2" />
                      Our high quality applications will help you avoid government delays
                    </li>
                  </ul>
                </div>
              </div>
              <div className="o-griddle__col--md-6 o-griddle__col--show-md-up o-griddle__col--hide-md-down">
                <img
                  src={EstimatedTimelineBg}
                  style={{
                    height: 'auto',
                    position: 'absolute',
                    right: '-70px',
                    top: '-20px',
                    maxWidth: '130%'
                  }}
                />
              </div>
              <div className="o-griddle__col--12 o-griddle__col--show-md-down o-griddle__col--hide-md-up">
                <img
                  src={EstimatedTimelineBg}
                  style={{
                    height: 'auto',
                    marginRight: '-20%',
                    width: '120%'
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

function mapStateToProps(state: any): MappedProps {
  return {
    currentKaseKindString: getCurrentKaseKindString(state)
  }
}

export default connect(mapStateToProps)(EstimatedTimelineOverview)
