import u from 'updeep'
import { LOADED_EMPLOYMENTS, LOADING_EMPLOYMENTS } from '../../lib/constants'

export interface Address {
  street: string
  unit_number: string
  unit_type: string
  city: string
  country_code: string
  postal_code: string
  province: string
}

export interface EmploymentHistory {
  id?: number
  start_date: string
  end_date: string
  employer_name: string
  job_title: string
  remote: boolean
  self_employed: boolean
  unemployed: boolean
  address?: Address
  current: boolean
  gap?: boolean
  address_history_mismatch?: boolean
}

export interface PersonEmploymentHistory {
  complete: boolean
  employment_history?: EmploymentHistory[]
  five_year_complete?: boolean
  has_valid_outside_us_address?: boolean
}

export interface KaseEmploymentHistory {
  kase_id: number
  sponsor: PersonEmploymentHistory
  beneficiary: PersonEmploymentHistory
}

export interface EmploymentHistoryById {
  [key: string]: EmploymentHistory
}

export interface EmploymentHistoryStore {
  employmentHistories: KaseEmploymentHistory
  loadingEmploymentHistories?: boolean
}

const initialState: EmploymentHistoryStore = {
  employmentHistories: {
    kase_id: null,
    sponsor: {
      complete: false,
      employment_history: []
    },
    beneficiary: {
      complete: false,
      employment_history: []
    }
  },
  loadingEmploymentHistories: false
}

export default function employmentHistoriesReducer(state: EmploymentHistoryStore, action: any): EmploymentHistoryStore {
  state = state || initialState
  switch (action.type) {
    case LOADING_EMPLOYMENTS: {
      return u(
        {
          loadingEmploymentHistories: action.payload
        },
        state
      )
    }

    case LOADED_EMPLOYMENTS: {
      return u(
        {
          employmentHistories: action.payload
        },
        state
      )
    }

    default: {
      return state
    }
  }
}
