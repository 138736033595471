import moment from 'moment'

import { getKaseModelTemplate } from 'lib/kase_model_template_helpers'
import { resizeCollection } from './helpers'

import { AfterChangeHooks } from './types'

const hooks: AfterChangeHooks = {}

hooks.naturalizationUpdateChildrenCollectionCount = resizeCollection(
  'applicant.family.children',
  getKaseModelTemplate('NaturalizationChild')
)

hooks.naturalizationUpdateSchoolsCollectionCount = resizeCollection(
  'applicant.education.schools',
  getKaseModelTemplate('NaturalizationSchool')
)

hooks.naturalizationUpdateApplicantPreviousMarriageCollectionCount = resizeCollection(
  'applicant.previous_marriages',
  getKaseModelTemplate('NaturalizationMarriage')
)

hooks.naturalizationUpdateSpousePreviousMarriageCollectionCount = resizeCollection(
  'applicant.current_marriage.spouse.previous_marriages',
  getKaseModelTemplate('NaturalizationMarriage')
)

hooks.naturalizationUpdateMaritalStatusAfterMarriedToUsCitizen = ({ serializedValue, changeValueAtPath }) => {
  const newStatusValue = serializedValue ? 'married' : null

  changeValueAtPath('applicant.family.marital_status', {
    value: newStatusValue
  })
}

const resizeAddresses = resizeCollection('applicant.addresses', getKaseModelTemplate('Address'))

hooks.naturalizationResizeAddressHistoryOnStartDateChange = (options) => {
  const { serializedValue, getModelValueAtPath, previousValue } = options

  if (!serializedValue) return

  const addresses = getModelValueAtPath('applicant.addresses')
  const dates = addresses
    .map((address) => address.start_date)
    // we need to filter out the previous value of this field, as it hasn't
    // quite changed yet in Redux
    .filter((dateString) => dateString !== previousValue)
    .concat([serializedValue])
    .map((dateString) => moment(dateString, 'YYYY/MM/DD'))
    .filter((date) => date.isValid())

  const minDate = moment.min(dates)
  const fiveYearsAgo = moment().subtract(5, 'years')

  if (fiveYearsAgo < minDate) {
    const newSize = addresses.length + 1

    resizeAddresses({
      ...options,
      serializedValue: newSize
    })
  }
}

export default hooks
