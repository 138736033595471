import React, { FunctionComponent, useEffect, useState } from 'react'
import { connect } from 'react-redux'

import { BoundlessTieredPricingSalesPitch, EligibilityData, EligibilitySections } from './types'

import {
  getCurrentKaseId,
  getCurrentKaseKind,
  isUserLoggedIn,
  getCurrentKaseCompletedEligibility
} from '../../../../reducers/selectors'
import { getIsFeatureEnabled } from '../../../../reducers/features/selectors'

import { KaseKind } from 'lib/constants'
import api from 'lib/api'
import { FEATURES } from 'lib/features'
import {
  AnalyticsContext,
  ApplicationPackageCard,
  BoundlessPalette,
  Box,
  Button,
  Card,
  Divider,
  Grid,
  Illustration,
  IllustrationStampedCard,
  MainContentHeader,
  MainContentSection,
  Typography,
  useMediaQuery,
  VerticalTimelineOrderedList
} from '@boundless-immigration/boundless-ui'
import { trackUserEvent } from 'actions/telemetry_actions_v2'
import { openChat } from 'actions/chat_actions'
import ContextualHelpModal from 'components/modals/modal/contextual_help_modal'

interface MappedProps {
  currentKaseKind: string
  currentKaseId: number
  isUserLoggedIn: boolean
  completedEligibility: boolean
  isChatDiscoveryTestEnabled: boolean
  isAnalyticsDebugEnabled: boolean
}

interface ActionProps {
  trackUserEvent: typeof trackUserEvent
  openChat: typeof openChat
}

type Props = MappedProps & ActionProps

const PrePaymentDashboard: FunctionComponent<Props> = ({
  currentKaseKind,
  currentKaseId,
  isAnalyticsDebugEnabled,
  openChat,
  trackUserEvent
}) => {
  const [eligibilityData, setEligibilityData] = useState<EligibilityData>()
  const [isLoadingData, setIsLoadingData] = useState(true)
  const [mbgcType, setMbgcType] = useState('')
  const {isXS, isMobile} = useMediaQuery()
  const rootUrl = `/applications/${currentKaseId}`
  const [costAndTimelineData, setSetCostAndTimelineData] = useState<EligibilitySections>()
  const [applyingWithBoundlessData, setApplyingWithBoundlessData] = useState<EligibilitySections>()
  const [showFeeBreakdownModal, setShowFeeBreakdownModal] = useState(false)

  const kaseUrls = {
    K1FianceVisa: {
      eligibilityUrl: `/start/k1`,
      petitionPart1Url: `${rootUrl}/questionnaire/k1_petition_1`,
      paymentPageUrl: `${rootUrl}/questionnaire/k1_payment`
    },
    NewMarriageBasedGreenCard: {
      eligibilityUrl: `/start/marriage`,
      petitionPart1Url: `${rootUrl}/questionnaire/petition_1`,
      paymentPageUrl: `${rootUrl}/questionnaire/mbgc_payment`
    }
  }

  useEffect(() => {
    trackUserEvent('ViewedEligibilityResults')
  }, [])

  useEffect(() => {
    api.eligibilityData.getEligibilityDataApi({kaseId: currentKaseId}).then((res) => {
      setEligibilityData(res.data)
      setIsLoadingData(false)
    })
  }, [currentKaseId])

  interface PriceInfo {
    essential: BoundlessTieredPricingSalesPitch | null
    premium: BoundlessTieredPricingSalesPitch | null
  }

  const [priceInfo, setPriceInfo] = useState<PriceInfo | null>(null)

  useEffect(() => {
    if (currentKaseKind === KaseKind.NewMarriageBasedGreenCard) {
      const type = eligibilityData?.attributes.tags.find((el) => el === 'AOS' || el === 'CR1')
      setMbgcType(type)
    }

    setPriceInfo(getPriceInfoFromEligibilityData(eligibilityData))
  }, [eligibilityData])

  const getMaxMonths = () => {
    if (priceInfo) {
      // we're using essential, but both essential and premium should be the same
      return priceInfo.essential.max_installments_in_months
    } else {
      // This should be set no matter what, but just in case, return null when not set
      return null
    }
  }

  const onShowFeeBreakdownModal = (event) => {
    event.preventDefault()
    setShowFeeBreakdownModal(true)
  }

  // The price info is pretty hidden away in in the current eligibility data API response
  // If we can make the `PriceInfo` a top level attribute in the future, then we can
  // remove this function
  const getPriceInfoFromEligibilityData = (eligibilityData: EligibilityData): PriceInfo | null => {
    const salesPitchesByTier = eligibilityData?.attributes?.sections?.find(
      (section) => section.title === 'BOUNDLESS TIERED PRICING'
    )?.sales_pitches as unknown as BoundlessTieredPricingSalesPitch[]

    if (!salesPitchesByTier) {
      return null
    }

    return {
      essential: salesPitchesByTier.find((pitch) => pitch.title === 'essential'),
      premium: salesPitchesByTier.find((pitch) => pitch.title === 'premium')
    }
  }

  const getCostAndTimelineData = () => {
    eligibilityData.attributes.sections.find((section) => {
      if (section.title === 'COST AND TIMELINE') {
        setSetCostAndTimelineData(section) as unknown as EligibilitySections
      }
    })
  }

  useEffect(() => {
    if (eligibilityData) {
      getCostAndTimelineData()
    }
  }, [eligibilityData])

  const getApplyingWithBoundlessData = () => {
    eligibilityData.attributes.sections.find((section) => {
      if (section.title === 'APPLYING WITH BOUNDLESS') {
        setApplyingWithBoundlessData(section) as unknown as EligibilitySections
      }
    })
  }

  useEffect(() => {
    if (eligibilityData) {
      getApplyingWithBoundlessData()
    }
  }, [eligibilityData])

  // Could add a skeleton layout here as a nice to have
  if (isLoadingData) {
    return null
  }

  if (!eligibilityData || !eligibilityData.attributes) {
    return null
  }

  const handlePackageSelected = (type: 'essential' | 'premium') => {
    trackUserEvent('ProceedToCart', { ctaLocation: `${type}Tier` })
    location.href = `${kaseUrls[currentKaseKind].paymentPageUrl}?package=${type}`
  }

  const handleChatButtonClicked = () => {
    openChat({ via: 'prePaymentDashboard' })
  }

  const getProductInfoText = () => {
    return {
      title: `Starting at $${priceInfo?.essential?.boundless_fee}`,
      subtitle: 'Boundless application service'
    }
  }

  return (
    <Box sx={{ width: '100%' }}>
      {/* TODO - if/when we add an AnalyticsContext.Provider to the root of the app, remove this one */}
      <AnalyticsContext.Provider
        value={{
          appName: 'apply',
          pageName: 'EligibilityResults',
          apiFunction: trackUserEvent,
          debug: isAnalyticsDebugEnabled
        }}
      >
        <MainContentHeader contentAlignment="center" xs={10} sx={{ pb: 0 }}>
          <Typography sx={{ mt: 4 }} variant="heading-md-bold">
            {eligibilityData.attributes.title}
          </Typography>
          {eligibilityData.attributes.body && <Typography sx={{ mt: 2 }}>{eligibilityData.attributes.body}</Typography>}
          <Button
            analytics={{
              eventName: 'ProceedToCart',
              eventProperties: {
                ctaLocation: 'header'
              }
            }}
            onClick={() => {
              location.href = kaseUrls[currentKaseKind].paymentPageUrl
            }}
            color="secondary"
            sx={{ mt: 4 }}
          >
            Apply with Boundless
          </Button>
          <Box sx={{ mt: 2 }}>
            <Button
              onClick={() => {
                location.href = kaseUrls[currentKaseKind].petitionPart1Url
              }}
              variant="text"
              color="primary"
              size="small"
            >
              Start for free
            </Button>
          </Box>
        </MainContentHeader>
        <MainContentSection
          md={12}
          lg={10}
          bgImage={`linear-gradient(180deg, ${BoundlessPalette.white} 0%, ${BoundlessPalette.backgroundMedium} 100%)`}
          sx={{ py: 2 }}
        >
          <Card elevation={4} sx={{ p: 2, textAlign: 'center' }}>
            <Grid container alignItems="center">
              <Grid item xs={12} sm sx={{ p: 2, pb: { xs: 0, sm: 2 } }}>
                <Typography>
                  <strong>{getProductInfoText().title}</strong>
                </Typography>
                <Typography>{getProductInfoText().subtitle}</Typography>
                {isXS && <Divider sx={{ mt: 2 }} />}
              </Grid>
              {!isXS && <Divider orientation="vertical" variant="middle" flexItem />}
              <Grid item xs={12} sm sx={{ p: 2, pb: { xs: 0, sm: 2 } }}>
                <Typography>
                  <a href="" onClick={onShowFeeBreakdownModal} title="Government Fees">
                    <strong>{costAndTimelineData?.sales_pitches[1].title}</strong>
                  </a>
                </Typography>
                <Typography>{costAndTimelineData?.sales_pitches[1].body}</Typography>
                {isXS && <Divider sx={{ mt: 2 }} />}
              </Grid>
              {!isXS && <Divider orientation="vertical" variant="middle" flexItem />}
              <Grid item xs={12} sm sx={{ p: 2 }}>
                <Typography>
                  <strong>{costAndTimelineData?.sales_pitches[0].title}</strong>
                </Typography>
                <Typography>{costAndTimelineData?.sales_pitches[0].body}</Typography>
              </Grid>
            </Grid>
          </Card>
          {showFeeBreakdownModal && (
            <ContextualHelpModal
              /* Its okay to use the Essential tier data here, since the user has not picked a Tier yet */
              header={
                eligibilityData?.attributes.payment_details.essential.fee_breakdown_contextual_help_govt_itemized.header
              }
              isOpen={showFeeBreakdownModal}
              onRequestClose={() => setShowFeeBreakdownModal(false)}
              text={
                eligibilityData?.attributes.payment_details.essential.fee_breakdown_contextual_help_govt_itemized.text
              }
            />
          )}
        </MainContentSection>
        <MainContentSection
          textAlign="center"
          md={12}
          lg={12}
          xl={10}
          bgImage={`linear-gradient(180deg, ${BoundlessPalette.backgroundMedium} 0%, ${BoundlessPalette.white} 100%)`}
          sx={{ py: 2 }}
        >
          <Typography align="center" variant="heading-md-bold" sx={{ mt: 2, mx: 1 }}>
            What you get with Boundless
          </Typography>
          <Grid container justifyContent="center" sx={{ mt: 2 }}>
            {applyingWithBoundlessData?.sales_pitches.map((pitch, index) => {
              const illustrations = ['application', 'support', 'review']
              return (
                <Grid item xs={12} sm={4} sx={{ p: 2 }} key={index}>
                  <IllustrationStampedCard
                    title={pitch.title}
                    description={pitch.body}
                    illustration={illustrations[index] as any}
                  />
                </Grid>
              )
            })}
          </Grid>
          <Box sx={{ mt: 4 }}>
            {isMobile ? <Illustration type="guarantee" size="15rem" /> : <Illustration type="guarantee-wide" />}
          </Box>
        </MainContentSection>
        <MainContentSection sx={{ py: 2 }} md={12} lg={10} bgColor={BoundlessPalette.white}>
          <Typography align="center" variant="heading-md-bold" sx={{ mt: 2 }}>
            Boundless Application Packages
          </Typography>
          <Grid container sx={{ mt: 2 }}>
            <Grid item xs={12} sm={6} sx={{ p: 2 }}>
              <ApplicationPackageCard
                type="essential"
                onPackageSelected={handlePackageSelected}
                totalPrice={priceInfo?.essential?.boundless_fee}
                monthlyPrice={priceInfo?.essential?.boundless_fee_per_month}
                numberOfChildren={priceInfo?.essential?.number_accompanying_children}
              />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ p: 2 }}>
              <ApplicationPackageCard
                type="premium"
                onPackageSelected={handlePackageSelected}
                totalPrice={priceInfo?.premium?.boundless_fee}
                monthlyPrice={priceInfo?.premium?.boundless_fee_per_month}
                numberOfChildren={priceInfo?.premium?.number_accompanying_children}
              />
            </Grid>
          </Grid>
        </MainContentSection>

        <MainContentSection
          md={12}
          lg={10}
          bgImage={`linear-gradient(180deg, ${BoundlessPalette.white} 0%, ${BoundlessPalette.backgroundMedium} 100%)`}
          sx={{ py: 2 }}
        >
          <Typography align="center" variant="heading-md-bold" sx={{ mt: 2 }}>
            How it works
          </Typography>
          <Grid container sx={{ mt: 2 }}>
            <Grid
              item
              xs={12}
              md={6}
              sx={{ p: 2, boxShadow: isMobile ? 'none' : '0px 20px 18px rgba(0, 0, 0, 0.15)' }}
              bgcolor={isMobile ? 'transparent' : BoundlessPalette.white}
            >
              <VerticalTimelineOrderedList
                items={[
                  {
                    title: 'Answer simple questions',
                    description: 'We’ll guide you through each step and fill the paperwork for you'
                  },
                  {
                    title: 'Collect required documents with help from our live support team',
                    description: 'We’ll provide a personalized checklist, based on your situation'
                  },
                  {
                    title: 'Consult with an immigration lawyer',
                    description: 'With Boundless Premium, you’ll get a consultation with an immigration lawyer',
                    premium: true
                  },
                  {
                    title: 'We review your application multiple times',
                    description: 'All applications are reviewed by an experienced application guide'
                  },
                  {
                    title: 'An immigration lawyer conducts a final review',
                    description: 'Your independent lawyer will make sure your application is ready',
                    premium: true
                  },
                  {
                    title: 'We assemble your application and send it directly to USCIS',
                    description:
                      'We use the exact format USCIS prefers, so you can avoid delays, rejections, or follow-up requests.'
                  },
                  {
                    title: 'Prepare for your interview, with lawyer support',
                    description: 'An lawyer will explain the process, so you know exactly what to expect',
                    premium: true
                  }
                ]}
              />
            </Grid>
            {!isMobile && (
              <Grid item md={6} sx={{ pt: 8 }}>
                <Illustration type="how-it-works" size="35rem" />
              </Grid>
            )}
          </Grid>
        </MainContentSection>

        <MainContentSection
          md={8}
          lg={6}
          textAlign={'center'}
          bgImage={`linear-gradient(180deg, ${BoundlessPalette.backgroundMedium} 0%, ${BoundlessPalette.white} 100%)`}
          sx={{ pt: 2 }}
        >
          <Typography variant="heading-md-bold" sx={{ mt: 2 }}>
            Guaranteed approval or your money back
          </Typography>
          <Typography sx={{ mt: 2 }}>
            With step-by-step guidance, document and form preparation, and unlimited live support, Boundless is your
            best chance of immigration success.
          </Typography>
          <Typography variant="heading-sm-bold" sx={{ mt: 2 }}>
            <strong>Starting at ${priceInfo?.essential?.boundless_fee}</strong>
          </Typography>
          <Typography color={BoundlessPalette.textLight}>
            Or ${priceInfo?.essential?.boundless_fee_per_month}/month over {getMaxMonths()} months
          </Typography>
          <Button
            analytics={{
              eventName: 'ProceedToCart',
              eventProperties: {
                ctaLocation: 'footer'
              }
            }}
            onClick={() => {
              location.href = kaseUrls[currentKaseKind].paymentPageUrl
            }}
            color="secondary"
            sx={{ mt: 4 }}
          >
            Get Started
          </Button>
        </MainContentSection>

        <MainContentSection textAlign={'center'} bgColor={BoundlessPalette.backgroundMedium} md={12} lg={8}>
          <Illustration type="real-person-2" size="6rem" />
          <Typography sx={{ mt: 2 }} variant="heading-sm-bold">
            Still have questions?
          </Typography>
          <Typography sx={{ mt: 2 }}>
            <em>Ask an Immigration Assistant</em>
          </Typography>
          {!isMobile && (
            <Grid container justifyContent="center" sx={{ mt: 0 }} spacing={2}>
              <Grid item md={4} sx={{ height: '220px', mt: '-73px' }}>
                <Illustration type="question-example-1" size="250px" />
              </Grid>
              <Grid item md={4} sx={{ height: '220px', mt: '-73px' }}>
                <Illustration type="question-example-2" size="250px" />
              </Grid>
              <Grid item md={4} sx={{ height: '220px', mt: '-73px' }}>
                <Illustration type="question-example-3" size="250px" />
              </Grid>
            </Grid>
          )}
          <Button
            onClick={handleChatButtonClicked}
            sx={{ mt: { xs: 4, md: 0 } }}
            analytics={{
              eventName: 'LaunchedChat',
              eventProperties: {
                via: 'eligibilityResults'
              }
            }}
          >
            Talk with us now
          </Button>
        </MainContentSection>
      </AnalyticsContext.Provider>
    </Box>
  )
}

function mapStateToProps(state) {
  return {
    currentKaseKind: getCurrentKaseKind(state),
    currentKaseId: getCurrentKaseId(state),
    isUserLoggedIn: isUserLoggedIn(state),
    completedEligibility: getCurrentKaseCompletedEligibility(state),
    isChatDiscoveryTestEnabled: getIsFeatureEnabled(state, FEATURES.DISCOVERABLE_CHAT),
    isAnalyticsDebugEnabled: getIsFeatureEnabled(state, FEATURES.BUI_ANALYTICS_DEBUG_ENABLED)
  }
}

function mapDispatchToActions(dispatch: Function): ActionProps {
  return {
    trackUserEvent: (event: string, data: any) => dispatch(trackUserEvent(event, data)),
    openChat: (...args) => dispatch(openChat(...args))
  }
}

export default connect(mapStateToProps, mapDispatchToActions)(PrePaymentDashboard)
