import React, { FunctionComponent, useEffect } from 'react'
import { connect } from 'react-redux'
import {
  getCurrentKase,
  getCurrentKaseKind,
  getCurrentKaseState,
  getIsConvertedKase,
  isCurrentKasePaid,
  isKaseBeingRefunded,
  isUserLoggedIn,
  getCurrentUserEmail,
  getCurrentUserPhoneNumber
} from 'reducers/selectors'

import PrePaymentDashboard from 'components/screens/dashboard/pre_payment_dashboard'
import PostPaymentDashboard from 'components/screens/dashboard/post_payment_dashboard'
import PostShipDashboard from 'components/screens/dashboard/post_ship_dashboard'
import DashboardLayout from 'components/screens/dashboard/customer_dashboard/layout'
import NewDashboardLayout from 'components/screens/dashboard/customer_dashboard/v2'
import RefundingScreen from 'components/screens/refunding_screen'
import PaymentProcessing from 'components/screens/dashboard/customer_dashboard/payment_processing'
import SetupComplete from 'components/panels/outcomes/rapid_visa_redirect/setup_complete'
import InlineSignInNav from 'components/inline_sign_in_nav'

import { KaseKind } from 'lib/constants'

interface Props {
  currentKaseKind: KaseKind
  currentKaseState: string
  isConvertedKase: boolean
  isCurrentKasePaid: boolean
  isKaseBeingRefunded: boolean
  isLoggedIn: boolean
  getCurrentUserEmail: string
  getCurrentUserPhoneNumber?: string | null
}

const DashboardRouter: FunctionComponent<Props> = ({
  currentKaseKind,
  currentKaseState,
  isConvertedKase,
  isCurrentKasePaid,
  isKaseBeingRefunded,
  isLoggedIn,
  getCurrentUserEmail,
  getCurrentUserPhoneNumber
}) => {
  const kaseKindIsGreenCard =
    currentKaseKind === KaseKind.MarriageBasedGreenCard || currentKaseKind === KaseKind.NewMarriageBasedGreenCard
  const params = new URLSearchParams(window.location.search.substring(1))
  const paymentSuccess = params.get('payment_success')

  useEffect(() => {
    if (paymentSuccess && isCurrentKasePaid) {
      //@ts-ignore
      if (window.dataLayer) {
        //@ts-ignore
        window.dataLayer.push({
          event: 'customer_cart_payment',
          enhanced_conversion_data: {
            email: getCurrentUserEmail,
            // only add phone number if it exists and remove the whitespace
            ...(getCurrentUserPhoneNumber && { phoneNumber: getCurrentUserPhoneNumber.replace(/\s/g, '') })
          }
        })
      }
    }
  }, [])

  if (isKaseBeingRefunded) {
    return <RefundingScreen />
  }

  const isPaymentProcessing = () => {
    return !!paymentSuccess && !isCurrentKasePaid
  }
  if (isPaymentProcessing()) {
    return <PaymentProcessing />
  }

  if (isCurrentKasePaid) {
    if (currentKaseKind === KaseKind.MarriageBasedGreenCard) {
      return <DashboardLayout />
    } else if (currentKaseKind === KaseKind.NewMarriageBasedGreenCard || currentKaseKind === KaseKind.K1FianceVisa) {
      if (currentKaseKind === KaseKind.K1FianceVisa && currentKaseState === 'customer_completed') {
        return <PostShipDashboard />
      } else {
        return <NewDashboardLayout />
      }
    } else {
      // Render the post-payment dashboard for NATZ
      return <PostPaymentDashboard />
    }
  } else if (kaseKindIsGreenCard) {
    if (currentKaseKind === KaseKind.NewMarriageBasedGreenCard) {
      if (isConvertedKase) {
        return <NewDashboardLayout />
      } else {
        return <PrePaymentDashboard />
      }
    } else {
      return <SetupComplete />
    }
  } else if (currentKaseKind === KaseKind.K1FianceVisa) {
    if (!isLoggedIn) {
      return <InlineSignInNav isLogIn={false} expired={false} />
    }

    return <PrePaymentDashboard />
  }

  return <PrePaymentDashboard />
}

function mapStateToProps(state) {
  return {
    currentKase: getCurrentKase(state),
    currentKaseKind: getCurrentKaseKind(state),
    currentKaseState: getCurrentKaseState(state),
    isConvertedKase: getIsConvertedKase(state),
    isCurrentKasePaid: isCurrentKasePaid(state),
    isKaseBeingRefunded: isKaseBeingRefunded(state),
    isLoggedIn: isUserLoggedIn(state),
    getCurrentUserEmail: getCurrentUserEmail(state),
    getCurrentUserPhoneNumber: getCurrentUserPhoneNumber(state)
  }
}

export default connect(mapStateToProps, null)(DashboardRouter)
