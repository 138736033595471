import React from 'react'

const LetterIllustration = () => (
  <svg width="58" height="56" viewBox="0 0 58 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.3"
      d="M29 -0.00057474L58 20.7811L58 23.6055L29 45.1438L5.69764e-06 23.6055L5.94455e-06 20.7811L29 -0.00057474Z"
      fill="#C4C4C4"
    />
    <path d="M49.0316 5.979H8.96973V33.3133L29.0007 46.6388L49.0316 33.3133V5.979Z" fill="white" stroke="#3243B2" />
    <path d="M0 20.9275L29 41.5564L58 20.9275V55.01H0V20.9275Z" fill="#C1C1C1" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M37.8895 22.3477C37.8895 27.1327 34.0104 31.0118 29.2253 31.0118C24.4403 31.0118 20.5612 27.1327 20.5612 22.3477C20.5612 17.5626 24.4403 13.6835 29.2253 13.6835C34.0104 13.6835 37.8895 17.5626 37.8895 22.3477ZM29.2253 32.4558C34.8079 32.4558 39.3335 27.9302 39.3335 22.3477C39.3335 16.7651 34.8079 12.2395 29.2253 12.2395C23.6428 12.2395 19.1172 16.7651 19.1172 22.3477C19.1172 27.9302 23.6428 32.4558 29.2253 32.4558ZM29.9474 21.9682V16.0902H28.5033V22.8475L33.1454 25.2459L33.8083 23.963L29.9474 21.9682Z"
      fill="#3243B2"
    />
  </svg>
)

export default LetterIllustration
