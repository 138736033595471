import React, { FunctionComponent, useState, useEffect } from 'react'
import { connect } from 'react-redux'

import { DocumentUploadSummaryStates, KaseKind } from 'lib/constants'

import {
  allSectionsComplete,
  getCurrentDocumentUploadSummaryState,
  getCurrentKaseKind,
  isWorkflowCategoryCP2
} from 'reducers/selectors'

import IconModal from 'components/modals/icon_modal'
import Paragraph from 'components/type/paragraph'

interface MappedProps {
  allDocumentsCompleted: boolean
  allSectionsComplete: boolean
  currentKaseKind: KaseKind
  isWorkflowCategoryCP2: boolean
}

export const IncompleteQuestionnairesModal: FunctionComponent<MappedProps> = ({
  allDocumentsCompleted,
  allSectionsComplete,
  currentKaseKind,
  isWorkflowCategoryCP2
}) => {
  if (currentKaseKind !== KaseKind.MarriageBasedGreenCard || isWorkflowCategoryCP2) {
    return null
  }

  const shouldShowModal = allDocumentsCompleted && !allSectionsComplete
  const [showModal, setShowModal] = useState(shouldShowModal)

  useEffect(() => {
    setShowModal(allDocumentsCompleted && !allSectionsComplete)
  }, [allDocumentsCompleted])

  const closeModal = () => {
    setShowModal(false)
  }

  return (
    <IconModal
      showModal={showModal}
      closeModal={closeModal}
      headerContent="Heads-up: You have incomplete questionnaires"
      variant="warning"
    >
      <Paragraph spacing="lg">
        Please return to your dashboard to answer any outstanding questions so we can begin Quality Assurance.
      </Paragraph>
      <a className="c-btn c-btn--primary o-block w-full" href="/timeline">
        Return to Dashboard
      </a>
    </IconModal>
  )
}

const mapStateToProps = (state: any): MappedProps => {
  return {
    allDocumentsCompleted:
      getCurrentDocumentUploadSummaryState(state) === DocumentUploadSummaryStates.AllSectionsCustomerCompleted,
    allSectionsComplete: allSectionsComplete(state),
    currentKaseKind: getCurrentKaseKind(state),
    isWorkflowCategoryCP2: isWorkflowCategoryCP2(state)
  }
}

export default connect(mapStateToProps)(IncompleteQuestionnairesModal)
