import React, { FunctionComponent, ChangeEvent, useEffect } from 'react'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import cx from 'classnames'

import { acceptedFileTypesForDocumentRequest } from 'lib/accepted_files_types'
import { addUploadsToRequest, deleteDocument, fetchDocumentRequests } from 'actions/document_request_actions'
import { uploadRequestedFiles, uploadRequestedFileFinished, uploadRequestedFileProgress } from 'actions/upload_actions'

import { postTelemetry } from 'actions/telemetry_actions'

import { TELEMETRY_EVENTS, DocumentRequestCustomerStatus } from 'lib/constants'
import UploadQueue, { SignResult, UploadResult } from 'lib/upload_queue'
import S3Upload, { UploadProgress } from 'lib/admin/s3_upload'
import {
  isCurrentUserAdmin,
  getCurrentKaseId,
  getDocumentUploadLocation,
  getWorkflowStatus,
  getUploadsForDocumentRequest,
  slaIsSet
} from 'reducers/selectors'
import { getDocRequestCustomerStatus } from 'components/screens/document_upload/utils'

import Heading from 'components/type/heading'
import Paragraph from 'components/type/paragraph'
import Switch, { Case } from 'components/switch'
import FancySpinner from 'components/admin/fancy_spinner'
import MobileListedDocuments from './mobile_listed_documents'
import LinkifyText from 'components/linkify_text'

interface UploadingDoc extends DocumentModel {
  progress: number
  rawFile: File
}

interface ExplicitProps {
  setRequestStatusAutomatically?: boolean
  documentRequest?: DocumentRequestModel
  revealFirstAutoSubmissionModal?: () => void
  uploadDisabled?: boolean
}

interface ActionProps {
  deleteDocument: typeof deleteDocument
  fetchDocumentRequests: typeof fetchDocumentRequests
  addUploadsToRequest: typeof addUploadsToRequest
  uploadRequestedFiles: typeof uploadRequestedFiles
  uploadRequestedFileFinished: typeof uploadRequestedFileFinished
  uploadRequestedFileProgress: typeof uploadRequestedFileProgress
  postTelemetry: typeof postTelemetry
}

interface MappedProps {
  kaseId: number
  isCurrentUserAdmin: boolean
  workflowStatus: string
  documentUploadLocation?: string
  uploads: UploadingDoc[]
  slaIsSet: boolean
}

type Props = ExplicitProps & ActionProps & MappedProps

const MobileDocUploadArea: FunctionComponent<Props> = ({
  documentRequest,
  documentUploadLocation,
  uploadRequestedFiles,
  isCurrentUserAdmin,
  workflowStatus,
  fetchDocumentRequests,
  kaseId,
  uploadRequestedFileFinished,
  uploadRequestedFileProgress,
  addUploadsToRequest,
  postTelemetry,
  uploads,
  slaIsSet
}) => {
  /**
   * When the UploadQueue is processed, it calls this method to upload docs to
   * the server and update the document requests in the Redux store.
   */
  const onAllUploadsComplete = (uploadResults: UploadResult[]) => {
    addUploadsToRequest({
      documentRequest,
      uploadResults,
      isAdmin: isCurrentUserAdmin,
      updateRequestStatus: false
    })
      .then(() => fetchDocumentRequests({ kaseId }))
      .then(() => {
        uploadResults.forEach((result) => {
          uploadRequestedFileFinished({
            rawFile: result.rawFile,
            uploadingBy: 'UploadDialog',
            documentType: documentRequest.document_type,
            workflowStatus: workflowStatus
          })

          postTelemetry(TELEMETRY_EVENTS.UPLOADED_DOCUMENT, {
            fileSize: result.rawFile.size,
            documentType: documentRequest.document_type,
            documentContentType: result.signResult.contentType,
            uploadingFrom: documentUploadLocation,
            uploadingBy: 'UploadDialog',
            workflowStatus: workflowStatus,
            _analyticsIntegrations: {
              All: true,
              AutopilotHQ: false
            }
          })
        })
      })
  }

  let uploadQueue

  // TODO: there's probably a better way to do this, originally this was a useRef hook,
  // but because docRequest is null on load, was not adding the ref? Need to make this better
  useEffect(() => {
    uploadQueue = new UploadQueue({
      onUploadsComplete: onAllUploadsComplete
    })
  }, [documentRequest, uploads])

  const onS3UploadComplete = (signResult: SignResult, rawFile: File) => {
    uploadQueue.addResult({ rawFile, signResult })
  }

  const onUploadRequestedFileProgress = (percent: number, status: UploadProgress, rawFile: File) => {
    uploadRequestedFileProgress({
      percent,
      status,
      rawFile
    })
  }

  /**
   * Triggered when the input receives one or more files. It adds files to the
   * UploadQueue, which takes care of processing everything.
   * TODO add a progress bar
   */
  const processUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(event.target.files)

    uploadQueue.addCount(files.length)

    uploadRequestedFiles({
      files,
      documentRequest
    })

    new S3Upload(files, kaseId, {
      onProgress: onUploadRequestedFileProgress,
      onUploadComplete: onS3UploadComplete
    })
  }

  let acceptedFileTypes = ''
  let documentsState = 'IsLoading'
  let docRequestStatus = ''

  if (documentRequest != null) {
    docRequestStatus = getDocRequestCustomerStatus(documentRequest, slaIsSet)
    acceptedFileTypes = acceptedFileTypesForDocumentRequest(documentRequest).types.join(',')
    if (documentRequest.documents.length > 0 || uploads.length > 0) {
      documentsState = 'HasDocuments'
    } else {
      documentsState = 'HasNoDocuments'
    }
  }

  const disableUpload =
    docRequestStatus === DocumentRequestCustomerStatus.SentToBoundless ||
    docRequestStatus === DocumentRequestCustomerStatus.DoneUploading ||
    documentRequest == null

  const uploadDocsClasses = cx('c-type py-6 c-type--body-sans-sm', {
    't-color--primary': !disableUpload
  })

  if (!documentRequest) {
    return null
  }

  return (
    <div>
      {documentRequest.comment && documentRequest.comment.body && (
        <div className="o-box c-tooltip--warning o-layout--padded-x">
          <p className="o-block o-block--compact c-type c-type--subhead-sm">Comment from Boundless</p>
          <Paragraph size="sm">
            <LinkifyText>{documentRequest.comment.body}</LinkifyText>
          </Paragraph>
        </div>
      )}
      <div className="o-layout--padded-x o-box--light flex items-center justify-between">
        <Heading tag="p" subhead size="sm" spacing="none">
          Your Documents
        </Heading>
        <label htmlFor="mobile-file-upload" className={uploadDocsClasses}>
          Upload Documents
        </label>
        <input
          style={{ display: 'none' }}
          id="mobile-file-upload"
          type="file"
          accept={acceptedFileTypes}
          onChange={processUpload}
          disabled={disableUpload}
        />
      </div>
      <Switch state={documentsState}>
        <Case value="IsLoading">
          <div className="o-box">
            <Paragraph centered size="sm">
              Loading your documents...
            </Paragraph>
            <div className="o-block o-layout--center">
              <FancySpinner variation="primary" />
            </div>
          </div>
        </Case>
        <Case value="HasNoDocuments">
          <div className="o-box mx-auto w-3/4">
            <Paragraph size="sm" centered>
              Please follow the document instructions above to finish this section.
            </Paragraph>
          </div>
        </Case>
        <Case value="HasDocuments">
          <MobileListedDocuments documentRequest={documentRequest} kaseId={kaseId} uploads={uploads} />
        </Case>
      </Switch>
    </div>
  )
}

function mapStateToProps(state: any, ownProps: Props): MappedProps {
  return {
    isCurrentUserAdmin: isCurrentUserAdmin(state),
    kaseId: getCurrentKaseId(state),
    workflowStatus: getWorkflowStatus(state),
    documentUploadLocation: getDocumentUploadLocation(state),
    uploads: getUploadsForDocumentRequest(state, ownProps.documentRequest),
    slaIsSet: slaIsSet(state)
  }
}

function mapDispatchToActions(dispatch: Dispatch): ActionProps {
  return bindActionCreators(
    {
      deleteDocument,
      fetchDocumentRequests,
      addUploadsToRequest,
      uploadRequestedFiles,
      uploadRequestedFileFinished,
      uploadRequestedFileProgress,
      postTelemetry
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToActions)(MobileDocUploadArea)
