// base guidelines for households of size 1
// https://aspe.hhs.gov/poverty-guidelines
// https://www.uscis.gov/i-864p
const BASE_HAWAII = 14680
const BASE_ALASKA = 15950
const BASE_LOWER_48 = 12760

const PER_EXTRA_MEMBER_HAWAII = 5150
const PER_EXTRA_MEMBER_ALASKA = 5600
const PER_EXTRA_MEMBER_LOWER_48 = 4480

const baseGuideline = (base: number, perExtraMember: number, householdSize: number, multiplier: number) =>
  Math.floor((base + (householdSize - 1) * perExtraMember) * multiplier)

const hawaiiGuideline = (householdSize: number, multiplier: number) =>
  baseGuideline(BASE_HAWAII, PER_EXTRA_MEMBER_HAWAII, householdSize, multiplier)

const alaskaGuideline = (householdSize: number, multiplier: number) =>
  baseGuideline(BASE_ALASKA, PER_EXTRA_MEMBER_ALASKA, householdSize, multiplier)

const lower48Guideline = (householdSize: number, multiplier: number) =>
  baseGuideline(BASE_LOWER_48, PER_EXTRA_MEMBER_LOWER_48, householdSize, multiplier)

export const provincialPovertyGuideline = (province: string, householdSize: number, multiplier: number) => {
  switch (province) {
    case 'HI':
      return hawaiiGuideline(householdSize, multiplier)
    case 'AK':
      return alaskaGuideline(householdSize, multiplier)
    default:
      return lower48Guideline(householdSize, multiplier)
  }
}
