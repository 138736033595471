import React, { FunctionComponent } from 'react'

import Accordion from 'components/accordion'
import ArrowIllustration from 'components/illustrations/arrow_illustration'
import DashboardSearchIllustration from 'components/illustrations/dashboard_search_illustration'
import ExternalLink from 'components/external_link'
import Paragraph from 'components/type/paragraph'

import { AOS_TIMELINE_STEPS } from './content'

const CustomerCompletedAOS: FunctionComponent = () => {
  return (
    <div className="o-block o-box o-box--light c-dashboard__work-area o-layout--impose">
      <div className="o-griddle__col px-0 pb-8">
        <div className="o-griddle__row o-box pb-0">
          <div className="o-griddle__col--hide o-griddle__col--show-sm-up o-griddle__col--auto pl-0">
            <ArrowIllustration />
          </div>
          <div className="o-griddle__col pl-4">
            <h2 className="o-block c-type c-type--headline-md c-type--emphasized">
              Next: Create an Online USCIS Account
            </h2>
            <Paragraph>
              To create an online account on USCIS.gov, please follow these{' '}
              <ExternalLink href="https://www.uscis.gov/file-online/how-to-create-a-uscis-online-account">
                instructions
              </ExternalLink>
              . Creating an account will allow USCIS to send you email updates on your application after the agency has
              provided your "receipt numbers" (official codes from USCIS confirming that they've received your
              application package).
            </Paragraph>
          </div>
        </div>

        <div className="o-griddle__row o-box pb-0">
          <div className="o-griddle__col--hide o-griddle__col--show-sm-up o-griddle__col--auto pl-0">
            <DashboardSearchIllustration />
          </div>
          <div className="o-griddle__col pl-4 mt-4">
            <h3 className="o-block c-type c-type--headline-sm c-type--emphasized">Check Your Case Status</h3>
            <Paragraph>
              <ExternalLink href="https://egov.uscis.gov/casestatus/landing.do">Enter the receipt number </ExternalLink>
              on your USCIS receipt notices to stay updated on the status of your application. Please keep in mind that
              though this is usually one of the quickest, most effective ways to track your application, occasionally
              the site is not updated as it should be.
            </Paragraph>
          </div>
        </div>
      </div>

      <h3 className="mb-3 mt-5 text-black">Timeline for Spouse Seeking a Green Card After Filing:</h3>
      <hr className="c-divider c-divider--primary c-divider--emphasized" />
      <ul className="o-block c-list">
        {AOS_TIMELINE_STEPS?.map((step, index) => (
          <li key={step.title}>
            <Accordion
              className="border-l-0 border-r-0 border-t-0 py-4"
              noXPadding={true}
              header={
                <div className="flex justify-between items-center">
                  <span className="c-type--bold">
                    {index + 1}. {step.title}
                  </span>
                  <span className="u-hide@md-down pr-1">Learn More</span>
                </div>
              }
            >
              {step.description}
            </Accordion>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default CustomerCompletedAOS
