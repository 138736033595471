import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'

import CircleCheckmark from 'components/icons/circle_checkmark'
import classNames from 'classnames'

import { TELEMETRY_EVENTS } from 'lib/constants'
import { postTelemetry } from 'actions/telemetry_actions'
import { getSectionDisplayName } from 'components/screens/document_upload/utils'
import Paragraph from 'components/type/paragraph'

interface ActionProps {
  trackSectionAction?: (eventName: string, { currentPhase: string, sectionName: string }) => void
}

interface SectionItemProps {
  name: string
  customTitle?: string
  customCTA?: string
  url: string
  phaseName: string
  progress: number
  hideProgress?: boolean
  empty?: boolean
  dashboard_meta: {
    ordinal: number
    disable_when_complete?: boolean
    is_post_ila_section?: boolean
  }
}

type Props = ActionProps & SectionItemProps

/**
 * Possible states of a section:
 * - not started: progress === 0
 * - started: progress > 0 && progress < 100
 * - complete: progress === 100
 * - complete and disabled:
 *     progress === 100 && dashboardMeta.disable_when_complete === true
 */

const SectionItem: FunctionComponent<Props> = ({
  name,
  customTitle,
  customCTA,
  url,
  phaseName,
  progress = 0,
  hideProgress,
  empty,
  dashboard_meta,
  trackSectionAction
}) => {
  const sectionTitle = customTitle || getSectionDisplayName(name)
  let progressText
  if (!hideProgress && progress > 0 && progress < 100) {
    progressText = `${progress}% complete`
  }

  const trackSectionClick = (telemetryEvent) => () => {
    trackSectionAction(telemetryEvent, {
      CurrentPhase: phaseName,
      SectionName: name
    })
  }

  const renderCTAs = () => {
    if (customCTA) {
      return (
        <div className="o-griddle__col--12 o-griddle__col--auto-sm-up py-1">
          <a href={url} className="c-btn c-btn--primary" onClick={trackSectionClick(TELEMETRY_EVENTS.VISITED_SECTION)}>
            {customCTA}
          </a>
        </div>
      )
    }

    if (empty) {
      return (
        <div className="c-type c-type--body-sans-sm c-type--italic">
          Complete more of your petition to begin this section.
        </div>
      )
    }

    if (progress < 100) {
      const sectionStarted = progress > 0
      const trackingActionName = sectionStarted ? TELEMETRY_EVENTS.CONTINUED_SECTION : TELEMETRY_EVENTS.STARTED_SECTION

      return (
        <div className="o-griddle__col--12 o-griddle__col--auto-sm-up py-1">
          <a href={url} className="c-btn c-btn--primary" onClick={trackSectionClick(trackingActionName)}>
            {sectionStarted ? 'Continue' : 'Begin'}
          </a>
        </div>
      )
    }

    if (progress === 100 && dashboard_meta.disable_when_complete) {
      return (
        <div className="o-griddle__col--12 o-griddle__col--auto-sm-up py-3">
          <p className="c-type c-type--body-sans-md pr-6">Complete</p>
        </div>
      )
    }

    return (
      <div className="o-griddle__col--12 o-griddle__col--auto-sm-up py-1">
        <a href={url} className="c-btn c-btn--secondary" onClick={trackSectionClick(TELEMETRY_EVENTS.VISITED_SECTION)}>
          Update
        </a>
      </div>
    )
  }

  return (
    <li
      className={classNames(
        'c-list__item',
        'o-griddle__row',
        'o-griddle__row--no-gutters',
        'o-griddle__row--align-items-center',
        {
          'c-list__item--disabled': progress === 100 && dashboard_meta.disable_when_complete
        }
      )}
      style={{ minHeight: '5rem' }}
    >
      <div className="o-griddle__col--auto py-3 pr-2">
        <CircleCheckmark checked={progress === 100} />
      </div>
      <div className="o-griddle__col py-3">
        <Paragraph spacing="none" color="emphasized">
          {sectionTitle}
        </Paragraph>{' '}
        {progressText && (
          <Paragraph size="sm" color="warning" spacing="none" className="mt-3">
            {progressText}
          </Paragraph>
        )}
      </div>
      {renderCTAs()}
    </li>
  )
}

function mapDispatchToActions(dispatch: Function): ActionProps {
  return {
    trackSectionAction: (eventName, params) => dispatch(postTelemetry(eventName, params))
  }
}

export default connect(null, mapDispatchToActions)(SectionItem)
