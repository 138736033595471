import _compact from 'lodash/compact'
import _isObject from 'lodash/isObject'
import _map from 'lodash/map'

import { pathToArray } from '../../path_helpers'

import dateRange from './date_range'
import dateRangeWithTimeframe from './date_range_with_timeframe'
import phoneFormat from './phone_format'

import { postalRequiredIfUSAddress, provinceRequiredIfUSAddress } from './required_if_us_address'

import { validatesIf } from 'lib/validations/validates_if'

import required from './required'
import { requiredIfHasLanguageSkills, requiredIfHasOccupationalSkills } from './required_if_has_skills'
import requiredIfLiabilityExists from './required_if_liability_exists'
import requiredIfNativeLanguageIsNonRoman from './required_if_native_language_is_non_roman'
import { requiredDependingOnTaxReturnReason } from './tax_return_validations'
import validSsn from './ssn'
import uniqueEmail from './unique_email'
import validDate from './valid_date'
import validDateOrOverride from './valid_date_or_override'
import validEmail from './valid_email'
import collectionCount from './collection_count'
import validDateNotFuture from './valid_date_not_future'

import { PathValidationConfig, PathValidationMessage } from '..'
import requiredIfHasFeeWaivers from 'lib/validations/validators/required_if_has_fee_waivers'

export interface ValidatorArguments {
  path: string
  data: any
  options: { [key: string]: any }
  getModelData: (path: string) => any
}

type ValidatorSyncResult = PathValidationMessage[]
type ValidatorAsyncResult = Promise<ValidatorSyncResult>

export type ValidatorResult = ValidatorSyncResult | ValidatorAsyncResult

export interface Validator {
  (args: ValidatorArguments): ValidatorResult
  _async?: boolean
}

interface Validators {
  [validatorName: string]: Validator
}

interface FilteredValidator {
  async: boolean
  func: Validator
  name: string
  options: { [key: string]: any }
}

export const validators: Validators = {
  collectionCount,
  dateRange,
  dateRangeWithTimeframe,
  phoneFormat,
  postalRequiredIfUSAddress,
  provinceRequiredIfUSAddress,
  required,
  requiredDependingOnTaxReturnReason,
  requiredIfHasFeeWaivers,
  requiredIfHasLanguageSkills,
  requiredIfHasOccupationalSkills,
  requiredIfLiabilityExists,
  requiredIfNativeLanguageIsNonRoman,
  validSsn,
  uniqueEmail,
  validDate,
  validDateOrOverride,
  validEmail,
  validDateNotFuture
}

function getPathConfig(configSlice: any, currentPathParts: string[]) {
  if (!configSlice) {
    return null
  } else if (currentPathParts.length > 0) {
    let key = currentPathParts.shift()
    if (!configSlice.hasOwnProperty(key)) key = '$'

    return getPathConfig(configSlice[key], currentPathParts)
  } else {
    return configSlice || null
  }
}

export function buildMessage(text: string): PathValidationMessage {
  return {
    type: 'error',
    text
  }
}

export function getValidatorsAtPath(path: string, validationConfig: PathValidationConfig): FilteredValidator[] {
  const pathConfig = getPathConfig(validationConfig, pathToArray(path))

  if (!pathConfig) return []

  const pathValidators = _map(pathConfig, (options: any, name: string) => {
    let validationFn = validators[name]

    if (!options || !validationFn) return null

    if (!_isObject(options)) options = {}

    // If this is a conditional validation, wrap it with validatesIf
    if (options.if) {
      const { condition, ...conditionArgs } = options.if

      validationFn = validatesIf({
        conditionFnName: condition,
        conditionArgs
      })(validationFn)
    }

    return {
      async: Boolean(validationFn._async),
      name,
      func: validationFn,
      options
    }
  })

  return _compact(pathValidators)
}
