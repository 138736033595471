import React, { ReactNode, FunctionComponent } from 'react'
import cx from 'classnames'

import ControlledModalWrapper from './controlled_modal_wrapper'
import Heading from 'components/type/heading'
import CloseIcon from 'components/icons/close_icon'
import Markdown from 'components/markdown'

interface Props {
  header: ReactNode
  imageUrl?: string
  isOpen?: boolean
  modalWidth?: string | number
  onRequestClose: () => void
  text: string
}

const ContextualHelpModal: FunctionComponent<Props> = ({
  header,
  imageUrl,
  isOpen,
  modalWidth = '50rem',
  onRequestClose,
  text
}) => (
  <ControlledModalWrapper onRequestClose={onRequestClose} isOpen={isOpen} style={{ width: modalWidth }}>
    <div className="c-modal__content--original">
      <header className="c-modal__header--original">
        <Heading tag="h3" color="emphasized" size="sm" spacing="none">
          {header}
        </Heading>
        <button className="c-close" type="button" title="Dismiss this modal" onClick={onRequestClose}>
          <CloseIcon />
        </button>
      </header>
      <div className="p-8">
        <div className={cx({ 'lg:flex lg:justify-between': imageUrl })}>
          <Markdown
            source={text}
            className={cx('c-type--react-markdown o-block c-type c-type--body-serif-md c-type--emphasized', {
              'lg:pr-4': imageUrl
            })}
            options={{ linkToNewTabs: true }}
          />

          {imageUrl && <img src={imageUrl} className="sm:w-full lg:w-1/2 lg:pl-4"></img>}
        </div>
        <div className="o-grid--fluid pt-8">
          <div className="o-grid__cell--fill o-grid__cell--off@sm o-block@sm-down">
            <button className="c-btn c-btn--secondary o-grid__cell--off@sm" onClick={onRequestClose}>
              GOT IT
            </button>
          </div>
        </div>
      </div>
    </div>
  </ControlledModalWrapper>
)

export default ContextualHelpModal
