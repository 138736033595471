import { EmploymentHistoryStore } from '../employment_histories'

export const getKaseSponsorEmploymentHistories = (employmentHistories: EmploymentHistoryStore) => {
  if (!employmentHistories) return []
  return employmentHistories.employmentHistories.sponsor
}

export const getKaseBeneficiaryEmploymentHistories = (employmentHistories: EmploymentHistoryStore) => {
  if (!employmentHistories) return []
  return employmentHistories.employmentHistories.beneficiary
}

export const isLoadingEmploymentHistories = (employmentHistories: EmploymentHistoryStore) => {
  if (!employmentHistories) return true
  return employmentHistories.loadingEmploymentHistories
}
