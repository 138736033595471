import React, { FunctionComponent, useState } from 'react'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'

import { closeModal } from 'actions/modal_actions'
import { updateWorkflowStatus } from 'actions/kase_actions'
import api from 'lib/api'

import Button from 'components/button'
import ModalWithHeader from 'components/modals/modal/modal_with_header'
import ArrowIcon from 'components/icons/arrow_icon'
import { getCurrentKaseId } from 'reducers/selectors'

interface ExplicitProps {
  isActive?: boolean
}

interface MappedProps {
  kaseId: number
}

interface ActionProps {
  closeModal: () => void
  updateWorkflowStatus: (workflowStatus: string) => void
}

type Props = ExplicitProps & MappedProps & ActionProps

const CustomerMoveToQAModal: FunctionComponent<Props> = ({ isActive, closeModal, kaseId, updateWorkflowStatus }) => {
  const [moveToQAInProcess, setMoveToQAInProcess] = useState(false)
  const [showError, setShowError] = useState(false)

  const closeConfirmationModal = (moveToQA) => () => {
    if (moveToQA) {
      setMoveToQAInProcess(true)

      api.kases
        .moveKaseToQA({ kaseId, movedByCustomer: true })
        .then((response) => {
          const { new_workflow_status } = response.data

          setMoveToQAInProcess(false)
          updateWorkflowStatus(new_workflow_status)
          closeModal()
        })
        .catch(() => {
          setShowError(true)
          setMoveToQAInProcess(false)
        })
    } else {
      setShowError(false)
      closeModal()
    }
  }

  return (
    <ModalWithHeader
      header="Are you ready to begin your assessment?"
      isOpen={isActive}
      onRequestClose={closeConfirmationModal(false)}
      modalWidth="40rem"
      body={
        <div className="px-3 py-6">
          <p className="o-block o-block--copious c-type c-type--body-sans-md">
            The rest of the application process will go quicker if your information is accurate and complete at this
            point. It’s a good time to double check everything now before your lawyer begins their assessment.
          </p>
          {/* TODO: standardize error handling from API calls */}
          {showError && (
            <div className="o-block c-alert c-alert--danger">
              <p className="c-type c-type--body-sans-md">
                Something went wrong. Please make sure you're connected to the internet and try again. If this error
                persists, please <a href="mailto:help@boundless.com">contact our support team</a>.
              </p>
            </div>
          )}
          <div className="o-grid--fluid">
            <div className="o-grid__cell--fill o-grid__cell--off@sm o-block@sm-down">
              <Button label="No, go back to my info" color="secondary" onClick={closeConfirmationModal(false)} />
            </div>
            <div className="o-grid__cell--fill o-grid__cell--off@sm o-layout--right">
              <Button
                label="Yes, I'm Ready"
                color="primary"
                className="c-btn--block@sm-down"
                icon={<ArrowIcon arrowDirection="right" />}
                isLoading={moveToQAInProcess}
                onClick={closeConfirmationModal(true)}
              />
            </div>
          </div>
        </div>
      }
    />
  )
}

function mapStateToProps(state) {
  return {
    kaseId: getCurrentKaseId(state)
  }
}

function mapDispatchToActions(dispatch: Dispatch): ActionProps {
  return bindActionCreators({ closeModal, updateWorkflowStatus }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToActions)(CustomerMoveToQAModal)
