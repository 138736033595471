import React from 'react'

import UnitType from 'components/forms/inputs/unit_info/unit_type_input'
import UnitNumber from 'components/forms/inputs/unit_info/unit_number_input'

const addressTypes = {
  APT: 'APT',
  FLR: 'Floor',
  STE: 'Suite'
}

interface Props {
  disabled?: boolean
  id: string
  path: string
}

export default class UnitInfo extends React.Component<Props> {
  render() {
    const { disabled, id, path } = this.props

    return (
      <React.Fragment>
        <div key="apt-container" className="o-grid--fluid">
          <div className="o-grid__cell--7/12 o-grid__cell--off@sm">
            <div className="o-grid--fluid o-block c-paper-form__group">
              {Object.keys(addressTypes).map((type) => (
                <UnitType
                  containerClassName="o-grid__cell--4/12"
                  key={type}
                  type={type}
                  label={addressTypes[type]}
                  path={`${path}.unit_type`}
                />
              ))}
            </div>
          </div>

          <div className="o-grid__cell--5/12 o-grid__cell--off@sm">
            <div className="o-grid--fluid o-block c-paper-form__group">
              <label
                className="o-grid__cell--6/12 c-paper-form__label c-paper-form__label--border-on@sm c-paper-form__segment"
                htmlFor={`${id}-unit-number`}
              >
                <span className="c-type c-type--subhead-sm">Unit Number</span>
              </label>

              <div className="o-grid__cell--6/12 c-paper-form__segment">
                <UnitNumber disabled={disabled} id={id} path={`${path}.unit_number`} />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}
