import React, { FunctionComponent, Fragment } from 'react'
import cx from 'classnames'
import _groupBy from 'lodash/groupBy'

import ChevronIcon from 'components/icons/chevron_icon'
import CollapsibleOutlineRequest from './collapsible_outline_request'

import { DocumentRequestStatus, DocumentRequestCustomerStatus } from 'lib/constants'
import RequestStatusIndicator from './request_status_indicator'

import { hasCustomerActionItems } from './utils'
import { NestedDocumentRequest } from 'reducers/cross_domain_selectors'
import { withCurlyQuotes } from 'lib/utilities/text_parsing'

interface Props {
  /**
   * An array of document requests to render in this section.
   */
  documentRequests?: DocumentRequestModel[]
  /**
   * An array of nested document requests to render in this section.
   * The requests will be displayed after the `title` of the sub-section.
   */
  nestedDocumentRequestCategories?: NestedDocumentRequest[]
  /**
   * The title of this section.
   */
  title: string
  /**
   * The ID of the document request that is currently dispayed.
   */
  activeRequestId?: string
  /**
   * Callback that's triggered when a document request is clicked.
   */
  onRequestClicked: (requestId: string) => void
  /**
   * Determines whether request has been submitted for review or not
   */
  slaIsSet: boolean
  /**
   * Determines whether the section is open.
   */
  isActiveCategory
  /**
   * Callback that's triggered when a section is clicked.
   */
  onSectionClicked: () => void
}

const CollapsibleOutlineSection: FunctionComponent<Props> = ({
  documentRequests,
  title,
  onRequestClicked,
  activeRequestId,
  nestedDocumentRequestCategories,
  slaIsSet,
  isActiveCategory,
  onSectionClicked
}) => {
  const allRequests: DocumentRequestModel[] = []
  if (documentRequests != null) {
    allRequests.push(...documentRequests)
  }

  if (nestedDocumentRequestCategories != null) {
    nestedDocumentRequestCategories.forEach((category) => {
      allRequests.push(...category.documentRequests)
    })
  }

  // Keep track of the open/closed status of the <details>
  // It's a little weird but hey https://github.com/facebook/react/issues/15486
  const onToggle = (event) => {
    event.preventDefault()
    onSectionClicked()
  }

  const mostUrgentRequestStatus = () => {
    if (!allRequests.length) return
    const requestsByStatus = _groupBy(allRequests, (req) => req.status)

    // Only requests with needs_customer_action or accepted statuses result in a display icon
    const needCustomerActionRequests = requestsByStatus[DocumentRequestStatus.NeedsCustomerAction] || []
    const acceptedRequests = requestsByStatus[DocumentRequestCustomerStatus.Accepted] || []

    // This assumes that the doc request status is updated to match any documents that have been
    // reviewed, but it seems like common practice, so I feel okay relying on that being true
    if (needCustomerActionRequests.length > 0) {
      // if the request has a comment or a document has 'needs_customer_action'
      const requestHasActiveAction = allRequests.some((req) => hasCustomerActionItems(req))

      if (requestHasActiveAction) {
        return DocumentRequestCustomerStatus.SeeComment
      }

      return DocumentRequestCustomerStatus.ToDo
    }

    // this should only show if ALL requests are accepted
    if (acceptedRequests.length === allRequests.length) {
      return DocumentRequestCustomerStatus.Accepted
    }

    return null
  }

  const currentSectionStatus = mostUrgentRequestStatus()

  return (
    <details open={isActiveCategory} className="o-action-items__details">
      <summary onClick={onToggle}>
        <div className="o-action-items__outline-category o-griddle__row o-griddle__row--no-gutters o-griddle__row--align-items-center">
          <div className="o-griddle__col flex items-center">
            <RequestStatusIndicator customerStatus={currentSectionStatus} iconOnly />
            <span
              className={cx('o-action-items__category-name flex', {
                'is-active': isActiveCategory,
                'needs-action': currentSectionStatus === DocumentRequestCustomerStatus.SeeComment
              })}
            >
              {withCurlyQuotes(title)}
            </span>
          </div>
          <div className="o-griddle__col--auto">
            <ChevronIcon size="small" chevronDirection={isActiveCategory ? 'up' : 'down'} />
          </div>
        </div>
      </summary>

      {documentRequests && (
        <ul className="o-list-bare">
          {documentRequests.map((request) => (
            <CollapsibleOutlineRequest
              key={request.id}
              request={request}
              activeRequestId={activeRequestId}
              onRequestClicked={onRequestClicked}
              slaIsSet={slaIsSet}
            />
          ))}
        </ul>
      )}
      {nestedDocumentRequestCategories &&
        nestedDocumentRequestCategories.map((category) => (
          <Fragment key={category.title}>
            <header className="mt-2 mb-1 c-type c-type--subhead-sm o-action-items__sub-category-name">
              {category.title}
            </header>
            <ul className="o-list-bare">
              {category.documentRequests.map((request) => (
                <CollapsibleOutlineRequest
                  key={request.id}
                  request={request}
                  activeRequestId={activeRequestId}
                  onRequestClicked={onRequestClicked}
                  slaIsSet={slaIsSet}
                />
              ))}
            </ul>
          </Fragment>
        ))}
    </details>
  )
}

export default CollapsibleOutlineSection
