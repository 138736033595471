import React, { FunctionComponent, useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import cx from 'classnames'

import Paragraph from 'components/type/paragraph'
import { WORKFLOW_STATUSES } from 'lib/constants'
import DashboardEmailIllustration from 'components/illustrations/dashboard_email_illustration'
import SectionItemBasic from 'components/screens/dashboard/customer_dashboard/section_item_basic'
import Heading from 'components/type/heading'
import formatSectionUrl from 'lib/utilities/format_section_url'
import FancySpinner from 'components/admin/fancy_spinner'

import {
  getCurrentKaseId,
  hasIssues,
  isLoadingTasks,
  isLoadingIssues,
  getIssuesProgress,
  allIssuesAddressed
} from 'reducers/selectors'
import { loadTasks } from 'actions/tasks_actions'
import { loadIssues } from 'actions/issue_actions'
import CheckmarkIcon from 'components/icons/checkmark_icon'

interface ExplicitProps {
  workflowStatus: string
}

interface MappedProps {
  allIssuesAddressed: boolean
  hasIssues: boolean
  isLoading: boolean
  kaseId: number
  percentIssuesCompleted: number
}

interface MappedActions {
  loadTasks: Function
  loadIssues: Function
}

type Props = ExplicitProps & MappedProps & MappedActions

const QualityAssurancePhase: FunctionComponent<Props> = ({
  allIssuesAddressed,
  hasIssues,
  isLoading,
  loadTasks,
  loadIssues,
  kaseId,
  workflowStatus,
  percentIssuesCompleted
}) => {
  useEffect(() => {
    if (kaseId) {
      loadTasks({ kaseId })
      loadIssues({ kaseId })
    }
  }, [kaseId])

  const isWaitingForResponse =
    workflowStatus === WORKFLOW_STATUSES.AOS_WAITING_FOR_RESPONSE_TO_OPEN_QUESTIONS ||
    workflowStatus === WORKFLOW_STATUSES.CP_WAITING_FOR_RESPONSE_TO_OPEN_QUESTIONS

  const isFinalizingOpenQuestions =
    workflowStatus === WORKFLOW_STATUSES.AOS_FINALIZING_OPEN_QUESTIONS ||
    workflowStatus === WORKFLOW_STATUSES.CP_FINALIZING_OPEN_QUESTIONS

  if (!isWaitingForResponse && !isFinalizingOpenQuestions) {
    return null
  }

  const questionsAdditionalInfo = () => {
    if (!isFinalizingOpenQuestions) {
      if (allIssuesAddressed) {
        return 'Please submit your pending answers'
      }

      return `${percentIssuesCompleted}% completed`
    }

    return null
  }

  if (isLoading) {
    return (
      <div className="o-block o-layout--center flex items-center justify-center" style={{ height: '100%' }}>
        <FancySpinner variation="primary" />
      </div>
    )
  }

  if (hasIssues) {
    const ctaContent = () => {
      if (!isFinalizingOpenQuestions) {
        if (allIssuesAddressed) {
          return 'Submit Answers'
        }

        return 'Resolve Questions'
      }

      return 'View Items'
    }

    const ctaClasses = cx('c-btn o-griddle__col--xs-12 o-griddle__col--sm-4', {
      'c-btn--secondary': isFinalizingOpenQuestions,
      'c-btn--primary': !isFinalizingOpenQuestions
    })

    return (
      <div>
        {isFinalizingOpenQuestions && (
          <div className="flex flex-col items-center mb-8">
            <div className="c-action-items__icon-wrapper success">
              <CheckmarkIcon color="#168753" size="medium" />
            </div>
            <Heading tag="h1" color="emphasized">
              You're all set
            </Heading>
            <Paragraph>There's nothing else for you to do now - we'll reach out soon!</Paragraph>
          </div>
        )}
        <Heading tag="h2" size="sm" color="emphasized">
          Items to Complete
        </Heading>
        <ul className="o-block c-list">
          <SectionItemBasic
            sectionTitle="Questions and Tasks"
            cta={
              <a href={formatSectionUrl(kaseId, 'questions')} className={ctaClasses}>
                {ctaContent()}
              </a>
            }
            additionalInfo={questionsAdditionalInfo()}
            additionalInfoColor={percentIssuesCompleted < 100 ? 'warning' : null}
            isComplete={isFinalizingOpenQuestions}
          />
        </ul>
      </div>
    )
  }

  // Some people in the pipeline will still be going through the older process
  // where issues were communicated using email, keep this here for now to
  // keep dashboard information relevant.
  if (isWaitingForResponse) {
    return (
      <div className="o-layout--center o-box o-box--spacious">
        <div className="o-block o-block--compact">
          <DashboardEmailIllustration />
        </div>
        <h2 className="o-block c-type c-type--headline-md c-type--emphasized">Look out for an email from us!</h2>
        <Paragraph>We just need your answers to a few questions before we proceed.</Paragraph>
      </div>
    )
  } else {
    return null
  }
}

function mapStateToProps(state): MappedProps {
  const isLoading = isLoadingTasks(state) || isLoadingIssues(state)

  return {
    allIssuesAddressed: allIssuesAddressed(state),
    kaseId: getCurrentKaseId(state),
    hasIssues: hasIssues(state),
    isLoading,
    percentIssuesCompleted: getIssuesProgress(state)
  }
}

function mapDispatchToActions(dispatch: Dispatch) {
  return bindActionCreators(
    {
      loadTasks,
      loadIssues
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToActions)(QualityAssurancePhase) as FunctionComponent<ExplicitProps>
