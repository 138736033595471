import { reportingEnvironment, honeybadgerApiKey } from './settings'

export function configureErrorReporting() {
  if (window.Honeybadger) {
    window.Honeybadger.configure({
      apiKey: honeybadgerApiKey(),
      environment: reportingEnvironment()
    })
  }
}

export function setErrorContext(context) {
  if (window.Honeybadger) {
    window.Honeybadger.setContext(context)
  }
}

export function reportError(error) {
  if (window.Honeybadger) {
    window.Honeybadger.notify(error)
  }
}
