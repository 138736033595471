import React, { FunctionComponent } from 'react'
import Paragraph from 'components/type/paragraph'
import Heading from 'components/type/heading'
import CheckmarkIcon from 'components/icons/checkmark_icon'

export const SubmitSuccess: FunctionComponent = () => {
  return (
    <div className="flex flex-col items-center o-box py-4 md:py-16">
      <div className="c-action-items__icon-wrapper success mb-8">
        <CheckmarkIcon color="#168753" size="medium" />
      </div>
      <Heading tag="h1" color="emphasized" spacing="xl">
        Success!
      </Heading>
      <Paragraph centered>
        Your responses have been submitted to Boundless. We’ll check your info and/or documents soon and let you know if
        we need anything else.
      </Paragraph>
    </div>
  )
}

export default SubmitSuccess
