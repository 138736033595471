// THIS IS INTENDED TO BE A PLACEHOLDER ICON, FEEL FREE TO REPLACE THE PATH
// IF WE GET AN UPDATED VERSION
import React, { FunctionComponent } from 'react'
import IconWrapper, { IconWrapperExternalProps } from './icon_wrapper'

const ResourcesIcon: FunctionComponent<IconWrapperExternalProps> = (props) => (
  <IconWrapper {...props} viewBox="0 0 18 21">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.40273 2.40273C2.66059 2.14487 3.01033 2 3.375 2H15.2V14.25H3.375C2.89658 14.25 2.42864 14.3516 2 14.5428V3.375C2 3.01033 2.14487 2.66059 2.40273 2.40273ZM2 17.625C2 17.9897 2.14487 18.3394 2.40273 18.5973C2.66059 18.8551 3.01033 19 3.375 19H15.2V16.25H3.375C3.01033 16.25 2.66059 16.3949 2.40273 16.6527C2.14487 16.9106 2 17.2603 2 17.625ZM17.2 15.25V1C17.2 0.447715 16.7523 0 16.2 0H3.375C2.47989 0 1.62145 0.355579 0.988515 0.988515C0.355579 1.62145 0 2.47989 0 3.375V17.625C0 18.5201 0.355579 19.3785 0.988515 20.0115C1.62145 20.6444 2.47989 21 3.375 21H16.2C16.7523 21 17.2 20.5523 17.2 20V15.25Z"
    />
  </IconWrapper>
)

export default ResourcesIcon
