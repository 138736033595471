import React, { FunctionComponent } from 'react'
import IconWrapper, { IconWrapperExternalProps } from './icon_wrapper'
import classNames from 'classnames'

export type ArrowIconDirection = 'up' | 'right' | 'down' | 'left'

type Props = IconWrapperExternalProps & {
  arrowDirection?: ArrowIconDirection
}

const ArrowIcon: FunctionComponent<Props> = (props) => {
  const { arrowDirection, ...otherProps } = props

  const classes = classNames(props.className, {
    'c-icon--direction-up': arrowDirection === 'up',
    'c-icon--direction-right': arrowDirection === 'right',
    'c-icon--direction-left': arrowDirection === 'left'
  })

  return (
    <IconWrapper {...otherProps} viewBox="0 0 32 32" className={classes}>
      <path d="M17 22.586v-16.586h-2v16.586l-7-7-1.414 1.414 9.414 9.414 9.414-9.414-1.414-1.414-7 7z" />
    </IconWrapper>
  )
}

ArrowIcon.defaultProps = {
  arrowDirection: 'down'
}

export default ArrowIcon
