import React, { ReactNode } from 'react'
import cx from 'classnames'

import api from 'lib/api'
import { DocumentStatus } from 'lib/constants'
import { readableFileSize } from 'lib/filesize'
import DocumentIcon from 'components/icons/document_icon'
import Paragraph from 'components/type/paragraph'
import Heading from 'components/type/heading'
import CheckmarkIcon from 'components/icons/checkmark_icon'
import LinkifyText from 'components/linkify_text'

interface Props {
  document: DocumentModel & {
    progress: number
    rawFile: File
  }
  handleFileNameChange?: (doc: DocumentModel) => () => void
  inlineControls?: ReactNode
  isMobile: boolean
  kaseId: number
  renderThumbnails: boolean
  selected?: boolean
  showDocumentStatus: boolean
  showFileSize?: boolean
}

export default class ListedDocument extends React.Component<Props> {
  static defaultProps = {
    isMobile: false,
    showDocumentStatus: true
  }

  getFilesize() {
    const { document } = this.props

    const size = document.size || (document.rawFile && document.rawFile.size) || 0

    return readableFileSize(size)
  }

  renderDocumentUploadProgress() {
    const { document } = this.props

    return (
      <div className="o-block o-block--compact c-progress-bar">
        <div className="c-progress-bar__filled" style={{ width: `${document.progress}%` }} />
      </div>
    )
  }

  renderDocumentStatus() {
    const { document } = this.props

    if (document.status === DocumentStatus.Accepted) {
      return (
        <Paragraph italic color="success">
          Accepted <CheckmarkIcon />
        </Paragraph>
      )
    }

    if (document.status === DocumentStatus.NeedsReview) {
      return <Paragraph italic>Pending initial check</Paragraph>
    }

    if (document.status === DocumentStatus.NeedsCustomerAction) {
      return <Paragraph italic>See comment</Paragraph>
    }

    return null
  }

  shortenedFilename() {
    const { document } = this.props
    if (!document) return null

    const originalName = document.original_filename

    if (!originalName) return null

    const maxFilenameLength = 25

    if (originalName.length < maxFilenameLength) {
      return originalName
    }

    const prefix = originalName.substring(0, maxFilenameLength - 2)
    const suffix = originalName.substring(originalName.length - 3)

    return (
      <span>
        {prefix}&hellip;{suffix}
      </span>
    )
  }

  onClicked = (event) => {
    event.preventDefault()

    const { kaseId, document } = this.props
    const openUrl = api.routes.documentUrl(kaseId, document, {
      disposition: 'inline'
    })

    window.open(openUrl, '_blank')
  }

  isImage() {
    const { content_type } = this.props.document
    if (!content_type) {
      return false
    }
    return !!content_type.match(/^image/)
  }

  renderThumbnail() {
    if (this.isImage()) {
      const { kaseId, document, isMobile } = this.props
      const documentUrl = api.routes.documentUrl(kaseId, document)
      const imageDivStyle = { backgroundImage: `url(${documentUrl})` }
      const thumbnailClasses = cx('c-card-horizontal__thumbnail o-media__figure c-img c-img--bg-cover', {
        'c-card-horizontal__thumbnail--mobile': isMobile
      })

      return <a href="#" onClick={this.onClicked} className={thumbnailClasses} style={imageDivStyle} />
    } else {
      return (
        <a href="#" onClick={this.onClicked}>
          <DocumentIcon className="c-card-horizontal__thumbnail o-media__figure c-img" />
        </a>
      )
    }
  }

  renderEditName = () => {
    const { handleFileNameChange, document } = this.props
    if (!handleFileNameChange) return null

    return (
      <div className="o-block o-block--tight">
        <form onSubmit={handleFileNameChange(document)} className="o-media">
          <input type="text" defaultValue={document.original_filename} className="o-media__body" />
          <button className="c-btn o-media__figure" type="submit">
            Rename File
          </button>
        </form>
      </div>
    )
  }

  render() {
    const {
      children,
      document,
      inlineControls,
      isMobile,
      renderThumbnails,
      selected,
      showDocumentStatus,
      showFileSize
    } = this.props

    const containerClasses = cx('c-listed-document-request__document', {
      'c-listed-document-request__document--selected': selected,
      'js-admin-document--selected': selected,
      'c-custom-control': !renderThumbnails
    })

    const cardClasses = cx('o-media', 'c-card-horizontal', 'o-action-items__card', 'o-block o-block--compact', {
      'js-document-upload-complete': document.progress == null,
      'c-listed-document-request__document--accepted': document.status === DocumentStatus.Accepted,
      'o-action-items__card--warning': document.status === DocumentStatus.NeedsCustomerAction,
      'border-b border-t-0 border-gray-200 p-1 mb-0': isMobile,
      'border-none': isMobile && document.status === DocumentStatus.NeedsCustomerAction
    })

    const commentClasses = cx(
      'o-block o-block--compact c-tooltip c-tooltip--warning c-tooltip--block c-tooltip--bottom',
      { 'c-tooltip--bottom--mobile mb-2': isMobile }
    )

    // Do not show dismissed documents to customer
    if (document.status === DocumentStatus.Dismissed) return null

    return (
      <div className={containerClasses}>
        {this.renderEditName()}
        <div className={cardClasses} onClick={this.onClicked}>
          {renderThumbnails && this.renderThumbnail()}

          <div className="o-media__body o-box">
            <Paragraph spacing="sm">{this.shortenedFilename()}</Paragraph>
            {showFileSize && <Paragraph>{this.getFilesize()}</Paragraph>}
            {document.progress != null && this.renderDocumentUploadProgress()}
            {showDocumentStatus && this.renderDocumentStatus()}
          </div>
          {inlineControls}
        </div>

        {document.status === DocumentStatus.NeedsCustomerAction && document.comment && document.comment.body && (
          <div className={commentClasses} style={{ marginTop: isMobile ? '0.25rem' : -1 }}>
            <Heading tag="h4" size="sm" subhead>
              Comment from Boundless
            </Heading>

            <Paragraph size="sm">
              <span className="whitespace-pre-wrap">
                <LinkifyText>{document.comment.body}</LinkifyText>
              </span>
            </Paragraph>
          </div>
        )}

        {children}
      </div>
    )
  }
}
