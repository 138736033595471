import { buildMessage, Validator } from './'

const requiredIfNativeLanguageIsNonRoman: Validator = ({ getModelData, path, options = {} }) => {
  const currentValue = getModelData(path)
  const nativeLanguageUsesNonRomanCharacters = getModelData(options.hasNativeLanguageWithNonRomanCharactersPath)

  if (nativeLanguageUsesNonRomanCharacters === 'yes' && currentValue == null) {
    return [buildMessage('Please enter a country')]
  }

  return []
}

export default requiredIfNativeLanguageIsNonRoman
