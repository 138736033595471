const ordinalWords = [
  'zero',
  'first',
  'second',
  'third',
  'fourth',
  'fifth',
  'sixth',
  'seventh',
  'eighth',
  'ninth',
  'tenth',
  'eleventh',
  'twelfth',
  'thirteenth',
  'fourteenth',
  'fifteenth',
  'sixteenth',
  'seventeenth',
  'eighteenth',
  'nineteenth'
]

const ordinalPrefixes = {
  '20': 'twent',
  '30': 'thirt',
  '40': 'fort',
  '50': 'fift',
  '60': 'sixt',
  '70': 'sevent',
  '80': 'eight',
  '90': 'ninet'
}

const humanNumbers = ['zero', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten']

type OrdinalIndex = string | number

export const getHumanNumber = (number: number): string => {
  return humanNumbers[number] || number.toString()
}

export function getOrdinal(index: Nullable<OrdinalIndex>): Nullable<string> {
  if (typeof index === 'string') index = parseInt(index, 10)

  const num = index + 1

  if (num < 20) {
    return ordinalWords[num]
  } else if (num < 100) {
    const ones = num % 10
    const tens = num - ones

    let ordinal = ordinalPrefixes[tens]

    if (ones === 0) {
      ordinal += 'ieth'
    } else {
      ordinal += `y-${ordinalWords[ones]}`
    }

    return ordinal
  } else if (num < 200) {
    const remainder = getOrdinal(index - 100)

    if (remainder) return `one hundred and ${remainder}`
  }

  return null
}

export const capitalize = (message: string): string =>
  message ? message.charAt(0).toUpperCase() + message.slice(1) : message

export const listToSentence = (array: string[]) => {
  const connectors = {
    words: ', ',
    twoWords: ' and ',
    lastWord: ', and '
  }

  switch (array.length) {
    case 0: {
      return '-'
    }

    case 1: {
      return array[0]
    }

    case 2: {
      return `${array[0]}${connectors.twoWords}${array[1]}`
    }

    default: {
      const list = array.slice(0, array.length - 1).join(connectors.words)
      const last = array[array.length - 1]

      return `${list}${connectors.lastWord}${last}`
    }
  }
}

export const snakeToTitle = (input: string): string => input.split('_').map(capitalize).join(' ')
