import React, { FunctionComponent } from 'react'
import HelpIcon from 'components/icons/help_icon'

interface Props {
  toggleContextualHelp: (args: any) => void
  text: string
}

export const InlineContextualHelp: FunctionComponent<Props> = (props) => {
  const onClick = (event) => {
    event.preventDefault()
    props.toggleContextualHelp({ via: 'InlineHelp', desktopOnly: true })
    document.activeElement.blur()
  }

  return (
    <a data-qa="inline-help-button" href="#" style={{ display: 'inline' }} onClick={onClick} title="Get help">
      {props.text}
      <HelpIcon />
    </a>
  )
}

export default InlineContextualHelp
