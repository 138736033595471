import React from 'react'

import { isFeatureEnabled } from '../lib/feature_flags_deprecated'

function raiseError(event) {
  event.preventDefault()
  throw new Error('Test Honeybadger error from React')
}

export default function DebugError() {
  if (!isFeatureEnabled('honeybadger_test_error')) {
    return null
  }

  return (
    <div>
      <a href="#" onClick={raiseError}>
        Create test error
      </a>
    </div>
  )
}
