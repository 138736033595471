import moment from 'moment'

import { clearCollectionFromQualifier, resizeCollection, resizeCollectionFromIndexedQualifier } from './helpers'

import { getKaseModelTemplate } from 'lib/kase_model_template_helpers'
import t from 'lib/update_transformations'
import { devConsoleLog } from 'lib/logging'

import { AfterChangeFunction } from 'components/forms/buffered_field_value'
import { AfterChangeHooks } from './types'

const hooks: AfterChangeHooks = {}

// Previous marriages
hooks.newmbgcClearSponsorPreviousMarriagesIfNo = clearCollectionFromQualifier('sponsor.previous_marriages')

hooks.newmbgcClearBeneficiaryPreviousMarriagesIfNo = clearCollectionFromQualifier('beneficiary.previous_marriages')

hooks.newmbgcUpdateSponsorPreviousMarriagesCount = resizeCollection(
  'sponsor.previous_marriages',
  getKaseModelTemplate('Marriage')
)

hooks.newmbgcUpdateBeneficiaryPreviousMarriagesCount = resizeCollection(
  'beneficiary.previous_marriages',
  getKaseModelTemplate('Marriage')
)

// Children
hooks.newmbgcClearBeneficiaryChildrenIfNo = clearCollectionFromQualifier('beneficiary.children')

hooks.newmbgcUpdateBeneficiaryChildrenCount = resizeCollection('beneficiary.children', getKaseModelTemplate('Child'))

// Previous Names
const getIndexFromQualifierPath = (path) => parseInt(path.split('.')[2], 10)

// TODO: we have to use a different function to calculate the new size
// of the collection because we're on an offset panel. We should really get
// rid of the concept of offset panels, as they are causing a fair amount
// of pain.
const getNewSizeFromOffsetIndex = (index) => index + 1

// Previous addresses
const prunePreviousAddressesOnStartDateChange = (collectionPath: string): AfterChangeFunction<Date> => ({
  eventTargetPath,
  serializedValue,
  changeValueAtPath
}) => {
  if (!serializedValue) return

  const newDate = moment(serializedValue, 'YYYY/MM/DD')

  if (!newDate.isValid()) return

  const fiveYearsAgo = moment().subtract(5, 'years')

  if (newDate < fiveYearsAgo) {
    const index = parseInt(eventTargetPath.split('.')[2], 10)
    const newSize = index + 1

    devConsoleLog(`[after-change] resizing addresses to ${newSize}`)

    changeValueAtPath(collectionPath, {
      operation: t.resizeCollection(newSize, getKaseModelTemplate('Address'))
    })
  }
}

hooks.newmbgcPruneSponsorPreviousAddressesOnStartDateChange = prunePreviousAddressesOnStartDateChange(
  'sponsor.addresses'
)

hooks.newmbgcPruneBeneficiaryPreviousAddressesOnStartDateChange = prunePreviousAddressesOnStartDateChange(
  'beneficiary.addresses'
)

hooks.newmbgcResizeSponsorEmployments = resizeCollectionFromIndexedQualifier(
  'sponsor.employments',
  getKaseModelTemplate('Employment'),
  getIndexFromQualifierPath,
  getNewSizeFromOffsetIndex
)

hooks.newmbgcResizeBeneficiaryEmployments = resizeCollectionFromIndexedQualifier(
  'beneficiary.employments',
  getKaseModelTemplate('Employment'),
  getIndexFromQualifierPath,
  getNewSizeFromOffsetIndex
)

hooks.newmbgcResizeClubOrOrganizationMemberships = resizeCollectionFromIndexedQualifier(
  'uscis_questionnaire.club_or_organization_memberships',
  getKaseModelTemplate('ClubOrOrganizationMembership'),
  getIndexFromQualifierPath,
  getNewSizeFromOffsetIndex
)

hooks.newmbgcResizeOtherNationalities = resizeCollectionFromIndexedQualifier(
  'beneficiary.other_nationalities', // B20-1267
  getKaseModelTemplate('Country'),
  getIndexFromQualifierPath,
  getNewSizeFromOffsetIndex
)

export default hooks
