import React, { FunctionComponent } from 'react'

import ModalWithHeader from 'components/modals/modal/modal_with_header'
import Paragraph from 'components/type/paragraph'
import inflect from 'lib/utilities/inflect'

interface Props {
  initialThresholdReached: boolean
  isOpen: boolean
  closeModal: () => void
  thresholdForFirstReview: number
}

const ProcessModal: FunctionComponent<Props> = ({
  initialThresholdReached,
  isOpen,
  closeModal,
  thresholdForFirstReview
}) => {
  const firstStepContent = initialThresholdReached ? (
    <>
      <Paragraph bold spacing="sm">
        We'll check your documents
      </Paragraph>
      <Paragraph spacing="xl">
        We’ll continually look over your newly uploaded documents for quality and consistency with your immigration
        forms. This step takes 3–4 business days.
      </Paragraph>
    </>
  ) : (
    <>
      <Paragraph bold spacing="sm">
        Upload {inflect(thresholdForFirstReview, 'document set', 'document sets')}
      </Paragraph>
      <Paragraph spacing="xl">
        A “document set” may be a single document or multiple. We need at least half of your document sets before we can
        start checking them for quality and consistency with your immigraton forms. Each check takes 3–4 business days.
      </Paragraph>
    </>
  )

  return (
    <ModalWithHeader
      isOpen={isOpen}
      header="Supporting Document Process"
      onRequestClose={closeModal}
      modalWidth="35rem"
      body={
        <ol style={{ padding: '2.5rem' }}>
          <li>{firstStepContent}</li>
          <li>
            <Paragraph bold spacing="sm">
              Complete tasks
            </Paragraph>
            <Paragraph spacing="xl">
              After each check, we may ask you to upload additional or replacement documents. Don’t worry — this is
              typical for Boundless customers.
            </Paragraph>
          </li>
          <li>
            <Paragraph bold spacing="sm">
              Keep uploading!
            </Paragraph>
            <Paragraph spacing="xl">
              We know the U.S. government requires a lot of documents, but the sooner you upload all of them, the sooner
              we can start Quality Assurance.
            </Paragraph>
          </li>
        </ol>
      }
    />
  )
}

export default ProcessModal
