import { isPresent } from './presence'

function environment(): string {
  return window.settings.environment
}

export function reportingEnvironment(): string {
  // 'development' seems like a safe fallback?
  switch (window.location.hostname) {
    case 'cactus-staging.boundless.com':
      return 'cactus-staging'

    case 'ltv-staging.boundless.com':
      return 'ltv-staging'

    default:
      return window.settings.reportingEnvironment || 'development'
  }
}

/**
 * Aliasing to make this concept match Rails server-side and PHP server-side
 * TODO it's a little gross that the deployment stage is equated to the
 * reporting environment, but we can't fix that until we have different
 * builds for staging vs prod.
 */
export const deployStage = reportingEnvironment

export function wwwDomain() {
  switch (deployStage()) {
    case 'development': {
      return 'http://boundless.test'
    }

    case 'staging': {
      return 'https://wwwstage.boundless.com'
    }

    case 'production': {
      return 'https://www.boundless.com'
    }

    case 'cactus-staging': {
      return 'https://cactus-staging.boundless.com'
    }

    case 'ltv-staging': {
      return 'https://ltv-staging.boundless.com'
    }

    case 'review': {
      return `https://${window.location.hostname}`
    }

    default: {
      return 'http://boundless.test'
    }
  }
}

export function isProductionEnv(): boolean {
  return environment() === 'production'
}

export function isDevelopmentEnv(): boolean {
  return environment() === 'development'
}

export function isProductionStage() {
  return deployStage() === 'production'
}

export function isDevelopmentStage() {
  return deployStage() === 'development'
}

export function isStagingStage() {
  return deployStage() === 'staging'
}

export function honeybadgerApiKey(): string {
  return window.settings.honeybadgerApiKey
}

export function paymentLogoPath(): string {
  return window.settings.paymentLogoPath
}

export function paymentSuccessUrl(): string {
  return window.settings.paymentSuccessUrl
}

export function stripePublishableKey(): string {
  return window.settings.stripePublishableKey
}

export function settingsPresent(): boolean {
  return isPresent(window.settings)
}

export function appDomain() {
  switch (deployStage()) {
    case 'development': {
      return 'http://localhost:3000'
    }

    case 'staging': {
      return 'https://staging.boundless.com'
    }

    case 'production': {
      return 'https://apply.boundless.com'
    }

    case 'cactus-staging': {
      return 'https://cactus-staging.boundless.com'
    }

    case 'ltv-staging': {
      return 'https://ltv-staging.boundless.com'
    }

    case 'review': {
      return `https://${window.location.hostname}`
    }

    default: {
      return 'http://localhost:3000'
    }
  }
}

export function externalUrl(path) {
  return `${wwwDomain()}${path}`
}

export function rapidVisaRedirectUrl() {
  switch (deployStage()) {
    case 'development': {
      return 'http://localhost:3000'
    }

    case 'staging': {
      return 'https://vsts.rapidvisa.com'
    }

    case 'production': {
      return 'https://account.rapidvisa.com'
    }

    default: {
      return 'http://localhost:3000'
    }
  }
}
