import React, { FunctionComponent, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import cx from 'classnames'
import { scroller } from 'react-scroll'

import AskQuestionButton from 'components/ask_question_button'
import MobileChatCTA from 'components/screens/shared/mobile_chat_cta'
import CheckmarkIcon from 'components/icons/checkmark_icon'
import ControlledAccordion from 'components/accordion/controlled_accordion'
import PaymentDetermineGovernmentFeesV2 from './payment_determine_government_fees_v2'
import PaymentDetermineGovernmentFees from './payment_determine_government_fees'
import PaymentSelectService from './payment_select_service'
import PaymentPanelTotalAndOptions from './payment_total_and_options'
import PaymentSelectGovernmentFeeOption from './payment_select_government_fee_option'
import RotateIcon from 'components/icons/rotate_icon'
import ContextualHelpModal from 'components/modals/modal/contextual_help_modal'
import FeeHikeNotice from 'components/screens/shared/fee_hike_notice'
import PaymentEmergency from 'components/screens/shared/payment_emergency'

import { getIsFeatureEnabled } from 'reducers/features/selectors'

import { KaseKind } from 'lib/constants'
import { getCookie, setCookie } from 'lib/cookies'
import { PaymentDetails, ServicePaymentDetail } from '../lib/types'
import { FEATURES } from 'lib/features'
import { Icon, Palette } from '@boundless-immigration/boundless-ui'
import { Tier } from 'components/payment'
import { getCurrentKaseIsAOS } from 'reducers/selectors'

interface ExplicitProps {
  /**
   * The current kase id
   */
  currentKaseId?: number
  /**
   * The current kase kind
   */
  currentKaseKind: KaseKind
  /**
   * The current page id
   */
  currentPageId?: string
  /**
   * If we are viewing this in a mobile device or not
   */
  isMobile?: boolean
  /**
   * The details of the payment info we receive from the backend
   */
  paymentDetails: PaymentDetails
}

interface MappedProps {
  isChatDiscoveryTestEnabled: boolean
  isV2QuestionnaireEnabled: boolean
  currentKaseIsAOS: boolean
}

type Props = ExplicitProps & MappedProps

interface ReviewStep {
  component: JSX.Element
  header: string
  show: boolean
}

const PaymentPanel: FunctionComponent<Props> = ({
  currentKaseId,
  currentKaseKind,
  currentKaseIsAOS,
  currentPageId,
  isMobile,
  isChatDiscoveryTestEnabled,
  isV2QuestionnaireEnabled,
  paymentDetails
}) => {
  const COOKIE_CHILDREN_AGE_NAME = `_boundless_${currentKaseId}_${currentKaseKind}_payment_children_ages`
  const COOKIE_STEP_NAME = `_boundless_${currentKaseId}_${currentKaseKind}_payment_step`
  const COOKIE_INCLUDE_USCIS_FEES_NAME = `_boundless_${currentKaseId}_${currentKaseKind}_payment_use_uscis_fees`
  const COOKIE_SERVICE_NAME = `_boundless_${currentKaseId}_${currentKaseKind}_payment_service`

  /**
   * The currently viewed review step
   */
  const [currentReviewStep, setCurrentReviewStep] = useState(0)
  /**
   * The review step that still needs to be considered by the customer
   * currentReviewStep should not exceed this (i.e. can't click ahead without clicking CTA of latestReviewStep)
   */
  const [latestReviewStep, setLatestReviewStep] = useState(0)
  /**
   * Used to determine if customer wants to bundle uscis fees into their payment
   */
  const [includeUscisFees, setIncludeUscisFees] = useState(false)
  const [showFeeBreakdownModal, setShowFeeBreakdownModal] = useState(false)
  const [teenNumberKey, setTeenNumberKey] = useState('teen_number_0')
  const [allQuestionsAnswered, setAllQuestionsAnswered] = useState(false)
  const [storedChildrenAgeValues, setStoredChildrenAgeValues] = useState([])

  // The service selected will determine which payment details we use
  const [selectedService, setSelectedService] = useState<Tier>('essential')
  const [totalCombinedFees, setTotalCombinedFees] = useState(0)
  const [uscisFeesWithoutBoundlessServiceFee, setUscisFeesWithoutBoundlessServiceFee] = useState(0)
  const [finalTotalFeesInCents, setFinalTotalFeesInCents] = useState(0)
  const [details, setDetails] = useState<ServicePaymentDetail>(null)

  // This won't vary based on the service selected, so getting it from the essential service
  const { number_of_children_for_age } = paymentDetails.essential

  const queryParams = new URLSearchParams(window.location.search)

  const getStepId = (reviewStep: number) => `step-${reviewStep}`

  const modifyLatestReviewStep = (latestReviewStep: number) => {
    setLatestReviewStep(latestReviewStep)
    setCookie({ name: COOKIE_STEP_NAME, value: latestReviewStep })
  }

  const onNextReviewStepBtnClicked = (reviewStep: number, isIncludeUscisFeesSet: boolean = false) => {
    // If isIncludeUscisFeesSet is true, that means we are on the step where uscis fees can be
    // used or not, which means we need to reset what the latest step is afterwards or else
    // the wrong COOKIE_STEP_NAME cookie will be set if the customer switches between them
    if (isIncludeUscisFeesSet) {
      modifyLatestReviewStep(reviewStep + 1)
    } else if (latestReviewStep === reviewStep) {
      modifyLatestReviewStep(latestReviewStep + 1)
    }

    // Subtract the current step height from where we scroll to account for where we should
    // scroll to after the current accordion collapses
    const currentStepHeight = document.getElementById(getStepId(reviewStep))?.offsetHeight || 0
    const newCurrentReviewStep = currentReviewStep + 1
    setCurrentReviewStep(newCurrentReviewStep)
    scroller.scrollTo(getStepId(newCurrentReviewStep), {
      duration: 500,
      delay: 0,
      smooth: true,
      offset: -currentStepHeight
    })
  }

  const handleServiceBtnClicked = (currentStep: number, serviceType: Tier) => {
    setCookie({ name: COOKIE_SERVICE_NAME, value: serviceType })
    setSelectedService(serviceType)
    onNextReviewStepBtnClicked(currentStep)
  }

  useEffect(() => {
    const packageParam = queryParams.get('package') as Tier
    if (packageParam) {
      handleServiceBtnClicked(0, packageParam)
      window.history.replaceState({}, '', window.location.pathname)
    }
  }, [])

  useEffect(() => {
    const currentLatestStepValue = getCookie(COOKIE_STEP_NAME)
    const currentIncludeUscisValue = getCookie(COOKIE_INCLUDE_USCIS_FEES_NAME)
    const currentServiceSelected = getCookie(COOKIE_SERVICE_NAME) as Tier

    if (currentLatestStepValue) {
      setCurrentReviewStep(+currentLatestStepValue)
      setLatestReviewStep(+currentLatestStepValue)
    }

    if (currentIncludeUscisValue) {
      setIncludeUscisFees(currentIncludeUscisValue === 'true')
    }

    if (currentServiceSelected) {
      setSelectedService(currentServiceSelected)
    }

    // If the number of children is less than what the stored cookie has, update the cookie
    let newChildrenAgeValuesStr = getCookie(COOKIE_CHILDREN_AGE_NAME)
    let newChildrenAgeValuesArr = newChildrenAgeValuesStr ? JSON.parse(newChildrenAgeValuesStr) : []
    if (newChildrenAgeValuesArr.length > number_of_children_for_age) {
      newChildrenAgeValuesArr.splice(
        number_of_children_for_age,
        newChildrenAgeValuesArr.length - number_of_children_for_age
      )
    }
    newChildrenAgeValuesStr = JSON.stringify(newChildrenAgeValuesArr)
    setCookie({ name: COOKIE_CHILDREN_AGE_NAME, value: newChildrenAgeValuesStr })

    // Set state values based on cookie
    setTeenNumberKey(`teen_number_${newChildrenAgeValuesArr.filter((age) => age === 'teen').length}`)
    setStoredChildrenAgeValues([...newChildrenAgeValuesArr])
  }, [currentKaseId])

  useEffect(() => {
    const serviceName = selectedService || 'essential'
    const servicePaymentDetails = paymentDetails[serviceName]
    if (servicePaymentDetails) {
      const { total_combined_fees, total_uscis_fees_without_service_fees, total_boundless_fees } = servicePaymentDetails
      const finalTotalFees = includeUscisFees ? total_combined_fees[teenNumberKey] : total_boundless_fees
      const finalTotalFeesInCents = finalTotalFees * 100

      setDetails(servicePaymentDetails)
      setTotalCombinedFees(total_combined_fees[teenNumberKey])
      setUscisFeesWithoutBoundlessServiceFee(total_uscis_fees_without_service_fees[teenNumberKey])
      setFinalTotalFeesInCents(finalTotalFeesInCents)
    }
  }, [paymentDetails, selectedService, includeUscisFees, teenNumberKey])

  if (!details) return null

  const onReviewStepClicked = (index: number) => {
    if (index <= latestReviewStep) {
      setCurrentReviewStep(index)
    }
  }

  const modifyIncludeUscisFees = (includeFees: boolean) => {
    setIncludeUscisFees(includeFees)
    setCookie({ name: COOKIE_INCLUDE_USCIS_FEES_NAME, value: includeFees })
  }

  const onSelectDetermineFeesBtnClicked = (currentStep: number) => {
    modifyIncludeUscisFees(true)
    onNextReviewStepBtnClicked(currentStep, true)
  }

  const onSelectSeeTotalBtnClicked = (currentStep: number) => {
    modifyIncludeUscisFees(false)
    onNextReviewStepBtnClicked(currentStep, true)
  }

  const onShowFeeBreakdownModal = (event) => {
    event.preventDefault()
    setShowFeeBreakdownModal(true)
  }

  const handleUpdateChildrenAge = (newTeenCount: number, childrenAges: string) => {
    setTeenNumberKey(`teen_number_${newTeenCount}`)
    setCookie({ name: COOKIE_CHILDREN_AGE_NAME, value: childrenAges })
  }

  let finePrint =
    'A portion of your payment goes to the independent attorneys who review your application, as provided in the ' +
    '<a href="https://apply.boundless.com/attorney-agreement.pdf" target="_blank" rel="noopener noreferrer">' +
    'Limited Scope Agreement</a>.'

  if (!includeUscisFees) finePrint += ' Government filing fees not included.'

  const reviewSteps: ReviewStep[] = [
    {
      component: (
        <PaymentSelectService
          key="review-step-component-1"
          onClickEssentialServiceBtn={() => handleServiceBtnClicked(0, 'essential')}
          onClickPremiumServiceBtn={() => handleServiceBtnClicked(0, 'premium')}
          numberOfChildren={number_of_children_for_age}
          isMobile={isMobile}
          paymentDetails={paymentDetails}
        />
      ),
      header: 'Select a service',
      show: true
    },
    {
      component: (
        <>
          {showFeeBreakdownModal && (
            <ContextualHelpModal
              header={details.fee_breakdown_contextual_help_govt_itemized.header}
              isOpen={showFeeBreakdownModal}
              onRequestClose={() => setShowFeeBreakdownModal(false)}
              text={details.fee_breakdown_contextual_help_govt_itemized.text}
            />
          )}
          <PaymentSelectGovernmentFeeOption
            key="review-step-component-2"
            boundlessServiceFee={details.boundless_service_fee}
            handleOpenFeeBreakdownModal={onShowFeeBreakdownModal}
            isMobile={isMobile}
            maxMonths={details.split_it_max_months}
            minMonths={details.split_it_min_months}
            onSelectDetermineFeesBtnClicked={() => onSelectDetermineFeesBtnClicked(1)}
            onSelectSeeTotalBtnClicked={() => onSelectSeeTotalBtnClicked(1)}
            totalUscisFeeRange={details.total_uscis_fee_range}
            totalUscisFees={details.total_uscis_fees[teenNumberKey]}
            uscisFeesWithoutBoundlessServiceFee={uscisFeesWithoutBoundlessServiceFee}
            usesExtraQuestions={details.uses_extra_questions}
          />
        </>
      ),
      header: 'Select USCIS fee option',
      show: true
    },
    {
      component: (
        <PaymentDetermineGovernmentFeesV2
          key="review-step-component-4"
          extraQuestions={details.extra_questions}
          handleOpenFeeBreakdownModal={onShowFeeBreakdownModal}
          handleUpdateChildrenAge={(newTeenCount, childrenAges) => handleUpdateChildrenAge(newTeenCount, childrenAges)}
          isMobile={isMobile}
          numberOfKids={number_of_children_for_age}
          onNextPaymentOptionsBtnClicked={() => onNextReviewStepBtnClicked(2)}
          storedChildrenAgeValues={storedChildrenAgeValues}
          totalUscisFeeRange={details.total_uscis_fee_range}
          updateAllQuestionsAnswered={(bool) => setAllQuestionsAnswered(bool)}
          uscisFeesWithoutBoundlessServiceFee={uscisFeesWithoutBoundlessServiceFee}
          currentPageId={currentPageId}
        />
      ),
      header: 'Calculate USCIS fees',
      show: includeUscisFees && details.uses_extra_questions && isV2QuestionnaireEnabled
    },
    {
      component: (
        <PaymentDetermineGovernmentFees
          key="review-step-component-4"
          extraQuestions={details.extra_questions}
          handleOpenFeeBreakdownModal={onShowFeeBreakdownModal}
          handleUpdateChildrenAge={(newTeenCount, childrenAges) => handleUpdateChildrenAge(newTeenCount, childrenAges)}
          isMobile={isMobile}
          numberOfKids={number_of_children_for_age}
          onNextPaymentOptionsBtnClicked={() => onNextReviewStepBtnClicked(2)}
          storedChildrenAgeValues={storedChildrenAgeValues}
          totalUscisFeeRange={details.total_uscis_fee_range}
          updateAllQuestionsAnswered={(bool) => setAllQuestionsAnswered(bool)}
          uscisFeesWithoutBoundlessServiceFee={uscisFeesWithoutBoundlessServiceFee}
        />
      ),
      header: 'Calculate USCIS fees',
      show: includeUscisFees && details.uses_extra_questions && !isV2QuestionnaireEnabled
    },
    {
      component: (
        <PaymentPanelTotalAndOptions
          key="review-step-component-3"
          currentKaseIsAOS={currentKaseIsAOS}
          finalTotalFeesInCents={finalTotalFeesInCents}
          includeUscisFees={includeUscisFees}
          isMobile={isMobile}
          maxMonths={details.split_it_max_months}
          minMonths={details.split_it_min_months}
          numberTeens={+teenNumberKey.substring(12)}
          sideBySideLineItems={details.side_by_side_line_items}
          teenNumberKey={teenNumberKey}
          hasAppliedDiscounts={details.has_applied_discounts}
          serviceName={details.readable_service_name}
          tier={selectedService}
        />
      ),
      header: 'Select payment option',
      show: true
    }
  ]

  const shownReviewSteps = reviewSteps.filter((reviewStep) => reviewStep.show)

  const allExtraQuestionsAnswered = details.uses_extra_questions ? allQuestionsAnswered : false

  // Since this gets rendered dynamically the padding doesn't work sometimes for the circled
  // numbers
  // But this is probably only an issue in localhost
  const getHeader = (header: string, index: number) => {
    const totalPaymentOptionsReviewStepIndex = shownReviewSteps.findIndex(
      (reviewStep) => reviewStep.header === 'Total & Payment Options'
    )

    const showTotalPaymentOptionsAdditionalHeader =
      currentReviewStep !== totalPaymentOptionsReviewStepIndex && index === totalPaymentOptionsReviewStepIndex

    return (
      <div className="text-left">
        <div
          className={cx('content-center flex items-center', {
            'text-black': index === currentReviewStep
          })}
        >
          <div className="flex items-center">
            <p className="c-type--body-sans-md c-type--bold mr-2">{header}</p>
            {index < latestReviewStep && <Icon fillColor={Palette.successMain} size="1.5rem" type="check-filled" />}
            {showTotalPaymentOptionsAdditionalHeader && allExtraQuestionsAnswered && !isMobile && (
              <p className="c-type c-type--body-sans-sm font-bold text-success-500">
                <RotateIcon />
                Updated
              </p>
            )}
          </div>
        </div>
        {showTotalPaymentOptionsAdditionalHeader && allExtraQuestionsAnswered && isMobile && (
          <p className="c-type c-type--body-sans-sm font-bold text-success-500 ml-7 my-2">
            <RotateIcon />
            Updated
          </p>
        )}
      </div>
    )
  }

  return (
    <div className="c-sheet__body">
      <div className="o-grid__row">
        <div className="o-grid__col-6 o-grid__col--offset-1">
          <div className="o-block o-block--tight">
            <h1 className="o-block c-type c-type--headline-md c-type--emphasized">
              Apply with confidence: guaranteed approval or your money back
            </h1>
            <p className="o-block c-type c-type--body-sans-md">
              Reduce your risk of application rejection or denial by <strong>25%</strong> with step-by-step guidance,
              live support, and quality reviews by an experienced team.
            </p>
          </div>

          {/* This message was deemed to be too aggressively placed since its only Splitit */}
          {/* <PaymentEmergency /> */}
          <FeeHikeNotice />

          <div className="o-block">
            <div className="o-box o-box--ample">
              <p className="o-block c-type c-type--body-sans-md c-type--emphasized">
                To checkout, follow the steps below.
              </p>
              {shownReviewSteps.map((reviewStep, index) => (
                <ControlledAccordion
                  id={getStepId(index)}
                  key={`review-step-${index}`}
                  className="border-x-0"
                  disabled={index > latestReviewStep}
                  header={getHeader(reviewStep.header, index)}
                  isOpen={currentReviewStep === index}
                  onClicked={() => onReviewStepClicked(index)}
                  showChevron={index <= latestReviewStep}
                >
                  {reviewStep.component}
                </ControlledAccordion>
              ))}
            </div>
            {isChatDiscoveryTestEnabled && (
              <div className="o-grid__col-8 c-sheet__footer u-hide@md-up -mt-1 pb-4">
                <MobileChatCTA variant="solid" context="Payment Page" />
              </div>
            )}
          </div>

          {shownReviewSteps.length - 1 === latestReviewStep && (
            <p className="o-block c-type c-type--body-sans-sm" dangerouslySetInnerHTML={{ __html: finePrint }} />
          )}
          <p className="o-block c-type c-type--body-sans-sm">
            Have questions? <AskQuestionButton via="PetitionOutcome">Chat with us</AskQuestionButton> or give us a call
            at{' '}
            <a href="tel:1-855-268-6353" className="c-link">
              1&#8209;855&#8209;268&#8209;6353
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  )
}

function mapStateToProps(state): MappedProps {
  return {
    currentKaseIsAOS: getCurrentKaseIsAOS(state),
    isChatDiscoveryTestEnabled: getIsFeatureEnabled(state, FEATURES.DISCOVERABLE_CHAT),
    isV2QuestionnaireEnabled: getIsFeatureEnabled(state, FEATURES.QUESTIONNAIRE_V2_API__PAYMENT_PAGES)
  }
}

export default connect(mapStateToProps, null)(PaymentPanel) as FunctionComponent<ExplicitProps>
