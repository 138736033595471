import React, { FunctionComponent } from 'react'

import ModalNoHeader from 'components/modals/modal/modal_no_header'
import Button from 'components/button'
import ArrowIcon from 'components/icons/arrow_icon'
import Paragraph from 'components/type/paragraph'
import { KasePhase } from '../v2/types'
import K1ToAOSConversionModalBody from './entry_modal_body/k1_to_aos_conversion_modal_body'

export enum ModalType {
  StartAOSFromK1 = 'StartAOSFromK1'
}

interface Props {
  currentPhase: KasePhase
  showModal?: boolean
  hideModal: () => void
}

const PhaseEntryModal: FunctionComponent<Props> = (props) => {
  const { currentPhase, showModal, hideModal } = props
  const modalData = currentPhase.entry_modal_data

  const renderBody = () => {
    switch (modalData.type) {
      case ModalType.StartAOSFromK1:
        return <K1ToAOSConversionModalBody data={modalData} />
      default:
        return (
          <div>
            <h3 className="o-block o-block--ample c-type c-type--headline-md c-type--emphasized">{modalData.header}</h3>
            <Paragraph centered>{modalData.body}</Paragraph>
          </div>
        )
    }
  }

  return (
    <ModalNoHeader
      onRequestClose={hideModal}
      modalWidth="50rem"
      isOpen={showModal}
      body={renderBody()}
      footer={
        <div className="o-layout--right">
          <Button
            mobileLabel="Continue"
            color="primary"
            label={modalData.button_label || 'Continue'}
            icon={<ArrowIcon arrowDirection="right" />}
            onClick={hideModal}
          />
        </div>
      }
    />
  )
}

export default PhaseEntryModal
