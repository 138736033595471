import React, { FunctionComponent } from 'react'
import IconWrapper, { IconWrapperExternalProps } from './icon_wrapper'

const EmailIcon: FunctionComponent<IconWrapperExternalProps> = (props) => (
  <IconWrapper {...props} viewBox="0 0 32 32">
    <path d="M20 10.24h-8v-1.28h8zM20 12.8h-8v1.28h8zM28.8 14.434v13.966h-25.6v-13.966l4.16-3.603v-7.070h17.28v7.070zM24.64 12.946v3.123l2.128-1.28zM8.64 5.040v11.797l7.36 4.416 7.36-4.416v-11.797zM7.36 12.947l-2.13 1.845 2.128 1.28zM27.2 26.8v-10.774l-11.2 6.72-11.2-6.72v10.774z" />
  </IconWrapper>
)

export default EmailIcon
