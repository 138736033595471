import React, { FunctionComponent } from 'react'
import IconWrapper, { IconWrapperExternalProps } from './icon_wrapper'
import classNames from 'classnames'

export type ChevronIconDirection = 'up' | 'right' | 'down' | 'left'

type Props = IconWrapperExternalProps & {
  chevronDirection?: ChevronIconDirection
}

const ChevronIcon: FunctionComponent<Props> = (props) => {
  const { chevronDirection, ...otherProps } = props

  const classes = classNames(props.className, {
    'c-icon--direction-up': chevronDirection === 'up',
    'c-icon--direction-right': chevronDirection === 'right',
    'c-icon--direction-left': chevronDirection === 'left'
  })

  return (
    <IconWrapper {...otherProps} viewBox="0 0 32 32" className={classes}>
      <path d="M16 23.164l-10.914-10.914 1.414-1.414 9.5 9.5 9.5-9.5 1.414 1.414-10.914 10.914z" />
    </IconWrapper>
  )
}

ChevronIcon.defaultProps = {
  chevronDirection: 'down'
}

export default ChevronIcon
