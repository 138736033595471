import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'

import { openModal } from 'actions/modal_actions'

import Button from 'components/button'

interface ExplicitProps {
  /**
   * @param {boolean} inWorkArea if true, button will be primary
   */
  inWorkArea: boolean
}

interface ActionProps {
  openConfirmationModal: () => void
}

type Props = ExplicitProps & ActionProps

const ConfirmMoveToQA: FunctionComponent<Props> = ({ inWorkArea, openConfirmationModal }) => {
  if (inWorkArea) {
    return <Button color="primary" label="Begin" onClick={openConfirmationModal} />
  } else {
    return <Button color="emphasized" label="Begin Quality Assurance" onClick={openConfirmationModal} />
  }
}

function mapDispatchToActions(dispatch) {
  return {
    openConfirmationModal: () =>
      dispatch(
        openModal({
          name: 'CustomerMoveToQAModal',
          via: 'Move To QA Button'
        })
      )
  }
}

export default connect(null, mapDispatchToActions)(ConfirmMoveToQA)
