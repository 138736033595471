import { updateUserEmailFromQuestionnaire } from 'actions/questionnaire_actions'
import cx from 'classnames'
import Button from 'components/button'
import PencilIcon from 'components/icons/pencil_icon'
import JITFeedback from 'components/forms/panel/jit_feedback'
import api from 'lib/api'
import { capitalize } from 'lib/language_helpers'
import React, { FunctionComponent, useState } from 'react'
import { connect } from 'react-redux'
import { getCurrentUserEmail } from 'reducers/selectors'
import { bindActionCreators, Dispatch } from 'redux'
import InputTransformer from '../inputs/input_transformer'
import { allQuestionsInSetCompleted } from '../lib/helpers'
import {
  DependentJITElement,
  QuestionnairePageElement,
  QuestionnaireQuestion,
  QuestionnaireQuestionSet,
  QuestionnaireQuestionSetAttributes
} from '../lib/types'
import QuestionText from './question_text'

interface MappedProps {
  currentUserEmail: Nullable<string>
  isUpdating: boolean
}

interface ActionProps {
  updateUserEmailFromQuestionnaire: typeof updateUserEmailFromQuestionnaire
}

interface ExplicitProps {
  isEditing: boolean
  isEditingAnotherSet: boolean
  onEdit: (args: any) => void
  onSave: (args: any) => void
  pageElement: QuestionnaireQuestionSet | QuestionnairePageElement
  renderQuestions: (questions: any) => any
  toggleContextualHelp: (args: any) => void
}

type Props = ActionProps & MappedProps & ExplicitProps

const getDisplayValue = (question: QuestionnaireQuestion) => {
  if (question.attributes.input_options.length) {
    return (
      question.attributes.input_options?.find((option) => option.key === question.attributes.input_value)?.value || ''
    )
  } else {
    return capitalize(question.attributes.input_value)
  }
}

const SummaryQuestionSet: FunctionComponent<Props> = ({
  currentUserEmail,
  isEditing,
  isEditingAnotherSet,
  isUpdating,
  onEdit,
  onSave,
  pageElement,
  renderQuestions,
  toggleContextualHelp,
  updateUserEmailFromQuestionnaire
}) => {
  const [isEmailTaken, setIsEmailTaken] = useState(false)
  const [showJIT, setShowJIT] = useState(false)
  const questionSet = pageElement.attributes as QuestionnaireQuestionSetAttributes
  const isUserEmailQuestionSet = questionSet.questions[0]?.attributes?.input_type === 'user_email'

  const onSaveEmail = (email: string) => {
    setIsEmailTaken(false)
    api.users.emailAvailable({ email }).then((isAvailable) => {
      if (isAvailable) {
        updateUserEmailFromQuestionnaire(email)
      } else {
        setIsEmailTaken(true)
      }
    })
  }

  const renderEditingBody = () => {
    if (isUserEmailQuestionSet) {
      return (
        <>
          <InputTransformer
            id="summary_user_email_input"
            name={questionSet.questions[0]?.attributes?.text}
            onSave={onSaveEmail}
            type="email"
            value={currentUserEmail}
          />
          {isEmailTaken && (
            <div className="c-alert c-alert--danger">
              <div className="c-type c-type--body-sans-sm">
                This email already belongs to a different registered user. Please check that you have entered your email
                correctly, and contact our team if you registered a user with this email before.
              </div>
            </div>
          )}
        </>
      )
    }

    return <fieldset>{renderQuestions(questionSet.questions)}</fieldset>
  }

  return (
    <>
      <div
        key={pageElement.id}
        className={cx('o-block c-paper-form', {
          'c-paper-form-slim-shadow': isEditing,
          'c-paper-form-slim': !isEditing
        })}
      >
        <div className="w-full flex flex-row">
          <div className="o-layout--padded-x md:w-3/4">
            <QuestionText question={questionSet.markdown} toggleContextualHelp={toggleContextualHelp} />
          </div>

          <div className="w-full md:w-1/4 o-layout--right o-layout--padded-x">
            {isEditing ? (
              <Button
                className="c-btn-right"
                onClick={onSave}
                type="button"
                color="secondary"
                label="Save"
                disabled={
                  isUpdating || isEmailTaken || !allQuestionsInSetCompleted(pageElement as QuestionnaireQuestionSet)
                }
              />
            ) : isEditingAnotherSet ? (
              <PencilIcon size="xsm" className="c-type--muted o-media__figure" aria-label="edit this information" />
            ) : (
              <PencilIcon
                size="xsm"
                className="c-type--primary o-media__figure o-media--hover"
                onClick={onEdit}
                aria-label="edit this information"
              />
            )}
          </div>
        </div>

        {isEditing ? (
          <>
            <div className="w-full" onClick={() => setShowJIT(true)}>
              {renderEditingBody()}
            </div>
            {showJIT &&
              !isUpdating &&
              questionSet.jit_elements.map((jit: DependentJITElement, index) => (
                <div className="w-full" key={'jit' + index}>
                  <div className="o-layout--padded-x">
                    <JITFeedback key={pageElement.id} text={jit.markdown} />
                  </div>
                </div>
              ))}
          </>
        ) : (
          <>
            <div className="o-layout--padded-x ">
              {isUserEmailQuestionSet
                ? currentUserEmail
                : questionSet.questions.map((question) => getDisplayValue(question)).join(', ')}
            </div>
          </>
        )}
      </div>
    </>
  )
}

function mapStateToProps(state) {
  return {
    currentUserEmail: getCurrentUserEmail(state),
    isUpdating: state.questionnaire.isUpdating
  }
}

function mapDispatchToActions(dispatch: Dispatch): ActionProps {
  return bindActionCreators(
    {
      updateUserEmailFromQuestionnaire
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToActions)(SummaryQuestionSet)
