import React from 'react'
import classNames from 'classnames'

import { QuestionnaireInputContainerProps } from './'
import { joinI18nKeys } from 'lib/i18n'
import panelLocaleService from 'services/panel_locale_service'

import BufferedFieldValue from 'components/forms/buffered_field_value'
import ValidatedInput from 'components/forms/validated_input'

type Props = QuestionnaireInputContainerProps & {
  choices: string[]
}

export default class MultipleCheckboxes extends React.Component<Props> {
  static defaultProps = {
    choices: []
  }

  getChoiceString(choice: string): string {
    const { name, resourceKeys } = this.props

    // Do not translate the choice if it's a number
    if (isNaN(parseInt(choice, 10))) {
      return panelLocaleService.t(joinI18nKeys(resourceKeys, `${name}.${choice}`))
    } else {
      return choice
    }
  }

  renderChoices(dataAtPath: any, onChange: Function, onBlur: Function): React.ReactNode[] {
    const { name, choices, handleSave, isChoiceSelected } = this.props

    const shouldWrap = this.shouldWrapLayout()

    return choices.map((choice) => (
      <div
        className={classNames('o-block o-block--compact', {
          'o-grid__cell--6/12 o-grid__cell--off@sm': shouldWrap
        })}
        key={choice}
      >
        <label className="c-custom-control c-custom-control--check c-custom-control--multiline">
          <div className="c-custom-control__multiline-wrapper">
            <input
              type="checkbox"
              className={'c-custom-control__input'}
              name={name}
              value={choice}
              checked={isChoiceSelected(dataAtPath, choice)}
              onBlur={onBlur}
              onChange={(event) => {
                onChange(handleSave(dataAtPath, event))
              }}
              id={`checkbox-for-${choice}`}
            />

            <span className="c-custom-control__indicator" />
          </div>

          <span className="c-custom-control__description c-type c-type--body-sans-md">
            {this.getChoiceString(choice)}
          </span>
        </label>
      </div>
    ))
  }

  shouldWrapLayout() {
    const { choices, no_wrap } = this.props

    return !no_wrap && choices.length > 4
  }

  render() {
    const { disabled, path } = this.props

    const containerClasses = classNames('o-flag o-grid--stack@sm o-block c-paper-form__group', {
      'o-grid--fluid': this.shouldWrapLayout(),
      'o-block--grid': this.shouldWrapLayout()
    })

    return (
      <ValidatedInput disabled={disabled} path={path}>
        {({ fireValidation, getValidationClasses, renderValidations }) => (
          <div className={`${containerClasses} ${getValidationClasses()}`}>
            <BufferedFieldValue path={path}>
              {(value, onChange) => this.renderChoices(value, onChange, fireValidation)}
            </BufferedFieldValue>

            {renderValidations()}
          </div>
        )}
      </ValidatedInput>
    )
  }
}
