import { mbgcCustomIndices, mbgcRepeatsFunctions, mbgcPathIndexOffsets } from './marriage_based_green_card'

import { natzRepeatsFunctions, natzNameIndexOffsets, natzPathIndexOffsets } from './naturalization'

import { newMbgcCustomIndices, newMbgcRepeatsFunctions, newMbgcPathIndexOffsets } from './new_marriage_based_green_card'

import Decorator from 'lib/decorator'
import { FieldIndexType } from 'components/forms/field/index'
import _isNil from 'lodash/isNil'

// panel group repeat logic

interface GetPanelGroupRepeatCountArgs {
  kaseKind: string
  modelData: Decorator
  panelGroupName: string
  parentIndex?: FieldIndexType
}

interface RepeatsFunctionArgs {
  modelData: any // TODO: ModelData doesn't exist
  pathIndex?: FieldIndexType
}

type RepeatsFunction = (args: RepeatsFunctionArgs) => number

export interface PanelGroupRepeatsFunctionCollection {
  [panelGroupName: string]: RepeatsFunction
}

const repeatsFunctions = {
  MarriageBasedGreenCard: mbgcRepeatsFunctions,
  Naturalization: natzRepeatsFunctions,
  NewMarriageBasedGreenCard: newMbgcRepeatsFunctions
}

function getRepeatsFunction(kaseKind, panelGroupName) {
  const functions = repeatsFunctions[kaseKind]
  return functions && functions[panelGroupName]
}

export const getPanelGroupRepeatCount = ({
  modelData,
  kaseKind,
  panelGroupName,
  parentIndex
}: GetPanelGroupRepeatCountArgs): number => {
  const repeatsFunction = getRepeatsFunction(kaseKind, panelGroupName)

  if (!_isNil(parentIndex)) {
    return repeatsFunction ? repeatsFunction(modelData, parentIndex) : 0
  } else {
    return repeatsFunction ? repeatsFunction(modelData) : 0
  }
}

// index offset interfaces
interface IndexOffsetCollection {
  [kaseKind: string]: Set
}

interface GetPanelGroupIndexOffsetArgs {
  kaseKind: string
  panelGroupName: string
  indexOffsetCollection: IndexOffsetCollection
}

const getPanelGroupIndexOffset = ({
  kaseKind,
  panelGroupName,
  indexOffsetCollection
}: GetPanelGroupIndexOffsetArgs): number => {
  const offsets = indexOffsetCollection[kaseKind]

  return offsets && offsets.has(panelGroupName) ? 1 : 0
}

// path index offset logic

const pathIndexOffsets: IndexOffsetCollection = {
  MarriageBasedGreenCard: mbgcPathIndexOffsets,
  Naturalization: natzPathIndexOffsets,
  NewMarriageBasedGreenCard: newMbgcPathIndexOffsets
}

export const getPanelGroupPathIndexOffset = ({ kaseKind, panelGroupName }): number =>
  getPanelGroupIndexOffset({
    kaseKind,
    panelGroupName,
    indexOffsetCollection: pathIndexOffsets
  })

// name index offset logic

const nameIndexOffsets: IndexOffsetCollection = {
  Naturalization: natzNameIndexOffsets
}

export const getPanelGroupNameIndexOffset = ({ kaseKind, panelGroupName }): number =>
  getPanelGroupIndexOffset({
    kaseKind,
    panelGroupName,
    indexOffsetCollection: nameIndexOffsets
  })

// custom index logic
interface CustomIndexCollection {
  [kaseKind: string]: {
    [panelName: string]: any[]
  }
}

const customIndexCollection: CustomIndexCollection = {
  MarriageBasedGreenCard: mbgcCustomIndices,
  NewMarriageBasedGreenCard: newMbgcCustomIndices
}

export const getPanelGroupCustomIndices = ({ modelData, kaseKind, panelGroupName }): any[] => {
  const customIndicesByName = customIndexCollection[kaseKind]

  return customIndicesByName && customIndicesByName[panelGroupName] && customIndicesByName[panelGroupName](modelData)
}
