import React, { FunctionComponent, CSSProperties } from 'react'
import _times from 'lodash/times'
import cx from 'classnames'
import { TYPE_SPACING_MAP } from 'components/type/settings'

interface Props {
  className?: string
  style?: CSSProperties
  /**
   * The relative font size of the element.
   * Note that sub-headers don't come in a large size.
   */
  size?: 'lg' | 'md' | 'sm'
  /**
   * The amount of space below the element. This space is canceled out if the
   * element is the last o-block in a o-box.
   */
  spacing?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'none'
  /**
   * The number of lines you'd like to render.
   */
  numLines?: number
  centered?: boolean
}

/**
 * An element that represents a heading that hasn't finished loading.
 * https://boundless-ui.surge.sh/react/skeleton#text
 */
const SkeletonHeading: FunctionComponent<Props> = ({
  className,
  style,
  size = 'md',
  centered,
  spacing = 'md',
  numLines = 1
}) => {
  const spacingClassName = TYPE_SPACING_MAP[spacing]

  const classes = cx('o-block', className, {
    [`o-block--${spacingClassName}`]: !!spacingClassName
  })

  const lineClasses = cx('o-skeleton', {
    'o-skeleton--headline-lg': size === 'lg',
    'o-skeleton--headline-md': size === 'md',
    'o-skeleton--headline-sm': size === 'sm',
    'mx-auto': centered
  })

  if (numLines < 1) {
    numLines = 1
  }

  return (
    <div className={classes} style={style}>
      {_times(numLines, (i) => (
        <div key={`line=${i}`} className={cx(lineClasses, { 'w-3/4': i === numLines - 1 })} />
      ))}
    </div>
  )
}

export default SkeletonHeading
