import React from 'react'
import { connect } from 'react-redux'

import { TELEMETRY_EVENTS } from 'lib/constants'

import { postTelemetry, resetTelemetry } from 'actions/telemetry_actions'
import { isUserLoggedIn, getCurrentUserEmail } from 'reducers/selectors'

import { showSignInModal } from 'components/sign_in_nav'
import api from 'lib/api'
import Button from 'components/button'

interface ComponentProps {
  mobile: boolean
  userLoggedIn: boolean
  currentUserEmail: Nullable<string>
}

interface ActionProps {
  trackLogout: () => void
}

type Props = ComponentProps & ActionProps

class LoginNavLink extends React.Component<Props> {
  constructor(props: Props) {
    super(props)
  }

  render() {
    let email = this.props.currentUserEmail
    let loggedIn = this.props.userLoggedIn && email !== undefined
    let linkText = loggedIn ? (this.props.mobile ? 'Log Out' : 'Log Out ' + (email || '')) : 'Log In'
    let className = 'c-link c-link--plain c-navbar-natz-launch__item'

    return (
      <Button
        color="link"
        label={linkText}
        className={`c-type c-type--body-sans-sm ${className}`}
        onClick={() => {
          this.onClick(loggedIn)
        }}
      ></Button>
    )
  }

  onClick = (loggedIn: boolean) => {
    if (!loggedIn) {
      showSignInModal({
        successUrl: '/start',
        via: 'Navbar'
      })
    } else {
      this.props.trackLogout()
      api.users.signOut().then(() => {
        window.location.href = '/users/sign_in'
      })
    }
  }
}

function mapStateToProps(state) {
  return {
    userLoggedIn: isUserLoggedIn(state),
    currentUserEmail: getCurrentUserEmail(state)
  }
}

function mapDispatchToActions(dispatch: Function): ActionProps {
  return {
    trackLogout: () => {
      dispatch(postTelemetry(TELEMETRY_EVENTS.LOGGED_OUT_USER))
      dispatch(resetTelemetry)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToActions)(LoginNavLink)
