import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'
import {
  getCurrentKaseId,
  getCurrentKaseKindString,
  getRecordedPetitionProgress,
  isJointSponsorRequired,
  workflowStatusIsDocGathering
} from 'reducers/selectors'

import DashboardWelcomeMessage from './shared/welcome_message'
import DocumentIcon from 'components/icons/document_icon'
import AskIcon from 'components/icons/ask_icon'
import WorkflowDebug from 'components/screens/dashboard/customer_dashboard/workflow_debug'

interface Props {
  currentKaseId: number
  currentKaseKindString: string
  currentKasePetitionProgress?: number
  isJointSponsorRequired: boolean
  workflowStatusIsDocGathering?: boolean
}

const PostPaymentDashboard: FunctionComponent<Props> = ({
  currentKaseId,
  currentKaseKindString,
  currentKasePetitionProgress,
  isJointSponsorRequired,
  workflowStatusIsDocGathering
}) => {
  const openChat = (event: React.MouseEvent<HTMLAnchorElement>) => {
    if (window.handleOpenChatTool) {
      window.handleOpenChatTool(event.nativeEvent, { via: 'TimelinePostpay' })
    }
  }

  return (
    <div className="o-pane__fill o-layout--impose">
      <WorkflowDebug />
      <div className="timeline-view o-grid">
        <div className="o-grid__row">
          <DashboardWelcomeMessage />
          <div className="o-grid__col-8">
            <div className="o-block o-block--spacious">
              <p className="o-block o-block--compact c-type c-type--body-sans-md">Here is what will happen next:</p>
              <ul className="list-group o-list-bare o-block">
                <li className="list-group-item">
                  <div className="o-block c-type c-type--headline-sm">Independent Attorney Review:</div>
                  <div className="c-type c-type--body-sans-sm">
                    You will receive an email connecting you with your independent immigration attorney within one
                    business day after your payment. Your attorney will answer your remaining questions and conduct an
                    initial review of your {currentKaseKindString} application.
                  </div>
                </li>
                <li className="list-group-item">
                  <div className="o-block c-type c-type--headline-sm">Document Gathering:</div>
                  <div className="c-type c-type--body-sans-sm">
                    After your attorney has completed an initial review of your application and determined which
                    supporting documents are necessary, Boundless will send you a complete list by email so you can
                    upload each document to your secure online account.
                  </div>
                </li>
                <li className="list-group-item">
                  <div className="o-block c-type c-type--headline-sm">Final Review:</div>
                  <div className="c-type c-type--body-sans-sm">
                    Once all info and documents are complete, your independent attorney will thoroughly check your
                    application for accuracy and completeness to make sure you have the best possible chance at success.
                    Then, you’ll be able to look it over one last time before we print and assemble your packet.
                  </div>
                </li>
                <li className="list-group-item">
                  <div className="o-block c-type c-type--headline-sm">Submit your application to the government:</div>
                  <div className="c-type c-type--body-sans-sm">
                    Once you and your independent attorney have confirmed that everything is complete and accurate,
                    Boundless will print out all of your forms and documents, assembled precisely how the government
                    prefers. You'll receive the complete application package at your doorstep, with clear instructions
                    on how to sign and send it to the government.
                  </div>
                </li>
              </ul>
              <p className="o-block c-type c-type--body-sans-md">
                These steps typically take 4—5 weeks, from the moment your initial documents have been approved to the
                moment you file.
              </p>
              <p className="o-block o-block--spacious c-type c-type--body-sans-md">
                Boundless will stick with you throughout the entire {currentKaseKindString} process, helping you respond
                to government questions and get ready for your {currentKaseKindString} interview. We want to celebrate
                with you at the moment you receive your {currentKaseKindString}!
              </p>
            </div>
            <div className="o-block o-block--ample">
              <p className="o-block c-type c-type--body-sans-sm">
                <span>For more information, check out this Boundless article </span>
                <a target="_blank" rel="noopener noreferrer" href="/content/explained">
                  explaining the entire {currentKaseKindString} process
                </a>
                {currentKaseKindString !== 'naturalization' && (
                  <React.Fragment>
                    <span>, plus many more </span>
                    <a target="_blank" rel="noopener noreferrer" href="/content/immigration-resources">
                      free immigration resources
                    </a>
                  </React.Fragment>
                )}
                <span>.</span>
              </p>
              <p className="o-block c-type c-type--body-sans-sm">
                <span>
                  We would love your feedback. Please send any comments or questions directly to Xiao Wang, the CEO of
                  Boundless, at{' '}
                </span>
                <a href="mailto:xiao.wang@boundless.com">xiao.wang@boundless.com</a>
                <span>.</span>
              </p>
              <p className="o-block c-type c-type--body-sans-sm">
                <span>If you have any other questions, please </span>
                <a onClick={openChat} href="#">
                  click here
                </a>
                <span> to reach us.</span>
              </p>
            </div>
          </div>
          <div className="o-grid__col-4">
            {currentKasePetitionProgress < 75 && (
              <div className="o-block o-block--copious">
                <p className="o-block c-type c-type--body-sans-md c-type--emphasized">
                  Need to complete more questions?
                </p>
                <a className="c-btn c-btn--primary" href={`/applications/${currentKaseId}`}>
                  Continue
                </a>
              </div>
            )}
            <div className="o-block">
              <p className="o-block c-type c-type--body-sans-md c-type--emphasized">
                Need to upload additional documents?
              </p>
              <ul className="o-list-bare tool-menu-sublist">
                <li className="tool-menu-item">
                  <a
                    className="o-media o-media--tight o-box o-box--tight tool-menu-item-link"
                    href={`/applications/${currentKaseId}/document_upload`}
                  >
                    <DocumentIcon />
                    <span className="o-media__body c-type c-type--subhead-sm">Documents</span>
                  </a>
                </li>
              </ul>
            </div>
            {workflowStatusIsDocGathering && isJointSponsorRequired && (
              <div className="o-block">
                <p className="o-block c-type c-type--body-sans-md c-type--emphasized">
                  Complete the Joint Sponsor questionnaire
                </p>
                <ul className="o-list-bare tool-menu-sublist">
                  <li className="tool-menu-item">
                    <a
                      className="o-media o-media--tight o-box o-box--tight tool-menu-item-link"
                      href="/joint_sponsor_info"
                    >
                      <AskIcon />
                      <span className="o-media__body c-type c-type--subhead-sm">Joint Sponsor</span>
                    </a>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

function mapStateToProps(state) {
  return {
    currentKaseId: getCurrentKaseId(state),
    currentKaseKindString: getCurrentKaseKindString(state),
    currentKasePetitionProgress: getRecordedPetitionProgress(state),
    isJointSponsorRequired: isJointSponsorRequired(state),
    workflowStatusIsDocGathering: workflowStatusIsDocGathering(state)
  }
}

export default connect(mapStateToProps, null)(PostPaymentDashboard)
