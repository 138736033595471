import React, { FunctionComponent } from 'react'

import { QuestionnaireInputContainerProps } from '../lib/types'

const TextArea: FunctionComponent<QuestionnaireInputContainerProps> = ({
  disabled,
  id,
  name,
  onBlurEvent,
  onChangeEvent,
  value
}) => {
  return (
    <div className="o-flag o-grid--fluid o-grid--stack@sm o-block c-paper-form__group">
      <textarea
        autoComplete="off"
        className="c-qa-textarea c-paper-form__control px-1"
        disabled={disabled}
        id={id}
        name={name}
        onBlur={onBlurEvent}
        onChange={onChangeEvent}
        placeholder={name}
        rows={7}
        value={value}
      />
    </div>
  )
}

export default TextArea
