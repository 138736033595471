import * as fromDocumentRequests from './document_requests/selectors'
import * as fromDocumentReviewDashboard from './document_review_dashboard/selectors'
import * as fromQA from './qa/selectors'
import * as fromModel from 'reducers/model/selectors'
import * as fromRouter from './router/selectors'
import * as fromApplication from './application/selectors'

import _find from 'lodash/find'
import _flatMap from 'lodash/flatMap'
import _some from 'lodash/some'

import { ToolsAdminState } from './index'
import DocumentRequestDecorator from './document_requests/decorator'
import { DocumentStatus } from 'lib/constants'
import { createSelector } from 'reselect'
import {
  getApplicantFirstName,
  getBeneficiaryFirstName,
  getChildrenFirstNames,
  getHouseholdMembersFirstNames,
  getJointSponsorFirstName,
  getModelMetadata,
  getSponsorFirstName
} from 'reducers/selectors'
import * as fromCrossDomain from 'reducers/cross_domain_selectors'

export const getDocumentRequestsForKase = (state: ToolsAdminState, kaseId: number): DocumentRequestDecorator[] => {
  const documentRequestPaths = fromDocumentRequests.getDocumentRequestPathsForKase(state.documentRequests, kaseId)

  return fromModel
    .getModelValues(state.model, documentRequestPaths)
    .map((documentRequest: DocumentRequestModel) => new DocumentRequestDecorator(documentRequest))
}

export const getDocumentsByStatus = (state: ToolsAdminState, kaseId: number, status: string) => {
  const documents = _flatMap(getDocumentRequestsForKase(state, kaseId).map((request) => request.documents))

  return documents.filter((document) => document.status === status)
}

export const getArchivableDocuments = (state: ToolsAdminState, kaseId: number) => {
  const documents = _flatMap(getDocumentRequestsForKase(state, kaseId).map((request) => request.documents))

  return documents.filter(
    (document) => document.status === DocumentStatus.Accepted || document.status === DocumentStatus.NeedsReview
  )
}

export const getCurrentDocumentId = (state: ToolsAdminState) => fromRouter.getCurrentDocumentId(state.router)

export const getCurrentDocumentRequestId = (state: ToolsAdminState) =>
  fromRouter.getCurrentDocumentRequestId(state.router)

export const getCurrentKaseId = (state: ToolsAdminState) => fromApplication.getCurrentKaseId(state.application)

export const getCurrentDocument = (state: ToolsAdminState, kaseId: number, documentId: string) => {
  return _flatMap(getDocumentRequestsForKase(state, kaseId), (request) => request.documents).find(
    (document) => document.id === documentId
  )
}

export const getDocumentRequestForDocument = (
  state: ToolsAdminState,
  kaseId: number,
  documentId: string
): DocumentRequestModel => {
  const requests = getDocumentRequestsForKase(state, kaseId)

  return _find(requests, (request) => _some(request.documents, (document) => document.id === documentId))
}

export const getDocumentRequest = (
  state: ToolsAdminState,
  kaseId: number,
  documentRequestId: string
): DocumentRequestModel => {
  const documentRequests = getDocumentRequestsForKase(state, kaseId)

  return _find(documentRequests, (request) => request.id === documentRequestId)
}

export const isCurrentlyLoadingDocumentRequests = (state: ToolsAdminState) =>
  fromDocumentRequests.isCurrentlyLoadingDocumentRequests(state.documentRequests)

export const isCurrentlyLoadingPreviewDocument = (state: ToolsAdminState) =>
  fromDocumentRequests.isCurrentlyLoadingPreviewDocument(state.documentRequests)

export const isCurrentlyReplacingDocument = (state: ToolsAdminState) =>
  fromDocumentRequests.isCurrentlyReplacingDocument(state.documentRequests)

export const getTrelloCardUrl = (state) => fromApplication.getTrelloCardUrl(state.application)

export const getTrelloCardId = (state) => fromApplication.getTrelloCardId(state.application)

export const getCurrentQATabIndex = (state: ToolsAdminState) => fromQA.getCurrentTabIndex(state.qa)

export const getSortedDocumentRequests = createSelector(
  (state, kaseId) => getDocumentRequestsForKase(state, kaseId),
  getModelMetadata,
  (requests, modelMetadata) => fromCrossDomain.getSortedDocumentRequests(requests, modelMetadata)
)

export const groupSortedDocumentRequests = createSelector(
  (state, kaseId) => getSortedDocumentRequests(state, kaseId),
  getBeneficiaryFirstName,
  getSponsorFirstName,
  getJointSponsorFirstName,
  getHouseholdMembersFirstNames,
  getChildrenFirstNames,
  getApplicantFirstName,
  fromCrossDomain.groupSortedDocumentRequests
)

// Document Review Dashboard

export const isDocumentReviewDashboardLoading = (state) =>
  fromDocumentReviewDashboard.isDocumentReviewDashboardLoading(state.documentReviewDashboard)

export const documentReviewDashboardHasError = (state) =>
  fromDocumentReviewDashboard.documentReviewDashboardHasError(state.documentReviewDashboard)

export const getDocumentReviewKases = (state) =>
  fromDocumentReviewDashboard.getDocumentReviewKases(state.documentReviewDashboard)
