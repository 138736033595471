import React, { ReactNode, FunctionComponent } from 'react'

import ControlledModalWrapper, { CloseReason } from './controlled_modal_wrapper'
import Button from 'components/button'
import Heading from 'components/type/heading'
import CloseIcon from 'components/icons/close_icon'
import ArrowIcon from 'components/icons/arrow_icon'

interface Props {
  body: ReactNode
  cancelLabel?: string
  confirmEmphasized?: boolean
  confirmLabel?: string
  header: ReactNode
  isOpen?: boolean
  modalWidth?: string | number
  onRequestClose: (reason: CloseReason) => void
  onRequestConfirm: () => any
}

const ConfirmationModal: FunctionComponent<Props> = ({
  body,
  cancelLabel = 'No, go back',
  confirmLabel = 'Yes, continue',
  confirmEmphasized = false,
  header,
  isOpen,
  modalWidth = '50rem',
  onRequestClose,
  onRequestConfirm
}) => (
  <ControlledModalWrapper onRequestClose={onRequestClose} isOpen={isOpen} style={{ width: modalWidth }}>
    <div className="c-modal__content--original">
      <header className="c-modal__header--original">
        <Heading tag="h3" color="emphasized" size="sm" spacing="none">
          {header}
        </Heading>
        <button
          className="c-close"
          type="button"
          title="Dismiss this modal"
          onClick={() => onRequestClose(CloseReason.CloseButton)}
        >
          <CloseIcon />
        </button>
      </header>
      <div className="py-8 px-4">
        {body}
        <div className="o-grid--fluid pt-8">
          <div className="o-grid__cell--fill o-grid__cell--off@sm o-block@sm-down">
            <Button
              className="c-btn c-btn--secondary o-grid__cell--off@sm"
              onClick={() => onRequestClose(CloseReason.CloseButton)}
              label={cancelLabel}
            />
          </div>
          <div className="o-grid__cell--fill o-grid__cell--off@sm o-layout--right">
            <Button
              className="c-btn c-btn--primary c-btn--block@sm-down"
              onClick={onRequestConfirm}
              label={confirmLabel}
              icon={<ArrowIcon arrowDirection="right" />}
              color={confirmEmphasized ? 'emphasized' : undefined}
            />
          </div>
        </div>
      </div>
    </div>
  </ControlledModalWrapper>
)

export default ConfirmationModal
