import React, { FunctionComponent, useState } from 'react'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'

import {
  getDocumentReviewKase,
  fetchDocumentRequests,
  submitRequestsForReview,
  updateDocumentRequestStatus
} from 'actions/document_request_actions'
import { postTelemetry } from 'actions/telemetry_actions'
import { completedReviewCount, getInitialDocReviewThresholdCount, getCurrentKaseId, slaIsSet } from 'reducers/selectors'
import { AutoSubmitModalType, DocumentRequestStatus, TELEMETRY_EVENTS } from 'lib/constants'

import Button from 'components/button'
import { requestHasDocumentNeedReview } from './utils'
import AutoSubmissionModal from 'components/screens/document_upload/modals/auto_submission_modal.tsx'

interface ExplicitProps {
  className?: string
  documentRequest?: DocumentRequestModel
}

interface MappedProps {
  completedReviewCount?: number
  initialDocReviewThresholdCount: number
  kaseId: number
  slaIsSet?: boolean
}

interface MappedActions {
  getDocumentReviewKase: typeof getDocumentReviewKase
  fetchDocumentRequests: typeof fetchDocumentRequests
  submitRequestsForReview: typeof submitRequestsForReview
  updateDocumentRequestStatus: typeof updateDocumentRequestStatus
  postTelemetry: typeof postTelemetry
}

export const UpdateRequestStatusButton: FunctionComponent<MappedProps & MappedActions & ExplicitProps> = ({
  className,
  completedReviewCount,
  getDocumentReviewKase,
  fetchDocumentRequests,
  documentRequest,
  initialDocReviewThresholdCount,
  kaseId,
  slaIsSet,
  submitRequestsForReview,
  postTelemetry,
  updateDocumentRequestStatus
}) => {
  const [waitingForAjax, setWaitingForAjax] = useState(false)
  const [autoSubmissionModalType, setAutoSubmissionModalType] = useState(null)
  const [showAutoSubmissionModal, setShowAutoSubmissionModal] = useState(false)
  const hideAutoSubmissionModal = () => setShowAutoSubmissionModal(false)

  const revealAutoSubmissionModal = (kind: AutoSubmitModalType) => {
    setAutoSubmissionModalType(kind)
    setShowAutoSubmissionModal(true)
  }

  const updateDocRequestStatus = (newStatus: DocumentRequestStatus) => () => {
    setWaitingForAjax(true)

    if (newStatus === DocumentRequestStatus.NeedsReview) {
      postTelemetry(TELEMETRY_EVENTS.COMPLETED_DOCUMENT_REQUEST, {
        documentRequestPath: documentRequest && documentRequest.path
      })
    } else {
      postTelemetry(TELEMETRY_EVENTS.EDITED_DOCUMENT_REQUEST, {
        documentRequestPath: documentRequest && documentRequest.path
      })
    }

    // Update document request status
    updateDocumentRequestStatus(documentRequest, newStatus)
      .then(() => {
        // Get updated version of doc request array
        return fetchDocumentRequests({ kaseId }).then((requests) => {
          // In order to avoid a funky race condition with using a selector after the state is
          // updated using fetchDocumentRequests, return requests from the call and
          // calculate with return instead
          const requestsNeedReview = requests.filter((request) => request.status === DocumentRequestStatus.NeedsReview)

          const completedReqThresholdReached =
            requestsNeedReview.length >= initialDocReviewThresholdCount || completedReviewCount > 0

          // Auto submission occurs when there is no sla set meaning kase is not currently being reviewed
          // and after 50% threshold reached
          if (completedReqThresholdReached && !slaIsSet) {
            return submitRequestsForReview({ kaseId }).then(() => {
              getDocumentReviewKase({ kaseId })
              // If this is the first time a review has been submitted,
              // reveal modal to indicate review has been triggered

              if (!completedReviewCount) {
                revealAutoSubmissionModal(AutoSubmitModalType.FirstReview)
              } else {
                revealAutoSubmissionModal(AutoSubmitModalType.StandardReview)
              }
            })
          }
        })
      })
      .finally(() => {
        setWaitingForAjax(false)
      })
  }

  // This accounts for document requests with no documents uploaded and for the case
  // when a customer action is needed, but they have not uploaded any new documents
  const noDocumentsNeedReview = documentRequest && !requestHasDocumentNeedReview(documentRequest)

  const currentStatusButton =
    documentRequest && documentRequest.status === DocumentRequestStatus.NeedsReview ? (
      <Button
        className={className}
        label="Edit this section"
        color="primary"
        disabled={waitingForAjax}
        onClick={updateDocRequestStatus(DocumentRequestStatus.NeedsCustomerAction)}
      />
    ) : (
      <Button
        className={className}
        label="I'm done uploading"
        color="emphasized"
        isLoading={waitingForAjax}
        onClick={updateDocRequestStatus(DocumentRequestStatus.NeedsReview)}
        disabled={waitingForAjax || noDocumentsNeedReview}
      />
    )

  return (
    <>
      <AutoSubmissionModal
        modalType={autoSubmissionModalType}
        hideAutoSubmissionModal={hideAutoSubmissionModal}
        showModal={showAutoSubmissionModal}
      />
      {currentStatusButton}
    </>
  )
}

function mapStateToProps(state): MappedProps {
  return {
    completedReviewCount: completedReviewCount(state),
    initialDocReviewThresholdCount: getInitialDocReviewThresholdCount(state),
    kaseId: getCurrentKaseId(state),
    slaIsSet: slaIsSet(state)
  }
}

function mapDispatchToActions(dispatch: Dispatch) {
  return bindActionCreators(
    {
      getDocumentReviewKase,
      fetchDocumentRequests,
      submitRequestsForReview,
      updateDocumentRequestStatus,
      postTelemetry
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToActions)(UpdateRequestStatusButton)
