import React, { FunctionComponent } from 'react'
import IconWrapper, { IconWrapperExternalProps } from './icon_wrapper'

const MissingDocumentsIcon: FunctionComponent<IconWrapperExternalProps> = (props) => (
  <IconWrapper {...props} viewBox="0 0 25 25">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5 2.5C6.97715 2.5 2.5 6.97715 2.5 12.5C2.5 18.0228 6.97715 22.5 12.5 22.5C18.0228 22.5 22.5 18.0228 22.5 12.5C22.5 6.97715 18.0228 2.5 12.5 2.5ZM3.76405 12C4.01386 7.56564 7.56564 4.01386 12 3.76405V7.52469C9.63779 7.75922 7.75922 9.63779 7.52469 12H3.76405ZM3.76405 13C4.01386 17.4344 7.56564 20.9861 12 21.236V17.4753C9.63779 17.2408 7.75922 15.3622 7.52469 13H3.76405ZM13 21.236C17.4344 20.9861 20.9861 17.4344 21.236 13H17.4753C17.2408 15.3622 15.3622 17.2408 13 17.4753V21.236ZM13 3.76405C17.4344 4.01386 20.9861 7.56564 21.236 12H17.4753C17.2408 9.63779 15.3622 7.75922 13 7.52469V3.76405ZM8.75 12.5C8.75 10.4289 10.4289 8.75 12.5 8.75C14.5711 8.75 16.25 10.4289 16.25 12.5C16.25 14.5711 14.5711 16.25 12.5 16.25C10.4289 16.25 8.75 14.5711 8.75 12.5Z"
    />
  </IconWrapper>
)

export default MissingDocumentsIcon
