import React, { Component, ReactNode } from 'react'
import ControlledAsideWrapper, { CloseReason } from './aside_wrapper'

interface Props {
  className?: string
  footer?: ReactNode
  isOpen?: boolean
  asideWidth?: string | number
  onRequestClose: (reason: CloseReason) => void
}

export default class Aside extends Component<Props> {
  static defaultProps = {
    asideWidth: '34rem'
  }

  render() {
    const { children, asideWidth, onRequestClose, isOpen } = this.props

    return (
      <ControlledAsideWrapper onRequestClose={onRequestClose} isOpen={isOpen} asideWidth={asideWidth}>
        {children}
      </ControlledAsideWrapper>
    )
  }
}
