import i18next from 'i18next'
import _compact from 'lodash/compact'
import _startsWith from 'lodash/startsWith'
import { getOrdinal, capitalize } from './language_helpers'
import { getModelValue, getCurrentKaseKind, getResourceContext } from 'reducers/selectors'
import { buildBeneficiary } from 'resource_context/marriage_based_green_card/helpers'

const INDEX_REGEX = /\.(\d+)\./ // e.g. 'children.0.' => ['.0.', '0']

export default class DocumentRequestLocale {
  private store

  constructor(store) {
    this.store = store
  }

  t(documentRequest: DocumentRequestModel, part: string, shouldCapitalize = false, returnNullIfNotFound = false) {
    const dataPath = `document_requests.${documentRequest.document_type}.${part}`
    const result = i18next.t(dataPath, this.resourceContext(documentRequest, shouldCapitalize))

    const resultNotFound = dataPath.toLowerCase() === result.toLowerCase()
    if (returnNullIfNotFound && resultNotFound) {
      return null
    }

    return result
  }

  // private

  getKaseKind() {
    return getCurrentKaseKind(this.store.getState())
  }

  // TODO[naturalization]: this really sucks but I'd like to avoid refactoring
  // this whole i18n situation if possible, until post-naturalization-launch.
  getPerson(path: string, context: any) {
    switch (this.getKaseKind()) {
      case 'Naturalization': {
        if (_startsWith(path, 'applicant.current_marriage.spouse')) {
          return context.spouse
        } else {
          return context.applicant
        }
      }

      case 'MarriageBasedGreenCard':
      default: {
        if (path.match(/^sponsor/)) {
          return context.sponsor
        } else if (path.match(/^beneficiary/) || path.match(/marriage_certificate/)) {
          return context.beneficiary
        } else if (path.match(/^joint_sponsor/)) {
          return context.joint_sponsor
        }
      }
    }

    return null
  }

  /**
   * Trims last item from a path
   *
   * @param path - a string,
   * representing dot-separated path
   * @returns path to a parent model
   * @example
   *
   * trimLastItemInPath('foo.bar.baz')
   * // =>'foo.bar'
   */
  trimLastItemInPath(path: string) {
    const pathParts = path.split('.')
    pathParts.pop()

    return pathParts.join('.')
  }

  getAssetContext(path: string) {
    const state = this.store.getState()
    const personPath = path.split('.assets.')[0]
    const assetPath = this.trimLastItemInPath(path)
    const person = getModelValue(state, personPath)
    const asset = getModelValue(state, assetPath)

    return {
      owner: person.name.first_name,
      institution: asset.institution,
      accountNumber: asset.account_number,
      otherKindName: asset.other_kind_name,
      streetAddress: asset.property_street_address
    }
  }

  resourceContext(documentRequest: DocumentRequestModel, shouldCapitalize: boolean) {
    const { path } = documentRequest
    const state = this.store.getState()
    const baseContext = getResourceContext(state)

    const result = {
      person: this.getPerson(path, baseContext),
      last_three_tax_years: baseContext.last_three_tax_years,
      mostRecentTaxYear: baseContext.mostRecentTaxYear,
      mostRecentTaxYearForPublicCharge: baseContext.mostRecentTaxYearForPublicCharge,
      externalDomain: baseContext.externalDomain,
      assetsWorksheetUrl: baseContext.assetsWorksheetUrl
    }

    if (documentRequest.title) {
      result.request_title = documentRequest.title
    }

    const numberMatches = path.match(INDEX_REGEX)

    if (numberMatches) {
      const ordinal = getOrdinal(numberMatches[1])

      result.ordinal = shouldCapitalize ? capitalize(ordinal) : ordinal
    }

    if (documentRequest.document_type === 'previous_name') {
      this.addPreviousNameContext(path, result)
    }

    if (documentRequest.document_type === 'previous_marriage') {
      this.addPreviousMarriageContext(path, result)
    }

    if (path.includes('members_who_are_not_bene_or_children') || path.includes('children')) {
      this.addFamilyMemberContext(path, result, state)
    }

    if (path.includes('sponsor') && !path.includes('joint_sponsor')) {
      this.addFamilyMemberContextForSponsor(path, result)
    }

    if (path.match(/\.assets\./)) {
      result.assetContext = this.getAssetContext(path)
    }
    return result
  }

  // TODO[naturalization]: remove this workaround
  addPreviousMarriageContextNaturalization(path, context) {
    const previousMarriage = this.getParentModel(path)

    if (previousMarriage) {
      const spouseName = _compact([
        previousMarriage.spouse.name.first_name,
        previousMarriage.spouse.name.middle_name,
        previousMarriage.spouse.name.last_name
      ])
        .map(capitalize)
        .join(' ')

      context.spouseName = spouseName || 'their previous spouse'
    }
  }

  addPreviousMarriageContext(path, context) {
    if (this.getKaseKind() === 'Naturalization') {
      return this.addPreviousMarriageContextNaturalization(path, context)
    }

    const previousMarriage = this.getParentModel(path)

    if (previousMarriage) {
      const spouseName = _compact([
        previousMarriage.spouse_name.first_name,
        previousMarriage.spouse_name.middle_name,
        previousMarriage.spouse_name.last_name
      ])
        .map(capitalize)
        .join(' ')

      context.spouseName = spouseName || 'their previous spouse'
    }
  }

  addPreviousNameContext(path, context) {
    let previousName = this.getParentModel(path)

    if (!(previousName.first_name || previousName.last_name)) {
      context.shortPreviousName = context.person.name
      context.previousName = null
      return
    }

    previousName = _compact([previousName.first_name, previousName.middle_name, previousName.last_name])
      .map(capitalize)
      .join(' ')

    let shortPreviousName = previousName

    if (previousName.length > 10) {
      shortPreviousName = `${previousName.slice(0, 10)}…`
    }

    context.shortPreviousName = shortPreviousName
    context.previousName = ` "${previousName}"`
  }

  addFamilyMemberContext(path, context, state) {
    const familyMember = this.getParentModel(path)
    const beneficiary = buildBeneficiary(state)

    if (familyMember && familyMember.name) {
      context.familyMember = {
        name: familyMember.name.first_name
      }
    }

    context.beneficiary = beneficiary
  }

  addFamilyMemberContextForSponsor(path: string, context: any) {
    const familyMember = this.getParentModel(path)

    if (familyMember && familyMember.name) {
      context.familyMember = {
        name: familyMember.name.first_name
      }
    }
  }

  getParentModel(path: string) {
    const parentPath = this.trimLastItemInPath(path)

    return getModelValue(this.store.getState(), parentPath)
  }
}
