import React from 'react'
import { connect } from 'react-redux'

import formatMoney from 'lib/format_money'

import { getApplicationFeeInCents, getCurrentApplicationFeeInCents, isApplicationFeeChanged } from 'reducers/selectors'

interface Props {
  applicationFeeInCents: number
  currentApplicationFeeInCents: number
  feeChanged: boolean
}

class Fee extends React.Component<Props> {
  render() {
    let strikeout = { textDecoration: 'line-through' }
    if (!this.props.feeChanged) {
      return <span> {formatMoney(this.props.applicationFeeInCents)}</span>
    } else {
      return (
        <span>
          <span style={strikeout}>{formatMoney(this.props.applicationFeeInCents)}</span>
          <span> {formatMoney(this.props.currentApplicationFeeInCents)}</span>
        </span>
      )
    }
  }
}

function mapStateToProps(state) {
  return {
    applicationFeeInCents: getApplicationFeeInCents(state),
    currentApplicationFeeInCents: getCurrentApplicationFeeInCents(state),
    feeChanged: isApplicationFeeChanged(state)
  }
}

export default connect(mapStateToProps)(Fee)
