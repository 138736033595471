import CommonService from './common/service'

export default class TelemetryService extends CommonService {
  constructor(userData) {
    super()
    this.userData = userData
  }

  track(eventName, eventData = {}, callback = null) {
    return this.reporter.track(eventName, eventData, callback)
  }

  captureGoogleAdsConversion(eventName, eventData = {}) {
    return this.reporter.captureGoogleAdsConversion(eventName, eventData)
  }

  captureFacebookPixelConversion(eventName, eventData = {}) {
    return this.reporter.captureFacebookPixelConversion(eventName, eventData)
  }

  reset() {
    this.reporter.reset()
  }

  setupSession(user) {
    this._toggleOptIn(user)
    this.identify()
  }

  _toggleOptIn(user) {
    this._boundlessUser(user) ? this.reporter.optOut() : this.reporter.optIn()
  }

  _boundlessUser(user) {
    return !!user.email.trim().match(/@boundless.(co|com)$/i)
  }
}
