import server from './server'
import { AxiosPromise } from 'axios'

interface TagJsonApiData {
  id: string
  type: string
  attributes: {
    name: string
  }
}

function index(kaseId: string | number): AxiosPromise<TagJsonApiData[]> {
  return server.get(`/api/v1/kases/${kaseId}/tags`).then(({ data }) => data.data)
}

export default { index }
