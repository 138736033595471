import React from 'react'
import { connect } from 'react-redux'

import Section from './section'
import SectionEntry from './section_entry'

import datedEntryLabel from './dated_entry_label'
import formatDate from '../../../lib/format_date'

import { getAccountHolderRole, getModelValue } from 'reducers/selectors'

import { Role } from 'components/forms/inputs/index'

interface Props {
  accountHolderRole: string
  employments: any[]
  personFirstName: string
  role: Role
}

class EmploymentHistory extends React.Component<Props> {
  renderCurrentEmployment() {
    const { employments, role } = this.props
    if (employments.length === 0) return
    const currentEmployment = employments[0]
    const nextEmployment = employments[1]
    let label

    if (currentEmployment.start_date) {
      label = datedEntryLabel(currentEmployment, { current: true })
    } else if (nextEmployment && nextEmployment.end_date.date) {
      label = datedEntryLabel(currentEmployment, {
        startOverride: formatDate.reviewScreen(nextEmployment.end_date.date),
        current: true
      })
    }

    return <SectionEntry label={label} value={currentEmployment} editHref={`${role}-current-employer`} />
  }

  renderEmployment = (employment, i) => {
    if (employment.has_previous_employment === 'no') return null

    return (
      <SectionEntry
        key={i + 1}
        label={datedEntryLabel(employment)}
        value={employment}
        editHref={`${this.props.role}-previous-employer/${i + 1}`}
      />
    )
  }

  render() {
    const name = this.props.personFirstName || 'Spouse'
    const header =
      this.props.accountHolderRole === this.props.role ? 'Your Employment History' : `${name}'s Employment History`

    return (
      <Section headerText={header}>
        {this.renderCurrentEmployment()}
        {this.props.employments.slice(1).map(this.renderEmployment)}
      </Section>
    )
  }
}

function mapStateToProps(state, props) {
  return {
    accountHolderRole: getAccountHolderRole(state),
    employments: getModelValue(state, `${props.role}.employments`),
    personFirstName: getModelValue(state, `${props.role}.name.first_name`)
  }
}

export default connect(mapStateToProps)(EmploymentHistory)
