import { ShouldSkipCollection } from '.'

const defaultSkipFunctions: ShouldSkipCollection = {
  sponsor_email: 'beneficiaryRole',
  petition_beneficiary_profile: 'beneficiaryRole',
  sponsor_current_employer_address: 'sponsorCurrentlyUnemployed',
  sponsor_previous_employer_address: [
    {
      functionName: 'hasNoFurtherPreviousEmployment',
      args: {
        path: 'sponsor.employments.$'
      }
    }
  ],
  beneficiary_email: 'sponsorRole',
  last_together_address: 'coupleCohabitate',
  last_together_address_dates: 'togetherAddressIfAnyIsNotPast',
  beneficiary_outside_address: 'beneficiaryHasOutsideUSAddress',
  beneficiary_outside_address_dates: 'beneficiaryHasOutsideUSAddress',
  beneficiary_current_employer_address: 'beneficiaryCurrentlyUnemployed',
  beneficiary_outside_employer: 'beneficiaryHasOutsideUSEmployment',
  beneficiary_outside_employer_address: 'beneficiaryOutsideEmploymentIfAnyIsNotPast',
  beneficiary_previous_employer_address: [
    {
      functionName: 'hasNoFurtherPreviousEmployment',
      args: {
        path: 'beneficiary.employments.$'
      }
    }
  ],
  criminal_intent_or_history_first_aos: 'isConsular',
  criminal_intent_or_history_second_aos: 'isConsular',
  criminal_intent_or_history_third_aos: 'isConsular',
  criminal_intent_or_history_fourth_aos: 'isConsular',
  criminal_intent_or_history_first_cp: 'isConcurrent',
  criminal_intent_or_history_second_cp: 'isConcurrent',
  criminal_intent_or_history_third_cp: 'isConcurrent',
  criminal_intent_or_history_fourth_cp: 'isConcurrent',
  question_status: 'hasEmploymentBasedGC'
}

const skipFunctions: ShouldSkipCollection = Object.assign({}, defaultSkipFunctions)

export default skipFunctions
