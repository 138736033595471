import React, { FunctionComponent } from 'react'
import IconWrapper, { IconWrapperExternalProps } from './icon_wrapper'

const CalendarIcon: FunctionComponent<IconWrapperExternalProps> = (props) => (
  <IconWrapper {...props} fill="none" viewBox="0 0 24 24" stroke="currentColor" style={{ fill: 'none' }}>
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
    />
  </IconWrapper>
)

export default CalendarIcon
