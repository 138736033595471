import React, { FunctionComponent } from 'react'

import { DocumentUploadSummaryStates } from 'lib/constants'
import { DashboardPhase } from 'components/screens/dashboard/customer_dashboard/phase_map'
import {
  beingReviewedWithActiveRequestsContent,
  commentsToReadContent,
  docGatheringUploadingInProgressContent,
  initialDocRequestContent,
  aosInReviewNoActiveRequestContent,
  cpInReviewNoActiveRequestContent,
  questionnairesCompletedAfterFinalReview
} from 'components/screens/dashboard/customer_dashboard/phases/document_upload_phase_information/doc_upload_phase_content'

import PhaseInformationTemplate from 'components/screens/dashboard/customer_dashboard/phase_information/phase_information_template'
import FancySpinner from 'components/admin/fancy_spinner'

interface ExplicitProps {
  actionableDocumentRequestCount: number
  currentDocumentUploadSummaryState: DocumentUploadSummaryStates
  currentPhase: DashboardPhase
  isAOS: boolean
}

const DocGatheringQuestionnairesComplete: FunctionComponent<ExplicitProps> = ({
  actionableDocumentRequestCount,
  currentDocumentUploadSummaryState,
  currentPhase,
  isAOS
}) => {
  const allSectionsAcceptedContent = () => {
    if (window.isUserCompletionEnabled()) {
      return questionnairesCompletedAfterFinalReview
    } else {
      if (isAOS) {
        return aosInReviewNoActiveRequestContent
      } else {
        return cpInReviewNoActiveRequestContent
      }
    }
  }

  const contentForPhase = () => {
    switch (currentDocumentUploadSummaryState) {
      case DocumentUploadSummaryStates.UploadingNotStarted:
        return initialDocRequestContent(true)
      case DocumentUploadSummaryStates.ThresholdNotReached:
        return docGatheringUploadingInProgressContent
      case DocumentUploadSummaryStates.ThresholdReachedNoComments:
        return beingReviewedWithActiveRequestsContent(actionableDocumentRequestCount)
      case DocumentUploadSummaryStates.CommentsExist:
        return commentsToReadContent
      case DocumentUploadSummaryStates.AllSectionsCustomerCompleted:
        if (isAOS) {
          return aosInReviewNoActiveRequestContent
        } else {
          return cpInReviewNoActiveRequestContent
        }
      case DocumentUploadSummaryStates.AllSectionsAccepted:
        return allSectionsAcceptedContent()
      default:
        return null
    }
  }

  const currentContent = contentForPhase()

  if (!currentContent) {
    return (
      <div className="o-block o-layout--center flex items-center justify-center" style={{ height: '100%' }}>
        <FancySpinner variation="primary" />
      </div>
    )
  }

  return (
    <PhaseInformationTemplate
      currentPhase={currentPhase}
      primaryCTA={currentContent.primaryCTA}
      header={currentContent.phaseInstruction}
      description={currentContent.phaseDescription}
    />
  )
}

export default DocGatheringQuestionnairesComplete as FunctionComponent<ExplicitProps>
