import React, { FunctionComponent } from 'react'

interface ExplicitProps {
  content: string
}

const EligibilityInfoSectionHeader: FunctionComponent<ExplicitProps> = ({ content }) => {
  return (
    <div className="o-grid--fluid o-grid--fluid--trailed pr-8">
      <div className="o-box o-box--light o-box--dense">
        <div className="o-layout o-layout--padded-x--minuscule c-type c-type--subhead-sm">{content}</div>
      </div>
    </div>
  )
}

export default EligibilityInfoSectionHeader as FunctionComponent<ExplicitProps>
