import u from 'updeep'
import { createSelector } from 'reselect'
import moment from 'moment'

import _every from 'lodash/every'
import _find from 'lodash/find'
import _findIndex from 'lodash/findIndex'
import _flatMap from 'lodash/flatMap'
import _get from 'lodash/get'
import _groupBy from 'lodash/groupBy'
import _isEmpty from 'lodash/isEmpty'
import _some from 'lodash/some'
import _values from 'lodash/values'
import _includes from 'lodash/includes'

import PanelMetadata from 'components/forms/panel/metadata'
import RepeatablePanelGroup from 'components/forms/panel/repeatable_panel_group'
import { isPathNeeded as isPathNeededLib } from 'lib/model/needed'
import { shouldSkipPanel } from './panels/should_skip'
import { shouldSkipChapter } from './chapters/should_skip'
import { getChaptersForSection } from './chapters/order'
import { getPanelCondition, getCondition } from 'lib/conditions'
import {
  SECTION_NAME_SETUP,
  SECTION_NAME_PETITION,
  LEAD_CAPTURE_PANEL_SLUGS,
  SAVING_STATES,
  SECTION_NAME_PUBLIC_CHARGE,
  WorkflowCategory
} from 'lib/constants'
import { NULL_VALIDATION } from 'lib/validations/constants'
import { getValueAtPath } from 'lib/model/data'
import { isBlank, isPresent } from 'lib/presence'
import { getPanelSequence } from './panels/graph'

import getSectionErrors from './panels/errors'
import getSectionOutcome from './sections/outcomes'
import decorate from 'reducers/model/decorators'
import panelLocaleService from 'services/panel_locale_service'
import { joinI18nKeys } from 'lib/i18n'
import _compact from 'lodash/compact'

// We keep a local copy in the reducer here so we can filter panels
// effectively without having to plumb it through everywhere.
const getRawModelData = (model) => model.data
const getCurrentKaseKind = (model) => model.currentKaseKind

export const getModelData = createSelector(getRawModelData, getCurrentKaseKind, (data, kaseKind) =>
  decorate(data, kaseKind)
)

export const getSections = (model) => model.sections

// B20-1267
export const getAccountHolderRole = createSelector(getModelData, (data) => (isPresent(data) ? data.account.role : null))

// ********* Model data values
export const getModelValue = (model, path) => getValueAtPath(getModelData(model), path)

//
export const getModelValues = (model, paths) => paths.map((path) => getModelValue(model, path))

export const isModelValueEmpty = (model, path) => _isEmpty(getModelValue(model, path))

export const isSetupIneligible = createSelector(getModelData, (data) => data.setupIneligible())

export const isQualifierYesAtPath = (model, path) => getModelValue(model, path) === 'yes'

export const sponsorHasPetitionHistory = (model) => getModelValue(model, 'sponsor.petition_history') // B20-1267

export const sponsorIsCitizen = (model) => getModelValue(model, 'sponsor.legal_status.code') === 'usc' // B20-1267

export const getPreviousNames = (model, role) => getModelValue(model, `${role}.previous_names.items`)

export const areSponsorAndBeneficiaryLivingTogether = (model) =>
  getModelValue(model, 'computed_currently_live_together')

export const beneficiaryHasInternationalAddressHistory = (model) =>
  _some(getModelValue(model, 'beneficiary.addresses'), (address) => address.country.code !== 'US')

export const beneficiaryHasInternationalEmploymentHistory = (model) =>
  _some(getModelValue(model, 'beneficiary.employments'), (employment) => employment.address.country.code !== 'US')

function currentNaturalizationAccountAddress(model) {
  return getModelValue(model, 'applicant.addresses.0')
}

function currentMarriageGreenCardAccountAddress(model, path) {
  return getModelValue(model, path)
}

export const getAccountCurrentAddress = (model, kaseKind) => {
  switch (kaseKind) {
    case 'Naturalization':
      return currentNaturalizationAccountAddress(model)

    case 'MarriageBasedGreenCard':
    default: {
      const path = getAccountHolderRole(model) === 'sponsor' ? 'sponsor.addresses.0' : 'beneficiary.addresses.0'

      return currentMarriageGreenCardAccountAddress(model, path)
    }
  }
}

export const getSponsorFirstName = (model) => getModelValue(model, 'sponsor.name.first_name') || undefined // B20-1267

export const getSponsorAddressHistory = (model) => getModelValue(model, 'sponsor.addresses')

// B20-1267
export const getBeneficiaryFirstName = (model) => getModelValue(model, 'beneficiary.name.first_name') || undefined

export const getApplicantFirstName = (model) => getModelValue(model, 'applicant.name.first_name') || undefined

// B20-1267
export const getJointSponsorFirstName = (model) => getModelValue(model, 'joint_sponsor.name.first_name') || undefined

export const getBeneficiaryAddressHistory = (model) => getModelValue(model, 'beneficiary.addresses')

export const getNatzApplicantFirstName = (model) => getModelValue(model, 'applicant.name.first_name') || undefined

export const getHouseholdMembersFirstNames = (model) => {
  // return an array of first names
  const householdMembers = getModelValue(model, 'household.members_who_are_not_bene_or_children.items')
  if (householdMembers) {
    return householdMembers.map((member) => _get(member, 'name.first_name', null))
  } else {
    return []
  }
}

export const getChildrenFirstNames = (model) => {
  // return an array of first names
  const children = getModelValue(model, 'beneficiary.children')
  if (children) {
    return children.map((child) => _get(child, 'name.first_name', null))
  } else {
    return []
  }
}

export const getUnitConversionPreference = (model) => getModelValue(model, 'preferences.unit_conversions')

export const REPEATABLE_PANEL_GROUP = 'repeatable_panel_group'

export const hasEnteredContactInfo = createSelector(getModelData, getCurrentKaseKind, (modelData, kaseKind) => {
  const role = modelData.get('account.role') // B20-1267
  if (!role) {
    return false
  }

  const contactInfoPath = kaseKind === 'MarriageBasedGreenCard' ? 'uscis_contact_info' : 'contact_info'

  const emailEntered = !modelData.valueIsMissing(`${role}.${contactInfoPath}.email`)
  const phoneEntered =
    !modelData.valueIsMissing(`${role}.${contactInfoPath}.mobile_phone.country_code)`) &&
    !modelData.valueIsMissing(`${role}.${contactInfoPath}.mobile_phone.number)`)

  return emailEntered || phoneEntered
})

export const isJointSponsorRequired = (model) => {
  return (
    Boolean(getModelValue(model, 'joint_sponsor_required')) ||
    Boolean(getModelValue(model, 'household.has_additional_sponsors')) // B20-1267
  )
}

export const isInterpreterInfoRequired = (model) => {
  return Boolean(getModelValue(model, 'beneficiary.has_interpreter'))
}

export const isWorkflowCategoryAOS = (model) => {
  const workflowCategory = getModelValue(model, 'computed_workflow_category')

  return workflowCategory === WorkflowCategory.AOS
}

export const isWorkflowCategoryCP2 = (model) => {
  return Boolean(getModelValue(model, 'cp2_ready_for_docs'))
}

// ********* MBGC / resource context model functions
export const getHouseholdSize = (model) => {
  const dependentChildren = getModelValue(model, 'household.dependent_children') || 0 // B20-1267

  const otherDependents = getModelValue(model, 'household.other_dependents') || 0 // B20-1267

  const othersSupported = getModelValue(model, 'household.others_supported') || 0 // B20-1267

  return dependentChildren + otherDependents + othersSupported
}

export const getSponsorProvince = (model) => getModelValue(model, 'sponsor.addresses.0.province')

export const getApplicantProvince = (model) => getModelValue(model, 'applicant.addresses.0.province')

export const getMBGCSpouse = (model) => {
  switch (getAccountHolderRole(model)) {
    case 'sponsor':
      return getModelValue(model, 'beneficiary')
    case 'beneficiary':
      return getModelValue(model, 'sponsor')
    default:
      return null
  }
}

export const getWorkflowCategory = (model) => getModelValue(model, 'computed_workflow_category')

// ********* Naturalization data model functions
export const getNaturalizationHouseholdSize = (model) => getModelValue(model, 'household.other_members') || 0

const isGreenCardDateBetween3And5YearsAgo = (model) => {
  let date = getModelValue(model, 'eligibility.green_card_date')

  if (!date) return false

  date = moment(date, 'YYYY-MM-DD')

  return date.isAfter(moment().subtract(5, 'years')) && date.isBefore(moment().subtract(3, 'years'))
}

const marriedToUsCitizen = (model) => Boolean(getModelValue(model, 'eligibility.married_to_us_citizen'))

const marriageOlderThan3Years = (model) => {
  let marriageDate = getModelValue(model, 'applicant.current_marriage.start_date')

  if (!marriageDate) return false

  marriageDate = moment(marriageDate, 'YYYY-MM-DD')

  return marriageDate.isBefore(moment().subtract(3, 'years'))
}

const spouseHasBeenCitizenForAtLeast3Years = (model) => {
  const citizenSinceBirth = Boolean(getModelValue(model, 'applicant.current_marriage.spouse.citizen_since_birth'))

  if (citizenSinceBirth) return true

  let date = getModelValue(model, 'applicant.current_marriage.spouse.citizenship_date')

  if (!date) return false

  date = moment(date, 'YYYY-MM-DD')

  return date.isBefore(moment().subtract(3, 'years'))
}

export const isFilingOnMarriageBasis = (state) =>
  isGreenCardDateBetween3And5YearsAgo(state) &&
  marriedToUsCitizen(state) &&
  marriageOlderThan3Years(state) &&
  spouseHasBeenCitizenForAtLeast3Years(state)

// ********* session functions
// TODO consider moving to session reducer
export const isUserLoggedIn = (model) => Boolean(model.isLoggedIn)

// ********* Needed/metadata
export const getMetadata = (model) => model.metadata

export const getKaseTags = (model) => model.tags

export const readableKaseTags = {
  CR1: 'spousal visa',
  AOS: 'marriage green card',
  K1: 'finance visa'
}

export const getReadableKaseTag = (model) => {
  const kaseTags = getKaseTags(model)
  const readableKaseTag = kaseTags.find((tag) => readableKaseTags[tag.name])

  return readableKaseTag ? readableKaseTags[readableKaseTag.name] : 'visa'
}

export const isPathNeeded = (model, path) => isPathNeededLib(getModelData(model), getMetadata(model), path)

// B20-1267
export const beneficiaryNotInUS = (model) => Boolean(getModelValue(model, 'eligibility.beneficiary_not_in_us'))

// ********* Validations
export const getValidationConfig = (model) => model.validationConfig

const getPathValidations = (model) => model.pathValidations

export function getValidationResultAtPath(model, path) {
  return getPathValidations(model)[path] || NULL_VALIDATION
}

export const getActiveValidationPaths = createSelector(
  (model) => model,
  getPathValidations,
  (model, validations) =>
    Object.keys(validations).filter((path) => isPathNeeded(model, path) && validations[path].state.fired)
)

// ********* Section/Chapter functions
export const getCurrentSectionName = (model) => model.currentSection

export const getSectionByName = (model, sectionName) => model.sections && model.sections[sectionName]

const isOnSection = (name) => createSelector(getCurrentSectionName, (sectionName) => sectionName === name)

export const isOnSetupSection = isOnSection(SECTION_NAME_SETUP)
export const isOnPetitionSection = isOnSection(SECTION_NAME_PETITION)
export const isOnPublicChargeEstimatorSection = isOnSection('public_charge_estimator')
export const getCurrentContentSource = (model) => model.currentContentSource
export const getCurrentPanelSlug = (model) => model.currentPanel
export const getCurrentPanelSlugIndex = (model) => model.currentPanelIndex
export const getChildPanelSlug = (model) => model.childPanelSlug
export const getChildSlugIndex = (model) => model.childSlugIndex
export const getIndexForCurrentPanel = (model) => {
  return model.childSlugIndex || model.currentPanelIndex
}

// helpers
const shouldShowChapter = (modelData, kaseKind) => (chapterName) => {
  const doNotSkip = !shouldSkipChapter({
    kaseKind,
    modelData,
    chapterName
  })

  return doNotSkip
}

const getLastViewedSectionAndPanelName = (model) => model.lastViewedSectionAndPanelName

const getLastViewedSectionName = createSelector(getLastViewedSectionAndPanelName, (lastViewedSectionAndPanelName) => {
  if (!lastViewedSectionAndPanelName) {
    return null
  }

  const lastViewedSectionAndPanelSplit = lastViewedSectionAndPanelName.split('.')

  if (lastViewedSectionAndPanelSplit.length === 2) {
    return lastViewedSectionAndPanelSplit[0]
  } else {
    return null
  }
})

const getLastViewedPanelName = createSelector(getLastViewedSectionAndPanelName, (lastViewedSectionAndPanelName) => {
  if (!lastViewedSectionAndPanelName) {
    return null
  }

  const lastViewedSectionAndPanelSplit = lastViewedSectionAndPanelName.split('.')

  if (lastViewedSectionAndPanelSplit.length === 2) {
    return lastViewedSectionAndPanelSplit[1]
  } else {
    return lastViewedSectionAndPanelSplit[0]
  }
})

const panelIsComplete = (panel) => panel && panel.hasData && panel.complete

const panelIsQuestionPanel = (panel) => {
  if (!panel || !panel.fields) return false

  return panel.navigable && panel.fields.length
}

const getQuestionPanels = (panels) => panels.filter(panelIsQuestionPanel)

function findPanelByName(panels, panelName) {
  if (!panelName) {
    return
  }

  return _find(panels, (panel) => panel.name === panelName)
}

function findLeftmostInterstitialPanelFromIndex(panels, index) {
  let panelToReturn = null

  let previousPanelIndex = index - 1
  while (previousPanelIndex >= 0) {
    const previousPanel = panels[previousPanelIndex]
    if (!previousPanel.component) {
      break
    }

    panelToReturn = previousPanel
    previousPanelIndex--
  }

  return panelToReturn
}

function findFirstIncompletePanel(panels) {
  for (let i = 0; i < panels.length; i++) {
    const panel = panels[i]
    // skip complete panels and interstitials
    if (!panel.component && !panel.complete) {
      const previousInterstitial = findLeftmostInterstitialPanelFromIndex(panels, i)

      // if the previous panel is an interstitial, use that instead
      // this helps us display important interstitials
      // even if a section has some pre-populated panels
      // (ie progress > 0% by default)
      return previousInterstitial || panel
    }
  }

  return null
}

function findPanelWithSlug(panels, slug) {
  if (isBlank(slug)) {
    return null
  }

  return _find(panels, (panel) => panel.slug === slug)
}

function findPanelIndexBySlug(panels, panelSlug) {
  return _findIndex(panels, (panel) => panel.slug === panelSlug)
}

const shouldShowPanel = (modelData, kaseKind) => (panel) => {
  const doNotSkip = !shouldSkipPanel({
    panel,
    kaseKind,
    modelData
  })

  const hiddenIf = panel.hidden_if && getPanelCondition(panel.hidden_if)
  const hidden = Boolean(hiddenIf && hiddenIf())

  return doNotSkip && !hidden
}

/**
 * Returns the progress through the provided panels as a percentage
 */
export const getProgressForPanels = (panels) => {
  let progress = 0
  let progressContributingPanels = panels.filter((panel) => panel.contributesToProgress)
  if (progressContributingPanels.length > 0) {
    const completedPanels = progressContributingPanels.filter((panel) => panel.complete)
    progress = Math.floor((completedPanels.length / progressContributingPanels.length) * 100)
  }

  return progress
}

// TODO having getSectionChapters and getChaptersForSection is confusing
const getSectionChapters = ({ section, modelData, kaseKind }) =>
  getChaptersForSection({ section, modelData, kaseKind }).filter((chapter) =>
    shouldShowChapter(modelData, kaseKind)(chapter.name)
  )

const getPanelMetadatas = (panel, kaseKind, modelData, modelMetadata, validationConfig) => {
  let instances

  if (panel.type === REPEATABLE_PANEL_GROUP) {
    instances = new RepeatablePanelGroup(panel, kaseKind, modelData).getInstances()
  } else {
    instances = [panel]
  }

  return instances
    .map((instance) => new PanelMetadata(instance, kaseKind, modelData, modelMetadata, validationConfig).getMetadata())
    .filter((panelMetadata) => panelMetadata.component || !_isEmpty(panelMetadata.fields))
}

const getPanelsForSection = ({ section, modelData, kaseKind, modelMetadata, validationConfig }) => {
  const sectionChapters = getSectionChapters({
    section,
    modelData,
    kaseKind
  })

  let panels = []
  if (section.uses_graph) {
    const panelMap = {}
    sectionChapters.forEach((chapter) => {
      chapter.panels.forEach((panel) => {
        const key = `${chapter.name}.${panel.name}`
        panelMap[key] = panel
      })
    })

    const panelSequence = getPanelSequence({
      kaseKind,
      modelData,
      sectionName: section.name
    })

    if (panelSequence == null || _isEmpty(panelSequence)) {
      panels = _flatMap(sectionChapters, (chapter) => chapter.panels)
    } else {
      panels = _flatMap(panelSequence, (key) =>
        getPanelMetadatas(panelMap[key], kaseKind, modelData, modelMetadata, validationConfig)
      )
    }
  } else {
    panels = _flatMap(sectionChapters, (chapter) =>
      _flatMap(chapter.panels, (panel) =>
        getPanelMetadatas(panel, kaseKind, modelData, modelMetadata, validationConfig)
      )
    )
  }

  return panels.filter(shouldShowPanel(modelData, kaseKind))
}

const dynamicChapterMetadata = (chapterPanels) => {
  // If every panel in the chapter is an interstitial
  const chapterStatic = _every(chapterPanels, (panel) => panel.component)

  // If every panel in the chapter is complete (or an interstitial)
  const chapterComplete = _every(chapterPanels, (panel) => panel.component || panel.complete)

  // If at least one panel in the chapter has data
  const chapterHasSomeData = _some(chapterPanels, (panel) => panel.hasData)

  return {
    complete: chapterComplete,
    hasSomeData: chapterHasSomeData,
    panels: chapterPanels,
    static: chapterStatic
  }
}

// selectors
export const getCurrentSectionOutcome = createSelector(
  getCurrentSectionName,
  getModelData,
  getCurrentKaseKind,
  (sectionName, modelData, kaseKind) =>
    getSectionOutcome({
      modelData,
      kaseKind,
      sectionName
    })
)

export const getCurrentSection = (model) => getSectionByName(model, getCurrentSectionName(model))

export const getCurrentFlattenedPanels = createSelector(
  getModelData,
  getCurrentSection,
  getCurrentKaseKind,
  getMetadata,
  getValidationConfig,
  (modelData, section, kaseKind, modelMetadata, validationConfig) => {
    if (!section) return []

    return getPanelsForSection({
      section,
      modelData,
      kaseKind,
      modelMetadata,
      validationConfig
    })
  }
)

export const getCurrentSectionForOutline = createSelector(
  getModelData,
  getCurrentSection,
  getCurrentKaseKind,
  getCurrentFlattenedPanels,
  (modelData, section, kaseKind, panels) => {
    if (!section) return { chapters: [] }

    const sectionChapters = getSectionChapters({
      section,
      modelData,
      kaseKind
    })

    const panelsByChapterName = _groupBy(panels, (panel) => panel.chapter_name)

    return u(
      {
        chapters: sectionChapters.map((chapter) =>
          u(dynamicChapterMetadata(panelsByChapterName[chapter.name]), chapter)
        )
      },
      section
    )
  }
)

/**
 * Returns the sections where `dashboard_meta.show_section` is true, ordered
 * by `dashboard_meta.ordinal`, and with an extra `progress` property.
 */
export const getNavigableSectionsForStopGapDashboard = createSelector(
  getModelData,
  getSections,
  getCurrentKaseKind,
  getMetadata,
  getValidationConfig,
  (modelData, sections, kaseKind, modelMetadata, validationConfig) => {
    const sectionsArray = _values(sections)
      .filter((section) => {
        const show_section = section.dashboard_meta.show_section
        if (typeof show_section === 'string') {
          const condition = show_section && getCondition(show_section)
          if (condition) {
            return condition(modelData)
          } else {
            return false
          }
        }

        return show_section
      })
      .map((section) => {
        const panels = getPanelsForSection({
          section,
          modelData,
          kaseKind,
          modelMetadata,
          validationConfig
        })

        const questionPanels = getQuestionPanels(panels)
        return {
          ...section,
          progress: getProgressForPanels(questionPanels),
          empty: questionPanels.length === 0,
          numPanels: questionPanels.length
        }
      })
      .filter((section) => {
        const { dashboard_meta, numPanels } = section
        if (dashboard_meta.hide_if_no_panels && numPanels === 0) {
          return false
        }
        return true
      })
      .sort((a, b) => (a.dashboard_meta.ordinal > b.dashboard_meta.ordinal ? 1 : -1))

    return sectionsArray
  }
)

export const getNavigableSectionsForCustomerDashboard = createSelector(
  getNavigableSectionsForStopGapDashboard,
  (sections) => {
    return sections.filter((section) => !section.dashboard_meta.is_post_ila_section)
  }
)

export const getPostILASectionsForCustomerDashboard = createSelector(
  getNavigableSectionsForStopGapDashboard,
  (sections) => {
    return sections.filter((section) => section.dashboard_meta.is_post_ila_section)
  }
)

export const isSelfSufficiencyIncomplete = createSelector(getNavigableSectionsForStopGapDashboard, (sections) => {
  return _some(sections, (section) => {
    return section.name === SECTION_NAME_PUBLIC_CHARGE && section.progress < 100
  })
})

export const firstIncompleteSection = createSelector(getNavigableSectionsForStopGapDashboard, (sections) =>
  sections.find((section) => section.progress < 100)
)

export const allSectionsComplete = createSelector(getNavigableSectionsForStopGapDashboard, (sections) =>
  sections.every((section) => section.progress === 100)
)

export const isCurrentlyInSection = createSelector(getCurrentSection, (section) => Boolean(section))

export const getCurrentSectionErrors = createSelector(
  getModelData,
  getCurrentSectionName,
  getCurrentKaseKind,
  (modelData, sectionName, kaseKind) =>
    getSectionErrors({
      modelData,
      sectionName,
      kaseKind
    })
)

export const getCurrentFlattenedPanelsCount = createSelector(
  getCurrentFlattenedPanels,
  (panels) => (panels || []).length
)

export const getCurrentSectionProgress = createSelector(getCurrentFlattenedPanels, (panels) => {
  const questionPanels = getQuestionPanels(panels)
  return getProgressForPanels(questionPanels)
})

const getAllFlattenedPanels = createSelector(
  getModelData,
  getSections,
  getCurrentKaseKind,
  getMetadata,
  getValidationConfig,
  (modelData, sections, kaseKind, modelMetadata, validationConfig) => {
    return _flatMap(sections, (section) =>
      getPanelsForSection({
        section,
        modelData,
        kaseKind,
        modelMetadata,
        validationConfig
      })
    )
  }
)

const getFlattenedPanelsForSection = createSelector(
  (model) => model,
  (model, sectionName) => sectionName,
  getModelData,
  getCurrentKaseKind,
  getMetadata,
  getValidationConfig,
  (model, sectionName, modelData, kaseKind, modelMetadata, validationConfig) => {
    const section = getSectionByName(model, sectionName)

    return getPanelsForSection({
      section,
      modelData,
      kaseKind,
      modelMetadata,
      validationConfig
    })
  }
)

export const getSetupSectionProgress = createSelector(
  (model) => model,
  (model) => {
    const panels = getFlattenedPanelsForSection(model, SECTION_NAME_SETUP)
    const questionPanels = getQuestionPanels(panels)
    return getProgressForPanels(questionPanels)
  }
)

export const getCurrentPanel = createSelector(
  getCurrentFlattenedPanels,
  getCurrentPanelSlug,
  getCurrentPanelSlugIndex,
  getChildPanelSlug,
  getChildSlugIndex,
  (panels, panelSlug, slugIndex, childSlug, childIndex) => {
    const slug = _compact([panelSlug, slugIndex, childSlug, childIndex]).join('/')

    if (isBlank(slug)) {
      return null
    }

    return findPanelWithSlug(panels, slug)
  }
)

export const getCurrentPanelName = createSelector(getCurrentPanel, (panel) => {
  if (!panel) {
    return null
  }

  return panel.name
})

export const getPanelSlug = createSelector(getCurrentPanel, (panel) => {
  if (!panel) {
    return null
  }

  return panel.slug
})

export const getCurrentPanelIndex = createSelector(getCurrentFlattenedPanels, getPanelSlug, findPanelIndexBySlug)

export const isCurrentPanelComplete = createSelector(getCurrentPanel, panelIsComplete)

export const isCurrentPanelCustomComponent = createSelector(getCurrentPanel, (panel) =>
  Boolean(panel && panel.component)
)

export const isCurrentPanelSaveProgressInterstitial = createSelector(getCurrentPanel, (panel) =>
  Boolean(panel && panel.component && panel.component.name === 'SaveProgress')
)

export function getFirstPanelOfSection(model, sectionName) {
  const modelData = getModelData(model)
  const modelMetadata = getMetadata(model)
  const kaseKind = getCurrentKaseKind(model)
  const section = getSectionByName(model, sectionName)
  const validationConfig = getValidationConfig(model)

  return getPanelsForSection({
    section,
    modelData,
    kaseKind,
    modelMetadata,
    validationConfig
  })[0]
}

export const getFirstPanelOfCurrentSection = createSelector(getCurrentFlattenedPanels, (panels) => panels[0])

// ********* panel + chapters
export const getCurrentSectionChapters = createSelector(
  getCurrentSection,
  getModelData,
  getCurrentKaseKind,
  (section, modelData, kaseKind) => {
    return getSectionChapters({ section, modelData, kaseKind })
  }
)

const getCurrentChapterName = createSelector(getCurrentPanel, (panel) => panel.chapter_name)

export const getCurrentChapter = createSelector(
  getCurrentSectionChapters,
  getCurrentChapterName,
  (chapters, chapterName) => {
    return _find(chapters, (chapter) => chapter.name === chapterName)
  }
)

// Initial panel
export const getComputedInitialPanel = createSelector(
  (model) => model,
  getLastViewedSectionName,
  getLastViewedPanelName,
  getCurrentPanelSlug,
  getCurrentFlattenedPanels,
  getCurrentSectionProgress,
  (model, lastViewedSectionName, lastViewedPanelName, initialSlug, panels, progress) => {
    let currentPanels = panels

    if (_isEmpty(currentPanels)) {
      // When the current section cannot be inferred from the slug,
      // we will have an empty set of current panels
      // as getCurrentFlattenedPanels needs the current section

      if (lastViewedSectionName) {
        // if there is a recorded last viewed section, get panels only
        // for that section

        currentPanels = getFlattenedPanelsForSection(model, lastViewedSectionName)
      } else if (lastViewedPanelName || initialSlug) {
        // the below computation is expensive, so we only do it when we need to
        // know the full list of panels (eg in the case when we need to go to a
        // lastViewedPanelName but we don't know which section we are in)

        currentPanels = getAllFlattenedPanels(model)
      } else {
        // fall back to first panel of setup if no direction is given

        return getFirstPanelOfSection(model, SECTION_NAME_SETUP)
      }
    }

    if (initialSlug) {
      return findPanelWithSlug(currentPanels, initialSlug)
    }

    const partiallyThroughSection = progress > 0 && progress < 100

    return (
      (partiallyThroughSection && findFirstIncompletePanel(currentPanels)) ||
      (Boolean(lastViewedPanelName) && findPanelByName(currentPanels, lastViewedPanelName)) ||
      currentPanels[0]
    )
  }
)

export const getAllFieldsInCurrentPanel = createSelector(getCurrentPanel, (panel) => panel.allFields)

// More panel crap
export const getPanelBySlug = (model, slug) => {
  const panels = getAllFlattenedPanels(model)

  return findPanelWithSlug(panels, slug)
}

const contactInfoPanelSlugs = (model) => {
  const role = getAccountHolderRole(model)
  return LEAD_CAPTURE_PANEL_SLUGS.map((slug) => {
    return role + slug
  })
}

export const isLeadCapturePanel = (model, panel = {}) => {
  return _includes(contactInfoPanelSlugs(model), panel.slug)
}

export const isJITFeedbackPanel = (panel) =>
  panelLocaleService.exists(joinI18nKeys(panel.panel_keys, 'jit_feedback.text'))

export const getPreviousPanel = createSelector(
  getCurrentFlattenedPanels,
  getCurrentPanelIndex,
  (panels, currentPanelIndex) => {
    if (currentPanelIndex === 0) {
      return null
    }

    return panels[currentPanelIndex - 1]
  }
)

export const getNextPanel = createSelector(
  getCurrentFlattenedPanels,
  getCurrentPanelIndex,
  (panels, currentPanelIndex) => {
    if (currentPanelIndex === panels.length - 1) {
      return null
    }

    return panels[currentPanelIndex + 1]
  }
)

export const isCurrentlyOnOutcome = createSelector(getCurrentPanel, (panel) => Boolean(panel.outcome))

export const hasCurrentPanelHasBeenModified = (model) => model.currentPanelHasBeenModified

export const getFieldValuesForCurrentPanel = createSelector(getCurrentPanel, getModelData, (panel, data) =>
  panel.fields.reduce((result, field) => {
    result[field.name] = getValueAtPath(data, field.path)
    return result
  }, {})
)

// ********* UI concerns

export const areSaveRequestsInFlight = (model) => model.currentSaveRequestsInFlight > 0

export const hasCurrentPanelBeenModified = (model) => model.currentPanelHasBeenModified

export const isCurrentlySavingData = (model) => model.currentSaveState === SAVING_STATES.saving

export const isDataSaved = (model) => model.currentSaveState === SAVING_STATES.saved || model.currentSaveState === null

export const isPostSaveTransitioning = (model) => model.currentSaveState === SAVING_STATES.transitioning

export const getLastUpdatedAt = (model) => model.lastUpdatedAt
export const getPendingUpdates = (model) => model.pendingUpdates

export const isPanelCurrentlyUpdating = createSelector(
  areSaveRequestsInFlight,
  getPendingUpdates,
  (inFlight, pendingUpdates) => {
    return inFlight || pendingUpdates.length > 0
  }
)

export const isCurrentlyAtSectionStart = createSelector(getCurrentPanel, (panel) => panel.index === 0)

export const isCurrentlyAtEnd = createSelector(getNextPanel, (panel) => !panel)

// ********* Advancing
const panelHasValidationErrors = createSelector(
  (model) => model,
  getPathValidations,
  (model, validations) => {
    if (!validations) return false

    return _some(Object.keys(validations), (validationPath) => {
      if (!isPathNeeded(model, validationPath)) return false

      const validation = validations[validationPath]

      return validation.state.error
    })
  }
)

export const canAdvanceToNextPanel = createSelector(
  isPanelCurrentlyUpdating,
  getCurrentPanel,
  getCurrentSection,
  panelHasValidationErrors,
  (isCurrentlyUpdating, panel, section, hasValidationErrors) => {
    const isPanelIncompleteInLinearSection = section.linear && !panel.complete

    return !(isCurrentlyUpdating || isPanelIncompleteInLinearSection || hasValidationErrors)
  }
)

// ********** Public Charge Results
export const getPublicChargeEstimatorResults = (model) => model.sections.public_charge_estimator
