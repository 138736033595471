export default [
  {
    name: 'Your Info',
    panelChapterMapping: ['Your Profile']
  },
  {
    name: 'Home & Work',
    panelChapterMapping: ['Your Addresses', 'Your Employment']
  },
  {
    name: 'Parents',
    panelChapterMapping: ['Your Parents']
  },
  {
    name: 'Legal Details',
    panelChapterMapping: ['Your Government Info']
  },
  {
    name: 'Your Spouse',
    panelChapterMapping: [
      "Spouse's Profile",
      "Spouse's Addresses",
      "Spouse's Employment",
      "Spouse's Parents",
      "Spouse's Government Info"
    ]
  }
]
