import { LOCATION_CHANGED } from 'redux-little-router'
import u from 'updeep'

import _forEach from 'lodash/forEach'
import _cloneDeep from 'lodash/cloneDeep'
import _last from 'lodash/last'
import _remove from 'lodash/remove'

import {
  LOAD_DATA,
  UPDATE_DATA,
  SAVING_DATA,
  POST_SAVE_TRANSITION_END,
  VALIDATING_PATH,
  VALIDATED_PATH,
  SAVED_DATA,
  SAVING_STATES,
  INITIALIZE_STORE,
  USER_LOGGED_IN,
  LOAD_KASE_METADATA,
  LOAD_KASE,
  LOADING_KASE_TAGS,
  LOADED_KASE_TAGS_SUCCESS,
  LOAD_LAST_VIEWED_SECTION_AND_PANEL_NAME,
  ADDED_ADMIN_DOCUMENT_REQUEST,
  LOAD_KASE_FORM_METADATA
} from 'lib/constants'

import { NULL_VALIDATION } from 'lib/validations/constants'

import { ModelOperation, ModelOperationJson } from 'lib/update_transformations'

import { PathValidationConfig, PathValidationResult } from 'lib/validations'

export type ModelDataPrimitiveValue = boolean | number | string | Date | null

// This is a value that is serializable by a single input component
export type ModelDataSerializableValue = ModelDataPrimitiveValue | ModelDataPrimitiveValue[]

type ModelDataValueSet = ModelDataPrimitiveValue
export type ModelObjectValue = KaseModelUnionType

export type ModelDataValue = ModelDataValueSet | ModelObjectValue | ModelDataValueSet[]

export type ModelDataObject = MarriageBasedGreenCardModel | NaturalizationModel | NewMarriageBasedGreenCardModel | {}

export interface ModelDataPerson {
  gender: 'male' | 'female' | null
  name: PersonNameModel
}

interface ModelUpdate {
  path: string
  operations: ModelOperation[]
}

export interface ServerModelUpdate {
  path: string
  operations: ModelOperationJson[]
}

interface ModelChapter {
  name: string
  title: Nullable<string>
  hidden: boolean
  description: Nullable<string>
  chapter_key: string
  panels: any[] // TODO
  static: boolean
  complete: boolean
  has_some_data: boolean
}

interface ModelSection {
  chapters: ModelChapter[]
  name: string
  linear: boolean
  time_estimation_mins: number
}

interface ModelSection {
  errors: string[]
  section: ModelSection
  outcome: string
  progress: number
}

export type KaseModelMetadata = any

export interface ModelState {
  currentKaseKind: 'MarriageBasedGreenCard' | 'Naturalization' | 'NewMarriageBasedGreenCard' | null
  currentContentSource: Nullable<string>
  currentPanel: Nullable<string>
  currentPanelIndex: Nullable<string>
  currentPanelHasBeenModified: boolean
  currentSaveRequestsInFlight: number
  currentSaveState: Nullable<string>
  currentSection: Nullable<string>
  data: ModelDataObject
  lastViewedSectionAndPanelName: Nullable<string>
  pathValidations: { [pathKey: string]: PathValidationResult }
  isLoggedIn: boolean
  lastUpdatedAt: Nullable<Date>
  metadata: Nullable<KaseModelMetadata>
  pendingUpdates: ServerModelUpdate[]
  sections: {
    [sectionName: string]: ModelSection
  }
  tags: string[]
  updatesOccurredSinceSaving: boolean
  validationConfig: PathValidationConfig
}

const initialState: ModelState = {
  currentKaseKind: null,
  currentPanel: null,
  currentPanelIndex: null,
  currentPanelHasBeenModified: false,
  currentSaveRequestsInFlight: 0,
  currentSaveState: null,
  currentSection: null,
  data: {},
  isLoggedIn: false,
  lastViewedSectionAndPanelName: null,
  lastUpdatedAt: null,
  metadata: null,
  pathValidations: {},
  pendingUpdates: [],
  sections: {},
  tags: [],
  updatesOccurredSinceSaving: false,
  validationConfig: {}
}

function applyUpdatesToData(state: ModelState, updates: ModelUpdate[]): ModelState {
  return updates.reduce((newState: ModelState, update: ModelUpdate): ModelState => {
    return update.operations.reduce((newStateAtPath, operation) => {
      return u.updateIn('data.' + update.path, operation(), newStateAtPath)
    }, newState)
  }, state)
}

function areSaveRequestsInFlight(state: ModelState) {
  return state.currentSaveRequestsInFlight > 0
}

function buildUpdate(action) {
  return {
    path: action.path,
    operations: [action.operation]
  }
}

function canOverwriteDataWithServerResponse(state: ModelState) {
  return state.currentSaveRequestsInFlight === 0 && !state.updatesOccurredSinceSaving
}

const mergeOperations = (previousOperations) => (newOperations) => {
  const lastOperation = _last(previousOperations)

  const recentReplacement = Boolean(lastOperation && lastOperation.name === 'replaceValue')
  const addingReplacement = Boolean(newOperations[0] && newOperations[0].name === 'replaceValue')

  if (recentReplacement && addingReplacement) {
    // We can drop the last replaceValue operation since this new one will
    // overwrite it
    previousOperations = previousOperations.slice(0, -1)
  }

  return previousOperations.concat(newOperations)
}

function buildUpdateList(pendingUpdates, newUpdates) {
  let updateList = _cloneDeep(pendingUpdates)

  _forEach(newUpdates, (newUpdate) => {
    const oldUpdatesAtPath = _remove(updateList, (update) => update.path === newUpdate.path)

    let newUpdateJson = u(
      {
        operations: (operations) => operations.map((o) => o.asJSON())
      },
      newUpdate
    )

    if (oldUpdatesAtPath.length > 0) {
      const existingOperations = oldUpdatesAtPath[0].operations

      newUpdateJson = u(
        {
          operations: mergeOperations(existingOperations)
        },
        newUpdateJson
      )
    }

    updateList = updateList.concat([newUpdateJson])
  })

  return updateList
}

function getUrlParams(params) {
  if (!params) return {}

  return {
    currentPanel: params.panelSlug || null,
    currentPanelIndex: params.panelSlugIndex || null,
    currentSection: params.sectionName || null,
    childPanelSlug: params.childPanelSlug || null,
    childSlugIndex: params.childSlugIndex || null
  }
}

export default function modelReducer(
  state: Nullable<ModelState>,
  action: any // TODO fix
): ModelState {
  state = state || initialState

  let newState

  switch (action.type) {
    case ADDED_ADMIN_DOCUMENT_REQUEST: {
      const { documentRequest } = action
      const updatePath = `data.${documentRequest.path}`

      return u.updateIn(updatePath, documentRequest, state)
    }

    case LOCATION_CHANGED: {
      const { params, query } = action.payload

      return u(
        {
          currentPanelHasBeenModified: false,
          pathValidations: u.constant({}),
          currentContentSource: query ? query.path : null,
          ...getUrlParams(params)
        },
        state
      )
    }

    case LOAD_LAST_VIEWED_SECTION_AND_PANEL_NAME: {
      return u(
        {
          lastViewedSectionAndPanelName: action.lastViewedSectionAndPanelName
        },
        state
      )
    }

    case USER_LOGGED_IN: {
      return u(
        {
          isLoggedIn: true
        },
        state
      )
    }

    case LOAD_KASE: {
      return u(
        {
          currentKaseKind: u.constant(action.kase.kind),
          data: u.constant(action.kase.data)
        },
        state
      )
    }

    case INITIALIZE_STORE: {
      const params = action.initialLocation ? action.initialLocation.params : {}
      const query = action.initialLocation ? action.initialLocation.query || {} : {}

      return u(
        {
          isLoggedIn: action.storeData.isLoggedIn,
          validationConfig: u.constant(action.storeData.validationConfig),
          currentContentSource: query.path,
          ...getUrlParams(params)
        },
        state
      )
    }

    case LOAD_DATA: {
      return u(
        {
          data: u.constant(action.data)
        },
        state
      )
    }

    case UPDATE_DATA: {
      const newUpdates = [buildUpdate(action)]

      newState = u(
        {
          pendingUpdates: (updates) => buildUpdateList(updates, newUpdates),
          currentPanelHasBeenModified: true
        },
        state
      )

      if (areSaveRequestsInFlight(newState)) {
        newState = u({ updatesOccurredSinceSaving: true }, newState)
      }

      // TODO[perf] updating the data key means an expensive re-render
      newState = applyUpdatesToData(newState, newUpdates)

      return newState
    }

    case SAVING_DATA: {
      return u(
        {
          currentSaveState: SAVING_STATES.saving,
          pendingUpdates: u.constant([]),
          updatesOccurredSinceSaving: false,
          currentSaveRequestsInFlight: (i) => i + 1
        },
        state
      )
    }

    case POST_SAVE_TRANSITION_END: {
      return u(
        {
          currentSaveState: SAVING_STATES.saved
        },
        state
      )
    }

    case SAVED_DATA: {
      newState = u(
        {
          lastUpdatedAt: new Date(),
          currentSaveState: SAVING_STATES.transitioning,
          currentSaveRequestsInFlight: (i) => i - 1
        },
        state
      )

      const updates = action.response.data
      if (canOverwriteDataWithServerResponse(newState, updates)) {
        updates.forEach(({ path, value }) => {
          newState = u.updateIn(`data.${path}`, value, newState)
        })
      }

      return newState
    }

    case VALIDATING_PATH: {
      const validation = u(
        {
          state: {
            validating: true
          }
        },
        NULL_VALIDATION
      )

      return u(
        {
          pathValidations: {
            [action.path]: u.constant(validation)
          }
        },
        state
      )
    }

    case VALIDATED_PATH: {
      return u(
        {
          pathValidations: {
            [action.path]: u.constant(action.validation)
          }
        },
        state
      )
    }

    case LOAD_KASE_METADATA: {
      return u(
        {
          metadata: u.constant(action.metadata)
        },
        state
      )
    }

    case LOAD_KASE_FORM_METADATA: {
      return u(
        {
          sections: u.constant(action.metadata.sections)
        },
        state
      )
    }

    case LOADING_KASE_TAGS: {
      return u(
        {
          isLoading: true
        },
        state
      )
    }

    case LOADED_KASE_TAGS_SUCCESS: {
      return u(
        {
          tags: action.payload,
          isLoading: false,
          isLoaded: true
        },
        state
      )
    }

    default: {
      return state
    }
  }
}
