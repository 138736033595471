import React, { ChangeEventHandler, FocusEventHandler, FunctionComponent } from 'react'
import CommonLabelV2 from './common/common_label_v2'
import CommonSelectV2 from './common/common_select_v2'
import UNITED_STATES from '../../../../../data/united_states'
import CommonInputV2 from './common/common_input_v2'
import JITFeedback from '../../../panel/jit_feedback'

interface Props {
  value?: string
  disabled?: boolean
  onChangeEvent: ChangeEventHandler
  onBlurEvent: FocusEventHandler
  isUnitedStates?: boolean
}

const defaultProps = {
  value: ''
}

const ProvinceInputV2: FunctionComponent<Props> = ({ value, disabled, onChangeEvent, onBlurEvent, isUnitedStates }) => {
  const noStateOrProvinceJitText =
    'If your country does not have states or provinces, please leave "state or province" blank'

  return (
    <div className="o-grid--fluid o-grid--stack@sm o-block c-paper-form__group">
      {!isUnitedStates && <JITFeedback text={noStateOrProvinceJitText} />}
      <CommonLabelV2 label={isUnitedStates ? 'State' : 'Province'} labelFor="province" />
      {isUnitedStates ? (
        <CommonSelectV2
          inputName="province"
          value={value ? value : ''}
          options={UNITED_STATES}
          containerClass="c-paper-form__control"
          onChangeEvent={onChangeEvent}
          onBlur={onBlurEvent}
          disabled={disabled}
        />
      ) : (
        <CommonInputV2
          inputName="province"
          value={value ? value : ''}
          onChangeEvent={onChangeEvent}
          onBlur={onBlurEvent}
          disabled={disabled}
        />
      )}
    </div>
  )
}

ProvinceInputV2.defaultProps = defaultProps

export default ProvinceInputV2
