// TODO: Exclude this file in webpack production mode
// Once that's done, change actioncable back to a devDependency
import { createConsumer } from '@rails/actioncable'

export default class DebugCable {
  constructor() {
    this.consumer = createConsumer()
  }

  getConsumer() {
    return this.consumer
  }
}
