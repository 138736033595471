import React from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'

import panelLocaleService from 'services/panel_locale_service'
import { joinI18nKeys } from 'lib/i18n'
import { getModelValue } from 'reducers/selectors'

import CountryCodeInput from './country_code_input'
import CountryCodePrefix from './country_code_prefix'
import PhoneNumberInput from './phone_number_input'
import ValidatedInput from 'components/forms/validated_input'

import { QuestionnaireInputContainerProps } from 'components/forms/inputs'

interface Phone {
  iso_country_code: string
  country_code: string
  number: string
}

interface Props extends QuestionnaireInputContainerProps {
  phone: Nullable<Phone>
}

class Phone extends React.Component<Props> {
  handleClickNumber = (event) => {
    event.preventDefault()
    this.focusNumberField()
  }

  focusNumberField() {
    if (this.inputRef) {
      this.inputRef.focus()
    }
  }

  countryHasChanged(prevProps) {
    return this.props.phone.iso_country_code !== prevProps.phone.iso_country_code
  }

  componentDidUpdate(prevProps) {
    if (this.countryHasChanged(prevProps)) {
      this.focusNumberField()
    }
  }

  renderCountryCodeInput() {
    const countryCodeId = `${this.props.id}-country-code`
    const path = `${this.props.path}.iso_country_code`
    const countryCodeNumberPath = `${this.props.path}.country_code`
    const { disabled, resourceKeys, name } = this.props

    return (
      <ValidatedInput disabled={disabled} path={path}>
        {({ fireValidation, getValidationClasses, renderValidations }) => (
          <div className="o-grid--fluid o-flag o-grid__stacked@sm o-block c-paper-form__group">
            <label
              className="o-grid__cell--3/12 o-grid__cell--off@sm c-paper-form__label c-paper-form__segment"
              htmlFor={countryCodeId}
            >
              <span className="c-type c-type--subhead-sm">
                {panelLocaleService.t(joinI18nKeys(resourceKeys, `${this.props.name}.label`))} Country
              </span>
            </label>

            <div className="o-grid__cell--9/12 o-grid__cell--off@sm c-paper-form__segment">
              <CountryCodeInput
                className={`c-paper-form__control c-custom-select ${getValidationClasses()}`}
                countryCodeNumberPath={countryCodeNumberPath}
                disabled={disabled}
                id={countryCodeId}
                onBlur={fireValidation}
                path={path}
                name={name}
              />

              {renderValidations()}
            </div>
          </div>
        )}
      </ValidatedInput>
    )
  }

  renderPhoneNumberInput() {
    const path = `${this.props.path}.number`
    const { disabled, id, phone, resourceKeys } = this.props

    const noCountryCodeSelected = phone.country_code !== 0 && !phone.country_code

    const className = classNames('c-paper-form__control o-grid__cell--fill', {
      'c-type--muted': !phone.number
    })

    return (
      <ValidatedInput disabled={disabled} path={path}>
        {({ fireValidation, getValidationClasses, renderValidations }) => {
          return (
            <React.Fragment>
              <div
                className={`o-grid--fluid o-flag o-grid__stacked@sm o-block c-paper-form__group ${getValidationClasses()}`}
              >
                <label
                  className="o-grid__cell--3/12 o-grid__cell--off@sm c-paper-form__label"
                  htmlFor={`${this.props.id}-number`}
                >
                  <span className="c-type c-type--subhead-sm">
                    {panelLocaleService.t(joinI18nKeys(resourceKeys, `${this.props.name}.label`))} Number
                  </span>
                </label>

                <div className="o-grid__cell--9/12 o-grid__cell--off@sm o-grid--fluid c-paper-form__segment">
                  <div className="c-paper-form__combined" onClick={this.handleClickNumber}>
                    <div className="c-paper-form__combined-label">
                      <CountryCodePrefix prefix={phone.country_code} />
                    </div>

                    <PhoneNumberInput
                      className={className}
                      disabled={noCountryCodeSelected || disabled}
                      id={id}
                      isoCountryCode={phone.iso_country_code}
                      onBlur={fireValidation}
                      path={path}
                    />

                    {renderValidations()}
                  </div>
                </div>
              </div>
            </React.Fragment>
          )
        }}
      </ValidatedInput>
    )
  }

  render() {
    return (
      <div className="o-block">
        {this.renderCountryCodeInput()}
        {this.props.phone.iso_country_code && this.renderPhoneNumberInput()}
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    phone: getModelValue(state, ownProps.path)
  }
}

export default connect(mapStateToProps)(Phone)
