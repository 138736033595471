import React, { ChangeEventHandler, FocusEventHandler, FunctionComponent } from 'react'
import cx from 'classnames'

interface Props {
  inputName: string
  value?: string
  options: {}
  containerClass?: string
  disabled?: boolean
  onChangeEvent: ChangeEventHandler
  onBlur: FocusEventHandler
  size?: string
}

const defaultProps = {
  value: '',
  containerClass: '',
  size: 'normal'
}

const CommonSelectV2: FunctionComponent<Props> = ({
  inputName,
  value,
  options,
  containerClass,
  disabled,
  onChangeEvent,
  onBlur,
  size
}) => {
  return (
    <div className={cx('o-grid__cell--off@sm c-paper-form__segment', { 'o-grid__cell--9/12': size === 'normal' })}>
      <select
        className={containerClass}
        onChange={onChangeEvent}
        name={inputName}
        onBlur={onBlur}
        value={value}
        disabled={disabled}
      >
        {Object.keys(options).map((opt) => {
          const optLabel = options[opt]
          return (
            <option key={opt} value={opt}>
              {optLabel}
            </option>
          )
        })}
      </select>
    </div>
  )
}

CommonSelectV2.defaultProps = defaultProps

export default CommonSelectV2
