import React from 'react'

import { Provider } from 'react-redux'
import { ModalRevealer } from './modals'

import getStore from 'stores/app_store'

interface Props {
  onlyModals?: string[]
}

function ModalRoot(props: Props) {
  return (
    <Provider store={getStore()}>
      <ModalRevealer onlyModals={props.onlyModals} />
    </Provider>
  )
}

export default ModalRoot
