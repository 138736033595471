import { addDocumentMetaAnswer, fetchDocumentSets, updateDocumentMetaAnswer } from 'actions/document_request_actions_v2'
import InputTransformer from 'components/screens/questionnaire/inputs/input_transformer'
import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'
import { DocumentMetaQuestion } from 'reducers/documents_v2'

interface Props {
  documentId: number
  isFileUploading: boolean
  kaseId: number
  metaQuestion: DocumentMetaQuestion
}

interface ActionProps {
  addDocumentMetaAnswer: typeof addDocumentMetaAnswer
  updateDocumentMetaAnswer: typeof updateDocumentMetaAnswer
  fetchDocumentSets: typeof fetchDocumentSets
}

const MetaQuestion: FunctionComponent<Props & ActionProps> = ({
  addDocumentMetaAnswer,
  documentId,
  fetchDocumentSets,
  isFileUploading,
  kaseId,
  metaQuestion,
  updateDocumentMetaAnswer
}) => {
  const { id } = metaQuestion
  const { document_meta_answer_id, key, input_type, input_value, markdown, placeholder } = metaQuestion.attributes

  const onSave = (text: string) => {
    if (document_meta_answer_id) {
      updateDocumentMetaAnswer(kaseId, document_meta_answer_id, text).then(() => fetchDocumentSets(kaseId))
    } else {
      addDocumentMetaAnswer(kaseId, documentId, id, text).then(() => fetchDocumentSets(kaseId))
    }
  }

  return (
    <div className="mb-4">
      <p>{markdown}</p>
      <InputTransformer
        id={key}
        disabled={isFileUploading}
        onSave={onSave}
        type={input_type}
        placeholder={placeholder}
        value={input_value}
        isMetaQuestion={true}
      />
    </div>
  )
}

const mapDispatchToActions = (dispatch: Function): ActionProps => {
  return {
    addDocumentMetaAnswer: (kase_id: number, document_id: number, document_meta_question_id: number, text: string) =>
      dispatch(addDocumentMetaAnswer(kase_id, document_id, document_meta_question_id, text)),
    fetchDocumentSets: (kaseId: number) => dispatch(fetchDocumentSets(kaseId)),
    updateDocumentMetaAnswer: (kase_id: number, document_meta_answer_id: number, text: string) =>
      dispatch(updateDocumentMetaAnswer(kase_id, document_meta_answer_id, text))
  }
}

export default connect(null, mapDispatchToActions)(MetaQuestion)
