import React, { SyntheticEvent } from 'react'
import classNames from 'classnames'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import _last from 'lodash/last'

import { validatePath, revalidate, noValidationsAtPath } from 'actions/validation_actions'

import { getValidationResultAtPath, isModelValueEmpty } from 'reducers/selectors'

import { PathValidationResult } from 'lib/validations'

interface RenderPropArguments {
  fireValidation: Function
  getValidationClasses: () => string
  renderValidations: Function
  validation: PathValidationResult
}

interface MappedProps {
  isEmpty: boolean
  validation: PathValidationResult
  noValidations: boolean
}

type Props = {
  children: (args: RenderPropArguments) => any
  className?: string
  disabled: boolean
  path: string
  revalidate: Function
  validatePath: Function
} & MappedProps

class ValidatedInput extends React.Component<Props> {
  static defaultProps = {
    disabled: false
  }

  changed: boolean = false

  disableValidations() {
    return this.props.isEmpty && !this.changed
  }

  fireValidation = (event: SyntheticEvent<HTMLElement>) => {
    if (this.disableValidations()) return
    if (event && event.preventDefault) event.preventDefault()
    if (this.props.noValidations) return

    this.props.revalidate()
    this.props.validatePath(this.props.path)
  }

  disabled() {
    return this.props.disabled
  }

  enabled() {
    return !this.disabled()
  }

  isSuccess() {
    return this.enabled() && this.props.validation.state.success
  }

  isError() {
    return this.enabled() && this.props.validation.state.error
  }

  getValidationClasses = () => {
    const { validation } = this.props

    if (this.enabled() && validation.state.fired) {
      return classNames({
        'c-paper-form__control--validation-success': this.isSuccess(),
        'c-paper-form__control--validation-error': this.isError()
      })
    }

    return ''
  }

  registerChange = () => (this.changed = true)

  renderValidations = () => {
    if (this.disabled()) return null

    const { validation } = this.props

    if (!validation.state.fired) return null

    const message = _last(validation.messages)

    if (!message) return null

    return (
      <div className="o-flag__item o-flag__item--drop@sm c-tooltip c-tooltip--danger" key={message.text}>
        <div className="c-type c-type--body-sans-sm">{message.text}</div>
      </div>
    )
  }

  render() {
    const { className } = this.props

    return (
      <div onChange={this.registerChange} className={className || ''}>
        {this.props.children({
          fireValidation: this.fireValidation,
          getValidationClasses: this.getValidationClasses,
          renderValidations: this.renderValidations,
          validation: this.props.validation
        })}
      </div>
    )
  }
}

function mapStateToProps(state: any, ownProps: Props): MappedProps {
  return {
    isEmpty: isModelValueEmpty(state, ownProps.path),
    validation: getValidationResultAtPath(state, ownProps.path),
    noValidations: noValidationsAtPath(state, ownProps.path)
  }
}

function mapDispatchToActions(dispatch: Function) {
  return bindActionCreators(
    {
      revalidate,
      validatePath
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToActions)(ValidatedInput)
