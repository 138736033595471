import React from 'react'
import { connect } from 'react-redux'
import _capitalize from 'lodash/capitalize'

import SectionEntry from './section_entry'

import { getModelValue } from 'reducers/selectors'
import { getOrdinal } from '../../../lib/language_helpers'

import { Role } from 'components/forms/inputs/index'

interface Props {
  role: Role
  previousMarriages: MarriageModel[]
}

class PreviousMarriages extends React.Component<Props> {
  renderMarriage(marriage, i) {
    const { role } = this.props
    const ordinal = _capitalize(getOrdinal(i))
    const nameLabel = `${ordinal} Marriage Spouse Name`
    const dateLabel = `${ordinal} Marriage End Date`
    const panelSlug = role === 'beneficiary' ? 'beneficiary-previous-marriage-info' : 'sponsor-previous-marriage'
    const editHref = `${panelSlug}/${i + 1}`

    return [
      <SectionEntry label={nameLabel} path={`${marriage.path}.spouse_name`} key={`${i}-name`} editHref={editHref} />,
      <SectionEntry label={dateLabel} path={`${marriage.end_date.path}`} key={`${i}-date`} editHref={editHref} />
    ]
  }

  render() {
    return this.props.previousMarriages.map((marriage, i) => this.renderMarriage(marriage, i))
  }
}

const mapStateToProps = (state, props) => ({
  previousMarriages: getModelValue(state, `${props.role}.previous_marriages`)
})

export default connect(mapStateToProps)(PreviousMarriages)
