import React, { FunctionComponent, useState, ReactNode, useEffect } from 'react'
import cx from 'classnames'

import ChevronIcon from 'components/icons/chevron_icon'

interface Props {
  /**
   * Which side of the text the chevron goes
   */
  chevronPosition?: 'left' | 'right'
  /**
   * The class names that will be applied to the outermost container
   */
  className?: string
  /**
   * Controls whether or not to add padding to the children of the accordion
   */
  hasChildPadding?: boolean
  /**
   * The header text of the accordion
   */
  header: ReactNode
  /**
   * Allows for external controls to open and close the accordion
   */
  isOpen?: boolean
  /**
   * Controls whether this accordion is open by default on mount
   */
  isOpenOnMount?: boolean
  /**
   * Creates a lightly padded version for use in FAQs (dashboard sidebar)
   */
  isSlim?: boolean
  /**
   * Hides the default border
   */
  noBorder?: boolean
  /**
   * Controls whether the header text section will have horizontal padding or not
   */
  noXPadding?: boolean
}

const Accordion: FunctionComponent<Props> = ({
  chevronPosition = 'right',
  children,
  className,
  hasChildPadding = true,
  header,
  isOpen,
  isOpenOnMount,
  isSlim,
  noBorder,
  noXPadding
}) => {
  const [open, setOpen] = useState(!!isOpenOnMount || !!isOpen)

  const toggleAccordion = (event) => {
    event.preventDefault()
    setOpen(!open)
  }

  useEffect(() => {
    if (isOpen !== undefined) {
      setOpen(isOpen)
    }
  }, [isOpen])

  const containerClassName = cx('c-accordion--new', {
    'border border-gray-200': !noBorder && !isSlim,
    [className]: !!className
  })

  const innerClassNames = cx('flex items-center u-clickable', {
    'p-4': !noXPadding && !isSlim,
    'py-4': noXPadding && !isSlim,
    'flex-row-reverse': chevronPosition === 'left'
  })

  const chevronClassNames = cx('flex-initial', {
    'mr-2': chevronPosition === 'left'
  })

  const headerClassNames = cx('flex-auto', {
    'c-type--body-sans-md': !isSlim || (isSlim && open)
  })

  /**
   * "fun" fact: there's a bug in Safari that prevents <summary> elements from
   * acting as flex containers. Therefore, we nest the content of the <summary>
   * within a div for proper styling. I love browsers.
   * https://bugs.webkit.org/show_bug.cgi?id=190065
   */
  return (
    <details className={containerClassName} open={open}>
      <summary onClick={toggleAccordion}>
        <div className={innerClassNames}>
          <span className={headerClassNames}>{header}</span>
          <span className={chevronClassNames}>
            <ChevronIcon chevronDirection={open ? 'up' : 'down'} />
          </span>
        </div>
      </summary>
      <div className={cx({ 'o-box p-4': hasChildPadding })}>{children}</div>
    </details>
  )
}

export default Accordion
