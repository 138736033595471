import { buildMessage, Validator } from './'

const requiredIfHasFeeWaivers: Validator = ({ getModelData, path }) => {
  const currentValue = getModelData(path)
  const feeWaiversExist = getModelData('beneficiary.has_fee_waivers')

  if (feeWaiversExist && currentValue == null) {
    return [buildMessage('Please add fee waivers')]
  }

  return []
}

export default requiredIfHasFeeWaivers
