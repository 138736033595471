export default class SingleRequestEnsurer {
  queue: Function[] = []
  running: boolean = false

  enqueue(fn: Function) {
    this.queue.push(fn)
    this.run()
  }

  run = () => {
    if (this.queue.length === 0) return
    if (this.running) return

    this.running = true

    const fn = this.queue.pop()
    this.queue = []

    return fn().then(() => {
      this.running = false
      this.run()
    })
  }
}
