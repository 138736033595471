import React from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import moment from 'moment'

import { getLastUpdatedAt, isCurrentlySavingData, isPostSaveTransitioning } from 'reducers/selectors'

interface Props {
  currentlySaving: boolean
  currentlyTransitioning: boolean
  lastUpdatedAt?: Date
}

class SaveIndicator extends React.Component<Props> {
  constructor(props) {
    super(props)

    this.state = {}
    this.interval = setInterval(this.renderTimestamp, 30000)
  }

  renderTimestamp = () => {
    this.setState(this.state)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  statusText() {
    if (this.props.currentlySaving || this.props.currentlyTransitioning) {
      return 'Saving...'
    }

    if (!this.props.lastUpdatedAt) {
      return ''
    }

    const oneMinuteAgo = moment().subtract(1, 'minute')
    const recentlySaved = this.props.lastUpdatedAt > oneMinuteAgo

    if (recentlySaved) {
      return 'All progress saved'
    } else {
      return `Last saved ${moment(this.props.lastUpdatedAt).fromNow()}`
    }
  }

  render() {
    const savingStatusClasses = classNames('o-block o-block--tight c-type c-type--body-sans-sm c-type--italic', {
      fade: this.props.currentlyTransitioning
    })

    return <div className={savingStatusClasses}>{this.statusText()}</div>
  }
}

function mapStateToProps(state) {
  return {
    currentlySaving: isCurrentlySavingData(state),
    currentlyTransitioning: isPostSaveTransitioning(state),
    lastUpdatedAt: getLastUpdatedAt(state)
  }
}

export default connect(mapStateToProps)(SaveIndicator)
