import React from 'react'
import { LawyerInteractionStatusData, LawyerInteraction } from './types'
import moment from 'moment-timezone'
import { KasePhase } from '../types'

/**
 * Helper function to get the description for the confirm and cancel section
 * for either lawyer consult or interview prep
 */
const getConfirmAndCancelDescription = (openChat: (args: { via: string }) => void) => (
  <>
    See confirmation email for details. If you need to cancel or reschedule, please{' '}
    <a className="c-link c-type--hover" onClick={() => openChat({ via: 'Lawyer Consults' })}>
      chat with us
    </a>
    , or email{' '}
    <a href="mailto: support.team@boundless.com" target="_blank" rel="noreferrer">
      support.team@boundless.com
    </a>
  </>
)

/**
 * Takes an ISO date string and optional laywer name and returns a formatted date
 * string in the following format:
 * `Month Day, Year at Time` (ex: 'August 14, 2023 at 2:30pm (EST) with John Lawyerman')
 */
const formattedMeetingDetails = (date: string, lawyerName?: string) => {
  const dateObject = new Date(date)

  const formattedDate = moment(dateObject).format('MMMM D, YYYY [at] h:mma')

  // get the customers current timezone
  const timezone = moment.tz.guess()
  const timezoneString = timezone ? `(${moment.tz(timezone).format('z')})` : ``

  return (
    <>
      <strong>
        {formattedDate} {timezoneString}
      </strong>
      {lawyerName && <span> with {lawyerName}</span>}
    </>
  )
}

/**
 * LAWYER CONSULT STATUS DATA
 *
 * This is the first meeting with a lawyer if the customer has purchased a premium package,
 * all other customers will not see this section.
 *
 * Legacy premium customers will only have an interview prep event (see the next function below) and
 * essential tier customers will not see any lawyer interactions.
 *
 * ----------------
 *
 * This function determines the section display data based on the `lawyer_consult_event` response from the API.
 */
export const getLawyerConsultStatusData = (
  eventData: LawyerInteraction,
  openChat: (args: { via: string }) => void,
  currentPhaseName?: string
): LawyerInteractionStatusData => {
  let statusData: LawyerInteractionStatusData = {
    callType: 'Lawyer Consult'
  }

  const callDescription = (
    <>
      We recommend using this time to discuss questions about your application; the call will be most effective after
      you've completed the questionnaire and uploaded your supporting documents.
    </>
  )

  const confirmAndCancelDescription = getConfirmAndCancelDescription(openChat)

  // NOT APPLICABLE (OR UNAVAILABLE)
  if (!eventData?.status || eventData.status === 'not_applicable' || eventData.status === 'unavailable') {
    return null
  }

  // Only show as a primary button if the customer has completed doc upload
  const getLawyerConsultButtonStyle = () => {
    if (
      currentPhaseName === 'DOCUMENT_CHECK' ||
      currentPhaseName === 'QUALITY_ASSURANCE' ||
      currentPhaseName === 'LEGAL_REVIEW' ||
      currentPhaseName === 'FINALIZE_AND_SHIP_PACKAGE'
    ) {
      return 'primary'
    } else {
      return 'secondary'
    }
  }

  // NOT SCHEDULED
  if (eventData.status === 'not_scheduled') {
    statusData = {
      callType: 'Lawyer Consult',
      statusText: 'Ready to book',
      meetingDetails: null,
      description: callDescription,
      buttonText: 'Schedule call',
      buttonStyle: getLawyerConsultButtonStyle(),
      calendlyScheduleUrl: eventData.calendly_scheduling_url
    }
  }

  // SCHEDULED
  else if (eventData.status === 'scheduled') {
    statusData = {
      callType: 'Lawyer Consult',
      statusText: 'Scheduled',
      buttonText: 'Join call',
      meetingDetails: formattedMeetingDetails(eventData.date_scheduled, eventData.lawyer_name),
      description: confirmAndCancelDescription,
      googleMeetUrl: eventData.google_meet_url
    }
  }

  // COMPLETED
  else if (eventData.status === 'completed') {
    statusData = {
      callType: 'Lawyer Consult',
      statusText: 'Complete'
    }
  }

  return statusData
}

/**
 * INTERVIEW PREP STATUS DATA
 *
 * This is the second meeting with a lawyer if the customer has purchased a premium package,
 * and the first and only meeting with a lawyer if the customer is a legacy premium customer.
 *
 * This meeting is only schedulable for premium customers who have completed the first lawyer consult
 * meeting, and its encouraged that they schedule it after they've completed all questionnaires and
 * uploaded all supporting documents and their application package has shipped.
 *
 * Essential tier customers will not see this section.
 *
 * ----------------
 *
 * This function determines the section display data based on the `interview_prep_event` response from the API.
 */
export const getInterviewPrepStatusData = (
  eventData: LawyerInteraction,
  openChat: (args: { via: string }) => void,
  lawyerConsultEventData: LawyerInteraction,
  currentPhaseName?: string
): LawyerInteractionStatusData => {
  let statusData: LawyerInteractionStatusData = {
    callType: 'Interview Prep'
  }

  const showFirstCallMessage =
    lawyerConsultEventData?.status === 'scheduled' || lawyerConsultEventData?.status === 'not_scheduled'

  const callDescription = (
    <>
      {showFirstCallMessage && (
        <p className="mb-1">This call will be available to book after your first call is complete.</p>
      )}
      <p>
        We recommend using this time to ask interview-related questions; the call will be most effective once your
        interview is scheduled.
      </p>
    </>
  )

  const confirmAndCancelDescription = getConfirmAndCancelDescription(openChat)

  // NOT APPLICABLE
  if (!eventData?.status || eventData.status === 'not_applicable') {
    return null
  }

  // UNAVAILABLE
  else if (eventData.status === 'unavailable') {
    statusData = {
      ...statusData,
      statusText: 'Book later',
      statusColor: 'muted',
      description: callDescription
    }
  }

  // NOT SCHEDULED
  if (eventData.status === 'not_scheduled') {
    statusData = {
      ...statusData,
      statusText: 'Ready to book',
      buttonText: 'Schedule call',
      description: callDescription,
      // Only show as a primary button if the customers package has shipped
      buttonStyle: currentPhaseName === 'CUSTOMER_COMPLETED' ? 'primary' : 'secondary',
      calendlyScheduleUrl: eventData.calendly_scheduling_url
    }
  }

  // SCHEDULED
  else if (eventData.status === 'scheduled') {
    statusData = {
      ...statusData,
      statusText: 'Scheduled',
      buttonText: 'Join call',
      meetingDetails: formattedMeetingDetails(eventData.date_scheduled, eventData.lawyer_name),
      description: confirmAndCancelDescription,
      googleMeetUrl: eventData.google_meet_url
    }

    // COMPLETED
  } else if (eventData.status === 'completed') {
    statusData = {
      callType: 'Interview Prep',
      statusText: 'Complete'
    }
  }

  return statusData
}
