import React, { FunctionComponent, CSSProperties } from 'react'
import cx from 'classnames'

interface Props {
  className?: string
  rounded?: boolean
  variant?: 'warning' | 'success' | 'primary' | 'warningAlt'
  style?: CSSProperties
}

const Badge: FunctionComponent<Props> = ({ children, className, variant, rounded, style }) => {
  const classes = cx('c-badge inline-flex', {
    'c-badge--rounded': rounded,
    [`c-badge--${variant}`]: !!variant,
    [className]: !!className
  })

  return (
    <span style={style} className={classes}>
      {children}
    </span>
  )
}

export default Badge
