import React, { FunctionComponent } from 'react'
import IconWrapper, { IconWrapperExternalProps } from './icon_wrapper'

const SuccessCheckmarkIcon: FunctionComponent<IconWrapperExternalProps> = (props) => (
  <IconWrapper {...props} viewBox="0 0 32 32">
    <path
      d="M24.2224647,5.91362092 L12.4229366,22.3663747 L5.49248459,17.5075544 C5.11639735,17.2434161 4.59405397,17.3320142 4.32744991,17.7054511 C4.06084584,18.0788881 4.15027103,18.5951584 4.52719402,18.8597107 L12.1383639,24.1958845 C12.285038,24.2985589 12.4534415,24.3478261 12.6201735,24.3478261 C12.881763,24.3478261 13.1391739,24.2265212 13.302145,23.9992297 L25.5843184,6.87288188 C25.8521761,6.50027296 25.7635867,5.9835886 25.3870815,5.71862225 C25.0105764,5.4532419 24.4890688,5.541012 24.2224647,5.91362092 Z M31.1634943,7.9875632 C31.6250147,7.9875632 31.999582,7.61299594 31.999582,7.15147556 L31.999582,0.836087632 C31.999582,0.374567259 31.6250147,0 31.1634943,0 L0.836087632,0 C0.374567259,0 0,0.374567259 0,0.836087632 L0,31.1639124 C0,31.6254327 0.374567259,32 0.836087632,32 L31.1639124,32 C31.6254327,32 32,31.6254327 32,31.1639124 L32,12.0283747 C32,11.5668544 31.6254327,11.1922871 31.1639124,11.1922871 C30.702392,11.1922871 30.3278247,11.5668544 30.3278247,12.0283747 L30.3278247,30.3274067 L1.67217526,30.3274067 L1.67217526,1.67217526 L30.3278247,1.67217526 L30.3278247,7.15147556 C30.3274067,7.61299594 30.701974,7.9875632 31.1634943,7.9875632 Z"
      fill="#597DFF"
      fillRule="nonzero"
    />
  </IconWrapper>
)

export default SuccessCheckmarkIcon
