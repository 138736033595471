import api from '../lib/api'
import { LOADING_EMPLOYMENTS, LOADED_EMPLOYMENTS } from '../lib/constants'
import { EmploymentHistory } from '../reducers/employment_histories'
import moment from 'moment'
import { loadQuestionnaire } from 'actions/questionnaire_actions'

export function loadedEmployments(data) {
  return (dispatch) => {
    dispatch({
      type: LOADED_EMPLOYMENTS,
      payload: data ?? {}
    })
  }
}

export const loadCurrentEmploymentHistories = (kaseId: number) => (dispatch) => {
  dispatch({ type: LOADING_EMPLOYMENTS, payload: true })

  return api.employmentHistories
    .index(kaseId)
    .then((data) => {
      dispatch(loadedEmployments(data.data))
    })
    .finally(() => {
      dispatch({ type: LOADING_EMPLOYMENTS, payload: false })
    })
}

export const addNewEmploymentHistory = (kaseId: number, owner: string, employmentHistory: EmploymentHistory) => (
  dispatch
) => {
  // Format the dates to how the backend likes them
  employmentHistory = {
    ...employmentHistory,
    start_date: moment(employmentHistory.start_date).format('YYYY-MM-DD'),
    end_date: moment(employmentHistory.end_date).format('YYYY-MM-DD')
  }

  return (
    api.employmentHistories
      .createEmploymentHistory(kaseId, owner, employmentHistory)
      // Update the questionnaire since we read the address/employment history data from there
      .then(() => dispatch(loadQuestionnaire('address_and_employment_history')))
  )
}

export const updateEmploymentHistory = (kaseId: number, owner: string, employmentHistory: EmploymentHistory) => (
  dispatch
) => {
  // Format the dates to how the backend likes them
  employmentHistory = {
    ...employmentHistory,
    start_date: moment(employmentHistory.start_date).format('YYYY-MM-DD'),
    end_date: moment(employmentHistory.end_date).format('YYYY-MM-DD')
  }

  return (
    api.employmentHistories
      .updateEmploymentHistory(kaseId, owner, employmentHistory)
      // Update the questionnaire since we read the address/employment history data from there
      .then(() => dispatch(loadQuestionnaire('address_and_employment_history')))
  )
}

export const deleteEmploymentHistory = (kaseId: number, id: number) => (dispatch) => {
  return (
    api.employmentHistories
      .deleteEmploymentHistory(kaseId, id)
      // Update the questionnaire since we read the address/employment history data from there
      .then(() => dispatch(loadQuestionnaire('address_and_employment_history')))
  )
}
