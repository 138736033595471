import React, { FunctionComponent } from 'react'
import IconWrapper, { IconWrapperExternalProps } from './icon_wrapper'

const PencilIcon: FunctionComponent<IconWrapperExternalProps> = (props) => (
  <IconWrapper {...props} viewBox="0 0 32 32">
    <path d="M25.718 11.808l2.73-2.73-5.303-5.303-2.73 2.73 5.303 5.303zM24.303 13.222l-5.303-5.303-15.527 15.527v5.303h5.303l15.527-15.527zM23.144 0.239l8.839 8.839-22.171 22.171h-8.839v-8.839l22.171-22.171z" />
  </IconWrapper>
)

export default PencilIcon
