import React, { MouseEvent } from 'react'
import { connect } from 'react-redux'
import _sortBy from 'lodash/sortBy'

import { getCurrentKase, getUploadsForDocumentRequest } from 'reducers/selectors'
import { deleteDocument } from 'actions/document_request_actions'
import getDocumentRequestLocaleService from 'services/document_request_locale_service'
import DocumentRequestUploadArea from 'components/screens/document_upload/document_request_upload_area'
import ListedDocument from 'components/screens/document_upload/listed_document'
import Button from 'components/button'
import Paragraph from 'components/type/paragraph'
import Heading from 'components/type/heading'
import { AutoSubmitModalType, DocumentStatus, KaseKind, DocumentRequestStatus } from 'lib/constants'
import TrashIcon from 'components/icons/trash_icon'
import HelpIcon from 'components/icons/help_icon'

import Markdown from 'components/markdown'
import FirstRequestCompleteModal from 'components/screens/document_upload/modals/first_request_complete_modal'
import CommentsPresentModal from 'components/screens/document_upload/modals/comments_present_modal'
import IncompleteQuestionnairesModal from 'components/screens/document_upload/modals/incomplete_questionnaires_modal'
import LinkifyText from 'components/linkify_text'

interface MappedProps {
  uploads: any[]
  documents: DocumentModel[]
  kase: { id: number; kind: KaseKind }
}

interface ActionProps {
  deleteDocument: Function
}

interface Props {
  documentRequest: DocumentRequestModel
  showHelpText?: boolean
  toggleTipsForSuccess?: (event: MouseEvent) => void
}

type ComponentProps = Props & MappedProps & ActionProps

interface State {
  autoSubmissionModalType?: AutoSubmitModalType
}

class ListedDocumentRequest extends React.Component<ComponentProps, State> {
  static defaultProps = {
    showHelpText: true
  }

  constructor(props) {
    super(props)

    this.state = {
      autoSubmissionModalType: null
    }
  }

  sortedDocuments() {
    return _sortBy(this.props.documents, 'created_at').reverse()
  }

  onDeleteDocument = (document) => (event) => {
    event.preventDefault()
    event.stopPropagation()

    this.props.deleteDocument({
      document,
      documentRequest: this.props.documentRequest
    })
  }

  renderDocument(doc: DocumentModel, i: number) {
    const { kase } = this.props

    return (
      <ListedDocument
        key={i}
        document={doc}
        inlineControls={
          doc.status === DocumentStatus.NeedsReview && (
            <div className="o-layout o-layout--right">
              <Button onClick={this.onDeleteDocument(doc)} icon={<TrashIcon />} iconOnly color="tertiary" />
            </div>
          )
        }
        kaseId={kase.id}
        renderThumbnails={true}
      />
    )
  }

  render() {
    const { documentRequest, kase, toggleTipsForSuccess, uploads } = this.props
    const documentRequestLocaleService = getDocumentRequestLocaleService()

    const documentInstructions = documentRequest.description || (
      <Markdown
        className="o-block o-block--compact"
        source={documentRequestLocaleService.t(documentRequest, 'description')}
        options={{ linkToNewTabs: true }}
      />
    )

    return (
      <div className="o-block">
        <FirstRequestCompleteModal />
        <CommentsPresentModal />
        {window.isUserCompletionEnabled() && <IncompleteQuestionnairesModal />}
        {this.props.showHelpText && (
          <div className="o-block o-block--ample c-type c-type--body-sans-md">
            {documentInstructions}
            <Button
              color="link-primary"
              className="u-hide@sm-down c-type--body-sans-sm"
              onClick={toggleTipsForSuccess}
              label={
                <>
                  <HelpIcon size="small" />
                  <span>Tips For Success</span>
                </>
              }
            />
          </div>
        )}

        {documentRequest.comment.body && (
          <div className="o-block o-box c-tooltip--warning o-layout--padded-x">
            <Heading tag="h4" size="sm" subhead>
              Comment from Boundless
            </Heading>
            <Paragraph size="sm">
              <span className="whitespace-pre-wrap">
                <LinkifyText>{documentRequest.comment.body}</LinkifyText>
              </span>
            </Paragraph>
          </div>
        )}
        <DocumentRequestUploadArea
          documentRequest={documentRequest}
          uploadDisabled={documentRequest.status === DocumentRequestStatus.NeedsReview}
        />
        {uploads.map((document, i) => (
          <ListedDocument kaseId={kase.id} key={`uploading-doc-${i}`} document={document} renderThumbnails={true} />
        ))}
        {this.sortedDocuments().map((doc, i) => this.renderDocument(doc, i))}
      </div>
    )
  }
}

const mapStateToProps = (state: any, ownProps: Props): MappedProps => {
  const { documentRequest } = ownProps

  return {
    documents: documentRequest.documents,
    kase: getCurrentKase(state),
    uploads: getUploadsForDocumentRequest(state, documentRequest)
  }
}

const mapDispatchToActions = (dispatch: Function): ActionProps => {
  return {
    deleteDocument: (...args) => dispatch(deleteDocument(...args))
  }
}

export default connect(mapStateToProps, mapDispatchToActions)(ListedDocumentRequest)
