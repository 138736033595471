import React, { FunctionComponent } from 'react'
import IconWrapper, { IconWrapperExternalProps } from './icon_wrapper'

const HelpIcon: FunctionComponent<IconWrapperExternalProps> = (props) => (
  <IconWrapper {...props} viewBox="0 0 32 32">
    <path d="M16 28.8c-7.069 0-12.8-5.731-12.8-12.8s5.731-12.8 12.8-12.8c7.069 0 12.8 5.731 12.8 12.8v0c-0.008 7.066-5.734 12.792-12.799 12.8h-0.001zM16 4.8c-6.186 0-11.2 5.014-11.2 11.2s5.014 11.2 11.2 11.2c6.186 0 11.2-5.014 11.2-11.2v0c-0.007-6.183-5.017-11.193-11.199-11.2h-0.001z" />
    <path d="M15.040 14.4h1.918v8h-1.918v-8z" />
    <path d="M15.040 10h1.918v2.24h-1.918v-2.24z" />
  </IconWrapper>
)

export default HelpIcon
