import React, { FunctionComponent } from 'react'
import cx from 'classnames'

import Milestone from './milestone'
import { MilestoneGroup } from './lib/types'

interface Props {
  /**
   * The key for the currently opened milestone
   */
  currentMilestoneKey: string
  /**
   * This is an error thrown after there was an issue when we hit an endpoint
   */
  error?: string
  /**
   * Boolean used for all mobile styling
   */
  isMobile: boolean
  /**
   * Boolean from the reducer when the request to update a Milestone
   * has been made and before it is complete
   */
  isUpdating: boolean
  /**
   * The width of the milestones container, used for calculating the new width of the
   * milestone when it's selected
   */
  milestonesContainerWidth: number
  /**
   * Milestones grouped by an overarching theme, described by the name, all provided by backend
   */
  milestoneGroups: MilestoneGroup[]
  /**
   * The key for the next incomplete milestone
   */
  nextMilestoneKey?: string
  /**
   * Function that saves the milestone answer, posting it to the backend and updating the redux store
   */
  onSaveMilestoneAnswer: (answer: string, milestoneTaskId: number, milestoneTaskKey: string, answerId?: number) => void
  /**
   * Sets the new current milestone key by passing in the milestone key of the one just clicked
   */
  setCurrentMilestoneKey: (newCurrentMilestoneKey: string) => void
}

const MilestoneGroupsList: FunctionComponent<Props> = ({
  currentMilestoneKey,
  error,
  isMobile,
  isUpdating,
  milestonesContainerWidth,
  milestoneGroups,
  nextMilestoneKey,
  onSaveMilestoneAnswer,
  setCurrentMilestoneKey
}) =>
  milestoneGroups && (
    <ul className="o-list-bare mt-8 mx-8">
      {milestoneGroups.map((milestoneGroup, index) => {
        const theVeryLastMilestoneKey = milestoneGroups.slice(-1)[0].milestones.slice(-1)[0].key

        return (
          <li className="divide-x-dashed pb-8" key={index}>
            <h5 className="c-type c-type--subhead-md text-black">{milestoneGroup.name}</h5>
            <ul className="o-list-bare">
              {milestoneGroup.milestones.map((milestone, index) => {
                const isSelectedMilestone = currentMilestoneKey === milestone.key

                return (
                  <li
                    key={index}
                    className={cx({
                      'pb-4': isSelectedMilestone,
                      'pt-4': index === 0
                    })}
                  >
                    <div
                      className={cx('bg-white', {
                        'c-task-selected py-4 pl-6 pr-8': isSelectedMilestone,
                        'ml-4': !isSelectedMilestone
                      })}
                      style={{
                        width: isSelectedMilestone ? milestonesContainerWidth : 'inherit',
                        marginLeft: isSelectedMilestone ? '-2rem' : '',
                        marginTop: isSelectedMilestone ? '-1px' : ''
                      }}
                    >
                      <Milestone
                        currentMilestoneKey={currentMilestoneKey}
                        error={error}
                        index={index}
                        isMobile={isMobile}
                        isUpdating={isUpdating}
                        lastMilestoneKey={theVeryLastMilestoneKey}
                        milestone={milestone}
                        nextMilestoneKey={nextMilestoneKey}
                        onSaveMilestoneAnswer={(answer, milestoneTaskId, milestoneTaskKey, answerId) =>
                          onSaveMilestoneAnswer(answer, milestoneTaskId, milestoneTaskKey, answerId)
                        }
                        setCurrentMilestoneKey={setCurrentMilestoneKey}
                      />
                    </div>
                  </li>
                )
              })}
            </ul>
          </li>
        )
      })}
    </ul>
  )

export default MilestoneGroupsList
