import u from 'updeep'

import {
  LOADING_LAWYER_INTERACTIONS,
  LOADING_LAWYER_INTERACTIONS_SUCCESS,
  LOADING_LAWYER_INTERACTIONS_ERROR
} from 'lib/constants'
import { LawyerInteractions } from 'components/screens/dashboard/customer_dashboard/v2/lawyer_interactions_panel/types'

interface LawyerInteractionsReducerState {
  lawyerInteractions: LawyerInteractions
  loading: boolean
  error: Error | null
}

const initialState: LawyerInteractionsReducerState = {
  lawyerInteractions: {},
  loading: false,
  error: null
}

export default function lawyerInteractionsReducer(state, action) {
  state = state || initialState

  switch (action.type) {
    case LOADING_LAWYER_INTERACTIONS:
      return u({ loading: true, error: null }, state)
    case LOADING_LAWYER_INTERACTIONS_SUCCESS:
      return u({ loading: false, lawyerInteractions: action.payload }, state)
    case LOADING_LAWYER_INTERACTIONS_ERROR:
      return u({ loading: false, error: action.payload }, state)
    default:
      return state
  }
}
