import React, { Component } from 'react'

import { openChat } from '../actions/chat_actions'
import { connect } from 'react-redux'

interface Props {
  openChat: (args: any) => void
  via: string
}

export class AskQuestionButton extends Component<Props> {
  handleAskQuestionClick = (event) => {
    event.preventDefault()
    event.nativeEvent.stopImmediatePropagation()

    this.props.openChat({ via: this.props.via })
  }

  render() {
    return (
      <a href="#chat" onClick={this.handleAskQuestionClick}>
        {this.props.children}
      </a>
    )
  }
}

function mapDispatchToActions(dispatch) {
  return {
    openChat: (...args) => dispatch(openChat(...args))
  }
}

export default connect(null, mapDispatchToActions)(AskQuestionButton)
