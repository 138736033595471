import React, { FunctionComponent } from 'react'
import cx from 'classnames'

import RequestStatusIndicator from './request_status_indicator'
import getDocumentRequestLocaleService from 'services/document_request_locale_service'
import { getDocRequestCustomerStatus } from './utils'
import { DocumentRequestCustomerStatus } from 'lib/constants'
import { keyIsEnter } from 'lib/keyboard_helpers'
import Markdown from 'components/markdown'

interface Props {
  request: DocumentRequestModel
  activeRequestId?: string
  onRequestClicked: (requestId: string) => void
  slaIsSet: boolean
}

const CollapsibleOutlineRequest: FunctionComponent<Props> = ({
  request,
  activeRequestId,
  onRequestClicked,
  slaIsSet
}) => {
  const documentRequestLocaleService = getDocumentRequestLocaleService()
  const docRequestCustomerStatus = getDocRequestCustomerStatus(request, slaIsSet)

  const defaultTitle =
    documentRequestLocaleService.t(request, 'outline_title', true, true) ||
    documentRequestLocaleService.t(request, 'generic_title', true, true) ||
    documentRequestLocaleService.t(request, 'title', true, true)

  const requestTitleClasses = cx('c-type c-type--body-sans-sm o-action-items__request-status o-layout--flex', {
    'c-type--alert': docRequestCustomerStatus === DocumentRequestCustomerStatus.SeeComment,
    'c-type--primary': docRequestCustomerStatus === DocumentRequestCustomerStatus.ToDo
  })

  const requestClasses = cx('o-action-items__outline-action-item c-btn c-btn__link c-btn__link--primary w-full', {
    'is-active': activeRequestId === request.id
  })

  const handleEnterDocRequestSelected = (id) => (event: React.KeyboardEvent) => {
    if (keyIsEnter(event)) onRequestClicked(id)
  }

  return (
    <li
      key={request.id}
      onClick={() => onRequestClicked(request.id)}
      onKeyPress={handleEnterDocRequestSelected(request.id)}
    >
      <button className={requestClasses}>
        <span className={requestTitleClasses}>
          <RequestStatusIndicator iconOnly customerStatus={docRequestCustomerStatus} />
          <Markdown source={request.title || defaultTitle} />
        </span>
      </button>
    </li>
  )
}

export default CollapsibleOutlineRequest
