import server from 'lib/api/v1/server'
import getTelemetryService from 'lib/telemetry'

export interface EventData {
  ctaLocation?: string
  questionKey?: string
  questionnaireName?: string
  pageName?: string
  sectionName?: string
  containerSection?: string
  hasSelectedUSCISFees?: boolean
  paymentType?: string
  value?: number
  currency?: string
  error?: string
  inputType?: string
  milestoneKey?: string
  taskKey?: string
  timeZone?: string
  tierCartIntent?: string
}

export const trackUserEvent =
  (event_name: string, event_data: EventData = {}) =>
  () => {
    return server.post(`/api/v1/user_events`, { event_name, event_data })
  }

export const trackClientSideUserEvent = (event_name: string, event_data: EventData = {}) => {
  getTelemetryService().track(event_name, event_data)
}
