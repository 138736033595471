import { createSelector } from 'reselect'
import { getCurrentKase } from 'reducers/application/selectors'
import { getContentForStatusData, getLawyerInfoFromKase, getStewardInfoFromKase } from 'reducers/dashboard/index'

export const getLawyerInfo = createSelector(getCurrentKase, (kase) => getLawyerInfoFromKase(kase))

export const getStewardInfo = createSelector(getCurrentKase, (kase) => getStewardInfoFromKase(kase))

export const getDashboardContentForWorkflowStatus = createSelector(getCurrentKase, (kase) =>
  getContentForStatusData(kase.workflow_status)
)
