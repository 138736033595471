import React, { FunctionComponent } from 'react'
import Heading from 'components/type/heading'

interface ExplicitProps {
  isMobileBrowser: boolean
}

const ProcessInfoHeader: FunctionComponent<ExplicitProps> = ({ isMobileBrowser }) => {
  const renderDesktop = () => {
    return (
      <header className="flex justify-center">
        <div className="flex w-3/4">
          <div className="w-1/2">
            <Heading tag="h3" color="emphasized">
              We make applying fast and easy
            </Heading>
          </div>
        </div>
      </header>
    )
  }

  const renderMobile = () => {
    return (
      <header className="flex justify-start">
        <div className="flex pl-6 w-1/2">
          <Heading tag="h3" color="emphasized">
            We make applying fast and easy
          </Heading>
        </div>
      </header>
    )
  }

  return isMobileBrowser ? renderMobile() : renderDesktop()
}

export default ProcessInfoHeader as FunctionComponent<ExplicitProps>
