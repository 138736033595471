import React, { FunctionComponent } from 'react'
import IconWrapper, { IconWrapperExternalProps } from './icon_wrapper'
import classNames from 'classnames'

const VisaIcon: FunctionComponent<IconWrapperExternalProps> = (props) => {
  const className = classNames(props.className, 'c-icon--payment')

  return (
    <IconWrapper {...props} className={className} viewBox="0 0 32 20">
      <g fill="currentColor" fillRule="nonzero">
        <path d="M28,0.842105263 C29.7440571,0.842105263 31.1578947,2.2559429 31.1578947,4 L31.1578947,16 C31.1578947,17.7440571 29.7440571,19.1578947 28,19.1578947 L4,19.1578947 C2.2559429,19.1578947 0.842105263,17.7440571 0.842105263,16 L0.842105263,4 C0.842105263,2.2559429 2.2559429,0.842105263 4,0.842105263 L28,0.842105263 Z M28,7.47939722e-16 L4,7.47939722e-16 C1.790861,0 2.705415e-16,1.790861 0,4 L0,16 C2.705415e-16,18.209139 1.790861,20 4,20 L28,20 C30.209139,20 32,18.209139 32,16 L32,4 C32,1.790861 30.209139,0 28,7.47939722e-16 Z" />
        <path d="M13.5107945,7.11735616 L10.9199315,13.3372329 L9.21375342,13.3372329 L7.95894521,8.40827397 C7.93832567,8.17076611 7.79431373,7.96160591 7.57979452,7.85760274 C7.07764752,7.61443871 6.54687197,7.43549152 6,7.3249863 L6,7.14443836 L8.70821918,7.14443836 C9.07680975,7.14433003 9.39073471,7.41231476 9.44846575,7.77635616 L10.1255205,11.3873151 L11.7865616,7.15346575 L13.5107945,7.11735616 Z M17.8890822,8.84158904 C17.8890822,8.61590411 18.1057397,8.38119178 18.566137,8.318 C19.105394,8.2676435 19.6482185,8.36435362 20.1369041,8.59784932 L20.4167534,7.27984932 C19.9381218,7.09724098 19.4304829,7.00244094 18.9182055,7 C17.338411,7 16.2099863,7.84857534 16.2099863,9.05824658 C16.2099863,9.9609863 17.0043973,10.4574932 17.6092329,10.7553973 C18.2140685,11.0533014 18.4397534,11.2519041 18.4397534,11.5317534 C18.4397534,11.9470137 17.9432466,12.136589 17.4828493,12.136589 C16.9101004,12.1480611 16.3441174,12.01123 15.839863,11.7393836 L15.5329315,13.1025205 C16.1076758,13.3289378 16.7207039,13.442348 17.338411,13.4365342 C19.0175068,13.4365342 20.1098219,12.6060137 20.1188493,11.3060685 C20.1278767,9.66308219 17.8710274,9.57280822 17.8890822,8.84158904 Z M24.2895068,13.3372329 L25.77,13.3372329 L24.4790822,7.11735616 L23.1249726,7.11735616 C22.8264701,7.11823973 22.5584735,7.3004774 22.4479178,7.57775342 L20.0466301,13.3372329 L21.725726,13.3372329 L22.0597397,12.4344932 L24.1089589,12.4344932 L24.2895068,13.3372329 Z M22.4840274,11.1345479 L23.3235753,8.79645205 L23.838137,11.1164932 L22.4840274,11.1345479 Z M15.7676438,7.11735616 L14.4496438,13.3372329 L12.8788767,13.3372329 L14.1968767,7.11735616 L15.7676438,7.11735616 Z" />
      </g>
    </IconWrapper>
  )
}

export default VisaIcon
