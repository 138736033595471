import React, { FunctionComponent } from 'react'
import IconWrapper, { IconWrapperExternalProps } from './icon_wrapper'

const CloseIcon: FunctionComponent<IconWrapperExternalProps> = (props) => (
  <IconWrapper {...props} viewBox="0 0 32 32">
    <path d="M25.708 7.708l-1.416-1.416-8.292 8.294-8.292-8.294-1.416 1.416 8.294 8.292-8.294 8.292 1.416 1.416 8.292-8.294 8.292 8.294 1.416-1.416-8.294-8.292 8.294-8.292z" />
  </IconWrapper>
)

export default CloseIcon
