import React from 'react'
import { connect } from 'react-redux'

import Modal from '../modal'
import SignInNav from '../../sign_in_nav'
import { isUserLoggedIn } from 'reducers/selectors'

interface Props {
  customTitle?: string
  isActive: boolean
  isLogIn: boolean
  noRedirect: boolean
  successUrl?: string
  userLoggedIn: boolean
}

interface State {
  title: string
}

class SessionModal extends React.Component<Props, State> {
  static defaultProps = {
    isLogIn: true,
    noRedirect: false
  }

  modalTitle(isLogIn: boolean) {
    if (isLogIn) {
      return this.props.customTitle || 'Log In'
    } else {
      return this.props.customTitle || 'Save Progress'
    }
  }

  constructor(props: Props) {
    super(props)

    this.state = {
      title: this.modalTitle(props.isLogIn)
    }
  }

  onViewChange = (isLogIn: boolean): void => this.setState({ title: this.modalTitle(isLogIn) })

  render() {
    const navProps = {
      successUrl: this.props.successUrl,
      isLogIn: this.props.isLogIn,
      noRedirect: this.props.noRedirect
    }

    return (
      <Modal isActive={this.props.isActive} title={this.state.title}>
        {() => <SignInNav onViewChange={this.onViewChange} {...navProps} />}
      </Modal>
    )
  }
}

function mapStateToProps(state) {
  return {
    userLoggedIn: isUserLoggedIn(state)
  }
}

export default connect(mapStateToProps)(SessionModal)
