import React from 'react'

import { QuestionnaireInputContainerProps } from '..'

import t from 'lib/update_transformations'

import BufferedFieldValue, { AfterChangeFunction } from 'components/forms/buffered_field_value'
import { ModelDataSerializableValue } from 'reducers/model'

import QualifierComponent from 'components/forms/inputs/qualifier/component'
import ValidatedInput from 'components/forms/validated_input'

import { MappedProps } from './'
import { getKaseModelTemplate } from 'lib/kase_model_template_helpers'

type Props = MappedProps &
  QuestionnaireInputContainerProps & {
    collection: string
    counter: string
    qualifier_collection: string
    member_type: string
  }

export default class CollectionQualifier extends React.Component<Props> {
  handleChange = (onChange: Function, fireValidation: Function) => (value: Nullable<string>) => {
    onChange(value)
    fireValidation()
  }

  updateCounter: AfterChangeFunction<ModelDataSerializableValue> = ({
    value,
    serializedValueChanged,
    changeValueAtPath
  }) => {
    if (!serializedValueChanged) return
    if (!value) return

    let newValue = -1

    if (value === 'yes') {
      newValue = this.props.currentIndex
    } else {
      newValue = this.props.currentIndex - 1
    }

    if (newValue >= 0) {
      changeValueAtPath(this.props.counter, { value: newValue })
    }
  }

  updateQualifierCollection: AfterChangeFunction<ModelDataSerializableValue> = ({
    value,
    serializedValueChanged,
    changeValueAtPath
  }) => {
    if (!serializedValueChanged) return

    if (value === 'no') {
      const operation = t.resizeCollection(this.props.currentIndex, getKaseModelTemplate(this.props.member_type))
      changeValueAtPath(this.props.qualifier_collection, { operation })
    }
  }

  updateCollection: AfterChangeFunction<ModelDataSerializableValue> = ({
    value,
    serializedValueChanged,
    changeValueAtPath
  }) => {
    if (!serializedValueChanged) return

    const newSize = value === 'no' ? this.props.currentIndex - 1 : this.props.currentIndex

    changeValueAtPath(this.props.collection, {
      operation: t.resizeCollection(Math.max(newSize, 0), getKaseModelTemplate(this.props.member_type))
    })
  }

  render() {
    const { disabled, name, path } = this.props

    const afterChangeEvents = [this.updateCounter, this.updateCollection, this.updateQualifierCollection]

    return (
      <ValidatedInput disabled={disabled} path={path}>
        {({ fireValidation, getValidationClasses, renderValidations }) => (
          <React.Fragment>
            <BufferedFieldValue afterChangeEvents={afterChangeEvents} path={path}>
              {(value, onChange) => (
                <QualifierComponent
                  className={getValidationClasses()}
                  name={name}
                  onChange={this.handleChange(onChange, fireValidation)}
                  value={value}
                />
              )}
            </BufferedFieldValue>

            {renderValidations()}
          </React.Fragment>
        )}
      </ValidatedInput>
    )
  }
}
