import { Palette } from '@boundless-immigration/boundless-ui'
import React from 'react'

const DashboardEmailIllustration = () => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13 9.5H23V11.1H13V9.5Z" fill={Palette.emphasisMain} />
    <path d="M13 14.3H23V15.9H13V14.3Z" fill={Palette.emphasisMain} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.2 3H28.8V11.8302L34 16.3438V33.8H2V16.3438L7.2 11.8302V3ZM7.2 14.4785L4.53747 16.7896L7.2 18.3871V14.4785ZM4 18.333V31.8H32V18.3329L17.9999 26.733L4 18.333ZM31.4624 16.7895L28.8 14.4785V18.387L31.4624 16.7895ZM27.2 4.6V19.347L17.9999 24.867L8.8 19.3471V4.6H27.2Z"
      fill={Palette.emphasisMain}
    />
  </svg>
)

export default DashboardEmailIllustration
