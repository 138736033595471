import { OPEN_CHAT } from 'lib/constants'

export function openChat({ via }) {
  return (dispatch) => {
    dispatch({
      type: OPEN_CHAT,
      via: via
    })
  }
}
