import { connect } from 'react-redux'

import { getIndexForCurrentPanel, getModelValue } from 'reducers/selectors'

export interface MappedProps {
  counterValue: Nullable<number>
  currentIndex: number
}

import CollectionQualifier from './component'

function mapStateToProps(state, ownProps): MappedProps {
  return {
    counterValue: getModelValue(state, ownProps.counter),
    currentIndex: parseInt(getIndexForCurrentPanel(state) || 1, 10)
  }
}

export default connect(mapStateToProps)(CollectionQualifier)
