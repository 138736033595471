import Accordion from 'components/accordion'
import React from 'react'
import { ContextualHelp } from '../../../lib/types'
import ReactMarkdown from 'react-markdown'
import { MarkdownLinkRenderer } from 'lib/markdown'
import AskIcon from 'components/icons/ask_icon'
import PhoneIcon from 'components/icons/phone_icon'
import EmailIcon from 'components/icons/email_icon'
import { ContactItem } from './contactItem'

export interface ChatABTestProps {
  contactEmail: string
  contextualHelpOpen: boolean
  contextualHelpV2: ContextualHelp
  handleSupportMessageClick: (event: any) => void
}

export const ChatABTest = ({
  contactEmail,
  contextualHelpV2,
  handleSupportMessageClick,
  contextualHelpOpen
}: ChatABTestProps) => {
  return (
    <li className="o-block o-block--ample pr-4">
      {contextualHelpV2?.text && (
        <div className="pb-8">
          <h1 className="c-type c-type--headline-sm c-type--emphasized">About this question</h1>
          <Accordion
            className="py-2 "
            noBorder={true}
            noXPadding
            hasChildPadding={false}
            header={
              <span className="c-type--primary">
                {contextualHelpV2.header ? contextualHelpV2.header : 'Additional Information'}
              </span>
            }
            isOpen={contextualHelpOpen}
          >
            <div className="o-block o-block--compact c-type c-type--emphasized c-type--body-serif-md c-type--react-markdown">
              {/* @ts-ignore */}
              <ReactMarkdown source={contextualHelpV2.text} renderers={{ Link: MarkdownLinkRenderer }} />
            </div>
          </Accordion>
          <hr />
        </div>
      )}

      <h1 className="pb-5 c-type c-type--headline-sm c-type--emphasized">We're here to help</h1>

      <ContactItem
        href="#support-message"
        contactType="chat"
        onClick={handleSupportMessageClick}
        icon={<AskIcon aria-hidden="true" />}
        text="Chat with us"
      />

      <ContactItem
        contactType="phone"
        href="tel:1-855-268-6353"
        icon={<PhoneIcon aria-hidden="true" />}
        text="Call 1-855-268-6353"
      />

      <ContactItem
        contactType="email"
        href={`mailto:${contactEmail}@boundless.com`}
        icon={<EmailIcon aria-hidden="true" />}
        text="Email Us"
      />
    </li>
  )
}
