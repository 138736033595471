import React, { FunctionComponent } from 'react'
import Paragraph from 'components/type/paragraph'
import WorkflowDebug from 'components/screens/dashboard/customer_dashboard/workflow_debug'

const RefundingScreen: FunctionComponent = () => (
  <div className="o-pane__fill o-layout--impose">
    <WorkflowDebug />
    <div className="o-container">
      <section className="o-layout--center o-box o-box--spacious">
        <h1 className="o-block c-type c-type--headline-md">We've started the refund process</h1>
        <Paragraph>You will get an email confirmation as soon as the refund has been processed.</Paragraph>
      </section>
    </div>
  </div>
)

export default RefundingScreen
