import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'
import cx from 'classnames'

import { isMobileBrowser } from 'reducers/selectors'

import HCCLink from './hcc_link'
import Paragraph from 'components/type/paragraph'

interface ExplicitProps {
  content: any
  contentId?: string
  header: string
  headerClass?: string
  headerId?: string
  hasLink?: boolean
  linkHref?: string
  linkOpensChat?: boolean
  linkText?: string
  subtitle?: string
  smallContent?: boolean
}

interface MappedProps {
  isMobileBrowser: boolean
}

const EligibilityReportInfoCell: FunctionComponent<ExplicitProps & MappedProps> = (props) => {
  const headerSpacingClasses = cx('o-block o-grid--fluid o-grid--fluid--trailed', {
    'o-block--ample': !props.isMobileBrowser,
    'o-block--compact': props.isMobileBrowser
  })

  const layoutClasses = cx({
    'o-layout--left': props.isMobileBrowser
  })

  const typeClasses = cx({
    'c-type--headline-sm': props.smallContent,
    'c-type--headline-md': !props.smallContent
  })

  return (
    <>
      <div className={`${headerSpacingClasses} ${props.headerClass}`}>
        <div className="o-box o-box--light o-box--dense">
          <div id={props.headerId} className="o-layout o-layout--padded-x--minuscule c-type c-type--subhead-sm">
            {props.header}
          </div>
        </div>
      </div>
      <div
        className={cx('o-block o-block--compact c-type c-type--emphasized', layoutClasses, typeClasses)}
        id={props.contentId}
      >
        {props.content}
      </div>
      <div className={cx('o-layout', layoutClasses)}>
        {props.hasLink && (
          <HCCLink
            href={props.linkHref}
            overrideToChat={props.linkOpensChat}
            linkText={props.linkText}
            icon="question"
          />
        )}
        {props.subtitle && (
          <Paragraph serif size="sm" className="c-type--understated">
            {props.subtitle}
          </Paragraph>
        )}
      </div>
    </>
  )
}

function mapStateToProps(state: any): MappedProps {
  return {
    isMobileBrowser: isMobileBrowser(state)
  }
}

export default connect(mapStateToProps)(EligibilityReportInfoCell) as FunctionComponent<ExplicitProps>
