import React, { FunctionComponent } from 'react'
import IconWrapper, { IconWrapperExternalProps } from './icon_wrapper'

const DocumentIcon: FunctionComponent<IconWrapperExternalProps> = (props) => (
  <IconWrapper {...props} viewBox="0 0 32 32">
    <path d="M27.2 11.253l-7.89-8.053h-14.51v25.6h22.4zM19.616 5.797l4.885 4.987h-4.885zM6.4 27.2v-22.4h11.934v7.266h7.266v15.134z" />
    <path d="M10.4 16.96h11.2v1.282h-11.2v-1.282z" />
    <path d="M10.4 21.76h11.2v1.282h-11.2v-1.282z" />
  </IconWrapper>
)

export default DocumentIcon
