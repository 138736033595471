import { getCurrentApplicationFeeInCents } from 'reducers/selectors'
import { dollarsWithCommas } from 'lib/format_money'

import { ApplicationState } from 'reducers'

export function applicationFee(state: ApplicationState): string {
  const dollars = parseInt(getCurrentApplicationFeeInCents(state) / 100, 10)

  return dollarsWithCommas(dollars)
}
