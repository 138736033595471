import React from 'react'

import BufferedFieldValue from 'components/forms/buffered_field_value'
import { defaultInputProps, InputProps } from 'components/forms/inputs'

class CheckboxInput extends React.Component<InputProps> {
  static defaultProps = defaultInputProps

  handleChange = (onChange) => (event) => {
    onChange(event)

    // We want the onBlur handler (validation typically) to fire when an
    // checkbox gets checked, as checking a checkbox doesn't fire blur (you
    // have to click on another element and that flies against expectations for
    // validation). Leaking the relationship of onBlur to validation in here is
    // not great, however...
    this.props.onBlur()
  }

  render() {
    const { afterChangeEvents, disabled, name, path } = this.props

    return (
      <BufferedFieldValue
        afterChangeEvents={afterChangeEvents}
        formatter={(value) => Boolean(value)}
        path={path}
        className="c-custom-control__multiline-wrapper"
      >
        {(checked, onChange) => (
          <React.Fragment>
            <input
              checked={checked}
              className="c-custom-control__input"
              data-model-path={path}
              disabled={disabled}
              id={path}
              name={name}
              onChange={this.handleChange(onChange)}
              type="checkbox"
              value="true"
            />

            <span className="c-custom-control__indicator" />
          </React.Fragment>
        )}
      </BufferedFieldValue>
    )
  }
}

export default CheckboxInput
