import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'

import { joinI18nKeys } from 'lib/i18n'
import { openModal } from 'actions/modal_actions'
import getTelemetryService from 'lib/telemetry'
import panelLocaleService from 'services/panel_locale_service'
import { getCurrentChapter } from 'reducers/selectors'

import ProfileIcon from 'components/icons/profile_icon'
import HelpIcon from 'components/icons/help_icon'

interface Props {
  chapterTitle?: string
  onNext: () => void
  openModal: (args: { name: string }) => void
  panel: any
}

const MobileChapterInterstitial: FunctionComponent<Props> = (props) => {
  const panel = props.panel
  const hideModalLauncher = panel.index === 0

  const handleModalClick = (event) => {
    event.preventDefault()

    getTelemetryService().trackLink({
      linkName: 'LaunchContinueOnDesktopModal'
    })

    props.openModal({ name: 'ContinueOnDesktopModal' })
  }

  const panelString = (key) => {
    return panelLocaleService.t(joinI18nKeys(panel.panel_keys, key))
  }

  return (
    <div className="o-grid">
      <div className="o-box o-box--spacious o-grid__row">
        <div className="o-grid__col-5">
          <div className="o-grid__col-6">
            <ul className="o-block o-list__inline o-list__inline--tight">
              <li>
                <ProfileIcon className="c-icon--title c-icon--emphasized c-icon--lg-responsive" />
              </li>
              <li>
                <h3 className="o-block o-block--tight c-type c-type--subhead-md c-type--secondary">
                  {props.chapterTitle}
                </h3>
                <hr className="c-divider c-divider--narrow c-divider--secondary c-divider--emphasized" />
              </li>
            </ul>
            <div className="o-block o-block--ample">
              <h1 className="c-type c-type--headline-lg c-type--emphasized">{panelString('header')}</h1>
            </div>

            <div className="o-block">
              <p className="c-type c-type--body-serif-md">{panelString('body')}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="o-layout o-layout--center">
        {!hideModalLauncher && (
          <div className="o-block o-block--compact">
            <a className="c-type c-type--body-sans-sm" href="#" onClick={handleModalClick}>
              Continue on a desktop or laptop <HelpIcon className="c-icon--link" size="small" />
            </a>
          </div>
        )}
      </div>
    </div>
  )
}

function mapStateToProps(state) {
  const chapter = getCurrentChapter(state)
  const chapterTitle = chapter && panelLocaleService.t(`${chapter.chapter_key}.title`)

  return {
    chapterTitle
  }
}

function mapDispatchToActions(dispatch) {
  return {
    openModal: (...args) => dispatch(openModal(...args))
  }
}

export default connect(mapStateToProps, mapDispatchToActions)(MobileChapterInterstitial)
