import { createResponsiveStateReducer } from 'redux-responsive'

import sassSettings from 'stylesheets/settings/shared.json'

export default createResponsiveStateReducer(
  {
    small: sassSettings['grid-breakpoint-md-min'] - 1,
    medium: sassSettings['grid-breakpoint-lg-min'] - 1
  },
  {
    infinity: 'large'
  }
)

/**
 * TODO we could rely on the :export keyword provided by webpack instead of
 * using a JSON file. https://github.com/css-modules/icss#export
 *
 * In SCSS:
 * $grid-breakpoint-md-min: 544;
 * $grid-breakpoint-lg-min: 1024;
 *
 * :export {
 *   gridBreakpointMedium: $grid-breakpoint-md-min;
 *   gridBreakpointLarge: $grid-breakpoint-lg-min;
 * }
 *
 * In JS:
 * import sassSettings from 'stylesheets/settings/_settings.custom.scss'
 *
 * The only downside is that Mocha doesn't know to parse a SCSS import without
 * the appropriate webpack loaders. We could write a test-specific webpack
 * configuration for Mocha, but we'd have to make sure it's fast enough to
 * encourage running the test suite.
 */
