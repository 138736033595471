import React, { FunctionComponent, useEffect } from 'react'
import { connect } from 'react-redux'
import { openChat } from 'actions/chat_actions'
import { StatusSection } from './interaction_status_section'
import { LawyerInteractions } from './types'
import { getInterviewPrepStatusData, getLawyerConsultStatusData } from './interaction_display_logic'
import CalendarIcon from 'components/icons/calendar_icon'
import { getLawyerInteractions } from 'reducers/selectors'
import { fetchLawyerInteractions } from 'actions/kase_actions'
import ControlledAccordion from 'components/accordion/controlled_accordion'
import classNames from 'classnames'

interface MappedProps {
  lawyerInteractions: LawyerInteractions
}

interface ActionProps {
  fetchLawyerInteractions: () => void
  openChat: (args: { via: string }) => void
}

interface ExplicitProps {
  currentPhaseName: string
  collapsable?: boolean // default is false
  defaultExpanded?: boolean // default is false
}

interface Props extends MappedProps, ExplicitProps, ActionProps {}

const LawyerInteractionsPanel: FunctionComponent<Props> = ({
  collapsable = false,
  currentPhaseName,
  defaultExpanded = false,
  fetchLawyerInteractions,
  lawyerInteractions,
  openChat
}) => {
  const [isExpanded, setIsExpanded] = React.useState(defaultExpanded)

  useEffect(() => {
    fetchLawyerInteractions()
  }, [])

  // if there is no response at all we want to hide this component
  if (!lawyerInteractions || Object.keys(lawyerInteractions).length === 0) {
    return null
  }

  // If the response has no applicable lawyer interactions we want to hide the panel as well
  if (Object.values(lawyerInteractions).every((lawyerInteraction) => lawyerInteraction.status === 'not_applicable')) {
    return null
  }

  const getNumberOfIncompleteCalls = (lawyerInteractions: LawyerInteractions) => {
    const NumberOfIncompleteCalls = Object.values(lawyerInteractions).filter(
      (lawyerInteraction) => lawyerInteraction.status !== 'completed' && lawyerInteraction.status !== 'not_applicable'
    ).length
    return NumberOfIncompleteCalls > 0 ? `(${NumberOfIncompleteCalls})` : ''
  }

  // The header text for this widget will change for legacy premium customers who only have an interview prep call
  const interviewPrepOnly = () => {
    return (
      lawyerInteractions.lawyer_consult_event.status === 'not_applicable' &&
      lawyerInteractions.interview_prep_event.status !== 'not_applicable'
    )
  }
  const headerText = interviewPrepOnly() ? 'Interview prep call' : 'Your lawyer consults'
  const headerStyles = classNames(
    'c-type c-type--headline-sm py-4',
    collapsable ? 'c-type--primary' : 'c-type--emphasized'
  )
  const header = (
    <span className={headerStyles}>
      <CalendarIcon className="mr-2 -mt-1 c-icon--md" aria-hidden="true" />
      {headerText} {collapsable && getNumberOfIncompleteCalls(lawyerInteractions)}
    </span>
  )

  const statusSections = (
    <>
      <StatusSection
        statusData={getLawyerConsultStatusData(lawyerInteractions.lawyer_consult_event, openChat, currentPhaseName)}
        fetchLawyerInteractions={fetchLawyerInteractions}
      />
      <StatusSection
        statusData={getInterviewPrepStatusData(
          lawyerInteractions.interview_prep_event,
          openChat,
          lawyerInteractions.lawyer_consult_event,
          currentPhaseName
        )}
        fetchLawyerInteractions={fetchLawyerInteractions}
      />
    </>
  )

  if (collapsable) {
    return (
      <ControlledAccordion
        id={'lawyer-interactions-panel'}
        className="border-x-0 border-b-8"
        header={header}
        hasSummaryOutline={false}
        isOpen={isExpanded}
        onClicked={() => setIsExpanded(!isExpanded)}
      >
        <ul className="c-list p-4">{statusSections}</ul>
      </ControlledAccordion>
    )
  }

  return (
    <aside className="c-dashboard__panel o-block o-box o-box--light o-layout--impose p-4">
      <header className="o-layout--flex o-layout--vertical-center o-layout--space-between o-block o-block--tight pt-2">
        {header}
      </header>
      <ul className="c-list">{statusSections}</ul>
    </aside>
  )
}

function mapStateToProps(state): MappedProps {
  return {
    lawyerInteractions: getLawyerInteractions(state)
  }
}

function mapDispatchToActions(dispatch) {
  return {
    fetchLawyerInteractions: () => dispatch(fetchLawyerInteractions()),
    openChat: (...args) => dispatch(openChat(...args))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToActions
)(LawyerInteractionsPanel) as FunctionComponent<ExplicitProps>
