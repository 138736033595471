import { ContactType } from './contactItem'

/**
 * CON-924
 *
 * Standard hours are 6am - 10pm PST and 7am - 11pm PDT 7 days a week
 *
 * Email Response within 12 hours (unless there’s a holiday, add return date)
 *
 * The office is closed Dec 24, 25, 31, and Jan 1. All other holidays
 * have some coverage. The offline messages should display the next day that the
 * office will be open.
 */
export const getAvailability = (contactType: ContactType): { isAvailable: boolean; displayText: string } => {
  const OFFICE_CLOSED_HOLIDAYS = [
    { month: 11, date: 24 }, // Dec 24
    { month: 11, date: 25 }, // Dec 25
    { month: 11, date: 31 }, // Dec 31
    { month: 0, date: 1 } // Jan 1
  ]

  const OFFICE_HOURS_PST = {
    start: 6, // 6am PST
    end: 22 // 10pm PST
  }

  const EMAIL_RESPONSE_HOURS = 12

  const getPSTLocalTimeInHours = () => {
    const now = new Date()
    const offset = now.getTimezoneOffset() / 60
    const pstOffset = -8
    const hour = now.getHours()

    const calculatedHour = hour + offset + pstOffset

    if (calculatedHour < 0) {
      return calculatedHour + 24
    } else if (calculatedHour > 23) {
      return calculatedHour - 24
    }
    return calculatedHour
  }

  const getIsHoliday = (day: Date): boolean => {
    return OFFICE_CLOSED_HOLIDAYS.some((holiday) => holiday.month === day.getMonth() && holiday.date === day.getDate())
  }

  const getNextOpenDay = (currentDay: Date): Date => {
    const nextDay = new Date(currentDay)

    if (getIsHoliday(nextDay)) {
      const date = nextDay.getDate()
      nextDay.setDate(date + 1)
      return getNextOpenDay(nextDay)
    }

    return nextDay
  }

  const getIsOfficeOpen = (): boolean => {
    const now = new Date()
    const hour = getPSTLocalTimeInHours()

    const isWorkingHours = hour >= OFFICE_HOURS_PST.start && hour < OFFICE_HOURS_PST.end

    return !getIsHoliday(now) && isWorkingHours
  }

  const getAvailabilityText = (isAvailable: boolean, contactType: ContactType): string => {
    const now = new Date()
    const isHoliday = getIsHoliday(now)
    const nextOpenDay = getNextOpenDay(now)
    const displayDate = `${nextOpenDay.toLocaleString('en-us', { month: 'short' })} ${nextOpenDay.getDate()}`

    switch (contactType) {
      case 'email':
        return isHoliday ? `Response on ${displayDate}` : `Response in ${EMAIL_RESPONSE_HOURS} hours`
      default:
        return isAvailable ? 'Online now' : isHoliday ? `Offline until ${displayDate}` : '6 am-10 pm PST'
    }
  }

  const isAvailable = getIsOfficeOpen()
  const displayText = getAvailabilityText(isAvailable, contactType)

  return { isAvailable, displayText }
}
