import React, { FunctionComponent } from 'react'
import cx from 'classnames'

interface Props {
  variation?: 'emphasis' | 'primary'
}

const FancySpinner: FunctionComponent<Props> = ({ variation }) => (
  <div
    className={cx('c-fancy-spinner', {
      'c-fancy-spinner--emphasis': variation === 'emphasis',
      'c-fancy-spinner--primary': variation === 'primary'
    })}
    aria-label="Loading..."
  >
    <span />
    <span />
    <span />
  </div>
)

export default FancySpinner
