import React, { FunctionComponent, useState, FormEvent, useEffect } from 'react'
import Button from 'components/button'

interface ExplicitProps {
  submitReply: (reply: string) => void
}

type Props = ExplicitProps

export const ThreadPanelReply: FunctionComponent<Props> = ({ submitReply }) => {
  const [reply, setReply] = useState('')
  const [isDisabled, setIsDisabled] = useState(false)

  useEffect(() => {
    setIsDisabled(reply.length === 0)
  }, [reply])

  const onMessageSave = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    submitReply(reply)
    setReply('')
  }

  return (
    <div className="flex-grow md:px-6">
      <div className="md:mb-6 py-4 border border-gray-200" style={{ boxShadow: '0 3px 8px rgba(0, 0, 0, .07)' }}>
        <form onSubmit={onMessageSave} className="px-4 text-left">
          <textarea
            className="mb-2 w-full border px-3 py-2 border-gray-300"
            onChange={(event) => setReply(event.target.value)}
            name="reply"
            value={reply}
            disabled={false}
            placeholder="Reply to this thread"
          />
          <Button
            type="submit"
            className="mt-2"
            label="Save reply"
            color="primary"
            disabled={isDisabled}
            aria-disabled={isDisabled}
          />
        </form>
      </div>
    </div>
  )
}

export default ThreadPanelReply
