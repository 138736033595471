let csrfTokenElement: Nullable<HTMLMetaElement> = null

import { AxiosRequestConfig, AxiosResponse } from 'axios'

interface TokenHeader {
  'X-CSRF-Token'?: string
}

function getCsrfToken(): Nullable<string> {
  if (!csrfTokenElement) {
    const element = document.querySelector('meta[name="csrf-token"]')

    if (!element) {
      return
    }

    csrfTokenElement = element as HTMLMetaElement
  }

  return csrfTokenElement && csrfTokenElement.content
}

function updateCsrfToken(token: string) {
  const element = document.querySelector('meta[name="csrf-token"]')
  if (!element) {
    return
  }

  element.setAttribute('content', token)
}

export function getCsrfHeaders(): TokenHeader {
  const token = getCsrfToken()

  return token ? { 'X-CSRF-Token': token } : {}
}

export const addCsrfHeaderToRequest = (config: AxiosRequestConfig) => {
  config.headers = Object.assign({}, getCsrfHeaders(), config.headers || {})

  return config
}

export function refreshCsrfInDom(response: AxiosResponse) {
  let refreshedToken = response.headers && response.headers['x-refresh-csrf-token']
  if (refreshedToken) {
    updateCsrfToken(refreshedToken)
  }

  return response
}
