import React, { FunctionComponent } from 'react'
import IconWrapper, { IconWrapperExternalProps } from './icon_wrapper'

type Props = IconWrapperExternalProps

const GearSpinnerIcon: FunctionComponent<Props> = (props) => {
  return (
    <IconWrapper {...props} viewBox="0 0 41 41">
      <path d="M0.928023 20.4861C0.928024 17.2458 1.67089 14.2083 3.15662 11.3737C4.64114 8.53786 6.71852 6.17486 9.38875 4.28469L4.52833 4.28469L4.52833 0.684386L15.3293 0.684386L15.3293 11.4853L11.729 11.4853L11.729 7.07493C9.47876 8.60506 7.7158 10.5474 6.4401 12.902C5.16559 15.2578 4.52833 17.7858 4.52833 20.4861C4.52833 23.9364 5.51842 27.0638 7.49858 29.8685C9.47875 32.6743 12.104 34.6473 15.3743 35.7874L14.5642 39.2977C10.4838 37.9476 7.19076 35.5474 4.68495 32.0971C2.18033 28.6468 0.928023 24.7764 0.928023 20.4861ZM22.5299 0.774394C24.54 0.984412 26.468 1.46445 28.3138 2.21452C30.1583 2.96458 31.8607 3.99967 33.4208 5.31978L30.8556 7.885C29.6255 6.92492 28.3132 6.14485 26.9186 5.5448C25.5229 4.94475 24.06 4.56972 22.5299 4.41971L22.5299 0.774394ZM33.3758 10.4052L35.941 7.84C37.2611 9.43013 38.304 11.1403 39.0697 12.9704C39.8342 14.8006 40.2914 16.7058 40.4414 18.6859L36.8411 18.6859C36.6911 17.1558 36.3161 15.7007 35.716 14.3206C35.116 12.9404 34.3359 11.6353 33.3758 10.4052ZM33.3758 30.6119C34.3359 29.3818 35.1159 28.0695 35.716 26.675C36.3161 25.2793 36.6911 23.8164 36.8411 22.2862L40.4414 22.2862C40.2614 24.2964 39.7963 26.2238 39.0463 28.0683C38.2962 29.9141 37.2611 31.617 35.941 33.1772L33.3758 30.6119ZM22.5299 36.5975C24.06 36.4474 25.5229 36.0724 26.9186 35.4724C28.3132 34.8723 29.6255 34.0922 30.8556 33.1322L33.4208 35.6974C31.8607 37.0175 30.1583 38.0526 28.3138 38.8026C26.468 39.5527 24.54 40.0178 22.5299 40.1978L22.5299 36.5975Z" />
      <g id="Group" transform="translate(11.5 11)">
        <path d="M6.93157 18.487H10.5319L11.0719 15.7868C11.432 15.6367 11.7698 15.4795 12.0854 15.3151C12.3998 15.1495 12.7221 14.9467 13.0521 14.7067L15.6623 15.5167L17.4625 12.4565L15.3923 10.6563C15.4523 10.2363 15.4823 9.84624 15.4823 9.48621C15.4823 9.12618 15.4523 8.73615 15.3923 8.31611L17.4625 6.51596L15.6623 3.4557L13.0521 4.26577C12.7221 4.02575 12.3998 3.82293 12.0854 3.65731C11.7698 3.4929 11.432 3.33569 11.0719 3.18567L10.5319 0.485443H6.93157L6.39152 3.18567C6.03149 3.33569 5.69366 3.4929 5.37804 3.65731C5.06361 3.82293 4.74138 4.02575 4.41135 4.26577L1.80113 3.4557L0.000976562 6.51596L2.07115 8.31611C2.01115 8.73615 1.98115 9.12618 1.98115 9.48621C1.98115 9.84624 2.01115 10.2363 2.07115 10.6563L0.000976562 12.4565L1.80113 15.5167L4.41135 14.7067C4.74138 14.9467 5.06361 15.1495 5.37804 15.3151C5.69366 15.4795 6.03149 15.6367 6.39152 15.7868L6.93157 18.487ZM8.73172 13.0865C7.74164 13.0865 6.89437 12.7337 6.18991 12.028C5.48425 11.3236 5.13141 10.4763 5.13141 9.48621C5.13141 8.49613 5.48425 7.64826 6.18991 6.9426C6.89437 6.23813 7.74164 5.88591 8.73172 5.88591C9.72181 5.88591 10.5697 6.23813 11.2753 6.9426C11.9798 7.64826 12.332 8.49613 12.332 9.48621C12.332 10.4763 11.9798 11.3236 11.2753 12.028C10.5697 12.7337 9.72181 13.0865 8.73172 13.0865Z" />
      </g>
    </IconWrapper>
  )
}

export default GearSpinnerIcon
