import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'

import { openChat as openChatAction } from 'actions/chat_actions'
import SolidQuestionMarkIcon from 'components/icons/solid_question_mark_icon'

interface ExplicitProps {
  variant?: string
  context: string
}

interface ActionProps {
  openChat: Function
}

type Props = ExplicitProps & ActionProps

const MobileChatCTA: FunctionComponent<Props> = ({ openChat, context, variant = 'solid' }) => {
  const buttonStyles =
    variant === 'solid'
      ? 'border-none rounded-full bg-blue-100 py-3 px-2 text-blue-500 c-type--body-sans-sm'
      : 'border border-2 border-blue-200 rounded-full bg-white py-3 px-2 text-blue-500 c-type--body-sans-sm'

  const handleChatTriggerClick = (event) => {
    event.preventDefault()
    event.nativeEvent.stopImmediatePropagation()

    openChat({ via: context })
  }

  return (
    <button className={buttonStyles} onClick={(event) => handleChatTriggerClick(event)} type="button">
      <SolidQuestionMarkIcon className="mr-2 mb-1 mt-px" />
      Questions? We're here to help
    </button>
  )
}

function mapDispatchToActions(dispatch): ActionProps {
  return {
    openChat: (...args) => dispatch(openChatAction(...args))
  }
}

export default connect(null, mapDispatchToActions)(MobileChatCTA)
