import React, { FunctionComponent } from 'react'
import SkeletonHeading from 'components/skeleton/heading'
import SkeletonBlock from 'components/skeleton/block'
import SkeletonParagraph from 'components/skeleton/paragraph'
import { Issue } from 'reducers/issues'
import Heading from 'components/type/heading'
import IssueCard from 'components/screens/issues/issue_card'
import IssuesHeader from 'components/screens/issues/issues_header'
import Paragraph from 'components/type/paragraph'

interface ExplicitProps {
  issues?: Issue[]
  onOpenThread: Function
}

const IssuesContainer: FunctionComponent<ExplicitProps> = ({ issues, onOpenThread }) => {
  if (!issues) {
    // Loading UI
    return (
      <div className="o-action-items__actions-container px-8">
        <SkeletonHeading centered />
        <SkeletonBlock width="5rem" height="2.5rem" className="mx-auto" />
        <SkeletonParagraph numLines={5} />
        <SkeletonParagraph className="w-1/4" />
        <SkeletonBlock height="12rem" />
      </div>
    )
  }

  return (
    <div className="o-action-items__actions-container px-8">
      <div className="o-block">
        <Heading tag="h1" color="emphasized" centered>
          Questions and Tasks
        </Heading>

        <IssuesHeader />
      </div>

      <div className="o-action-items__actions-container">
        <Paragraph color="emphasized" bold>
          Questions and Tasks
        </Paragraph>

        {issues.length === 0 && <Paragraph>There are no issues to resolve at the moment.</Paragraph>}
        {issues.map((issue) => (
          <IssueCard key={issue.id} issue={issue} onOpenThread={onOpenThread} />
        ))}
      </div>
    </div>
  )
}

export default IssuesContainer
