import { QuestionnairePage, QuestionnaireQuestion, QuestionnaireQuestionSet } from './types'

const filterRequiredQuestions = (page: QuestionnairePage): QuestionnaireQuestion[] => {
  const questions: QuestionnaireQuestion[] = []

  page.attributes?.page_elements?.forEach((pageElement) => {
    pageElement?.attributes?.questions?.forEach((question) => {
      questions.push(question)
    })
  })

  return questions.filter(
    (question) => question.attributes?.type !== 'OptionalQuestion' && question?.attributes?.input_type !== 'checkbox'
  )
}

export const questionIsCompleted = (question: QuestionnaireQuestion): boolean => {
  if (question?.attributes?.input_value_incomplete) {
    return false
  }

  return !!question?.attributes?.input_value
}

export const allQuestionsOnPageCompleted = (page: QuestionnairePage): boolean => {
  const requiredQuestions = filterRequiredQuestions(page)

  if (page.attributes?.type === 'HistoryPage') {
    return requiredQuestions.every(
      (question) =>
        question?.attributes?.input_type !== 'address_history_gap' &&
        question?.attributes?.input_type !== 'employment_history_gap' &&
        !question?.attributes?.input_value?.address_history_mismatch
    )
  }

  return requiredQuestions.every((question) => questionIsCompleted(question))
}

export const someQuestionsOnPageCompleted = (page: QuestionnairePage): boolean => {
  const requiredQuestions = filterRequiredQuestions(page)

  if (page.attributes.type === 'HistoryPage') {
    return requiredQuestions?.some(
      (question) =>
        question?.attributes?.input_type === 'address_history' ||
        question?.attributes?.input_type === 'employment_history'
    )
  }

  return requiredQuestions?.some((question) => questionIsCompleted(question))
}

export const allQuestionsInSetCompleted = (questionSet: QuestionnaireQuestionSet): boolean => {
  return questionSet.attributes.questions.every(
    (question) =>
      question.attributes.type === 'OptionalQuestion' ||
      question.attributes.input_type === 'checkbox' ||
      questionIsCompleted(question)
  )
}
