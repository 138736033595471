import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import BufferedFieldValue from 'components/forms/buffered_field_value'
import { getCurrentUserEmail, getCurrentKaseId, isCurrentUserTemporary } from 'reducers/selectors'
import { defaultInputProps, InputProps } from 'components/forms/inputs'
import { TELEMETRY_EVENTS } from 'lib/constants'
import { postTelemetry } from 'actions/telemetry_actions'

const MAX_LENGTH = 9

function serializeSsn(value) {
  return (value || '').toString().replace(/[^\d]/g, '').slice(0, MAX_LENGTH)
}

export function formatSsn(value) {
  value = serializeSsn(value)

  const parts = [value.slice(0, 3), value.slice(3, 5), value.slice(5, 9)]
  let formatted = parts.filter((part) => part !== '').join('-')

  return formatted
}

interface MappedProps {
  isCurrentUserTemporary: boolean
  userEmail: string
  kaseId: number
}

interface ActionProps {
  postTelemetry: Function
}

type Props = InputProps & MappedProps & ActionProps

const SSNInput: FunctionComponent<Props> = (props) => {
  const {
    className,
    disabled,
    id,
    onBlur,
    name,
    path,
    isCurrentUserTemporary,
    postTelemetry,
    userEmail,
    kaseId
  } = props

  const [revealSsn, setRevealSsn] = useState(false)

  const ssnRef = useRef<HTMLInputElement>()
  const isDisabled = isCurrentUserTemporary ? true : disabled
  const fieldType = revealSsn ? 'tel' : 'password'

  useEffect(() => {
    if (revealSsn === true) {
      postTelemetry(TELEMETRY_EVENTS.QA_VIEWED_SSN, {
        userEmail,
        kaseId,
        path
      })
    }
  }, [revealSsn])

  const toggleRevealSsn = (event) => {
    event.preventDefault()

    setRevealSsn(!revealSsn)

    const currentSsnRef = ssnRef.current
    if (currentSsnRef) {
      currentSsnRef.focus()
    }
  }

  const onSsnToggleBlur = (event) => {
    // no need to validate this in ValidationContainer
    event.stopPropagation()
  }

  const toggleSsn = (value) => {
    if (!value || value.length === 0 || isCurrentUserTemporary) return

    return (
      <div className="o-layout--vertical-center o-layout--impose__body">
        <a
          href="#"
          className="c-type c-type--body-sans-sm c-link o-layout--center"
          onClick={toggleRevealSsn}
          onBlur={onSsnToggleBlur}
          onChange={onSsnToggleBlur}
        >
          {revealSsn ? 'Hide' : 'Show'}
        </a>
      </div>
    )
  }

  return (
    <BufferedFieldValue path={path} serializer={serializeSsn} formatAsYouType={true} formatter={formatSsn}>
      {(value, onChange) => {
        return (
          <div className="o-layout--impose">
            <input
              ref={ssnRef}
              autoComplete="off"
              className={className}
              data-model-path={path}
              disabled={isDisabled}
              id={id}
              inputMode="numeric"
              name={name}
              onBlur={onBlur}
              onChange={onChange}
              placeholder="123-45-6789"
              type={fieldType}
              value={value}
            />
            {toggleSsn(value)}
          </div>
        )
      }}
    </BufferedFieldValue>
  )
}

SSNInput.defaultProps = defaultInputProps

function mapDispatchToActions(dispatch: Dispatch) {
  return bindActionCreators(
    {
      postTelemetry
    },
    dispatch
  )
}

function mapStateToProps(state): MappedProps {
  return {
    userEmail: getCurrentUserEmail(state),
    kaseId: getCurrentKaseId(state),
    isCurrentUserTemporary: isCurrentUserTemporary(state)
  }
}

export default connect(mapStateToProps, mapDispatchToActions)(SSNInput)
