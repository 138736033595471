export interface Pronouns {
  /**
   * He, she, they, etc.
   */
  personal: string
  /**
   * Him, her, them
   */
  demonstrative: string
  /**
   * His, her, their
   */
  possessive: string
}

// Make sure these map to input in app/admin/admins.rb
export const PRONOUN_MAP: { [key: string]: Pronouns } = {
  'He/him': {
    personal: 'he',
    demonstrative: 'him',
    possessive: 'his'
  },
  'She/her': {
    personal: 'she',
    demonstrative: 'her',
    possessive: 'her'
  },
  'They/them': {
    personal: 'they',
    demonstrative: 'them',
    possessive: 'their'
  }
}

export const DEFAULT_PRONOUNS: Pronouns = {
  personal: 'they',
  demonstrative: 'them',
  possessive: 'their'
}

/**
 * A string representation of a bigger set of pronouns. See app/admin/admins.rb
 * @param adminMapping "They/them", "She/her", "He/him", etc.
 */
export const getPronounsForAdminMapping = (adminMapping: string) => {
  return PRONOUN_MAP[adminMapping] || DEFAULT_PRONOUNS
}
