import React from 'react'

import { joinI18nKeys } from 'lib/i18n'
import panelLocaleService from 'services/panel_locale_service'
import { getAfterChangeHooks } from 'components/forms/field_after_change_events'

import ValidatedInput from 'components/forms/validated_input'
import { QuestionnaireInputContainerProps } from 'components/forms/inputs'
import NumberInput from 'components/forms/inputs/number/number_input'

type Props = QuestionnaireInputContainerProps

class Number extends React.Component<Props> {
  render() {
    const { after_change_event_list, disabled, id, name, path, placeholder, resourceKeys } = this.props

    const afterChangeEvents = getAfterChangeHooks(after_change_event_list)

    return (
      <div className="o-flag o-grid--fluid o-grid--stack@sm o-block c-paper-form__group">
        <label
          className="o-grid__cell--3/12 o-grid__cell--off@sm c-paper-form__label c-paper-form__segment"
          htmlFor={id}
        >
          <span className="c-type c-type--subhead-sm">
            {panelLocaleService.t(joinI18nKeys(resourceKeys, `${name}.label`))}
          </span>
        </label>

        <div className="o-grid__cell--9/12 o-grid__cell--off@sm c-paper-form__segment self-end">
          <ValidatedInput disabled={disabled} path={path}>
            {({ fireValidation, getValidationClasses, renderValidations }) => (
              <>
                <NumberInput
                  className={`c-paper-form__control ${getValidationClasses()}`}
                  disabled={disabled}
                  id={id}
                  name={name}
                  onBlur={fireValidation}
                  placeholder={placeholder}
                  path={path}
                  afterChangeEvents={afterChangeEvents}
                />
                {renderValidations()}
              </>
            )}
          </ValidatedInput>
        </div>
      </div>
    )
  }
}

export default Number
