import React, { FunctionComponent } from 'react'
import GoToRapidVisaLink from 'components/panels/outcomes/rapid_visa_redirect/go_to_rapid_visa_link'
import ProcessInfoColumnWrapper from 'components/panels/outcomes/rapid_visa_redirect/process_info_column_wrapper'
import BeginApplicationColumnItem from 'components/panels/outcomes/rapid_visa_redirect/begin_application_column_item'

interface ExplicitProps {
  isMobileBrowser: boolean
}

const BeginApplicationColumn: FunctionComponent<ExplicitProps> = ({ isMobileBrowser }) => {
  return (
    <ProcessInfoColumnWrapper isMobileBrowser={isMobileBrowser}>
      <div className="flex flex-col">
        <span className="flex pt-2 pb-1 c-type--body-sans-md c-type--emphasized">Also included:</span>
        <BeginApplicationColumnItem text="Complete application assembled to government standards" />
        <BeginApplicationColumnItem text="Option to apply for your children" />
        <BeginApplicationColumnItem text="Live chat and phone support" />
        <BeginApplicationColumnItem text="Important updates via SMS" />
      </div>
      {!isMobileBrowser && (
        <GoToRapidVisaLink className="c-btn c-btn--small c-btn--emphasized mt-4">Begin Application</GoToRapidVisaLink>
      )}
    </ProcessInfoColumnWrapper>
  )
}

export default BeginApplicationColumn as FunctionComponent<ExplicitProps>
