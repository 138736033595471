const getToken = () => {
  return localStorage.getItem('jwtToken')
}

const setToken = (token) => {
  localStorage.setItem('jwtToken', token)
}

const removeToken = () => {
  localStorage.removeItem('jwtToken')
}

export default {
  getToken,
  setToken,
  removeToken
}
