import server from 'lib/api/v1/server'
import { AxiosPromise } from 'axios'
import { formatKasesUrl } from 'lib/api/v1/helpers'

import { SectionProgress } from '../../../reducers/section_progress'

const formatProgressUrl = (kaseId) => {
  const baseUrl = formatKasesUrl(kaseId, 'progress')
  return baseUrl
}

function index(request): AxiosPromise<SectionProgress[]> {
  const { kaseId } = request

  return server
    .get(formatProgressUrl(kaseId))
    .then((response) => {
      return response
    })
    .catch((
      error // eslint-disable-next-line no-console
    ) => console.log('Error loading address and employment progress', error))
}

export default {
  index
}
