import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'
import { DashboardPhase } from 'components/screens/dashboard/customer_dashboard/phase_map'
import PhaseInformationTemplate from './phase_information_template'
import { ContentForStatus } from '../phase_config/phase_description'
import { getDashboardContentForWorkflowStatus } from 'reducers/selectors'

interface ExplicitProps {
  currentPhase: DashboardPhase
}

interface PropsFromRedux {
  contentForStatus: ContentForStatus
}

type Props = ExplicitProps & PropsFromRedux

const DefaultPhaseInformation: FunctionComponent<Props> = ({ contentForStatus, currentPhase }) => {
  return (
    <PhaseInformationTemplate
      currentPhase={currentPhase}
      header={contentForStatus.message}
      description={contentForStatus.phaseDescription}
      primaryCTA={contentForStatus.primaryCTA}
    />
  )
}

function mapStateToProps(state): PropsFromRedux {
  return {
    contentForStatus: getDashboardContentForWorkflowStatus(state)
  }
}

export default connect(mapStateToProps)(DefaultPhaseInformation) as FunctionComponent<ExplicitProps>
