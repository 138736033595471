import { AxiosPromise } from 'axios'

import server from './server'

interface Issue {
  kaseId: number
  issueId: number
}

function resolveIssue({ kaseId, issueId }: Issue): AxiosPromise {
  return server.put(` /api/v1/kases/${kaseId}/issues/${issueId}/resolve`).then((response) => response.data)
}

export default {
  resolveIssue
}
