import _last from 'lodash/last'

import { getRepeatsForSmartQualifierCollection } from './helpers'
import { PanelGroupRepeatsFunctionCollection } from './'

const getRepeatsForPreviousAddressGroup = (role) => (data) => {
  const addresses = data.get(`${role}.addresses`)
  const hasCompletedAddressHistory = data.get(`${role}.address_history_complete`)

  let previousAddressesCount = addresses.length - 1
  if (hasCompletedAddressHistory === false) {
    previousAddressesCount++
  }

  return Math.max(previousAddressesCount, 0)
}

const getRepeatsForPreviousEmployersGroup = (role) => (data) => {
  const employments = data.get(`${role}.employments`)
  const hasCompletedEmploymentHistory = data.get(`${role}.employment_history_complete`)

  let previousEmploymentsCount = employments.length - 1
  if (!hasCompletedEmploymentHistory) {
    previousEmploymentsCount++
  }

  return Math.max(previousEmploymentsCount, 0)
}

const getRepeatsForClubMembershipsGroup = (data) => {
  const memberships = data.get(`uscis_questionnaire.club_or_organization_memberships`)

  if (!memberships) {
    return 1
  }

  const lastMembership = _last(memberships)
  if (lastMembership && lastMembership.has_membership !== 'yes') {
    return memberships.length
  }

  return memberships.length + 1
}

const getRepeatsForPreviousMarriagesGroup = (role) => (data) => {
  return data.get(`${role}.number_of_previous_marriages`) || 0
}

const getRepeatsForBeneficiaryOtherNationalitiesGroup = (data) => {
  const nationalities = data.get(`beneficiary.other_nationalities`)

  if (!nationalities) {
    return 1
  }

  const lastNationality = _last(nationalities)
  if (lastNationality && lastNationality.qualified_at_path !== 'yes') {
    return nationalities.length
  }

  return nationalities.length + 1
}

const beneficiaryPath = (path) => `beneficiary.${path}`
const assetsPath = (path) => `${path}.assets`

export const mbgcRepeatsFunctions: PanelGroupRepeatsFunctionCollection = {
  sponsor_previous_marriage_group: getRepeatsForPreviousMarriagesGroup('sponsor'),
  beneficiary_previous_marriage_group: getRepeatsForPreviousMarriagesGroup('beneficiary'),
  sponsor_previous_names_group: getRepeatsForSmartQualifierCollection('sponsor.previous_names'),
  beneficiary_previous_names_group: getRepeatsForSmartQualifierCollection(beneficiaryPath('previous_names')),
  beneficiary_parents: () => 2,
  sponsor_parents: () => 2,
  sponsor_previous_address_group: getRepeatsForPreviousAddressGroup('sponsor'),
  beneficiary_previous_address_group: getRepeatsForPreviousAddressGroup('beneficiary'),
  sponsor_previous_employers_group: getRepeatsForPreviousEmployersGroup('sponsor'),
  beneficiary_previous_employers_group: getRepeatsForPreviousEmployersGroup('beneficiary'),
  beneficiary_children_group: (data) => data.get(beneficiaryPath('number_of_children')) || 0,
  beneficiary_other_nationalities_group: getRepeatsForBeneficiaryOtherNationalitiesGroup,
  organization_memberships: getRepeatsForClubMembershipsGroup,
  criminal_intent_or_history_explanations: (data) =>
    Object.keys(data.get('uscis_questionnaire.criminal_intent_or_history_items' || {})).length,
  disabilities_or_impairments_explanations: (data) =>
    Object.keys(data.get(beneficiaryPath('disabilities_or_impairments_items') || {})).length,
  sponsor_tax_return_incomes: () => 3,
  other_household_members_group: getRepeatsForSmartQualifierCollection(
    'household.members_who_are_not_bene_or_children'
  ),
  beneficiary_children_additional_info_group: (data) => data.get(beneficiaryPath('number_of_children')) || 0,
  education_degrees_group: getRepeatsForSmartQualifierCollection(beneficiaryPath('degrees')),
  occupational_skills_group: getRepeatsForSmartQualifierCollection(beneficiaryPath('occupational_skills')),
  language_skills_group: getRepeatsForSmartQualifierCollection(beneficiaryPath('language_skills')),
  fee_waivers_group: getRepeatsForSmartQualifierCollection(beneficiaryPath('fee_waivers')),
  beneficiary_assets_group: getRepeatsForSmartQualifierCollection(assetsPath('beneficiary')),
  sponsor_assets_group: getRepeatsForSmartQualifierCollection(assetsPath('sponsor')),
  child_assets_group: (data, index) => {
    const path = assetsPath(beneficiaryPath(`children.${index}`))

    const repeats = getRepeatsForSmartQualifierCollection(path)(data)

    return repeats
  },
  household_member_assets_group: (data, index) => {
    const path = assetsPath(`household.members_who_are_not_bene_or_children.items.${index}`)

    return getRepeatsForSmartQualifierCollection(path)(data)
  }
}

export const mbgcPathIndexOffsets = new Set([
  'sponsor_previous_address_group',
  'sponsor_previous_employers_group',
  'beneficiary_previous_address_group',
  'beneficiary_previous_employers_group'
])

export const mbgcCustomIndices = {
  criminal_intent_or_history_explanations: (data) =>
    Object.keys(data.get('uscis_questionnaire.criminal_intent_or_history_items') || {}),
  disabilities_or_impairments_explanations: () => ['low_vision', 'hearing_impairment', 'other_impairment']
}
