import React from 'react'
import { connect } from 'react-redux'
import {
  getCurrentPanelSlug,
  isOnSetupSection,
  isOnPublicChargeEstimatorSection,
  isUserLoggedIn,
  hasEnteredContactInfo,
  getTimelinePageUrl
} from 'reducers/selectors'

import { showSignInModal } from './sign_in_nav'
import AlertIcon from './icons/alert_icon'

interface Props {
  currentPanelSlug?: string
  contactInfoEntered: boolean
  isCompact: boolean
  isOnSetupSection: boolean
  isOnPublicChargeEstimatorSection: boolean
  userLoggedIn: boolean
  timelinePageUrl: string
}
const PIIPanels = ['sponsor-ssn', 'beneficiary-ssn']

class SaveProgressButton extends React.Component<Props> {
  static defaultProps = {
    isCompact: false
  }

  onClick = (event: SyntheticMouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    showSignInModal({
      via: 'SaveProgressButton',
      isLogIn: false
    })
  }

  isHidden() {
    // TODO: isOnSection conditions like this are a sad hack. We will ship this to ensure forward progress on PCE, but let's use the metadata instead of isOnSection type conditions.
    return this.props.userLoggedIn || this.props.isOnSetupSection || this.props.isOnPublicChargeEstimatorSection
  }

  buttonMsg() {
    if (this.isPIIPanel()) {
      return (
        <React.Fragment>
          <h4 className="o-block c-type c-type--body-sans-sm c-type--emphasized c-type--bold">
            <AlertIcon /> Set a password now so you don’t lose your application progress.
          </h4>
          <span className="o-block o-block--compact c-type c-type--body-sans-sm c-type--muted">
            It also allows you to
          </span>
          <ul className="c-type c-type--body-sans-sm c-type--muted o-layout--left">
            <li>Securely store your info on our servers</li>
            <li>Access your info on any device</li>
            <li>Get help from a teammate 7 days/week</li>
          </ul>
        </React.Fragment>
      )
    } else {
      return (
        <React.Fragment>
          <h4 className="o-block o-block--compact c-type c-type--body-sans-sm c-type--emphasized">Set a password to</h4>
          <ul className="c-type c-type--body-sans-sm c-type--muted o-layout--left">
            <li>Securely store your info on our servers</li>
            <li>Access your info on any device</li>
            <li>Get help from a teammate 7 days/week</li>
          </ul>
        </React.Fragment>
      )
    }
  }

  buttonMsgCompact() {
    return (
      <React.Fragment>
        <h4 className="o-block o-block--compact c-type c-type--body-sans-sm c-type--muted o-layout--padded-x">
          Set a password to securely save and access your info later on any device.
        </h4>
      </React.Fragment>
    )
  }

  isPIIPanel() {
    return PIIPanels.includes(this.props.currentPanelSlug)
  }

  getButtonMsg() {
    if (!(this.props.contactInfoEntered || this.isPIIPanel())) return
    return this.props.isCompact ? this.buttonMsgCompact() : this.buttonMsg()
  }

  render() {
    if (this.isHidden()) return null

    let btnClasses = 'c-btn c-btn--emphasized js-save-progress'

    btnClasses += this.props.isCompact ? ' c-btn--small' : ' c-btn--block'

    return (
      <div className="o-block">
        <div className="o-block">
          <button className={btnClasses} onClick={this.onClick} type="button">
            Save Progress
          </button>
        </div>
        {this.getButtonMsg()}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    currentPanelSlug: getCurrentPanelSlug(state),
    contactInfoEntered: hasEnteredContactInfo(state),
    isOnSetupSection: isOnSetupSection(state),
    isOnPublicChargeEstimatorSection: isOnPublicChargeEstimatorSection(state),
    userLoggedIn: isUserLoggedIn(state),
    timelinePageUrl: getTimelinePageUrl(state)
  }
}

export default connect(mapStateToProps)(SaveProgressButton)
