import React, { FunctionComponent, useEffect, useState } from 'react'
import ReactInputMask from 'react-input-mask'

import InputValidators, { InputValidatorType } from './input_validators'
import { QuestionnaireInputContainerProps } from '../../lib/types'
import { formatCurrency } from 'components/forms/inputs/currency/helpers'

import cx from 'classnames'

let validationTimeout = null

/**
 * Any string input type in a Questionnaire
 * Takes additional mask and validators params and displays validation messages
 */
const Input: FunctionComponent<QuestionnaireInputContainerProps & React.InputHTMLAttributes<HTMLInputElement>> = ({
  children,
  className,
  disabled,
  formatter,
  id,
  isMetaQuestion,
  mask,
  maskChar,
  name,
  onBlurEvent,
  onChangeEvent,
  permanents,
  placeholder,
  prefix,
  type,
  validators,
  value,
  warning
}) => {
  const [validationMessage, setValidationMessage] = useState(null as string)
  // Making an assumption that masked inputs tend to be invalid until they
  // are completed, so a longer lead time before blasting the user with a
  // red flag is desired.
  // If this doesn't turn out to be the case, we can set a different set
  // of parameters here to get the desired times for each input type
  const validationBufferTime = mask ? 1500 : 300

  useEffect(() => {
    return () => clearTimeout(validationTimeout)
  }, [])

  /**
   * Runs the input value through the provided validators for the input and
   * sets the validation message to be displayed if invalid. Also returns
   * true if the input is valid so it can be used as a gate to save on blur
   * @param newValue
   * @returns {boolean}
   */
  const validateInputValue = (newValue: string) => {
    let newValidationMessage: string = null

    if (validators) {
      const invalidTests = validators.filter((validator) => {
        const Validator: InputValidatorType = InputValidators[validator.name]
        // If the validator passed in with the question doesn't match
        // a Validator from our library, print a warning a pass it as valid
        if (!Validator) {
          // eslint-disable-next-line no-console
          console.warn('Missing Validator for ', validator.name)
          return false
        }
        return !Validator.test(newValue, validator.options)
      })

      if (invalidTests.length) {
        newValidationMessage = InputValidators[invalidTests[0].name].message
      }
    }

    setValidationMessage(newValidationMessage)

    return !newValidationMessage
  }

  // Apply formatting based on formatter
  const format = (value) => {
    let formattedValue = value
    if (formatter === 'currency') {
      formattedValue = formatCurrency(value)
    } else if (formatter === 'allCaps') {
      formattedValue = value.toUpperCase()
    }
    return formattedValue
  }

  const handleChange = (event) => {
    const newValue = format(event.target.value)
    setValidationMessage(null)
    clearTimeout(validationTimeout)

    validationTimeout = setTimeout(() => {
      validateInputValue(newValue)
    }, validationBufferTime)

    event.target.value = newValue
    onChangeEvent(event)
  }

  const handleBlur = (event) => {
    // Do not send back onBlurEvent if the input is invalid to prevent saving
    if (validateInputValue(event.target.value)) {
      onBlurEvent(event)
    }
  }

  let inputComponent
  if (mask) {
    inputComponent = (
      //@ts-ignore
      <ReactInputMask
        autoComplete="off"
        className={className || 'c-paper-form__control'}
        disabled={disabled}
        id={id}
        mask={mask}
        maskChar={maskChar}
        //@ts-ignore
        permanents={permanents}
        name={name}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={placeholder}
        type={type || 'text'}
        value={value}
      />
    )
  } else {
    inputComponent = (
      <input
        autoComplete="off"
        className={className || 'c-paper-form__control'}
        disabled={disabled}
        id={id}
        name={name}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={placeholder}
        type={type || 'text'}
        value={value}
      />
    )
  }

  // Different styling options for if a name is provided for the input
  const nameExists = name && name.length > 0

  return (
    <div
      className={cx('o-flag o-grid--fluid o-grid--stack@sm o-block', {
        'c-paper-form__group': !isMetaQuestion
      })}
    >
      {nameExists && (
        <label
          className="o-grid__cell--3/12 o-grid__cell--off@sm c-paper-form__label c-paper-form__segment"
          htmlFor={id}
        >
          <span className="c-type c-type--subhead-sm">{name}</span>
        </label>
      )}

      <div
        className={cx('o-grid__cell--off@sm', {
          'c-paper-form__segment c-paper-form__segment--drop@sm o-grid__cell--9/12': nameExists,
          'w-full': !nameExists
        })}
      >
        {prefix ? (
          <div className="c-paper-form__combined">
            <div className="c-paper-form__combined-label pb-2">
              <span className="c-type c-type--body-sans-md">{prefix}</span>
            </div>
            {inputComponent}
            {children}
          </div>
        ) : (
          <div>
            {inputComponent}
            {children}
            {warning && value && value.length > warning.maxLength && (
              <div className="o-flag__item o-flag__item--drop@sm c-tooltip c-tooltip--warning" key="too-long">
                <div className="c-type c-type--body-sans-sm">{warning.message}</div>
              </div>
            )}
          </div>
        )}
      </div>

      {validationMessage && (
        <div className="o-flag__item o-flag__item--drop@sm c-tooltip c-tooltip--danger" key={validationMessage}>
          <div className="c-type c-type--body-sans-sm">{validationMessage}</div>
        </div>
      )}
    </div>
  )
}

export default Input
