import React, { FunctionComponent } from 'react'

import ControlledModalWrapper, { CloseReason } from './controlled_modal_wrapper'

interface Props {
  isOpen?: boolean
  modalWidth?: string | number
  onRequestClose: (reason: CloseReason) => void
}

const BlankModal: FunctionComponent<Props> = ({ children, isOpen, onRequestClose, modalWidth = '30rem' }) => {
  return (
    <ControlledModalWrapper onRequestClose={onRequestClose} isOpen={isOpen} style={{ width: modalWidth }}>
      {children}
    </ControlledModalWrapper>
  )
}

export default BlankModal
