import React, { FocusEventHandler } from 'react'
import InputMask from 'react-input-mask'

import { phoneMaskForCountry } from '../../../../lib/input_mask'
import BufferedFieldValue from 'components/forms/buffered_field_value'

interface SerializerFunction {
  (arg: string): string
}

interface Props {
  className: string
  disabled: boolean
  id: string
  isoCountryCode: Nullable<string>
  onBlur: FocusEventHandler<HTMLInputElement>
  path: string
  placeholder: string
  serializer?: SerializerFunction
}

// http://www.fileformat.info/info/unicode/char/2007/index.htm
const MASK_CHAR = '\u2007'

function formatNumber(numberString: string) {
  return numberString.replace(/[^\d]/g, '')
}

class PhoneNumberInput extends React.Component<Props> {
  static defaultProps = {
    className: '',
    disabled: false,
    placeholder: ''
  }

  getPlaceholder() {
    let mask = this.numberMask()
    return mask ? mask.replace(/\d/g, MASK_CHAR) : this.props.placeholder
  }

  numberMask() {
    return phoneMaskForCountry(this.props.isoCountryCode, {
      maskChar: MASK_CHAR
    })
  }

  permanentMaskChars() {
    const mask = this.numberMask()

    if (!mask) return []

    const indexes = []

    for (let i = 0; i < mask.length; i++) {
      if (mask[i] !== '9') {
        indexes.push(i)
      }
    }

    return indexes
  }

  render() {
    const { className, disabled, id, onBlur, path } = this.props
    return (
      <BufferedFieldValue serializer={formatNumber} path={path}>
        {(value, onChange) => {
          return (
            <InputMask
              alwaysShowMask={false}
              autoComplete="off"
              className={className}
              data-model-path={path}
              disabled={disabled}
              id={`${id}-number`}
              inputRef={(ref) => (this.inputRef = ref)}
              mask={value ? this.numberMask() : null}
              maskChar={MASK_CHAR}
              name="number"
              onBlur={onBlur}
              onChange={onChange}
              permanents={this.permanentMaskChars()}
              placeholder={this.getPlaceholder()}
              type="tel"
              value={value}
            />
          )
        }}
      </BufferedFieldValue>
    )
  }
}

export default PhoneNumberInput
