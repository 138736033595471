import React, { MouseEvent } from 'react'
import classNames from 'classnames'
import { connect } from 'react-redux'

import {
  areSaveRequestsInFlight,
  canAdvanceToNextPanel,
  getCurrentKaseId,
  getCurrentPanel,
  getCurrentUserTrackingMetadata,
  isCurrentlyAtEnd,
  isCurrentlyAtSectionStart,
  isCurrentPanelSaveProgressInterstitial,
  isInReviewMode,
  isOnPublicChargeEstimatorSection,
  isOnSetupSection,
  isUserLoggedIn
} from 'reducers/selectors'

import {
  SECTION_NAME_SETUP,
  SETUP_OUTCOME_ELIGIBLE,
  TELEMETRY_EVENTS,
  PUBLIC_CHARGE_ESTIMATOR_RESULTS_PATH
} from 'lib/constants'
import { postTelemetry } from 'actions/telemetry_actions'
import { showPanel, showSection, showNextPanel } from 'actions/panel_actions'
import { showTimeline } from 'actions/navigation_actions'
import { joinI18nKeys } from 'lib/i18n'
import panelLocaleService from 'services/panel_locale_service'

import { externalUrl } from 'lib/settings'

import { PanelType } from 'components/forms/panel'
import ArrowIcon from 'components/icons/arrow_icon'
import Button from 'components/button'

interface Props {
  canAdvanceToNextPanel: boolean
  currentKaseId: number
  currentPanel: PanelType
  currentUserTrackingMetadata?: object
  floating?: 'bare' | 'normal'
  isAttached?: boolean
  isEditingIssue: boolean
  isInReviewMode: boolean
  isOnPublicChargeEstimatorSection: boolean
  isOnSaveProgressInterstitial: boolean
  isOnSectionEnd: boolean
  isOnSectionStart: boolean
  isOnSetupSection: boolean
  isTextShownOnMobile: boolean
  manualDisable?: boolean
  saveInProgress: boolean
  showNextPanel: Function
  showPanel: Function
  showSection: Function
  showTimeline: Function
  trackFinishedSetupAsEligible: Function
  userLoggedIn: boolean
}

interface State {
  loading: boolean
}

const launchSignInModal = () => {
  window.showSignInModal({
    successUrl: '/petition',
    via: 'SetupOutcome',
    isLogIn: false
  })
}

// TODO: Ideally this component would *NOT* have all this responsibility.
// - Particularly, it knows too much about which panel/section it's currently
// a part of, and it houses behavioral logic based on that knowledge. E.g.
// "if you are at the end of the PCE estimator section, navigate to www"
// "if you are on the save progress interstitial, launch the sign-in modal"
// "if you are on the setup/eligible screen, go to petition"
// "if you are on setup/start, post VWO telemetry to Heap"
//
// - Given the confines of our current design, one way to solve the problem is
// introducing more YML metadata that refers to hooks on the client, similar to
// the field_after_change hooks.
// - That's not a great solution, because it involves writing more YAML
// and creating more indirection that's hard for devs to parse.
// - Moving the YAML UI declarations to the client code (i.e. rewriting
// the declarations in JS) could enable us to make behavioral hooks that
// live outside this file.
//
// Story: https://www.pivotaltracker.com/story/show/173201893
class ContinueButton extends React.Component<Props, State> {
  state = {
    loading: false
  }

  continueButtonClicked = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault()

    if (this.props.isEditingIssue) {
      const issuesURL = `/applications/${this.props.currentKaseId}/questions`
      window.location.href = issuesURL

      return
    }

    // End of PC estimator
    if (this.props.isOnPublicChargeEstimatorSection && this.props.isOnSectionEnd) {
      this.setState({ loading: true })

      const resultsURL = PUBLIC_CHARGE_ESTIMATOR_RESULTS_PATH + this.props.currentKaseId
      window.location = externalUrl(resultsURL)

      return
    }

    // Review mode (this is likely deprecated as we don't show the
    // petition review screen anymore)
    if (this.props.isInReviewMode) {
      this.props.showPanel({
        slug: 'application-summary',
        via: 'ReviewConfirmButton'
      })

      return
    }

    // Save progress interstitial
    if (this.props.isOnSaveProgressInterstitial) {
      launchSignInModal()
      return
    }

    // Eligibility (final) screen of Boundless legacy setup
    if (this.isOnEligibleOutcome()) {
      const petitionURL = `/applications/${this.props.currentKaseId}/petition`
      window.location.href = petitionURL

      return
    }

    // End of any section
    if (this.props.isOnSectionEnd) {
      if (this.props.isOnSetupSection) {
        // NOTE - we use a dispatched action which calls redux-little-router
        // to "navigate" around when the user finishes setup.
        //
        // The advantage of this approach, rather than a window.open call, is
        // that some of the telemetry hooks we attach to panel navigation
        // (recording the last panel name, tracking email leads etc.) fire
        // for free!
        //
        // TODO: After the RV redirect exp is done, consider if we want to
        // make this behavior default (i.e. we perform "client-side" nav to the
        // timeline regardless of section).

        this.props.trackFinishedSetupAsEligible()
        this.setState({ loading: true })

        // Use a timeout to "fake" page loading -- UX request
        setTimeout(() => this.props.showTimeline(), 2500)
      } else {
        this.props.showTimeline()
      }

      return
    }

    // Default behavior -- go to next panel
    this.props.showNextPanel({ via: 'ContinueButton' })
  }

  continueLinkClicked = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault()
    this.props.showNextPanel({ via: 'ContinueLink' })
  }

  icon(mobile: boolean = false) {
    if (this.props.saveInProgress || this.state.loading) {
      return (
        <div className="c-icon spinner">
          <div className="spinner__circle" />
        </div>
      )
    } else {
      return <ArrowIcon arrowDirection={mobile ? 'right' : 'down'} />
    }
  }

  // ContinueLink only shows up in save progress interstitials. On these
  // interstitials, the main Continue button invokes the registration button
  // and we add an extra link for users to continue if they choose to.
  continueLink(desktop: boolean = false) {
    if (!this.props.isOnSaveProgressInterstitial) return

    let typeClasses = 'o-box c-type c-type--body-sans-sm'

    if (desktop) {
      typeClasses += ' o-layout--center'
    }

    return (
      <p className={typeClasses}>
        <a className="c-sheet__action c-link js-continue-link" onClick={this.continueLinkClicked} href="#">
          {this.linkText()}
        </a>
      </p>
    )
  }

  text() {
    if (this.props.isEditingIssue) {
      return 'Save & Return to Issues'
    }

    if (this.props.isInReviewMode) {
      return 'Save & Confirm'
    }

    if (this.props.isOnPublicChargeEstimatorSection && this.props.isOnSectionEnd) {
      if (!this.state.loading) {
        return 'See My Results'
      } else {
        return 'Loading Results'
      }
    }

    const continueKey =
      this.props.currentPanel.panel_keys && joinI18nKeys(this.props.currentPanel.panel_keys, 'continue_label')
    if (panelLocaleService.exists(continueKey)) {
      return panelLocaleService.t(continueKey)
    }

    return 'Continue'
  }

  linkText() {
    return 'Or continue application'
  }

  isDisabled() {
    return !this.props.canAdvanceToNextPanel || this.state.loading || this.props.manualDisable
  }

  isOnEligibleOutcome() {
    return this.props.currentPanel.outcome === 'eligible'
  }

  actionContainerClasses(mobile) {
    const { isAttached, floating } = this.props

    return classNames('o-layout c-sheet__action-container c-sheet__action-container--primary', {
      'c-sheet__action-container--floating': floating === 'normal',
      'c-sheet__action-container--floating-bare': floating === 'bare',
      'c-sheet__action-container--attached': isAttached,
      'o-layout--right': mobile,
      'o-layout--vertical-bottom': !mobile && !floating,
      'o-layout--vertical-center': !mobile && floating
    })
  }

  getColor() {
    if (this.props.isEditingIssue) return 'emphasized'
    return 'primary'
  }

  getArrowDirection() {
    if (this.props.isEditingIssue) return 'right'
    return 'down'
  }

  getContinueBtnId() {
    let continueBtnId = 'continue-button'
    const currentPanel = this.props.currentPanel

    if (currentPanel && currentPanel.section_name && currentPanel.name) {
      continueBtnId = `continue-button-${currentPanel.section_name}-${currentPanel.name}`
    }

    return continueBtnId
  }

  renderMobile() {
    return (
      <div className={this.actionContainerClasses(true) + ' u-hide@md-up'}>
        <div className="o-block">
          <Button
            id={this.getContinueBtnId()}
            data-qa="continue-button"
            data-cy="continue-button"
            color={this.getColor()}
            className="c-sheet__action js-continue-button o-block o-block--compact"
            icon={<ArrowIcon arrowDirection="right" aria-hidden="true" />}
            iconOnly={!this.props.isTextShownOnMobile}
            disabled={this.isDisabled()}
            isLoading={this.props.saveInProgress || this.state.loading}
            label={this.props.isTextShownOnMobile && this.text()}
            onClick={this.continueButtonClicked}
          />
          {this.continueLink()}
        </div>
      </div>
    )
  }

  renderDesktop() {
    return (
      <div className={this.actionContainerClasses(false) + ' u-hide@sm-down'}>
        <div className="o-layout--block-center o-layout--sticky">
          <Button
            id={this.getContinueBtnId()}
            data-cy="continue-button"
            className="c-sheet__action js-continue-button o-block o-block--compact"
            color={this.getColor()}
            disabled={this.isDisabled()}
            onClick={this.continueButtonClicked}
            label={this.text()}
            icon={<ArrowIcon arrowDirection={this.getArrowDirection()} aria-hidden="true" />}
            isLoading={this.props.saveInProgress || this.state.loading}
          />
          {this.continueLink(true)}
        </div>
      </div>
    )
  }

  render() {
    return (
      <React.Fragment>
        {this.renderDesktop()}
        {this.renderMobile()}
      </React.Fragment>
    )
  }
}

const getIsEditingIssue = () => {
  const params = new URLSearchParams(window.location.search.substring(1))
  return !!params.get('hasIssue')
}

function mapStateToProps(state) {
  return {
    canAdvanceToNextPanel: canAdvanceToNextPanel(state),
    currentKaseId: getCurrentKaseId(state),
    currentPanel: getCurrentPanel(state),
    currentUserTrackingMetadata: getCurrentUserTrackingMetadata(state),
    isEditingIssue: getIsEditingIssue(),
    isInReviewMode: isInReviewMode(state),
    isOnPublicChargeEstimatorSection: isOnPublicChargeEstimatorSection(state),
    isOnSaveProgressInterstitial: isCurrentPanelSaveProgressInterstitial(state),
    isOnSectionEnd: isCurrentlyAtEnd(state),
    isOnSectionStart: isCurrentlyAtSectionStart(state),
    isOnSetupSection: isOnSetupSection(state),
    saveInProgress: areSaveRequestsInFlight(state),
    userLoggedIn: isUserLoggedIn(state)
  }
}

function mapDispatchToActions(dispatch) {
  return {
    showNextPanel: (...args) => dispatch(showNextPanel(...args)),
    showPanel: (...args) => dispatch(showPanel(...args)),
    showSection: (...args) => dispatch(showSection(...args)),
    showTimeline: () => dispatch(showTimeline({ scrollTop: true })),
    trackFinishedSetupAsEligible: () =>
      dispatch(
        postTelemetry(TELEMETRY_EVENTS.FINISHED_SECTION, {
          SectionName: SECTION_NAME_SETUP,
          Status: SETUP_OUTCOME_ELIGIBLE,
          ErrorCount: 0
        })
      )
  }
}

export default connect(mapStateToProps, mapDispatchToActions)(ContinueButton)
