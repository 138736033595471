import React, { Component } from 'react'

import SetupCompletedActionButton from 'components/panels/outcomes/setup_completed_action_button'
import Modal from '../modal'

interface Props {
  isActive: boolean
}

export default class NaturalizationValuePropsModal extends Component<Props> {
  render() {
    return (
      <Modal isActive={this.props.isActive} title="How Boundless Helps You">
        {() => (
          <div>
            <p className="o-block o-block--ample c-type c-type--body-sans-md">
              We&rsquo;ll guide you through simple questions that will help you complete the N-400 and other government
              forms for naturalization.
            </p>

            <p className="o-block o-block--ample c-type c-type--body-sans-md">
              An independent immigration attorney will then review all of your materials and answer any questions you
              have&#8239;&mdash;&thinsp;for no additional fee!
            </p>

            <p className="o-block o-block--ample c-type c-type--body-sans-md">
              In the end, you&rsquo;ll receive a ready-to-file application package at your doorstep.
            </p>

            <div className="o-block o-layout o-layout--center">
              <SetupCompletedActionButton btnClass="c-btn--block" btnText="Continue Application" />
            </div>
          </div>
        )}
      </Modal>
    )
  }
}
