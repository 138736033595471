import React, { FunctionComponent, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { push } from 'redux-little-router'

import {
  getCurrentKaseId,
  getCurrentDocumentRequestId,
  getCurrentDocRequestIndexV2,
  getCurrentDocSetIndex,
  getCurrentDocRequest,
  getDocumentSets,
  getNextDocRequestId,
  getPrevDocRequestId,
  slaIsSet
} from 'reducers/selectors'
import {
  fetchDocumentSets,
  updateCurrentDocumentRequest,
  getNextDocumentRequest,
  getPreviousDocumentRequest,
  updateCurrentDocumentRequestFromId
} from 'actions/document_request_actions_v2'
import AdditionalInformation from 'components/screens/document_upload_v2/additional_information'
import ArrowIcon from 'components/icons/arrow_icon'
import BreakpointWrapper from 'components/breakpoint_wrapper'
import Button from 'components/button'
import DocumentUploadRequestContainer from './request_container'
import Outline from 'components/screens/document_upload_v2/outline'

import MobileDocumentDetails from './mobile_document_details'
import MobileDocUploadArea from 'components/screens/document_upload_v2/mobile_doc_upload_area'
import MobileDocNavigation from './mobile_doc_navigation'
import MobileOutline from 'components/screens/document_upload_v2/mobile_outline'

import { smoothScrollToTopOfElement } from 'lib/ui_helpers'
import { DocumentRequestModelV2, DocumentSet } from 'reducers/documents_v2'

interface MappedProps {
  currentDocRequestIdParam?: string
  currentDocSetIndex: number
  currentDocRequestIndex: number
  currentDocRequest: DocumentRequestModelV2
  documentSets: DocumentSet[]
  kaseId: number
  nextDocRequestId: number
  prevDocRequestId: number
  slaIsSet: boolean
}

type NavigationVia = 'Outline' | 'ArrowNavigation' | null

interface ActionProps {
  setDocumentRequestRoute: (newRoute: string, via: NavigationVia) => void
  updateCurrentDocumentRequest: (docSetIndex: number, docRequestIndex: number) => void
  fetchDocumentSets: (kase_id: number) => void
  getNextDocumentRequest: () => void
  getPreviousDocumentRequest: () => void
  updateCurrentDocumentRequestFromId: (docRequestId: number) => void
}

const DocumentUploadV2: FunctionComponent<MappedProps & ActionProps> = ({
  currentDocRequestIdParam,
  currentDocRequestIndex,
  currentDocSetIndex,
  currentDocRequest,
  documentSets,
  fetchDocumentSets,
  kaseId,
  nextDocRequestId,
  prevDocRequestId,
  setDocumentRequestRoute,
  slaIsSet,
  updateCurrentDocumentRequest,
  updateCurrentDocumentRequestFromId
}) => {
  const [currentNavigationVia, setCurrentNavigationVia] = useState(null)
  const [showOutlineModal, setShowOutlineModal] = useState(false)
  const [showTips, setShowTips] = useState(false)

  useEffect(() => {
    fetchDocumentSets(kaseId).then(() => {
      if (currentDocRequestIdParam !== undefined) {
        updateCurrentDocumentRequestFromId(parseInt(currentDocRequestIdParam))
      }
    })
  }, [])

  useEffect(() => {
    updateCurrentDocumentRequestFromId(parseInt(currentDocRequestIdParam))
  }, [currentDocRequestIdParam])

  const closeOutlineModal = () => {
    setShowOutlineModal(false)
  }

  const showTheOutlineModal = () => {
    setShowOutlineModal(true)
  }

  const onOutlineRequestClicked = (docSetIndex: number, docRequestIndex: number) => {
    const currentlyViewedId = documentSets[docSetIndex].document_requests[docRequestIndex].id

    updateCurrentDocumentRequest(docSetIndex, docRequestIndex)
    setDocumentRequestRoute(`/applications/${kaseId}/document_upload/${currentlyViewedId}`, currentNavigationVia)
    setShowOutlineModal(false)
  }

  const showPreviousDocRequest = () => {
    const currentlyViewedId = prevDocRequestId

    setCurrentNavigationVia('ArrowNavigation')
    setDocumentRequestRoute(`/applications/${kaseId}/document_upload/${currentlyViewedId}`, currentNavigationVia)
  }

  const showNextDocRequest = () => {
    const currentlyViewedId = nextDocRequestId

    setCurrentNavigationVia('ArrowNavigation')
    setDocumentRequestRoute(`/applications/${kaseId}/document_upload/${currentlyViewedId}`, currentNavigationVia)
  }

  const toggleTips = (event) => {
    event.preventDefault()

    // Scroll to the top of the tooltip container
    if (!showTips) {
      smoothScrollToTopOfElement(document.getElementById('tips-for-success-anchor'))
    }

    setShowTips(!showTips)
  }

  return (
    <div className="o-action-items__wrapper">
      <BreakpointWrapper mobile>
        <div style={{ minHeight: '90vh' }}>
          <MobileDocumentDetails documentRequest={currentDocRequest} slaIsSet={slaIsSet} />
          <MobileDocUploadArea documentRequest={currentDocRequest} />
        </div>
        <MobileOutline
          closeModal={closeOutlineModal}
          isActive={showOutlineModal}
          onDocRequestClick={onOutlineRequestClicked}
          slaIsSet={slaIsSet}
        />
        <MobileDocNavigation
          currentDocRequestIndex={currentDocRequestIndex}
          currentDocSetIndex={currentDocSetIndex}
          documentSets={documentSets}
          showPreviousDocRequest={showPreviousDocRequest}
          showNextDocRequest={showNextDocRequest}
          showOutlineModal={showTheOutlineModal}
        />
      </BreakpointWrapper>

      <BreakpointWrapper desktop>
        <div className="o-griddle__row o-griddle__row--no-gutters">
          <aside className="o-griddle__col--3">
            <Outline onClick={onOutlineRequestClicked} activeRequest={currentDocRequest} />
          </aside>
          <main className="o-griddle__col--6">
            <div className="o-action-items__center-column">
              <div className="o-griddle__col o-griddle__col--auto">
                <Button
                  className="block o-layout--block-center"
                  color="secondary"
                  icon={<ArrowIcon arrowDirection="up" />}
                  disabled={currentDocSetIndex === 0 && currentDocRequestIndex === 0}
                  iconOnly
                  onClick={showPreviousDocRequest}
                  title="View the previous document"
                />
              </div>

              <DocumentUploadRequestContainer
                documentRequest={currentDocRequest}
                toggleTipsForSuccess={toggleTips}
                slaIsSet={slaIsSet}
              />

              <div className="o-layout--block-center">
                <Button
                  className="block o-layout--block-center mb-6"
                  color="secondary"
                  disabled={
                    documentSets.length > 0 &&
                    currentDocSetIndex >= documentSets.length - 1 &&
                    currentDocRequestIndex >= documentSets[currentDocSetIndex].document_requests.length - 1
                  }
                  icon={<ArrowIcon arrowDirection="down" />}
                  iconOnly
                  onClick={showNextDocRequest}
                  title="View the next document request"
                  style={{ backgroundColor: 'white' }}
                />
              </div>
            </div>
          </main>
          <aside className="o-griddle__col o-griddle__col--3 px-4 pt-8 lg:px-6">
            <div className="ml-3">
              <AdditionalInformation showTips={showTips} />
            </div>
          </aside>
        </div>
      </BreakpointWrapper>
    </div>
  )
}

function mapStateToProps(state: any): MappedProps {
  return {
    currentDocRequestIndex: getCurrentDocRequestIndexV2(state),
    currentDocSetIndex: getCurrentDocSetIndex(state),
    currentDocRequest: getCurrentDocRequest(state),
    currentDocRequestIdParam: getCurrentDocumentRequestId(state),
    documentSets: getDocumentSets(state),
    kaseId: getCurrentKaseId(state),
    nextDocRequestId: getNextDocRequestId(state),
    prevDocRequestId: getPrevDocRequestId(state),
    slaIsSet: slaIsSet(state)
  }
}

function mapDispatchToActions(dispatch): ActionProps {
  return {
    setDocumentRequestRoute: (newRoute: string, via: NavigationVia) => {
      const action = push(newRoute)
      action.payload.via = via

      dispatch(action)
    },
    updateCurrentDocumentRequest: (docSetIndex: number, docRequestIndex: number) => {
      return dispatch(updateCurrentDocumentRequest(docSetIndex, docRequestIndex))
    },
    fetchDocumentSets: (kase_id: number) => dispatch(fetchDocumentSets(kase_id)),
    getNextDocumentRequest: () => dispatch(getNextDocumentRequest()),
    getPreviousDocumentRequest: () => dispatch(getPreviousDocumentRequest()),
    updateCurrentDocumentRequestFromId: (docRequestId: number) =>
      dispatch(updateCurrentDocumentRequestFromId(docRequestId))
  }
}

export default connect(mapStateToProps, mapDispatchToActions)(DocumentUploadV2) as FunctionComponent
