import React, { FunctionComponent, useState } from 'react'
import cx from 'classnames'

import CommonSelectV2 from 'components/forms/inputs/v2/address/common/common_select_v2'
import CommonRadiosV2 from 'components/forms/inputs/v2/address/common/common_radios_v2'

import { QuestionnaireQuestion } from '../lib/types'

interface Props {
  /**
   * The default value for the input component
   */
  defaultValue?: string
  /**
   * The unique key for the input
   */
  inputName: string
  /**
   * Tells us if the user is accessing the website from a mobile sized browser
   */
  isMobile?: boolean
  /**
   * The label of the question
   */
  label: string
  /**
   * The callback function for when an input value changes
   * Question is optional, it comes from the backend. If not provided, we assume
   * it's a age_of_applying_child question we construct on the frontend
   */
  onChange: (event: React.ChangeEvent, question?: QuestionnaireQuestion) => void
  /**
   * The input options
   */
  options?: {
    label: string
    value: string
  }[]
  /**
   * Question required to answer to determine exact USCIS fees. If provided, it is a question
   * that comes from the backend. Otherwise we assume it's a age_of_applying_child question we
   * construct on the frontend
   */
  question?: QuestionnaireQuestion
  /**
   * The type of question input
   */
  type: string
}

const CartQuestion: FunctionComponent<Props> = ({
  defaultValue,
  inputName,
  isMobile,
  label,
  onChange,
  options,
  question,
  type
}) => {
  const [inputValue, setInputValue] = useState(defaultValue ? defaultValue : '')

  const updateInput = (event: React.ChangeEvent) => {
    const target = event.target as HTMLInputElement
    const newInputValue = target.value
    if (newInputValue !== inputValue) {
      setInputValue(newInputValue)
      onChange(event, question)
    }
  }

  const inputComponent = () => {
    switch (type) {
      case 'select': {
        const formattedOptions = { '': 'Select an option' }
        question.attributes.input_options.forEach((option) => {
          formattedOptions[option.key] = option.value
        })
        return (
          <CommonSelectV2
            inputName={inputName}
            value={inputValue}
            options={{
              ...formattedOptions
            }}
            containerClass="c-paper-form__control c-type--body-sans-sm text-gray-500"
            onChangeEvent={updateInput}
            onBlur={updateInput}
            size="small"
          />
        )
      }
      case 'radio':
        return (
          <CommonRadiosV2
            inputName={inputName}
            value={inputValue}
            options={options}
            onChangeEvent={updateInput}
            size="small"
          />
        )

      default:
        return <></>
    }
  }

  return (
    <div className={cx('border border-default flex justify-between p-4', { 'flex-col': isMobile })}>
      <div
        className={cx('c-type c-type--body-sans-sm flex-grow items-center', {
          'flex pr-1 flex-basis-0': !isMobile
        })}
      >
        {label}
      </div>
      <div
        className={cx('flex flex-grow items-center', {
          'mt-3 justify-start': isMobile,
          'flex-basis-0 justify-evenly': !isMobile
        })}
      >
        {inputComponent()}
      </div>
    </div>
  )
}

export default CartQuestion
