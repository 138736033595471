import React, { FunctionComponent } from 'react'
import IconWrapper, { IconWrapperExternalProps } from './icon_wrapper'
import classNames from 'classnames'

export type ArrowIconDirection = 'up' | 'right' | 'down' | 'left'

type Props = IconWrapperExternalProps & {
  arrowDirection?: ArrowIconDirection
}

const ArrowCicleIcon: FunctionComponent<Props> = (props) => {
  const { arrowDirection, ...otherProps } = props

  const classes = classNames(props.className, {
    'c-icon--direction-up': arrowDirection === 'up',
    'c-icon--direction-right': arrowDirection === 'right',
    'c-icon--direction-left': arrowDirection === 'left'
  })
  return (
    <IconWrapper {...otherProps} viewBox="0 0 22 22" fill="none" className={classes}>
      <circle cx="11" cy="11" r="11" transform="rotate(90 11 11)" fill="#FA5E50" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.25 11.4595L5.66473 10.0447L9.99981 14.3798L9.99981 3.79016L12.0005 3.79016L12.0005 14.3795L16.3353 10.0447L17.75 11.4595L11 18.2095L4.25 11.4595Z"
        fill="white"
      />
      <mask id="mask0_592_11844" maskUnits="userSpaceOnUse" x="4" y="3" width="14" height="16">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.25 11.4595L5.66473 10.0447L9.99981 14.3798L9.99981 3.79016L12.0005 3.79016L12.0005 14.3795L16.3353 10.0447L17.75 11.4595L11 18.2095L4.25 11.4595Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_592_11844)"></g>
    </IconWrapper>
  )
}

ArrowCicleIcon.defaultProps = {
  arrowDirection: 'down'
}

export default ArrowCicleIcon
