import React from 'react'

import _capitalize from 'lodash/capitalize'
import _compact from 'lodash/compact'
import _some from 'lodash/some'

import formatDate from '../lib/format_date'
import formatMoney from '../lib/format_money'
import { formatPhone } from '../lib/format_phone'
import { COUNTRIES } from '../data/countries'

const renderAddress = (address, prepend) => {
  const unitLine = address.unit_type && address.unit_number ? `${address.unit_type} ${address.unit_number}` : null

  let cityLine = null

  if (address.city) {
    cityLine = address.city

    if (address.province) {
      cityLine += `, ${address.province}`

      if (address.postal_code) {
        cityLine += `, ${address.postal_code}`
      }
    }
  }

  const hasData = _some([cityLine, unitLine, address.country.code])

  if (!hasData) return null

  return (
    <div>
      {prepend && prepend()}
      {address.street}
      {address.street && <br />}
      {unitLine}
      {unitLine && <br />}
      {cityLine}
      {cityLine && <br />}
      {COUNTRIES[address.country.code]}
    </div>
  )
}

const renderCitizenshipPath = (model) => _capitalize(model.via)

const renderCountry = (country) => COUNTRIES[country.code]

const renderDateOrPresent = (dateOrPresent) =>
  dateOrPresent.present_day ? 'present' : formatDate.reviewScreen(dateOrPresent.date)

const renderEmployment = (employment) => {
  if (employment.unemployed) {
    return 'Unemployed'
  }

  const hasData = _some([employment.name, employment.occupation])

  if (!hasData) return null

  return renderAddress(employment.address, () => (
    <div>
      {employment.occupation}
      {employment.occupation && <br />}

      {employment.name}
      {employment.name && <br />}
    </div>
  ))
}

const renderImmigrationProceeding = (model) => {
  if (!model.kind) return null

  const renderDateAndKind = () => (
    <div>
      {_capitalize(model.kind)} proceedings
      <br />
      {formatDate.reviewScreen(model.date)}
    </div>
  )

  if (model.address.city) {
    return renderAddress(model.address, renderDateAndKind)
  } else {
    return renderDateAndKind()
  }
}

const renderLegalStatus = (legalStatus) => {
  switch (legalStatus.code) {
    case 'usc': {
      return 'U.S. Citizen'
    }

    case 'lpr': {
      return 'U.S. Green Card Holder'
    }

    case 'other':
    default: {
      return 'Unspecified'
    }
  }
}

const renderLprPath = (lprPath) => _capitalize(lprPath.via)

const renderMoney = (money) => formatMoney(money.cents)

const renderPersonName = (name) => _compact([name.first_name, name.middle_name, name.last_name]).join(' ')

const renderPreviousPetition = (previousPetition) => {
  const renderOtherInfo = () => (
    <div>
      {renderPersonName(previousPetition.name)}
      <br />
      {formatDate.reviewScreen(previousPetition.date_of_filing)}
      <br />
      Petition {previousPetition.result}
    </div>
  )

  if (previousPetition.address_at_filing_time.city) {
    return renderAddress(previousPetition.address_at_filing_time, renderOtherInfo)
  } else {
    return renderOtherInfo()
  }
}

export const renderKaseModel = (kaseModel: any) => {
  switch (kaseModel.type) {
    case 'Address': {
      return renderAddress(kaseModel)
    }

    case 'CitizenshipPath': {
      return renderCitizenshipPath(kaseModel)
    }

    case 'Country': {
      return renderCountry(kaseModel)
    }

    case 'DateOrPresent': {
      return renderDateOrPresent(kaseModel)
    }

    case 'Employment': {
      return renderEmployment(kaseModel)
    }

    case 'ImmigrationProceeding': {
      return renderImmigrationProceeding(kaseModel)
    }

    case 'LegalStatus': {
      return renderLegalStatus(kaseModel)
    }

    case 'LprPath': {
      return renderLprPath(kaseModel)
    }

    case 'Money': {
      return renderMoney(kaseModel)
    }

    case 'PersonName': {
      return renderPersonName(kaseModel)
    }

    case 'Phone': {
      return formatPhone(kaseModel)
    }

    case 'PreviousPetition': {
      return renderPreviousPetition(kaseModel)
    }

    default: {
      throw new Error(`No model renderer defined for ${kaseModel.type}`)
    }
  }
}
