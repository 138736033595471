import validator from 'validator'

/**
 * Exports the validator.js library with a few added functions.
 * Documentation for the other included functions are here:
 * https://github.com/validatorjs/validator.js#validators
 */
const customValidators = {
  // Allows an empty masked field, ex `___-___-____`,
  // removes non-digits from value for validation
  isEmptyMaskedNumberInput: (value: string) => {
    return (value && value.replace(/\D/g, '') === '') || !value
  },

  isValidCurrency: (value: string) => {
    // If there is a single decimal in the value, and if its at
    // the end of the value, it strips it out before calling validator.isCurrency.
    // That way, '5.' will become '5' and will still return as a valid currency.
    if (!value.match(/\..*\./)) {
      value = value.replace(/\.$/, '')
    }

    // If there is only a single digit after the decimal, we add a trailing 0 before
    // calling validator.isCurrency.
    // That way, '5.3' will become '5.30' and will still return as a valid currency.
    if (value.match(/\.[0-9]$/)) {
      value += '0'
    }

    return validator.isCurrency(value)
  },

  // This allows the user to enter 0 for "positive currency" values
  // so that they aren't blocked if they do not have any income
  // see https://boundless-immigration.atlassian.net/browse/B20-934 for more info
  isPositiveCurrency: (value: string): boolean => {
    return customValidators.isValidCurrency(value) && parseFloat(value.replace(/\$/, '')) >= 0
  },

  isValidSSN: (value: string): boolean => {
    // Matches valid SSNs with or without `-`s ~ stolen from https://regex101.com/r/kdXrYe/1
    return !!value.match(/(?!(\d){3}(-| |)\1{2}\2\1{4})(?!666|000|9\d{2})(\b\d{3}(-| |)(?!00)\d{2}\4(?!0{4})\d{4}\b)/gm)
  }
}

export default {
  ...validator,
  ...customValidators
}
