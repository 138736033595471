import React, { FunctionComponent } from 'react'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'

import { deleteDocument, fetchDocumentSets } from 'actions/document_request_actions_v2'

import { PartContent, DocumentRequestModelV2 } from 'reducers/documents_v2'
import { getCurrentDocRequest } from 'reducers/selectors'
import DocumentRequestPart from 'components/screens/document_upload_v2/document_request_part'

interface UploadingDoc extends PartContent {
  progress: number
  rawFile: File
}

interface ExplicitProps {
  documentRequest: DocumentRequestModelV2
  kaseId: number
  uploads: UploadingDoc[]
}

interface ActionProps {
  deleteDocument: Function
  fetchDocumentSets: Function
}

interface MappedProps {
  currentDocRequest: DocumentRequestModelV2
}

type Props = ExplicitProps & ActionProps & MappedProps

const MobileListedDocuments: FunctionComponent<Props> = ({ currentDocRequest }) => {
  return (
    <ul className="c-list pb-6 border-t border-gray-200">
      {currentDocRequest.parts.map((documentRequestPart) => (
        <DocumentRequestPart
          key={documentRequestPart.id}
          documentRequest={currentDocRequest}
          documentRequestPart={documentRequestPart}
        />
      ))}
    </ul>
  )
}

function mapStateToProps(state: any): MappedProps {
  return {
    currentDocRequest: getCurrentDocRequest(state)
  }
}

function mapDispatchToActions(dispatch: Dispatch): ActionProps {
  return bindActionCreators(
    {
      deleteDocument,
      fetchDocumentSets
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToActions)(MobileListedDocuments)
