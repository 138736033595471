import React, { FunctionComponent, ReactNode } from 'react'
import cx from 'classnames'
import Paragraph from 'components/type/paragraph'

interface Props {
  title?: ReactNode
  variant?: 'primary' | 'success' | 'warning' | 'danger'
}

const Hint: FunctionComponent<Props> = ({ title, children, variant }) => {
  const classes = cx('c-hint o-block o-box', {
    [`c-hint--${variant}`]: !!variant
  })

  return (
    <div className={classes}>
      <div className="o-griddle__row o-griddle__row--no-gutters">
        {title && (
          <div className="o-griddle__col--12 o-griddle__col--auto-sm-up">
            <Paragraph className="c-hint__title" bold>
              {title}
            </Paragraph>
          </div>
        )}
        <div className="o-griddle__col">{children}</div>
      </div>
    </div>
  )
}

export default Hint
