import React, { FunctionComponent, useMemo } from 'react'
import { connect } from 'react-redux'
import _every from 'lodash/every'

import { openModal } from 'actions/modal_actions'
import {
  getCurrentKaseId,
  getCurrentKaseKind,
  getCurrentKaseLawyer,
  getNavigableSectionsForCustomerDashboard
} from 'reducers/selectors'
import SectionItem from 'components/screens/dashboard/customer_dashboard/section_item'
import StartAssessmentPanel from 'components/screens/dashboard/customer_dashboard/phases/intake_phase/start_assessment_panel'
import StartFormCheckPanel from 'components/screens/dashboard/customer_dashboard/phases/intake_phase/start_form_check'
import { DASHBOARD_PHASE_INTAKE } from 'components/screens/dashboard/customer_dashboard/phase_map'

import { KaseKind } from 'lib/constants'

interface Props {
  sections: any[]
  kaseId: number
  kaseKind: KaseKind
  lawyerName?: string
  openConfirmationModal: () => void
}

const IntakePhase: FunctionComponent<Props> = ({ kaseId, kaseKind, lawyerName, sections, openConfirmationModal }) => {
  const allSectionsFinished = useMemo(() => {
    return _every(sections, (section) => section.progress === 100)
  }, [])

  return (
    <>
      <h2 className="o-block c-type c-type--headline-sm c-type--emphasized">Items to Complete</h2>
      <ul className="o-block c-list">
        {sections.map((section) => {
          const url = `/applications/${kaseId}/${section.name}`
          return (
            <SectionItem
              empty={section.empty}
              name={section.name}
              phaseName={DASHBOARD_PHASE_INTAKE}
              progress={section.progress}
              url={url}
              key={section.name}
              dashboard_meta={section.dashboard_meta}
            />
          )
        })}
      </ul>
      {kaseKind === KaseKind.NewMarriageBasedGreenCard ? (
        <StartFormCheckPanel allSectionsFinished={allSectionsFinished} openConfirmationModal={openConfirmationModal} />
      ) : (
        <StartAssessmentPanel
          allSectionsFinished={allSectionsFinished}
          openConfirmationModal={openConfirmationModal}
          lawyerName={lawyerName}
        />
      )}
    </>
  )
}

function mapDispatchToActions(dispatch) {
  return {
    openConfirmationModal: () =>
      dispatch(
        openModal({
          name: 'BeginLawyerReviewConfirmationModal',
          via: 'Begin Assessment button'
        })
      )
  }
}

function mapStateToProps(state) {
  return {
    kaseId: getCurrentKaseId(state),
    kaseKind: getCurrentKaseKind(state),
    sections: getNavigableSectionsForCustomerDashboard(state),
    lawyerName: getCurrentKaseLawyer(state)
  }
}

export default connect(mapStateToProps, mapDispatchToActions)(IntakePhase)
