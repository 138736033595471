import React from 'react'
import cx from 'classnames'

import getStore from 'stores/app_store'
import SignInNav from './sign_in_nav'

interface Props {
  isLogIn: boolean
  expired?: boolean
  successUrl?: string
  noHeader?: boolean
}

interface State {
  title: string
}

function headerTitle(isLogIn) {
  return isLogIn ? 'Log In' : 'Save Progress'
}

export default class InlineSignInNav extends React.Component<Props, State> {
  static defaultProps = {
    isLogIn: true,
    expired: false
  }

  constructor(props: Props) {
    super(props)

    this.state = { title: headerTitle(props.isLogIn) }
  }

  onViewChange = (isLogIn: boolean): void => this.setState({ title: headerTitle(isLogIn) })

  render() {
    return (
      <div className="o-grid__row text-left">
        <div className={cx('o-grid__col-5', { 'o-grid__col--offset-3': !this.props.noHeader })}>
          <div className={cx('o-box o-block', { 'o-box--spacious': !this.props.noHeader })}>
            {!this.props.noHeader && (
              <h1 className="o-block c-type c-type--headline-sm c-type--emphasized">{this.state.title}</h1>
            )}
            {this.props.expired && this.props.isLogIn && (
              <div className="o-block c-type c-type--body-sans-md">
                Apologies, you have been logged out due to inactivity. Please log back in.
              </div>
            )}

            <SignInNav
              isLogIn={this.props.isLogIn}
              onViewChange={this.onViewChange}
              successUrl={this.props.successUrl}
              store={getStore()}
              noHeader={this.props.noHeader}
            />
          </div>
        </div>
      </div>
    )
  }
}
