import React from 'react'
import { getAvailability } from './availability'

export interface ContactItemProps {
  contactType: ContactType
  href: string
  icon: JSX.Element
  text: string
  onClick?: (event: any) => void
}

export type ContactType = 'chat' | 'phone' | 'email'

export const ContactItem = ({ contactType, href, icon, text, onClick }: ContactItemProps) => {
  const { isAvailable, displayText } = getAvailability(contactType)

  return (
    <a href={href} className="contact-item o-box o-box--tight is-not-touching" onClick={onClick}>
      {icon}
      <span className="contact-item-title">{text}</span>

      <div className="contact-item-available c-type--emphasized">
        <div className={`contact-item-available-indicator ${isAvailable ? 'available' : ''}`} />
        {displayText}
      </div>
    </a>
  )
}
