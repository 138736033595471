import React from 'react'
import { connect } from 'react-redux'
import ReactMarkdown from 'react-markdown'

import { MarkdownLinkRenderer } from 'lib/markdown'

import { getCurrentKaseKindResourceKey, getCurrentSectionErrors, getResourceContext } from 'reducers/selectors'

import panelLocaleService from 'services/panel_locale_service'

function AskMyAttorney(props) {
  const { errors, resourceContext, resourceKey } = props
  const ama = [
    'failed_income_requirement',
    'beneficiary_criminal',
    'beneficiary_invalid_visa',
    'bene_in_us_sponsor_is_lpr'
  ]

  let showAma
  errors.forEach((error) => {
    const sponsor_legal_status_beneficiary =
      resourceContext.accountHolderRole === 'beneficiary' && error === 'sponsor_legal_status'
    showAma = ama.includes(error) || sponsor_legal_status_beneficiary
  })

  const mbgc = resourceKey === 'marriage_based_green_card'
  const amaText = `${resourceKey}.outcomes.ama_help.ama`

  if (!mbgc || !showAma) return null

  return (
    <div className="o-block c-type c-type--body-sans-md">
      <ReactMarkdown source={panelLocaleService.t(amaText)} renderers={{ Link: MarkdownLinkRenderer }} />
    </div>
  )
}

function mapStateToProps(state) {
  return {
    errors: getCurrentSectionErrors(state),
    resourceContext: getResourceContext(state),
    resourceKey: getCurrentKaseKindResourceKey(state)
  }
}

export default connect(mapStateToProps)(AskMyAttorney)
