import React from 'react'
import EligibilityReportInfo from 'components/panels/outcomes/setup_completed_eligibility_report_info'
import { connect } from 'react-redux'
import { isBeneficiaryNotInUS, sponsorIsCitizen } from 'reducers/selectors'
import HCCLink from 'components/panels/outcomes/setup_completed_hcc_link'
import Paragraph from 'components/type/paragraph'

interface Props {
  screen: string
  beneficiaryNotInUS: boolean
  sponsorIsCitizen: boolean
}

class EligibilityReport extends React.Component<Props> {
  renderMessage() {
    const sponsorLegalStatus = this.props.sponsorIsCitizen ? 'U.S. Citizen' : 'U.S. Permanent Resident'
    const beneficiaryResidence = this.props.beneficiaryNotInUS ? 'outside' : 'in'

    return (
      <React.Fragment>
        Green card for the spouse of a <strong className="c-type--bold c-type--inline">{sponsorLegalStatus}</strong>,
        currently residing{' '}
        <strong className="c-type--bold c-type--inline">{beneficiaryResidence} the United States</strong>.
      </React.Fragment>
    )
  }

  renderDesktop() {
    return (
      <React.Fragment>
        <div className="o-grid--fluid o-block o-block--ample">
          <div className="o-grid__cell--3/12">
            <h1 className="c-type c-type--headline-md c-type--emphasized">Congrats!</h1>
            <Paragraph color="emphasized" className="mt-8" bold>
              You’re eligible to apply with the fastest and best green card service
            </Paragraph>
          </div>
          <div className="o-grid__cell--9/12 c-type c-type--body-serif-md c-type--emphasized">
            {this.renderMessage()}
          </div>
        </div>
        <EligibilityReportInfo screen={this.props.screen} />
        <div className="o-grid--fluid o-block o-block--ample">
          <div className="o-grid__cell--3/12" />
          <div className="o-grid__cell--9/12 o-grid--fluid o-grid--fluid--trailed">
            <div className="o-box o-box--light o-box--dense">
              <div className="o-layout o-layout--padded-x--minuscule c-type c-type--subhead-sm">Read More</div>
            </div>
          </div>
        </div>
        <div className="o-grid--fluid">
          <div className="o-grid__cell--3/12" />
          <div className="o-grid__cell--4/12 c-type c-type--body-serif-sm o-layout o-layout--left">
            <ol className="c-list">
              <li className="c-list__item c-list__item--light">
                <HCCLink href="/content/mbgc-explained" linkText="Marriage-based green cards, explained" icon="arrow" />
              </li>
              <li className="c-list__item c-list__item--light">
                <HCCLink href="/content/docs-needed-mbgc" linkText="What documents do I need?" icon="arrow" />
              </li>
              <li className="c-list__item c-list__item--light">
                <HCCLink href="/content/income-reqs-mbgc" linkText="What are the income requirements?" icon="arrow" />
              </li>
            </ol>
          </div>
          <div className="o-grid__cell--1/12" />
          <div className="o-grid__cell--4/12 c-type c-type--body-serif-sm o-layout o-layout--left">
            <ol className="c-list">
              <li className="c-list__item c-list__item--light">
                <HCCLink
                  href="/content/what-you-get-with-boundless"
                  linkText="What you get with Boundless"
                  icon="arrow"
                />
              </li>
              <li className="c-list__item c-list__item--light">
                <HCCLink
                  href="/content/attorneys"
                  linkText="Meet the independent attorneys included in our network"
                  icon="arrow"
                />
              </li>
            </ol>
          </div>
        </div>
      </React.Fragment>
    )
  }

  renderMobile() {
    return (
      <React.Fragment>
        <div className="o-grid--fluid o-block o-block--ample">
          <div className="o-grid__cell--12/12">
            <h1 className="c-type c-type--headline-md c-type--emphasized">Congrats!</h1>
            <Paragraph color="emphasized" className="mt-8" bold>
              You’re eligible to apply with the fastest and best green card service
            </Paragraph>
          </div>
        </div>
        <div className="o-grid--fluid o-block o-block--ample">
          <div className="o-grid__cell--12/12 c-type c-type--body-serif-sm c-type--emphasized o-layout o-layout--left">
            {this.renderMessage()}
          </div>
        </div>
        <div className="o-grid--fluid">
          <EligibilityReportInfo screen={this.props.screen} />
          <div className="o-grid__cell">
            <div className="o-block o-block--compact">
              <div className="o-grid--fluid o-grid--fluid--trailed">
                <div className="o-box o-box--light o-box--dense">
                  <div className="o-layout o-layout--padded-x--minuscule c-type c-type--subhead-sm">Read More</div>
                </div>
              </div>
            </div>
            <div className="o-block o-block--ample c-type c-type--headline-sm c-type--emphasized o-layout o-layout--left">
              <ol className="c-list">
                <li className="c-list__item c-list__item--light">
                  <HCCLink href="/content/mbgc-explained" linkText="Green Cards" icon="arrow" />
                </li>
                <li className="c-list__item c-list__item--light">
                  <HCCLink href="/content/docs-needed-mbgc" linkText="Documents" icon="arrow" />
                </li>
                <li className="c-list__item c-list__item--light">
                  <HCCLink href="/content/income-reqs-mbgc" linkText="Income" icon="arrow" />
                </li>
                <li className="c-list__item c-list__item--light">
                  <HCCLink href="/content/what-you-get-with-boundless" linkText="Why Trust Us" icon="arrow" />
                </li>
                <li className="c-list__item c-list__item--light">
                  <HCCLink href="/content/attorneys" linkText="Attorneys" icon="arrow" />
                </li>
              </ol>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }

  render() {
    if (this.props.screen === 'desktop') {
      return this.renderDesktop()
    } else {
      return this.renderMobile()
    }
  }
}

function mapStateToProps(state) {
  return {
    beneficiaryNotInUS: isBeneficiaryNotInUS(state),
    sponsorIsCitizen: sponsorIsCitizen(state)
  }
}

export default connect(mapStateToProps, null)(EligibilityReport)
