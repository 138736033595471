import $ from 'jquery'
import modernizr from 'modernizr'

// Fix hover behavior on touch enabled devices, so that hover styles end when
// the user's finger lifts from the screen.
export function fixHoverOnTouchDevices() {
  if (modernizr.touchevents) {
    document.addEventListener('mouseover', function (e) {
      $(e.target).parentsUntil('body').add(e.target).removeClass('is-not-touching')
    })

    document.addEventListener('mouseup', function (e) {
      $(e.target).parentsUntil('body').add(e.target).addClass('is-not-touching')
    })
  }
}
