import u from 'updeep'
import _omit from 'lodash/omit'

import {
  ATTORNEY_AGREEMENT_ACCEPTED,
  LOAD_REFERRAL_STATE,
  LOAD_CURRENT_USER,
  LOAD_KASE,
  LOAD_RESOURCE_CONTEXT,
  TRANSITION_CURRENT_PANEL_OUT,
  TRANSITION_NEW_PANEL_IN,
  TRANSITION_NEW_PANEL_COMPLETE,
  UPDATE_WORKFLOW_STATUS,
  SET_IS_PAID
} from 'lib/constants'

const initialState = {
  currentUser: null,
  kase: null,
  panelAnimationState: null,
  resourceContext: null,
  transitionDirection: null
}

export default function applicationReducer(state, action) {
  state = state || initialState

  switch (action.type) {
    case LOAD_KASE: {
      return u(
        {
          kase: _omit(action.kase, 'data')
        },
        state
      )
    }
    case UPDATE_WORKFLOW_STATUS: {
      return u(
        {
          kase: {
            workflow_status: action.newWorkflowStatus
          }
        },
        state
      )
    }
    case LOAD_CURRENT_USER: {
      return u(
        {
          currentUser: action.currentUser
        },
        state
      )
    }
    case LOAD_RESOURCE_CONTEXT: {
      return u(
        {
          resourceContext: action.resourceContext
        },
        state
      )
    }
    case LOAD_REFERRAL_STATE: {
      return u(
        {
          referral: action.referral
        },
        state
      )
    }
    case ATTORNEY_AGREEMENT_ACCEPTED: {
      return u(
        {
          currentUser: {
            accepted_attorney_agreement: true
          },
          attorneyAgreementAccepted: true
        },
        state
      )
    }
    case TRANSITION_CURRENT_PANEL_OUT: {
      const { direction } = action

      return u(
        {
          panelAnimationState: action.type,
          transitionDirection: direction
        },
        state
      )
    }
    case TRANSITION_NEW_PANEL_IN: {
      return u(
        {
          panelAnimationState: action.type
        },
        state
      )
    }
    case TRANSITION_NEW_PANEL_COMPLETE: {
      return u(
        {
          panelAnimationState: null,
          transitionDirection: null
        },
        state
      )
    }
    case SET_IS_PAID: {
      return u(
        {
          kase: {
            paid: action.payload
          }
        },
        state
      )
    }
    default:
      return state
  }
}
