import _includes from 'lodash/includes'

import { getCurrentPanelName, getCurrentSectionName } from 'reducers/selectors'
import { OPEN_CHAT, SECTION_NAME_SETUP, SECTION_NAME_PETITION, TELEMETRY_EVENTS } from 'lib/constants'
import getTelemetryService from 'lib/telemetry'

function isChatDisabled() {
  return typeof window.FrontChat !== 'function'
}

function currentPanelName(state) {
  const panelExists = _includes([SECTION_NAME_SETUP, SECTION_NAME_PETITION], getCurrentSectionName(state))

  if (panelExists) {
    return getCurrentPanelName(state)
  } else {
    return null
  }
}

function openChatTool({ via, questionName }) {
  if (isChatDisabled()) return

  getTelemetryService().track(TELEMETRY_EVENTS.LAUNCHED_CHAT, {
    QuestionName: questionName,
    Via: via,
    Path: window.location.pathName
  })

  window.FrontChat('show')
}

// Opens the chat tool from various links in static pages
window.handleOpenChatTool = (event, { via, questionName }) => {
  event.stopImmediatePropagation()
  openChatTool({ via, questionName })
  return false
}

export default (store) => (next: Function) => (action: any) => {
  next(action)

  if (action.type === OPEN_CHAT) {
    openChatTool({
      via: action.via,
      questionName: currentPanelName(store.getState())
    })
  }
}
