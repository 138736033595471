import { FEATURES } from 'lib/features'
import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'
import { RouterProps } from 'react-router'
import { getIsFeatureEnabled } from 'reducers/features/selectors'
import QuestionnaireContainerV1 from './QuestionnaireContainerV1'
import QuestionnaireContainerV2 from './QuestionnaireContainerV2'

interface MatchParams {
  questionnaireSlug: string
}

interface Match {
  params: MatchParams
}

interface ConnectProps {
  match: Match
  history: RouterProps['history']
}

interface MappedProps {
  questionnaireSlug: string
  isV2QuestionnaireEnabled: boolean
  isV2QuestionnaireEnabledForImmigrationHistoryAndMBGCChildren: boolean
  isV2QuestionnaireEnabledForAdmissibility: boolean
  isV2QuestionnaireEnabledForK1Petition2: boolean
  isV2QuestionnaireEnabledForMBGCPetition2: boolean
  isV2QuestionnaireEnabledForFinancialSponsorship: boolean
  isV2QuestionnaireEnabledForPaymentPages: boolean
  pathname: string
}

type Props = ConnectProps & MappedProps & RouterProps

const Questionnaire = ({
  isV2QuestionnaireEnabled,
  isV2QuestionnaireEnabledForImmigrationHistoryAndMBGCChildren,
  isV2QuestionnaireEnabledForAdmissibility,
  isV2QuestionnaireEnabledForK1Petition2,
  isV2QuestionnaireEnabledForMBGCPetition2,
  isV2QuestionnaireEnabledForFinancialSponsorship,
  isV2QuestionnaireEnabledForPaymentPages,
  questionnaireSlug,
  pathname,
  history
}: Props) => {
  const handleHistoryPush = (path: string) => {
    history.push(path)
  }

  const handleHistoryReplace = (path: string) => {
    history.replace(path)
  }

  // To ease the transition to v2, we are using multiple feature flags to enable v2 for specific questionnaires
  // The breakdown is based on the tickets that were created for the v2 migration
  // CON-1166, CON-1167, CON-1168, CON-1169, CON-1162
  const v2EnabledSlugs = (): string[] => {
    let enabledSlugs = []
    if (isV2QuestionnaireEnabled) {
      enabledSlugs = ['mbgc_setup', 'petition_1', 'k1_eligibility', 'k1_petition_1']
    }

    if (isV2QuestionnaireEnabledForImmigrationHistoryAndMBGCChildren) {
      enabledSlugs = enabledSlugs.concat(['immigration_history', 'mbgc_children'])
    }

    if (isV2QuestionnaireEnabledForAdmissibility) {
      enabledSlugs = enabledSlugs.concat(['admissibility'])
    }

    if (isV2QuestionnaireEnabledForK1Petition2) {
      enabledSlugs = enabledSlugs.concat(['k1_petition_2'])
    }

    if (isV2QuestionnaireEnabledForMBGCPetition2) {
      enabledSlugs = enabledSlugs.concat(['petition_2'])
    }

    if (isV2QuestionnaireEnabledForFinancialSponsorship) {
      enabledSlugs = enabledSlugs.concat(['financial_sponsorship'])
    }

    if (isV2QuestionnaireEnabledForPaymentPages) {
      enabledSlugs = enabledSlugs.concat(['mbgc_payment', 'k1_payment', 'k1_to_aos_conversion'])
    }

    return enabledSlugs
  }

  if (v2EnabledSlugs().includes(questionnaireSlug)) {
    return (
      <QuestionnaireContainerV2
        questionnaireSlug={questionnaireSlug}
        pathname={pathname}
        historyPush={handleHistoryPush}
      />
    )
  } else {
    return (
      <QuestionnaireContainerV1
        questionnaireSlug={questionnaireSlug}
        pathname={pathname}
        historyPush={handleHistoryPush}
        historyReplace={handleHistoryReplace}
      />
    )
  }
}

function mapStateToProps(state: any, ownProps: ConnectProps): MappedProps {
  return {
    isV2QuestionnaireEnabled: getIsFeatureEnabled(state, FEATURES.QUESTIONNAIRE_V2_API),
    isV2QuestionnaireEnabledForImmigrationHistoryAndMBGCChildren: getIsFeatureEnabled(
      state,
      FEATURES.QUESTIONNAIRE_V2_API__IMMIGRATION_HISTORY_AND_MBGC_CHILDREN
    ),
    isV2QuestionnaireEnabledForAdmissibility: getIsFeatureEnabled(state, FEATURES.QUESTIONNAIRE_V2_API__ADMISSIBILITY),
    isV2QuestionnaireEnabledForK1Petition2: getIsFeatureEnabled(state, FEATURES.QUESTIONNAIRE_V2_API__K1_PETITION_2),
    isV2QuestionnaireEnabledForMBGCPetition2: getIsFeatureEnabled(
      state,
      FEATURES.QUESTIONNAIRE_V2_API__MBGC_PETITION_2
    ),
    isV2QuestionnaireEnabledForFinancialSponsorship: getIsFeatureEnabled(
      state,
      FEATURES.QUESTIONNAIRE_V2_API__FINANCIAL_SPONSORSHIP
    ),
    isV2QuestionnaireEnabledForPaymentPages: getIsFeatureEnabled(state, FEATURES.QUESTIONNAIRE_V2_API__PAYMENT_PAGES),
    questionnaireSlug: ownProps.match.params.questionnaireSlug,
    pathname: ownProps.history.location.pathname
  }
}

export default connect(mapStateToProps)(Questionnaire) as FunctionComponent
