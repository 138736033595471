import { getCurrentKaseId } from 'reducers/selectors'
import api from 'lib/api'

import {
  LOADING_POST_SHIP_DASHBOARD,
  LOADING_POST_SHIP_DASHBOARD_SUCCESS,
  UPDATING_POST_SHIP_DASHBOARD,
  UPDATING_POST_SHIP_DASHBOARD_SUCCESS,
  SET_GLOBAL_ERROR
} from 'lib/constants'

function updatingDashboardSuccess(data) {
  return (dispatch) => {
    dispatch({
      type: UPDATING_POST_SHIP_DASHBOARD_SUCCESS,
      payload: data ?? {}
    })
  }
}

export const saveMilestoneAnswer = (answer: string, milestoneTaskId: number, answerId?: number) => (
  dispatch,
  getState
) => {
  const state = getState()
  const kaseId = getCurrentKaseId(state)

  const saveAnswer = () => {
    const { milestones } = api
    if (answerId) {
      return milestones.patchMilestoneAnswer(answer, answerId, kaseId)
    } else {
      return milestones.postMilestoneAnswer(answer, kaseId, milestoneTaskId)
    }
  }

  dispatch({ type: UPDATING_POST_SHIP_DASHBOARD, payload: true })
  return saveAnswer()
    .then((data) => dispatch(updatingDashboardSuccess(data)))
    .catch(() =>
      dispatch({
        type: SET_GLOBAL_ERROR,
        payload: 'Sorry, we’re having trouble updating your dashboard information.'
      })
    )
    .finally(() => dispatch({ type: UPDATING_POST_SHIP_DASHBOARD, payload: false }))
}

export const getPostShipDashboardData = () => (dispatch, getState) => {
  let state = getState()
  const kaseId = getCurrentKaseId(state)
  dispatch({ type: LOADING_POST_SHIP_DASHBOARD, payload: true })
  return api.milestones
    .fetchMilestoneDataForKase(kaseId)
    .then((data) => {
      dispatch({ type: LOADING_POST_SHIP_DASHBOARD_SUCCESS, payload: data })
    })
    .finally(() => {
      dispatch({ type: LOADING_POST_SHIP_DASHBOARD, payload: false })
    })
    .catch(() => {
      dispatch({
        type: SET_GLOBAL_ERROR,
        payload: 'We’re having trouble fetching information for your dashboard.'
      })
    })
}
