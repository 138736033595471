import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'
import _includes from 'lodash/includes'
import { isCurrentKasePaid, getCurrentKaseKind, isKaseBeingRefunded } from 'reducers/selectors'

import PrePaymentDashboard from 'components/screens/dashboard/pre_payment_dashboard'
import Issues from 'components/screens/issues'
import IssuesV2 from 'components/screens/issues_v2'
import RefundingScreen from 'components/screens/refunding_screen'

interface Props {
  isCurrentKasePaid: boolean
  currentKaseKind: 'Naturalization' | 'MarriageBasedGreenCard' | 'NewMarriageBasedGreenCard' | 'K1FianceVisa'
  isKaseBeingRefunded: boolean
}

const QuestionsRouter: FunctionComponent<Props> = ({ isCurrentKasePaid, currentKaseKind, isKaseBeingRefunded }) => {
  if (isKaseBeingRefunded) {
    return <RefundingScreen />
  }

  if (isCurrentKasePaid) {
    const kaseKind = _includes(['NewMarriageBasedGreenCard', 'K1FianceVisa'], currentKaseKind)
    if (kaseKind) {
      return <IssuesV2 />
    } else {
      return <Issues />
    }
  }

  return <PrePaymentDashboard />
}

function mapStateToProps(state) {
  return {
    isKaseBeingRefunded: isKaseBeingRefunded(state),
    isCurrentKasePaid: isCurrentKasePaid(state),
    currentKaseKind: getCurrentKaseKind(state)
  }
}

export default connect(mapStateToProps, null)(QuestionsRouter)
