import React, { FunctionComponent } from 'react'
import cx from 'classnames'
import Heading from 'components/type/heading'
import CheckmarkIcon from 'components/icons/checkmark_icon'
import CommentIcon from 'components/icons/comment_icon'
interface Props {
  isResolved?: boolean
  hasNewMessage?: boolean
  isMostRecentMessageFromCustomer?: boolean
  numberOfMessages: number
  customerResponded: boolean
}

const IssueCardHeader: FunctionComponent<Props> = ({
  isResolved,
  hasNewMessage,
  isMostRecentMessageFromCustomer,
  customerResponded,
  numberOfMessages
}) => {
  let icon = <CheckmarkIcon aria-hidden="true" />
  let title = 'Replied'

  if (isResolved) {
    icon = <CheckmarkIcon aria-hidden="true" />
    title = 'Resolved'
  }

  if (!isResolved && numberOfMessages < 1 && !isMostRecentMessageFromCustomer) {
    icon = ''
    title = 'Open Task'
  }

  if (numberOfMessages >= 1 && !isMostRecentMessageFromCustomer) {
    icon = <CommentIcon aria-hidden="true" />
    title = 'New Message'
  }

  if (isMostRecentMessageFromCustomer) {
    icon = <CheckmarkIcon aria-hidden="true" />
    title = 'Replied'
  }

  const classes = cx('p-2 border', {
    'border-gray-200 bg-gray-100': !isResolved,
    'border-success-300 bg-success-200 text-success-600': isMostRecentMessageFromCustomer || isResolved,
    'border-warning-300 bg-warning-200 text-warning-600': hasNewMessage && !customerResponded
  })

  return (
    <div className={classes}>
      <Heading tag="h2" size="md" spacing="xs" subhead className="mb-1">
        <div className="flex items-center">
          {icon}
          <span className="ml-1 pt-1">{title}</span>
        </div>
      </Heading>
    </div>
  )
}

export default IssueCardHeader
