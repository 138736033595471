const states = require('./states.json')

const UNITED_STATES = Object.assign(
  {
    '': 'Select State'
  },
  states
)

export default UNITED_STATES
