import { buildMessage, Validator } from './'

const collectionCount: Validator = ({ getModelData, path, options = {} }) => {
  const currentValue = getModelData(path)

  if (currentValue > 20) {
    const message = options.message || 'Value cannot be more than 20'
    return [buildMessage(message)]
  }

  return []
}

export default collectionCount
