import React, { FunctionComponent } from 'react'
import IconWrapper, { IconWrapperExternalProps } from './icon_wrapper'

const ApprovedIdIcon: FunctionComponent<IconWrapperExternalProps> = (props) => (
  <IconWrapper {...props} viewBox="0 0 22 15">
    <path
      d="M 10 11 H 4 V 10.55 C 4 10.2667 4.07933 10.004 4.238 9.762 C 4.396 9.52067 4.61667 9.33333 4.9 9.2 C 5.23333 9.05 5.571 8.93767 5.913 8.863 C 6.25433 8.78767 6.61667 8.75 7 8.75 C 7.38333 8.75 7.746 8.78767 8.088 8.863 C 8.42933 8.93767 8.76667 9.05 9.1 9.2 C 9.38333 9.33333 9.604 9.52067 9.762 9.762 C 9.92067 10.004 10 10.2667 10 10.55 V 11 Z"
      fill="#fff"
    />
    <path
      d="M 8.062 7.562 C 7.77067 7.854 7.41667 8 7 8 C 6.58333 8 6.22933 7.854 5.938 7.562 C 5.646 7.27067 5.5 6.91667 5.5 6.5 C 5.5 6.08333 5.646 5.72933 5.938 5.438 C 6.22933 5.146 6.58333 5 7 5 C 7.41667 5 7.77067 5.146 8.062 5.438 C 8.354 5.72933 8.5 6.08333 8.5 6.5 C 8.5 6.91667 8.354 7.27067 8.062 7.562 Z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M 0 3 C 0 1.34315 1.34315 0 3 0 H 17 C 18.6569 0 20 1.34315 20 3 H 18 C 18 2.44772 17.5523 2 17 2 H 3 C 2.44772 2 2 2.44772 2 3 V 12 C 2 12.5523 2.44772 13 3 13 H 17 C 17.5523 13 18 12.5523 18 12 V 11.5 H 20 V 12 C 20 13.6569 18.6569 15 17 15 H 3 C 1.34315 15 0 13.6569 0 12 V 3 Z"
      fill="#fff"
    />
    <path d="M 15.55 11.1 L 21.2 5.45 L 19.75 4 L 15.525 8.225 L 13.425 6.125 L 12 7.55 L 15.55 11.1 Z" fill="#fff" />
  </IconWrapper>
)

export default ApprovedIdIcon
