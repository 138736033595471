import mbgcSkipFunctions from './marriage_based_green_card'
import newmbgcSkipFunctions from './new_marriage_based_green_card'
import naturalizationSkipFunctions from './naturalization'

import { PanelType } from 'components/forms/panel'
import Decorator from 'lib/decorator'

interface ShouldSkipPanelPublicArgs {
  kaseKind: string
  modelData: Decorator
  panel: PanelType
}

interface ShouldSkipArgs {
  modelState: ModelState
  panel: PanelType
}

type ShouldSkipFunction = (args: ShouldSkipArgs) => boolean

export interface ShouldSkipCollection {
  [panelName: string]: ShouldSkipFunction
}

const skipFunctions = {
  MarriageBasedGreenCard: mbgcSkipFunctions,
  Naturalization: naturalizationSkipFunctions,
  NewMarriageBasedGreenCard: newmbgcSkipFunctions
}

// Remove _<number> from the end of repeatable panel names
const rawPanelName = (panelName) => panelName.replace(/_(\d+)$/g, '')

export const shouldSkipPanel = ({ modelData, kaseKind, panel }: ShouldSkipPanelPublicArgs): boolean => {
  const fns = skipFunctions[kaseKind]
  const skipFunctionDetails = fns && fns[rawPanelName(panel.name)]

  if (!skipFunctionDetails) {
    return false
  }

  let skipFunction = null
  if (typeof skipFunctionDetails === 'string') {
    skipFunction = skipFunctionDetails

    return modelData[skipFunction]()
  }

  let shouldSkip = false

  skipFunctionDetails.forEach((fn) => {
    const args = fn.args || {}
    // since this function is deprecated and won't be used going forward
    // we don't need to worry about supporting parentIndex for repeatable panels
    const pathIndex = panel.currentIndex
    const path = (args.path || '').replace('$', pathIndex)

    shouldSkip = shouldSkip || modelData[fn.functionName]({ ...args, path, pathIndex })
  })

  return shouldSkip
}
