import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import _some from 'lodash/some'

export default class Decorator {
  constructor(obj) {
    Object.keys(obj).forEach((key) => {
      this[key] = obj[key]
    })
  }

  get(path: string, { pathIndex } = {}) {
    return _get(this, path.replace('$', pathIndex))
  }

  valueIsMissing(path: string): boolean {
    const value = this.get(path)

    if (value == null) {
      return true
    } else if (Array.isArray(value)) {
      return _isEmpty(value)
    } else {
      return false
    }
  }

  isAddressUS(path: string): boolean {
    const address = this.get(path)

    return address != null && address.country != null && address.country.code === 'US'
  }

  addressIsUnavailableAt({ path }): boolean {
    const address = this.get(path)

    return address == null || address.street == null
  }

  addressIsIncompleteAt({ path }): boolean {
    const fieldsToCheck = ['street', 'city', 'country.code']
    if (this.isAddressUS(path)) {
      fieldsToCheck.push('postal_code', 'province')
    }

    const address = this.get(path)

    return address == null || _some(fieldsToCheck, (field) => this.get(`${path}.${field}`) == null)
  }
}
