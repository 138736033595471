import React, { FunctionComponent } from 'react'
import IconWrapper, { IconWrapperExternalProps } from './icon_wrapper'

const AirplaneIcon: FunctionComponent<IconWrapperExternalProps> = (props) => (
  <IconWrapper {...props} viewBox="0 0 20 20">
    <path
      d="M 6.5 20 V 18.5 L 8.5 17 V 11.5 L 0 14 V 12 L 8.5 7 V 1.5 C 8.5 1.08333 8.646 0.729333 8.938 0.438 C 9.22933 0.146 9.58333 0 10 0 C 10.4167 0 10.7707 0.146 11.062 0.438 C 11.354 0.729333 11.5 1.08333 11.5 1.5 V 7 L 20 12 V 14 L 11.5 11.5 V 17 L 13.5 18.5 V 20 L 10 19 L 6.5 20 Z"
      fill="#fff"
    />
  </IconWrapper>
)

export default AirplaneIcon
