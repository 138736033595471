import React from 'react'

import { joinI18nKeys } from 'lib/i18n'
import { QuestionnaireInputContainerProps } from 'components/forms/inputs'
import panelLocaleService from 'services/panel_locale_service'
import { getAfterChangeHooks } from 'components/forms/field_after_change_events'
import ValidatedInput from 'components/forms/validated_input'

import RadioInput, { Choice, choiceToString } from './radio_input'

type Props = QuestionnaireInputContainerProps & {
  clear_panel_fields?: boolean
  choices: Choice[]
  no_wrap?: boolean
}

class Radio extends React.Component<Props> {
  static defaultProps = {
    name: '',
    onChange: () => {}
  }

  getLabel() {
    if (!this.props.name) return null

    const key = joinI18nKeys(this.props.resourceKeys, `${this.props.name}.label`)
    if (!panelLocaleService.exists(key)) {
      return null
    }

    return panelLocaleService.t(key)
  }

  getChoiceLabels() {
    return this.props.choices.reduce((dict, choice) => {
      // Do not translate the choice if it's a number
      if (isNaN(parseInt(choice, 10))) {
        dict[choiceToString(choice)] = panelLocaleService.t(
          joinI18nKeys(this.props.resourceKeys, `${this.props.name}.${choice}`)
        )
      } else {
        dict[choiceToString(choice)] = choice
      }

      return dict
    }, {})
  }

  render() {
    const { clear_panel_fields, disabled, name, no_wrap, path, choices, after_change_event_list } = this.props
    const afterChangeEvents = getAfterChangeHooks(after_change_event_list)

    return (
      <ValidatedInput disabled={disabled} path={path}>
        {({ fireValidation }) => (
          <RadioInput
            id={path}
            afterChangeEvents={afterChangeEvents}
            shouldClearPanelFields={clear_panel_fields}
            disabled={disabled}
            label={this.getLabel()}
            name={name}
            path={path}
            choices={choices}
            noWrap={no_wrap}
            choiceLabels={this.getChoiceLabels()}
            fireValidation={fireValidation}
            className={'o-block c-paper-form__group'}
          />
        )}
      </ValidatedInput>
    )
  }
}

export default Radio
