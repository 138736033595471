import moment from 'moment'

const reviewScreen = (parseableDate) => moment(parseableDate, 'YYYY-MM-DD').format('MMMM D, YYYY')

const admin = (parseableDate) => moment(parseableDate).format('dddd, MMMM Do YYYY, h:mm:ss a')

const qa = (parseableDate) => {
  if (!parseableDate) return 'Missing Date'
  const date = moment(parseableDate, 'YYYY-MM-DD')
  return date.isValid() ? date.format('MM/DD/YYYY') : parseableDate
}

// if today returns Saturday, August 17th 2019, 11:03 am
// else returns Today at 11:03 am
export const todayOrDateWithName = (parseableDate) => {
  const date = moment(parseableDate)
  const today = moment()
  if (date.isSame(today, 'd')) return `Today at ${date.format('h:mm a')}`

  return date.format('dddd, MMMM Do YYYY, h:mm a')
}

export default { admin, qa, reviewScreen, todayOrDateWithName }
