import React from 'react'
import classNames from 'classnames'
import { connect } from 'react-redux'

import { openChat } from 'actions/chat_actions'
import getTelemetryService from 'lib/telemetry'
import ArrowIcon from 'components/icons/arrow_icon'
import HelpIcon from 'components/icons/help_icon'

interface Props {
  linkText: string
  href: string
  icon: string
  inline?: boolean
  openChat: typeof openChat
  overrideToChat?: boolean
}

class HCCLink extends React.Component<Props> {
  render() {
    let linkClasses = classNames('c-link c-link--plain', {
      'c-type c-type--body-serif-sm c-type--understated': !this.props.inline
    })

    let icon = null
    if (this.props.icon === 'question') {
      icon = <HelpIcon className="c-icon--xsm" />
    }
    if (this.props.icon === 'arrow') {
      icon = <ArrowIcon className="c-icon--xsm" arrowDirection="right" />
    }

    return (
      <a
        className={linkClasses}
        href={this.props.href}
        onClick={this.handleClick.bind(this)}
        rel="noopener noreferrer"
        target="_blank"
      >
        {!this.props.inline && this.props.linkText + ' '}
        {icon}
      </a>
    )
  }

  handleClick = (evt) => {
    evt.preventDefault()

    let redirectTo = this.props.href
    let formattedLinkName = `HCC_${this.props.href.replace(/\//gi, '-')}`

    if (this.props.overrideToChat) {
      redirectTo = null
      formattedLinkName = `HCC_open-chat-modal`

      this.props.openChat({ via: 'HCCLinkChat' })
    }

    getTelemetryService().trackLink({
      linkName: formattedLinkName,
      redirectTo: redirectTo,
      newTab: true
    })
  }
}

function mapDispatchToActions(dispatch) {
  return {
    openChat: (...args) => dispatch(openChat(...args))
  }
}

export default connect(null, mapDispatchToActions)(HCCLink)
