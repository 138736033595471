import React, { FunctionComponent } from 'react'

import HtmlSafe from './html_safe'
import InlineHelp from './inline_help'
import AlternativeHelpLink from './alternative_help_link'

import { splitQuestionTextIntoParts } from '../lib/split_question_text'

function splitQuestionParts(question) {
  const splitQuestion = splitQuestionTextIntoParts(question)

  return splitQuestion.parts.map((text) => {
    if (text === splitQuestion.linkPlaceholder) {
      const linkText = splitQuestion.helpLinkText

      return React.createElement(InlineHelp, {
        text: linkText,
        key: `${question}-${linkText}`
      })
    } else {
      return React.createElement(HtmlSafe, {
        text,
        key: `${question}-${text}`
      })
    }
  })
}

interface Props {
  question: string
}

const QuestionText: FunctionComponent<Props> = (props) => {
  return (
    <React.Fragment>
      <p className="o-block c-type c-type--body-serif-md c-type--emphasized">{splitQuestionParts(props.question)}</p>

      <AlternativeHelpLink />
    </React.Fragment>
  )
}

export default QuestionText
