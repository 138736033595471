import u from 'updeep'

import { OPEN_MODAL, CLOSE_MODAL } from 'lib/constants'

const initialState = {
  activeModal: null,
  activeModalProps: {}
}

export default function modalsReducer(state, action) {
  state = state || initialState

  switch (action.type) {
    case OPEN_MODAL: {
      return u(
        {
          activeModal: action.modal,
          activeModalProps: action.modalProps
        },
        state
      )
    }

    case CLOSE_MODAL: {
      return initialState
    }

    default: {
      return state
    }
  }
}
