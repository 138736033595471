import React from 'react'
import { connect } from 'react-redux'

import { openChat } from 'actions/chat_actions'

import AskIcon from 'components/icons/ask_icon'

interface Props {
  headline: string
  image: string
  openChat: openChat
  subtitle: React.ReactNode
  body: string
}

class Interstitial extends React.Component<Props> {
  static defaultProps = {
    image: 'setup-start',
    body: ''
  }

  handleChatTriggerClick = (event) => {
    event.preventDefault()
    event.nativeEvent.stopImmediatePropagation()

    this.props.openChat({ via: 'Interstitial' })
  }

  render() {
    return (
      <div className="c-context-panel">
        <div className="o-grid__row c-context-panel__content">
          <div className="o-grid__col-3">
            <div className={`c-context-panel__image c-context-panel__image--${this.props.image}`} />
          </div>

          <div className="o-grid__col-5">
            <div className="o-grid__row">
              <div className="o-grid__col-6 c-context-panel__copy">
                <h1 className="o-block o-block--ample c-type c-type--headline-lg c-type--emphasized">
                  {this.props.headline}
                </h1>

                {this.props.subtitle && <h2 className="c-type c-type--headline-sm">{this.props.subtitle}</h2>}

                {this.props.body && this.props.body}
              </div>
            </div>
          </div>
          <div className="o-grid__col-8 c-sheet__footer u-hide@md-up">
            <button className="c-btn c-btn--flat" onClick={this.handleChatTriggerClick} type="button">
              <AskIcon />
              Need Help?
            </button>
          </div>
        </div>
      </div>
    )
  }
}

function mapDispatchToActions(dispatch) {
  return {
    openChat: (...args) => dispatch(openChat(...args))
  }
}

export default connect(null, mapDispatchToActions)(Interstitial)
