import React from 'react'
import { connect } from 'react-redux'

import { getCurrentPanel } from 'reducers/selectors'

import Forms from 'components/forms'
import Outline from 'components/outline'
import ToolMenu from 'components/tool_menu'

interface Props {
  isCurrentPanelPresent: boolean
}

function AppLayout(props: Props) {
  if (!props.isCurrentPanelPresent) return null

  return (
    <div className="o-pane__fill o-grid__row">
      <Outline />

      <Forms.Container />

      <div className="o-grid__col-2 u-hide@sm-down">
        <ToolMenu />
      </div>
    </div>
  )
}

function mapStateToProps(state): Props {
  return {
    isCurrentPanelPresent: Boolean(getCurrentPanel(state))
  }
}

export default connect(mapStateToProps)(AppLayout)
