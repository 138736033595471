function getPathParts(path) {
  if (!path) {
    throw new Error('Missing a path on one or more fields in this panel.')
  }

  return path.split('.').map((part) => {
    const isInteger = /^\d+$/.test(part)
    return isInteger ? parseInt(part, 10) : part
  })
}

export function getValueAtPath(data, path) {
  const parts = getPathParts(path)

  let value = data
  let part

  for (let i = 0; i < parts.length; i++) {
    part = parts[i]

    if (!value) {
      return null
    }

    value = value[part]
  }

  return value
}

export const bindGetModelData = (data) => (path) => getValueAtPath(data, path)
