import { PanelType, PanelGroupType } from './index'
import { shouldSkipPanel } from 'reducers/model/selectors/panels/should_skip'
import Decorator from 'lib/decorator'
import {
  getPanelGroupCustomIndices,
  getPanelGroupPathIndexOffset,
  getPanelGroupNameIndexOffset,
  getPanelGroupRepeatCount
} from './group/index'
import { FieldIndexType } from 'components/forms/field/index'
import { REPEATABLE_PANEL_GROUP } from 'reducers/model/selectors'

export default class RepeatablePanelGroup {
  panelGroup: PanelGroupType
  kaseKind: string
  data: Decorator
  parentIndex: FieldIndexType

  constructor(panelGroup: PanelGroupType, kaseKind: string, data: Decorator, parentIndex?: FieldIndexType) {
    this.panelGroup = panelGroup
    this.kaseKind = kaseKind
    this.data = data
    this.parentIndex = parentIndex
  }

  getInstances(): PanelType[] {
    // Get number of repeats
    const repeatsCount = this.getRepeatCount()

    // Initialize an array of unrolled panels
    const unrolledPanels = []

    for (let index = 0; index < repeatsCount; index++) {
      this.panelGroup.panels.forEach((panel) => {
        if (panel.type === REPEATABLE_PANEL_GROUP) {
          // If the inner panel is a panel group,
          // make an inner object recursively,
          // and unroll its members

          const innerGroup = new RepeatablePanelGroup(panel, this.kaseKind, this.data, index)

          unrolledPanels.push(...innerGroup.getInstances())
        } else {
          // Otherwise, unroll the panel itself
          const currentIndex = this.getCurrentPathIndex(index)
          const panelIndex = this.getPanelIndex(index)
          const unrolledPanel = {
            ...panel,
            currentIndex,
            parentIndex: this.parentIndex,
            panel_index: panelIndex
          }

          // Don't push to the array if we need to hide this instance
          if (this.isPanelVisible(unrolledPanel)) {
            unrolledPanels.push(unrolledPanel)
          }
        }
      })
    }

    return unrolledPanels
  }

  getRepeatCount(): number {
    return getPanelGroupRepeatCount({
      modelData: this.data,
      kaseKind: this.kaseKind,
      panelGroupName: this.panelGroup.name,
      parentIndex: this.parentIndex
    })
  }

  getCurrentPathIndex(index: FieldIndexType): FieldIndexType {
    const customIndices = this.getCustomIndices(this.panelGroup.name)

    return customIndices ? customIndices[index] : index + this.getPathIndexOffset(this.panelGroup.name)
  }

  getPanelIndex(index: FieldIndexType): FieldIndexType {
    const customIndices = this.getCustomIndices(this.panelGroup.name)

    return customIndices ? customIndices[index] : index + this.getNameIndexOffset(this.panelGroup.name)
  }

  getPathIndexOffset(panelGroupName): number {
    return getPanelGroupPathIndexOffset({
      kaseKind: this.kaseKind,
      panelGroupName
    })
  }

  getCustomIndices(panelGroupName): any[] {
    return getPanelGroupCustomIndices({
      modelData: this.data,
      kaseKind: this.kaseKind,
      panelGroupName
    })
  }

  getNameIndexOffset(panelGroupName): number {
    return getPanelGroupNameIndexOffset({
      kaseKind: this.kaseKind,
      panelGroupName
    })
  }

  isPanelVisible(panel: PanelType): boolean {
    return !shouldSkipPanel({
      modelData: this.data,
      kaseKind: this.kaseKind,
      panel
    })
  }
}
