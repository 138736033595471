const BASE = 1000
const KILOBYTE = BASE
const MEGABYTE = KILOBYTE * BASE
const GIGABYTE = MEGABYTE * BASE

/**
 * Warning: this is pretty nerdy. However, it's important to adhere to a
 * widespread standard because people might be confused if they see
 * different file sizes in their computer vs on our site.
 *
 * Different standards:
 * - On Windows, "1 KB" represents 1024 bytes (KiB would be more accurate)
 * - On Mac OS, "1 KB" is 1000 bytes
 *
 * Mac OS uses the IEC standards when displaying file sizes.
 * https://physics.nist.gov/cuu/Units/binary.html
 *
 * Bytes are displayed as such: "242 bytes"
 * Kilobytes never show decimals: "5 MB"
 * Megabytes and gigabytes show 1 digit after the decimal: "23.5 GB"
 *
 * On Mac OS, values are rounded (not floored).
 * 5,499 bytes is displayed as "5 KB"
 * 5,500 bytes is displayed as "6 KB"
 */

/**
 * Formats a file size the same way as Mac OS.
 * @param bytes File size in bytes
 */
export function readableFileSize(sizeInBytes: number): string {
  if (!sizeInBytes || isNaN(sizeInBytes)) return '0 bytes'

  if (sizeInBytes <= BASE) {
    return `${sizeInBytes} bytes`
  } else if (sizeInBytes <= MEGABYTE) {
    const kilobytes = Math.round(sizeInBytes / KILOBYTE)
    return `${kilobytes} KB`
  } else if (sizeInBytes <= GIGABYTE) {
    const megabytes = (sizeInBytes / MEGABYTE).toFixed(1)
    return `${megabytes} MB`
  } else {
    const gigabytes = (sizeInBytes / GIGABYTE).toFixed(1)
    return `${gigabytes} GB`
  }
}
