import {
  INITIALIZE_TELEMETRY_SESSION,
  POST_TELEMETRY,
  POST_TELEMETRY_WITH_CONVERSION,
  RESET_TELEMETRY,
  CONVERSION_TRACKING_DESTINATIONS
} from 'lib/constants'

export function initializeTelemetrySession(user) {
  return (dispatch) => {
    dispatch({
      type: INITIALIZE_TELEMETRY_SESSION,
      user
    })
  }
}

export function postTelemetry(eventName, params, callback = null) {
  return (dispatch) => {
    dispatch({
      type: POST_TELEMETRY,
      eventName,
      params,
      callback
    })
  }
}

export function postTelemetryWithConversion(
  eventName,
  eventParams,
  conversionEventName,
  conversionEventParams,
  callback = null,
  // Default to at least sending conversion to Google Analytics
  conversionTrackingDestinations = [CONVERSION_TRACKING_DESTINATIONS.GOOGLE]
) {
  return (dispatch) => {
    dispatch({
      type: POST_TELEMETRY_WITH_CONVERSION,
      eventName,
      eventParams,
      conversionEventName,
      conversionEventParams,
      conversionTrackingDestinations,
      callback
    })
  }
}

export function resetTelemetry() {
  return (dispatch) => {
    dispatch({
      type: RESET_TELEMETRY
    })
  }
}
