import { SET_ASSETS_EXPIRED, SET_ASSETS_VERSION } from 'lib/constants'

import { DispatchAPI } from 'redux'

export interface AssetsExpiredAction {
  type: typeof SET_ASSETS_EXPIRED
}

export interface SetAssetsVersionAction {
  type: typeof SET_ASSETS_VERSION
  version: Nullable<string>
}

export function setAssetsExpired() {
  return (dispatch: DispatchAPI<AssetsExpiredAction>) => {
    dispatch({
      type: SET_ASSETS_EXPIRED
    })
  }
}

export function setAssetsVersion({ version }: { version: Nullable<string> }) {
  return (dispatch: DispatchAPI<SetAssetsVersionAction>) => {
    dispatch({
      type: SET_ASSETS_VERSION,
      version
    })
  }
}
