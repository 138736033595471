import React, { FunctionComponent, useEffect } from 'react'

import ArrowIcon from 'components/icons/arrow_icon'
import { TELEMETRY_EVENTS } from 'lib/constants'

import FormImg from 'images/interstitials/form.png'
import GreenCardImg from 'images/interstitials/green-card.png'
import InterviewImg from 'images/interstitials/interview.png'
import LawyerImg from 'images/interstitials/lawyer.png'
import getTelemetryService from 'lib/telemetry'

interface Props {
  currentKaseId: number
}

const K1MidwayInterstitialPage: FunctionComponent<Props> = ({ currentKaseId }) => {
  useEffect(() => {
    const telemetryService = getTelemetryService()
    telemetryService.track(TELEMETRY_EVENTS.MIDWAY_PP1)
  }, [])
  const paymentPageUrl = `/applications/${currentKaseId}/questionnaire/k1_payment`

  return (
    <div className="flex flex-col text-left">
      <div className="border-b border-default o-block" />
      <h1 className="o-block o-block--ample c-type c-type--headline-lg c-type--emphasized mt-4">
        We’re your best chance for K-1 success
      </h1>
      <h2 className="c-type--subhead-md text-black">50x less likely to get a denial</h2>
      <img className="mt-2" src={FormImg} alt="government form" />
      <p className="mb-0 mt-2">
        Government forms can be confusing. Those who file on their own are over 50x more likely to get a denial vs.
        those who use Boundless.
      </p>
      <div className="partial-underline mb-6" />
      <h2 className="c-type--subhead-md text-black">Filing with USCIS is just the first step</h2>
      <img className="mt-2" src={InterviewImg} alt="government form" />
      <p className="mb-0 mt-2">
        After USCIS approval, you have to complete your DS-160, I-134, get a medical exam and do your embassy interview.
        Our experienced team will guide you through each of these milestones to success.
      </p>
      <div className="partial-underline mb-6" />
      <h2 className="c-type--subhead-md text-black">Affordable lawyer support</h2>
      <img className="mt-2" src={LawyerImg} alt="government form" />
      <p className="mb-0 mt-2">
        An independent immigration attorney will review your application to ensure it’s in the best possible state for
        filing.
      </p>
      <div className="partial-underline mb-6" />
      <p className="text-black c-type--headline-sm mb-6">
        Get everything for $995, or as low as $83/mo, plus our money back guarantee
      </p>
      <a className="text-blue-500 mb-6" href={paymentPageUrl}>
        <strong>See payment options</strong> <ArrowIcon arrowDirection="right" />
      </a>
      <h2 className="c-type--subhead-md text-black">Your pathway to a green card</h2>
      <img className="mt-2" src={GreenCardImg} alt="government form" />
      <p className="mb-0 mt-2 mb-6">
        After receiving your K-1 visa, our services can help you obtain your green card so you can work and travel in
        the U.S.
      </p>
    </div>
  )
}

export default K1MidwayInterstitialPage
