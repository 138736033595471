import {
  INITIALIZE_STORE,
  LOAD_REFERRAL_STATE,
  LOAD_LAST_VIEWED_SECTION_AND_PANEL_NAME,
  LOAD_RESOURCE_CONTEXT
} from 'lib/constants'

import { showPanel } from './panel_actions'

import { getComputedInitialPanel, getCurrentPanel, isCurrentlyInSection } from 'reducers/selectors'

export function initializeStore(storeData) {
  return (dispatch, getState) => {
    const state = getState()
    const initialLocation = state && state.router ? state.router : {}

    dispatch({
      type: INITIALIZE_STORE,
      storeData: storeData,
      initialLocation
    })
  }
}

export function loadLastViewedSectionAndPanelName(lastViewedSectionAndPanelName) {
  return (dispatch) => {
    dispatch({
      type: LOAD_LAST_VIEWED_SECTION_AND_PANEL_NAME,
      lastViewedSectionAndPanelName
    })
  }
}

export function loadResourceContext(resourceContext) {
  return (dispatch) => {
    dispatch({
      type: LOAD_RESOURCE_CONTEXT,
      resourceContext: resourceContext
    })
  }
}

export function loadReferralState(referral) {
  return (dispatch) => {
    dispatch({
      type: LOAD_REFERRAL_STATE,
      referral: referral
    })
  }
}

function isOnRootKasePath() {
  return /^\/applications\/\d+$/.test(window.location.pathname)
}

export function initializeCurrentPanel(store) {
  const state = store.getState()

  if (!isCurrentlyInSection(state) && !isOnRootKasePath()) {
    return
  }

  if (!getCurrentPanel(state)) {
    const panel = getComputedInitialPanel(state)

    store.dispatch(showPanel({ panel, via: 'PageLaunch' }, 'replace'))
  }
}

// For unknown reasons, the font defined in our BUI themes is not loading
// in Apply automatically like it does in our other client side react apps.
// This is a temporary fix to load the font from the CDN. We only
// use this font for BUI elements which means two places (as of Nov-2023):
// - the prepay dashboard (elegibility results)
// - some notices about fees in the apply flow
// If we were to continue heavily investing in the Apply FE, we should consider
// a more permanent solution, but this seems unlikely to be the case.
export function initializeInterFont() {
  const interFont = document.createElement('link')
  interFont.rel = 'stylesheet'
  interFont.href = 'https://fonts.cdnfonts.com/css/inter'
  document.head.appendChild(interFont)
}
