import React from 'react'

import _difference from 'lodash/difference'
import _isEmpty from 'lodash/isEmpty'
import _reduce from 'lodash/reduce'

import operations from 'lib/update_transformations'

import ValidatedInput from 'components/forms/validated_input'
import BufferedFieldValue, { AfterChangeFunction } from 'components/forms/buffered_field_value'

interface Address {
  autofilled?: boolean
  city?: string
  path: string
  postal_code?: string
  province?: string
  street?: string
  type: 'Address'
  country?: {
    code: string
    name_override?: string
    path: string
    type: 'Country'
  }
  end_data: {
    date?: string
    path: string
    present_day?: string
    type: 'DateOrPresent'
  }
}

interface Props {
  address?: Address
  disabled: boolean
  id: string
  inputRef: (ref: HTMLInputElement) => never
  path: string
  updateHiddenFieldsOnAutocomplete: boolean
  visibleFields: string[]
}

const allFields = ['city', 'country', 'postal_code', 'province', 'street', 'unit_number', 'unit_type']

export default class StreetInput extends React.Component<Props> {
  static defaultProps = {
    disabled: false,
    visible_fields: allFields,
    updateHiddenFieldsOnAutocomplete: false
  }

  hiddenFields(): string[] {
    return _difference(allFields, this.props.visibleFields)
  }

  clearHiddenFieldsAfterStreetChange: AfterChangeFunction<string> = ({ changeValueAtPath, serializedValueChanged }) => {
    if (!this.props.updateHiddenFieldsOnAutocomplete) return
    if (!serializedValueChanged) return

    const hiddenFieldUpdate = _reduce(
      this.hiddenFields(),
      (update, fieldName) => {
        let currentValue = this.props.address && this.props.address[fieldName]
        let newValue = null

        if (fieldName === 'country') {
          newValue = { code: null }

          if (currentValue != null) {
            currentValue = currentValue.code
          }
        }

        if (!_isEmpty(currentValue)) update[fieldName] = newValue

        return update
      },
      {}
    ) as Partial<Address>

    if (!_isEmpty(hiddenFieldUpdate)) {
      hiddenFieldUpdate.autofilled = false

      const operation = operations.mergeObject(hiddenFieldUpdate)

      let parentPathArr = this.props.path.split('.')
      parentPathArr.pop()
      const parentPath = parentPathArr.join('.')

      changeValueAtPath(parentPath, { operation })
    }
  }

  render() {
    const { disabled, id, inputRef, path } = this.props

    return (
      <ValidatedInput disabled={disabled} path={path}>
        {({ fireValidation, getValidationClasses, renderValidations }) => (
          <React.Fragment>
            <BufferedFieldValue afterChangeEvents={[this.clearHiddenFieldsAfterStreetChange]} path={path}>
              {(value, onChange) => (
                <input
                  id={`${id}-street`}
                  ref={inputRef}
                  name="street"
                  type="search"
                  className={`c-paper-form__control ${getValidationClasses()}`}
                  value={value}
                  required={true}
                  onBlur={fireValidation}
                  onChange={onChange}
                  disabled={disabled}
                  autoComplete="off"
                />
              )}
            </BufferedFieldValue>

            {renderValidations()}
          </React.Fragment>
        )}
      </ValidatedInput>
    )
  }
}
