import React, { FunctionComponent, CSSProperties } from 'react'
import cx from 'classnames'

interface Props {
  className?: string
  tag?: keyof JSX.IntrinsicElements
  variant?: 'primary' | 'secondary' | 'emphasized'
  style?: CSSProperties
}

const Card: FunctionComponent<Props> = ({ className, style, tag = 'div', children, variant }) => {
  const CardElement = tag

  const classes = cx('c-app-card', {
    [className]: !!className,
    'c-app-card--primary': variant === 'primary',
    'c-app-card--secondary': variant === 'secondary',
    'c-app-card--emphasized': variant === 'emphasized'
  })

  return (
    <CardElement style={style} className={classes}>
      {children}
    </CardElement>
  )
}

interface GenericProps {
  className?: string
}

export const CardBody: FunctionComponent<GenericProps> = ({ className, children }) => {
  return <div className={cx('c-app-card__body o-box', { [className]: !!className })}>{children}</div>
}

export const CardHeader: FunctionComponent<GenericProps> = ({ children, className }) => {
  return <header className={cx('c-app-card__header', { [className]: !!className })}>{children}</header>
}

export default Card
