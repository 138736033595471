import React, { FunctionComponent } from 'react'

interface Props {
  modalWidth?: string
  modalOffset?: string
  preventModalClose: (event: React.MouseEvent) => void
}

const ModalBodyWrapper: FunctionComponent<Props> = ({ modalWidth, modalOffset, children, preventModalClose }) => {
  return (
    <div className="o-grid__row o-grid__row--no-edges">
      <div
        className={`c-modal__content ${modalWidth} ${modalOffset} o-grid__col--no-gutter o-grid__col--no-edges`}
        onClick={preventModalClose}
      >
        <div className="c-modal__body o-box o-box--ample">
          <div className="o-block c-modal__body-content">{children}</div>
        </div>
      </div>
    </div>
  )
}

export default ModalBodyWrapper
