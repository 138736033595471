import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'

import { getIsPaid } from 'actions/kase_actions'
import FancySpinner from 'components/admin/fancy_spinner'
import useInterval from 'lib/hooks/useInterval'

interface ActionProps {
  getIsPaid?: () => any
}

const PaymentProcessing: FunctionComponent<ActionProps> = ({ getIsPaid }) => {
  useInterval(() => {
    getIsPaid()
  }, 5000)

  return (
    <div className="o-pane">
      <div className="c-context-panel">
        <div className="o-grid__row c-context-panel__content--borderless border-0">
          <div className="o-grid__col-12">
            <div className="o-block o-layout--center o-layout--padded-x">
              <FancySpinner variation="primary" />
              <p className="c-type c-type--body-sans-md my-5">
                We are experiencing longer than usual payment processing time, <br />
                you will be redirected to your dashboard upon completion.
              </p>
              <p className="c-type c-type--body-sans-md c-type--bold">Thank you for your patience.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function mapDispatchToActions(dispatch: Function): ActionProps {
  return {
    getIsPaid: () => dispatch(getIsPaid())
  }
}

export default connect(null, mapDispatchToActions)(PaymentProcessing)
