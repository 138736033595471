import React, { FunctionComponent } from 'react'

import IconWrapper from 'components/icons/icon_wrapper'

export const DOCUMENT_ICON_STATUS = {
  NOT_STARTED: 'not started',
  IN_PROGRESS: 'in progress',
  HAS_COMMENTS: 'has comments',
  ACCEPTED: 'accepted'
}

interface Props {
  status?: string
}

const InProgressIcon = () => (
  <IconWrapper viewBox="0 0 15 15" size="small">
    <circle cx="7.5" cy="7.5" r="6.75" fill="transparent" strokeWidth="1.5" />
    <circle cx="7.5" cy="7.5" r="2.5" fill="#3243B2" />
  </IconWrapper>
)

const OutstandingCommentsIcon = () => (
  <IconWrapper viewBox="0 0 13 13" size="small">
    <path
      d="M12.125 8.375C12.125 8.70652 11.9933 9.02446 11.7589 9.25888C11.5245 9.4933 11.2065 9.625 10.875 9.625H3.375L0.875 12.125V2.125C0.875 1.79348 1.0067 1.47554 1.24112 1.24112C1.47554 1.0067 1.79348 0.875 2.125 0.875H10.875C11.2065 0.875 11.5245 1.0067 11.7589 1.24112C11.9933 1.47554 12.125 1.79348 12.125 2.125V8.375Z"
      fill="transparent"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </IconWrapper>
)

const AcceptedIcon = () => (
  <IconWrapper viewBox="0 0 17 17" size="small">
    <path
      d="M16 7.81429V8.50429C15.9991 10.1216 15.4754 11.6953 14.507 12.9907C13.5386 14.286 12.1775 15.2336 10.6265 15.6922C9.07557 16.1508 7.41794 16.0957 5.90085 15.5352C4.38376 14.9747 3.08849 13.9389 2.20822 12.5821C1.32795 11.2253 0.909843 9.62034 1.01626 8.00653C1.12267 6.39271 1.7479 4.85654 2.79871 3.6271C3.84951 2.39766 5.26959 1.54083 6.84714 1.1844C8.42469 0.827975 10.0752 0.991046 11.5525 1.64929"
      fill="transparent"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.998 2.66772L8.30574 10.1677L5.99805 7.91997"
      fill="transparent"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </IconWrapper>
)

const NotStartedIcon = () => (
  <IconWrapper viewBox="0 0 15 15" size="small">
    <circle cx="7.5" cy="7.5" r="6.75" strokeWidth="1.5" fill="transparent" />
  </IconWrapper>
)

const DocumentStatusIcon: FunctionComponent<Props> = ({ status }) => {
  const getIcon = () => {
    switch (status) {
      case DOCUMENT_ICON_STATUS.IN_PROGRESS:
        return <InProgressIcon />
      case DOCUMENT_ICON_STATUS.HAS_COMMENTS:
        return <OutstandingCommentsIcon />
      case DOCUMENT_ICON_STATUS.ACCEPTED:
        return <AcceptedIcon />
      default:
        return <NotStartedIcon />
    }
  }

  return <span style={{ display: 'inline-block' }}>{getIcon()}</span>
}

export default DocumentStatusIcon
