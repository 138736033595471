import React from 'react'
import { connect } from 'react-redux'
import { isBeneficiaryNotInUS, sponsorIsCitizen } from 'reducers/selectors'
import HCCLink from 'components/panels/outcomes/setup_completed_hcc_link'
import Paragraph from 'components/type/paragraph'
import RapidVisaEligibilityReportInfo from 'components/panels/outcomes/rapid_visa_redirect/eligibility_report_info'
import ArrowIcon from 'components/icons/arrow_icon'
import EligibilityInfoSectionHeader from './eligibility_info_section_header'
import GoToRapidVisaLink from 'components/panels/outcomes/rapid_visa_redirect/go_to_rapid_visa_link'

interface Props {
  screen: string
  beneficiaryNotInUS: boolean
  sponsorIsCitizen: boolean
}

class RapidVisaRedirectEligibilityReport extends React.Component<Props> {
  renderMessage() {
    const sponsorLegalStatus = this.props.sponsorIsCitizen ? 'U.S. Citizen' : 'U.S. Permanent Resident'
    const beneficiaryResidence = this.props.beneficiaryNotInUS ? 'outside' : 'in'

    return (
      <div className="c-type--emphasized">
        Green card for the spouse of a <strong className="c-type--bold c-type--inline">{sponsorLegalStatus}</strong>{' '}
        currently residing{' '}
        <strong className="c-type--bold c-type--inline">{beneficiaryResidence} the United States</strong>.
      </div>
    )
  }

  renderDesktop() {
    return (
      <React.Fragment>
        <div className="o-griddle__row">
          <div className="o-griddle__col--3">
            <h1 className="c-type c-type--headline-md c-type--emphasized">Congrats!</h1>
            <Paragraph color="emphasized" className="mt-8" bold>
              You’re eligible to apply with the fastest and best green card service
            </Paragraph>
          </div>
          <div className="o-griddle__col--9 c-type c-type--body-serif-md pl-12">
            <div className="o-griddle__row">
              <div className="o-griddle__col--12 mb-12">
                <EligibilityInfoSectionHeader content="Results" />
                {this.renderMessage()}
              </div>
            </div>
            <RapidVisaEligibilityReportInfo screen={this.props.screen} />
            <div className="o-griddle__row">
              <div className="o-griddle__col--12">
                <EligibilityInfoSectionHeader content="Read More" />
              </div>
              <div className="o-griddle__col--6 c-type c-type--body-serif-sm o-layout o-layout--left pr-12">
                <ol className="c-list">
                  <li className="c-list__item c-list__item--light">
                    <HCCLink
                      href="/content/mbgc-explained"
                      linkText="Marriage-based green cards, explained"
                      icon="arrow"
                    />
                  </li>
                  <li className="c-list__item c-list__item--light">
                    <HCCLink href="/content/docs-needed-mbgc" linkText="What documents do I need?" icon="arrow" />
                  </li>
                  <li className="c-list__item c-list__item--light">
                    <HCCLink
                      href="/content/income-reqs-mbgc"
                      linkText="What are the income requirements?"
                      icon="arrow"
                    />
                  </li>
                </ol>
              </div>
              <div className="o-griddle__col--6 c-type c-type--body-serif-sm o-layout o-layout--left pr-12">
                <ol className="c-list">
                  <li className="c-list__item c-list__item--light">
                    <HCCLink
                      href="/content/what-you-get-with-boundless"
                      linkText="What you get with Boundless"
                      icon="arrow"
                    />
                  </li>
                  <li className="c-list__item c-list__item--light">
                    <HCCLink
                      href="/content/attorneys"
                      linkText="Meet the independent attorneys included in our network"
                      icon="arrow"
                    />
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }

  renderMobile() {
    return (
      <React.Fragment>
        <div className="o-grid--fluid o-block o-block--ample">
          <div className="o-grid__cell--12/12 c-type c-type--body-serif-sm o-layout o-layout--left">
            <h1 className="c-type c-type--headline-md c-type--emphasized">Congrats!</h1>
            <Paragraph color="emphasized" className="mt-8" bold>
              You’re eligible to apply with the fastest and best green card service
            </Paragraph>
          </div>
        </div>
        <div className="o-block o-block--compact">
          <div className="o-grid--fluid o-grid--fluid--trailed" />
          <div className="o-grid--fluid">
            <div className="o-box o-box--light o-box--dense">
              <div className="o-layout o-layout--padded-x--minuscule c-type c-type--subhead-sm">Results</div>
            </div>
          </div>
        </div>
        <div className="o-grid--fluid o-block o-block--ample">
          <div className="o-grid__cell--12/12 c-type c-type--body-serif-sm o-layout o-layout--left">
            {this.renderMessage()}
          </div>
        </div>
        <div className="o-grid--fluid">
          <RapidVisaEligibilityReportInfo screen={this.props.screen} />
          <div className="o-grid__cell">
            <div className="o-block o-block--compact">
              <div className="o-grid--fluid o-grid--fluid--trailed" />
              <div className="o-grid--fluid">
                <div className="o-box o-box--light o-box--dense">
                  <div className="o-layout o-layout--padded-x--minuscule c-type c-type--subhead-sm">Read More</div>
                </div>
              </div>
            </div>
            <div className="o-block o-block--ample c-type c-type--headline-sm c-type--emphasized o-layout o-layout--left">
              <ol className="c-list">
                <li className="c-list__item c-list__item--light">
                  <HCCLink href="/content/mbgc-explained" linkText="Green Cards" icon="arrow" />
                </li>
                <li className="c-list__item c-list__item--light">
                  <HCCLink href="/content/docs-needed-mbgc" linkText="Documents" icon="arrow" />
                </li>
                <li className="c-list__item c-list__item--light">
                  <HCCLink href="/content/income-reqs-mbgc" linkText="Income" icon="arrow" />
                </li>
                <li className="c-list__item c-list__item--light">
                  <HCCLink href="/content/what-you-get-with-boundless" linkText="Why Trust Us" icon="arrow" />
                </li>
                <li className="c-list__item c-list__item--light">
                  <HCCLink href="/content/attorneys" linkText="Attorneys" icon="arrow" />
                </li>
              </ol>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }

  render() {
    if (this.props.screen === 'desktop') {
      return (
        <div className="c-context-panel">
          <div className="o-grid__row c-context-panel__content p-12">
            <div>{this.renderDesktop()}</div>
          </div>
          <div className="flex items-center mt-8 flex-col">
            <GoToRapidVisaLink className="c-btn c-btn--primary mb-3">
              Continue <span className="c-btn__icon">{<ArrowIcon arrowDirection="right" />}</span>
            </GoToRapidVisaLink>
            <Paragraph size="sm">We'll send you to the RapidVisa + Boundless site</Paragraph>
          </div>
        </div>
      )
    } else {
      return (
        <div className="c-context-panel p-2">
          <div className="o-grid__row c-context-panel__content">
            <div className="o-grid__col-8 c-context-panel__copy">{this.renderMobile()}</div>
          </div>
          <div className="mt-4 text-center o-layout--sticky bg-gray-100">
            <GoToRapidVisaLink className="c-btn c-btn--primary mb-3 w-full">
              Continue <span className="c-btn__icon">{<ArrowIcon arrowDirection="right" />}</span>
            </GoToRapidVisaLink>
            <Paragraph size="sm">We'll send you to the RapidVisa + Boundless site</Paragraph>
          </div>
        </div>
      )
    }
  }
}

function mapStateToProps(state) {
  return {
    beneficiaryNotInUS: isBeneficiaryNotInUS(state),
    sponsorIsCitizen: sponsorIsCitizen(state)
  }
}

export default connect(mapStateToProps, null)(RapidVisaRedirectEligibilityReport)
