import React from 'react'
import MultipleCheckboxes from 'javascript/components/forms/inputs/multiple_checkboxes'

import _includes from 'lodash/includes'

const CollectionCheckboxes = (props) => {
  const handleSave = (dataAtPath: any, event: any) => {
    let newDataAtPath = dataAtPath
    const clickedChoice = event.currentTarget.value

    if (event.currentTarget.checked) {
      newDataAtPath = newDataAtPath.concat([clickedChoice])
    } else {
      newDataAtPath = newDataAtPath.filter((value) => value !== clickedChoice)
    }

    return newDataAtPath
  }

  const isChoiceSelected = (dataAtPath: any, choice: string) => _includes(dataAtPath, choice)

  return <MultipleCheckboxes {...props} handleSave={handleSave} isChoiceSelected={isChoiceSelected} />
}

export default CollectionCheckboxes
