import React, { FunctionComponent } from 'react'
import IconWrapper, { IconWrapperExternalProps } from './icon_wrapper'

const FamilyIcon: FunctionComponent<IconWrapperExternalProps> = (props) => (
  <IconWrapper {...props} viewBox="0 0 21 20">
    <path
      d="M5.25012 7.00968C5.94633 7.00968 6.61403 6.74593 7.10632 6.27644C7.59862 5.80695 7.87518 5.17018 7.87518 4.50623C7.87518 3.84227 7.59862 3.2055 7.10632 2.73601C6.61403 2.26652 5.94633 2.00277 5.25012 2.00277C4.55391 2.00277 3.88622 2.26652 3.39392 2.73601C2.90163 3.2055 2.62506 3.84227 2.62506 4.50623C2.62506 5.17018 2.90163 5.80695 3.39392 6.27644C3.88622 6.74593 4.55391 7.00968 5.25012 7.00968ZM5.25012 9.01245C4.62961 9.01245 4.01518 8.8959 3.4419 8.66944C2.86862 8.44298 2.34773 8.11105 1.90897 7.69261C1.4702 7.27417 1.12215 6.7774 0.88469 6.23068C0.647231 5.68396 0.525012 5.09799 0.525012 4.50623C0.525012 3.91446 0.647231 3.32849 0.88469 2.78177C1.12215 2.23505 1.4702 1.73828 1.90897 1.31984C2.34773 0.901401 2.86862 0.569475 3.4419 0.343016C4.01518 0.116557 4.62961 -8.818e-09 5.25012 0C6.5033 1.78088e-08 7.70515 0.474762 8.59128 1.31984C9.47741 2.16492 9.97523 3.3111 9.97523 4.50623C9.97523 5.70135 9.47741 6.84753 8.59128 7.69261C7.70515 8.53769 6.5033 9.01245 5.25012 9.01245ZM8.4002 19.0263V15.0208C8.4002 14.224 8.06832 13.4599 7.47756 12.8965C6.88681 12.3331 6.08557 12.0166 5.25012 12.0166C4.41467 12.0166 3.61344 12.3331 3.02268 12.8965C2.43193 13.4599 2.10005 14.224 2.10005 15.0208V19.0263H0V15.0208C0 13.6928 0.553136 12.4193 1.53773 11.4803C2.52231 10.5413 3.8577 10.0138 5.25012 10.0138C6.64254 10.0138 7.97793 10.5413 8.96252 11.4803C9.94711 12.4193 10.5002 13.6928 10.5002 15.0208V19.0263H8.4002Z"
      fill="#3243B2"
    />
    <path
      opacity="0.5"
      d="M16.2749 11.0161C16.8319 11.0161 17.366 10.8051 17.7599 10.4295C18.1537 10.0539 18.375 9.54451 18.375 9.01335C18.375 8.48218 18.1537 7.97277 17.7599 7.59718C17.366 7.22158 16.8319 7.01058 16.2749 7.01058C15.7179 7.01058 15.1838 7.22158 14.79 7.59718C14.3961 7.97277 14.1749 8.48218 14.1749 9.01335C14.1749 9.54451 14.3961 10.0539 14.79 10.4295C15.1838 10.8051 15.7179 11.0161 16.2749 11.0161ZM16.2749 13.0189C15.161 13.0189 14.0927 12.5969 13.305 11.8457C12.5173 11.0945 12.0748 10.0757 12.0748 9.01335C12.0748 7.95101 12.5173 6.93219 13.305 6.18101C14.0927 5.42982 15.161 5.00781 16.2749 5.00781C17.3889 5.00781 18.4572 5.42982 19.2448 6.18101C20.0325 6.93219 20.475 7.95101 20.475 9.01335C20.475 10.0757 20.0325 11.0945 19.2448 11.8457C18.4572 12.5969 17.3889 13.0189 16.2749 13.0189ZM18.9 19.0272V18.5265C18.9 17.8625 18.6234 17.2258 18.1311 16.7563C17.6388 16.2868 16.9711 16.023 16.2749 16.023C15.5787 16.023 14.911 16.2868 14.4187 16.7563C13.9264 17.2258 13.6499 17.8625 13.6499 18.5265V19.0272H11.5498V18.5265C11.5498 17.9347 11.672 17.3488 11.9095 16.802C12.1469 16.2553 12.495 15.7585 12.9338 15.3401C13.3725 14.9217 13.8934 14.5897 14.4667 14.3633C15.04 14.1368 15.6544 14.0203 16.2749 14.0203C16.8954 14.0203 17.5099 14.1368 18.0831 14.3633C18.6564 14.5897 19.1773 14.9217 19.6161 15.3401C20.0548 15.7585 20.4029 16.2553 20.6403 16.802C20.8778 17.3488 21 17.9347 21 18.5265V19.0272H18.9Z"
      fill="#3243B2"
    />
  </IconWrapper>
)

export default FamilyIcon
