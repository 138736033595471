import React from 'react'

import Interstitial from 'components/panels/interstitial'

function SpouseQuestions() {
  return (
    <Interstitial
      headline="The next few questions will be all about your spouse."
      subtitle="This will help determine which forms are required for your situation and how long the process will take."
      image="setup-spouse-questions"
    />
  )
}

export default SpouseQuestions
