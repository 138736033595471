import {
  MASTER_PDF_ADD_UNUSED_PAGE,
  MASTER_PDF_DUPLICATE_PAGE,
  MASTER_PDF_SET_PAGES,
  MASTER_PDF_UPDATE,
  MASTER_PDF_UPDATE_PAGES,
  UPDATE_WORKFLOW_STATUS
} from 'lib/constants'

import api from 'lib/api'
import { getCurrentKaseId, getMasterPDFPagesList } from 'reducers/selectors'

export const createMasterPDF = ({ watermark }) => (dispatch, getState) => {
  const state = getState()

  const masterPagesList = getMasterPDFPagesList(state)
  const kaseId = getCurrentKaseId(state)

  return api.masterPdf.create({ kaseId, masterPagesList, watermark })
}

export const addMasterPDFUnusedPage = (page) => (dispatch) =>
  dispatch({
    type: MASTER_PDF_ADD_UNUSED_PAGE,
    page
  })

export const duplicateMasterPDFPage = (page) => (dispatch) => dispatch({ type: MASTER_PDF_DUPLICATE_PAGE, page })

export const setMasterPDFPages = (pages) => (dispatch) =>
  dispatch(
    Object.assign(
      {
        type: MASTER_PDF_SET_PAGES
      },
      pages
    )
  )

export const updateMasterPDFPages = (pages) => (dispatch) =>
  dispatch(
    Object.assign(
      {
        type: MASTER_PDF_UPDATE_PAGES
      },
      pages
    )
  )

export const addFeedbackToMasterPDF = (masterPdfId, signResult, rawFile) => (dispatch) => {
  api.masterPdf
    .update(masterPdfId, {
      review_file_original_name: signResult.originalFilename,
      review_file_s3_direct_path: signResult.filename,
      review_file_size: rawFile.size
    })
    .then(() =>
      dispatch({
        type: MASTER_PDF_UPDATE,
        masterPdf: {
          reviewFileOriginalName: signResult.originalFilename,
          feedbackUploaded: true
        }
      })
    )
}

export const removeFeedbackFromMasterPDF = (masterPdfId) => (dispatch) => {
  api.masterPdf
    .update(masterPdfId, {
      review_file_size: null,
      review_file_s3_direct_path: null,
      review_file_original_name: null
    })
    .then(() =>
      dispatch({
        type: MASTER_PDF_UPDATE,
        masterPdf: {
          reviewFileOriginalName: null,
          feedbackUploaded: false
        }
      })
    )
}

export const submitFeedbackForMasterPDF = (masterPdfId) => (dispatch) => {
  return api.masterPdf
    .update(masterPdfId, {
      review_feedback_submitted: true
    })
    .then((data) => {
      dispatch({
        type: MASTER_PDF_UPDATE,
        masterPdf: {
          feedbackUploaded: true,
          feedbackSubmitted: true
        }
      })

      dispatch({
        type: UPDATE_WORKFLOW_STATUS,
        newWorkflowStatus: data.data.new_workflow_status
      })
    })
}
