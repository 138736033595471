import React, { FunctionComponent } from 'react'

import { allIssuesAddressed, canSubmitTask, getCurrentOpenQaTask } from 'reducers/selectors'
import { connect } from 'react-redux'
import { Task } from 'reducers/tasks'
import NewMessagesHint from 'components/screens/issues/new_messages_hint'
import Heading from 'components/type/heading'
import Paragraph from 'components/type/paragraph'
import Accordion from 'components/accordion'
import DocumentIcon from 'components/icons/document_icon'

interface MappedProps {
  allIssuesHaveBeenAddressed: boolean
  taskCanBeSubmitted: boolean
  currentOpenTask: Task
}

const MobileIssueHints: FunctionComponent<MappedProps> = ({
  allIssuesHaveBeenAddressed,
  taskCanBeSubmitted,
  currentOpenTask
}) => {
  const isReturn = currentOpenTask?.attributes.metadata?.number_of_returns > 0 && !allIssuesHaveBeenAddressed

  let hintContent = null
  if (taskCanBeSubmitted && isReturn) {
    hintContent = <NewMessagesHint />
  }

  return (
    <div className="o-box o-box--light">
      <div className="o-block o-block--spacious o-layout--center">
        <Heading color="emphasized" tag="h1" centered>
          Questions and Tasks
        </Heading>
      </div>
      {hintContent}
      {taskCanBeSubmitted && (
        <Accordion
          className="o-block"
          header={
            <span className="c-type--bold c-type--body-sans-sm">
              <DocumentIcon className="mr-1" /> Instructions
            </span>
          }
        >
          <Paragraph>
            Please carefully read each question or task, follow the instructions, and{' '}
            <span className="font-bold">reply to all items before submitting them to Boundless</span> — otherwise, we
            won’t see your responses. Even if you can’t address a certain item, please type a reply to let us know. If
            your task is to upload a document or update your petition, make sure to come back here and add a reply.
          </Paragraph>
        </Accordion>
      )}
    </div>
  )
}

function mapStateToProps(state): MappedProps {
  return {
    allIssuesHaveBeenAddressed: allIssuesAddressed(state),
    taskCanBeSubmitted: canSubmitTask(state),
    currentOpenTask: getCurrentOpenQaTask(state)
  }
}

export default connect(mapStateToProps)(MobileIssueHints) as FunctionComponent
