import React, { FunctionComponent } from 'react'
import Button from 'components/button'

interface Props {
  redirectToBeginning: () => void
}

const InvalidPage: FunctionComponent<Props> = ({ redirectToBeginning }) => {
  return (
    <div className="c-sheet__body">
      <div className="o-grid__row">
        <div className="o-grid__col-6 o-grid__col--offset-1">
          <div className="o-box o-box--copious flex flex-col justify-items-center content-center">
            <div className="o-grid__cell--12/12">
              <h1 className="c-type c-type--headline-md c-type--emphasized">This page does not exist.</h1>
              <div className="o-grid__cell--12/12 py-8">
                Please navigate to another page in the questionnaire, or click the button below to go to the start of
                this questionniare.
              </div>
              <Button
                color="primary"
                className="o-block w-3/4 md:w-1/2 p-2"
                onClick={redirectToBeginning}
                label="Take me home"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InvalidPage
