import React from 'react'
import MultipleCheckboxes from 'javascript/components/forms/inputs/multiple_checkboxes'
import _cloneDeep from 'lodash/cloneDeep'

const DictionaryCheckboxes = (props) => {
  const { path_in_item } = props

  const handleSave = (dataAtPath: any, event: any) => {
    let newDataAtPath = _cloneDeep(dataAtPath)
    const clickedChoice = event.currentTarget.value

    if (event.currentTarget.checked) {
      newDataAtPath[clickedChoice][path_in_item] = 'yes'
    } else {
      newDataAtPath[clickedChoice][path_in_item] = 'no'
    }

    return newDataAtPath
  }

  const isChoiceSelected = (dataAtPath: any, choice: string) => {
    const dictionaryItem = dataAtPath[choice]

    return dictionaryItem[path_in_item] === 'yes'
  }

  return <MultipleCheckboxes {...props} handleSave={handleSave} isChoiceSelected={isChoiceSelected} />
}

export default DictionaryCheckboxes
