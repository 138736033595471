import {
  getCurrentKaseId,
  getCurrentUser,
  getCurrentUserEmail,
  getPetitionEmail,
  getRecordedPetitionProgress,
  getResourceContext,
  isUserLoggedIn,
  isDesktopBrowser,
  isCurrentKasePaid,
  isBeneficiary,
  isSponsor
} from 'reducers/selectors'

// wrapper around redux store until we are redux-complete
export default class ApplicationDataStore {
  constructor(store) {
    this.store = store
  }

  // TODO requires modal plumbing to remove
  getResourceContext() {
    return getResourceContext(this.store.getState())
  }

  // TODO requires modal plumbing to remove
  getCanonicalEmailAddress() {
    return getCurrentUserEmail(this.store.getState()) || getPetitionEmail(this.store.getState())
  }

  getCurrentUser() {
    return getCurrentUser(this.store.getState())
  }

  getCurrentKaseId() {
    return getCurrentKaseId(this.store.getState())
  }

  getRecordedPetitionProgress() {
    return getRecordedPetitionProgress(this.store.getState())
  }

  isUserLoggedIn() {
    return isUserLoggedIn(this.store.getState())
  }

  isDesktopBrowser() {
    return isDesktopBrowser(this.store.getState())
  }

  isCurrentKasePaid() {
    return isCurrentKasePaid(this.store.getState())
  }

  isSponsor() {
    return isSponsor(this.store.getState())
  }

  isBeneficiary() {
    return isBeneficiary(this.store.getState())
  }
}
