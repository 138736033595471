import React, { FunctionComponent } from 'react'
import ReactMarkdown from 'react-markdown'
import DocumentIcon from 'components/icons/document_icon'
interface Props {
  text: string
}

const DocumentWarning: FunctionComponent<Props> = ({ text }) => {
  return (
    <div className="o-grid--fluid c-paper-form__group">
      <div className="c-hint o-block o-box c-hint--warning">
        <div className="o-grid--fluid">
          <div className="o-grid__cell--2/12">
            <DocumentIcon className="o-media__figure tool-menu-item-icon" size="large" aria-hidden="true" />
          </div>
          <div className="o-grid__cell--10/12 c-type o-block c-type--body-sans-md">
            <ReactMarkdown source={text} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default DocumentWarning
