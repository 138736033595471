export type PanelConditionFunction = () => boolean

interface PanelConditionFunctionList {
  [name: string]: PanelConditionFunction
}

const conditions: PanelConditionFunctionList = {}

// TODO this is a terrible fix for circular imports
// if you try to import the store here and do:
//
//   import store from 'store'
//   import { isDesktopBrowser } from 'reducers/selectors'
//   conditions.isDesktop = () => isDesktopBrowser(store.getState())
//
// This causes circular imports and the selector tests stop working.
conditions.isDesktop = () => {
  return Boolean(window.applicationDataStore && window.applicationDataStore.isDesktopBrowser())
}

conditions.isLoggedInOrLowPetitionProgress = () => {
  return (
    Boolean(window.applicationDataStore) &&
    (window.applicationDataStore.isUserLoggedIn() || window.applicationDataStore.getRecordedPetitionProgress() < 50)
  )
}

conditions.isSponsor = () => Boolean(window.applicationDataStore) && window.applicationDataStore.isSponsor()

conditions.isBeneficiary = () => Boolean(window.applicationDataStore) && window.applicationDataStore.isBeneficiary()

conditions.isLoggedIn = () => Boolean(window.applicationDataStore) && window.applicationDataStore.isUserLoggedIn()

conditions.isCurrentKasePaid = () =>
  Boolean(window.applicationDataStore) && window.applicationDataStore.isCurrentKasePaid()

conditions.hideSetupEligible = () => {
  return (
    Boolean(window.applicationStoreData) &&
    window.applicationStoreData.kase.kind === 'MarriageBasedGreenCard' &&
    !window.isLegacyBoundlessMBGCEnabled()
  )
}

export default conditions
