import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'

import { getIndexForCurrentPanel } from 'reducers/selectors'
import { saveData } from 'actions/kase_actions'

import SmartCollectionQualifier from './component'

export interface MappedProps {
  currentIndex: number
  saveData: typeof saveData
}

function mapStateToProps(state): MappedProps {
  return {
    currentIndex: parseInt(getIndexForCurrentPanel(state) || 1, 10)
  }
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      saveData
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(SmartCollectionQualifier)
