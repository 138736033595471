const separator = '__SEPARATOR__'
const linkPlaceholder = '__InlineHelp__'
const linkRegex = /\|(.+?)\|/
const replacement = `${separator}${linkPlaceholder}${separator}`

export function splitQuestionTextIntoParts(question) {
  const result = {}
  const match = question.match(linkRegex)

  if (match) {
    const linkText = match[1]
    const questionText = question.replace(linkRegex, replacement)

    result.parts = questionText.split(separator)
    result.linkPlaceholder = linkPlaceholder
    result.helpLinkText = linkText
  } else {
    result.parts = [question]
  }

  return result
}
