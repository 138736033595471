import server from 'lib/api/v1/server'
import { AxiosPromise } from 'axios'
import { formatKasesUrl } from './helpers'

function fetchPhaseDataForKase(kaseId): AxiosPromise {
  return server
    .get(formatKasesUrl(kaseId, 'show_states'))
    .then((response) => {
      return response.data
    })
    .catch((error) => Promise.reject(error))
}

function fetchIsPaid(kaseId): AxiosPromise {
  return server
    .get(formatKasesUrl(kaseId, 'dashboard'))
    .then((response) => {
      return response.data
    })
    .catch((error) => Promise.reject(error))
}

// api/v1/kases/:kaseId/kase_lawyer_interactions
function fetchLawyerInteractions(kaseId): AxiosPromise {
  return server
    .get(formatKasesUrl(kaseId, 'kase_lawyer_interactions'))
    .then((response) => {
      return response.data
    })
    .catch((error) => Promise.reject(error))
}

export default {
  fetchPhaseDataForKase,
  fetchIsPaid,
  fetchLawyerInteractions
}
