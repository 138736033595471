const daysInMonth = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]

function clampValue(value: string, range: [number, number] = [0, 12]): string {
  if (!value) return ''

  while (value !== '') {
    const number = parseInt(value)

    if (number >= range[0] && number <= range[1]) {
      return value
    } else {
      value = value.slice(0, value.length - 1)
    }
  }

  return ''
}

interface FormatResult {
  formatted: string
  valid: boolean
}

interface FormatOptions {
  lastChar: string
  maxValue: number
}

function formatTwoDigit(value: string, { lastChar, maxValue }: FormatOptions): FormatResult {
  const result = { valid: true, formatted: value }

  if (value === '') return { ...result, valid: false }
  if (value === '0') return result

  result.formatted = clampValue(value, [1, maxValue])
  result.valid = value === result.formatted

  const number = parseInt(result.formatted, 10)
  const minSingleDigit = Math.floor(maxValue / 10) + 1

  const isSingleDigit = (number < minSingleDigit && lastChar === '/') || (number >= minSingleDigit && number < 10)

  if (isSingleDigit) {
    result.formatted = result.valid ? `0${number}` : ''
  }

  if (result.formatted.length === 2) result.formatted += '/'

  return result
}

function formatMonth(month: string, { lastChar }): FormatResult {
  return formatTwoDigit(month, { lastChar, maxValue: 12 })
}

function formatDay(day: string, { month, lastChar }): FormatResult {
  const monthNumber = parseInt(month, 10)
  const maxDays = daysInMonth[monthNumber - 1]

  return formatTwoDigit(day, { lastChar, maxValue: maxDays })
}

function formatYear(year: string): string {
  if (!year) return ''

  const firstDigit = parseInt(year[0], 10)

  if (firstDigit === 0 || firstDigit > 2) return ''

  return year
}

export function formatValue(value: string): string {
  if (!value) return ''

  const [month, day, year] = value
    .replace(/[^0-9/]/, '')
    .split('/')
    .filter((part) => Boolean(part))

  const lastChar = value[value.length - 1]

  let formattedValue = ''

  if (month) {
    const monthResult = formatMonth(month, { lastChar })
    formattedValue += monthResult.formatted

    if (!monthResult.valid) return formattedValue
  }

  if (day) {
    const dayResult = formatDay(day, { month, lastChar })
    formattedValue += dayResult.formatted

    if (!dayResult.valid) return formattedValue
  }

  if (year) {
    formattedValue += formatYear(year).slice(0, 4)
  }

  return formattedValue
}
