import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'

import { DashboardPhase } from 'components/screens/dashboard/customer_dashboard/phase_map'
import { ContentForStatus } from 'components/screens/dashboard/customer_dashboard/phase_config/phase_description'
import PhaseInformationTemplate from 'components/screens/dashboard/customer_dashboard/phase_information/phase_information_template'
import FancySpinner from 'components/admin/fancy_spinner'

import { WORKFLOW_STATUSES } from 'lib/constants'

import {
  allIssuesAddressed,
  getCurrentOpenQaTask,
  getCurrentKaseId,
  hasIssues,
  getDashboardContentForWorkflowStatus,
  getWorkflowStatus,
  isLoadingTasks,
  isLoadingIssues
} from 'reducers/selectors'

import { Task } from 'reducers/tasks'

interface ExplicitProps {
  currentPhase: DashboardPhase
}

interface MappedProps {
  allIssuesAddressed: boolean
  currentOpenTask: Task
  kaseId: number
  isLoading?: boolean
  hasIssues: boolean
  contentForStatus: ContentForStatus
  workflowStatus: string
}

type Props = ExplicitProps & MappedProps

const WaitingForResponseCTA = ({ kaseId, allIssuesAddressed }) => {
  return (
    <a className="c-btn c-btn--emphasized" rel="noopener noreferrer" href={`/applications/${kaseId}/questions`}>
      {allIssuesAddressed ? 'Submit Answers' : 'Resolve Open Items'}
    </a>
  )
}

const waitingForResponseToQuestionsFirst = (kaseId: number, allIssuesAddressed: boolean) => {
  return {
    message: 'We looked over your entire application and have questions and tasks for you to resolve.',
    phaseDescription: (
      <>
        Boundless customers typically receive questions and tasks at this stage — we just need to make sure all of your
        information matches up and documents meet government specifications.
        <br />
        <br />
        The sooner you resolve the outstanding items, the sooner your independent attorney can begin Legal Review.
      </>
    ),
    primaryCTA: <WaitingForResponseCTA kaseId={kaseId} allIssuesAddressed={allIssuesAddressed} />
  }
}

const waitingForResponseToQuestionsSubsequent = (kaseId: number, allIssuesAddressed: boolean) => {
  return {
    message: 'We added follow-up questions and tasks for you to address.',
    phaseDescription: (
      <>
        We have some updates on your previous responses. After you reply to each item and submit your responses, we’ll
        take a look and incorporate your answers into your application.
        <br />
        <br />
        As a reminder, the sooner you finish, the sooner Legal Review can begin.
      </>
    ),
    primaryCTA: <WaitingForResponseCTA kaseId={kaseId} allIssuesAddressed={allIssuesAddressed} />
  }
}

export const QAPhaseInformation: FunctionComponent<Props> = ({
  allIssuesAddressed,
  contentForStatus,
  currentOpenTask,
  currentPhase,
  isLoading,
  hasIssues,
  kaseId,
  workflowStatus
}) => {
  const isWaitingForResponse =
    workflowStatus === WORKFLOW_STATUSES.AOS_WAITING_FOR_RESPONSE_TO_OPEN_QUESTIONS ||
    workflowStatus === WORKFLOW_STATUSES.CP_WAITING_FOR_RESPONSE_TO_OPEN_QUESTIONS

  let dashboardContent

  if (!isLoading) {
    if (hasIssues && isWaitingForResponse) {
      if (currentOpenTask?.attributes.metadata?.number_of_returns > 0) {
        dashboardContent = waitingForResponseToQuestionsSubsequent(kaseId, allIssuesAddressed)
      } else {
        dashboardContent = waitingForResponseToQuestionsFirst(kaseId, allIssuesAddressed)
      }
    } else {
      dashboardContent = contentForStatus
    }
  }

  if (!dashboardContent) {
    return (
      <div className="o-block o-layout--center flex items-center justify-center" style={{ height: '100%' }}>
        <FancySpinner variation="primary" />
      </div>
    )
  }

  return (
    <PhaseInformationTemplate
      currentPhase={currentPhase}
      header={dashboardContent?.message}
      description={dashboardContent?.phaseDescription}
      primaryCTA={dashboardContent?.primaryCTA}
    />
  )
}

function mapStateToProps(state): MappedProps {
  const isLoading = isLoadingTasks(state) || isLoadingIssues(state)

  return {
    allIssuesAddressed: allIssuesAddressed(state),
    contentForStatus: getDashboardContentForWorkflowStatus(state),
    currentOpenTask: getCurrentOpenQaTask(state),
    hasIssues: hasIssues(state),
    kaseId: getCurrentKaseId(state),
    workflowStatus: getWorkflowStatus(state),
    isLoading
  }
}

export default connect(mapStateToProps)(QAPhaseInformation) as FunctionComponent<ExplicitProps>
