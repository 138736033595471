import React, { FunctionComponent, useState } from 'react'
import PhaseDetailsLayout from './phase_details_layout'
import Timeline from './timeline'
import { KasePhase } from './types'

interface Props {
  allPhases?: KasePhase[]
  currentPhase: KasePhase
  isLoading: boolean
}

const TimelinePreviewWrapper: FunctionComponent<Props> = ({ allPhases, currentPhase, isLoading }) => {
  const [currentPhasePreview, setCurrentPhasePreview] = useState<KasePhase>(null)

  const [arrowLeftPosition, setArrowLeftPosition] = useState(0)
  const [isFullTimelineVisible, setIsFullTimelineVisible] = useState(false)

  return (
    <>
      <PhaseDetailsLayout
        arrowLeftPosition={arrowLeftPosition}
        closePhasePreview={() => setCurrentPhasePreview(null)}
        currentPhase={currentPhase}
        currentPhasePreview={currentPhasePreview}
        isFullTimelineVisible={isFullTimelineVisible}
        isLoading={isLoading}
        phaseList={allPhases}
      />
      <Timeline
        currentPhase={currentPhase}
        currentPhasePreview={currentPhasePreview}
        isLoading={isLoading}
        phaseList={allPhases}
        setArrowLeftPosition={setArrowLeftPosition}
        setIsFullTimelineVisible={setIsFullTimelineVisible}
        updateCurrentPhasePreview={(phase: KasePhase) => setCurrentPhasePreview(phase)}
      />
    </>
  )
}

export default TimelinePreviewWrapper
