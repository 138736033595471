import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'
import ProfileIcon from 'components/icons/profile_icon'
import HtmlSafe from 'components/html_safe'
import { openModal as openModalAction } from 'actions/modal_actions'
import { openChat as openChatAction } from 'actions/chat_actions'
import HelpIcon from 'components/icons/help_icon'
import AskIcon from 'components/icons/ask_icon'
import MobileChatCTA from 'components/screens/shared/mobile_chat_cta'

interface ExplicitProps {
  headline: string
  image: string
  body: string
  sectionName: string
  showDesktopLink?: boolean
  isChatDiscoveryTestEnabled?: boolean
}

interface ActionProps {
  openModal: ({ name: string }) => void
  openChat: ({ via: string }) => void
}

type Props = ExplicitProps & ActionProps

const Interstitial: FunctionComponent<Props> = ({
  headline,
  isChatDiscoveryTestEnabled,
  image = undefined,
  body = '',
  openChat,
  openModal,
  sectionName,
  showDesktopLink = false,
  children
}) => {
  const handleModalClick = (event) => {
    event.preventDefault()

    // getTelemetryService().trackLink({
    //   linkName: 'LaunchContinueOnDesktopModalNew'
    // })

    openModal({ name: 'ContinueOnDesktopModalNew' })
  }

  const handleChatTriggerClick = (event) => {
    event.preventDefault()
    event.nativeEvent.stopImmediatePropagation()

    openChat({ via: 'Interstitial' })
  }

  return (
    <div className="c-sheet__body">
      <div className="c-context-panel">
        <div className="o-grid__row c-context-panel__content">
          {!image && (
            <div className="o-grid__col-6">
              <ul className="o-block o-list__inline o-list__inline--tight">
                <li>
                  <ProfileIcon
                    className="c-icon c-icon--title c-icon--emphasized c-icon--lg-responsive"
                    aria-hidden="true"
                  />
                </li>
                <li>
                  <h3 className="o-block o-block--tight c-type c-type--subhead-md c-type--secondary">{sectionName}</h3>
                  <hr className="c-divider c-divider--narrow c-divider--secondary c-divider--emphasized" />
                </li>
              </ul>
            </div>
          )}

          {image && (
            <div className="o-grid__col-3">
              <div className={`c-context-panel__image c-context-panel__image--${image}`} />
            </div>
          )}

          <div className="o-grid__col-5">
            <div className="o-grid__row">
              <div className="o-grid__col-6 c-context-panel__copy interstitial-body-text">
                <h1 className="o-block o-block--ample c-type c-type--headline-lg c-type--emphasized">{headline}</h1>

                {body && HtmlSafe({ text: body })}
                {children}
              </div>
            </div>
          </div>

          <div className="o-grid__col-8 c-sheet__footer u-hide@md-up">
            {isChatDiscoveryTestEnabled ? (
              <MobileChatCTA variant="bordered" context="Interstitial" />
            ) : (
              <button className="c-btn c-btn--flat" onClick={handleChatTriggerClick} type="button">
                <AskIcon />
                Need Help?
              </button>
            )}
          </div>

          {showDesktopLink && (
            <div className="o-grid__col-8 c-sheet__footer u-hide@md-up">
              <div className="o-block o-block--compact">
                <a className="c-type c-type--body-sans-sm" href="#" onClick={handleModalClick}>
                  Continue on a desktop or laptop <HelpIcon className="c-icon--link" size="small" />
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

function mapDispatchToActions(dispatch): ActionProps {
  return {
    openModal: (...args) => dispatch(openModalAction(...args)),
    openChat: (...args) => dispatch(openChatAction(...args))
  }
}

export default connect(null, mapDispatchToActions)(Interstitial)
