import React, { Children, FunctionComponent, isValidElement, ReactNode } from 'react'

interface SwitchProps {
  state: string | boolean | number
}

const CASE_COMPONENT_NAME = 'SwitchCaseComponent'
const DEFAULT_COMPONENT_NAME = 'SwitchDefaultComponent'

/**
 * The <Switch> component emulates the `switch/case` syntax in JavaScript.
 *
 */
const Switch: FunctionComponent<SwitchProps> = ({ children, state }) => {
  let defaultChild: ReactNode = null
  let childToDisplay = Children.toArray(children).find((child) => {
    if (!isValidElement(child)) {
      return false
    }

    // Enforce that children are wrapped within a <Case> or <Default> component
    if (!child.hasOwnProperty('type') || !child.type.hasOwnProperty('displayName')) {
      // eslint-disable-next-line no-console
      console.warn('Only <Default> and <Case> components should be nested within a <Switch>.')
      return false
    }

    const childType: string = (child as any).type.displayName
    if (childType !== CASE_COMPONENT_NAME && childType !== DEFAULT_COMPONENT_NAME) {
      // eslint-disable-next-line no-console
      console.warn('Only <Default> and <Case> components should be nested within a <Switch>.')
      return false
    }

    // If the child is a <Default> component, keep track of it
    if (childType === DEFAULT_COMPONENT_NAME) {
      if (!defaultChild != null) {
        // eslint-disable-next-line no-console
        console.warn('A <Switch> component should only have 1 <Default> child.')
      }
      defaultChild = child
      return false
    }

    // If the child has no `value`, reject it
    let value = child.props.value
    if (value == null) {
      return false
    }

    // Return the child if its value matches the state
    return state === value
  })

  return <>{childToDisplay || defaultChild}</>
}

interface CaseProps {
  value: string | number | boolean
}

export const Case: FunctionComponent<CaseProps> = ({ children }) => <>{children}</>

Case.displayName = CASE_COMPONENT_NAME

export const Default: FunctionComponent = ({ children }) => <>{children}</>

Default.displayName = DEFAULT_COMPONENT_NAME

export default Switch
