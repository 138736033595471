import React from 'react'

import Interstitial from 'components/panels/interstitial'

function MoralCharacterInterstitial() {
  return (
    <Interstitial
      headline="The next few questions may seem intrusive."
      subtitle="These questions are required by the government. Any concerns can be confidentially discussed with your independent immigration attorney."
    />
  )
}

export default MoralCharacterInterstitial
