import React, { FunctionComponent, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import CircleCheckmark from 'components/icons/circle_checkmark'
import classNames from 'classnames'

import { TELEMETRY_EVENTS } from 'lib/constants'

import { postTelemetry } from 'actions/telemetry_actions'
import { sendTaskEvent } from 'actions/tasks_actions'

import Paragraph from 'components/type/paragraph'
import Button from 'components/button'
import { KasePhaseStepConfirmation } from './types'
import ConfirmationModal from 'components/modals/modal/confirmation_modal'

interface ActionProps {
  postTelemetry?: (eventName: string, { currentPhase, sectionName }) => void
  sendTaskEvent: typeof sendTaskEvent
}

interface SectionItemProps {
  name: string
  confirmation?: KasePhaseStepConfirmation
  detail?: string
  disabled?: boolean
  url?: string
  phaseName: string
  progress?: number
  eventName?: string
  hideProgress?: boolean
  empty?: boolean
}

type Props = ActionProps & SectionItemProps

/**
 * Possible states of a section:
 * - not started: progress === 0
 * - started: progress > 0 && progress < 100
 * - complete: progress === 100
 * - complete and disabled:
 *     progress === 100 && dashboardMeta.disable_when_complete === true
 */

const StepItem: FunctionComponent<Props> = ({
  name,
  confirmation,
  detail,
  disabled,
  eventName,
  url,
  phaseName,
  progress = 0,
  postTelemetry,
  sendTaskEvent
}) => {
  const [showModal, setShowModal] = useState(false)
  const sectionTitle = name
  let progressText
  if (progress > 0 && progress < 100) {
    progressText = `${progress}% complete`
  }

  const sendTransitionEvent = () => {
    sendTaskEvent(eventName)
    setShowModal(false)
  }

  const handleCTAClick = (telemetryEvent) => {
    postTelemetry(telemetryEvent, {
      currentPhase: phaseName,
      sectionName: name
    })
    if (eventName) {
      if (confirmation) {
        setShowModal(true)
      } else {
        sendTransitionEvent()
      }
    }
  }

  // conditionally renders a link, button, or plain text on the right
  // side of the step item row
  const renderCTAs = () => {
    let buttonText = 'Begin'
    let trackingActionName = TELEMETRY_EVENTS.STARTED_SECTION
    if (progress && progress > 0 && progress < 100) {
      buttonText = 'Continue'
      trackingActionName = TELEMETRY_EVENTS.CONTINUED_SECTION
    }
    if (progress && progress === 100) {
      buttonText = 'Update'
      trackingActionName = TELEMETRY_EVENTS.VISITED_SECTION
    }
    if (phaseName === 'INACTIVE' || phaseName === 'CANCELLED') {
      buttonText = 'Contact Boundless'
    }

    if (url) {
      return (
        <a
          href={url}
          className={classNames('c-btn', {
            'c-btn--primary': !progress || progress < 100,
            'c-btn--secondary': progress === 100,
            disabled: disabled
          })}
          onClick={() => handleCTAClick(trackingActionName)}
        >
          {buttonText}
        </a>
      )
    } else if (eventName) {
      return (
        <Button
          color={progress === 100 ? 'secondary' : 'primary'}
          disabled={disabled}
          label={buttonText}
          onClick={() => handleCTAClick(trackingActionName)}
        />
      )
    } else {
      return (
        <Paragraph className="pr-6" spacing="none" color={disabled ? null : 'emphasized'}>
          {progress === 100 ? 'Completed' : 'In Progress'}
        </Paragraph>
      )
    }
  }

  return (
    <li
      className={classNames(
        'c-list__item',
        'o-griddle__row',
        'o-griddle__row--no-gutters',
        'o-griddle__row--align-items-center',
        {
          'c-list__item--disabled': disabled
        }
      )}
      style={{ minHeight: '5rem' }}
      data-qa="timeline-step"
    >
      <div className="o-griddle__col--1 o-griddle__col--auto-sm-up py-3">
        <CircleCheckmark checked={progress === 100} aria-hidden="true" />
      </div>
      <div className="o-griddle__col py-3">
        <Paragraph spacing="none" color={disabled ? null : 'emphasized'}>
          {sectionTitle}
        </Paragraph>{' '}
        {progressText && phaseName !== 'PREPARE_TO_APPLY' && (
          <Paragraph size="sm" color="warning" spacing="none" className="mt-3">
            {progressText}
          </Paragraph>
        )}
        {detail && (
          <Paragraph size="sm" spacing="none" className="mt-3">
            {detail}
          </Paragraph>
        )}
      </div>
      <div className="o-griddle__col--12 o-griddle__col--auto-sm-up py-1 o-griddle__col--push-1 o-griddle__col--push-sm-0">
        {renderCTAs()}
      </div>
      {confirmation && (
        <ConfirmationModal
          cancelLabel={confirmation.cancel_label}
          confirmLabel={confirmation.confirm_label}
          onRequestClose={() => setShowModal(false)}
          header={confirmation.title}
          body={confirmation.description}
          isOpen={showModal}
          onRequestConfirm={sendTransitionEvent}
        />
      )}
    </li>
  )
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators({ sendTaskEvent, postTelemetry }, dispatch)
}

export default connect(null, mapDispatchToProps)(StepItem)
