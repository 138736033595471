import React, { FunctionComponent } from 'react'
import cx from 'classnames'

import { KasePhaseEntryModalData } from '../../v2/types'
import LongArrowIcon from 'components/icons/long_arrow_icon'
import GearSpinnerIcon from 'components/icons/gear_spinner_icon'
import SparkleIcon from 'components/icons/sparkle_icon'
import BreakpointWrapper from 'components/breakpoint_wrapper'
import CheckmarkIcon from 'components/icons/checkmark_icon'

interface Props {
  data: KasePhaseEntryModalData
}

const renderListItem = (item: { name: string; percent: number }) => {
  return (
    <div className="full-width">
      {item.name}
      <span className="ml-2 text-success">{item.percent}%</span>
    </div>
  )
}

const DecoratedSpinner: FunctionComponent<{ animationDelay: number }> = ({ animationDelay }) => {
  return (
    <div className="position-relative pt-1 mt-12">
      <BreakpointWrapper desktop>
        <div className="absolute-centered">
          <LongArrowIcon arrowDirection="right" className="text-coral-200" width={18} height={100} />
        </div>
      </BreakpointWrapper>
      <BreakpointWrapper mobile>
        <div className="absolute-centered">
          <LongArrowIcon className="text-coral-200" width={18} height={100} />
        </div>
      </BreakpointWrapper>
      <div className="c-action-items__icon-wrapper-lg absolute-centered secondary-light">
        <GearSpinnerIcon
          className={`c-type--secondary spin-thrice delay-${animationDelay}`}
          size="large"
        ></GearSpinnerIcon>
      </div>
      <div className="absolute-centered -mt-8 -ml-8 pt-2 pl-2">
        <SparkleIcon className="c-type--secondary"></SparkleIcon>
      </div>
    </div>
  )
}

const K1ToAOSConversionModalBody: FunctionComponent<Props> = (props) => {
  const { data } = props

  const k1Items = [
    { name: 'Fiancé Petition (Form I-129F)', percent: 100 },
    { name: '10+ Documents Uploaded', percent: 100 }
  ]
  const cf1Items = [
    { name: 'Adjustment of Status (Form I-485)', percent: data.metadata.i485 },
    { name: 'Proof of Sponsorship (Form I-864)', percent: data.metadata.proof_of_sponsorship_i864 },
    { name: 'Work Permit (Form I-765)', percent: data.metadata.work_permit_i765 },
    { name: 'Travel Permit (Form I-131)', percent: data.metadata.travel_permit_i131 },
    { name: '30+ Documents To Upload', percent: data.metadata.documents_uploaded }
  ]
  if (data.metadata.cf1_i130 < 100) {
    cf1Items.unshift({ name: 'Marriage Petition (Form I-130)', percent: data.metadata.cf1_i130 })
  }

  const animationDivisor = 2 // amount of delay needed to add one second
  const k1AnimationDelay = 2 * animationDivisor
  const photo1AnimationDelay = k1AnimationDelay + k1Items.length
  const photo2AnimationDelay = photo1AnimationDelay + animationDivisor
  const spinnerAnimationDelay = photo2AnimationDelay + 2
  const cf1HeaderAnimationDelay = spinnerAnimationDelay + 3 * animationDivisor
  const cf1BarAnimationDelay = cf1HeaderAnimationDelay + 1
  const cf1ItemsAnimationDelay = cf1BarAnimationDelay + animationDivisor

  return (
    <div>
      <h3 className="o-block c-type c-type--headline-md c-type--emphasized">{data.header}</h3>
      <div className="mb-4">{data.body}</div>
      <div className="o-grid--fluid o-grid--stack@sm c-modal__body-content--small-text">
        <div className="o-grid__cell--2/5 o-grid__cell--off@sm sm:pt-4 md:pt-12 lg:pt-12">
          <h4 className="uppercase c-type--emphasized">
            Your K-1 Application <CheckmarkIcon className="text-success -mt-2"></CheckmarkIcon>
          </h4>
          <div className="w-full my-4 o-block o-block--compact c-progress-bar c-progress-bar--extra-slim bg-success-200">
            <div className="c-progress-bar__filled grow-to-full c-progress-bar--extra-slim bg-success-500" />
          </div>
          <div>
            {k1Items.map((item, index) => (
              <div className={`mb-2 fade-in delay-${k1AnimationDelay + index}`} key={index}>
                {renderListItem(item)}
              </div>
            ))}
            {data.metadata.photos.length && (
              <div className={`fade-in delay-${photo1AnimationDelay}`}>
                <div className="text-gray-400 ml-6 -mt-1 flex flex-row w-full">
                  <div className="p-2 mb-2 mr-2 border-gray-300 border-l border-b rounded-bl-lg border-dashed border-t-0 border-r-0"></div>
                  <div className="self-end">Remember uploading these?</div>
                </div>
                <div className="mt-2 w-full flex flex-col">
                  {data.metadata.photos.map((src, index) => (
                    <div
                      key={index}
                      className={cx('w-1/2', {
                        'self-start ml-12': index === 0,
                        [`self-end -mt-16 fade-in delay-${photo2AnimationDelay}`]: index === 1
                      })}
                    >
                      <img src={src} className="w-full"></img>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
        <div
          className={`o-grid__cell--1/5 o-grid__cell--off@sm mt-1 sm:pt-4 md:pt-8 lg:pt-8 pb-4 fade-in delay-${spinnerAnimationDelay}`}
        >
          <DecoratedSpinner animationDelay={spinnerAnimationDelay} />
        </div>
        <div className={`o-grid__cell--2/5 o-grid__cell--off@sm pt-12 fade-in delay-${cf1HeaderAnimationDelay}`}>
          <h4 className="uppercase c-type--emphasized">Your Green Card Application</h4>
          <div className="w-full my-4 o-block o-block--compact c-progress-bar c-progress-bar--extra-slim bg-success-200">
            <div
              className={`c-progress-bar__filled grow-to-25 delay-${cf1BarAnimationDelay} c-progress-bar--extra-slim bg-success-500`}
            />
          </div>
          <div>
            {cf1Items.map((item, index) => (
              <div className={`mb-2 fade-in delay-${cf1ItemsAnimationDelay + index}`} key={index}>
                {renderListItem(item)}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default K1ToAOSConversionModalBody
