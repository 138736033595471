import mbgcChapterOrderFunctions from './marriage_based_green_card'
import newmbgcChapterOrderFunctions from './new_marriage_based_green_card'
import { SectionType, ChapterType } from 'components/forms/panel'
import Decorator from 'lib/decorator'

interface ChapterOrderForSectionPublicArgs {
  kaseKind: string
  modelData: Decorator
  section: SectionType
}

export interface ChapterOrderForSectionArgs {
  modelData: Decorator
  originalChapters: ChapterType[]
}

type ChapterOrderFunction = (args: ChapterOrderForSectionArgs) => ChapterType[]

export interface ChapterOrderFunctionCollection {
  [sectionName: string]: ChapterOrderFunction
}

const chapterOrderFunctions = {
  MarriageBasedGreenCard: mbgcChapterOrderFunctions,
  NewMarriageBasedGreenCard: newmbgcChapterOrderFunctions
}

export const getChaptersForSection = ({
  modelData,
  kaseKind,
  section
}: ChapterOrderForSectionPublicArgs): ChapterType[] => {
  const originalChapters = section.chapters
  const fns = chapterOrderFunctions[kaseKind]
  const chapterOrderFunction = fns && fns[section.name]
  if (!chapterOrderFunction) {
    return originalChapters
  }

  return chapterOrderFunction({ modelData, originalChapters })
}
