import api from 'lib/api'
import { buildMessage, Validator } from './'

const uniqueEmail: Validator = ({ getModelData, path }) => {
  const email = getModelData(path)

  return api.users.emailAvailable({ email }).then((available) => {
    return available ? [] : [buildMessage('Email already registered. Please log in.')]
  })
}

uniqueEmail._async = true

export default uniqueEmail
