import React, { FunctionComponent } from 'react'
import getDocumentRequestLocaleService from 'services/document_request_locale_service'
interface Props {
  docRequest: DocumentRequestModel
}

const ReadOnlyDocumentRequest: FunctionComponent<Props> = ({ docRequest }) => {
  const documentRequestLocaleService = getDocumentRequestLocaleService()

  return (
    <div className="o-block o-block--compact">
      <p className="c-type c-type--body-sans-sm">
        {docRequest.title || documentRequestLocaleService.t(docRequest, 'title', true)}
      </p>
    </div>
  )
}

export default ReadOnlyDocumentRequest
