import { LOCATION_CHANGED } from 'redux-little-router'

const assetsExpired = (store) => store.getState().assets.expired

export default (store) => (next) => (action) => {
  next(action)

  if (action.type === LOCATION_CHANGED && assetsExpired(store)) {
    location.reload()
  }
}
