import _reduce from 'lodash/reduce'
import { $AxiosError } from 'axios'

export interface BoundlessApiErrors {
  topLevel?: string[]
  [fieldKey: string]: string[]
}

function capitalize(text: string): string {
  return text.charAt(0).toUpperCase() + text.slice(1)
}

function formatFieldErrors(errors: BoundlessApiErrors): BoundlessApiErrors {
  return _reduce(
    errors,
    (result, messages, fieldKey) => {
      result[fieldKey] = messages.map((msg) => capitalize(`${fieldKey} ${msg}`))
      return result
    },
    {}
  )
}

function singleError(error: string): BoundlessApiErrors {
  return {
    topLevel: [error]
  }
}

type AxiosTransformFunction = (defaultError?: string) => any

export const transformServerErrors: AxiosTransformFunction = (defaultError) => (error: $AxiosError) => {
  let errors: BoundlessApiErrors = {}

  if (error.response && error.response.data) {
    const data = error.response.data

    if (data.errors) {
      errors = formatFieldErrors(data.errors)
    } else if (data.error) {
      // Devise returns { error: 'msg' }
      errors = singleError(data.error)
    } else if (defaultError) {
      errors = singleError(defaultError)
    }
  }

  throw errors
}
