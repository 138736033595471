import { joinPaths, pathParent } from '../../path_helpers'
import { requiredPhoneLengthForCountry } from '../../input_mask'
import { buildMessage, Validator } from './'

function countryCodePath(fromPath: string): string {
  return joinPaths(pathParent(fromPath), 'iso_country_code')
}

const phoneFormat: Validator = ({ getModelData, path }) => {
  const codePath = countryCodePath(path)
  const countryCode = getModelData(codePath)
  const currentNumber: Nullable<string> = getModelData(path)

  const requiredLength = requiredPhoneLengthForCountry(countryCode)

  if (currentNumber && requiredLength && requiredLength !== currentNumber.length) {
    return [buildMessage(`Phone number must be ${requiredLength} digits in length`)]
  }

  return []
}

export default phoneFormat
