import React, { FunctionComponent } from 'react'
import Paragraph from 'components/type/paragraph'
import Button from 'components/button'
import IssueCardHeader from 'components/screens/issues/issue_card_header'
import { getCurrentUserId } from 'reducers/selectors'
import { connect } from 'react-redux'
import { Issue, Message } from 'reducers/issues'
import { getMessagesForIssue } from 'reducers/issues/selectors'
import LinkifyText from 'components/linkify_text'

interface MappedProps {
  currentUserId: string
  messagesForIssue: Message[]
}

interface ExplicitProps {
  issue: Issue
  onOpenThread: Function
}

type Props = MappedProps & ExplicitProps

export const IssueCard: FunctionComponent<Props> = ({ issue, onOpenThread, currentUserId, messagesForIssue }) => {
  const numberOfMessages = messagesForIssue.length

  const hasCustomerResponses =
    numberOfMessages > 0 && messagesForIssue.some((message) => message.attributes.creator_id === currentUserId)

  const customerResponded =
    numberOfMessages > 0 && messagesForIssue[numberOfMessages - 1].attributes.creator_id === currentUserId

  const buttonColor = customerResponded ? 'secondary' : 'primary'

  return (
    <div className="o-block">
      <IssueCardHeader hasCustomerResponses={hasCustomerResponses} customerResponded={customerResponded} />
      <div className="border border-gray-200 border-t-0 p-4">
        <Paragraph>
          <LinkifyText>{issue.attributes.description}</LinkifyText>
        </Paragraph>

        <Button
          label="Open Thread"
          color={buttonColor}
          size="small"
          onClick={() => {
            onOpenThread(issue)
          }}
        />
      </div>
    </div>
  )
}

function mapStateToProps(state: any, ownProps: Props) {
  const { issue } = ownProps

  return {
    currentUserId: getCurrentUserId(state).toString(),
    messagesForIssue: getMessagesForIssue(state.kaseIssues, issue.id)
  }
}

export default connect(mapStateToProps)(IssueCard)
