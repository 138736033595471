import React from 'react'

interface Props {
  className?: string
  id?: string
  isUS: boolean
}

export default class ProvinceLabel extends React.Component<Props> {
  render() {
    const { className, id, isUS } = this.props
    return (
      <label className={className} htmlFor={`${id}-province`} key="province-label">
        <span className="c-type c-type--subhead-sm">{isUS ? 'State' : 'Province'}</span>
      </label>
    )
  }
}
