import React, { FunctionComponent } from 'react'
import IconWrapper, { IconWrapperExternalProps } from './icon_wrapper'

type Props = IconWrapperExternalProps

const SparkleIcon: FunctionComponent<Props> = (props) => {
  return (
    <IconWrapper {...props} viewBox="0 0 19 18">
      <path d="M14.7557 6.58815L13.7399 4.35335L11.5051 3.33753L13.7399 2.32172L14.7557 0.0869141L15.7715 2.32172L18.0063 3.33753L15.7715 4.35335L14.7557 6.58815ZM14.7557 17.9653L13.7399 15.7305L11.5051 14.7147L13.7399 13.6989L14.7557 11.4641L15.7715 13.6989L18.0063 14.7147L15.7715 15.7305L14.7557 17.9653ZM6.62917 15.5274L4.59753 11.0578L0.12793 9.02612L4.59753 6.99448L6.62917 2.52488L8.66081 6.99448L13.1304 9.02612L8.66081 11.0578L6.62917 15.5274Z" />
    </IconWrapper>
  )
}

export default SparkleIcon
