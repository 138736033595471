import React, { FunctionComponent, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'

import { openChat as openChatAction } from 'actions/chat_actions.js'

import {
  Answer,
  QuestionnairePage,
  QuestionnairePageElement,
  QuestionnaireQuestion,
  QuestionnaireQuestionSet,
  QuestionnaireQuestionSetAttributes,
  SaveQuestionFn,
  QuestionnairePageDataV2
} from '../lib/types'
import { getAnswersToSaveFromPlaceUpdate } from '../lib/places'

import AddHistoryButton, { HistoryType } from './add_history_button'
import InlineContextualHelp from './inline_contextual_help'
import QuestionContainer from './question'
import QuestionText from './question_text'
import SummaryQuestionSet from './summary_question_set'
import HtmlSafe from 'components/html_safe'
import JITFeedback from 'components/forms/panel/jit_feedback'
import MobileChatCTA from 'components/screens/shared/mobile_chat_cta'
import AskIcon from 'components/icons/ask_icon'
import ProfileIcon from 'components/icons/profile_icon'

interface QuestionSetProps {
  currentPagePath: string
  isChatDiscoveryTestEnabled?: boolean
  editingSummaryQuestionSetIndex: number
  onSaveQuestion: SaveQuestionFn
  openChat: (args: any) => void
  panelData: QuestionnairePage | QuestionnairePageDataV2
  sectionName?: string
  questionnaireSlug: string
  setEditingSummaryQuestionSetIndex: (index: number) => void
  toggleContextualHelp: (args: any) => void
}

const QuestionSet: FunctionComponent<QuestionSetProps> = ({
  currentPagePath,
  isChatDiscoveryTestEnabled,
  editingSummaryQuestionSetIndex,
  onSaveQuestion,
  openChat,
  panelData,
  questionnaireSlug,
  sectionName,
  setEditingSummaryQuestionSetIndex,
  toggleContextualHelp
}) => {
  const getPlaceholder = (attributes) => {
    if (attributes?.placeholder) {
      return attributes?.placeholder
    }

    if (attributes?.type === 'OptionalQuestion') {
      return 'Optional'
    }

    return undefined
  }

  const handleSave = (question: QuestionnaireQuestion, answer: string) => {
    if (typeof answer === 'string') {
      const modifiedAnswerObject = {
        questionId: question.id,
        answer,
        answerId: question.attributes.answer_id
      }

      onSaveQuestion(question, [modifiedAnswerObject])
    }
  }

  const [pendingPlacesUpdate, setPendingPlacesUpdate] = useState<Answer[]>(null)

  const handlePlacesUpdate = (
    question: QuestionnaireQuestion,
    addressComponents: google.maps.GeocoderAddressComponent[]
  ) => {
    const answersToSave = getAnswersToSaveFromPlaceUpdate(question, panelData, addressComponents)
    onSaveQuestion(question, answersToSave)
    setPendingPlacesUpdate(answersToSave)
  }

  /**
   * This prefills the input fields in the question set (city, state, country) if a places update has
   * been made but has not been saved yet, since normally we wait until the saved questionnaire data
   * comes back to fill the questions, but that looks disorienting when updating multiple fields on
   * the page simultaneously.
   */
  const checkForPlacesUpdate = (question: QuestionnaireQuestion, answersToSave: Answer[]): string => {
    if (answersToSave) {
      const temporaryAnswer = answersToSave.find((answer: Answer) => answer.questionId === question.id)
      if (temporaryAnswer) {
        return temporaryAnswer.answer
      }
    }
    return null
  }

  useEffect(() => {
    setPendingPlacesUpdate(null)
  }, [currentPagePath])

  const renderQuestions = (questions) => {
    return questions.map((question: QuestionnaireQuestion) => {
      const attributes = question.attributes
      const displayValue = checkForPlacesUpdate(question, pendingPlacesUpdate) || attributes.input_value
      return (
        <QuestionContainer
          key={question.id}
          questionKey={question.attributes.key}
          questionnaireName={questionnaireSlug}
          type={attributes.input_type}
          currentPagePath={currentPagePath}
          id={question.id}
          name={attributes.text}
          placeholder={getPlaceholder(attributes)}
          onPlacesUpdate={(answer) => handlePlacesUpdate(question, answer)}
          onSave={(answer) => handleSave(question, answer)}
          options={attributes.input_options}
          validators={attributes.validators}
          value={displayValue}
        />
      )
    })
  }

  const renderQuestionSetOrHistorySet = (pageElement) => {
    const questionSet = pageElement.attributes as QuestionnaireQuestionSetAttributes
    const isHistorySet = pageElement.type === 'address_history_set' || pageElement.type === 'employment_history_set'
    return (
      <div key={pageElement.id}>
        <QuestionText question={questionSet.markdown} toggleContextualHelp={toggleContextualHelp} />

        <fieldset
          className={classNames('o-block', {
            'c-paper-form': pageElement.type === 'question_set'
          })}
        >
          {renderQuestions(questionSet.questions)}
          {isHistorySet && (
            <AddHistoryButton
              currentPagePath={currentPagePath}
              historyType={pageElement.type as HistoryType}
              currentAddressFilledOptions={questionSet.questions[0].attributes.input_options[0]}
            />
          )}
        </fieldset>
      </div>
    )
  }

  const handleChatTriggerClick = (event) => {
    event.preventDefault()
    event.nativeEvent.stopImmediatePropagation()
    openChat({ via: 'Interstitial' })
  }

  return (
    <>
      <div className="c-sheet__header">
        <div className="o-grid__row">
          <div className="o-grid__col-6 o-grid__col--offset-1">
            {panelData.attributes.type !== 'SummaryPage' && (
              <div className="c-image-label">
                <ProfileIcon className="c-image-label__image c-icon--lg-responsive" aria-hidden="true" />
                <h1 className="c-image-label__copy" aria-label={`${sectionName} | ${panelData.attributes.name}`}>
                  {sectionName}
                </h1>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="c-sheet__body">
        <div className="o-grid__row">
          <div className="o-grid__col-6 o-grid__col--offset-1">
            {panelData.attributes.type === 'SummaryPage' && (
              <>
                <div className="-mt-12 text-black">
                  <h1>{panelData.attributes.subtitle}</h1>
                </div>
                <div className="mt-4 mb-6 c-type c-type--body-serif-md c-type--emphasized">
                  <div>{HtmlSafe({ text: panelData.attributes.body })}</div>
                  <div>
                    <InlineContextualHelp
                      text="Where this info is coming from"
                      toggleContextualHelp={toggleContextualHelp}
                    ></InlineContextualHelp>
                  </div>
                </div>
              </>
            )}
            {panelData.attributes.page_elements.map(
              (pageElement: QuestionnairePageElement | QuestionnaireQuestionSet, i) => {
                switch (pageElement.type) {
                  case 'question_set':
                  case 'address_history_set':
                  case 'employment_history_set':
                    return renderQuestionSetOrHistorySet(pageElement)
                  case 'summary_question_set':
                    return (
                      <SummaryQuestionSet
                        key={pageElement.id}
                        pageElement={pageElement}
                        isEditing={editingSummaryQuestionSetIndex === i}
                        isEditingAnotherSet={editingSummaryQuestionSetIndex > -1}
                        renderQuestions={renderQuestions}
                        toggleContextualHelp={toggleContextualHelp}
                        onEdit={() => setEditingSummaryQuestionSetIndex(i)}
                        onSave={() => setEditingSummaryQuestionSetIndex(-1)}
                      />
                    )
                  case 'page_element':
                  case 'heads_up_box':
                  default:
                    return (
                      <JITFeedback
                        key={pageElement.id}
                        text={pageElement.attributes.markdown}
                        variant={pageElement.type === 'heads_up_box' ? 'headsUp' : 'default'}
                      />
                    )
                }
              }
            )}
            <div className="c-sheet__footer u-hide@md-up">
              <div className="o-block">
                {isChatDiscoveryTestEnabled ? (
                  <MobileChatCTA variant="solid" context="Questionnaire" />
                ) : (
                  <button
                    className="c-btn c-btn--flat"
                    onClick={(event) => handleChatTriggerClick(event)}
                    type="button"
                  >
                    <AskIcon />
                    Need Help?
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const mapDispatchToActions = (dispatch) => {
  return {
    openChat: (...args) => dispatch(openChatAction(...args))
  }
}

export default connect(null, mapDispatchToActions)(QuestionSet)
