import { Palette } from '@boundless-immigration/boundless-ui'
import React from 'react'

const DashboardInfoIllustration = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.6159 33.1599H35.0799V6.91992H3.91992V33.1599H13.7599V39.7199L22.6159 33.1599ZM16.2199 34.8363L21.804 30.6999H32.6199V9.37992H6.37992V30.6999H16.2199V34.8363Z"
      fill={Palette.emphasisMain}
    />
    <path fillRule="evenodd" clipRule="evenodd" d="M20 14V21H18V14H20Z" fill={Palette.emphasisMain} />
    <path fillRule="evenodd" clipRule="evenodd" d="M20 23V25H18V23H20Z" fill={Palette.emphasisMain} />
  </svg>
)

export default DashboardInfoIllustration
