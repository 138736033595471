export const APPLICATION_RESOURCE = '/applications/:kaseId'
const SECTION_RESOURCE = '/:sectionName'
const PANEL_RESOURCE = '/:panelSlug(/:panelSlugIndex)(/:childPanelSlug)(/:childSlugIndex)'

const DOCUMENT_UPLOAD_RESOURCE = '/document_upload'
const DOCUMENT_UPLOAD_SINGLE_REQUEST_RESOURCE = '/:documentRequestId'
const NEW_ADDRESS_EMPLOYMENT_RESOURCE = '/address_employment_history'

export const fragments = {
  // all V1 panels use this panel resource for routing. Its kind of a catch all so
  // new routes not using the V1 system should be explicity added to app.tsx
  panel: [SECTION_RESOURCE, PANEL_RESOURCE].join(''),
  document_upload: DOCUMENT_UPLOAD_RESOURCE,
  document_upload_single_request: DOCUMENT_UPLOAD_SINGLE_REQUEST_RESOURCE,
  questions: '/questions',
  review: '/review',
  timeline: '/timeline',
  petition: '/petition'
}

const routeMap = {
  applicationPath: APPLICATION_RESOURCE,
  sectionPath: [APPLICATION_RESOURCE, SECTION_RESOURCE].join(''),
  panelPath: [APPLICATION_RESOURCE, SECTION_RESOURCE, PANEL_RESOURCE].join(''),
  newAddressEmpHistoryPath: [APPLICATION_RESOURCE, NEW_ADDRESS_EMPLOYMENT_RESOURCE].join(''),
  newDocumentUploadPath: [APPLICATION_RESOURCE, DOCUMENT_UPLOAD_RESOURCE, DOCUMENT_UPLOAD_SINGLE_REQUEST_RESOURCE].join(
    ''
  )
}

export function routeMatches(route, routePathName) {
  const routePath = routeMap[routePathName]

  if (!routePath) {
    throw new Error(`Invalid route path name: ${routePathName}`)
  }

  return route === routePath
}

export default {
  [APPLICATION_RESOURCE]: {
    [fragments.document_upload]: {
      [fragments.document_upload_single_request]: {}
    },
    [SECTION_RESOURCE]: {
      [PANEL_RESOURCE]: {}
    }
  }
}
