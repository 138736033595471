import React from 'react'

import BufferedFieldValue from 'components/forms/buffered_field_value'
import TypeaheadInput from 'components/forms/inputs/typeahead'

import countries from 'data/country_list.json'

import { getCodeFromName, getNameFromCode } from 'components/forms/inputs/typeahead/config'

import { defaultInputProps, InputProps } from 'components/forms/inputs'

interface Props extends InputProps {
  hiddenCountryCodes: string[]
}

export default class CountryInput extends React.Component<Props> {
  static defaultProps = {
    ...defaultInputProps,
    hiddenCountryCodes: []
  }

  render() {
    const { afterChangeEvents, className, disabled, hiddenCountryCodes, id, onBlur, path, placeholder } = this.props

    return (
      <BufferedFieldValue
        afterChangeEvents={afterChangeEvents}
        formatter={getNameFromCode(countries)}
        path={path}
        serializer={getCodeFromName(countries)}
      >
        {(value, onChange) => (
          <div className="qa-typeahead-container">
            <TypeaheadInput
              className={className}
              path={path}
              disabled={disabled}
              hiddenCodes={hiddenCountryCodes || []}
              id={`${id}-country`}
              name="country.code"
              onBlur={onBlur}
              onChange={onChange}
              optionsPinnedToTop={['United States of America']}
              placeholder={placeholder}
              value={value}
              typeaheadValuesList={countries}
            />
          </div>
        )}
      </BufferedFieldValue>
    )
  }
}
