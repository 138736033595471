import React, { ChangeEventHandler, FocusEventHandler, FunctionComponent, MouseEvent, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Button from 'components/button'

import { getCurrentKaseId, getCurrentKaseKind, getCurrentKaseProcess } from 'reducers/selectors'
import { AddressHistory } from 'reducers/address_histories'
import { bindActionCreators, Dispatch } from 'redux'
import {
  addNewAddressHistory,
  updateAddressHistory,
  updateIntendedAddress,
  updateMailingAddress,
  updatePhysicalAddressAbroad
} from 'actions/address_actions'
import AddressV2 from 'components/forms/inputs/v2/address'
import CommonCheckboxV2 from 'components/forms/inputs/v2/address/common/common_checkbox_v2'
import { AddressHistoryCheckboxOption } from '../lib/types'
import { KaseKind, WorkflowCategory } from 'lib/constants'
import DateInputV2 from 'components/forms/inputs/v2/address/date_input_v2'
import { validateDates } from './histories_utils/date_validators'

enum AddressTypes {
  Mailing = 'mailing_address',
  Intended = 'intended_address',
  PhysicalAbroad = 'physical_address_abroad',
  Current = 'current_address'
}
import { getAddressHistoryObjectFromPlaceUpdate } from '../lib/places'

interface Props {
  /**
   * the AddressHistory that will populate this field
   * we call it 'value' so it fits in with other questionnaire fields in the QuestionContainer component
   */
  value: AddressHistory
  /**
   * The current page path which is used to determine if we're looking at the beneficiary's address history
   * or the sponsor's
   */
  currentPagePath?: string
  /**
   * The list of checkbox options used to populate the address form when clicked
   */
  addressHistoryCheckboxOptions?: AddressHistoryCheckboxOption[]
  /**
   * Determines how to save the address history
   */
  type: AddressTypes
}

interface InjectedProps {
  kaseId: number
  kaseKind: KaseKind
  kaseProcess: WorkflowCategory
}

interface MappedActions {
  addNewAddressHistory: typeof addNewAddressHistory
  updateAddressHistory: typeof updateAddressHistory
  updateIntendedAddress: typeof updateIntendedAddress
  updateMailingAddress: typeof updateMailingAddress
  updatePhysicalAddressAbroad: typeof updatePhysicalAddressAbroad
}

type AllProps = Props & InjectedProps & MappedActions

const AddressInput: FunctionComponent<AllProps> = ({
  addressHistoryCheckboxOptions,
  addNewAddressHistory,
  currentPagePath,
  kaseId = -1,
  kaseKind,
  kaseProcess,
  updateAddressHistory,
  updateIntendedAddress,
  updateMailingAddress,
  updatePhysicalAddressAbroad,
  value,
  type
}) => {
  const isCurrentAddress = type === AddressTypes.Current
  const isMailingAddress = type === AddressTypes.Mailing
  const isIntendedAddress = type === AddressTypes.Intended
  const isPhysicalAddressAbroad = type === AddressTypes.PhysicalAbroad
  const today = new Date()
  const yesterday = new Date(today.setDate(today.getDate() - 1)).toString()
  const dateErrorMessage = 'Please enter a valid date'

  const [currentOwner, setCurrentOwner] = useState<string>()
  const [spouseStartDateIsValid, setSpouseStartDateIsValid] = useState(true)
  const [spouseEndDateIsValid, setSpouseEndDateIsValid] = useState(true)
  const [spouseEndDateTouched, setSpouseEndDateTouched] = useState(false)
  const [isBeneficiaryCurrentAddressChecked, setIsBeneficiaryCurrentAddressChecked] = useState(false)
  const [spouseStartDateValidationMessage, setSpouseStartDateValidationMessage] = useState(dateErrorMessage)
  const [spouseEndDateValidationMessage, setSpouseEndDateValidationMessage] = useState(dateErrorMessage)

  const isAddressRequiredInUS = () => {
    if (isIntendedAddress || isCurrentAddress) {
      return true
    }
    if (
      isMailingAddress &&
      (currentOwner === 'sponsor' ||
        (kaseKind === KaseKind.NewMarriageBasedGreenCard && kaseProcess === WorkflowCategory.AOS))
    ) {
      return true
    }
    return false
  }

  const defaultFormValues: AddressHistory = {
    address: {
      unit_number: null,
      unit_type: null,
      country_code: isAddressRequiredInUS() ? 'US' : null,
      street: null,
      city: null,
      postal_code: null,
      province: null
    },
    address_history_mismatch: false,
    end_date: null,
    start_date: isCurrentAddress ? yesterday : null,
    mailing_address: isMailingAddress,
    intended_address: isIntendedAddress,
    physical_address_abroad: isPhysicalAddressAbroad,
    spouse_start_date: null,
    spouse_end_date: null
  }

  const [formValues, setFormValues] = useState<AddressHistory>(defaultFormValues)
  const [formErrorMessages, setFormErrorMessages] = useState([])

  const changeAddressValue: ChangeEventHandler<HTMLInputElement> = (evt) => {
    // this will be coming from unchecking unit radio
    if (evt === null) {
      setFormValues({
        ...formValues,
        address: { ...formValues.address, unit_type: null }
      })
      return
    }
    const { value, name } = evt.currentTarget

    if (name === 'spouse_start_date' || name === 'spouse_end_date') {
      setFormValues({
        ...formValues,
        [name]: value
      })
    } else {
      setFormValues({
        ...formValues,
        address: { ...formValues.address, [name]: value }
      })
    }
  }

  const handlePlacesUpdate = (addressComponents: google.maps.GeocoderAddressComponent[]) => {
    const placesAddress = getAddressHistoryObjectFromPlaceUpdate(addressComponents)

    setFormValues({
      ...formValues,
      address: { ...formValues.address, ...placesAddress }
    })
  }

  const handleErrorMessages = (error) => {
    const errors = error.response.data.errors
    if (errors) {
      setFormErrorMessages(errors.map((error) => error.detail))
    } else {
      setFormErrorMessages(['There was an error handling your request. Please check that no fields are empty'])
    }
  }

  const saveAddress = async (address?: AddressHistory) => {
    const owner = address?.owner ? address.owner : currentOwner
    let finalFormValues = address ? address : formValues

    // Add start_date here since it's needed for posting to address history endpoint
    if (isCurrentAddress) {
      finalFormValues = { ...finalFormValues, start_date: yesterday }
    }
    const addressValues = { kaseId, owner, formValues: finalFormValues }

    try {
      if (isMailingAddress) {
        await updateMailingAddress(addressValues)
      } else if (isIntendedAddress) {
        await updateIntendedAddress(addressValues)
      } else if (isPhysicalAddressAbroad) {
        await updatePhysicalAddressAbroad(addressValues)
      } else if (isCurrentAddress && value) {
        await updateAddressHistory(addressValues)
      } else if (isCurrentAddress && !value) {
        await addNewAddressHistory(addressValues)
      }
    } catch (error) {
      handleErrorMessages(error)
    }
  }

  const onChangeMailingCheckbox = (event: any, addressHistory: AddressHistory) => {
    if (event.target.checked) {
      const address = {
        ...addressHistory,
        mailing_address: isMailingAddress || addressHistory.mailing_address,
        intended_address: isIntendedAddress || addressHistory.intended_address,
        physical_address_abroad: isPhysicalAddressAbroad || addressHistory.physical_address_abroad
      }
      setCurrentOwner(addressHistory.owner)
      setFormValues(address)
      saveAddress(address)
    } else {
      setFormValues(defaultFormValues)
    }
  }

  const getCurrentOwner = (currentPagePath: string) => {
    return currentPagePath === "beneficiary's-profile-mailing-address" ||
      currentPagePath === "beneficiary's-profile-intended-address" ||
      currentPagePath === "beneficiary's-profile-physical-address-abroad"
      ? 'beneficiary'
      : 'sponsor'
  }

  useEffect(() => {
    setCurrentOwner(getCurrentOwner(currentPagePath))
    setFormValues({ ...value })
  }, [])

  useEffect(() => {
    setCurrentOwner(getCurrentOwner(currentPagePath))
  }, [currentPagePath])

  useEffect(() => {
    setFormValues({
      ...value,
      address: { ...value?.address, country_code: isAddressRequiredInUS() ? 'US' : value?.address?.country_code }
    })
    if (value?.shared && !value?.spouse_end_date) {
      setIsBeneficiaryCurrentAddressChecked(true)
    }
  }, [value, type])

  // Returns true if inputs are valid, false otherwise
  const datesAreValid = (): boolean => {
    // Set these as scoped variables to avoid race conditions related to state
    let spouseStartDateValid = spouseStartDateIsValid,
      spouseEndDateValid = spouseEndDateIsValid

    // startDateIsValid only gets set during onBlur, so there could be a case where
    // they never enter the field and thus it is valid yet there is nothing entered
    // same with endDateIsValid
    if (formValues.shared && spouseStartDateIsValid && !formValues.spouse_start_date) {
      spouseStartDateValid = false
      setSpouseStartDateIsValid(false)
      setSpouseStartDateValidationMessage(dateErrorMessage)
    }

    // If the checkbox for current address is not checked then end date should be filled
    if (
      formValues.shared &&
      !isBeneficiaryCurrentAddressChecked &&
      spouseEndDateIsValid &&
      !formValues.spouse_end_date
    ) {
      spouseEndDateValid = false
      setSpouseEndDateIsValid(false)
      setSpouseEndDateValidationMessage(dateErrorMessage)
    }

    if (spouseStartDateValid && spouseEndDateValid) {
      return true
    } else {
      setFormErrorMessages(['Please fix any errors in the form above'])
      return false
    }
  }

  const onClickSaveAddress = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    if (datesAreValid()) {
      saveAddress(formValues)
    }
  }

  const onLivingTogetherCheckboxChange = () => {
    setFormValues({
      ...formValues,
      shared: !formValues.shared,
      spouse_end_date: null,
      spouse_start_date: null
    })
    // Reset these to true
    setSpouseStartDateIsValid(true)
    setSpouseEndDateIsValid(true)
    setSpouseEndDateTouched(false)
  }

  const onSpouseCurrentAddressCheckboxChange = () => {
    // Reset validation for end date and touched if checking box
    if (!isBeneficiaryCurrentAddressChecked) {
      setSpouseEndDateIsValid(true)
      setSpouseEndDateTouched(false)
    }
    setIsBeneficiaryCurrentAddressChecked(!isBeneficiaryCurrentAddressChecked)
    setFormValues({ ...formValues, spouse_end_date: null })
  }

  const onBlurSpouseStartDate: FocusEventHandler<HTMLInputElement> = (event) => {
    // We use event.target.value to avoid race conditions when setting the state of
    // formValues.start_date
    // We have to add the time so that the date isn't offset by one day
    const startDate = new Date(event.target.value + ' 00:00:00')
    const endDate = new Date(formValues.spouse_end_date + ' 00:00:00')
    const validationObj = validateDates(startDate, endDate)

    setSpouseStartDateIsValid(validationObj.isStartDateValid)
    setSpouseStartDateValidationMessage(validationObj.startDateValidationMsg)
    // Only set these validations when end date is rendered
    if (!isBeneficiaryCurrentAddressChecked && spouseEndDateTouched) {
      setSpouseEndDateIsValid(validationObj.isEndDateValid)
      setSpouseEndDateValidationMessage(validationObj.endDateValidationMsg)
    }
  }

  const onBlurSpouseEndDate: FocusEventHandler<HTMLInputElement> = (event) => {
    // We use event.target.value to avoid race conditions when setting the state of
    // formValues.start_date
    // We have to add the time so that the date isn't offset by one day
    const startDate = new Date(formValues.spouse_start_date + ' 00:00:00')
    const endDate = new Date(event.target.value)
    const validationObj = validateDates(startDate, endDate)

    setSpouseEndDateTouched(true)
    setSpouseStartDateIsValid(validationObj.isStartDateValid)
    setSpouseStartDateValidationMessage(validationObj.startDateValidationMsg)
    setSpouseEndDateIsValid(validationObj.isEndDateValid)
    setSpouseEndDateValidationMessage(validationObj.endDateValidationMsg)
  }

  return (
    <>
      {addressHistoryCheckboxOptions?.map((addressHistoryOption, index) => (
        <CommonCheckboxV2
          key={`address-history-checkbox-${index}`}
          label={addressHistoryOption.text}
          name={type}
          id={`checkbox-for-mailing-address-${index}`}
          checked={formValues?.id === addressHistoryOption.id}
          onChangeEvent={(e) => onChangeMailingCheckbox(e, addressHistoryOption)}
        />
      ))}
      <AddressV2
        address={formValues?.address}
        onChange={changeAddressValue}
        disabled={!!formValues.start_date && !isCurrentAddress}
        onPlacesUpdate={handlePlacesUpdate}
        disabledFields={isAddressRequiredInUS() ? ['country_code'] : null}
        hiddenCountryCodes={isPhysicalAddressAbroad ? ['US'] : null}
        isUnitedStates={isAddressRequiredInUS()}
      />
      {isCurrentAddress && (
        <CommonCheckboxV2
          label={`Sponsor lived at this address with beneficiary (for example, we rented an apartment or owned a home together)`}
          name="living-together"
          id="checkbox-for-living-together"
          checked={formValues.shared || false}
          onChangeEvent={onLivingTogetherCheckboxChange}
        />
      )}
      {isCurrentAddress && formValues.shared && (
        <>
          <CommonCheckboxV2
            label={`Beneficiary currently lives here`}
            name="spouse_current_address"
            id="checkbox-for-spouse-current-address"
            checked={isBeneficiaryCurrentAddressChecked}
            onChangeEvent={onSpouseCurrentAddressCheckboxChange}
          />
          <DateInputV2
            value={formValues.spouse_start_date ? formValues.spouse_start_date : null}
            name="spouse_start_date"
            label={`Beneficiary’s move in date`}
            onBlurEvent={onBlurSpouseStartDate}
            onChangeEvent={changeAddressValue}
            isValid={spouseStartDateIsValid}
            validationMessage={spouseStartDateValidationMessage}
          />
          {!isBeneficiaryCurrentAddressChecked && (
            <DateInputV2
              value={formValues.spouse_end_date ? formValues.spouse_end_date : null}
              name="spouse_end_date"
              label={`Beneficiary’s move out date`}
              onBlurEvent={onBlurSpouseEndDate}
              onChangeEvent={changeAddressValue}
              isValid={spouseEndDateIsValid}
              validationMessage={spouseEndDateValidationMessage}
            />
          )}
        </>
      )}
      <div className="o-grid--fluid o-grid--stack@sm o-block c-paper-form__group">
        <div className="o-grid__cell">
          <Button className="c-btn-right" color="primary" onClick={onClickSaveAddress} type="button" label="Save" />
        </div>
      </div>
      {formErrorMessages.length > 0 && (
        <div className="c-alert c-alert--danger">
          <ul>
            {formErrorMessages.map((message) => (
              <li key={message}>{message}</li>
            ))}
          </ul>
        </div>
      )}
    </>
  )
}

function mapStateToProps(state): InjectedProps {
  return {
    kaseId: getCurrentKaseId(state),
    kaseKind: getCurrentKaseKind(state),
    kaseProcess: getCurrentKaseProcess(state)
  }
}

function mapDispatchToActions(dispatch: Dispatch): MappedActions {
  return bindActionCreators(
    {
      addNewAddressHistory,
      updateMailingAddress,
      updateIntendedAddress,
      updatePhysicalAddressAbroad,
      updateAddressHistory
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToActions)(AddressInput)
