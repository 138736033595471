import t from 'lib/update_transformations'
import { devConsoleLog } from 'lib/logging'

import { AfterChangeFunction } from 'components/forms/buffered_field_value'
import { ModelDataSerializableValue } from 'reducers/model'
import { COLLECTION_SIZE_LIMIT } from 'lib/constants'

function devLog(msg: string) {
  devConsoleLog(`[after-change] ${msg}`)
}

export const resizeCollection = (collectionPath: string, defaultValue: any): AfterChangeFunction<number> => ({
  serializedValue,
  changeValueAtPath
}) => {
  const count = serializedValue

  if (!(typeof count === 'number')) return
  // TODO: this is a quick fix to prevent users from adding too many members
  // to a collection and exploding the database. There is probably a more
  // robust solution that prevents invalid data from saving. Currently,
  // we do save onChange and validate onBlur, so the data is saving before
  // it's confirmed valid

  if (count > COLLECTION_SIZE_LIMIT) return

  if (count || count === 0) {
    devLog(`Resizing ${collectionPath} to ${(serializedValue || 'null').toString()}`)

    changeValueAtPath(collectionPath, {
      operation: t.resizeCollection(count, defaultValue)
    })
  }
}

export const ensureCollectionHasCount = (
  collectionPath: string,
  defaultValue: any,
  desiredCount: number
): AfterChangeFunction<ModelDataSerializableValue> => ({ changeValueAtPath, getModelValueAtPath }) => {
  const collection = getModelValueAtPath(collectionPath)

  if (collection.length < desiredCount) {
    devLog(`Resizing ${collectionPath} to ${desiredCount}`)

    changeValueAtPath(collectionPath, {
      operation: t.resizeCollection(desiredCount, defaultValue)
    })
  }
}

type GetPathIndexFunction = (path: string) => number
type GetNewSizeFunction = (index: number, answer: string) => number

const defaultNewSizeFunction: GetNewSizeFunction = (index, answer) => (answer === 'yes' ? index + 1 : index)

export const resizeCollectionFromIndexedQualifier = (
  collectionPath: string,
  defaultValue: any,
  getPathIndex: GetPathIndexFunction,
  getNewSize: GetNewSizeFunction = defaultNewSizeFunction
): AfterChangeFunction<ModelDataSerializableValue> => ({ changeValueAtPath, eventTargetPath, serializedValue }) => {
  const qualifierAnswer = (serializedValue || '').toString()
  const pathIndex = getPathIndex(eventTargetPath)
  const newSize = getNewSize(pathIndex, qualifierAnswer)

  devLog(`Resizing ${collectionPath} at index ${pathIndex} to ${newSize}`)

  changeValueAtPath(collectionPath, {
    operation: t.resizeCollection(newSize, defaultValue)
  })
}

export const clearCollectionFromQualifier = (collectionPath: string): AfterChangeFunction<string> => ({
  serializedValue,
  changeValueAtPath
}) => {
  if (serializedValue !== 'yes') {
    // No default value needed, we're clearing it out.
    const defaultValue = {}

    devLog(`Resizing ${collectionPath} to 0`)

    changeValueAtPath(collectionPath, {
      operation: t.resizeCollection(0, defaultValue)
    })
  }
}
