import React, { ChangeEventHandler, FunctionComponent } from 'react'
import cx from 'classnames'

interface Props {
  inputName: string
  value?: string
  options: any[]
  onChangeEvent: ChangeEventHandler
  disabled?: boolean
  size?: string
}

const defaultProps = {
  value: '',
  size: 'normal'
}

const CommonRadiosV2: FunctionComponent<Props> = ({ inputName, disabled, value, options, onChangeEvent, size }) => {
  return (
    <>
      {options.map((opt, index) => {
        return (
          <div
            key={opt.value}
            className={cx('o-grid--fluid o-grid--stack@sm', {
              'c-paper-form__group o-block': size === 'normal'
            })}
          >
            <label
              className={cx(
                'o-block--compact c-custom-control align-self-start c-custom-control--radio c-custom-control--multiline',
                {
                  'o-block': size === 'normal',
                  'mr-4': size === 'small' && index === 0
                }
              )}
            >
              <div className="c-custom-control__multiline-wrapper">
                <input
                  checked={opt.value === value}
                  type="radio"
                  name={inputName}
                  onChange={onChangeEvent}
                  value={opt.value}
                  disabled={disabled}
                  className="c-custom-control__input"
                />
                <span className="c-custom-control__indicator" />
              </div>
              <span
                className={cx('c-custom-control__description c-type', {
                  'c-type--body-sans-md': size === 'normal',
                  'c-type--body-sans-sm': size === 'small'
                })}
              >
                {opt.label}
              </span>
            </label>
          </div>
        )
      })}
    </>
  )
}

CommonRadiosV2.defaultProps = defaultProps

export default CommonRadiosV2
