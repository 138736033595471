import React, { ChangeEventHandler, FocusEventHandler, FunctionComponent } from 'react'
import CommonLabelV2 from 'components/forms/inputs/v2/address/common/common_label_v2'

import { CLASS_OF_ADMISSION_CODES } from 'lib/visa_types'

import CommonSelectV2 from 'components/forms/inputs/v2/address/common/common_select_v2'

interface Props {
  label?: string
  value?: string
  onChangeEvent: ChangeEventHandler
  onBlurEvent: FocusEventHandler
  disabled?: boolean
}

const defaultProps = {
  label: 'Visa Type',
  value: ''
}

const visaOptions = {
  '': 'Please select a visa class',
  ...CLASS_OF_ADMISSION_CODES
}

const VisaTypes: FunctionComponent<Props> = ({ label, value, onChangeEvent, onBlurEvent, disabled }) => {
  return (
    <div className="o-grid--fluid o-grid--stack@sm o-block c-paper-form__group">
      <CommonLabelV2 label={label} labelFor="country" />
      <CommonSelectV2
        inputName="visa_class_type"
        value={value ? value : ''}
        options={visaOptions}
        containerClass="c-paper-form__control"
        onChangeEvent={onChangeEvent}
        onBlur={onBlurEvent}
        disabled={disabled}
      />
    </div>
  )
}

VisaTypes.defaultProps = defaultProps

export default VisaTypes
