import { Issue, KaseIssuesStore, MessagesById } from 'reducers/issues'
import _pick from 'lodash/pick'
import _orderBy from 'lodash/orderBy'
import { createSelector } from 'reselect'

export const isLoadingIssues = (kaseIssues: KaseIssuesStore) => {
  if (!kaseIssues) return true
  return kaseIssues.loadingIssues
}

export const hasIssues = (kaseIssues: KaseIssuesStore) => {
  if (!kaseIssues) return false
  return kaseIssues.issues && Object.values(kaseIssues.issues).length > 0
}

export const getAllIssues = (kaseIssues: KaseIssuesStore) => {
  if (!kaseIssues) return []
  return Object.values(kaseIssues.issues)
}

export const getIssuesSorted = (kaseIssues: KaseIssuesStore) => {
  if (!kaseIssues) return []
  const sortedIssues = _orderBy(kaseIssues.issues, ['attributes.status', 'attributes.created_at'], ['asc', 'desc'])
  return Object.values(sortedIssues)
}

export const getOpenIssues = (kaseIssues: KaseIssuesStore) => {
  if (!kaseIssues) return []
  return Object.values(kaseIssues.issues).filter((issue) => issue.attributes.status === 'open')
}

export const getIssueById = (kaseIssues: KaseIssuesStore, issueId: string) => {
  if (!issueId) return null
  return kaseIssues?.issues[issueId] ?? null
}

export const getMessages = (kaseIssues: KaseIssuesStore) => {
  return kaseIssues?.messages ?? {}
}

export const getCurrentIssue = (kaseIssues: KaseIssuesStore) => {
  const issueId = kaseIssues?.currentIssueId
  const currentIssue = kaseIssues?.issues[issueId] ?? null
  return currentIssue
}

const filterIssueMessages = (messages: MessagesById, issue?: Issue) => {
  if (!issue) {
    return []
  }
  const messageIdentifiers = issue.relationships?.messages?.data || []
  const messageIds = messageIdentifiers.map((messageIdentifier) => messageIdentifier.id)
  return Object.values(_pick(messages, messageIds))
}

export const getMessagesForIssue = createSelector(
  (state, issueId) => getIssueById(state, issueId),
  getMessages,
  (issue, messages) => filterIssueMessages(messages, issue)
)

export const getMessagesForCurrentIssue = createSelector(
  (kaseIssues) => getCurrentIssue(kaseIssues),
  getMessages,
  (issue, messages) => filterIssueMessages(messages, issue)
)
