import _last from 'lodash/last'
import Decorator from 'lib/decorator'

export interface PanelNode {
  nextPanels: PanelEdge[]
}

export interface PanelEdge {
  condition?: string
  key: string
}

export interface PanelGraph {
  start: string
  panels: { [key: string]: PanelNode }
}

function appendToSequence(graph: PanelGraph, sequence: string[], data: Decorator): string[] {
  const currentPanel = _last(sequence)
  if (!graph.panels[currentPanel]) {
    return
  }

  const edges = graph.panels[currentPanel].nextPanels

  const defaultNextPanel = _last(edges)

  const potentialNextPanels = edges.filter((panel) => Boolean(panel.condition && data[panel.condition]()))
  const nextPanel = potentialNextPanels[0] || defaultNextPanel

  if (nextPanel) {
    sequence.push(nextPanel.key)
    appendToSequence(graph, sequence, data)
  }
}

export function getPanelSequenceFromGraph(graph: PanelGraph, data: Decorator) {
  let sequence = [graph.start]
  appendToSequence(graph, sequence, data)

  return sequence
}
