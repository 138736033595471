import React, { FunctionComponent, HTMLAttributes } from 'react'
import cx from 'classnames'
import { TYPE_SPACING_MAP } from './settings'

type AllowedElementNames = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'small'

export type HeadingProps = HTMLAttributes<HTMLHeadingElement> & {
  /**
   * The color of the text.
   */
  color?: 'primary' | 'warning' | 'success' | 'accent' | 'emphasized'
  /**
   * The relative font size of the element.
   * Note that sub-headers don't come in a large size.
   */
  size?: 'lg' | 'md' | 'sm'
  /**
   * The amount of space below the element. This space is canceled out if the
   * element is the last o-block in a o-box.
   */
  spacing?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'none'
  /**
   * If true, the heading will be styed as a sub-header (usually all caps)
   * Note that sub-headers don't come in a large size.
   */
  subhead?: boolean
  /**
   * If true, the text will be centered horizontally.
   */
  centered?: boolean

  /**
   * If false, o-block style won't be applied.
   */
  block?: boolean
  /**
   * The HTML tag to render in. This is a required property to encourage good
   * layout practices.
   * https://www.w3.org/WAI/tutorials/page-structure/headings
   */
  tag: AllowedElementNames
}

const Heading: FunctionComponent<HeadingProps> = (props) => {
  const {
    className,
    children,
    color,
    size = 'md',
    subhead,
    spacing,
    tag,
    centered,
    block = true,
    ...otherProps
  } = props

  const spacingClassName = TYPE_SPACING_MAP[spacing]

  const classes = cx(className, 'c-type', {
    'o-block': block,
    'c-type--headline-lg': size === 'lg' && !subhead,
    'c-type--headline-md': size === 'md' && !subhead,
    'c-type--headline-sm': size === 'sm' && !subhead,
    'c-type--subhead-md': size !== 'sm' && subhead, // There's no lg size for subheaders, so catch all non-sm sizes
    'c-type--subhead-sm': size === 'sm' && subhead,
    'o-layout--center': centered,
    't-color--primary': color === 'primary',
    't-color--success': color === 'success',
    't-color--warning': color === 'warning',
    't-color--accent': color === 'accent',
    'c-type--emphasized': color === 'emphasized',
    [`o-block--${spacingClassName}`]: !!spacingClassName
  })

  const HeadingTag = tag

  return (
    <HeadingTag className={classes} {...otherProps}>
      {children}
    </HeadingTag>
  )
}

export default Heading
