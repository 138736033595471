import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'
import { isMobileBrowser } from 'reducers/selectors'

interface ExplicitProps {
  mobile?: boolean
  desktop?: boolean
}

interface MappedProps {
  isMobileBrowser: boolean
}

/**
 * Component that wraps our breakpoint system.
 * @example <BreakpointWrapper mobile>Mobile-only content</BreakpointWrapper>
 * @example <BreakpointWrapper desktop>Desktop-only content</BreakpointWrapper>
 */
const BreakpointWrapper: FunctionComponent<ExplicitProps & MappedProps> = ({
  children,
  isMobileBrowser,
  mobile,
  desktop
}) => {
  if ((isMobileBrowser && mobile) || (!isMobileBrowser && desktop)) {
    return <>{children}</>
  }

  return null
}

function mapStateToProps(state: any): MappedProps {
  return {
    isMobileBrowser: isMobileBrowser(state)
  }
}

export default connect(mapStateToProps)(BreakpointWrapper) as FunctionComponent<ExplicitProps>
