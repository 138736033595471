import React, { FunctionComponent } from 'react'

import ConfirmMoveToQA from 'components/screens/dashboard/customer_dashboard/confirm_move_to_qa.tsx'
import IntakePrimaryCTA from 'components/screens/dashboard/customer_dashboard/phases/intake_phase/intake_primary_cta'

interface ExplicitProps {
  readyToMoveToQA?: boolean
}

type Props = ExplicitProps

const IncompleteQuestionnairesCTA: FunctionComponent<Props> = ({ readyToMoveToQA }) => {
  if (!readyToMoveToQA) {
    return <IntakePrimaryCTA />
  } else {
    return <ConfirmMoveToQA inWorkArea={false} />
  }
}

export default IncompleteQuestionnairesCTA
