import { createSelector } from 'reselect'

export const getCurrentQuestionnaire = (state) => state.questionnaire.data

export const getCurrentQuestionnaireId = (state) => state.questionnaire.data?.id

export const getCurrentQuestionnaireSections = (state) =>
  state.questionnaire.data?.attributes?.questionnaire_sections?.map((section) => section)

/**
 * Pulls all pages from their section arrays into 1 array for easier url matching
 *
 * Also adds the sectionName prop to the page as its used in the panel view and
 * otherwise unavailable. Consider moving this to reducer level once that work is
 * completed.
 *
 * @returns QuestionnairePage[]
 */
export const getAllQuestionnairePages = createSelector(getCurrentQuestionnaire, (questionnaire) => {
  if (!questionnaire) {
    return []
  }

  return questionnaire.attributes.questionnaire_sections.reduce((pages, section) => {
    const sectionPages = section?.pages?.map((page) => {
      return { ...page, sectionName: section.name }
    })
    return pages.concat(sectionPages)
  }, [])
})
