export const defaultTypes = {
  types: ['application/pdf', 'image/png', 'image/jpeg', 'image/jpg'],
  names: ['PDF', 'PNG', 'JPEG']
}

export const pdfs = {
  types: ['application/pdf'],
  names: ['PDF']
}

export function defaultAcceptedFileTypesForDocumentRequest() {
  return defaultTypes
}

export function acceptedFileTypesForDocumentRequest(documentRequest) {
  switch (documentRequest.document_type) {
    case 'self_sufficiency_questionnaire':
    case 'joint_sponsor_questionnaire':
    case 'evidence_of_relationship_photos': {
      return pdfs
    }
    default: {
      return defaultAcceptedFileTypesForDocumentRequest()
    }
  }
}
