import React, { FunctionComponent, useEffect, useState } from 'react'
import { ApplicationState } from 'reducers'
import { connect } from 'react-redux'
import {
  getDocRequestsLeftBeforeThreshold,
  getInitialThresholdReached,
  groupSortedDocumentRequests,
  slaIsSet,
  getDocumentSets,
  getCurrentDocSetIndex
} from 'reducers/selectors'
import CollapsibleOutlineSection from './collapsible_outline_section'
import QuestionCircleIcon from 'components/icons/question_circle_icon'
import ProcessModal from './process_modal'
import { DocumentRequestModelV2, DocumentSet } from 'reducers/documents_v2'

interface ExplicitProps {
  /**
   * The document request that's currently displayed.
   */
  activeRequest?: DocumentRequestModelV2
  /**
   * Callback that's triggered when a document request is clicked.
   */
  onClick: (docSetIndex: number, docRequestIndex: number) => void
}

interface MappedProps {
  requestCountLeftBeforeThreshold: number
  initialThresholdReached: boolean
  slaIsSet: boolean
  documentSets: DocumentSet[]
  currentDocSetIndex: number
}

const DocumentUploadOutline: FunctionComponent<ExplicitProps & MappedProps> = ({
  onClick,
  activeRequest,
  slaIsSet,
  documentSets,
  currentDocSetIndex
}) => {
  const [displayProcessModal, toggleDisplayProcessModal] = useState(false)
  const [activeDocumentSetIndex, setActiveDocumentSetIndex] = useState(0)

  const activeRequestId = activeRequest != null ? activeRequest.id : null

  const onRequestClicked = (docSetIndex: number, docRequestIndex: number) => {
    onClick(docSetIndex, docRequestIndex)
  }

  const onDocumentSetClicked = (docSetIndex: number) => {
    setActiveDocumentSetIndex(docSetIndex)
  }

  const isActiveDocumentSet = (docSetIndex) => {
    return docSetIndex === activeDocumentSetIndex
  }

  useEffect(() => {
    setActiveDocumentSetIndex(currentDocSetIndex)
  }, [currentDocSetIndex])

  return (
    <div className="o-action-items__outline px-3 lg:px-6">
      <ProcessModal isOpen={displayProcessModal} closeModal={() => toggleDisplayProcessModal(false)} />
      <div className="o-griddle__row">
        <div className="o-griddle__col">
          <button
            className="o-action-items__next-step border border-l-8 border-t-1 border-r-1 border-b-1 border-blue-500 flex justify-between items-center mt-4"
            onClick={() => toggleDisplayProcessModal(true)}
          >
            <span className="o-action-items__next-step-content c-type c-type--body-sans-sm">Instructions</span>
            <QuestionCircleIcon />
          </button>
        </div>
      </div>

      {documentSets.map((docSet, index) => (
        <CollapsibleOutlineSection
          key={docSet.name}
          setIndex={index}
          title={docSet.name}
          documentRequests={docSet.document_requests}
          onRequestClicked={onRequestClicked}
          activeRequestId={activeRequestId}
          slaIsSet={slaIsSet}
          isActiveDocumentSet={isActiveDocumentSet(index)}
          onDocumentSetClicked={() => onDocumentSetClicked(index)}
        />
      ))}
    </div>
  )
}

function mapStateToProps(state: ApplicationState) {
  return {
    requestCountLeftBeforeThreshold: getDocRequestsLeftBeforeThreshold(state),
    initialThresholdReached: getInitialThresholdReached(state),
    groupedDocumentRequests: groupSortedDocumentRequests(state),
    slaIsSet: slaIsSet(state),
    documentSets: getDocumentSets(state),
    currentDocSetIndex: getCurrentDocSetIndex(state)
  }
}

export default connect(mapStateToProps, null)(DocumentUploadOutline) as FunctionComponent<ExplicitProps>
