import React, { FunctionComponent, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import {
  getCurrentKaseId,
  getIssuesSorted,
  getStewardInfo,
  isLoadingIssues,
  isLoadingTasks,
  getCurrentKaseState
} from 'reducers/selectors'

import { loadIssues, addMessage, setCurrentIssue } from 'actions/issue_actions'
import { assignTask, loadTasks } from 'actions/tasks_actions'

import { Issue } from 'reducers/issues'

import BreakpointWrapper from 'components/breakpoint_wrapper'
import LoadingSkeleton from 'components/screens/issues_v2/loading_skeleton'
import SubmitTaskButton from 'components/screens/issues_v2/submit_task_button'
import Outline from 'components/screens/issues_v2/outline'
import HelpSection from 'components/screens/issues_v2/help_section'
import IssuesContainer from './issues_container'
import ThreadPanel from './thread_panel'

interface MappedProps {
  currentIssueID?: string
  isLoading?: boolean
  issues: Issue[]
  kaseId: number
  kaseState: string
  stewardEmail: string
}
interface ActionProps {
  addMessage: typeof addMessage
  assignTask: typeof assignTask
  loadIssues: typeof loadIssues
  loadTasks: typeof loadTasks
  setCurrentIssue: typeof setCurrentIssue
}

const IssuesV2: FunctionComponent<MappedProps & ActionProps> = (props) => {
  const {
    addMessage,
    currentIssueID,
    isLoading,
    issues,
    kaseId,
    kaseState,
    loadIssues,
    loadTasks,
    setCurrentIssue,
    stewardEmail
  } = props

  const [isThreadOpen, setIsThreadOpen] = useState(false)

  const saveNewMessage = (reply: string) => {
    if (!currentIssueID) {
      return
    }

    addMessage({ kaseId, issueId: currentIssueID, text: reply })
  }

  const onOpenThread = (issue) => {
    setCurrentIssue(issue.id)
    setIsThreadOpen(!isThreadOpen)
  }

  const closePanel = () => {
    setIsThreadOpen(!isThreadOpen)
  }

  useEffect(() => {
    if (kaseId) {
      loadIssues({ kaseId })
    }
  }, [kaseId])

  useEffect(() => {
    if (kaseId) {
      loadTasks({ kaseId })
    }
  }, [kaseId])

  if (isLoading) {
    return <LoadingSkeleton />
  }

  return (
    <div className="o-action-items__wrapper">
      <BreakpointWrapper mobile>
        <div className="o-box o-box--light p-4" style={{ minHeight: '90vh' }}>
          <IssuesContainer issues={issues} onOpenThread={onOpenThread} kaseState={kaseState} />
        </div>
      </BreakpointWrapper>
      <BreakpointWrapper desktop>
        <div className="o-griddle__row o-griddle__row--no-gutters">
          <aside className="o-griddle__col--3">
            <Outline />
          </aside>
          <main className="o-griddle__col--6">
            <div className="o-action-items__center-column">
              <IssuesContainer issues={issues} onOpenThread={onOpenThread} kaseState={kaseState} />
            </div>
          </main>
          <aside className="o-griddle__col o-griddle__col--3 px-4 pt-16 lg:px-6">
            <div className="ml-3">
              <HelpSection stewardEmail={stewardEmail} kaseState={kaseState} />
            </div>
          </aside>
        </div>
      </BreakpointWrapper>
      <ThreadPanel isOpen={isThreadOpen} closePanel={closePanel} submitReply={saveNewMessage} />
      <div className="u-hide@md-up c-issues-sticky-footer">
        <div className="c-issues-sticky-footer__row">
          <SubmitTaskButton />
        </div>
      </div>
    </div>
  )
}

function mapStateToProps(state: any): MappedProps {
  const isLoading = isLoadingIssues(state) || isLoadingTasks(state)
  const issues = getIssuesSorted(state)

  return {
    currentIssueID: state.kaseIssues.currentIssueId,
    isLoading: isLoading,
    issues,
    kaseId: getCurrentKaseId(state),
    kaseState: getCurrentKaseState(state),
    stewardEmail: getStewardInfo(state).email || ''
  }
}

function mapDispatchToActions(dispatch: Dispatch) {
  return bindActionCreators(
    {
      assignTask,
      loadIssues,
      loadTasks,
      addMessage,
      setCurrentIssue
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToActions)(IssuesV2) as FunctionComponent
