// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import 'babel-polyfill'
import 'stylesheets/client.scss'
import 'javascript/index'
import 'javascript/lib/spec_support'

if (process.env.RAILS_ENV === 'test') {
  require('stylesheets/disable_visual_animations')

  // Disable jquery animation transitions if we use any.
  // https://github.com/twbs/bootstrap/pull/16157
  if (window.$) {
    window.$.support.transition = false
  }
}
