import React from 'react'

import { QuestionnaireInputContainerProps } from 'components/forms/inputs'
import { getAfterChangeHooks } from 'components/forms/field_after_change_events'
import ValidatedInput from 'components/forms/validated_input'

import RadioInput from 'components/forms/inputs/radio/radio_input'
import { connect } from 'react-redux'
import { dollarsWithCommas } from 'lib/format_money'
import { minimumIncomeRequirementAmount } from 'resource_context/marriage_based_green_card/helpers'

interface MappedProps {
  incomeRequirementLevels: number[]
}

type Props = QuestionnaireInputContainerProps &
  MappedProps & {
    clear_panel_fields?: boolean
    no_wrap?: boolean
  }

class SpecializedIncomeQuestionRadio extends React.Component<Props> {
  static defaultProps = {
    name: '',
    onChange: () => {}
  }

  getChoiceLabels() {
    const { incomeRequirementLevels } = this.props
    const labels = incomeRequirementLevels.map((_, index) => (index === 0 ? 'minimum' : `level${index}`))
    let index = 0
    const choiceDict = {}
    while (index <= labels.length) {
      if (index === 0) {
        const minLevelCurrency = dollarsWithCommas(incomeRequirementLevels[index])
        choiceDict['less_than_minimum'] = `Less than $${minLevelCurrency}`
      } else if (index < labels.length) {
        const priorLabel = labels[index - 1]
        const priorLevelCurrency = dollarsWithCommas(incomeRequirementLevels[index - 1])
        const curLabel = labels[index]
        const curLevelCurrency = dollarsWithCommas(incomeRequirementLevels[index])

        choiceDict[`${priorLabel}_to_${curLabel}`] = `$${priorLevelCurrency} - $${curLevelCurrency}`
      } else {
        const priorLabel = labels[index - 1]
        const priorLevelCurrency = dollarsWithCommas(incomeRequirementLevels[index - 1])

        choiceDict[`more_than_${priorLabel}`] = `More than $${priorLevelCurrency}`
      }

      index++
    }

    return choiceDict
  }

  render() {
    const { clear_panel_fields, disabled, name, no_wrap, path, after_change_event_list } = this.props
    const afterChangeEvents = getAfterChangeHooks(after_change_event_list)
    const choiceLabels = this.getChoiceLabels()
    const choices = Object.keys(choiceLabels)

    return (
      <ValidatedInput disabled={disabled} path={path}>
        {({ fireValidation }) => (
          <RadioInput
            id={path}
            afterChangeEvents={afterChangeEvents}
            shouldClearPanelFields={clear_panel_fields}
            disabled={disabled}
            name={name}
            path={path}
            choices={choices}
            noWrap={no_wrap}
            choiceLabels={choiceLabels}
            fireValidation={fireValidation}
            className={'o-block c-paper-form__group'}
          />
        )}
      </ValidatedInput>
    )
  }
}

const getIncomeRequirementLevels = (state: any): number[] => {
  const minIncome = minimumIncomeRequirementAmount(state)

  return [minIncome, 60000, 120000].filter((amount) => amount >= minIncome)
}

function mapStateToProps(state: any): MappedProps {
  return {
    incomeRequirementLevels: getIncomeRequirementLevels(state)
  }
}

export default connect(mapStateToProps, null)(SpecializedIncomeQuestionRadio)
