import server from 'lib/api/v1/server'
import { AxiosPromise } from 'axios'
import { QuestionnaireV2Response } from './mock-v2-questionnaire-response'
import { QuestionnaireOutlineV2Response } from './mock-questionnaire-outline-response'
import { MarkPageViewed, SaveQuestionForQuestionnaireParams } from 'actions/questionnaire_actions'

interface LoadQuestionnaireV2Params {
  kaseId: number
  questionnaireSlug: string
  page?: string
}

const FormatQuestionnaireUrlWithId = (kaseId: number, questionnaireId: string) => {
  return `/api/v2/kases/${kaseId}/questionnaires/${questionnaireId}`
}

const FormatQuestionnaireUrlWithSlug = (kaseId: number, questionnaireSlug: string) => {
  return `/api/v2/kases/${kaseId}/questionnaires/by_slug/${questionnaireSlug}`
}

function index(request: LoadQuestionnaireV2Params): AxiosPromise<QuestionnaireV2Response> {
  const { kaseId, questionnaireSlug, page } = request

  return server
    .get(
      FormatQuestionnaireUrlWithSlug(kaseId, questionnaireSlug) + (page ? `?page_path=${encodeURIComponent(page)}` : '')
    )
    .then((response) => {
      return response
    })
    .catch((error) => Promise.reject(error))
}

function postQuestionnaireAnswerV2(request: SaveQuestionForQuestionnaireParams): AxiosPromise<QuestionnaireV2Response> {
  const { kaseId, questionnaireId, questionId, answer, answerId, currentPageId } = request

  const currentPageParam = currentPageId ? `?current_page=${currentPageId}` : ''

  if (answerId) {
    return server
      .patch(FormatQuestionnaireUrlWithId(kaseId, questionnaireId) + '/answers/' + answerId + currentPageParam, {
        question_id: questionId,
        text: answer,
        kase_id: kaseId
      })
      .then((response) => {
        return response
      })
      .catch((error) => Promise.reject(error))
  }

  return server
    .post(FormatQuestionnaireUrlWithId(kaseId, questionnaireId) + '/answers' + currentPageParam, {
      question_id: questionId,
      text: answer,
      kase_id: kaseId
    })
    .then((response) => {
      return response
    })
    .catch((error) => Promise.reject(error))
}

function markPageViewed(request: MarkPageViewed): AxiosPromise<QuestionnaireV2Response> {
  const { kaseId, pageId } = request
  return server
    .post(`/api/v2/kases/${kaseId}/pages/${pageId}/page_views`)
    .then((response) => {
      return response
    })
    .catch((error) => Promise.reject(error))
}

function fetchQuestionnaireOutline(request: LoadQuestionnaireV2Params): AxiosPromise<QuestionnaireOutlineV2Response> {
  const { kaseId, questionnaireSlug } = request

  // GET /api/v2/kases/:kase_id/questionnaires/by_slug/:slug/outline/navigation.json
  return server
    .get(FormatQuestionnaireUrlWithSlug(kaseId, questionnaireSlug) + '/outline/navigation.json')
    .then((response) => {
      return response
    })
    .catch((error) => Promise.reject(error))
}

export default {
  fetchQuestionnaireOutline,
  index,
  postQuestionnaireAnswerV2,
  markPageViewed
}
