import { QuestionMapping } from './types'

/** We use questionMapping in order to map the questions into their cart question equivalent
 * beneficiary_visa_type and mbgc_k1_married_within_90_days come from the backend via payment_details
 * age_of_applying_child is used when numberOfKids > 0 and we only store its value in the frontend
 * for the purpose of calculating USCIS fees
 */
export const QUESTION_MAPPINGS: {
  [key: string]: QuestionMapping
} = {
  beneficiary_visa_type: {
    label: 'What U.S. visa is the beneficiary currently on?',
    type: 'select'
  },
  mbgc_k1_married_within_90_days: {
    label: 'Did the sponsor and beneficiary get married within 90 days of the beneficiary’s most recent U.S. entry?',
    type: 'radio',
    options: [
      {
        label: 'Yes',
        value: 'yes'
      },
      {
        label: 'No',
        value: 'no'
      }
    ]
  },
  age_of_applying_child: {
    label: 'How old is your applying child?',
    type: 'radio',
    options: [
      {
        label: 'Under 14',
        value: 'child'
      },
      {
        label: '14 or older',
        value: 'teen'
      }
    ]
  }
}

export const CHILD_NUMBERS: { [key: number]: string } = {
  0: 'first',
  1: 'second',
  2: 'third',
  3: 'fourth',
  4: 'fifth',
  5: 'sixth',
  6: 'seventh',
  7: 'eighth',
  8: 'ninth'
}
