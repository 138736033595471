import React from 'react'
import _defaults from 'lodash/defaults'

import formatDate from '../../../lib/format_date'

function dateOrUnknown(date) {
  if (date) {
    return formatDate.reviewScreen(date)
  } else {
    return 'Unknown'
  }
}

export default function datedEntryLabel(model, options = {}) {
  _defaults(options, { current: false, startOverride: null, prepend: null })

  const start = options.startOverride || dateOrUnknown(model.start_date)
  let end

  if (model.end_date.present || options.current) {
    end = 'Present'
  } else {
    end = dateOrUnknown(model.end_date.date)
  }

  return (
    <div>
      {options.prepend}
      {options.prepend && <br />}
      {start} to
      <br /> {end}
    </div>
  )
}
