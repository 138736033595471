import { createTrackingId } from './common/id'
import { initializeScrollTracking } from './common/scroll_tracking'
import loadSegment from './common/segment'
import TelemetryReporter from './reporter'
import TelemetryService from './service'
import { getMetaValue } from '../meta_tags'
import { getCurrentKaseKind, getCurrentUserTelemetry } from 'reducers/selectors'

export function initializeTelemetry(options = {}) {
  if (window.telemetryService) return window.telemetryService

  options.segmentKey = options.segmentKey || getMetaValue('segment-api-key')

  // for details on forceAdminTelemetry see tools.tsx
  const { reportingEnvironment, segmentKey, forceAdminTelemetry } = options

  /* eslint-disable no-console */
  if (!segmentKey && process.env.RAILS_ENV !== 'test') {
    console.warn('Missing segmentKey in initializeTelemetry(). Set <meta name="segment-api-key" />')
  }

  if (!reportingEnvironment) {
    console.warn('Missing reportingEnvironment in initializeTelemetry()')
  }
  /* eslint-enable no-console */

  const reduxStore = options.reduxStore || null
  const state = reduxStore.getState()
  const userData = getCurrentUserTelemetry(state)
  const currentKaseKind = getCurrentKaseKind(state)

  /* eslint-disable no-console */
  console.log(`Initializing telemetry with user data: ${JSON.stringify(userData)}`)
  /* eslint-enable no-console */

  const telemetryService = new TelemetryService(userData)

  telemetryService.setReporter(
    new TelemetryReporter({
      reportingEnvironment,
      currentKaseKind,
      forceAdminTelemetry
    })
  )

  window.telemetryService = telemetryService

  const _doInitialTracking = () => {
    // run the identify call
    telemetryService.identify()
    // enqueue the initial tracking calls
    telemetryService.trackPageView()
    // enqueue scroll tracking
    initializeScrollTracking(telemetryService)
  }

  const _trackingIsDisabled = () => {
    /* eslint-disable no-console */
    console.warn('Could not reach Segment.')
    /* eslint-enable no-console */
  }

  createTrackingId()

  if (segmentKey) {
    // support for app
    loadSegment(segmentKey).then(_doInitialTracking, _trackingIsDisabled)
  } else {
    // support for tests
    _doInitialTracking()
  }
}

const getTelemetryService = () => window.telemetryService

export default getTelemetryService
