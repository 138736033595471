import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'

import { KaseKind } from 'lib/constants'
import getTelemetryService from 'lib/telemetry'
import { getCurrentKaseKind } from 'reducers/selectors'

import Accordion from 'components/accordion'
import ArrowIllustration from 'components/illustrations/arrow_illustration'
import Button from 'components/button'
import DashboardEmailIllustration from 'components/illustrations/dashboard_email_illustration'
import DashboardSearchIllustration from 'components/illustrations/dashboard_search_illustration'
import DashboardInfoIllustration from 'components/illustrations/dashboard_info_illustration'
import ExternalLink from 'components/external_link'
import Paragraph from 'components/type/paragraph'

import K1GuideThumb from 'images/dashboard/k1-diy-guide-thumb.png'
import MBGCCPGuideThumb from 'images/dashboard/mbgc-cp-diy-guide-thumb.png'
import { K1_CP_TIMELINE_STEPS, MBGC_CP_TIMELINE_STEPS } from './content'

type Props = {
  isGC: boolean
  isK1: boolean
}

interface TrackLink {
  newTab: boolean
  linkName: string
  redirectTo: string
}

const CustomerCompletedCP: FunctionComponent<Props> = ({ isGC, isK1 }) => {
  const S3_URL = 'https://boundless-assets.s3.us-west-2.amazonaws.com/' // boundless-assets bucket
  const trackLink: TrackLink = {
    linkName: 'MBGC CP DIY Guide',
    redirectTo: `${S3_URL}Boundless-Green-Card-CP-Guide.pdf`,
    newTab: true
  }
  let timelineSteps = MBGC_CP_TIMELINE_STEPS
  let cta = {
    thumbnail: MBGCCPGuideThumb,
    intro: 'Once your I-130 is approved, you’ll begin Consular Processing.'
  }

  // K-1 overrides
  if (isK1) {
    timelineSteps = K1_CP_TIMELINE_STEPS
    trackLink.linkName = 'K1 CP DIY Guide'
    trackLink.redirectTo = `${S3_URL}Boundless-K1-CP-Guide.pdf`
    cta.thumbnail = K1GuideThumb
    cta.intro = 'Once your I-129F is approved, you’ll begin Consular Processing.'
  }

  const onClickDownloadGuide = () => {
    getTelemetryService().trackLink(trackLink)
  }

  return (
    <div className="o-block o-box o-box--light c-dashboard__work-area o-layout--impose">
      <div className="o-griddle__col px-0 pb-8">
        <div className="o-griddle__row o-box pb-0">
          <div className="o-griddle__col--hide o-griddle__col--show-sm-up o-griddle__col--auto pl-0">
            <ArrowIllustration />
          </div>
          <div className="o-griddle__col pl-4">
            <h2 className="o-block c-type c-type--headline-md c-type--emphasized">
              Next: Get Our Step-By-Step Guide to Consular Processing
            </h2>
            <div className="o-griddle__row">
              <div className="o-griddle__col--4">
                <button
                  onClick={onClickDownloadGuide}
                  style={{ background: 'none', border: 'none', cursor: 'pointer' }}
                >
                  <img
                    src={cta.thumbnail}
                    alt="Consular Processing DIY Guide"
                    style={{ width: '100%', height: 'auto' }}
                  />
                </button>
              </div>
              <div className="o-griddle__col-8">
                <Paragraph>{`${cta.intro}  This involves working with a U.S. embassy to complete your application, including scheduling your interview. For detailed instructions, we highly recommend reading our Consular Processing guide, which we’ll refer to as we walk you through next steps.`}</Paragraph>
                <Button color="primary" label="Download the Guide" onClick={onClickDownloadGuide} />
              </div>
            </div>
          </div>
        </div>
        <div className="o-griddle__row o-box pb-0">
          <div className="o-griddle__col--hide o-griddle__col--show-sm-up o-griddle__col--auto pl-0">
            <DashboardEmailIllustration />
          </div>
          <div className="o-griddle__col pl-4 mt-4">
            <h3 className="o-block c-type c-type--headline-sm c-type--emphasized">Get Updates On Your Application</h3>
            <Paragraph>
              To allow USCIS to send email updates on your application, create an online account on USCIS.gov by
              following these{' '}
              <ExternalLink href="https://www.uscis.gov/file-online/how-to-create-a-uscis-online-account">
                instructions
              </ExternalLink>
              . You will be able to get updates after the agency has provided your “receipt numbers” (official code from
              USCIS confirming that they’ve received your application package).
            </Paragraph>
          </div>
        </div>

        <div className="o-griddle__row o-box pb-0">
          <div className="o-griddle__col--hide o-griddle__col--show-sm-up o-griddle__col--auto pl-0">
            <DashboardSearchIllustration />
          </div>
          <div className="o-griddle__col pl-4 mt-4">
            <h3 className="o-block c-type c-type--headline-sm c-type--emphasized">Check Your Case Status</h3>
            <Paragraph>
              <ExternalLink href="https://egov.uscis.gov/casestatus/landing.do">Enter the receipt number </ExternalLink>
              on your USCIS receipt notices to stay updated on the status of your application. Please keep in mind that
              though this is usually one of the quickest, most effective ways to track your application, occasionally
              the site is not updated as it should be.
            </Paragraph>
          </div>
        </div>
        {isGC ? (
          <div className="o-griddle__row o-box">
            <div className="o-griddle__col--hide o-griddle__col--show-sm-up o-griddle__col--auto pl-0">
              <DashboardInfoIllustration />
            </div>
            <div className="o-griddle__col pl-4 mt-4">
              <h2 className="o-block c-type c-type--headline-sm c-type--emphasized">Keep Us Posted</h2>
              <Paragraph>
                To help you prepare for what’s next, it’s important you notify the{' '}
                <ExternalLink href="mailto:support.team@boundless.com">Boundless Support Team </ExternalLink>when:
              </Paragraph>
              <Paragraph>
                1. USCIS approves your family-based petition (Form I-130)
                <br />
                2. You receive your Interview Appointment Notice
              </Paragraph>
              <Paragraph>For more details on when to expect these, please read the timeline below.</Paragraph>
            </div>
          </div>
        ) : null}
      </div>
      <h3 className="mb-3 mt-5 text-black">
        Consular Processing Timeline for Your {isK1 ? 'K-1 Fiancé Visa' : 'Marriage-Based Green Card'}:
      </h3>
      <hr className="c-divider c-divider--primary c-divider--emphasized" />
      <ul className="o-block c-list">
        {timelineSteps?.map((step, index) => (
          <li key={step.title}>
            <Accordion
              className="border-l-0 border-r-0 border-t-0 py-4"
              noXPadding={true}
              header={
                <div className="flex justify-between items-center">
                  <span className="c-type--bold">
                    {index + 1}. {step.title}
                  </span>
                  <span className="u-hide@md-down pr-1">Learn More</span>
                </div>
              }
            >
              {step.description}
            </Accordion>
          </li>
        ))}
      </ul>
    </div>
  )
}

function mapStateToProps(state: object) {
  return {
    isGC: getCurrentKaseKind(state) === KaseKind.NewMarriageBasedGreenCard,
    isK1: getCurrentKaseKind(state) === KaseKind.K1FianceVisa
  }
}

export default connect(mapStateToProps)(CustomerCompletedCP)
