import React, { FunctionComponent, useEffect, useState } from 'react'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import classNames from 'classnames'
import Dropzone, { DropzoneRef } from 'react-dropzone'

import { uploadRequestedFiles, uploadRequestedFileProgress, uploadRequestedFileFinished } from 'actions/upload_actions'

import { postTelemetry } from 'actions/telemetry_actions'

import UploadQueue, { SignResult, UploadResult } from 'lib/upload_queue'
import { listToSentence } from 'lib/language_helpers'
import { TELEMETRY_EVENTS } from 'lib/constants'

import { addUploadsToCurrentDocRequest, fetchDocumentSets } from 'actions/document_request_actions_v2'
import { acceptedFileTypesForDocumentRequest } from 'lib/accepted_files_types'
import { getCurrentKaseId } from 'admin/reducers/selectors'
import { getCurrentDocRequest, getWorkflowStatus, getDocumentUploadLocation } from 'reducers/selectors'
import S3Upload, { UploadProgress } from 'lib/admin/s3_upload'
import { DocumentRequestModelV2 } from 'reducers/documents_v2'

interface MappedProps {
  currentDocRequest: DocumentRequestModelV2
  kaseId: number
  workflowStatus: string | null // confirm this is ok
  documentUploadLocation?: string
}

interface ActionProps {
  fetchDocumentSets: typeof fetchDocumentSets
  addUploadsToCurrentDocRequest: typeof addUploadsToCurrentDocRequest
  uploadRequestedFiles: typeof uploadRequestedFiles
  uploadRequestedFileProgress: typeof uploadRequestedFileProgress
  uploadRequestedFileFinished: typeof uploadRequestedFileFinished
  postTelemetry: typeof postTelemetry
}

interface ExplicitProps {
  uploadLimit: number
  documentRequest: DocumentRequestModelV2
  documentRequestPartId?: number
  uploadDisabled?: boolean
}

type Props = ExplicitProps & MappedProps & ActionProps

const DocumentRequestUploadArea: FunctionComponent<Props> = ({
  currentDocRequest,
  kaseId,
  workflowStatus,
  documentUploadLocation,
  fetchDocumentSets,
  addUploadsToCurrentDocRequest,
  uploadRequestedFiles,
  uploadRequestedFileProgress,
  uploadRequestedFileFinished,
  postTelemetry,
  uploadLimit,
  documentRequest,
  documentRequestPartId,
  uploadDisabled
}) => {
  const [uploadErrorMessage, setUploadErrorMessage] = useState('')
  const [isUploading, setIsUploading] = useState(false)

  const classes = classNames(
    'o-block',
    'c-card',
    'c-card--uploader',
    'c-card--transparent',
    'c-dropzone',
    'text-center'
  )

  let uploadingBy: string

  const onAllUploadsComplete = (uploadResults: UploadResult[]) => {
    addUploadsToCurrentDocRequest({
      kaseId,
      docRequestId: currentDocRequest.id,
      docRequestPartId: documentRequestPartId,
      uploadResults
    })
      .then(() => {
        setIsUploading(false)
        return fetchDocumentSets(kaseId)
      })
      .then(() => {
        uploadResults.forEach((result) => {
          uploadRequestedFileFinished({
            rawFile: result.rawFile,
            uploadingBy: uploadingBy,
            documentType: documentRequest.name,
            workflowStatus: workflowStatus
          })

          postTelemetry(TELEMETRY_EVENTS.UPLOADED_DOCUMENT, {
            fileSize: result.rawFile.size,
            documentType: documentRequest.name,
            documentContentType: result.signResult.contentType,
            uploadingFrom: documentUploadLocation,
            uploadingBy: uploadingBy,
            workflowStatus: workflowStatus,
            _analyticsIntegrations: {
              All: true,
              AutopilotHQ: false
            }
          })
        })
      })
  }

  let dropzoneRef: DropzoneRef
  let uploadQueue: UploadQueue = new UploadQueue({
    onUploadsComplete: onAllUploadsComplete
  })

  useEffect(() => {
    window.addEventListener(
      'dragover',
      (event) => {
        event.preventDefault()
      },
      false
    )
    window.addEventListener(
      'drop',
      (event) => {
        event.preventDefault()
      },
      false
    )
  }, [])

  useEffect(() => {
    setUploadErrorMessage('')
  }, [documentRequest])

  const onFileUploadClick: React.MouseEventHandler<HTMLAnchorElement> = (event) => {
    event.preventDefault()

    uploadingBy = 'UploadDialog'
    dropzoneRef.open()
  }

  // Although not ideal this is the best function available to us from Dropzone
  // to differentiate drag and drop uploads from uploads via clicking the file
  // upload button.
  const handleDragEnter = () => {
    uploadingBy = 'DragAndDrop'
  }

  const onUploadRequestedFileProgress = (percent: number, status: UploadProgress, rawFile: File) => {
    uploadRequestedFileProgress({
      percent,
      status,
      rawFile
    })
  }

  const onUploadFinish = (signResult: SignResult, rawFile: File) => {
    uploadQueue.addResult({ rawFile, signResult })
  }

  const acceptedFileTypes = () => {
    return acceptedFileTypesForDocumentRequest(documentRequest)
  }

  const handleDrop = (acceptedFiles: File[], rejectedFiles: File[]) => {
    if (rejectedFiles.length > 0) {
      setUploadErrorMessage(
        `Error uploading file(s). Please make sure you are uploading files of the following file types: ${acceptedFileTypes().names.join(
          ', '
        )}`
      )
    } else if (acceptedFiles.length > uploadLimit) {
      setUploadErrorMessage(`Can only upload ${uploadLimit} more ${uploadLimit > 1 ? 'files' : 'file'} to this part.`)
    } else {
      setUploadErrorMessage('')
      setIsUploading(true)
      // Signal to the queue that we need to wait on additional uploads
      // before firing a data update.
      uploadQueue.addCount(acceptedFiles.length)

      uploadRequestedFiles({
        files: acceptedFiles,
        documentRequest,
        documentRequestPartId
      })

      new S3Upload(acceptedFiles, kaseId, {
        onProgress: onUploadRequestedFileProgress,
        onUploadComplete: onUploadFinish
      })
    }
  }

  return (
    <>
      {uploadErrorMessage.length > 0 && (
        <div className="c-alert c-alert--danger">
          <div className="c-type c-type--body-sans-md">{uploadErrorMessage}</div>
        </div>
      )}
      <Dropzone
        accept={acceptedFileTypes().types.join(',')}
        acceptClassName="c-dropzone--accepted"
        activeClassName="c-dropzone--active"
        className={classes}
        disabled={uploadDisabled || isUploading}
        disabledClassName="c-dropzone--disabled"
        disableClick={true}
        key="document-upload-card"
        onDragEnter={handleDragEnter}
        onDrop={handleDrop}
        ref={(node) => {
          dropzoneRef = node
        }}
        rejectClassName="c-dropzone--rejected"
      >
        <div className="c-card__img-container">
          <img className="c-card__img--top c-img--fluid" alt="" />
        </div>

        <div className="c-card--block">
          <a
            href="#upload-document"
            onClick={onFileUploadClick}
            className="o-block o-block--compact c-type c-type--subhead-sm"
          >
            Upload Your Documents
          </a>

          <p className="o-block o-block--ample c-type c-type--body-sans-sm">or drop your files here.</p>

          <p className="o-block c-type c-type--body-sans-sm c-type--muted">
            Supports: {listToSentence(acceptedFileTypes().names)}
          </p>

          <p className="o-block c-type c-type--body-sans-sm c-type--alert">
            Very long or very large documents may slow down your application
          </p>
        </div>
      </Dropzone>
    </>
  )
}

function mapStateToProps(state): MappedProps {
  return {
    currentDocRequest: getCurrentDocRequest(state),
    kaseId: getCurrentKaseId(state),
    workflowStatus: getWorkflowStatus(state),
    documentUploadLocation: getDocumentUploadLocation(state)
  }
}

function mapDispatchToActions(dispatch: Dispatch): ActionProps {
  return bindActionCreators(
    {
      fetchDocumentSets,
      addUploadsToCurrentDocRequest,
      uploadRequestedFiles,
      uploadRequestedFileFinished,
      uploadRequestedFileProgress,
      postTelemetry
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToActions)(DocumentRequestUploadArea)
