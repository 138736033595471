import { LOCATION_CHANGED } from 'redux-little-router'
import _includes from 'lodash/includes'

import {
  CONVERSION_TRACKING_EVENTS,
  OPEN_MODAL,
  CLOSE_MODAL,
  INITIALIZE_TELEMETRY_SESSION,
  POST_TELEMETRY,
  POST_TELEMETRY_WITH_CONVERSION,
  SECTION_NAME_PUBLIC_CHARGE_ESTIMATOR,
  SECTION_NAME_SETUP,
  RESET_TELEMETRY,
  TOGGLE_SIDEBAR_HELP,
  TELEMETRY_EVENTS,
  CONVERSION_TRACKING_DESTINATIONS
} from 'lib/constants'

import getTelemetryService from 'lib/telemetry'
import { isPresent, isBlank } from 'lib/presence'
import { routeMatches } from 'config/routes'

import { panelChangedListener, paymentFunnelListener } from './telemetry_listeners'

import api from 'lib/api'

import {
  getCurrentKaseId,
  getCurrentPanel,
  getCurrentSectionErrors,
  getCurrentSectionOutcome,
  getCurrentSectionName,
  getFirstPanelOfCurrentSection,
  hasCurrentPanelBeenModified,
  isCurrentlyAtEnd,
  isCurrentlyOnOutcome,
  isCurrentlyAtSectionStart,
  isSidebarHelpOpen,
  isRouterLocationChanging,
  isLeadCapturePanel,
  isJITFeedbackPanel
} from 'reducers/selectors'

import { resetScrollTracking } from 'lib/telemetry/common/scroll_tracking'

const trackingData = {
  questionStartTime: null,
  initiallyComplete: false
}

const paymentFunnelPanels = ['boundless_overview', 'satisfaction_guarantee']

function trackingDataIsPresent() {
  return isPresent(trackingData.questionStartTime)
}

function questionDuration() {
  return new Date().getTime() - trackingData.questionStartTime
}

function trackSkippedPanel({ previousPanel, sectionName, via }) {
  getTelemetryService().track(TELEMETRY_EVENTS.SKIPPED_QUESTION, {
    QuestionName: previousPanel.name,
    SectionName: sectionName,
    Via: via,
    Duration: questionDuration(),
    HasJITFeedback: isJITFeedbackPanel(previousPanel)
  })
}

function trackCompletedPanel({ previousPanel, sectionName, via }) {
  getTelemetryService().track(TELEMETRY_EVENTS.COMPLETED_QUESTION, {
    QuestionName: previousPanel.name,
    SectionName: sectionName,
    Via: via,
    Duration: questionDuration(),
    HasJITFeedback: isJITFeedbackPanel(previousPanel)
  })
}

function trackUpdatedPanel({ previousPanel, sectionName, via }) {
  getTelemetryService().track(TELEMETRY_EVENTS.UPDATED_QUESTION, {
    QuestionName: previousPanel.name,
    SectionName: sectionName,
    Via: via,
    Duration: questionDuration(),
    HasJITFeedback: isJITFeedbackPanel(previousPanel)
  })
}

function trackFinishedSection({ sectionName, outcome, errors }) {
  const telemetryService = getTelemetryService()

  telemetryService
    .captureGoogleAdsConversion(CONVERSION_TRACKING_EVENTS[`${sectionName.toUpperCase()}_COMPLETE`])
    .then(() => {
      telemetryService.track(TELEMETRY_EVENTS.FINISHED_SECTION, {
        SectionName: sectionName,
        Status: outcome,
        ErrorCount: errors.length
      })
    })
}

function trackLeadCaptured({ panel }) {
  const panelSlug = panel.slug
  const leadType = panelSlug.indexOf('-email') >= 0 ? 'EMAIL_CAPTURED' : 'PHONE_CAPTURED'
  const telemetryService = getTelemetryService()
  const data = { leadType }

  telemetryService.captureGoogleAdsConversion(CONVERSION_TRACKING_EVENTS[leadType])
  telemetryService.captureFacebookPixelConversion(TELEMETRY_EVENTS.BECAME_LEAD, data)
  telemetryService.track(TELEMETRY_EVENTS.BECAME_LEAD, data)
}

export function trackV2LeadCaptured(leadType = 'EMAIL_CAPTURED') {
  const telemetryService = getTelemetryService()
  const data = { leadType }

  telemetryService.captureGoogleAdsConversion(CONVERSION_TRACKING_EVENTS[leadType])
  telemetryService.captureFacebookPixelConversion(TELEMETRY_EVENTS.LEAD, data)
  telemetryService.track(TELEMETRY_EVENTS.BECAME_LEAD, data)
}

export function trackFacebookEvent(event, kaseKind) {
  const telemetryService = getTelemetryService()
  const data = { kaseKind }

  telemetryService.captureFacebookPixelConversion(event, data)
}

function trackOpenedContextualHelp({ via, panel }) {
  getTelemetryService().track(TELEMETRY_EVENTS.OPENED_CONTEXTUAL_HELP, {
    QuestionName: panel?.name,
    Via: via
  })
}

function trackClosedContextualHelp({ via, panel }) {
  getTelemetryService().track(TELEMETRY_EVENTS.CLOSED_CONTEXTUAL_HELP, {
    QuestionName: panel?.name,
    Via: via
  })
}

const DEFAULT_NAVIGATION_VIA = 'PageLaunch'

function beforePanelChange(state, action) {
  const newPath = action.payload.pathname
  const via = action.payload.via || DEFAULT_NAVIGATION_VIA
  const previousPanel = getCurrentPanel(state)

  if (!trackingDataIsPresent()) {
    return
  }

  if (!isRouterLocationChanging(state, newPath)) {
    return
  }

  if (!previousPanel) {
    return
  }

  const sectionName = getCurrentSectionName(state)
  const panelSkipped = !hasCurrentPanelBeenModified(state)

  const previouslyIncomplete = !trackingData.initiallyComplete
  const currentlyComplete = previousPanel.complete
  const completedPanel = previouslyIncomplete && currentlyComplete

  if (panelSkipped) {
    trackSkippedPanel({ via, sectionName, previousPanel })
  } else {
    panelChangedListener(action, previousPanel)

    if (completedPanel) {
      trackCompletedPanel({ via, sectionName, previousPanel })

      if (isLeadCapturePanel(state, previousPanel)) {
        trackLeadCaptured({ panel: previousPanel })
      }
    } else {
      trackUpdatedPanel({ via, sectionName, previousPanel })
    }
  }
}

function afterPanelChange(newState, action) {
  const panel = getCurrentPanel(newState)
  if (isBlank(panel)) {
    return
  }

  const via = action.payload.via || DEFAULT_NAVIGATION_VIA
  const sectionName = getCurrentSectionName(newState)
  const kaseId = getCurrentKaseId(newState)
  const atEndOfSection = isCurrentlyAtEnd(newState)
  const atStartOfSection = isCurrentlyAtSectionStart(newState)
  const telemetryService = getTelemetryService()

  if (kaseId) {
    let lastPanelName = panel.name
    if (sectionName === SECTION_NAME_PUBLIC_CHARGE_ESTIMATOR && atEndOfSection) {
      const firstPanel = getFirstPanelOfCurrentSection(newState)
      lastPanelName = firstPanel.name
    }

    if (panel.component && panel.component.name === 'SectionEndInterstitial') {
      lastPanelName = ''
    }

    api.kases.updateLastViewedSectionAndPanelName({
      kaseId,
      lastViewedSectionAndPanelName: `${sectionName}.${lastPanelName}`
    })
  }

  telemetryService._delay(() => {
    if (sectionName === SECTION_NAME_SETUP && atStartOfSection) {
      telemetryService.captureGoogleAdsConversion(CONVERSION_TRACKING_EVENTS.APP_START).then(() => {
        telemetryService.track(TELEMETRY_EVENTS.STARTED_APP)
      })
    }

    telemetryService.track(TELEMETRY_EVENTS.VIEWED_QUESTION, {
      QuestionName: panel.name,
      SectionName: sectionName,
      Via: via,
      HasJITFeedback: isJITFeedbackPanel(panel)
    })
  })

  if (_includes(paymentFunnelPanels, panel.name)) {
    paymentFunnelListener(panel.name)
  }

  if (isCurrentlyOnOutcome(newState)) {
    const errors = getCurrentSectionErrors(newState)
    const outcome = getCurrentSectionOutcome(newState)

    trackFinishedSection({
      sectionName,
      outcome,
      errors
    })
  }

  trackingData.questionStartTime = new Date().getTime()
  trackingData.initiallyComplete = panel.complete
}

function documentRequestChange(state, action) {
  const documentRequestPath = action.payload.pathname
  const via = action.payload.via || DEFAULT_NAVIGATION_VIA

  getTelemetryService().track(TELEMETRY_EVENTS.VIEWED_DOCUMENT_REQUEST, {
    documentRequestPath,
    via
  })
}

function trackOpenModal(state, action) {
  switch (action.modal) {
    case 'SessionModal': {
      return getTelemetryService().track(TELEMETRY_EVENTS.VIEWED_MODAL, {
        via: action.via,
        isLogIn: action.modalProps.isLogIn,
        ModalName: 'SignIn'
      })
    }
    case 'ContextualHelpModal': {
      const { via } = action
      const panel = getCurrentPanel(state)

      return trackOpenedContextualHelp({ panel, via })
    }
    default:
  }
}

function trackCloseModal(state, action) {
  if (action.modal === 'ContextualHelpModal') {
    const { via } = action
    const panel = getCurrentPanel(state)

    return trackClosedContextualHelp({ panel, via })
  }
}

const telemetryMiddleware = (store) => (next) => (action) => {
  const state = store.getState()
  switch (action.type) {
    case INITIALIZE_TELEMETRY_SESSION: {
      const { user } = action

      return getTelemetryService().setupSession(user)
    }

    case POST_TELEMETRY: {
      const { eventName, params, callback } = action

      // Short-circuit so that this action does
      // not reach the store
      return getTelemetryService().track(eventName, params, callback)
    }

    case POST_TELEMETRY_WITH_CONVERSION: {
      const {
        eventName,
        eventParams,
        conversionEventName,
        conversionEventParams,
        conversionTrackingDestinations,
        callback
      } = action

      const telemetryService = getTelemetryService()

      if (_includes(conversionTrackingDestinations, CONVERSION_TRACKING_DESTINATIONS.GOOGLE)) {
        telemetryService.captureGoogleAdsConversion(conversionEventName, conversionEventParams)
      }

      /**
       * 08/2022 Removed the direct call to the window._fbq via telemetryService.captureFacebookPixelConversion
       * See [this commit](https://github.com/boundlesshq/boundless/commit/ff54c054714ab976889faec0d5af74a299f4288b)
       * for the removed the implementation code
       */
      if (_includes(conversionTrackingDestinations, CONVERSION_TRACKING_DESTINATIONS.FACEBOOK)) {
        return telemetryService.track(eventName, { ...eventParams, ...conversionEventParams }, callback)
      } else {
        // Short-circuit so that this action does
        // not reach the store
        return telemetryService.track(eventName, eventParams, callback)
      }
    }

    case RESET_TELEMETRY: {
      // Short-circuit so that this action does
      // not reach the store
      return getTelemetryService().reset()
    }

    default:
  }

  switch (action.type) {
    case LOCATION_CHANGED: {
      const { route } = state.router

      if (routeMatches(route, 'panelPath')) {
        beforePanelChange(state, action)
      }

      if (routeMatches(route, 'newDocumentUploadPath')) {
        documentRequestChange(state, action)
      }

      /**
       * Our scroll tracking library caches the events it sends when the page
       * loads. This means it will not track scroll depth when the route changes
       * without a page reload. To remedy the issue, we reset the scroll
       * tracking on every route change.
       */
      resetScrollTracking()

      break
    }

    case OPEN_MODAL: {
      trackOpenModal(state, action)
      break
    }

    case CLOSE_MODAL: {
      trackCloseModal(state, action)
      break
    }
    default:
  }

  const result = next(action)
  const newState = store.getState()

  switch (action.type) {
    case TOGGLE_SIDEBAR_HELP: {
      const opened = isSidebarHelpOpen(newState)

      const trackHelpEvent = opened ? trackOpenedContextualHelp : trackClosedContextualHelp

      trackHelpEvent({
        via: action.via,
        panel: getCurrentPanel(newState)
      })

      break
    }

    case LOCATION_CHANGED: {
      const { route } = newState.router

      if (routeMatches(route, 'panelPath')) {
        afterPanelChange(newState, action)
      }

      break
    }

    default:
  }

  return result
}

export default telemetryMiddleware
