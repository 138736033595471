import { createSelector } from 'reselect'
import { DocumentRequestStatus, DocumentRequestCustomerStatus, DocumentUploadSummaryStates } from 'lib/constants'
import { DocumentsStore } from '.'
import {
  requestHasComments,
  documentNeedsAction,
  getDocRequestCustomerStatus,
  getNumberOfDocRequestsNeedCustomerAction
} from 'components/screens/document_upload/utils.ts'

export const isDocumentRequestHidden = (documents: DocumentsStore, documentRequest: DocumentRequestModel) =>
  !documents.uploadModal.expandedRequestIds[documentRequest.id]

export const getDocumentRequests = (documents: DocumentsStore) => {
  if (!documents) return []
  return documents.requests
}

export const currentlyHasDocumentRequests = createSelector(getDocumentRequests, (requests) =>
  Boolean(requests && requests.length > 0)
)

export const getVisibleCustomerDocumentRequests = createSelector(
  getDocumentRequests,
  (requests: DocumentRequestModel[]) => requests.filter((request) => request.status !== DocumentRequestStatus.Dismissed)
)

// TODO: I think the only component this is used in actionable_document_reqeusts,
// is not being used anywhere, so that component can be removed,
// I think this can just return the count or a boolean
export const getActionableDocumentRequests = createSelector(
  getVisibleCustomerDocumentRequests,
  (requests: DocumentRequestModel[]) =>
    requests.filter((request) => request.status === DocumentRequestStatus.NeedsCustomerAction)
)

export const getNumberOfDocRequestsNeedReview = createSelector(
  getVisibleCustomerDocumentRequests,
  (requests: DocumentRequestModel[]) => {
    const requestsNeedReview = requests.filter((request) => request.status === DocumentRequestStatus.NeedsReview)

    return requestsNeedReview.length
  }
)

// Half of the doc requests must be completed by user before first
// automatic submission
export const getInitialDocReviewThresholdCount = createSelector(
  getVisibleCustomerDocumentRequests,
  (requests: DocumentRequestModel[]) => {
    return Math.round(requests.length / 2)
  }
)

export const getDocRequestsLeftBeforeThreshold = createSelector(
  getNumberOfDocRequestsNeedReview,
  getInitialDocReviewThresholdCount,
  (requestsNeedReview, thresholdCount) => thresholdCount - requestsNeedReview
)

export const completedReviewCount = (documentsData: DocumentsStore) =>
  documentsData.documentReviewKase && documentsData.documentReviewKase.completedReviewCount

export const getInitialThresholdReached = createSelector(
  getNumberOfDocRequestsNeedReview,
  getInitialDocReviewThresholdCount,
  completedReviewCount,
  (requestsNeedReview, initialThreshold, completedReviewCount) =>
    requestsNeedReview >= initialThreshold || completedReviewCount > 0
)

export const slaIsSet = (documentsData: DocumentsStore) =>
  !!documentsData && !!documentsData.documentReviewKase && !!documentsData.documentReviewKase.sla

export const getTodoDocumentRequests = createSelector(
  getVisibleCustomerDocumentRequests,
  slaIsSet,
  (requests: DocumentRequestModel[], slaIsSet) =>
    requests.filter((request) => getDocRequestCustomerStatus(request, slaIsSet) === DocumentRequestCustomerStatus.ToDo)
)

export const getDocumentRequestsWithActiveComments = createSelector(
  getVisibleCustomerDocumentRequests,
  slaIsSet,
  (requests: DocumentRequestModel[], slaIsSet) =>
    requests.filter(
      (request) => getDocRequestCustomerStatus(request, slaIsSet) === DocumentRequestCustomerStatus.SeeComment
    )
)

export const getDocumentReviewKase = (documentsData: DocumentsStore) => documentsData.documentReviewKase

export const unresolvedCommentCount = createSelector(
  getVisibleCustomerDocumentRequests,
  (requests: DocumentRequestModel[]) => {
    let count = 0
    requests.forEach((request) => {
      // Only want to count comments in requests that are currently in needs_customer_action
      // since only admin can remove comments
      if (request.status === DocumentRequestStatus.NeedsCustomerAction) {
        if (requestHasComments(request)) count += 1

        request.documents.forEach((doc) => {
          if (documentNeedsAction(doc)) {
            count += 1
          }
        })
      }
    })

    return count
  }
)

export const hasUnresolvedComments = createSelector(unresolvedCommentCount, (count: number) => count !== 0)

export const getCurrentDocumentUploadSummaryState = createSelector(
  getVisibleCustomerDocumentRequests,
  getDocumentReviewKase,
  getNumberOfDocRequestsNeedReview,
  getInitialThresholdReached,
  unresolvedCommentCount,
  (requests, docReviewKase, numberOfRequestsInReview, thresholdReached, unresolvedCommentCount) => {
    if (requests && requests.length === 0) return null
    if (!docReviewKase) return null
    const numberOfRequestsNeedCustomerAction = getNumberOfDocRequestsNeedCustomerAction(requests)

    const completedReviewCount = docReviewKase.completedReviewCount
    const slaIsSet = !!docReviewKase.sla

    if (!completedReviewCount && numberOfRequestsInReview === 0) {
      return DocumentUploadSummaryStates.UploadingNotStarted
    } else if (!thresholdReached && !slaIsSet) {
      return DocumentUploadSummaryStates.ThresholdNotReached
    } else if (unresolvedCommentCount > 0) {
      return DocumentUploadSummaryStates.CommentsExist
    } else if (numberOfRequestsNeedCustomerAction === 0 && numberOfRequestsInReview === 0) {
      return DocumentUploadSummaryStates.AllSectionsAccepted
    } else if (numberOfRequestsNeedCustomerAction === 0) {
      return DocumentUploadSummaryStates.AllSectionsCustomerCompleted
    }

    return DocumentUploadSummaryStates.ThresholdReachedNoComments
  }
)

// This is the current index in the array of sorted doc requests
export const getCurrentDocRequestIndex = (documentsData: DocumentsStore) => documentsData.currentDocRequestIndex

export interface CategorizedDocumentRequests {
  beneficiary: DocumentRequestModel[]
  sponsor: DocumentRequestModel[]
  evidenceOfRelationship: DocumentRequestModel[]
  financialDocuments: DocumentRequestModel[]
  selfSufficiency?: DocumentRequestModel[]
  jointSponsor?: DocumentRequestModel[]
}
