export const getCustomerReviewFeedbackFileName = (masterPdf) => masterPdf.reviewFileOriginalName

export const getMasterPdfId = (masterPdf) => masterPdf.id

export const hasCustomerReviewPdf = (masterPdf) => masterPdf.status === 'ready'

export const isCustomerReviewFeedbackSubmitted = (masterPdf) => masterPdf.feedbackSubmitted

export const isCustomerFeedbackUploaded = (masterPdf) => masterPdf.feedbackUploaded

export const getMasterPDFPagesList = (masterPdf) => masterPdf.masterPagesList
export const getMasterPDFUnusedPagesList = (masterPdf) => masterPdf.unusedPagesList
