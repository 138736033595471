import { ADDED_MESSAGE, LOADED_ISSUES, LOADING_ISSUES, SET_CURRENT_ISSUE, UPDATE_ISSUE } from 'lib/constants'
import api from 'lib/api'

interface FetchIssuesParams {
  kaseId: number
}

interface AddMessageParams {
  kaseId: number
  issueId: string
  text: string
}

export function loadedIssues(data) {
  return (dispatch) => {
    dispatch({
      type: LOADED_ISSUES,
      issues: data.issues,
      messages: data.messages
    })
  }
}

export function setMessage(data) {
  return (dispatch) => {
    dispatch({
      type: ADDED_MESSAGE,
      messages: data.messages
    })
  }
}

export const setCurrentIssue = (issueId: number) => (dispatch) => {
  dispatch({
    type: SET_CURRENT_ISSUE,
    issueId: issueId
  })
}

export const resolveCurrentIssue = (params) => (dispatch) => {
  return api.issuesV2.resolveIssue(params).then((data) => {
    dispatch({ type: UPDATE_ISSUE, issue: data.data })
  })
}

/**
 * Fetch all the issues for the target kase and update the Redux
 * store.
 * @param param kaseId
 */
export const loadIssues = ({ kaseId }: FetchIssuesParams) => (dispatch) => {
  dispatch({ type: LOADING_ISSUES })

  return api.issues.index({ kaseId }).then((data) => {
    dispatch(loadedIssues(data))
  })
}

export const addMessage = (request: AddMessageParams) => (dispatch) => {
  return api.issues.saveMessage(request).then((data) => {
    dispatch(setMessage(data))
  })
}
