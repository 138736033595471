import React, { FunctionComponent, useEffect, useState } from 'react'
import Confetti from 'react-confetti'
import cx from 'classnames'

import ArrowIcon from 'components/icons/arrow_icon'
import Button from 'components/button'
import ModalNoHeader from 'components/modals/modal/modal_no_header'

import Binoculars from 'images/postship-dashboard/binoculars.png'
import Calendar from 'images/postship-dashboard/calendar.png'
import Checklist from 'images/postship-dashboard/checklist.png'
import HandWithFolder from 'images/postship-dashboard/hand_with_folder.png'
import HoldingHands from 'images/postship-dashboard/holding_hands.png'
import HoldingHandsMobile from 'images/postship-dashboard/holding_hands_mobile.png'
import Stopwatch from 'images/postship-dashboard/stopwatch.png'
import Together from 'images/postship-dashboard/together.png'
import TogetherMobile from 'images/postship-dashboard/together_mobile.png'

import { ModalDataType } from './lib/types'

interface Props {
  hideModal: () => void
  isMobile: boolean
  modalData: ModalDataType
  showModal?: boolean
}

const MilestoneTrackerModal: FunctionComponent<Props> = ({ hideModal, isMobile, modalData, showModal }) => {
  const { header, main, showConfetti, type } = modalData

  const getScreenDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window
    return {
      width,
      height
    }
  }

  const [screenDimensions, setScreenDimensions] = useState(getScreenDimensions())

  useEffect(() => {
    const handleResize = () => {
      setScreenDimensions(getScreenDimensions())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const getImgSrc = (fileName) => {
    if (fileName) {
      switch (fileName) {
        case 'checklist':
          return Checklist
        case 'calendar':
          return Calendar
        case 'binoculars':
          return Binoculars
        case 'stopwatch':
          return Stopwatch
        case 'hand_with_folder':
          return HandWithFolder
        case 'together':
          if (isMobile) {
            return TogetherMobile
          } else {
            return Together
          }
        case 'holding_hands':
          if (isMobile) {
            return HoldingHandsMobile
          } else {
            return HoldingHands
          }
        default:
          return Calendar
      }
    }
  }

  const getTutorialModal = () => (
    <div className={cx({ 'o-layout--center py-6': isMobile })}>
      <h1 className="o-block o-block--ample c-type c-type--headline-md c-type--emphasized">{header}</h1>
      <div className={cx({ 'flex justify-between': !isMobile })}>
        {main?.map((item, i) => {
          return (
            <div
              key={i}
              className={cx({
                'w-1/2': !isMobile && main.length > 1,
                'pr-6': !isMobile && main.length > 1 && i === 0,
                'pl-6': !isMobile && main.length > 1 && i === 1,
                'pb-6': isMobile && main.length > 1 && i === 0
              })}
            >
              <img
                className={cx('o-block', { 'm-auto': isMobile })}
                src={getImgSrc(item.fileName)}
                alt={item.imageDescription}
              />
              <h2 className="o-block o-block--compact c-type c-type--subhead-md">{item.header}</h2>
              <p className="c-type c-type--body-sans-sm">{item.body}</p>
            </div>
          )
        })}
      </div>
    </div>
  )

  const getCelebrationModal = () => {
    const { fileName, imageDescription, body } = main?.[0]
    return (
      <div className={cx({ 'o-layout--center': isMobile, 'inline-block': !isMobile })}>
        <div className={cx({ 'float-right ml-12 -mr-12 -mt-12 -mb-13 w-3/5': !isMobile })}>
          <img className="w-full" src={getImgSrc(fileName)} alt={imageDescription} />
        </div>
        <div className={cx({ 'px-6 pt-4 pb-2': isMobile })}>
          <h1 className="o-block o-block--ample c-type c-type--headline-md c-type--emphasized py-2">{header}</h1>
          <p className="c-type c-type--body-sans-sm">{body}</p>
        </div>
      </div>
    )
  }

  return (
    <ModalNoHeader
      onRequestClose={hideModal}
      modalWidth={isMobile ? '22rem' : '38rem'}
      isOpen={showModal}
      body={
        <>
          {showConfetti && (
            <Confetti
              height={screenDimensions.height}
              width={screenDimensions.width}
              colors={['#3242b2', '#e0e3f3', '#fa5e50', '#ffdfdc', '#168753', '#daf0e6', '#f57f18']}
            />
          )}
          {type === 'tutorial' ? getTutorialModal() : getCelebrationModal()}
        </>
      }
      footer={
        <div className={cx({ 'o-layout--right': !isMobile })}>
          <Button
            mobileLabel="Continue"
            color="primary"
            label="Continue"
            icon={<ArrowIcon arrowDirection="right" />}
            onClick={hideModal}
            className="c-btn--block@sm-down"
          />
        </div>
      }
    />
  )
}

export default MilestoneTrackerModal
