import React, { FunctionComponent, useEffect } from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'

import { getCurrentKaseId } from 'reducers/selectors'

import { KaseKind } from 'lib/constants'
import { QuestionnairePage, QuestionnairePageDataV2, SaveQuestionFn } from '../lib/types'
import { allQuestionsOnPageCompleted } from '../lib/helpers'

import Button from 'components/button'
import InlineSignInNav from 'components/inline_sign_in_nav'
import ConfirmationModal from 'components/modals/modal/confirmation_modal'
import ContextualHelpModal from 'components/modals/modal/contextual_help_modal'
import ArrowIcon from 'components/icons/arrow_icon'

import Interstitial from '../interstitials'
import ExitInterstitial from '../interstitials/exit'
import PaymentPanel from '../interstitials/payment'
import BoundlessOverview from '../interstitials/overview'
import EstimatedTimelineOverview from './estimated_timeline_overview'
import EligibilityReport from '../interstitials/eligibility_report/index'
import K1MidwayInterstitialPage from '../interstitials/k1/midway'
import K1PrePayInterstitialPage from '../interstitials/k1/prepay'

import QuestionSet from './question_set'
import InvalidPage from './invalid_page'

interface QuestionnairePanelProps {
  contextualHelp?: any
  currentKaseKind: KaseKind
  currentPagePath?: string
  editingSummaryQuestionSetIndex: number
  handleContinueClick: any
  handlePreviousClick: any
  isContextualHelpOpen: boolean
  isEditingIssue?: boolean
  isMobile?: boolean
  isUpdating?: boolean
  nextPagePath?: string
  panelData: QuestionnairePage | QuestionnairePageDataV2
  sectionName?: string
  previousPagePath?: string
  questionnaireSlug: string
  showContextualHelpModal?: boolean
  slimOutline?: boolean
  isChatDiscoveryTestEnabled?: boolean
  markPageViewed?: () => void
  onSaveQuestion: SaveQuestionFn
  redirectToBeginning: () => void
  setEditingSummaryQuestionSetIndex: (number) => void
  toggleContextualHelp?: (args: { via: string; desktopOnly: boolean }) => void
}

interface MappedProps {
  currentKaseId: number
}

const QuestionnairePanel: FunctionComponent<QuestionnairePanelProps & MappedProps> = ({
  contextualHelp,
  currentKaseKind,
  currentKaseId,
  currentPagePath,
  editingSummaryQuestionSetIndex,
  handleContinueClick,
  handlePreviousClick,
  isChatDiscoveryTestEnabled,
  isContextualHelpOpen,
  isEditingIssue = false,
  isMobile,
  isUpdating,
  nextPagePath,
  panelData,
  previousPagePath,
  questionnaireSlug,
  sectionName,
  showContextualHelpModal,
  slimOutline,
  markPageViewed,
  onSaveQuestion,
  redirectToBeginning,
  setEditingSummaryQuestionSetIndex,
  toggleContextualHelp
}) => {
  if (!panelData) {
    return <InvalidPage redirectToBeginning={redirectToBeginning} />
  }

  const isExitPage = panelData.attributes.type === 'ExitPage'
  const isPrePayExitPage = isExitPage && panelData.attributes.name === 'ExitPagePrePayment'

  const isEligibilityReport = panelData.attributes.type === 'EligibilityReport'
  const isLandingPage = panelData.attributes.type === 'LandingPage'
  const isLoginPage = panelData.attributes.type === 'LoginPage'
  const isPrepaymentPage = panelData.attributes.type === 'PrepaymentPage'
  const isPaymentPage = panelData.attributes.type === 'PaymentPage'
  const isEstimatedTimelinePage = panelData.attributes.type === 'EstimatedTimelinePage'
  const isQuestionPage = ['Page', 'HistoryPage', 'SummaryPage'].includes(panelData.attributes.type)
  const isInterstitial =
    !isQuestionPage &&
    !isExitPage &&
    !isEligibilityReport &&
    !isPrepaymentPage &&
    !isPaymentPage &&
    !isEstimatedTimelinePage

  // K1 Specific screens
  const isK1 = currentKaseKind === KaseKind.K1FianceVisa
  const isK1PrePayEligibilityExitPage = isK1 && panelData.attributes.path === 'eligibility-eligibilityexitpage'
  const isK1PrePayInterstitialPage = isK1 && panelData.attributes.name === 'ExitPagePrePayment'
  const isK1MidwayInterstitialPage = isK1 && panelData.attributes.name === 'Midway Interstitial'

  // Show the "back" button if we have a path to send them back to, but not if they are on this
  // questionnaire page to edit an issue or on mobile
  const showPreviousButton = previousPagePath && !isEditingIssue && !isMobile
  const showContinueButton =
    (nextPagePath && panelData && !isMobile && !isPaymentPage && !isLoginPage) ||
    (slimOutline && !isPaymentPage && !isLoginPage)
  const showIncompleteMessage = !nextPagePath && !isMobile && !isPrepaymentPage && !isPaymentPage && !slimOutline

  const continueButtonLabel = () => {
    if (isExitPage) {
      if (isK1PrePayEligibilityExitPage) {
        return 'Continue'
      } else if (isPrePayExitPage) {
        return 'See Payment Options'
      } else {
        return 'Back to Dashboard'
      }
    } else if (isK1PrePayInterstitialPage || isEstimatedTimelinePage) {
      return 'See Payment Options'
    } else if (panelData.attributes.type === 'IneligiblePage') {
      return 'Chat with Boundless'
    } else if (isQuestionPage && isEditingIssue) {
      return 'Save & Return to Issues'
    } else {
      return 'Continue'
    }
  }

  // Mark page as viewed when the page id changes
  if (markPageViewed) {
    useEffect(() => {
      markPageViewed()
    }, [panelData.id])
  }

  // close contextual help when the panel changes
  useEffect(() => {
    if (isContextualHelpOpen) {
      toggleContextualHelp({ via: 'InlineHelp', desktopOnly: true })
    }
  }, [panelData.id])

  return (
    <>
      <div className="c-sheet__action-container c-sheet__action-container--secondary u-hide@sm-down">
        {showPreviousButton && (
          <Button
            isLoading={isUpdating}
            id="previous-button-desktop"
            aria-label="back to previous question"
            className="c-sheet__action c-btn c-btn--icon-only c-btn--secondary c-btn--btn-events-only"
            color="secondary"
            onClick={handlePreviousClick}
            icon={<ArrowIcon arrowDirection="up" aria-hidden="true" />}
          />
        )}
      </div>

      {isInterstitial && (
        <Interstitial
          sectionName={sectionName}
          headline={panelData?.attributes?.subtitle}
          body={panelData?.attributes?.body}
          image={panelData?.attributes?.image}
          showDesktopLink={panelData.attributes.type === 'SectionPage'}
          isChatDiscoveryTestEnabled={isChatDiscoveryTestEnabled}
        >
          {isLoginPage && <InlineSignInNav isLogIn={false} expired={false} noHeader={true} successUrl="/timeline" />}
          {isK1PrePayInterstitialPage && <K1PrePayInterstitialPage />}
        </Interstitial>
      )}

      {isEligibilityReport && <EligibilityReport reportData={panelData.attributes.attributes} />}

      {isK1MidwayInterstitialPage && <K1MidwayInterstitialPage currentKaseId={currentKaseId} />}

      {isExitPage && <ExitInterstitial title={panelData?.attributes?.subtitle} body={panelData?.attributes?.body} />}

      {isPrepaymentPage && <BoundlessOverview />}

      {isEstimatedTimelinePage && <EstimatedTimelineOverview />}

      {isPaymentPage && (
        <PaymentPanel
          currentKaseId={currentKaseId}
          currentKaseKind={currentKaseKind}
          currentPageId={panelData.id}
          paymentDetails={panelData.attributes.payment_details}
          isMobile={isMobile}
        />
      )}

      {isQuestionPage && (
        <QuestionSet
          currentPagePath={currentPagePath}
          isChatDiscoveryTestEnabled={isChatDiscoveryTestEnabled}
          editingSummaryQuestionSetIndex={editingSummaryQuestionSetIndex}
          setEditingSummaryQuestionSetIndex={setEditingSummaryQuestionSetIndex}
          panelData={panelData}
          sectionName={sectionName}
          questionnaireSlug={questionnaireSlug}
          onSaveQuestion={onSaveQuestion}
          toggleContextualHelp={toggleContextualHelp}
        />
      )}

      {showContinueButton && (
        <div
          className={classNames(
            'o-layout c-sheet__action-container c-sheet__action-container--primary u-hide@sm-down',
            {
              'o-layout--vertical-bottom': isQuestionPage,
              'o-layout--vertical-center':
                isPrepaymentPage || isPaymentPage || isEstimatedTimelinePage || isEligibilityReport || isLandingPage
            }
          )}
        >
          <div className="o-layout--block-center o-layout--sticky">
            <Button
              id="continue-button-desktop"
              className="c-sheet__action c-btn--btn-events-only o-block o-block--compact"
              color={isEditingIssue ? 'emphasized' : 'primary'}
              onClick={handleContinueClick}
              label={continueButtonLabel()}
              icon={<ArrowIcon arrowDirection={isEditingIssue ? 'right' : 'down'} aria-hidden="true" />}
              isLoading={isUpdating}
              data-cy="continue-button"
              disabled={
                (slimOutline && (!allQuestionsOnPageCompleted(panelData) || !nextPagePath)) ||
                editingSummaryQuestionSetIndex > -1
              }
            />
          </div>
        </div>
      )}

      {showIncompleteMessage && isUpdating && (
        <div className="flex justify-center">
          <div className="c-icon c-fancy-spinner">
            <div className="spinner__circle" />
          </div>
        </div>
      )}

      {showIncompleteMessage && !isUpdating && (
        <div className="o-layout c-sheet__action-container c-sheet__action-container--primary o-layout--vertical-bottom u-hide@sm-down">
          <div className="c-sheet__action c-btn--btn-events-only o-block o-block--compact">
            Please make sure all questions on the left have a <strong>green check mark</strong> before continuing.
          </div>
        </div>
      )}

      {showContextualHelpModal && (
        <ContextualHelpModal
          cancelLabel="OK"
          onRequestClose={() => toggleContextualHelp({ via: 'InlineHelp', desktopOnly: true })}
          header={contextualHelp.header}
          text={contextualHelp.text}
          isOpen={showContextualHelpModal}
        />
      )}
    </>
  )
}

function mapStateToProps(state) {
  return {
    currentKaseId: getCurrentKaseId(state)
  }
}

export default connect(mapStateToProps, null)(QuestionnairePanel)
