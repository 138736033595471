import React from 'react'
import { connect } from 'react-redux'

import Section from './section'
import SectionEntry from './section_entry'

import { getAccountHolderRole } from 'reducers/selectors'

import { Role } from 'components/forms/inputs/index'

interface Props {
  role: Role
  firstName: string
  accountHolderRole: string
}

class Parents extends React.Component<Props> {
  render() {
    const { role, accountHolderRole, firstName } = this.props
    const name = firstName || 'Spouse'
    const header = accountHolderRole === role ? 'Your Parents' : `${name}'s Parents`

    return (
      <Section headerText={header}>
        <SectionEntry label="Mother's Name" path={`${role}.parents.0.name`} editHref={`${role}-first-parent`} />
        <SectionEntry
          label="Mother's Birth Date"
          path={`${role}.parents.0.birthday`}
          editHref={`${role}-first-parent-birth`}
        />
        <SectionEntry
          label="Mother's Birth Place"
          path={`${role}.parents.0.born_at`}
          editHref={`${role}-first-parent-birth`}
        />
        <SectionEntry
          label="Mother's Current Location"
          path={`${role}.parents.0.current_address`}
          editHref={`${role}-first-parent-residence`}
        />
        <SectionEntry label="Father's Name" path={`${role}.parents.1.name`} editHref={`${role}-second-parent`} />
        <SectionEntry
          label="Father's Birth Date"
          path={`${role}.parents.1.birthday`}
          editHref={`${role}-second-parent-birth`}
        />
        <SectionEntry
          label="Father's Birth Place"
          path={`${role}.parents.1.born_at`}
          editHref={`${role}-second-parent-birth`}
        />
        <SectionEntry
          label="Father's Current Location"
          path={`${role}.parents.1.current_address`}
          editHref={`${role}-second-parent-residence`}
        />
      </Section>
    )
  }
}

const mapStateToProps = (state) => ({
  accountHolderRole: getAccountHolderRole(state)
})

export default connect(mapStateToProps)(Parents)
