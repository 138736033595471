import { Link, Notice, NoticeProps, Typography } from '@boundless-immigration/boundless-ui'
import { FEATURES } from 'lib/features'
import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'
import { getIsFeatureEnabled } from 'reducers/features/selectors'

interface ExplicitProps {
  checkout?: boolean
  sx?: NoticeProps['sx']
}

interface MappedProps {
  currentKaseProcess?: string
  isFeeHikeNoticesEnabled?: boolean
}

type Props = MappedProps & ExplicitProps

/**
 * CON-972 Fee Hike Notices
 *
 * The government is raising fees later this year (2023). We want to let users know that they should apply as soon as possible to avoid paying additional fees.
 * These will be temporary notices that will be removed once the fee hike goes into effect. They are controlled by a feature flag `feat__fee_hike_notices`.
 *
 */
const FeeHikeNotice: FunctionComponent<Props> = ({ checkout, isFeeHikeNoticesEnabled, sx }) => {
  if (!isFeeHikeNoticesEnabled) {
    return null
  }

  const headerText = checkout ? (
    'The U.S. government is raising fees later this year. Apply now!'
  ) : (
    <Typography>
      USCIS is raising filing fees. All new Boundless applications will reflect these updated government fees.
      <Link href="https://www.boundless.com/blog/uscis-fees-to-increase/">Learn more</Link>
    </Typography>
  )

  return (
    <Notice headerIcon="time-and-money" headerText={headerText} severity="warning" sx={{ my: 4, ...sx }}>
      {checkout ? (
        <>
          <Typography variant="body-sans-sm">
            File your application as soon as possible to avoid paying additional fees.
          </Typography>
          <Typography variant="body-sans-sm">
            <em>
              Please note, we cannot know for certain when fees will increase or guarantee that your application will be
              complete beforehand. If fees increase before you file, you will need to pay the additional fees for your
              application.
            </em>
          </Typography>
        </>
      ) : null}
    </Notice>
  )
}

function mapStateToProps(state): MappedProps {
  return {
    isFeeHikeNoticesEnabled: getIsFeatureEnabled(state, FEATURES.FEE_HIKE_NOTICES)
  }
}

export default connect(mapStateToProps, null)(FeeHikeNotice)
