import React, { FunctionComponent } from 'react'
import GreenCircleCheckmarkIcon from 'components/icons/green_circle_checkmark_icon'
import ProcessInfoColumnWrapper from 'components/panels/outcomes/rapid_visa_redirect/process_info_column_wrapper'

interface ExplicitProps {
  isMobileBrowser: boolean
  blurb: string
  imgSrc: string
  alt: string
}

const ProcessInfoColumn: FunctionComponent<ExplicitProps> = ({ isMobileBrowser, blurb, imgSrc, alt }) => {
  const renderDesktop = () => {
    return (
      <ProcessInfoColumnWrapper isMobileBrowser={isMobileBrowser}>
        <span className="flex pb-4 c-type--body-sans-md c-type--emphasized">
          <GreenCircleCheckmarkIcon className="mr-4" />
          {blurb}
        </span>
        <img src={imgSrc} alt={alt} className="w-full pt-1" />
      </ProcessInfoColumnWrapper>
    )
  }

  const renderMobile = () => {
    return (
      <ProcessInfoColumnWrapper isMobileBrowser={isMobileBrowser}>
        <span className="flex flex-col flex-1 pr-1 pb-4 c-type--body-sans-md c-type--emphasized">
          <GreenCircleCheckmarkIcon className="mb-2" />
          {blurb}
        </span>
        <div className="flex-1 ml-4 ">
          <img src={imgSrc} alt={alt} className="w-full h-auto" />
        </div>
      </ProcessInfoColumnWrapper>
    )
  }

  return isMobileBrowser ? renderMobile() : renderDesktop()
}

export default ProcessInfoColumn as FunctionComponent<ExplicitProps>
