import { getCsrfHeaders } from './csrf'

import documents from './documents'
import documentRequests from './document_requests'
import documentRequestsV2 from './document_requests_v2'
import documentRequestInventory from './document_request_inventory'
import documentReviewKase from './document_review'
import eligibilityData from './eligibility_data'
import features from './v1/features'
import issues from './v1/issues'
import issuesV2 from './issues'
import milestones from './v1/milestones'
import tasks from './v1/tasks'
import addressHistories from './v1/address_histories'
import employmentHistories from './v1/employment_histories'
import kaseDocumentReviews from './kase_document_reviews'
import kaseEvents from './kase_events'
import kaseFormData from './kase_form_data'
import kaseFormEdits from './kase_form_edits'
import kaseTags from './kase_tags'
import kases from './kases'
import kasesV2 from './v1/kase'
import masterPdf from './master_pdf'
import questionnaire from './v1/questionnaire'
import questionnaireV2 from './v2/questionnaire'
import payments from './payments'
import pdfTools from './pdf_tools'
import routes from './routes'
import adminUsers from './admin_users'
import storedFiles from './stored_files'
import userEvents from './user_events'
import users from './users'
import usersV2 from './v1/users'
import sectionProgress from './v1/section_progress'
import wordpress from './wordpress'

export default {
  documents,
  documentRequests,
  documentRequestsV2,
  documentRequestInventory,
  documentReviewKase,
  eligibilityData,
  features,
  getCsrfHeaders,
  issues,
  issuesV2,
  milestones,
  kaseDocumentReviews,
  kaseEvents,
  kaseFormData,
  kaseFormEdits,
  kaseTags,
  kases,
  kasesV2,
  masterPdf,
  questionnaire,
  questionnaireV2,
  payments,
  pdfTools,
  routes,
  storedFiles,
  tasks,
  adminUsers,
  userEvents,
  users,
  usersV2,
  addressHistories,
  employmentHistories,
  sectionProgress,
  wordpress
}
