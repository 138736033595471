import React, { FunctionComponent } from 'react'
import IconWrapper, { IconWrapperExternalProps } from './icon_wrapper'

const TaskIcon: FunctionComponent<IconWrapperExternalProps> = (props) => (
  <IconWrapper {...props} viewBox="0 0 16 20">
    <path
      d="M 6.95 16 L 12.6 10.35 L 11.15 8.9 L 6.925 13.125 L 4.825 11.025 L 3.4 12.45 L 6.95 16 Z M 2 20 C 1.45 20 0.979333 19.8043 0.588 19.413 C 0.196 19.021 0 18.55 0 18 V 2 C 0 1.45 0.196 0.979 0.588 0.587 C 0.979333 0.195667 1.45 0 2 0 H 10 L 16 6 V 18 C 16 18.55 15.8043 19.021 15.413 19.413 C 15.021 19.8043 14.55 20 14 20 H 2 Z M 9 7 V 2 H 2 V 18 H 14 V 7 H 9 Z"
      fill="#fff"
    />
  </IconWrapper>
)

export default TaskIcon
