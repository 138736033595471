import api from 'lib/api'
import { LOADED_FEATURES, LOADING_FEATURES } from 'lib/constants'

export const loadCurrentFeatures = (userId: number) => (dispatch) => {
  dispatch({ type: LOADING_FEATURES, payload: true })

  return api.features
    .fetchFeatures(userId)
    .then((data) => {
      dispatch({ type: LOADED_FEATURES, payload: data || {} })
    })
    .finally(() => {
      dispatch({ type: LOADING_FEATURES, payload: false })
    })
}
