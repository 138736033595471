import React, { FunctionComponent, CSSProperties } from 'react'
import cx from 'classnames'
import _merge from 'lodash/merge'
import { TYPE_SPACING_MAP } from 'components/type/settings'

interface Props {
  className?: string
  style?: CSSProperties
  height?: string | number
  width?: string | number
  /**
   * The amount of space below the element. This space is canceled out if the
   * element is the last o-block in a o-box.
   */
  spacing?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'none'
}

/**
 * A block that represents an element that hasn't finished loading.
 * https://boundless-ui.surge.sh/react/skeleton#blocks
 */
const SkeletonBlock: FunctionComponent<Props> = ({ className, style, width, height, spacing }) => {
  const spacingClassName = TYPE_SPACING_MAP[spacing]
  const classes = cx('o-skeleton', 'o-skeleton--rect', 'o-block', className, {
    [`o-block--${spacingClassName}`]: !!spacingClassName
  })

  style = _merge(style, { width, height })
  return <div className={classes} style={style} />
}

export default SkeletonBlock
