import React, { FunctionComponent } from 'react'

import CircleCheckmark from 'components/icons/circle_checkmark'

const K1MidwayInterstitialPage: FunctionComponent = () => {
  return (
    <div className="text-left">
      <h2 className="c-type--subhead-md-partial-underline text-black">What You Get With Boundless</h2>
      <ul className="o-block c-list mt-4 text-gray-500">
        <li className="o-griddle__row o-griddle__row--no-gutters">
          <div className="o-griddle__col--auto pb-5 pr-2">
            <CircleCheckmark checked={true} aria-hidden="true" />
          </div>

          <div className="o-griddle__col">
            <p className="c-type o-block c-type--body-sans-md o-block--none">
              4 separate quality checks, including a review by an independent attorney
            </p>
          </div>
        </li>
        <li className="o-griddle__row o-griddle__row--no-gutters">
          <div className="o-griddle__col--auto pb-5 pr-2">
            <CircleCheckmark checked={true} aria-hidden="true" />
          </div>

          <div className="o-griddle__col">
            <p className="c-type o-block c-type--body-sans-md o-block--none">
              Complete guidance through the process, including help scheduling your medical exam and preparing for your
              interview
            </p>
          </div>
        </li>
        <li className="o-griddle__row o-griddle__row--no-gutters">
          <div className="o-griddle__col--auto pb-5 pr-2">
            <CircleCheckmark checked={true} aria-hidden="true" />
          </div>

          <div className="o-griddle__col">
            <p className="c-type o-block c-type--body-sans-md o-block--none">
              Worry-free payment: if you’re not satisfied within 14 days, we’ll give you a full refund
            </p>
          </div>
        </li>
      </ul>
    </div>
  )
}

export default K1MidwayInterstitialPage
