/**
 * All features that are used on the client-side should be added here
 * using the notation formatting in the example.
 */
export const FEATURES = {
  // EXAMPLE:
  /**
   * @enabled Shows the test version of the new chat discovery experience. This
   * is just a temporary feature flag until we have the A/B test flag available.
   * @added 12/13/22
   * @remove When the A/B test flag is available
   */
  // CHAT_DISCOVERY_TEST_PLACEHOLDER: 'chat_discovery_test_placeholder'

  /**
   * @enabled Shows notices that warn the customer of the upcoming government fee hike.
   * @added 2/14/23 & recycled 2/2/24
   * @remove When the fee hike has happened, likely in late 2024
   */
  FEE_HIKE_NOTICES: 'feat__fee_hike_notices',

  /**
   * @enabled Shows the new questionnaire v2 experience.
   * @added 3/27/23
   * @remove When the new questionnaire v2 API is used in production for all questionnaires in the apply codebase
   */
  QUESTIONNAIRE_V2_API: 'feat__questionnaire_v2_api',

  /**
   * @enabled Shows the new questionnaire v2 experience for the `immigration_history` and `mbgc_children` questionnaires.
   * @added 4/18/23
   * @remove When the new questionnaire v2 API is used in production for all questionnaires in the apply codebase
   */
  QUESTIONNAIRE_V2_API__IMMIGRATION_HISTORY_AND_MBGC_CHILDREN:
    'feat__questionnaire_v2_api__immigration_history_and_mbgc_children',

  /**
   * @enabled Shows the new questionnaire v2 experience for the `admissibility` questionnaire.
   * @added 4/21/23
   * @remove When the new questionnaire v2 API is used in production for all questionnaires in the apply codebase
   */
  QUESTIONNAIRE_V2_API__ADMISSIBILITY: 'feat__questionnaire_v2_api__admissibility',

  /**
   * @enabled Shows the new questionnaire v2 experience for the `k1_petition_2` questionnaire.
   * @added 5/9/23
   * @remove When the new questionnaire v2 API is used in production for all questionnaires in the apply codebase
   */
  QUESTIONNAIRE_V2_API__K1_PETITION_2: 'feat__questionnaire_v2_api__k1_petition_2',

  /**
   * @enabled Shows the new questionnaire v2 experience for the mbgc `petition_2` questionnaire.
   * @added 5/9/23
   * @remove When the new questionnaire v2 API is used in production for all questionnaires in the apply codebase
   */
  QUESTIONNAIRE_V2_API__MBGC_PETITION_2: 'feat__questionnaire_v2_api__mbgc_petition_2',

  /**
   * @enabled Shows the new questionnaire v2 experience for the `financial_sponsorship` questionnaire.
   * @added 4/18/23
   * @remove When the new questionnaire v2 API is used in production for all questionnaires in the apply codebase
   */
  QUESTIONNAIRE_V2_API__FINANCIAL_SPONSORSHIP: 'feat__questionnaire_v2_api__financial_sponsorship',

  /**
   * @enabled Shows the new questionnaire v2 experience for the `k1_payment`, `mbgc_payment`, and `k1_to_aos_conversion` questionnaires.
   * @added 4/21/23
   * @remove When the new questionnaire v2 API is used in production for all questionnaires in the apply codebase
   */
  QUESTIONNAIRE_V2_API__PAYMENT_PAGES: 'feat__questionnaire_v2_api__payment_pages',

  /**
   * @enabled When Splitit or Stripe is down, shows a notice to the customer that the payment system is down.
   * @added 6/13/23
   * @remove When Splitit or Stripe are backup and running
   */
  PAYMENT_OUTAGE_NOTICE: 'feat__payment_outage_notice',

  /**
   * @enabled Shows live help options in the sidebar
   * @added 7/3/23
   * @remove After we've fully enabled this as the default
   */
  DISCOVERABLE_CHAT: 'feat__discoverable_chat',

  /**
   * @enabled Shows event data in the browser window when any BUI analytics event is fired
   * @added 1/18/24
   * @remove This is a permanent feature flag, intended to be used for debugging purposes
   */
  BUI_ANALYTICS_DEBUG_ENABLED: 'feat__bui_analytics_debug_enabled',

  /**
   * @enabled shows in the Splitit Modal that they can select up to 12 months financing
   * and changes all the language on the cart for the 12 month offering instead of 6 months
   * @added 4/10/2024
   */
  SPLITIT_MAX_INSTALLMENTS_12MO_ENABLED: 'feat__splitit_max_installments_12_months'
}
