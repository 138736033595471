import server from './server'
import { AxiosPromise } from 'axios'

export interface UserEventCreateRequest {
  kaseId: number
  eventType: string
  [extraEventParam: string]: any
}

function create(request: UserEventCreateRequest): AxiosPromise {
  const extra = {}

  if (request.panelName) {
    extra.panel_name = request.panelName
  }

  if (request.newPanelName) {
    extra.new_panel_name = request.newPanelName
  }

  if (request.hasOwnProperty('timezoneOffset')) {
    extra.timezoneOffset = request.timezoneOffset
  }

  const data = {
    event: {
      kase_id: request.kaseId,
      event_type: request.eventType,
      ...extra
    }
  }

  return server.post('/api/user_events', data)
}

export default {
  create
}
