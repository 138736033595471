import _sortBy from 'lodash/sortBy'
import _cloneDeep from 'lodash/cloneDeep'
import _groupBy from 'lodash/groupBy'
import _map from 'lodash/map'
import _compact from 'lodash/compact'
import { getCategoryAtPath } from 'lib/model/needed'

export interface NestedDocumentRequest {
  title: string
  documentRequests: DocumentRequestModel[]
}

export interface DocumentRequestSection {
  title: string
  category: string
  documents?: DocumentRequestModel[]
  nestedDocuments?: NestedDocumentRequest[]
}

const HOUSEHOLD_PATH = 'household'
const CHILDREN_PATH = 'children'

export const Categories = {
  Applicant: 'applicant',
  Beneficiary: 'beneficiary',
  Sponsor: 'sponsor',
  Household: 'household',
  JointSponsor: 'jointSponsor',
  FinancialDocuments: 'financialDocuments',
  EvidenceOfRelationship: 'evidenceOfRelationship',
  SelfSufficiency: 'selfSufficiency',
  AdminRequested: 'adminRequested'
}

export const isHouseholdPath = (path) => {
  // Example:
  // household.members_who_are_not_bene_or_children.items.0.proof_of_assets
  return path.startsWith(HOUSEHOLD_PATH)
}

export const isChildrenPath = (path) => {
  // Example:
  // beneficiary.children.0.proof_of_assets
  return /\.children\./.test(path)
}

const isNaturalizationDocument = (natzApplicant) => {
  return natzApplicant !== undefined
}

export const getCategory = (request, modelMetadata) => {
  const { path } = request
  let category = getCategoryAtPath(modelMetadata, path)

  if (category === null) {
    if (isNaturalizationDocument(modelMetadata.applicant)) {
      category = Categories.Applicant
    } else if (path.startsWith('sponsor')) {
      category = Categories.Sponsor
    } else if (path.startsWith('joint_sponsor')) {
      category = Categories.JointSponsor
    } else if (isHouseholdPath(path)) {
      // Assume that all other household documents will be financial
      category = Categories.FinancialDocuments
    } else if (isChildrenPath(path)) {
      // Assume that all other children documents will be financial
      category = Categories.FinancialDocuments
    } else {
      // Default to beneficiary category
      category = Categories.Beneficiary
    }
  }

  return category
}

export const setCategory = (request, modelMetadata) => {
  const category = getCategory(request, modelMetadata)

  // Financial Document Section has documents for all members associated
  // with the account, therefore needs to be broken down into subcategories
  if (category === Categories.FinancialDocuments) {
    let subCategory
    const path = request.path
    const splitPath = path.split('.')

    if (isHouseholdPath(path)) {
      const householdMemberIndex = parseInt(splitPath[3], 10)
      subCategory = `${HOUSEHOLD_PATH}_${householdMemberIndex}`
    } else if (isChildrenPath(path)) {
      const childrenIndex = parseInt(splitPath[2], 10)
      subCategory = `${CHILDREN_PATH}_${childrenIndex}`
    } else {
      subCategory = splitPath[0]
    }

    request.subCategory = subCategory
  }

  request.category = category
}

const docRequestOrder = [
  Categories.Applicant,
  Categories.Beneficiary,
  Categories.Sponsor,
  Categories.EvidenceOfRelationship,
  Categories.FinancialDocuments,
  Categories.SelfSufficiency,
  Categories.JointSponsor,
  Categories.AdminRequested
]

const subCategoryOrder = (req) => {
  // Order matters!!
  // mapping subcategory to letters to be able to sort
  if (!req.subCategory) return
  if (req.subCategory === Categories.Beneficiary) return 'A'
  if (req.subCategory === Categories.Sponsor) return 'B'

  // For household members/children the order will be after bene and sponsor.
  // Coincidentally, household and children sub-categories are
  // alphabetized in the correct order, so just return the subCategory string
  // Example: children_0, children_1, household_0
  return req.subCategory
}

const categorySort = (req) => {
  return docRequestOrder.indexOf(req.category)
}

export const getSortedDocumentRequests = (requests: DocumentRequestModel[], modelMetadata) => {
  // Clone requests to be able to add category field to each request
  const copiedRequests = _cloneDeep(requests)

  copiedRequests.forEach((req) => setCategory(req, modelMetadata))

  // Sort by category then by subCategory if it exists
  const sortedDocs = _sortBy(copiedRequests, [categorySort, subCategoryOrder])

  return sortedDocs
}

const getFamilyMembersFinancialDocuments = (
  pathForFamilyMembers,
  groupedFinancialDocuments,
  firstNames,
  defaultTitle
) => {
  // Get the financial doc keys associated with household members/children
  const docRequests = Object.keys(groupedFinancialDocuments).filter((subCategory) =>
    subCategory.startsWith(pathForFamilyMembers)
  )

  return docRequests.map((personIndex) => {
    // example personIndex: "household_0", "children_1"
    const splitIndex = personIndex.split('_')
    const index = parseInt(splitIndex[1], 10)

    return {
      title: firstNames[index] || `${defaultTitle} ${index + 1}`,
      documentRequests: groupedFinancialDocuments[personIndex]
    }
  })
}

const groupFinancialDocuments = (
  financialDocuments,
  beneficiaryFirstName,
  sponsorFirstName,
  householdMembersFirstNames,
  childrenFirstNames
) => {
  const nestedFinancialDocuments = {
    title: 'Financial Documents',
    category: Categories.FinancialDocuments,
    nestedDocuments: []
  }

  const groupedFinancialDocuments = _groupBy(financialDocuments, (req) => req.subCategory)

  if (groupedFinancialDocuments.beneficiary) {
    nestedFinancialDocuments.nestedDocuments.push({
      title: beneficiaryFirstName,
      documentRequests: groupedFinancialDocuments.beneficiary
    })
  }

  if (groupedFinancialDocuments.sponsor) {
    nestedFinancialDocuments.nestedDocuments.push({
      title: sponsorFirstName,
      documentRequests: groupedFinancialDocuments.sponsor
    })
  }

  nestedFinancialDocuments.nestedDocuments.push(
    ...getFamilyMembersFinancialDocuments(CHILDREN_PATH, groupedFinancialDocuments, childrenFirstNames, 'Child')
  )

  nestedFinancialDocuments.nestedDocuments.push(
    ...getFamilyMembersFinancialDocuments(
      HOUSEHOLD_PATH,
      groupedFinancialDocuments,
      householdMembersFirstNames,
      'Household Member'
    )
  )

  return nestedFinancialDocuments
}

export const groupSortedDocumentRequests = (
  documentRequests,
  beneficiaryFirstName = 'Beneficiary',
  sponsorFirstName = 'Sponsor',
  jointSponsorFirstName = 'Joint Sponsor',
  householdMembersFirstNames = [],
  childrenFirstNames = [],
  applicantFirstName = 'Applicant'
) => {
  const titleForCategory = {
    applicant: `${applicantFirstName}'s Documents`,
    beneficiary: `${beneficiaryFirstName}'s Documents`,
    sponsor: `${sponsorFirstName}'s Documents`,
    evidenceOfRelationship: 'Evidence of Relationship',
    selfSufficiency: `${beneficiaryFirstName}'s Self Sufficiency`,
    jointSponsor: `${jointSponsorFirstName}'s Documents`,
    adminRequested: 'Additional Documents'
  }

  const categorizedDocumentRequests = _groupBy(documentRequests, (req) => req.category)

  const groupedDocumentRequests = _map(categorizedDocumentRequests, (documents, category) => {
    // Financial Doc requests need to be broken into subcategories -
    // dealt with in a later step
    if (category !== Categories.FinancialDocuments) {
      return {
        title: titleForCategory[category],
        documents,
        category
      }
    }
  })

  if (categorizedDocumentRequests.financialDocuments) {
    // Break Financial docs into subcategories before
    // appending to groupedDocumentRequests
    const nestedFinancialDocuments = groupFinancialDocuments(
      categorizedDocumentRequests.financialDocuments,
      beneficiaryFirstName,
      sponsorFirstName,
      householdMembersFirstNames,
      childrenFirstNames
    )
    // Insert in order, right after evidenceOfRelationship
    groupedDocumentRequests.splice(3, 0, nestedFinancialDocuments)
  }

  return _compact(groupedDocumentRequests)
}
