import React from 'react'

import Paragraph from 'components/type/paragraph'
import ExternalLink from 'components/external_link'

const BOUNDLESS_URL = 'https://www.boundless.com/immigration-resources/'

export const MBGC_CP_TIMELINE_STEPS = [
  {
    title: 'Form I-130 Approval',
    description: (
      <>
        <Paragraph>
          After you mail your package to U.S. Citizenship and Immigration Services (USCIS), it’ll typically take{' '}
          <ExternalLink href={`${BOUNDLESS_URL}how-long-does-marriage-green-card-take/#currently-living-abroad`}>
            14 months
          </ExternalLink>{' '}
          to receive their decision on Form{' '}
          <ExternalLink href={`${BOUNDLESS_URL}form-i-130-explained/`}>Form I-130</ExternalLink> (family sponsorship
          petition) and move on to the next stage.
        </Paragraph>
        <Paragraph>
          You’ll then receive a welcome email or letter from the National Visa Center (NVC) typically about{' '}
          <strong>7–8 weeks</strong> following approval. We’ve created a{' '}
          <ExternalLink href="https://boundless-assets.s3.us-west-2.amazonaws.com/Boundless-Green-Card-CP-Guide.pdf">
            guide
          </ExternalLink>{' '}
          to help you through the next steps.
        </Paragraph>
      </>
    )
  },
  {
    title: 'Forms and Documents',
    description: (
      <Paragraph>
        Once you have received your{' '}
        <ExternalLink href={`${BOUNDLESS_URL}form-i-130-explained/`}>Form I-130</ExternalLink> approval, you can start
        your Form <ExternalLink href={`${BOUNDLESS_URL}the-ds260-and-ds261-online-forms`}>Form DS-260</ExternalLink>{' '}
        (online green card application) and document gathering. The NVC Welcome Letter will contain a code so you can
        log into the State Department's{' '}
        <ExternalLink href="https://ceac.state.gov/IV/Login.aspx">CEAC website</ExternalLink>. This is where you will
        upload the supporting documents once they are gathered.
      </Paragraph>
    )
  },
  {
    title: 'In-Person Interview and Medical Exam',
    description: (
      <Paragraph>
        The{' '}
        <ExternalLink href={`${BOUNDLESS_URL}preparing-for-the-marriage-green-card-interview/`}>
          in-person interview
        </ExternalLink>{' '}
        typically happens <strong>3–4 months</strong> after the NVC approves all your documents. As soon as you receive
        your Interview Appointment Notice, you’ll schedule a{' '}
        <ExternalLink href={`${BOUNDLESS_URL}preparing-for-the-medical-exam/`}>medical exam</ExternalLink> and prepare
        all original copies of the documents you already submitted. You’ll bring the originals and medical-exam results
        to your interview.
      </Paragraph>
    )
  },
  {
    title: 'Green Card Approval',
    description: (
      <Paragraph>
        You can expect to receive a decision on your application either the same day as your interview or, more
        typically, <strong>about 1–2 weeks later</strong>. If you’re approved, you’ll receive your physical green card
        in the mail <strong>about 3–4 weeks</strong> after arriving in the United States! Fingers crossed!
      </Paragraph>
    )
  }
]

export const AOS_TIMELINE_STEPS = [
  {
    title: 'Receive USCIS Receipt Numbers',
    description: (
      <Paragraph>
        You can expect to receive your official{' '}
        <ExternalLink href={`${BOUNDLESS_URL}how-to-check-green-card-status/#receipt-numbers`}>
          Receipt Numbers
        </ExternalLink>{' '}
        via mail, typically <strong>about 2–3 weeks</strong> after mailing your package to U.S. Citizenship and
        Immigration Services (USCIS). We’ll be notified, too.
      </Paragraph>
    )
  },
  {
    title: 'Attend Biometrics Appointment',
    description: (
      <Paragraph>
        You’ll receive a notice about your{' '}
        <ExternalLink href={`${BOUNDLESS_URL}biometrics-appointment/`}>biometrics appointment</ExternalLink> typically
        about <strong>1 month</strong> after USCIS receives your application package. The appointment usually takes
        place at the{' '}
        <ExternalLink href="https://www.uscis.gov/about-us/find-uscis-office/field-offices">
          USCIS field office
        </ExternalLink>{' '}
        closest to where you live and is usually low-stress — USCIS will simply take fingerprints and photos of the
        spouse seeking a green card for background and security checks.
      </Paragraph>
    )
  },
  {
    title: 'Receive Work and Travel Permits',
    description: (
      <>
        <Paragraph>
          You’ll receive your{' '}
          <ExternalLink href={`${BOUNDLESS_URL}the-work-permit-explained/`}>work permit</ExternalLink> and{' '}
          <ExternalLink href={`${BOUNDLESS_URL}the-advance-parole-travel-document-explained/`}>
            travel permit
          </ExternalLink>{' '}
          typically about <strong>5–8 months</strong> after USCIS receives your application package, followed by your
          Social Security Number, which typically arrives <strong>about 2 weeks</strong> later.
        </Paragraph>
        <Paragraph>
          <strong>IMPORTANT</strong>: While the green card application is pending, a spouse seeking a green card must
          not travel anywhere outside the United States until they receive their travel permit.
        </Paragraph>
      </>
    )
  },
  {
    title: 'Attend In-Person Interview',
    description: (
      <Paragraph>
        You’ll receive an Interview Appointment Notice — listing the date, time, and location of your in-person
        interview — typically about <strong>10–14 months</strong> after receiving your Receipt Numbers. As soon as you
        notify the Boundless Support Team via email of your interview appointment, they’ll send you a detailed guide to
        help you{' '}
        <ExternalLink href={`${BOUNDLESS_URL}preparing-for-the-marriage-green-card-interview/`}>
          prepare for a successful interview
        </ExternalLink>
        .
      </Paragraph>
    )
  },
  {
    title: 'Receive a Decision',
    description: (
      <Paragraph>
        You can expect to receive a decision on your application either the same day as your interview or, more
        typically, about <strong>1–3 weeks later</strong>. If you’re approved, you’ll receive your physical green card
        in the mail <strong>about 2–4 weeks</strong> after that! Fingers crossed!
      </Paragraph>
    )
  }
]

export const K1_CP_TIMELINE_STEPS = [
  {
    title: 'USCIS Case Number',
    description: (
      <>
        <Paragraph>
          <strong>Timing:</strong> 2–4 weeks after your I-129F was shipped to USCIS
        </Paragraph>
        <Paragraph>Once you receive your USCIS Case Number, this means USCIS is processing your I-129F.</Paragraph>
      </>
    )
  },
  {
    title: 'I-129F Approval',
    description: (
      <>
        <Paragraph>
          <strong>Timing:</strong> 6–8 months after filing the I-129F
        </Paragraph>
        <Paragraph>
          USCIS will approve your I-129F and notify you via letter (I-797) and email. Once approved, they will then
          forward your case to the National Visa Center (NVC).
        </Paragraph>
      </>
    )
  },
  {
    title: 'NVC Case Number',
    description: (
      <>
        <Paragraph>
          <strong>Timing:</strong> up to 8 weeks after the I-129F is approved
        </Paragraph>
        <Paragraph>
          Once the NVC has received your case, they will send you an NVC Case Number, which you can use to track the
          status of your application. The NVC will then forward your case to the local embassy or consulate in your home
          country.
        </Paragraph>
      </>
    )
  },
  {
    title: 'U.S. Embassy Packet of Instructions',
    description: (
      <>
        <Paragraph>
          <strong>Timing:</strong> 6–8 weeks after receiving your NVC Case Number
        </Paragraph>
        <Paragraph>
          The U.S. Embassy Packet of Instructions, which arrives via regular mail or email, instructs you on everything
          you need to do before the interview.
        </Paragraph>
      </>
    )
  },
  {
    title: 'DS-160 Filed Online',
    description: (
      <>
        <Paragraph>
          <strong>Timing:</strong> once the{' '}
          <ExternalLink href="https://ceac.state.gov/CEACStatTracker/Status.aspx?eQs=o/iS8zDpeAKjMWCuebHqOw==">
            status of your case
          </ExternalLink>
          at the NVC is <strong>“Ready”</strong> you can apply – the sooner you apply the faster you can schedule your
          interview.
        </Paragraph>
        <Paragraph>
          You file the DS-160 Online Nonimmigrant Visa Application on the U.S. Department of State’s{' '}
          <ExternalLink href="https://ceac.state.gov/GenNIV/Default.aspx">website</ExternalLink>. You can generate a
          “DS-160 Worksheet” on your Boundless account to assist with this process.
        </Paragraph>
      </>
    )
  },
  {
    title: 'Interview Date',
    description: (
      <>
        <Paragraph>
          <strong>Timing:</strong> you can schedule it after you receive your U.S. Embassy Packet of Instructions and
          file the DS-160 online
        </Paragraph>
        <Paragraph>
          You will schedule your interview by following the instructions listed in the U.S. Embassy Packet of
          Instructions.
        </Paragraph>
      </>
    )
  },
  {
    title: 'Medical Exam Date',
    description: (
      <>
        <Paragraph>
          <strong>Timing:</strong> 7–14 days before your interview date
        </Paragraph>
        <Paragraph>
          You will schedule your medical exam with the instructions provided by your{' '}
          <ExternalLink href="https://travel.state.gov/content/travel/en/us-visas/visa-information-resources/list-of-posts.html">
            embassy or consulate
          </ExternalLink>
          .
        </Paragraph>
      </>
    )
  },
  {
    title: 'K1 Fiancé(e) Visa Approval',
    description: (
      <>
        <Paragraph>
          <strong>Timing:</strong> ≈2 weeks after the interview
        </Paragraph>
        <Paragraph>
          The officer typically approves the K1 Fiancé(e) Visa at the interview. You’ll then receive your visa in the
          mail.
        </Paragraph>
      </>
    )
  },
  {
    title: 'U.S. Entry Date',
    description: (
      <>
        <Paragraph>
          <strong>Timing:</strong> up to 6 months after the medical exam date
        </Paragraph>
        <Paragraph>
          You will enter the U.S. and go through a final security screening with a Customs and Border Protection (CBP)
          officer at the border.
        </Paragraph>
      </>
    )
  },
  {
    title: 'Marriage Date',
    description: (
      <>
        <Paragraph>
          <strong>Timing:</strong> up to 90 days after entry into the U.S.
        </Paragraph>
        <Paragraph>The beneficiary will marry the U.S. citizen sponsor within 90 days of entering the U.S.</Paragraph>
      </>
    )
  }
]
