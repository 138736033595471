import React, { FunctionComponent } from 'react'
import IconWrapper, { IconWrapperExternalProps } from './icon_wrapper'

const LightningBoltIcon: FunctionComponent<IconWrapperExternalProps> = (props) => {
  return (
    <IconWrapper {...props} viewBox="0 0 13 21" aria-hidden="true">
      <path d="M12.9321 9.24967L4.08757 20.6753C4.0259 20.7535 3.93159 20.7989 3.83195 20.7983C3.7801 20.7996 3.72883 20.7873 3.6832 20.7627C3.54413 20.6912 3.4761 20.5302 3.52145 20.3805L5.90563 12.4914H1.17595C1.0542 12.4909 0.942859 12.4221 0.887979 12.3133C0.83801 12.2084 0.847977 12.085 0.913974 11.9894L9.49305 0.204431C9.58043 0.083565 9.73885 0.0380218 9.87692 0.0941171C10.015 0.150068 10.0969 0.293345 10.0754 0.44096L8.93994 8.7283H12.6762C12.8 8.72801 12.9129 8.79842 12.9674 8.90974C13.0211 9.02063 13.0074 9.15234 12.9318 9.24979L12.9321 9.24967Z" />
    </IconWrapper>
  )
}

export default LightningBoltIcon
