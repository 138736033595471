import _each from 'lodash/forEach'
import _extend from 'lodash/extend'
import _isArray from 'lodash/isArray'
import _isBoolean from 'lodash/isBoolean'
import _isDate from 'lodash/isDate'
import _isNumber from 'lodash/isNumber'
import _keys from 'lodash/keys'

// Flatten nested objects
// Appropriated from https://www.npmjs.com/package/flat
function flatten(target, opts) {
  opts = opts || {}

  const delimiter = opts.delimiter || '.'
  const output = {}
  let maxDepth = opts.maxDepth
  let currentDepth = 1

  function step(object, prev) {
    _each(_keys(object), (key) => {
      const value = object[key]
      const isarray = opts.safe && Array.isArray(value)
      const type = Object.prototype.toString.call(value)
      const isobject = type === '[object Object]' || type === '[object Array]'
      const newKey = prev ? prev + delimiter + key : key

      if (!opts.maxDepth) {
        maxDepth = currentDepth + 1
      }

      if (!isarray && isobject && Object.keys(value).length && currentDepth < maxDepth) {
        ++currentDepth
        return step(value, newKey)
      }

      output[newKey] = value
    })
  }

  step(target)

  return output
}

export default class HeapAdapter {
  constructor(reporter) {
    this.reporter = reporter
  }

  identify(userId, traits, options, callback) {
    const sanitizedTraits = this._sanitize(traits)

    try {
      window.heap.identify(userId)
      window.heap.addUserProperties(sanitizedTraits)
      this._debugHeap('identify', sanitizedTraits)

      this.reporter._runCallback(callback)
    } catch (e) {
      this.reporter._catchError(e)
    }
  }

  track(eventName, eventData, callback) {
    const sanitizedProperties = this._sanitize(eventData)

    try {
      window.heap.track(eventName, sanitizedProperties)
      this._debugHeap('track', sanitizedProperties)
      this.reporter._runCallback(callback)
    } catch (e) {
      this.reporter._catchError(e)
    }
  }

  addUserProperties(properties) {
    const sanitizedProperties = this._sanitize(properties)

    try {
      window.heap.addUserProperties(sanitizedProperties)
      this._debugHeap('addUserProperties', sanitizedProperties)
    } catch (e) {
      this.reporter._catchError(e)
    }
  }

  // sanitization, flattening, and preprocessing of event data
  _sanitize(object) {
    var output = {}

    // json
    // must flatten including the name of the original trait/property
    function trample(key, value) {
      const nestedObject = {}
      nestedObject[key] = value
      const flattenedObject = flatten(nestedObject, { safe: true })

      // stringify arrays inside nested object to be consistent with top level behavior of arrays
      for (let key in flattenedObject) {
        if (_isArray(flattenedObject[key])) flattenedObject[key] = JSON.stringify(flattenedObject[key])
      }

      return flattenedObject
    }

    for (let key in object) {
      if (object.hasOwnProperty(key)) {
        let value = object[key]
        // Heap's native library will drop null and undefined properties anyway
        // so no need to send these
        // also prevents uncaught errors since we call .toString() on non objects
        if (value === null || value === undefined) continue

        // date
        if (_isDate(value)) {
          output[key] = value.toISOString()
          continue
        }

        // leave boolean and numbers as is
        if (_isBoolean(value) || _isNumber(value)) {
          output[key] = value
          continue
        }

        // arrays of objects (eg. `products` array)
        if (toString.call(value) === '[object Array]') {
          output = _extend(output, trample(key, value))
          continue
        }

        // non objects
        if (toString.call(value) !== '[object Object]') {
          output[key] = value.toString()
          continue
        }

        output = _extend(output, trample(key, value))
      }
    }

    return output
  }

  _debugHeap(eventName, data) {
    if (!this.debug) return

    this.reporter._debugHeap(eventName, data)
  }
}

export function loadHeap(appId) {
  // Create a queue, but don't obliterate an existing one!
  const heap = (window.heap = window.heap || [])

  // If the real heap.js is already on the page return.
  if (heap.initialize) {
    return heap
  }

  // If the snippet was invoked already show an error.
  if (heap.invoked) {
    /* eslint-disable no-console */
    if (window.console && console.error) {
      console.error('Heap snippet included twice.')
    }
    /* eslint-enable no-console */

    return
  }

  // Invoked flag, to make sure the snippet
  // is never invoked twice.
  heap.invoked = true

  // Keep track of whether the load() function worked
  heap.loadedSuccessfully = false

  // Define a method to load Analytics.js from our CDN,
  // and that will be sure to only ever load it once.
  heap.initialize = function (config) {
    const heap = window.heap || []

    heap.load = function (appId, config) {
      heap.appid = appId
      heap.config = config

      const methodFactory = function (type) {
        return function () {
          heap.push([type].concat(Array.prototype.slice.call(arguments, 0)))
        }
      }

      const heapMethods = [
        'addEventProperties',
        'addUserProperties',
        'clearEventProperties',
        'identify',
        'removeEventProperty',
        'setEventProperties',
        'track',
        'unsetEventProperty',
        'resetIdentity'
      ]
      _each(heapMethods, function (method) {
        heap[method] = methodFactory(method)
      })
    }

    heap.load(appId, config)
  }

  const _loader = () => {
    return new Promise((resolve, reject) => {
      heap.initialize()

      if (heap.identify) {
        resolve()
      } else {
        reject()
      }
    })
  }

  // Add a version to keep track of what's in the wild.
  heap.SNIPPET_VERSION = '4.0.0'

  // insert heap js
  const script = document.createElement('script')
  const heapTag = document.getElementsByTagName('script')[0]

  script.type = 'text/javascript'
  script.async = !0
  script.src = `https://cdn.heapanalytics.com/js/heap-${appId}.js`

  heapTag.parentNode.insertBefore(script, heapTag)

  return _loader()
}
