import React from 'react'
import { connect } from 'react-redux'
import convert from 'convert-units'

import { joinI18nKeys } from 'lib/i18n'
import panelLocaleService from 'services/panel_locale_service'
import { getUnitConversionPreference } from 'reducers/selectors'
import { QuestionnaireInputContainerProps } from '../index'

import ValidatedInput from 'components/forms/validated_input'
import WeightInput from './weight_input'
import WeightLabel from './weight_label'

const IMPERIAL_UNIT = 'lb'
const METRIC_UNIT = 'kg'

interface MappedProps {
  units: 'metric' | 'imperial'
}

type Props = QuestionnaireInputContainerProps & MappedProps

class WeightInputContainer extends React.Component<Props> {
  currentUnit() {
    return this.isImperial() ? IMPERIAL_UNIT : METRIC_UNIT
  }

  isImperial() {
    return this.props.units === 'imperial'
  }

  isMetric() {
    return this.props.units === 'metric'
  }

  convertInputValueToMetric = (value: Nullable<string>): number | null => {
    if (!value || value === '') {
      return null
    }

    value = value.trim().replace(/[^\d]/g, '')

    let metricValue

    if (this.isMetric()) {
      metricValue = parseInt(value, 10)
    } else {
      metricValue = convert(value).from(IMPERIAL_UNIT).to(METRIC_UNIT)
    }

    return metricValue
  }

  formatInputValue = (value: Nullable<string>): string => {
    if (!value) {
      return ''
    }

    let displayValue

    if (this.isMetric()) {
      displayValue = value
    } else {
      displayValue = convert(value).from(METRIC_UNIT).to(IMPERIAL_UNIT)
    }

    return parseInt(displayValue, 10).toString()
  }

  fieldLabel() {
    return this.isImperial() ? 'lbs' : METRIC_UNIT
  }

  render() {
    const { disabled, path } = this.props
    const id = `${this.props.id}-${this.currentUnit()}`

    return (
      <div className="o-block c-paper-form__group o-grid--fluid o-flag o-grid__cell--stack@sm">
        <label
          htmlFor={id}
          className="o-grid__cell--3/12 o-grid__cell--off@sm c-paper-form__label c-paper-form__label--border-on\@sm c-paper-form__segment"
        >
          <span className="c-type c-type--subhead-sm">
            {panelLocaleService.t(joinI18nKeys(this.props.resourceKeys, `${this.props.name}.label`))}
          </span>
        </label>

        <div className="o-grid__cell--9/12 o-grid__cell--off@sm o-grid--fluid c-paper-form__segment">
          <ValidatedInput className={'o-grid__cell--3/12'} disabled={disabled} path={path}>
            {({ fireValidation, getValidationClasses, renderValidations }) => (
              <React.Fragment>
                <WeightInput
                  className={getValidationClasses()}
                  formatter={this.formatInputValue}
                  path={path}
                  id={`${this.props.id}-${this.currentUnit()}`}
                  serializer={this.convertInputValueToMetric}
                  onBlur={fireValidation}
                />
                {renderValidations()}
              </React.Fragment>
            )}
          </ValidatedInput>
          <WeightLabel
            label={this.fieldLabel()}
            className="o-grid__cell--fill c-paper-form__label c-paper-form__label--border-on@sm"
          />
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    units: getUnitConversionPreference(state)
  }
}

export default connect(mapStateToProps)(WeightInputContainer)
