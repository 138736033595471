import React from 'react'

import { Provider } from 'react-redux'
import LoginNavLink from './login_navlink'

import getStore from 'stores/app_store'

interface Props {
  mobile: boolean
}

function LoginNavLinkRoot({ mobile }: Props) {
  return (
    <Provider store={getStore()}>
      <LoginNavLink mobile={mobile} />
    </Provider>
  )
}

export default LoginNavLinkRoot
