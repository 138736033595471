import moment from 'moment'

import getStore from 'stores/app_store'
import api from 'lib/api'
import { getCurrentKaseId } from 'reducers/selectors'
import forceRefresh from 'lib/force_refresh'
import Panel from 'lib/model/panel'

const errorText = 'Oops, an error occurred reaching Boundless. ' + 'Press OK to refresh the page.'

export function panelChangedListener(action: any, previousPanel: Panel) {
  if ((action && previousPanel) == null) {
    return null
  }

  // Pacific UTC offset
  const pacificTimezoneOffset = -7

  // getting the local offset, then setting pacific as the offset focal point
  const localOffset = moment().utcOffset() / 60 - pacificTimezoneOffset

  const request = {
    kaseId: getCurrentKaseId(getStore().getState()),
    eventType: 'panel_changed',
    panelName: previousPanel.name,
    timezoneOffset: localOffset
  }

  api.userEvents.create(request).catch(() => {
    alert(errorText)
    forceRefresh()
  })
}

export function paymentFunnelListener(panel_name: string) {
  const request = {
    kaseId: getCurrentKaseId(getStore().getState()),
    eventType: 'payment_funnel_viewed',
    panelName: panel_name
  }

  api.userEvents.create(request).catch(() => {
    alert(errorText)
    forceRefresh()
  })
}
