import React from 'react'
import ReactMarkdown from 'react-markdown'
import { withCurlyQuotes } from 'lib/utilities/text_parsing'
import InlineContextualHelp from './screens/questionnaire/panel/inline_contextual_help'

interface MarkdownOptions {
  linkToNewTabs?: boolean
  toggleContextualHelp?: (args: any) => void
}

interface MarkdownProps {
  source: string
  options?: MarkdownOptions
  className?: string
}

const linkRenderer = (properties, options: MarkdownOptions) => {
  if (options.toggleContextualHelp && !properties.href) {
    return <InlineContextualHelp text={properties.children[0]} toggleContextualHelp={options.toggleContextualHelp} />
  }
  return (
    <a href={properties.href} target="_blank" rel="noopener noreferrer">
      {properties.children}
    </a>
  )
}

const getRenderers = (options: MarkdownOptions) => {
  const renderers: any = {}

  if (options.linkToNewTabs) {
    renderers.Link = (props) => linkRenderer(props, options)
  }

  return renderers
}

const Markdown = ({ source, options, className }: MarkdownProps) => {
  source = withCurlyQuotes(source)

  return <ReactMarkdown className={className} source={source} renderers={getRenderers(options || {})} />
}

export default Markdown
