import React from 'react'
import ReactDOM from 'react-dom'
import domReady from 'domready'
import { initializeCurrentLocation } from 'redux-little-router'

import getStore from 'stores/app_store'

import { fixHoverOnTouchDevices } from './lib/touch_events'
import { isPresent } from './lib/presence'
import { initializeTelemetry } from './lib/telemetry'
import { configureErrorReporting } from './lib/honeybadger'
import { settingsPresent, reportingEnvironment } from './lib/settings'
import i18nConfig from './config/i18n'
import { loadKase, fetchKaseMetadata, fetchKaseFormMetadata, loadCurrentTags } from './actions/kase_actions'
import { loadCurrentUser } from './actions/user_actions'

import {
  initializeStore,
  initializeCurrentPanel,
  loadReferralState,
  loadLastViewedSectionAndPanelName,
  initializeInterFont
} from './actions/initialize_actions'

import { loadCurrentFeatures } from 'actions/feature_actions'
import { isMobileBrowser } from 'reducers/selectors'

import ApplicationDataStore from './stores/application_data_store'
import AppRoot from './components/app_root'
import DocumentRequestLocale from './lib/document_request_locale'
import ModalRoot from './components/modal_root'
import LoginNavLinkRoot from './components/login_navlink_root'
import NavbarRoot from './components/navbar_root'

import './config/silence_react_devtools'
import './config/polyfills'
import './globals.js'
import './lib/feature_flags_deprecated'
import './lib/api/config/asset_checking'

if (!isPresent(window.Rails)) {
  window.Rails = require('@rails/ujs')
  window.Rails.start() // to support data-method="delete" on sign out link
}

domReady(function () {
  const store = getStore()

  window.applicationDataStore = new ApplicationDataStore(store)

  i18nConfig(window.resourceServiceData)
  window.documentRequestLocaleService = new DocumentRequestLocale(store)

  if (settingsPresent()) {
    configureErrorReporting()

    // Disable jQuery's exception hook and prevent it from swallowing errors
    window.jQuery.Deferred.exceptionHook = null
  }

  // TODO remove check after timeline becomes a React component
  if (window.applicationStoreData) {
    store.dispatch(loadCurrentFeatures(window.applicationStoreData.currentUser.id))
    store.dispatch(loadKase(window.applicationStoreData.kase))
    store.dispatch(loadLastViewedSectionAndPanelName(window.applicationStoreData.kase.last_panel_name))
    store.dispatch(loadCurrentUser(window.applicationStoreData.currentUser))
    store.dispatch(loadReferralState(window.applicationStoreData.referral))
    store.dispatch(initializeStore(window.applicationStoreData))
  }

  initializeTelemetry({
    reportingEnvironment: reportingEnvironment(),
    reduxStore: store
  })

  const rootContainer = document.getElementById('react-root')
  const modalContainer = document.getElementById('modal-react-root')
  const desktopSessionContainer = document.getElementById('desktop-session-link')
  const mobileSessionContainer = document.getElementById('mobile-session-link')
  const navbarContainer = document.getElementById('app-navbar')

  function renderRootComponents() {
    if (navbarContainer) {
      ReactDOM.render(<NavbarRoot />, navbarContainer)
    }

    if (rootContainer) {
      initializeCurrentPanel(store)
      initializeInterFont()
      ReactDOM.render(<AppRoot />, rootContainer)
    }

    if (modalContainer) {
      const onlyModals = rootContainer ? null : ['SessionModal']
      const props = { onlyModals }

      ReactDOM.render(<ModalRoot {...props} />, modalContainer)
    }

    if (desktopSessionContainer) {
      ReactDOM.render(<LoginNavLinkRoot mobile={false} />, desktopSessionContainer)
    }

    if (mobileSessionContainer) {
      ReactDOM.render(<LoginNavLinkRoot mobile={true} />, mobileSessionContainer)
    }
  }

  const isMobile = isMobileBrowser(store.getState())

  // In order to disable front chat, add the section name to application_helper.rb
  if (window.frontUserHash && window.frontIdentity && window.FrontChat) {
    window.FrontChat('init', {
      chatId: window.FCSP,
      email: window.frontIdentity,
      userHash: window.frontUserHash,
      useDefaultLauncher: isMobile ? false : true
    })
  }

  fixHoverOnTouchDevices()

  // Final data load and render
  const actionPromises = [
    store.dispatch(fetchKaseFormMetadata()),
    store.dispatch(fetchKaseMetadata()),
    store.dispatch(loadCurrentTags())
  ]

  Promise.all(actionPromises).then(() => {
    const initialLocation = store.getState().router

    if (initialLocation && rootContainer) {
      store.dispatch(initializeCurrentLocation(initialLocation))
    }

    renderRootComponents()
  })
})
