import _isEmpty from 'lodash/isEmpty'
import moment from 'moment'

import { buildMessage, Validator } from './'

function parseDate(date: string) {
  return moment(date, 'YYYY/MM/DD')
}

const MIN_VALID_DATE = parseDate('1800/01/01')

const validDateOrOverride: Validator = ({ getModelData, path, options = {} }) => {
  const date = getModelData(`${path}.date`)
  const override = getModelData(`${path}.string`)

  if (_isEmpty(date) && _isEmpty(override)) {
    const message = options.message || 'Please fill out either date or override'
    return [buildMessage(message)]
  }

  if (!_isEmpty(override)) {
    return []
  }

  const parsedDate = parseDate(date)

  const errors = []

  if (!parsedDate.isValid()) {
    errors.push(buildMessage('Please enter a valid date'))
  } else if (parsedDate < MIN_VALID_DATE) {
    errors.push(buildMessage(`Please enter a date after ${MIN_VALID_DATE.format('l')}`))
  }

  return errors
}

export default validDateOrOverride
