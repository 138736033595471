import React from 'react'
import { connect } from 'react-redux'
import ReactMarkdown from 'react-markdown'

import { MarkdownLinkRenderer } from 'lib/markdown'

import { getCurrentKaseKindResourceKey, getCurrentSectionErrors } from 'reducers/selectors'

import panelLocaleService from 'services/panel_locale_service'

function PanelErrors(props) {
  return (
    <div>
      {props.errors.map((error, i) => {
        return (
          <div key={i} className="o-block c-type c-type--body-sans-md">
            <ReactMarkdown
              source={panelLocaleService.t(`${props.resourceKey}.outcomes.errors.${error}`)}
              renderers={{ Link: MarkdownLinkRenderer }}
            />
          </div>
        )
      })}
    </div>
  )
}

function mapStateToProps(state) {
  return {
    errors: getCurrentSectionErrors(state),
    resourceKey: getCurrentKaseKindResourceKey(state)
  }
}

export default connect(mapStateToProps)(PanelErrors)
