import React, { FunctionComponent } from 'react'
import IconWrapper, { IconWrapperExternalProps } from './icon_wrapper'

const SectionCompleteGrayIcon: FunctionComponent<IconWrapperExternalProps> = (props) => (
  <IconWrapper {...props} viewBox="0 0 170 170">
    <circle opacity="0.3" cx="85" cy="85" r="85" fill="#BDBDBD" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32 151.457V47H137V152.244C122.626 163.375 104.587 170 85 170C64.9561 170 46.5327 163.062 32 151.457Z"
      fill="white"
    />
    <circle cx="144" cy="39" r="25" fill="#5ED09C" />
    <path d="M56 70H100" stroke="#919292" strokeWidth="5" strokeLinecap="round" />
    <path d="M56 91H110" stroke="#919292" strokeWidth="5" strokeLinecap="round" />
    <path d="M56 112H75" stroke="#919292" strokeWidth="5" strokeLinecap="round" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M159.001 30.7865C160.029 31.8264 160.019 33.5024 158.979 34.5299L141.716 51.5888C140.684 52.6077 139.026 52.6077 137.994 51.5888L128.787 42.4907C127.747 41.4632 127.737 39.7872 128.765 38.7473C129.793 37.7074 131.469 37.6974 132.508 38.725L139.855 45.9845L155.258 30.7642C156.298 29.7366 157.974 29.7466 159.001 30.7865Z"
      fill="white"
    />
  </IconWrapper>
)

export default SectionCompleteGrayIcon
