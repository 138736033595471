import React, { FunctionComponent } from 'react'
import EmptyCircleIcon from 'components/icons/empty_circle_icon'
import Button from 'components/button'

interface Props {
  openConfirmationModal: () => void
  allSectionsFinished?: boolean
}

const StartAssessmentPanel: FunctionComponent<Props> = ({ openConfirmationModal, allSectionsFinished }) => (
  <div className="o-block" style={{ opacity: allSectionsFinished ? 1 : 0.6 }}>
    <div className="o-block o-griddle__row o-griddle__row--no-gutters o-griddle__row--align-items-center">
      <div className="o-griddle__col--auto py-3">
        <EmptyCircleIcon className="o-media__figure mr-3" />
      </div>
      <div className="o-griddle__col">
        <h3 className="c-type--inline c-type--body-sans-md py-3">Boundless Checks All Your Forms</h3>
        {!allSectionsFinished && <p>Complete all questionnaires to unlock</p>}
      </div>
      <div className="o-griddle__col--12 o-griddle__col--auto-sm-up py-1">
        <Button color="primary" onClick={openConfirmationModal} disabled={!allSectionsFinished} label="Begin" />
      </div>
    </div>
  </div>
)

export default StartAssessmentPanel
