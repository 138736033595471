import React from 'react'

interface Props {
  headerText: string
  children: any
}

export default class Section extends React.Component<Props> {
  render() {
    const { headerText, children } = this.props

    return (
      <div className="o-block o-block--ample">
        <div className="o-block">
          <h3 className="c-type c-type--headline-sm c-type--secondary o-block o-block--compact">{headerText}</h3>

          <hr className="o-block c-divider c-divider--emphasized" />
        </div>

        <div className="o-block">{children}</div>
      </div>
    )
  }
}
