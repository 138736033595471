import React, { FunctionComponent } from 'react'
import IconWrapper, { IconWrapperExternalProps } from './icon_wrapper'

const AskIcon: FunctionComponent<IconWrapperExternalProps> = (props) => (
  <IconWrapper {...props} viewBox="0 0 32 32">
    <path d="M27.6 30.227l-8.226-5.027h-14.974v-21.6h23.2zM6 23.6h13.826l6.174 3.773v-22.173h-20zM12.56 13.68h-1.92v2.24h1.92zM16.96 13.68h-1.92v2.24h1.92zM21.36 13.68h-1.92v2.24h1.92z" />
  </IconWrapper>
)

export default AskIcon
