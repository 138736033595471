import Card from './card'
import ChapterHeader from './chapter_header'
import { FieldIndexType, FieldType } from 'components/forms/field/index'

interface PanelComponent {
  name: string
  props: { [propName: string]: any }
}

export interface SectionType {
  name: string
  chapters: ChapterType[]
}

export interface ChapterType {
  name: string
  panels: PanelType[]
  section_name?: string
}

export interface PanelType {
  allFields: FieldType[]
  chapter_title_key: string
  component?: PanelComponent
  complete: boolean
  contributesToProgress: boolean
  emphasized: boolean
  fields: FieldType[]
  hasData: boolean
  name: string
  navigable: boolean
  outcome: string
  panel_keys: string
  panel_index: Nullable<number | string>
  chapter_name: string
  section_name: string
  currentIndex: FieldIndexType
  parentIndex: FieldIndexType
  slug: string
  type?: string
  panels?: PanelType[]
}

export interface PanelGroupType {
  name: string
  panels: PanelType[]
}

const Panel = {
  Card,
  ChapterHeader
}

export default Panel
