import Paragraph from 'components/type/paragraph'
import React, { FunctionComponent } from 'react'
import { Issue } from 'reducers/issues'
import IssueCard from './issue_card'
import SubmitSuccess from './submit_success'

interface ExplicitProps {
  taskIsAssignedToBoundless: boolean
  issues: Issue[]
  onOpenThread: (issue: Issue) => void
}

const MobileIssuesContaienr: FunctionComponent<ExplicitProps> = ({
  issues,
  taskIsAssignedToBoundless,
  onOpenThread
}) => {
  if (taskIsAssignedToBoundless) {
    return <SubmitSuccess />
  }

  if (issues.length === 0) {
    return <Paragraph>There are no issues to resolve at the moment.</Paragraph>
  }

  return (
    <>
      {issues.map((issue) => (
        <IssueCard key={issue.id} issue={issue} onOpenThread={onOpenThread} />
      ))}
    </>
  )
}

export default MobileIssuesContaienr
