import React, { FunctionComponent, useState } from 'react'
import AddressForm from '../inputs/address_history/address_form'
import EmploymentForm from '../inputs/employment_history/employment_form'
import { CurrentAddressFilledOptions } from '../lib/types'

export type HistoryType = 'address_history_set' | 'employment_history_set'

interface Props {
  /**
   * The page path of the questionnaire. Used to determine if we're viewing a sponsor or beneficiary history
   */
  currentPagePath?: string
  /**
   * The type of history set. Used to determine which form to render on click
   */
  historyType: HistoryType
  /**
   * An object that tells you if current address has been filled or if spouse's current address has been filled
   * Used for the AddressForm to determine whether or not to render the currently lived here checkbox
   */
  currentAddressFilledOptions?: CurrentAddressFilledOptions
}

const AddHistoryButton: FunctionComponent<Props> = ({ currentPagePath, historyType, currentAddressFilledOptions }) => {
  const [isAddingHistory, setIsAddingHistory] = useState(false)

  const addHistoryBtnClick = (event) => {
    event.preventDefault()
    setIsAddingHistory(true)
  }

  const saveCallback = (isFormError?: boolean) => {
    if (!isFormError) {
      setIsAddingHistory(false)
    }
  }

  const renderForm = () => {
    if (historyType === 'address_history_set') {
      return (
        <AddressForm
          saveCallback={saveCallback}
          cancelCallback={() => setIsAddingHistory(false)}
          editSaveCallback={saveCallback}
          currentPagePath={currentPagePath}
          currentAddressFilledOptions={currentAddressFilledOptions}
        />
      )
    } else if (historyType === 'employment_history_set') {
      return (
        <EmploymentForm
          saveCallback={saveCallback}
          cancelCallback={() => setIsAddingHistory(false)}
          currentPagePath={currentPagePath}
        />
      )
    }
  }

  return isAddingHistory ? (
    renderForm()
  ) : (
    <button className="c-btn c-btn--quaternary" onClick={(event) => addHistoryBtnClick(event)} type="button">
      Add History
    </button>
  )
}

export default AddHistoryButton
