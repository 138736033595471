import React, { FunctionComponent, ReactNode, useState } from 'react'
import { connect } from 'react-redux'

import Paragraph from 'components/type/paragraph'
import Button from 'components/button'
import { DASHBOARD_PHASE_BUTTON_TYPE_MAIN_CTA, TELEMETRY_EVENTS } from 'lib/constants'
import ClockIcon from 'components/icons/clock_icon'
import { openChat } from 'actions/chat_actions'
import { postTelemetry } from 'actions/telemetry_actions'
import { sendTaskEvent } from 'actions/tasks_actions'
import { KasePhaseStepConfirmation } from './types'
import { bindActionCreators, Dispatch } from 'redux'
import ConfirmationModal from 'components/modals/modal/confirmation_modal'

interface ExplicitProps {
  confirmation?: KasePhaseStepConfirmation
  currentPhaseName: string
  description?: ReactNode
  estimatedLength?: string
  header: ReactNode
  primaryCTA?: string | ReactNode
  primaryCTAActionName?: string
  primaryCTAEventName?: string
  primaryCTAUrl?: string
}

interface ActionProps {
  openChat: (args: { via: string }) => void
  postTelemetry?: (eventName: string, { currentPhase, sectionName }) => void
  sendTaskEvent: typeof sendTaskEvent
}

type Props = ExplicitProps & ActionProps

const PhaseSummary: FunctionComponent<Props> = ({
  confirmation,
  currentPhaseName,
  description,
  estimatedLength,
  header,
  openChat,
  primaryCTA,
  primaryCTAActionName,
  primaryCTAEventName,
  primaryCTAUrl,
  sendTaskEvent
}) => {
  const [showModal, setShowModal] = useState(false)
  const primaryCTAIsLabel = typeof primaryCTA === 'string'

  const sendTransitionEvent = (eventName: string) => {
    sendTaskEvent(eventName)
    setShowModal(false)
  }

  const onCTAClick = (eventName?: string) => {
    postTelemetry(TELEMETRY_EVENTS.CLICKED_PHASE_DETAILS, {
      currentPhaseName,
      buttonType: DASHBOARD_PHASE_BUTTON_TYPE_MAIN_CTA
    })

    if (eventName) {
      if (confirmation) {
        setShowModal(true)
      } else {
        sendTransitionEvent(eventName)
      }
    }
  }

  const renderCTA = () => {
    if (primaryCTAUrl) {
      if (primaryCTAUrl.endsWith('.pdf')) {
        return (
          <a
            href={primaryCTAUrl}
            className="c-btn c-btn--emphasized c-btn--block@sm-down"
            onClick={() => onCTAClick()}
            target="_blank"
            rel="noopener noreferrer"
          >
            {primaryCTA}
          </a>
        )
      } else {
        return (
          <a href={primaryCTAUrl} className="c-btn c-btn--emphasized c-btn--block@sm-down" onClick={() => onCTAClick()}>
            {primaryCTA}
          </a>
        )
      }
    } else if (primaryCTAEventName) {
      return (
        <Button
          className="c-btn--block@sm-down"
          color="emphasized"
          label={primaryCTA}
          onClick={() => onCTAClick(primaryCTAEventName)}
        />
      )
    } else if (primaryCTAActionName === 'open_chat') {
      return (
        <Button
          className="c-btn--block@sm-down"
          color="emphasized"
          label={primaryCTA}
          onClick={(e) => {
            e.preventDefault()
            e.nativeEvent.stopImmediatePropagation()

            openChat({ via: 'DashboardIneligibleState' })
          }}
        />
      )
    }
  }

  return (
    <div className="c-phase-details__info">
      <small className="c-type c-type--subhead-sm o-block--compact c-phase-details__subhead">The latest</small>
      <h1 className="c-phase-details__header o-block" data-qa="phase-message">
        {header}
      </h1>
      <Paragraph size="sm" data-qa="phase-description" spacing="none">
        {description}
      </Paragraph>
      <div className="c-phase-details__controls">
        <div className="o-griddle__row o-griddle__row--align-items-center">
          {estimatedLength && (
            <div className="sm:hidden md:block o-griddle__col">
              <Paragraph size="sm" data-qa="phase-estimated-length" spacing="none">
                <ClockIcon size="small" /> {estimatedLength}
              </Paragraph>
            </div>
          )}
          {primaryCTA && (
            <div className="o-griddle__col text-right" data-qa="phase-cta">
              {primaryCTAIsLabel ? renderCTA() : primaryCTA}
            </div>
          )}
        </div>
      </div>
      {confirmation && (
        <ConfirmationModal
          cancelLabel={confirmation.cancel_label}
          confirmLabel={confirmation.confirm_label}
          onRequestClose={() => setShowModal(false)}
          header={confirmation.title}
          body={confirmation.description}
          isOpen={showModal}
          onRequestConfirm={() => sendTransitionEvent(primaryCTAEventName)}
        />
      )}
    </div>
  )
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators({ openChat, postTelemetry, sendTaskEvent }, dispatch)
}

export default connect(null, mapDispatchToProps)(PhaseSummary)
