import buildMarriageBasedGreenCardResourceContext from './marriage_based_green_card'
import buildNewMarriageBasedGreenCardResourceContext from './new_marriage_based_green_card'
import buildNaturalizationResourceContext from './naturalization'
import buildDomainResourceContext from './shared/domain'

import { ApplicationState } from 'reducers'

type ComputedResourceContext = any

type TranslateDataIndex = Nullable<number>

export interface TranslateOptions {
  index?: TranslateDataIndex
}

const builders = {
  MarriageBasedGreenCard: buildMarriageBasedGreenCardResourceContext,
  NewMarriageBasedGreenCard: buildNewMarriageBasedGreenCardResourceContext,
  Naturalization: buildNaturalizationResourceContext
}

export function getComputedResourceContext(
  state: ApplicationState,
  options: TranslateOptions,
  kaseKind: string
): ComputedResourceContext {
  return {
    ...builders[kaseKind](state, options),
    ...buildDomainResourceContext()
  }
}
