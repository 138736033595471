import SingleRequestEnsurer from 'lib/single_request_ensurer'
import api from 'lib/api'
import forceRefresh from 'lib/force_refresh'
import { reportError } from 'lib/honeybadger'
import { openModal } from 'actions/modal_actions'

import {
  ATTORNEY_AGREEMENT_ACCEPTED,
  LOAD_DATA,
  LOAD_KASE_FORM_METADATA,
  LOAD_KASE_METADATA,
  LOAD_KASE,
  LOADING_DASHBOARD,
  LOADING_DASHBOARD_SUCCESS,
  LOADING_KASE_TAGS,
  LOADED_KASE_TAGS_SUCCESS,
  POST_SAVE_TRANSITION_END,
  SAVED_DATA,
  SAVING_DATA,
  SECTION_NAME_PETITION,
  SECTION_NAME_REMAINING_INFORMATION,
  SET_GLOBAL_ERROR,
  SET_IS_PAID,
  UPDATE_DATA,
  UPDATE_WORKFLOW_STATUS,
  LOADING_LAWYER_INTERACTIONS_SUCCESS,
  LOADING_LAWYER_INTERACTIONS,
  LOADING_LAWYER_INTERACTIONS_ERROR
} from 'lib/constants'

import {
  getCurrentKaseId,
  getCurrentPanel,
  getCurrentPanelName,
  getCurrentSectionName,
  getPendingUpdates,
  getCurrentSectionProgress,
  isCurrentlyInSection
} from 'reducers/selectors'

const singleRequestEnsurer = new SingleRequestEnsurer()

function postSaveTransitionEnd() {
  return (dispatch) => {
    dispatch({
      type: POST_SAVE_TRANSITION_END
    })
  }
}

function updateFieldData({ path, operation }) {
  return {
    type: UPDATE_DATA,
    path,
    operation
  }
}

function savingData(panelName) {
  return {
    type: SAVING_DATA,
    panelName: panelName
  }
}

function savedData(response) {
  return {
    type: SAVED_DATA,
    response: response
  }
}

const fetchErrorMessage = `Apologies - an error occurred fetching your data. \
Press OK to refresh this page. The rest of your application is unaffected.`

const updateErrorMessage = `Apologies - an error occurred saving your entry \
on this question. Press OK to refresh this page and enter it again. The \
rest of your application is unaffected.`

const logoutErrorMessage = `You have been logged out. Please log back in \
to continue your application`

function postSectionProgress(state, kaseId, sectionName) {
  if (sectionName === SECTION_NAME_PETITION || sectionName === SECTION_NAME_REMAINING_INFORMATION) {
    const sectionProgress = getCurrentSectionProgress(state)

    if (sectionName === SECTION_NAME_PETITION) {
      return api.kases.updateProgress({
        kaseId: kaseId,
        progress: sectionProgress
      })
    } else if (sectionName === SECTION_NAME_REMAINING_INFORMATION) {
      return api.kases.updateRemainingInfoProgress({
        kaseId: kaseId,
        progress: sectionProgress
      })
    }
  }

  return Promise.resolve()
}

function savePendingUpdates() {
  return (dispatch, getState) => {
    let state = getState()

    const kaseId = getCurrentKaseId(state)
    const panelName = isCurrentlyInSection(state) && getCurrentPanelName(state)
    const updates = getPendingUpdates(state)
    const sectionName = getCurrentSectionName(state)

    dispatch(savingData(panelName))

    const request = {
      panelName,
      kaseId,
      updates
    }

    return api.kases
      .updateData(request)
      .then((responseData) => {
        dispatch(savedData(responseData))
        setTimeout(() => {
          dispatch(postSaveTransitionEnd())
        }, 500)

        return postSectionProgress(state, kaseId, sectionName)
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error)
        reportError(error)

        let httpStatus = error.response.status
        if (httpStatus === 422 || httpStatus === 404) {
          dispatch(
            openModal({
              name: 'SessionModal',
              via: 'Error',
              modalProps: {
                isLogIn: true,
                customTitle: 'Log in again to access your application.'
              }
            })
          )

          return
        }

        setTimeout(() => {
          alert(updateErrorMessage)
          setTimeout(forceRefresh, 250)
        }, 500)
      })
  }
}

export function loadKase(kase) {
  return (dispatch) => {
    dispatch({
      type: LOAD_KASE,
      kase: kase
    })
  }
}

export function loadDataFromKase(kase) {
  return (dispatch) => {
    dispatch({
      type: LOAD_DATA,
      data: kase.data
    })
  }
}

export function acceptAttorneyAgreement() {
  return (dispatch) => {
    dispatch({
      type: ATTORNEY_AGREEMENT_ACCEPTED
    })
    return api.usersV2.acceptAttorneyAgreement()
  }
}

export function updateWorkflowStatus(newWorkflowStatus) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_WORKFLOW_STATUS,
      newWorkflowStatus
    })
  }
}

export function saveData({ path, operation }) {
  return (dispatch, getState) => {
    const panel = getCurrentPanel(getState())

    const options = {
      panel,
      operation,
      path
    }

    dispatch(updateFieldData(options))
    singleRequestEnsurer.enqueue(() => dispatch(savePendingUpdates()))
  }
}

export function forceSaveData({ path, operation }) {
  return (dispatch) => {
    dispatch(updateFieldData({ path, operation }))

    return dispatch(savePendingUpdates())
  }
}

export function forceMultiOperationSaveData(updates) {
  return (dispatch) => {
    for (let i = 0; i < updates.length; i++) {
      dispatch(updateFieldData(updates[i]))
    }

    return dispatch(savePendingUpdates())
  }
}

export function fetchKaseMetadata() {
  return (dispatch, getState) => {
    const state = getState()
    const kaseId = getCurrentKaseId(state)

    if (!kaseId) {
      return Promise.resolve(null)
    }

    return api.kases.fetchMetadata(kaseId).then((metadata) => {
      dispatch({
        type: LOAD_KASE_METADATA,
        metadata
      })
    })
  }
}

export function fetchKaseFormMetadata() {
  return (dispatch, getState) => {
    const state = getState()
    const kaseId = getCurrentKaseId(state)

    if (!kaseId) {
      return Promise.resolve(null)
    }

    return api.kases.fetchFormMetadata(kaseId).then((metadata) => {
      dispatch({
        type: LOAD_KASE_FORM_METADATA,
        metadata
      })
    })
  }
}

export function fetchKaseData() {
  return (dispatch, getState) => {
    const state = getState()
    const kaseId = getCurrentKaseId(state)

    api.kases
      .fetchKase(kaseId)
      .then((resp) => {
        const kase = resp.data

        dispatch(loadKase(kase))
        dispatch(loadDataFromKase(kase))
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error)

        reportError(error)
        const msg = error.response.status === 422 ? logoutErrorMessage : fetchErrorMessage

        setTimeout(() => {
          alert(msg)
          setTimeout(forceRefresh, 250)
        }, 500)
      })
  }
}

export function updateKase(attributes) {
  return (dispatch, getState) => {
    const state = getState()
    const kaseId = getCurrentKaseId(state)

    api.kases.adminUpdate({ kaseId, attributes }).then((response) => {
      dispatch(loadKase(response.kase))
    })
  }
}

// For v2 kase types only
export const getDashboardData = () => (dispatch, getState) => {
  let state = getState()
  const kaseId = getCurrentKaseId(state)
  dispatch({ type: LOADING_DASHBOARD, payload: true })
  return api.kasesV2
    .fetchPhaseDataForKase(kaseId)
    .then((data) => {
      dispatch({ type: LOADING_DASHBOARD_SUCCESS, payload: data })
    })
    .finally(() => {
      dispatch({ type: LOADING_DASHBOARD, payload: false })
    })
    .catch(() => {
      dispatch({
        type: SET_GLOBAL_ERROR,
        payload: 'We’re having trouble fetching your application information.'
      })
    })
}

export const fetchLawyerInteractions = () => (dispatch, getState) => {
  const state = getState()
  const kaseId = getCurrentKaseId(state)

  dispatch({ type: LOADING_LAWYER_INTERACTIONS })
  return api.kasesV2
    .fetchLawyerInteractions(kaseId)
    .then((data) => {
      dispatch({ type: LOADING_LAWYER_INTERACTIONS_SUCCESS, payload: data })
    })
    .catch(() => {
      dispatch({ type: LOADING_LAWYER_INTERACTIONS_ERROR, payload: data })
    })
}

/**
 * Load kase tags for v2 kases only
 */
export const loadCurrentTags = () => (dispatch, getState) => {
  const state = getState()
  const kaseId = getCurrentKaseId(state)
  dispatch({ type: LOADING_KASE_TAGS, payload: true })

  return api.kaseTags
    .index(kaseId)
    .then((data) => {
      const payload = data.map(({ attributes }) => ({ name: attributes.name })) || []
      dispatch({ type: LOADED_KASE_TAGS_SUCCESS, payload })
    })
    .finally(() => {
      dispatch({ type: LOADING_KASE_TAGS, payload: false })
    })
    .catch(() => {
      dispatch({
        type: SET_GLOBAL_ERROR,
        payload: 'We’re having trouble fetching your application information.'
      })
    })
}

export const getIsPaid = () => (dispatch, getState) => {
  const state = getState()
  const kaseId = getCurrentKaseId(state)
  return api.kasesV2.fetchIsPaid(kaseId).then((data) => {
    dispatch({ type: SET_IS_PAID, payload: data.data.attributes.paid })
  })
}
