import React, { FunctionComponent } from 'react'
import Heading from 'components/type/heading'

export const Outline: FunctionComponent = () => {
  return (
    <div className="o-pane__fill o-grid__row">
      <div className="o-grid__col-2 px-4">
        <div className="pt-16">
          <hr className="o-block c-divider c-divider--narrow c-divider--emphasized c-divider--secondary" />
          <Heading tag="h1" size="sm" color="emphasized">
            Questions and Tasks
          </Heading>
        </div>
      </div>
    </div>
  )
}

export default Outline
