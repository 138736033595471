import React, { FunctionComponent } from 'react'
import Milestone from 'components/screens/dashboard/customer_dashboard/phases/shipment_phase/milestone'

interface Props {
  milestones: {
    title: string
    description: React.ReactNode
  }[]
}

const MilestonesList: FunctionComponent<Props> = ({ milestones }) => {
  const milestoneListItems = milestones.map((milestone) => (
    <li key={milestone.title}>
      <Milestone {...milestone} />
    </li>
  ))

  return (
    <>
      <div className="c-shipment-phase--milestones">
        <h2 className="o-block c-type c-type--headline-sm c-type--emphasized">
          Timeline for Spouse Seeking a Green Card After Filing:
        </h2>
        <hr className="c-divider c-divider--important" />
        <ol className="o-block o-list c-shipment-phase--ol">{milestoneListItems}</ol>
      </div>
    </>
  )
}

export default MilestonesList
