import ModalRevealer from './modal_revealer'

import AttorneyAgreementModal from './attorney_agreement_modal'
import BeginLawyerReviewConfirmationModal from './begin_lawyer_review_confirmation_modal'
import ContextualHelpModal from './contextual_help_modal'
import ContinueOnDesktopModal from './continue_on_desktop_modal'
import ContinueOnDesktopModalNew from './continue_on_desktop_modal_new'
import CustomerMoveToQAModal from './customer_move_to_qa_modal'
import DocumentsModal from './documents_modal'
import MobileValuePropsModal from './mobile_value_props_modal'
import NaturalizationValuePropsModal from './naturalization_value_props_modal'
import SeeAllSectionsModal from './see_all_sections_modal'
import SeeAllSectionsModalNew from './see_all_sections_modal_new'
import SeeAllSectionsModalNewV2 from './see_all_sections_modal_new_v2'
import SessionModal from './session_modal'
import SplititModalV2 from './splitit_v2_modal'

const modals = {
  AttorneyAgreementModal,
  BeginLawyerReviewConfirmationModal,
  ContextualHelpModal,
  ContinueOnDesktopModal,
  ContinueOnDesktopModalNew,
  CustomerMoveToQAModal,
  DocumentsModal,
  MobileValuePropsModal,
  NaturalizationValuePropsModal,
  SeeAllSectionsModal,
  SeeAllSectionsModalNew,
  SeeAllSectionsModalNewV2,
  SessionModal,
  SplititModalV2
}

export { ModalRevealer }
export default modals
