import {
  applicant26thBirthday,
  buildApplicant,
  buildSpouse,
  timeInCountry,
  maximumIncome,
  ResourceContextTimeInCountry
} from './helpers'

import { applicationFee } from '../shared/application_fee'

import { ApplicationState } from 'reducers'
import { ResourceContextPerson } from '../shared/build_person'

interface NaturalizationResourceContext {
  applicant: ResourceContextPerson
  applicant_26th_birthday: string
  applicationFee: string
  maximumIncome: string
  spouse: ResourceContextPerson
  time_in_country: ResourceContextTimeInCountry
}

export default function buildNaturalizationResourceContext(state: ApplicationState): NaturalizationResourceContext {
  return {
    applicant: buildApplicant(state),
    applicant_26th_birthday: applicant26thBirthday(state),
    applicationFee: applicationFee(state),
    maximumIncome: maximumIncome(state),
    spouse: buildSpouse(state),
    time_in_country: timeInCountry(state)
  }
}
