import i18next from 'i18next'
import moment from 'moment'
import _isNumber from 'lodash/isNumber'

import { getOrdinal } from 'lib/language_helpers'
import getStore from 'stores/app_store'

import { getI18nContext, getResourceContext } from 'reducers/selectors'
import { FieldIndexType } from 'components/forms/field/index'
interface PersonResourceContext {
  name: string
  gender_pronoun: string
  gender_pronoun_cap: string
  gender_possessive_pronoun: string
  gender_possessive_pronoun_cap: string
}

interface ResourceContext {
  beneficiary: PersonResourceContext
  context: 'sponsor' | 'beneficiary'
  income: {
    total: string
    federal_minimum: string
  }
  last_three_tax_years: string
  month_and_year_five_years_ago: string
  sponsor: PersonResourceContext
}

type TranslateDataIndex = Nullable<number>

interface TranslateOptions {
  index?: TranslateDataIndex
  parentIndex?: FieldIndexType
}

interface PanelResourceContext extends ResourceContext, TranslateOptions {
  ordinal: Nullable<string>
  parentOrdinal: Nullable<string>
  previousOrdinal: Nullable<string>
  nextOrdinal: Nullable<string>
  nextIndex: Nullable<number>
  dates: {
    education: {
      earliest: moment.Moment
    }
    employment: {
      earliest: moment.Moment
    }
  }
}

const employmentHistoryNeededInDays = 1735
const educationHistoryNeededInDays = 1735

function getPreviousOrdinal(index: TranslateDataIndex): Nullable<string> {
  if (_isNumber(index) && index > 0) {
    return getOrdinal(index - 1)
  }

  return null
}

function getNextOrdinal(index: TranslateDataIndex): Nullable<string> {
  if (_isNumber(index)) {
    return getOrdinal(index + 1)
  }

  return null
}

class PanelLocaleService {
  public t(keys: string | string[], options: TranslateOptions = {}): string {
    return i18next.t(keys, this.buildResourceContext(options))
  }

  public exists(keys: string | string[]): boolean {
    return i18next.exists(keys, { context: this.getI18nContext() })
  }

  private resourceContext(options: TranslateOptions): ResourceContext | {} {
    return getResourceContext(this.getState(), options) || {}
  }

  private getState() {
    return getStore().getState()
  }

  private getParentOrdinal(parentIndex) {
    if (_isNumber(parentIndex)) {
      return getOrdinal(parentIndex)
    }

    return null
  }

  private buildResourceContext(options: TranslateOptions): PanelResourceContext {
    const { index, parentIndex } = options

    return {
      ...this.resourceContext(options),
      ...options,
      ordinal: getOrdinal(index),
      parentOrdinal: this.getParentOrdinal(parentIndex),
      previousOrdinal: getPreviousOrdinal(index),
      nextOrdinal: getNextOrdinal(index),
      nextIndex: _isNumber(index) ? index + 1 : null,
      dates: {
        education: {
          earliest: moment().subtract(educationHistoryNeededInDays, 'd')
        },
        employment: {
          earliest: moment().subtract(employmentHistoryNeededInDays, 'd')
        }
      }
    }
  }

  private getI18nContext(): unknown {
    return getI18nContext(this.getState())
  }
}

export default new PanelLocaleService()
