import React, { FunctionComponent } from 'react'
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker'
import CalendarIcon from 'components/icons/calendar_icon'

type Props = ReactDatePickerProps & {
  onChangeEvent: () => void
}

// find all the props that react-datepicker can receive:
// https://github.com/Hacker0x01/react-datepicker/blob/master/docs/datepicker.md

const DatePicker: FunctionComponent<Props> = (props) => {
  const { onChangeEvent } = props

  return (
    <span className="o-block">
      <ReactDatePicker
        {...props}
        className="c-paper-form__control o-flag o-grid--fluid o-grid--stack@sm"
        onChange={onChangeEvent}
        placeholderText="Select date"
      />
      <CalendarIcon className="react-datepicker--icon" />
    </span>
  )
}

export default DatePicker as FunctionComponent<ReactDatePickerProps>
