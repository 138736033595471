import u from 'updeep'

import {
  ADDED_DOCUMENTS_TO_CURRENT_DOCUMENT_REQUEST,
  LOADED_DOCUMENT_SETS,
  UPDATED_CURRENT_DOCUMENT_REQUEST,
  LOADED_NEXT_DOCUMENT_REQUEST,
  LOADED_PREVIOUS_DOCUMENT_REQUEST
} from 'lib/constants'

export interface DocumentReviewKaseModel {
  sla: Date
  completedReviewCount: number
}

export interface DocumentsStoreV2 {
  documentSets: DocumentSet[]
  loadingRequests?: boolean
  uploadModal: {
    expandedRequestIds: any
  }
  documentReviewKase: DocumentReviewKaseModel
  currentDocRequest: DocumentRequestModelV2
  currentDocRequestIndex: number
  currentDocSetIndex: number
  documentSetsComplete: boolean
}

export interface DocumentRequestModelV2 {
  description: string
  id: number
  kase_id: number
  name: string
  order: number
  parts: DocumentRequestPartModel[]
  title: string
}

export interface DocumentSet {
  name: string
  complete: boolean
  document_requests: DocumentRequestModelV2[]
  id: number
}

export interface DocumentRequestPartModel {
  id: number
  can_upload: boolean
  contents: PartContent[]
  header: string
  limit: number
  optional: boolean
}

export interface DocumentMetaQuestion {
  id: number
  type: 'document_meta_question'
  attributes: DocumentMetaQuestionAttributes
}

interface DocumentMetaQuestionAttributes {
  document_meta_answer_id: number
  input_type: 'string' | 'date'
  input_value: string
  key: string
  markdown: string
  order: number
  placeholder?: string
}

export interface Document {
  created_at: string
  deleted: boolean
  deleted_at: string
  document_request_id: number
  document_request_part_id: number
  file_name: string
  file_size: string
  file_type: 'image/jpeg' | 'image/gif' | 'image/png' | 'application/pdf' | null
  kase_id: number
  id: number
  replaced_at: string
  replaced_by: string
  s3_path: string
  state: string
  updated_at: string
  uploaded_by_admin: boolean
  uploaded_by_user: boolean
  uploaded_user_name: string
  user_id: number
}

export interface PartContent {
  document: Document
  meta_questions: DocumentMetaQuestion[]
}

const initialState: DocumentsStoreV2 = {
  documentSets: [],
  loadingRequests: false,
  uploadModal: {
    expandedRequestIds: {}
  },
  documentReviewKase: null,
  currentDocRequest: null,
  currentDocRequestIndex: 0,
  currentDocSetIndex: 0,
  documentSetsComplete: false
}

export default function documentsReducer(state: DocumentsStoreV2, action: any): DocumentsStoreV2 {
  state = state || initialState

  switch (action.type) {
    case ADDED_DOCUMENTS_TO_CURRENT_DOCUMENT_REQUEST: {
      const currentDocRequest = action.documentRequest

      return u(
        {
          currentDocRequest
        },
        state
      )
    }

    case LOADED_DOCUMENT_SETS: {
      return u(
        {
          documentSets: action.documentSets,
          documentSetsComplete: action.complete,
          currentDocRequest:
            action.documentSets[state.currentDocSetIndex].document_requests[state.currentDocRequestIndex],
          loadingRequests: false
        },
        state
      )
    }

    case UPDATED_CURRENT_DOCUMENT_REQUEST:
      {
        // Updated using index
        if (action.docSetIndex !== undefined && action.docRequestIndex !== undefined) {
          return u(
            {
              currentDocSetIndex: action.docSetIndex,
              currentDocRequestIndex: action.docRequestIndex,
              currentDocRequest: state.documentSets[action.docSetIndex].document_requests[action.docRequestIndex]
            },
            state
          )
        }
        // Updated using doc request id
        else if (action.docRequestId !== undefined) {
          const docRequestId = action.docRequestId
          let currentDocSetIndex = state.currentDocSetIndex
          let currentDocRequestIndex = state.currentDocRequestIndex
          let currentDocRequest = state.currentDocRequest

          state.documentSets.forEach((docSet, docSetIndex) => {
            docSet.document_requests.forEach((docRequest, docRequestIndex) => {
              if (docRequest.id === docRequestId) {
                currentDocRequest = docRequest
                currentDocSetIndex = docSetIndex
                currentDocRequestIndex = docRequestIndex
              }
            })
          })

          return u(
            {
              currentDocSetIndex,
              currentDocRequestIndex,
              currentDocRequest
            },
            state
          )
        }
      }
      break

    case LOADED_NEXT_DOCUMENT_REQUEST: {
      let currentDocRequestIndex = state.currentDocRequestIndex
      let currentDocSetIndex = state.currentDocSetIndex

      // Go to next doc set if current doc request index is exceeding doc requests length
      if (
        currentDocRequestIndex + 1 >= state.documentSets[currentDocSetIndex].document_requests.length &&
        currentDocSetIndex + 1 < state.documentSets.length
      ) {
        currentDocRequestIndex = 0
        currentDocSetIndex++
      } else {
        currentDocRequestIndex++
      }

      return u(
        {
          currentDocRequestIndex,
          currentDocSetIndex,
          currentDocRequest: state.documentSets[currentDocSetIndex].document_requests[currentDocRequestIndex]
        },
        state
      )
    }

    case LOADED_PREVIOUS_DOCUMENT_REQUEST: {
      let currentDocRequestIndex = state.currentDocRequestIndex
      let currentDocSetIndex = state.currentDocSetIndex

      // Go to previous doc set if current doc request index goes below 0
      if (currentDocRequestIndex - 1 < 0 && currentDocSetIndex - 1 >= 0) {
        currentDocSetIndex--
        currentDocRequestIndex = state.documentSets[currentDocSetIndex].document_requests.length - 1
      } else {
        currentDocRequestIndex--
      }

      return u(
        {
          currentDocSetIndex,
          currentDocRequestIndex,
          currentDocRequest: state.documentSets[currentDocSetIndex].document_requests[currentDocRequestIndex]
        },
        state
      )
    }

    default: {
      return state
    }
  }
}
