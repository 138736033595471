import React, { ChangeEventHandler, FocusEventHandler, FunctionComponent } from 'react'
import CommonLabelV2 from 'components/forms/inputs/v2/address/common/common_label_v2'
import CommonSelectV2 from 'components/forms/inputs/v2/address/common/common_select_v2'
import { InputOption } from '../lib/types'
import cx from 'classnames'

interface Props {
  classNames?: string
  disabled?: boolean
  name: string
  options: InputOption[]
  placeholder?: string
  onBlurEvent: FocusEventHandler
  onChangeEvent: ChangeEventHandler
  value?: string
}

const defaultProps = {
  value: ''
}

const SelectDropdown: FunctionComponent<Props> = ({
  classNames,
  disabled,
  name,
  options,
  onBlurEvent,
  onChangeEvent,
  placeholder,
  value
}) => {
  const formattedOptions = { '': placeholder || 'Select an option' }

  options.forEach((option) => {
    formattedOptions[option.key] = option.value
  })

  return (
    <div className={cx('o-grid--fluid o-grid--stack@sm o-block c-paper-form__group', classNames)}>
      <CommonLabelV2 label={name} labelFor={name} />
      <CommonSelectV2
        inputName={name}
        value={value ? value : ''}
        options={{
          '': placeholder || 'Select an option...',
          ...formattedOptions
        }}
        containerClass="c-paper-form__control"
        onChangeEvent={onChangeEvent}
        onBlur={onBlurEvent}
        disabled={disabled}
      />
    </div>
  )
}

SelectDropdown.defaultProps = defaultProps

export default SelectDropdown
