/* eslint-disable */
import _includes from 'lodash/includes'
import _uniq from 'lodash/uniq'
import Cookies from 'js-cookie'
import { cookieDomain } from 'javascript/lib/cookies'
import { deployStage, isProductionEnv } from 'lib/settings'

const statesToNotShowI485Alert = [
  'pre_payment', // 1
  'post_payment', // 2
  'print_shop', // 10
  'ineligible', // 11
  'pending_refund', // 12
  'refunded', // 13
  'printer_packaging', // 22
  'customer_package_shipped', // 23
  'customer_completed' // 24
]

const paidCustomerCurrentlyApplying_statesToNotShow = [
  'pre_payment', // 1
  'print_shop', // 10
  'ineligible', // 11
  'pending_refund', // 12
  'refunded', // 13
  'printer_packaging', // 22
  'customer_package_shipped', // 23
  'customer_completed' // 24
]

const isProd = deployStage() === 'production'
// Point WP site links to WP-staging unless we are in prod, then point to WP-production
const marketingSiteDomain = isProd ? 'https://www.boundless.com' : 'https://wwwstage.boundless.com'

const ALERTS = [
  {
    id: 6,
    message:
      'Notice: As of today we will no longer provide reviews through our legacy platform. To protect your ' +
      "privacy, we won't migrate your data; please download any documents in your old profile before creating " +
      'your new account. Reach us at (855) 268-6353 or support.team@boundless.com with any questions. For any ' +
      'customers who have received their I-130 approval notice and are looking for assistance with the NVC stage, ' +
      'do not create a new account. Please reach out to the phone number / email above.',
    kase_kinds: ['MarriageBasedGreenCard'],
    dismissible: true,
    cta: 'Click Here to Create New Account',
    ctaUrl: (kase) => `//apply.boundless.com/start/improved_marriage?kase_id=${kase?.id}`
  },
  // Disabled for now, but will probably use this again in the future, with likely a new id and updated messaging
  // {
  //   id: 9,
  //   message:
  //     'ALERT: Government application fees could increase dramatically as early as this summer -- Get your application in today!',
  //   kase_kinds: ['NewMarriageBasedGreenCard', 'K1FianceVisa'],
  //   dismissible: true,
  //   cta: 'Learn More',
  //   ctaUrl: `${marketingSiteDomain}/blog/green-card-fees-skyrocket-new-proposal/`,
  //   kase_filter: (kase) => ['pre_payment', 'post_payment'].includes(kase?.state)
  // }
  {
    id: 1725422400000,
    message:
      'UPCOMING SYSTEM MAINTENANCE — Boundless will be down briefly for routine system maintenance on Thursday, September 12th from 1AM to 3AM Pacific Time. Thanks for your understanding!',
    dismissible: true,
    start_date: '1725422400000', // Wednesday, September 4, 2024, 00:00:00 AM EST (UTC-5)
    end_date: '1726138800000' // Thursday, September 12, 2024, 6:00 AM EST (UTC-5)
  }
]

class Ribbon {
  transitionDuration = 300
  cookieName = '_boundless_hidden_notifications'

  constructor($containerElement) {
    this.$containerElement = $containerElement
    this.$body = $('body')
    this.$ribbon = $('<div class="c-ribbon c-ribbon--notification">')
    this.hiddenNotifications = this.getHiddenNotifications()
  }

  show = () => {
    const { $body, $ribbon, $containerElement } = this
    const activeNotification = this.getActiveNotification()

    if (!activeNotification) return

    // build the notification element
    const templates = this.getTemplates(activeNotification)

    if (activeNotification.dismissible) {
      $ribbon.append(templates.closeButton())
    }

    $ribbon.append(templates.ribbonNotification())

    // attach the notification element to the DOM
    $containerElement.replaceWith($ribbon)

    // setup event handler for the Close button
    $body.on('click.notifications', '.c-ribbon__close', this.onCloseButtonClicked)
  }

  onCloseButtonClicked = (event) => {
    const $target = $(event.currentTarget)

    event.preventDefault()

    this.removeNotification($target.data('notification-id'))
  }

  getTemplates = (notification) => {
    notification = notification || {}

    return {
      ribbonNotification: function () {
        const { message, cta, ctaUrl } = notification || {}
        return $('<p class="c-type c-type--body-sans-md c-ribbon__message">')
          .html(`${message}&nbsp;&nbsp;&nbsp;<br class="u-hide@md-up" />`)
          .append(
            $('<a class="c-type--inline c-link c-type--bold c-ribbon__cta">')
              .html(cta)
              .attr('href', ctaUrl)
              .attr('target', '_blank')
          )
      },
      closeButton: function () {
        return $('<a class="c-link c-type--bold c-ribbon__close">')
          .html('<span class="c-type--close-button">&times;</span>')
          .attr('href', '#')
          .data('notification-id', notification.id)
      }
    }
  }

  getHiddenNotifications = () => {
    return (Cookies.get(this.cookieName) || '0').split(',').map((i) => parseInt(i))
  }

  getActiveNotification = () => {
    const kase = window.applicationDataStore.store.getState()?.application?.kase
    let notifications = this.Notifications(kase)

    if (!notifications || !notifications.length) return false

    const notDismissedNotifications = notifications.filter(
      (notification) => !this.hiddenNotifications.includes(notification.id)
    )

    return this.applyFilters(notDismissedNotifications)[0] || null
  }

  removeNotification = (notificationId) => {
    const { $ribbon, hiddenNotifications, cookieName } = this
    $ribbon.slideUp()

    hiddenNotifications.push(notificationId)

    Cookies.set(cookieName, _uniq(hiddenNotifications).join(',').trim(), this.getCookieSettings())

    // remove element from the dom
    window.setTimeout(() => this.$ribbon.remove, this.transitionDuration)
  }

  getCookieSettings = () => {
    return {
      domain: cookieDomain(),
      expires: 365 * 20,
      secure: isProductionEnv()
    }
  }

  // Filters notifications based on the kase kind, kase filter function, and start/end dates
  applyFilters = (notifications) => {
    const kase = window.applicationDataStore.store.getState()?.application?.kase

    return notifications
      .filter((notif) => notif.kase_kinds?.includes(kase.kind) || !notif.kase_kinds)
      .filter((notif) => (typeof notif.kase_filter === 'function' ? notif.kase_filter(kase) : true))
      .filter((notif) => {
        const now = new Date().getTime()
        const startDate = notif.start_date ? notif.start_date : null
        const endDate = notif.end_date ? notif.end_date : null

        return (startDate ? now >= startDate : true) && (endDate ? now <= endDate : true)
      })
  }

  Notifications = (kase) => {
    return ALERTS.map((notif) => {
      return {
        id: notif.id,
        kase_kinds: notif.kase_kinds,
        message: notif.message,
        cta: notif.cta,
        ctaUrl: typeof notif.ctaUrl === 'function' ? notif.ctaUrl(kase) : notif.ctaUrl,
        dismissible: notif.dismissible,
        kase_filter: notif.kase_filter,
        start_date: notif.start_date,
        end_date: notif.end_date
      }
    })
  }
}

export default function initRibbon() {
  $('.js-ribbon').each(function () {
    try {
      const ribbon = new Ribbon($(this))
      ribbon.show()
    } catch (e) {
      console.log(`Failed to initRibbon with error: ${e}`)
    }
  })
}
/* eslint-disable */
