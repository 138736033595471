import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'

import Button from '../../../button'
import HelpIcon from '../../../icons/help_icon'
import FamilyIcon from '../../../icons/family_icon'
import GovernmentIcon from '../../../icons/government_icon'
import { readableKaseTag } from '../../../../reducers/selectors'

interface ExplicitProps {
  /**
   * Service fee charged by Boundless
   */
  boundlessServiceFee: number
  /**
   * Function to handle opening the contextual help fee breakdown modal from a link
   * styled like a contextual help link
   */
  handleOpenFeeBreakdownModal: (args: any) => void
  /**
   * If we are viewing this in a mobile device or not
   */
  isMobile?: boolean
  /**
   * The maximum number of months to pay
   */
  maxMonths: number
  /**
   * The minimum number of months to pay
   */
  minMonths: number
  /**
   * Function for when select-determine-fees-button is clicked
   */
  onSelectDetermineFeesBtnClicked: () => void
  /**
   * Function for when select-see-total-button is clicked
   */
  onSelectSeeTotalBtnClicked: () => void
  /**
   * The total cost of USCIS fees
   */
  totalUscisFees: number
  /**
   * The range for the total cost of USCIS fees
   * Used if usesExtraQuestions boolean is true
   */
  totalUscisFeeRange?: string
  /**
   * Total USCIS fees, not including the boundless service fee
   */
  uscisFeesWithoutBoundlessServiceFee: number
  /**
   * This boolean determines if the kase needs to use extra questions to determine the exact
   * fee amount. If it's true we render fee ranges since we don't know the exact amount
   * at this step yet
   */
  usesExtraQuestions: boolean
}

interface MappedProps {
  readableKaseTag: string
}

type Props = ExplicitProps & MappedProps

const PaymentSelectGovernmentFeeOption: FunctionComponent<Props> = ({
  handleOpenFeeBreakdownModal,
  onSelectDetermineFeesBtnClicked,
  onSelectSeeTotalBtnClicked,
  totalUscisFeeRange,
  uscisFeesWithoutBoundlessServiceFee,
  usesExtraQuestions,
  maxMonths
}) => {
  const getGovFeeText = () => {
    return (
      <>
        {usesExtraQuestions ? (
          <>{totalUscisFeeRange}</>
        ) : (
          <>
            <a href="" onClick={handleOpenFeeBreakdownModal} title="Fee breakdown">
              ${uscisFeesWithoutBoundlessServiceFee.toLocaleString()}
              <HelpIcon />
            </a>
          </>
        )}
      </>
    )
  }

  return (
    <>
      <div className="border border-default c-sheet__body c-type--emphasized p-0 position-relative text-left">
        <div className="box-header c-type c-type--subhead-sm">RECOMMENDED</div>
        <div className="bg-gray-100 flex justify-between pb-4 pt-4">
          <div className="c-type flex-basis-0 flex-1 px-4">
            <FamilyIcon className="mb-3 ml-2 mt-4" size="medium" />
            <h4 className="c-type--body-sans-md font-bold mb-4">Pay USCIS fees later</h4>
          </div>
          <div className="border-l border-default c-type flex-basis-0 flex-1 p-4">
            <div className="mb-3">
              <FamilyIcon className="ml-1 mr-2" size="medium" />+<GovernmentIcon className="ml-2" size="medium" />
            </div>
            <h4 className="c-type--body-sans-md font-bold mb-4">Pay USCIS fees now</h4>
          </div>
        </div>
        <div className="c-type--subhead-sm text-center pt-4 text-gray-400">How It Works</div>
        <div className="flex justify-between pt-4">
          <div className="c-type flex-basis-0 flex-1 px-4">
            <p className="c-type--body-sans-sm my-4">
              Pay by credit card, or mail us a check to send with your application.
            </p>
            <p>You'll need to pay USCIS when we ship your application.</p>
            <p className="c-type--body-sans-sm py-4 ">USCIS fees: {getGovFeeText()}</p>
          </div>
          <div className="border-l border-default c-type flex-basis-0 flex-1 p-4">
            <p className="c-type--body-sans-sm mb-4">
              Pay via credit or debit, with an option to pay over {maxMonths} months. We'll write the check to USCIS for
              you.
            </p>
            <p className="c-type--body-sans-sm py-4">USCIS fees: {getGovFeeText()}</p>
          </div>
        </div>

        <div className="flex justify-between pb-4">
          <div className="c-type flex-basis-0 flex-1 p-4">
            <Button
              block
              color="primary"
              id="select-just-bondless-button"
              label="Select"
              onClick={onSelectSeeTotalBtnClicked}
            />
          </div>
          <div className="border-l border-default c-type flex-basis-0 flex-1 p-4">
            <Button
              block
              className="mb-4"
              color="primary"
              id="select-determine-fees-button"
              label="Select"
              onClick={onSelectDetermineFeesBtnClicked}
            />
            {usesExtraQuestions && (
              <p className="c-type--body-sans-sm text-gray-500">Next we’ll determine your exact USCIS fees</p>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

function mapStateToProps(state): MappedProps {
  return {
    readableKaseTag: readableKaseTag(state)
  }
}

export default connect(mapStateToProps)(PaymentSelectGovernmentFeeOption) as FunctionComponent<ExplicitProps>
