import api from 'lib/api'
import { LOADED_TASKS, LOADING_TASKS, UPDATED_TASK, SET_GLOBAL_ERROR } from 'lib/constants'
import { getCurrentKaseId } from 'reducers/selectors'
import server from 'lib/api/v1/server'
import { getDashboardData } from './kase_actions'

interface FetchTasksParams {
  kaseId: number
}

interface AssignTasksParams {
  kaseId: number
  taskId: string
  assigneeId?: string
}

export function loadedTasks(data) {
  return (dispatch) => {
    dispatch({
      type: LOADED_TASKS,
      tasks: data?.tasks ?? {}
    })
  }
}

export function updateTask(data) {
  return (dispatch) => {
    dispatch({
      type: UPDATED_TASK,
      tasks: data?.tasks ?? {}
    })
  }
}

export const loadTasks = ({ kaseId }: FetchTasksParams) => (dispatch) => {
  dispatch({ type: LOADING_TASKS })

  return api.tasks.index({ kaseId }).then((data) => {
    dispatch(loadedTasks(data))
  })
}

export const sendTaskEvent = (event: string, finallyFunc?: () => any) => (dispatch, getState) => {
  let state = getState()
  const kaseId = getCurrentKaseId(state)

  return server
    .post(`/api/v1/kases/${kaseId}/events?event=${event}`)
    .then(() => {
      dispatch(getDashboardData())
    })
    .catch(() => {
      dispatch({
        type: SET_GLOBAL_ERROR,
        payload: 'Sorry, something went wrong.'
      })
    })
    .finally(() => {
      if (finallyFunc) {
        finallyFunc()
      }
    })
}

export const assignTask = ({ kaseId, taskId, assigneeId }: AssignTasksParams) => (dispatch) => {
  const assigneeParams = { assignee_id: assigneeId }

  return api.tasks.assign({ kaseId, taskId, assigneeParams }).then((data) => {
    dispatch(updateTask(data))
  })
}
