import React, { MouseEvent } from 'react'

import { QuestionnaireInputContainerProps } from './'

import BufferedFieldValue from 'components/forms/buffered_field_value'

const isImperial = (value: string) => value === 'imperial'

type Unit = 'metric' | 'imperial'

export default class MetricToggle extends React.Component<QuestionnaireInputContainerProps> {
  handleClick = (currentValue: Unit, onChange: Function) => (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault()
    const value = isImperial(currentValue) ? 'metric' : 'imperial'

    onChange(value)
  }

  render() {
    return (
      <div className="o-block c-paper-form__group">
        <BufferedFieldValue path={this.props.path}>
          {(value, onChange) => (
            <a
              href="#"
              key="metric_toggle"
              onClick={this.handleClick(value, onChange)}
              className="c-type c-type--body-sans-sm"
            >
              {isImperial(value) ? 'Show cm / kg' : 'Show ft / lbs'}
            </a>
          )}
        </BufferedFieldValue>
      </div>
    )
  }
}
