import React, { FunctionComponent } from 'react'
import { bindActionCreators, Dispatch } from 'redux'
import Paragraph from 'components/type/paragraph'
import Button from 'components/button'
import IssueCardHeader from 'components/screens/issues_v2/issue_card_header'
import { getCurrentKaseId, getCurrentUserId, getCurrentKaseState } from 'reducers/selectors'
import { KaseStates } from 'lib/kase_state'
import { getMessagesForIssue } from 'reducers/issues/selectors'
import { connect } from 'react-redux'
import { Issue, Message } from 'reducers/issues'
import LinkifyText from 'components/linkify_text'
import { resolveCurrentIssue } from 'actions/issue_actions'
import ArrowIcon from 'components/icons/arrow_icon'

interface MappedProps {
  currentUserId?: number
  kaseId: number
  messagesForIssue?: Message[]
  onClickResolveIssue: typeof resolveCurrentIssue
}
interface OwnProps {
  issue: Issue
  onOpenThread: Function
}

type Props = MappedProps & OwnProps

export const IssueCard: FunctionComponent<Props> = ({
  issue,
  kaseId,
  onClickResolveIssue,
  onOpenThread,
  currentUserId,
  messagesForIssue,
  kaseState
}) => {
  const isResolved = issue.attributes.status === 'resolved'
  const links = issue.attributes.links
  const numberOfMessages = messagesForIssue.length

  // The last message on this issue has been sent by the customer
  const customerResponded =
    numberOfMessages > 0 && messagesForIssue[numberOfMessages - 1].attributes.creator_id === currentUserId.toString()

  const hasNewMessage = numberOfMessages > 0

  const preQAState = kaseState === KaseStates.customer_form_check || kaseState === KaseStates.customer_document_check

  const hideIssue = !preQAState && isResolved

  if (hideIssue) {
    return null
  }

  return (
    <div className="o-block">
      <IssueCardHeader
        isResolved={isResolved}
        isMostRecentMessageFromCustomer={customerResponded}
        hasNewMessage={hasNewMessage}
        numberOfMessages={numberOfMessages}
        kaseState={kaseState}
        customerResponded={customerResponded}
      />
      <div className="border border-gray-200 border-t-0 p-4">
        <Paragraph spacing="lg">
          <LinkifyText>{issue.attributes.description}</LinkifyText>
        </Paragraph>

        {links?.length > 0 && (
          <ul className="c-list o-block o-block--copious mb-1">
            {links.map((link, index) => {
              return (
                <li className="o-block o-block--tight" key={`issue-link${link.label}-${index}`}>
                  <Button
                    icon={<ArrowIcon arrowDirection="right" />}
                    color="primary"
                    label={`${link.label}`}
                    className="c-btn--truncated"
                    onClick={() => (window.location.href = `${link.uri}?hasIssue=true`)}
                  />
                </li>
              )
            })}
          </ul>
        )}
        <Button
          color="link-primary"
          label="Reply"
          size="small"
          onClick={() => {
            onOpenThread(issue)
          }}
        />
      </div>
    </div>
  )
}

function mapStateToProps(state: any, ownProps: OwnProps) {
  const { issue } = ownProps

  const messagesForIssue = getMessagesForIssue(state.kaseIssues, issue.id)
  const currentUserId = getCurrentUserId(state)
  const kaseState = getCurrentKaseState(state)

  return {
    issue,
    kaseState,
    kaseId: getCurrentKaseId(state),
    messagesForIssue,
    currentUserId
  }
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    onClickResolveIssue: bindActionCreators(resolveCurrentIssue, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(IssueCard)
