import _every from 'lodash/every'
import _intersection from 'lodash/intersection'
import _some from 'lodash/some'
import _isNil from 'lodash/isNil'

import Decorator from 'lib/decorator'
import { getCondition } from 'lib/conditions'
import { isPathNeeded, isAnsweredAtPath } from 'lib/model/needed'
import { PathValidationConfig } from 'lib/validations'
import { getValidatorsAtPath } from 'lib/validations/validators'
import { KaseModelMetadata } from 'reducers/model'

import { QUALIFIER_TYPE, COLLECTION_QUALIFIER_TYPE, SMART_COLLECTION_QUALIFIER_TYPE } from 'lib/constants'
import { FieldIndexType, FieldType } from 'components/forms/field/index'

export default class FieldMetadata {
  field: FieldType
  path: string
  data: Decorator
  hidden: boolean
  required: boolean
  hasData: boolean
  complete: boolean
  currentIndex: FieldIndexType
  parentIndex: FieldIndexType
  modelMetadata: KaseModelMetadata
  paths: string[]
  validationConfig: PathValidationConfig

  // TODO: Figure out a way to break up this constructor
  // It might be that this class has too much responsibility
  constructor(
    field: FieldType,
    data: Decorator,
    modelMetadata: KaseModelMetadata,
    validationConfig: PathValidationConfig,
    currentIndex: FieldIndexType,
    parentIndex: FieldIndexType
  ) {
    this.currentIndex = currentIndex
    this.parentIndex = parentIndex
    this.path = this.addIndicesToPath(field.path)
    this.field = {
      ...field,
      currentIndex,
      parentIndex,
      path: this.path
    }
    this.data = data
    this.modelMetadata = modelMetadata
    this.paths = this.getPaths()
    this.validationConfig = validationConfig

    this.setHidden()
    this.setRequired()
    this.setHasData()
    this.setComplete()
  }

  replaceNextIndexInPath(path, index) {
    return path.replace('$', index.toString())
  }

  addIndicesToPath(path: string): string {
    if (_isNil(this.currentIndex)) {
      return path
    }

    let pathToReplace = path

    if (!_isNil(this.parentIndex)) {
      pathToReplace = this.replaceNextIndexInPath(path, this.parentIndex)
    }
    return this.replaceNextIndexInPath(pathToReplace, this.currentIndex)
  }

  getMetadata(): FieldType {
    return {
      ...this.field,
      hidden: this.hidden,
      required: this.isRequired()
    }
  }

  getPaths(): string[] {
    switch (this.field.type) {
      case 'address': {
        let allFields = ['street', 'city', 'country']

        if (this.data.isAddressUS(this.path)) {
          allFields.push('postal_code', 'province')
        }

        if (this.field.visible_fields) {
          allFields = _intersection(allFields, this.field.visible_fields)
        }

        return allFields.map((suffix) => {
          if (suffix === 'country') {
            return `${this.path}.${suffix}.code`
          }

          return `${this.path}.${suffix}`
        })
      }
      case 'tel':
      case 'phone':
        return ['country_code', 'number'].map((suffix) => `${this.path}.${suffix}`)
      case 'dictionary_checkboxes':
        return this.field.choices.map((choice: string) => `${this.path}.${choice}.${this.field.path_in_item}`)
      default:
        return [this.path]
    }
  }

  isExcuser(): boolean {
    return this.field.type === 'excuser'
  }

  isQualifier(): boolean {
    return [QUALIFIER_TYPE, COLLECTION_QUALIFIER_TYPE].includes(this.field.type)
  }

  isSmartCollectionQualifier(): boolean {
    return this.field.type === SMART_COLLECTION_QUALIFIER_TYPE
  }

  isExcused(): boolean {
    const value = this.data.get(this.path)
    if (value == null) {
      return false
    }

    return value === true
  }

  isQualified(): boolean {
    const value = this.data.get(this.path)
    if (value == null) {
      return false
    }

    return value === 'no'
  }

  isHidden(): boolean {
    return this.hidden === true
  }

  isRequired(): boolean {
    return this.required
  }

  isDataField(): boolean {
    return !this.isExcuser() && !this.isQualifier()
  }

  isIgnoredForPresence(): boolean {
    return ['metric_toggle'].includes(this.field.type)
  }

  fieldHasData(): boolean {
    return this.hasData
  }

  isComplete(): boolean {
    return this.complete
  }

  private setHidden() {
    const needed = _some(this.paths, (path) => isPathNeeded(this.data, this.modelMetadata, path))

    if (!needed) {
      this.hidden = true
    } else {
      // hidden_if refers to panel functions defined in yml, not metadata
      const hiddenIf = getCondition(this.field.hidden_if) || (() => false)
      this.hidden = hiddenIf(this.data, this.field)
    }
  }

  private setRequired() {
    this.required = _some(this.paths, (path) => {
      const validatorsAtPath = getValidatorsAtPath(path, this.validationConfig)
      return _some(validatorsAtPath, (validator) => validator.name === 'required')
    })
  }

  private setHasData() {
    this.hasData = _some(this.paths, (path) => isAnsweredAtPath(this.data, this.modelMetadata, path))
  }

  private setComplete() {
    if (!this.isSmartCollectionQualifier()) {
      this.complete = _every(this.paths, (path) => isAnsweredAtPath(this.data, this.modelMetadata, path))
    } else {
      const items = this.data.get(`${this.path}.items`)
      const collectionSize = items ? items.length : 0

      if (this.currentIndex >= collectionSize) {
        const collectionComplete = this.data.get(`${this.path}.complete`)
        this.complete = collectionComplete === true
      } else {
        this.complete = true
      }
    }
  }
}
