import React, { Component } from 'react'
import { connect } from 'react-redux'
import Dropzone, { DropzoneProps } from 'react-dropzone'
import S3Upload from 'lib/admin/s3_upload'

import { defaultTypes as defaultAllowedFileTypes } from 'javascript/lib/accepted_files_types'
import { uploadError, uploadFinished } from 'javascript/actions/upload_actions'
import { listToSentence } from 'lib/language_helpers'
import { getCurrentKaseId } from 'reducers/selectors'

interface ExplicitProps {
  onDocumentUploaded?: () => void
  acceptedFileTypes?: any
}

interface ActionProps {
  uploadError: ({ rawFile: any, message: string }) => void
  uploadFinished: ({ rawFile: any, message: string }) => void
}

interface MappedProps {
  kaseId: number
}

type Props = MappedProps & ExplicitProps & ActionProps

class UploadToPathButton extends Component<Props> {
  static defaultProps = {
    acceptedFileTypes: defaultAllowedFileTypes
  }

  dropzoneRef = React.createRef<Component<DropzoneProps>>()

  constructor(props) {
    super(props)
  }

  onFileUploadClick = (event) => {
    event.preventDefault()
    this.dropzoneRef.current.open()
  }

  handleDrop = (files) => {
    new S3Upload(files, this.props.kaseId, {
      onError: this.onUploadError,
      onUploadComplete: this.props.onDocumentUploaded
    })
  }

  onUploadError = (status, rawFile) => {
    this.props.uploadError({
      message: status,
      rawFile
    })
  }

  onDocumentCreatedError = (rawFile) => () => {
    this.props.uploadError({
      rawFile,
      message: 'There was an error uploading your file. ' + 'Please contact help@boundless.com.'
    })
  }

  render() {
    return (
      <Dropzone
        key="document-upload-card"
        onDrop={this.handleDrop}
        className="o-block c-card c-card--uploader c-dropzone text-center"
        activeClassName="c-dropzone--active"
        acceptClassName="c-dropzone--accepted"
        rejectClassName="c-dropzone--rejected"
        disableClick={true}
        accept={this.props.acceptedFileTypes.types.join(',')}
        ref={this.dropzoneRef}
      >
        <div className="c-card__img-container">
          <img className="c-card__img--top c-img--fluid" alt="" />
        </div>

        <div className="c-card--block">
          <a
            href="#upload-document"
            onClick={this.onFileUploadClick}
            className="o-block o-block--compact c-type c-type--subhead-sm"
          >
            Upload Document
          </a>

          <p className="o-block o-block--ample c-type c-type--body-sans-sm">or drag documents here.</p>

          <p className="o-block c-type c-type--body-sans-sm c-type--muted">
            We support {listToSentence(this.props.acceptedFileTypes.names)} files.
          </p>
        </div>
      </Dropzone>
    )
  }
}

function mapDispatchToProps(state): MappedProps {
  return {
    kaseId: getCurrentKaseId(state)
  }
}

function mapDispatchToActions(dispatch): ActionProps {
  return {
    uploadError: (...args) => dispatch(uploadError(...args)),
    uploadFinished: (...args) => dispatch(uploadFinished(...args))
  }
}

export default connect(mapDispatchToProps, mapDispatchToActions)(UploadToPathButton)
