import { TasksStore } from 'reducers/tasks/index'
import { createSelector } from 'reselect'
import _filter from 'lodash/filter'
import { TaskTypes } from 'lib/constants'

export const isLoadingTasks = (kaseTasks: TasksStore) => {
  if (!kaseTasks) return true
  return kaseTasks.loadingTasks
}

export const getTasks = (kaseTasks: TasksStore) => {
  if (!kaseTasks) return {}
  return kaseTasks.tasks
}

export const getCurrentOpenTask = (state) => {
  if (!state.kaseTasks) return {}
  return state.kaseTasks.tasks
}

export const getTaskById = (state, taskId: string) => {
  if (!taskId) return null
  return state.kaseIssues?.tasks[taskId] ?? null
}

const taskMatchesFilterParams = (task_attributes, params) => {
  return Object.keys(params).every((key) => {
    if (key[0] === '!') {
      return task_attributes[key.slice(1)] !== params[key]
    } else {
      return task_attributes[key] === params[key]
    }
  })
}

const filterTasks = (tasks, filterParams) => {
  return _filter(tasks, (task) => taskMatchesFilterParams(task.attributes, filterParams))
}

export const currentOpenQaTask = (tasks) => {
  const filteredTasks = filterTasks(tasks, {
    '!state': 'resolved',
    kind: TaskTypes.QA
  }).concat(
    filterTasks(tasks, {
      '!state': 'resolved',
      kind: TaskTypes.CUSTOMER_QA
    })
  )

  return filteredTasks ? filteredTasks[0] : null
}

export const getCurrentOpenQaTask = createSelector(getTasks, (tasks) => currentOpenQaTask(tasks))
