import PhoneNumber from 'awesome-phonenumber'
import { COUNTRIES } from './countries'

let codes = null
let codeMap = null

function loadCodes() {
  return Object.keys(COUNTRIES).reduce((result, regionCode) => {
    result[regionCode] = PhoneNumber.getCountryCodeForRegionCode(regionCode)
    return result
  }, {})
}

export function phoneCountryCodes() {
  codes = codes || loadCodes()
  return codes
}

function loadCodeMap() {
  const countryCodes = phoneCountryCodes()

  return Object.keys(COUNTRIES).reduce((result, regionCode) => {
    const name = COUNTRIES[regionCode]
    const countryCode = countryCodes[regionCode]

    result[regionCode] = {
      name,
      countryCode
    }

    return result
  }, {})
}

export function phoneCountryCodeMap() {
  codeMap = codeMap || loadCodeMap()
  return codeMap
}

interface CodeMapEntries {
  name: string
  countryCode: number
}

export function getIsoFromCountryCode(countryCode) {
  const codeMap = phoneCountryCodeMap()
  for (const [iso, val] of Object.entries(codeMap)) {
    const country = val as CodeMapEntries
    const code = parseInt(countryCode)
    if (country.countryCode === code) {
      return iso
    }
  }
  return null
}
