import React from 'react'
import { connect } from 'react-redux'
import { Collapse } from 'react-collapse'
import ReactMarkdown from 'react-markdown'

import AskQuestionButton from './ask_question_button'
import contextualHelpForPanel from 'lib/contextual_help_for_panel'
import { MarkdownLinkRenderer } from 'lib/markdown'

import {
  getCurrentKaseKind,
  getCurrentPanel,
  isOnPublicChargeEstimatorSection,
  isSidebarHelpOpen
} from 'reducers/selectors'

interface Props {
  open: boolean
  help?: any
  currentKaseKind: string
  isOnPublicChargeEstimatorSection: boolean
  contextualHelpV2?: any
}

class SidebarHelp extends React.Component<Props> {
  render() {
    return (
      <Collapse isOpened={this.props.open}>
        <div className="o-box o-box--ample c-accordion__content c-sidebar-help">
          <div className="c-sidebar-help__line" />
          {this.props?.help?.text ? this.renderHelp() : this.renderDefaultHelp()}
        </div>
      </Collapse>
    )
  }

  renderHelp() {
    return (
      <div className="c-sidebar-help__content">
        <h3 className="o-block c-type c-type--headline-sm c-type--emphasized">{this.props.help.header}</h3>

        <div className="o-block o-block--compact c-type c-type--body-serif-sm c-type--react-markdown">
          <ReactMarkdown source={this.props.help.text} renderers={{ Link: MarkdownLinkRenderer }} />
        </div>
      </div>
    )
  }

  renderDefaultHelp() {
    if (this.props.currentKaseKind === 'Naturalization') {
      return this.renderNaturalizationDefaultHelp()
    } else if (this.props.currentKaseKind === 'K1FianceVisa') {
      return this.renderK1DefaultHelp()
    } else {
      if (this.props.isOnPublicChargeEstimatorSection) {
        return this.renderPublicChargeRiskEstimatorDefaultHelp()
      } else {
        return this.renderMarriageBasedGreenCardDefaultHelp()
      }
    }
  }

  renderNaturalizationDefaultHelp() {
    return (
      <div className="c-sidebar-help__content">
        <h2 className="o-block c-type c-type--headline-sm c-type--emphasized">Looking for help?</h2>

        <div className="o-block c-type c-type--body-serif-sm">
          <p>Here are some useful resources:</p>
          <ul className="o-list-bare">
            <li>
              <a href="/content/what-you-get-with-boundless" target="_blank" rel="noopener noreferrer">
                What You Get With Boundless
              </a>
            </li>
            <li>
              <a href="/content/explained" target="_blank" rel="noopener noreferrer">
                Overview of Naturalization Process
              </a>
            </li>
          </ul>
        </div>
        <p className="o-block c-type c-type--body-serif-sm">
          More questions? <AskQuestionButton via="SidebarHelp">Ask us directly</AskQuestionButton>!
        </p>
      </div>
    )
  }

  renderK1DefaultHelp() {
    return (
      <div>
        <h2 className="o-block c-type c-type--headline-sm c-type--emphasized">Looking for help?</h2>
        <div className="o-block c-type c-type--body-serif-sm">
          <p>Here are some useful resources:</p>
          <ul className="o-list-bare">
            <li>
              <a href="/content/k-1-fiancee-visa-explained" target="_blank" rel="noopener noreferrer">
                Overview of K1 Fiancé(e) Visas
              </a>
            </li>
          </ul>
        </div>
      </div>
    )
  }

  renderMarriageBasedGreenCardDefaultHelp() {
    return (
      <div className="c-sidebar-help__content">
        <h2 className="o-block c-type c-type--headline-sm c-type--emphasized">Looking for help?</h2>

        <div className="o-block c-type c-type--body-serif-sm">
          <p>Here are some useful resources:</p>
          <ul className="o-list-bare">
            <li>
              <a href="/content/what-you-get-with-boundless" target="_blank" rel="noopener noreferrer">
                What You Get With Boundless
              </a>
            </li>
            <li>
              <a href="/content/explained" target="_blank" rel="noopener noreferrer">
                Overview of Marriage-Based Green Cards
              </a>
            </li>
            <li>
              <a href="/content/how-long-mbgc" target="_blank" rel="noopener noreferrer">
                How Long Does It Take?
              </a>
            </li>
            <li>
              <a href="/content/cost-mbgc" target="_blank" rel="noopener noreferrer">
                How Much Does It Cost?
              </a>
            </li>
          </ul>
        </div>
        <p className="o-block c-type c-type--body-serif-sm">
          More questions? Check out our other{' '}
          <a href="/content/immigration-resources" target="_blank" rel="noopener noreferrer">
            immigration resources
          </a>{' '}
          or <AskQuestionButton via="SidebarHelp">ask us directly</AskQuestionButton>!
        </p>
      </div>
    )
  }

  renderPublicChargeRiskEstimatorDefaultHelp() {
    return (
      <div className="c-sidebar-help__content">
        <h2 className="o-block c-type c-type--headline-sm c-type--emphasized">Looking for help?</h2>

        <div className="o-block c-type c-type--body-serif-sm">
          <p>Here are some useful resources:</p>
          <ul className="o-list-bare">
            <li>
              <a href="/content/public-charge-explained" target="_blank" rel="noopener noreferrer">
                Public Charge Rule for Green Card Applicants Within U.S.
              </a>
            </li>
            <li>
              <a href="/content/public-charge-foreign-affairs" target="_blank" rel="noopener noreferrer">
                Public Charge Policy for Green Card Applicants Outside U.S.
              </a>
            </li>
            <li>
              <a href="/content/public-charge-unlawful-rule" target="_blank" rel="noopener noreferrer">
                Report: Public Charge Will Cost $13 Billion
              </a>
            </li>
            <li>
              <a href="/content/public-charge-separation" target="_blank" rel="noopener noreferrer">
                Report: Public Charge Will Separate Nearly 200,000 Couples
              </a>
            </li>
            <li>
              <a href="/content/public-charge-predictions" target="_blank" rel="noopener noreferrer">
                Top 10 Legal Immigration Predictions for 2019
              </a>
            </li>
            <li>
              <a href="/content/public-charge-rule" target="_blank" rel="noopener noreferrer">
                Everything You Need to Know About Public Charge
              </a>
            </li>
          </ul>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    open: isSidebarHelpOpen(state),
    help: contextualHelpForPanel(getCurrentPanel(state)) || ownProps.contextualHelpV2,
    currentKaseKind: getCurrentKaseKind(state),
    isOnPublicChargeEstimatorSection: isOnPublicChargeEstimatorSection(state)
  }
}

export default connect(mapStateToProps)(SidebarHelp)
