import { createStore, applyMiddleware, combineReducers } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { createLogger } from 'redux-logger'
import { responsiveStoreEnhancer } from 'redux-responsive'
import { composeWithDevTools } from 'redux-devtools-extension'

import telemetryMiddleware from 'middleware/telemetry_middleware'
import navigationBlockingMiddleware from 'middleware/navigation_blocking_middleware'
import refreshAssetsMiddleware from 'middleware/refresh_assets_middleware'
import chatMiddleware from 'middleware/chat_middleware'
import honeybadgerMiddleware from 'middleware/honeybadger_middleware'
import appReducers from '../reducers'
import { enableDebugLogging } from 'lib/feature_flags_deprecated'
import router from '../router'

let store = null

export default function getStore() {
  if (store) return store

  const composeEnhancers = composeWithDevTools({
    maxAge: 250 // amount of actions to store
  })

  const storeMiddleware = [
    router.middleware,
    navigationBlockingMiddleware,
    thunkMiddleware,
    telemetryMiddleware,
    refreshAssetsMiddleware,
    chatMiddleware,
    honeybadgerMiddleware
  ]

  if (enableDebugLogging()) {
    const loggerMiddleware = createLogger({ collapsed: true })
    storeMiddleware.push(loggerMiddleware)
  }

  const reducers = combineReducers(appReducers)

  const enhancer = composeEnhancers(router.enhancer, responsiveStoreEnhancer, applyMiddleware(...storeMiddleware))

  store = createStore(reducers, enhancer)

  return store
}
