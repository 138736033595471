import React from 'react'

type FillStyle = 'slim' | 'secondary'

export interface Props {
  progress: number
  className?: string
  fillStyles: FillStyle[]
  [containerPropKey: string]: any
}

function buildFillStyles(styles: FillStyle[]): string {
  if (styles) {
    return styles.map((style) => `c-progress-bar__filled--${style}`).join(' ')
  }

  return ''
}

export default function Horizontal(props: Props) {
  const { progress, className, fillStyles } = props
  const classes = `c-progress-bar ${className || ''}`

  const style = {
    width: `${progress}%`
  }

  return (
    <div className={classes}>
      <div className={`c-progress-bar__filled ${buildFillStyles(fillStyles)}`} style={style} />
    </div>
  )
}
