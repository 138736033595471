import u from 'updeep'

import _cloneDeep from 'lodash/cloneDeep'
import _every from 'lodash/every'
import _findIndex from 'lodash/findIndex'
import _times from 'lodash/times'

import { ModelDataValue } from 'reducers/model'
import { COLLECTION_SIZE_LIMIT } from 'lib/constants'

// TODO a Typescript pass is necessary

export interface ModelOperationJson {
  name: string
  value: ModelDataValue
}

export interface ModelOperation {
  (): any
  asJSON: () => ModelOperationJson
  __operationName: string
  __operationValue: ModelDataValue
}

function coerceValue(value: any): any {
  switch (value) {
    case 'null':
      return null
    case 'true':
      return true
    case 'false':
      return false
    case '':
      return null
    default:
      return value
  }
}

function coerceValues(values: any): any {
  if (typeof values === 'object') {
    const newValues = _cloneDeep(values)

    for (let key in values) {
      newValues[key] = coerceValues(newValues[key])
    }

    return newValues
  } else {
    return coerceValue(values)
  }
}

function buildOperation(operationName: string, value: ModelDataValue, operation: ModelOperation): ModelOperation {
  operation.asJSON = (): ModelOperationJson => {
    return {
      name: operationName,
      value
    }
  }

  operation.__operationName = operationName
  operation.__operationValue = value

  return operation
}

const replaceValue = (value: ModelDataValue): ModelOperation => {
  value = coerceValues(value)

  return buildOperation('replaceValue', value, () => u.constant(value))
}

const mergeObject = (object: ModelDataValue): ModelOperation => {
  object = coerceValues(object)

  return buildOperation('mergeObject', object, () => object)
}

const pushValue = (...values: ModelDataValue[]): ModelOperation => {
  return buildOperation('pushValue', values, () => (collection: ModelDataValue): any => {
    if (!Array.isArray(collection)) {
      return u.constant(collection)
    }

    return [].concat(collection, values)
  })
}

// Pushes a document model onto a collection of documents
const addDocuments = (...values: ModelDataValue): ModelOperation => {
  return buildOperation('addDocument', values, pushValue(...values))
}

const addValueAt0Index = (value: ModelDataValue[]): ModelOperation => {
  return buildOperation('addValueAt0Index', value, () => (collection: ModelDataValue[]): ModelDataValue[] => {
    if (!Array.isArray(collection)) {
      collection = []
    }

    return [value].concat(collection)
  })
}

const removeValue = (withMatching: { [key: string]: any }): ModelOperation => {
  return buildOperation('removeValue', withMatching, () => (collection: ModelDataValue): any => {
    if (!Array.isArray(collection)) {
      return u.constant(collection)
    }

    const index = _findIndex(collection, (member: { [key: string]: any }) => {
      return _every(withMatching, (matchValue, matchKey) => {
        return member[matchKey] === matchValue
      })
    })

    if (index < 0) {
      return collection
    }

    return collection.slice(0, index).concat(collection.slice(index + 1))
  })
}

const removeValueAtIndex = (index: number): ModelOperation => {
  return buildOperation('removeValueAtIndex', index, () => (collection: ModelDataValue): any => {
    if (!Array.isArray(collection)) {
      return u.constant(collection)
    }

    return collection.slice(0, index).concat(collection.slice(index + 1))
  })
}

const resizeCollection = (size: number, defaultValue: any = {}): ModelOperation => {
  return buildOperation('resizeCollection', size, () => (collection: ModelDataValue[]): any => {
    // TODO: this is a quick fix to prevent users from adding too many members
    // to a collection and exploding the database. There is probably a more
    // robust solution that prevents invalid data from saving. Currently,
    // we do save onChange and validate onBlur, so the data is saving before
    // it's confirmed valid

    if (size > COLLECTION_SIZE_LIMIT) return

    const resized = collection.slice(0, size)
    const lengthDiff = size - resized.length

    if (lengthDiff > 0) {
      _times(lengthDiff, () => resized.push(_cloneDeep(defaultValue)))
    }

    return resized
  })
}

const transformations = {
  replaceValue,
  mergeObject,
  pushValue,
  addDocuments,
  addValueAt0Index,
  removeValue,
  removeValueAtIndex,
  resizeCollection
}

export default transformations
