import React, { FunctionComponent, MouseEvent } from 'react'

import ListedDocumentRequest from 'components/screens/document_upload_v2/listed_document_request'

import Heading from 'components/type/heading'
import SkeletonHeading from 'components/skeleton/heading'
import SkeletonBlock from 'components/skeleton/block'
import SkeletonParagraph from 'components/skeleton/paragraph'
import Markdown from 'components/markdown'
import { DocumentRequestModelV2 } from 'reducers/documents_v2'

interface Props {
  documentRequest?: DocumentRequestModelV2
  toggleTipsForSuccess?: (event: MouseEvent) => void
  slaIsSet: boolean
}

const DocumentUploadRequestContainer: FunctionComponent<Props> = ({ documentRequest, toggleTipsForSuccess }) => {
  if (!documentRequest) {
    // Loading UI
    return (
      <div className="o-action-items__actions-container">
        <SkeletonHeading centered />
        <SkeletonBlock width="5rem" height="2.5rem" className="mx-auto" />
        <SkeletonParagraph numLines={5} />
        <SkeletonParagraph className="w-1/4" />
        <SkeletonBlock height="12rem" />
      </div>
    )
  }

  const title = documentRequest.title

  return (
    <div className="o-action-items__actions-container">
      <div className="o-block">
        <Heading tag="h1" color="emphasized" centered>
          <Markdown source={title} />
        </Heading>
      </div>
      <ListedDocumentRequest documentRequest={documentRequest} toggleTipsForSuccess={toggleTipsForSuccess} />
    </div>
  )
}

export default DocumentUploadRequestContainer
