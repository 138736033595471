import React, { FunctionComponent } from 'react'
import Paragraph from 'components/type/paragraph'
import MessageInitials from 'components/screens/issues/message_initials'
import LinkifyText from 'components/linkify_text'

interface ExplicitProps {
  creatorId?: string
  createdAt?: string
  messageBody?: string
}

export const ThreadPanelMessage: FunctionComponent<ExplicitProps> = ({ creatorId, createdAt, messageBody }) => {
  return (
    <div className="py-4 md:py-6 border-gray-200">
      <MessageInitials creatorId={creatorId} createdAt={createdAt} />
      <Paragraph spacing="xs">
        <LinkifyText>{messageBody}</LinkifyText>
      </Paragraph>
    </div>
  )
}

export default ThreadPanelMessage
