import React, { FunctionComponent, CSSProperties } from 'react'
import cx from 'classnames'

export type ArrowIconDirection = 'up' | 'right' | 'down' | 'left'

interface Props {
  arrowDirection?: ArrowIconDirection
  className?: string
  style?: CSSProperties
  width?: number
  height?: number
}

// ⚠️ This icon doesn't fit the dimensions of our other icons!
const LongArrowIcon: FunctionComponent<Props> = (props) => {
  const { arrowDirection, className, width = 26, height = 60, style } = props

  const classes = cx(className, {
    'c-icon--direction-up': arrowDirection === 'up',
    'c-icon--direction-right': arrowDirection === 'right',
    'c-icon--direction-left': arrowDirection === 'left'
  })
  return (
    <svg
      className={classes}
      width={height}
      height={height}
      viewBox={'0 0 ' + height + ' ' + height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <g id="Group" transform={'translate(' + (height - width) / 2 + ' 0)'}>
        <path
          d={
            'M' +
            (width - 2) +
            ' ' +
            (height - width / 2) +
            ' L' +
            width / 2 +
            ' ' +
            (height - 2) +
            ' L2 ' +
            (height - width / 2)
          }
          stroke="currentColor"
          strokeWidth="2"
        />
        <line x1={width / 2} y1={height - 2} x2={width / 2} y2="4.37114e-08" stroke="currentColor" strokeWidth="2" />
      </g>
    </svg>
  )
}

export default LongArrowIcon
