import React from 'react'
import ReactMarkdown from 'react-markdown'
import { connect } from 'react-redux'

import contextualHelpForPanel, { ContextualHelpType } from 'lib/contextual_help_for_panel'

import { MarkdownLinkRenderer } from '../../../lib/markdown'
import { getCurrentPanel, isCurrentlyInSection } from 'reducers/selectors'

import Modal from '../modal'

interface MappedProps {
  help: Nullable<ContextualHelpType>
}

type Props = MappedProps & {
  isActive: boolean
}

class ContextualHelpModal extends React.Component<Props> {
  render() {
    const { help } = this.props

    if (!help) return null

    return (
      <Modal isActive={this.props.isActive} title={help.header}>
        {({ closeModal }) => (
          <div>
            <div className="o-block c-type c-type--body-sans-sm">
              <ReactMarkdown source={help.text} renderers={{ Link: MarkdownLinkRenderer }} />
            </div>

            <div className="o-block o-layout o-layout--center">
              <button type="button" className="c-btn c-btn--primary" onClick={closeModal({ via: 'HelpGotItButton' })}>
                Got It
              </button>
            </div>
          </div>
        )}
      </Modal>
    )
  }
}

function mapStateToProps(state): MappedProps {
  if (!isCurrentlyInSection(state)) {
    return { help: null }
  }

  const panel = getCurrentPanel(state)

  return {
    help: contextualHelpForPanel(panel)
  }
}

export default connect(mapStateToProps)(ContextualHelpModal)
