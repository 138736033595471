import React, { FunctionComponent, useEffect, useState } from 'react'
import classNames from 'classnames'

import CountryCodeInput from './country_code_input'
import PhoneNumberInput from './phone_number_input'

import { QuestionnaireInputContainerProps } from '../../lib/types'
import { getIsoFromCountryCode } from 'data/phone'

const Phone: FunctionComponent<QuestionnaireInputContainerProps> = ({ disabled, id, name, onBlurEvent, value }) => {
  const [isoCountryCode, setIsoCountryCode] = useState('')
  const [countryCode, setCountryCode] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')

  /**
   * Separates the full phone number into country code and the rest of the phone number
   * Ex: +1 (555) 555-5555 sets countryCode = 1 and phoneNumber = (555) 555-5555
   */
  const parsePhoneNumberWithCountryCode = (fullPhoneNumber: string) => {
    if (fullPhoneNumber) {
      const newCountryCode =
        fullPhoneNumber.indexOf('+') === 0 ? fullPhoneNumber.substring(1, fullPhoneNumber.indexOf(' ')) : ''
      setCountryCode(newCountryCode || '')
      setPhoneNumber(fullPhoneNumber.substring(fullPhoneNumber.indexOf(' ') + 1) || '')
      setIsoCountryCode(getIsoFromCountryCode(newCountryCode) || '')
    }
  }

  const savePhoneNumber = () => {
    onBlurEvent(`+${countryCode} ${phoneNumber}`)
  }

  const updateCountryCode = (iso: string, country: string) => {
    setIsoCountryCode(iso)
    setCountryCode(country)
  }

  const updatePhoneNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(event.target.value)
  }

  useEffect(() => {
    parsePhoneNumberWithCountryCode(value)
  }, [value])

  useEffect(() => {
    if (countryCode && phoneNumber) {
      savePhoneNumber()
    }
  }, [countryCode])

  const renderCountryCodeInput = () => {
    const countryCodeId = `country-code`

    return (
      <div className="o-grid--fluid o-flag o-grid__stacked@sm o-block c-paper-form__group">
        <label
          className="o-grid__cell--3/12 o-grid__cell--off@sm c-paper-form__label c-paper-form__segment"
          htmlFor={countryCodeId}
        >
          <span className="c-type c-type--subhead-sm">{name} Country</span>
        </label>

        <div className="o-grid__cell--9/12 o-grid__cell--off@sm c-paper-form__segment">
          <CountryCodeInput
            className={`c-paper-form__control c-custom-select`}
            disabled={disabled}
            id={countryCodeId}
            onChange={updateCountryCode}
            value={isoCountryCode}
          />
        </div>
      </div>
    )
  }

  const renderPhoneNumberInput = () => {
    const className = classNames('c-paper-form__control o-grid__cell--fill', {
      'c-type--muted': !value
    })

    return (
      <PhoneNumberInput
        className={className}
        disabled={disabled}
        id={id}
        isoCountryCode={isoCountryCode}
        name={name}
        onBlurEvent={savePhoneNumber}
        onChangeEvent={updatePhoneNumber}
        prefix={`+${countryCode}`}
        value={phoneNumber}
      />
    )
  }

  return (
    <div className="o-block">
      {renderCountryCodeInput()}
      {!!countryCode && renderPhoneNumberInput()}
    </div>
  )
}

export default Phone
