import { AfterChangeFunction } from 'components/forms/buffered_field_value'

import { ModelDataSerializableValue } from 'reducers/model'

export type ValidationConditionFunction = (options: { value: ModelDataSerializableValue }) => boolean

export const conditionallyFireValidation = (
  condition: ValidationConditionFunction,
  fireValidation: Function
): AfterChangeFunction<ModelDataSerializableValue> => ({ value }) => {
  if (condition({ value })) {
    fireValidation()
  }
}
