import { ModalDataType } from './types'

export const ModalData: ModalDataType[] = [
  {
    key: 'intro_modal',
    type: 'tutorial',
    header: 'Your application is ready to be shipped!',
    main: [
      {
        fileName: 'binoculars',
        imageDescription: 'Illustration of binoculars',
        header: 'Get Personalized Guidance',
        body:
          "Once shipped, you must complete certain tasks in order to be approved for the K-1 Visa. At each step, go to your Boundless dashboard and provide the requested information -- you'll then see personalized instructions and tasks based on where you're at in the process."
      },
      {
        fileName: 'stopwatch',
        imageDescription: 'Illustration of a stopwatch',
        header: 'Minimize your wait time',
        body:
          "With our expert guidance, we'll help you avoid mistakes on the application, which can cause long processing delays. We'll also help you get a head start collecting documents, so that your K-1 Visa is approved as fast as possible. "
      }
    ]
  },
  {
    key: 'i-129f_filing_date',
    type: 'tutorial',
    header: 'You can now see your estimated I‑129F approval date!',
    main: [
      {
        fileName: 'hand_with_folder',
        imageDescription: 'Illustration of a hand holding a folder',
        header: 'Data from 400+ Customers',
        body:
          "We've used your filing date, plus data from 400+ Boundless customers to provide you with your estimated I-129F approval date range, which will appear at the top of this dashboard. Keep in mind, I-129F processing times can vary widely depending on where you're applying from. However, 70% of our customers get through this step within the range listed."
      },
      {
        fileName: 'calendar',
        imageDescription: 'Illustration of a Calendar',
        header: "What's next",
        body:
          "Boundless will stay with you every step of the way! At every stage, please enter the information requested on your dashboard and we'll provide you with detailed instructions on what's next. "
      }
    ]
  },
  {
    key: 'beneficiary_non_immigrant_visa_receipt_number',
    type: 'tutorial',
    header: 'Complete tasks to avoid delays',
    main: [
      {
        fileName: 'checklist',
        imageDescription: 'Illustration of a checklist',
        header: 'Get a Head Start',
        body:
          "As you complete each step, you'll receive a series of tasks that you can get started on right away. Completing these tasks will give you a head start on your application and help you to avoid delays. If the government does not receive everything they need at the right time, it could result in processing delays on your application. "
      }
    ]
  },
  {
    key: 'beneficiary_alien_registration_number',
    type: 'tutorial',
    header: 'See your estimated Embassy Approval Date!',
    main: [
      {
        fileName: 'calendar',
        imageDescription: 'Illustration of a Calendar',
        body:
          "You're making great progress! Now that your I-129F has been approved, we've used this information, plus data from 400+ Boundless customers, to estimate when your local U.S. Embassy will approve your K-1 Visa. Your estimated embassy approval date will show up as a date range at the top of your Boundless dashboard. Keep in mind embassy processing times can vary widely. However, 70% of our customers get through this step within the range listed."
      }
    ]
  },
  {
    key: 'beneficiary_current_visa_number',
    type: 'celebration',
    header: 'Congrats on getting your K-1 Fiancé(e) Visa!',
    main: [
      {
        fileName: 'together',
        imageDescription: 'Photo of two people together in a field',
        body:
          "This is a huge milestone and it's time to celebrate! All your hard work has paid off - it's now time to start your life together with your fiancé(e) in the U.S."
      }
    ],
    showConfetti: true
  },
  {
    key: 'beneficiary_marriage_state_or_province',
    type: 'celebration',
    header: "We're so happy for you!",
    main: [
      {
        fileName: 'holding_hands',
        imageDescription: 'Photo of two people in wedding attire holding hands',
        body:
          "Congratulations on your big day! You've both done a lot of work to get here and you deserve to celebrate. Plus, this is a huge step towards getting your green card and becoming a legal permanent resident in the U.S."
      }
    ]
  }
]
