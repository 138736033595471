import { WORKFLOW_STATUSES } from 'lib/constants'
import {
  DASHBOARD_PHASES_DATA,
  DashboardPhaseData
} from 'components/screens/dashboard/customer_dashboard/phase_config/phase_preview'

export const DASHBOARD_PHASE_PRE_PAYMENT = 'PRE_PAYMENT'
export const DASHBOARD_PHASE_INTAKE = 'INTAKE'
export const DASHBOARD_PHASE_ILA = 'ILA'
export const DASHBOARD_PHASE_DOCUMENT_UPLOAD = 'DOCUMENT_UPLOAD'
export const DASHBOARD_PHASE_DOCUMENT_REVIEW = 'DOCUMENT_REVIEW'
export const DASHBOARD_PHASE_LEGAL_REVIEW = 'LEGAL_REVIEW'
export const DASHBOARD_PHASE_CUSTOMER_REVIEW = 'CUSTOMER_REVIEW'
export const DASHBOARD_PHASE_SHIPMENT = 'SHIPMENT'
export const DASHBOARD_PHASE_POST_SHIPMENT = 'POST_SHIPMENT'
export const DASHBOARD_PHASE_UNKNOWN = 'UNKNOWN'

export type DashboardPhase =
  | 'PRE_PAYMENT'
  | 'INTAKE'
  | 'ILA'
  | 'DOCUMENT_UPLOAD'
  | 'DOCUMENT_REVIEW'
  | 'LEGAL_REVIEW'
  | 'CUSTOMER_REVIEW'
  | 'SHIPMENT'
  | 'POST_SHIPMENT'
  | 'UNKNOWN'

export const DASHBOARD_PHASE_LIST: DashboardPhase[] = [
  DASHBOARD_PHASE_INTAKE,
  DASHBOARD_PHASE_ILA,
  DASHBOARD_PHASE_DOCUMENT_UPLOAD,
  DASHBOARD_PHASE_DOCUMENT_REVIEW,
  DASHBOARD_PHASE_LEGAL_REVIEW,
  DASHBOARD_PHASE_CUSTOMER_REVIEW,
  DASHBOARD_PHASE_SHIPMENT
]

export function getDashboardPhaseData(phase: DashboardPhase): DashboardPhaseData {
  return DASHBOARD_PHASES_DATA[phase]
}

export function getDashboardPhaseTitle(phase: DashboardPhase) {
  return getDashboardPhaseData(phase).title
}

export const STATUS_TO_PHASE_MAP = {
  // AOS Mapping
  [WORKFLOW_STATUSES.AOS_PAUSE]: DASHBOARD_PHASE_UNKNOWN, // TODO: FOLLOW UP WITH JOHN
  [WORKFLOW_STATUSES.AOS_PAID]: DASHBOARD_PHASE_INTAKE,
  [WORKFLOW_STATUSES.AOS_INITIAL_LAWYER_REVIEW]: DASHBOARD_PHASE_ILA,
  [WORKFLOW_STATUSES.AOS_INITIAL_DOC_REQUEST]: DASHBOARD_PHASE_DOCUMENT_UPLOAD,
  [WORKFLOW_STATUSES.AOS_DOC_GATHERING]: DASHBOARD_PHASE_DOCUMENT_UPLOAD,
  [WORKFLOW_STATUSES.AOS_PUBLIC_CHARGE_QUEUE]: DASHBOARD_PHASE_UNKNOWN, // TODO: FOLLOW UP WITH JOHN
  [WORKFLOW_STATUSES.AOS_READY_FOR_QA]: DASHBOARD_PHASE_DOCUMENT_REVIEW,
  [WORKFLOW_STATUSES.AOS_QA]: DASHBOARD_PHASE_DOCUMENT_REVIEW,
  [WORKFLOW_STATUSES.AOS_OPEN_QUESTIONS_READY_TO_SEND]: DASHBOARD_PHASE_DOCUMENT_REVIEW,
  [WORKFLOW_STATUSES.AOS_WAITING_FOR_RESPONSE_TO_OPEN_QUESTIONS]: DASHBOARD_PHASE_DOCUMENT_REVIEW,
  [WORKFLOW_STATUSES.AOS_FINALIZING_OPEN_QUESTIONS]: DASHBOARD_PHASE_DOCUMENT_REVIEW,
  [WORKFLOW_STATUSES.AOS_BOUNDLESS_LEGAL_REVIEW]: DASHBOARD_PHASE_LEGAL_REVIEW,
  [WORKFLOW_STATUSES.AOS_MASTER_PDF_INITIAL_EDITS_POST_LAWYER_REVIEW]: DASHBOARD_PHASE_LEGAL_REVIEW,
  [WORKFLOW_STATUSES.AOS_INITIAL_LR_QUESTIONS_READY_TO_SEND]: DASHBOARD_PHASE_LEGAL_REVIEW,
  [WORKFLOW_STATUSES.AOS_MASTER_PDF_WAITING_FOR_CUSTOMER_INFO_DOCS_POST_LAWYER_REVIEW]: DASHBOARD_PHASE_LEGAL_REVIEW,
  [WORKFLOW_STATUSES.AOS_MASTER_PDF_FINAL_EDITS_POST_LAWYER_REVIEW]: DASHBOARD_PHASE_LEGAL_REVIEW,
  [WORKFLOW_STATUSES.AOS_MASTER_PDF_ARC_NEEDED]: DASHBOARD_PHASE_LEGAL_REVIEW,
  [WORKFLOW_STATUSES.AOS_MASTER_PDF_ARC_COMPLETED]: DASHBOARD_PHASE_LEGAL_REVIEW,
  [WORKFLOW_STATUSES.AOS_READY_FOR_CUSTOMER_REVIEW]: DASHBOARD_PHASE_CUSTOMER_REVIEW,
  [WORKFLOW_STATUSES.AOS_CUSTOMER_REVIEW]: DASHBOARD_PHASE_CUSTOMER_REVIEW,
  [WORKFLOW_STATUSES.AOS_MASTER_PDF_INITIAL_EDITS_POST_CUSTOMER_REVIEW]: DASHBOARD_PHASE_CUSTOMER_REVIEW,
  [WORKFLOW_STATUSES.AOS_INITIAL_CR_QUESTIONS_READY_TO_SEND]: DASHBOARD_PHASE_CUSTOMER_REVIEW,
  [WORKFLOW_STATUSES.AOS_MASTER_PDF_WAITING_FOR_CUSTOMER_INFO_DOCS_POST_CUSTOMER_REVIEW]: DASHBOARD_PHASE_CUSTOMER_REVIEW,
  [WORKFLOW_STATUSES.AOS_MASTER_PDF_FINAL_EDITS]: DASHBOARD_PHASE_CUSTOMER_REVIEW,
  [WORKFLOW_STATUSES.AOS_READY_FOR_PRINTSHOP]: DASHBOARD_PHASE_SHIPMENT,
  [WORKFLOW_STATUSES.AOS_IN_PRINTSHOP]: DASHBOARD_PHASE_SHIPMENT,
  [WORKFLOW_STATUSES.AOS_READY_TO_SHIP]: DASHBOARD_PHASE_SHIPMENT,
  [WORKFLOW_STATUSES.AOS_PACKAGE_SHIPPED]: DASHBOARD_PHASE_SHIPMENT,
  [WORKFLOW_STATUSES.AOS_REFUNDING]: DASHBOARD_PHASE_UNKNOWN, // TODO: FOLLOW UP WITH JOHN
  [WORKFLOW_STATUSES.AOS_REFUNDED]: DASHBOARD_PHASE_UNKNOWN, // TODO: FOLLOW UP WITH JOHN
  [WORKFLOW_STATUSES.POST_SHIPMENT_PACKAGE_DELIVERED_TO_CUSTOMER]: DASHBOARD_PHASE_SHIPMENT,
  [WORKFLOW_STATUSES.POST_SHIPMENT_PACKAGE_DELIVERED_TO_USCIS]: DASHBOARD_PHASE_SHIPMENT,
  [WORKFLOW_STATUSES.POST_SHIPMENT_WAITING]: DASHBOARD_PHASE_SHIPMENT,
  [WORKFLOW_STATUSES.POST_SHIPMENT_PROCESSING_RFE]: DASHBOARD_PHASE_SHIPMENT,
  [WORKFLOW_STATUSES.POST_SHIPMENT_PROCESSING_SPECIAL_REQUEST]: DASHBOARD_PHASE_SHIPMENT,
  [WORKFLOW_STATUSES.POST_SHIPMENT_INTERVIEW_NOTICE_RECEIVED]: DASHBOARD_PHASE_SHIPMENT,
  [WORKFLOW_STATUSES.POST_SHIPMENT_INTERVIEW_COMPLETE]: DASHBOARD_PHASE_SHIPMENT,
  [WORKFLOW_STATUSES.POST_SHIPMENT_APPROVED]: DASHBOARD_PHASE_SHIPMENT,
  [WORKFLOW_STATUSES.POST_SHIPMENT_RETIRED]: DASHBOARD_PHASE_SHIPMENT,
  [WORKFLOW_STATUSES.POST_SHIPMENT_READY_FOR_QA]: DASHBOARD_PHASE_SHIPMENT,
  [WORKFLOW_STATUSES.POST_SHIPMENT_IN_PRINTSHOP]: DASHBOARD_PHASE_SHIPMENT,
  [WORKFLOW_STATUSES.POST_SHIPMENT_DENIED]: DASHBOARD_PHASE_SHIPMENT,

  // CP Mapping
  [WORKFLOW_STATUSES.CP_PAUSE]: DASHBOARD_PHASE_UNKNOWN,
  [WORKFLOW_STATUSES.CP_PAID]: DASHBOARD_PHASE_INTAKE,
  [WORKFLOW_STATUSES.CP_INITIAL_LAWYER_REVIEW]: DASHBOARD_PHASE_ILA,
  [WORKFLOW_STATUSES.CP_INITIAL_DOC_REQUEST]: DASHBOARD_PHASE_DOCUMENT_UPLOAD,
  [WORKFLOW_STATUSES.CP_DOC_GATHERING]: DASHBOARD_PHASE_DOCUMENT_UPLOAD,
  [WORKFLOW_STATUSES.CP_READY_FOR_QA]: DASHBOARD_PHASE_DOCUMENT_REVIEW,
  [WORKFLOW_STATUSES.CP_QA]: DASHBOARD_PHASE_DOCUMENT_REVIEW,
  [WORKFLOW_STATUSES.CP_OPEN_QUESTIONS_READY_TO_SEND]: DASHBOARD_PHASE_DOCUMENT_REVIEW,
  [WORKFLOW_STATUSES.CP_WAITING_FOR_RESPONSE_TO_OPEN_QUESTIONS]: DASHBOARD_PHASE_DOCUMENT_REVIEW,
  [WORKFLOW_STATUSES.CP_FINALIZING_OPEN_QUESTIONS]: DASHBOARD_PHASE_DOCUMENT_REVIEW,
  [WORKFLOW_STATUSES.CP_BOUNDLESS_LEGAL_REVIEW]: DASHBOARD_PHASE_LEGAL_REVIEW,
  [WORKFLOW_STATUSES.CP_MASTER_PDF_INITIAL_EDITS_POST_LAWYER_REVIEW]: DASHBOARD_PHASE_LEGAL_REVIEW,
  [WORKFLOW_STATUSES.CP_INITIAL_LR_QUESTIONS_READY_TO_SEND]: DASHBOARD_PHASE_LEGAL_REVIEW,
  [WORKFLOW_STATUSES.CP_MASTER_PDF_WAITING_FOR_CUSTOMER_INFO_DOCS_POST_LAWYER_REVIEW]: DASHBOARD_PHASE_LEGAL_REVIEW,
  [WORKFLOW_STATUSES.CP_MASTER_PDF_FINAL_EDITS_POST_LAWYER_REVIEW]: DASHBOARD_PHASE_LEGAL_REVIEW,
  [WORKFLOW_STATUSES.CP_MASTER_PDF_ARC_NEEDED]: DASHBOARD_PHASE_LEGAL_REVIEW,
  [WORKFLOW_STATUSES.CP_MASTER_PDF_ARC_COMPLETED]: DASHBOARD_PHASE_LEGAL_REVIEW,
  [WORKFLOW_STATUSES.CP_READY_FOR_CUSTOMER_REVIEW]: DASHBOARD_PHASE_CUSTOMER_REVIEW,
  [WORKFLOW_STATUSES.CP_CUSTOMER_REVIEW]: DASHBOARD_PHASE_CUSTOMER_REVIEW,
  [WORKFLOW_STATUSES.CP_MASTER_PDF_INITIAL_EDITS_POST_CUSTOMER_REVIEW]: DASHBOARD_PHASE_CUSTOMER_REVIEW,
  [WORKFLOW_STATUSES.CP_INITIAL_QUESTIONS_READY_TO_SEND]: DASHBOARD_PHASE_CUSTOMER_REVIEW,
  [WORKFLOW_STATUSES.CP_MASTER_PDF_WAITING_FOR_CUSTOMER_INFO_DOCS_POST_CUSTOMER_REVIEW]: DASHBOARD_PHASE_CUSTOMER_REVIEW,
  [WORKFLOW_STATUSES.CP_MASTER_PDF_FINAL_EDITS]: DASHBOARD_PHASE_CUSTOMER_REVIEW,
  [WORKFLOW_STATUSES.CP_READY_FOR_PRINTSHOP]: DASHBOARD_PHASE_SHIPMENT,
  [WORKFLOW_STATUSES.CP_IN_PRINTSHOP]: DASHBOARD_PHASE_SHIPMENT,
  [WORKFLOW_STATUSES.CP_READY_TO_SHIP]: DASHBOARD_PHASE_SHIPMENT,
  [WORKFLOW_STATUSES.CP_PACKAGE_SHIPPED]: DASHBOARD_PHASE_SHIPMENT, // TODO: keep description same
  [WORKFLOW_STATUSES.CP_PACKAGE_DELIVERED_TO_CUSTOMER]: DASHBOARD_PHASE_SHIPMENT,
  [WORKFLOW_STATUSES.CP_PACKAGE_DELIVERED_TO_USCIS]: DASHBOARD_PHASE_SHIPMENT,
  [WORKFLOW_STATUSES.CP_2_PAUSED]: DASHBOARD_PHASE_SHIPMENT,
  [WORKFLOW_STATUSES.CP_I130_APPROVAL_NOTICE_RECEIVED]: DASHBOARD_PHASE_SHIPMENT,
  [WORKFLOW_STATUSES.CP_NVC_WELCOME_LETTER_RECEIVED]: DASHBOARD_PHASE_SHIPMENT,
  [WORKFLOW_STATUSES.CP_IV_DOCUMENT_GATHERING]: DASHBOARD_PHASE_SHIPMENT,
  [WORKFLOW_STATUSES.CP_IV_READY_FOR_QA]: DASHBOARD_PHASE_SHIPMENT,
  [WORKFLOW_STATUSES.CP_IV_QA]: DASHBOARD_PHASE_SHIPMENT,
  [WORKFLOW_STATUSES.CP_IV_OPEN_QUESTIONS_READY_TO_SEND]: DASHBOARD_PHASE_SHIPMENT,
  [WORKFLOW_STATUSES.CP_IV_WAITING_FOR_RESPONSE_TO_OPEN_QUESTIONS]: DASHBOARD_PHASE_SHIPMENT,
  [WORKFLOW_STATUSES.CP_IV_FINALIZING_OPEN_QUESTIONS]: DASHBOARD_PHASE_SHIPMENT,
  [WORKFLOW_STATUSES.CP_IV_LAWYER_REVIEW]: DASHBOARD_PHASE_SHIPMENT,
  [WORKFLOW_STATUSES.CP_IV_INITIAL_EDITS_POST_LAWYER_REVIEW]: DASHBOARD_PHASE_SHIPMENT,
  [WORKFLOW_STATUSES.CP_IV_WAITING_FOR_CUSTOMER_INFO_DOCS_POST_LAWYER_REVIEW]: DASHBOARD_PHASE_SHIPMENT,
  [WORKFLOW_STATUSES.CP_IV_FINAL_EDITS_POST_LAWYER_REVIEW]: DASHBOARD_PHASE_SHIPMENT,
  [WORKFLOW_STATUSES.CP_IV_READY_FOR_CUSTOMER_REVIEW]: DASHBOARD_PHASE_SHIPMENT,
  [WORKFLOW_STATUSES.CP_IV_CUSTOMER_REVIEW]: DASHBOARD_PHASE_SHIPMENT,
  [WORKFLOW_STATUSES.CP_IV_INITIAL_EDITS_POST_CUSTOMER_REVIEW]: DASHBOARD_PHASE_SHIPMENT,
  [WORKFLOW_STATUSES.CP_IV_WAITING_FOR_CUSTOMER_INFO_DOCS_POST_CUSTOMER_REVIEW]: DASHBOARD_PHASE_SHIPMENT,
  [WORKFLOW_STATUSES.CP_IV_FINAL_EDITS_POST_CUSTOMER_REVIEW]: DASHBOARD_PHASE_SHIPMENT,
  [WORKFLOW_STATUSES.CP_IV_READY_FOR_NVC_ONLINE_UPLOAD_PRINTSHOP]: DASHBOARD_PHASE_SHIPMENT,
  [WORKFLOW_STATUSES.CP_IV_IN_PRINTSHOP]: DASHBOARD_PHASE_SHIPMENT,
  [WORKFLOW_STATUSES.CP_IV_APPLICATION_SENT_TO_CUSTOMER]: DASHBOARD_PHASE_SHIPMENT,
  [WORKFLOW_STATUSES.CP_IV_PENDING_DOC_APPROVAL]: DASHBOARD_PHASE_SHIPMENT,
  [WORKFLOW_STATUSES.CP_IV_DOC_REJECTION]: DASHBOARD_PHASE_SHIPMENT,
  [WORKFLOW_STATUSES.CP_IV_APPLICATION_ACCEPTED_FOR_REVIEW_OR_WAITING]: DASHBOARD_PHASE_SHIPMENT,
  [WORKFLOW_STATUSES.CP_INTERVIEW_NOTICE_RECEIVED]: DASHBOARD_PHASE_SHIPMENT,
  [WORKFLOW_STATUSES.CP_INTERVIEW_COMPLETE]: DASHBOARD_PHASE_SHIPMENT,
  [WORKFLOW_STATUSES.CP_APPROVED]: DASHBOARD_PHASE_SHIPMENT,
  [WORKFLOW_STATUSES.CP_CP_REFUNDED]: DASHBOARD_PHASE_SHIPMENT,
  [WORKFLOW_STATUSES.CP_CP_RETIRED]: DASHBOARD_PHASE_SHIPMENT,
  [WORKFLOW_STATUSES.CP_PROCESSING_RFE]: DASHBOARD_PHASE_SHIPMENT,
  [WORKFLOW_STATUSES.CP_PROCESSING_SPECIAL_REQUEST]: DASHBOARD_PHASE_SHIPMENT,
  [WORKFLOW_STATUSES.CP_RETIRED]: DASHBOARD_PHASE_SHIPMENT,
  [WORKFLOW_STATUSES.CP_REFUNDING]: DASHBOARD_PHASE_UNKNOWN, // TODO: FOLLOW UP WITH JOHN
  [WORKFLOW_STATUSES.CP_REFUNDED]: DASHBOARD_PHASE_UNKNOWN, // TODO: FOLLOW UP WITH JOHN

  [WORKFLOW_STATUSES.POST_SHIPMENT_PACKAGE_SHIPPED]: DASHBOARD_PHASE_SHIPMENT,
  [WORKFLOW_STATUSES.POST_SHIPMENT_PACKAGE_SHIPPED_2]: DASHBOARD_PHASE_SHIPMENT,

  // Dev Mappings
  [WORKFLOW_STATUSES.CP_I130_INITIAL_DOC_REQUEST]: DASHBOARD_PHASE_UNKNOWN, // TODO: FOLLOW UP WITH JOHN
  [WORKFLOW_STATUSES.CP_I130_DOC_GATHERING]: DASHBOARD_PHASE_UNKNOWN, // TODO: FOLLOW UP WITH JOHN
  [WORKFLOW_STATUSES.CP_NEW_LIST_FOR_TESTS]: DASHBOARD_PHASE_UNKNOWN // TODO: FOLLOW UP WITH JOHN
}
