import server from './server'
import { AxiosPromise } from 'axios'

interface CreateDocument {
  kaseId: number
  documentType: string
  kaseModelPath: string
  file: {
    size: number
    contentType: string
    originalFilename: string
    s3DirectPath: string
  }
}

interface DestroyDocument {
  document: {
    id: number
    kase_id: number
    [otherKey: string]: any
  }
}

function create(request: CreateDocument): AxiosPromise {
  const { documentType, kaseModelPath, kaseId, file } = request

  const data = {
    document: {
      document_type: documentType,
      kase_model_path: kaseModelPath,
      file_size: file.size,
      file_content_type: file.contentType,
      file_original_filename: file.originalFilename,
      file_s3_direct_path: file.s3DirectPath
    }
  }

  return server.post(`/applications/${kaseId}/documents`, data).then((response) => response.data)
}

function destroy({ document }: DestroyDocument): AxiosPromise {
  const { kase_id, id } = document

  return server.delete(`/applications/${kase_id}/documents/${id}`).then((response) => response.data)
}

export default {
  create,
  destroy
}
