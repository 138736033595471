import React, { ChangeEventHandler, FocusEventHandler, FunctionComponent } from 'react'
import CommonInputV2 from './common/common_input_v2'
import CommonLabelV2 from './common/common_label_v2'

interface Props {
  value?: string
  disabled?: boolean
  onChangeEvent: ChangeEventHandler
  onBlurEvent: FocusEventHandler
}

const defaultProps = {
  value: ''
}

const PostalCodeInputV2: FunctionComponent<Props> = ({ value, disabled, onChangeEvent, onBlurEvent }) => {
  return (
    <div className="o-grid--fluid o-grid--stack@sm o-block c-paper-form__group">
      <CommonLabelV2 label="Zip or Postal Code" labelFor="postal_code" />
      <CommonInputV2
        inputName="postal_code"
        value={value ? value : ''}
        onChangeEvent={onChangeEvent}
        onBlur={onBlurEvent}
        disabled={disabled}
      />
    </div>
  )
}

PostalCodeInputV2.defaultProps = defaultProps

export default PostalCodeInputV2
