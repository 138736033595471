import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'

import contextualHelpForPanel, { ContextualHelpType } from 'lib/contextual_help_for_panel'
import { getCurrentPanel } from 'reducers/selectors'
import { toggleContextualHelp } from 'actions/help_actions'

import HelpIcon from './icons/help_icon'

interface Props {
  help: ContextualHelpType
  toggleContextualHelp: (args?: any) => void
}

const AlternativeHelpLink: FunctionComponent<Props> = (props) => {
  if (!props.help || !props.help.alternative_link_text) {
    return null
  }

  const onClick = (event) => {
    event.preventDefault()
    props.toggleContextualHelp({ via: 'AlternativeHelpLink' })
    document.activeElement.blur()
  }

  return (
    <a
      data-qa="inline-help-button"
      href="#"
      onClick={onClick}
      title="Get help"
      className="o-block c-type c-type--body-sans-sm"
    >
      {props.help.alternative_link_text}
      <HelpIcon />
    </a>
  )
}

function mapStateToProps(state) {
  return {
    help: contextualHelpForPanel(getCurrentPanel(state))
  }
}

function mapDispatchToActions(dispatch) {
  return {
    toggleContextualHelp: (...args) => dispatch(toggleContextualHelp(...args))
  }
}

export default connect(mapStateToProps, mapDispatchToActions)(AlternativeHelpLink)
