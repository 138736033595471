import React, { FunctionComponent, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'

import { getPostShipDashboardData, saveMilestoneAnswer } from 'actions/post_ship_dashboard_actions'
import { trackUserEvent } from 'actions/telemetry_actions_v2'

import { getCurrentKaseId, isMobileBrowser, isScreenSmallOrMediumWidth } from 'reducers/selectors'
import { getGlobalError } from 'reducers/global/selector'

import PostShipHeader from './header'
import NextImportantDateCard from './dateCard'
import MilestoneGroupsList from './milestoneGroupsList'
import MilestoneTrackerModal from './milestone_tracker_modal'
import PostShipHelp from './help'
import LoadingDashboard from './loading'
import AirplaneIcon from 'components/icons/airplane_icon'
import ApprovedIdIcon from 'components/icons/approved_id_icon'
import ArrowIcon from 'components/icons/arrow_icon'
import ClockIcon from 'components/icons/clock_icon'

import TaskIcon from 'components/icons/task_icon'
import { PostShipDashboardData, ModalDataType } from './lib/types'
import { ModalData } from './lib/modal_data'
import { TELEMETRY_EVENTS } from 'lib/constants'
import LawyerInteractionsPanel from '../customer_dashboard/v2/lawyer_interactions_panel'
import { KaseStates } from 'lib/kase_state'

interface ActionProps {
  /**
   * Action that makes a request to the backend for the entire blob of data needed
   * to render our Milestone Tracker
   */
  getPostShipDashboardData?: typeof getPostShipDashboardData
  /**
   * Action that updates the answer object on a Milestone - takes the milestone id,
   * the new answer value, and the answer id
   */
  saveMilestoneAnswer: typeof saveMilestoneAnswer
  /**
   * Action that allows us to send tracking events to the backend so they can
   * send them on the Segment, ad blockers can't block the events sent to BE first :D
   */
  trackUserEvent: typeof trackUserEvent
}

interface InjectedProps {
  /**
   * A really cool and big data blob with arrays within arrays within arrays, and strings,
   * numbers, keys ... you'll really just have to check out the type definition of
   * PostShipDashboardData to have a complete appreciation of it. Trust me
   */
  dashboardData?: PostShipDashboardData
  /**
   * A global error message
   */
  errorMessage: string
  /**
   * From our reducer, this is true after we have made the request for the Dashboard data
   * and we are waiting to receive it.
   */
  isLoading?: boolean
  /**
   * Boolean used for mobile styling
   */
  isMobile: boolean
  /**
   * Boolean to flag small and medium sized browser windows. When someone accesses
   * the Milestone Tracker on a small or medium sized window, we make the Milestone
   * list full width and move the Help menu to the header, other than this difference,
   * medium sized windows follow the same styles as large screens.
   */
  isMobileOrTablet: boolean
  /**
   * Boolean from the reducer when the request to update a Milestone
   * has been made and before it is complete
   */
  isUpdating?: boolean
  /**
   * The current Kase ID
   */
  kaseId: number
}

type AllProps = InjectedProps & ActionProps

const PostShipDashboard: FunctionComponent<AllProps> = ({
  dashboardData,
  errorMessage,
  getPostShipDashboardData,
  isLoading,
  isMobile,
  isMobileOrTablet,
  isUpdating,
  kaseId,
  saveMilestoneAnswer,
  trackUserEvent
}) => {
  const innerSectionEl = useRef(null)
  const dateCardEl = useRef(null)
  const afterTaskModalKeys = ModalData.map((data) => data['key'])

  const [currentMilestoneKey, setCurrentMilestoneKey] = useState('')
  const [milestonesContainerWidth, setMilestonesContainerWidth] = useState(0)
  const [showStickyHeader, setShowStickyHeader] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [modalData, setModalData] = useState<ModalDataType>({
    key: '',
    type: '',
    header: '',
    main: [{ fileName: '', imageDescription: '', header: '', body: '' }]
  })
  const [currentModalKey, setCurrentModalKey] = useState('')

  useEffect(() => {
    getPostShipDashboardData()
    trackUserEvent(TELEMETRY_EVENTS.VIEWED_POST_SHIP_DASHBOARD)
  }, [])

  useEffect(() => {
    !isLoading && setCurrentMilestoneKey(dashboardData?.current_active_milestone || '')
  }, [isLoading])

  useEffect(() => {
    if (currentModalKey) {
      const dataForModal = ModalData.find((modal) => {
        return modal.key === currentModalKey
      })

      setModalData(dataForModal)
    }
  }, [currentModalKey])

  useLayoutEffect(() => {
    // Add event listener so that this fires whenever window resizes
    const handleResize = () =>
      dashboardData && !isLoading && setMilestonesContainerWidth(innerSectionEl.current.offsetWidth)
    window.addEventListener('resize', handleResize)

    handleResize()

    // Clean up event listener
    return () => window.removeEventListener('resize', handleResize)
  }, [isLoading])

  const showCurrentModal = (modalKey: string) => {
    setCurrentModalKey(modalKey)
    setShowModal(true)
  }

  useEffect(() => {
    const options = { threshold: 0.7 }

    if (dashboardData && !isLoading) {
      const observer = new IntersectionObserver((entries) => {
        const dateCard = entries[0]

        setShowStickyHeader(!dateCard.isIntersecting)
      }, options)

      observer.observe(dateCardEl.current)

      // Show intro modal if no milestones have been completed
      if (dashboardData.current_active_milestone === 'mail_application') {
        showCurrentModal('intro_modal')
      }
    }
  }, [dashboardData, isLoading])

  const getApplicableNextDateIcon = () => {
    const dateName = dashboardData.next_important_date.name
    if (dateName === 'Estimated I-129F Approval') {
      return <TaskIcon size="default" />
    } else if (dateName === 'Embassy Approval') {
      return <ApprovedIdIcon size="default" />
    } else if (dateName === `After ${dashboardData.beneficiary_first_name} enters the U.S.`) {
      return <AirplaneIcon size="default" />
    } else {
      return <ClockIcon size="default" />
    }
  }

  const nextIncompleteMilestoneKey = () => {
    if (dashboardData && !isLoading) {
      const incompleteMilestoneKeyArr = []
      dashboardData.milestone_groups.map((group) => {
        group.milestones.map((milestone) => {
          if (!milestone.completed || milestone.key === currentMilestoneKey) {
            incompleteMilestoneKeyArr.push(milestone.key)
          }
        })
      })

      const currentMilestoneIndex = incompleteMilestoneKeyArr.indexOf(currentMilestoneKey)

      if (incompleteMilestoneKeyArr.length >= currentMilestoneIndex + 1) {
        return incompleteMilestoneKeyArr[currentMilestoneIndex + 1]
      } else {
        return currentMilestoneKey
      }
    }
  }

  const isLastMilestoneComplete = () => {
    if (dashboardData && !isLoading) {
      const lastGroup = dashboardData.milestone_groups[dashboardData.milestone_groups.length - 1]
      const lastMilestone = lastGroup.milestones[lastGroup.milestones.length - 1]

      return lastMilestone.completed
    }

    return false
  }

  const onSaveMilestoneAnswer = async (
    answer: string,
    milestoneTaskId: number,
    milestoneTaskKey: string,
    answerId?: number
  ) => {
    await saveMilestoneAnswer(answer, milestoneTaskId, answerId)

    if (!answerId && afterTaskModalKeys.includes(milestoneTaskKey)) {
      showCurrentModal(milestoneTaskKey)
    }
  }

  if (!dashboardData || isLoading) {
    return <LoadingDashboard error={errorMessage} />
  } else {
    return (
      <>
        <MilestoneTrackerModal
          hideModal={() => setShowModal(false)}
          isMobile={isMobile}
          modalData={modalData}
          showModal={showModal}
        />
        <div className="w-full">
          {showStickyHeader && (
            <div className="o-layout--sticky-top bg-blue-500 text-white text-center p-4">
              <div className="lg:w-3/4">
                <h1 className="c-type c-type--body-sans-md">
                  {isLastMilestoneComplete() ? (
                    <div>
                      <span className="pr-5">Begin your green card application with 25% already done</span>
                      <a className="c-link--white" href={`/api/v1/kases/${kaseId}/continue_from_completed`}>
                        <b>Start Green Card</b>
                        <ArrowIcon arrowDirection="right" />
                      </a>
                    </div>
                  ) : (
                    <>
                      <span className="mr-2">{getApplicableNextDateIcon()}</span>
                      {dashboardData.next_important_date.name} -{' '}
                      <strong>{dashboardData.next_important_date.date}</strong>
                    </>
                  )}
                </h1>
              </div>
            </div>
          )}
          <PostShipHeader
            beneficiaryFirstName={dashboardData.beneficiary_first_name}
            sponsorFirstName={dashboardData.sponsor_first_name}
            isMobileOrTablet={isMobileOrTablet}
          />
          <div className="o-dashboard__container -mt-52 lg:flex">
            <div ref={innerSectionEl} className="bg-white lg:w-3/4">
              <div ref={dateCardEl}>
                <NextImportantDateCard
                  beneficiaryFirstName={dashboardData.beneficiary_first_name}
                  dateName={dashboardData.next_important_date.name}
                  estimatedCompleteDate={dashboardData.next_important_date.date}
                  explanation={dashboardData.next_important_date.explanation}
                  isLastMilestoneComplete={isLastMilestoneComplete()}
                />
              </div>
              {isMobileOrTablet && <LawyerInteractionsPanel currentPhaseName={'CUSTOMER_COMPLETED'} collapsable />}
              <MilestoneGroupsList
                currentMilestoneKey={currentMilestoneKey}
                error={errorMessage}
                isMobile={isMobile}
                isUpdating={isUpdating}
                milestoneGroups={dashboardData.milestone_groups}
                milestonesContainerWidth={milestonesContainerWidth}
                nextMilestoneKey={nextIncompleteMilestoneKey()}
                onSaveMilestoneAnswer={(answer, milestoneTaskId, milestoneTaskKey, answerId) =>
                  onSaveMilestoneAnswer(answer, milestoneTaskId, milestoneTaskKey, answerId)
                }
                setCurrentMilestoneKey={(newCurrentMilestoneKey) => setCurrentMilestoneKey(newCurrentMilestoneKey)}
              />
            </div>
            {!isMobileOrTablet && (
              <div className="lg:w-1/4">
                <aside className="c-dashboard__panel pl-12 mt-56">
                  <LawyerInteractionsPanel currentPhaseName={'CUSTOMER_COMPLETED'} />
                  <PostShipHelp />
                </aside>
              </div>
            )}
          </div>
        </div>
      </>
    )
  }
}

function mapDispatchToActions(dispatch: Function): ActionProps {
  return {
    trackUserEvent: (event: string) => dispatch(trackUserEvent(event)),
    getPostShipDashboardData: () => dispatch(getPostShipDashboardData()),
    saveMilestoneAnswer: (answer, milestoneTaskId, answerId) =>
      dispatch(saveMilestoneAnswer(answer, milestoneTaskId, answerId))
  }
}

function mapStateToProps(state: any): InjectedProps {
  return {
    dashboardData: state.postShipDashboard.data,
    errorMessage: getGlobalError(state),
    isLoading: state.postShipDashboard.isLoading,
    isUpdating: state.postShipDashboard.isUpdating,
    isMobile: isMobileBrowser(state),
    isMobileOrTablet: isScreenSmallOrMediumWidth(state),
    kaseId: getCurrentKaseId(state)
  }
}

export default connect(mapStateToProps, mapDispatchToActions)(PostShipDashboard)
