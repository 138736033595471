import u from 'updeep'
import _sortBy from 'lodash/sortBy'

import {
  TOGGLE_EXPAND_DOCUMENT_REQUEST,
  LOADING_DOCUMENT_REQUESTS,
  LOADED_DOCUMENT_REQUESTS,
  LOADED_DOCUMENT_REVIEW_KASE,
  UPDATE_CURRENT_DOC_REQUEST_INDEX
} from 'lib/constants'

export interface DocumentReviewKaseModel {
  sla: Date
  completedReviewCount: number
}

export interface DocumentsStore {
  requests: DocumentRequestModel[]
  loadingRequests?: boolean
  uploadModal: {
    expandedRequestIds: any
  }
  documentReviewKase: DocumentReviewKaseModel
  currentDocRequestIndex: number
}

const initialState: DocumentsStore = {
  requests: [],
  loadingRequests: false,
  uploadModal: {
    expandedRequestIds: {}
  },
  documentReviewKase: null,
  currentDocRequestIndex: 0
}

export default function documentsReducer(state: DocumentsStore, action: any): DocumentsStore {
  state = state || initialState

  switch (action.type) {
    case LOADING_DOCUMENT_REQUESTS: {
      return u(
        {
          loadingRequests: true
        },
        state
      )
    }

    case LOADED_DOCUMENT_REQUESTS: {
      return u(
        {
          requests: _sortBy(action.documentRequests, 'title'),
          loadingRequests: false
        },
        state
      )
    }

    case LOADED_DOCUMENT_REVIEW_KASE: {
      return u({ documentReviewKase: action.documentReviewKase }, state)
    }

    case TOGGLE_EXPAND_DOCUMENT_REQUEST: {
      return u(
        {
          uploadModal: {
            expandedRequestIds: { [action.docRequestId]: (val) => !val }
          }
        },
        state
      )
    }

    case UPDATE_CURRENT_DOC_REQUEST_INDEX: {
      return u({ currentDocRequestIndex: action.docRequestIndex }, state)
    }

    default: {
      return state
    }
  }
}
