/**
 * This is a fully-controlled modal wrapper. The API was written to be content-
 * agnostic, which means we can render whatever we want inside the modal
 * without overriding this code.
 *
 * Features:
 * - when the modal isn't visible, it's not in the DOM
 * - when the modal is shown, it's appended to the document.body
 * - the modal uses the correct aria attributes
 * - focus is "trapped" within the modal when it's open
 * - handling the Escape key and clicking on the backdrop to close the modal
 *   MUST be handled by the consumer
 * - prevents the body from scrolling, without the sidebar jitter effect
 *
 * Implementation details:
 * - there needs to be at least one focusable element within the modal
 */

import React, { CSSProperties, createRef, MouseEvent } from 'react'
import uuidv4 from 'uuid/v4'
import { createPortal } from 'react-dom'
import { CSSTransition } from 'react-transition-group'
import FocusTrap from 'focus-trap-react'

import { keyIsEscape } from 'lib/keyboard_helpers'

export enum CloseReason {
  EscapeKey,
  BackdropClicked,
  CloseButton
}

interface Props {
  isOpen?: boolean
  style?: CSSProperties
  handleBackdropClick?: () => void
  /**
   * This callback is required for accessibility reasons. It's bad practice not
   * to allow users to close the modal using the Escape key or clicking on the
   * backdrop. The consumer of this component can choose not to close the modal
   * when this callback is triggered, but I hope that by making it required, we
   * will encourage good practices.
   */
  onRequestClose: (reason: CloseReason) => void
}

export default class ControlledModalWrapper extends React.Component<Props> {
  private uniqueKey: string
  private portalElement: HTMLDivElement
  private modalContainerRef = createRef<HTMLElement>()

  constructor(props) {
    super(props)

    this.uniqueKey = uuidv4()
  }

  componentDidMount() {
    this.portalElement = document.createElement('div')
    this.portalElement.dataset['modalId'] = this.uniqueKey
    document.body.appendChild(this.portalElement)

    // Force a render on the next frame
    setTimeout(() => {
      this.forceUpdate()

      // If the modal is open when mounted, componentDidUpdate will be skipped.
      // Thus, we set up the side effects here.
      if (this.props.isOpen) {
        this.addModalSideEffects()
      }
    })
  }

  componentDidUpdate(prevProps: Props) {
    if (!prevProps.isOpen && this.props.isOpen) {
      this.addModalSideEffects()
    } else if (prevProps.isOpen && !this.props.isOpen) {
      this.removeModalSideEffects()
    }
  }

  componentWillUnmount() {
    document.body.removeChild(this.portalElement)
    this.portalElement = null

    this.removeModalSideEffects()
  }

  /**
   * Call this method when showing the modal to activate the side effects:
   * - lock the html and body elements
   * - add a keyboard listener for the Escape key
   */
  private addModalSideEffects() {
    document.body.style.overflow = 'hidden'
    document.addEventListener('keydown', this.handleKeyPress)
  }

  private removeModalSideEffects() {
    document.body.style.overflow = 'auto'
    document.removeEventListener('keydown', this.handleKeyPress)
  }

  private handleKeyPress = (event: KeyboardEvent) => {
    if (keyIsEscape(event)) {
      this.props.onRequestClose(CloseReason.EscapeKey)
    }
  }

  private onClickOutside = (event: MouseEvent) => {
    if (this.modalContainerRef.current && this.modalContainerRef.current.contains(event.target as HTMLElement)) {
      return
    }

    this.props.onRequestClose(CloseReason.BackdropClicked)
  }

  private getFocusTrapFallback = () => {
    // eslint-disable-next-line no-console
    console.error('A modal needs at least one focusable element to be accessible.')
    return this.portalElement
  }

  render() {
    const { children, isOpen, style } = this.props

    // The portalElement is added to the DOM after mount, so the first render
    // should be skipped
    if (!this.portalElement) {
      return null
    }

    return createPortal(
      <>
        <CSSTransition appear classNames="backdrop-animation" in={isOpen} timeout={400} unmountOnExit>
          <div className="c-new-modal__backdrop" />
        </CSSTransition>
        <CSSTransition appear classNames="modal-animation" in={isOpen} timeout={200} unmountOnExit>
          <FocusTrap focusTrapOptions={{ fallbackFocus: this.getFocusTrapFallback }}>
            <div className="c-new-modal__wrapper" onClick={this.onClickOutside}>
              <aside
                ref={this.modalContainerRef}
                className="c-new-modal__container"
                aria-modal="true"
                role="dialog"
                style={style}
                tabIndex={-1}
              >
                {children}
              </aside>
            </div>
          </FocusTrap>
        </CSSTransition>
      </>,
      this.portalElement
    )
  }
}
