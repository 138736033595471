import * as React from 'react'
import { connect } from 'react-redux'
import _sortBy from 'lodash/sortBy'

import { getCurrentKase, getUploadsForDocumentRequest } from 'reducers/selectors'
import getDocumentRequestLocaleService from 'services/document_request_locale_service'
import { deleteDocument } from 'actions/document_request_actions'
import DocumentRequestUploadArea from 'components/screens/document_upload/document_request_upload_area'
import Markdown from 'components/markdown'
import Paragraph from 'components/type/paragraph'
import Button from 'components/button'
import ListedDocument from 'components/screens/document_upload/listed_document'
import { DocumentStatus, KaseKind } from 'lib/constants'
import TrashIcon from 'components/icons/trash_icon'
import LinkifyText from 'components/linkify_text'

interface ConnectedProps {
  uploads: any[]
  documents: DocumentModel[]
  kase: { id: number; kind: KaseKind }
}

interface ActionProps {
  deleteDocument: Function
}

interface Props {
  documentRequest: DocumentRequestModel
  showHelpText?: boolean
}

type ComponentProps = Props & ConnectedProps & ActionProps

class ListedDocumentRequestBasic extends React.Component<ComponentProps> {
  static defaultProps = {
    showHelpText: true
  }

  sortedDocuments() {
    return _sortBy(this.props.documents, 'created_at').reverse()
  }

  onDeleteDocument = (document) => (event) => {
    event.preventDefault()
    event.stopPropagation()

    this.props.deleteDocument({
      document,
      documentRequest: this.props.documentRequest
    })
  }

  renderDocument(doc: DocumentModel, i: number) {
    const { kase } = this.props

    return (
      <ListedDocument
        key={i}
        document={doc}
        inlineControls={
          doc.status === DocumentStatus.NeedsReview && (
            <div className="o-layout o-layout--right">
              <Button onClick={this.onDeleteDocument(doc)} icon={<TrashIcon />} iconOnly color="tertiary" />
            </div>
          )
        }
        kaseId={kase.id}
        renderThumbnails={true}
      />
    )
  }

  render() {
    const { documentRequest, kase, uploads } = this.props
    const documentRequestLocaleService = getDocumentRequestLocaleService()

    return (
      <div className="o-block">
        {this.props.showHelpText && (
          <div className="o-block o-block--ample c-type c-type--body-sans-md">
            {documentRequest.description || (
              <Markdown
                className="o-block o-block--compact"
                source={documentRequestLocaleService.t(documentRequest, 'description')}
                options={{ linkToNewTabs: true }}
              />
            )}
          </div>
        )}

        {documentRequest.comment.body && (
          <div className="o-block o-box c-tooltip--warning o-layout--padded-x">
            <p className="o-block o-block--compact c-type c-type--subhead-sm">Comment from Boundless</p>
            <Paragraph size="sm">
              <LinkifyText>{documentRequest.comment.body}</LinkifyText>
            </Paragraph>
          </div>
        )}

        <div className="o-griddle__row">
          <div className="o-griddle__col--12 o-griddle__col--sm-6 u-hide@sm-down">
            <div className="o-box o-box--paper o-layout--padded-x">
              <p className="c-type c-type--emphasized c-type--subhead-sm o-block o-block--compact">Tips for success</p>
              <ol className="o-list o-block c-type c-type--body-sans-sm">
                <li>Get certified translations</li>
                <li>Ensure documents are readable</li>
                <li>Ask your CSA for help</li>
              </ol>
            </div>
          </div>
          <div className="o-griddle__col--12 o-griddle__col--sm-6">
            <DocumentRequestUploadArea documentRequest={this.props.documentRequest} setRequestStatusAutomatically />
          </div>
        </div>

        {uploads.map((document, i) => (
          <ListedDocument kaseId={kase.id} key={`uploading-doc-${i}`} document={document} renderThumbnails={true} />
        ))}

        {this.sortedDocuments().map((doc, i) => this.renderDocument(doc, i))}
      </div>
    )
  }
}

const mapStateToProps = (state: any, ownProps: Props): ConnectedProps => {
  const { documentRequest } = ownProps

  return {
    documents: documentRequest.documents,
    kase: getCurrentKase(state),
    uploads: getUploadsForDocumentRequest(state, documentRequest)
  }
}

const mapDispatchToActions = (dispatch: Function): ActionProps => {
  return {
    deleteDocument: (...args) => dispatch(deleteDocument(...args))
  }
}

export default connect(mapStateToProps, mapDispatchToActions)(ListedDocumentRequestBasic)
