import React, { FunctionComponent, useEffect, useState } from 'react'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { Route } from 'react-router-dom'
import _includes from 'lodash/includes'
import classNames from 'classnames'
import { DateTime } from 'luxon'

import DesktopQuestionnaireOutlineV2, { OutlineTypes } from './outline/desktopV2'
import MobileQuestionnaireOutlineV2 from './outline/mobileV2'
import QuestionnairePanel from './panel'
import IneligiblePage from './panel/ineligible'
import ToolMenuV2 from 'components/screens/questionnaire/right_sidebar/tool_menu_v2'
import BreakpointWrapper from 'components/breakpoint_wrapper'
import GlobalError from 'components/error/global'
import LoadingSkeleton from 'components/screens/issues_v2/loading_skeleton'

import {
  loadQuestionnaireOutlineV2,
  loadQuestionnaireV2,
  markPageViewedV2,
  saveQuestionForQuestionnaireV2
} from 'actions/questionnaire_actions_v2'
import { sendTaskEvent } from 'actions/tasks_actions'
import { trackUserEvent, trackClientSideUserEvent } from 'actions/telemetry_actions_v2'
import { toggleContextualHelp } from 'actions/help_actions'

import {
  getCurrentKaseId,
  getCurrentKaseState,
  getCurrentKaseKind,
  isMobileBrowser,
  isSidebarHelpOpen,
  isCurrentKasePaid
} from 'reducers/selectors'
import { getIsFeatureEnabled } from 'reducers/features/selectors'
import { getCurrentQuestionnaireOutlineV2, getCurrentQuestionnaireV2 } from 'reducers/questionnaireV2/selectors'

import { Answer, QuestionnaireOutlineV2, QuestionnairePageV2, QuestionnaireV2, SaveQuestionFn } from './lib/types'
import { smoothScrollToTopOfElement } from 'lib/ui_helpers'
import { TELEMETRY_EVENTS } from 'lib/constants'
import getTelemetryService from 'lib/telemetry'
import { FEATURES } from 'lib/features'
import { openChat } from 'actions/chat_actions'

interface MappedProps {
  currentKaseId: number
  currentKaseKind?: string
  isChatDiscoveryTestEnabled: boolean
  isContextualHelpOpen: boolean
  isLoadingData: boolean
  isLoadingOutline: boolean
  isUpdating: boolean
  isPaidUser: boolean
  kaseState: string
  questionnaireData: QuestionnaireV2
  questionnaireOutlineData: QuestionnaireOutlineV2
  showContextualHelpModal: boolean
  isMobile: boolean
}

interface ActionProps {
  loadQuestionnaireV2: typeof loadQuestionnaireV2
  loadQuestionnaireOutlineV2: typeof loadQuestionnaireOutlineV2
  markPageViewedV2: typeof markPageViewedV2
  openChat: typeof openChat
  saveQuestionForQuestionnaireV2: typeof saveQuestionForQuestionnaireV2
  sendTaskEvent: typeof sendTaskEvent
  toggleContextualHelp: typeof toggleContextualHelp
  trackUserEvent: typeof trackUserEvent
  trackClientSideUserEvent: typeof trackClientSideUserEvent
}

interface ExplicitProps {
  questionnaireSlug: string
  pathname: string
  historyPush: (path: string) => void
}

type Props = MappedProps & ActionProps & ExplicitProps

const QuestionnaireContainerV2: FunctionComponent<Props> = ({
  currentKaseId,
  currentKaseKind,
  historyPush,
  pathname,
  isChatDiscoveryTestEnabled,
  isContextualHelpOpen,
  isLoadingData,
  isLoadingOutline,
  isMobile,
  isUpdating,
  isPaidUser,
  kaseState,
  loadQuestionnaireV2,
  loadQuestionnaireOutlineV2,
  markPageViewedV2,
  openChat,
  questionnaireData,
  questionnaireOutlineData,
  questionnaireSlug,
  saveQuestionForQuestionnaireV2,
  sendTaskEvent,
  showContextualHelpModal,
  toggleContextualHelp,
  trackUserEvent,
  trackClientSideUserEvent
}) => {
  const [previousPagePath, setPreviousPagePath] = useState('')
  const [currentPagePath, setCurrentPagePath] = useState('')
  const [nextPagePath, setNextPagePath] = useState('')
  const [currentPageData, setCurrentPageData] = useState(null as QuestionnairePageV2)
  const [didMidwayEventFire, setDidMidwayEventFire] = useState(false)
  const [editingSummaryQuestionSetIndex, setEditingSummaryQuestionSetIndex] = useState(-1)

  // Used below to parse the beginning and ending of the url path
  const baseQuestionnaireUrlSectionLength = 5
  const rootUrl = `/applications/${currentKaseId}`
  const issuesURL = `${rootUrl}/questions`

  const isEditingIssue = kaseState === 'customer_form_check'

  const getCurrentPagePathFromUrl = (): string | null => {
    let currentPagePath: string
    if (pathname) {
      currentPagePath = pathname.split('/').slice(baseQuestionnaireUrlSectionLength).join('/')
    }
    return currentPagePath
  }

  const fetchQuestionnaire = () => {
    const pagePathFromUrl = getCurrentPagePathFromUrl()
    loadQuestionnaireV2(questionnaireSlug, pagePathFromUrl)
  }

  // Sets the baseQuestionnaireUrl to allow the component to dynamically generate links and routes for the questionnaire
  // If anyone has a cleaner method to get this, please update this! Seems like the router should provide it already.
  // Ex: ('/applications/9/questionnaire/admissiblity') ~ anything after 'admissiblity' is stripped off
  const baseQuestionnaireUrl: string = pathname.split('/').slice(0, baseQuestionnaireUrlSectionLength).join('/')

  // Handles when a different section name is clicked in the outline view
  const handleOutlineSectionClick = (sectionName: string) => {
    const firstPageInSection = questionnaireOutlineData?.attributes.sections
      ?.find((section) => section.attributes.name === sectionName)
      ?.attributes.pages?.filter((page) => ['Page', 'HistoryPage', 'SummaryPage'].includes(page?.attributes?.type))[0]
      ?.attributes?.path
    historyPush(`${baseQuestionnaireUrl}/${firstPageInSection}`)
  }

  const getPreviousPagePath = (previousPage: QuestionnairePageV2) => {
    if (!previousPage?.data?.attributes.path) {
      return null
    }

    return `${baseQuestionnaireUrl}/${previousPage.data?.attributes.path}`
  }

  // get the next page’s path UNLESS the next page has type = ExitPage AND the customer
  // is not done answering all questions in the questionnaire
  const getNextPagePath = (nextPage: QuestionnairePageV2) => {
    const incompleteQuestionnaire = questionnaireOutlineData?.attributes.percent_complete < 100

    if (!nextPage && incompleteQuestionnaire) {
      return null
    }

    if (!nextPage) {
      return `${rootUrl}/timeline`
    }

    if (nextPage.data?.attributes.type === 'ExitPage' && incompleteQuestionnaire) {
      return null
    }

    return `${baseQuestionnaireUrl}/${nextPage.data?.attributes.path}`
  }

  // Navigation click handling
  // ------------------------------
  // When the continue or back buttons are clicked in the panel or the mobile
  // outline sections and the questionnaire is being updated simultaneously
  // we want to wait until the new questionnaire data is returned to ensure
  // that the next/previous page paths are accurate and also to give a better
  // ux experience where the customer sees the value being saved before the
  // next page appears.
  const [waitToContinueUntilUpdated, setWaitToContinueUntilUpdated] = useState(false)
  const [waitToGoBackUntilUpdated, setWaitToGoBackUntilUpdated] = useState(false)
  const [pageDataBeforeUpdate, setPageDataBeforeUpdate] = useState(currentPageData)

  const handleContinueClick = () => {
    const isExitPage = currentPageData?.data?.attributes?.type === 'ExitPage'
    const isPrePayExitPage = isExitPage && currentPageData?.data?.attributes.name === 'ExitPagePrePayment'
    const nextPage = questionnaireData.attributes.next_page
    const isIneligiblePage = currentPageData?.data?.attributes?.type === 'IneligiblePage'

    if (isEditingIssue) {
      window.location.href = issuesURL
      return
    }

    if (isUpdating) {
      setPageDataBeforeUpdate(currentPageData)
      setWaitToContinueUntilUpdated(true)
      return
    }

    if (isPrePayExitPage) {
      window.location.href = `/applications/${currentKaseId}/petition/satisfaction-guarantee`
      return
    }

    /** in case there are some instances where there is no next page
     * we want to route the user back to the dashboard as a fail safe
     */
    if (isExitPage || nextPage?.data?.attributes.type === 'EligibilityReport' || !nextPage.data || !nextPagePath) {
      window.location.href = `/applications/${currentKaseId}/timeline`
      return
    }

    if (currentPageData?.data?.attributes?.type === 'EligibilityReport') {
      window.location.href = `/applications/${currentKaseId}/questionnaire/petition_1`
      return
    }

    if (isIneligiblePage) {
      openChat({ via: 'IneligiblePage' })
      return
    }

    historyPush(nextPagePath)
  }

  const handlePreviousClick = () => {
    if (isUpdating) {
      setWaitToGoBackUntilUpdated(true)
    } else {
      historyPush(previousPagePath)
    }
  }

  const saveGroupOfQuestionsForQuestionnaire = (answers: Answer[]) => {
    answers.forEach((answer) => saveQuestionForQuestionnaireV2(answer))
  }

  const [updateTheOutline, setUpdateTheOutline] = useState(false)
  const handleSaveQuestion: SaveQuestionFn = (question, answer) => {
    if (answer.length > 1) {
      saveGroupOfQuestionsForQuestionnaire(answer)
    } else {
      saveQuestionForQuestionnaireV2(answer[0])

      if (question.attributes.key === 'mbgc_setup_account_email') {
        trackClientSideUserEvent(TELEMETRY_EVENTS.BECAME_LEAD)
      }
    }
    setUpdateTheOutline(true)
  }

  const getNumberOfQuestionsOnPage = (page: QuestionnairePageV2): number => {
    let numberOfQuestions = 0
    page.data.attributes.page_elements.forEach((pageElement) => {
      numberOfQuestions += pageElement?.attributes?.questions?.length || 0
    })
    return numberOfQuestions
  }

  // Checks to see if the current questionnaire page has
  // new elements since the questionnaire last updated
  const currentPageHasNewElements = (): boolean => {
    const hasNewPageElements =
      currentPageData.data.attributes.page_elements.length > pageDataBeforeUpdate.data.attributes.page_elements.length
    const hasNewQuestions =
      getNumberOfQuestionsOnPage(currentPageData) > getNumberOfQuestionsOnPage(pageDataBeforeUpdate)
    return hasNewPageElements || hasNewQuestions
  }

  /**
   * Finds the first instance of a question without a current
   * input value and puts it in focus
   */
  const focusFirstUnansweredQuestion = (pageData: QuestionnairePageV2): void => {
    pageData?.data.attributes?.page_elements?.every((pageElement) => {
      return pageElement.attributes?.questions?.every((question) => {
        if (!question.attributes.input_value) {
          document.getElementById(question.id)?.focus()
          return false
        }
        return true
      })
    })
  }

  // We don't need this for v2 functionality but the shared child components
  // still expect it to be there
  const markPageViewed = () => {}

  useEffect(() => {
    fetchQuestionnaire()
    loadQuestionnaireOutlineV2(questionnaireSlug)

    // For use in HubSpot: we want to track the most recent timezone
    // of the customer for our sales team
    trackUserEvent(TELEMETRY_EVENTS.GET_CUSTOMER_TIME_ZONE, {
      timeZone: DateTime.local().zoneName
    })
  }, [])

  /**
   * If the continue button was clicked while the questionnaire
   * is updating, this will trigger after the update is completed.
   * If the currentPage had additional elements added during the update
   * we stay on that page and focus the first unanswered question on the
   * page. If nothing was added to the current page, we navigate to the
   * next page.
   */
  useEffect(() => {
    if (!isUpdating && waitToContinueUntilUpdated) {
      setWaitToContinueUntilUpdated(false)
      if (currentPageHasNewElements()) {
        focusFirstUnansweredQuestion(currentPageData)
      } else {
        handleContinueClick()
      }
    }
    if (!isUpdating && waitToGoBackUntilUpdated) {
      setWaitToGoBackUntilUpdated(false)
      historyPush(previousPagePath)
    }
  }, [isUpdating])

  // This only fetches the outline after the questionnaire has been updated
  // and its not in mobile view, with one exception. If the user is
  // on the next to last page and the next page is an exit page we want to
  // fetch the outline to get the updated completion percentage in order to
  // enable the continue button if the completion percentage is 100%.
  useEffect(() => {
    const nextPage = questionnaireData?.attributes?.next_page
    const outlineNeeded = !isMobile || nextPage?.data?.attributes?.type === 'ExitPage'

    if (!isUpdating && updateTheOutline && outlineNeeded) {
      loadQuestionnaireOutlineV2(questionnaireSlug)
      setUpdateTheOutline(false)
    }
  }, [isUpdating, isMobile])

  /**
   * TODO: This should be updated so we send a useful "Eligibility Complete"
   *   event once we deprecate "Email Gate" for MBGC. The new event will be
   *   helpful with CON-283. This work can be pulled into a sprint once CON-560 is done
   */
  useEffect(() => {
    const progress = questionnaireOutlineData?.attributes.percent_complete
    /** This sends a midway PP1 event to facebook for MBGC kases ONLY
     * Checks if progress is over 50% and that we haven't already fired the event
     */

    if (
      questionnaireSlug === 'petition_1' &&
      currentKaseKind === 'NewMarriageBasedGreenCard' &&
      !didMidwayEventFire &&
      progress >= 50 &&
      progress <= 60
    ) {
      setDidMidwayEventFire(true)
      const telemetryService = getTelemetryService()
      telemetryService.track(TELEMETRY_EVENTS.MIDWAY_PP1)
    }
    if (progress === 100 && questionnaireSlug === 'mbgc_setup' && !!currentPageData?.data?.attributes) {
      trackUserEvent(TELEMETRY_EVENTS.VIEWED_QUESTIONNAIRE_PAGE, {
        pageName: currentPageData.data.attributes.name,
        questionnaireName: questionnaireSlug
      })
    }
  }, [
    questionnaireSlug,
    currentPageData?.data?.attributes?.name,
    questionnaireOutlineData?.attributes?.percent_complete,
    didMidwayEventFire
  ])

  // When the pathname changes or the questionnaire data is updated, we want to update the current page data
  // to match whichever page in the questionnaire data matches the current pathname. If the pathname does
  // not match any page in the questionnaire data, we make a request to the server to get the page data.
  useEffect(() => {
    const pagePathFromUrl = getCurrentPagePathFromUrl()

    if (questionnaireData && !isLoadingData) {
      const currentPage = questionnaireData.attributes.current_page
      const nextPage = questionnaireData.attributes.next_page
      const previousPage = questionnaireData.attributes.previous_page

      if (nextPage.data?.attributes.path === pagePathFromUrl) {
        setCurrentPageData(nextPage)
        setCurrentPagePath(nextPage.data?.attributes.path)
        setPreviousPagePath(getPreviousPagePath(currentPage))
        setNextPagePath(getNextPagePath(nextPage))
      } else if (previousPage.data?.attributes.path === pagePathFromUrl) {
        setCurrentPageData(previousPage)
        setCurrentPagePath(previousPage.data?.attributes.path)
        setPreviousPagePath(getPreviousPagePath(previousPage))
        setNextPagePath(getNextPagePath(currentPage))
      } else if (currentPage.data?.attributes.path === pagePathFromUrl || pagePathFromUrl === '') {
        setCurrentPageData(currentPage)
        setCurrentPagePath(currentPage.data?.attributes.path)
        setPreviousPagePath(getPreviousPagePath(previousPage))
        setNextPagePath(getNextPagePath(nextPage))
      } else {
        fetchQuestionnaire()
      }
    }
  }, [pathname, questionnaireData, isLoadingData])

  /**
   * This is only used to check for 100% completion of the questionnaire after the user has
   * answered the last question in the questionnaire since the percent complete only comes back
   * in the outline data.
   */
  useEffect(() => {
    if (questionnaireData?.attributes?.next_page) {
      const nextPage = questionnaireData.attributes.next_page
      setNextPagePath(getNextPagePath(nextPage))
    }
  }, [questionnaireOutlineData])

  const [oldCurrentPageData, setOldCurrentPageData] = useState(null)
  useEffect(() => {
    if (currentPageData && currentPageData.data.id !== oldCurrentPageData?.data?.id) {
      if (currentPageData && currentKaseId) {
        markPageViewedV2({
          kaseId: currentKaseId,
          pageId: currentPageData.data.id
        })
      } else {
        fetchQuestionnaire()
      }
    }
    setOldCurrentPageData(currentPageData)
  }, [currentPageData])

  /**
   * Scrolls the questionnaire to the top of the page whenever a
   * new page is rendered
   */
  useEffect(() => {
    smoothScrollToTopOfElement(document.getElementById('react-root'))
  }, [pathname])

  /**
   * New telemetry events
   */
  useEffect(() => {
    if (currentPageData) {
      trackUserEvent(TELEMETRY_EVENTS.VIEWED_QUESTIONNAIRE_PAGE, {
        pageName: currentPageData.data.attributes.name,
        questionnaireName: questionnaireSlug
      })
    }
  }, [currentPageData?.data?.id])

  if (isLoadingData && !questionnaireData) {
    return <LoadingSkeleton />
  }

  const redirectToBeginning = (): void => {
    loadQuestionnaireV2(questionnaireSlug)
  }

  const isQuestionPage = ['Page', 'HistoryPage', 'SummaryPage'].includes(currentPageData?.data?.attributes?.type)
  const isIneligiblePage = currentPageData?.data?.attributes?.type === 'IneligiblePage'
  const isPrepaymentPage = currentPageData?.data?.attributes?.type === 'PrepaymentPage'
  const isPaymentPage = currentPageData?.data?.attributes?.type === 'PaymentPage'
  const isEstimatedTimelinePage = currentPageData?.data?.attributes?.type === 'EstimatedTimelinePage'
  const isLandingPage = currentPageData?.data?.attributes?.type === 'LandingPage'
  const contextualHelp =
    currentPageData?.data?.attributes?.page_elements?.filter((element) =>
      ['question_set', 'summary_question_set'].includes(element.type)
    )?.[0]?.attributes?.contextual_help || currentPageData?.data?.attributes?.payment_details?.contextual_help // payment_details only exist on type of PaymentPage
  const isLoginPage = currentPageData?.data?.attributes?.type === 'LoginPage'
  const isInvalidPage = !currentPageData

  // We need to be more careful in V2 when to allow the continue button to be enabled
  // since we never have more than the next page of the flow at a time and navigating
  // quickly can be slowed down by how fast the next pages are loading.
  const isUpdatingV2 = isLoadingData || isUpdating

  const showPanelWithSidebars =
    (isQuestionPage ||
      isIneligiblePage ||
      isPrepaymentPage ||
      isPaymentPage ||
      isEstimatedTimelinePage ||
      isInvalidPage) &&
    !isLoginPage

  let outlineType = OutlineTypes.Complete
  if (_includes(['mbgc_setup', 'k1_eligibility', 'k1_payment', 'mbgc_payment'], questionnaireSlug)) {
    outlineType = OutlineTypes.Slim
  } else if (_includes(['k1_to_aos_conversion'], questionnaireSlug)) {
    outlineType = OutlineTypes.Disabled
  }

  return (
    <>
      {/* Mobile screens */}
      <BreakpointWrapper mobile>
        <div className="o-grid__col-12">
          <div className="o-pane__fill o-grid__row " style={{ height: '100%' }}>
            <GlobalError />
            <div className={classNames('o-grid__col-8 c-sheet', { 'c-sheet--clear': isLandingPage })}>
              {/* @ts-ignore */}
              <Route path={baseQuestionnaireUrl + '/:panelPath'}>
                <QuestionnairePanel
                  contextualHelp={contextualHelp}
                  currentKaseKind={currentKaseKind}
                  currentPagePath={currentPagePath}
                  editingSummaryQuestionSetIndex={editingSummaryQuestionSetIndex}
                  handleContinueClick={handleContinueClick}
                  handlePreviousClick={handlePreviousClick}
                  isChatDiscoveryTestEnabled={isChatDiscoveryTestEnabled}
                  isContextualHelpOpen={isContextualHelpOpen}
                  isMobile={true}
                  onSaveQuestion={handleSaveQuestion}
                  panelData={currentPageData?.data}
                  sectionName={currentPageData?.data?.attributes.section_name}
                  questionnaireSlug={questionnaireSlug}
                  redirectToBeginning={redirectToBeginning}
                  setEditingSummaryQuestionSetIndex={setEditingSummaryQuestionSetIndex}
                  showContextualHelpModal={showContextualHelpModal}
                  slimOutline={outlineType === OutlineTypes.Slim}
                  toggleContextualHelp={toggleContextualHelp}
                />
              </Route>
              {questionnaireOutlineData && (
                <MobileQuestionnaireOutlineV2
                  currentPageData={currentPageData}
                  handleContinueClick={handleContinueClick}
                  handlePreviousClick={handlePreviousClick}
                  isEditingIssue={isEditingIssue}
                  isPaidUser={isPaidUser}
                  isEditingSummaryQuestionSet={editingSummaryQuestionSetIndex > -1}
                  isUpdating={isUpdatingV2}
                  nextPagePath={nextPagePath}
                  outlineType={outlineType}
                  previousPagePath={previousPagePath}
                  questionnaireOutlineData={questionnaireOutlineData}
                />
              )}
            </div>
          </div>
        </div>
      </BreakpointWrapper>

      {/* Desktop */}
      <BreakpointWrapper desktop>
        {showPanelWithSidebars ? (
          <div className="o-pane__fill o-grid__row">
            <aside className="o-grid__col-2 u-hide@sm-down" aria-label="questionnaire-navigation">
              {questionnaireOutlineData && (
                <DesktopQuestionnaireOutlineV2
                  baseQuestionnaireUrl={baseQuestionnaireUrl}
                  currentPagePath={currentPagePath}
                  currentSectionName={currentPageData?.data?.attributes.section_name}
                  outlineType={outlineType}
                  questionnaireOutlineData={questionnaireOutlineData}
                  setCurrentSectionHandler={handleOutlineSectionClick}
                />
              )}
            </aside>

            <main className="o-grid__col-8 c-sheet">
              <GlobalError />
              {/* @ts-ignore */}
              <Route path={baseQuestionnaireUrl + '/:panelPath'}>
                <>
                  {!isIneligiblePage && (
                    <QuestionnairePanel
                      currentKaseKind={currentKaseKind}
                      currentPagePath={currentPagePath}
                      editingSummaryQuestionSetIndex={editingSummaryQuestionSetIndex}
                      handleContinueClick={handleContinueClick}
                      handlePreviousClick={handlePreviousClick}
                      isContextualHelpOpen={isContextualHelpOpen}
                      isEditingIssue={isEditingIssue}
                      isUpdating={isUpdatingV2}
                      markPageViewed={markPageViewed}
                      nextPagePath={nextPagePath}
                      onSaveQuestion={handleSaveQuestion}
                      panelData={currentPageData?.data}
                      sectionName={currentPageData?.data?.attributes.section_name}
                      previousPagePath={previousPagePath}
                      questionnaireSlug={questionnaireSlug}
                      redirectToBeginning={redirectToBeginning}
                      setEditingSummaryQuestionSetIndex={setEditingSummaryQuestionSetIndex}
                      slimOutline={outlineType === OutlineTypes.Slim}
                      toggleContextualHelp={toggleContextualHelp}
                    />
                  )}

                  {isIneligiblePage && (
                    <IneligiblePage
                      body={currentPageData?.data?.attributes?.body}
                      handlePreviousClick={handlePreviousClick}
                      isPaidUser={isPaidUser}
                      title={currentPageData?.data?.attributes?.subtitle}
                    />
                  )}
                </>
              </Route>
            </main>

            <aside className="o-grid__col-2 u-hide@sm-down">
              <ToolMenuV2
                contextualHelpV2={contextualHelp}
                currentPageData={currentPageData?.data}
                questionnaireData={questionnaireData}
              />
            </aside>
          </div>
        ) : (
          <div className="o-pane__fill o-grid__row">
            <div className="o-grid__col-2" />
            <form className="o-grid__col-8 c-sheet c-sheet--clear" autoComplete="off">
              {/* @ts-ignore */}
              <Route path={baseQuestionnaireUrl + '/:panelPath'}>
                <QuestionnairePanel
                  currentKaseKind={currentKaseKind}
                  currentPagePath={currentPagePath}
                  editingSummaryQuestionSetIndex={editingSummaryQuestionSetIndex}
                  handleContinueClick={handleContinueClick}
                  handlePreviousClick={handlePreviousClick}
                  isContextualHelpOpen={isContextualHelpOpen}
                  isEditingIssue={isEditingIssue}
                  isUpdating={isUpdatingV2}
                  markPageViewed={markPageViewed}
                  onSaveQuestion={handleSaveQuestion}
                  nextPagePath={nextPagePath}
                  panelData={currentPageData?.data}
                  sectionName={currentPageData?.data?.attributes.section_name}
                  previousPagePath={previousPagePath}
                  questionnaireSlug={questionnaireSlug}
                  slimOutline={outlineType === OutlineTypes.Slim}
                  redirectToBeginning={redirectToBeginning}
                  setEditingSummaryQuestionSetIndex={setEditingSummaryQuestionSetIndex}
                  toggleContextualHelp={toggleContextualHelp}
                />
              </Route>
            </form>
            <div className="o-grid__col-2" />
          </div>
        )}
      </BreakpointWrapper>
    </>
  )
}

function mapStateToProps(state: any): MappedProps {
  const isMobile = isMobileBrowser(state)

  return {
    currentKaseId: getCurrentKaseId(state),
    isChatDiscoveryTestEnabled: getIsFeatureEnabled(state, FEATURES.DISCOVERABLE_CHAT),
    isContextualHelpOpen: isSidebarHelpOpen(state),
    isLoadingData: state.questionnaireV2.isLoadingData,
    isLoadingOutline: state.questionnaireV2.isLoadingOutline,
    isUpdating: state.questionnaireV2.isUpdating,
    kaseState: getCurrentKaseState(state),
    currentKaseKind: getCurrentKaseKind(state),
    isPaidUser: isCurrentKasePaid(state),
    questionnaireData: getCurrentQuestionnaireV2(state),
    questionnaireOutlineData: getCurrentQuestionnaireOutlineV2(state),
    showContextualHelpModal: isMobile && isSidebarHelpOpen(state),
    isMobile
  }
}

function mapDispatchToActions(dispatch: Dispatch): ActionProps {
  return bindActionCreators(
    {
      loadQuestionnaireV2,
      loadQuestionnaireOutlineV2,
      markPageViewedV2,
      openChat,
      saveQuestionForQuestionnaireV2,
      sendTaskEvent,
      toggleContextualHelp,
      trackUserEvent,
      trackClientSideUserEvent
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToActions
)(QuestionnaireContainerV2) as FunctionComponent<ExplicitProps>
