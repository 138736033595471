import moment from 'moment'

export const validateDates = (
  startDate: Date,
  endDate: Date
): {
  isStartDateValid: boolean
  isEndDateValid: boolean
  startDateValidationMsg: string
  endDateValidationMsg: string
} => {
  const todaysDate = new Date()
  const validationObj = {
    isStartDateValid: true,
    isEndDateValid: true,
    startDateValidationMsg: '',
    endDateValidationMsg: ''
  }

  const furthestDate = moment('1900-01-01')

  if (!moment(startDate).isValid() || moment(startDate) < furthestDate) {
    validationObj.startDateValidationMsg = 'Please enter a valid date'
    validationObj.isStartDateValid = false
  } else if (startDate > todaysDate) {
    validationObj.startDateValidationMsg = 'Start date should not be after today'
    validationObj.isStartDateValid = false
  }

  if (!moment(endDate).isValid() || moment(endDate) < furthestDate) {
    validationObj.endDateValidationMsg = 'Please enter a valid date'
    validationObj.isEndDateValid = false
  } else if (endDate > todaysDate) {
    validationObj.endDateValidationMsg = 'End date should not be after today'
    validationObj.isEndDateValid = false
  }

  if (endDate && startDate && startDate > endDate) {
    validationObj.startDateValidationMsg = 'Start date should not be after end date'
    validationObj.isStartDateValid = false
  }

  return validationObj
}
