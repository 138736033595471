import { Palette } from '@boundless-immigration/boundless-ui'
import React from 'react'

const DashboardSearchIllustration = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="40" height="40" fill="white" />
    <rect x="5" y="11.6641" width="30" height="20.8571" stroke={Palette.emphasisMain} strokeWidth="2" />
    <path d="M25 39L23 33H17L15 39" stroke={Palette.emphasisMain} strokeWidth="2" />
    <line x1="12" y1="39" x2="28" y2="39" stroke={Palette.emphasisMain} strokeWidth="2" />
    <circle cx="19.5" cy="21.5" r="4.5" stroke={Palette.emphasisMain} strokeWidth="2" />
    <line x1="22.7071" y1="24.2929" x2="26.7071" y2="28.2929" stroke={Palette.emphasisMain} strokeWidth="2" />
  </svg>
)

export default DashboardSearchIllustration
