import server from './server'

interface KaseDocumentReview {
  kaseId: number
}

function retrieveData(request: KaseDocumentReview) {
  const { kaseId } = request

  return server
    .get(`/api/kases/${kaseId}/document-review/retrieve-data`)
    .then((response) => response.data)
    .catch((error) =>
      // eslint-disable-next-line no-console
      console.error('Error retrieving document kase review data', error)
    )
}

function initiateReview(request: KaseDocumentReview) {
  const { kaseId } = request

  return server.post(`/api/kases/${kaseId}/document-review/initiate`).catch((error) =>
    // eslint-disable-next-line no-console
    console.error('Error submitting kase for review', error)
  )
}

export default {
  initiateReview,
  retrieveData
}
