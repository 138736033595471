import uuidv4 from 'uuid/v4'
import _omit from 'lodash/omit'

import {
  TOGGLE_EXPAND_DOCUMENT_REQUEST,
  LOADING_DOCUMENT_REQUESTS,
  LOADED_DOCUMENT_REQUESTS,
  DocumentRequestStatus,
  TELEMETRY_EVENTS,
  LOADED_DOCUMENT_REVIEW_KASE,
  UPDATE_CURRENT_DOC_REQUEST_INDEX
} from 'lib/constants'

import api from '../lib/api'
import t from '../lib/update_transformations'
import { forceMultiOperationSaveData, forceSaveData } from './kase_actions'
import { postTelemetry } from 'actions/telemetry_actions'

import { getCurrentKaseId, getDocumentUploadLocation } from 'reducers/selectors'
import { buildKaseModelTemplate } from 'lib/kase_model_template_helpers'
import { UploadResult } from 'lib/upload_queue'

const loadedDocumentRequests = (documentRequests: DocumentRequestModel[]) => (dispatch) =>
  dispatch({
    type: LOADED_DOCUMENT_REQUESTS,
    documentRequests
  })

export const goToDocRequestByIndex = (docRequestIndex: number) => (dispatch) =>
  dispatch({
    type: UPDATE_CURRENT_DOC_REQUEST_INDEX,
    docRequestIndex
  })

export function toggleExpand(docRequestId: string) {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_EXPAND_DOCUMENT_REQUEST,
      docRequestId
    })
  }
}

const loadedDocumentReviewKase = (documentReviewKase: {}) => (dispatch) =>
  dispatch({
    type: LOADED_DOCUMENT_REVIEW_KASE,
    documentReviewKase
  })

interface FetchDocumentRequestsParams {
  kaseId: number
}

/**
 * Fetch all the document requests for the target kase and update the Redux
 * store.
 * @param param kaseId
 */
export const fetchDocumentRequests = ({ kaseId }: FetchDocumentRequestsParams) => (dispatch) => {
  dispatch({ type: LOADING_DOCUMENT_REQUESTS })

  return api.documentRequests.index({ kaseId }).then((requests) => {
    dispatch(loadedDocumentRequests(requests))
    return requests
  })
}

export const submitRequestsForReview = (kase) => () => api.documentReviewKase.initiateReview(kase)

export const getDocumentReviewKase = (kaseId) => (dispatch) => {
  return api.documentReviewKase.retrieveData(kaseId).then((response) => dispatch(loadedDocumentReviewKase(response)))
}

export const loadDocumentRequestData = (kaseId) => (dispatch) =>
  Promise.all([dispatch(fetchDocumentRequests(kaseId)), dispatch(getDocumentReviewKase(kaseId))])

interface DeleteDocumentParams {
  documentRequest: DocumentRequestModel
  document: DocumentModel
}

export const deleteDocument = ({ documentRequest, document }: DeleteDocumentParams) => (dispatch, getState) => {
  const state = getState()
  const kaseId = getCurrentKaseId(state)

  dispatch(
    forceSaveData({
      path: `${documentRequest.path}.documents`,
      operation: t.removeValue(document)
    })
  ).then(() => {
    dispatch(
      postTelemetry(TELEMETRY_EVENTS.DELETED_DOCUMENT, {
        documentType: documentRequest.document_type,
        deletingFrom: getDocumentUploadLocation(state),
        documentRequestPath: documentRequest.path
      })
    )

    dispatch(fetchDocumentRequests({ kaseId }))
  })
}

const buildDocument = ({ signResult, rawFile, isAdmin = false }): DocumentModel => {
  const newDoc = buildKaseModelTemplate('Document') as DocumentModel
  newDoc.uploaded_by_admin = isAdmin

  return {
    ...newDoc,
    created_at: new Date().toISOString(),
    content_type: signResult.contentType,
    id: uuidv4(),
    size: rawFile.size,
    status: 'pending',
    original_filename: signResult.originalFilename,
    s3_direct_path: signResult.filename
  }
}

interface AddUploadsToRequestParams {
  documentRequest: DocumentRequestModel
  uploadResults: UploadResult[]
  isAdmin: boolean
  updateRequestStatus: boolean
}

interface BulkDocumentRequestUpdateParams {
  documentRequest: DocumentRequestModel
  documentsList: DocumentModel[]
}

export const addUploadsToRequest = ({
  documentRequest,
  uploadResults,
  isAdmin,
  updateRequestStatus
}: AddUploadsToRequestParams) => (dispatch) => {
  const documents = uploadResults.map(({ signResult, rawFile }) => buildDocument({ signResult, rawFile, isAdmin }))

  const saveOperations = [
    {
      path: `${documentRequest.path}.documents`,
      operation: t.addDocuments(...documents)
    }
  ]

  if (updateRequestStatus) {
    const markPending = t.replaceValue(DocumentRequestStatus.NeedsReview)
    saveOperations.push({
      path: `${documentRequest.path}.status`,
      operation: markPending
    })
  }

  return dispatch(forceMultiOperationSaveData(saveOperations))
}

const rootPath: string = (path: string) => {
  let pathParts = path.split('.')

  // Since each document is part of a collection, the final part of the path
  // is its index, ie: `beneficiary.marriage_certificate.documents.0`.
  // removeValue() wants the root path, so we have to pop off the index
  pathParts.pop()

  return pathParts.join('.')
}

export const duplicateDocumentsInSameRequest = ({
  documentRequestPath,
  documentsToDuplicate
}: BulkDocumentRequestUpdateParams) => (dispatch) => {
  const duplicates = documentsToDuplicate.map((document) => {
    const newDoc = buildKaseModelTemplate('Document') as DocumentModel
    return Object.assign(newDoc, _omit(document, ['id', 'path']))
  })

  return dispatch(
    forceSaveData({
      path: rootPath(documentRequestPath),
      operation: t.addDocuments(...duplicates)
    })
  )
}

export const moveDocumentsToAnotherRequest = ({
  newDocumentRequestPath,
  documentsToBeMoved
}: BulkDocumentRequestUpdateParams) => (dispatch) => {
  const addDocuments = t.addDocuments(...documentsToBeMoved)

  const deletionList = documentsToBeMoved.map((document) => {
    const deleteDocument = t.removeValue(document)

    return { path: rootPath(document.path), operation: deleteDocument }
  })

  return dispatch(
    forceMultiOperationSaveData([
      ...deletionList,
      {
        path: newDocumentRequestPath,
        operation: addDocuments
      }
    ])
  )
}

export const updateDocumentRequestStatus = (
  documentRequest: DocumentRequestModel,
  newStatus: DocumentRequestStatus
) => (dispatch) =>
  dispatch(
    forceSaveData({
      path: `${documentRequest.path}.status`,
      operation: t.replaceValue(newStatus)
    })
  )
