import convert from 'convert-units'

const INCHES_IN_FOOT = 12

type Measurement = string | number | null

export function getTotalInches(centimeters: Measurement): number {
  if (!centimeters) return 0

  return convert(centimeters).from('cm').to('in')
}

function roundFeetCorrectly(feet: number): number {
  if (Math.floor(feet) !== Math.floor(feet + 0.05)) {
    return Math.floor(feet + 0.05)
  }

  return Math.floor(feet)
}

export function getInchesFromFeet(feet: Measurement) {
  if (!feet) return 0

  return parseInt(feet, 10) * INCHES_IN_FOOT
}

export function getFeet(centimeters: Measurement): number {
  if (!centimeters) return 0

  return parseInt(roundFeetCorrectly(getTotalInches(centimeters) / INCHES_IN_FOOT), 10)
}

export function getCentimetersFromFtIn(feet: Measurement, inches: Measurement) {
  feet = feet || 0
  inches = inches || 0

  const totalInches = getInchesFromFeet(feet) + parseInt(inches, 10)

  return convert(totalInches).from('in').to('cm')
}

export function getInches(centimeters: Measurement): number {
  if (!centimeters) return 0

  const inches = Math.round(getTotalInches(centimeters) % INCHES_IN_FOOT)

  return inches === 12 ? 0 : inches
}
