import { TELEMETRY_EVENTS } from 'lib/constants'

export default class TelemetryService {
  setReporter(reporter) {
    this.reporter = reporter
  }

  enqueue(fn) {
    if (!this._queue) {
      this._queue = []
    }

    this._queue.push(fn)
  }

  dequeue() {
    if (!this._queue) return

    for (let i in this._queue) {
      this._queue[i]()
    }

    delete this._queue
  }

  identify() {
    let self = this

    this._identified = false
    return this.reporter.identify(this.userData).then(() => {
      self._identified = true
      self.dequeue()
    })
  }

  trackLink({ linkName, redirectTo = null, newTab = false, eventData = {} }) {
    const trackingData = Object.assign(eventData, {
      LinkName: linkName
    })

    if (redirectTo === null) {
      this.reporter.track(TELEMETRY_EVENTS.CLICKED_LINK, trackingData)
    } else {
      this.reporter.track(TELEMETRY_EVENTS.CLICKED_LINK, trackingData, () => {
        window.open(redirectTo, newTab ? '_blank' : '_self')
      })
    }
  }

  trackPageView = () => {
    this._delay(() => {
      // set page data
      this.reporter.page()
      // Set PageView event to non-interactive to fix the GA bounce rates.
      // https://segment.com/docs/destinations/google-analytics/#bounce-rates
      this.reporter.track(TELEMETRY_EVENTS.VIEWED_PAGE, {
        Path: window.location.pathname,
        nonInteraction: 1
      })
    })
  }

  trackScroll({ percentage, timing }) {
    this._delay(() => {
      this.reporter.track(TELEMETRY_EVENTS.SCROLLED_PAGE, {
        Percentage: percentage,
        Timing: timing,
        Path: window.location.pathname
      })
    })
  }

  // Added to support browsers with Adblock enabled.
  // for tracking requests that are called on page load, this method ensures
  // the request is made after the reporter has loaded and identify has run
  _delay(fn) {
    if (!this.reporter || !this._identified) {
      this.enqueue(fn)
    } else {
      fn()
    }
  }
}
