import server, { ejectAuthHeader, addAuthHeader } from './server'
import jwtStorage from './jwt_storage'
import { transformServerErrors } from './errors'

import { AxiosPromise } from 'axios'

interface UserCreateRequest {
  email: string
  password: string
  email_opt_in: boolean
}

interface UserSigninRequest {
  email: string
  password: string
}

interface UserEmailAvailableRequest {
  email: string
}

interface FrontUserRequest {
  email: string
  frontAuthSecret: string
}

const defaultError = 'An error occurred while checking your information. ' + 'Please refresh this page and try again.'

function signIn({ email, password }: UserSigninRequest): AxiosPromise {
  const data = {
    user: {
      email,
      password
    }
  }

  ejectAuthHeader()

  return server
    .post('/users/sign_in.json', data)
    .then((response) => {
      jwtStorage.setToken(response.headers['authorization'])
      addAuthHeader()

      return response.data
    })
    .catch((error) => {
      // Remove any stray token from storage
      jwtStorage.removeToken()

      return transformServerErrors(defaultError)(error)
    })
}

function me(): AxiosPromise {
  return server.get(`/api/users/me`)
}

function emailAvailable({ email }: UserEmailAvailableRequest): AxiosPromise {
  return server.get('/api/emails', { params: { email } }).then((response) => !response.data.exists)
}

function frontPluginData({ email, frontAuthSecret }: FrontUserRequest): AxiosPromise {
  return server
    .get('/api/front_plugin', {
      params: { email, auth_secret: frontAuthSecret }
    })
    .then((response) => response.data)
}

function create({ email, password, email_opt_in }: UserCreateRequest): AxiosPromise {
  const data = {
    user: {
      email,
      password
    },
    email_opt_in
  }

  ejectAuthHeader()

  return server
    .post('/users.json', data)
    .then((response) => {
      jwtStorage.setToken(response.headers['authorization'])
      addAuthHeader()

      return response.data
    })
    .catch((error) => {
      // Remove any stray token from storage
      jwtStorage.removeToken()

      return transformServerErrors(defaultError)(error)
    })
}

function signOut(): Promise<void> {
  return server
    .delete('/users/sign_out.json')
    .then(() => {
      jwtStorage.removeToken()
    })
    .catch(transformServerErrors(defaultError))
}

export default {
  create,
  emailAvailable,
  frontPluginData,
  signIn,
  me,
  signOut
}
