import React, { FunctionComponent } from 'react'
import _map from 'lodash/map'

import { phoneCountryCodeMap } from 'data/phone'

const countryCodes = phoneCountryCodeMap()

interface Props {
  className: string
  disabled?: boolean
  id: string
  onChange: (isoCode: string, countryCode: string) => any
  value: string
}

const CountryCodeInput: FunctionComponent<Props> = ({ className, id, onChange, value }) => {
  const updateCountryCode = (isoCountryCode) => {
    const countryCode = countryCodes[isoCountryCode].countryCode
    onChange(isoCountryCode, countryCode)
  }

  const blankCountryOption = (value: Nullable<string>) => {
    const disabled = Boolean(value)

    return (
      <option disabled={disabled} hidden={disabled} value="">
        Select a country code
      </option>
    )
  }

  return (
    <select
      id={id}
      name="iso_country_code"
      className={className}
      required={true}
      value={value}
      onChange={(event) => updateCountryCode(event.target.value)}
      autoComplete="off"
    >
      {blankCountryOption(value)}

      {_map(countryCodes, (info, isoCode) => {
        return (
          <option key={`code-${isoCode}`} value={isoCode}>
            {info.name} (+{info.countryCode})
          </option>
        )
      })}
    </select>
  )
}

export default CountryCodeInput
