import { getCurrentKaseId } from 'reducers/selectors'
import { push } from 'redux-little-router'

export function showTimeline({ scrollTop }) {
  return (dispatch, getState) => {
    const state = getState()
    const currentKaseId = getCurrentKaseId(state)
    const path = `/applications/${currentKaseId}/timeline`

    dispatch(push(path))

    if (scrollTop) {
      window.scrollTo(0, 0)
    }
    return
  }
}
