import React, { FunctionComponent } from 'react'

import { DocumentRequestCustomerStatus } from 'lib/constants'

import Badge from 'components/badge'
import CheckmarkIcon from 'components/icons/checkmark_icon'
import ClockIcon from 'components/icons/clock_icon'
import UploadIcon from 'components/icons/upload_icon'

interface Props {
  iconOnly?: boolean
  customerStatus: DocumentRequestCustomerStatus
}

const RequestStatusIndicator: FunctionComponent<Props> = ({ iconOnly, customerStatus }) => {
  const noIcon = <span className="c-icon"></span>

  if (customerStatus === DocumentRequestCustomerStatus.SeeComment) {
    if (iconOnly) {
      return (
        <span className="c-icon o-action-items__indicator-container">
          <span className="o-action-items__indicator danger"></span>
        </span>
      )
    }

    return (
      <Badge variant="warning" className="pl-2">
        <span className="c-icon o-action-items__indicator-container">
          <span className="o-action-items__indicator warning"></span>
        </span>
        See Comment
      </Badge>
    )
  }

  if (customerStatus === DocumentRequestCustomerStatus.ToDo) {
    if (iconOnly) {
      return (
        <span className="c-icon o-action-items__indicator-container">
          <span className="o-action-items__indicator primary"></span>
        </span>
      )
    }

    return (
      <Badge variant="primary" className="pl-2">
        <span className="c-icon o-action-items__indicator-container">
          <span className="o-action-items__indicator primary"></span>
        </span>
        <span>To Do</span>
      </Badge>
    )
  }

  if (customerStatus === DocumentRequestCustomerStatus.DoneUploading) {
    if (iconOnly) {
      return noIcon
    }

    return (
      <Badge>
        <UploadIcon className="mr-1" />
        Done Uploading
      </Badge>
    )
  }

  if (customerStatus === DocumentRequestCustomerStatus.SentToBoundless) {
    if (iconOnly) {
      return noIcon
    }

    return (
      <Badge variant="warningAlt">
        <ClockIcon className="mr-2" />
        Sent To boundless
      </Badge>
    )
  }

  if (customerStatus === DocumentRequestCustomerStatus.Accepted) {
    if (iconOnly) {
      return <CheckmarkIcon className="o-action-items__icon--accepted mr-1" />
    }

    return (
      <Badge variant="success">
        <CheckmarkIcon className="mr-1" />
        Accepted
      </Badge>
    )
  }

  return iconOnly ? noIcon : null
}

export default RequestStatusIndicator
