import mbgcSetupGraph from './marriage_based_green_card'
import newmbgcSetupGraph from './new_marriage_based_green_card'
import natzSetupGraph from './naturalization'
import Decorator from 'lib/decorator'
import { getPanelSequenceFromGraph, PanelGraph } from './shared'

interface Args {
  kaseKind: string
  modelData: Decorator
  sectionName: string
}

export interface GraphCollection {
  [kaseKind: string]: {
    [sectionName: string]: PanelGraph
  }
}

const graphs: GraphCollection = {
  MarriageBasedGreenCard: {
    setup: mbgcSetupGraph
  },
  Naturalization: {
    setup: natzSetupGraph
  },
  NewMarriageBasedGreenCard: {
    setup: newmbgcSetupGraph
  }
}

export const getPanelSequence = ({ kaseKind, modelData, sectionName }: Args): string[] => {
  const graph = graphs[kaseKind] && graphs[kaseKind][sectionName]
  const graphSequence = (graph && getPanelSequenceFromGraph(graph, modelData)) || []

  return graphSequence
}
