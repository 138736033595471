import React, { FunctionComponent, useEffect, useState } from 'react'
import { connect } from 'react-redux'

import { TELEMETRY_EVENTS, CONVERSION_TRACKING_EVENTS, CONVERSION_TRACKING_DESTINATIONS } from 'lib/constants'
import { postTelemetry, postTelemetryWithConversion } from 'actions/telemetry_actions'
import { getDashboardData } from 'actions/kase_actions'

import TimelinePreviewWrapper from './timeline_preview_wrapper'
import WorkArea from './work_area'
import ResourcesPanel from './resources_panel'
import DocumentUploadPanel from './document_upload_panel'

import {
  getCurrentKaseId,
  getCurrentKaseProcess,
  getCurrentKaseState,
  getCurrentKaseIsAOS,
  getCurrentUserHasAcceptedAttorneyAgreement,
  isScreenLargeWidth,
  getApplicationFeeInCents,
  getKaseTags,
  getCurrentUserEmail,
  getShouldOpenAttorneyAgreementModal
} from 'reducers/selectors'
import PaymentSuccessModal from '../modals/payment_success_modal'
import GlobalError from 'components/error/global'

import { KasePhaseData, KasePhase } from './types'
import { smoothScrollToTopOfElement } from 'lib/ui_helpers'
import { openModal } from 'actions/modal_actions'
import { trackClientSideUserEvent } from 'actions/telemetry_actions_v2'
import PhaseEntryModal from '../modals/phase_entry_modal'
import LawyerInteractionsPanel from './lawyer_interactions_panel'

interface ActionProps {
  trackViewedDashboard?: ({ currentPhase }: { currentPhase: string }) => any
  getDashboardData?: () => any
  openAttorneyAgreementModal: () => void
  trackSuccessfulPayment: (data: { applicationFeeInCents: number }) => void
}

interface Props {
  currentKaseId: number
  currentKaseProcess: string
  currentKaseIsAOS: boolean
  currentKaseState: string
  currentPhase: KasePhase
  dashboardData: KasePhaseData
  isAttorneyAgreementAccepted?: boolean
  isScreenLargeWidth?: boolean
  applicationFeeInCents: number
  currentUserEmail: string
  kaseTags: any
  shouldOpenAttorneyAgreementModal: boolean
}

const DashboardLayout: FunctionComponent<ActionProps & Props> = ({
  currentKaseId,
  currentKaseProcess,
  currentKaseIsAOS,
  currentKaseState,
  currentPhase,
  dashboardData,
  getDashboardData,
  isScreenLargeWidth,
  openAttorneyAgreementModal,
  applicationFeeInCents,
  trackSuccessfulPayment,
  currentUserEmail,
  kaseTags,
  shouldOpenAttorneyAgreementModal
}) => {
  const [showPaymentSuccessModal, setShowPaymentSuccessModal] = useState(
    window.location.search.indexOf('payment_success') !== -1
  )
  const [showPhaseEntryModal, setShowPhaseEntryModal] = useState(
    window.location.search.indexOf('starting_phase') !== -1
  )

  useEffect(() => {
    if (shouldOpenAttorneyAgreementModal) {
      openAttorneyAgreementModal()
    }
  }, [shouldOpenAttorneyAgreementModal])

  useEffect(() => {
    getDashboardData()
  }, [])

  useEffect(() => {
    smoothScrollToTopOfElement()
  }, [currentPhase])

  const hidePaymentSuccessModal = () => {
    setShowPaymentSuccessModal(false)
    const queryParams = new URLSearchParams(window.location.search)
    if (kaseTags?.length > 0)
      trackClientSideUserEvent(`Purchase-${kaseTags[0].name}`, {
        value: applicationFeeInCents / 100.0,
        currency: 'USD'
      })
    if (queryParams.get('payment_type') === 'stripe') {
      trackSuccessfulPayment({ applicationFeeInCents })
      // remove payment success query params so we don't track successful
      // payment twice if the user refreshes the app
      window.history.replaceState({}, '', `${window.location.pathname}`)
    }
  }

  return (
    <div style={{ width: '100%' }} className="o-layout--impose">
      <GlobalError />
      <PaymentSuccessModal hideModal={hidePaymentSuccessModal} showModal={showPaymentSuccessModal} />
      {currentPhase?.entry_modal_data && (
        <PhaseEntryModal
          currentPhase={currentPhase}
          hideModal={() => setShowPhaseEntryModal(false)}
          showModal={showPhaseEntryModal}
        />
      )}
      <TimelinePreviewWrapper currentPhase={currentPhase} allPhases={dashboardData?.phases} isLoading={!currentPhase} />
      <div className="o-dashboard__container">
        <div className="o-griddle__row">
          <div className="o-griddle__col--12 o-griddle__col--md-8">
            <WorkArea
              currentPhase={currentPhase}
              kaseId={currentKaseId}
              kaseIsAOS={currentKaseIsAOS}
              isLoading={!currentPhase}
              kaseState={currentKaseState}
            />
          </div>
          <div className="o-griddle__col">
            <LawyerInteractionsPanel currentPhaseName={currentPhase?.name} />
            <ResourcesPanel currentUser={currentUserEmail} currentPhase={currentPhase} isLoading={!currentPhase} />
            {currentPhase?.show_document_upload_panel && <DocumentUploadPanel />}
          </div>
        </div>
      </div>
    </div>
  )
}

function mapDispatchToActions(dispatch: Function): ActionProps {
  return {
    trackViewedDashboard: (args) => dispatch(postTelemetry(TELEMETRY_EVENTS.VIEWED_DASHBOARD, args)),
    trackSuccessfulPayment: ({ applicationFeeInCents }) =>
      dispatch(
        postTelemetryWithConversion(
          TELEMETRY_EVENTS.SUCCEEDED_PAYMENT,
          { containerSection: 'timeline' },
          CONVERSION_TRACKING_EVENTS.PAYMENT,
          {
            value: applicationFeeInCents / 100.0,
            currency: 'USD'
          },
          null,
          [CONVERSION_TRACKING_DESTINATIONS.FACEBOOK, CONVERSION_TRACKING_DESTINATIONS.GOOGLE]
        )
      ),
    getDashboardData: () => dispatch(getDashboardData()),
    openAttorneyAgreementModal: () =>
      dispatch(
        openModal({
          name: 'AttorneyAgreementModal',
          via: 'PaymentPage',
          modalProps: { disableClosingWithoutAction: true }
        })
      )
  }
}

function mapStateToProps(state: any): Props {
  return {
    dashboardData: state.dashboard.data,
    currentKaseId: getCurrentKaseId(state),
    currentKaseState: getCurrentKaseState(state),
    currentKaseProcess: getCurrentKaseProcess(state),
    shouldOpenAttorneyAgreementModal: getShouldOpenAttorneyAgreementModal(state),
    currentPhase: state.dashboard.data?.currentPhase,
    isAttorneyAgreementAccepted: getCurrentUserHasAcceptedAttorneyAgreement(state),
    isScreenLargeWidth: isScreenLargeWidth(state),
    applicationFeeInCents: getApplicationFeeInCents(state),
    currentKaseIsAOS: getCurrentKaseIsAOS(state),
    currentUserEmail: getCurrentUserEmail(state),
    kaseTags: getKaseTags(state)
  }
}

export default connect(mapStateToProps, mapDispatchToActions)(DashboardLayout)
