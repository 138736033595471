import React, { FunctionComponent, useState } from 'react'
import { getLawyerInfo, getStewardInfo } from 'reducers/selectors'
import { connect } from 'react-redux'
import { TeamMemberInfo } from 'reducers/dashboard'
import { TeamMember } from 'components/screens/dashboard/customer_dashboard/team_member_info'
import TeamMemberModal from './modals/team_member_modal'

interface Props {
  lawyerInfo?: TeamMemberInfo
  stewardInfo?: TeamMemberInfo
}

interface ModalContent {
  teamMember: TeamMemberInfo
  memberType: 'lawyer' | 'steward'
}

const TeamPanel: FunctionComponent<Props> = ({ lawyerInfo, stewardInfo }) => {
  const [showTeamModal, setShowTeamModal] = useState(false)
  const [modalContent, setModalContent] = useState<ModalContent>({
    teamMember: stewardInfo,
    memberType: 'steward'
  })

  const hideTeamModal = () => setShowTeamModal(false)

  const showStewardModal = () => {
    setModalContent({
      teamMember: stewardInfo,
      memberType: 'steward'
    })
    setShowTeamModal(true)
  }

  const showLawyerModal = () => {
    setModalContent({
      teamMember: lawyerInfo,
      memberType: 'lawyer'
    })
    setShowTeamModal(true)
  }

  if (!stewardInfo) {
    return null
  }

  return (
    <div className="o-griddle__col--12 o-griddle__col--md-6 o-griddle__col--lg-12 o-block o-block--spacious">
      <aside className="c-dashboard__panel">
        <header className="o-block o-block--compact c-type c-type--headline-sm c-type--emphasized">Your Team</header>
        <hr className="o-block c-divider" />
        <div>
          <div className="o-block o-block--compact" onClick={showStewardModal}>
            <TeamMember {...stewardInfo} />
          </div>
          {lawyerInfo && (
            <div className="o-block o-block--compact" onClick={showLawyerModal}>
              <TeamMember {...lawyerInfo} />
            </div>
          )}
        </div>
        <TeamMemberModal
          teamMember={modalContent.teamMember}
          memberType={modalContent.memberType}
          contactEmail={stewardInfo.email}
          showModal={showTeamModal}
          hideModal={hideTeamModal}
        />
      </aside>
    </div>
  )
}

function mapStateToProps(state: any): Props {
  return {
    lawyerInfo: getLawyerInfo(state),
    stewardInfo: getStewardInfo(state)
  }
}

export default connect(mapStateToProps)(TeamPanel)
