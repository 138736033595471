import React, { FunctionComponent, useEffect } from 'react'
import { connect } from 'react-redux'
import { getWorkflowStatus } from 'reducers/selectors'

import {
  DASHBOARD_PHASE_INTAKE,
  DASHBOARD_PHASE_ILA,
  DASHBOARD_PHASE_DOCUMENT_UPLOAD,
  DASHBOARD_PHASE_DOCUMENT_REVIEW,
  DASHBOARD_PHASE_LEGAL_REVIEW,
  DASHBOARD_PHASE_CUSTOMER_REVIEW,
  DASHBOARD_PHASE_SHIPMENT,
  DASHBOARD_PHASE_POST_SHIPMENT,
  DASHBOARD_PHASE_PRE_PAYMENT,
  DashboardPhase
} from 'components/screens/dashboard/customer_dashboard/phase_map'
import PrePaymentPhase from './phases/pre_payment_phase'
import IntakePhase from './phases/intake_phase'
import CustomerReviewPhase from './phases/customer_review_phase'
import QualityAssurancePhase from './phases/quality_assurance_phase'
import LegalReviewPhase from './phases/legal_review_phase'
import ShipmentPhase from './phases/shipment_phase'
import { smoothScrollToTopOfElement } from 'lib/ui_helpers'
import RemainingInfoAndDocuments from 'components/screens/dashboard/customer_dashboard/remaining_info_and_documents'
import { WORKFLOW_STATUSES } from 'lib/constants'
import SupportingDocuments from 'components/screens/dashboard/customer_dashboard/supporting_documents'

interface Props {
  currentPhase: DashboardPhase
  workflowStatus: WORKFLOW_STATUSES
}

const CurrentPhase = ({ phase }) => {
  return (
    <div
      className="o-block o-box o-box--light o-box--ample"
      style={{
        display: 'flex',
        justifyContent: 'center',
        minHeight: '8rem',
        textAlign: 'center'
      }}
    >
      {phase}
    </div>
  )
}

const WorkArea: FunctionComponent<Props> = ({ currentPhase, workflowStatus }) => {
  useEffect(() => {
    // When the workflowStatus changes, scroll to the top of the page
    smoothScrollToTopOfElement(document.body)
  }, [workflowStatus])

  const workPhaseComponent = () => {
    switch (currentPhase) {
      case DASHBOARD_PHASE_PRE_PAYMENT:
        return <PrePaymentPhase />
      case DASHBOARD_PHASE_INTAKE:
        return <IntakePhase />
      case DASHBOARD_PHASE_ILA:
        return <RemainingInfoAndDocuments phase={currentPhase} />
      case DASHBOARD_PHASE_DOCUMENT_UPLOAD:
        return <RemainingInfoAndDocuments phase={currentPhase} />
      case DASHBOARD_PHASE_DOCUMENT_REVIEW:
        return (
          <>
            <QualityAssurancePhase workflowStatus={workflowStatus} />
            <SupportingDocuments />
          </>
        )
      case DASHBOARD_PHASE_LEGAL_REVIEW:
        return (
          <>
            <LegalReviewPhase workflowStatus={workflowStatus} />
            <SupportingDocuments />
          </>
        )
      case DASHBOARD_PHASE_CUSTOMER_REVIEW:
        return (
          <>
            <CustomerReviewPhase />
            <SupportingDocuments />
          </>
        )
      case DASHBOARD_PHASE_SHIPMENT:
        return (
          <>
            <ShipmentPhase workflowStatus={workflowStatus} />
            <SupportingDocuments />
          </>
        )
      case DASHBOARD_PHASE_POST_SHIPMENT:
        return (
          <>
            <CurrentPhase phase={currentPhase} />
            <SupportingDocuments />
          </>
        )
      default:
        return <CurrentPhase phase={currentPhase} />
    }
  }

  return (
    <div className="o-block o-box o-box--light c-dashboard__work-area o-layout--impose">{workPhaseComponent()}</div>
  )
}

function mapStateToProps(state) {
  return { workflowStatus: getWorkflowStatus(state) }
}

export default connect(mapStateToProps)(WorkArea)
