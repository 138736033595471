import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'
import { openChat } from 'actions/chat_actions'

import AskIcon from '../../icons/ask_icon'
import PhoneIcon from '../../icons/phone_icon'

interface Props {
  openChat: Function
  kaseState: string
}

const HelpSection: FunctionComponent<Props> = ({ openChat, kaseState }) => {
  const handleSupportMessageClick = (event) => {
    event.preventDefault()
    event.nativeEvent.stopImmediatePropagation()

    openChat({ via: 'ToolMenu' })
  }

  return (
    <>
      <a href="/timeline" className="o-block c-btn c-btn__block c-btn--secondary">
        Return to Dashboard
      </a>
      {kaseState === 'customer_document_check' ? (
        <a href="/document_upload" className="o-block c-btn c-btn__block c-btn--primary">
          View all Documents
        </a>
      ) : (
        ''
      )}
      <ul className="tool-menu-sublist tool-menu-sublist--no-bottom o-box">
        <li className="tool-menu-sublist-header block">
          <span className="o-block o-block--tight c-type c-type--body-sans-md c-type--emphasized">Have Questions?</span>
        </li>
        <li className="tool-menu-item block">
          <a
            href="#support-message"
            className="o-media o-media--tight o-box o-box--tight tool-menu-item-link"
            onClick={handleSupportMessageClick}
          >
            <AskIcon className="o-media__figure tool-menu-item-icon" />

            <span className="o-media__body c-type c-type--subhead-sm">Chat With Us</span>
          </a>
        </li>
        <li className="tool-menu-item block">
          <a href="tel:1-855-268-6353" className="o-media o-media--tight o-box o-box--tight tool-menu-item-link">
            <PhoneIcon className="o-media__figure tool-menu-item-icon" />

            <span className="o-media__body c-type c-type--subhead-sm">Call 1-855-Boundless</span>
          </a>
        </li>
      </ul>
    </>
  )
}

function mapStateToProps(state) {
  return state
}

function mapDispatchToActions(dispatch) {
  return {
    openChat: (...args) => dispatch(openChat(...args))
  }
}

export default connect(mapStateToProps, mapDispatchToActions)(HelpSection)
