import React from 'react'

import { formatValue } from './format'

interface Props extends HTMLInputElement {
  id: string
  onChange: Function
}

const placeholder = 'mm/dd/yyyy'

const BACKSPACE_KEY = 8
const AT_SLASH_BOUNDARY = /^[0-9]{2}(\/[0-9]{2})?$/

export default class DateInputControl extends React.Component<Props> {
  deleting: boolean = false
  inputRef: Nullable<HTMLInputElement> = null

  static defaultProps = {
    onChange: () => {}
  }

  handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.keyCode === BACKSPACE_KEY) this.deleting = true
  }

  isCursorAtEnd(): boolean {
    if (!this.inputRef) return false
    if (this.inputRef !== document.activeElement) return false

    const endPosition = this.inputRef.value.length
    const currentPosition = this.inputRef.selectionEnd

    return currentPosition >= endPosition
  }

  deletePastTrailingSlashIfNeeded(value: string) {
    if (!this.isCursorAtEnd()) return value

    if (AT_SLASH_BOUNDARY.test(value)) {
      value = value.slice(0, value.length - 1)
    }

    return value
  }

  deleteAfterCursorIfInCenter(value: string) {
    if (this.isCursorAtEnd()) return value
    if (!this.inputRef) return value

    const currentPosition = this.inputRef.selectionStart

    return value.slice(0, currentPosition)
  }

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let { value } = event.target

    if (this.deleting) {
      value = this.deletePastTrailingSlashIfNeeded(value)
      value = this.deleteAfterCursorIfInCenter(value)
    }

    value = formatValue(value)

    this.props.onChange(value)
    this.deleting = false
  }

  render() {
    const { id, ...otherProps } = this.props

    // input type="tel" allows the numeric keyboard to pop up
    // on Android devices
    return (
      <input
        id={id}
        placeholder={placeholder}
        pattern="\d*/\d*/\d*"
        inputMode="numeric"
        type="tel"
        ref={(ref) => (this.inputRef = ref)}
        {...otherProps}
        onKeyDown={this.handleKeyPress}
        onChange={this.handleChange}
      />
    )
  }
}
