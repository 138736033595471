import React, { FunctionComponent } from 'react'

import { DocumentUploadSummaryStates } from 'lib/constants'
import { DashboardPhase } from 'components/screens/dashboard/customer_dashboard/phase_map'
import { initialDocRequestContent } from './doc_upload_phase_content'

import PhaseInformationTemplate from 'components/screens/dashboard/customer_dashboard/phase_information/phase_information_template'

interface ExplicitProps {
  currentDocumentUploadSummaryState: DocumentUploadSummaryStates
  currentPhase: DashboardPhase
}

const InitialDocRequestContent: FunctionComponent<ExplicitProps> = ({
  currentDocumentUploadSummaryState,
  currentPhase
}) => {
  const uploadingNotStarted = currentDocumentUploadSummaryState === DocumentUploadSummaryStates.UploadingNotStarted

  const content = initialDocRequestContent(uploadingNotStarted)

  return (
    <PhaseInformationTemplate
      currentPhase={currentPhase}
      primaryCTA={content.primaryCTA}
      header={content.phaseInstruction}
      description={content.phaseDescription}
    />
  )
}

export default InitialDocRequestContent as FunctionComponent<ExplicitProps>
