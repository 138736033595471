import React, { Component } from 'react'
import { connect } from 'react-redux'

import getTelemetryService from 'lib/telemetry'

import { currentlyHasDocumentRequests, getCurrentKaseKindString, isCurrentKasePaid } from 'reducers/selectors'

import DocumentRequestList from 'components/screens/document_upload/document_request_list'
import HelpIcon from 'components/icons/help_icon'
import Modal from '../modal'
import { externalUrl } from 'lib/settings'

const clickedDocumentUploadInstructions = (event) => {
  event.preventDefault()

  getTelemetryService().trackLink({
    linkName: 'DocumentUploadInstructions',
    redirectTo: externalUrl('/immigration-resources/creating-flawless-digital-documents/'),
    newTab: true
  })
}

const clickedDocumentTranslationInstructions = (event) => {
  event.preventDefault()

  getTelemetryService().trackLink({
    linkName: 'DocumentTranslationInstructions',
    redirectTo: externalUrl('/immigration-resources//translating-immigration-documents-english/'),
    newTab: true
  })
}

interface Props {
  currentKaseKindString: string
  hasDocumentRequests: boolean
  isActive: boolean
  isPaidUser: boolean
}

class DocumentsModal extends Component<Props> {
  firstSentence() {
    if (this.props.hasDocumentRequests) {
      return (
        <React.Fragment>
          Below are some of the{' '}
          <a
            href={externalUrl('/immigration-resources/what-documents-for-marriage-green-card')}
            target="_blank"
            rel="noopener noreferrer"
          >
            required documents
          </a>{' '}
          for your {this.props.currentKaseKindString} application.
        </React.Fragment>
      )
    } else {
      return <React.Fragment>We don't currently require any uploaded documents from you at this time.</React.Fragment>
    }
  }

  renderPrePaymentMessage() {
    return (
      <div className="o-block o-block--ample">
        <p className="o-block o-block--ample c-type c-type--body-sans-md">
          {this.firstSentence()} Once you start your application with Boundless, your independent immigration attorney
          will review your information and finalize the list of documents required for your specific situation.
        </p>
        <p className="o-block o-block--ample c-type c-type--body-sans-md">
          As soon as your personalized list of required documents is finalized, you’ll be able to{' '}
          <a
            href={externalUrl('/immigration-resources/creating-flawless-digital-documents')}
            target="_blank"
            rel="noopener noreferrer"
          >
            scan or snap a picture
          </a>{' '}
          of each document and securely upload them to your account. Your independent immigration attorney will then
          review all of your documents for completeness and consistency before you submit your application to the
          government.
        </p>
      </div>
    )
  }

  renderPostPaymentMessage() {
    return (
      <React.Fragment>
        <div className="o-grid__col-12 o-block">
          <h3 className="c-type c-type--headline-md">Tips For Success</h3>
        </div>
        <ol className="o-block o-list c-type c-type--body-sans-md">
          <li>
            <strong>Get certified translations.</strong> The U.S. government requires all documents written in a
            language other than English to be accompanied by a certified English translation–this includes documents
            that are already translated, but don’t have a certified translation. Please provide this requirement for all
            such documents, including those written in multiple languages. Please refer to{' '}
            <a
              href={externalUrl('/immigration-resources/translating-immigration-documents-english')}
              rel="noopener noreferrer"
              onClick={clickedDocumentTranslationInstructions}
              target="_blank"
            >
              our guide <HelpIcon />
            </a>{' '}
            for help with translating documents.
          </li>
          <li>
            <strong>Ensure documents are readable.</strong> Clearly photograph or scan your documents so that they are
            readable by USCIS. Please refer to{' '}
            <a
              href={externalUrl('/immigration-resources/creating-flawless-digital-documents')}
              rel="noopener noreferrer"
              onClick={clickedDocumentUploadInstructions}
              target="_blank"
            >
              our guide
              <HelpIcon />
            </a>{' '}
            on how to photograph or scan your documents.
          </li>
          <li>
            <strong>Read the descriptions below.</strong> Each document request below includes a detailed description of
            the exact document(s) needed by USCIS, and all requirements they have for that document.
          </li>
        </ol>
      </React.Fragment>
    )
  }

  renderMessage() {
    return this.props.isPaidUser ? this.renderPostPaymentMessage() : this.renderPrePaymentMessage()
  }

  render() {
    return (
      <Modal isActive={this.props.isActive} title="Documents">
        {() => (
          <div className="o-block">
            {this.renderMessage()}

            <hr className="c-divider o-block o-block--compact" />

            <DocumentRequestList />
          </div>
        )}
      </Modal>
    )
  }
}

function mapStateToProps(state) {
  return {
    currentKaseKindString: getCurrentKaseKindString(state),
    hasDocumentRequests: currentlyHasDocumentRequests(state),
    isPaidUser: isCurrentKasePaid(state)
  }
}

export default connect(mapStateToProps)(DocumentsModal)
