import React, { FunctionComponent } from 'react'

import SectionCompleteGrayIcon from 'components/icons/section_complete_gray_icon'
import HtmlSafe from 'components/html_safe'

interface Props {
  body: string
  title: string
}

const ExitInterstitial: FunctionComponent<Props> = ({ title, body }) => {
  return (
    <div className="interstitial c-sheet__body">
      <div className="o-box o-box--copious">
        <div className="o-block o-layout o-layout--center">
          <SectionCompleteGrayIcon className="c-icon--gigantic" />
        </div>
        <div className="o-box o-box--copious">
          <div className="o-layout o-layout--center o-block o-block--copious o-grid--fluid">
            <div className="o-grid__cell--12/12">
              <h1 className="c-type c-type--headline-md c-type--emphasized">{title}</h1>
            </div>
          </div>
          <div className="o-layout o-layout--center o-block o-block--copious o-grid--fluid">
            <div className="o-grid__cell--12/12">
              <h2 className="c-type c-type--headline-sm">{body && HtmlSafe({ text: body })}</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ExitInterstitial
