import React, { FunctionComponent } from 'react'
import IconWrapper, { IconWrapperExternalProps } from './icon_wrapper'

const GovernmentIcon: FunctionComponent<IconWrapperExternalProps> = (props) => (
  <IconWrapper {...props} width="30" height="28" viewBox="0 0 30 28" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.3691 7.10259C11.264 7.10259 7.93608 10.4305 7.93608 14.5356H5.18311C5.18311 8.91004 9.74354 4.34961 15.3691 4.34961C20.9947 4.34961 25.5551 8.91004 25.5551 14.5356H22.8022C22.8022 10.4305 19.4743 7.10259 15.3691 7.10259Z"
      fill="#5E5E5E"
    />
    <path
      opacity="0.5"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.2613 18.9405H3.47559V16.1875H27.2613V18.9405Z"
      fill="#5E5E5E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30 27.751L-2.40916e-07 27.751L0 24.998L30 24.998L30 27.751Z"
      fill="#5E5E5E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.23775 25.5484L5.23775 20.7031L7.99072 20.7031L7.99072 25.5484L5.23775 25.5484Z"
      fill="#5E5E5E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.0473 4.84524L14.0473 -1.20242e-07L16.8003 0L16.8003 4.84524L14.0473 4.84524Z"
      fill="#5E5E5E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.3691 0.000929886L20.2144 0.000930309L20.2144 2.75391L15.3691 2.75391L15.3691 0.000929886Z"
      fill="#5E5E5E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.4043 25.5484L11.4043 20.7031L14.1572 20.7031L14.1572 25.5484L11.4043 25.5484Z"
      fill="#5E5E5E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.5712 25.5484L17.5712 20.7031L20.3242 20.7031L20.3242 25.5484L17.5712 25.5484Z"
      fill="#5E5E5E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.7387 25.5484L23.7387 20.7031L26.4917 20.7031L26.4917 25.5484L23.7387 25.5484Z"
      fill="#5E5E5E"
    />
  </IconWrapper>
)

export default GovernmentIcon
