import React, { FunctionComponent, useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import api from 'lib/api'
import { closeModal } from 'actions/modal_actions'
import { isAsyncSplitItTestRunning } from 'lib/feature_flags_deprecated'
import {
  CONVERSION_TRACKING_DESTINATIONS,
  CONVERSION_TRACKING_EVENTS,
  SET_GLOBAL_ERROR,
  TELEMETRY_EVENTS
} from 'lib/constants'
import { postTelemetryWithConversion } from 'actions/telemetry_actions'
import { getAccountCurrentAddress, getCurrentKase } from 'reducers/selectors'
import { paymentSuccessUrl } from 'lib/settings'
import { trackUserEvent } from 'actions/telemetry_actions_v2'
import Modal from 'components/modals/modal'
import googleMaps from 'google-maps-api'
import ArrowIcon from 'components/icons/arrow_icon'
import { GlobalErrorObject } from 'components/screens/questionnaire/lib/types'
import { Tier } from 'components/payment'
import { getIsFeatureEnabled } from 'reducers/features/selectors'
import { FEATURES } from 'lib/features'
import { Typography } from '@boundless-immigration/boundless-ui'

interface ExplicitProps {
  applicationFeeInCents?: number
  containerSection?: string
  includeUscisFees?: boolean
  isActive?: boolean
  numberTeens?: number
  onCancel?: () => void
  tier: Tier
}

interface MappedProps {
  address?: any
  kase: Kase
  isPaymentOutageNoticeActive?: boolean
  isMaxOf12PaymentsActive?: boolean
}

interface ActionProps {
  closeModal: () => void
  setGlobalError: ({ type, payload }: GlobalErrorObject) => void
  trackFailedPayment: (data: { paymentType: string; error: string }) => void
  trackInitiatedPayment: (data: { paymentType: string; hasSelectedUSCISFees: boolean }) => void
  trackSuccessfulPayment: (data: { successUrl: string }) => void
}

type Props = ExplicitProps & ActionProps & MappedProps

const SplititModalV2: FunctionComponent<Props> = ({
  applicationFeeInCents,
  closeModal,
  containerSection,
  includeUscisFees,
  isActive,
  isPaymentOutageNoticeActive,
  kase,
  numberTeens,
  onCancel = () => {},
  setGlobalError,
  tier,
  trackFailedPayment,
  trackSuccessfulPayment,
  trackInitiatedPayment
}) => {
  const fullname = window.applicationDataStore.getCurrentUser().full_name
  const errorMsg = 'Sorry, something went wrong with SplitIt checkout. Please contact help@boundless.com for assistance'

  const [name, setName] = useState(fullname || '')
  const [address1, setAddress1] = useState('')
  const [address2, setAddress2] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [zip, setZip] = useState('')
  const [country, setCountry] = useState('')
  const [address1Ref, setAddress1Ref] = useState(null)
  const [submittedBillingAddress, setSubmittedBillingAddress] = useState(false)
  const [disableBillingAddressBtn, setDisableBillingAddressBtn] = useState(true)

  useEffect(() => {
    if (name !== '' && address1 !== '' && city !== '' && state !== '' && country !== '' && zip !== '') {
      setDisableBillingAddressBtn(false)
    } else {
      setDisableBillingAddressBtn(true)
    }
  }, [name, address1, city, state, country, zip])

  const autocomplete = useRef(null)

  const expireSplitItCheckout = () => {
    api.payments.expireSplitItCheckout(kase.id).catch((err) => {
      setGlobalError({
        type: SET_GLOBAL_ERROR,
        payload: errorMsg
      })
      trackFailedPayment({
        paymentType: 'splitIt',
        error: err
      })
    })
  }

  /** This is needed to trigger our on cancel method and set the
   * loading state on the parent back to false
   */
  const onSplititCloseModal = () => {
    onCancel()
    expireSplitItCheckout()
    closeModal()
  }

  /** Gets google maps api options
   * For information on autocomplete results types see:
   * https://developers.google.com/places/supported_types#table3
   */
  const getAutocompleteOptions = () => {
    //
    const options = { types: ['address'] }
    return options
  }

  /** Extracts the address pieces and sets them to the state values
   * for the rest of the billing address form
   */
  const extractAddress = (addressComponents) => {
    const address: any = {}
    addressComponents.forEach((c) => {
      address[c.types[0]] = c.long_name
      address[`${c.types[0]}_short`] = c.short_name
    })
    setAddress1(`${address.street_number} ${address.route}`)
    //sometimes google uses sublocality instead of locality (like for Brooklyn Addresses)
    setCity(address.locality || address.sublocality_level_1)
    setCountry(address.country)
    setState(address.administrative_area_level_1_short)
    setZip(address.postal_code)
    setDisableBillingAddressBtn(false)
  }

  /** Handles the address autocomplete and then extracts the address parts*/
  const handleAddressAutocomplete = () => {
    const place = autocomplete.current.getPlace()

    if (place.address_components) {
      extractAddress(place.address_components)
    }
  }

  /** Creates the payment on the kase */
  const createPayment = (installmentPlanNumber) => {
    api.payments
      .create({
        containerSection: containerSection,
        kase_id: kase.id,
        paymentType: 'splitIt',
        tokenId: installmentPlanNumber
      })
      .then(() => {
        // Make sure the telemetry event is delivered
        // before moving to a new page
        trackSuccessfulPayment({
          successUrl: paymentSuccessUrl()
        })
      })
       .catch((errors) => {
         const error = errors.response.data.errors[0]
         setGlobalError({
          type: SET_GLOBAL_ERROR,
          payload: errorMsg
        })
        trackFailedPayment({
          paymentType: 'splitIt',
          error
        })
      })
  }

  /** On payment success */
  const splititOnSuccess = (installmentPlanNumber) => {
    api.payments
       .verifySplitItPayment(kase.id, installmentPlanNumber)
       .then((resp) => {
        if (resp.data.IsAuthorized) {
          if (!isAsyncSplitItTestRunning()) {
            createPayment(installmentPlanNumber)
          }
        } else {
          const error = resp.data.ResponseHeader.Errors[0].Message
          setGlobalError({
            type: SET_GLOBAL_ERROR,
            payload: errorMsg
          })
          trackFailedPayment({
            paymentType: 'splitIt',
            error
          })
        }
      })
      .catch((err) => {
        setGlobalError({
          type: SET_GLOBAL_ERROR,
          payload: errorMsg
        })
        trackFailedPayment({
          paymentType: 'splitIt',
          error: err
        })
      })
  }

  /** configure splitit flex fields and show the form */
  const configureFlexFields = (installmentPlanNumber) => {
    const email = window.applicationDataStore.getCanonicalEmailAddress()
    Splitit.FlexForm.setup({
      showOnReady: true,
      container: 'splitit-card-data',
      ipn: installmentPlanNumber,
      culture: 'en-US',
      nameField: {
        selector: '#splitit-cardholder-name'
      },
      paymentButton: {
        selector: '#splitit-btn-pay'
      },
      // numberOfInstallments: 5, // optional
      billingAddress: {
        addressLine: address1,
        addressLine2: address2,
        city: city,
        state: state,
        country: country,
        zip: zip
      },
      consumerData: {
        fullName: name,
        email: email
      },
      onSuccess(data) {
        // console.log('SplitIt onSuccess', data) for debugging
        splititOnSuccess(installmentPlanNumber)
      },
      onError(err) {
        // console.error('SplitIt onError', err) for debugging
        trackFailedPayment({
          paymentType: 'splitIt',
          error: err
        })
      }
    }).ready(() => {
      trackInitiatedPayment({ paymentType: 'splitIt', hasSelectedUSCISFees: includeUscisFees })
    })
  }

  /** configure google maps API */
  useEffect(() => {
    if (address1Ref) {
      address1Ref.dataset.blockEnterKeyNavigation = 'true'

      googleMaps(window.GOOGLE_MAPS_KEY, ['places'])().then((maps) => {
        autocomplete.current = new maps.places.Autocomplete(address1Ref, getAutocompleteOptions())
        autocomplete.current.addListener('place_changed', handleAddressAutocomplete)
      })
    }
  }, [address1Ref])

  /** We want to wait to call the api until the modal is already
   * open, we've received the amount for payment, and that
   * they've submitted their billing address or it causes an error in splitit */
  useEffect(() => {
    isActive &&
      applicationFeeInCents &&
      submittedBillingAddress &&
      api.payments
        .initiateSplitItCheckout({
          include_uscis_fees: includeUscisFees,
          kase_id: kase.id,
          number_teens: numberTeens,
          tier: tier
        })
        .then((resp) => {
          configureFlexFields(resp.data.InstallmentPlanNumber)
        })
        .catch((error) => {
          // console.error('SplitIt error', error) for debugging
          setGlobalError({
            type: SET_GLOBAL_ERROR,
            payload: errorMsg
          })
          trackFailedPayment({
            paymentType: 'splitIt',
            error
          })
        })
  }, [isActive, applicationFeeInCents, submittedBillingAddress])

  const onBackToBillingAddressBtnClicked = () => {
    setSubmittedBillingAddress(false)
    expireSplitItCheckout()
  }

  /** billing address form */
  const billingAddress = (
    <div className="splitit-billing-address">
      <h3 className="o-block--compact">Billing Address</h3>
      <input
        value={name}
        className="c-input o-block--compact"
        placeholder="Your Full Name"
        type="text"
        name="name"
        id="name"
        onChange={(e) => setName(e.target.value)}
      />
      <input
        value={address1}
        className="c-input o-block--compact"
        placeholder="Address Line 1"
        type="search"
        name="address1"
        id="address1"
        autoComplete="on"
        ref={setAddress1Ref}
        onChange={(e) => setAddress1(e.target.value)}
      />
      <input
        value={address2}
        placeholder="Address Line 2 (e.g. Unit 12)"
        className="c-input o-block--compact"
        type="text"
        name="address2"
        id="address2"
        onChange={(e) => setAddress2(e.target.value)}
      />
      <input
        value={city}
        placeholder="City"
        className="c-input o-block--compact"
        type="text"
        name="city"
        id="city"
        onChange={(e) => setCity(e.target.value)}
      />
      <input
        value={state}
        placeholder="State/Province"
        className="c-input o-block--compact"
        type="text"
        name="state"
        id="state"
        onChange={(e) => setState(e.target.value)}
      />
      <input
        value={zip}
        placeholder="Postal Code"
        className="c-input o-block--compact"
        type="text"
        name="zip"
        id="zip"
        onChange={(e) => setZip(e.target.value)}
      />
      <input
        value={country}
        placeholder="Country"
        className="c-input o-block--compact"
        type="text"
        name="country"
        id="country"
        onChange={(e) => setCountry(e.target.value)}
      />
      <button
        className="c-btn c-btn--block c-btn--primary"
        onClick={() => setSubmittedBillingAddress(true)}
        disabled={disableBillingAddressBtn}
      >
        Continue to Payment
      </button>
    </div>
  )

  return (
    <Modal
      isActive={isActive}
      title="Set Up Monthly Payments"
      modalWidth="o-grid__col-4"
      modalOffset="o-grid__col--offset-4"
      closeModal={onSplititCloseModal}
    >
      {() => (
        <>
          {isPaymentOutageNoticeActive ? (
            <>
              <Typography variant="body-sans-lg" sx={{ fontWeight: 'bold', mb: 2 }}>
                Call <a href="tel:8552686353">(855) 268-6353</a> to pay by phone
              </Typography>
              <Typography>
                Online payment plans are temporarily unavailable. If you’d like to start a payment plan, please call{' '}
                <a href="tel:8552686353">(855) 268-6353</a>.
              </Typography>
            </>
          ) : (
            <>
              {!submittedBillingAddress && billingAddress}
              {submittedBillingAddress && (
                <>
                  <button className="c-btn c-btn__link" onClick={onBackToBillingAddressBtnClicked}>
                    <ArrowIcon arrowDirection="left" /> Back to Billing Address
                  </button>
                  <div
                    className="splitit-design-classic split-it-container"
                    data-splitit-auto-align="standard"
                    id="splitit-card-data"
                  ></div>
                </>
              )}
            </>
          )}
        </>
      )}
    </Modal>
  )
}

function mapDispatchToActions(dispatch: Function, ownProps: ExplicitProps & MappedProps): ActionProps {
  return {
    closeModal: (...args) => dispatch(closeModal(...args)),
    setGlobalError: ({ type, payload }: GlobalErrorObject) => dispatch({ type, payload }),
    trackFailedPayment: (args) => dispatch(trackUserEvent(TELEMETRY_EVENTS.FAILED_PAYMENT, args)),
    trackInitiatedPayment: (args) =>
      dispatch(
        trackUserEvent(TELEMETRY_EVENTS.INITIATED_PAYMENT, {
          ...args,
          containerSection: ownProps.containerSection,
          tierCartIntent: ownProps.tier
        })
      ),
    trackSuccessfulPayment: ({ successUrl }) =>
      dispatch(
        postTelemetryWithConversion(
          TELEMETRY_EVENTS.SUCCEEDED_PAYMENT,
          { containerSection: ownProps.containerSection },
          CONVERSION_TRACKING_EVENTS.PAYMENT,
          {
            value: ownProps.applicationFeeInCents / 100.0,
            currency: 'USD'
          },
          () => (window.location.href = successUrl),
          [CONVERSION_TRACKING_DESTINATIONS.FACEBOOK, CONVERSION_TRACKING_DESTINATIONS.GOOGLE]
        )
      )
  }
}

function mapStateToProps(state): MappedProps {
  return {
    address: getAccountCurrentAddress(state),
    kase: getCurrentKase(state),
    // we need to show a temporary message about splitit being down
    // until we have a better way to handle this. Useinga feature flag
    // for now. Uing the feat__payment_outage_notice feature flag
    isPaymentOutageNoticeActive: getIsFeatureEnabled(state, FEATURES.PAYMENT_OUTAGE_NOTICE),
    isMaxOf12PaymentsActive: getIsFeatureEnabled(state, FEATURES.SPLITIT_MAX_INSTALLMENTS_12MO_ENABLED)
  }
}

export default connect(mapStateToProps, mapDispatchToActions)(SplititModalV2)
