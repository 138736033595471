import { applicationFee } from 'resource_context/shared/application_fee'
import { getAccountHolderRole } from 'reducers/selectors'

import {
  assetsWorksheetUrl,
  buildEligibility,
  buildPublicChargeEstimatorProto,
  buildBeneficiary,
  buildJointSponsor,
  buildMiscDetails,
  buildPublicChargeContext,
  buildSponsor,
  buildSpouse,
  getMonthAndYearFiveYearsAgo,
  lastThreeTaxYears,
  relativeLastThreeTaxYears,
  minimumIncomeRequirement,
  mostRecentTaxYear,
  mostRecentTaxYearForPublicCharge,
  ResourceContextBeneficiary,
  ResourceContextSponsor,
  ResourcePublicChargeContext,
  ResourceContextMiscDetails
} from './helpers'

import { ApplicationState } from 'reducers'
import { ResourceContextPerson } from 'resource_context/shared/build_person'
import { TranslateOptions } from 'resource_context/index'

export interface ResourceContextMBGCSpouse {
  name?: Nullable<string>
}

interface MBGCResourceContext {
  accountHolderRole: string
  applicationFee: string
  assetsWorksheetUrl: string
  beneficiary: ResourceContextBeneficiary
  context: 'beneficiary' | 'sponsor'
  joint_sponsor: ResourceContextPerson
  last_three_tax_years: string[]
  relative_last_three_tax_years: string[]
  minimum_income_requirement: string
  month_and_year_five_years_ago: string
  mostRecentTaxYear: number
  mostRecentTaxYearForPublicCharge: number
  parentAtIndex: string
  sponsor: ResourceContextSponsor
  spouse: ResourceContextMBGCSpouse
  eligibility: any // ResourceContextEligibility <- this is not a thing
  publicChargeEstimatorProto: any // ResourceContextPublicChargeEstimatorProto <- this is not a thing
  publicChargeContext: ResourcePublicChargeContext
  miscDetails: ResourceContextMiscDetails
}

export default function buildMarriageBasedGreenCardResourceContext(
  state: ApplicationState,
  options: TranslateOptions
): MBGCResourceContext {
  const parents = ['mother', 'father']
  const opts = options || {}

  return {
    accountHolderRole: getAccountHolderRole(state) || 'sponsor',
    applicationFee: applicationFee(state),
    assetsWorksheetUrl: assetsWorksheetUrl(state),
    beneficiary: buildBeneficiary(state),
    context: getAccountHolderRole(state) || 'sponsor',
    eligibility: buildEligibility(state),
    publicChargeEstimatorProto: buildPublicChargeEstimatorProto(state),
    joint_sponsor: buildJointSponsor(),
    last_three_tax_years: lastThreeTaxYears(),
    relative_last_three_tax_years: relativeLastThreeTaxYears(state),
    minimum_income_requirement: minimumIncomeRequirement(state),
    mostRecentTaxYear: mostRecentTaxYear(),
    mostRecentTaxYearForPublicCharge: mostRecentTaxYearForPublicCharge(),
    publicChargeContext: buildPublicChargeContext(state),
    month_and_year_five_years_ago: getMonthAndYearFiveYearsAgo(),
    parentAtIndex: parents[opts.index || 0],
    sponsor: buildSponsor(state),
    spouse: buildSpouse(state),
    miscDetails: buildMiscDetails(state)
  }
}
