import _capitalize from 'lodash/capitalize'

import { ModelDataPerson } from 'reducers/model'

import { Role } from 'components/forms/inputs/index'

type GenderPronoun = 'he' | 'she' | 'they'
type GenderPossessivePronoun = 'his' | 'her' | 'their'

interface InterrogativeClauses {
  secondPersonPresent: string
  secondPersonPresentContinuous: string
  secondPersonPresentPerfect: string
  thirdPersonPresent: string
  thirdPersonPresentContinuous: string
  thirdPersonPresentPerfect: string
}

interface NeutralPronouns {
  secondPerson: string
  thirdPerson: string
}

export interface ResourceContextPerson {
  name: string
  name_cap: string
  gender_pronoun: GenderPronoun
  gender_pronoun_cap: string
  gender_possessive_pronoun: GenderPossessivePronoun
  gender_possessive_pronoun_cap: string
}

function genderPronoun(person: ModelDataPerson): GenderPronoun {
  switch (person.gender) {
    case 'male':
      return 'he'
    case 'female':
      return 'she'
    default:
      return 'they'
  }
}

function genderPossessivePronoun(person: ModelDataPerson): GenderPossessivePronoun {
  switch (person.gender) {
    case 'male':
      return 'his'
    case 'female':
      return 'her'
    default:
      return 'their'
  }
}

export function getInterrogativeClauses(person: ModelDataPerson): InterrogativeClauses {
  const name = person.name.first_name

  return {
    secondPersonPresent: 'Do you',
    secondPersonPresentContinuous: 'Are you',
    secondPersonPresentPerfect: 'Have you',
    thirdPersonPresent: name ? `Does ${name}` : 'Does your spouse',
    thirdPersonPresentContinuous: name ? `Is ${name}` : 'Is your spouse',
    thirdPersonPresentPerfect: name ? `Has ${name}` : 'Has your spouse'
  }
}

export function getNeutralPronouns(person: ModelDataPerson): NeutralPronouns {
  const name = person.name.first_name

  return {
    secondPerson: 'you',
    thirdPerson: name ? `${name}` : 'your spouse'
  }
}

export function buildPerson(person: ModelDataPerson, role: Role): ResourceContextPerson {
  const name = person.name.first_name || role
  const pronoun = genderPronoun(person)
  const possessivePronoun = genderPossessivePronoun(person)

  return {
    name,
    name_cap: _capitalize(name),
    gender_pronoun: pronoun,
    gender_pronoun_cap: _capitalize(pronoun),
    gender_possessive_pronoun: possessivePronoun,
    gender_possessive_pronoun_cap: _capitalize(possessivePronoun)
  }
}
