import React, { FunctionComponent, useMemo } from 'react'
import cx from 'classnames'
import HelpIcon from 'components/icons/help_icon'
import Heading from 'components/type/heading'
import CheckmarkIcon from 'components/icons/checkmark_icon'
import CommentIcon from 'components/icons/comment_icon'

interface Props {
  hasCustomerResponses?: boolean
  customerResponded?: boolean
}

const IssueCardHeader: FunctionComponent<Props> = ({ hasCustomerResponses, customerResponded }) => {
  const getTitle = () => {
    if (hasCustomerResponses) {
      if (customerResponded) {
        return {
          icon: <CheckmarkIcon />,
          title: 'Replied'
        }
      }

      return {
        icon: <CommentIcon />,
        title: 'New Message'
      }
    } else {
      return {
        icon: <HelpIcon />,
        title: 'Reply Required'
      }
    }
  }

  const { icon, title } = useMemo(getTitle, [hasCustomerResponses, customerResponded])

  const classes = cx('p-2 border', {
    'border-gray-200 bg-gray-100': !hasCustomerResponses && !customerResponded,
    'border-success-300 bg-success-200 text-success-600': hasCustomerResponses && customerResponded,
    'border-warning-300 bg-warning-200 text-warning-600': hasCustomerResponses && !customerResponded
  })

  return (
    <div className={classes}>
      <Heading tag="h3" size="md" spacing="xs" subhead className="mb-1">
        <div className="flex items-center">
          {icon}
          <span className="ml-1 pt-1">{title}</span>
        </div>
      </Heading>
    </div>
  )
}

export default IssueCardHeader
