import React from 'react'
import { Provider } from 'react-redux'
import { Fragment } from 'redux-little-router'
import getStore from 'stores/app_store'
import Navbar from 'components/navbar'
function NavbarRoot() {
  return (
    <Provider store={getStore()}>
      <Navbar />
    </Provider>
  )
}
export default NavbarRoot
