import React from 'react'

import panelLocaleService from '../../../../services/panel_locale_service'
import ValidatedInput from '../../validated_input'

import { QuestionnaireInputContainerProps } from '../'

import { joinI18nKeys } from 'lib/i18n'
import { getAfterChangeHooks } from 'components/forms/field_after_change_events'

import DateInput from './date_input'

class DateInputContainer extends React.Component<QuestionnaireInputContainerProps> {
  render() {
    const { disabled, id, name, path, resourceKeys } = this.props

    const afterChangeEvents = getAfterChangeHooks(this.props.after_change_event_list)

    return (
      <div className="o-flag o-grid--fluid o-grid--stack@sm o-block c-paper-form__group">
        <label
          className="o-grid__cell--3/12 o-grid__cell--off@sm c-paper-form__label c-paper-form__segment"
          htmlFor={id}
        >
          <span className="c-type c-type--subhead-sm">
            {panelLocaleService.t(joinI18nKeys(resourceKeys, `${name}.label`))}
          </span>
        </label>

        <div className="o-grid__cell--9/12 o-grid__cell--off@sm c-paper-form__segment self-end">
          <ValidatedInput disabled={disabled} path={path}>
            {({ fireValidation, getValidationClasses, renderValidations }) => (
              <React.Fragment>
                <DateInput
                  afterChangeEvents={afterChangeEvents}
                  className={`c-paper-form__control ${getValidationClasses()}`}
                  disabled={disabled}
                  name={name}
                  onBlur={fireValidation}
                  path={path}
                  id={id}
                />

                {renderValidations()}
              </React.Fragment>
            )}
          </ValidatedInput>
        </div>
      </div>
    )
  }
}

export default DateInputContainer
