import React, { FunctionComponent, ReactNode } from 'react'
import Heading from 'components/type/heading'
import Aside from 'components/aside'
import CloseIcon from 'components/icons/close_icon'
import Button from 'components/button'

interface ExplicitProps {
  children: ReactNode
  closePanel: () => void
  headerContent?: string
  showPanel: boolean
}

const AsidePanel: FunctionComponent<ExplicitProps> = ({ children, closePanel, headerContent, showPanel }) => {
  return (
    <Aside isOpen={showPanel} onRequestClose={closePanel}>
      <div className="flex items-center justify-between md:p-4 pl-6 border-b border-gray-200">
        <Heading tag="h3" color="emphasized" block={false} subhead>
          {headerContent}
        </Heading>
        <Button color="link" icon={<CloseIcon />} onClick={closePanel} />
      </div>
      {children}
    </Aside>
  )
}

export default AsidePanel as FunctionComponent<ExplicitProps>
