import React, { FunctionComponent } from 'react'

import Heading from 'components/type/heading'
import Paragraph from 'components/type/paragraph'
import ArrowIcon from 'components/icons/arrow_icon'
import GreenCheckmarkIcon from 'components/icons/green_circle_checkmark_icon'
import ExternalLink from 'components/external_link'
import BoundlessRVLogo from 'images/rvpp_logo@2x.png'

const rapidVisaDetails = [
  'Live chat and phone support 7 days a week',
  'Thorough application review for the best chance of success',
  'Guaranteed money back if your application is denied',
  'Application filed with the U.S. government on your behalf (available with Premium Review)'
]

const NaturalizationEligible: FunctionComponent = () => {
  const rapidVisaListItems = () => {
    return rapidVisaDetails.map((detail, index) => (
      <li className="mb-3" key={`rapid-visa-detail-${index}`}>
        <div className="o-griddle__row">
          <div className="o-griddle__col--1">
            <GreenCheckmarkIcon size="small" />
          </div>
          <Paragraph
            size="sm"
            spacing="none"
            className="o-griddle__col flex items-center pt-0"
            style={{ textAlign: 'left' }}
          >
            {detail}
          </Paragraph>
        </div>
      </li>
    ))
  }

  return (
    <div className="c-context-panel c-context-panel--important">
      <div className="o-grid__row">
        <div className="o-grid__col-3 sm:mt-5">
          <div className="c-context-panel__image c-context-panel__image--setup-completed" />
        </div>

        <div className="o-grid__col-5 sm:mt-5">
          <div className="o-grid__row">
            <div className="o-grid__col-6 c-context-panel__copy" style={{ textAlign: 'left' }}>
              <Heading color="emphasized" tag="h1" subhead spacing="lg">
                <span style={{ position: 'relative' }}>YOU’RE ELIGIBLE TO APPLY FOR CITIZENSHIP!</span>
                <hr className="o-block o-block--ample c-divider c-divider--narrow c-divider--secondary c-divider--emphasized" />
              </Heading>
              <Heading size="lg" tag="h2" color="emphasized" spacing="lg">
                Start your application for free with our trusted partner RapidVisa
              </Heading>
              <ExternalLink
                className="c-btn mb-12 c-btn--emphasized md:w-auto sm:w-full"
                href="https://rapidvisa.com/citizenship/?utm_source=boundless&utm_medium=referral&utm_campaign=naturalization"
                trackLinkName="NatzEligibleGoToRapidVisa"
              >
                Go To Rapidvisa
                <span className="c-btn__icon">
                  <ArrowIcon arrowDirection="right" />
                </span>
              </ExternalLink>
              <span className="c-divider c-divider--gray-200" />
              <div className="o-griddle__row mt-12 ">
                <div className="o-griddle__col--12 o-griddle__col--md-6 mb-8">
                  <img src={BoundlessRVLogo} alt="RapidVisa Boundless logo" style={{ width: 180 }} className="mb-5" />
                  <Paragraph size="sm" className="ml-2">
                    We’ve joined forces — Boundless plus RapidVisa means you get the fastest and best naturalization
                    service — all starting at just $249.
                  </Paragraph>
                </div>
                <div className="o-griddle__col--12 o-griddle__col--md-6">
                  <Heading tag="h3" color="emphasized" subhead>
                    What's included
                  </Heading>
                  <ul className="c-list">{rapidVisaListItems()}</ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NaturalizationEligible as FunctionComponent
