import { LOAD_CURRENT_USER } from 'lib/constants'

export function loadCurrentUser(user) {
  return (dispatch) => {
    dispatch({
      type: LOAD_CURRENT_USER,
      currentUser: user
    })
  }
}
