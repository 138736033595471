import server from './server'

async function push(): Promise {
  const url = '/api/admin/document_request_inventory'

  return server.post(url)
}

export default {
  push
}
