import _forEachRight from 'lodash/forEachRight'

export function dollarsWithCommas(dollars: string | number): string {
  dollars = dollars.toString()

  let dollarsWithCommas = ''
  let count = -1

  _forEachRight(dollars.split(''), (char) => {
    if (++count % 3 === 0 && count) {
      dollarsWithCommas = `${char},${dollarsWithCommas}`
    } else {
      dollarsWithCommas = `${char}${dollarsWithCommas}`
    }
  })

  return dollarsWithCommas
}

export default function formatMoney(amountInCents: number, ceiling = false): string {
  if (!amountInCents) return '$0'

  if (amountInCents < 100) return `¢${amountInCents}`

  const centsForRounding = ceiling ? amountInCents + 99 : amountInCents
  const stringCents = centsForRounding.toString()
  let dollars = stringCents.substring(0, stringCents.length - 2)

  return `$${dollarsWithCommas(dollars)}`
}
