import React, { FunctionComponent } from 'react'

import Paragraph from 'components/type/paragraph'

const DocumentUploadPanel: FunctionComponent = () => {
  return (
    <aside className="c-dashboard__panel">
      <header className="o-block o-block--compact c-type c-type--headline-sm c-type--emphasized">Your Documents</header>
      <hr className="o-block c-divider" />
      <Paragraph>
        <a href="/document_upload">Upload Documents</a>
      </Paragraph>
    </aside>
  )
}

export default DocumentUploadPanel
