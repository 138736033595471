import React, { FunctionComponent } from 'react'
import IconWrapper, { IconWrapperExternalProps } from './icon_wrapper'

const CommentIcon: FunctionComponent<IconWrapperExternalProps> = (props) => (
  <IconWrapper {...props} viewBox="0 0 32 32">
    <path d="M26.143 0.781v30.527l-8.262-5.68h-16.585v-24.847h24.847zM24.368 2.556h-21.297v21.297h15.361l5.936 4.081v-25.379zM20.449 17.452v1.524h-13.469v-1.524h13.469zM20.449 12.587v1.524h-13.469v-1.524h13.469zM20.449 7.722v1.524h-13.469v-1.524h13.469z" />
  </IconWrapper>
)

export default CommentIcon
