import _some from 'lodash/some'
import { VALIDATING_PATH, VALIDATED_PATH } from 'lib/constants'

import { getActiveValidationPaths, getModelData, getValidationConfig } from 'reducers/selectors'

import { getValidationResults, getValidationResultsSync } from 'lib/validations'
import { getValidatorsAtPath } from 'lib/validations/validators'

function validatingPath(path) {
  return (dispatch) => {
    dispatch({
      type: VALIDATING_PATH,
      path
    })
  }
}

export function validatePath(path) {
  return (dispatch, getState) => {
    const state = getState()
    const data = getModelData(state)
    const validationConfig = getValidationConfig(state)

    dispatch(validatingPath(path))

    getValidationResults(path, { data, validationConfig }).then((validation) =>
      dispatch({
        type: VALIDATED_PATH,
        path,
        validation
      })
    )
  }
}

export function validatePathSync(path) {
  return (dispatch, getState) => {
    const state = getState()
    const data = getModelData(state)
    const validationConfig = getValidationConfig(state)

    dispatch(validatingPath(path))

    const validation = getValidationResultsSync(path, {
      data,
      validationConfig
    })

    dispatch({
      type: VALIDATED_PATH,
      path,
      validation
    })
  }
}

function requiresAsyncValidation(validators) {
  return _some(validators, 'async')
}

export function noValidationsAtPath(state, path) {
  const validationConfig = getValidationConfig(state)
  const validators = getValidatorsAtPath(path, validationConfig) || []

  return validators.length === 0
}

export function validatePaths(paths) {
  return (dispatch, getState) => {
    const state = getState()
    const validationConfig = getValidationConfig(state)

    paths.forEach((path) => {
      const validators = getValidatorsAtPath(path, validationConfig)
      const validationAction = requiresAsyncValidation(validators) ? validatePath : validatePathSync

      dispatch(validationAction(path))
    })
  }
}

export function revalidate() {
  return (dispatch, getState) => {
    const activePaths = getActiveValidationPaths(getState())

    activePaths.forEach((path) => dispatch(validatePath(path)))
  }
}
