import { buildMessage, Validator } from './'

const requiredIfLiabilityExists: Validator = ({ getModelData, path }) => {
  const currentValue = getModelData(path)
  const liabilityExists = getModelData('beneficiary.has_debts_or_liabilities')

  if (liabilityExists && currentValue == null) {
    return [buildMessage('Please add liabilities')]
  }

  return []
}

export default requiredIfLiabilityExists
