import server from './server'
import { AxiosPromise } from 'axios'

interface CreateParams {
  filename: string
  size: number
  s3_direct_path: string
}

function create(kaseId: number, params: CreateParams): AxiosPromise {
  const url = `/admin/kases/${kaseId}/stored_files`

  return server.post(url, { stored_file: params })
}

export default { create }
