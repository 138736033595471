import React, { FunctionComponent } from 'react'
import classNames from 'classnames'

// Properties that can be added to icons when they're consumed
export type IconWrapperExternalProps = React.SVGAttributes<SVGSVGElement> & {
  className?: string
  size?: 'small' | 'medium' | 'large' | 'xl' | 'default' | 'klarna' | 'xsm'
  alt?: string
}

/**
 * Properties that can be added _to the wrapper_ from the icons.
 * This forces users to add a viewBox in icon definitions.
 */
type IconWrapperProps = IconWrapperExternalProps & {
  viewBox: string
}

const IconWrapper: FunctionComponent<IconWrapperProps> = ({
  className,
  children,
  size,
  viewBox,
  alt,
  ...otherProps
}) => {
  const svgClasses = classNames('c-icon__glyph', {
    'c-icon--body-sm': size === 'small',
    'c-icon--md': size === 'medium',
    'c-icon--lg': size === 'large',
    'c-icon--xl': size === 'xl',
    'c-icon--klarna': size === 'klarna',
    'c-icon--xsm': size === 'xsm'
  })

  return (
    <span className={classNames('c-icon', className)}>
      <svg {...otherProps} className={svgClasses} xmlns="http://www.w3.org/2000/svg" viewBox={viewBox} role="img">
        {alt && <title>{alt}</title>}
        {children}
      </svg>
    </span>
  )
}

IconWrapper.defaultProps = {
  size: 'default'
}

export default IconWrapper
