import React, { FunctionComponent, useEffect, useRef } from 'react'
import cx from 'classnames'
import CheckmarkIcon from 'components/icons/checkmark_icon'
import LocationIcon from 'components/icons/location_icon'
import { KasePhase } from './types'

interface Props {
  phase: KasePhase
  isPastStep?: boolean
  isCurrentStep?: boolean
  isFutureStep?: boolean
  isPreviewedStep?: boolean
  showCPStyle?: boolean
  setCurrentPhaseElem?: (currentPhaseElem: HTMLButtonElement) => void
  onPhaseClicked?: (phase: KasePhase) => void
}

const TimelineStep: FunctionComponent<Props> = ({
  phase,
  isPastStep,
  isCurrentStep,
  isFutureStep,
  isPreviewedStep,
  showCPStyle,
  setCurrentPhaseElem,
  onPhaseClicked
}) => {
  const phaseRef = useRef(null)
  const classes = {
    button: cx('c-timeline__step', {
      'c-timeline__step--past': isPastStep,
      'c-timeline__step--present': isCurrentStep,
      'c-timeline__step--future': isFutureStep,
      'c-timeline__step--preview': isPreviewedStep,
      'c-timeline__step--cp': showCPStyle,
      'c-timeline__step--md': phase.name === 'PREPARE_TO_APPLY',
      'c-timeline__step--lg': phase.name === 'FINALIZE_AND_SHIP_PACKAGE'
    }),
    name: cx('c-timeline__step-name', {
      'c-timeline__step-name--cp': showCPStyle
    })
  }

  const handlePhaseClicked = () => {
    onPhaseClicked(phase)
  }

  let buttonTitle = ''
  if (isFutureStep) {
    buttonTitle = 'Preview this future step'
  } else if (isCurrentStep) {
    buttonTitle = 'View the current step'
  }

  useEffect(() => {
    if (phase.active && phase.index !== 0) {
      setCurrentPhaseElem(phaseRef.current)
    }
  }, [])

  return (
    <button
      disabled={isPastStep}
      className={classes.button}
      onClick={handlePhaseClicked}
      title={buttonTitle}
      type="button"
      ref={phaseRef}
    >
      <div className="c-timeline__step-content">
        {isPastStep && <CheckmarkIcon className="mr-2" aria-hidden="true" />}
        {isCurrentStep && <LocationIcon className="mr-2" aria-hidden="true" />}
        <span className={classes.name} style={{ textAlign: isFutureStep ? 'center' : 'left' }}>
          {phase.display_name}
        </span>
      </div>
    </button>
  )
}

export default TimelineStep
