import React, { FunctionComponent } from 'react'
import IconWrapper, { IconWrapperExternalProps } from './icon_wrapper'
import classNames from 'classnames'

// NOTE: This is the official pay mark icon from Google's brand website
// but we trimmed the edges off so that it would be of comparable size to the Apple pay mark icon
// https://developers.google.com/pay/api/web/guides/brand-guidelines
const GooglePayMarkIcon: FunctionComponent<IconWrapperExternalProps> = (props) => {
  const className = classNames(props.className, 'c-icon--payment')

  return (
    <IconWrapper {...props} className={className} width="752" height="400" viewBox="0 0 752 400" fill="none">
      <path
        d="M552 0H200C90 0 0 90 0 200C0 310 90 400 200 400H552C662 400 752 310 752 200C752 90 662 0 552 0Z"
        fill="white"
      />
      <path
        d="M552 16.2C576.7 16.2 600.7 21.1 623.3 30.7C645.2 40 664.8 53.3 681.8 70.2C698.7 87.1 712 106.8 721.3 128.7C730.9 151.3 735.8 175.3 735.8 200C735.8 224.7 730.9 248.7 721.3 271.3C712 293.2 698.7 312.8 681.8 329.8C664.9 346.7 645.2 360 623.3 369.3C600.7 378.9 576.7 383.8 552 383.8H200C175.3 383.8 151.3 378.9 128.7 369.3C106.8 360 87.2 346.7 70.2 329.8C53.3 312.9 40 293.2 30.7 271.3C21.1 248.7 16.2 224.7 16.2 200C16.2 175.3 21.1 151.3 30.7 128.7C40 106.8 53.3 87.2 70.2 70.2C87.1 53.3 106.8 40 128.7 30.7C151.3 21.1 175.3 16.2 200 16.2H552ZM552 0H200C90 0 0 90 0 200C0 310 90 400 200 400H552C662 400 752 310 752 200C752 90 662 0 552 0Z"
        fill="#3C4043"
      />
      <path
        d="M358.6 214.201V274.701H339.4V125.301H390.3C403.2 125.301 414.2 129.601 423.2 138.201C432.4 146.801 437 157.301 437 169.701C437 182.401 432.4 192.901 423.2 201.401C414.3 209.901 403.3 214.101 390.3 214.101H358.6V214.201ZM358.6 143.701V195.801H390.7C398.3 195.801 404.7 193.201 409.7 188.101C414.8 183.001 417.4 176.801 417.4 169.801C417.4 162.901 414.8 156.801 409.7 151.701C404.7 146.401 398.4 143.801 390.7 143.801H358.6V143.701Z"
        fill="#3C4043"
      />
      <path
        d="M487.2 169.102C501.4 169.102 512.6 172.902 520.8 180.502C529 188.102 533.1 198.502 533.1 211.702V274.702H514.8V260.502H514C506.1 272.202 495.5 278.002 482.3 278.002C471 278.002 461.6 274.702 454 268.002C446.4 261.302 442.6 253.002 442.6 243.002C442.6 232.402 446.6 224.002 454.6 217.802C462.6 211.502 473.3 208.402 486.6 208.402C498 208.402 507.4 210.502 514.7 214.702V210.302C514.7 203.602 512.1 198.002 506.8 193.302C501.5 188.602 495.3 186.302 488.2 186.302C477.5 186.302 469 190.802 462.8 199.902L445.9 189.302C455.2 175.802 469 169.102 487.2 169.102ZM462.4 243.302C462.4 248.302 464.5 252.502 468.8 255.802C473 259.102 478 260.802 483.7 260.802C491.8 260.802 499 257.802 505.3 251.802C511.6 245.802 514.8 238.802 514.8 230.702C508.8 226.002 500.5 223.602 489.8 223.602C482 223.602 475.5 225.502 470.3 229.202C465 233.102 462.4 237.802 462.4 243.302Z"
        fill="#3C4043"
      />
      <path
        d="M637.5 172.4L573.5 319.6H553.7L577.5 268.1L535.3 172.4H556.2L586.6 245.8H587L616.6 172.4H637.5Z"
        fill="#3C4043"
      />
      <path
        d="M282.23 202C282.23 195.74 281.67 189.75 280.63 183.99H200.15V216.99L246.5 217C244.62 227.98 238.57 237.34 229.3 243.58V264.99H256.89C273 250.08 282.23 228.04 282.23 202Z"
        fill="#4285F4"
      />
      <path
        d="M229.31 243.58C221.63 248.76 211.74 251.79 200.17 251.79C177.82 251.79 158.86 236.73 152.07 216.43H123.61V238.51C137.71 266.49 166.69 285.69 200.17 285.69C223.31 285.69 242.75 278.08 256.9 264.98L229.31 243.58Z"
        fill="#34A853"
      />
      <path
        d="M149.39 200.05C149.39 194.35 150.34 188.84 152.07 183.66V161.58H123.61C117.78 173.15 114.5 186.21 114.5 200.05C114.5 213.89 117.79 226.95 123.61 238.52L152.07 216.44C150.34 211.26 149.39 205.75 149.39 200.05Z"
        fill="#FABB05"
      />
      <path
        d="M200.17 148.3C212.8 148.3 224.11 152.65 233.04 161.15L257.49 136.72C242.64 122.89 223.28 114.4 200.17 114.4C166.7 114.4 137.71 133.6 123.61 161.58L152.07 183.66C158.86 163.36 177.82 148.3 200.17 148.3Z"
        fill="#E94235"
      />
    </IconWrapper>
  )
}

export default GooglePayMarkIcon
