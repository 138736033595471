import React, { Component } from 'react'
import { connect } from 'react-redux'
import { animateScroll as scroll } from 'react-scroll'

import { showPanel } from 'actions/panel_actions'
import { openModal } from 'actions/modal_actions'
import { openChat } from 'actions/chat_actions'
import { toggleContextualHelp } from 'actions/help_actions'
import { postTelemetry } from 'actions/telemetry_actions'
import { TELEMETRY_EVENTS } from 'lib/constants'

import {
  getCurrentKaseId,
  getCurrentKaseKindString,
  getCurrentPanel,
  getTimelinePageUrl,
  isCurrentKasePaid,
  isOnPetitionSection,
  isOnPublicChargeEstimatorSection,
  isOnSetupSection,
  isSidebarHelpOpen,
  isUserLoggedIn
} from 'reducers/selectors'

import HelpIcon from './icons/help_icon'
import SidebarHelp from './sidebar_help'
import Fee from './fee'
import SaveProgressButton from './save_progress_button'
import AskIcon from './icons/ask_icon'
import Button from 'components/button'
import CloseIcon from './icons/close_icon'
import EmailIcon from './icons/email_icon'
import PhoneIcon from './icons/phone_icon'

interface Props {
  currentKaseKindString: string
  currentPanel?: any
  isOnPetitionSection: boolean
  isOnPublicChargeEstimatorSection: boolean
  isOnSetupSection: boolean
  isOnV2SetupSection?: boolean
  isPaidUser: boolean
  kaseId: number
  open: boolean
  openChat: (args: { via: string }) => void
  openModal: (args: { name: string }) => void
  showPanel: (args: { slug: string; via: string }) => void
  timelinePageUrl: string
  toggleContextualHelp: (args: { via: string }) => void
  trackLaunchedDocuments?: (args: { questionName: string }) => void
  userLoggedIn: boolean
  contextualHelpV2?: any
}

class ToolMenu extends Component<Props> {
  handleSupportMessageClick = (event) => {
    event.preventDefault()
    event.nativeEvent.stopImmediatePropagation()

    this.props.openChat({ via: 'ToolMenu' })
  }

  toggleHelp() {
    return (event) => {
      event.preventDefault()
      this.props.toggleContextualHelp({ via: 'ToolMenu' })
      document.activeElement.blur()
    }
  }

  renderHelpButton() {
    return (
      <li className="tool-menu-item" key="menu-help-link">
        <a
          data-qa="sidebar-help-button"
          href="#help"
          className="o-media o-media--tight o-box o-box--tight tool-menu-item-link"
          onClick={this.toggleHelp()}
        >
          <HelpIcon className="o-media__figure tool-menu-item-icon" aria-hidden="true" />

          <span className="o-media__body c-type c-type--subhead-sm">Help</span>

          {this.props.open && <CloseIcon className="tool-menu-item-icon tool-menu-item-icon--right" />}
        </a>
      </li>
    )
  }

  paymentFunnel = () => {
    this.props.showPanel({
      slug: 'boundless-overview',
      via: 'ReviewAndFinish'
    })
    scroll.scrollToTop({ duration: 400, delay: 0 })
  }

  renderPaymentFunnelButton() {
    return this.props.userLoggedIn && this.renderConditionalPaymentFunnelButton()
  }

  renderConditionalK1PaymentButton() {
    if (this.props.userLoggedIn && !this.props.isPaidUser && this.props.currentKaseKindString === 'k1 fiance visa') {
      return (
        <a href="summary-your-payment-options" className="o-block c-btn c-btn--block c-btn--primary">
          See Payment Options
        </a>
      )
    }
  }

  renderConditionalPaymentFunnelButton() {
    if (this.props.isPaidUser || this.props.currentKaseKindString === 'k1 fiance visa') {
      return (
        <a href={this.props.timelinePageUrl} className="o-block c-btn c-btn--block c-btn--secondary">
          Return to Dashboard
        </a>
      )
    }

    if (!this.props.isOnPetitionSection) {
      return <div />
    }

    return (
      <div>
        <Button
          color="secondary"
          className="mb-3"
          onClick={this.paymentFunnel}
          id="sb-r-cta"
          block
          label="Apply With Lawyer Support"
        />
        <span className="o-block c-type c-type--body-sans-sm">
          We’ll guide you through completing your application, provide independent lawyer support, then print and ship
          it to your doorstep — for just <Fee />.
        </span>
      </div>
    )
  }

  isHidden() {
    return Boolean(this.props.currentPanel && this.props.currentPanel.emphasized)
  }

  render() {
    if (this.isHidden()) return null

    const contactEmail = this.props.isPaidUser ? 'support.team' : 'help'

    return (
      <div className="tool-menu">
        <ul className="tool-menu-top o-list-bare pr-3">
          <li className="o-block o-block--ample">
            <div className="o-grid__row tool-menu-bottom">
              <div className="o-grid__col-3">
                {this.renderConditionalK1PaymentButton()}
                {this.renderPaymentFunnelButton()}
              </div>
            </div>
          </li>
          <li className="o-block o-block--ample pr-4">
            <ul className="tool-menu-sublist o-list-bare">
              <li className="tool-menu-sublist-header">
                <span className="o-block o-block--tight c-type c-type--body-sans-sm c-type--emphasized">
                  Have Questions?
                </span>
              </li>
              <li className="tool-menu-item">
                <ul className="tool-menu-sublist o-list-bare">
                  {this.renderHelpButton()}
                  <li>
                    <SidebarHelp contextualHelpV2={this.props.contextualHelpV2} />
                  </li>
                </ul>
              </li>

              <li className="tool-menu-item">
                <a
                  href="#support-message"
                  className="o-media o-media--tight o-box o-box--tight tool-menu-item-link"
                  onClick={this.handleSupportMessageClick}
                >
                  <AskIcon className="o-media__figure tool-menu-item-icon" aria-hidden="true" />

                  <span className="o-media__body c-type c-type--subhead-sm">Chat With Us</span>
                </a>
              </li>

              <li className="tool-menu-item">
                <a
                  href={`mailto:${contactEmail}@boundless.com`}
                  className="o-media o-media--tight o-box o-box--tight tool-menu-item-link"
                >
                  <EmailIcon className="o-media__figure tool-menu-item-icon" aria-hidden="true" />

                  <span className="o-media__body c-type c-type--subhead-sm">Email {contactEmail}@boundless.com</span>
                </a>
              </li>

              <li className="tool-menu-item">
                <a href="tel:1-855-268-6353" className="o-media o-media--tight o-box o-box--tight tool-menu-item-link">
                  <PhoneIcon className="o-media__figure tool-menu-item-icon" aria-hidden="true" />

                  <span className="o-media__body c-type c-type--subhead-sm">Call 1-855-268-6353</span>
                </a>
              </li>
            </ul>
          </li>
        </ul>
        <SaveProgressButton isOnV2SetupSection={this.props.isOnV2SetupSection} />
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    currentKaseKindString: getCurrentKaseKindString(state),
    currentPanel: getCurrentPanel(state),
    isOnPetitionSection: isOnPetitionSection(state),
    isOnPublicChargeEstimatorSection: isOnPublicChargeEstimatorSection(state),
    isOnSetupSection: isOnSetupSection(state),
    isPaidUser: isCurrentKasePaid(state),
    kaseId: getCurrentKaseId(state),
    open: isSidebarHelpOpen(state),
    timelinePageUrl: getTimelinePageUrl(state),
    userLoggedIn: isUserLoggedIn(state)
  }
}

function mapDispatchToActions(dispatch) {
  return {
    openModal: (...args) => dispatch(openModal(...args)),
    openChat: (...args) => dispatch(openChat(...args)),
    showPanel: (...args) => dispatch(showPanel(...args)),
    toggleContextualHelp: (...args) => dispatch(toggleContextualHelp(...args)),
    trackLaunchedDocuments: (args) =>
      dispatch(
        postTelemetry(TELEMETRY_EVENTS.LAUNCHED_DOCUMENTS, {
          QuestionName: args.questionName,
          Path: window.location.pathname
        })
      )
  }
}

export default connect(mapStateToProps, mapDispatchToActions)(ToolMenu)
