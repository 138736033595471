import React from 'react'

import { QuestionnaireInputContainerProps } from '../'

import { joinI18nKeys } from 'lib/i18n'
import panelLocaleService from 'services/panel_locale_service'
import CurrencyInput from './currency_input'
import ValidatedInput from 'components/forms/validated_input'
import { getAfterChangeHooks } from 'components/forms/field_after_change_events'

type Props = QuestionnaireInputContainerProps

class Currency extends React.Component<Props> {
  render() {
    const { disabled, id, name, path, placeholder, resourceKeys, after_change_event_list } = this.props

    const afterChangeEvents = after_change_event_list && getAfterChangeHooks(after_change_event_list)

    return (
      <div className="o-flag o-grid--fluid o-grid--stack@sm o-block c-paper-form__group">
        <label
          className="o-grid__cell--3/12 o-grid__cell--off@sm c-paper-form__label c-paper-form__segment"
          htmlFor={id}
        >
          <span className="c-type c-type--subhead-sm">
            {panelLocaleService.t(joinI18nKeys(resourceKeys, `${name}.label`))}
          </span>
        </label>

        <div className="o-grid__cell--9/12 o-grid__cell--off@sm c-paper-form__segment">
          <ValidatedInput disabled={disabled} path={path}>
            {({ fireValidation, getValidationClasses, renderValidations }) => (
              <React.Fragment>
                <CurrencyInput
                  disabled={disabled}
                  id={id}
                  path={path}
                  placeholder={placeholder}
                  className={`c-paper-form__control ${getValidationClasses()}`}
                  onBlur={fireValidation}
                  afterChangeEvents={afterChangeEvents}
                />
                {renderValidations()}
              </React.Fragment>
            )}
          </ValidatedInput>
        </div>
      </div>
    )
  }
}

export default Currency
