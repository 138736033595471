import _flatten from 'lodash/flatten'
import _values from 'lodash/values'

export const getCurrentPdfFormName = (pdf) => pdf.formName

export const getPdfFormDataForField = (pdf, fieldName) => pdf.formData[fieldName]

export const getPdfSelectedFieldName = (pdf) => pdf.selectedFieldName

export const getPdfFormCurrentFieldEditHistory = (pdf, fieldName) => pdf.formEdits[fieldName] || []

export const getPdfFormCurrentFieldEditValue = (pdf, fieldName) => {
  const history = getPdfFormCurrentFieldEditHistory(pdf, fieldName)
  return history[0]
}

const getPdfCheckboxFieldsForPage = (pdf, pageIndex) =>
  _flatten(_values(pdf.checkboxFields)).filter((field) => field.pageIndex === pageIndex)

export const getPdfFormFieldsForPage = (pdf, pageIndex) =>
  _values(pdf.formFields[pageIndex]).concat(getPdfCheckboxFieldsForPage(pdf, pageIndex))

export const getPdfCheckboxFormFields = (pdf, fieldName) => pdf.checkboxFields[fieldName]

export const getPdfFormField = (pdf, fieldName) => {
  const checkboxFields = getPdfCheckboxFormFields(pdf, fieldName)

  if (checkboxFields) return checkboxFields[0]

  const pages = _values(pdf.formFields)

  for (let i = 0; i < pages.length; i++) {
    const field = pages[i][fieldName]

    if (field) return field
  }
}

export const getPdfFormEditFieldValue = (pdf) => pdf.formEditFieldValue

export const getPdfSelectedFormField = (pdf) => getPdfFormField(pdf, getPdfSelectedFieldName(pdf))
