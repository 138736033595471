import { AddressHistoryStore } from '../address_histories'

export const getKaseSponsorAddressHistories = (addressHistories: AddressHistoryStore) => {
  if (!addressHistories) return []
  return addressHistories.addressHistories.sponsor
}

export const getKaseBeneficiaryAddressHistories = (addressHistories: AddressHistoryStore) => {
  if (!addressHistories) return []
  return addressHistories.addressHistories.beneficiary
}

export const isLoadingAddressHistories = (addressHistories: AddressHistoryStore) => {
  if (!addressHistories) return true
  return addressHistories.loadingAddressHistories
}

export const getCurrentEditedAddressHistory = (addressHistories: AddressHistoryStore) => {
  if (!addressHistories) return null
  return addressHistories.currentAddressHistoryEdit
}
