import _includes from 'lodash/includes'
import _startsWith from 'lodash/startsWith'
import moment from 'moment'

import getStore from 'stores/app_store'
import { getCountryNameFromCode } from 'data/countries'
import { formatDate } from './formatter'
import { isPresent } from 'lib/presence'
import { getIndexForCurrentPanel, getModelValue, getCurrentPanelSlugIndex } from 'reducers/selectors'

import { ModelDataValue } from 'reducers/model'

// 0 - interpolation, e.g. "{{the.key}}"
// 1 - key name, e.g. "the.key"
interface MissingInterpolationValue {
  0: string
  1: string
  index: number
  input: string
}

function isAccessingData(interpolationKey: string): boolean {
  return _startsWith(interpolationKey, '$data.')
}

function getCurrentIndex(): number {
  return parseInt(getIndexForCurrentPanel(getStore().getState()), 10) - 1
}

function getParentIndex(): number {
  return parseInt(getCurrentPanelSlugIndex(getStore().getState()), 10) - 1
}

function filterOffsetDate(value: Date | string, operation: string, amount: string, format: string = 'MMMM Do, Y') {
  const [offsetAmount, offsetType] = amount.split(' ')
  const date = moment(value)

  const offsetDate = date[operation](offsetAmount, offsetType)

  return formatDate(offsetDate, format)
}

function filterIfPresent(value: ModelDataValue, presentValue: string, notPresentValue: string): string {
  const actualValue = value || value === 0 ? presentValue : notPresentValue

  if (actualValue === '$value') return value.toString()

  return actualValue.toString()
}

function specialKeywordValue(modelKey: string) {
  switch (modelKey) {
    case 'today': {
      return new Date()
    }
    default: {
      return null
    }
  }
}

function formatAddress(address: any): string {
  let street = `${address.street}`

  if (address.unit_type && address.unit_number) {
    street += ` ${address.unit_type} ${address.unit_number}`
  }

  const parts = [
    street,
    `${address.city}, ${address.province} ${address.postal_code}`,
    getCountryNameFromCode(address.country.code)
  ]

  return parts.join(', ')
}

const prefixedCountries = new Set(['US', 'GB'])

function formatCountry(country: any) {
  const prefix = prefixedCountries.has(country.code) ? 'the ' : ''

  return `${prefix}${getCountryNameFromCode(country.code)}`
}

function applyTypeFormat(value) {
  switch (value.type) {
    case 'Address': {
      return formatAddress(value)
    }

    case 'Country': {
      return formatCountry(value)
    }

    default: {
      return value
    }
  }
}

function filterMap(collection: ModelDataValue[], property: Nullable<string> = null) {
  const collectionFilter = property ? (collection) => collection[property] : (collection) => collection

  return collection.map(collectionFilter).map(applyTypeFormat).filter(isPresent).join(', ')
}

function filterValue(value: ModelDataValue, filterName: string, ...filterArgs: string[]) {
  if (filterName !== 'ifPresent' && !value) return value

  switch (filterName) {
    case 'offsetDate': {
      let [operation, amount, format] = filterArgs
      format = format || 'MMMM Do, Y'

      return filterOffsetDate(value as string, operation, amount, format)
    }
    case 'map': {
      return filterMap(value as ModelDataValue[], ...filterArgs)
    }
    case 'ifPresent': {
      const [presentValue, notPresentValue] = filterArgs
      return filterIfPresent(value, presentValue, notPresentValue)
    }
    default: {
      return value
    }
  }
}

function getModelValueFromI18nKey(interpolationKey: string): any {
  const state = getStore().getState()
  const keyParts = interpolationKey.split(/,\s*/)

  let modelKey = keyParts[0].replace(/^\$data\./, '')

  if (_includes(modelKey, '$index')) {
    modelKey = modelKey.replace('$index', getCurrentIndex().toString())
  }

  if (_includes(modelKey, '$parentIndex')) {
    modelKey = modelKey.replace('$parentIndex', getParentIndex().toString())
  }

  if (_includes(modelKey, '$previousIndex')) {
    const prevIndex = getCurrentIndex() - 1

    modelKey = modelKey.replace('$previousIndex', prevIndex.toString())
  }

  let value = getModelValue(state, modelKey) || specialKeywordValue(modelKey)

  if (keyParts[1]) {
    value = filterValue(value, keyParts[1], ...keyParts.slice(2))
  }

  if (process.env.NODE_ENV === 'development') {
    value = value || modelKey
  }

  return value
}

function applyFormat(value: any) {
  if (!value) return value

  if (value instanceof moment) return formatDate(value as Date)

  return applyTypeFormat(value)
}

const SPECIAL_KEYWORDS = ['today']
const keywordRegex = new RegExp(`^(${SPECIAL_KEYWORDS.join('|')})`)

function isSpecialKeyword(key: string) {
  return keywordRegex.test(key)
}

export function i18nMissingInterpolationHandler(_text: string, value: MissingInterpolationValue): string {
  const key = value[1]

  if (isAccessingData(key) || isSpecialKeyword(key)) {
    return applyFormat(getModelValueFromI18nKey(key))
  }

  return ''
}
