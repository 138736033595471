import React, { FunctionComponent, useState } from 'react'
import cx from 'classnames'

import ApplePayMarkIcon from 'components/icons/apple_pay_mark_icon'
import GooglePayMarkIcon from 'components/icons/google_pay_mark_icon'
import GreenCircleCheckmarkListIcon from 'components/icons/green_circle_checkmark_list_icon'
import HelpIcon from 'components/icons/help_icon'
import Payment, { Tier } from 'components/payment'
import { getIsFeatureEnabled } from 'reducers/features/selectors'

import formatMoney from 'lib/format_money'
import ContextualHelpModal from 'components/modals/modal/contextual_help_modal'
import CheckoutLineItems from './components/checkout_line_items'
import {FEATURES} from "lib/features";
import { connect } from 'react-redux'

/**
 * The reason these are called "final" is because at this step the fees may or may
 * not include USCIS fees based on what they selected in prior steps
 */
interface ExplicitProps {
  /**
   * The current kase process (AOS, CR1, K1, etc.)
   */
  currentKaseIsAOS: boolean
  /**
   * The total cost of all the itemized products combined
   */
  finalTotalFeesInCents: number
  /**
   * Used as a prop for the Payment component to include USCIS fees in the checkout session or not
   */
  includeUscisFees: boolean
  /**
   * Whether or not we're on mobile
   */
  isMobile?: boolean
  /**
   * The maximum number of months to pay
   */
  maxMonths: number
  /**
   * The minimum number of months to pay
   */
  minMonths: number
  /**
   * Number of accompanying children who are teenagers (this will affect the fees if applicable)
   */
  numberTeens: number
  /**
   * The key to access the correct itemized products for the number of teens
   */
  teenNumberKey: any
  /**
   * The name of the service that is being purchased
   */
  serviceName: string
  /**
   * The itemized products that are displayed side by side
   */
  sideBySideLineItems: any
  /**
   * Tells us if any of the itemized products have an associated discount applied (outside of the Pay-in-full discount)
   */
  hasAppliedDiscounts?: boolean
  /**
   * The service tier that the user is purchasing
   */
  tier: Tier
}

interface MappedProps {
  /**
   * The feature flag for Splitit payments
   */
  isMaxOf12PaymentsActive?: boolean
}

type Props = ExplicitProps & MappedProps

const PaymentPanelTotalAndOptions: FunctionComponent<Props> = ({
  currentKaseIsAOS,
  finalTotalFeesInCents,
  includeUscisFees,
  isMobile,
  maxMonths,
  minMonths,
  numberTeens,
  serviceName,
  sideBySideLineItems,
  teenNumberKey,
  hasAppliedDiscounts,
  tier,
  isMaxOf12PaymentsActive
}) => {
  const [showServiceFeeModal, setShowServiceFeeModal] = useState(false)
  const displayLineItemsInFull = includeUscisFees
    ? sideBySideLineItems.with_uscis.pay_in_full[teenNumberKey]
    : sideBySideLineItems.boundless_only.pay_in_full
  const displayLineItemsOverTime = includeUscisFees
    ? sideBySideLineItems.with_uscis.pay_over_time[teenNumberKey]
    : sideBySideLineItems.boundless_only.pay_over_time
  const showAppliedDiscounts = hasAppliedDiscounts

  const onShowServiceFeeModal = () => {
    setShowServiceFeeModal(true)
  }
  const serviceFeeExplanation =
    'This service charge covers the work involved in Boundless taking care of your application filing fees exactly to government standards to avoid the risk of mistakes and delays.  \n  \nIf choosing to pay monthly (vs. in full), the service charge is higher because of the increased work involved in our finance team processing your government fees over a series of months. This is common in many companies and services that offer a pay-over-time option, and helps ensure we can offer you the most convenient payment plans.'

  const childLineItem = displayLineItemsInFull.line_items.find((item) => item.name.includes('child'))
  const modifiedLineItems = (lineItems) => {
    return lineItems.map((lineItem) => {
      if (lineItem.name === 'Boundless application fee') {
        if (childLineItem) {
          return { ...lineItem, name: '1 adult' }
        } else {
          return { ...lineItem, name: serviceName }
        }
      } else {
        return lineItem
      }
    })
  }

  // Find the USCIS filing fees, divide that by the monthly payment amount, and round up to the nearest whole number
  const minInstallmentsBeforeShipping = () => {
    // Get the raw data
    const uscis_fees = displayLineItemsOverTime.line_items.find((item) => item.name === 'USCIS filing fees')
    const monthly_payment = displayLineItemsOverTime.monthly

    // create some JSX with the result
    let result_string: string
    if (uscis_fees && monthly_payment) {
      // calculate the minimum number of installments before shipping
      const number_of_payment = Math.ceil(uscis_fees.amount / monthly_payment)

      // if things get weird, limit the damage
      if (number_of_payment < 1 || number_of_payment > 12) {
        result_string = 'A minimum number of installments will be due before we file your application with USCIS'
      } else if (number_of_payment === 1) {
        result_string = '1 installment due before we file your application with USCIS'
      } else {
        result_string = `${number_of_payment} installments due before we file your application with USCIS`
      }
    } else {
      result_string = 'A minimum number of installments will be due before we file your application with USCIS'
    }

    // Now append the Learn More url link
    return (
      <span>
        {result_string + ' '}
        <a
          href="https://www.boundless.com/payment-plan/#understanding-boundless-payment-schedule"
          target="_blank"
          rel="noreferrer"
        >
          (Learn More)
        </a>
      </span>
    )
  }

  return (
    <>
      {showServiceFeeModal && (
        <ContextualHelpModal
          header="What’s included in the filing fee service charge"
          isOpen={showServiceFeeModal}
          onRequestClose={() => setShowServiceFeeModal(false)}
          text={serviceFeeExplanation}
        />
      )}
      <div className="border border-default c-sheet__body p-0 position-relative">
        <div className="bg-gray-100 mb-3 sm:p-3 md:px-4 md:py-6 text-left">
          <div className="flex flex-row justify-between">
            <div className="flex flex-col sm:pr-3 md:pt-2 md:pr-4" style={{ flex: '0 0 50%' }}>
              <p
                className={cx('c-type--emphasized c-type--body-sans-md c-type--bold', {
                  'mb-3': isMobile,
                  'mb-5': !isMobile
                })}
              >
                Pay in full
              </p>
              <>
                <ul
                  className={cx('o-list-bare c-type c-type--body-sans-sm flex flex-col pt-2', {
                    'mb-4': !showAppliedDiscounts,
                    'mb-6': showAppliedDiscounts
                  })}
                >
                  {childLineItem && (
                    <li className="flex mb-4">
                      <p className="c-type--body-sans-sm c-type--bold">{serviceName}</p>
                    </li>
                  )}

                  <CheckoutLineItems
                    lineItems={modifiedLineItems(displayLineItemsInFull.line_items)}
                    onShowServiceFeeModal={onShowServiceFeeModal}
                    showAppliedDiscounts={showAppliedDiscounts}
                  />
                  {showAppliedDiscounts && (
                    <CheckoutLineItems
                      isDiscount={true}
                      lineItems={displayLineItemsInFull.discounts}
                      showAppliedDiscounts={true}
                    />
                  )}
                </ul>
                <div className="md:flex justify-between c-type c-type--emphasized" style={{ marginTop: 'auto' }}>
                  <div className="c-type--body-sans-sm sm:mb-2">Total</div>
                  <div className="mt-1 c-type--headline-sm">{formatMoney(displayLineItemsInFull.total)}</div>
                </div>
              </>
            </div>
            <div
              className="border-l border-default sm:pl-3 md:pt-2 md:pl-4 flex flex-col position-relative"
              style={{ flex: '0 0 50%' }}
            >
              <p
                className={cx('c-type--emphasized c-type--body-sans-md c-type--bold', {
                  'mb-3': isMobile,
                  'mb-5': !isMobile
                })}
              >
                Pay monthly
              </p>
              <>
                <ul
                  className={cx('o-list-bare c-type c-type--body-sans-sm flex flex-col pt-2', {
                    'mb-4': !showAppliedDiscounts,
                    'mb-6': showAppliedDiscounts
                  })}
                >
                  {childLineItem && (
                    <li className="flex mb-4">
                      <p className="c-type--body-sans-sm c-type--bold">{serviceName}</p>
                    </li>
                  )}
                  <CheckoutLineItems
                    lineItems={modifiedLineItems(displayLineItemsOverTime.line_items)}
                    onShowServiceFeeModal={onShowServiceFeeModal}
                    showAppliedDiscounts={showAppliedDiscounts}
                  />
                  {showAppliedDiscounts && (
                    <CheckoutLineItems
                      isDiscount={true}
                      lineItems={displayLineItemsOverTime.discounts}
                      showAppliedDiscounts={true}
                    />
                  )}
                </ul>
                <div className="md:flex justify-between c-type c-type--emphasized" style={{ marginTop: 'auto' }}>
                  <div className="c-type--body-sans-sm sm:mb-2">As little as</div>
                  <div className="mt-1 c-type--headline-sm">
                    {formatMoney(displayLineItemsOverTime.monthly, true)}/month
                  </div>
                </div>
              </>
            </div>
          </div>
          <div className="flex flex-row justify-between">
            <div style={{ flex: '0 0 50%' }} />
            <div className="border-l border-default sm:pl-3 sm:py-1 md:pl-4 md:py-2" style={{ flex: '0 0 50%' }}>
              <div className="checkout-fine-print md:text-right">
                over {maxMonths} months, or {formatMoney(displayLineItemsOverTime.total)} total
              </div>
            </div>
          </div>
        </div>

        <div className="c-type--subhead-sm text-center text-gray-400">TERMS</div>
        <div className="flex justify-between sm:px-3 sm:pt-3 md:px-4 md:pt-4 text-left">
          <div className="c-type sm:pr-3 md:pr-4" style={{ flex: '0 0 50%' }}>
            <ul className="c-type--body-sans-sm mb-4 o-list-bare">
              <li className="flex mb-2">
                <GreenCircleCheckmarkListIcon className="mr-2" />
                One-time payment
              </li>
              <li className="flex mb-2">
                <GreenCircleCheckmarkListIcon className="mr-2" />
                <div>
                  Credit or debit{' '}
                  <span className="c-type--inline">
                    <ApplePayMarkIcon className="mr-1 text-black" />
                    <GooglePayMarkIcon className="mr-1" />
                  </span>
                </div>
              </li>
            </ul>
          </div>
          <div className="border-l border-default c-type sm:pl-3 md:pl-4" style={{ flex: '0 0 50%' }}>
            <ul className="c-type--body-sans-sm mb-4 o-list-bare">
              <li className="flex mb-2">
                <GreenCircleCheckmarkListIcon className="mr-2" />
                Pay over {minMonths} - {maxMonths} months, no credit check
              </li>
              <li className="flex mb-2">
                <GreenCircleCheckmarkListIcon className="mr-2" />
                Credit or debit accepted
              </li>
              {/* This term is only displayed if the customer is including USCIS fees */}
              {includeUscisFees && (
                <li className="flex">
                  <HelpIcon className="mr-2" />
                  <div>
                    <span className="c-type--inline">
                        {minInstallmentsBeforeShipping()}
                    </span>
                  </div>
                </li>
              )}
            </ul>
          </div>
        </div>
        <div className="flex justify-between sm:px-3 sm:pb-3 md:px-4 md:pb-4">
          <div className="c-type sm:pr-3 md:pr-4 pb-2 sm:text-left" style={{ flex: '0 0 50%' }}>
            <Payment
              buttonBlock={true}
              buttonLabel="Select"
              includeUscisFees={includeUscisFees}
              isMobile={isMobile}
              numberTeens={numberTeens}
              paymentType="stripe"
              tier={tier}
              totalFeesInCents={finalTotalFeesInCents}
            />
          </div>
          <div className="border-l border-default c-type sm:pl-3 md:pl-4 pb-2 sm:text-left" style={{ flex: '0 0 50%' }}>
            <Payment
              buttonBlock={true}
              buttonLabel="Select"
              includeUscisFees={includeUscisFees}
              isMobile={isMobile}
              numberTeens={numberTeens}
              paymentType="splitIt"
              tier={tier}
              totalFeesInCents={finalTotalFeesInCents}
            />
            <p className="c-type--body-sans-sm text-gray-500">Next you'll select your monthly terms</p>
          </div>
        </div>
        {/* This was for the 2023 fee hike notice, but we turned it off for the 2024 one */}
        {/* <FeeHikeNotice sx={{ mx: 1, width: 'auto' }} checkout /> */}
        <div className="sm:px-3 md:px-4">
          <hr className="c-divider mb-5" />
        </div>
        <div className="c-type--subhead-sm text-center text-gray-400">BOUNDLESS MONEY-BACK GUARANTEE</div>
        <div className="sm:p-3 md:p-4">
          <ul className="c-type c-type--body-sans-sm o-list-bare sm:text-left">
            <li className="flex mb-2">
              <GreenCircleCheckmarkListIcon className="mr-2" />
              Change your mind within 14 days of purchasing? Get a full refund — guaranteed.
            </li>
            <li className="flex">
              <GreenCircleCheckmarkListIcon className="mr-2" />
              Guaranteed visa approval or your money back, with a 99.7% success rate.
            </li>
          </ul>
        </div>
      </div>
    </>
  )
}

function mapStateToProps(state): MappedProps {
  return {
    isMaxOf12PaymentsActive: getIsFeatureEnabled(state, FEATURES.SPLITIT_MAX_INSTALLMENTS_12MO_ENABLED)
  }
}

export default connect(mapStateToProps, null) ( PaymentPanelTotalAndOptions )
