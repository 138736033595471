import { connect } from 'react-redux'

import { getCurrentSectionProgress } from 'reducers/selectors'
import Horizontal from './horizontal'
import Vertical from './vertical'

import { ApplicationState } from '../../reducers'

function mapStateToProps(state: ApplicationState, ownProps) {
  return {
    progress: ownProps.progress == null ? getCurrentSectionProgress(state) : ownProps.progress
  }
}

const ProgressBar = {
  Horizontal: connect(mapStateToProps)(Horizontal),
  Vertical: connect(mapStateToProps)(Vertical)
}

export default ProgressBar
