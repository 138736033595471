import React, { FunctionComponent } from 'react'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import _sortBy from 'lodash/sortBy'

import { DocumentStatus } from 'lib/constants'

import { deleteDocument } from 'actions/document_request_actions'

import ListedDocument from './listed_document'
import Button from 'components/button'
import TrashIcon from 'components/icons/trash_icon'

interface UploadingDoc extends DocumentModel {
  progress: number
  rawFile: File
}

interface ExplicitProps {
  documentRequest: DocumentRequestModel
  kaseId: number
  uploads: UploadingDoc[]
}

interface ActionProps {
  deleteDocument: typeof deleteDocument
}

type Props = ExplicitProps & ActionProps

const MobileListedDocuments: FunctionComponent<Props> = ({ deleteDocument, documentRequest, kaseId, uploads }) => {
  const onDeleteDocument = (document) => (event) => {
    event.preventDefault()
    event.stopPropagation()

    deleteDocument({
      document,
      documentRequest
    })
  }

  const sortedDocuments = documentRequest ? _sortBy(documentRequest.documents, 'created_at').reverse() : []

  return (
    <ul className="c-list pb-6 border-t border-gray-200">
      {uploads.map((doc, i) => (
        <ListedDocument
          isMobile={true}
          kaseId={kaseId}
          key={`uploading-doc-${i}`}
          document={doc}
          renderThumbnails={true}
          showDocumentStatus={false}
        />
      ))}
      {sortedDocuments.map((doc, i) => (
        <ListedDocument
          isMobile={true}
          key={`uploaded-doc-${i}`}
          document={doc}
          inlineControls={
            doc.status === DocumentStatus.NeedsReview && (
              <div className="o-layout o-layout--right">
                <Button onClick={onDeleteDocument(doc)} icon={<TrashIcon />} iconOnly color="tertiary" />
              </div>
            )
          }
          kaseId={kaseId}
          renderThumbnails={true}
          showDocumentStatus={false}
        />
      ))}
    </ul>
  )
}

function mapDispatchToActions(dispatch: Dispatch): ActionProps {
  return bindActionCreators(
    {
      deleteDocument
    },
    dispatch
  )
}

export default connect(null, mapDispatchToActions)(MobileListedDocuments)
