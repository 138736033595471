import React, { FunctionComponent, useMemo } from 'react'
import _every from 'lodash/every'
import { connect } from 'react-redux'
import { getCurrentKaseKind, getNavigableSectionsForStopGapDashboard } from 'reducers/selectors'

import PhaseInformationTemplate from './phase_information_template'
import { DASHBOARD_PHASE_INTAKE } from '../phase_map'
import IntakePrimaryCTA from '../phases/intake_phase/intake_primary_cta'
import { KaseKind } from 'lib/constants'

// Initial state, forms incomplete
export const INTAKE_IN_PROGRESS_MESSAGE =
  'Help us get to know you better. Finish the questionnaires you started to begin Attorney Assessment.'
export const INTAKE_IN_PROGRESS_DESCRIPTION =
  'No two applications are alike. Depending on your situation, U.S. Citizenship and Immigration Services (USCIS) will require specific information from you and your spouse. Your answers help {{attorney}} see the full picture of your situation and decide how to maximize your chance of getting a green card.<br/><br/>'

// All forms are complete, ready for ILA to begin
export const INTAKE_COMPLETE_MESSAGE =
  'You’ve finished the questionnaires — nice work! Submit your answers to begin Attorney Assessment.'
export const INTAKE_COMPLETE_DESCRIPTION =
  'After you submit your answers, {{attorney}} will review them to make sure there are no issues affecting your eligibility for a marriage green card. Your answers also help them decide what your application needs for the best possible chance of success. They’ll reach out via email if they have any questions.<br/><br/>'

// Initial state, forms incomplete (New MBGC)
export const NEW_INTAKE_IN_PROGRESS_MESSAGE = 'Finish the questionnaires below to start checking your forms.'
export const NEW_INTAKE_IN_PROGRESS_DESCRIPTION =
  'Your answers are used by our software to automatically fill out the government forms within your green card application.<br/><br/>'

// All forms are complete, ready for form check (New MBGC)
export const NEW_INTAKE_COMPLETE_MESSAGE =
  'Congrats! With the questionnaires complete, we’ve filled out your application forms with your answers.'
export const NEW_INTAKE_COMPLETE_DESCRIPTION = 'Click below and we’ll start checking your forms.'
interface Props {
  kaseKind: KaseKind
  sections: any[]
}

const IntakePhaseInformation: FunctionComponent<Props> = ({ kaseKind, sections }) => {
  const allSectionsFinished = useMemo(() => {
    return _every(sections, (section) => section.progress === 100)
  }, [])

  let description = allSectionsFinished ? INTAKE_COMPLETE_DESCRIPTION : INTAKE_IN_PROGRESS_DESCRIPTION

  let header = allSectionsFinished ? INTAKE_COMPLETE_MESSAGE : INTAKE_IN_PROGRESS_MESSAGE

  if (kaseKind === KaseKind.NewMarriageBasedGreenCard) {
    description = allSectionsFinished ? NEW_INTAKE_COMPLETE_DESCRIPTION : NEW_INTAKE_IN_PROGRESS_DESCRIPTION

    header = allSectionsFinished ? NEW_INTAKE_COMPLETE_MESSAGE : NEW_INTAKE_IN_PROGRESS_MESSAGE
  }

  return (
    <PhaseInformationTemplate
      currentPhase={DASHBOARD_PHASE_INTAKE}
      header={header}
      description={description}
      primaryCTA={<IntakePrimaryCTA allSectionsFinished={allSectionsFinished} />}
    />
  )
}

function mapStateToProps(state) {
  return {
    kaseKind: getCurrentKaseKind(state),
    sections: getNavigableSectionsForStopGapDashboard(state)
  }
}

export default connect(mapStateToProps, null)(IntakePhaseInformation)
