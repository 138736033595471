import React, { FunctionComponent } from 'react'
import IconWrapper, { IconWrapperExternalProps } from './icon_wrapper'

const AlertIcon: FunctionComponent<IconWrapperExternalProps> = (props) => (
  <IconWrapper {...props} viewBox="0 0 32 32">
    <path d="M12.4102429,4.01805494 C13.1743781,2.76263537 14.5336553,2 15.9993059,2 C17.4649565,2 18.8242337,2.76263537 19.5883689,4.01805494 L31.438092,23.7321148 C32.1831092,25.017849 32.1875573,26.6009854 31.4497769,27.890857 C30.7119965,29.1807286 29.3428011,29.9835983 27.8376754,30 L4.14557504,29.9999159 C2.65581061,29.9835983 1.2866153,29.1807286 0.548834861,27.890857 C-0.18894558,26.6009854 -0.184497398,25.017849 0.571873318,23.7128787 L12.4102429,4.01805494 Z M2.98127916,25.1251278 C2.7329401,25.5537058 2.73145737,26.0814179 2.97738419,26.5113751 C3.223311,26.9413323 3.67970944,27.2089556 4.16093631,27.2143106 L27.8223142,27.2143948 C28.3189023,27.2089556 28.7753008,26.9413323 29.0212276,26.5113751 C29.2671544,26.0814179 29.2656717,25.5537058 29.0286861,25.1443639 L17.1943243,5.45616579 C16.9409485,5.03990115 16.4878561,4.78568936 15.9993059,4.78568936 C15.5118269,4.78568936 15.0596503,5.03878762 14.805957,5.45343006 L2.98127916,25.1251278 Z M14.6016235,11.8930192 C14.6016235,11.1237723 15.2273872,10.5001745 15.9993059,10.5001745 C16.7712245,10.5001745 17.3969882,11.1237723 17.3969882,11.8930192 L17.3969882,17.4643979 C17.3969882,18.2336448 16.7712245,18.8572426 15.9993059,18.8572426 C15.2273872,18.8572426 14.6016235,18.2336448 14.6016235,17.4643979 L14.6016235,11.8930192 Z M15.0109952,22.6278218 C14.4651663,22.0838822 14.4651663,21.2019817 15.0109952,20.658042 C15.5568241,20.1141023 16.4417876,20.1141023 16.9876166,20.658042 C17.5334455,21.2019817 17.5334455,22.0838822 16.9876166,22.6278218 C16.4417876,23.1717615 15.5568241,23.1717615 15.0109952,22.6278218 Z" />
  </IconWrapper>
)

export default AlertIcon
