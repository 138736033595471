import { AxiosPromise } from 'axios'
import { ServerModelUpdate } from 'reducers/model'

import server from './server'
import * as userApi from 'lib/api/users'

interface KaseUpdateRequest {
  kaseId: number
  panelName: string
  updates: ServerModelUpdate[]
}

interface KaseProgressUpdateRequest {
  kaseId: number
  progress: number
}

function fetchKase(kaseId: number): AxiosPromise {
  return server.get(`/api/kases/${kaseId}`)
}

function fetchMetadata(kaseId: number): AxiosPromise {
  return server.get(`/api/kases/${kaseId}/metadata.json`).then((response) => response.data)
}

function fetchFormMetadata(kaseId: number): AxiosPromise {
  return server
    .get(`/api/kases/${kaseId}/form_metadata.json`)
    .then((response) => response.data)
    .catch((err) => {
      if (err.response.status === 401) {
        // we were ending up here because a user who logged out of the visit subdomain but retained a JWT on apply
        // would end up seeing a mismatch of the new guest kase and the user in the JWT
        // so this is a simple fix until we use oauth everywhere
        userApi.default.signOut().then(() => {
          window.location.href = '/users/sign_in'
        })
      }
    })
}

function updateData({ kaseId, panelName, updates }: KaseUpdateRequest): AxiosPromise {
  const data = {
    panel_name: panelName,
    updates
  }

  return server.put(`/applications/${kaseId}/data`, data).then((response) => response.data)
}

function updateProgress({ kaseId, progress }: KaseProgressUpdateRequest): AxiosPromise {
  const data = {
    kase: {
      progress: progress
    }
  }

  return server.put(`/api/kases/${kaseId}`, data).then((response) => response.data)
}

function updateRemainingInfoProgress({ kaseId, progress }: KaseProgressUpdateRequest): AxiosPromise {
  const data = {
    kase: {
      remaining_information_progress: progress
    }
  }

  return server.put(`/api/kases/${kaseId}`, data).then((response) => response.data)
}

function updateLastViewedSectionAndPanelName({
  kaseId,
  lastViewedSectionAndPanelName
}: KaseProgressUpdateRequest): AxiosPromise {
  const data = {
    kase: {
      last_panel_name: lastViewedSectionAndPanelName
    }
  }

  return server.put(`/api/kases/${kaseId}`, data).then((response) => response.data)
}

function adminUpdate({ kaseId, attributes }: { kaseId: number; attributes: any }): AxiosPromise {
  return server.put(`/admin/kases/${kaseId}`, attributes).then((response) => response.data)
}

function pollBulkDocumentDownload(kaseId, resolve) {
  server.get(`/applications/${kaseId}/document_archive`).then(function (response) {
    if (response.status === 204) {
      setTimeout(() => pollBulkDocumentDownload(kaseId, resolve), 1000)
    } else {
      window.location = response.data.download_url
      resolve()
    }
  })
}

function bulkDocumentDownload({ kaseId }: { kaseId: number }): Promise<null> {
  return new Promise((resolve, reject) => {
    server.post(`/applications/${kaseId}/document_archive`).then(function (response) {
      if (response.status === 201) {
        pollBulkDocumentDownload(kaseId, resolve)
      } else {
        reject()
      }
    })

    return null
  })
}

function refund({ kaseId, refund }: { kaseId: number; refund: any }): AxiosPromise {
  return server.post(`/admin/kases/${kaseId}/refunds`, refund).then((response) => response.data)
}

interface StartAssessmentParams {
  kaseId: string | number
}

function startLawyerAssessment({ kaseId }: StartAssessmentParams): AxiosPromise<{ new_workflow_status?: string }> {
  const url = `/api/kases/${kaseId}/lawyer_assessment?from=post_payment_dashboard`
  return server.post(url)
}

function moveKaseToQA({ kaseId, movedByCustomer }) {
  const moveToQAData = {
    data: {
      movedByCustomer
    }
  }

  return server.post(`/api/kases/${kaseId}/document-review/move-to-qa`, moveToQAData)
}

export default {
  fetchKase,
  fetchMetadata,
  fetchFormMetadata,
  moveKaseToQA,
  updateData,
  updateLastViewedSectionAndPanelName,
  updateProgress,
  updateRemainingInfoProgress,
  adminUpdate,
  bulkDocumentDownload,
  refund,
  startLawyerAssessment
}
