import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'

import {
  getVisibleCustomerDocumentRequests,
  getActionableDocumentRequests,
  getCurrentDocumentUploadSummaryState,
  getWorkflowStatus,
  hasUnresolvedComments,
  isSelfSufficiencyIncomplete,
  allSectionsComplete
} from 'reducers/selectors'

import { WORKFLOW_STATUSES, DocumentUploadSummaryStates } from 'lib/constants'

import PhaseInformationTemplate from 'components/screens/dashboard/customer_dashboard/phase_information/phase_information_template'
import { DashboardPhase } from 'components/screens/dashboard/customer_dashboard/phase_map'
import { selfSufficiencyIncompleteContent, initialDocRequestContent } from './doc_upload_phase_content'

import InitialDocRequestContent from './initial_doc_request_content'
import DocGatheringQuestionnairesComplete from './doc_gathering_questionnaires_complete'
import DocGatheringQuestionnairesIncomplete from './doc_gathering_questionnaires_incomplete'

interface MappedProps {
  allSectionsComplete: boolean
  commentsPresent: boolean
  currentDocumentUploadSummaryState?: DocumentUploadSummaryStates
  documentRequests: { documents: DocumentModel[] }[]
  actionableDocumentRequestCount: number
  workflowStatus: string
  selfSufficiencyIncomplete: boolean
}

interface ExplicitProps {
  currentPhase: DashboardPhase
}

type Props = MappedProps & ExplicitProps

const DocumentUploadPhaseInformation: FunctionComponent<Props> = ({
  allSectionsComplete,
  currentDocumentUploadSummaryState,
  currentPhase,
  actionableDocumentRequestCount,
  workflowStatus,
  documentRequests,
  selfSufficiencyIncomplete
}) => {
  if (documentRequests.length === 0) null

  const isAOS =
    workflowStatus === WORKFLOW_STATUSES.AOS_INITIAL_DOC_REQUEST ||
    workflowStatus === WORKFLOW_STATUSES.AOS_DOC_GATHERING

  const isInitialDocRequest =
    workflowStatus === WORKFLOW_STATUSES.AOS_INITIAL_DOC_REQUEST ||
    workflowStatus === WORKFLOW_STATUSES.CP_INITIAL_DOC_REQUEST

  const userCompletionEnabled = window.isUserCompletionEnabled()

  if (selfSufficiencyIncomplete) {
    const contentForStatus = selfSufficiencyIncompleteContent

    return (
      <PhaseInformationTemplate
        currentPhase={currentPhase}
        primaryCTA={contentForStatus.primaryCTA}
        header={contentForStatus.phaseInstruction}
        description={contentForStatus.phaseDescription}
      />
    )
  }

  if (isInitialDocRequest) {
    return (
      <InitialDocRequestContent
        currentDocumentUploadSummaryState={currentDocumentUploadSummaryState}
        currentPhase={currentPhase}
      />
    )
  }

  if (allSectionsComplete || !userCompletionEnabled) {
    return (
      <DocGatheringQuestionnairesComplete
        actionableDocumentRequestCount={actionableDocumentRequestCount}
        currentDocumentUploadSummaryState={currentDocumentUploadSummaryState}
        currentPhase={currentPhase}
        isAOS={isAOS}
      />
    )
  }

  if (!allSectionsComplete && userCompletionEnabled) {
    return (
      <DocGatheringQuestionnairesIncomplete
        currentDocumentUploadSummaryState={currentDocumentUploadSummaryState}
        currentPhase={currentPhase}
      />
    )
  }

  const defaultContent = initialDocRequestContent(false)

  return (
    <PhaseInformationTemplate
      currentPhase={currentPhase}
      primaryCTA={defaultContent.primaryCTA}
      header={defaultContent.phaseInstruction}
      description={defaultContent.phaseDescription}
    />
  )
}

function mapStateToProps(state) {
  return {
    actionableDocumentRequestCount: getActionableDocumentRequests(state).length,
    allSectionsComplete: allSectionsComplete(state),
    commentsPresent: hasUnresolvedComments(state),
    currentDocumentUploadSummaryState: getCurrentDocumentUploadSummaryState(state),
    documentRequests: getVisibleCustomerDocumentRequests(state),
    workflowStatus: getWorkflowStatus(state),
    selfSufficiencyIncomplete: isSelfSufficiencyIncomplete(state)
  }
}

export default connect(mapStateToProps, null)(DocumentUploadPhaseInformation)
