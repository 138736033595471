import React from 'react'
import { Palette } from '@boundless-immigration/boundless-ui'

const ArrowIllustration = () => (
  <svg width="34" height="30" viewBox="0 0 34 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.0234 30L14.8796 26.8562L24.5127 17.223L0.980205 17.223L0.980205 12.777L24.5127 12.777L14.8796 3.14385L18.0234 1.46031e-05L33.0234 15L18.0234 30Z"
      fill={Palette.emphasisMain}
    />
  </svg>
)

export default ArrowIllustration
