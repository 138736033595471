import React, { FunctionComponent } from 'react'
import classNames from 'classnames'

import { QuestionnaireInputContainerProps, InputOption } from '../lib/types'

type Props = QuestionnaireInputContainerProps & {
  options: InputOption[]
  orientation: 'vertical' | 'horizontal' | 'grid'
}

const RadioGroup: FunctionComponent<Props> = ({
  disabled,
  id,
  orientation,
  options,
  onBlurEvent,
  onChangeEvent,
  value
}) => {
  return (
    <div
      className={classNames('c-paper-form__group', {
        'o-block': orientation === 'vertical',
        'o-grid--fluid': orientation !== 'vertical'
      })}
    >
      {options.map((option) => {
        const classes = classNames({
          'o-block': orientation !== 'horizontal',
          'o-grid__cell--6/12 o-grid--fill-y@sm': orientation === 'grid',
          'o-grid__cell--6/12': orientation === 'horizontal' && options.length === 2,
          'o-grid__cell--4/12': orientation === 'horizontal' && options.length === 3,
          'o-grid__cell--3/12': orientation === 'horizontal' && options.length === 4
        })

        return (
          <div className={classes} key={option.key}>
            <label className={classNames('o-block', 'c-custom-control', 'c-custom-control--radio')}>
              <input
                type="radio"
                checked={value === option.key}
                className="c-custom-control__input"
                id={`form-${id}-${option.key}`}
                disabled={disabled}
                name={id}
                value={option.key}
                onChange={onChangeEvent}
                onBlur={onBlurEvent}
              />

              <span className="c-custom-control__indicator" />

              <div
                className={classNames('c-custom-control__description c-type--primary c-type', {
                  'c-type--subhead-sm': orientation === 'horizontal',
                  'c-type--body-sans-md': orientation !== 'horizontal'
                })}
              >
                {option.value}
              </div>
            </label>
          </div>
        )
      })}
    </div>
  )
}

export default RadioGroup
