import { Issue, MessagesById } from 'reducers/issues'

export const mostRecentMessageIsFromUser = (issue: Issue, messages: MessagesById, userId: number) => {
  const issueMessageIds = issue?.relationships?.messages?.data || []
  const numberOfMessages = issueMessageIds.length

  if (numberOfMessages > 0) {
    // sort messages by created_at in descending order
    const mostRecentMessage = issueMessageIds
      .map((message) => {
        return {
          id: message.id,
          ...messages[message.id]
        }
      })
      .sort((a, b) => {
        return new Date(b.attributes.created_at) - new Date(a.attributes.created_at)
      })[0]

    return messages[mostRecentMessage?.id]?.attributes.creator_id === userId.toString()
  }

  return false
}
