import React, { FunctionComponent } from 'react'
import StepItem from './step_item'
import { KasePhase } from './types'
import SkeletonParagraph from 'components/skeleton/paragraph'
import SkeletonHeading from 'components/skeleton/heading'
import CustomerCompletedAOS from './customer_completed/aos'
import CustomerCompletedCP from './customer_completed/cp'

interface Props {
  currentPhase: KasePhase
  kaseId: number
  kaseState: string
  kaseIsAOS: boolean
  isLoading: boolean
}

const WorkArea: FunctionComponent<Props> = (props) => {
  const { currentPhase, kaseId, kaseIsAOS, kaseState, isLoading } = props
  /**
   * Handles the established dashboard button_location pattern of:
   * If the path starts with `/`, its an absolute path, pass through as is.
   * If the path does not start with `/` its relative and should have `/applications/:kaseId/` appended.
   * Special case for Inactive and Cancelled phases
   */
  const getButtonUrl = (path: string | null): string | null => {
    if (!path) {
      return null
    }
    if (currentPhase.name === 'INACTIVE' || currentPhase.name === 'CANCELLED') {
      return 'mailto:be@boundless.com'
    } else {
      return path.indexOf('/') === 0 ? path : `/applications/${kaseId}/${path}`
    }
  }

  const isCustomerCompleted = kaseState === 'customer_completed'

  if (isLoading) {
    return (
      <div className="o-block o-box o-box--light c-dashboard__work-area o-layout--impose">
        <SkeletonHeading />
        <SkeletonParagraph numLines={3} />
        <SkeletonParagraph numLines={3} className="pt-8" />
        <SkeletonParagraph numLines={3} className="pt-8" />
        <SkeletonParagraph numLines={3} className="pt-8" />
        <SkeletonParagraph numLines={3} className="pt-8" />
      </div>
    )
  }

  if (isCustomerCompleted) {
    if (kaseIsAOS) {
      return <CustomerCompletedAOS />
    }
    return <CustomerCompletedCP />
  }

  return (
    <div className="o-block o-box o-box--light c-dashboard__work-area o-layout--impose" data-qa="work-area">
      <h2 className="o-block c-type c-type--subhead-md c-type--emphasized">Next Steps</h2>
      <ul className="o-block c-list">
        {currentPhase?.steps?.map((step) => {
          return (
            <StepItem
              confirmation={step.confirmation}
              detail={step.detail}
              disabled={step.disabled}
              eventName={step.button_event}
              key={step.name}
              name={step.name}
              phaseName={currentPhase.name}
              progress={step.percent_complete}
              url={getButtonUrl(step.button_location)}
            />
          )
        })}
      </ul>
    </div>
  )
}

export default WorkArea
