import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'

import Paragraph from 'components/type/paragraph'
import CustomerFeedbackUploader from './customer_feedback_uploader'
import api from 'lib/api'
import { getCurrentKaseId, getMasterPdfId } from 'reducers/selectors'

interface Props {
  kaseId: number
  masterPdfId?: number
}

const UploadCustomerFeedback: FunctionComponent<Props> = (props) => {
  const url = api.routes.viewMasterPdfUrl(props.masterPdfId)

  return props.masterPdfId ? (
    <>
      <h2 className="o-block c-type c-type--subhead-sm">Next steps</h2>
      <hr className="o-block o-block--generous c-divider" />
      <h3 className="o-block c-type c-type--headline-sm c-type--emphasized">1. Preview Application</h3>
      <hr className="o-block c-divider c-divider--narrow c-divider--emphasized c-divider--secondary" />
      <Paragraph>
        Please click below to download a PDF preview of your complete application package. We highly recommend using{' '}
        <a href="https://get.adobe.com/reader/" rel="noopener noreferrer" target="_blank">
          Adobe Reader
        </a>{' '}
        to view your PDF preview as it may not display correctly using your phone, web browser, or Mac PDF reader.
      </Paragraph>

      <Paragraph>
        Boundless encrypts your digital application documents with an access passcode to protect your personal
        information. Please let us know if you have any trouble accessing the document.
      </Paragraph>

      <div className="o-block o-block--generous">
        <a role="button" target="_blank" rel="noopener noreferrer" href={url} className="c-btn c-btn--primary">
          Download Application
        </a>
      </div>

      <h3 className="o-block c-type c-type--headline-sm c-type--emphasized">2. Download Digital Review Packet</h3>
      <hr className="o-block c-divider c-divider--narrow c-divider--emphasized c-divider--secondary" />
      <Paragraph>
        Please click below to download your Digital Review Packet for your application package. Use the Feedback Form in
        the packet to approve your application package or ask for changes you’d like us to make.
      </Paragraph>
      <div className="o-block o-block--generous">
        <a href={api.routes.feedbackTemplateUrl(props.kaseId)} className="c-btn c-btn--primary">
          Download Packet
        </a>
      </div>

      <h3 className="o-block c-type c-type--headline-sm c-type--emphasized">3. Upload Digital Review Packet</h3>
      <hr className="o-block c-divider c-divider--narrow c-divider--emphasized c-divider--secondary" />
      <Paragraph>
        Please upload your completed Digital Review Packet below, and then click the "Submit Feedback" button. We'll
        incorporate your feedback shortly after.
      </Paragraph>
      <CustomerFeedbackUploader />
      <Paragraph>
        <strong className="c-type--inline c-type--bold">Don't have any feedback to submit?</strong> Please write "no
        feedback" on the template and upload the file to let Boundless know.
      </Paragraph>
    </>
  ) : (
    <div className="o-layout--center o-box o-box--spacious">
      <Paragraph>The PDF preview of application package is not ready for review yet.</Paragraph>
    </div>
  )
}

function mapStateToProps(state) {
  return {
    kaseId: getCurrentKaseId(state),
    masterPdfId: getMasterPdfId(state)
  }
}

export default connect(mapStateToProps)(UploadCustomerFeedback)
