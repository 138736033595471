import { createSelector } from 'reselect'

import { isBlank } from 'lib/presence'
import { WORKFLOW_STATUSES } from 'lib/constants'
import {
  STATUS_TO_PHASE_MAP,
  DASHBOARD_PHASE_SHIPMENT
} from 'components/screens/dashboard/customer_dashboard/phase_map'

export const getCurrentKase = (application) => application.kase

export const getCurrentKaseId = createSelector(getCurrentKase, (kase) => kase && kase.id)

export const getPaymentPageUrl = createSelector(getCurrentKaseId, (kaseId) => `/applications/${kaseId}/payment`)

export const getTimelinePageUrl = createSelector(getCurrentKaseId, (kaseId) => `/applications/${kaseId}/timeline`)

export const getCurrentKaseKind = createSelector(getCurrentKase, (kase) => kase && kase.kind)

export const getCurrentKaseState = createSelector(getCurrentKase, (kase) => kase && kase.state)

export const getCurrentKaseProcess = createSelector(getCurrentKase, (kase) => kase && kase.process)

export const getCurrentKaseIsAOS = createSelector(getCurrentKase, (kase) => kase && kase.is_aos)

export const getShouldOpenAttorneyAgreementModal = createSelector(getCurrentKase, (kase) => {
  return kase && kase.show_attorney_agreement
})

export const getCurrentKaseCompletedEligibility = createSelector(
  getCurrentKase,
  (kase) => kase && kase.completed_eligibility
)

export const getCurrentKaseKindResourceKey = createSelector(getCurrentKaseKind, (kaseKind) => {
  if (kaseKind === 'Naturalization') {
    return 'naturalization'
  } else if (kaseKind === 'K1FianceVisa') {
    return 'k1_fiance_visa'
  } else if (kaseKind === 'MarriageBasedGreenCard') {
    return 'marriage_based_green_card'
  } else {
    return kaseKind
  }
})

export const getCurrentKaseKindString = createSelector(getCurrentKaseKind, (kaseKind) => {
  if (kaseKind === 'Naturalization') {
    return 'naturalization'
  } else if (kaseKind === 'K1FianceVisa') {
    return 'K-1 fiancé visa'
  } else if (kaseKind === 'MarriageBasedGreenCard' || kaseKind === 'NewMarriageBasedGreenCard') {
    return 'green card'
  } else {
    return kaseKind
  }
})

export const getTrelloCardLabels = (application) => application.kase.trello_card_labels

export const isAttorneyAgreementAccepted = (application) => Boolean(application.attorneyAgreementAccepted)

export const isCurrentKasePaid = createSelector(getCurrentKase, (kase) => kase && kase.paid === true)

export const getIsConvertedKase = createSelector(getCurrentKase, (kase) => kase && kase.is_converted_kase === true)

export const getCoupleFirstNames = createSelector(getCurrentKase, ({ couple_first_names }) => couple_first_names)

export const getApplicationFeeInCents = (application) => application.kase.application_fee_in_cents

export const getLawyerReviewFeeInCents = (application) => application.kase.lawyer_review_fee_in_cents

export const getBoundlessFeeInCents = (application) =>
  getApplicationFeeInCents(application) - getLawyerReviewFeeInCents(application)

const getNetApplicationFeeInCents = (application) => application.kase.net_application_fee_in_cents

export const isApplicationFeeChanged = (application) =>
  getApplicationFeeInCents(application) !== getNetApplicationFeeInCents(application)

export const getCurrentApplicationFeeInCents = (application) => {
  return getNetApplicationFeeInCents(application)
}

export const getCurrentApplicationFeeInDollars = createSelector(
  getCurrentApplicationFeeInCents,
  (applicationFeeInCents) => parseFloat(applicationFeeInCents / 100)
)

export const getCurrentKaseLawyer = createSelector(getCurrentKase, (kase) => kase.lawyer_name)

// User functions
// TODO consider moving these to their own session reducer

function emailIsGuestAccount(email) {
  return !!email.trim().match(/^guest_(\d+)@boundless.(co|com)$/i)
}

export const getCurrentUser = (application) => application.currentUser

export const getCurrentUserInitialTrackingMetadata = createSelector(
  getCurrentUser,
  (user) => user.initial_tracking_metadata
)

export const getCurrentUserEmail = createSelector(getCurrentUser, (user) => {
  if (isBlank(user) || emailIsGuestAccount(user.email)) {
    return null
  }

  return user.email
})

export const getCurrentUserPhoneNumber = createSelector(getCurrentUser, (user) => {
  if (isBlank(user)) {
    return null
  }

  return user.phone_number
})

export const getCurrentUserId = createSelector(getCurrentUser, (user) => {
  if (isBlank(user)) {
    return null
  }

  return user.id
})

export const getCurrentUserTrackingMetadata = createSelector(getCurrentUser, (user) => {
  if (isBlank(user)) {
    return null
  }

  return user.initial_tracking_metadata
})

export const getCurrentUserHasAcceptedAttorneyAgreement = createSelector(getCurrentUser, (user) => {
  if (isBlank(user)) {
    return null
  }

  return user.accepted_attorney_agreement
})

export const getCurrentUserVWOEnrollments = createSelector(getCurrentUser, (user) => {
  if (isBlank(user)) {
    return null
  }

  return user.vwo_enrollments
})

/**
 *
 * @param {*} application
 * @param {*} campaignKey The campaign key is the name of the campaign in VWO without the environment suffix
 * @returns
 */
export const getVwoCampaignVariant = (application, campaignKey) => {
  const enrollments = getCurrentUserVWOEnrollments(application)

  const vwoEnrollment = enrollments.find((enrollment) => enrollment.attributes.campaign_key.indexOf(campaignKey) !== -1)
  if (vwoEnrollment && vwoEnrollment.attributes.enabled) {
    return vwoEnrollment.attributes.variant
  }
  return null
}

export const getRecordedPetitionProgress = (application) => application.kase.recorded_petition_progress

export const getWorkflowStatus = (application) => application.kase.workflow_status || null

export const workflowStatusIsPaid = (application) => {
  const paidEnums = [WORKFLOW_STATUSES.AOS_PAID, WORKFLOW_STATUSES.CP_PAID, 'natz_typeforms_sent']

  return paidEnums.indexOf(getWorkflowStatus(application)) !== -1
}

export const workflowStatusIsDocGathering = (application) => {
  const docGatheringEnums = [
    WORKFLOW_STATUSES.AOS_DOC_GATHERING,
    WORKFLOW_STATUSES.CP_DOC_GATHERING,
    WORKFLOW_STATUSES.CP_I130_DOC_GATHERING,
    'natz_document_gathering'
  ]

  return docGatheringEnums.indexOf(getWorkflowStatus(application)) !== -1
}

export const workflowStatusAllowsCustomerReview = (application) => {
  const customerReviewEnums = [
    WORKFLOW_STATUSES.AOS_READY_FOR_CUSTOMER_REVIEW,
    WORKFLOW_STATUSES.CP_READY_FOR_CUSTOMER_REVIEW,
    WORKFLOW_STATUSES.AOS_CUSTOMER_REVIEW,
    WORKFLOW_STATUSES.CP_CUSTOMER_REVIEW
  ]

  return customerReviewEnums.indexOf(getWorkflowStatus(application)) !== -1
}

export const isKaseBeingRefunded = (application) => {
  const refundingMappings = [
    WORKFLOW_STATUSES.AOS_REFUNDING,
    WORKFLOW_STATUSES.CP_REFUNDING,
    WORKFLOW_STATUSES.NATZ_REFUNDING,
    WORKFLOW_STATUSES.POST_SHIPMENT_REFUNDING
  ]

  return refundingMappings.indexOf(getWorkflowStatus(application)) !== -1
}

export const getCurrentDashboardPhaseForKase = createSelector(getWorkflowStatus, (workflowStatus) => {
  // TODO: handle RFE Board Columns, for now default to Shipped if workflow status is null
  return STATUS_TO_PHASE_MAP[workflowStatus] || DASHBOARD_PHASE_SHIPMENT
})
