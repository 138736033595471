import React, { FunctionComponent } from 'react'
import cx from 'classnames'

interface ExplicitProps {
  isMobileBrowser: boolean
}

const ProcessInfoColumnWrapper: FunctionComponent<ExplicitProps> = ({ isMobileBrowser, children }) => {
  const outerClassName = cx('border-t border-r border-gray-300 py-4 px-4', {
    'o-griddle__col--12': isMobileBrowser,
    'o-griddle__col': !isMobileBrowser
  })
  const innerClassName = cx('flex justify-between h-full', {
    'flex-row': isMobileBrowser,
    'flex-col': !isMobileBrowser
  })

  return (
    <div className={outerClassName}>
      <div className={innerClassName}>{children}</div>
    </div>
  )
}

export default ProcessInfoColumnWrapper as FunctionComponent<ExplicitProps>
