import { buildMessage, Validator } from './'
import { pathParent } from 'lib/path_helpers'

const hasFiled: string = (path: string, getModelData: Function) => {
  return getModelData(pathParent(path) + '.has_filed_tax_recent_year_return')
}

const requiredDependingOnTaxReturnReason: Validator = ({ getModelData, path }) => {
  const currentValue = getModelData(path)

  const reasonsThatRequireExplanation = ['not_required_to_file', 'not_going_to_file', 'other']

  const reason = getModelData(pathParent(path) + '.reason_for_not_filing_recent_tax_return')

  if (
    hasFiled(path, getModelData) === 'no' &&
    reasonsThatRequireExplanation.includes(reason) &&
    currentValue === null
  ) {
    return [buildMessage('Please explain why')]
  }

  return []
}

export { requiredDependingOnTaxReturnReason }
