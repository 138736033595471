import React, { FunctionComponent } from 'react'
import ProcessInfoColumn from 'components/panels/outcomes/rapid_visa_redirect/process_info_column'
import ProcessInfoHeader from 'components/panels/outcomes/rapid_visa_redirect/process_info_header'
import BeginApplicationColumn from 'components/panels/outcomes/rapid_visa_redirect/begin_application_column'
import MonthlyPlannerImage from 'images/rvpp_monthly_planner.png'
import ApplicationFormImage from 'images/rvpp_application_form.png'
import AttorneyImage from 'images/rvpp_attorney.png'
import CoupleHuggingImage from 'images/rvpp_couple_hugging.png'
import GoToRapidVisaLink from 'components/panels/outcomes/rapid_visa_redirect/go_to_rapid_visa_link'

interface ExplicitProps {
  isMobileBrowser: boolean
}

const ProcessInfo: FunctionComponent<ExplicitProps> = ({ isMobileBrowser }) => {
  const sectionClassName = isMobileBrowser ? 'pt-16' : 'p-16'

  return (
    <section className={sectionClassName}>
      <ProcessInfoHeader isMobileBrowser={isMobileBrowser} />
      <div className="flex justify-center">
        <div className="flex w-5/6">
          <div className="m-6">
            <div className="o-griddle__row o-griddle__row--no-gutters border-b border-l border-gray-300">
              <ProcessInfoColumn
                isMobileBrowser={isMobileBrowser}
                blurb="Split your Boundless and government filing fees into 6 monthly payments"
                imgSrc={MonthlyPlannerImage}
                alt="Monthly planner notebook next to a mac computer and a coffee mug"
              />
              <ProcessInfoColumn
                isMobileBrowser={isMobileBrowser}
                blurb="We review your entire application 4 separate times for the best chance of approval"
                imgSrc={ApplicationFormImage}
                alt="Hands holding an application form and a pen"
              />
              <ProcessInfoColumn
                isMobileBrowser={isMobileBrowser}
                blurb="Your independent attorney reviews your application and answers your legal questions"
                imgSrc={AttorneyImage}
                alt="A professional woman smiling at camera"
              />
              <ProcessInfoColumn
                isMobileBrowser={isMobileBrowser}
                blurb="Get your money back if your application is denied"
                imgSrc={CoupleHuggingImage}
                alt="A young couple hugging"
              />
              <BeginApplicationColumn isMobileBrowser={isMobileBrowser} />
            </div>
            {isMobileBrowser && (
              <GoToRapidVisaLink className="c-btn c-btn--block@sm-down c-btn--emphasized mt-12">
                Begin Application
              </GoToRapidVisaLink>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default ProcessInfo as FunctionComponent<ExplicitProps>
