import React, { Component } from 'react'
import { connect } from 'react-redux'

import {
  getCustomerReviewFeedbackFileName,
  getCurrentKaseId,
  getMasterPdfId,
  hasCustomerReviewPdf,
  isCustomerReviewFeedbackSubmitted,
  isCustomerFeedbackUploaded
} from 'reducers/selectors'

import { wordDocsAndPdfs } from 'javascript/lib/accepted_files_types'
import api from 'javascript/lib/api'
import UploadToPathButton from 'javascript/components/upload_to_path_button'
import { forceSaveData } from 'javascript/actions/kase_actions'
import { addFeedbackToMasterPDF } from 'javascript/actions/master_pdf_actions'

interface Props {
  addFeedbackToMasterPDF: (masterPdfId: number, signResult: any, rawFile: File) => void
  customerReviewFeedbackSubmitted: boolean
  forceSaveData: () => void
  hasCustomerFeedbackUploaded: any
  hasCustomerReviewPdf: boolean
  kaseId: number
  masterPdfId?: number
  reviewFileName?: string
  saveDocument: () => void
}

class ReviewScreen extends Component<Props> {
  renderDownloadPdfView() {
    const { masterPdfId, hasCustomerReviewPdf } = this.props

    if (hasCustomerReviewPdf) {
      const url = api.routes.viewMasterPdfUrl(masterPdfId)

      return (
        <div className="o-grid__col-4">
          <h2 className="o-block c-type c-type--headline-sm c-type--emphasized">Preview Application</h2>

          <hr className="o-block c-divider c-divider--narrow c-divider--emphasized c-divider--secondary" />

          <p className="o-block c-type c-type--body-sans-sm">
            Please click below to download a PDF preview of your complete application package. We highly recommend using{' '}
            <a href="https://get.adobe.com/reader/" rel="noopener noreferrer" target="_blank">
              Adobe Reader
            </a>{' '}
            to view your PDF preview as it may not display correctly using your phone, web browser, or Mac PDF reader.
          </p>

          <div>
            <a role="button" target="_blank" rel="noopener noreferrer" href={url} className="c-btn c-btn--primary">
              Download Application
            </a>
          </div>
        </div>
      )
    } else {
      return (
        <div className="o-block o-grid__col-4 c-card">
          <div className="o-box o-layout--padded-x o-layout--padded-y">
            <h2 className="c-type c-type--headline-sm">Application Not Yet Ready for Review</h2>
          </div>
        </div>
      )
    }
  }

  renderUploadFeedbackView() {
    const { customerReviewFeedbackSubmitted, hasCustomerFeedbackUploaded, masterPdfId, reviewFileName } = this.props

    if (customerReviewFeedbackSubmitted) {
      const url = api.routes.masterPdfFeedbackUrl(masterPdfId)

      return (
        <div className="o-block o-block--compact">
          <div className="o-block o-block--tight o-layout--padded-x o-layout--padded-y">
            <a className="c-type c-type--body-sans-md o-block o-block--compact" href={url}>
              Uploaded {reviewFileName}
            </a>
          </div>
        </div>
      )
    } else if (hasCustomerFeedbackUploaded) {
      const url = api.routes.masterPdfFeedbackUrl(masterPdfId)

      return (
        <div className="o-block o-block--compact">
          <div className="o-block o-block--tight o-layout--padded-x o-layout--padded-y">
            <a className="c-type c-type--body-sans-md o-block o-block--compact" href={url}>
              Uploaded {reviewFileName}
            </a>

            <button className="c-btn c-btn--secondary" onClick={this.deleteCustomerReviewFeedback}>
              Delete
            </button>
          </div>
        </div>
      )
    } else {
      return (
        <UploadToPathButton
          onDocumentUploaded={(signResult, rawFile) =>
            this.props.addFeedbackToMasterPDF(this.props.masterPdfId, signResult, rawFile)
          }
          acceptedFileTypes={wordDocsAndPdfs}
        />
      )
    }
  }

  deleteCustomerReviewFeedback = () => {
    const { masterPdfId } = this.props

    api.masterPdf
      .update(masterPdfId, {
        review_file_size: null,
        review_file_s3_direct_path: null,
        review_file_original_name: null
      })
      .then(() => window.location.reload(false))
  }

  submitFeedback = () => {
    const { masterPdfId } = this.props

    api.masterPdf
      .update(masterPdfId, {
        review_feedback_submitted: true
      })
      .then(() => (window.location = api.routes.timelineUrl(this.props.kaseId)))
  }

  render() {
    const { customerReviewFeedbackSubmitted } = this.props

    return (
      <div>
        <div className="o-box o-box--spacious">
          <div className="o-grid__row">
            <div className="o-grid__col-12">
              <h1 className="o-block c-type c-type--headline-md c-type--emphasized">
                It's Time to Review Your Application
              </h1>
            </div>

            <div className="o-grid__col-8">
              <p className="c-type c-type--body-sans-md">
                Your independent attorney has signed off on your complete green card application package, so we are
                almost ready to print it and ship it to you!
                <br />
                <br />
                But first, to verify that everything looks accurate to you, please follow the steps below:
              </p>
            </div>
          </div>
        </div>

        <div className="o-grid__row">
          <hr className="o-grid__col-12 c-divider o-block o-block--ample" />

          {this.renderDownloadPdfView()}

          <div className="o-grid__col-4">
            <h2 className="o-block c-type c-type--headline-sm c-type--emphasized">Download Digital Review Packet</h2>

            <hr className="o-block c-divider c-divider--narrow c-divider--emphasized c-divider--secondary" />

            <p className="o-block c-type c-type--body-sans-sm">
              Please click below to download your Digital Review Packet for your application package. Use the Feedback
              Form in the packet to approve your application package or ask for changes you’d like us to make.
            </p>

            <a href={api.routes.feedbackTemplateUrl(this.props.kaseId)} className="c-btn c-btn--primary">
              Download Packet
            </a>
          </div>

          <div className="o-grid__col-4">
            <h2 className="o-block c-type c-type--headline-sm c-type--emphasized">Upload Digital Review Packet</h2>

            <hr className="o-block c-divider c-divider--narrow c-divider--emphasized c-divider--secondary" />

            {!customerReviewFeedbackSubmitted && (
              <p className="o-block c-type c-type--body-sans-sm">
                Please upload your completed Digital Review Packet below, and then click the "Submit Feedback" button.
                We'll incorporate your feedback shortly after.
              </p>
            )}

            {customerReviewFeedbackSubmitted && (
              <p className="o-block c-type c-type--body-sans-sm">
                Thank you for submitting your feedback! We'll include it and then ship your application package to you
                shortly after. If you have any questions please contact your customer success advocate.
              </p>
            )}

            {this.renderUploadFeedbackView()}
          </div>

          <hr className="o-grid__col-12 o-block c-divider" />

          <div className="o-grid__row">
            <div className="o-layout o-layout--right o-grid__col-12">
              {!customerReviewFeedbackSubmitted && (
                <button
                  disabled={!this.props.hasCustomerFeedbackUploaded}
                  className="o-block c-btn c-btn--primary"
                  onClick={this.submitFeedback}
                >
                  Submit Feedback
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    customerReviewFeedbackSubmitted: isCustomerReviewFeedbackSubmitted(state),
    hasCustomerFeedbackUploaded: isCustomerFeedbackUploaded(state),
    hasCustomerReviewPdf: hasCustomerReviewPdf(state),
    kaseId: getCurrentKaseId(state),
    masterPdfId: getMasterPdfId(state),
    reviewFileName: getCustomerReviewFeedbackFileName(state)
  }
}

function mapDispatchToActions(dispatch) {
  return {
    addFeedbackToMasterPDF: (...args) => dispatch(addFeedbackToMasterPDF(...args)),
    forceSaveData: (...args) => dispatch(forceSaveData(...args))
  }
}

export default connect(mapStateToProps, mapDispatchToActions)(ReviewScreen)
