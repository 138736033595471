import React from 'react'

import BufferedFieldValue from 'components/forms/buffered_field_value'
import ValidatedInput from 'components/forms/validated_input'

interface Props {
  autoComplete: string
  disabled: boolean
  id: string
  inputRef: Function
  path: string
  type: string
}

export default class CityInput extends React.Component<Props> {
  static defaultProps = {
    disabled: false
  }

  render() {
    const { autoComplete, disabled, id, inputRef, path, type } = this.props

    return (
      <ValidatedInput disabled={disabled} path={path}>
        {({ fireValidation, getValidationClasses, renderValidations }) => (
          <React.Fragment>
            <BufferedFieldValue path={path}>
              {(value, onChange) => (
                <input
                  id={`${id}-city`}
                  type={type}
                  name="city"
                  ref={inputRef}
                  className={`c-paper-form__control ${getValidationClasses()}`}
                  value={value}
                  onBlur={fireValidation}
                  onChange={onChange}
                  disabled={disabled}
                  autoComplete={autoComplete}
                />
              )}
            </BufferedFieldValue>
            {renderValidations()}
          </React.Fragment>
        )}
      </ValidatedInput>
    )
  }
}
